
                <template>
<v-card  width="100%" height="100%" :dark="AppisDarkMode" flat style="padding-bottom: 100px;">
  
  
    <v-dialog v-model="UploadingMultipleSharedDocuments" max-width="500px">
                   <v-card width="100%" height="100%">
                     <v-card-title>Uploading Multiple Files</v-card-title>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="shareddocumentsprogressbar" style="display:none">
                            <ul id="shareddocumentsprogressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                   </v-card>
                 </v-dialog>
<v-dialog v-model="MustReadNoticeDialog" max-width="500">
       <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between">
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>

    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
        <v-list-item :to="'/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
     </v-dialog>

    <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
                    <v-dialog v-model="FeaturePhotoSelect" width="400">
                        <v-card width="400">
                            <v-card-title>Select Featured Photo</v-card-title>
                            <v-card-text>
                                <div v-for="img in AttachmentsArray" :key="img.itemObjKey">
                                    <v-icon @click="FeaturedPhoto = img,FeaturePhotoSelect = false">mdi-check</v-icon>
                                <v-img 
                                :src="img.ThumbURL" contain
                                height="60"/>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-btn style="margin-top:30px;" @click="ShareBlog()">Share<v-icon>mdi-share-circle</v-icon></v-btn>
   <SocialSharing :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="$route.params.id" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="UserRecord" :collectionname="'classifieds'" :SiteModerators="SiteModerators"/>
                    <v-row class="justify-center" style="padding-top:25px;">
                        <v-flex lg8 md8 sm12 xs12  class="mx-1">
                         
                            <v-btn v-if="!PreviewMode && UserisCreator" color="orange" dark @click="PreviewMode = !PreviewMode">Preview</v-btn>
                            <v-btn v-if="!PreviewMode && UserisCreator" color="blue" dark @click="$refs.ClassifiedImagesUpload.click()">Images<v-icon>mdi-image-multiple</v-icon></v-btn>
                            <input hidden v-if="!PreviewMode && UserisCreator" ref="ClassifiedImagesUpload" multiple type="file" accept="image/*" @change="UploadFiles($event, FilterType)">
                            <v-btn v-if="PreviewMode && UserisCreator" color="blue" dark  @click="PreviewMode = !PreviewMode">Edit</v-btn>
                            <v-btn v-if="PreviewMode && UserisCreator" color="green" dark  @click="SaveClassified()">Save</v-btn>
                        </v-flex>
                    </v-row>
                    <v-row class="justify-center" style="padding-top:25px;">
                      <v-flex lg4 md4 sm12 xs12  class="mx-1">
                          <v-row  
                            class="mx-2 fill-height"
                            align="center"
                            justify="center"
                          >
                            <v-carousel  v-if="PreviewMode || AttachmentsArray" v-model="model" height="400">
                              <v-carousel-item
                              v-for="picture in AttachmentsArray" :key="picture.itemObjKey"
                              >
                              <v-img
                                    :src="picture.ThumbURL"
                                    height="100%"
                                    :contain="PreviewMode"
                                  >
                                <v-btn class="mx-2 my-1" fab outlined @click="FeaturedPhoto = picture" :color="FeaturedPhoto.fileurl === picture.fileurl? 'orange' : 'white'"><v-icon >mdi-star</v-icon></v-btn>
                                </v-img>
                              </v-carousel-item>
                            </v-carousel>
                          </v-row>
                      </v-flex>

              
            <v-flex lg4 md4 sm12 xs12 class="mx-1">
 <v-row
                            class="mx-2 fill-height"
                            align="center"
                            justify="center"
                          >
            
             
            
<v-card color="#ffffff" class="transparent" tile height="100%" width="100%" flat>
    <!-- <v-container class="grey lighten-5"> -->
       
        <v-form ref="Enquiriesform" id="Enquiriesform">
                     <v-card flat :dark="AppisDarkMode" :class="AppisDarkModeCard" height="100%" tile>
                      <v-card-text style="align-self:center;">
                            <v-select  class="pop--text" :rules="[$store.state.formrules.required]" v-model="MainClass" :items="ComputedClassifiedsClasses" label="Main" return-object item-text="Name"></v-select>
                            
                            <v-select v-if="MainClass" class="pop--text" :rules="[$store.state.formrules.required]" v-model="SubClass" return-object :items="MainClass.Options" label="Sub" item-text="Name"></v-select>
                            <v-select v-if="SubClass" class="pop--text" :rules="[$store.state.formrules.required]" v-model="Category" return-object :items="SubClass.Options" label="Sub" item-text="Name"></v-select>
                            <div v-if="SubClass">
                              <!-- NewClassified {{NewClassified}} -->
                            <span  v-for="filter in SubClass.AppendedFilters" :key="filter.itemObjKey"><span v-if="filter.Field && !filter.Filters">
                               <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="SubClass" :field="filter.Field"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="''" :RelatedObj="RelatedObj"
                                  /></span><span v-if="filter.Filters"><span v-for="subfilter in filter.Filters" :key="subfilter.itemObjKey">
                                <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="SubClass" :field="subfilter"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="''" :RelatedObj="RelatedObj"
                                  /></span></span>
                              
                              <!-- <span v-if="filter.Field.Type === 'Option Set'">
                                            <v-select  :rules="[$store.state.formrules.required]" v-model="filter.Field.Value" :items="filter.Field.Options" :label="filter.Field.Name" item-text="Name"></v-select></span><span v-if="filter.Field.Type === 'Single Line Text'">
                                            <v-text-field  :rules="[$store.state.formrules.required]" v-model="filter.Field.Value" :label="filter.Field.Name"></v-text-field></span><span v-if="filter.Field.Type === 'Number Field'">
                                            <v-text-field  :rules="[$store.state.formrules.required]" v-model="filter.Field.Value" type="number" :label="filter.Field.Name"></v-text-field></span><span v-if="filter.Field.Type === 'Multiple Lines Text'">
                                            <v-textarea  :rules="[$store.state.formrules.required]" v-model="filter.Field.Value"  :label='filter.Field.Name'></v-textarea></span><span v-if="filter.Field.Type === 'Lookup'">
                                            <v-autocomplete  :rules="[$store.state.formrules.required]" v-model="filter.Field.Value"  :label='filter.Field.Name'></v-autocomplete></span><span v-if="filter.Field.Type === 'Date'">
                                            <v-menu 
                                        
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="200px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                :label="filter.Field.Name"
                                                prepend-icon="mdi-calendar-month"
                                                readonly  :rules="[$store.state.formrules.required]"
                                                v-on="on"
                                                v-model="filter.Field.Value"
                                                ></v-text-field>
                                                </template>
                                            <v-date-picker></v-date-picker>
                                            </v-menu></span><span v-if="filter.Field.Type === 'Single File Upload'">
                                            <input
                                    
                                        type="file"></span> -->
                                   </span></div>
                            <v-select  class="pop--text" :rules="[$store.state.formrules.required]" v-model="Condition" :items="['New','Used']" label="Condition" item-text="Name"></v-select>
                            <v-text-field  class="pop--text" :rules="[$store.state.formrules.required]" v-model="Region" label="Region"></v-text-field>
                            <v-menu 
                            v-model="Publish_Datemenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field  class="pop--text"
                                  label="Publish Date"
                                   v-model="PublishDate"
                                  prepend-icon="mdi-calendar-month"
                                  readonly  :rules="[$store.state.formrules.required]"
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker v-model="PublishDate" @input="Publish_Datemenu = false"></v-date-picker>
                            </v-menu>
                            
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <v-btn color="warning" dark>Cancel</v-btn> -->
                        <v-dialog width="800">
      <template v-slot:activator="{ on }">
    <v-btn v-on="on" dark color="pop">
      Enquiry
    </v-btn>
      </template>
    <v-card max-width="800" >
    <v-expand-transition>
        <div
            v-if="FormProcessing"
            :class="'d-flex transition-fast-in-fast-out '+ThemeColor+' v-card--reveal mediumoverline white--text'"
            style="height: 100%;"
        >
            <v-progress-circular size="130"
            :width="5"
            :color="ThemeHeaderColor"
            indeterminate
            >Processing</v-progress-circular>
        </div>
        </v-expand-transition>
    <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :RelatedEntity="'siteclassifieds'"
          :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"
          @ConfirmSubmission="ConfirmSubmission" :PageName="'Classified'" @ProcessingData="ProcessingData"
          :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="0"
          :tab="MappedClassifiedTab" :i="0"
          />
    </v-card>
    </v-dialog>
                      </v-card-actions>
                     </v-card>
                    </v-form>
</v-card>

 </v-row>
           
          
           
          
          </v-flex>
          <v-flex lg8 md10 sm12 xs12 class="mx-1">
                <v-card color="" class="transparent" tile  width="100%" flat >
                    <v-card-title class="largeoverline" v-if="PreviewMode">
                        {{Title}}
                    </v-card-title>
                    <v-card-title class="largeoverline" v-if="!PreviewMode">
                        <v-text-field v-model="Title" label="Title"/>
                    </v-card-title>
                    <v-card-subtitle class="mediumoverline">
                        Add to Wishlist
                    </v-card-subtitle>
                    <v-card-subtitle class="blue--text mediumoverline" v-if="PreviewMode">
                        {{Price}}
                    </v-card-subtitle>
                    <v-card-subtitle class="blue--text mediumoverline" v-if="!PreviewMode">
                         <v-text-field v-model.number="Price" label="Price"/>
                    </v-card-subtitle>
                    <v-card-text v-html="Description" :class="EditingInput" :contenteditable="!PreviewMode" id="DescriptionValue">
                        
                    </v-card-text>
                    
                </v-card>
          </v-flex>
  </v-row>
    </v-card>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NoticeboardFeed from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeboardFeed';
import GroupsFeed from '@/components/SuitePlugins/Groups/GroupsFeed';
import SiteBlogsFeed from '@/components/SuitePlugins/Blogs/SiteBlogsFeed';
import SiteEventsFeed from '@/components/SuitePlugins/Events/SiteEvents';
import SiteArticlesFeed from '@/components/SuitePlugins/Articles/SiteArticleFeed';
import SystemActivitiesFeed from '@/components/SocialNetwork/SystemActivitiesFeed';
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';
import SocialSharing from '@/components/SocialNetwork/SocialSharing'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';

export default {
    props: ['AppisDarkMode','IntranetView','AppisDarkModeCard','FriendsList'],
    components: {
      WebFormTab,
        NoticeboardFeed,
        GroupsFeed,
        SiteBlogsFeed,
        SiteArticlesFeed,
        SystemActivitiesFeed,
        SocialSitePosts,
        SiteEventsFeed,
        SocialSharing,
        FieldValueEditerComponent,
        },
    data() {
    return {
        FeaturePhotoSelect: false,
        PreviewMode: true,
        MainClass: '',
        SubClass: '',
        Condition: '',
        Region: '',
        Title: 'Some header for Classified',
        
        Price: 0,
        PublishDate: '',
        Publish_Datemenu: false,
        AttachmentsArray: [],
          selectedSharedDocumentsFileArray: [],
          SharedDocumentstotallist: 0,
          UploadingMultipleSharedDocuments: false,
          SharedDocumentstotallist: 0,
          SharedDocumentslistuploadcount: 0,
          SharedDocumentsuploadTotal: 0,
          SharedDocumentsrunningpercentage: 0,
          SharedDocumentsrunningProgress: 0,
          SharedDocumentsuploadPercentage: 0,
        mainclass: '',
      subclass: '',
      selectedtagtype: '',
        FeaturedMembers: [],
        ClassifiedData: '',
        Classifieds: [],
        ClassifiedsClasses: [
        ],
        mustreadnotices: [],
        noticestep: 0,
          
          
          
      ShareItemDialog: false,
      InternalLinkType: 'Classified',
      LinkComponent: 'SiteClassifiedsShared',      
      RelevantTopBannerAds: [],      
      UserRecord: '',
      FeaturedPhoto: '',
      Description: '',
      ClassifiedTab: {
        Name: 'Web Form',
        FormName: 'Classified Enquiry',
        FromDatabase: true,
        DarkForm: false,
        BGColor: {
              hexa: '#ffffff'},
        HeaderColor: {hex: '#000000'},
        EntityForm: true,
        RelatedEntity: {id: 'classifiedenquiries'},
        FormSubmitted:false,

        SingleName:'Classified Enquiry',
        FormFields: [
  {
    "Type": "Lookup",
    "Name": "Enquirying User",
    "OnWebForm": true,
    "RelatedBuildID": "Users",
    "UsedonForm": true,
    "Primary": false,
    "RelatedRecord": "Users",
    "LookupFieldName": "Full_Name",
    "DisplayName": "Enquirying User",
    "RelatedLocation": "User",
    "id": "Client",
    "IsHeader": true,
    "Created": false,
    StrictlyLoggedIn: true,
    "MappedField": {
      "Primary": false,
      "Name": "Enquirying User",
      LoggedInUser: true,
    }
  },
  {
    "Type": "Common Field",
    "CommonFieldType": "Email",
    "Name": "Email",
    "OnWebForm": true,
    "UsedonForm": true,
    "Primary": false,
    "DisplayName": "Email",
    "IsHeader": true,
    "Created": false,
    "MappedField": {
      "Primary": false,
      "Name": "Email",
      LoggedInUserValue: true,
    }
  },
  {
    "Type": "Common Field",
    "CommonFieldType": "Telephone Number",
    "Name": "Mobile_Number",
    "OnWebForm": true,
    "UsedonForm": true,
    "Primary": false,
    "DisplayName": "Mobile Number",
    "IsHeader": true,
    "Created": false,
    "MappedField": {
      "Primary": false,
      "Name": "Mobile_Number",
      LoggedInUserValue: true,
    }
  },

  {
    "DisplayName": "Classified",
    "RelatedBuildID": "Classifieds",
    "Type": "Lookup",
    "Created": false,
    "LookupFieldName": "Title",
    "OnWebForm": true,
    "Primary": false,
    "Name": "Classified",
    "RelatedRecord": "Classifieds",
    "UsedonForm": true,
    "RelatedLocation": "Classified",
    "IsHeader": true,
    "id": "Classified",
    "MappedField": {
      "Name": "Title",
      "Primary": true
    }
  },
  {
    "Type": "Multiple Lines Text",
    "IsHeader": true,
    "id": "Enquiry",
    "Name": "Enquiry",
    "DisplayName": "Enquiry",
    "OnWebForm": true,
    "Primary": false,
    "UsedonForm": true,
    "Created": false
  },
],
        StatusDefault: {
  "HasDeepHierarchy": true,
  "Level": {
    "id": 1,
    "FieldName": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "id": 2,
        "children": [],
        "name": "Status Reason"
      }
    ],
    "name": "Status"
  },
  "Name": "Active",
  "LevelFieldName": "Status",
  "ID": 1000001,
  "Options": [
    {
      "Level": {
        "id": 2,
        "children": [],
        "FieldName": "Status_Reason",
        "name": "Status Reason"
      },
      "Name": "Open",
      "LevelFieldName": "Status_Reason",
      "ID": 1000003
    }
  ]
},
        Status_ReasonDefault: {
  "ID": 1000003,
  "Name": "Open",
  "Level": {
    "id": 2,
    "children": [],
    "name": "Status Reason",
    "FieldName": "Status_Reason"
  },
  "LevelFieldName": "Status_Reason"
},
      },
      
      FormProcessing: false,
      AutomationCreatedSnackbar: false,
      AutomationSnackbarDetails: '',
      rules: {
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },

    }
    },
    watch: {
    mustreadnotices (value) {
      if (value.length>0) {
        this.MustReadNoticeDialog = true
      }
    },
    },
    computed:{
      RecordRef(){
        return this.$store.state.TemplateView ? this.RecordsColRef.doc(this.$route.params.slug) : this.NonGroupRoute ? 
        this.RecordsColRef.doc(this.$route.params.id) : this.RecordsColRef.doc(this.$route.params.slug)
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      RecordsColRef(){
        return this.$store.state.TemplateView ? this.TemplateRootPath.collection('sampleclassifieds') : this.NonGroupRoute ? this.ContentRootPath.collection('classifieds') : 
        this.ContentRootPath.collection('groupclassifieds')
      },      
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      ConfigRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('Libraries').doc(this.$store.state.ActiveSuiteid)
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      UsersArray(){
        return this.$store.state.UsersArray
      },
        MappedClassifiedTab(){
      this.ClassifiedTab.FormFields = this.ClassifiedTab.FormFields.map(field => {
        if(field.MappedField){
          let mappedfield = field.MappedField.Name
          //alert(mappedfield+'id')
          if(field.Type === 'Lookup'){
            field.Array = []
            if(!field.MappedField.Primary && !field.MappedField.LoggedInUser && this.userLoggedIn){
             field.Response = {
              [field.LookupFieldName]: this[mappedfield],
              id: this.ClassifiedData[mappedfield+'id']
            } 
            }
            else if(!field.MappedField.Primary && field.MappedField.LoggedInUser){
             field.Response = {
              Full_Name: this.userLoggedIn.Full_Name,
              id: this.userLoggedIn.id
            } 
            }
            else{
              field.Response = {
              [field.LookupFieldName]: this.ClassifiedData.Title,
              id: this.$route.params.id
              }               
            }
            
            field.Array.push(field.Response )
          }
          else if(field.MappedField && field.MappedField.LoggedInUserValue){
            field.Response = this.userLoggedIn[field.MappedField.Name]
          }
          else if(field.Type !== 'Date'){
            field.Response = this[mappedfield]
          }
          else{
            field.Response = this[mappedfield].toDate()
          }
        }
        return field
      }).filter(field => {  
        return this.userLoggedIn?  field : !field.StrictlyLoggedIn    
      })
      
      if(this.ClassifiedData.Created_By){
        this.ClassifiedTab.DefaultOwner = this.ClassifiedData.Created_By
        console.log(this.$store.state)
        let userobj = this.UsersArray.find(obj => obj.id === this.ClassifiedData.Created_By.id)
        this.ClassifiedTab.DefaultOwner.Email = userobj.Email
      }
      
      return this.ClassifiedTab
    },
        UserisCreator(){
            if(this.ClassifiedData){
                if(this.UserRecord.id === this.ClassifiedData.Created_Byid){
                    return true
                }
                else{
                    return false
                }
            }
            else{
                return true
            }
        },
        NewClassified(){
          let mainclass = {ID: this.MainClass.ID,Name: this.MainClass.Name}
          let subclass = {ID: this.SubClass.ID,Name: this.SubClass.Name}
          let catclass = ''
            let obj = {
                Title: this.Title,
                Description: this.Description,
                MainClass: mainclass,
                SubClass: subclass,
                Region: this.Region,
                AttachmentsArray: this.AttachmentsArray,
                Price: this.Price,
                Condition: this.Condition,
                Path: '/Classified/'+this.$route.params.id,
                Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
                Created_Byid: this.UserRecord.id,
                
            }
            if(this.Category){              
              obj.Category = {ID: this.Category.ID,Name: this.Category.Name}
            }
            if(this.PublishDate){
                obj.PublishDate = this.DateFormatter(this.PublishDate)
            }
            if(this.SubClass.AppendedFilters){
              this.SubClass.AppendedFilters.map(filter => {
                if(!filter.Filters && filter.Field){
                  obj[filter.Field.Name] = this.SubClass[filter.Field.Name] 
                }
                else if(filter.Filters){
                  filter.Filters.map(subfilter => {
                    obj[subfilter.Name] = this.SubClass[subfilter.Name] 
                  })
                }
              })
            }
            // if(this.SubClass.Tags){
            //   this.SubClass.Tags.map(tag => {
            //     obj[tag.Name] = tag.Value
            //   })
            // }
            if(this.FeaturedPhoto){
                obj.FeaturedPhoto = this.FeaturedPhoto
                obj.IMG = this.FeaturedPhoto.ThumbURL
            }
            else{
               if(this.AttachmentsArray.length>0){
                this.FeaturedPhoto = this.AttachmentsArray[0]
                obj.IMG = this.FeaturedPhoto.ThumbURL
               }
            }
            return obj
        },
        ClassifiedValidates(){
            if(this.Title && this.Description && this.Price && this.MainClass && this.SubClass && this.Condition && this.Region && this.PublishDate && this.AttachmentsArray.length>0){
                return true
            }
            else if(!this.Title){
                return 'No Title'
            }
            else if(!this.Description){
                console.log(this.Description)
                return 'No Description'
            }
            else if(!this.Price){
                return 'No Price'
            }
            else if(!this.MainClass){
                return 'No MainClass'
            }
            else if(!this.SubClass){
                return 'No SubClass'
            }
            else if(!this.Condition){
                return 'No Condition'
            }
            else if(!this.Region){
                return 'No Region'
            }
            else if(!this.PublishDate){
                return 'No Publish Date'
            }
            else if(this.AttachmentsArray.length === 0){
                return 'No AttachmentsArray'
            }
        },
        // Description(){
        // let postelmnt = document.getElementById('DescriptionValue')
  
        //     if(postelmnt !== null){
        //         return postelmnt.innerHTML
        //     }
        // },
        EditingInput(){
            if(this.PreviewMode){
                return ''
            }
            else{
                return 'postinputbox'
            }
        },
        ComputedClassifiedsClasses(){
            return this.ClassifiedsClasses.slice(0,2)
        },
        FeaturedMemberClasses(){
            let tags = this.ClassifiedsbyClasses.map(ad => {
                return ad.tags
            }).flat()
             let uniq = [...new Set(tags)];
            return uniq
        },
        ClassifiedsbyClasses(){
            let Members =  this.FeaturedMembers.map(record => {
                let obj = {
                    title: record.Full_Name,
                    IMG: record.profilephoto,
                    FavoriteQuote: record.FavoriteQuote,
                    tags: record.tags,
                    groupid: record.groupid,
                    grouptype: record.grouptype,
                    id: record.id
                }
                return obj
            })
            if(this.mainclass && this.mainclass.ID === 1000003){
                if(this.subclass && this.subclass.ID === 1000001){
                    return Members.filter(member => {
                        return member.grouptype === 'Staff'
                    }).filter(obj => {
                if(typeof this.selectedtagtype !== 'undefined'){
                let tagtypematch = obj.tags.flat()
                    console.log(tagtypematch,this.selectedtagtype)
                    if(this.contains(tagtypematch, this.selectedtagtype)){
                    return obj
                    }
                }
                else{
                return obj
                }
            })
                }
                else if(this.subclass && this.subclass.ID === 1000002){
                    return Members.filter(member => {
                        return member.grouptype === 'Quality'
                    }).filter(obj => {
                if(typeof this.selectedtagtype !== 'undefined'){
                let tagtypematch = obj.tags.flat()
                    console.log(tagtypematch,this.selectedtagtype)
                    if(this.contains(tagtypematch, this.selectedtagtype)){
                    return obj
                    }
                }
                else{
                return obj
                }
            })
                }
            }
            else{
                return []
            }
        },
      currentNoticeTitle () {
        if(this.mustreadnotices.length > 0 && this.noticestep){
          console.log(this.mustreadnotices)
          return this.mustreadnotices[this.noticestep].title
        }
        
      },
      
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      SiteBlogsFilter(){
        return        
      },
      
      
      
      SiteArticlesFilter(){
        return        
      },
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.CheckUser()
      if(!this.$store.state.TemplateView){
        
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      }    
    },
    
    methods: {
      // UpdateEditableField(){
      //   if(this.SubClass.AppendedFilters){
      //     this.SubClass.AppendedFilters.map(filter => {
      //       if(!filter.Filters){
      //         obj[tag.Name] = this.SubClass[filter.Name] 
      //       }
      //     })
      //   }
      // },
      ProcessingData(boolean){
            this.FormProcessing = boolean
        },
      ConfirmSubmission(tab,obj,docid){
            console.log(tab,obj,docid)
            tab.FormSubmitted = true
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record <a :href="'/`+tab.RelatedEntity.SingleName+`/'+`+docid+`" target="_blank">here</a>`
        },

      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
        SaveClassified(){
           let postelmnt = document.getElementById('DescriptionValue')
            this.Description = postelmnt.innerHTML
            if(this.ClassifiedValidates === true){
              this.$store.dispatch('CreateSearchTitle',this.NewClassified.Title).then(searchtitle => {
              this.NewClassified.TitleQuery = searchtitle
                  if(this.userLoggedIn.Company){
                    this.NewClassified.Company = this.userLoggedIn.Company
                    this.NewClassified.Companyid = this.userLoggedIn.Companyid
                  }
                this.RecordRef.set(this.NewClassified).then(doc => {
                    alert('great, added it')
                })
              })
            }
            else{
                alert(this.ClassifiedValidates)
            }
        },
        UploadFiles(event,Type){
          let vm = this
          this.selectedSharedDocumentsFileArray = event.target.files || event.dataTransfer.files
          this.UploadingMultipleSharedDocuments = true
          
          setTimeout(() => {
          this.SharedDocumentstotallist = this.selectedSharedDocumentsFileArray.length
          this.SharedDocumentsuploadTotal = this.selectedSharedDocumentsFileArray.length*100
          const topelmnt = document.getElementById('shareddocumentsprogressbar')
          topelmnt.style.display = "block";
          const elmnt = document.getElementById('shareddocumentsprogressgroupbar')      
          const totalprogresselementptag = document.createElement('p')
          totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
          totalprogresselementptag.setAttribute('id','shareddocumentstotalprogressbarptag')
          const totalprogresselement = document.createElement('progress')
          totalprogresselement.setAttribute('id','shareddocumentstotalprogressbar')
          totalprogresselement.setAttribute('max','100')
          totalprogresselement.setAttribute('value',1)
          var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.SharedDocumentstotallist+' files uploaded');
          totalprogresselementptag.appendChild(startingtotaltext);
          elmnt.append(totalprogresselement) 
          elmnt.append(totalprogresselementptag)
          //console.log(this.selectedSharedDocumentsFileArray)
          Array.from(Array(this.selectedSharedDocumentsFileArray.length).keys()).map((x,index) => {  
           // console.log(vm.selectedSharedDocumentsFileArray[x])
             vm.PreparePostThumbnails(vm.selectedSharedDocumentsFileArray[x],index,vm.selectedSharedDocumentsFileArray.length)
            
          })
          },300)
        },
    PreparePostThumbnails(file,index){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        let StorageRef = this.ActiveSuiteTemplate ? 'UserAssets/SuiteTemplate/'+this.$route.params.id+'/Classifieds/'+new Date()+'/'+filename : 
        this.ActiveMarketTemplate ? 'UserAssets/MarketplaceTemplate/'+this.$route.params.id+'/Classifieds/'+new Date()+'/'+filename : 'Classifieds/'+this.$route.params.id+'/'+new Date()+'/'+filename
        file.tmpsrc = URL.createObjectURL(file)
              //this.PostImagesforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            //console.log(blobfile)
            
            var storageRef = firebase.storage().ref(StorageRef+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                     // console.log(thumburl)
                      vm.onDocument_URLUpload(file,index,thumburl,StorageRef)
                    })
              })
      }
      },

    onDocument_URLUpload(uploadfile,index,thumburl,StorageRef) {
        let filename = uploadfile.name.split('.')[0]+'_'+uploadfile.size+'.'+uploadfile.name.split('.')[1]
        let NewFile = {}
        const totalprogresselement = document.getElementById('shareddocumentstotalprogressbar')
        const totalprogresselementptag = document.getElementById('shareddocumentstotalprogressbarptag')
        const elmnt = document.getElementById('shareddocumentsprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)        
        var storageRef = firebase.storage().ref(StorageRef);
        var uploadTask = storageRef.put(uploadfile);
        let vm = this
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.SharedDocumentsuploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.SharedDocumentsuploadPercentage)
                var textnode = document.createTextNode(vm.SharedDocumentsuploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.SharedDocumentsuploadPercentage === 100){
                  vm.SharedDocumentsrunningProgress = vm.SharedDocumentsrunningProgress+vm.SharedDocumentsuploadPercentage
                  vm.SharedDocumentsrunningpercentage = Number(((vm.SharedDocumentsrunningProgress/vm.SharedDocumentsuploadTotal)*100).toFixed(2))                  
                  vm.SharedDocumentslistuploadcount = vm.SharedDocumentslistuploadcount-1+2
                  var totaltext = document.createTextNode(vm.SharedDocumentsrunningpercentage+'% complete  - '+vm.SharedDocumentslistuploadcount+' of '+vm.SharedDocumentstotallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.SharedDocumentsrunningpercentage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                 // console.log('File available at', downloadURL);
                });
              });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            // db.collection('companydocuments').doc(doc.id).update({
            //         filename: filename,
            //         Document_URL: url,
            //         Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            //         Modified_Byid: this.UserRecord.id,
            //         Modified_On: new Date(),
            //         DocumentID: doc.id,
            // })
            NewFile.Created_By = {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
            NewFile.Created_Byid = this.UserRecord.id,
            NewFile.Path = url
            NewFile.FileType = uploadfile.type
            NewFile.fileurl = url
            NewFile.ThumbURL = thumburl
            NewFile.StorageRef = StorageRef
            NewFile.Size = uploadfile.size
            NewFile.Name = filename
            this.AttachmentsArray.push(NewFile)
            if(this.SharedDocumentsrunningpercentage === 100){
              //console.log(this.AttachmentsArray)
              //  if(vm.AttachmentsArray.length === listlength){
              //     vm.CreatePost()
              //   }
              // db.collection('notices').doc(this.$route.params.id).
                      this.UploadingMultipleSharedDocuments = false
                      this.SharedDocumentstotallist = 0
                      this.SharedDocumentsuploadTotal = 0
                      this.SharedDocumentsrunningpercentage = 0
                      this.SharedDocumentsrunningProgress = 0
                      this.SharedDocumentsuploadPercentage = 0
                      this.SharedDocumentslistuploadcount = 0
                      elmnt.style.display = "none";
                      }
           
                }),
                  this.Document_URLfilesnackbar = true



      },
        ActivateTagTypeFilter(tag){
            this.selectedtagtype = tag
        },
        contains(a, obj) {
            for (var i = 0; i < a.length; i++) {
                if (a[i].includes(obj)) {
                    return true;
                }
            }
            return false;
        },
        FilterClassifieds(mainclass,child){
            console.log(child)
            this.mainclass = mainclass
            this.subclass = child
            if(mainclass.ID === 1000003){
                console.log(this.mainclass,this.subclass)

            }
        },
      
      GetMustReadNotices() {
           this.ContentRootPath.collection('notices').where('MustRead','==',true).onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                this.ContentRootPath.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                  })
                  
                
              }  
              

            })
          })

          },
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
             let vm = this
            if(this.userLoggedIn){
              vm.GetClassFiedNavList(this.ConfigRootPath.collection('Directories').doc('Classifieds'))
                vm.GetClassifiedListing()
              vm.UserRecord = this.userLoggedIn
              if(!this.$store.state.TemplateView){
              vm.GetMustReadNotices()
              let RouteBannerAdsRef =  db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)           
              vm.GetBannerAds(RouteBannerAdsRef)
              console.log(vm.UserRecord)
              }
                }
                else{
                  vm.$router.push('/Login')
                  let PublicRouteBannerAdsRef =  db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
                 
                  vm.GetBannerAds(PublicRouteBannerAdsRef)
                }
        },
        GetClassFiedNavList(dbref){
          dbref.onSnapshot(snapshot => {
            let data = snapshot.data()
            if(data){
              this.ClassifiedsClasses = data.ListPrimaryField.Options
              //console.log('this.ClassifiedsClasses',this.ClassifiedsClasses)
            }
          })
        },
        GetClassifiedListing(){
            
            this.RecordRef.onSnapshot(snapshot => {
            let classifieddata = snapshot.data()
                if(classifieddata){
                  if(classifieddata.FeaturedPhoto){
                    this.FeaturedPhoto = classifieddata.FeaturedPhoto
                  }
                  if(classifieddata.PublishDate){
                    let pubdate = classifieddata.PublishDate.toDate()
                    this.PublishDate = format(pubdate,'yyyy-MM-dd')
                  }
                    this.Title = classifieddata.Title
                     this.$emit('PushRecordName',classifieddata.Title)
                    this.AttachmentsArray = classifieddata.AttachmentsArray
                    this.Region = classifieddata.Region
                    this.MainClass = classifieddata.MainClass
                    this.SubClass = classifieddata.SubClass
                    this.Price = classifieddata.Price
                    this.Condition = classifieddata.Condition
                    this.ClassifiedData = classifieddata
                    this.ClassifiedData.id = this.$route.params.id
                    //console.log('this.ClassifiedsClasses',this.ClassifiedsClasses)
                    if(this.ClassifiedData.MainClass){
                      let mainclassmatch = this.ClassifiedsClasses.find(obj => obj.ID === this.ClassifiedData.MainClass.ID)
                      
                      if(mainclassmatch){
                        this.MainClass = mainclassmatch
                        this.ClassifiedData.MainClass.Options = mainclassmatch.Options
                      }
                      if(this.ClassifiedData.SubClass){
                        let subclassmatch = mainclassmatch.Options.find(obj => obj.ID === this.ClassifiedData.SubClass.ID)
                        if(subclassmatch){
                          this.SubClass = subclassmatch
                          // this.Category =subclassmatch
                          this.ClassifiedData.SubClass.Options = subclassmatch.Options
                          if(subclassmatch.Options && subclassmatch.Options.length >0){
                            if(this.ClassifiedData.Category){
                              let catclassmatch = subclassmatch.Options.find(obj => obj.ID === this.ClassifiedData.Category.ID)
                              if(catclassmatch){
                                this.Category = catclassmatch
                              }
                            }
                          }
                          if(subclassmatch.AppendedFilters){
                            this.ClassifiedData.SubClass.AppendedFilters = subclassmatch.AppendedFilters
                            //console.log(this.ClassifiedData.SubClass.AppendedFilters)
                            this.ClassifiedData.SubClass.AppendedFilters.map(tagopt => {
                              if(tagopt.Filters){
                                tagopt.Filters.map(subfilt => {
                                    if(typeof this.ClassifiedData[subfilt.Name] !== 'undefined'){
                                      this.ClassifiedData.SubClass[subfilt.Name] = this.ClassifiedData[subfilt.Name]
                                    } 
                                })
                              }
                              else{
                                if(typeof this.ClassifiedData[tagopt.Field.Name] !== 'undefined'){
                                  this.ClassifiedData.SubClass[tagopt.Field.Name] = this.ClassifiedData[tagopt.Field.Name]
                                } 
                              }
                            })
                          }
                        }
                      }
                    }
                    console.log(this.ClassifiedData,this.UserRecord)
                    
                    let postelmnt = document.getElementById('DescriptionValue')
                    if(postelmnt){
                        postelmnt.innerHTML = classifieddata.Description
                    }
                }
                
            })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}

</style>
  
            
        


    