
<template>
  <v-card color="rgba(255,255,255,0.01)" flat>
    <!-- <v-btn @click="SendEmail()">Test Email</v-btn>
  <v-dialog width="800px" v-model="previewsendemaildialog"> -->
      <v-card flat width="100%" height="100%">
        <v-card-title class="caption">Email Preview - <h3 class="caption pop--text"> Validate The Email before sending!</h3></v-card-title>
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item>
                      <v-list-item-content>               
                      <strong>From:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox dense class="caption" v-model="EmailFrom" label="From"  :rules="[$store.state.formrules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>               
                   <v-list-item>
                     <v-list-item-avatar tile>               
                      <v-btn small @click="ToggleSelectDialog('To')" color="pop" dark>To:</v-btn>
                      </v-list-item-avatar>
                      <v-list-item-content>               
                      <v-combobox dense class="caption" v-model="EmailTo" multiple  label="To"  :rules="[$store.state.formrules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content>              
                   </v-list-item>
                   <v-list-item>
                      <v-list-item-avatar tile>               
                      <v-btn small @click="ToggleSelectDialog('CC')" color="pop" dark>CC:</v-btn>
                      </v-list-item-avatar>
                      <v-list-item-content>               
                      <v-combobox dense class="caption" v-model="EmailCC" multiple  label="CC" autocomplete="off"></v-combobox>  
                      </v-list-item-content>                                
                   </v-list-item>
                   <v-list-item>
                     <v-list-item-avatar tile>               
                      <v-btn small @click="ToggleSelectDialog('BCC')" color="pop" dark>BCC:</v-btn>
                      </v-list-item-avatar>
                      <v-list-item-content>             
                      <v-combobox dense class="caption" v-model="EmailBCC" multiple  label="BCC" autocomplete="off"></v-combobox>
                      </v-list-item-content>
                      </v-list-item>
                   <v-list-item>
                      <v-list-item-content>             
                      <v-text-field dense class="caption" v-model="EmailSubject" label="Subject" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list>
                   </v-layout>
                   <v-card flat>
                      <v-card-title class="overline">
                        Type/Verify the Email message below
                      </v-card-title>
                  <v-layout class="justify-center">
                    <v-card width="90%" min-height="100%" outlined flat>
                      <div contenteditable id="previewsendemail" class="postinputbox caption" v-html="PresetEmailBody"/>
                      </v-card>
                  </v-layout>
                </v-card>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSendEmail()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="ProcessEmail()">Send <v-icon>mdi-email-send-outline</v-icon></v-btn>
                </v-layout>
        </v-card>
     <!--</v-dialog> -->
      <v-dialog width="80%" v-model="MailSelectDialog">
      <v-card flat width="100%" height="100%">
        <v-card-title class="headline primary white--text">Email Preview - <h3 class="headline primary pop--text"> Validate The Email before sending!</h3></v-card-title>
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item class='basicoutlined'>
                      <v-list-item-content>               
                      <strong>Record:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="MailRecordFilter" @change="CheckifUser()" label="Record" autocomplete="off" :items="['Accounts','Users','Contacts']" item-text="companyemail"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item class='basicoutlined' v-if="MailRecordFilter !== 'Users'">
                      <v-list-item-content>               
                      <strong>Field:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="MailFieldFilter" label="Email Field" autocomplete="off" :items="MailRecordFilterEmailFields" item-text="Name"></v-combobox>  
                      </v-list-item-content>              
                   </v-list-item>              
                   
                  </v-list>
                   </v-layout>
                  <v-layout class="justify-center">
                  <v-card width ="80%" outlined flat>
                    <v-data-table
                      :headers="RelatedFilterHeaders"
                      :items="RelatedFilterRecords"
                      class="elevation-1"
                    >
                      <template v-slot:top>
                    <v-toolbar flat color="white">
                      <v-toolbar-title>{{MailRecordFilter}} records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-checkbox light
                        v-model="relatedsearches"
                        label="Prefilter to Related"
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-text-field light
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>
                                     
                    </v-card>
                </v-layout>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSelection()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="AddSelection()">OK <v-icon>mdi-check</v-icon></v-btn>
                </v-layout>
        </v-card>
      </v-dialog>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['PresetEmailBody','PresetEmailFrom','PresetEmailTo','PresetEmailCC','PresetEmailBCC','PresetEmailSubject'],
    components: {
},
  data() {
    return {
      rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
        search: '',
        relatedsearches: true,
      ArrayFilter: '',
      MailFieldFilter: '',
      MailRecordFilter: '',
      MailSelectDialog: false,  
      previewsendemaildialog: true,
      EmailFrom: '',
      EmailTo: '',
      EmailCC: '',
      EmailBCC: '',
      EmailSubject: '',      
      UsersArray:[],
      UserHeaders:[
        { text: 'Selected', value: 'selected',sortable: false},
        { text: 'Title', value: 'Title'},
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Gender', value: 'Gender'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
    }
  },

  created(){
    // this.toggledialog = this.initialpreviewsendemaildialog
    this.EmailFrom = this.PresetEmailFrom
    this.EmailTo = this.PresetEmailTo
    this.EmailCC = this.PresetEmailCC
    this.EmailBCC = this.PresetEmailBCC
    this.EmailSubject = this.PresetEmailSubject
    this.EmailBody = this.PresetEmailBody
    this.GetUsers()
  },
  computed: {
    RelatedFilterHeaders(){
      if(this.MailRecordFilter === 'Users'){
        return this.UserHeaders
      }
    },
    MailRecordFilterEmailFields(){
      if(this.MailRecordFilter === 'Users'){
        return ['Email']
      }
      if(this.MailRecordFilter === 'Contacts'){
        return ['emailaddress']
      }
    },
    RelatedMailingList(){
      if(this.ArrayFilter === 'To'){
        return this.EmailTo
      }
      if(this.ArrayFilter === 'CC'){
        return this.EmailCC
      }
      if(this.ArrayFilter === 'BCC'){
          return this.EmailBCC
      }      
    },
    RelatedRecordfilterArray(){
      if(this.MailRecordFilter === 'Users'){
        return this.UsersArray
      }
    },
    RelatedRecordPrimaryField(){
      if(this.MailRecordFilter === 'Users'){
        return 'Full_Name'
      }
    },
    RelatedFilterRecords(){
      if(this.MailRecordFilter && this.ArrayFilter){
        return this.RelatedRecordfilterArray.filter(record => {
          return record[this.RelatedRecordPrimaryField].toLowerCase().includes(this.search.toLowerCase()
          )}).map(record => {
            let ToCheck = this.RelatedMailingList.find(to => to == record[this.MailFieldFilter])
            if(typeof ToCheck !== 'undefined'){
              record.selected = true
            }
            else{
              record.selected = false
            }
            return record            
          })
    }
     else{
      return []
    }
         
    },
    
    AccountswithCompanyEmail(){
      return this.AccountsArray.filter(account => {
        return account.companyemail
      })
    },
    
  },
  methods: {
    CheckifUser(){
      this.MailFieldFilter = ''
      if(this.MailRecordFilter === 'Users'){
        this.MailFieldFilter = 'Email'
      }
    },

    GetUsers(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    AddSelection(){
      if(this.ArrayFilter === 'To'){
      let ToPush = this.RelatedFilterRecords.filter(record => {return record.selected}).map(record => {return record[this.MailFieldFilter]})
      this.EmailTo = ToPush
      }
      if(this.ArrayFilter === 'CC'){
        let ToPush = this.RelatedFilterRecords.filter(record => {return record.selected}).map(record => {return record[this.MailFieldFilter]})
      this.EmailCC = ToPush
      }
      if(this.ArrayFilter === 'BCC'){
        let ToPush = this.RelatedFilterRecords.filter(record => {return record.selected}).map(record => {return record[this.MailFieldFilter]})
      this.EmailBCC = ToPush
      }
      this.CancelSelection()
    },
    CancelSelection(){
      this.MailSelectDialog = false
      this.ArrayFilter = ''
      this.MailRecordFilter = ''
      this.MailFieldFilter = ''
    },
    
    ToggleSelectDialog(ArrayFilter){
      this.ArrayFilter = ArrayFilter
      this.MailSelectDialog = true
    },
    CancelSendEmail(){
            this.previewsendemaildialog = false
          },
      ProcessEmail(){
        this.EmailBody = document.getElementById('previewsendemail').innerHTML

        let EmailObject = {          
          EmailFrom: this.EmailFrom,
          EmailTo: this.EmailTo,
          EmailCC: this.EmailCC,
          EmailBCC: this.EmailBCC,
          EmailSubject: this.EmailSubject,
          EmailBody: this.EmailBody,

        }
            alert('email sending')
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
            })
          },
  }
}
</script>

<style>

</style>

    