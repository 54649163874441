
<template>
      <v-card  :style="tab ? BoxStyle(tab) : UserView ? 'margin-top:50px;' : ''" :class="tab ? tab.BoxTransparency+' '+tab.CustomClass+' mx-3 footermargin' : 'transparent footermargin'" :color="tab && tab.BGColor ? tab.BGColor.hexa : ''"   
             :elevation="tab ? tab.Elevation : 0" :tile="tab ? tab.Tile : true" :outlined="tab ? tab.Outlined : false" :shaped="tab ? tab.Shaped : false"
             :flat="!tab">
         <v-btn outlined v-if="$store.state.TemplateOwner"
         class="green--text" @click="Activatedialog()" fab small absolute style="margin-right:40px;" right><v-icon>mdi-plus</v-icon>
        </v-btn>  
        <v-btn class="red--text" outlined @click="OpenAboutDialog('AboutGroupsDialog')" fab small absolute  right><v-icon>mdi-help</v-icon>
        </v-btn>
        <!-- Option1 {{Option1}}
        Option2 {{Option2}}
        Option3 {{Option3}} -->
        {{Description}}
      
    
     <v-dialog persistent v-model="dialog"	max-width="400"		
            :close-on-content-click="false"                            									
            transition="scale-transition"                            									
            >		
            <v-card v-if="$store.state.TemplateView" tile>
              <v-card-title class="black white--text">
                  New Sample Group
              </v-card-title>
              <v-card-text>
                <v-form  ref="form">
                 <v-text-field :rules="[$store.state.formrules.required]" v-model="editedItem.name" label="Name"></v-text-field>
                   <v-textarea :rules="[$store.state.formrules.required]" v-model="editedItem.Description" label="Description"></v-textarea>
                   <!-- these are samples only, we NOT going down this road. title, description and image only
                    <v-select :items="GroupCategoryTypes" :rules="[$store.state.formrules.required]"
                    label="Group Category" v-model="editedItem.GroupCategory"
                    item-text="Name"
                    return-object
                    />  -->
                     <v-img class="black" height="200" contain v-if="!SampleCoverIMG && editedItem.logo"									
                        :src="editedItem.logo"									
                        >
                        <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                     
                            <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleGrouplogo')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </v-row>
                  </v-img>	
                   <v-img height="200" contain v-if="!editedItem.logo"									
                    src="@/assets/ImageHolder.png"									
                   >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleGrouplogo')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                    </v-row>
               </v-img>	
                </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-btn dark @click="Closedialog()" outlined color="warning">Cancel</v-btn>
              <v-spacer>
              </v-spacer>
              <v-btn @click="save()" dark outlined color="success">Save</v-btn>
              </v-card-actions>
            </v-card>  	
     </v-dialog>
     
      <v-card  :class="tab ? tab.BoxTransparency+' '+tab.CustomClass+'' : 'transparent'" :color="tab && tab.BGColor ? tab.BGColor.hexa : ''" :flat="!tab"
       :elevation="tab ? tab.Elevation : 0"  :tile="tab ? tab.Tile : true"  :outlined="tab ? tab.Outlined : false" :shaped="tab ? tab.Shaped : false"
            :id="tab ? tab.ElementID+'_Tab' : ''"
            :height="tab && tab.Name === 'Card Row Single' && tab.InfographicBox ? '100%' : ''" v-if="Option1"
             :style="tab && tab.AnimationCSS && tab.AnimationCSS.Tab ? 
             BoxStyle(tab)+tab.AnimationCSS.Tab : tab ?
             BoxStyle(tab) : ''">
            <v-row :class="tab ? tab.Justify : 'justify-center'">
            <v-hover v-slot:default="{ hover }" v-for="group in filteredGroups.slice(0,Excerpt)"
                  :key="group.id">
                  <!-- SingleRoutePrefix replaced  @click="$store.state.TemplateView ? ToggleEdit(group,'AboutGroupsDialog') : ''" 
                  AboutGroupsDialog moving to group single-->
                  <v-card :width="MiniView ? '120px' : '150px'" :height="MiniView ? '120px' : '150px'" flat
                  :to="SingleRoutePrefix+group.id" :tile="tab && tab.FeedCardTiled ? tab.FeedCardTiled : true"
                    class="transparent mx-1 my-1"
                  >
                  <!--  gradient="to top right, rgba(255,255,255,0.85), rgba(255,255,255,0.85)" -->
                  <v-img  :height="MiniView ? '120px' : '150px'"
                 
                  :src="group.logo ? group.logo : require('@/assets/logo.png')">
                <v-card-title class="mediumoverline white--text" :style="tab && tab.FeedBGColor ? 'background-color: '+tab.FeedBGColor.hexa+';' : 'background-color: black;'">                  
                          <span class="subheading text-left" style="font-size:16px">{{ group.name }}</span>
                      </v-card-title>
                  </v-img>
                   <v-expand-transition>
                    <v-card 
                    v-if="hover"
                        class="transition-fast-in-fast-out white--text"
                        :style="tab && tab.OverlayColor?'height: 100%;background-color: '+tab.OverlayColor.hexa+';position: absolute;top:0px;' :
                        'height: 100%;background-color: rgba(40,50,60,0.7);position: absolute;top:0px;'"
                    >
                    <div style="padding:1px;">
                     <v-avatar size="30" class="blue" >
                            <img 
                             :src="group.logo ? group.logo : require('@/assets/BlankProfilePic.png')"
                            style="object-fit: cover;"          
                            >                
                            </v-avatar> {{group.name}}
                    </div>
                        <v-card-text class="white--text">
                        <div :id="group.id+'smartdescription'">
                                <span  v-html="group.Description ? smart_substr(group.Description,60) : ''">
                                </span>
                            </div>  
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
                  </v-card>
            </v-hover>
           </v-row>
            </v-card>
      <v-row
        class="fill-height ma-0"
        align="center"
        v-if="Option2">
        <v-flex v-for="group in filteredGroups.slice(0,Excerpt)"
          :key="group.id">
      <v-card
             :style="{backgroundImage: GroupsGradient}"
            :to="'/Group/' + group.id"
            outlined            
            elevation="2"
            class="my-1 mx-1"
          >
           
          <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="subheader white--text"
                  v-text="group.name"
                ></v-card-title>

                <v-card-subtitle class="white--text" v-text="group.Description"></v-card-subtitle>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-img :src="group.logo"></v-img>
              </v-avatar>
            </div>    
                
          </v-card>
        </v-flex>
      </v-row>
      <span
        v-if="Option3"
      >
      <v-card
          v-for="group in filteredGroups.slice(0,Excerpt)"
          :key="group.id"
            :to="'/Group/' + group.id"
            outlined            
            elevation="2"
            class="my-1 mx-1"
          >
         
          <v-list-item three-line>
              <v-list-item-content class="align-self-start">
                <v-container grid-list-xl>
                <v-layout row wrap>
                  
                    <v-flex xs12 s12 md3 lg2>
                  <v-layout column> 
                  <v-avatar tile><v-img :src="group.logo"></v-img></v-avatar></v-layout>

                </v-flex>
                           
                   <v-layout row>
                                 <h2> <v-list-item-title class="primary--text mx-3"
                  v-text="group.name" 
                ></v-list-item-title></h2>
                   </v-layout>
                 <v-layout column>
                   <v-flex xs12 s12 md10 lg8>
                  <h6 id="Grouptitle" class="mx-3"> {{group.GroupCategory}} <v-icon v-if="group.EndorsingNetwork" light color="white" medium>cloud_done</v-icon></h6></v-flex>
                    </v-layout>
                    <v-layout column>                 
                     <h5 class="mx-3">Group has a Privacy of {{group.GroupPrivacy}} <v-icon>{{group.GroupPrivacyIcon}}</v-icon></h5>
                    </v-layout> 
                                         <v-layout row wrap>
               </v-layout>            
                </v-layout><v-divider></v-divider><br>
                <v-layout row wrap>
                <v-list-item-action-text v-text="group.Description"></v-list-item-action-text></v-layout>
                </v-container>   
              </v-list-item-content>  
            </v-list-item>
       
           </v-card>
      </span>
      </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['clientDB','Description','Excerpt','Filter','View','UserView','UserID','UsersArray','AppisDarkMode','Editor','tab'],
    middleware: 'auth',
    data () {
        return {      
          Option1: false,
          Option2: false,
          Option3: false,
          
          tempid: 'new',
          //userid: this.$store.state.user.id,
        GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                logo: null,
                                
            },
            UserRecord: {},     

        }
    },
    computed: {
      SingleRoutePrefix(){
        //$route.name !== 'GroupSingle' ? '/Forum/'+forum.id : '/Group-Forum/'+$route.params.id+'/Discussion/'+forum.id
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Group/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Group/'
        }
        else{
          return '/Group/'
        }
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id)
        : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      System(){
        return this.$store.state.SystemObj
      },
      GroupsGradient(){
      return this.$vuetify.theme.dark ? 'linear-gradient(338deg, '+this.System.Light_Social_App_SearchBar.hex+'EE 0%, '+this.System.Dark_Social_App_SearchBar.hex+'DD  100%)': 'linear-gradient(338deg, '+this.System.Dark_Social_App_SearchBar.hex+'DD 0%, '+this.System.Light_Social_App_SearchBar.hex+'DD  100%)' 
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
    userLoggedIn () {
        return this.$store.getters.user
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    filteredGroups() {
      
      return this.groups.filter(group => {
          //return this.FilterComputed.length > 0 ? this.FilterComputed.includes(group.GroupCategory.ID) : group
          return group
      });
    
    },
    FilterComputed(){
        return this.Filter.map(filter => {
            return filter.ID
        })
    }
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleGrouplogo']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {
      //console.log('this.ActiveMarketTemplate',this.ActiveMarketTemplate)
      if(this.$store.state.TemplateView){
        
          if(this.UserView && this.UserID){
            this.Option2 = true
          this.GetSamples(this.ConfigPath.collection('samplegroups').where('Ownerid','==',this.UserID),'groups')
          }
          else{
            this.Option1 = true
            this.GetSamples(this.ConfigPath.collection('samplegroups'),'groups')
          }
      }
      else{
        //console.log(this.Filter)
      setTimeout(() => {
        this[this.View] = true
        //console.log(this.View,this[this.View])
      }, 300);
      if(this.UserView){
        //console.log('is user view',this.UserID)
        // this.CollectionRef = this.CollectionRef.where('groupmembers','array-contains',this.UserID)
        // this.getGroups()
      }
      else{
        
        // this.getGroups()
      }
   
    this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.GetRequestingUser()
      }
      //console.log('groups',this.groups)
      // this.$store.dispatch('GetUserStateChange')
      
},
    methods: {
      BoxStyle(tab){
        if(tab){
        if(tab.FullHeight){
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'
        }
        }
        else{
          return ''
        }
       },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
       GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      Activatedialog(){
        this.dialog = true
      },
      AssignSampleGrouplogo(){
        this.editedItem.logo = this.SelectedImage.fileurl
        this.$store.commit('setIMGProp','')
      },
      CallUserGroup(groupid){
        //alert('calling user group '+groupid)
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(groupid).onSnapshot(snapshot =>{
              let groupdata = snapshot.data()
              groupdata.id = groupid
              this.groups.push(groupdata)
              console.log('this.groups',this.groups)
            })
          },
        GetGroupMemberData(){
          let vm = this
          return new Promise(function(resolve, reject) {
            //membergroup.Status === 'Active' && membergroup.GroupPublishStatus !== 'Draft'
            db.collection('SocialNetworks').doc(vm.$store.state.ActiveSuiteid).collection('groupmembers').doc(vm.UserID).collection('groups').where('Status','==','Active').where('GroupPublishStatus','==','Published').onSnapshot(res => {
              let membergroups = []
              let listlength = res.docs.length
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      membergroups.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                  if(membergroups.length === listlength){
                    resolve(membergroups)
                  }
              })
            })
          })
        },
       GetRequestingUser(){
        var vm = this;
        this.UserRecord = this.userLoggedIn
        if(this.UserRecord){
          this.GetGroupMemberData().then(membergroups => {
            if(membergroups){
              console.log('membergroups',membergroups,this.UserRecord.groups)
              membergroups.map(membergroup => {
                // if(membergroup.Status === 'Active' && membergroup.GroupPublishStatus !== 'Draft'){
                  //here we build in check for guest
                  if(this.$store.state.UserisGuest){
                    if(membergroup.GroupPrivacy === 'Public' || membergroup.GroupPrivacy === 'All Site Members'){
                      this.CallUserGroup(membergroup.id)
                    }
                    else{
                    let match = this.UserRecord.groups.find(obj => obj.Groupid === membergroup.id)
                    if(match){
                      this.CallUserGroup(membergroup.id)
                    }
                  }
                  }
                  else{
                    if(membergroup.GroupPrivacy === 'Public' || membergroup.GroupPrivacy === 'All Site Members' || membergroup.GroupPrivacy === 'Non Guest Site Members'){
                      this.CallUserGroup(membergroup.id)
                    }
                    else{
                    let match = this.UserRecord.groups.find(obj => obj.Groupid === membergroup.Groupid)
                      if(match){
                        this.CallUserGroup(membergroup.id)
                      }
                    }
                  }
                // }
                
              
              })
            }
          })
        }
        

      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
    ToggleEdit(item,dialprop){
      //console.log(item,dialprop)
      if(this.$store.state.TemplateView && this.Editor && this.$store.state.TemplateOwner){
        this.editedItem = Object.assign({},item)
        this.OpenEditdialog()
      }
      else if(!this.Editor){
        //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
        this.OpenAboutDialog(dialprop)
      }
      
    } ,    
    OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
    OpenEditdialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
      onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                      let group = {
                        name: this.editedItem.name,
                        Description: this.editedItem.Description,
                        Created_By: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                        Created_Byid: this.userLoggedIn.id,
                        Created_On: new Date(),
                        logo: this.editedItem.logo,
                        Administrators: []
                      } 
                      this.$store.dispatch('CreateSearchTitle',group.name).then(searchtitle => {
                       group.nameQuery = searchtitle 
                      if(this.editedItem.id){
                        this.ConfigPath.collection('samplegroups').doc(this.editedItem.id).set(group).then(function(doc) {
                          //console.log(doc.id)
                            })
                      }
                      else{
                        this.ConfigPath.collection('samplegroups').add(group).then(function(doc) {
                          //console.log(doc.id)
                            })
                      }
                      })
                        
                    this.Closedialog()
                            this.dialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}
.grouphovercard{  
  opacity: 0.6;
}
.grouphovercard:hover {
  opacity: 1;
  transition: opacity .4s ease-in-out;
}
</style>
    
    