<template>
  <div>
     <v-card-subtitle class="mediumoverline" style="padding-top:30px;">
                       My Wishlist
                    </v-card-subtitle>
                     <v-card  class="siteconfigcard"  width="100%">
                   <!-- <v-row class="justify-center" v-for="item in WishListItems" :key="item.itemObjKey">
                       <v-col sm="12" md="6" lg="4">
                           <v-img contain :src="item.IMG"/>
                       </v-col>
                   </v-row> -->
                   <v-list>
                       <v-list-item v-for="item in WishListItems" :key="item.itemObjKey">
                           <v-list-item-content>
                             <v-list-item-title>
                                    {{item.Title}}
                               </v-list-item-title>
                               <v-list-item-subtitle v-if="item.Added_On.toDate" class="caption">
                                   {{item.Added_On.toDate()}}
                               </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="!item.Added_On.toDate" class="caption">
                                   {{item.Added_On}}
                               </v-list-item-subtitle>
                           </v-list-item-content>
                           <v-list-item-content>
                               <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                <v-list-item-avatar  v-bind="attrs"
                                    v-on="on" size="150" tile>
                                   <v-img  contain :src="item.IMG"/>
                               </v-list-item-avatar>
                                </template>
                                <v-card flat tile max-height="300" max-width="300">
                                    <v-card-text style="overflow-y: hidden !important;" >
                                        <span v-html="item.DescriptiveText">
                                        </span>
                                    </v-card-text>
                                </v-card>
                                </v-tooltip>
                               
                           </v-list-item-content>
                           
                           <!-- <v-list-item-content>
                               <span v-html="item.DescriptiveText">
                               </span>
                           </v-list-item-content> -->
                           <v-list-item-actions>
                               <v-btn :small="MiniView" :to="'/StoreItem/'+item.StoreCollection+'/'+item.id">View in Store</v-btn>
                           </v-list-item-actions>
                       </v-list-item>
                   </v-list>
                     </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','ActiveAccount','ClientStoreOrders'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        WishListItems(){
            return this.ActiveAccount.WishList ? this.ActiveAccount.WishList : []
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



