
     <template>
     <div>
      <v-navigation-drawer :dark="System.SystemBarDark" class="pubnavbarbg" app v-if="MiniView" v-model="NavbarDialog">
        <v-layout column align-center>
      <!-- <div class="cloudbg" :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"/> -->
      <!-- <v-flex class="mt-5 hidden-sm-and-down"> -->
        <v-flex>
          <v-avatar
            class="profile"
            size="220"
            tile
          >
            <img href="/Login" style="padding: 0px;object-fit: contain;" :width="$vuetify.breakpoint.xs?'140px' : '140px'"
        :src="System.Public_NavBar_IMG && System.Public_NavBar_IMG.url ? System.Public_NavBar_IMG.url : require('@/assets/logo.png')">
          </v-avatar>
          <!-- <p class="white--text subheading mt-1">GP Combrink Vehicle Sales & Finance</p> -->
        </v-flex>
      </v-layout>
      <v-list>        
        <v-divider></v-divider>
        <div v-for="act in PrependedPublicNavbarActions" :key="act.itemObjKey">
          <v-list-item @click="OpenActionDialog(act)">
          <!-- <v-list-item-action>
          <v-icon large class="actionicon" color="accent">{{ link.icon }}</v-icon>
          </v-list-item-action> -->
          <v-list-item-content>
            <v-list-item-title class="navoverline">{{ act.title}}
            </v-list-item-title>
            </v-list-item-content>
             <v-list-item-action>
              <v-btn small fab tile class="primary"><v-icon class="secondary--text">mdi-sitemap
           </v-icon></v-btn>
           </v-list-item-action>
          </v-list-item>
        </div>
        <div v-for="link in FinalRender" :key="link.title">
          <v-list-item @click="RoutetoItem(link)"  v-if="!link.Children || link.Children.length<0" :to="link.to" :target="link.target">
           <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="navoverline" :to="link.to" :target="link.target" >{{ link.title}}
            </v-list-item-title>
            </v-list-item-content>
             <v-list-item-action v-if="link.Page">
              <v-btn small fab tile class="primary"><v-icon class="secondary--text">mdi-sitemap
           </v-icon></v-btn>
           </v-list-item-action>
          </v-list-item>
            <v-list-group
              v-if="link.Children && link.Children.length"
              >
                <template v-slot:activator>
                  <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
                  <v-list-item-title class="navoverline">{{link.title}}</v-list-item-title>
                </template>
                <div v-for="subsub in link.Children"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title !== 'Dashboard Builder'"
                         @click="RoutetoItem(subsub)"
                    >          
                    <v-list-item-action>
                     <v-icon >{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title === 'Dashboard Builder' "
                        
                        @click="OpenDashboardBuilder()"
                    >          
                    <v-list-item-action>
                    <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-group
                    sub-group
                      v-if="subsub.Children && subsub.Children.length"
                      >
                        <template v-slot:activator>
                          <!-- <v-list-item-action>
                         <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                        </v-list-item-action> -->
                          <v-list-item-title class="navoverline">{{subsub.title}}</v-list-item-title>
                        </template>
                        <v-list-item class="mx-3" v-for="subchild in subsub.Children" :key="subchild.itemObjKey"
                          :to="subchild.to" :target="subchild.target"
                      >          
                      <v-list-item-action>
                      <v-icon class="actionicon" color="accent">{{ subchild.icon }}</v-icon>
                          
                        </v-list-item-action>                          
                          <v-list-item-title class="navoverline">{{ subchild.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                </div>
              </v-list-group>
        </div>
      </v-list>
      </v-navigation-drawer>
        <!-- <v-card  tile> -->
          <!-- !System.PublicNavbarStyle ||  -->
          <v-system-bar :style="SiteDialog?  'margin-top: 120px;z-index: 5;' : 'z-index: 5;'" v-if="System.SystemBarEnabled" :color="System.SystemBarColor ? System.SystemBarColor : ''"
           fixed :dark="System.SystemBarDark">
           <v-list-item dense>
           <marquee :style="$vuetify.breakpoint.md? 'max-width: 42%;' : $vuetify.breakpoint.lg? 'max-width: 55%;' : 'max-width: 60%;'" v-if="System.SiteSlogan && !MiniView && System.SloganMarquee" behavior="scroll" direction="left">
            {{System.SiteSlogan}}</marquee>
           <span v-if="System.SiteEmail && $vuetify.breakpoint.xs" >
           <v-icon class="primary--text">mdi-email</v-icon> <a :href="'mailto:'+System.SiteEmail" target="_blank" rel="noopener">
           {{smart_substr(System.SiteEmail,17)}}
            </a></span><v-spacer></v-spacer><span style="padding-right: 5px;" v-if="System.SiteSlogan && !MiniView && !System.SloganMarquee">
            {{System.SiteSlogan}}
            </span>
           <span v-if="System.SiteEmail && !$vuetify.breakpoint.xs" style="padding-right: 5px;">
           <v-icon left class="primary--text">mdi-email</v-icon> <a :href="'mailto:'+System.SiteEmail" target="_blank" rel="noopener">
            {{System.SiteEmail}}
            </a></span>
           <span v-if="System.SiteTelephoneNumber">
           <v-icon left class="primary--text">mdi-phone</v-icon> {{System.SiteTelephoneLabel && !$vuetify.breakpoint.xs ? System.SiteTelephoneLabel : ''}}: <a :href="'tel:'+System.SiteTelephoneNumber" target="_blank" rel="noopener">
            {{System.SiteTelephoneNumber}}
            </a>
           </span>
            <v-btn v-if="!userLoggedIn && System.SystemBarHasSignup" to="/SignUp" x-small>Sign Up</v-btn>
            <v-btn icon v-if="!userLoggedIn" to="/" x-small><v-icon right>mdi-home</v-icon></v-btn>
           </v-list-item>
          </v-system-bar>
          <v-app-bar height="70"
      fixed v-if="System.PublicNavbarStyle === 'STD Bar'"
      :class="TransparentNavbar ? 'transparent' : 'pubnavbarbg'"
      elevate-on-scroll :dark="System.PublicNavbarDark" :style="SiteDialog? System.SystemBarEnabled ? 'margin-left: 100px;margin-top:144px;z-index:5;' : 'margin-left: 100px;margin-top:120px;z-index:5;' : System.SystemBarEnabled ? 'top:24px;padding-top:5px;' : ''"
    >
    
      <v-app-bar-nav-icon v-if="MiniView" @click="ActivateNavbarDialog()"></v-app-bar-nav-icon>
      <v-toolbar-title style="overflow: visible;"><a href="/Login"><img href="/Login" style="padding: 0px;object-fit: cover;" :width="$vuetify.breakpoint.xs?'80px' : '80px'"
        :src="System.Logo ? System.Logo : System.Public_NavBar_IMG && System.Public_NavBar_IMG.url ? System.Public_NavBar_IMG.url : require('@/assets/logo.png')"></a></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-tabs style="height: 90%;" v-model="CurrentPageIndex" right class="mx-3" v-if="!MiniView">
        <v-hover v-for="(item,index) in FinalRenderwithActions" :key="item.itemObjKey">
        <v-tab @click="item.Action ? OpenActionDialog(item) : RoutetoItem(item)" 
        slot-scope="{ hover }"
      :class="`${hover? 'stdwebmenubarbtn pubnavbaritem': 'stdwebmenubarbtn'}`"
       :style="`${hover? 'stdwebmenubarbtn pubnavbaritem': 'stdwebmenubarbtn'}`"
        active-class="pubnavbaritem">  
        <v-list-item style="place-content: center;">          
           <v-icon small left :style="System.PublicNavbarSelectedText && index === CurrentPageIndex || 
            System.PublicNavbarSelectedText && hover ? 
            'color: '+System.PublicNavbarSelectedText+';' : 
            System.PublicNavbarText  ? 
           'color: '+System.PublicNavbarText+';' : ''">{{item.icon}}</v-icon>
           <span  :style="System.PublicNavbarSelectedText && index === CurrentPageIndex || 
            System.PublicNavbarSelectedText && hover ? 
            'color: '+System.PublicNavbarSelectedText+';' : 
            System.PublicNavbarText  ? 
           'color: '+System.PublicNavbarText+';' : ''">
        
            {{item.title}}
           <v-menu
                v-if="item.Children && item.Children.length > 0"
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  
                    <v-icon v-on="on" large
                    v-bind="attrs">
                      mdi-menu-down
                    </v-icon>
                </template>

                <v-list class="pubnavbarbg" dense  :dark="System.PublicNavbarDark">
                  <v-hover  v-for="(child,childindex) in item.Children"
                    :key="child.itemObjKey">
                  <v-list-item dense @click="item.Action ? OpenActionDialog(child) : RoutetoItem(child)" 
                    slot-scope="{ hover }"
                    :class="`${hover? 'stdmenubarchildbtn pubnavbaritem': 'stdmenubarchildbtn'}`"
                  >
                  <v-list-item-avatar>
                    <v-icon small left :style="System.PublicNavbarSelectedText && childindex === CurrentChildIndex || 
            System.PublicNavbarSelectedText && hover ? 
            'color: '+System.PublicNavbarSelectedText+';' : 
            System.PublicNavbarText  ? 
           'color: '+System.PublicNavbarText+';' : ''">{{child.icon}}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content :style="System.PublicNavbarSelectedText && childindex === CurrentChildIndex || 
            System.PublicNavbarSelectedText && hover ? 
            'color: '+System.PublicNavbarSelectedText+';' : 
            System.PublicNavbarText  ? 
           'color: '+System.PublicNavbarText+';' : ''">
                    {{ child.title }}
                  </v-list-item-content>
                  </v-list-item>
                  </v-hover>
                </v-list>
              </v-menu>
        
            </span>
        </v-list-item>               
        </v-tab>
        </v-hover>
      </v-tabs>
    <!-- <div v-if="!MiniView">
      <v-hover>
      <button @click="RoutetoItem(item)"
      slot-scope="{ hover }"
      :class="`${hover? 'stdwebmenubarbtn pubnavbaritem': 'stdwebmenubarbtn'}`"
      text v-for="item in FinalRender" :key="item.itemObjKey">
        <v-icon small left>{{item.icon}}</v-icon>{{item.title}}
      </button>
      </v-hover>
    </div> -->
    </v-app-bar>
    <v-app-bar      
      fixed v-if="!System.PublicNavbarStyle || System.PublicNavbarStyle === 'Default'"
      color="pubnavheaderbg"
      dark :style="SiteDialog? 'margin-left: 100px;margin-top:65px;z-index:5;' : ''"
      :prominent="NonSigninRoute"
      
      :shrink-on-scroll="NonSigninRoute"
      
      :fade-img-on-scroll="NonSigninRoute">
      <v-app-bar-title >
        <div v-if="!userLoggedIn" style="text-align: center;align:center;"><a href="/Login"><img href="/Login" contain :width="$vuetify.breakpoint.xs?'150px' : '150px'"
        :src="System.Public_NavBar_IMG && System.Public_NavBar_IMG.url ? System.Public_NavBar_IMG.url : require('@/assets/logo.png')"></a>
          </div>
          <div v-if="userLoggedIn" style="text-align: center;align:center;"><img contain :width="$vuetify.breakpoint.xs?'150px' : '150px'" :src="System.Public_NavBar_IMG && System.Public_NavBar_IMG.url ? System.Public_NavBar_IMG.url : require('@/assets/logo.png')"></div>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-list-item class="primary--text overline">                      
        <v-spacer></v-spacer><v-icon class="primary--text">mdi-phone</v-icon> {{TopLocation.Name}}: <a :href="'tel:'+TopLocation.Telephone" target="_blank" rel="noopener">{{TopLocation.Telephone}}</a>
      </v-list-item>
      <template v-slot:extension>
       <v-tabs align-with-title dark class="pubnavbarbg">
          <!-- <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer" /> -->
        <v-icon class="mx-3" @click="ToggleAppStylingView()" v-if="userIsAdmin">mdi-palette-advanced</v-icon>
          <v-list-item class="pubnavbarbg hidden-sm-and-down">
            <v-list-item-content class="justify-center" v-for="item in FinalRender" :key="item.itemObjKey">
                      <v-btn @click="RefreshRouter()" class="pubnavbaritem--text" small plain :dark="false" :to="'/'+item.Path" v-if="!item.Children || !item.Children.length" text>{{item.DisplayName}}</v-btn>
                      <v-menu
                          v-if="item.Children && item.Children.length"
                          bottom
                          left
                          offset-y
                          :close-on-content-click="false"
                      >
                          <template v-slot:activator="{ on }">
                          <v-btn
                              text
                              small
                              class="align-self-center mr-4"
                              v-on="on"
                              :dark="false"
                          >
                          {{item.DisplayName}}
                              <v-icon right>mdi-menu-down</v-icon>
                          </v-btn>
                          </template>
                          <v-list dense :dark="false" color="#EEEEEE">
                          <v-list-item
                              v-for="subitem in item.Children"
                              :key="subitem.itemObjKey"
                          >                            
                              <v-btn small plain width="100%" :dark="false"  :to="'/'+subitem.Path" v-if="!subitem.Children || !subitem.Children.length" text>{{subitem.DisplayName}}</v-btn>
                              <v-list-group
                              v-if="subitem.Children && subitem.Children.length"
                            >
                              <template v-slot:activator>
                                <v-btn small plain width="100%" :dark="false" text>{{subitem.DisplayName.toUpperCase()}}<v-icon>mdi-menu-down</v-icon></v-btn>
                              </template>
                            
                                  <v-list-item
                                      v-for="subsub in subitem.Children"
                                      :key="subsub.itemObjKey"
                                      :href="subsub.Path"
                                      :dark="false" color="#EEEEEE"
                                  >                                    
                                      <v-btn small plain width="100%" :dark="false" text>{{subsub.DisplayName.toUpperCase()}}</v-btn>
                                  </v-list-item>
                              </v-list-group>
                          </v-list-item>
                          </v-list>
                      </v-menu>                                        
              </v-list-item-content>
          </v-list-item>
         
        </v-tabs>

        <!-- <v-list width="100%" dense :dark="false" class="primary justify-end hidden-sm-and-down" > -->
                              
                            <!-- </v-list> -->
      </template>
    </v-app-bar>
    <!-- <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="600"
    >
      <v-container style="height: 1000px;"></v-container>
    </v-sheet>
  </v-card> -->
  <BottomNavigation v-if="$vuetify.breakpoint.sm && !AltPubNavbar || $vuetify.breakpoint.xs && !AltPubNavbar" :style="'position: fixed;bottom: 50px;z-index: 5;'" :BottomNavigationItems="FinalRender" :ThemeColor="'pubnavbaritem'" :ThemeBGColor="'pubnavbarbg'"
    :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView" :DBNavbar="false" :UserRecord="UserRecord"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :ReadNotifications="ReadNotifications"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB" :UnreadNotifications="UnreadNotifications"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements" :UserPicture="UserPicture" :BottomMenu="false" :TopMenu="true"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
    <v-navigation-drawer temporary class="waytop"       
      fixed :dark="true" right  v-model="drawer" color="primary">
      <v-list>
        <div v-for="item in FinalRender" :key="item.title">
          <v-list-item v-if="!item.Children || item.Children.length===0" :to="'/'+item.Path">
          <v-list-item-content>
            <v-list-item-title :to="'/'+item.Path">{{ item.DisplayName}}
            </v-list-item-title>
            </v-list-item-content>
           
          </v-list-item>
            <v-list-group
              v-if="item.Children && item.Children.length"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{item.DisplayName}}</v-list-item-title>
                </template>
                <div v-for="subsub in item.Children"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3"
                        
                        :to="'/'+subsub.Path"
                    >                                   
                        <v-list-item-title>{{ subsub.DisplayName }}</v-list-item-title>
                    </v-list-item>
                    
                </div>
              </v-list-group>
          </div>
      </v-list>
    </v-navigation-drawer>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BottomNavigation from '@/components/Navigation/BottomNavigation'
export default {
  props: ['SitePages','System','SystemEntities'],
  components: {BottomNavigation},
  data() {
    return {
      CurrentPageIndex: 0,
      CurrentChildIndex: -1,
      NavbarDialog: false,
      ScrollisTop: true,
      drawer: false,
      TopLocation: '',
      BusinessLocations: [],
      pubitems: [
        {
          DisplayName:'Home',
          Path:'/',
        },
        {
          DisplayName:'Practice Areas',
          Path:'/PracticeAreas',
        },
        {
          DisplayName:'Contact Us',
          Path:'/ContactUs',
        },
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  computed: {
    WebFormEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'
            })
            // .map(entity => {
            //     return entity.WebTab
            // })
        },
    FinalRenderwithActions(){
      return this.PrependedPublicNavbarActions.concat(this.FinalRender,this.AppendedPublicNavbarActions)
    },
    AppendedPublicNavbarActions(){
      let actions = []
      if(this.System && this.System.AppendedPublicNavbarActions){
        return this.System.AppendedPublicNavbarActions.map(act => {
          let actobj = Object.assign({},act)
          if(actobj.Type === 'Web Form'){
            act.icon = 'mdi-form-select'
            actobj.title = act.id.split('_').join(' ')
          }
          return actobj
        })
      }
      return actions
    },
    PrependedPublicNavbarActions(){
      let actions = []
      if(this.System && this.System.PrependedPublicNavbarActions){
        return this.System.PrependedPublicNavbarActions.map(act => {
          let actobj = Object.assign({},act)
          if(actobj.Type === 'Web Form'){
            act.icon = 'mdi-form-select'
            actobj.title = act.id.split('_').join(' ')
          }
          return actobj
        })
      }
      return actions
    },
    AltPubNavbar(){
      return this.System.PublicNavbarStyle === 'STD Bar'
    },
    TransparentNavbar(){
      //console.log(this.ActivePage,this.ActivePage.ClearNavbarColor)
      return this.ActivePage && this.ActivePage.ClearNavbarColor && this.ScrollisTop
    },
    ActivePageMenuIndex(){
      let index = 0
      if(this.ActivePage){
        let menuobj = this.FinalRender.find(obj => obj.title === this.ActivePage.Name)
        if(menuobj){
          index = this.FinalRender.indexOf(menuobj)
        }
      }
      return index
    },
    ActivePage(){
      let page = this.SitePages.find(obj => obj.Name === this.$route.path.split('/').join('').split('-').join(' '))
      //console.log('this.$route.path',this.$route.path,this.System.Public_Landing_Page,this.SitePages.find(obj => obj.Name === this.System.Public_Landing_Page.split('/').join('').split('-').join(' ')))
      if(this.$route.path === '/' && this.System.Public_Landing_Page){
        return this.SitePages.find(obj => obj.Name === this.System.Public_Landing_Page.split('/').join('').split('-').join(' '))
      }
      return page
    },
    MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    SiteDialog(){
            return this.$store.state.SiteDialog
        },
    AppBuilderView(){
      return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView
    },
    BuilderView(){
    return this.$store.state.BuilderView
    },
    WebsiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Website Builder'
    },
    DBBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Database Builder'
    },
    NetworkBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
    },
    NonSigninRoute(){
      return this.$route.name !== 'login' && this.$route.name !== 'signup' && this.$route.name !== 'PhoneLogin'
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    userLoggedIn () {
      return this.$store.getters.user
    },
    PublicPages(){
      return this.SitePages.filter(page => {
        return page.PublishType === 'Public'
      })
    },
     FinalRender(){
       if(this.System && this.System.PublicNavbarItems){
         return this.System.PublicNavbarItems.map(item => {
            let newobj = {
              DisplayName: item.title,
              Path: item.Path.split(' ').join('-'),
              IsRoute: true,
              icon: item.icon,
              title: item.title
            }
            if(item.Children){
              newobj.Children = item.Children.map(child => {
                let newchild = {
                  Path: child.Path.split('/').join(''),
                  DisplayName: child.title,
                  IsRoute: true,
                  icon: child.icon,
                  title: child.title
                }
                return newchild
              })
              if(typeof item.MenuOnly !== 'undefined'){
                newobj.MenuOnly = item.MenuOnly
              }
            }
            return newobj
          })
       }
       else{
         return []
       }
     }
    },
mounted() {
  window.addEventListener('scroll', this.handlePBNBScroll)
  },
  watch: {
     ActivePage : {
            handler: function(newvalue, oldvalue) {
              //console.log(newvalue, oldvalue)
                if(newvalue !== oldvalue){
                  this.SetPageIndex()
                }
            }
     }
  },
  created() {
    this.SetPageIndex()
    if(this.$route.path.includes('UserInvite')){
              
    }
    else{
      if(this.FinalRender.length === 0 && this.$route.name !== 'BusinessMember'){
        this.$router.push('/Login')
      }
      this.GetBusinessLocations()
    }
    
  },

  methods: {
    smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
    OpenActionDialog(item){
      if(item.Action.Type === 'Web Form'){
        // let webform = Object.assign({},item.Action)
        item.Action.title = item.title
        this.$store.commit('SetSocialItemInteractMethodProp',item.Action) 
        this.$store.commit('SetSocialItemInteractMethod','ActivateWebFormDialog')
      }
    },
    SetPageIndex(){
      if(this.ActivePage){
        let menuobj = this.FinalRenderwithActions.find(obj => obj.title === this.ActivePage.Name)
        if(!menuobj){
          menuobj = this.FinalRenderwithActions.find(obj => obj.Children && obj.Children.find(child => child.title === this.ActivePage.Name))
          // let parent = this.FinalRenderwithActions.find(obj => obj.Children && obj.Children.find(child => child.title === this.ActivePage.Name))
          // menuobj = parent.Children.find(child => child.title === this.ActivePage.Name)
          //better to keep parent selected at least
          let child = menuobj.Children.find(obj => obj.title === this.ActivePage.Name)
          this.CurrentChildIndex = menuobj.Children.indexOf(child)
        }
        //console.log(this.FinalRender,menuobj,this.CurrentChildIndex,this.ActivePage.Name)
        if(menuobj){
          this.CurrentPageIndex = this.FinalRenderwithActions.indexOf(menuobj)
        }
      }
    },
    ActivateNavbarDialog(){
      this.NavbarDialog = true
    },
    handlePBNBScroll(event){
      if(window.scrollY==0){
        //user is at the top of the page; no need to show the back to top button
        this.ScrollisTop = true
      } else {
        this.ScrollisTop = false
      }
    },
    RoutetoItem(item){
      if(this.$route.path !== +'/'+item.Path && !item.MenuOnly){
       this.$router.push(item.Path) 
      }
      
    },
    SelectBottomNavMenuItem(item){
      if(!item.Children && !item.Method && !item.RouteBlank){
        this.$router.push(item.Path)
      }
      else if(item.Method){
        if(item.Method === 'IntranetViewToggle' && item.Prop === true){
          this[item.Method](item.Prop,true)
        }
        else if(item.SecondProp){
          this[item.Method](item.Prop,item.SecondProp)
        }
        else{
          this[item.Method](item.Prop)
        }
      }
    },
    ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
    RefreshRouter(){
      this.$emit('RefreshRouter')
    },
     GetBusinessLocations(){
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').where('IsPublicLocation','==',true).onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                          let buobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        if(buobj.id !== 'Guest'){
                            this.BusinessLocations.push(buobj)
                            if(buobj.Top){
                                this.TopLocation = buobj                       
                            }
                        }
                          
                        }
                    }) 
            })
        },
    
    
  }

}
</script>

<style>
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  position: fixed;
  z-index: 100;
  top: 0px
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 100
}
</style>
    