<template>
<div>
    
  <v-card class="white" flat tile height="100%" width="100%" style="padding-bottom:130px;padding-top:30px;">
      <!-- <v-parallax 
        height="150"
            :src="System.StoreCheckoutParallax.Path"
            v-if="System.StoreCheckoutParallax"
        >
        <v-row
              align="center"
              justify="center"
            >
        <v-col class="stdparallaxgradient text-center" cols="12">
        <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Checkout</span>
        </v-col>
      </v-row>
        </v-parallax>
      <v-parallax 
        height="150"
            src="@/assets/RASolidA.jpeg"
            v-if="!$route.params.id  && AppisDarkMode  && !PageView && !System.StoreCheckoutParallax"
        >
        <v-row
              align="center"
              justify="center"
            >
        <v-col class="stdparallaxgradient text-center" cols="12">
        <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Checkout</span>
        </v-col>
      </v-row>
        </v-parallax>
        
        <v-parallax 
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
            v-if="!$route.params.id  && !AppisDarkMode && !System.StoreCheckoutParallax || PageView && !System.StoreCheckoutParallax"
        >
        <v-row
              align="center"
              justify="center"
            >
        <v-col class="stdparallaxgradient text-center" cols="12">
        <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Checkout
</span>
        </v-col>
      </v-row>
        </v-parallax> -->
        
        <v-card flat tile width="100%" height="100%" style="padding:40px;">
        <StoreCheckoutForm :System="System" :StoreCheckout="true" :SystemEntities="SystemEntities" :GrandTotal="GrandTotal" :TaxTotal="TaxTotal" :OrderTotal="OrderTotal" :TaxPercentage="TaxPercentage"
        :tab="PreparedCheckoutTab" @ActivateStandbyDialog="ProcessActivateStandbyDialog" @DoSomething="DoSomething" @UpdateSurvey="UpdateOrder" :SurveyItem="FinalItem"/>
        </v-card>
  </v-card>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import StoreCheckoutForm from '@/components/WebPages/RenderComponents/StoreCheckoutForm';

export default {
    props: ['CartItems','System','SystemEntities'],
    components: {
        StoreCheckoutForm
    },
    data(){
        return {            
            CheckoutTab: {
                FinalStepName: 'Process Order',
                Method: 'ProcessOrder',
                RelatedEntity: {
                    id: 'Website_Orders'
                },
                StatusDefault: {
    "LevelFieldName": "Status",
    "Options": [
      {
        "Name": "Open",
        "LevelFieldName": "Status_Reason",
        "Level": {
          "id": 2,
          "name": "Status Reason",
          "children": [],
          "FieldName": "Status_Reason"
        },
        "ID": 1000003
      }
    ],
    "ID": 1000001,
    "HasDeepHierarchy": true,
    "Level": {
      "id": 1,
      "name": "Status",
      "FieldName": "Status",
      "children": [
        {
          "children": [],
          "name": "Status Reason",
          "id": 2,
          "FieldName": "Status_Reason"
        }
      ]
    },
    "Name": "Active"
  },
            Status_ReasonDefault: {
    "Name": "Open",
    "ID": 1000003,
    "LevelFieldName": "Status_Reason",
    "Level": {
      "id": 2,
      "children": [],
      "name": "Status Reason",
      "FieldName": "Status_Reason"
    }
  },
                Elements: [
                    {
                        Name: 'Cart Overview',
                        Type: 'Table',
                        MustContainData: true,
                        NoDataMessage: `<br><p>Your cart is empty and you cannot continue</p><p>To add items to the cart, browse the shop and add items</p> `,
                        NoDataAction: {
                            Name: 'Browse Shop',
                            Method: 'BrowseShop'
                        },
                        ObjectProp: 'Cart_Items',
                        ObjectDisplayName: 'Cart Items',
                        ObjectValue: 'TableData',
                        FormFields: [
                            {
                                Name: 'Item_Name',
                                DisplayName: 'Item Name',
                                Type: 'Single Line Text',
                                Response: ''
                            },
                            {
                                Name: 'Time_Added',
                                DisplayName: 'Time Added',
                                Type: 'Date',
                                Response: ''
                            },
                            {
                                Name: 'Stock_Qty',
                                DisplayName: 'Stock Qty',
                                Type: 'Number Field',
                                Response: ''
                            },
                            {
                                Name: 'SubTotal',
                                DisplayName: 'SubTotal',
                                Type: 'Number Field',
                                Response: ''
                            },
                            {
                                Name: 'Unit_Price',
                                DisplayName: 'Unit Price',
                                Type: 'Number Field',
                                Response: ''
                            },
                            {
                                Name: 'Discounted_Price',
                                DisplayName: 'Discounted Price',
                                Type: 'Number Field',
                                Response: ''
                            },
                            // {
                            //     Name: 'Discount',
                            //     DisplayName: 'Discount',
                            //     Type: 'Number Field',
                            //     Response: ''
                            // },
                            {
                                Name: 'Qty',
                                DisplayName: 'Order Qty',
                                Type: 'Number Field',
                                Response: ''
                            },
                            {
                                Name: 'Estimated_Arrival',
                                DisplayName: 'Estimated Arrival',
                                Type: 'Date',
                                Response: ''
                            },
                            {
                                Name: 'Description',
                                DisplayName: 'Description',
                                Type: 'Multiple Lines Text',
                                Response: ''
                            },
            //                 storeobj.Description = this.ActiveProduct.Description
            // storeobj.DescriptiveText = this.ActiveProduct.DescriptiveText
            // storeobj.Discount = this.ActiveProduct.Discount
            // storeobj.Discounted_Price = this.ActiveProduct.Discounted_Price
            // storeobj.Brand = this.ActiveProduct.Brand
            // storeobj.EntityID = this.ActiveProduct.EntityID
            // storeobj.Unit_Price = this.ActiveProduct.Price
            // storeobj.Siteid = this.ActiveProduct.Siteid
            // storeobj.Product_Class = this.ActiveProduct.Product_Class
            // storeobj.Product_Group = this.ActiveProduct.Product_Group
            // storeobj.Product_Type = this.ActiveProduct.Product_Type
            // storeobj.EstimatedArrival = this.EstimatedArrival
            // storeobj.ItemShips = this.ActiveProduct.ItemShips
            // storeobj.Stock_Qty = this.ActiveProduct.Stock_Qty            
            // storeobj.Qty = this.Qty,
            // storeobj.Availability = this.Availability,
            // storeobj.CartAddTime = new Date()    
            // storeobj.IMG = this.ActiveProduct.IMG     
            // storeobj.id = this.ActiveProduct.id
                        ],                      
                        TableHeaders: [
                            { text: 'Title', value: 'Item_Name',class: "overline",Type: 'Single Line Text'},
                            { text: 'Qty', value: 'Qty',class: "overline",Type: 'Number Field'},
                            { text: 'SubTotal', value: 'SubTotalDisplay',class: "overline",Type: 'Calculated Field',IsCurrency: true,Currency: 'ZAR'},
                            { text: 'Actions', value: 'actions',class: "overline"},
                        ],
                        TableData: [],
                        TableExpands: true,
                        TableExpandDetails: '',
                        TableExpandHasAction: true,
                        TableExpandProps: [
                            {
                                PropName: 'IMG',
                                Type: 'img',
                                ImgHeight: 128
                            },
                            {
                                PropName: 'DescriptiveText',
                                Type: 'v-html'
                            }
                        ],
                        TableExpandItemsActions: [
                            {
                                Display: 'Show in Store',
                                Type: 'RecordRoute',
                                RecordLocation: '/StoreItem/'
                            }
                        ],
                        TableActions: [
                            {
                                Name: '',
                                Type: 'Icon',
                                Icon: 'mdi-pencil',
                                Method: 'editItem',
                                Class: 'elevation-6 orange--text'
                            },
                            {
                                Name: '',
                                Type: 'Icon',
                                Icon: 'mdi-trash-can-outline',
                                Method: 'RemoveItemfromCart',
                                Class: 'elevation-6 red--text'
                            }
                        ]
                    },
                    {
                        // Name: 'Shopper Info',
                        Type: 'Web Form',
                        Name: 'Web Form',
                        ObjectProp: 'Shopper_Info',
                        ObjectDisplayName: 'Shopper Info',
                        ObjectValue: 'FormFields',
                        HideFormName: true,
                        HideSubmit: true,
                        FormName: 'Shopper Info',
                        FromDatabase: true,
                        DarkForm: false,
                        BGColor: {
                        "hexa": "#ffffffff",
                        "hex": "#ffffff"
                        },
                                HeaderColor: {
                        "hex": "#000000"
                        },  
                        FormSubmitted: false,
                        FormFields: [
                            {
                                Name: 'Name',
                                DisplayName: 'Name',
                                Type: 'Single Line Text',                                
                            },
                            {
                                Name: 'Surname',
                                DisplayName: 'Surname',
                                Type: 'Single Line Text',                                
                            },
                            {
                                Name: 'Email',
                                DisplayName: 'Email',
                                Type: 'Common Field',
                                CommonFieldType: 'Email'                                
                            },
                            {
                                Name: 'Delivery',
                                DisplayName: 'Delivery',
                                Type: 'Boolean', 
                                Response: false,                               
                            },
                        ]
                    },
                    //  {
                    //     // Name: 'Shopper Info',
                    //     Type: 'Web Form',
                    //     Name: 'Web Form',
                    //     ObjectProp: 'Delivery_Address',
                    //     ObjectDisplayName: 'Delivery Address',
                    //     ObjectValue: 'FormFields',
                    //     HideFormName: true,
                    //     HideSubmit: true,
                    //     FormName: 'Delivery Address',
                    //     FromDatabase: true,
                    //     DarkForm: false,
                    //     BGColor: {
                    //     "hexa": "#ffffffff",
                    //     "hex": "#ffffff"
                    //     },
                    //             HeaderColor: {
                    //     "hex": "#000000"
                    //     },  
                    //     FormSubmitted: false,
                    //     FormFields: [
                           
                    //         {
                    //             Name: 'Delivery_Address',
                    //             DisplayName: 'Delivery Address',
                    //             Type: 'Common Field',
                    //             CommonFieldType: 'Physical Address',
                    //             FieldBreakdown: [
                    //                 {Name: 'Delivery_Address_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '',Response: '1'},
                    //                 {Name: 'Delivery_Address_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '',Response: 'Long Street'},
                    //                 {Name: 'Delivery_Address_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true,Response: 'SmallVille'},
                    //                 {Name: 'Delivery_Address_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true,Response: 'Big Town'},
                    //                 {Name: 'Delivery_Address_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true,Response: '1777'},
                    //                 {Name: 'Delivery_Address_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true,Response: 'FarmState'},
                    //                 {Name: 'Delivery_Address_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true,Response: 'DisneyLand'},

                    //             ],
                    //             Response: {}                               
                    //         }
                    //     ]
                    // },
                   
                    {
                        Name: 'Review',
                        ObjectDisplayName: 'Review',
                        Type: 'Summary',
                        IsFinal: true,
                    },
                    {
                        Name: 'Confirmation',
                        ObjectDisplayName: 'Confirmation',
                        Type: 'Confirmation',
                        IsConfirmation: true,
                    },
                ],
                HasFooter: true,
                TabFooters: [
                    {
                        Name: 'Order Total',
                        Prop: 'OrderTotal',
                        IsCurrency: true,
                        Currency: 'ZAR',
                        CurrencyShort: 'R',
                        SubTitle: true
                    },
                    {
                        Name: 'Tax Total',
                        Prop: 'TaxTotal',
                        IsCurrency: true,
                        Currency: 'ZAR',
                        CurrencyShort: 'R',
                        SubTitle: true
                    },
                    {
                        Name: 'Grand Total',
                        Prop: 'GrandTotal',
                        IsCurrency: true,
                        Currency: 'ZAR',
                        CurrencyShort: 'R'
                    },
                ],
                FinalAdditions: [
                    {
                        Name: 'Order Delivery Date',
                        Type: 'Date Range Max',
                        IsSummaryAddition: true,
                        ObjectProp: 'Order_Delivery_Date',
                        ObjectDisplayName: 'Order Delivery Date',
                        ObjectValue: 'Estimated_Arrival',
                        ObjectLoop: 'Cart_Items'                        
                    }
                ]
            },
            FinalItem: {}
        }
    },
    computed:{
        
        userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
        PreparedCheckoutTab(){
            let tab = Object.assign({},this.CheckoutTab)
            tab.RelatedEntity.id = this.$store.state.Cart_Entity
            //console.log(tab,this.CartItems)
            if(this.CartItems){
             tab.Elements[0].TableData = this.CartItems.map(item => {
                 item.SubTotal = item.Unit_Price*item.Qty
                 if( this.$store.state.DefaultCurrency){
                  item.SubTotalDisplay = this.CurrencyFormatter(item.SubTotal, this.$store.state.DefaultCurrency.Currency)   
                 }                 
                return item
            })
            tab.OrderTotal = this.OrderTotal
            tab.TaxTotal = this.TaxTotal
            tab.TaxPercentage = this.TaxPercentage
            tab.GrandTotal = this.GrandTotal
            tab.Elements[0].TableExpandItemsActions[0].Result = '/StoreItem'
            tab.Elements[2].ExportstoPDF = true
            tab.Elements[2].HasAppendices = true
            tab.Elements[2].HeaderTitle = 'Proposal'
            tab.Elements[2].HeaderObjectProp = 'Shopper_Info'
            tab.Elements[1].FormFields = tab.Elements[1].FormFields.map(field => {
                //or basically you edit it but what the hell right?
                if(field.Type !== 'Boolean'){
                field.Response = this.userLoggedIn[field.Name]  
                  field.MappedField = {                        
                        Primary: false,
                        Name: field.Name,
                        IsHeader: true,
                        HeaderIndex: 1,
                        LoggedInUserValue: true,                        
                        }
                }
                
                return field
            })
            tab.Elements[2].HeaderObjectStyle = 'Data Context'
            tab.Elements[2].HeaderSize = 44
            tab.Elements[2].HeaderDataContext = [
                {
                    ShowField: true,
                    FieldName: 'Name',
                    FieldType: 'Single Line Text'
                },
                {
                    ShowField: true,
                    FieldName: 'Surname',
                    FieldType: 'Single Line Text'
                },
            ],
            tab.Elements[2].Appendices = [
                {
                    ObjectProp: 'Cart_Items',
                    DisplayName: 'Cart Items',
                    IsArray: true,
                    ArrayEntryName: 'Product',
                    LayoutStyle: 'Two Columns',
                    HasHeaderPage: true,
                   
                    Columns: [
                        {
                            HeaderType: 'Field',
                            HeaderFieldName: 'Item_Name',
                            HeaderBackgroundColod: 'red',
                            HeaderTextColor: 'white',
                            ContentType: 'Image',
                            ContentImageFieldName: 'IMG',
                        },
                        {
                            HeaderType: 'Custom Text',
                            HeaderCustomText: 'Features and Benefits',
                            HeaderBackgroundColod: 'red',
                            HeaderTextColor: 'white',
                            ContentType: 'Data Context',
                            DataContext: [
                                {
                                    ShowField: true,
                                    FieldName: 'Description',
                                    FieldType: 'HTML'
                                },
                                // {
                                //     ShowField: true,
                                //     FieldName: 'Description',
                                //     FieldType: 'HTML'
                                // }
                            ]
                        },

                    ]
                                    
                }

            ]
            //console.log(tab)   
            
            }
            
            return tab
        },
        GrandTotal(){
            let total = this.TaxTotal+this.OrderTotal
            return Number(total.toFixed(2))
        },
        TaxPercentage(){
            return this.$store.state.DefaultTax_Percentage
        },
        TaxTotal(){
            let total = this.OrderTotal*(this.$store.state.DefaultTax_Percentage/100)
            return Number(total.toFixed(2))
        },
        OrderTotal(){
            let total = this.CartItems.map(item => {
                let subtotal = item.Unit_Price*item.Qty
                return subtotal 
            }).reduce((a, b) => a + b, 0)
            return Number(total.toFixed(2))
        },
    },
    created(){
        if(this.System.CheckoutTab){
         this.CheckoutTab = this.System.CheckoutTab   
        }
        
    },
    methods: {
        ProcessActivateStandbyDialog(boolean){
            this.$emit('ActivateStandbyDialog',boolean)
        },
        UpdateOrder(UpdateType,ObjectDisplayName,ObjectProp,ObjectValue,ElementIndex,IsSummaryAddition){
            //console.log(UpdateType,ObjectDisplayName,ObjectProp,ObjectValue,ElementIndex,IsSummaryAddition)
            if(UpdateType !== 'Confirmation' && UpdateType !== 'Processing'){
                if(ElementIndex === 1){
                    let DeliveryElement = {
                        // Name: 'Shopper Info',
                        Type: 'Web Form',
                        Name: 'Web Form',
                        ObjectProp: 'Delivery_Address',
                        ObjectDisplayName: 'Delivery Address',
                        ObjectValue: 'FormFields',
                        HideFormName: true,
                        HideSubmit: true,
                        FormName: 'Delivery Address',
                        FromDatabase: true,
                        DarkForm: false,
                        BGColor: {
                        "hexa": "#ffffffff",
                        "hex": "#ffffff"
                        },
                                HeaderColor: {
                        "hex": "#000000"
                        },  
                        FormSubmitted: false,
                        FormFields: [
                           
                            {
                                Name: 'Delivery_Address',
                                DisplayName: 'Delivery Address',
                                Type: 'Common Field',
                                CommonFieldType: 'Physical Address',
                                FieldBreakdown: [
                                    {Name: 'Delivery_Address_Street_Number',DisplayName: 'Street Number',Primary: '',Secondary: '',Type: 'Child Common Field',IsSearch: '',Response: '1'},
                                    {Name: 'Delivery_Address_Street_Name',DisplayName: 'Street Name',Primary: '',Secondary: '', Type: 'Child Common Field',IsSearch: '',Response: 'Long Street'},
                                    {Name: 'Delivery_Address_Suburb',DisplayName: 'Suburb',Primary: '',Secondary: '',Type: 'Child Common Field',IsSearch:true,IsFilter:true,Response: 'SmallVille'},
                                    {Name: 'Delivery_Address_City',DisplayName: 'City',Primary: '',Secondary: '',Type: 'Child Common Field',IsSearch:true,IsFilter:true,Response: 'Big Town'},
                                    {Name: 'Delivery_Address_Postal_Code',DisplayName: 'Postal Code',Primary: '',Secondary: '',Type: 'Child Common Field',IsSearch:true,Response: '1777'},
                                    {Name: 'Delivery_Address_State_Province',DisplayName: 'State Province',Primary: '',Secondary: '',Type: 'Child Common Field',IsSearch:true,IsFilter:true,Response: 'FarmState'},
                                    {Name: 'Delivery_Address_Country_Region',DisplayName: 'Country Region',Primary: '',Secondary: '',Type: 'Child Common Field',IsSearch:true,IsFilter:true,Response: 'DisneyLand'},

                                ],
                                Response: {}                               
                            }
                        ]
                    }
                    if(this.System.StoreDeliveryMethod !== 'Collection Only'){
                        //actually we have a boolean, or need one, on the "Shopper Info"
                        //if shopper info boolean "Delivery" is true, then do this. 
                        if(this.FinalItem.Shopper_Info){
                          //console.log(this.FinalItem.Shopper_Info.Response)  
                        }
                        
                        if(this.FinalItem && this.FinalItem.Shopper_Info && this.FinalItem.Shopper_Info.Response && this.FinalItem.Shopper_Info.Response.Delivery === true){
                           this.CheckoutTab.Elements.splice(2,0,DeliveryElement) 
                        }                       

                    }
                }
                
                this.FinalItem[ObjectProp] = {DisplayTitle: ObjectDisplayName,Title: ObjectProp,Response: '',Type: UpdateType,ElementIndex: ElementIndex}
                if(IsSummaryAddition){
                    this.FinalItem[ObjectProp].IsSummaryAddition = IsSummaryAddition
                }
                if(UpdateType === 'Table'){
                    this.FinalItem[ObjectProp].Headers = this.CheckoutTab.Elements[ElementIndex].TableHeaders
                    .filter(header => {
                        return header.text !== 'Actions'
                    })
                }
                if(UpdateType !== 'Web Form' && UpdateType !== 'Web Form'){
                this.FinalItem[ObjectProp].Response = ObjectValue  
                }
                else{
                    this.FinalItem[ObjectProp].Response = {}             
                    ObjectValue.map(field => {
                        this.FinalItem[ObjectProp].Response[field.Name] = field.Response
                    })
                }    
            }
            else if(UpdateType === 'Confirmation'){
                this.FinalItem.Confirmation = `<div style="text-align: center;"><p>Thank you for your Order</p>
                <p>You Reference Number is `+ObjectDisplayName+`,please use it for future enquiries on the Order</p>
                <p>Please feel free to browse the store for more Products</p></div>`
                this.FinalItem.ConfirmationButton = 'Browse Shop'
                this.FinalItem.ConfirmationAction = {
                    Name: 'Browse Shop',
                    Method: 'BrowseShop'
                }
                this.$emit('ProcessClearStoreCart')
            }
            else if(UpdateType === 'Processing'){
                this.FinalItem.Processing = true
            }
            
            //do bear in mind Currency stuff must become number stuff again
            //console.log(this.FinalItem)
        },
        BrowseShop(object){
            this.$router.push('/StoreProducts')
        },
        ProcessOrder(SurveyItem){
            //console.log(SurveyItem)
            let FormFields = []
            let NewRecord = {}
            for(var prop in SurveyItem) {
                if (SurveyItem.hasOwnProperty(prop) && SurveyItem[prop].Type === 'Web Form') {
                    //console.log(prop)
                   let element = this.CheckoutTab.Elements[SurveyItem[prop].ElementIndex]
                   element.FormFields.map(field => {
                       FormFields.push(field)
                   })
                    
                }
                if (SurveyItem.hasOwnProperty(prop) && SurveyItem[prop].Type === 'Table') {
                    let field = {Name: prop,Entries: [],Type: 'SubCollection',DisplayName: SurveyItem[prop].DisplayTitle}
                    field.Response = SurveyItem[prop].Response
                    FormFields.push(field)
                }
            }
            //console.log(FormFields)
                let length = FormFields.length
                FormFields.map((field,i) => {
                    //Okay we just need to now acknowledge or decide how to handle certain things
                    //shop cart items for instnace is a subcolleciton or a complete separate entity. so how to handle?
                    //Ideally, I have a entity "OrderItem", and I add order items with lookup to order and lookup to product, and it's like opportunity product
                    //can we do this similar here?
                    //it means we need to submit response a little differently,
                    //the function must now consider this, if entity type then add this as subcollaction afterwards
                    //we did actually already mark field as Type "Subcollection"
                NewRecord[field.Name.split(' ').join('')] = field.Response
                if(field.Type === 'Lookup'){
                    NewRecord[field.Name.split(' ').join('')+'id'] = field.Response.id
                }
                NewRecord.Status = this.StatusDefault
                NewRecord.Status_Reason = this.Status_ReasonDefault
                if(field.Type === 'Date'){
                    if(!field.HasTimeInput){
                     let date = this.DateFormatter(field.Response)   
                     NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: date.getTime()} 
                    }
                    else{
                     //console.log(field.Response,field.TimeResponse)
                     let finaldate = new Date(field.Response+' '+field.TimeResponse)
                     NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: finaldate.getTime()}   
                    }    
                }
                if(i-1+2 === length){
                  const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(!NewRecord.Created_By && this.userLoggedIn){
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Created_Byid = this.userLoggedIn.id
                    NewRecord.User = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Userid = this.userLoggedIn.id                    
                  }
                  else if(this.userLoggedIn){
                      NewRecord.User = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Userid = this.userLoggedIn.id  
                  }
                  
                //   if(tab.DefaultOwner){
                //     NewRecord.Owner = tab.DefaultOwner
                //     NewRecord.Ownerid = tab.DefaultOwner.id
                //     NewRecord.NewNotification = {
                //     itemidprop: 'recordid',
                //     Type: 'Web Form Submission',
                //     Header: 'Form Submission for '+tab.FormName,
                //     Content: 'New Submission for a '+tab.SingleName,
                //     Owner: NewRecord.Owner,
                //     Ownerid: NewRecord.Ownerid,
                //     Read: false,
                //     Path: '/'+tab.SingleName.split(' ').join(''),
                //     Message: 'New Submission for a '+tab.SingleName,
                //     }
                //     if(this.userLoggedIn){
                //         NewRecord.NewNotification.CreatorFullName = this.userLoggedIn.Full_Name
                //         NewRecord.NewNotification.CreatorID = this.userLoggedIn.id
                //         NewRecord.NewNotification.Content = NewRecord.NewNotification.Content+' by '+NewRecord.NewNotification.CreatorFullName
                //         NewRecord.NewNotification.Message = NewRecord.NewNotification.Message+' by '+NewRecord.NewNotification.CreatorFullName
                //     }
                //   }
                  if(this.userLoggedIn){
                      NewRecord.WebSubmission = false
                  }
                  else{
                      NewRecord.WebSubmission = true
                  }
                  
                  let obj = {
                      //Cart_Entity
                    collection: this.$store.state.Cart_Entity,
                    data: NewRecord
                  }    
                  //console.log(obj)        
                //   if(!tab.ExistingRecord){
                //       SubmitFormData(obj).then(result => {
                //         console.log(result)
                //         if(tab.FromDatabase){
                //             this.$emit('ConfirmSubmission',tab,obj,result.data)
                //             this.$emit('ProcessingData',false)
                //         }
                //         else{
                //         tab.FormSubmitted = true  
                //         }
                        
                //     }) 
                //   }  
                //   else{
                //     if(tab.FromDatabase){
                //         this.$emit('ConfirmSubmission',tab,obj,tab.ExistingRecord.id)
                //         this.$emit('ProcessingData',false)
                //     }
                //     else{
                //     tab.FormSubmitted = true  
                //     }
                //   }
            }
                })
        },
        DoSomething(action,object){
            let method = action.Method
            //console.log(method,this[method])
            this[method](object)
        },
        editItem(item){
            console.log(item)
        },
        RemoveItemfromCart(item){
            this.$emit('RemoveItemfromCart',item)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
    }
}
</script>

<style>

</style>