<template>
<v-main>
      <v-card class="transparent" style="margin-top:60px;" flat tile>
          
          <v-dialog v-model="UserAccessdialog" width="400">
              <v-card   flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                      {{editedUser.Full_Name}} Permissions
                  </v-card-title>
                  <v-card-text>
                      <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-card-text>
                             <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="AdvanceDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                            :UsersLookupArray="NonInteractingUsers" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
                            />
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text>
                        <v-tabs v-model="PermissionTab" centered>
                          <v-tab>CRUD</v-tab>
                          <v-tab-item>
                      <v-list  v-if="editedUser && editedUser.Warehouses">
                        <v-list-item>
                        <v-list-item-content v-for="action in ProductsInteractions.slice(0,2)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in ProductsInteractions.slice(2,4)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in ProductsInteractions.slice(4,6)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" :disabled="action.DisablingFalsePeer ? !editedUser.Warehouses[ActiveWarehouse.id][action.DisablingFalsePeer] : false" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                    </v-list>
                          </v-tab-item>
                     
                          <v-tab>Moderation</v-tab>
                          <v-tab-item>
                      <v-list  v-if="editedUser && editedUser.Warehouses">
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(0,2)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(2,4)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(4,6)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(6,8)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                        <v-list-item-content v-for="action in TransactionalWarehouseActions.slice(8,10)" :key="action.itemObjKey">
                            <v-checkbox @change="UpdateWarehousePermissions(action)"
                            :label="action.Display" v-model="editedUser.Warehouses[ActiveWarehouse.id][action.Prop]" />
                        </v-list-item-content>
                        </v-list-item>
                    </v-list>
                          </v-tab-item>
                      </v-tabs>
                        </v-card-text>
                    </v-window-item>
                    </v-window>
                      
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CloseUserDialog()">
                          Cancel
                      </v-btn>
                    <!-- <v-btn
                        :disabled="step === 1"
                        text
                        @click="step--"
                    >
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!editedUser.id"
                        color="primary"
                        depressed
                        @click="step++"
                    >
                        Next
                    </v-btn> -->
                    </v-card-actions>
              </v-card>
          </v-dialog>
          <v-card-title class="recordtoolbar white--text largeoverline">
               <v-btn dark v-if="ActiveWarehouse.Business_Unit" :to="'/BusinessUnit/'+ActiveWarehouse.Business_Unit.id" outlined >{{ActiveWarehouse.Business_Unit.Name}}</v-btn>
            <v-btn dark v-if="ActiveWarehouse.Warehouse_Number" outlined >{{ActiveWarehouse.Warehouse_Number}}</v-btn> Warehouse - {{ActiveWarehouse.Name}} <v-icon @click="EditingConfig = !EditingConfig" v-if="userIsAdmin">mdi-pencil</v-icon>
              <v-spacer></v-spacer><v-btn @click="CleanupEVERYTHING()" v-if="userIsAdmin">Major Cleanup</v-btn>
              <!-- <v-btn @click="CopyAutoNumbers()" v-if="userIsAdmin">CopyAutoNumbers</v-btn> -->
              <!-- <v-btn @click="CopyPDFTemplates()" v-if="userIsAdmin">CopyPDFTemplates</v-btn> -->
          </v-card-title>
          <v-card tile  :dark="AppisDarkMode" width="100%" class="stickytopbanner3" id="recordownershipbanner1">
              
            <v-layout row class="justify-start" id="recordbanner" v-if="!EditingConfig">
                <v-col>
                    <strong>Store Entity: </strong>{{ ActiveWarehouse.Store_Entity}}
                </v-col>
                <v-col>
                    <strong>Sales Order Entity: </strong>{{ ActiveWarehouse.Sales_Order_Entity}}
                </v-col>
                </v-layout>   
                <v-list dense v-if="EditingConfig">
                <v-list-item>
               <v-list-item-content>
                    <strong class="accent--text">Store Entity:</strong>
                </v-list-item-content>
                <v-list-item-content>
                    <v-select label="Store Entity" v-model="ActiveWarehouse.Store_Entity"/>
                </v-list-item-content>
                <v-list-item-content>
                    <strong class="accent--text">Sales Order Entity:</strong>
                </v-list-item-content>
                <v-list-item-content>
                    <v-select :items="SystemEntities" item-text="id" label="Sales Order Entity" v-model="ActiveWarehouse.Sales_Order_Entity"/>
                </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-btn @click="UpdateWarehouse()">Save</v-btn>
                </v-list-item>
            </v-list>
            </v-card>

            <v-card v-if="EditingConfig" :dark="AppisDarkMode" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">

            <v-list dense>
                <v-list-item>
               <v-list-item-content>
                    <strong class="accent--text">Store Entity:</strong>
                </v-list-item-content>
                <v-list-item-content>
                    <v-autocomplete :items="StoreEntities" item-text="id" label="Store Entity" v-model="ActiveWarehouse.Store_Entity"></v-autocomplete>
                </v-list-item-content>
                <v-list-item-content>
                    <strong class="accent--text">Sales Order Entity:</strong>
                </v-list-item-content>
                <v-list-item-content>
                    <v-autocomplete :items="SystemEntities" item-text="id" label="Sales Order Entity" v-model="ActiveWarehouse.Sales_Order_Entity"></v-autocomplete>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-card> 
            <v-btn  class="my-10" v-if="!ActiveWarehouse.Warehouse_Number" @click="GenerateWHNumber()">Generate Code</v-btn>
        <v-card-text class="my-10">
                <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="ComputedSystemEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true"
                :PrimaryFieldName="''" :SingleFormEditing="false"
                :Record="ActiveWarehouse" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="InfoSection" :tab="''" :Fields="WarehouseHeaders" :AppisDarkMode="AppisDarkMode" :CanEdit="userIsAdmin"
                />
            </v-card-text>
            <v-card-text>
                <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenUserDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                :UsersLookupArray="InteractingUsers" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="true" @AddMethod="OpenNEWUserDialog"
                
                />
            </v-card-text>
      </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/FieldsForm';
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import DataSingleCabinet from '@/components/Database/DataSingleCabinet.vue';
import UserLookupTable from '@/components/General/UserLookupTable'

export default {
    props: ['System','SystemEntities','RADB','RAApp'],
    components: {FieldsForm,SingleTabFieldsSection,DataSingleCabinet,UserLookupTable},
    data() {
        return {
            UsersArrayChecked: [],
            UsersReady: false,
            PermissionTab: 0,
            step: 1,
            editedUserIndex: -1,
            editedUser: {

            },
            defaulteditedUser: {

            },
            UserAccessdialog: false,
            UserHeaders: [
                {text: 'Full Name', value: 'Full_Name', class: 'overline'},
                {text: 'Business Unit', value: 'Business_Unit.Name', class: 'overline'}
            ],
            EditingConfig: false,
            EntityReady: false,
            WarehouseHeaders: [
                {Name: 'Name',DisplayName: 'Name',propvalue: 'Name',text: 'Name',value: 'Name',class: 'overline',Type: 'Single Line Text',Primary: true},
                {Name: 'Description',DisplayName: 'Description',propvalue: 'Description',text: 'Description',value: 'Description',class: 'overline',Type: 'Multiple Lines Text'},
                {Name: 'Is_Public',DisplayName: 'Is Public',propvalue: 'Is_Public',text: 'Is Public',value: 'Is_Public',class: 'overline',Type: 'Boolean'},
                {Name: 'Guests_Allowed',DisplayName: 'Guests Allowed',propvalue: 'Guests_Allowed',text: 'Guests Allowed',value: 'Guests_Allowed',class: 'overline',Type: 'Boolean'},
                //Only if Warehouse Shipping plugin installed NYB {Name: 'Exports',DisplayName: 'Exports',propvalue: 'Exports',text: 'Exports',value: 'Exports',class: 'overline',Type: 'Boolean'},
                {Name: 'Company_Name',DisplayName: 'Company Name',propvalue: 'Company_Name',text: 'Company Name',value: 'Company_Name',class: 'overline',Type: 'Single Line Text'},
                // {Name: 'Bill_To_Address',DisplayName: 'Bill To Address',propvalue: 'Bill_To_Address',text: 'Bill To Address',value: 'Bill_To_Address',class: 'overline',Type: 'Common Field',CommonFieldType: 'Postal Address',
                // FieldBreakdown: []},
                {Name: 'Delivery_Address',DisplayName: 'Delivery Address',propvalue: 'Delivery_Address',text: 'Delivery Address',value: 'Delivery_Address',class: 'overline',Type: 'Common Field',CommonFieldType: 'Physical Address',
                FieldBreakdown: []},
            ],
            WarehouseActions: [
        {Display: 'Allocating',Prop: 'Allocating',List: 'Warehouse Transactions'},
        {Display: 'Requisitions',Prop: 'Requisitions',List: 'Warehouse Transactions'},
        {Display: 'Ordering',Prop: 'Ordering',List: 'Warehouse Transactions'},
        {Display: 'Receiving',Prop: 'Receiving',List: 'Warehouse Transactions'},
        {Display: 'Receiving Priorities',Prop: 'Receiving_Priorities',List: 'Warehouse Transactions',DisablingFalsePeer: 'Receiving'},
        {Display: 'Picking',Prop: 'Picking',List: 'Warehouse Transactions'},
        {Display: 'Packing',Prop: 'Packing',List: 'Warehouse Transactions'},
        {Display: 'Dispatching',Prop: 'Dispatching',List: 'Warehouse Transactions'},
        {Display: 'Auditing',Prop: 'Auditing',List: 'Warehouse Transactions'},        
        {Display: 'Create',Prop: 'Create',List: 'Warehouse Products'},
        {Display: 'Get',Prop: 'Get',List: 'Warehouse Products'},
        {Display: 'List',Prop: 'List',List: 'Warehouse Products'},
        {Display: 'Update',Prop: 'Update',List: 'Warehouse Products'},
        {Display: 'Delete',Prop: 'Delete',List: 'Warehouse Products'},
        {Display: 'Publish',Prop: 'Publish',List: 'Warehouse Products',DisablingFalsePeer: 'Update'},
      ],
            warehousesearch: '',
            DefaultSubCol: {
                MultipleFileUploadType: 'SubCollection',
                Entityid: 'Inbound_Transactions',
                ShowComponents: true,
                SectionType: 'SubCollection',
                OptionType: 'SubCollection',
                ConfigColleciton: 'SubCollections',
                DisplayName: 'Inbound Transactions',
                Name: 'Inbound Transactions',
                Type: 'Standard',
                Headers: [],
                HeadersInteger: 1000005,
                EntitySource: 'SubCollection'
            },
            RegisteredSubCollections: ['GoodsReceivingVouchers','PickingSlips'],
            SubCollections: [],
            SubColHeaders: [
                {id: 'Created_By',value: 'Created_By',Name: 'Created_By',text: 'Created By',DisplayName: 'Created By',Type: 'Lookup',RelatedBuildID: 'Users',LookupFieldName: 'Full_Name'},
                {id: 'Created_On',value: 'Created_On',Name: 'Created_On',text: 'Created On',DisplayName: 'Created On',Type: 'Date'},
                {id: 'LookupObj',value: 'LookupObj',Name: 'LookupObj',text: 'LookupObj',DisplayName: 'LookupObj',Type: ''}
                ]
        }
    },
    computed:{
        SalesOrderEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Sales_Order_Entity)
        },
        StoreProductsEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Store_Entity)
        },
        ArticleProductsEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Store_Article_Entity)
        },
        UserWarehouseObj(){
            console.log(this.userLoggedIn,this.ActiveWarehouse,this.userLoggedIn.Warehouses[this.ActiveWarehouse.id])
            return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        PermittedTransactions(){
            return this.PermittedItems.filter(item => {
                return item.Title !== 'Purchase Orders'
            })
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        WarehouseApp(){
            return this.AppsDataBase.find(obj => obj.id === this.ActiveWarehouse.Primary_Appid)
        },
        PermittedItems(){
            //now actually should eb based on plugins installed on the ActiveSuiteApp
             let WarehouseActions = [
              {Pluginid: 'Warehouse_Inbound',Display: 'Purchase Requisitions',Prop: 'Requisitions', ArrayName: 'PurchaseRequisitions',Description: 'View the items on Backorder, that need to be converted to Purchase Orders with Suppliers.',Path: '/BackorderProcessing/'+this.ActiveWarehouse.id},
              {Pluginid: 'Warehouse_Inbound',Display: 'Purchase Orders',Prop: 'Ordering', ArrayName: 'PurchaseOrders',Description: 'View Purchase Orders, placed with Suppliers.',Path: '/TBD/'+this.ActiveWarehouse.id},
              {Pluginid: 'Warehouse_Inbound',Display: 'Receiving',Prop: 'Receiving', ArrayName: 'GoodsReceivingVouchers',Description: 'Receive delivered goods from open Purchase Orders.',Path: '/GoodsReceiving/'+this.ActiveWarehouse.id},
              {Pluginid: 'Warehouse_Outbound',Display: 'Picking',Prop: 'Picking', ArrayName: 'PickingSlips',Description: 'View Picking Slips in Progress.',Path: '/Picking/'+this.ActiveWarehouse.id},
              {Pluginid: 'Warehouse_Shipping',Display: 'Packing',Prop: 'Packing', ArrayName: 'PackingLists',Description: 'View Packing Lists in Progress.',Path: '/TBD/'+this.ActiveWarehouse.id},
              {Pluginid: 'Warehouse_Outbound',Display: 'Dispatching',Prop: 'Dispatching', ArrayName: 'DispatchInstructions',Description: 'View items ready for Dispatching.',Path: '/Dispatching/'+this.ActiveWarehouse.Sales_Order_Entity+'/'+this.ActiveWarehouse.id},
            ]
            return WarehouseActions
            .filter(action => {
                return this.ActiveSuiteApp.Plugins.find(obj => obj.id === action.Pluginid)
            })
            .filter(action => {
                return this.UserWarehouseObj && this.UserWarehouseObj[action.Prop] || this.UserWarehouseObj && this.UserWarehouseObj.Auditing || this.userIsEagleViewer || this.userIsAdmin
            }).filter(item => {
                if(this.SalesOrderEntity && this.SalesOrderEntity.Warehouse_Dispatch_After && this.SalesOrderEntity.Warehouse_Dispatch_After.Name === 'Picking'){
                    return item.Prop !== 'Packing'
                }
                else{
                    return item
                }
            })
            .map(actionitem => {
                let actionobj = {
                    Title: actionitem.Display,
                    Description: actionitem.Description,
                    Path: actionitem.Path
                }
                console.log(actionobj)
                return actionobj
            })
        },
        TransactionalWarehouseActions(){
            return this.WarehouseActions.filter(act => {
                //act.ModeratorProp = act.Prop+'Clerks'
                return act.List === 'Warehouse Transactions'
            })
        },
        ProductsInteractions(){
            return this.WarehouseActions.filter(act => {
                //act.ModeratorProp = act.Prop+'Clerks'
                return act.List === 'Warehouse Products'
            })
        },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        
        UsersArrayPrepared(){
            return this.UsersReady ? this.UsersArrayChecked.map(user => {
                let counter = 0
                this.WarehouseActions.map((act,actindex) => {
                    if(!user.Warehouses[this.ActiveWarehouse.id]){
                        user.Warehouses[this.ActiveWarehouse.id] = {}
                    }
                    if(typeof user.Warehouses[this.ActiveWarehouse.id][act.Prop] === 'undefined'){
                        user.Warehouses[this.ActiveWarehouse.id][act.Prop] = false
                    }
                    else if(user.Warehouses[this.ActiveWarehouse.id][act.Prop]){
                        counter = counter-1+2
                        user.Pass = true
                    }
                    if(actindex-1+2 === this.WarehouseActions.length && counter === 0){
                        
                        user.Pass = false
                    }
                })
                return user
            }) : []
        },
        InteractingUsers(){
            return this.UsersArrayPrepared.filter(user => {
                return user.Warehouses[this.ActiveWarehouse.id] && user.Pass
            })
        },
        NonInteractingUsers(){
            return this.UsersArrayPrepared.filter(user => {
                return user.Warehouses[this.ActiveWarehouse.id] && !user.Pass
            })
        },
        StoreEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Store Product'
            })
        },
        DynamicWikiData(){
            let obj = Object.assign({},this.ActiveWarehouse)
            obj.SubCollections = this.ComputedSubCollections
            return obj
        },
        TransactionsTab(){
            let deftab = {									
                RowColor: '#ffffff',									
                Name: 'Transactions',
                DisplayName: 'Transactions',
                DataViewName: 'Data Single SubCollection',	
                DataSingleRelated: true,				
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-database-arrow-down-outline',									
                Height: 300,								
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 11,									
                FlexXSmall: 11,										
                Cols: 12,									
            }
            deftab.Elements = this.ComputedSubCollections
            return deftab
        },
        ComputedSubCollections(){
            return this.RegisteredSubCollections.map((subcol,subcolindex) => {
                let newsubcol = Object.assign({},this.DefaultSubCol)
                newsubcol.Data = []
                newsubcol.id = subcol
                newsubcol.Entityid = subcol
                newsubcol.DisplayName = subcol.split('_').join(' ')
                newsubcol.Name = subcol
                newsubcol.Headers = JSON.parse(JSON.stringify(this.SubColHeaders))
                console.log('newsubcol',newsubcol)
                return newsubcol
            })
        },
        EntityCollectionRef(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ConfigEntityID)
        },
        EntityDataRef(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id)
        },
        ConfigEntityID(){
            return this.CurrentEntity.id
        },
        CurrentEntity(){
            let obj = this.ComputedSystemEntities.find(obj => obj.id === 'Warehouses')
            obj.SubCollections = this.SubCollections
            console.log('obj',obj)
            return obj
        },
        InfoSection(){
            return {Name: 'Basic'}
        },
        ComputedSystemEntities(){
            let arr1 = this.SystemEntities
            let arr2 = [
                {id: 'Warehouses',DisplayName: 'Warehouses',SingleName: 'Warehouses',AllFields: this.WarehouseHeaders.map(field => {
                    field.id = field.Name
                    return field
                })}
            ]
            return arr1.concat(arr2)
        },
        BUData(){
            return this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.BuID)
        },
        BuID(){
            return this.$route.params.slug
        },
        ActiveWarehouse(){
            return this.$store.state.WarehousesArray.find(obj => obj.id === this.$route.params.id)
        },
        Warehouses(){
            return this.$store.state.WarehousesArray.filter(wh => {
                return wh.Business_Unitid === this.BuID
            })
            },
        WarehousesSearched(){
        return this.Warehouses.filter(wh => {
            if(this.warehousesearch){
            return wh.Name.toLowerCase().includes(this.warehousesearch.toLowerCase())
            }
            else{
            return wh
            }
        })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        
    },
    watch: {
        WarehouseApp(v){
            if(v){
                if(!this.ActiveSuiteApp){
                this.$store.commit('setActiveSuiteApp',this.WarehouseApp)   
                }
            }
        }
    },
    created(){
        if(!this.ActiveSuiteApp && this.WarehouseApp){
         this.$store.commit('setActiveSuiteApp',this.WarehouseApp)   
        }
        
        //this.PrepareAddressField('Bill_To_Address')
        this.PrepareAddressField('Delivery_Address')
        
        this.PrepSubCollectionInfo()
        this.UsersArrayChecked = this.UsersArray.map((user,userindex) => {
            this.GetUserRolesDoc(user).then(result => {
                user.Warehouses = result
                if(userindex-1+2 === this.UsersArray.length){
                    console.log('result',result)
                    this.UsersReady = true
                }
            })
            return user
        })
    },
    methods:{
        CopyPDFTemplates(){
             let pdfcollection = ['Purchase_Orders','Sales_Invoices','Sales_Quotes']
                pdfcollection.map(col => {
                    let docpath = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        this.RADB.collection('SystemConfig').doc('ConfigMaster').collection('pdfexportfunctions').doc(docid).set(docdata)
                        if(!docdata.IsEntityDefault){
                            this.RADB.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc(docid).set(docdata)
                        }
                    })
                })
        },
        CopyAutoNumbers(){
             let autonumberscollection = ['Beauty_Shop_Orders_SO_Number','Store_Suppliers_Supplier_Code',
                 'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number','GoodsReceivingVouchers_GRV_Number','PackingLists_Number','Payment_Invoices_Invoice_Number',
                 'PickingSlips_Number','PurchaseRequisitions_Number','Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number','Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
                autonumberscollection.map(col => {
                    let docpath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        docdata.LastNumbers = []
                        docdata.Last_Number = 0
                        docdata.Last_Value = 0
                        let docid = col
                        if(docid === 'Beauty_Shop_Orders_SO_Number'){
                            docid = 'StoreOrderEntityDefault'
                            docdata.IsEntityDefault = true
                            docdata.Entity_Type = {Name: 'Store Order'}
                        }
                        else if(docid === 'Store_Suppliers_Supplier_Code'){
                            docid = 'StoreSupplierEntityDefault'
                            docdata.IsEntityDefault = true
                            docdata.Entity_Type = {Name: 'Store Supplier'}
                        }
                        this.RADB.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(docid).set(docdata)
                        if(!docdata.IsEntityDefault){
                            this.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('AutoNumbers').doc(docid).set(docdata)
                        }
                    })
                })
        },
        FixStatementInvoice(){
            let note = `<p>Monthly "Basic Listing" Group Ownership on JAX0012 for April 2022</p>`
            let Line_Items = [
                {  
                  //you see, if a salesinvoice gets paid we push a line underneath this one to mark it paid, see further below
                  id: 'SU-INV-00000017',    
                  Line_Type: 'Debit',    
                  Name: 'Basic Listing',
                  Type: {ID: 1000002, Name: 'Group Ownership'},
                  Description: note,
                  Unit_Price: 172.5,
                  Qty: 1,
                  Sub_Total: 172.5
                },
                {  
                  //you see, if a salesinvoice gets paid we push a line underneath this one to mark it paid, see further below
                  id: 'SU-INV-00000017',    
                  Line_Type: 'Payment',    
                  Name: 'Basic Listing',
                  Type: {ID: 1000002, Name: 'Group Ownership'},
                  Description: '<p>Payment on SU-INV-00000017 - Thank you...</p>',
                  RemoveonNext: true,
                  Unit_Price: -172.5,
                  Qty: 1,
                  Sub_Total: -172.5
                },
                {    
                  Line_Type: 'Credit',      
                  Name: 'Membership Earnings',
                  Type: 'Group Membership',
                  Description: `<p>Earnings for Group Membership Subscriptions</p>`,
                  Unit_Price: 0,
                  Qty: 0,
                  Sub_Total: 0
                },
                {    
                  Line_Type: 'Credit',          
                  Name: 'Credit Token Earnings',
                  Type: 'Credit Tokens',
                  Description: `<p>Credit Token Earnings for Group Membership Subscriptions</p>`,
                  Unit_Price: 0,
                  Qty: 0,
                  Sub_Total: 0
                }

            ]
            db.collection('statementinvoices').doc('ST-IN-GO-00000003').update({
                Line_Items: Line_Items
            })
        },
        Cheatinautonumber(){
            
            let col = 'DispatchInstructions_Number'
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(col).get().then(snapshot => {
                let docdata = snapshot.data()
                let newid = 'Statement_Invoices_Invoice_Number'
                //before we do this, wcich of these do we actually make payments for?
                //1. defiitely group membership, and MAYBE Group Featured Member
                //Site Channel also yes..I don't thinkt he otehr used
                docdata.Prefixes[0].Prefix_Value.Options = this.$store.state.SubscriptionPackageTypes
                docdata.Prefixes[0].Prop = 'Subscription_Type'
                docdata.LastNumbers = []
                docdata.Last_Value = ''
                docdata.Last_Number = 0
                db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(newid).set(docdata)
            })
        },
        CleanupEVERYTHING(){
            let transactioncollections = ['PurchaseRequisitions','PurchaseOrders','GoodsReceivingVouchers','PickingSlips','PackingLists','DispatchInstructions']
            let movementcollections = ['Inbound_Transactions','Outbound_Transactions']
            let financecollections = ['purchaseinvoices','salesinvoices','journalentries','salesquotes']
            let opscollections = ['clientrecords','clientliaison']
            let autonumberscollection = ['PurchaseRequisitions_Number','Purchase_Orders_PO_Number','GoodsReceivingVouchers_GRV_Number','PickingSlips_Number','PackingLists_Number','DispatchInstructions_Number','Sales_Quotes_Quote_Number']
            autonumberscollection.map(col => {
                let docpath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(col)
                docpath.get().then(snapshot => {
                    let docdata = snapshot.data()
                    docdata.LastNumbers[0].Last_Number = 0
                    docpath.update({
                        LastNumbers: docdata.LastNumbers
                    })
                })
            })
            transactioncollections.map(col => {
                let colpath = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).collection(col)
                let linespath = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).collection(col+'Line_Items')
               
                console.log(linespath)
                linespath.get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                             if(col === 'PurchaseOrders'){
                                let solinespath = linespath.doc(entry.id).collection('SalesOrdersLine_Items')
                                solinespath.get().then(solineres => {
                                    let length = solineres.docs.length
                                    if(length === 0){
                                        linespath.doc(entry.id).delete() 
                                    }
                                    else{
                                        const solinechanges = solineres.docChanges();
                                        let counter = 0
                                        solinechanges.forEach(solinechange => {
                                        if (solinechange.type === 'added') {
                                            counter = counter-1+2
                                            let solineentry = {
                                            ...solinechange.doc.data(),
                                            id: solinechange.doc.id
                                            }             
                                            solinespath.doc(solineentry.id).delete().then(solinedel => {
                                                if(counter === length){
                                                    linespath.doc(entry.id).delete() 
                                                }
                                            })            
                                        }
                                        })
                                    }                                
                            })
                            }
                            else{
                              linespath.doc(entry.id).delete()  
                            }                            
                        }
                        })
                })
                colpath.get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            
                            colpath.doc(entry.id).delete()
                        }
                        })
                })
            })
            movementcollections.map(col => {
                let colpath = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).collection(col)
                colpath.get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            colpath.doc(entry.id).delete()
                        }
                        })
                })
            })
            financecollections.map(col => {
                let colpath = db.collection(col)
                colpath.get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            colpath.doc(entry.id).delete()
                        }
                        })
                })
            })
            opscollections.map(col => {
                let colpath = db.collection(col)
                colpath.get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            colpath.doc(entry.id).delete()
                        }
                        })
                })
            })
            let articlecolpath = db.collection(this.ArticleProductsEntity.id.split('_').join('').toLowerCase()+'store')
            
            articlecolpath.get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            // db.collection(this.ArticleProductsEntity.id.split('_').join('').toLowerCase()).doc(entry.id).update({                                
                            //     Available: 0,
                            // })
                            let storeentrypath = articlecolpath.doc(entry.id).collection('Warehouses').doc(this.ActiveWarehouse.id)
                            storeentrypath.update({                                
                                Available: 0,
                                Inbound: 0
                            })
                        }
                        })
                })
            db.collection(this.StoreProductsEntity.id.split('_').join('').toLowerCase()).get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            db.collection(this.StoreProductsEntity.id.split('_').join('').toLowerCase()).doc(entry.id).update({                                
                                Available: 0,
                                Average_Price: 0,
                                Backorder: 0,
                                Dispatching: 0,
                                Inbound: 0,
                                Latest_Price: 0,
                                On_Hand: 0,
                                On_Order: 0,
                                Packing: 0,
                                Picking: 0,
                                Received: 0,
                                Total_Cost: 0,
                                Total_Received: 0,
                            })
                        }
                        })
                })
                db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).get().then(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                        if (change.type === 'added') {
                            let entry = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                                let solinespath = db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).doc(entry.id).collection('Cart_Items')
                                solinespath.get().then(solineres => {
                                    let length = solineres.docs.length
                                    if(length === 0){
                                        db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).doc(entry.id).delete() 
                                    }
                                    else{
                                        const solinechanges = solineres.docChanges();
                                        let counter = 0
                                        solinechanges.forEach(solinechange => {
                                        if (solinechange.type === 'added') {
                                            counter = counter-1+2
                                            let solineentry = {
                                            ...solinechange.doc.data(),
                                            id: solinechange.doc.id
                                            }             
                                            solinespath.doc(solineentry.id).delete().then(solinedel => {
                                                if(counter === length){
                                                    db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).doc(entry.id).delete() 
                                                }
                                            })            
                                        }
                                        })
                                    }                                
                            })
                            let intliais = db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).doc(entry.id).collection('InternalLiaison')
                                intliais.get().then(solineres => {
                                    let length = solineres.docs.length
                                    if(length === 0){
                                        db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).doc(entry.id).delete() 
                                    }
                                    else{
                                        const solinechanges = solineres.docChanges();
                                        let counter = 0
                                        solinechanges.forEach(solinechange => {
                                        if (solinechange.type === 'added') {
                                            counter = counter-1+2
                                            let solineentry = {
                                            ...solinechange.doc.data(),
                                            id: solinechange.doc.id
                                            }             
                                            intliais.doc(solineentry.id).delete().then(solinedel => {
                                                if(counter === length){
                                                    db.collection(this.SalesOrderEntity.id.split('_').join('').toLowerCase()).doc(entry.id).delete() 
                                                }
                                            })            
                                        }
                                        })
                                    }                                
                            })
                            
                        }
                        })
                })
            //this.SalesOrderEntity.id.split('_').join(''),
        },
        GenerateWHNumber(){
        const functions = firebase.functions();
          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let payload = {
            Docid: 'Warehouse_Warehouse_Number',
            Data: {},
            siteid: this.$store.state.ActiveSuiteid
          }
          AssignAutoNumber(payload).then(result => {
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).update({
              Warehouse_Number: result.data
              })
          })
    },
        PrepareAddressField(fieldname){
            let field = this.WarehouseHeaders.find(obj => obj.Name === fieldname)
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
        CloseUserDialog(){
            this.editedUser = Object.assign({},this.defaulteditedUser)
            this.UserAccessdialog = false
        },
        AdvanceDialog(user){
            this.editedUser = user
            this.editedUserIndex = this.NonInteractingUsers.indexOf(user)
            this.step++
        },
        GetUserRolesDoc(user){
            return new Promise(function(resolve, reject) {
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(user.id).onSnapshot(snapshot => {
                    let userroledoc = snapshot.data()
                    if(userroledoc.Warehouses){
                        resolve(userroledoc.Warehouses)     
                    }
                    else{
                        resolve({})
                    }                    
                })
            })
        },
        UpdateWarehousePermissions(action){
        //console.log(action,this.editedUser,this.InteractingUsers)
         this.UsersReady = false
         if(!this.editedUser.Warehouses[this.ActiveWarehouse.id].Update){
             this.editedUser.Warehouses[this.ActiveWarehouse.id].Publish = false
         }
         db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.editedUser.id).update({
            Warehouses: this.editedUser.Warehouses
            })
            setTimeout(() => {
                this.UsersReady = true
            }, 50);
        },
        OpenUserDialog(user){
            this.PermissionTab = 0
            this.editedUser = user
            this.editedUserIndex = this.InteractingUsers.indexOf(user)
            this.step = 2
            this.UserAccessdialog = true
            
        },
        OpenNEWUserDialog(){
            
            this.step = 1
            this.editedUser = Object.assign({},this.defaulteditedUser)
            this.editedUserIndex = -1
            this.UserAccessdialog = true
        },
        UpdateWarehouse(){
            if(this.ActiveWarehouse.Store_Entity){
                let entity = this.StoreEntities.find(obj => obj.id === this.ActiveWarehouse.Store_Entity)
                if(entity){
                    let articlefield = entity.AllFields.find(obj => obj.id === 'Inventory_Article')
                    if(articlefield){
                        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).update({
                            Store_Article_Entity: articlefield.RelatedBuildID,
                            Store_Entity: this.ActiveWarehouse.Store_Entity
                        })          
                    }
                }
            }
            if(this.ActiveWarehouse.Sales_Order_Entity){
                db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).update({
                    Sales_Order_Entity: this.ActiveWarehouse.Sales_Order_Entity
                })
            }
        },
        PrepSubCollectionInfo(){
            this.ComputedSubCollections.map((subcol,subcolindex) => {
                this.GetSubCollectionData(subcol.id,subcol.Data,subcol.LookupBuilds)
                if(subcolindex === this.SubCollections.length){
                    this.EntityReady = true
                }
            })
        },
        GetSubCollectionData(subcolarrayname,subcoldata,LookupBuilds){
            console.log('this.EntityDataRef.collection(subcolarrayname)',this.EntityDataRef.collection(subcolarrayname))
        this.EntityDataRef.collection(subcolarrayname).onSnapshot(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let subcolentry = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    subcolentry.LookupObj = {
                        Reference: subcolentry.id,
                        identifier: 'Reference',
                        single: '/WarehouseTransaction/'+this.ActiveWarehouse.id,
                        id: subcolentry.id+'?TransactionType='+subcolarrayname,
                        RoutePath: '/WarehouseTransaction/'+this.ActiveWarehouse.id+'/'+subcolentry.id,
                        RouteQuery: {TransactionType: subcolarrayname}
                    }
                    subcolentry.CanCreate = this.userIsAdmin
                    subcolentry.CanEdit = this.userIsAdmin
                    subcolentry.CanDelete = this.userIsAdmin
                subcoldata.push(subcolentry)
                }
                })
        })
        },
        UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList){
        let fieldobj = this.CurrentEntity.AllFields.find(obj => obj.Name === prop)
        //console.log(prop,fieldobj,this.CurrentEntity,FieldObject)
        if(fieldobj && typeof FieldObject[fieldobj.Name] !== 'undefined' && !prop.includes('Bill_To_Address') && !prop.includes('Delivery_Address')){
          db.collection(this.CurrentEntity.id).doc(this.ActiveWarehouse.id).update({
                [fieldobj.Name]: FieldObject[fieldobj.Name]
            })  
        }
        else if(!fieldobj && prop.includes('Bill_To_Address') && typeof FieldObject[prop] !== 'undefined' || !fieldobj && prop.includes('Delivery_Address') && typeof FieldObject[prop] !== 'undefined'){
            db.collection(this.CurrentEntity.id).doc(this.ActiveWarehouse.id).update({
                [prop]: FieldObject[prop]
            })
        }
        
        },
    }
}
</script>

<style>

</style>
