<template>
<v-main>
<v-card :style="PageBGGradient" class="dbcard" :dark="$vuetify.theme.dark" flat height="100%" >
    <v-dialog v-if="PreviewForm" v-model="WebFormPreviewDialog" width="400">
        <v-card flat tile
                   
                    class="transition-fast-in-fast-out background"
                    
                >
                    <v-card-actions class="pt-0">
                    <v-btn
                        text
                        color="teal accent-4"
                        @click="WebFormPreviewDialog = !WebFormPreviewDialog"
                    >
                        Close
                    </v-btn>
                    </v-card-actions>
                    <v-card-text class="pb-0">
                    <WebFormTab  :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                    :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="''" :rowindex="''"									
                    :tab="RenderedWebFormTab" :i="i"									
                    />
                    </v-card-text>
                    
                </v-card>
    </v-dialog>
    <v-card-title class="recordtoolbar largeoverline white--text">
            Web Forms
        </v-card-title>
    <v-layout row class="transparent justify-start" style="padding:50px;">
        <v-col cols="12" lg="3" md="4" sm="6" xs="12"  v-for="item in ConcatnenatedEntities" :key="item.itemObjKey"> 
        <v-card
            class="mx-1"
            width="350"
        >
            <v-card-text>
            
            <p class="text-h5 configtext--text">
               {{item.DisplayName}}
            </p>
            <div>
            <v-icon>
                {{item.Table_Icon}}
                </v-icon>
             <v-btn
             v-if="item.Entity_Type.Name === 'Web Form'"
                text
                color="teal accent-4"
                @click="ActivateWebFormPreviewDialog(item)"
            >
                Show Form
            </v-btn></div>
            <!-- <p>item.reveal {{item.reveal}}</p> -->
            
            </v-card-text>
            <v-card-text>
                <div v-html="item.Description">
                
            </div>
            </v-card-text>
            <v-card-actions>
           
            <v-spacer></v-spacer>
            <v-btn
                text
                color="teal accent-4"
                :to="item.Entity_Type.Name === 'Web Form' ? '/WebFormBuilder/'+item.id : '/SurveyBuilder/'+item.id"
            >
                Edit
            </v-btn>
            </v-card-actions>

            <!-- 
                Edit @click="item.reveal = !item.reveal"
                <v-expand-transition>
            <v-card
                v-if="item.reveal"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
            >
                <v-card-text class="pb-0">
                <p class="text-h4 text--primary">
                    Origin
                </p>
                <p>late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’ </p>
                </v-card-text>
                <v-card-actions class="pt-0">
                <v-btn
                    text
                    color="teal accent-4"
                    @click="item.reveal = !item.reveal"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-expand-transition> -->
        </v-card>
        </v-col>
    </v-layout>
</v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
export default {
    props: ['SystemEntities','System'],
    components:{
        WebFormTab
    },
    data(){
        return {
            PageBGGradient: 'rgba(255,255,255,0.01); margin-top:60px;margin-bottom:-150px;',
            WebFormEntities: [],
            WebFormPreviewDialog: false,
            PreviewForm: '',
            RenderedWebFormTab: {}
        }
    },
    computed:{
        userLoggedIn () {
        return this.$store.getters.user
        },
        SurveyEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Survey Form'
            })
        },
        ConcatnenatedEntities(){
            return this.RenderedWebFromEntities.concat(this.SurveyEntities)
        },
        RenderedWebFromEntities(){
            return this.WebFormEntities.map(entity => {
                let entityobj = Object.assign({},entity)
                // if(typeof entity.reveal === 'undefined'){
                //     entityobj.reveal = false
                // }
                entityobj.RenderedWebFormTab = entity.WebFormTab
                entityobj.RenderedWebFormTab.FormName = entity.DisplayName
                entityobj.RenderedWebFormTab.RelatedEntity.id = entity.DisplayName.split(' ').join('_')
                entityobj.RenderedWebFormTab.SingleName = entity.SingleName
                if(entity.WebFormType){
                    entityobj.RenderedWebFormTab.WebFormType = entity.WebFormType
                }
                if(entity.ParentEntity){
                    entityobj.RenderedWebFormTab.ParentEntity = entity.ParentEntity
                }
                if(entity.AllFields){
                entityobj.RenderedWebFormTab.FormFields = entity.AllFields.filter(field => {
                        return field.UsedonWebForm
                    }).sort((a, b) => {			
                            // console.log('sorting again')			
                            // console.log(a.Value)			
                            var key1 = b.WebFormIndex			
                            var key2 = a.WebFormIndex			

                            if (key1 > key2) {			
                                return -1;			
                            } else if (key1 == key2) {			
                                return 0;			
                            } else {			
                                return 1;			
                            }			
                    })
                }
                
                entityobj.RenderedWebFormTab.FormFields.map((field,fieldindex) => {
                    field.WebFormIndex = fieldindex
                    if(field.MappedField){
                    let mappedfield = field.MappedField.Name
                    if(!field.MappedField.Primary && field.MappedField.LoggedInUser){
                        //alert('one')
                        field.Response = {
                            Full_Name: this.userLoggedIn.Full_Name,
                            Name: this.userLoggedIn.Name,
                            Surname: this.userLoggedIn.Surname,
                            id: this.userLoggedIn.id
                        } 
                        
                        field.Array = [field.Response]
                    }
                    else if(field.MappedField.LoggedInUserValue){
                        field.Response = this.userLoggedIn[mappedfield]
                        if(field.Type === 'Lookup'){
                            field.Array = [field.Response]
                        }
                    }
                    }
                    //console.log(field)
                    return field
                })
                return entityobj
            })
        }
    },
    created(){
        this.WebFormEntities = this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'
            })
            .map(entity => {
                if(typeof entity.reveal === 'undefined'){
                    entity.reveal = false
                }
                return entity
            })
        //alert('webformbuildercol')
    },
    methods:{
        ActivateWebFormPreviewDialog(item){
            this.PreviewForm  = item
            this.RenderedWebFormTab = item.RenderedWebFormTab
            this.WebFormPreviewDialog = true
        },
        DeactivateWebFormPreviewDialog(item){
            this.WebFormPreviewDialog = false
            this.PreviewForm  = ''
            this.RenderedWebFormTab = {}
            
        }
    }
}
</script>

<style>

</style>