<template>
  <div>
      <v-card v-if="MessageDialog">
         
          <v-card>
            <v-img height="80" src="@/assets/RABaseBG.jpeg">
            <v-list two-line class="transparent">
              <v-list-item>
                
                  <v-list-item-avatar  size="35">
              <v-img :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="MessagetoUser.Full_Name"></v-list-item-title>
                    <v-list-item-subtitle v-text="MessagetoUser.onlinestatus"></v-list-item-subtitle>
                  </v-list-item-content>
            
            <v-spacer>
                </v-spacer>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-phone</v-icon></v-btn>
                  <v-btn v-if="MessagetoUser.Mobile_Number" icon :href="'callto:'+MessagetoUser.Mobile_Number"><v-icon color="light-green accent-3">mdi-video</v-icon></v-btn>
                  <v-btn icon @click="DeactivateUserMessage"><v-icon color="red">mdi-close</v-icon></v-btn>
                  
        
              </v-list-item>
              
            </v-list>
            </v-img>
          </v-card>
            
             <v-list flat id="messagelist">
               <v-list-item v-for="message in ConversationAscending" :key="message.itemObjKey">
                 <v-list flat class="transparent" width="100%">
                   <v-list-item :dark="message.dark">
                 <v-list-item-avatar size="25"  v-if="message.ReadType === 'Received'">
                   <v-img :src="MessagetoUser.Profile_Photo" v-if="MessagetoUser.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!MessagetoUser.Profile_Photo"/>
                 </v-list-item-avatar>
                 <v-list-item-content :class="message.Color">
                   <span style="padding-left:10px;padding-right:1px;" v-html="message.message"></span>
                   
                 </v-list-item-content>
               </v-list-item>
               <v-list-item class="justify-center" v-if="message.Rating">
                 <v-rating
                            v-model="message.Rating" class="justify-center"
                            background-color="amber lighten-1"
                            color="amber lighten-1"
                            readonly
                          ></v-rating>
               </v-list-item>
               <v-list-item>
                 <v-list-item-subtitle style="font-size:10px">
                   {{message.SendTime}}
                 </v-list-item-subtitle>
               </v-list-item>
                 </v-list>
               </v-list-item>            
             </v-list>
             <v-list>
                <v-list-item>
                  
                <span  class="newmessagepost mx-3" role="textbox" contenteditable id="messageinputchip"  @keydown.enter="CreateMessage(ConversationAscending[ConversationAscending.length-1].id,MessagetoUser,GroupData)"  placeholder="Type Message"/>
                <v-icon>mdi-share</v-icon>
               </v-list-item>
                 </v-list>
             
        </v-card>
        <div v-if="!MessageDialog"  style="padding-top:10px;">
          <v-card flat outlined v-if="ConversingUsers && ConversingUsers.length === 0">
            <v-list two-line >
            <v-list-item>
                There are no Messages for this Group
            </v-list-item>
            
            </v-list>
        </v-card>
       <v-card @click="ActivateMessage(msg)" outlined flat style="z-index:1;" v-for="msg in ConversingUsers" :key="msg.itemObjKey">
            <v-list two-line :class="msg.Color">
            <v-list-item>
                
                <v-list-item-avatar  size="35">
                <!-- <v-avatar size="35"> -->
            <v-img :src="msg.Profile_Photo" v-if="msg.Profile_Photo"/>
                <v-img src="@/assets/BlankProfilePic.png" v-if="!msg.Profile_Photo"/>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="msg.Full_Name"></v-list-item-title>
                    <v-list-item-subtitle v-if="msg.IsGroup"><v-chip small>Group</v-chip></v-list-item-subtitle>
                    <v-list-item-subtitle v-if="msg.lastMessage" v-text="msg.lastMessage.message"></v-list-item-subtitle>
                </v-list-item-content>
                    <v-spacer>
                    </v-spacer>
                    <span style="font-size:8px">{{msg.lastMessage.SendTime}}</span>
            </v-list-item>
            
            </v-list>
        </v-card>
        </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','GroupData'],
    components: {},
    data() {
        return {
            MessageDialog: false,
            MessagetoUser: '',
            ReceivedMessages: [],
            SentMessages: [],
        }
    },
    computed:{
        Conversation(){
      let arr1 = this.SentMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.recipientid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.senderid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'background messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      //console.log(arr1)
      return arr1.concat(arr2)
    },
        ConversationAscending(){
      return this.Conversation.map(msg => {
        //console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        UnreadMessages(){
      return this.AllUserConversationsAscending.filter(msg => {
        return !msg.Read === true && msg.ReadType === 'Received'
      })
    },
        MessagesColor(){
      if(this.UnreadMessages.length > 0){
        return 'pop'
      }
      else{
        return 'secondary'
      }
    },
        ConversingUsers(){
      let users = this.UsersArray.filter(user => {
        let recipientcheck = this.AllUserConversationsAscending.find(msg => msg.recipientid == user.id)
        let sendercheck = this.AllUserConversationsAscending.find(msg => msg.senderid == user.id)
        if(sendercheck){
          user.lastMessage = Object.assign({},sendercheck)
          user.SendTime = this.TimestampFormatterSTRING(sendercheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(sendercheck.SendTime)
          return user
        }
        else if(recipientcheck){
          user.lastMessage = Object.assign({},recipientcheck)
          user.SendTime = this.TimestampFormatterSTRING(recipientcheck.Created_On.toDate())
          user.TimestampSort = this.DateFormatterwithTime(recipientcheck.SendTime)
          return user
        }
        
      }).map(user => {
        let sendercheck = this.UnreadMessages.find(msg => msg.senderid == user.id)
       
        if(sendercheck){
          user.Color = ' grey lighten-2'          
        }
        else{
          user.Color = ' white'
        }
        //console.log(user)
        return user
      }).filter(user => {
        return user.id !== this.GroupData.id
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      
      return users
    },
    AllUserConversations(){
      let arr1 = this.SentMessages.map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'background messageblock'
         msg.dark = false
         msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
         msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      //console.log(arr1)
      return arr1.concat(arr2)
    },
        AllUserConversationsAscending(){
      return this.AllUserConversations.map(msg => {
        //console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    AllUserConversationsAscendingGroups(){
      return this.AllUserConversationsAscending.filter(conv => {
        return conv.groupname
      }).map(groupmsg => {
        let obj = {Full_Name: groupmsg.groupname,Profile_Photo: groupmsg.grouplogo,IsGroup: true}
        if(groupmsg.Rating){
            obj.IsGroupRating = msg.IsGroupRating
            obj.Rating = msg.Rating
          }
        if(groupmsg.senderid === this.GroupData.id){
          obj.id = groupmsg.recipientid          
          return obj
        }
        else{
          obj.id = groupmsg.senderid
          return obj
        }        
      })
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
        MessageBoxPadRight(){
        if (this.socialquicknav === true) {
        return 'right: 260px;'
        }
        else{
          return 'right: 0px;'
        }
    },
    },
    created(){
        this.GetSentMessages()
        this.GetReceivedMessages()
    },
    methods:{
        CreateMessage(msgid,userto,userfrom){
        let inputelmnt = document.getElementById('messageinputchip')
        let NewMessage = {
          recipientid: userto.id,
          senderid: this.GroupData.id,
          groupid: this.GroupData.id,
          message: inputelmnt.innerHTML,
          Created_On: new Date(),
          Read: false,
          GroupSender: true,
          grouplogo: this.GroupData.logo,
          groupname: this.GroupData.name,
          GroupMember: this.userLoggedIn.id,
          Replytoid: msgid,
        }
        
        db.collection('usermessages').add(NewMessage)
        inputelmnt.innerHTML = ''
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            objDiv.scrollTop = objDiv.scrollHeight;
            
        }, 50);
      },
        UpdateMessageasRead(msg){
        db.collection('usermessages').doc(msg.id).update({
          Read: true
        })
      },
        DateFormatterwithTime(date){
      if(date){
        // console.log(date)
        // console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},

TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
        GetSentMessages(){
        db.collection('usermessages').where('GroupSender','==',true).where('groupid','==',this.GroupData.id).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.SentMessages.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }  
        })
        //console.log('this.SentMessages',this.SentMessages,this.AllUserConversations,this.AllUserConversationsAscending)
      })      
      },
      GetReceivedMessages(){
        db.collection('usermessages').where('GroupSender','==',false).where('groupid','==',this.GroupData.id).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.ReceivedMessages.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }  
          if (change.type === 'modified') {
          //console.log('here is change')
          //console.log(change.doc.data())
          let msgdata = change.doc.data()
          msgdata.id = change.doc.id
          let msgobj = this.ReceivedMessages.find(obj => obj.id == change.doc.id)
          let msgindex = this.ReceivedMessages.indexOf(msgobj)
          if(this.ReceivedMessages[msgindex].Read !== msgdata.Read){
            this.ReceivedMessages[msgindex] = msgdata
          }
          
        }
        })
      })      
      },
      DeactivateUserMessage(){
        this.MessageDialog = false
        this.MessagetoUser = ''
        this.socialquicknav = true
      },
        ActivateMessage(user){
        this.socialquicknav = false
        this.MessageDialog = true
        this.MessagetoUser = user
        setTimeout(() => {
            var objDiv = document.getElementById("messagelist");
            //console.log(objDiv,objDiv.scrollHeight)
            objDiv.scrollTop = objDiv.scrollHeight;
            this.ReceivedMessages.map(msg => {
              if(msg.ReadType === 'Received' && msg.Read === false){
                //this.UpdateMessageasRead(msg)
              }
            })
            document.querySelector(".newmessagepost[contenteditable]").addEventListener("paste", function(e) {
              e.preventDefault();
              var text = "";
              if (e.clipboardData && e.clipboardData.getData) {
                text = e.clipboardData.getData("text/plain");
              } else if (window.clipboardData && window.clipboardData.getData) {
                text = window.clipboardData.getData("Text");
              }
              document.execCommand("insertHTML", false, text);
            });
        }, 200);
      },
    }
}
</script>

<style>

</style>
