<template>
  <v-row
      
      align="center"
      >
      <v-card outlined class="mx-1 my-1" width="100%">
        <v-card-title >
          <v-list dense :class="!PostsbyApproval || PostsbyApproval && post.Approved ? 'transparent' : 'transparent'" width="100%">
            <v-list-item v-if="PostsbyApproval && !post.Approved && UserisGroupAdmin">
             <v-chip @click="ApprovegroupPost(post)" small color="success" class="white--text" v-on="on" v-if="UserRecord.id">
                  Approve<v-icon small>mdi-check</v-icon>
                </v-chip>
            </v-list-item>
          <v-list-item>
          <v-list-item-avatar>
              <img v-if="post.creatorimg"
                :src="post.creatorimg"
                :alt="post.creatorname"
                style="object-fit: cover;"
            >
            <img v-if="!post.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="post.creatorname"
            >
            
          </v-list-item-avatar>
             <v-list-item-content style="font-size:10px" v-if="post.Creatorid !== post.WallID && UserorGroupID !== post.WallID && post.WallType === 'Group'">
              <span><b><router-link :to="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</router-link></b> on <v-chip small :to="'/'+post.WallType+'/'+post.WallID">{{post.ShareName}}</v-chip></span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.Creatorid !== post.WallID && UserorGroupID !== post.WallID && post.WallType === 'SiteMember'">
              <span><b><router-link :to="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</router-link></b> on <router-link :to="'/'+post.WallType+'/'+post.WallID">{{post.ShareName}}</router-link>'s Wall</span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.Creatorid === post.WallID || UserorGroupID === post.WallID">
              <span><b><router-link :to="'/SiteMember/'+post.Creatorid">{{post.creatorname}}</router-link></b></span><span v-if="post.Meetuptitle"> while at <b><router-link :to="post.CheckedInMeetupRoute">{{post.Meetuptitle}}</router-link></b></span>
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="post.createdon">
              <span v-if="post.createdon.toDate">{{TimestampFormatterSTRING(post.createdon.toDate())}}</span>
              <span v-else>{{post.createdon}}</span>
            </v-list-item-content>
            <v-list-item-action v-if="userLoggedIn.id === post.WallID || userLoggedIn.id === post.Creatorid || UserMemberObj && UserMemberObj.Administrator">
              <v-menu v-model="post.DeletePostDialog" max-width="300">
               <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab icon>
                <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
               </template>
               <v-card>
                 <v-card-text>
                   Are you sure you want to delete this post entirely?
                 </v-card-text>
                 <v-card-actions>
                   <v-btn dark plain outlined class="warning" @click="post.DeletePostDialog = !post.DeletePostDialog">Cancel</v-btn>
                   <v-spacer></v-spacer>
                   <v-btn dark plain outlined class="error" @click="DeletePost(post)">Delete</v-btn>
                 </v-card-actions>
               </v-card>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          
        
           <v-list-item v-if="post.IsLink && post.InternalLink && post.ComponentName && !SharedPost">
               
             <SocialPostSharedItem :LinkID="post.LinkID" :collectionname="post.collectionname" :UserRecord="UserRecord" :Location="post.InternalLinkType"
              />
            </v-list-item>
          <v-list-item >
          <v-list-item-content  v-html="post.post" style="font-size:12px">
              
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="post.Images" class="black">
            <!-- :aspect-ratio="post.Images[0].DisplayRatio.Ratio"
              :max-width="450/post.Images[0].DisplayRatio.Ratio" -->
            <v-img
              :max-height="post.Images.length === 1 ? WindowHeight/1.6 : '335'"
              contain
              :width="post.Images.length === 1 ? '100%' : 150/post.Images[0].DisplayRatio.Ratio"
               @click="OpenCarousel(post,'Start')"
              :src="post.Images[0].ThumbURL"                   
              
                  />
                  <v-row class="mx-1">
                    <v-col
                      v-for="(card,i) in post.Images.slice(1,ImagesSlice)"
                      :key="card.itemObjKey"
                      class="d-flex child-flex"
                      :cols="GetImageColumns(post.Images.slice(1,ImagesSlice))"
                    >
                      <v-img
                        :src="card.ThumbURL"
                        :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                         @click="OpenCarousel(post,i)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
           <!-- <div class="layout justify-start row mx-0">
             
             <div :class="'col col-'+GetImageColumns(post.Images.slice(1,ImagesSlice))" v-for="(card,i) in post.Images.slice(1,ImagesSlice)"
                :key="card.itemObjKey" 
                :cols="ImagesColumns">
            
                  <v-img @click="OpenCarousel(post,i)"
                    :src="card.ThumbURL"                   
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                   
                  </v-img>
             </div>
           </div> -->
          </v-list-item>
      </v-list> 
        </v-card-title>
        <!-- HERE STARTS WHAT SHOULD BECOME LIKES AND COMMENTS COMP -->
              <!-- </v-card-actions> -->
              
             <SocialCommentsandLikes v-if="!SharedPost" :FromSocialPosts="true" :PathName="'SitePosts'"
             :CanSocial="UserCanSocialTopic" :notificationitem="'Post'" :RelatedType="'Site Post'"
             :SocialItem="post" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
             
           
                  <!-- HERE ENDS WHAT SHOULD BECOME LIKES AND COMMENTS COMP -->
                     
                   
              
            </v-card>  
            </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SocialPostSharedItem from '@/components/SocialNetwork/SocialPostSharedItem';
import SocialCommentsandLikes from '@/components/SocialNetwork/SocialCommentsandLikes';
export default {
    props: ['System','SystemEntities','post','UserRecord','PostsbyApproval','WindowHeight','UserMemberObj','SharedPost'],
    components: {SocialPostSharedItem,SocialCommentsandLikes},
    data() {
        return {

        }
    },
    computed:{
        userLoggedIn () {
      return this.$store.getters.user
    },
    },
    created(){

    },
    methods:{
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        // console.log(finaldate)
        return finaldate
      },
        ApprovegroupPost(post){
            this.$emit('ApprovegroupPost',post)
        },
        DeletePost(post){
            this.$emit('DeletePost',post)
        },
        OpenCarousel(post,position){
            this.$emit('OpenCarousel',post,position)
        },
        GetImageColumns(prop){
            this.$emit('ApprovegroupPost',prop)
        },
    }
}
</script>

<style>

</style>
