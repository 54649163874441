<template>
  <span v-if="navitem.Field && navitem.Range">
    <v-list-item class="overline">
    {{navitem.Title}} <v-icon  v-if="$route.name === 'DirectoryBuilder'" @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon>
    
    </v-list-item>
    <v-list-item>
        
        <v-range-slider    
         @change="ActivateFilter(navitem)"    
        v-model="navitem.Range"
        :max="navitem.Max"
        :min="navitem.Min"
        hide-details
        class="align-center"
    >
        <template v-slot:prepend>
        <span class="caption">
            {{navitem.Range[0]}}</span>
        </template>
        <template v-slot:append>
        <span class="caption">
            {{navitem.Range[1]}}</span>
        </template>
        </v-range-slider>
    </v-list-item>
        <!-- SLIDER FILTER - NUMBER --></span>
</template>

<script>
export default {
    props: ['navitem','navitemindex'],
    components: {

    },
    data(){
        return {

        }
    },
    created(){

    },
    computed:{

    },
    methods: {
        ActivateFilter(filter){
            //this.$emit('ActivateFilter',filter)
        },
        EditNavItem(navitem,navitemindex){
            this.$emit('EditNavItem',navitem,navitemindex)
        },
        
    }
}
</script>

<style>

</style>