
  <template>
  <v-row
    class="fill-height ma-0 cloudsbgcolor"
    align="center"
    justify="center">
    <div class="moving-clouds" 
        :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
        <div class="moving-clouds2" 
        style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>

        <v-card
            class="mx-auto"
            max-width="800"
            min-width="500"            
            >
            <v-img
            
            src="@/assets/RABaseBG-Soft.png"
            width="500">
            <v-card-title>Expired Link - {{LinkedfromObj.Type}}
            </v-card-title>
            <v-card-text>
                The link you are trying to view has already Expired on {{LinkedfromObj.ExpiryDate}}
            </v-card-text>
            <v-card-text v-if="!LinkedfromObj.CanAction">
                Please ask the User that shared the link to share it again
            </v-card-text>
            <v-card-text v-if="LinkedfromObj.CanAction">
                All hope is not lost...
                <span v-html="LinkedfromObj.ActionText">
                </span>
            </v-card-text>
            <v-card-actions  v-if="LinkedfromObj.CanAction">
               <v-spacer></v-spacer> <v-btn color="pop" dark @click="RoutetoInitiateExpiryLift()">Go</v-btn>
            </v-card-actions>
            </v-img>
            </v-card>      
    </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'


  export default {
    props: ['LinkedfromObj'],
    data() {
      return {
        rules: {
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
        step: 1,
        email: '',
        password: '',
        usertest: ''
      }
    },
     computed: {
       
    },

    watch: {
      
    },
    created(){
        console.log(this.LinkedfromObj)
    },
    methods: {
      
        RoutetoInitiateExpiryLift(){
            this.$router.push(this.LinkedfromObj.ActionLink)
        },
  }
    }



</script>

<style scoped>
.signinpad {
  padding: 5%
}
  .login {
    margin-top: 40px;
    color: black
  }
  .logincard {
    padding: 10%
  }
  input {
    margin: 10px 0;
    width: 100%;
    padding: 15px;
  }
  button {
    margin-top: 20px;
    width: 10%;
    cursor: pointer;
  }
  p {
    margin-top: 40px;
    font-size: 13px;
  }
  p a {
    text-decoration: underline;
    cursor: pointer;
  }
  .social-button {
    width: 75px;
    background: white;
    padding: 10px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    outline: 0;
    border: 0;
  }
  .social-button:active {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }
  .social-button img {
    width: 100%;
  }
.stickytopconscious{
  top: 100px
}
</style>

  