
<template>
  <div row :class="LargeView ? tab.Justify+' row' : tab.Justify">
    <v-col :cols="LargeView ? 4 : 12" :id="'InfographicSelected_'+tab.ElementID" v-if="tab.ListPosition === 'Right'">
      <HTMLBox
        v-if="ActiveElement"
        :PageHeadersFont="PageHeadersFont"
        :PageContentFont="PageContentFont"
        :PageHeadersFontSize="PageHeadersFontSize"
        :i="ActiveElementIndex"
        :IsElement="true"
        :tabindex="i"
        :rowindex="rowindex"
        :tab="ActiveElement"
        @OpenDialog="OpenDialog"
      />
    </v-col>
    <v-col :cols="LargeView ? 7 : 12">
      <div
        v-for="(elmnt, elmntindex) in tab.Elements"
        :key="elmnt.itemObjKey"
        style="padding-bottom: 20px"
      >
        <v-card rounded>
          <v-list-item three-line @click="ActivateActiveElement(elmntindex)">
            <v-list-item-avatar tile size="120" color="grey"
              ><v-img :src="elmnt.IMG"
            /></v-list-item-avatar>
            <v-list-item-content v-if="elmnt.IMGHasTitle">
              <div
                 :style="MiniView ? IMGTitleStyle(elmnt) + 'z-index: 2;position: absolute;transform: scale(0.5);' : IMGTitleStyle(elmnt) + 'z-index: 2;position: absolute;'"
              >
                {{ elmnt.IMGTitle }}
              </div>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title  :style="HeaderFontFamily(elmnt,elmnt.HeaderFontFamily)+'text-align: -webkit-left;'">
                {{ elmnt.Title }}
              </v-list-item-title>
              <v-list-item-subtitle style="text-align: -webkit-left;"
                v-html="elmnt.Description"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-col>
     <v-col :cols="LargeView ? 4 : 12" :id="'InfographicSelected_'+tab.ElementID" v-if="tab.ListPosition === 'Left'">
      <HTMLBox
        v-if="ActiveElement"
        :PageHeadersFont="PageHeadersFont"
        :PageContentFont="PageContentFont"
        :PageHeadersFontSize="PageHeadersFontSize"
        :i="ActiveElementIndex"
        :IsElement="true"
        :tabindex="i"
        :rowindex="rowindex"
        :tab="ActiveElement"
        @OpenDialog="OpenDialog"
      />
    </v-col>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";
import ImageRenderComp from "@/components/WebPages/RenderComponents/ImageRenderComp";
import HTMLBox from "@/components/WebPages/RenderComponents/HTMLBoxReadOnly";
export default {
  props: [
    "AppisDarkMode",
    "tab",
    "PageHeadersFont",
    "PageContentFont",
    "PageHeadersFontSize",
    "i",
    "IsElement",
    "tabindex",
    "rowindex",
    "PageName",
    "PageID",
  ],
  components: {
    ImageRenderComp,
    HTMLBox,
  },
  data() {
    return {
      ActiveElementIndex: 0,
      WebFormDialogFancy: false,
      WebFormDialogNonFancy: false,
      UserRecord: "",
      rules: {
        youtubeurl: (value) => {
          const pattern =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
          return pattern.test(value) || "Not a Youtube Video Link.";
        },
        min8Chars: (value) => value.length >= 8 || "Min. 8 characters",
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const urlpattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          );
          return urlpattern.test(value) || "Invalid Link.";
        },
      },
    };
  },
  computed: {
    LargeView(){
      return this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg
    },
    ActiveElement() {
      return this.tab.Elements[this.ActiveElementIndex];
    },
    MiniView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    DefaultPageFont() {
      return `font-family: "` + this.PageContentFont + `", sans-serif;`;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
  },
  created() {},

  methods: {
    ActivateActiveElement(elmntindex) {
      var myDiv = document.getElementById('InfographicSelected_'+this.tab.ElementID);
      let elmnttop = myDiv.getBoundingClientRect().top
      if(elmnttop<0){
        myDiv.scrollIntoView()
      }
      this.ActiveElementIndex = elmntindex;
    },
    IMGTitleStyle(elmnt) {
      //IMGHasTitle - IMGTitle - IMGTitleColor - IMGTitleFontSize - IMGBorderRadius - IMGTitleStyle
      let style = "";
      if (elmnt.IMGTitleColor) {
        style = style + "color: " + elmnt.IMGTitleColor.hexa + ";";
      }
      if (elmnt.IMGBorderRadius) {
        style = style + "border-radius: " + elmnt.IMGBorderRadius + "px;";
      }
      if (elmnt.IMGTitleBGColor) {
        style = style + "background: " + elmnt.IMGTitleBGColor.hexa + ";";
      }
      if (elmnt.IMGTitleFontSize) {
        style = style + "font-size: " + elmnt.IMGTitleFontSize + "px;";
      }
      if (elmnt.IMGTitleStyle) {
        //height: 130px;line-height: 130px;width: 130px;text-align: center;
        style = style + elmnt.IMGTitleStyle;
      }
      return style;
    },
    ActivateElementConfigComp(compname, tab, i) {
      this.$store.commit("setActiveElmntConfigComp", compname);
      this.OpenDialog(tab, i);
    },
    BaseTitleStyle(tab) {
      return tab.HeaderBlendMode
        ? "mix-blend-mode: " + tab.HeaderBlendMode + ";" + this.TitleStyle(tab)
        : this.TitleStyle(tab);
    },
    ArticleColStyle(tab, boxwrap) {
      let style = "";

      if (boxwrap && tab.HasArticleCols && !this.MiniView && tab.ColsWrapBox) {
        style = "columns:" + tab.TotalArticleCols + ";column-gap:3rem;";
        if (tab.ColsBorder) {
          style =
            style +
            "border: " +
            tab.ColsBorderWidth +
            "px solid " +
            tab.ColsBorderColor.hexa +
            ";border-radius: " +
            tab.ColsBorderRadius +
            "px;";
          style = style + "padding:2em;";
        } else {
          //0.65 looks real magazine style have to keep in consideration
          //2 looks super cool as well dunno
          style = style + "padding:1em;";
        }
      } else if (
        !boxwrap &&
        tab.HasArticleCols &&
        !this.MiniView &&
        !tab.ColsWrapBox
      ) {
        style =
          "padding:2em;columns:" + tab.TotalArticleCols + ";column-gap:3rem;";
        if (tab.ColsBorder) {
          style =
            style +
            "border: " +
            tab.ColsBorderWidth +
            "px solid " +
            tab.ColsBorderColor.hexa +
            ";border-radius: " +
            tab.ColsBorderRadius +
            "px;";
        }
      }
      return style;
    },
    TabFontStyle(tab) {
      return tab.FontBGColor && !tab.FontUnderline
        ? this.MiniView
          ? "background-color: " + tab.FontBGColor + ";width: 80%;"
          : "background-color: " + tab.FontBGColor + ";width: 50%;"
        : tab.FontUnderline && tab.FontUnderlineColor
        ? "background-image: linear-gradient(180deg," +
          tab.FontUnderlineColor +
          "," +
          tab.FontUnderlineColor +
          ");background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;"
        : "";
    },
    TitleStyle(tab) {
      // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
      if (tab.HeaderBGColor) {
        return tab.Center
          ? "background-color: " +
              tab.HeaderBGColor.hexa +
              ";padding-bottom: 20px;text-align: center;color:" +
              tab.HeaderColor.hex +
              ";" +
              this.HeaderFontFamily(tab, tab.HeaderFontFamily)
          : "background-color: " +
              tab.HeaderBGColor +
              ";padding-bottom: 20px;color:" +
              tab.HeaderColor.hex +
              ";" +
              this.HeaderFontFamily(tab, tab.HeaderFontFamily);
      } else {
        return tab.Center
          ? "padding-bottom: 20px;text-align: center;color:" +
              tab.HeaderColor.hex +
              ";" +
              this.HeaderFontFamily(tab, tab.HeaderFontFamily)
          : "padding-bottom: 20px;color:" +
              tab.HeaderColor.hex +
              ";" +
              this.HeaderFontFamily(tab, tab.HeaderFontFamily);
      }
    },
    BoxStyle(tab) {
      if (tab.FullHeight) {
        return (
          "width: 100%;padding:20px;margin-top:" +
          tab.MarginTop +
          "px;margin-bottom:" +
          tab.MarginBottom +
          "px;margin-left:" +
          tab.MarginLeft +
          "px;margin-right:" +
          tab.MarginRight +
          "px;height: 100%;"
        );
      } else {
        return (
          "width: 100%;padding:20px;margin-top:" +
          tab.MarginTop +
          "px;margin-bottom:" +
          tab.MarginBottom +
          "px;margin-left:" +
          tab.MarginLeft +
          "px;margin-right:" +
          tab.MarginRight +
          "px"
        );
      }
    },
    OpenDialog(
      tab,
      tabindex,
      IsElement,
      CustomBoxTabIndex,
      isrow,
      rowindex,
      ContentDialog
    ) {
      //  console.log(this.rowindex)
      //  console.log(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog)
      //only for conentdialog tab,tabindex,'','','','',true
      if (this.rowindex > -1) {
        this.$emit(
          "OpenDialog",
          tab,
          tabindex,
          true,
          this.tabindex,
          true,
          this.rowindex,
          ContentDialog
        );
      } else if (ContentDialog) {
        this.$emit(
          "OpenDialog",
          tab,
          tabindex,
          this.IsElement,
          this.tabindex,
          "",
          "",
          ContentDialog
        );
      } else {
        this.$emit(
          "OpenDialog",
          tab,
          tabindex,
          this.IsElement,
          this.tabindex,
          "",
          "",
          ContentDialog
        );
      }
    },
    CheckifClipped(tab) {
      if (tab.Clipped) {
        if (tab.ClipAnimated) {
          if (tab.ClipAnimation === "Spotlight Search") {
            return (
              `animation: spotlightsearch 3s ` + tab.CircleAnimationRepeat + `;`
            );
          }
        } else if (tab.ClippedbyPath) {
          if (tab.ClippedPathOption) {
            if (tab.ClippedPathOption === "elipse") {
              return (
                `clip-path: ellipse(` +
                tab.ElipseX +
                ` ` +
                tab.EliseY +
                ` at ` +
                tab.ElipseXPosition +
                ` ` +
                tab.ElipseYPosition +
                `);`
              );
            }
            if (tab.ClippedPathOption === "circle") {
              return (
                `clip-path: circle(` +
                tab.CircleRadius +
                ` at ` +
                tab.CircleXPosition +
                ` ` +
                tab.CircleYPosition +
                `);`
              );
            }
            if (tab.ClippedPathOption === "polygon") {
              return (
                `clip-path: polygon(` +
                tab.TopLeftCrnrX +
                ` ` +
                tab.TopLeftCrnrY +
                `, ` +
                tab.TopRightCrnrX +
                ` ` +
                tab.TopRightCrnrY +
                `, ` +
                tab.BottomRightCrnrX +
                ` ` +
                tab.BottomRightCrnrY +
                `, ` +
                tab.BottomLeftCrnrX +
                ` ` +
                tab.BottomLeftCrnrY +
                `);`
              );
            }
            // if(tab.ClippedPathOption === 'beizer'){
            //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);`
            //     d="M 0,1
            //          L 0,0
            //          L 1,0
            //          L 1,1
            //          C .65 .8, .35 .8, 0 1
            //          Z"
            // }
          }
        } else if (tab.ClippingIMG) {
          return (
            `-webkit-mask-image: url(` +
            tab.ClippingIMG +
            `);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`
          );
        }
      }
    },
    HeaderFontFamily(tab, tabHeaderFontFamily) {
      if (this.PageHeadersFont) {
        if (!tab.HeaderFontSize) {
          return (
            "font-family: " +
            this.PageHeadersFont +
            ", sans-serif;font-size:50px;"
          );
        } else {
          if (tabHeaderFontFamily) {
            return (
              "font-family: " +
              tabHeaderFontFamily +
              ", sans-serif;font-size:" +
              tab.HeaderFontSize +
              "px;"
            );
          } else {
            return (
              "font-family: " +
              this.PageHeadersFont +
              ", sans-serif;font-size:" +
              tab.HeaderFontSize +
              "px;"
            );
          }
        }
      }
      console.log(tab, tabHeaderFontFamily);
    },
  },
};
</script>

<style>
.magartallp p::first-letter {
  margin: 1% 3% -2px 0;
  padding: 0 5% 0;
  font-size: 3em;
  line-height: 1;
  float: left;
  color: #f0f0f0;
  font-weight: 700;
  background: #ff6633;
  /* background: inherit; */
  vertical-align: bottom;
}
.magartfirstp p:first-of-type::first-letter {
  margin: 1% 3% -2px 0;
  padding: 0 5% 0;
  font-size: 3em;
  line-height: 1.5;
  float: left;
  color: #f0f0f0;
  font-weight: 700;
  background: #ff6633;
  /* background: inherit; */
  vertical-align: bottom;
}
</style>