
<template>
    <div>
        <v-dialog transition="fab-transition" v-if="NewParentPrimary" v-model="AddNewParentLookupDialog" width="100%">
      <v-layout class="justify-center">
    <v-card width="100%" class="stickytopbanner">
      <v-card-title>
        Add New {{ParentTitle}}
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              Serial Number
            </v-list-item-content>
            <v-list-item-content>
                <v-text-field style="font-size:14px;color:grey;" dense v-model="NewParentPrimary.Value" :label="NewParentPrimary.Name">
                </v-text-field>
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
            <v-list-item-content v-for="field in NewParentAdditional.slice(0,2)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="NewParentAdditional.length>3">
            <v-list-item-content v-for="field in NewParentAdditional.slice(2,4)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
             <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="NewParentAdditional.length>5">
            <v-list-item-content v-for="field in NewParentAdditional.slice(4,6)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="NewParentAdditional.length>7">
            <v-list-item-content v-for="field in NewParentAdditional.slice(6,8)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-layout class="justify-center" style="font-size:12px;color:grey;">
        This will create the {{ParentTitle}} and link it to {{Job_Number}} as a Parent 
      </v-layout>
      <v-card-actions>
        <v-layout class="justify-end">
          <v-btn @click="CancelAddParentDialog()" color="red" dark>Cancel</v-btn><v-btn  color="green" dark @click="AddParentandLink()">Save</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
      </v-layout>
    </v-dialog>
    <v-dialog :width="MobileView ? '100%' : '80%'" v-model="ViewExistingLookupDialog" persistent v-if="RelatedObj">
      <v-card flat width="100%" height="100%">
        <v-card-title class="headline primary white--text">Lookup Records - <h3 class="headline primary pop--text">{{RelatedObj.Link}}</h3></v-card-title>
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item :style="MobileView ? 'height: 20px;' : 'height: 40px;'">
                        <v-list-item-content v-if="!MobileView">               
                      <strong>Array:</strong>
                      </v-list-item-content>
                      <v-list-item-content>            
                      <v-combobox dense v-model="LookupObj" :readonly="SubCollectionLookupArrays.length <= 1"
                      @change="SeeLookupObj()" :filled="!MobileView" label="Lookup" autocomplete="off" :items="SubCollectionLookupArrays" item-text="ArrayName" return-object></v-combobox>  
                      </v-list-item-content>
                     </v-list-item>
                   <v-list-item :style="MobileView ? 'height: 20px;' : 'height: 40px;'" v-if="MailRecordFilter !== 'Users'">
                      <v-list-item-content v-if="!MobileView">               
                      <strong>Record:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox dense v-model="RelatedObj" readonly  :filled="!MobileView" label="Record" autocomplete="off" :items="RelatedObjArray" :item-text="RelatedObjidentifier"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                    <v-list-item :style="MobileView ? 'height: 20px;' : 'height: 40px;'" v-if="LookupObj && LookupObj.HasBusinessUnit">
                      <v-list-item-content v-if="!MobileView">               
                      <strong>Business Unit:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="BusinessUnit" return-object label="Business Unit" :items="BusinessUnitsArray" :item-text="'Name'"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item :style="MobileView ? 'height: 20px;' : 'height: 40px;'" v-if="LookupObj && LookupObj.HasWarehouse">
                      <v-list-item-content v-if="!MobileView">               
                      <strong>Warehouse:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="Warehouse" clearable return-object label="Warehouse" :items="FilteredWarhousesArray" :item-text="'Name'"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item :style="MobileView ? 'height: 20px;' : 'height: 40px;'" v-if="QuickListArticles && QuickListArticles.length > 0">
                     <!-- just a test really. but if articletablefield then on create I need to call the article table, and see if there are any "list" items in ther. query by them.
                     This way now as well, I could propogate this list in here. 
                     There is however an issue.
                     If we select a list, the table becomes that list. But
                     1. here we check if the list is "complete" referring tot eh items configured in the list
                     2. we need to perform a new "save" method, which would push all items. Or I mean, "pre emit" method, pushing all items part of the list as "selected" -->
                      <v-list-item-content>               
                      <strong>BOM Items:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox clearable v-model="selectedArticleQuickList" return-object label="BOM Item" :items="QuickListArticles" :item-text="LookupObj.ArticleTableField.LookupFieldName"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                  
                  </v-list>
                   </v-layout>
                  <v-layout class="justify-center">
                  <v-card width ="80%" outlined flat v-if="!selectedArticleQuickList">
                    <v-data-table
                      :headers="LookupObj.ArrayHeaders"
                      :items="LookupArraySearched"
                      class="elevation-1"
                      show-select
                        v-model="SelectedRecords"
                    >
                      <template v-slot:top>
                    <v-toolbar flat color="white">
                      <v-toolbar-title>{{FieldFilter}} records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field light
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox
                          :value="isSelected"
                          :readonly="item.disabled"
                          :disabled="item.disabled"
                          @input="select($event)"
                        ></v-simple-checkbox>
                      </template>
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>
                                     
                    </v-card>
                    <v-card width ="80%" outlined flat v-if="selectedArticleQuickList">
                    <v-data-table
                      :headers="LookupObj.ArrayHeaders"
                      :items="selectedArticleQuickListItems"
                      class="elevation-1"
                      show-select
                        v-model="SelectedRecords"
                    >
                      <template v-slot:top>
                    <v-toolbar flat color="white">
                      <v-toolbar-title>{{FieldFilter}} records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field light
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox
                          :value="isSelected"
                          :readonly="item.disabled"
                          :disabled="item.disabled"
                          @input="select($event)"
                        ></v-simple-checkbox>
                      </template>
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>
                                     
                    </v-card>
                </v-layout>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSelection()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="LinkParent()">OK <v-icon>mdi-check</v-icon></v-btn>
                </v-layout>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['SubCollectionLookupArrays','ParentCollection','ParentTitle','ParentLookup','LookupArrayHeaders',
    'LookupArray','FieldFilter','ViewExistingLookupDialog','RelatedObj','RelatedObjidentifier',
    'AddNewParentLookupDialog','NewParentPrimary','NewParentAdditional','SystemEntities'],
    data() {
        return {
            selectedArticleQuickList: '',
            QuickListArticles: [],
            RelatedRecordFilter: '',
            LookupObj: {ArrayData: []},
            SelectedRecords: [],
            search: '',
            BusinessUnit: '',
            Warehouse: '',
        }
    },

    created(){
      this.BusinessUnit = this.BusinessUnitsArray.find(obj => obj.id === this.userLoggedIn.Business_Unitid)
      if(this.SubCollectionLookupArrays[0]){
        this.LookupObj = this.SubCollectionLookupArrays[0]
        // console.log(this.LookupObj)
        if(this.LookupObj.HasWarehouse){
          let ent = this.SystemEntities.find(obj => obj.id === this.LookupObj.ArrayName)
          //console.log(ent)
          if(ent && ent.Primary_Appid){
            let app = this.AppsDataBase.find(obj => obj.id === ent.Primary_Appid)
            if(app && app.Default_Warehouseid){
            this.Warehouse = this.WarehousesArray.find(obj => obj.id === app.Default_Warehouseid)
            }
          }
        }
      }
      
    },

    computed: {
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      selectedArticleQuickListItems(){
        return this.selectedArticleQuickList.BOM_Items.map(item => {
          let match = this.LookupArrayFiltered.find(obj => obj.Inventory_Article.id === item.LookupObj.id)
          let newobject = Object.assign({},match)
          newobject.Qty = item.Qty
          if(match.Available < item.Qty){
            newobject.disabled = true
          }
          newobject.IsBOMQty = true
          return newobject
        })
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userBUID () {
        return this.$store.getters.userBUID
      },
      userBUChildren () {
        return this.$store.getters.userBUChildren
      },
      userBUParents () {
        return this.$store.getters.userBUParents
      },
      BusinessUnitsArray(){
        return this.$store.state.BusinessUnitsArray
      },
      WarehousesArray(){
        return this.$store.state.WarehousesArray
      },
      FilteredWarhousesArray(){
        return this.WarehousesArray.filter(wh => {
          return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Allocating
        })
      },
      LookupArrayFiltered(){
        return this.LookupObj.ArrayData.filter(item => {
              if(this.BusinessUnit && this.LookupObj && this.LookupObj.HasBusinessUnit){
                return item.Business_Unit && item.Business_Unitid === this.BusinessUnit.id
              }
              else{
                return item
              }
            }).filter(item => {
              if(this.Warehouse && this.LookupObj && this.LookupObj.HasWarehouse){
                return item.Warehouse && item.Warehouseid === this.Warehouse.id
              }
              else{
                return item
              }
            }).filter(item => {
              return this.LookupObj.HasWarehouse ? this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[item.Warehouseid] && this.userLoggedIn.Warehouses[item.Warehouseid].Allocating : item
            })
      },
        LookupArraySearched(){
            return this.LookupArrayFiltered.filter(item => {
                if(this.LookupObj && this.LookupObj.identifier && this.search){
                    console.log(this.search,this.LookupObj.identifier,item[this.LookupObj.identifier])
                    return item[this.LookupObj.identifier].toLowerCase().includes(this.search.toLowerCase())
                }
                else{
                    return item
                   
                }
            })
        }
    },

    methods: {
     
        GetArticleQuickListItems(query){
          query.where('Item_Type.Name','==','QuickList').onSnapshot(res => {
            //alert(res.docs.length)
          const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  let quicklistitem = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                  quicklistitem.BOM_Items = []
                  query.doc(quicklistitem.id).collection('BOM_Items').onSnapshot(bomres => {
                    let bomlength = bomres.docs.length
                    //alert(bomlength)
                    const bomchanges = bomres.docChanges();
                    bomchanges.forEach(bomchange => {
                      if (bomchange.type === 'added') {
                        quicklistitem.BOM_Items.push({
                          ...bomchange.doc.data(),
                          id: bomchange.doc.id
                        })
                      }
                      if(quicklistitem.BOM_Items.length === bomlength){
                        this.QuickListArticles.push(quicklistitem)
                      }
                  })
                  })
                }
              })
        })
        },
        SeeLookupObj(){
            console.log(this.LookupObj)
            if(this.LookupObj && this.LookupObj.ArticleTableField){
              console.log('this.LookupObj.ArticleTableField',this.LookupObj.ArticleTableField)
              let collectionname = this.LookupObj.ArticleTableField.RelatedBuildID.split('_').join('').toLowerCase()
              console.log('collectionname',collectionname)
              this.GetArticleQuickListItems(db.collection(collectionname))
            }
        },
        LinkParent(){
            // let field = this.ParentLookup
            // field.Value = this.SelectedRecords[0]
            // console.log(this.SelectedRecords[0])
            // let ParentObj = {                
            // }
            // ParentObj[field.LookupFieldName] = {[field.Prop]: field.Value[field.Prop],id: field.Value.id}
            // ParentObj[field.Propid] = field.Value.id
            this.$emit('LinkSubCollectionArray',this.SelectedRecords,this.LookupObj)
            // this.CancelSelection()
            setTimeout(() => {
                this.SelectedRecords = []
            }, 200);
        },
        CancelSelection(){
          // this.LookupObj = {ArrayData: []}
          // this.selectedArticleQuickList = ''
          // this.QuickListArticles = []
          // this.RelatedRecordFilter = ''
          // this.SelectedRecords = []
            this.$emit('CancelSelection')
        },
        CancelAddParentDialog(){
            this.$emit('CancelAddParentDialog')
        },

        AddParentandLink(){
      let ParentObj = {[this.NewParentPrimary.Prop] : this.NewParentPrimary.Value }
      this.NewParentAdditional.map(field => {
        if(field.Type !== 'Lookup'){
        ParentObj[field.Prop] = field.Value
        }
        if(field.Type === 'Lookup'){
        ParentObj[field.LookupFieldName] = {[field.Prop]: field.Value[field.Prop],id: field.Value.id}
        ParentObj[field.Propid] = field.Value.id
        }
      })
      console.log(ParentObj)
      console.log('will save on this',this.RelatedObj)
      // db.collection(this.ParentCollection).add(ParentObj).then(doc => {
      //   db.collection('capitalequipmentjobs').update({
      //     [this.NewParentPrimary.Prop]: ParentObj
      //   })
      // })
    },
    }
}
</script>
<style>
.stickytopbanner{
  position: fixed;
  top: 70px;
  z-index: 100
}
</style>
    