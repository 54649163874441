<template>
  <div>
      <v-list-item v-for="itemprop in Object.entries(DataObject)" :key="itemprop.itemObjKey">			
            <v-list-item-content :class="typeof itemprop[1] === 'object' ? 'objectcontentheader' : ''">			
                {{itemprop[0]}}			
            </v-list-item-content>			
            <v-list-item-content v-if="typeof itemprop[1] === 'string'">			
                {{itemprop[1]}}			
            </v-list-item-content>			
            <v-list-item-content v-if="typeof itemprop[1] === 'object'">               		
                <v-list v-if="!itemprop[1].toDate">			
                    <v-list-item v-for="objprop in itemprop[1]" :key="objprop.itemObjKey">			
                        {{objprop}}			
                    </v-list-item>			
                </v-list>	
                <span v-else>
                     {{itemprop[1].toDate()}}	
                </span>		
            </v-list-item-content>			
        </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','DataObject'],
    components: {},
    data() {
        return {

        }
    },
    computed:{

    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
