
  <template>
  <!--  :style="cssProps" -->
  <!-- <v-app :class="AppStylingView && !PublicPreview ? 'focusedviewborder grey lighten-4' : 'grey lighten-4'" :style="AppStyling" :dark="AppisDarkMode"> -->
    <v-app :class="AppStylingView && !PublicPreview ? 'grey lighten-4' : 'grey lighten-4'" :style="AppStyling" :dark="AppisDarkMode">
    <div v-if="System.User_Location_Enabled" id="map"></div>
  <v-dialog max-width="800" v-model="UserInfoRequiredDialog" persistent>
      <v-card>
        <v-card-title>Required Information</v-card-title>
        <v-card-text>
          <v-form id="infoform">
              <v-list>
                <v-list-item v-for="info in UserRequiredInfo" :key="info.itemObjKey">
                  <v-text-field :rules="[$store.state.formrules.required]" :label="info" v-model="UserObj[info]" />
                </v-list-item>
              </v-list>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn>Cancel</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn @click="UpdateRequiredUserInformation()" dark class="green">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout  column style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" id="chatbtn" v-if="AppBuilderView && !ActiveMarketTemplate">
    <v-btn small fab @click="ExitAppBuilder()" dark color="blue">
      <v-icon>mdi-exit-to-app</v-icon>
    </v-btn>
  </v-layout>
  <!-- <v-layout  column style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" id="chatbtn" v-if="userIsAdmin && RouterReady">
    <v-btn small fab @click="ToggleAppQuickStartDialog()" dark color="purple">
      <v-icon>mdi-help</v-icon>
    </v-btn>
  </v-layout> -->
    <v-layout style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" column id="chatbtn" v-if="ShowChatbot">
    <v-btn small fab @click="ToggleChatbotActive()" dark color="green">
      <v-icon>mdi-robot</v-icon>
    </v-btn>
  </v-layout>
  
  
  <v-layout  column class="order-container-btn" v-if="RouterReady && StoreView && $route.name === 'StoreItemSingle'">
    <v-btn fab @click="SetViewOrderNavbar()" dark color="links">
      <v-icon>mdi-cart</v-icon>
    </v-btn>
  </v-layout>
  <!--<SuiteLiaisonChannel v-if="RelatedSuiteTemplates.length > 0 && SuiteTemplatesRoute && !SuiteTemplateWebsite || 
    RelatedSuiteTemplates.length > 0 && SuiteTemplatesRoute && PublicNavbarEditing" 
    Eitehr we jac up the z-index if it's owner, or just jack up zindex anyway...or...-->
    <AppPluginsandRoutes :SystemEntities="SystemEntities" :System="System" :ActivePlugins="ActivePlugins"
     :SocialNavActive="ShowIntranetNavbar || TemplateSocialNavbarActive" :Notifications="Notifications"
    />
    <AppStyleSheet :SystemEntities="SystemEntities" :System="System" :SettingColorstoSystemColors="SettingColorstoSystemColors"
    />
    <AppStructureFixing v-if="userIsAdmin" :StoredDefaultDirectories="StoredDefaultDirectories"
    :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages"
    :SystemPhotoLibraries="SystemPhotoLibraries" :DocumentationLibraries="DocumentationLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
    
    />
  <SuiteLiaisonChannel v-if="SuiteLiaisonChannelActive" style="z-index:26;"
  :SystemEntities="SystemEntities" :System="System" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries"
  :PublicNavbarEditing="PublicNavbarEditing" :LabelledFooterActive="LabelledFooterActive"
  />
  <BuilderViewAppBar v-if="AppBuilderView && !SuiteAppView || AppQuickStartDialog" :SitePages="SitePages" :SystemDocumentLibraries="SystemDocumentLibraries"
  :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements" :RADB="RADB" :RAApp="RAApp" :SiteModerators="SiteModerators"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" @ToggleAppDarkMode="ToggleAppDarkMode"
   @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" @TogglePublicNavbarEditing="TogglePublicNavbarEditing"
   :PublicNavbarEditing="PublicNavbarEditing" :SuiteLiaisonChannelActive="SuiteLiaisonChannelActive" :Directories="ComputedDirectories"
   :SubscriptionPackages="SubscriptionPackages"
  />
  <SuiteAppNavigator v-if="SuiteAppView && !RecordChatView && userLoggedIn" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements" :RADB="RADB" :RAApp="RAApp"
    @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
    @IntranetViewToggle="IntranetViewToggle" @ActivateSearch="ActivateSearch" @DeactivateSearch="DeactivateSearch" :SystemDocumentLibraries="SystemDocumentLibraries" :SiteModerators="SiteModerators"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" @ToggleAppDarkMode="ToggleAppDarkMode" :UserDBNavList="UserDBNavList"
    :UnreadNotifications="UnreadNotifications" :ActiveSearch="ActiveSearch"
    :WebFormEntities="WebFormEntities"
  />
   <RecordChatRoom v-if="RecordChatView" :RecordData="ActiveChatRecord" :ActiveSession="CurrentEntity" :LiaisonDialogActive="true"
      :OperationalDB="ActiveChatRecordOperationalDB" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :BackView="true"
      @ActivateProcessing="ActivateProcessing" @ToggleLiaisonDialog="ToggleLiaisonDialog" :FullonCreate="true" @GoBack="DeactivateActiveChatRecord()"
      :System="System" :SystemEntities="SystemEntities" :CanEdit="ActiveChatRecord.CanEdit" :ActiveChatRecord="ActiveChatRecord.ActiveProcess"
      />
  <GroupInteractionComponent v-if="IsGroupView" :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities"
  />
    <ChatBot :ActiveSession="ActiveSession" @ToggleChatbotActive="ToggleChatbotActive" v-if="ChatbotActive" :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName"  @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" />    
    <AppStylingComponent :ActiveNavbar="ActiveNavbar" :Directories="ComputedDirectories" v-if="AppStylingView" :System="System" @RefreshRouter="RefreshRouter" @ToggleAppDarkMode="ToggleAppDarkMode"
    @IntranetViewToggle="IntranetViewToggle" @TogglePublicPreview="TogglePublicPreview" :PublicPreview="PublicPreview" :GraphicalElements="GraphicalElements" :SystemPhotoLibraries="SystemPhotoLibraries"
    :FontOptions="FontOptions" :GradientElements="GradientElements" :SystemDocumentLibraries="SystemDocumentLibraries" :SiteModerators="SiteModerators"
    :SystemEntities="SystemEntities"
    @SetColorstoSystemColors="SetColorstoSystemColors" @ToggleCatNavlistView="ToggleCatNavlistView"/>
    <GroupNavbar v-if="GroupNavView" :GroupNavigationalItems="GroupNavigationalItems"
    :AppStylingView="AppStylingView" :System="System" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SitePages="SitePages" @RefreshRouter="RefreshRouter" @ToggleAppStylingView="ToggleAppStylingView" :GraphicalElements="GraphicalElements"/>
   
     <FeaturedMemberNavbar v-if="FeaturedMemberView"
     :Site="ActiveFeaturedMember"
    :AppStylingView="AppStylingView" :System="System" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SitePages="SitePages" @RefreshRouter="RefreshRouter" @ToggleAppStylingView="ToggleAppStylingView" :GraphicalElements="GraphicalElements"/>
    <ActiveSiteTemplateNavbar v-if="ActiveTemplateSite && !SocialNetworkTemplate && $route.name !== 'Suite Template Main'"
     :Site="ActiveTemplateSite"
    :AppStylingView="AppStylingView" :System="System" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SitePages="SitePages" @RefreshRouter="RefreshRouter" @ToggleAppStylingView="ToggleAppStylingView" :GraphicalElements="GraphicalElements"/>
    <!-- we went batshit and put all in intranetnavbar 
      <ActiveSiteSocialNetworkBar :FriendsList="FriendsList" :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" @ToggleAppStylingView="ToggleAppStylingView"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" @IntranetViewToggle="IntranetViewToggle" :AppStylingView="AppStylingView"
    @DeactivateSearch="DeactivateSearch" @ActivateSearch="ActivateSearch" :UserRecord="UserRecord" :ActivityStatusField="ActivityStatusField"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" :GraphicalElements="GraphicalElements" :ActiveSearch="ActiveSearch" :SiteModerators="SiteModerators"
    :MyActivitiesArray="MyActivitiesArray" :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" @AssignPushedNavItem="AssignPushedNavItem"
    :SystemEntities="SystemEntities" :System="ActiveTemplateSite" :SitePages="SitePages" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
    /> -->
    <StoreNavbar v-if="StoreView" @ActivateStoreView="ActivateStoreView" @AddItemtoCart="AddItemtoCart" :CartItems="CartItems" @ToggleAppDarkMode="ToggleAppDarkMode" @ActivateStoreSearch="ActivateStoreSearch"
    :SystemEntities="SystemEntities" :ActiveStore="System" :ActiveProduct="ActiveProduct" :Directories="ComputedDirectories" @PushActiveProduct="PushActiveProduct" :SitePages="SitePages"
    />
    <PublicNavbar v-if="ShowPublicNavbar" :SystemEntities="SystemEntities"
     :AppStylingView="AppStylingView" :System="System" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SitePages="SitePages" @RefreshRouter="RefreshRouter" @ToggleAppStylingView="ToggleAppStylingView" :GraphicalElements="GraphicalElements"/>
    <IntranetNavbar :FriendsList="FriendsList" :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" @ToggleAppStylingView="ToggleAppStylingView"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" @IntranetViewToggle="IntranetViewToggle" :AppStylingView="AppStylingView"
    @DeactivateSearch="DeactivateSearch" @ActivateSearch="ActivateSearch" :UserRecord="UserRecord" :ActivityStatusField="ActivityStatusField"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" :GraphicalElements="GraphicalElements" :ActiveSearch="ActiveSearch" :SiteModerators="SiteModerators"
    :MyActivitiesArray="MyActivitiesArray" :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" @AssignPushedNavItem="AssignPushedNavItem"
    :SystemEntities="SystemEntities" :System="ActiveTemplateSite ? ActiveTemplateSite : System" :SitePages="SitePages" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
    v-if="ShowIntranetNavbar || TemplateSocialNavbarActive"/>
    <OffLineNotice  v-if="!onLine"
    />
    <RASignin class="pop" style="position: relative;z-index:100;" v-if="userIsAdmin && RouterReady && GotEntities" @ToggleRAAppAdminDialog="ToggleRAAppAdminDialog" :RAAppAdminDialog="RAAppAdminDialog"
    :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :SystemEntities="SystemEntities" :System="System"
    />
    <!-- <AdminAlerts class="pop" style="position: relative;z-index:100;" v-if="userIsAdmin && RouterReady && GotEntities" :RAAppAdminDialog="RAAppAdminDialog"
    :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :SystemEntities="SystemEntities" :System="System"
    /> -->
    <AppQuickStartComponent class="pop" style="position: relative;z-index:100;" v-if="userIsAdmin" :RAAppAdminDialog="RAAppAdminDialog"
    :RADB="RADB" :RAApp="RAApp" :AppisDarkMode="AppisDarkMode" :SitePages="SitePages"
    />
    <ClientPortalNavbar v-if="GuestNavbarView && userLoggedIn && RouterReady" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" :Notifications="Notifications"
    :NotificationObj="NotificationObj" :SystemEntities="SystemEntities" :System="System" :SubscriptionPackages="SubscriptionPackages"
    @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" @ToggleAppDarkMode="ToggleAppDarkMode"
    @ActivateSnackbar="ActivateSnackbar" @ActivateProcessing="ActivateProcessing" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
    @SignoutNotifications="SignoutNotifications"
    />
    <Navbar :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
     :SystemDocumentLibraries="SystemDocumentLibraries" :SiteModerators="SiteModerators"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    @ToggleTimesheetDialog="ToggleTimesheetDialog" :TimesheetDialogActive="TimesheetDialogActive" :TimesheetRelated="TimesheetRelated" :SitePages="SitePages"
    :ActivityRegarding="ActivityRegarding" @SetActivityRegarding="SetActivityRegarding" @AssignPushedNavItem="AssignPushedNavItem" @TriggerDashboardbyNotification="TriggerDashboardbyNotification"
    v-if="DBView && !SocialPageRoute && !WebPageRoute && !WebsiteBuilderView && !SuiteAppView && userLoggedIn"/>
    
    <BreadCrumbs style="z-index:2;" :AppisDarkMode="AppisDarkMode" :AppisDarkModeCard="AppisDarkModeCard" :System="System" :SystemEntities="SystemEntities"
    :CurrentRecordName="CurrentRecordName" v-if="BreadCrumbsActive"
     class="breadcrumbstop" />
   
   <v-card tile flat :style="StyleMargin" :class="'fullapp '+BGImageClass" height="100%" v-if="!IntranetView && !SearchView && RouterReady && GotEntities && !RefreshRouteView 
   || !IntranetView && !SearchView && RouterReady && PublicUser && !RefreshRouteView">
      <div class="cloudbg" v-if="RendersClouds"
        :style="SystemisRA ? { backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' } : { backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' ,opacity: 0.4}"></div><div :class="SunorMoon" :style="SunorMoonTexture"  v-if="RendersClouds"/>
      <router-view :style="ComputedRouteStyle" @ActivateRecordLoadingDialog="ActivateRecordLoadingDialog" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView" @LandingPageView="LandingPageView" :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName"
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :PushedNavItem="PushedNavItem" @AssignPushedNavItem="AssignPushedNavItem"
       @UpdateGroupNavigationalItems="UpdateGroupNavigationalItems" @ToggleTimesheetDialog="ToggleTimesheetDialog" @SetActivityRegarding="SetActivityRegarding"
      :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :FavoriteFeatures="FavoriteFeatures" :DBNavLists="DBNavLists" :SystemTableFilters="SystemTableFilters"
      :AppStylingView="AppStylingView" @ToggleCatNavlistView="ToggleCatNavlistView" :Directories="ComputedDirectories" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList"
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       :SystemDocumentLibraries="SystemDocumentLibraries"  @TogglePublicNavbarEditing="TogglePublicNavbarEditing"
       @ActivateStoreView="ActivateStoreView" @PushActiveProduct="PushActiveProduct" :ActiveStore="ActiveStore" :CartItems="CartItems" @ProcessClearStoreCart="ProcessClearStoreCart"
      :AppisDarkMode="AppisDarkMode" :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" :SubscriptionPackages="SubscriptionPackages"
      :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :WikiEntities="WikiEntities" :GroupEntities="GroupEntities"
      :DocumentationLibraries="DocumentationLibraries" :PublicNavbarEditing="PublicNavbarEditing"
      :AppBuilderView="AppBuilderView" :StoredDefaultDirectories="StoredDefaultDirectories"
      class="transparent"
    @ToggleAppDarkMode="ToggleAppDarkMode"></router-view>    
    </v-card>
    <v-parallax v-if="!PublicPageView && !AppStylingView && !$route.name === 'PageEditor' && !PageView && userLoggedIn && System && SystemisRA"
     style="margin-bottom:-100px;margin:top:100px;"     
    height="500"
    src='@/assets/RapidappsParallax.jpeg'
  ></v-parallax>
    <v-card tile flat  :color="'background'" :style="StyleMargin" class="fullapp"  height="100%"  v-if="IntranetView && !SearchView && RouterReady && !NonSocialGuest">
      <router-view :style="IntranetRouteStyling" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView"  :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName" :AppisDarkMode="AppisDarkMode" 
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :PushedNavItem="PushedNavItem" @AssignPushedNavItem="AssignPushedNavItem"
      @UpdateGroupNavigationalItems="UpdateGroupNavigationalItems" @ToggleTimesheetDialog="ToggleTimesheetDialog" @SetActivityRegarding="SetActivityRegarding" :SubscriptionPackages="SubscriptionPackages"
      :AppStylingView="AppStylingView" :Directories="ComputedDirectories" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemGuides="SystemGuides" :DynamicWikiData="System"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList" :RADB="RADB" :RAApp="RAApp" 
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing" :CurrentEntity="SystemConfigEntity"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateStoreView="ActivateStoreView" @PushActiveProduct="PushActiveProduct" :ActiveStore="ActiveStore" :CartItems="CartItems" @ProcessClearStoreCart="ProcessClearStoreCart"
      :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" :WikiEntities="WikiEntities" :GroupEntities="GroupEntities"
      :DocumentationLibraries="DocumentationLibraries" :AppBuilderView="AppBuilderView" :SystemDocumentLibraries="SystemDocumentLibraries" :GuestNavbarView="GuestNavbarView"
      :PublicNavbarEditing="PublicNavbarEditing" @TogglePublicNavbarEditing="TogglePublicNavbarEditing" :SystemTableFilters="SystemTableFilters"
      :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries"></router-view>
    </v-card>
    <v-card tile flat  :color="'background'" :style="StyleMargin" class="fullapp"  height="100%"  v-if="IntranetView && !SearchView && RouterReady && NonSocialGuest">
      <v-main>
      <router-view :style="IntranetRouteStyling" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView"  :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName" :AppisDarkMode="AppisDarkMode" 
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :PushedNavItem="PushedNavItem" @AssignPushedNavItem="AssignPushedNavItem"
      @UpdateGroupNavigationalItems="UpdateGroupNavigationalItems" @ToggleTimesheetDialog="ToggleTimesheetDialog" @SetActivityRegarding="SetActivityRegarding" :SubscriptionPackages="SubscriptionPackages"
      :AppStylingView="AppStylingView" :Directories="ComputedDirectories" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemGuides="SystemGuides" :DynamicWikiData="System"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList" :RADB="RADB" :RAApp="RAApp" 
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing" :CurrentEntity="SystemConfigEntity"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateStoreView="ActivateStoreView" @PushActiveProduct="PushActiveProduct" :ActiveStore="ActiveStore" :CartItems="CartItems" @ProcessClearStoreCart="ProcessClearStoreCart"
      :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" :WikiEntities="WikiEntities" :GroupEntities="GroupEntities"
      :DocumentationLibraries="DocumentationLibraries" :AppBuilderView="AppBuilderView" :SystemDocumentLibraries="SystemDocumentLibraries" :GuestNavbarView="GuestNavbarView"
      :PublicNavbarEditing="PublicNavbarEditing" @TogglePublicNavbarEditing="TogglePublicNavbarEditing" :SystemTableFilters="SystemTableFilters"
      :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries"></router-view>
      </v-main>
    </v-card>
    <AppNotifications :StandbyDialog="StandbyDialog" :UploadingFiles="UploadingFiles" :snackbars="snackbars" :ReminderDialog="ReminderDialog" :ReminderNotifications="ReminderNotifications" @ToggleReminderDialog="ToggleReminderDialog"
    :UserisGuest="UserisGuest" :Notifications="Notifications" @TriggerDashboardbyNotification="TriggerDashboardbyNotification" :SystemLoading="SystemLoading" :SystemLoadingvalue="SystemLoadingvalue"
 :UploadingSingleFile="UploadingSingleFile" :AppisDarkMode="AppisDarkMode" :Processing="Processing" :RecordLoading="RecordLoading" :RecordLoadingvalue="RecordLoadingvalue"
 @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :PublicNavbarEditing="PublicNavbarEditing" @ActivateProcessing="ActivateProcessing"
 :SubscriptionPackages="SubscriptionPackages"
 :Directories="ComputedDirectories"
 :snackbar="snackbar" :snackbarmultiLine="snackbarmultiLine" :snackbartimeout="snackbartimeout" :snackbartext="snackbartext" :snackbartop="snackbartop" :snackbarpath="snackbarpath" 
 :SystemEntities="SystemEntities" :System="System" :RouterReady="RouterReady" :SystemPhotoLibraries="SystemPhotoLibraries" :SystemTableFilters="SystemTableFilters" @ActivateSnackbar="ActivateSnackbar"
 @CloseSnackbar="CloseSnackbar"/>   
    <SiteSearch  @DeactivateSearch="DeactivateSearch" @UpdateActiveSearch="UpdateActiveSearch"
    @ToggleAppDarkMode="ToggleAppDarkMode" class="footermargin" :UserRecord="UserRecord" v-if="SearchView" :ActiveSearch="ActiveSearch" :System="System" :SystemEntities="SystemEntities" />
    
    <PublicFooterComponent v-if="PublicFooterEngage" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"/>
    
     <v-footer
      fixed
      app v-if="LabelledFooterActive"
      :style="userLoggedIn ? 'z-index: 10;background: linear-gradient(345deg, rgba(78,85,143,1) 0%, rgba(192,77,139,1) 43%, rgba(238,147,126,1) 100%);z-index: 30;' : 'z-index: 30;'"
      height="50px"
      :class="userLoggedIn ? 'white--text' : 'footerbg'"
    >
    
    <!--  -->
      <span><span class="font-weight-light hidden-sm-and-down"> {{System.Name}} </span><span class="font-weight-light hidden-md-and-up">  </span>© 2023</span><v-spacer></v-spacer>
      <span v-if="!SystemisRA">Powered by</span><a v-if="!SystemisRA" href="https://www.businessandpeople.co.za/" style="color:white;padding-left:5px;" target="_blank" class="txtlink">Business<span class="font-weight-light">andPeople™</span></a>
      <v-btn v-if="!IsPrimarySite" @click="ActivateMainSite()">Main</v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import db from '@/main'
import format from 'date-fns/format'
import firebase from 'firebase';
import axios from 'axios'	

import AppStructureFixing from '@/components/General/AppStructureFixing'
import AppPluginsandRoutes from '@/components/General/AppPluginsandRoutes'
import AppStyleSheet from '@/components/General/AppStyleSheet'
import Navbar from '@/components/Navigation/Navbar'
import ClientPortalNavbar from '@/components/Navigation/ClientPortalNavbar'
import IntranetNavbar from '@/components/Navigation/IntranetNavbar'
import GroupNavbar from '@/components/Navigation/GroupNavbar'
import FeaturedMemberNavbar from '@/components/Navigation/FeaturedMemberNavbar'
import ActiveSiteTemplateNavbar from '@/components/Navigation/ActiveSiteTemplateNavbar'
import ActiveSiteSocialNetworkBar from '@/components/Navigation/ActiveSiteSocialNetworkBar'

import PublicNavbar from '@/components/Navigation/PublicNavbar'
import StoreNavbar from '@/components/Navigation/StoreNavbar'
import AppStylingComponent from '@/components/SuiteBuilder/AppStylingComponent'
import BuilderViewAppBar from '@/components/SuiteBuilder/Navigators/BuilderViewAppBar';	
import AppQuickStartComponent from '@/components/SuiteBuilder/Support/AppQuickStartComponent'
import ChatBot from './components/SuitePlugins/Chatbot/Chatbot'
import OffLineNotice from '@/components/General/OffLineNotice'
import RASignin from '@/components/SuiteBuilder/Support/RASignin'
import AdminAlerts from '@/components/SuiteBuilder/AdminAlerts'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'
import SiteSearch from '@/components/SuitePlugins/SiteSearch/SiteSearch'
import AppNotifications from '@/components/General/AppNotifications'
import PublicFooterComponent from '@/components/General/PublicFooterComponent'

import SuiteAppNavigator from '@/components/SuiteBuilder/SuiteApps/SuiteAppNavigator';
import RecordChatRoom from '@/components/General/RecordChatRoom';
import SuiteLiaisonChannel from '@/components/SuitePlugins/SuiteTemplates/SuiteLiaisonChannel';
import GroupInteractionComponent from '@/components/SuitePlugins/Groups/GroupInteractionComponent';
import LandingPageSingleVue from './components/WebPages/LandingPageSingle.vue'
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';

import Activity from '@/views/Activity/Single'
import Activities from '@/views/Activities';
import BusinessUnits from '@/views/BusinessUnits'
import Users from '@/views/Users'
import BusinessUnit from '@/views/BusinessUnit/Single';
import StockMovement from '@/components/SuitePlugins/Warehousing/StockMovement';
import ClassifiedSingleViewComp from '@/components/SuitePlugins/CustomDirectory/ClassifiedSingleViewComp.vue'
import DataSingleCall from '@/components/Database/DataSingleCall.vue'
// import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';
import TableMain from '@/components/Database/TableMain';
import User from '@/views/User/Single';

export default {
  components: { AppPluginsandRoutes,AppStyleSheet,AppStructureFixing,
  Navbar,ClientPortalNavbar,IntranetNavbar,GroupNavbar,FeaturedMemberNavbar,ActiveSiteTemplateNavbar,ActiveSiteSocialNetworkBar,PublicNavbar,StoreNavbar,AppStylingComponent,
  BuilderViewAppBar,AppQuickStartComponent,ChatBot,OffLineNotice,RASignin,AdminAlerts,BreadCrumbs,SiteSearch,AppNotifications,PublicFooterComponent,RecordChatRoom,SuiteAppNavigator,
  SuiteLiaisonChannel,GroupInteractionComponent,LandingPageSingleVue,DataCollectionViewComponent,
  Activity,Activities,BusinessUnits,Users,BusinessUnit,StockMovement,ClassifiedSingleViewComp,DataSingleCall,DataCollectionViewComponent,TableMain,User
  },
  name: 'App',
  data () {
    return {
      ActiveSearch: '',
      UsersArray: [],
      UsersCall: '',
      PrimarySiteCalled: false,
      DefaultThemes: [
          {Name: 'light', Colors: [
              {Theme: 'light', Name: 'primary',Element_Name: 'Primary',SystemProp: 'Light_Primary', DefaultColor:  '#468FBE', DefaultColorObject: {hex: '#468FBE'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'secondary',Element_Name: 'Secondary',SystemProp: 'Light_Secondary', DefaultColor:  '#FFCDD2', DefaultColorObject: {hex: '#FFCDD2'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'accent',Element_Name: 'Accent',SystemProp: 'Light_Accent', DefaultColor:  '#89C8F0', DefaultColorObject: {hex: '#89C8F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'recordtoolbar',Element_Name: 'Record Toolbar',SystemProp: 'Light_Record_Toolbar', DefaultColor:  '#89C8F0', DefaultColorObject: {hex: '#89C8F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},              
              {Theme: 'light', Name: 'background',Element_Name: 'Background',SystemProp: 'Light_Background', DefaultColor:  '#EBF0F0', DefaultColorObject: {hex: '#EBF0F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'links',Element_Name: 'Links',SystemProp: 'Light_Links', DefaultColor:  '#FFA200', DefaultColorObject: {hex: '#FFA200'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pop',Element_Name: 'Pop',SystemProp: 'Light_Pop', DefaultColor:  '#FF0000', DefaultColorObject: {hex: '#FF0000'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'morning',Element_Name: 'Morning',SystemProp: 'Light_Morning', DefaultColor:  '#7cb9fa', DefaultColorObject: {hex: '#7cb9fa'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'night',Element_Name: 'Night',SystemProp: 'Light_Night', DefaultColor:  '#091319', DefaultColorObject: {hex: '#091319'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialappsearchbar',Element_Name: 'Social App SearchBar',SystemProp: 'Light_Social_App_SearchBar', DefaultColor:  '#84B2C9', DefaultColorObject: {hex: '#84B2C9'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialappnavbar',Element_Name: 'Social App NavBar',SystemProp: 'Light_Social_App_NavBar', DefaultColor:  '#FFFFFF', DefaultColorObject: {hex: '#FFFFFF'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialmenufont',Element_Name: 'Social Menu Font',SystemProp: 'Light_Social_Menu_Font', DefaultColor:  '#ff0000', DefaultColorObject: {hex: '#ff0000'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbcard',Element_Name: 'DB Card',SystemProp: 'Light_DB_Card', DefaultColor:  '#E3F2FD', DefaultColorObject: {hex: '#E3F2FD'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'datatext',Element_Name: 'Field Values',SystemProp: 'Light_Field_Values', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'configtext',Element_Name: 'Field Labels',SystemProp: 'Light_Field_Labels', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbnavbar',Element_Name: 'Database SideNavbar',SystemProp: 'Light_Database_SideNavbar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbnavlist',Element_Name: 'Database Navlist',SystemProp: 'Light_Database_Navlist', DefaultColor:  '#76FF03', DefaultColorObject: {hex: '#76FF03'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbappbar',Element_Name: 'Database AppBar',SystemProp: 'Light_Database_AppBar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestnavbar',Element_Name: 'Guest SideNavbar',SystemProp: 'Light_Guest_SideNavbar', DefaultColor:  '#9fcad7', DefaultColorObject: {hex: '#9fcad7'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestnavlist',Element_Name: 'Guest Navlist',SystemProp: 'Light_Guest_Navlist', DefaultColor:  '#76FF03', DefaultColorObject: {hex: '#76FF03'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestappbar',Element_Name: 'Guest AppBar',SystemProp: 'Light_Guest_AppBar', DefaultColor:  '#9fcad7', DefaultColorObject: {hex: '#9fcad7'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavheaderbg',Element_Name: 'Public Navbar Header BG',SystemProp: 'Light_Public_Navbar_Header_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavbarbg',Element_Name: 'Public Navbar BG',SystemProp: 'Light_Public_Navbar_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavbaritem',Element_Name: 'Public Navbar Item',SystemProp: 'Light_Public_Navbar_Item', DefaultColor:  '#ffffff', DefaultColorObject: {hex: '#ffffff'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'footerbg',Element_Name: 'Footer BG',SystemProp: 'Light_Footer_BG', DefaultColor:  '#f5f5f5', DefaultColorObject: {hex: '#f5f5f5'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
          ]
          },
          {Name: 'dark', Colors: [
              {Theme: 'dark', Name: 'primary',Element_Name: 'Primary',SystemProp: 'Dark_Primary', DefaultColor:  '#03111f', DefaultColorObject: {hex: '#03111f'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'secondary',Element_Name: 'Secondary',SystemProp: 'Dark_Secondary', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'accent',Element_Name: 'Accent',SystemProp: 'Dark_Accent', DefaultColor:  '#467999', DefaultColorObject: {hex: '#467999'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'recordtoolbar',Element_Name: 'Record Toolbar',SystemProp: 'Dark_Record_Toolbar', DefaultColor:  '#607D8B', DefaultColorObject: {hex: '#607D8B'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},              
              {Theme: 'dark', Name: 'background',Element_Name: 'Background',SystemProp: 'Dark_Background', DefaultColor:  '#474747', DefaultColorObject: {hex: '#474747'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'fieldcard',Element_Name: 'Field Card',SystemProp: 'Dark_Field_Card', DefaultColor:  '#707A81', DefaultColorObject: {hex: '#707A81'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'links',Element_Name: 'Links',SystemProp: 'Dark_Links', DefaultColor:  '#FFA200', DefaultColorObject: {hex: '#FFA200'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pop',Element_Name: 'Pop',SystemProp: 'Dark_Pop', DefaultColor:  '#ff4500', DefaultColorObject: {hex: '#ff4500'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'morning',Element_Name: 'Morning',SystemProp: 'Dark_Morning', DefaultColor:  '#7cb9fa', DefaultColorObject: {hex: '#7cb9fa'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'night',Element_Name: 'Night',SystemProp: 'Dark_Night', DefaultColor:  '#091319', DefaultColorObject: {hex: '#091319'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialappsearchbar',Element_Name: 'Social App SearchBar',SystemProp: 'Dark_Social_App_SearchBar', DefaultColor:  '#84B2C9', DefaultColorObject: {hex: '#84B2C9'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialappnavbar',Element_Name: 'Social App NavBar',SystemProp: 'Dark_Social_App_NavBar', DefaultColor:  '#55798A', DefaultColorObject: {hex: '#55798A'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialmenufont',Element_Name: 'Social Menu Font',SystemProp: 'Dark_Social_Menu_Font', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbcard',Element_Name: 'DB Card',SystemProp: 'Dark_DB_Card', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'datatext',Element_Name: 'Field Values',SystemProp: 'Dark_Field_Values', DefaultColor:  '#b6e1f1', DefaultColorObject: {hex: '#b6e1f1'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'configtext',Element_Name: 'Field Labels',SystemProp: 'Dark_Field_Labels', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbnavbar',Element_Name: 'Database SideNavbar',SystemProp: 'Dark_Database_SideNavbar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbnavlist',Element_Name: 'Database Navlist',SystemProp: 'Dark_Database_Navlist', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbappbar',Element_Name: 'Database AppBar',SystemProp: 'Dark_Database_AppBar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestnavbar',Element_Name: 'Guest SideNavbar',SystemProp: 'Dark_Guest_SideNavbar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestnavlist',Element_Name: 'Guest Navlist',SystemProp: 'Dark_Guest_Navlist', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestappbar',Element_Name: 'Guest AppBar',SystemProp: 'Dark_Guest_AppBar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavheaderbg',Element_Name: 'Public Navbar Header BG',SystemProp: 'Dark_Public Navbar Header BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavbarbg',Element_Name: 'Public Navbar BG',SystemProp: 'Dark_Public_Navbar_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavbaritem',Element_Name: 'Public Navbar Item',SystemProp: 'Dark_Public_Navbar_Item', DefaultColor:  '#ffffff', DefaultColorObject: {hex: '#ffffff'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'footerbg',Element_Name: 'Footer BG',SystemProp: 'Dark_Footer_BG', DefaultColor:  '#272727', DefaultColorObject: {hex: '#272727'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
          ]
          }
      ],
      SettingColorstoSystemColors: false,
      ModuleDatabase: {},
      ActivePlugins: [],
      DocumentationLibraries: [],
      GotConfigMaster: false,
      SubscriptionPackages: [],
      StoreView: false,
      CartItems: [],
      ActiveProduct: '',
      SocialCategories: ['ArticlesCategories','BlogsCategories','MeetupsCategories','NoticesCategories','PollsCategories'],
      PushedNavItem: '',
      ActivityRegarding: '',
      TimesheetDialogActive: false,
      GroupNavigationalItems: [],
      SystemTableFilters: [],
      DBNavLists: [],
      ReminderDialog: false,
      ChatbotActive: false,
      onLine: navigator.onLine,
      showBackOnline: false,

      FavoriteFeatures: [],
      FrequentlyAskedQuestions: [],
      RefreshRouteView: false,
      PublicUser: false,
      SitePages: [],
      GotEntities: false,
      RAAppAdminUserBase: '',
      RAAppAdminDialog: false,
      RAAppAdminSignedIn: false,
      RAApp: '',
      RADB: '',
      System: '',
      ActiveSystemDatabase: '',
      ActiveSystemWebsite: '',
      ActiveSystemSocialNetwork: '',
      ActiveSystemLibrary: '',
      OnLogin: false,
      SystemEntitiesCall: null,
      GroupEntitiesCall: null,
      SystemEntities: [],
      GroupEntities: [],
      SystemGuides: [],
      SystemPhotoLibraries: [],
      SystemDocumentLibraries: [],
      HasPublicFooter: false,
      NotificationObj: '',
      SiteModerators: [],
      MyGoals: [],
      MyGoalsisEmpty: false,
      MyActivitiesArray: [],
      Notifications: [],
      snackbars: [],
      Processing: false,
      SystemLoading: false,
      SystemLoadingvalue: 0,
      RecordLoading: false,
      RecordLoadingvalue: 0,
      UploadingSingleFile: false,
      UploadingFiles: false,
      snackbarmultiLine: true,
      snackbartimeout: 4000,
      snackbarpath: '',
      snackbartext: 'Here is a snackbar',
      snackbartop: true,
      StandbyDialog: false,
      snackbar: false,
  ActiveSession: '',
  CustomRolesDefault: 
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
      BURolesDefault: [
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
      ],
      AppisDarkMode: false,
      SignedIn: true,
      WikiEntities: [],
      StoredDefaultDirectories: [],
      DefaultDirectories: [
        {
          id: 'Store_Products',
          Name: 'Store Products'
        },
        {
          id: 'Classifieds',
          Name: 'Classifieds'
        },
        {
          id: 'Group_Categories',
          Name: 'Group Categories'
        },
        {
          id: 'Group_Featured_Members',
          Name: 'Group Featured Members'
        },
        // {
        //   id: 'Classified_Entity',
        //   Name: 'Classified Entity'
        // },

      ],
      FontOptions: [],
      CatNavlistView: false,
      PublicNavbarEditing: false,
      AppStylingView: false,
      PublicPreview: false,
      IntranetView: false,
      UserRecord: {},
      SearchView: false,
      RouterReady: false,
      CurrentRecordName: '',
      LandingPageViewActive: false,
      carddarkcolor: 'blue-grey',
      cardlightcolor: 'white',
      UserInfoRequiredDialog: false,
      NowVar: '',
      NowTime: 0,
       UserObj: '',
       rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },            
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
      UserGroupsCalled: []

    }
  },
  watch: { 
    ComputedSystem: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){                
                 this.SetActiveSystem()
                }
            },deep: true
    },
    AppMonetizeBU: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){                
                 this.SetMonetizationBU()
                }
            },deep: true
    },
    ActiveSystemWebsite: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){
                  this.$store.commit('setActiveSystemWebsite',newvalue)
                }
            },deep: true
    },
    ActiveSystemSocialNetwork: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){
                  this.$store.commit('setActiveSystemSocialNetwork',newvalue)
                }
            },deep: true
    },
    ActiveSystemLibrary: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){
                  this.$store.commit('setActiveSystemLibrary',newvalue)
                }
            },deep: true
    },
    ActiveSystemDatabase: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){
                  this.$store.commit('setActiveSystemDatabase',newvalue)
                }
            },deep: true
    },
    ActiveSuiteid: {
            handler: function(newvalue, oldvalue) {
              //console.log('ActiveSuiteid newvalue, oldvalue',newvalue, oldvalue,this.userLoggedIn.Sites)
                if(oldvalue !== newvalue){
                  let vm = this
                  let AppsPath = newvalue === process.env.VUE_APP_RA_SYSTEM_ID ? db.collection('SystemConfig').doc(newvalue).collection('apps') : db.collection('systems').doc(newvalue).collection('apps')
                  let PluginsPath = newvalue === process.env.VUE_APP_RA_SYSTEM_ID ? db.collection('SystemConfig').doc(newvalue).collection('plugins') : db.collection('systems').doc(newvalue).collection('plugins')
                  //console.log('PluginsPath',PluginsPath)
                  this.$store.commit('setPluginsPath',PluginsPath)
                  this.$store.commit('setAppsPath',AppsPath)
                  this.$store.commit('setAppsDataBase',[])
                  //CAN WE PLEASE DO NeedsRASync AS WELL? with setNeedsRASync
                  if(!this.PublicUser){
                    if(newvalue === process.env.VUE_APP_RA_SYSTEM_ID){
                      // this.$store.commit('setUsersArray',[])  
                      this.UsersArray = []   
                      this.UsersCall();    
                      setTimeout(() => {           
                      this.GetUsers(db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users'),true)
                      }, 100);
                      if(!this.SystemisRA){
                      this.$store.commit('setNeedsRASync',true)
                      }
                    }
                    else{
                      this.UsersArray = []           
                      this.UsersCall();
                      setTimeout(() => {
                       this.GetUsers(db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users'),true) 
                      }, 100);
                      
                      this.$store.commit('setNeedsRASync',false)
                    }
                  }
                  
                  this.ActivatePluginDB(this.$store.state.PluginsPath.where('Active','==',true))
                  //we may want to visit posisbility of setUserAdmin when applicable, and also making FALSE again on change you get?
                  //exception being if dude is the PrimarySiteAdmin
                  this.RouterReady = false
                  let usersitecheck = this.userLoggedIn ? this.userLoggedIn.Sites.find(obj => obj.id === newvalue) : ''
                  if(!this.PublicUser){
                  
                  //console.log('usersitecheck',usersitecheck,this.userLoggedIn)
                  
                  if(usersitecheck || newvalue === process.env.VUE_APP_RA_SYSTEM_ID){
                    this.PrepEntities()
                   }
                  }
                  if(newvalue === process.env.VUE_APP_RA_SYSTEM_ID){
                    //console.log('base reset right?')
                    if(!this.PublicUser){
                    this.$store.commit('setUserAdmin', this.$store.state.PrimaryUserObj.IsSystemAdmin)
                    this.$store.commit('setUserisGuest', this.$store.state.PrimaryUserObj.IsGuestUser)
                    this.$store.commit('setSiteUserObj',this.$store.state.PrimaryUserObj)
                    }
                    this.PrepSuite(this.userLoggedIn)
                    let pluginpayload = {                
                    query: this.AppsPath,
                    arrayname: 'AppsDataBase',
                    SubCollections: [
                      {
                        Subcolpath: 'plugins',
                        SubcolName: 'Plugins'
                      },
                      {
                        Subcolpath: 'sharedplugins',
                        SubcolName: 'SharedPlugins'
                      }
                    ]              
                    }
                    this.$store.dispatch('GetArrayandMultipleSubcols',pluginpayload).then(function(result) {    
                      console.log(vm.$store.state.AppsDataBase)
                    })
                  }
                  else if(usersitecheck && newvalue !== process.env.VUE_APP_RA_SYSTEM_ID){
                    //console.log('usersitecheck',usersitecheck)
                    this.$store.commit('setUserisGuest', false)
                    this.$store.commit('setUserAdmin', usersitecheck.User.rolesarrayQuery.includes('System Admin') || this.$store.state.PrimaryUserObj.IsSystemAdmin)
                    this.$store.commit('setSiteUserObj',usersitecheck.User)
                    //setSiteUserObj
                    
                    if(this.userIsAdmin && !this.$store.state.PrimaryUserObj.IsSystemAdmin){ 
                      //console.log('should call entity master',this.DBMasterPath)
                      if(this.$store.state.EntityMasterFields.length === 0){             
                      let entmasterfieldspayload = {                
                          query: this.DBMasterPath.collection('entities').doc('Entities').collection('AllFields'),
                          arrayname: 'EntityMasterFields'
                        }
                        this.$store.dispatch('GetCollectionArray',entmasterfieldspayload)
                      }
                      if(this.$store.state.SystemMasterFields.length === 0){ 
                        let sysmasterfieldspayload = {                
                          query: this.DBMasterPath.collection('entities').doc('Systems').collection('AllFields'),
                          arrayname: 'SystemMasterFields'
                        }
                        this.$store.dispatch('GetCollectionArray',sysmasterfieldspayload)
                      }
                      }
                    this.PrepSuite(this.userLoggedIn)
                    let pluginpayload = {                
                    query: this.AppsPath,
                    arrayname: 'AppsDataBase',
                    SubCollections: [
                      {
                        Subcolpath: 'plugins',
                        SubcolName: 'Plugins'
                      },
                      {
                        Subcolpath: 'sharedplugins',
                        SubcolName: 'SharedPlugins'
                      }
                    ]              
                    }
                    this.$store.dispatch('GetArrayandMultipleSubcols',pluginpayload).then(function(result) {    
                      console.log(vm.$store.state.AppsDataBase)
                    })
                  }
                  else{
                    if(!this.PublicUser){
                    this.$store.commit('setUserisGuest', true)
                    }
                    this.PrepSuite(this.userLoggedIn)
                  }
                  
                  //still need to reset
                  //1. userrecord.groups
                  //2. TBD
                }
            }
    },
    RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  //alert('this.BuilderView '+this.BuilderView)
                  if(this.CatNavlistView && newvalue.name !== 'DirectoryBuilder'){
                    this.ToggleCatNavlistView()
                  }
                }
            },
            deep: true
    },
    SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['ActivateUploadingSingleFileNotification','CommitSnackbar','ActivateProcessing','ActivateInteractBounce']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    UserisCompanyAdmin(v){  
      if(v){
        let companyaccountspayload = {                
          query: db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').where('Companyid', '==', this.MyCompany.id),
          arrayname: 'CompanyBillingAccounts',
          }
          this.$store.dispatch('GetCollectionArray',companyaccountspayload).then(function(result) {
          })
      }
    },
    CompanyBillingAccounts: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                //actually CompanyLoanEntities and CompanyStoreEntities first
                //then for each a query to either CompanyLoanOrders and or CompanyStoreOrders
                //however still check if user is copmanyadmin first as store ent and shit set on memgroup NOT on company
                //unless that's the missing link though?
                //okay if it is per company that we set the LoanEntities and StoreEntities for the admin...then we also need to set a default thereof
                //but I do agree with this because memgroup cannot know if the users are in fact company admins
                let arrayquery = []
                this.CompanyBillingAccounts.map(ba => {
                  //even so Bob, we don't know the TrackedEntity....???
                  arrayquery.push(db.collection(this.userLoggedIn.TrackedEntity.split('_').join('').toLowerCase()).where('Companyid','==',this.MyCompany.id))
                  //CompanyTrackingOrders
                })
                let payload = {                
                query: arrayquery,
                arrayname: 'CompanyTrackingOrders',
                }
                //really not, this won't work
                // this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
                //   //console.log(vm.$store.state.RAPlugins)
                // })
              }
            },deep: true
    },
    UserMemberGroup: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('SetActiveMemberGroup',this.UserMemberGroup)
                if(this.UserMemberGroup && this.UserMemberGroup.AdditionalProperties && this.UserMemberGroup.AdditionalProperties.InviteGroups && this.UserMemberGroup.AdditionalProperties.InviteGroups.length > 0){
                  let membergroupspayload = {                
                    query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('membergroups').where(firebase.firestore.FieldPath.documentId(), 'in', this.UserMemberGroup.AdditionalProperties.InviteGroups),
                    arrayname: 'MemberGroupsArray',
                    }
                    // membergroupspayload.query = this.UserMemberGroup.InviteGroups.map(grp => {
                    //   return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('membergroups')
                    // })
                    this.$store.dispatch('GetCollectionArray',membergroupspayload).then(function(result) {    
                      //console.log(vm.$store.state.RAPlugins)
                    })
                }
              }
            },deep: true
    },
    UserGroups: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                if(this.UserGroups){
                    this.UserGroups.map(group => {
                      if(group.Administrator || group.IsTeamMember){
                        let oncheck = this.UserGroupsCalled.find(obj => obj === group.id)
                        if(!oncheck){
                         this.UserGroupsCalled.push(group.id)
                          this.GetEntities(db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(group.id).collection('entities'),'GroupEntities',group.id)
                        }
                      }
                    })
                  }
              }
            },deep: true
        },
    ComputedDirectories: {
            handler: function(oldvalue, newvalue) {
              //alert('change navlists')
              if(oldvalue !== newvalue){
                //console.log('ComputedDirectories ',oldvalue, newvalue)
                //console.log('store groupnavlist',groupnavlist,this.ComputedDirectories)
                let groupnavlist = this.ComputedDirectories.find(obj => obj.id === 'Group_Categories')
                //console.log('store groupnavlist',groupnavlist,this.ComputedDirectories)
                if(groupnavlist){
                  let entities = this.$store.state.SocialEntities
                  let groupentity = entities.find(obj => obj.id === 'Site_Groups')
                  if(groupentity){
                    //let index = entities.indexOf(groupentity)
                    let additionalfields = groupnavlist.AdditionalFields
                    if(additionalfields){
                     let length = additionalfields.length
                    //console.log('store',length)
                    if(length > 0){
                      additionalfields.map((field,fieldindex) => {
                        let oncheck = groupentity.AllFields.find(obj => obj.id === field.id)
                        if(!oncheck){
                          groupentity.AllFields.push(field)
                        }                        
                        if(fieldindex-1+2 === length){
                          //console.log('store',entities)
                          this.$store.commit('setSocialEntities',entities)
                        }
                      })          
                    } 
                    }
                    
                  }
                }   
              }
            },
            deep: true
        },
    ReminderNotifications: {
            handler: function(oldvalue, newvalue) {
              
              if(oldvalue !== newvalue){
                //console.log('NowTime checking ReminderNotifications oldvalue, newvalue',oldvalue, newvalue) 
                if(oldvalue.length > 0 && this.NowTime !== 0 && !this.userLoggedIn.TaskNotificationsDisabled){
                  //console.log('NowTime !0 ReminderNotifications oldvalue, newvalue',oldvalue, newvalue)
                      this.ReminderDialog = true
                }
                else{
                  //console.log('no dial ReminderNotifications oldvalue, newvalue is 0 ',oldvalue, newvalue)
                  this.ReminderDialog = false
                }                 
              }
            },
            deep: true
        },
    MyActivitiesArray: {
            handler: function(oldvalue, newvalue) {
              
              if(oldvalue || newvalue){
                //console.log('MyActivitiesArray oldvalue, newvalue',oldvalue, newvalue)
                // oldvalue.map(item => {
                //   if(item.Has_Reminder && !this.ReminderDialog){
                //     setTimeout(() => {
                //       this.ReminderDialog = true  
                //       }, 5000);
                //   }
                // })
              }
            },
            deep: true
        },
    onLine(v) {
            if (v) {
                this.showBackOnline = true;
                setTimeout(() => {
                    this.showBackOnline = false;
                }, 1000);
            }
        },
    
    System : {
            handler: function(newvalue, oldvalue) {
              if(newvalue){
                let systempayload = {
                    system: this.System,
                }
                this.$store.commit('SetSystemObj', systempayload)
                 if(this.System.DefaultCurrency){
                  this.$store.commit('SetSystemDefaultCurrency', this.System.DefaultCurrency)
                }
              }
              // if(this.System.Has_Public_Signup && !this.userLoggedIn){
              //   let signuproute = {
              //     path: '/SignUp',
              //     name: 'signup',
              //     component: SignUp
              //   }
              //   this.$router.addRoute(signuproute)
              // }
              
            },
            deep: true
    },
    
      //db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(payload.userid).collection('credittokens').where('Active','==',true).orderBy('Date','asc')
      
      UserAvailableTokens: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('setUserAvailableTokens',newvalue)
                //console.log(this.$store.state.UserAvailableTokens)
              }
               },
            deep: true
        },
      userLoggedIn: {
            handler: function(newvalue, oldvalue) {
              let vm = this
        if (newvalue !== null && newvalue !== undefined && newvalue.id && !oldvalue || 
        newvalue !== null && newvalue !== undefined && newvalue.id && oldvalue && !oldvalue.id) {
            
          if(this.userLoggedIn.Company){
            this.GetMyCompany()
          }
          this.GetTrackingOrders()
          this.GetSentMessages()
          this.GetReceivedMessages()
            if(!this.GotConfigMaster && this.userIsAdmin){
              this.CheckConfigSystemTabs()
            }           
          this.GetSubscriptionPackages()
         this.UserObj = Object.assign({},newvalue)
         if(this.System.User_Location_Enabled){
           this.CheckLocation()
         }
        this.GetWarehouses()
            this.GetPlugins()
        }
      },deep: true
      },
      
      userIsEagleViewer (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value){
          
        }
      },
      userIsAdmin (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value && process.env.VUE_APP_RA_SYSTEM_ID === this.ActiveSuiteid && this.$store.state.MemberGroupsArray.length === 0){
          
          let membergroupspayload = {                
                  query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('membergroups'),
                  arrayname: 'MemberGroupsArray',
                  }
                  this.$store.dispatch('GetCollectionArray',membergroupspayload).then(function(result) {    
                    //console.log(vm.$store.state.RAPlugins)
                  })
          //console.log(this.$store.state.RAPlugins)
        }
      },
      // RouterReady (value){
      //   if(value){
      //     this.SystemLoading = false
      //   }
      // },
    
    UserInfoRequired (value) {
        if (value !== null && value !== undefined) {
         this.UserInfoRequiredDialog = true
        }
      },
    UserBillingAccounts: {
            handler: function(newvalue, oldvalue) {
              //console.log('WarehousesArray',oldvalue !== newvalue,oldvalue, newvalue)
              if(oldvalue !== newvalue){
                //WHY IN THE WORLD DID WE HAVE THIS?/?
                // let statearray = this.$store.state.BillingAccountsArray
                // if(!statearray || statearray && statearray.length === 0){
                //   let arraypayload = {
                //     arrayname : 'BillingAccountsArray', 
                //     array: []
                //   }
                //   arraypayload.array = newvalue
                //   this.$store.commit('setCollectionArray',arraypayload)
                // }
                
              }
            },deep: true
        },
    ComputedUsers: {
            handler: function(newvalue, oldvalue) {
              //console.log('newvalue',newvalue,'oldvalue', oldvalue)
              if(newvalue !== oldvalue){
                //console.log('setUsersArray due change',newvalue.length)
                this.$store.commit('setUsersArray',newvalue)
                //console.log(newvalue)
              }
              else{
                this.$store.commit('setUsersArray',newvalue)
                //console.log('no change set users ',newvalue.length)
              }
            },deep: true
        },
    WarehousesArray: {
            handler: function(oldvalue, newvalue) {
              //console.log('WarehousesArray',oldvalue !== newvalue,oldvalue, newvalue)
              if(oldvalue !== newvalue || oldvalue.length > 0){
                this.WarehouseQueries()
              }
            },deep: true
        },
    },
  mounted() {
        
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
  
  computed: {
    ComputedUsers(){
      return this.UsersArray
    },
    ComputedSystem(){
      return this.System
    },
    SocialPageRoute(){
      return this.$route.meta && this.$route.meta.RouteEventID === 1027
    },
    WebPageRoute(){
      return this.$route.meta && this.$route.meta.RouteEventID === 1028
    },
    Userunauthenticated(){
      return !this.SignedIn || !this.PrimarySiteCalled
    },
    IsPrimarySite(){
      return this.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
    },
    roottableentities(){
      return this.$store.state.roottableentities
    },
    ActiveSuiteid(){
        return this.$store.state.ActiveSuiteid
      },
    TemplateSocialNavbarActive(){
      return this.ActiveTemplateSiteSocialNetwork && !this.BuilderView && !this.TemplateGroupSingleView && !this.TemplateDirectoryBuilderView && !this.SearchView
      //yeah,we actually need to check if we in social page view or content channel views, but okay
    },
    TemplateDirectoryBuilderView(){
      return this.$route.name === 'MarketplaceTemplateDirectoryBuilder'
    },
    TemplateGroupSingleView(){
      return this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle'
    },
    ActiveTemplateSiteSocialNetwork(){
      return this.ActiveTemplateSite && this.SocialNetworkTemplate || this.SocialNetworkTemplate && this.SuiteSocialNetworkActive
    },
    GradientElements(){
      return this.$store.state.GradientElements
    },
    GraphicalElements(){
      return this.$store.state.GraphicalElements
    },
    // DefaultThemes(){
    //   return this.$store.state.DefaultThemes
    // },
    SuiteLiaisonChannelActive(){
      return this.RelatedSuiteTemplates.length > 0 && this.SuiteTemplatesRoute
    },
    WebAppBuilderActive(){
      return this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active
    },
    LabelledFooterActive(){
      return !this.AltPubNavbar || this.AltPubNavbar && !this.MiniView
    },
    CanLiaiseSuiteTemplate(){
      return this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Clientid === this.userLoggedIn.id || this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Ownerid === this.userLoggedIn.id
    },
    MySuiteTemplatesView(){
      return this.$route.name === 'My Suite Templates'
    },
    SuiteTemplateMain(){
      return this.$route.name === 'Suite Template Main'
    },
    SuiteTemplateWebsite(){
      return this.$route.name === 'Suite Template Website'
    },
    SuiteTemplateSocialNetwork(){
      return this.$route.name === 'Suite Template Social Network' || this.$route.path.includes('Social-Network')
    },
    SuiteTemplateDatabase(){
      return this.$route.name === 'Suite Template Database App'
    },
    SuiteTemplatesRoute(){
      return this.SuiteTemplateView || this.$route.name === 'MySuiteTemplates'
    },
    SuiteTemplatePreview(){
        return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
    },
    SuiteTemplateView(){
        return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
    },
    ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
    RelatedSuiteTemplates(){
      return this.MySuiteTemplates.concat(this.ClientSuiteTemplates) 
    },
    SuiteTemplates(){
    return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
    },
    OtherSuiteTemplates(){
    return this.$store.state.OtherSuiteTemplates
    },
    MySuiteTemplates(){
    return this.$store.state.MySuiteTemplates
    },
    ClientSuiteTemplates(){
    return this.$store.state.ClientSuiteTemplates
    },
    SuiteSocialNetworkActive(){
      return this.SuiteSocialNetwork && this.SuiteTemplateSocialNetwork
    },
    SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
    SuiteWebsiteActive(){
      return this.SuiteWebsite && this.$route.name === 'Suite Template Website'
    },
    SuiteWebsite(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.Website : ''
        },
    WebsiteTemplate(){
            return this.ActiveSuiteTemplate ? this.SuiteWebsite : this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Website'
        },
    SocialNetworkTemplate(){
      return this.SuiteSocialNetworkActive ? this.SuiteSocialNetwork :
      this.ActiveMarketTemplate && this.ActiveMarketTemplate.Primary_Category && this.ActiveMarketTemplate.Primary_Category.Name === 'Site' && this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Social Network'
    },
    FeaturedMemberTemplate(){
      return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Primary_Category && this.ActiveMarketTemplate.Primary_Category.Name === 'Site' && this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Featured Member'
    },
    GroupSiteTemplate(){
      return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Primary_Category && this.ActiveMarketTemplate.Primary_Category.Name === 'Site' && this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Group Site'
    },
    ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
    ActiveTemplateSiteType(){
      if(this.WebsiteTemplate){
        return 'Website Template'
      }
      else if(this.SocialNetworkTemplate){
        return 'Social Network Template'
      }
      else if(this.ActiveMarketTemplate){
        return 'Featured Member'
      }
      else if(this.GroupSiteTemplate){
        return 'Group Site'
      }
      else{
        return 'System'
      }
    },
    ActiveTemplateSite(){
      //console.log('this.PublicNavbarEditing',this.PublicNavbarEditing)
      //Forget previous attempts and misuse of the name, it was misued for Online store and perhaps traces in WH. It's irrelevant renamed to ActiveStore. 
      //ActiveTemplateSite NOW is whatever has a navigational view likely scoped to
      //std website, social network, group site, feat mem site, market template site
      //On second thought we don't need this....we don't need a state.ActiveTemplateSite...
      //below just a halfassed approach BUT..in time modulairy for public navbars will follow from the "WebsiteTemplate" etc etc
      if(this.ActiveTemplateSiteType === 'System'){
        return ''
      }
      else if(this.FeaturedMemberView){
        return this.ActiveFeaturedMember
      }
      else if(this.SuiteSocialNetworkActive){
        return this.SuiteSocialNetwork
      }
      else if(this.SuiteWebsite){
        return this.SuiteWebsite
      }
      else if(this.ActiveMarketTemplate && this.PublicNavbarEditing || this.ActiveMarketTemplate && this.$route.name === 'MarketplaceTemplate' &&  this.ActiveMarketTemplate.Ownerid !== this.userLoggedIn.id || 
      this.ActiveMarketTemplate && this.$route.name !== 'MarketplaceTemplate' &&  this.ActiveMarketTemplate.Ownerid === this.userLoggedIn.id){
        //really...&&  this.ActiveMarketTemplate.Ownerid !== this.userLoggedIn.id ONLY on Marketplace single
        return this.ActiveMarketTemplate.TemplateProps
      }
      //and as is, with this semi approach, this ActiveTemplateSite has no use whatsoever. 
      //as we will currently simply navigate with manual props, but all this will be used once we ready
      //for now the closes workaround let system mean "nothing" otherwise return neccesary.
    },
    SiteGroupsActive(){
      return this.PluginDataBase.Site_Groups && this.PluginDataBase.Site_Groups.Active
    },
    FeaturedMembersActive(){
      return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
    },
    ActiveFeaturedMember(){
        return this.$store.state.ActiveFeaturedMember
    },
    FeaturedMemberView(){
      return this.$route.name === 'FeaturedMemberViewer' || this.PublicNavbarEditing && this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'
    },
    CompanyBillingAccounts(){
        //console.log('this.MyCompany',this.MyCompany)
        return this.$store.state.CompanyBillingAccounts
      },
    UserisCompanyAdmin(){
        //console.log('this.MyCompany',this.MyCompany)
        return this.$store.state.UserisCompanyAdmin
      },
    NonSocialGuest(){
      return this.userLoggedIn && this.UserisGuest && !this.CanSocial && this.RouterReady && this.$route.name !== 'MyAccount'
    },
    FeaturedMemberBuilder(){
      return this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'
    },
    GuestNavbarView(){
      return this.userLoggedIn && this.UserisGuest && !this.CanSocial && this.RouterReady && 
      !this.FeaturedMemberView && !this.ActiveFeaturedMember && !this.MarketplaceSiteBuilderView && !this.ActiveTemplateSite && !this.SuiteTemplatePreview
      || this.$route.name === 'MyAccount'
    },
    MyCompany(){
      return this.$store.state.MyCompany
    },
    InviteGroups(){
      return this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties ? this.ActiveMemberGroup.AdditionalProperties.InviteGroups : []
    },
    ActiveMemberGroup(){
      return this.$store.state.ActiveMemberGroup
    },
    CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
      },
    ActiveMemberGroupAllowedChannels(){
      return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels ? this.ActiveMemberGroup.SocialChannels : ''
    },
    CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
    UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
    UserHasMemberGroup(){
      return this.userLoggedIn && this.userLoggedIn.MemberGroupid
    },
    UserMemberGroup(){
      return this.UserHasMemberGroup ? this.userLoggedIn.MemberGroup : ''
    },
    ConfigMasterPath(){
      return this.SystemisRA ? db.collection('SystemConfig').doc(this.ActiveSuiteid) : 
      this.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID)
    },
    DBMasterPath(){
      //exception, we use env. values because we do NOT copy "entity masters" to each "ActiveSuiteid"
      return this.SystemisRA  ? db.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID) : 
      this.RADB.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID)
    },
    
     SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
    CurrentEntity(){
      return this.$store.state.CurrentEntity
    },
    RecordChatView(){
        return this.ActiveChatRecord
      },
    ActiveChatRecordOperationalDB(){
      return this.ActiveChatRecord && this.ActiveChatRecord.id ? 
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.ActiveChatRecord.id)
        : ''
    },
    ActiveChatRecord(){
      return this.$store.state.ActiveChatRecord
    },
    ActiveSuiteApp(){
      return this.$store.state.ActiveSuiteApp
    } ,   
    BreadCrumbsActive(){
      return this.SignedIn && !this.StoreView && !this.IntranetView && this.RouterReady && !this.UserisGuest && !this.DBBuilderView && !this.AppBuilderView && !this.SuiteTemplatesRoute
    && this.$route.name !== 'bannerads' && this.$route.name !== 'MyDashboard' && this.$route.name !== 'MyAccount' && this.$route.name !== 'DashboardBuilder' && this.$route.name !== 'SystemConfig'
    && this.$route.name !== 'MyProfile' && this.$route.name !== 'GroupDashboard' && !this.CatNavlistView && this.$route.name !== 'Monetization'&& this.$route.name !== 'GroupPageEditor'
    && this.$route.name !== 'PageEditor' && this.$route.name !== 'GettingStarted' && !this.ActiveSuiteApp && this.$route.name !== 'MarketplaceTemplate' 
    && this.$route.name !== 'FeaturedMemberEditor' && this.$route.name !== 'FeaturedMemberViewer' && !this.TemplateGroupSingleView && !this.TemplateDirectoryBuilderView && !this.SearchView
    },
    ShowChatbot(){
      return this.$route.name !== 'Monetization' && this.$route.name !== 'ChatbotBuilder'
     && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor' && this.$route.name !== 'GroupEdit' 
     && this.$route.name !== 'DocumentationLibrary' && this.$route.name !== 'DocumentationLibraryEditor'
     && this.SignedIn && this.RouterReady && !this.StoreView && this.ChatbotPluginActive
    },
    ChatbotPluginActive(){
      return this.PluginDataBase && this.PluginDataBase.Chatbot && this.PluginDataBase.Chatbot.Active
    },
    AppQuickStartDialog(){
      return this.$store.state.AppQuickStartDialog
    },
    RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
    AppsDataBase(){
        return this.$store.state.AppsDataBase
    },
    AllAppPlugins(){
    return this.AppsDataBase.map(app => {
        let appobj = Object.assign({},app)
        return appobj.Plugins
    }).flat()
    },
    SuitehasWarehouse(){
    return this.AllAppPlugins.find(obj => obj.id === 'Warehouse_&_Products')
    },
    ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
    GroupidRoutes(){
            return this.$store.state.GroupidRoutes
        },
        GroupslugRoutes(){
            return this.$store.state.GroupslugRoutes
        },
        GroupID(){
            if(this.GroupidRoutes.includes(this.$route.name)){
                return this.$route.params.id
            }
            else if(this.$route.query && this.$route.query.GroupID){
                return this.$route.query.GroupID
            }
            else if(this.GroupslugRoutes.includes(this.$route.name)){
                return this.$route.params.slug
            }
        },
    SystemConfigEntity(){
      return this.$store.state.SystemConfigEntity
    },
    AppBuilderView(){
      return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView || this.DocLibBuilderView || this.FeaturedMemberBuilderView || this.MarketplaceSiteBuilderView
      || this.$route.name === 'GettingStarted' ||  this.$route.name === 'ChatbotBuilder' ||  this.$route.name === 'FAQAdmin'
      ||  this.$route.name === 'Glossary' && !this.IntranetNavView || this.$route.name === 'MyAccount' && this.BuilderView
    },
    BuilderView(){
    return this.$store.state.BuilderView
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    AppsPath(){
      return this.$store.state.AppsPath
    },
    PluginsPath(){
      return this.$store.state.PluginsPath
    },
    PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
    DocLibBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
      },
    FeaturedMemberBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Featured Member'
    },
    MarketplaceSiteBuilderView(){
          return this.BuilderView && this.BuilderView.Name === 'Marketplace Site'
      },
    WebsiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Website Builder'
    },
    SuiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Suite Builder'
    },
    DBBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Database Builder'
    },
    NetworkBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
    },
    UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
    IsGroupView(){
      //STDUserRoutes
        return this.$store.state.IsGroupView && this.RouterReady
    },
    UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
    WarehousesArray(){
      return this.$store.state.WarehousesArray
    },
    UserGroups(){
      return this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups : ''
    },
    CurrentItemModerator(){
      return this.$store.state.CurrentItemModerator
    },
    CurrentisFavorite(){
      let path = this.$route.path
      if(this.userLoggedIn.FavoriteLinks){
      return this.userLoggedIn.FavoriteLinks.find(obj => obj.Path === path)       
      }
      else{
        return false
      }
    },
    
    ActivityStatusField(){
      let activityentity = this.SystemEntities.find(obj => obj.id === 'Activities')
      if(activityentity){
        return activityentity.AllFields.find(obj => obj.id === 'Status')
      }
    },
    ReminderNotifications(){
      //console.log('this.MyActivitiesArray',this.MyActivitiesArray)
      return this.MyActivitiesArray.filter(task => {
        return task.Has_Reminder
      })
      .filter(task => {
        if(task.End){
          let endataobj = task.End.toDate()
          let endtime = endataobj.getTime()
          return endtime > this.NowTime
        }
        else{
          return task
        }
      })
      .map(task => {
        if(task.Start){
          task.Due_Date = task.Start
        }
        return task
      })
      .filter(task => {   
        //console.log('task',task)     
        let snoozetime = this.NowTime-1
        if(task.Snoozed_Until){
          let snoozedataobj = task.Snoozed_Until.toDate()
          snoozetime = snoozedataobj.getTime()
        }
        //console.log('snoozetime vs this.NowTime',snoozetime,this.NowTime)        
        return snoozetime < this.NowTime && task.Status_Reason.Name !== 'Completed' && task.Status_Reason.Name !== 'Cancelled'
      }).map(task => {
        let today = new Date()
        if(task.Due_Date.toDate()<today){
            task.overdue = true
          }
        task.monthdue = this.TimestampFormatterNoticeBoard(task.Due_Date.toDate(),'month')
        task.daydue = this.TimestampFormatterNoticeBoard(task.Due_Date.toDate(),'date')
          return task
      })
    },
    RouteObj(){
    return this.$route
  },
    UserAvailableTokens(){
      return this.userLoggedIn ? this.userLoggedIn.CreditTokens
      .filter(tokpurch => {
        return tokpurch.Remaining_Tokens > 0
      })
      .map(tokpurch => {
        return tokpurch.Remaining_Tokens
      }).reduce((a, b) => a + b, 0) : 0
    },
    
    UserTableFilters(){
      return this.userLoggedIn.UserTableFilters
    },
    ComputedDirectories(){
      let arr0 = this.DefaultDirectories.filter(navlist => {
        let oncheck = this.StoredDefaultDirectories.find(obj => obj.id === navlist.id)
        return !oncheck
      })
      let arr1 = this.StoredDefaultDirectories
      let arr2 = this.SystemEntities.filter(entity => {
        return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
      })
      .map(entity => {
        let obj = {
          id: entity.id,
          Name: entity.DisplayName
        }
        return obj
      })
      .filter(navlist => {
        let oncheck = this.StoredDefaultDirectories.find(obj => obj.id === navlist.id)
        return !oncheck
      })
      return arr0.concat(arr1,arr2)
    },
    AppStyling(){
      if(!this.PublicUser && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor' && this.$route.name !== 'SuiteApp' && this.$route.name !== 'MyAccount'){
        return 'padding-bottom:50px;'
      }
      else{
        return ''
      }
    },
    IntranetRouteStyling(){
      return this.$route.name === 'MyAccount' ? '' : 'z-index:1;'
    },
    cssProps () {
      var themeColors = {}
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
      })
      return themeColors
   },
   ActiveNavbar(){
     if(this.$route.name === 'MyAccount'){
       return 'Guest Navbar'
     }
     else if(this.DBView){
       return 'DB Navbar'
     }
     else if(this.IntranetNavView){
       return 'Social Navbar'
     }
     else if(this.PublicNavView){
       return 'Public Navbar'
     }
   },
   IntranetNavView(){
     if(this.CanSocial){
        return this.IntranetView && this.RouterReady && !this.PublicPreview && !this.CatNavlistView && !this.StoreView && this.$route.name !== 'Checkout' && !this.SuiteBuilderView && !this.WebsiteBuilderView && this.$route.name !== 'GettingStarted'
     }
     else if(this.System.Guests_can_Social){
        return this.SignedIn && this.IntranetView && this.RouterReady && !this.PublicPreview && !this.CatNavlistView && !this.StoreView && this.$route.name !== 'Checkout' && !this.SuiteBuilderView && !this.WebsiteBuilderView
     }
     else{
       return this.SignedIn && this.IntranetView && this.RouterReady && !this.UserisGuest && !this.PublicPreview && !this.CatNavlistView && !this.StoreView && !this.SuiteBuilderView && !this.WebsiteBuilderView
     }     
   },
   WebsiteisActive(){
     return this.$store.state.WebsiteisActive
   },
   SocialNetworkisActive(){
     return this.$store.state.SocialNetworkisActive
   },
   DatabaseisActive(){
     return this.$store.state.DatabaseisActive
   },
   DocumentationisActive(){
     return this.$store.state.DocumentationisActive
   },
   ShowPublicNavbar(){
     return this.WebsiteisActive && this.PublicNavView && !this.FeaturedMemberView && this.$route.name !== 'MarketplaceTemplate' && this.$route.name !== 'SuiteApp'
   },
   PublicNavView(){
    //&& !this.SignedIn
     return !this.LandingPageViewActive && !this.AppStylingView && !this.IntranetView && this.RouterReady 
     && !this.CatNavlistView && this.$route.name !== 'GroupPage' && !this.StoreView && this.WebPageRoute || 
     !this.LandingPageViewActive && !this.AppStylingView && !this.IntranetView && this.RouterReady 
     && !this.CatNavlistView && this.$route.name !== 'GroupPage' && !this.StoreView && !this.SignedIn
     || this.PublicPreview && !this.CatNavlistView && !this.StoreView || this.WebsiteBuilderView && !this.IsGroupView && this.$route.name !== 'WebFormEditor' && this.$route.name !== 'WebFormBuilderNew'
     && !this.$route.meta.DataSingleBuilder
     || this.PublicPreview && !this.CatNavlistView && !this.StoreView || this.WebsiteBuilderView && !this.IsGroupView && this.$route.name !== 'WebFormEditor' && this.$route.name !== 'WebFormBuilderNew'
     && this.$route.name === 'SystemConfig'
   },
    DBView(){
      return this.$route.name === 'FeaturedMemberViewer' || this.ActiveTemplateSite || this.SuiteTemplatesRoute ? false : 
      this.SignedIn && !this.IntranetView && this.RouterReady && !this.UserisGuest && !this.PublicPreview && !this.CatNavlistView && this.$route.name !== 'MyAccount'
      && this.$route.name !== 'GroupPage' && !this.StoreView && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor' && !this.AppBuilderView && !this.ActiveSuiteApp
      || this.WebsiteBuilderView && this.$route.meta && this.$route.meta.DataSingleBuilder && this.$route.name !== 'SystemConfig'
    },
    UserApps(){
        return this.AppsDataBase.filter(item => {
          return this.userLoggedIn.Apps.includes(item.id) || this.userIsAdmin
        })
      },
       SuiteAppView(){
        let routes = ['TableViewer','EntryViewer','StockMovement','SuiteApp','LedgersCollection']
        let routeprops = ['TableBuilder','DataSingleBuilder','DataCollection']
        let pass = routes.includes(this.$route.name)
        routeprops.map(prp => {
          if(this.$route.meta && this.$route.meta[prp]){
            pass = true
          }
        })
        //we COULD actually prevent if UserApps does not include
        return pass
      },
    // SuiteAppView(){
    //   return this.ActiveSuiteApp && this.$route.name !== 'GettingStarted' && !this.WebsiteBuilderView && !this.DocLibBuilderView && this.$route.name !== 'MyAccount' && !this.FeaturedMemberView &&
    //   !this.IntranetView && this.$route.name !== 'FeaturedMemberEditor'
    // },
    GroupNavView(){
      return this.$route.name === 'GroupPage' || this.$route.name === 'GroupPageEditor'
    },
    
    ComputedRouteStyle(){
      if(this.userLoggedIn && this.$route.name !== 'MyAccount' && !this.PublicPreview){
        return ''
      }
      else if(this.userLoggedIn && this.$route.name === 'MyAccount'){
        return 'z-index:1;'
      }
      else if(this.StoreView){
        return ''
      }
      else if(this.PublicPreview){
        return 'margin-top:198px;'
      }
      else if(this.LandingPageViewActive){
        return ''
      }
      else if(!this.WebsiteisActive){
        return ''
      }
      else if(this.$route.name === 'FeaturedMemberViewer' && this.ActiveFeaturedMember){
        let style = ''
        if(this.System.SystemBarEnabled){
          style = !this.ActiveFeaturedMember.PublicNavbarStyle || this.ActiveFeaturedMember.PublicNavbarStyle === 'Default' ? 'margin-top:239px;' : 
        this.ActivePage && this.ActivePage.ClearNavbarColor ? 'margin-top:20px;' : 'margin-top:90px;'
        }
        else{
          style = !this.ActiveFeaturedMember.PublicNavbarStyle || this.ActiveFeaturedMember.PublicNavbarStyle === 'Default' ? 'margin-top:219px;' : 
        this.ActivePage && this.ActivePage.ClearNavbarColor ? '' : 'margin-top:80px;'
        }
        return style
      }
      else{
        if(this.ActivePage && this.ActivePage.CatNavList){
           let style = ''
           if(this.System.SystemBarEnabled){
            style = !this.System.PublicNavbarStyle || this.System.PublicNavbarStyle === 'Default' ? 'margin-top:250px;' : 
            this.ActivePage && this.ActivePage.ClearNavbarColor ? '' : 'margin-top:125px;'
           }
           else{
            style = !this.System.PublicNavbarStyle || this.System.PublicNavbarStyle === 'Default' ? 'margin-top:250px;' : 
            this.ActivePage && this.ActivePage.ClearNavbarColor ? '' : 'margin-top:100px;'
           }
           return style
        }
        else{
          return !this.System.PublicNavbarStyle || this.System.PublicNavbarStyle === 'Default' ? 'margin-top:219px;' : 
        this.ActivePage && this.ActivePage.ClearNavbarColor ? '' : this.System.SystemBarEnabled ? 'margin-top:90px;' : 'margin-top:80px;'
        }
      }
    },
    AltPubNavbar(){
      return this.System.PublicNavbarStyle === 'STD Bar' && this.PublicNavView
    },
    ActivePage(){
      let page = ''      
      if(this.$route.path === '/' && this.System.Public_Landing_Page){
        page = this.SitePages.find(obj => obj.Name === this.System.Public_Landing_Page.split('/').join('').split('-').join(' '))
      }
      else if(this.$route.name === 'FeaturedMemberViewer'){
        if(this.ActiveFeaturedMember){
        page = this.ActiveFeaturedMember.Pages.find(obj => obj.id === this.$route.params.slug.split('-').join('_').split(' ').join('_'))
        }
      }
      else {
         page = this.SitePages.find(obj => obj.Name.split(' ').join('') === this.$route.path.split('/').join('').split('-').join(''))
      }
      //console.log('page',page,this.SitePages,this.$route.path,this.$route.path === '/')
      return page
    },
    PublicFooterEngage(){
      return this.HasPublicFooter && !this.userLoggedIn && this.RouterReady && this.$route.name !== 'login' && this.$route.name !== 'PhoneLogin' && this.$route.name !== 'signup' && this.$route.name !== 'UserInvite'
    },
    UserRequiredInfo(){
    return this.$store.state.UserRequiredInfo
  },
  UserInfoRequired(){
    return this.$store.state.UserInfoRequired
  },
  AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return this.carddarkcolor
        }
        else{
          return this.cardlightcolor
        }
      },
  UnreadNotifications(){
            return this.Notifications.filter(not => {
                return !not.Read
            })
        },
  UserisGuest(){
    return this.$store.state.UserisGuest && this.userLoggedIn 
    
  },
  FriendsList(){
    // if(this.userLoggedIn && this.userLoggedIn.id){
      return this.UsersStore.map(user => {
          this.FriendshipsStore.map(friendship => {
            //console.log(user.id, friendship[user.id])
          if(friendship[user.id]){
            user.IsFriend = true
          }
        })
        return user
    }).filter(user => {
      // console.log(user)
      // console.log(this.userLoggedIn,user.id)
      return user.id !== this.userLoggedIn.id && user.IsFriend
    })
    // }
    
  },
   FriendshipsStore() {
      return this.$store.state.Friendships ? this.$store.state.Friendships : []
    },
  UsersStore(){
            return this.$store.getters.getUsersArray
        },
  userLoggedIn () {
      return this.$store.getters.user
    },
    AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
  RendersClouds(){
    //removed || this.$route.name === 'MyAccount'
    if(this.$route.name === 'MyProfile'  || this.$route.name === 'login' || this.$route.name === 'PhoneLogin' || this.$route.name === 'signup' || this.$route.name === 'UserInvite' || this.$route.name === 'PasswordReset' || this.$route.meta && this.$route.meta.type === 'Data Tables'){
      if(!this.System.RemoveRenderClouds && this.$route.name !== 'businessunits' && this.$route.name !== 'users'){
        return true
      }
    }
    else{
      return false
    }
  },
  SunorMoonTexture(){
      var daytimecolor;
        var time = new Date().getHours();
        //console.log(time)
        if (time < 10) {
          return  ``
        } 
        else if (time < 18) {
          return  ``
        } else {
          return  ``
        }
        
  
    },
    SunorMoon(){
      var daytimecolor;
        var time = new Date().getHours();
        //console.log(time)
        if (time < 10 && !this.userLoggedIn) {
          return  'MorningSun'
        } 
        else if (time < 18 && !this.userLoggedIn) {
          return  'MiddaySun'
        } else if(!this.userLoggedIn) {
          return  'Moon'
        }
        
  
    },
    BGImageClass(){
      if(this.System && this.SystemisRA && this.$route.meta && this.$route.meta.SystemBuilder
      || this.System && this.SystemisRA && this.$route.meta && this.$route.meta.EntityBuilder){
      // || this.$route.meta.TableBuilder){
        return 'BuilderGradient footermargin relative'
      }
      else if(this.$route.meta.TableBuilder){
        return 'background footermargin relative'
      }
      else if(this.$route.params.id && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor'
      && this.$route.name !== 'FeaturedMemberViewer' && this.$route.name !== 'FeaturedMemberEditor'
      && this.$route.name !== 'landingpagesingle' && this.$route.name !== 'newlandingpage' && this.$route.name !== 'GroupPage'
      && !this.ActiveSuiteApp){      
        if(this.$route.name === 'StoreItemSingle'){
          return 'BGImage relative'
        }
        else if(this.$route.name === 'SystemConfig' || this.$route.name === 'GroupEdit'){
          return 'background BGImage relative'
        }
        else if(this.SuiteTemplateMain || this.SuiteWebsiteActive){
          return 'BGImage relative'
        }
        else{
         return 'BGImage footermargin relative' 
        }
        
      }
      else if(this.$route.name === 'MyDashboard'){
        return 'BGImage relative'
      }
      else if(this.$route.name === 'FeaturedMemberViewer'){
        return 'BGImage minifootermargin relative'
      }
       else if(this.$route.name === 'FeaturedMemberEditor'){
        return 'BGImage relative'
      }
      else if(this.userLoggedIn && this.$route.meta && this.$route.meta.type === 'Pages' || this.userLoggedIn && this.$route.name === 'PageEditor'){  
         //alert('yay')
          return 'background'
        }
      else if(!this.RendersClouds){
        return 'background'
      }
      else{
        var daytimecolor;
        var time = new Date().getHours();
        //console.log(time)
        if (time < 10) {
          daytimecolor = "morning";
        } 
        else if (time < 18) {
          daytimecolor = "blue"
        } else {
          daytimecolor = 'night'
        }  
        if(!this.userLoggedIn && this.$route.meta && this.$route.meta.type === 'Pages'){     
          return 'background'
        }
        else if(!this.userLoggedIn){     
          return 'BGImage relative '+daytimecolor
        }
        
        else{
          return 'BGImage footermargin relative '+daytimecolor
        }
      }
    },
    ShowGroupsasPortal(){
      return !this.System.GroupSinglePortal
    },
    ShowIntranetNavbar(){
      if(this.ShowGroupsasPortal){
        return this.IntranetNavView && !this.SearchView && this.$route.name !== 'GroupSingle' && this.$route.name !== 'DirectoryBuilder' && !this.ActiveSuiteApp
      }
      else{
        return this.IntranetNavView && !this.SearchView && !this.ActiveSuiteApp || this.TemplateSocialNavbarActive
      }
      
    },
    StyleMargin(){
      if(!this.ShowIntranetNavbar && !this.ActiveTemplateSite || this.ActiveTemplateSite && !this.SocialNetworkTemplate){
        return this.AppStylingView? 'margin-top:60px;' : ''
      }
      else if(this.$route.meta && this.$route.meta.RouteEventID === 1027){
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
          return this.AppStylingView? 'margin-top:340px;padding-bottom:180px;' : 'margin-top:200px;padding-bottom:50px;'
        }
        else{
          return this.AppStylingView? 'margin-top:340px;padding-bottom:180px;' : 'margin-top:200px;padding-bottom:50px;'
        }
        
      }
      else if(this.ShowIntranetNavbar || this.TemplateSocialNavbarActive){
        return this.ActiveTemplateSite && this.SocialNetworkTemplate ? this.SuiteSocialNetworkActive && this.$route.name === 'Suite Template Social Network' ? 'margin-top: 0px;' : 
        this.SuiteSocialNetworkActive ? 'margin-top: 220px;' : 'margin-top: 170px;' : this.$route.name === 'GroupSingle' && this.ShowGroupsasPortal || this.$route.name === 'DirectoryBuilder' ? 'padding-bottom:50px;' : 'margin-top:225px;padding-bottom:50px;'
      }
      else{
        return this.AppStylingView? 'margin-top:340px;' : this.TemplateGroupSingleView || this.TemplateDirectoryBuilderView ? '' : this.SuiteSocialNetworkActive ? '' : 'margin-top:205px;'
      }
      
    },
    BGImageCurrent(){
      if(this.$route.params.id || this.IntranetView){
        if(this.AppisDarkMode){
       return 'background'
       
        }
        else{
          return 'background'
       
        }
      }
      else{
        return ''
      }
    }
  },
  created(){
    this.$store.commit('setUsersArray',this.UsersArray)
    this.OldRAData()
    if(this.SystemisRA || process.env.VUE_APP_RA_PROVIDER_ID === process.env.PARENT_DB_ID){
      this.$store.commit('setNeedsRASync',false)
    }
    if(this.AppMonetizeBU){
      this.SetMonetizationBU()
    }
    if(this.System){
      this.SetActiveSystem()
    }
    //console.log(this.$store.state)
    // let root = document.documentElement;
    // root.style.setProperty('--app-linkscolor', `#FFA200`)
    //If not website only, then auth matters
  //   let Warehouseid = 'Z8WSyTNan523HzF3GOWk'
  //   let StockObj = 'gu4iIIppi9UHHC5JCzHl'
  //   db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(Warehouseid).collection('PurchaseRequisitionsLine_Items').where('Open_Allocations','==',true).where('StockObj.id','==',StockObj).orderBy('Created_On', 'asc').get()
  // .then(doc => {
  //   console.log('wh subcol index',doc.data());
  // })
  // .catch(error => console.log(error));
    this.CheckAuth()
    this.NowVar = setInterval(this.setNowTime,61000)
    setTimeout(() => {
      this.setNowTime()
    }, 5000);
    window.addEventListener('scroll', this.RecordSlider)
    window.addEventListener('scroll', this.handleScrollRecordToolbar)
    window.addEventListener('scroll', this.handleSiteDialogToolbar)
    //<!-- window.onload = this.RecordBannerParallax() -->
  },
  methods: {
    GetPlugins(){
      if(!this.PrimarySiteCalled && this.SystemisRA && this.$store.state.RAPlugins.length === 0 && this.userLoggedIn){
                  //alert('yay should call')
                  let pluginquery = db.collection('plugins')
                  let pluginpayload = {                
                    query: pluginquery,
                    arrayname: 'RAPlugins',
                    provider: 'RapidApps',
                    Subcolpath: 'carouselitems',
                    SubcolName: 'CarouselItems'
                    }
                    this.$store.dispatch('GetArrayandSubcols',pluginpayload).then(function(result) {    
                      //console.log(vm.$store.state.RAPlugins)
                    })
                }
    },
    OldRAData(){
    //   const firebaseConfig = {
    //   apiKey: "AIzaSyBizqYTsY9oNY_0U1L6evK9a22kjUyr9ps",
    //   authDomain: "rapidapps-7d6e7.firebaseapp.com",
    //   databaseURL: "https://rapidapps-7d6e7.firebaseapp.com",
    //   projectId: "rapidapps-7d6e7",
    //   storageBucket: "rapidapps-7d6e7.appspot.com",
    //   messagingSenderId: "674721806504",
    //   appId: "1:674721806504:web:4596bc44637e9f37eb6a9d",
    //   measurementId: "G-J7T6TGDTTB"
    // };
    //   let check = firebase.apps.find(obj => obj.name === 'RapidApps')
    //     if(!check){
    //      check = firebase.initializeApp(firebaseConfig, 'RapidApps')
    //     }
              
    //     let RADB = check.firestore()
    //   RADB.collection('pagepresets').onSnapshot(res => {
    //     const changes = res.docChanges();                 
    //               changes.forEach(change => {                 
    //                 if (change.type === 'added') {
    //                   let doc = {                 
    //                     ...change.doc.data(),                 
    //                     id: change.doc.id                 
    //                   } 
    //                   db.collection('pagepresets').doc(doc.id).set(doc)
    //                 }
    //   })
    //  })
    },
    SetActiveSystem(){
      if(this.IsPrimarySite){
        //console.log('setPrimarySystem')
          this.$store.commit('setPrimarySystem',this.System)
        }
        else{
        //console.log('setSiteSystem')
          this.$store.commit('setSiteSystem',this.System)
        } 
    },
    SetMonetizationBU(){
      if(this.IsPrimarySite){
          this.$store.commit('setPrimaryMonetizeBU',this.System.Monetization_BU)
        }
        else{
          this.$store.commit('setSiteMonetizationBU',this.System.Monetization_BU)
        } 
    },
    ActivateMainSite(){
      this.$store.commit('setActiveSuiteid',process.env.VUE_APP_RA_SYSTEM_ID)
      if(this.$route.path !== '/' && !this.userLoggedIn){
      this.$router.push('/')
      }
      else if(this.userLoggedIn && this.$route.path !== '/MyAccount'){
        this.$router.push('/MyAccount')
      }
    },
    AddRoute(route){
      let routes = this.$router.getRoutes()
      let oncheck = routes.find(obj => obj.name === route.name)
      if(!oncheck){
        this.$router.addRoute(route)
      }
    },
    PrepEntities(){
      let vm = this
      //console.log('PrepEntities,this.SystemEntitiesCall,this.GroupEntitiesCall',this.SystemEntitiesCall,this.GroupEntitiesCall)
      if(this.SystemEntitiesCall){
        this.SystemEntitiesCall();
      }
      if(this.GroupEntitiesCall){
        this.GroupEntitiesCall();
      }      
      this.ClearGetEntityRoutes()
      //GETENTITIES DONE IN ActivateUserAccess SURE
      //however
      //IT IS A FLOP! WE CALL EVERYTHING THEN DO EVERYTHING, BUT WHAT IF SOMETHING GOT ADDED?
      //SO OUR BEST APPROACH ON ENTITIES SI TO RATEHR KEEP A LISTENER. AND AS OPPOSED TO CHAINING STRINGS OF STUFF
      //1. CAL ENTITIES, START WITH "ALL PROPS"
      //2. FOR EACH, YES PERFORM ROUTES AND INTERACTION PERMISSIONS
      //3. KILL ENTRIES SNAPSHOT
      //IN ESSENCE AND INOTHER WORDS - AFTER GETANTITYALLPROPS, RUN ROUTES AND SECURITY. THEN WE DON'T NEED DO IT ANYWHERE ELSE

      //vm.GetEntityRoutes(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'))
      //THE REASON WE DO NOT CALL GETNTITIES AGAIN HERE IS BECAUSE ALREADY CALLED THROUGH ActivateUserAccess WHICH IS RECALLED ON SUITEID CHANGE
      // this.GetEntities(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'),'SystemEntities').then(entres => {
      //   console.log('entress',entres)
      //  // vm.GetEntityRoutes()
      // })
      //also have to add "'Query'" logic etc, but yeah this whole thing falls flat right here, perhaps only wokring once we alloe snapshot killing
    },
    ClearGetEntityRoutes(){
      let routes = this.$router.getRoutes()
      let entroutes = routes.filter(route => {
        return route.meta && route.meta.EntityID
        //!this.$store.state.roottableentities.includes(route.meta.EntityID)
      }).map(route => {
        return route.name
      })
      //console.log('entroutes',entroutes)
      //this.GetEntityRoutes(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'),entroutes)
    },
    GetEntityRoutes(DBPath,entroutes){
      DBPath.onSnapshot(res => {
                let routestoadd = []
                let CustomRolesDefault =  
                [
                {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
                {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
                {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
                {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
                {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
                ]
                let BURolesDefault = [
                  {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
                  {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
                  {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
                  {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
                  {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
                  {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
                  {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
                  {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
                  {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
                  {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
                  {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
                  {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
                  {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
                  {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
                  {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
                  {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
                  {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
                  {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
                  {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
                  {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
                ]
                let entitieslength = this.SystemEntities.length
                let counter = 0               
                 const changes = res.docChanges();                 
                changes.forEach(change => {                 
                  if (change.type === 'added') {
                    let entitydoc = {                 
                      ...change.doc.data(),                 
                      id: change.doc.id                 
                    } 
                  //console.log('entitydoc',entitydoc)
                  
                    let displaynamesplit = entitydoc.DisplayName.split(' ').join('')
                    let singlenamesplit = entitydoc.SingleName.split(' ').join('')
                    let entitysinglerouteobj = {
                      path: '/'+singlenamesplit+'/:id',
                      name: singlenamesplit.toLowerCase(),
                      component: DataSingleCall,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 1030,
                        type: 'DB Records',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        Collection: displaynamesplit.toLowerCase(),
                        DataSingleBuilder: true,
                        }
                    }
                    if(displaynamesplit === 'BusinessUnits'){
                      entitysinglerouteobj.component = BusinessUnit
                    }
                    else if(displaynamesplit === 'Users'){
                      entitysinglerouteobj.component = User
                    }
                    else if(displaynamesplit === 'Activities'){
                      entitysinglerouteobj.component = Activity
                    }
                    
                    if(entitydoc.Primary_Field_Name){
                      entitysinglerouteobj.meta.identifier = entitydoc.Primary_Field_Name
                    }
                    else if(entitydoc.RecordPrimaryField){
                      entitysinglerouteobj.meta.identifier = entitydoc.RecordPrimaryField.Name
                    }
                    if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'Store Product'){
                     let stockmovmentroute = {
                      path: '/StockMovement/'+entitydoc.id.split('_').join('')+'/:slug/:id',
                      name: 'StockMovement',
                      component: StockMovement,
                      meta: {
                        RouteEventID: 1054,
                        id: entitydoc.id,
                        icon: 'mdi-cube-send',
                        type: entitydoc.id+' Stock Movement',
                        subcat: entitydoc.id+'StockMovement',
                        viewicon: 'mdi-cube-send',
                        IsTopic: true,
                        TopicCategory: 'Stock Movement',
                        TopicName: 'Stock Movement',
                        EntityID: entitydoc.id,
                        }
                    }
                    //We need to resolve if a route exists, replace it with the new instance
                    // if(entroutes && entroutes.includes(entitydoc.id)){
                    //   this.$router.addRoute(stockmovmentroute)
                    // }
                    // else{
                    //   this.$router.addRoute(stockmovmentroute)
                    // }
                     this.AddRoute(stockmovmentroute)
                    }
                    
                    let entitycollectionrouteobj = {
                      path: '/'+displaynamesplit,
                      name: displaynamesplit.toLowerCase(),
                      component: TableMain,
                      meta: {
                        id: entitydoc.id,
                        icon: entitydoc.Table_Icon,
                        RouteEventID: 1029,
                        type: 'Data Tables',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        singlecat: entitydoc.SingleName,
                        viewicon: 'mdi-file-table-box',
                        IsTopic: true,
                        TopicCategory: 'Database',
                        TopicName: entitydoc.SingleName,
                        Collection: displaynamesplit.toLowerCase(),
                        TableBuilder: true
                        
                        },
                    }
                    if(displaynamesplit === 'BusinessUnits'){
                      entitycollectionrouteobj.component = BusinessUnits
                    }
                    else if(displaynamesplit === 'Users'){
                      entitycollectionrouteobj.component = Users
                    }
                    else if(displaynamesplit === 'Activities'){
                      entitycollectionrouteobj.component = Activities
                    }
                    if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'Entity Builder'){
                      entitycollectionrouteobj.meta.EntityBuilder = true
                    }
                    else if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'System Builder'){
                      entitycollectionrouteobj.meta.SystemBuilder = true
                      entitysinglerouteobj.meta.SystemBuilder = true
                      entitycollectionrouteobj.meta.SystemConfigBuilder = true
                    }
                    if(entitydoc.SingleRecordNavMenuView){
                      entitycollectionrouteobj.meta.SingleRecordNavMenuView = true
                    }
                    if(entitydoc.HasRecordTemplates){
                      entitycollectionrouteobj.meta.HasRecordTemplates = true
                    }
                    
                    if(entitydoc.Primary_Field_Name){
                      entitycollectionrouteobj.meta.identifier = entitydoc.Primary_Field_Name
                    }
                    else if(entitydoc.RecordPrimaryField){
                      entitycollectionrouteobj.meta.identifier = entitydoc.RecordPrimaryField.Name
                    }
                    let SecurityType = ''
                    if(entitydoc.datasetsecuritystructure){
                      SecurityType = entitydoc.datasetsecuritystructure
                    }
                    else if(entitydoc.Security_Structure){
                      SecurityType = entitydoc.Security_Structure.Name
                    }
                    if(SecurityType === 'Warehouse'){
                      entitycollectionrouteobj.meta.Security = {
                        SecurityType: 'Warehouse',
                      }
                      this.AddRoute(entitycollectionrouteobj)
                    }
                    else if(SecurityType === 'Custom Roles'){
                      
                      entitycollectionrouteobj.meta.Security = {
                        SecurityType: 'Custom Roles',
                      }
                      CustomRolesDefault.map((defrole,i) => {
                        if(entitydoc.id === 'Inventory_Articles'){
                          //console.log(i)
                        }
                        let array = entitydoc[defrole.Name]
                        let prop = entitydoc[defrole.Prop]
                        let boolean = entitydoc[defrole.Boolean]
                        
                        if(array){
                          entitycollectionrouteobj.meta.Security[defrole.Name] = array
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Name] = []
                        }
                        if(prop){
                          entitycollectionrouteobj.meta.Security[defrole.Prop] = prop
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Prop] = 'Custom Role'
                        }
                        if(boolean){
                          entitycollectionrouteobj.meta.Security[defrole.Boolean] = boolean
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Boolean] = false
                        }
                        if(i === 4){
                          this.AddRoute(entitycollectionrouteobj)
                        }
                        
                      })
          
                    }
                    else if(SecurityType === 'Business Unit'){
                      entitycollectionrouteobj.meta.Security = {
                        SecurityType: 'Business Unit',
                        AllgetRoles: [],
                        UnitDowngetRoles: [],
                        UserUnitgetRoles: [],
                        OwnergetRoles: [],
                        AlllistRoles: [],
                        UnitDownlistRoles: [],
                        UserUnitlistRoles: [],
                        OwnerlistRoles: [],
                        AllcreateRoles: [],
                        UnitDowncreateRoles: [],
                        UserUnitcreateRoles: [],
                        OwnercreateRoles: [],
                        AllupdateRoles: [],
                        UnitDownupdateRoles: [],
                        UserUnitupdateRoles: [],
                        OwnerupdateRoles: [],
                        AlldeleteRoles: [],
                        UnitDowndeleteRoles: [],
                        UserUnitdeleteRoles: [],
                        OwnerdeleteRoles: [],
                      }
                      BURolesDefault.map((defrole,i) => {
                        let array = entitydoc[defrole.Name]
                        if(array){
                          entitycollectionrouteobj.meta.Security[defrole.Name] = array
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Name] = []
                        }
                        if(i === 15){
                          this.AddRoute(entitycollectionrouteobj)
                        }
                      })
                      
                    }
                   
                    this.AddRoute(entitysinglerouteobj)
                    //router.addRoute(entitycollectionrouteobj)
                    //entity type specials...
                    if(entitydoc.Has_Wiki){
                    let wikiingleeditrouteobj = {
                      path: '/WikiPreview/'+singlenamesplit+'/:id',
                      name: singlenamesplit.toLowerCase()+'wikibuilder',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 6000,
                        type: 'Wiki Editor',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'wiki',
                        WikiListingBuilder: true,
                        }
                    }
                    this.AddRoute(wikiingleeditrouteobj)
          
                    let wikisingleviewerrouteobj = {
                      path: '/'+singlenamesplit+'-Wiki/:id',
                      name: singlenamesplit.toLowerCase()+'wikisingle',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 6001,
                        type: 'Wiki Viewer',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'wiki',
                        }
                    }
                    //console.log(wikisingleviewerrouteobj)
                    this.AddRoute(wikisingleviewerrouteobj)
                  }
                    if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'Classified Listing'){
                      let clsfdlistingsingleeditrouteobj = {
                      path: '/ClassifiedPreview/'+singlenamesplit+'/:id',
                      name: singlenamesplit.toLowerCase()+'classifiedbuilder',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 5000,
                        type: 'Classified Listing Editor',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'listings',
                        ClassifiedListingBuilder: true,
                        SingleName: entitydoc.SingleName
                        }
                    }
                    this.AddRoute(clsfdlistingsingleeditrouteobj)
                    let clsfdlistingsingleviewerrouteobj = {
                      path: '/'+singlenamesplit+'-Classified/:id',
                      name: singlenamesplit.toLowerCase()+'classifiedsingle',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 5001,
                        type: 'Classified Listing Viewer',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'listings',
                        }
                    }
                    this.AddRoute(clsfdlistingsingleviewerrouteobj)
                   }
                    // console.log('router.getRoutes()',router.getRoutes())
                    // console.log('router',router.options.routes)
                    let storeentityobj = {
                      ArrayName: displaynamesplit+'Array',
                      QueryName: displaynamesplit+'Query'
                    }
                    // store.state[storeentityobj.ArrayName] = []
                    // store.state[storeentityobj.QueryName] = ''
                    let newobj = {Name: storeentityobj.ArrayName,Data: []}
                    this.$store.state.TableData.push(newobj)
                    this.$store.dispatch('AddEntityObj', storeentityobj)
                    counter++
                    //console.log('calling RoutesandSecurity ? '+entitieslength === counter)
                    if(entitieslength === counter){
                      this.RoutesandSecurity()
                    }
                  }
                })
      })
    },
    CheckSiteModerators(){
      //really just, the absolute simplest way to resolve moderation issues is to add the administrator as a site moderator on load of the initial App. 
      //This I am however keeping blank for now until we absolutely happy with the repercussions, because it would mean all admins can always do all content and in some cases of course not desirted. 
      //But it does howver seem true that more often admin must do all, and only exceptional cases require that admin NOT be allowed
      //So my just about final thoughts being this is perect, add admin as sitemoderator. uhm. until you don't want him. Yeah you see? If app load always adds admin how would you handle
      //NOt adding when you don't want admin as sitemoderator?
    },
    ChecktoCopyEntityDefaults(){
      let vm = this
      let collection = ['Sales_Invoices','Users','System_Tickets']
      collection.map(col => {
        db.collection('Databases').doc(vm.ActiveSuiteid).collection('entities').doc(col).get().then(snapshot => {
          let docdata = snapshot.data()
          if(!docdata){
            console.log('adding ',col)
            let configdb = this.RADB.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('entities')
            configdb.doc(col).get().then(rasnapshot => {
            let build = rasnapshot.data()
              build.id = col
              if(build.Entity_Type && build.Entity_Type.Name === 'Survey Form'){
                vm.GetSurveyElements(configdb,build)
              }
              vm.GetAllEntityProps(configdb,build).then(function(allpropsresult) {
                //console.log('allpropsresult',allpropsresult,build)                          
                  if(allpropsresult){
                      
                      let finalbuild = Object.assign({},build)
                      let fields = build.AllFields
                      let deleteprops = ['PluginEntityid','Pluginid','Plugins','Primary_Appid','Creating_Plugin','PluginEntityid','Creating_Pluginid',
                      'AllFields','CanCreate','CanEdit','PluginEntityid','PluginEntityid','DocumentRegisters','EntityProcesses','FilterMatch','Galleries',
                      'GoalMeasurements','Template_System','Template_Systemid','Templateid','automations']
                      deleteprops.map(prp => {
                        delete finalbuild[prp]  
                      })
                      if(build.Security_Structure.Name === 'Business Unit'){
                        vm.BURolesDefault.map(role => {
                          finalbuild[role.Name] = []
                        })
                      }
                      else if(build.Security_Structure.Name === 'Custom Roles'){
                        vm.CustomRolesDefault.map(role => {
                          finalbuild[role.Name] = []
                        })
                      }
                      finalbuild.Created_On = new Date()
                      finalbuild.Modified_On = new Date()
                      finalbuild.Created_By = {id: vm.RAUserObj.id, Name: vm.RAUserObj.Name, Surname: vm.RAUserObj.Surname, Full_Name: vm.RAUserObj.Full_Name}
                      finalbuild.Created_Byid = vm.RAUserObj.id
                      finalbuild.Modified_By = {Name: vm.RAUserObj.Name,Surname: vm.RAUserObj.Surname,Full_Name: vm.RAUserObj.Full_Name,id: vm.RAUserObj.id},
                      finalbuild.Modified_Byid = vm.RAUserObj.id
                      //add to RA, AND add to client...
                      vm.RADB.collection('systems').doc(vm.ActiveSuiteid).collection('entities').doc(col).set(finalbuild).then(radoc => {
                        fields.map((field,fieldindex) => {
                          vm.RADB.collection('systems').doc(vm.ActiveSuiteid).collection('entities').doc(col).collection('AllFields').doc(field.id).set(field).then(rafield => {
                            if(fieldindex-1+2 === fields.length){
                              db.collection('Databases').doc(vm.ActiveSuiteid).collection('entities').doc(col).set(finalbuild).then(clientdoc => {
                                  fields.map((field,fieldindex) => {
                                    db.collection('Databases').doc(vm.ActiveSuiteid).collection('entities').doc(col).collection('AllFields').doc(field.id).set(field).then(clientfield => {
                                     
                                    })
                                  })
                                })
                            }
                          })
                        })
                      })
                  }
              })
            })
            }
            else{
              console.log('already have ',col)
            }
        })        
      })      
    },
    ChecktoCopyAccountingLedger(){
        let collection = ['General']
              collection.map(col => {
                  let docpath = db.collection('Databases').doc(this.ActiveSuiteid).collection('Ledgers').doc(col)
                  docpath.get().then(snapshot => {
                      let docdata = snapshot.data()
                      if(!docdata){
                        this.RADB.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('Ledgers').doc(col).get().then(snapshot => {
                            let radocdata = snapshot.data()
                            let docid = col
                            db.collection('Databases').doc(this.ActiveSuiteid).collection('Ledgers').doc(docid).set(radocdata)
                        }) 
                      }
                  })
              })
      },
    ActivateInteractBounce(docelmntid){
            let docelmnt = document.getElementById(docelmntid)
            //console.log(docelmntid,docelmnt)
            if(docelmnt){
                docelmnt.classList.add('interactbounce')
                setTimeout(() => {
                        docelmnt.classList.remove('interactbounce')
                }, 5000);
            }
        },	
    
    
    GetTrackingOrders(){
      if(this.userLoggedIn.TrackedEntity && this.userLoggedIn.TrackFilter){
        let payload = {                
          query: db.collection(this.userLoggedIn.TrackedEntity.split('_').join('').toLowerCase()).where('Userid','==',this.userLoggedIn.id).where('Status.ID','==',this.userLoggedIn.TrackFilter),
          arrayname: 'TrackingOrders',
          }
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
            //console.log(vm.$store.state.RAPlugins)
          })
      }
    },
    GetMyCompany(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('usercompanies').doc(this.userLoggedIn.Companyid).onSnapshot(snapshot => {
        let usercomp = snapshot.data()
        usercomp.id = this.userLoggedIn.Companyid
        if(usercomp.Userid === this.userLoggedIn.id){
          this.$store.commit('setUserisCompanyAdmin',true)
          usercomp.LoanOrderTables = {}
          usercomp.StoreOrderTables = {}
          if(usercomp.LoanEntities){
            usercomp.LoanEntities.map(ent => {
              let payload = {                
                query: db.collection(ent.split('_').join('').toLowerCase()).where('Companyid','==',usercomp.id),
                arrayname: ent.split('_').join(''),
                prop: 'LoanOrderTables'
                }
                //console.log(payload,this.$store.state)
                
                this.$store.dispatch('GetCompanyCollectionArray',payload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
            })
          }
          //okay now we can check the entities. But bear in mind bob I still don't know how much this helps. And the reason I say that is simple
          //"CompanyLoanOrders" or "CompanyStoreOrders" alike could now really be a little confusing. If we don't need to interact with them, fine. If we do, uh oh
        }
        else{
          this.$store.commit('setUserisCompanyAdmin',false)
        }
        this.$store.commit('setMyCompany',usercomp)
      })
    },
    GetSentMessages(){
      let payload = {                
          query: db.collection('usermessages').where('senderid','==',this.userLoggedIn.id),
          arrayname: 'SentMessages',
          }
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
            //console.log(vm.$store.state.RAPlugins)
          })
      },
      GetReceivedMessages(){
        let payload = {                
          query: db.collection('usermessages').where('recipientid','==',this.userLoggedIn.id),
          arrayname: 'ReceivedMessages',
          }
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
            //console.log(vm.$store.state.RAPlugins)
          })
      },
    
    DeactivateActiveChatRecord(){
      this.$store.commit('SetSocialItemInteractMethod','DeactivateActiveChatRecord')
    },
    ExitAppBuilder(){
      this.$store.commit('setBuilderView','')
      if(this.$route.name === 'PageEditor'){
        this.$router.push('/'+this.$route.params.id)
      }
      else if(this.IsGroupView){        
        this.$router.push('/Group/'+this.GroupID)
      }
    },
    CheckConfigSystemTabs(){
      let vm = this
      this.GotConfigMaster = true
      this.DBMasterPath.collection('entities').doc('Systems').get().then(snapshot => {
          let systemdata = snapshot.data()
          systemdata.id = 'Systems'
          systemdata.AllFields = []
          vm.DBMasterPath.collection('entities').doc('Systems').collection('AllFields').onSnapshot(res => {
            const changes = res.docChanges();
            let fieldslength = res.docs.length
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    systemdata.AllFields.push(item)
                }
                else if (change.type === 'modified') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = systemdata.AllFields.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = systemdata.AllFields.indexOf(oncheck)
                      systemdata.AllFields.splice(arrayobjindex, 1, item);
                    }
                } 
                })
                if(systemdata.AllFields.length === fieldslength){
                  this.$store.commit('SetSystemConfigEntity',systemdata)
                  this.$store.commit('SetSystemConfigTabs',systemdata.SingleBuildTabs)
                }
          })
      })
      
      },
    SetViewOrderNavbar(){
      this.$store.commit('SetViewOrderNavbar',!this.$store.state.ViewOrderNavbar)
    },
    ActivateStoreSearch(search){
      this.$store.commit('SetStoreSearch',search)
    },
    WarehouseQueries(){
      let vm = this
      let routes = vm.$router.getRoutes()
      let whroutes = routes.filter(route => {
        return route.meta && route.meta.Security && route.meta.Security.SecurityType === 'Warehouse'
      })
      //console.log('whroutes',whroutes)
        whroutes.map((route,routeindex) => {
          
            let payload = {
              collectionname: route.name.split(' ').join('').toLowerCase(),
              collectionstate: route.meta.subcat.split(' ').join('')+'Query',
              query: []
            }
            //no UserTableFilters would apply here 
            if(this.WarehousesArray.length === 0){
              vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
               }) 
            }
            this.WarehousesArray.map((wh,whindex) => {
              //console.log('this.userLoggedIn.Warehouses[wh.id].List',this.userLoggedIn.Warehouses[wh.id].List)
              if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].List || this.userIsAdmin){
                payload.query.push(db.collection(route.name.split(' ').join('').toLowerCase()).where('Warehouseid','==',wh.id))
              }
              if(whindex-1+2 === this.WarehousesArray.length){
                //alert('done wh queryd')
                //console.log('payload',payload,route.name.split(' ').join('').toLowerCase(),'Warehouseid','==',wh.id)
                vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
               }) 
              }
            })
        })
    },
    RemoveItemfromCart(product){
      let storeitem = this.CartItems.find(obj => obj.id === product.id)
      let index = this.CartItems.indexOf(storeitem)
      confirm('Are you sure you want to remove this item from the Cart?') && this.CartItems.splice(index,1)
    },
    ProcessClearStoreCart(){
      this.CartItems = []
    },
    AddItemtoCart(product){
      let oncheck = this.CartItems.find(obj => obj.id === product.id)
      if(!oncheck){
        this.CartItems.push(product)
      }
      else{
        oncheck.Qty = Number(product.Qty)+Number(oncheck.Qty)
      }
      //console.log(this.CartItems)
    },
    ActivateStoreView(boolean){
      //alert('sdfsdlhfhsdfhsdfh')
      this.StoreView = boolean
       if(!boolean && this.UserisGuest){
        if(this.ActiveMemberGroup && this.ActiveMemberGroup.Guest_Landing_Page){
          if(this.ActiveMemberGroup.Guest_Landing_Page.includes('/FeaturedMember/')){
            console.log(this.ActiveFeaturedMember)
            //this.$router.push('/FeaturedMember/'+this.userLoggedIn.id+'/')
          }
          else{
            this.$router.push(this.ActiveMemberGroup.Guest_Landing_Page)
          }
        }
        //  if(!this.System.Guest_Landing_Page){
        //     this.$router.push('/MyAccount')
        //   }  
        //   else{
        //     this.$router.push(this.System.Guest_Landing_Page)
        //   }
        if(this.$route.name !== 'MyAccount'){
         this.$router.push('/MyAccount')
        }
       }
      //  if(boolean && this.UserisGuest){
      //    this.$router.push('/MyAccount')
      //  }
    },
    PushActiveProduct(product){
      this.ActiveProduct = product
    },
    GetSubscriptionPackages(){
      let PackagesRef = db.collection('SystemConfig').doc(this.ActiveSuiteid).collection('SubscriptionPackages')
      PackagesRef.onSnapshot(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.SubscriptionPackages.push(item)
                }
                else if (change.type === 'modified') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.SubscriptionPackages.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = this.SubscriptionPackages.indexOf(oncheck)
                      this.SubscriptionPackages.splice(arrayobjindex, 1, item);
                    }
                   
                } 

                })
      })
    },
    
    AssignPushedNavItem(item){
      this.PushedNavItem = item
      if(this.$route.name !== 'My Dashboard' && item){
        this.$router.push('/MyDashboard')
      }
    },
    ToggleTimesheetDialog(item){
      this.TimesheetDialogActive = !this.TimesheetDialogActive
      if(!item){
        this.TimesheetRelated = ''
      }
      else{
        this.TimesheetRelated = item
      }
    },
    SetActivityRegarding(item){
      console.log(item)
      if(item){
        this.ActivityRegarding = item
      }
      else{
        this.ActivityRegarding = ''
      }
    },
    UpdateGroupNavigationalItems(array){
      this.GroupNavigationalItems = array
    } ,   
    CheckLocation(){
      navigator.geolocation.getCurrentPosition(position => {
        console.log('CheckLocation',position)
        this.ShowUserLocation(position.coords.latitude,position.coords.longitude)
          //this.getAddressFrom(position.coords.latitude,position.coords.longitude)
          // Show a map centered at latitude / longitude.
        });
    },
    ShowUserLocation(lat,long){
      let map = new google.maps.Map(document.getElementById('map'),{
          zoom: 15,
          center: new google.maps.LatLng(lat, long),
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        let icon = {
            url: "@/assets/BlankProfilePic.png", // url
            scaledSize: new google.maps.Size(50, 50), // scaled size
            origin: new google.maps.Point(0,0), // origin
            anchor: new google.maps.Point(0, 0) // anchor
        };
        let label = {
            text: this.userLoggedIn.Full_Name,
            color: "#C70E20",
            fontWeight: ""
          }
        let marker = {
          position: new google.maps.LatLng(lat, long),
          map: map,
          icon: icon,
          label: label

        }
        if(this.userLoggedIn.Profile_Photo){
          marker.icon.url = this.userLoggedIn.Profile_Photo
        }
        new google.maps.Marker(marker)
    },
    TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
    setNowTime(){
      let now = new Date()
      let nowtime = now.getTime()
      this.NowTime = nowtime
      //console.log('setNowTime this.NowTime',this.NowTime)
    },
    ToggleReminderDialog(){
      this.ReminderDialog = !this.ReminderDialog
    },
    ToggleChatbotActive(){
      this.ChatbotActive = !this.ChatbotActive
    },
    
    ToggleRAAppAdminDialog(boolean){
      this.RAAppAdminDialog = boolean
    },
    updateOnlineStatus(e) {
            const {
                type
            } = e;
            this.onLine = type === 'online';
        },
    GetAppFonts(){
      this.ConfigMasterPath.collection('AppFonts').onSnapshot(res =>{
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.FontOptions.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })        
      })
    },
    
    ToggleAppQuickStartDialog(){
      this.$store.commit('setAppQuickStartDialog',!this.$store.state.AppQuickStartDialog)
    },
    ToggleCatNavlistView(boolean){
      this.CatNavlistView = boolean
      if(this.AppStylingView){
        this.AppStylingView = false
      }
    },
    TogglePublicPreview(boolean){
      if(boolean === true){
        this.PublicPreview = boolean
        if(this.System.Public_Landing_Page){
          this.$router.push(this.System.Public_Landing_Page)
        }
        
      }
      else if(this.IntranetView === false){
        this.PublicPreview = boolean
        this.$router.push('/MyDashboard')
      }
      
      
    },
    RefreshRouter(){
      this.RefreshRouteView = true
      setTimeout(() => {
          this.RefreshRouteView = false
      }, 1);
    },
    ActivateRecordLoadingDialog(boolean,int){
      this.RecordLoading = boolean
      this.RecordLoadingvalue = int
    },
    SetColorstoSystemColors(System){
      this.SettingColorstoSystemColors = true
      setTimeout(() => {
        this.SettingColorstoSystemColors = false
      }, 100);
    },
    
    GetSystemGuides(query,provider){
      //let vm = this
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let guidedoc = change.doc.data()
            guidedoc.id = change.doc.id
            if(provider){
              guidedoc.Provider = provider 
            }
            guidedoc.CarouselItems = []
            query.doc(guidedoc.id).collection('carouselitems').onSnapshot(galres => {
              const galchanges = galres.docChanges();
              let gallength = galres.docs.length
              galchanges.forEach((galchange,i) => {
                if (change.type === 'added') {
                  guidedoc.CarouselItems.push({
                    ...galchange.doc.data(),
                    id: galchange.doc.id})
                  if(guidedoc.CarouselItems.length === gallength){
                    this.SystemGuides.push(guidedoc)
                  }
                }
               
              })
              if(gallength === 0){
                this.SystemGuides.push(guidedoc)
              }
            })
          }
           if(change.type === 'modified'){
                  let guideobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                  //console.log('guideobj change',guideobj)
                  let oncheck = this.SystemGuides.find(obj => obj.id === guideobj.id)
                  if(oncheck){
                    let index = this.SystemGuides.indexOf(oncheck)
                    guideobj.CarouselItems = oncheck.CarouselItems
                    this.SystemGuides.splice(index,1,guideobj)
                    //console.log('this.SystemGuides update',this.SystemGuides)
                  }
                }
        })
      })
    },
    GetDocumentLibraries(){
      db.collection('Libraries').doc(this.ActiveSuiteid).collection('DocumentLibraries').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            this.SystemDocumentLibraries.push(photolibdoc)
            let libraryroute = {
              path: '/'+photolibdoc.Collection,
              name: photolibdoc.DisplayName.split(' ').join('').toLowerCase(),
              component: DataCollectionViewComponent,
              meta: {
                id: photolibdoc.id,
                icon: photolibdoc.Icon,
                photolibrary: true,
                type: 'Document Libraries',
                subcat: photolibdoc.DisplayName,
                EntityID: photolibdoc.id,
                viewicon: 'mdi-camera-image',
                IsTopic: true,
                TopicCategory: 'Media Library',
                TopicName: photolibdoc.DisplayName+' Document Library',
                Security: {
                  SecurityType: 'Custom Roles',
                  CreateisCustom: true,
                  CreatePermission: 'Custom Role',
                  CustomCreateRoles: [],
                  GetisCustom: false,
                  GetPermission: 'Signed In',
                  CustomGetRoles: [],
                  ListisCustom: false,
                  ListPermission: 'Signed In',
                  CustomListRoles: [],
                  EditisCustom: true,
                  EditPermission: 'Custom Role',
                  CustomUpdateRoles: [],
                  DeleteisCustom: false,
                  DeletePermission: 'Admin',
                  CustomDeleteRoles: [],

                }
                },
              
            }
            // if(photolibdoc.PublishType === 'Public'){
            //   libraryroute.meta.RouteEventID = 1028
            // }
            // else{
            //   libraryroute.meta.RouteEventID = 1027
            // }
            // if(Public_Landing_Page && Public_Landing_Page === '/'+photolibdoc.Name.split(' ').join('-')){
            //   pageroute.path = '/'
            // }
            // else{
            //   pageroute.path = '/'+photolibdoc.Name.split(' ').join('-')
            // }
            // path: '/'+photolibdoc.Name.split(' ').join('-'),
        
            this.AddRoute(libraryroute)
          }
          if(change.type === 'modified'){
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            let oncheck = this.SystemDocumentLibraries.find(obj => obj.id === photolibdoc.id)
            if(oncheck){
              let index = this.SystemDocumentLibraries.indexOf(oncheck)
              this.SystemDocumentLibraries.splice(index,1,photolibdoc)
            }
          }
        })
      })
    },
    GetPhotoLibraries(){
      db.collection('Libraries').doc(this.ActiveSuiteid).collection('PhotoLibraries').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            this.SystemPhotoLibraries.push(photolibdoc)
            let libraryroute = {
              path: '/'+photolibdoc.Collection,
              name: photolibdoc.DisplayName.split(' ').join('').toLowerCase(),
              component: DataCollectionViewComponent,
              meta: {
                id: photolibdoc.id,
                icon: photolibdoc.Icon,
                photolibrary: true,
                type: 'Photo Libraries',
                subcat: photolibdoc.DisplayName,
                EntityID: photolibdoc.id,
                viewicon: 'mdi-camera-image',
                IsTopic: true,
                TopicCategory: 'Media Library',
                TopicName: photolibdoc.DisplayName+' Page',
                Security: {
                  SecurityType: 'Custom Roles',
                  CreateisCustom: true,
                  CreatePermission: 'Custom Role',
                  CustomCreateRoles: [],
                  GetisCustom: false,
                  GetPermission: 'Signed In',
                  CustomGetRoles: [],
                  ListisCustom: false,
                  ListPermission: 'Signed In',
                  CustomListRoles: [],
                  EditisCustom: true,
                  EditPermission: 'Custom Role',
                  CustomUpdateRoles: [],
                  DeleteisCustom: false,
                  DeletePermission: 'Admin',
                  CustomDeleteRoles: [],

                }
                },
              
            }
            // if(photolibdoc.PublishType === 'Public'){
            //   libraryroute.meta.RouteEventID = 1028
            // }
            // else{
            //   libraryroute.meta.RouteEventID = 1027
            // }
            // if(Public_Landing_Page && Public_Landing_Page === '/'+photolibdoc.Name.split(' ').join('-')){
            //   pageroute.path = '/'
            // }
            // else{
            //   pageroute.path = '/'+photolibdoc.Name.split(' ').join('-')
            // }
            // path: '/'+photolibdoc.Name.split(' ').join('-'),
           
            this.AddRoute(libraryroute)
          }
          if(change.type === 'modified'){
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            let oncheck = this.SystemPhotoLibraries.find(obj => obj.id === photolibdoc.id)
            if(oncheck){
              let index = this.SystemPhotoLibraries.indexOf(oncheck)
              this.SystemPhotoLibraries.splice(index,1,photolibdoc)
            }
          }
        })
      })
    },
    async ClientAppAdminSignin(){
      //console.log(firebase.apps)
           
              console.log('RAApp Active')
              // this.GetAppFonts()
              let vm = this
              await this.RAApp.auth().onAuthStateChanged(function(user) {
               
                  if(user){
                  //  vm.ChecktoCopyAccountingLedger()
                  //  vm.ChecktoCopyEntityDefaults()
                   vm.CheckSiteModerators()
                    let currentuserpayload = {
                      userid: user.uid,
                      db: vm.RADB,
                      RapidAppsDB: true
                  }
                  //console.log('currentuserpayload',currentuserpayload)
                  vm.$store.dispatch('SetUserObj',currentuserpayload).then(function(result) {
                      //console.log('RAUserObj',vm.$store.state.RAUserObj)
                      vm.RAAppAdminSignedIn = true
                      vm.RAAppAdminUserBase = user
                      //console.log('vm.RAAppAdminUserBase',vm.RAAppAdminUserBase)
                      let provider = {
                        Name: 'RapidApps',                      
                      }
                      //must reactivate once restructure takes palce
                      //vm.GetDocumentationLibraries(vm.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries'),vm.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries'),vm.RADB,process.env.VUE_APP_RA_PROVIDER_ID)
                      if(vm.$store.state.RAPluginPackages.length === 0){
                        let pluginpackquery = vm.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('SubscriptionPackages').where('Type.Name','==','Suite Plugin Package')
                      let pluginpackpayload = {                
                        query: pluginpackquery,
                        arrayname: 'RAPluginPackages'
                        }
                        vm.$store.dispatch('GetCollectionArray',pluginpackpayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPluginPackages)
                          })
                      }
                      if(vm.$store.state.RAPlugins.length === 0){
                        //moved away from vm.RADB - need to move back to it only option 3 stuff where client db used, not rlevant so straight db until then
                          let pluginquery = db.collection('plugins')
                      let pluginpayload = {                
                        query: pluginquery,
                        arrayname: 'RAPlugins',
                        provider: provider,        
                        Subcolpath: 'carouselitems',
                        SubcolName: 'CarouselItems'
                        }
                        vm.$store.dispatch('GetArrayandSubcols',pluginpayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPlugins)
                        })
                      }                      
                      
                      //vm.GetSystemGuides(vm.RADB.collection('plugins'),provider)
                      })
                      if(vm.$store.state.EntitiesArray.length === 0){
                      let provider = {
                        Name: 'RapidApps',                      
                      }
                      let entitiespayload = {                
                        query: vm.RADB.collection('entities'),
                        arrayname: 'EntitiesArray',
                        provider: provider,    
                          SubCollections: [
                            {
                              Subcolpath: 'AllFields',
                              SubcolName: 'AllFields'
                            },
                            {
                              Subcolpath: 'SubCollections',
                              SubcolName: 'SubCollections'
                            },
                            {
                              Subcolpath: 'EntityProcesses',
                              SubcolName: 'EntityProcesses'
                            },
                            {
                              Subcolpath: 'DigitizedForms',
                              SubcolName: 'DigitizedForms'
                            },
                            {
                              Subcolpath: 'DocumentRegisters',
                              SubcolName: 'DocumentRegisters'
                            },
                            {
                              Subcolpath: 'Galleries',
                              SubcolName: 'Galleries'
                            },
                            {
                              Subcolpath: 'automations',
                              SubcolName: 'automations'
                            },
                          ]       
                        }
                        vm.$store.dispatch('GetArrayandMultipleSubcols',entitiespayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPlugins)
                        })
                         let configentitiespayload = {                
                        query: vm.RADB.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('entities').where(firebase.firestore.FieldPath.documentId(), 'in',['Systems','Entities','Plugins']),
                        arrayname: 'ConfigEntitiesArray',
                        provider: provider,   
                        subcolquery: vm.RADB.collection('Databases').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('entities'),
                          SubCollections: [
                            {
                              Subcolpath: 'AllFields',
                              SubcolName: 'AllFields'
                            },
                            {
                              Subcolpath: 'SubCollections',
                              SubcolName: 'SubCollections'
                            },
                            {
                              Subcolpath: 'EntityProcesses',
                              SubcolName: 'EntityProcesses'
                            },
                            {
                              Subcolpath: 'DigitizedForms',
                              SubcolName: 'DigitizedForms'
                            },
                            {
                              Subcolpath: 'DocumentRegisters',
                              SubcolName: 'DocumentRegisters'
                            },
                            {
                              Subcolpath: 'Galleries',
                              SubcolName: 'Galleries'
                            },
                            {
                              Subcolpath: 'automations',
                              SubcolName: 'automations'
                            },
                          ]       
                        }
                        console.log(configentitiespayload)
                        vm.$store.dispatch('GetArrayandMultipleSubcols',configentitiespayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPlugins)
                        })
                      }
                    
                  }
                  else{
                    
                      vm.ToggleRAAppAdminDialog(true)
                  }
              })
    },
    GetSystemTableFilters(){
      db.collection('Databases').doc(this.ActiveSuiteid).collection('SystemTableFilters').onSnapshot(res =>{
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let filterobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.SystemTableFilters.push(filterobj)
            }
            else if (change.type === 'modified') {
              let filterobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              let oncheck = this.SystemTableFilters.find(obj => obj.id === filterobj.id)
              if(oncheck){
                let index = this.SystemTableFilters.indexOf(oncheck)
                this.SystemTableFilters[index] = filterobj
              }
            }
        })
      })
    },
    GetDBNavLists(){
      db.collection('Databases').doc(this.ActiveSuiteid).collection('DBNavlists').onSnapshot(res =>{
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let navlistobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.DBNavLists.push(navlistobj)
            }
        })
      })
    },
    GetSocialCategories(query,cat){
      this.System[cat] = []
      //used to do below, but since auth change could change categories "Websites" or "SocialNetworks" making array blank always
      // if(!this.System[cat]){
      //   this.System[cat] = []
      // }
      query.onSnapshot(res =>{
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let catobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.System[cat].push(catobj)
            }
        })
      })
    },
    GetWarehouseSuppliers(){
      let dpayload = {                
        query: db.collection('WarehouseSuppliers'),
        arraymutation: 'setWarehouseSuppliers',
        getter: 'getWarehouseSuppliers',
            arrayname: 'WarehouseSuppliersArray'
      }
      let qpayload = {
          collectionname: 'WarehouseSuppliers',
          collectionstate: 'WarehouseSuppliersQuery',
          query: db.collection('WarehouseSuppliers')
        }  
        this.$store.dispatch('SetUserQueryPermission',qpayload)
        this.$store.dispatch('GetCollectionArray',dpayload)
    },
    GetWarehouses(){
      let dpayload = {                
        query: db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses'),
        arraymutation: 'setWarehouses',
        getter: 'getWarehouses',
            arrayname: 'WarehousesArray'
      }
      let qpayload = {
          collectionname: 'Warehouses',
          collectionstate: 'WarehousesQuery',
          query: db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses')
        }  
        if(this.userLoggedIn){
          if(!this.UserisGuest){
          this.$store.dispatch('SetUserQueryPermission',qpayload)
          this.$store.dispatch('GetCollectionArray',dpayload)  
          }
          else{
          dpayload.query = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').where('Guests_Allowed','==',true) 
          qpayload.query = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').where('Guests_Allowed','==',true) 
          this.$store.dispatch('SetUserQueryPermission',qpayload)
          this.$store.dispatch('GetCollectionArray',dpayload) 
        }
        }
        else{
          dpayload.query = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').where('Is_Public','==',true) 
          qpayload.query = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').where('Is_Public','==',true) 
          this.$store.dispatch('SetUserQueryPermission',qpayload)
          this.$store.dispatch('GetCollectionArray',dpayload) 
        }
      
    },
    //ActivateAppsDB
    ActivatePluginDB(query){
      //console.log('ActivatePluginDB')
      let vm = this
      this.ActivePlugins = []
      query.onSnapshot(res => {
              let pluginlength = res.docs.length
              //console.log(pluginlength,this.userIsAdmin)
              if(pluginlength === 0 && this.userIsAdmin && this.$route.name !== 'MyAccount'){
                this.$router.push('/MyAccount')
              }
              const changes = res.docChanges();
              changes.forEach(change => {
                  if (change.type === 'added') {
                    let plugin = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.ActivePlugins.push(plugin)
                  }
                  if (change.type === 'modified') {
                    let plugin = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.ActivePlugins.find(obj => obj.id === plugin.id)
                    if(oncheck){
                      let index = this.ActivePlugins.indexOf(oncheck)
                      this.ActivePlugins.splice(index,1,plugin)
                    }
                  }
                  if (change.type === 'removed') {
                    let plugin = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.ActivePlugins.find(obj => obj.id === plugin.id)
                    if(oncheck){
                      let index = this.ActivePlugins.indexOf(oncheck)
                      this.ActivePlugins.splice(index,1)
                    }
                  }
              })
            })
    },
    ActivateRAandPlugins(){
      const firebaseConfig = {
            apiKey: "AIzaSyBizqYTsY9oNY_0U1L6evK9a22kjUyr9ps",
            authDomain: "rapidapps-7d6e7.firebaseapp.com",
            databaseURL: "https://rapidapps-7d6e7.firebaseapp.com",
            projectId: "rapidapps-7d6e7",
            storageBucket: "rapidapps-7d6e7.appspot.com",
            messagingSenderId: "674721806504",
            appId: "1:674721806504:web:4596bc44637e9f37eb6a9d",
            measurementId: "G-J7T6TGDTTB"
          };
            let check = firebase.apps.find(obj => obj.name === 'RapidApps')
              if(check){
                this.RAApp = check
              }
              else{
                this.RAApp = firebase.initializeApp(firebaseConfig, 'RapidApps')
              }  
              this.$store.commit('setRAApp',this.RAApp)            
              this.RADB = this.RAApp.firestore()
              this.$store.commit('setRADB',this.RADB)              
            //   let pluginspayload = { 
            //     SystemID: process.env.VUE_APP_RA_SYSTEM_ID
            //   }
            // console.log(pluginspayload)
            // const RAfunctions = firebase.functions(this.RAApp);
            // const ObtainSystemPlugins = RAfunctions.httpsCallable('ObtainSystemPlugins');
            // ObtainSystemPlugins(pluginspayload).then(result => {
            //   result.data.map(plugin => {

            //   })
            // })
            this.ActivatePluginDB(this.$store.state.PluginsPath.where('Active','==',true))
            //used GetApps this.ActivateAppsDB(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('apps').where('Active','==',true))
            if(this.RAAdmin){
              let rasystemassetspayload = {      
                //actually should be following once transfered
                //query: this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('PhotoLibraries').doc('System_Assets').collection('Assets'),          
                query: this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('PhotoLibraries').doc('System_Assets').collection('Assets'),
                arrayname: 'RASystemAssets'
              }
              //console.log(rasystemassetspayload)
              this.$store.dispatch('GetCollectionArray',rasystemassetspayload)   
            }
    },
    GetSystemModule(ModuleCol,ModuleName,ModuleProps){
      db.collection(ModuleCol).doc(this.ActiveSuiteid).onSnapshot(snapshot => {
        let moddata = snapshot.data()
        //console.log('moddata',moddata)
        if(moddata){
          if(!this[ModuleName] || this[ModuleName] && this.ActiveSuiteid !== this[ModuleName].id){
            this[ModuleName] = {id: this.ActiveSuiteid}
            //really this would be a "renew"/ "kill and recall" action
            //we could alternative assign watcher function to the change of a "ActiveDataBase" watch when id changes then...etc.
            //latter safest route
          }
          ModuleProps.map(prp => {
            if(typeof moddata[prp] !== 'undefined'){
              
              this[ModuleName][prp] = moddata[prp]
              this.System[prp] = moddata[prp]
            }
          })
          if(ModuleName === 'ActiveSystemWebsite' && !this.userLoggedIn && this.PublicUser){
            this.RouterReady = true
          }
        }
      })
    },
    PrepareSystemModules(){
      //VUETIFY CLASSES affected e.g. TYPE Public_NavBar_Gradient Guest_SideBar_Gradient Social_NavBar_Gradient Social_Parallax_Gradient DB_SideBar_Gradient
      //we otherse have default colors, can we work it in there? no, worked in manually

        //ActiveSystemDatabase, ActiveSystemWebsite, ActiveSystemSocialNetwork, ActiveSystemLibrary
        //do remember that SOME things forever remain under SystemConfig being generic. For example - "CustomFonts" is Suite Wide
        //In contrast some previous root props move from SystemConfig, e.g. this.SocialCategories

        //FINALLY YES WE GOT THIS STUFF BUT WE ALSO NEED TO RESET THEM IF WE CHANGE SITE DON'T WE? KNOW BECAUSE WE RESET SITE ON GET SYSTEM ANYWAY
       let webmoduleprops = ['PublicNavbarItems','PublicNavbarText','Public_Landing_Page','Site_Category','Site_Class','Site_Type','Description','PublicNavbarStyle','Logo','KeyWords',
        'PublicNavbarDark','Public_NavBar_IMG','Public_NavBar_Gradient','PublicNavbarSelectedText','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG','GoogleMapsLocation','Business_Hours',
        'Is_Directory_Listing','Company_Name',
        'SystemBarEnabled','SystemBarDark','SloganMarquee','SystemBarColor','SiteTelephoneLabel','SiteSlogan','SiteEmail','SiteTelephoneNumber','AppendedPublicNavbarActions','PrependedPublicNavbarActions',
        'Company_Address_City','Company_Address_Country_Region','Company_Address_Postal_Code','Company_Address_State_Province','Company_Address_Street_Name',
        'Company_Address_Street_Number','Company_Address_Suburb','Telephone','Published'].concat(this.DefaultThemes.map(theme => {
        return theme.Colors.filter(col => {
          return col.PublicNavbar && col.SystemProp
        }).map(col => {
          return col.SystemProp
        }).flat()
      }).flat()
      )
        //console.log('webmoduleprops',webmoduleprops)
        this.GetSystemModule('Websites','ActiveSystemWebsite',webmoduleprops)
        if(this.userLoggedIn){
        let libmoduleprops = []
        //Props for LibModule undetermined. In fact LibMOdule greatly unknown replacing DocumentationLibrary module, BUT...becoming parent of it
        //Lib Module will be parent to ['PhotoLibraries','DocumentLibraries','Directories','classifiedentities','wikientities'] 
        //Also remember about Lib Module the following - it is ALWAYS on for EVERY Suite by DEFAULT, only EXCEPT WHEN DBB Only. 
        //Both Social Network Builder, AND Website Builder REQUIRE Phot Lib "system assets" thus ALWAYS On..again, EXCEPT when entire Suite is DBB only. 
        this.GetSystemModule('Libraries','ActiveSystemLibrary',libmoduleprops)
        let socialnetmodprops = ['SocialNavbarItems','Social_Landing_Page','Social_Module_Name','Group_Categories','Base_App_Royalties','Credit_Tokens_ROE','Social_NavBar_Gradient',
        'Social_Parallax_Gradient','Social_Parallax',
        'Social_NavBar_Header_IMG','Social_NavBar_IMG','Social_Navbar_Subtitle','Monetization_BU','Member_Ribbon','Payout_Minimum','Payout_Schedule'].concat(this.DefaultThemes.map(theme => {
          return theme.Colors.filter(col => {
            return col.Social && col.SystemProp
        }).map(col => {
          return col.SystemProp
        }).flat()
      }).flat()
      )
        this.GetSystemModule('SocialNetworks','ActiveSystemSocialNetwork',socialnetmodprops)
        let dbmoduleprops = ['DBNavbarItems','DefaultStatusField','DB_SideBar_IMG','LastAutomationInteger','DB_SideBar_Gradient'].concat(this.DefaultThemes.map(theme => {
          return theme.Colors.filter(col => {
            return col.DataTables && col.SystemProp
        }).map(col => {
          return col.SystemProp
        }).flat()
      }).flat()
      )
        this.GetSystemModule('Databases','ActiveSystemDatabase',dbmoduleprops)
        }
    },
    GetSystem(user){
      //Changed from SystemConfigPath.onSnapshot(snapshot => { to SystemConfigPath.get().then(snapshot => { on main js, but kept snapshot here on App.vue
     //snapshot on app vuew looks at styling, but mainjs snapshot breaks all core things like security, routes, etc and thus breaks everything when changes made to system config.
      db.collection('SystemConfig').doc(this.ActiveSuiteid).onSnapshot(snapshot => {
        let systemobj = snapshot.data()
        //STILL NEED TBD WHEN TRIGGER this.PrepareSystemModules()
        this.PrepareSystemModules()
        this.System = systemobj
        if(!this.System.CustomFonts){
          this.System.CustomFonts = []
        }
        // let animobj = new KeyframeEffect(
        //   null, // element to animate
        //   [
        //     { filter: 'blur(0)' }, // keyframe
        //     //{ transform: 'translateY(100%)' } // keyframe
        //   ],
        //   { duration: 3000, fill: 'forwards' } // keyframe options
        // );
        // //let animobj = new Animation(rabbitDownKeyframes, document.timeline)
        // let newanim = new Animation(animobj, document.timeline)
        // //newanim.play()
        // console.log(newanim)
        this.System.CustomFonts.map(font => {
          let new_font = new FontFace(font.Name,font.Path)
          new_font.load().then(function(loaded_face) {
              document.fonts.add(loaded_face)
          }).catch(function(error) {
          });
        })
        this.System.id = this.ActiveSuiteid
        if(this.System && !this.System.SocialNavbarItems){
          this.System.SocialNavbarItems = []
        }
        //however, we should not repropogate things  liek claling warehouses, navlists etc. those are snapshots on their own. 
        //so we only do snapshot on system, to get update, but refrain from doing any of the others. should be clear cut full circle now
        if(!this.RouterReady && !this.OnLogin || this.OnLogin || this.PublicUser){
          
          if(!this.SystemisRA && !this.PrimarySiteCalled){
            //this is very wrong only system admin should get plugins from RA? oh no neevrmind uhm think I get it
            this.ActivateRAandPlugins()
          } 
          else if(this.SystemisRA){
            //else if(this.SystemisRA && !this.PrimarySiteCalled){
            this.ActivatePluginDB(this.$store.state.PluginsPath.where('Active','==',true))
            //used GetApps this.ActivateAppsDB(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('apps').where('Active','==',true))
          }     
          if(this.SuitehasWarehouse && !user && this.IsPrimarySite){
            // remove this.SuitehasWarehouse && ?
            this.GetWarehouses()
          }
        this.SocialCategories.map(cat => {
          if(user){
            this.GetSocialCategories(db.collection('SocialNetworks').doc(this.ActiveSuiteid).collection(cat),cat)
          }
          else{
            this.GetSocialCategories(db.collection('Websites').doc(this.ActiveSuiteid).collection(cat),cat)
          }
        })
        
         if(this.System.FavoriteFeatures){
                this.FavoriteFeatures = this.System.FavoriteFeatures
              }
        this.System.StoreProductsEntities = []
        this.ActivateUserAccess(user)
        } 
        
      })
    },
    CheckNewSiteWebsite(){
      console.log('site toggle')
            let site = this.SuiteTemplates.find(obj => obj.id === this.ActiveSuiteid)
              if(site){
                console.log('site',site)
                let webplugin = site.Plugins.find(obj => obj.id === 'Website_Builder')
                console.log('webplugin',webplugin)
                if(webplugin && webplugin.Active){
                  let website = this.System.Website
                  console.log('website',website)
                  console.log('this.SitePages',this.SitePages,this.SitePages.length)
                  if(website){
                    if(website.Public_Landing_Page){
                      this.$router.push('/'+website.Public_Landing_Page)
                    }
                    
                  }
                  else {
                      let firstwebpage = this.SitePages.find(obj => obj.PublishType === 'Public')
                      console.log('firstwebpage',firstwebpage)
                      if(firstwebpage){
                        this.$router.push('/'+firstwebpage.Name)
                      }
                    }
                }
              }
    },
    ActivateUserAccess(user){
      // console.log('this.PrimarySiteCalled @ ActivateUserAccess',this.PrimarySiteCalled)
      // console.log('this.SignedIn @ ActivateUserAccess',this.SignedIn)
      //   console.log('this.Userunauthenticated @ Userunauthenticated',this.SignedIn)
      // console.log('user @ ActivateUserAccess',user)
      //In time, this method and everything below it will move to AppUserandAcess, leaving the App comp pretty much "petite"
      let doclibquery = ''
      let doclibpath = db.collection('Libraries').doc(this.ActiveSuiteid).collection('DocumentationLibraries')
      let wikientityquery = ''
        if(user){
        //don't think we need reactivation after restructure here
        //this.GetDocumentationLibraries(this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries'),this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries').where('PublishType','==','Public'),this.RADB,process.env.VUE_APP_RA_PROVIDER_ID)

          doclibquery = db.collection('Libraries').doc(this.ActiveSuiteid).collection('DocumentationLibraries')
          wikientityquery = db.collection('Libraries').doc(this.ActiveSuiteid).collection('wikientities')
          this.GetSystemTableFilters()
          this.GetDBNavLists()
          //WHEN REPLICATEING ENTITIES TO NEW STRUCTURE USE this.GetEntities(db.collection('SystemConfig').doc(this.ActiveSuiteid).collection('entities'),'SystemEntities')
          //WHEN TESTING NEW STUCTURE USE this.GetEntities(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'),'SystemEntities')
          this.GetEntities(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'),'SystemEntities').then(result => {
            //console.log('GetEntities result - '+result)
          
          this.SystemLoadingvalue = 75
          if(this.Userunauthenticated){
            //would need to be divided some of the below has to happen on suite id change
           this.AssignAuth(user) 
          }
          else{
            this.GetEntityRoutes(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'))
            //console.log('seems user Userunauthenticatedwas authenticated already')
            
             setTimeout(() => {
            //THIS IS A CHEESY CHEAT!!
                  this.SystemLoadingvalue = 100
                  this.SystemLoading = false
                  this.RouterReady = true
                  //this.CheckNewSiteWebsite()
            // console.log('this.$route @ ActivateUserAccess',this.$route)
            // console.log('this.SitePages @ ActivateUserAccess',this.SitePages)
                }, 1000);
            
          }
          })
        }
        else{
          doclibquery = db.collection('Libraries').doc(this.ActiveSuiteid).collection('DocumentationLibraries').where('PublishType','==','Public')
          wikientityquery = db.collection('Libraries').doc(this.ActiveSuiteid).collection('wikientities').where('PublishType','==','Public')
          //alert('this.FeaturedMembersActive'+this.FeaturedMembersActive)

          //MAY NEED TO REINSTATE THIS INITIATEFEATMEMENT...
          // if(this.FeaturedMembersActive){
          //   this.InitiateFeatMemEntity()
          // }
        }
        // console.log('this.RADB',this.RADB)
        if(this.IsPrimarySite){
        this.GetWikiEntities(wikientityquery)
        this.GetStoredDefaultDirectories()
        this.GetDocumentationLibraries(doclibpath,doclibquery,db,this.ActiveSuiteid)
        //this may have been one too many
        //this.GetDocumentationLibraries(this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries'),this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('DocumentationLibraries').where('PublishType','==','Public'),this.RADB,process.env.VUE_APP_RA_PROVIDER_ID)
          if(this.PublicUser){
          this.SystemLoadingvalue = 100
          this.SystemLoading = false
        }
        }
        else if(this.PublicUser){
          this.SystemLoadingvalue = 100
          this.SystemLoading = false
        }
    },
    PrepareAddressField(field){
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
    GetWikiEntities(wikientityquery){
      wikientityquery.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let entityobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.WikiEntities.push(entityobj)
            }
        })
        //console.log('this.WikiEntities',this.WikiEntities)
      })
    },
    GetStoredDefaultDirectories(){
      this.StoredDefaultDirectories = []
      db.collection('Libraries').doc(this.ActiveSuiteid).collection('Directories').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
                let navlist = change.doc.data()
                navlist.Name = navlist.DisplayName
                navlist.id = change.doc.id
                this.StoredDefaultDirectories.push(navlist)
                let storeobjpayl = {
                  EntityID: navlist.id,
                  Collection: '',
                  Data: [],
                  CategoricalListType: navlist.CategoricalListType
                }
                //okay if CategoricalListType === 'Social Groups', then we should in fact first find the pages, being ALL pages it matches with
                
                if(navlist.Has_Ratings){
                  storeobjpayl.Has_Ratings = navlist.Has_Ratings
                }
                if(navlist.CategoricalListType === 'Social Groups'){
                  this.$store.commit('AddCatNavlistTable',storeobjpayl)
                  this.SitePages.map(page => {
                    let pagelements = page.PageTabs.map(tab => {
                      return tab.Elements
                    }).flat()
                    .filter(elmnt => {
                      return elmnt.CatNavList
                    })
                    if(pagelements.length > 0){
                      let newstoreobjpayl = Object.assign({},storeobjpayl)
                    newstoreobjpayl.EntityID = storeobjpayl.EntityID+'_'+page.id
                    //console.log(newstoreobjpayl)
                    this.$store.commit('AddCatNavlistTable',newstoreobjpayl)
                    }
                    
                  })
                }
                else{
                  //console.log(storeobjpayl)
                 this.$store.commit('AddCatNavlistTable',storeobjpayl) 
                }
                
                //getfeaures if has featuredlist
            }
            //console.log('StoredDefaultDirectories',this.StoredDefaultDirectories)
        })
      })
    },
    GetSurveyElements(entity){
      let vm = this
      return new Promise(function(resolve, reject) {
      db.collection('DataTables').doc(this.ActiveSuiteid).collection('entities').doc(entity.id).collection('SurveyElements').onSnapshot(res => {
            const changes = res.docChanges();
            let arraylength = res.docs.length
            
            if(arraylength === 0){
              resolve('done getting SurveyElements')
            }
            else{
              changes.forEach(change => {
                  if (change.type === 'added') {
                      entity.SurveyTab.Elements.push({
                      ...change.doc.data(),
                      id: change.doc.id
                      })
                      if(entity.SurveyTab.Elements.length === arraylength){
                        resolve('done getting SurveyElements')
                      }
                  }
              })
            }
            
        })
      })
    },
    
    GetDocumentationLibraries(path,query,database,systemid){
        query.onSnapshot(res => {
          let length = res.docs.length
          //console.log('length',length)
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {    
              let doclibobj = {
                ...change.doc.data(),
                id: change.doc.id
              }   
              //console.log(doclibobj)
              doclibobj.Database = database
              doclibobj.SystemID = systemid
              doclibobj.Query = path.doc(doclibobj.id)
              // if(!this.userLoggedIn){
              //   doclibobj.Query = doclibobj.Query.where('PublishType','==','Public')
              // }
              doclibobj.Pages = []  
            this.DocumentationLibraries.push(doclibobj)
            }
            if (change.type === 'modified') {
              let doclibobj = {
                ...change.doc.data(),
                id: change.doc.id
              } 
              doclibobj.Database = database
              doclibobj.Pages = []  
              doclibobj.SystemID = systemid
              let oncheck =  this.DocumentationLibraries.find(obj => obj.id === doclibobj.id) 
              if(oncheck){
                let index = this.DocumentationLibraries.indexOf(oncheck)
                this.DocumentationLibraries.splice(index,1,doclibobj)
              }      
            }
          })
        })
    },
    GetEntities(configdb,array,groupid){    
      //console.log('GetEntities for configdb',configdb)  
            let vm = this
            return new Promise(function(resolve, reject) {
            if(array === 'SystemEntities'){
              vm[array] = []
             vm.SystemLoadingvalue = 50 
            }            
            //vm.$emit('ActivateProcessing',true)
            //alert('getting entities')
          vm[array+'Call'] = configdb.onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                  resolve('GotEntities')
                    //vm.$emit('ActivateProcessing',false)
                }
                else{
                  const changes = res.docChanges();
                    changes.forEach((change,i) => {
                   if (change.type === 'added') {
                        let build = change.doc.data()
                        build.id = change.doc.id
                        if(groupid){
                          build.groupid = groupid
                          //console.log('group web form build',build)
                        }
                        if(build.Entity_Type && build.Entity_Type.Name === 'Survey Form'){
                          vm.GetSurveyElements(configdb,build)
                        }
                        vm.GetAllEntityProps(configdb,build).then(function(allpropsresult) {
                          //console.log('allpropsresult',allpropsresult,build)                          
                            if(allpropsresult){
                               vm[array].push(build)
                               
                               if(vm[array].length === arraylength){
                                 if(array === 'SystemEntities'){
                                 vm.GotEntities = true
                                 }
                                //  vm.CheckAuth()
                                resolve('GotEntities')
                                //vm.SystemLoadingvalue = 75
                               }
                            }
                        })                        
                   }
                  })
                }
                
                    
            })
            })
    },
    GetAllEntityProps(configdb,build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(configdb,build).then(function(allfieldsresult) {
                if(allfieldsresult){
                  
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(configdb,build).then(function(subcolresult) {
                        // console.log(subcolresult)
                        
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(configdb,build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(configdb,build).then(function(formsresult) {
                            vm.GetDocumentRegisters(configdb,build).then(function(docregresult) {  
                                vm.GetGalleries(configdb,build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(configdb,build).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                    if(build.Entity_Type && build.Entity_Type.Name === 'Cash Loan'){
                                      vm.GetLoanPackages(configdb,build).then(function(packagesresult) {
                                        resolve('got the props for '+configdb,build.id)
                                      })
                                    }
                                    else{
                                      resolve('got the props for '+configdb,build.id)
                                    }
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
    GetLoanPackages(configdb,build){    
            // console.log('calling fields') 
            build.LoanPackages = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.LoanPackages)
            configdb.doc(build.id).collection('LoanPackages').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities LoanPackages Finished for ' + build.LoanPackages.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.LoanPackages.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if (change.type === 'modified') {
                  let loanpackobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                  let oncheck = build.LoanPackages.find(obj => obj.id === loanpackobj.id)
                  if(oncheck){
                    let index = build.LoanPackages.indexOf(oncheck)
                    build.LoanPackages.splice(index,1,loanpackobj)
                  }
                }
                if (change.type === 'removed') {
                  let loanpackobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                  let oncheck = build.LoanPackages.find(obj => obj.id === loanpackobj.id)
                  if(oncheck){
                    let index = build.LoanPackages.indexOf(oncheck)
                    build.LoanPackages.splice(index,1)
                  }
                }
                if(build.LoanPackages.length === arraylength){
                    
                resolve('Method SystemEntities LoanPackages Finished for ' + build.LoanPackages.length)
                }
                })
                })
            })
        },
    GetEntityAllFields(configdb,build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(configdb,build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(configdb,build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('EntityProcesses').where('Status','!=','InActive').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(configdb,build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(configdb,build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(configdb,build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(configdb,build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            
                            //console.log(targetslength,i-1+2)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
    ActivateSingleRecordNavMenuItem(item){
      console.log(item)
      this.SingleRecordNavMenuItem = item
      
    },
    SingleTabsLayout(array,itemname){
      console.log('SingleTabsLayout',array)
      if(array){
        this.SingleRecordNavMenuView = true
        this.SingleRecordNavMenuArray = array
        this.SingleRecordNavName = itemname
        //this.SingleRecordNavMenuItem = array[0]
      }
    },
    UpdateRequiredUserInformation(){
      this.UserRequiredInfo.map(info => {
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserObj.id).update({
          [info]: this.UserObj[info]
        })
      })
      this.UserInfoRequiredDialog = false
    },
    TriggerDashboardbyNotification(payload){
      this.NotificationObj = payload
      console.log('NotificationObj',this.NotificationObj)
      if(this.UserisGuest && this.$route.name !== 'MyAccount'){
        if(!this.System.Guest_Default_Page){
          this.$router.push('/MyAccount')
        }
        else{
          this.$router.push(this.System.Guest_Default_Page)
        }
        
       setTimeout(() => {
         this.NotificationObj = ''
       }, 300);
      }
      else if(!this.UserisGuest && this.$route.name !== 'MyDashboard'){
        if(payload.groupid){
          let routeData = this.$router.resolve({name: 'GroupDashboard', params: {id: payload.groupid}});
          window.open(routeData.href);
        }
        else{
         this.$router.push('/MyDashboard')  
        }        
        setTimeout(() => {
         this.NotificationObj = ''
       }, 10000);
      }
      
    },
    ActivateNotificationSnackbar(boolean,snackbarcontent){
      // this.snackbar = boolean
      if(boolean === true){
        this.snackbars.push(snackbarcontent)
      }
    },
    CommitSnackbar(snackbar){
      let boolean = snackbar.boolean
      let snackbarcontent = snackbar
      this.ActivateSnackbar(boolean,snackbarcontent)
    },
    ActivateSnackbar(boolean,snackbarcontent){
      this.snackbar = boolean
      if(boolean === true){
        this.snackbartimeout = snackbarcontent.snackbartimeout
        this.snackbartext = snackbarcontent.snackbartext
        this.snackbartop = snackbarcontent.snackbartop
        this.snackbarpath = snackbarcontent.snackbarpath
        this.snackbarmultiLine = snackbarcontent.snackbarmultiLine
        let timeout = 4000
        if(this.snackbartimeout){
          timeout = this.snackbartimeout
        }
        setTimeout(() => {
        this.snackbartimeout = 4000
        this.snackbartext = ''
        this.snackbartop = true
        this.snackbarpath = ''
        this.snackbarmultiLine = true
        this.snackbar = false
        }, timeout);
      }
      
    },
    ActivateStandbyDialog(boolean){
      this.StandbyDialog = boolean
    },
    ActivateProcessing(boolean){
      this.Processing = boolean
    },
    ActivateUploadingSingleFileNotification(boolean){
      this.UploadingSingleFile = boolean
    },
    ActivateMultipleFilesUploadNotification(boolean){
      this.UploadingFiles = boolean
    },
    CloseSnackbar(){
      this.ActivateSnackbar(false)
    },
    LandingPageView(boolean){
      this.LandingPageViewActive = boolean
    },
    RecordSlider(event) {
      var invertedenvlopetopright = document.getElementById("invertedenvlopetopright");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopright){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopright.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetoprightforwards')){
            //console.log('already has this class')
          }
          else{
            invertedenvlopetopright.setAttribute('class','secondary invertedenvlopetopright invertedenvlopetoprightforwards')
          }
          
        }
        else {
          invertedenvlopetopright.setAttribute('class','secondary invertedenvlopetopright invertedenvlopetoprightbackwards');
        }
      }
      var invertedenvlopetopleft = document.getElementById("invertedenvlopetopleft");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopleft){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopleft.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetopleftforwards')){
            //console.log('already has this class')
          }
          else{
            invertedenvlopetopleft.setAttribute('class','secondary invertedenvlopetopleft invertedenvlopetopleftforwards')
          }
          
        }
        else {
          invertedenvlopetopleft.setAttribute('class','secondary invertedenvlopetopleft invertedenvlopetopleftbackwards');
        }
      }
      var invertedenvlopetopcenter = document.getElementById("invertedenvlopetopcenter");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopcenter){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopcenter.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetopcenterforwards')){
            //console.log('already has this class')
          }
          else{
            invertedenvlopetopcenter.setAttribute('class','white invertedenvlopetopcenter invertedenvlopetopcenterforwards')
          }
          
        }
        else {
          invertedenvlopetopcenter.setAttribute('class','white invertedenvlopetopcenter invertedenvlopetopcenterbackwards');
        }
      } 
    },
    PushRecordName(name){
      //console.log('pushing recordname',name)
      this.CurrentRecordName = name
    },
    ToggleAppDarkMode(){
  if(this.AppisDarkMode){
    this.AppisDarkMode = false
  
  }
  else{
    this.AppisDarkMode = true
  }
  this.$vuetify.theme.dark = !this.$vuetify.theme.dark
},
    ActivateSearch(search){
      //console.log('yep I activate search view')
      //console.log(search)
      this.SearchView = true
      this.ActiveSearch = search
      //console.log(this.SearchView)
      //console.log(this.ActiveSearch)
    },
    UpdateActiveSearch(search){
     this.ActiveSearch = search
    },
    DeactivateSearch(){
      //console.log('yep I deactivate or try to')
      this.SearchView = false
      this.ActiveSearch = ''
    },
    TogglePublicNavbarEditing(){
      this.PublicNavbarEditing = !this.PublicNavbarEditing
    },
    ToggleAppStylingView(){
        this.AppStylingView = !this.AppStylingView    
    },
    IntranetViewToggle(boolean,tointranet){   
      if(!this.ActiveMarketTemplate){
     //console.log('IntranetViewToggle ',this.IntranetView,boolean,tointranet)    
        if(this.IntranetView === true && boolean === false && this.userLoggedIn && !this.PublicPreview){
           this.IntranetView = boolean 
           if(this.UserisGuest && this.$route.name !== 'MyAccount'){
              if(!this.System.Guest_Landing_Page){
                this.$router.push('/MyAccount')
              }
              else{
                this.$router.push(this.System.Guest_Landing_Page)
             }
           }
           else{
            if(!this.System.Social_Landing_Page){
                //this.$router.push('/MyAccount')
              }
              else{
                this.$router.push(this.System.Guest_Landing_Page)
             }
           }
          //  else{
          //    this.$router.push('/MyDashboard')          
          //  }
         
       }  
       else if(this.IntranetView === false && boolean === true && this.userLoggedIn){
         this.$store.commit('setActiveSuiteApp','')
         this.IntranetView = boolean
         if(tointranet){
           this.$router.push(this.System.Social_Landing_Page)
         }
         
       } 
       //console.log(this.IntranetView)
      }
    },
    RecordBannerParallax(){
      setTimeout(() => {
      var recordbanner = document.getElementById("recordbanner");
      var recordparallax = document.getElementById("recordparallax");
      // console.log('recordbanner',recordbanner)
      // console.log('recordparallax',recordparallax)
      if(!recordbanner && recordparallax){
          recordparallax.style.top = '110px'
        }
        }, 500);
    },
    handleSiteDialogToolbar(event) {
      var sitetoolbar = document.getElementById("sitetoolbar");
      if(this.DBView && sitetoolbar){
       if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
         sitetoolbar.style.top = '135px'
        }
        else{
          sitetoolbar.style.top = '190px'
        } 
      }
      
    },
    handleScrollRecordToolbar(event) {
      var recordtoolbar = document.getElementById("recordtoolbar");
      var recordtoolbar2 = document.getElementById("recordtoolbar2");
      var recordownershipbanner1 = document.getElementById("recordownershipbanner1");
      var recordownershipbanner2 = document.getElementById("recordownershipbanner2");
      let logobar = document.getElementById('logobar')
      let belowlogobar = document.getElementById('belowlogobar')
      let socialsitequicknav = document.getElementById('socialsitequicknav')
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        if(logobar && belowlogobar && this.IntranetView && !this.AppStylingView){
          logobar.style.display = 'none'
          belowlogobar.style.top = '50px'
          if(socialsitequicknav){
            socialsitequicknav.style.top = '115px'
          }
        }
        else if(logobar && belowlogobar && this.IntranetView && this.AppStylingView){
          logobar.style.display = 'none'
          belowlogobar.style.top = '260px'
          if(socialsitequicknav){
            socialsitequicknav.style.top = '325px'
          }
        }
        if(recordtoolbar && !this.AppStylingView){
        recordtoolbar.style.top = '50px'
        }
        else if(recordtoolbar && this.AppStylingView){
        recordtoolbar.style.top = '260px'
        }
        if(recordtoolbar2){
        recordtoolbar2.style.top = '65px'
        }
        if(recordownershipbanner1){
        recordownershipbanner1.style.top = '110px'
        }
        if(recordownershipbanner2){
        recordownershipbanner2.style.top = '110px'
        }
      }
      else {
        if(logobar && belowlogobar && this.IntranetView && !this.AppStylingView){
          logobar.style.display = 'block'
          belowlogobar.style.top = "160px"
          if(socialsitequicknav){
            socialsitequicknav.style.top = '225px'
          }
        }
        else if(logobar && belowlogobar && this.IntranetView && this.AppStylingView){
          logobar.style.display = 'block'
          belowlogobar.style.top = "400px"
          if(socialsitequicknav){
            socialsitequicknav.style.top = '415px'
          }
        }
        if(recordtoolbar && !this.AppStylingView){
        recordtoolbar.style.top = '65px'
        }
        else if(recordtoolbar && this.AppStylingView){
        recordtoolbar.style.top = '260px'
        }
        if(recordtoolbar2){
        recordtoolbar2.style.top = '110px'
        }
        if(recordownershipbanner1){
        recordownershipbanner1.style.top = '130px'
        }
        if(recordownershipbanner2){
        recordownershipbanner2.style.top = '130px'
        }
      }
    },
    GetFAQ(query){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {         
           this.FrequentlyAskedQuestions.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
          else if (change.type === 'modified') {
            let oncheck =  this.FrequentlyAskedQuestions.find(obj => obj.id === change.doc.id) 
            if(oncheck){
              let index = this.FrequentlyAskedQuestions.indexOf(index)
                this.FrequentlyAskedQuestions[index] = {
                ...change.doc.data(),
                id: change.doc.id
              }
            }      
          }
      })
      })
    },
    PrepSuite(user){
      //console.log('this.PublicUser',this.PublicUser)
      let vm = this
      //GONNA NEED TO ADD A CLAL TO BUSINESS UNITS BUT SHOULD BE REOSLVED ON ENEITY ROUTER ETC
      this.SystemLoading = true
      if(!this.PublicUser){
      vm.GetPhotoLibraries()
      vm.GetDocumentLibraries()
      }
      vm.GetSystem(user)
      //console.log('vm.WebsiteisActive',vm.WebsiteisActive,'vm.SocialNetworkisActive',vm.SocialNetworkisActive)
      if(vm.WebsiteisActive || vm.SocialNetworkisActive){
        if(!this.PublicUser){
      vm.GetSitePages(db.collection('SocialNetworks').doc(vm.ActiveSuiteid).collection('Pages'),user)
        }
      //I know it sounded clever to not clal website when logged in, but loggedin users that maintain website need the pages. 
      //Unsure if this double call will breka things.
      vm.GetSitePages(db.collection('Websites').doc(vm.ActiveSuiteid).collection('Pages'),user)
      
      }
    },
    
    //Basically, if not a website only, then this applies
    async CheckAuth(){
      let vm = this
      	
    await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          vm.OnLogin = true
           
          //vm.GetFAQ(db.collection('frequentlyaskedquestions'))
           vm.SystemLoadingvalue = 25
          //console.log(user)
          vm.SystemLoading = true
          
          vm.$store.commit('SetUserStateObj', user)
           user.getIdToken().then(function(idToken) {
              
            vm.$store.commit('SetUserIdToken', idToken)
          });
          
            vm.GetSystemGuides(db.collection('systemguides'))
            vm.PrepSuite(user)
             setTimeout(() => {
              vm.OnLogin = false
            }, 150);
         // console.log('seeing signed in')
        }
        else{
          vm.GetFAQ(db.collection('Websites').doc(vm.ActiveSuiteid).collection('frequentlyaskedquestions').where('PublishType','==','Public'))
          vm.SignedIn = false
          vm.PublicUser = true
          vm.GetSystem()
          if(vm.WebsiteisActive){
          vm.GetSitePages(db.collection('Websites').doc(vm.ActiveSuiteid).collection('Pages').where('PublishType','==','Public'),user)  
          }  
          else{
            vm.RouterReady = true
          }        
          //vm.$router.push('/Home')
         
          //console.log('seeing as NOT signed in')
        }
    })
  },
  GetSitePages(query,user){
    this.SitePages = []
    let Public_Landing_Page = this.System.Public_Landing_Page
    let routes = this.$router.getRoutes()
    query.onSnapshot(res => {
      //console.log(res.docs)
      let length = res.docs.length
      if(length === 0 && !user){
        //This can happen when website is actvated but no pages as yet
        //do howvwer remember long as there are no public pages whatesoever nothing will work not even featured members
        //final check, do we allow feat mem Template without monetizaiton? or cross platfrom then?
        //I say but...yes...it should propogate.
        this.RouterReady = true
      }
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {         
          let pagedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let pageroute = {
              path: '/'+pagedoc.Name.split(' ').join('-'),
              name: pagedoc.Name.toLowerCase(),
              component: LandingPageSingleVue,
              meta: {
                id: pagedoc.id,
                icon: pagedoc.PageIcon,
                type: 'Pages',
                subcat: pagedoc.Name,
                PageID: pagedoc.id,
                viewicon: 'mdi-book-open-page-variant',
                IsTopic: true,
                TopicName: pagedoc.Name+' Page'
                }
            }
            if(pagedoc.PublishType === 'Public'){
              pageroute.meta.RouteEventID = 1028
              pageroute.meta.TopicCategory = 'Public Pages'
            }
            else{
              pageroute.meta.RouteEventID = 1027
              pageroute.meta.TopicCategory = 'Internal Pages'
            }
            if(pagedoc.CatNavList){
              pageroute.meta.CatNavList = pagedoc.CatNavList
            }
            //console.log(pageroute)
            // if(Public_Landing_Page && Public_Landing_Page === '/'+pagedoc.Name.split(' ').join('-')){
            //   pageroute.path = '/'
            // }
            // else{
            //   pageroute.path = '/'+pagedoc.Name.split(' ').join('-')
            // }
            // path: '/'+pagedoc.Name.split(' ').join('-'),
            this.AddRoute(pageroute)
          let oncheck = this.SitePages.find(obj => obj.id === pagedoc.id)
          if(!oncheck){
            this.SitePages.push(pagedoc)
          }
        }   
        if (change.type === 'added') {         
          let pagedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.SitePages.find(obj => obj.id === pagedoc.id)
          if(oncheck){
            let index = this.SitePages.indexOf(oncheck)
            this.SitePages.splice(index,1,pagedoc)
          }
        }
        if (change.type === 'modified') {         
          let pagedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.SitePages.find(obj => obj.id === pagedoc.id)
          if(oncheck){
            let index = this.SitePages.indexOf(oncheck)
            this.SitePages.splice(index,1,pagedoc)
          }
        }
        if(this.SitePages.length === length && this.PublicUser){
            // setTimeout(() => {
            //   this.RouterReady = true  
            // }, 1000);
           
        }
            
    })
    })
  },
  GetUserNotifications(query,timeout){
    //alert('yay')
    this.Notifications = []
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {         
          let notedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.Notifications.find(obj => obj.id === notedoc)
          if(!oncheck){
            this.Notifications.push(notedoc)
          }
          // console.log(notedoc.id,notedoc.Read,notedoc.Message,notedoc.Content)
          // console.log(notedoc.Read === false)
          if(notedoc.Read === false){            
            // this.focusednotificationssnackbarcontent.title = notedoc.Message
            // this.focusednotificationssnackbarcontent.body = notedoc.Content
            // this.focusednotificationssnackbar = true
            let snackbarcontent = {
              timeout: timeout,
              text: notedoc.Content,
              title: notedoc.Message,
              show: true,
              IsNotification: true,
              notificationid: notedoc.id,
              thumbnail: ''
            }
            if(notedoc.fileurl){
              snackbarcontent.Route = notedoc.fileurl
              snackbarcontent.Target = '_blank'
              snackbarcontent.isHref = true
            }
            else{
              snackbarcontent.Route = notedoc.Path
              snackbarcontent.Target = 'self'
            }
            this.snackbars.push(snackbarcontent)
          }          
        }
         if (change.type === 'modified') {
           let notedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let notobj = this.Notifications.find(obj => obj.id === change.doc.id)
          let notindex = this.Notifications.indexOf(notobj)
          this.Notifications.splice(notindex,1,notedoc)
        }
      })
    })
    },
    NotificationRead(notification){
      if(notification.Read === false){
       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notification.id).update({
        Read: true
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = true
      this.$router.push(notification.Path)

      }

    },
  
    MarkasUnread(notification){
      if(notification.Read === true && notification.Type === 'Task Created'){
       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notification.id).update({
        Read: false
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = false


      }
    },
    SignoutNotifications(){
    this.Notifications = []
  },
  GetSiteModerators(){
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              if (change.type === 'modified') {
                let newmodobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.SiteModerators.find(obj => obj.id === newmodobj.id)
                if(oncheck){
                  let index = this.SiteModerators.indexOf(oncheck)
                  this.SiteModerators.splice(index,1,newmodobj)
                }
              }
            })
          
        })
      },
  AssignAuth(user){
      let vm = this
       
    let friendhipspayload = {                
        query: db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('friendships').where(user.uid,'==',true),
        arraymutation: 'setFriendshipsArray',
        getter: 'getFriendshipsArray',
            arrayname: 'Friendships'
      }
      let currentuserpayload = {
        userid: user.uid,
        db: db,
        RapidAppsDB: false
      }
          vm.$store.dispatch('SetUserObj',currentuserpayload).then(function(result) {
          if(result){
            
            
            if(vm.userIsAdmin && !vm.SystemisRA){
             // console.log('result',result,'vm.IsPrimarySite',vm.IsPrimarySite)
              if(vm.IsPrimarySite && vm.$store.state.RAUserObj === null){
              vm.ClientAppAdminSignin()
              }
              vm.GetAppFonts()
             let entmasterfieldspayload = {                
                query: vm.DBMasterPath.collection('entities').doc('Entities').collection('AllFields'),
                arrayname: 'EntityMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',entmasterfieldspayload)
              let sysmasterfieldspayload = {                
                query: vm.DBMasterPath.collection('entities').doc('Systems').collection('AllFields'),
                arrayname: 'SystemMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',sysmasterfieldspayload)
            }
            else if(vm.userIsAdmin){
              vm.GetAppFonts()
              let entmasterfieldspayload = {                
                query: vm.DBMasterPath.collection('entities').doc('Entities').collection('AllFields'),
                arrayname: 'EntityMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',entmasterfieldspayload)
              let sysmasterfieldspayload = {                
                query: vm.DBMasterPath.collection('entities').doc('Systems').collection('AllFields'),
                arrayname: 'SystemMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',sysmasterfieldspayload)
            }
            let Session = localStorage.getItem('activesession')
            if (Session === null) {
              vm.ActiveSession = new Date()
              localStorage.setItem('activesession', vm.ActiveSession);
            }
            else{
              vm.ActiveSession = Session
            }
            let sitekwpayload = {                
                      query: db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('sitekeywords'),
                      arrayname: 'SiteKeyWordOptions'
                    }
                    // if(!vm.userIsAdmin){
                    //   sitekwpayload.query = sitekwpayload.query.where('Type.Name','==','Featured Member')
                    // }
                    vm.$store.dispatch('GetCollectionArray',sitekwpayload)
            //console.log(result)
            vm.$store.dispatch('SetUserBUObj',result.Business_Unitid).then(function(BUResult) {
              
              
              //console.log('in dispatch BUResult')
              if(BUResult){
                //console.log('if BUResult',BUResult)
              }
              //let userroles = vm.userLoggedIn.rolesarrayQuery
              if(vm.UserisGuest){             
                vm.SignedIn = true
                // if(!vm.System.Guests_can_Social){
                  //basically we used to think is guest cansocial that social is primary
                  //but here with sore we see store is primary. If you wanted community primary just change it.
                  //but where guest cansocial or not it does not matter
                  //the only catch is, there are thus two landing pages, 1 for when a user toggles to "intranetnavbar", and the other, beign the default page a user lands on when they sign in
                  //essentially, the previous code kept rerouting the user to the damn store page and crap and that's just tardy
                  if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){  
                    if(!vm.System.Guest_Landing_Page){
                          if(vm.ActiveMemberGroup && vm.ActiveMemberGroup.Guest_Landing_Page){
                            if(vm.UserisGuest){
                              if(vm.ActiveMemberGroup.Guest_Landing_Page.includes('/FeaturedMember/')){
                                //console.log(vm.ActiveMemberGroup)
                                if(!vm.$store.state.FeaturedMemberHasPages){
                                  vm.$router.push('/FeaturedMemberCreator/'+vm.userLoggedIn.id)
                                }
                                else{
                                  //must be ebale to set landingpage on featmem, in order to know where to go, so mockup being
                                  //if(vm.$store.state.FeaturedMemberDefault){
                                    // vm.$router.push('/FeaturedMember/'+vm.userLoggedIn.id+'/'+vm.$store.state.FeaturedMemberDefault)
                                  //}
                                  //else{
                                    //vm.$router.push('/FeaturedMember/'+vm.userLoggedIn.id+'/'+vm.$store.state.FeaturedMemberDefault)
                                  //}
                                  //so okay the difference being...well no like...
                                vm.$router.push('/FeaturedMember/'+vm.userLoggedIn.id+'/'+vm.$store.state.FeaturedMemberDefaultPage)
                                }
                              }
                              else{
                                 
                                vm.$router.push(vm.ActiveMemberGroup.Guest_Landing_Page)
                              }
                            }
                          }
                          else{
                            if(vm.$route.name !== 'MyAccount'){
                             vm.$router.push('/MyAccount')
                            }
                          }                     
                    }  
                    else{
                      vm.$router.push(vm.System.Guest_Landing_Page)
                    }                  
                  }
                // }
                //  else{
                //    if(!vm.System.Guest_Landing_Page){
                //         if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login' || vm.$route.path.toLowerCase() === '/signup'){
                //             vm.IntranetViewToggle(true,true)
                //         } 
                //       }
                //       else{
                //         vm.$router.push(vm.System.Guest_Landing_Page)
                //       } 
                    
                //   }      
                 let notificationsquery = db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('notifications').where('Ownerid', '==', vm.userLoggedIn.id)
                    vm.GetUserNotifications(notificationsquery,10000)
                    if(!vm.FriendshipsStore || vm.FriendshipsStore && vm.FriendshipsStore.length === 0){
                      vm.$store.dispatch('GetCollectionArray',friendhipspayload)
                    }
                     vm.GetUsers(db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users'))
                     
                    let onlineobj = {
                      status: 'online',
                      uid: user.uid
                    }
                    vm.$store.dispatch('UserLoginStatus',onlineobj)
                    vm.GetUserAccess()
              }
              else{
                vm.ProcessBalance(BUResult,user,vm,friendhipspayload)                
              }
              
          })
          }
          
        })
      
    },  
    GetUsers(query,recall){
      //console.log('GetUsers ',query)
      //console.log('well what do you know - GetUsers ',this.$store.state.UsersArray.length,this.$store.state.UsersArray,query)
      let payload = {                
            query: query,
            arraymutation: 'setUsersArray',
            getter: 'getUsersArray',
                arrayname: 'UsersArray'
          }
      if(this.UsersStore.length === 0 || recall){
        //console.log('apparently calling again')
          
          // if(vm.UserisGuest){  
          //   payload.query = db.collection('users').where('MyProfileViewPermissions','==','Anybody')
          // }
          // let usersquerypayload = {
          //     collectionstate: 'UsersQuery',
          //     query: payload.query
          //   }
          //   // console.log(payload)
          //   vm.$store.dispatch('SetUserQueryPermission',usersquerypayload).then(function(QueryResult) {
          //     console.log('set usersquerypayload ',QueryResult)
          //   })
        if(this.CompanyOnlyFilter){
          //uhm okay this is not too kind....we must maintain companyid on users please...
          payload.query = [payload.query.where('Companyid','==',this.UserCompanyid),payload.query.where('Companyid','==','Non_Guest')]
        }
        //this.$store.dispatch('GetCollectionArray',payload)
          
      }
      //db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
      this.UsersCall = payload.query.onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
                let userobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                  }
                this.UsersArray.push(userobj)
              }
              if(change.type === 'modified'){
                let userobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let item = this.UsersArray.find(obj => obj.id === userobj.id)
                if(item){
                  let index = this.UsersArray.indexOf(item)
                  this.UsersArray.splice(index,1,userobj)
                }
              }
              if(change.type === 'removed'){
                let userobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let item = this.UsersArray.find(obj => obj.id === userobj.id)
                if(item){
                  let index = this.UsersArray.indexOf(item)
                  this.UsersArray.splice(index,1)
                }
              }
            })
      })
    },
    ProcessBalance(BUResult,user,vm,friendhipspayload){
      vm.SystemEntities.map(build => {
         vm.GetGoalMeasurements(build).then(function(goalsresult) {
      })
        return build
      })
    
      if(BUResult){
                vm.SystemLoadingvalue = 100
                vm.GetSiteModerators()
                vm.GetUserAccess()
                
                // vm.SystemLoading = false
                if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                  if(vm.userIsAdmin && vm.$route.name !== 'MyAccount'){
                    vm.$router.push('/MyAccount')
                    // if(vm.userLoggedIn.Primary_Appid){
                    //   vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
                    // }
                  }
                  else if(vm.userLoggedIn.Primary_Appid){
                    vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
                  }
                  else{
                    vm.$router.push('/MyDashboard')
                  }
                    
                }
                let onlineobj = {
                  status: 'online',
                  uid: user.uid
                }
                if(!vm.FriendshipsStore || vm.FriendshipsStore && vm.FriendshipsStore.length === 0){
                  vm.$store.dispatch('GetCollectionArray',friendhipspayload)
                }
                vm.GetUsers(db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users'))                
                vm.$store.dispatch('UserLoginStatus',onlineobj)
                let notificationsquery = db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('notifications').where('Ownerid', '==', vm.userLoggedIn.id)
                vm.GetUserNotifications(notificationsquery,10000)
                let activitiesquery = db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('activities').where('Ownerid', '==', vm.userLoggedIn.id).where('Status_Reason.Name', 'in' ,['Assigned','Completed','In Progress','On Hold'])
                vm.GetUserActivities(activitiesquery)
                //GetMyTeamActivities - would actually return my activities as well mind you... 
                //usersubordinates().hasAny([RecordOwner()])
                // getUserRoleData().SubordinateArrayDBrules.keys()
                let goalsquery = db.collection('goals').where('UserListIDs', 'array-contains', vm.userLoggedIn.id)
                vm.GetUserGoals(goalsquery)
              }
    },
  
    GetUserGoals(query){
      let vm = this
      query.onSnapshot(res => {
        let listlength = res.docs.length
        if(listlength === 0){
          this.MyGoalsisEmpty = true
        }
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let obj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          obj.Targets = []
          this.GetGoalTargets(obj).then(result => {
              console.log(obj,result)
              vm.MyGoals.push(obj)
          })
        }
      })
    })
    },
    GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').doc(vm.userLoggedIn.id).onSnapshot(snapshot => {
                  let targetobj = snapshot.data()
                  if(targetobj){
                    let targetslength = targetobj.Targets.length
                  targetobj.Targets.map((target,i) => {
                  obj.Targets.push(target)                  
                  //console.log(targetslength,i-1+2)
                      if(i-1+2 === targetslength){                             
                          resolve('Got all targets for ',obj.id)  
                      }
                    }) 
                  }
                  else{
                    resolve('Got no targets for user on ',obj.id)
                  }
                   
                })
            })

        },
    GetUserActivities(query){
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          this.MyActivitiesArray.push(actobj)
        }
        if(change.type === 'modified'){
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let item = this.MyActivitiesArray.find(obj => obj.id === actobj.id)
          if(item){
            let index = this.MyActivitiesArray.indexOf(item)
            this.MyActivitiesArray.splice(index,1,actobj)
            //console.log('modified this.MyActivitiesArray',this.MyActivitiesArray)
          }
        }
      })
    })
    }, 
    GetUserAccess(Business_Unitid){
      let vm = this
      
      vm.SignedIn = true
      vm.PrimarySiteCalled = true
      vm.SystemLoadingvalue = 100
      if(vm.SystemLoading){
        vm.SystemLoading = false
      }      
      //console.log('UserTableFilters',vm.UserTableFilters)
      //console.log('calling GetEntityRoutes')
      this.GetEntityRoutes(db.collection('Databases').doc(this.ActiveSuiteid).collection('entities'))
      //vm.RoutesandSecurity()
    },
    RoutesandSecurity(){
      let vm = this
      let routes = vm.$router.getRoutes()
      //console.log('routes',routes)
        let userroles = vm.userLoggedIn.rolesarrayQuery
        let routeslength = routes.length
        routes.map((route,routeindex) => {
          
          //sstop mofo stop!!! right here we need to consider roottableentities
          let basetableroute = route.meta.EntityID ? this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(route.meta.EntityID) && !this.$store.state.rootdbentities.includes(route.meta.EntityID) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(route.meta.EntityID).collection('Records') : 
          this.$store.state.roottableentities.includes(route.meta.EntityID) ?
          db.collection(route.meta.EntityID.split('_').join('').toLowerCase()) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(route.meta.EntityID.split('_').join('').toLowerCase()) : ''
          if(route.meta && route.meta.Security && route.meta.Security.SecurityType === 'Warehouse'){
            //no interaction? what then? how do we set the permission level? do we need to?
            //also don't think we should do anything else for that matter, all the runs should be done from userloggedin watcher
          }
          else if(route.meta && route.meta.Security && route.meta.Security.SecurityType === 'Custom Roles'){
            //console.log(route.meta.EntityID)
            this.CustomRolesDefault.map(defrole => {
              if(route.meta.Security[defrole.Boolean] || route.meta.Security[defrole.Prop] === 'Custom Role'){
                
                vm.CustomRolesCheck(route.meta.Security[defrole.Name],userroles).then(PermissionLevel => {
                  if(PermissionLevel === 1){
                  let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }      
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                  }
                }) 
              }
              else if(route.meta.Security[defrole.Prop] === 'Signed In' || route.meta.Security[defrole.Prop] === 'Public'){
                let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }        
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
              }
              else  if(route.meta.Security[defrole.Prop] === 'Admin'){
                if(this.userIsAdmin){
                  let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }        
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                }                
              }
              if(defrole.Action === 'List'){
                //console.log(defrole.Name+' as defrole.Name on '+route.name,route.meta.Security[defrole.Boolean],route.meta.Security[defrole.Prop])
                if(route.meta.Security[defrole.Boolean] && route.name !== 'users' && route.name !== 'businessunits'){
                 
                  vm.CustomRolesCheck(route.meta.Security[defrole.Name],userroles).then(PermissionLevel => {
                    //console.log('PermissionLevel on ',route.name,' === ',PermissionLevel)
                  if(PermissionLevel === 1){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    let entity = vm.SystemEntities.find(obj => obj.id === route.meta.EntityID)
                    if(entity && entity.Entity_Type && entity.Entity_Type.Name === 'Store Product'){
                      
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  })
                }
                else if(route.name === 'businessunits'){
                  basetableroute = db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits')
                    if(!vm.UserisGuest){
                      let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                        query: basetableroute
                      }      
                      vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                        let bupayload = {                
                                query: payload.query,
                                arraymutation: 'setBusinessUnitsArray',
                                getter: 'getBusinessUnitsArray',
                                arrayname: 'BusinessUnitsArray'
                              }
                              vm.$store.dispatch('GetCollectionArray',bupayload)
                        })
                  }
                  else{
                    let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                        query: basetableroute.where(firebase.firestore.FieldPath.documentId(), 'in', vm.userBUChildren)
                      }   
                      vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                        let bupayload = {                
                                query: payload.query,
                                arraymutation: 'setBusinessUnitsArray',
                                getter: 'getBusinessUnitsArray',
                                arrayname: 'BusinessUnitsArray'
                              }
                              vm.$store.dispatch('GetCollectionArray',bupayload)
                        })
                  }
                }
                else if(route.meta.Security[defrole.Prop] === 'Signed In' || route.meta.Security[defrole.Prop] === 'Public'){
                  let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                }
                else if(this.userIsAdmin){
                  // else if(route.meta.Security[defrole.Prop] === 'Admin'){
                  //alert(defrole.Name+' as defrole.Name on '+route.name+' '+route.meta.Security[defrole.Boolean] +'as'+route.meta.Security[defrole.Prop])
                  // if(this.userIsAdmin){
                    let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                        query: basetableroute
                      }
                      if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                      vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                      })
                  // }
                }
              }            
            })
            
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Business Unit' && route.meta.Collection !== 'Activities'){
             //console.log(route.meta.EntityID,this.BURolesDefault)
            let RoleObject = {}
            let length = this.BURolesDefault.length
         
            this.BURolesDefault.map((defrole,i) => {
              RoleObject[defrole.Name] = route.meta.Security[defrole.Name]
              if(defrole.Action !== 'List'){
                vm.BURolesCheck(RoleObject,defrole,userroles).then(PermissionsObject => {
                  if(defrole.PermissionLevel === PermissionsObject.PermissionLevel){
                      let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+defrole.Boolean,
                        boolean: true
                      }
                      vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                  }                  
                })
              }              
              if(i-1+2 === length){
                
                vm.BUListRolesCheck(RoleObject,userroles).then(PermissionsObject => {
                  //console.log('PermissionsObject',PermissionsObject,route.name)
                  if(PermissionsObject.PermissionLevel === 4){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    // let entity = vm.SystemEntities.find(obj => obj.id === route.meta.EntityID)
                    // if(entity && entity.Entity_Type && entity.Entity_Type.Name === 'Store Product'){
                    //   //the problem is, query is going to be an array
                    //   let whqueryarray = []
                    //   let whlength = vm.$store.state.WarehousesArray.length
                    //   vm.$store.state.WarehousesArray.map((wh,whindex) => {
                    //     let passcheck = wh.id+route.meta.EntityID
                    //     let whquery = payload.query.where('Warehouseid','==',wh.id)
                    //     let whpayload = payload
                    //     //yrqFisJXWV2atmzk0OQBWarehouse_Products
                    //     if(passcheck){
                    //       whqueryarray.push(whquery)
                    //     }
                    //     if(whindex-1+2 === whlength){
                    //       whpayload.query = whqueryarray
                    //       vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    //       })
                    //     }
                    //   })
                    // }
                    // else{
                       vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                       
                    })
                    // }
                   
                  }
                  
                  else if(PermissionsObject.PermissionLevel === 3){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute.where('Business_Unitid', 'in', vm.userBUChildren)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(PermissionsObject.PermissionLevel === 2){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute.where('Business_Unitid', '==', vm.userBUID)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(PermissionsObject.PermissionLevel === 1){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute.where('Ownerid', '==', this.userLoggedIn.id)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(route.meta.EntityID === 'User_Companies' && this.MyCompany && this.MyCompany.Userid === this.userLoggedIn.id){                    
                     let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: basetableroute.where(firebase.firestore.FieldPath.documentId(), '==', this.MyCompany.id)
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
              })
              }
            })
              
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Signed In' && route.name !== 'BusinessUnits' || route.meta.Security && route.meta.Security.SecurityType === 'Signed In' && !this.UserisGuest){
            let payload = {
                      collectionname: route.name.split(' ').join('').toLowerCase(),
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name.split(' ').join('').toLowerCase())
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Signed In' && route.name === 'BusinessUnits' && this.UserisGuest){
            
            let payload = {
                      collectionname: route.name.split(' ').join('').toLowerCase(),
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name.split(' ').join('').toLowerCase()).where('IsPublicLocation','==',true)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Business Unit'){

          }
          setTimeout(() => {
            //THIS IS A CHEESY CHEAT!!
          if(routeindex-1+2 === routeslength){
            vm.RouterReady = true
          }  
          }, 1000);
          
        })
    },
    CustomRolesCheck(Roles,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        // console.log(Roleslength)
        return new Promise(function(resolve, reject) {
          if(vm.userIsAdmin || vm.userIsEagleViewer){
            PermissionLevel = 1
            resolve(PermissionLevel)
          }
          else{
             UserRoles.map((role,i) => {
          // console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest || vm.userIsAdmin || vm.userIsEagleViewer){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })
          }
       

        })
      },
    BURolesCheck(RoleObject,defrole,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        return new Promise(function(resolve, reject) {
          if(vm.userIsAdmin || vm.userIsEagleViewer){
            PermissionLevel = 4
            resolve({
                PermissionLevel: PermissionLevel,
              })
          }
          else{
        UserRoles.map((role,i) => {
          let roletest = RoleObject[defrole.Name].find(obj => obj == role)
           
          if(typeof roletest !== 'undefined' || vm.userIsAdmin){
              PermissionLevel = defrole.PermissionLevel
          }
         
            if(i-1+2 === Roleslength){
              
              let PermissionsObject = {
                PermissionLevel: PermissionLevel,
              }
              // console.log('Roleslength done',PermissionsObject)
              resolve(PermissionsObject)
            }
          })
          }
        })
      }, 
    BUListRolesCheck(RoleObject,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        //console.log(Roleslength)
        return new Promise(function(resolve, reject) {
          if(vm.userIsAdmin || vm.userIsEagleViewer){
            PermissionLevel = 4
            resolve({
                PermissionLevel: PermissionLevel,
              })
          }
          else{
        UserRoles.map((role,i) => {
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
           
          if(typeof alltest !== 'undefined' || vm.userIsAdmin || vm.userIsEagleViewer){
              PermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(PermissionLevel<3){
              PermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(PermissionLevel<2){
              PermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(PermissionLevel<1){
              PermissionLevel = 1
            }          
          }
            if(i-1+2 === Roleslength){
              let PermissionsObject = {
                PermissionLevel: PermissionLevel,
              }
              resolve(PermissionsObject)
            }
          })
          }
        })
      }, 



  
  }
}
</script>

<style>
</style>