<template>
  <div>
    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Web Forms for your Suite <v-spacer></v-spacer><v-btn @click="CheckBack()">Back</v-btn>
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-title v-if="!ActiveLibTypeComp" class="mediumoverline">
            {{ActiveWebForm ? ActiveWebForm.DisplayName : 'Forms'}}
          <v-spacer>
          </v-spacer>
          <v-btn outlined :to="'/WebFormBuilder-New'">New</v-btn>
           <v-btn outlined :to="'/WebFormBuilder/'+ActiveWebForm.id" dark class="BoldBuilderGradient" v-if="ActiveWebForm">
            Configure
          </v-btn>
        </v-card-title>         
      <v-card-text v-if="!ActiveWebForm">
         <v-list>
              <v-list-item @click="ActivateActiveWebForm(form)" v-for="form in WebForms" :key="form.itemObjKey">
                  <v-list-item-avatar v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="MiniView ? '' : 'align-self: center;padding-right: 10px;'">
                    <v-icon :size="40">{{form.Table_Icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-action>
                    <v-list-item-title style="align-self: start;" class="smallbanneroverline">
                      <v-icon v-if="MiniView" :size="20">{{form.Table_Icon}}</v-icon>
                      {{form.DisplayName}}
                    </v-list-item-title>
                    </v-list-item-action>
              </v-list-item>
          </v-list>
      </v-card-text>
      <v-card-text v-if="ActiveWebForm">
            <v-tabs>
            <v-tab>
                Settings
            </v-tab>
            <v-tab-item>
                <v-list>
                    <v-list-item>
                        <!-- <v-text-field label="Icon" :rules="[$store.state.formrules.required]" v-model="ActiveWebForm.Icon" /> -->
                        <v-select multiple
                        @change="UpdateSharedPlugins(ActiveWebForm)" :items="RegisteredApps" item-text="id" label="Active Apps" v-model="ActiveWebForm.ActiveApps"/>
                    </v-list-item>
                </v-list>
            </v-tab-item>
        </v-tabs>
      </v-card-text>
       </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            ActiveWebForm: ''
        }
    },	
    computed:{
        ConfigDB(){
            return this.SystemisRA ? db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID) : 
            this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID)
        },
        ConfigDBAppsPath(){
            return this.ConfigDB.collection('apps')
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        RADB(){
            return this.$store.state.RADB
        },
        RAUserObj(){
            return this.$store.state.RAUserObj
        },
        RegisteredApps(){
            return this.$store.state.AppsDataBase
        },
        WebForms(){
            return this.WebFormEntities.map(ent => {
                let entobj = {
                    id: ent.id,
                    DisplayName: ent.DisplayName,
                    Table_Icon: ent.Table_Icon,
                    ActiveApps: []
                }
                entobj.ActiveApps = this.RegisteredApps.filter(app => {
                    let SharedWebForms = []
                    if(app.SharedWebForms){
                        SharedWebForms = app.SharedWebForms
                    }
                    return SharedWebForms.includes(entobj.id)
                })
                return entobj
            })
        },
         WebFormEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'
            })
        },
         MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ActivateActiveWebForm(form){
            this.ActiveWebForm = Object.assign({},form)
        },
        UpdateSharedPlugins(ActiveWebForm){
            console.log(this.SystemEntities,this.ActiveWebForm)
            //so either we sahre the web form entity via shared plugins
            //or we share simply the entity (although that I don't think we allow I think we only share entity throught shared plugins right?)
            //or we share web forms plugin, which is what shared lugin comes down to, but then somehow propogate which web forms applicable to the sharing...?
            //or we keep it really simple which I prefer. Somehow AppEntities propogate with all shred webforms? new prop "Shared WebForms?"
            
            this.RegisteredApps.map(app => {
                let sharedwithactive = this.ActiveWebForm.ActiveApps.find(obj => obj = app.id)
                let SharedWebForms = []
                if(app.SharedWebForms){
                    SharedWebForms = app.SharedWebForms
                }
                if(sharedwithactive && !SharedWebForms.includes(this.ActiveWebForm.id)){
                    SharedWebForms.push(this.ActiveWebForm.id)
                }
                this.ConfigDBAppsPath.doc(app.id).update({
                    SharedWebForms: SharedWebForms
                })
            })
        },
        CheckBack(){
            if(this.ActiveWebForm){
                this.ActiveWebForm = ''
            }
            else{
                this.$emit('GoBack')
            }
        },
    }
}
</script>

<style>

</style>



