<template>
<div>
     <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveRecord({Record: Record,Fields: [field]})" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn>
    <v-list-item class="justify-center subtleoverline">
        {{field.DisplayName}}
    </v-list-item>
    <v-list-item class="justify-center" v-if="!Editing">
        <v-avatar size="110" color="blue">
        <v-icon size="90" dark>
                mdi-calendar
            </v-icon>
        </v-avatar>
    </v-list-item>
  <v-list-item style="text-align: center;">
    <!--  :class="$vuetify.theme.dark ? field.GraphicColor+' darken-3' : field.GraphicColor+' darken-1 white--text'" -->
        <v-list-item-content v-if="FieldValue !== 'NO DATA' && !Editing">
            <v-list-item-subtitle class="mediumoverline" style="font-size: 1.8em;">
                {{DateGraphicValue(FieldValue,'Week Day')}}, {{DateGraphicValue(FieldValue,'Day')}}
            </v-list-item-subtitle>
             <v-list-item-title style="font-size: 1.4em;font-weight: 300;">
                {{DateGraphicValue(FieldValue,'Month Name')}}
            </v-list-item-title>
            <v-list-item-title style="font-size: 1.2em;font-weight: 300;">
                {{DateGraphicValue(FieldValue,'Year')}}
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content class="justify-center mediumoverline" v-if="FieldValue === 'NO DATA' && !Editing">
            NO DATA
        </v-list-item-content>
        <v-list-item-content class="justify-center mediumoverline" v-if="Editing">
           <v-date-picker v-model="DateString" :readonly="field.ReadOnly"></v-date-picker>
        </v-list-item-content>
    </v-list-item>
     <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit'],
    components: {},
    data() {
        return {
            Editing: false,           
            DateMenu: false,
            TimeMenu: false,
            DateString: '',
            TimeResponse: '',
        }
    },	
    computed:{
    DateObject(){
        let dateobject = ''
        if(!this.field.HasTimeInput){
            dateobject = new Date(this.DateString)
        }
        else if(this.field.HasTimeInput){                       
            dateobject = new Date(this.DateString+' '+this.TimeResponse)
        }
        return dateobject
        },
    field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        'NO DATA'
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        Record: {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    this.AssignDateValue()
                }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.AssignDateValue()
    },
    methods:{        
        AssignDateValue(){
            if(this.FieldValue && this.FieldValue !== 'NO DATA'){
                let dateobject = this.FieldValue.toDate ? this.FieldValue.toDate() : this.FieldValue
                this.DateString = format(dateobject,'yyyy-MM-dd')
                if(this.field.HasTimeInput){
                    let hours = dateobject.getHours()
                    let mins = dateobject.getMinutes()
                    if(hours === 0){
                    hours = '00'
                    }
                    if(mins === 0){
                    mins = '00'
                    }
                    this.TimeResponse = hours+':'+mins
                    //this.Record[field.Name+'TimeResponse'] = field.TimeResponse
                }
            }
        },
        SaveRecord(payload){
           payload.Record[this.field.id] = this.DateObject
           if(this.field.HasTimeInput){
            payload.Record[this.field.id+'TimeResponse'] = this.TimeResponse
           }
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },
        DateGraphicValue(javadate,format){
            let d = new Date()
            let string = ''
            const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]
var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
       
            if(javadate.toDate){
                d = javadate.toDate()
            }
            else if(typeof javadate === 'string'){
                d = new Date(javadate)
            }
            else{
                d = javadate
            }
            if(format==='Year'){
                string = d.getFullYear()
            }
            else if(format==='Week Day'){
                string = days[d.getDay()].Longtext
            }
            else if(format==='Month Name'){
                string = monthNames[d.getMonth()]
            }
            else if(format==='Day'){
                string = d.getDate()
            }
            return string
                        
        },
    }
}
</script>

<style>

</style>



