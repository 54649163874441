
<template>
<v-card  :class="tab.BoxTransparency+' '+tab.Justify+' mx-3' "  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  v-if="tab.Name === 'Gallery'"
:style="'margin-top:'+tab.RowMarginTop+';margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'"
 @click="ActivateElementConfigComp('GalAssetsComponent',tab,i)">
     <v-card v-if="tab.ExtendWithGalleryParallax && tab.Assets.length>0" class="transparent" width="100%" flat>
      
            <v-parallax
              :style="CheckifParallaxClipped(tab)"         
            height="350"
            :src="tab.Assets[Math.floor(Math.random() * Math.floor(tab.Assets.length))].url ? tab.Assets[Math.floor(Math.random() * Math.floor(tab.Assets.length))].url : tab.Assets[Math.floor(Math.random() * Math.floor(tab.Assets.length))].URL"
            > 
            </v-parallax> 
            </v-card>
     <v-sheet
    :class="tab.GalleryType  !== 'Clipped Images' ? 'mx-auto my-3 transparent' : 'transparent'"
    :elevation="tab.Elevation"  :style="tab.GalleryType  !== 'Clipped Images' ? '' : $vuetify.breakpoint.sm ? 'height:30vh;' : $vuetify.breakpoint.xs ? 'height:20vh;' : 'height:60vh;'"
  >
     <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.Title && tab.GalleryType  !== 'Clipped Images'"								
        :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
        />
         <HTMLDescription v-if="tab.Description && tab.GalleryType  !== 'Clipped Images'"
            :tab="tab" :PageContentFont="PageContentFont"
            />
         <v-row :class="tab.Justify+' mx-3'" v-if="tab.HasFilters">
            <v-chip-group column v-if="tab.HasFilters">
              <v-chip style="background-color: background !important;" @click="ActiveGalleryFilter = ''" outlined>
                All
              </v-chip>
              <v-chip  style="background-color: background !important;" @click="ActivateFilter(filter)" outlined v-for="filter in tab.Filters" :key="filter.itemObjKey">
                {{filter.Name}}
              </v-chip>
            </v-chip-group>
        </v-row>
         <v-layout :style="'height: 100%;'+tab.BoxGradient+tab.CustomStyle" row v-if="tab.GalleryType  === 'Clipped Images'" >
          <!--  @mouseleave="SetCardStyleObject(card,cardindex)"  @mouseover="SetCardStyleObject(card,cardindex,true),SetPreviewCard(card,cardindex,true)" -->
          <v-col cols="6" v-if="!Refreshing" style="height: 100%;width:100%;position: absolute;padding: 0px;" >
          <v-img :style="card.StyleObject" v-for="(card,cardindex) in RenderedGalleryAssets" :key="card.itemObjKey"
         @click="cardindex === PreviewCardIndex ? '' : SetCardStyleObject(card,cardindex,true),SetPreviewCard(card,cardindex,true)" 
          :src="card.URL"
          />
          </v-col>
           <v-col cols="6" v-if="tab.Assets[PreviewCardIndex]" class="white--text" style="height: 100%;width:100%;position: absolute;right: 0px;">
            <v-list-item class="subtleoverline justify-center"
            :style="$vuetify.breakpoint.sm ? 'height: 100%;font-size: large !important;' : $vuetify.breakpoint.xs ? 'height: 100%;font-size: medium !important;' : 'height: 100%;font-size: xx-large !important;'">
              <v-textarea v-model="tab.Assets[PreviewCardIndex].Caption" label="Caption" />
            </v-list-item>
           </v-col>
           <v-avatar v-if="tab.HasImage"
                    tile style="opacity: 0.1;"
                    :size="'100%'"
                >
               <v-img  v-if="tab.HasImage && tab.IMG"
                
                    :src="tab.IMG"
                    :style="'background-attachment: fixed;width:100vh;height: 100vh; min-width: 100vh;'+CheckifClipped(tab)"
                    
                    />
                    <v-img  :style="'background-attachment: fixed;'+CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                :height="'100vh'"
                    src="@/assets/ImageHolder.png"
                    />
                </v-avatar> 
        </v-layout>
          <v-row :class="'justify-center'"  v-if="tab.GalleryType === 'Carousel'"
          :style="MiniView ? 'height: 500px;margin-bottom: 100px;'+tab.CustomStyle : 
         'height: 600px;margin-bottom: 100px;'+tab.CustomStyle">
              <!-- <svg height="0" width="0" v-if="tab.ClipAnimPathFWD && !PathReset" >
                     <clipPath :id="i+'ClipAnimPathFWD'" v-html="tab.ClipAnimPathFWD">
                      </clipPath>
                       <clipPath :id="i+'ClipAnimPathREV'" v-html="tab.ClipAnimPathREV">
                      </clipPath>
              </svg> -->
                <div class="v-window__prev" style="z-index:2;">
              <v-btn dark icon @click="SelectCarouselItem('Previous')"><v-icon>mdi-chevron-left</v-icon></v-btn>
              </div>
              <div class="v-window__next" style="z-index:2;">
              <v-btn dark icon @click="SelectCarouselItem('Next')"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </div>
              <v-col cols="12" :style="'display: inline-flex;height: 450;position: absolute;'">
               
                <v-layout  v-for="picture in RenderedCarouselAssets" :key="picture.itemObjKey" :style="tab.GalIMGContain ? 'padding:20px;width: 50%;position: absolute;'+picture.AnimationStyle :
                 'width: 100%;padding:20px;position: absolute;'+picture.AnimationStyle"  row class="justify-center">
                 <v-img
               :contain="tab.GalIMGContain" :style="'margin-top: 30px;margin-bottom: 30px;max-height:600px;min-height:450px;'+picture.AnimationStyle+picture.ClippingStyle"
                    :src="picture.URL"  :aspect-ratio="16/9"
                  >  
                  <v-overlay v-if="tab.BoxGradient" class="gradoverlay" color="#ffffff00" absolute :style="tab.BoxGradient"/>
                </v-img>
                     <v-flex :class="'lg'+6+' md'+8+' sm'+12+' xs'+12+''"  :style="MiniView? 
                    'position: absolute;align-self:center;z-index:2;width:70%;left:0%;top:0px;' : $vuetify.breakpoint.md ? 
                    'position: absolute;align-self:center;z-index:2;width:45%;left:5%;top:0px;' :
                    'position: absolute;align-self:center;z-index:2;width:30%;left:15%;top:0px;'">
                      <v-card tile :class="'transparent'" flat :style="MiniView? '' : 'margin:10px;padding:10px;'" >
                        <v-card-title v-if="picture.Title" :style="AssetTitleStyle(tab)">
                          {{picture.Title}}
                         </v-card-title>
                        <v-card-text class="my-1" :style="AssetFontStyle(tab)">
                        <span  v-html="picture.Caption"  :style="CaptionFontStyle(tab)"/>
                    </v-card-text>
                        </v-card>
                    </v-flex>
              </v-layout>
              </v-col>
         </v-row>
         <!-- <v-row :class="'justify-center'" v-if="tab.GalleryType  === 'Carousel'">
          <v-carousel v-model="model"  class="galcarousel" cycle continuous :aria-controls="false">
            <v-carousel-item
                v-for="picture in RenderedGalleryAssets" :key="picture.itemObjKey"
                >
                <v-img :contain="tab.GalIMGContain" :style="'margin-top: 30px;margin-bottom: 30px;'+tab.ClippingStyle"
                    :src="picture.URL" height="450"
                  >  
                  <v-overlay v-if="tab.BoxGradient" class="gradoverlay" color="#ffffff00" absolute :style="tab.BoxGradient"/>
                </v-img>                        
            </v-carousel-item>
            <v-layout :style="tab.GalIMGContain ? 'position: absolute;width: 100%;top: 200px;' : 'position: absolute;width: 100%;top: 200px;'"  row
            :class="'justify-center mx-3'" v-if="RenderedGalleryAssets[model] && RenderedGalleryAssets[model].Caption">
                    <v-flex :class="'lg'+6+' md'+8+' sm'+12+' xs'+12+''"  :style="MiniView? 
                    'position: absolute;align-self:center;z-index:2;width:70%;left:0%;top:0px;' : 
                    'position: absolute;align-self:center;z-index:2;width:25%;left:25%;top:0px;'">
                      <v-card tile :class="'transparent'" flat style="margin:10px;padding:10px" >
                        <v-card-title v-if="RenderedGalleryAssets[model].Title" :style="TitleStyle(tab)">
                          {{RenderedGalleryAssets[model].Title}}
                         </v-card-title>
                        <v-card-text class="my-1" :style="TabFontStyle(tab)">
                        <span  v-html="RenderedGalleryAssets[model].Caption" :style="DefaultPageFont+'color:'+tab.AssetCaptionColor.hex+';font-size:24px;'"/>
                    </v-card-text>
                        </v-card>
                    </v-flex>
              </v-layout>
          </v-carousel>
         </v-row> -->
        <v-row :class="tab.Justify" v-if="tab.GalleryType  === 'Cards'" :style="'min-height: 350px;'+tab.CustomStyle">
           <v-col
         v-for="(card,cardindex) in FilteredRenderedGalleryAssets"
                                :key="card.itemObjKey"
         class="d-flex child-flex" :style="tab.GalPadding? 'padding: '+tab.GalPadding+'px;' : 'padding: 0px;'"
          :cols="GalleryCols" > 
           <v-hover v-slot="{ hover }" v-if="!tab.NotHovered">
          <v-img @click="tab.FullScreenDialog ? OpenGalleryDialog(cardindex,tab.Assets) : ''"
            :src="card.URL"
            :aspect-ratio="GalleryColsRatio"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-expand-transition>
                <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out white--text"
                    :style="HoverCardStyle(tab)+'align-items: center;'"
                >
                 <div style="position: absolute; top: 15px;">
                      <span>
                      {{card.Title}} - 
                </span>
                <!-- <span class="font-weight-light">
                      {{card.Role}}
                </span> -->
                </div>
                <span :style="TabFontStyle(tab)">
                     <v-textarea v-model="card.Caption" label="Caption" />
                </span>
               
                </div>
                </v-expand-transition>
          </v-img>
           </v-hover>
           <v-img v-if="tab.NotHovered" @click="tab.FullScreenDialog ? OpenGalleryDialog(cardindex,tab.Assets) : ''"
            :src="card.URL"
            :aspect-ratio="GalleryColsRatio"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
         
          <!-- Caption -->
        </v-col>
        </v-row>
        
      <v-row :class="tab.Justify" v-if="!tab.GalleryType || tab.GalleryType  === 'Default'" :style="tab.CustomStyle">
    <v-slide-group
      v-model="model"
      class="pa-4"
      
      show-arrows
    >
      <v-slide-item
       v-for="(card,photoindex) in tab.Assets"
                                :key="card.itemObjKey"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-4"
          height="200"
          width="200"
          @click="toggle"
        >
        <v-row    
            v-if="!active"   
            class="fill-height"                    
            align="center"
            justify="center"
            >
            <v-avatar
            v-if="!active" 
            tile  :size="tab.NotHovered ? '220' : '100%'">
        <v-img 
                :src="card.ThumbURL"
            
            />
            </v-avatar>
        </v-row>
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-scale-transition>
                <v-btn
                 @click="DeleteTabAsset(tab,photoindex)"
                 v-if="active"
                >
              Delete
                </v-btn>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
      </v-row>
      
    <v-expand-transition v-if="!tab.HideCaptions" >
      <v-sheet
      v-if="model != null"
       class="mx-3"
        height="200"
        tile
      >
        <!-- <v-row
          class="fill-height"
          align="center"
          justify="center"
        > -->
         <v-text-field v-if="tab.GalleryType === 'Carousel'" v-model="tab.Assets[model].Title" label="Title" />
          <v-textarea v-model="tab.Assets[model].Caption" label="Caption" />
          <v-select v-if="tab.HasFilters" label="Filter" v-model="tab.Assets[model].Filter" return-object
          :items="tab.Filters" item-text="Name"/>
        <v-btn @click="model = null" outlined>Cancel</v-btn>
        <!-- </v-row> -->
      </v-sheet>
      
    </v-expand-transition>
    <v-expand-transition v-if="tab.ItemshaveViewURL && tab.Assets[model]" >
      
          <v-text-field :rules="[$store.state.formrules.url]" v-model="tab.Assets[model].ViewURL" label="View URL" />
      
    </v-expand-transition>
    <v-sheet :class="tab.BoxTransparency" :color="tab.BGColor.hexa"
       v-if="tab.Caption && !tab.HideCaptions && tab.GalleryType  !== 'Clipped Images' || tab.HasImage && !tab.HideCaptions && tab.GalleryType  !== 'Clipped Images'"
        :height="tab.Height? tab.Height+50 : 200"
        tile
      >

        <v-card-text
            v-if="tab.HasImage"
          align="center"
          justify="center"
        >
    
                    <v-avatar
                    tile
                    :size="tab.GalleryType  !== 'Clipped Images' ? tab.Height : '50%'"
                >
                <v-img  v-if="tab.HasImage && tab.IMG"
                :contain="!tab.Clipped"
                :height="tab.Height"
                    :src="tab.IMG"
                    :style="CheckifClipped(tab)"
                    
                    />
                    <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                :height="tab.Height"
                    src="@/assets/ImageHolder.png"
                    />
                </v-avatar> 
        </v-card-text>
       
      </v-sheet>
  </v-sheet>                 
    
        </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TitleTab from '@/components/WebPages/RenderComponents/TitleTab';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';


export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','System'],
    components: {
        TitleTab,
        HTMLDescription,
        },
    data() {
    return {  
        WindowHeight: 0,
      WindowWidth: 0,        
      ActiveGalleryFilter: '',       
      PreviewCardIndex: -1,
      PreviewCard: {
        StyleObject: '`width:50%;height:50%;z-index:2;clip-path: circle(70% at 30% 0%);object-fit: cover;transition: 0.5s;'
      },
      Refreshing: false,
        model: null, 
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      GalleryDialogWidth(){
        return this.WindowWidth-this.GalleryDialogNavbarWidth
      },
      GalleryDialogNavbarWidth(){
        return 350
      },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      GalleryCols(){
        if(this.tab.GalleryCols){
          return this.$vuetify.breakpoint.xs && this.tab.GalleryCols >  3 ? this.tab.GalleryCols : 
          this.$vuetify.breakpoint.xs && this.tab.GalleryCols <=  3 ? 4 : this.$vuetify.breakpoint.sm && this.tab.GalleryCols >  2 ? this.tab.GalleryCols : 
          this.$vuetify.breakpoint.sm && this.tab.GalleryCols <=  2 ? 3 : this.tab.GalleryCols
        }
        else{
          return 2
        }
      },
      GalleryColsRatio(){
        if(this.tab.GalleryColsRatio){
          return this.tab.GalleryColsRatio
        }
        else{
          return 0.5
        }
      },
      Gallerylength(){
        return this.FilteredRenderedGalleryAssets.length
      },
      GalleryFinalIndex(){
        return this.Gallerylength-1
      },
      CarouselNext(){
        let newint = this.model-1+2
        if(newint > this.GalleryFinalIndex){
          return 0
        }
        else{
          return newint
        }
      },
      CarouselPrev(){
        let newint = this.model-1+2
        if(newint < 0){
          return 0
        }
        else{
          return newint
        }
      },
      RevealClassMax(){
        return window.innerWidth/2
      },
      RenderedCarouselAssets(){
        return this.FilteredRenderedGalleryAssets.map((elmnt,elmntindex) => {
          if(elmntindex === this.model){
            elmnt.AnimationStyle = 'min-width: 100%;width: 100%;z-index:1;transition: 1s;right: 5px;'
            //elmnt.ClippingStyle = this.tab.ClippingStyle
            // elmnt.ClippingStyle = `-webkit-mask-image: url(#ClipAnimPathFWD);
            //                             -webkit-mask-repeat: no-repeat;
            //                             -webkit-mask-size: contain;
            //                             -webkit-mask-position: center;`
            if(this.tab.ClipAnimPathFWD){
              //elmnt.ClippingStyle = 'clip-path: url(#3TopWave1Path);position: absolute;'
              elmnt.ClippingStyle = 'clip-path: url(#'+this.i+'ClipAnimPathFWD);position: absolute;'
            }
            else{
              elmnt.ClippingStyle = this.tab.ClippingStyle
            }
            
          }
          else if(elmntindex === this.CarouselNext){
            elmnt.AnimationStyle = 'min-width: 100%;width: 100%;z-index:1;transition: 1s;right: '+(-this.WindowWidth)+'px;'
            if(this.tab.ClipAnimPathREV){
              elmnt.ClippingStyle = 'clip-path: url(#'+this.i+'ClipAnimPathREV);'
            }
          }
          else if(elmntindex === this.CarouselPrev){
            elmnt.AnimationStyle = 'min-width: 100%;width: 100%;z-index:1;transition: 1s;right: '+(-this.WindowWidth)+'px;'
            if(this.tab.ClipAnimPathREV){
              elmnt.ClippingStyle = 'clip-path: url(#'+this.i+'ClipAnimPathREV);'
            }
          }
          else{
            elmnt.AnimationStyle = 'min-width: 100%;width: 100%;z-index:1;transition: 1s;right: '+(-this.WindowWidth)+'px;'
            if(this.tab.ClipAnimPathREV){
              elmnt.ClippingStyle = 'clip-path: url(#'+this.i+'ClipAnimPathREV);'
            }
          }
          //elmnt.AnimationStyle = ''
          return elmnt
        })
      },
      FilteredRenderedGalleryAssets(){
        return this.RenderedGalleryAssets.filter(item => {
          if(this.ActiveGalleryFilter){
            return item.Filter.ID === this.ActiveGalleryFilter.ID
          }
          else{
            return item
          }
        })
      },
      RenderedGalleryAssets(){
        return this.tab.Assets && this.tab.Assets.length > 0 ? this.tab.Assets : this.SampleOnly ? [
          {Name: 'Sample',ThumbURL: require('@/assets/GallerySample1.jpg'),URL: require('@/assets/GallerySample1.jpg')},
          {Name: 'Sample',ThumbURL: require('@/assets/GallerySample2.jpg'),URL: require('@/assets/GallerySample2.jpg')}
        ] : []
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
      this.WindowWidth = window.innerWidth;
      this.WindowHeight = window.innerHeight;
      window.addEventListener("resize", this.ResizeWindow);
      if(this.tab.GalleryType === 'Carousel'){ 
      this.CheckifCarouselClipped(this.tab)
      this.model = 0
      //console.log(this.RenderedGalleryAssets[this.model])
      //this.ModelVar = setInterval(this.CarouselRoll,5000)  
      }
      else{
        this.CheckifParallaxClipped(this.tab)
      } 
      this.tab.Assets.map((card,cardindex) => {
        this.SetCardStyleObject(card,cardindex)
        })
        window.onload = setTimeout(() => {
        this.SetPreviewCard(this.tab.Assets[0],0)
        this.SetCardStyleObject(this.tab.Assets[0],0,true)
        }, 250)

    },
    
    methods: {
      ActivateElementConfigComp(compname,tab,i){
            this.$store.commit('setActiveElmntConfigComp',compname)
            this.OpenDialog(tab,i)
        },
      ResizeWindow(event){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
      },
      TabFontStyle(tab){
            return tab.FontBGColor && !tab.FontUnderline ? 'background-color: '+tab.FontBGColor+';width: 50%;' :
            tab.FontUnderline && tab.FontUnderlineColor ? 'background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;' :
            ''
        },
      HoverCardStyle(tab){
            //console.log(tab)
            let style = 'height: 100%;align-items: center;bottom: 0;justify-content: center;opacity: .75;position: absolute;width: 100%;'
            return style+'background-color: '+tab.HoverColor.hexa+';'
        },
      ActivateFilter(filter){
        if(this.ActiveGalleryFilter && this.ActiveGalleryFilter.ID === filter.ID){
          this.ActiveGalleryFilter = ''
        }
        else{
          this.ActiveGalleryFilter = filter
        }
      },
      SelectCarouselItem(newmodel){
        let newint = 0
        if(newmodel === 'Next'){
          newint = this.model-1+2
          if(newint > this.GalleryFinalIndex){
            newint = 0
          }
        }
        else{
          newint = this.model-1
          if(newint < 0){
            newint = this.GalleryFinalIndex
          }
        }
        this.model = newint
      },
      CarouselRoll(){
        //console.log('eyeyeye')
        let newint = this.model-1+2
        let lastint = this.RenderedGalleryAssets.length-1
        if(newint > lastint){
          this.model = 0
        }
        else{
          this.model++
        }
        //clearInterval(this.ModelVar)
      },
      AssetTitleStyle(tab){
        return 'background-color: '+tab.AssetTitleBGColor.hex+';padding-bottom: 20px;color:'+tab.AssetTitleColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
      },
      CaptionFontStyle(tab){
        let style = ''
        style = this.DefaultPageFont+'color:'+tab.AssetCaptionColor.hex+';'
        if(tab.CaptionFontSize){
          style = style+'font-size:'+tab.CaptionFontSize+'px;'
        }
        else if(!this.$vuetify.breakpoint.xs){
          style = style+'font-size:24px;'
        }
        return style
      },
      AssetFontStyle(tab){
            let style = 'background-color: '+tab.AssetCaptionBGColor.hex+';'
            if(this.MiniView){
              style = style+'width: 80%;'
            }
            else if(this.$vuetify.breakpoint.md) {
               style = style+'width: 65%;'
            } 
            else{
               style = style+'width: 50%;'
            } 
            return   style
            //return 'background-color: '+tab.GalleryBGColor.hex+';width: 100%;'
        },
      SetPreviewCard(card,cardindex){
        if(this.PreviewCardIndex !== cardindex && this.tab.Assets[this.PreviewCardIndex]){
            this.SetCardStyleObject(this.tab.Assets[this.PreviewCardIndex],this.PreviewCardIndex)
          }
        setTimeout(() => {
          
          this.PreviewCard = Object.assign({},card)
         
          this.PreviewCardIndex = cardindex
          this.SetCardStyleObject(this.PreviewCard,this.PreviewCardIndex,true)
        }, 500);
        
      },
      SetCardStyleObject(card,cardindex,onhover){
        this.Refreshing = true
        let assets = this.tab.Assets.filter((card, cardindex) => {
          return card
        })
        let total = assets.length
        
        let int = cardindex
        let division = int/total
        let perc = (division*100)
        let circcircumfance = 8
        let circradius = circcircumfance/2
        let circleymin = 8
        let circlemax = 80 
        let circlexmin = 85
        let circlexdiff = circlemax-circlexmin
        let circleydiff = circlemax-circleymin
        let circlexinc = circlexdiff/total
        let circlexint = circlexmin
        let circleyinc = circleydiff/total
        let circleyint = circleymin
        
        for(var x = 0; x < cardindex; x++){
          if(this.isOdd(x) === 0 && this.isOdd(cardindex) === 0){
            circlexint = circlexint+(circlexinc+circradius)
            circleyint = circleyint+(circleyinc-circradius)
          }
          else{
            circlexint = circlexint+(circlexinc)
            circleyint = circleyint+(circleyinc)
          }                        
        }
        let startdeg = 45
        let enddeg = 225        
        let deg = (startdeg-enddeg)/total
        let itemdeg = enddeg-(deg*cardindex)
        let min = 8
        let max = 12
        let ranint = Math.floor(Math.random() * (max - min + 1) + min)
        //console.log(itemdeg)
        //console.log(total,cardindex,int,division,card.URL)
        //transform: rotate(`+itemdeg+`deg);
        if(onhover){
          card.StyleObject = `display: inline-flex;min-width: 100%;width: 100%;position: absolute;z-index:1;clip-path: circle(50% at 50% 50%);object-fit: cover;transition: 0.5s;`
          if(this.MiniView){
            card.StyleObject = card.StyleObject+'height: 100%;'
          }
          else if(this.$vuetify.breakpoint.md){
            card.StyleObject = card.StyleObject+'height: 60vh;'
          }
          else{
            card.StyleObject = card.StyleObject+'height: 80vh;'
          }
        }
        else{
          card.StyleObject = `height: 100%;min-width: 100%;width: 100%;position: absolute;z-index:2;clip-path: circle(`+circcircumfance+`% at `+circlexint+`% `+circleyint+`%);object-fit: cover;transition: 0.5s;`
        }
        this.Refreshing = false
      },
      OddCardsRatio(cardindex){
        console.log(this.isOdd(cardindex),this.isOdd(cardindex) === 0)
        if(this.isOdd(cardindex) === 0){
          // alert('and a '+cardindex)
          return 0.5
        }
        else{
          return 2
        }
      },
      isOdd(num) {
            return num % 2;
        },
        CheckifCarouselClipped(tab){
        //console.log(tab)
        if(tab.Clipped && tab.ClippingIMG){
          this.GetBlob(tab.ClippingIMG).then(result => {
            
            tab.ClippingStyle = `-webkit-mask-image: url(`+result+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`
                                        // was -webkit-mask-size: auto;
                                        // console.log('clippingstyle',clippingstyle)
                                        //console.log(tab)
                                        this.TabReady = true
          })
          }
          else{
            this.TabReady = true
          }
      },
      CheckifParallaxClipped(tab){
        if(tab.ParallaxClipped && tab.ParallaxClippingImage){
          this.GetBlob(tab.ParallaxClippingImage).then(result => {
            
            tab.ClippingStyle = `-webkit-mask-image: url(`+result+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: auto;
                                        -webkit-mask-position: center;`
                                        // console.log('clippingstyle',clippingstyle)
                                        //console.log(tab)
                                        this.TabReady = true
          })
          }
          else{
            this.TabReady = true
          }
      },
      //WAS IN RUSH TURNED BELOW OFF IT IS COMPELTLEY OFF IS IT NOT? KEPT ON FOR LATER REFERENCE IF ANYTHING WENT WRONG
      // CheckifParallaxClipped(tab){
      //   if(tab.ParallaxClipped && tab.ParallaxClippingImage){
      //     return `-webkit-mask-image: url(`+tab.ParallaxClippingImage.url+`);
      //                                   -webkit-mask-repeat: no-repeat;
      //                                   -webkit-mask-size: contain;
      //                                   -webkit-mask-position: center;`
      //   }
      // },
        DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
     OpenDialog(tab,tabindex){
         this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                  console.log(tab.ClippingIMG)
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
          let style = ''
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 style = 'font-family: '+this.PageHeadersFont+', sans-serif;' 
                  if(this.$vuetify.breakpoint.xs){
                      style = style+'font-size: 2.5em;'
                     }  
                     else{
                      style = style+'font-size:50px;'
                     } 
               }
               else{
                   if(tabHeaderFontFamily){
                       style = 'font-family: '+tabHeaderFontFamily+', sans-serif;'
                       if(this.$vuetify.breakpoint.xs){
                      style = style+'font-size: 2.5em;'
                     }  
                     else{
                      style = style+'font-size:'+tab.HeaderFontSize+'px;'
                     }
                   }
                   else{
                     style = 'font-family: '+this.PageHeadersFont+', sans-serif;'
                     if(this.$vuetify.breakpoint.xs){
                      style = style+'font-size: 2.5em;'
                     }  
                     else{
                      style = style+'font-size:'+tab.HeaderFontSize+'px;'
                     }
                   }
                   
               }
               
           }
           return style
           //console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>