// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Activity from '@/views/Activity/Single'
import Activities from '@/views/Activities';
import BusinessUnits from '@/views/BusinessUnits'
import Users from '@/views/Users'
import BusinessUnit from '@/views/BusinessUnit/Single';
import StockMovement from '@/components/SuitePlugins/Warehousing/StockMovement';


import User from '@/views/User/Single';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import firebase from 'firebase';
import vuetify from './plugins/vuetify';
import ClassifiedSingleViewComp from '@/components/SuitePlugins/CustomDirectory/ClassifiedSingleViewComp.vue'
import DataSingleCall from '@/components/Database/DataSingleCall.vue'
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';
import TableMain from '@/components/Database/TableMain';

import LandingPageSingleVue from './components/WebPages/LandingPageSingle.vue'

Vue.config.productionTip = false

let app = '';
// const sql = require('mssql')
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyCDGigEc19GmusEGhsaW8Rb3zyK91p2Kaw",
  authDomain: "business-and-people.firebaseapp.com",
  projectId: "business-and-people",
  storageBucket: "business-and-people.appspot.com",
  messagingSenderId: "475286560557",
  appId: "1:475286560557:web:bc40b7548f06558ea2bda4",
  measurementId: "G-YL9VDB1DYE"
};
// const RAfirebaseConfig = {
//   apiKey: "AIzaSyCDGigEc19GmusEGhsaW8Rb3zyK91p2Kaw",
//   authDomain: "business-and-people.firebaseapp.com",
//   projectId: "business-and-people",
//   storageBucket: "business-and-people.appspot.com",
//   messagingSenderId: "475286560557",
//   appId: "1:475286560557:web:bc40b7548f06558ea2bda4",
//   measurementId: "G-YL9VDB1DYE"
// };

firebase.initializeApp(firebaseConfig);
// const RAApp = firebase.initializeApp(RAfirebaseConfig, 'RapidApps');
//firebase.analytics();
const db = firebase.firestore();
// const RADB = RAApp.firestore()
// db.settings({ timestampsInSnapshots: true });
const moduledb = {}
//Databases/{site}/Tables/Systems/Records/{systemid}/apps
const SystemConfigPath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID)
const PluginsPath = process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID ? SystemConfigPath.collection('plugins') : 
db.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('plugins')
const AppsPath = process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID ? SystemConfigPath.collection('apps') : 
db.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('apps')
const DBPath = db.collection('Databases').doc(process.env.VUE_APP_RA_SYSTEM_ID)
const WebsitePath = db.collection('Websites').doc(process.env.VUE_APP_RA_SYSTEM_ID)
store.commit('setPluginsPath',PluginsPath)
store.commit('setAppsPath',AppsPath)
//Changed from SystemConfigPath.onSnapshot(snapshot => { to SystemConfigPath.get().then(snapshot => { here but kept snapshot on App.vue
  //snapshot on app vuew looks at styling, but this one breaks everything when changes made to system.
PluginsPath.doc('Website_Builder').get().then(snapshot => {
  //console.log('window.location.hostname',window.location.hostname)
  let webbuilderdata = snapshot.data()
  moduledb.Website_Builder = webbuilderdata
  if(webbuilderdata && webbuilderdata.Active){
    store.commit('setWebsiteisActive',true)
  }
  PluginsPath.doc('Social_Network_Builder').get().then(snapshot => {
    let snbdata = snapshot.data()
    moduledb.Social_Network_Builder = snbdata
    if(snbdata && snbdata.Active){
      store.commit('setSocialNetworkisActive',true)
    }
    PluginsPath.doc('Database_Builder').get().then(snapshot => {
      let dbbdata = snapshot.data()
      moduledb.Database_Builder = dbbdata
      if(dbbdata && dbbdata.Active){
        store.commit('setDatabaseisActive',true)
      }
      PluginsPath.doc('Documentation_Builder').get().then(snapshot => {
        let docbdata = snapshot.data()
        moduledb.Documentation_Builder = docbdata
        if(docbdata && docbdata.Active){
          store.commit('setDocumentationisActive',true)
        }
      SystemConfigPath.get().then(snapshot => {
        //Public_Landing_Page
        const SystemDoc = snapshot.data()
        //console.log(SystemDoc)
        if(SystemDoc){
          WebsitePath.get().then(websnap => {
            let WebDoc = websnap.data()
          if(WebDoc.Public_Landing_Page && moduledb.Website_Builder && moduledb.Website_Builder.Active){
            let pageid = WebDoc.Public_Landing_Page.split('/').join('').split('-').join('_')
            WebsitePath.collection('Pages').doc(pageid).onSnapshot(snapshot =>{
              let pagedoc = snapshot.data()
              //console.log(pagedoc)
              if(pagedoc){
               let pageroute = {
                path: '/',
                name: 'Default Public',
                component: LandingPageSingleVue,
                meta: {
                  id: pageid,
                  icon: pagedoc.PageIcon,
                  type: 'Pages',
                  subcat: pagedoc.Name,
                  PageID: pageid,
                  viewicon: 'mdi-book-open-page-variant',
                  IsTopic: true,
                  TopicCategory: 'Public Pages',
                  TopicName: pagedoc.Name+' Page',
                  RouteEventID: 1028
                  }
              }
              if(pagedoc.CatNavList){
                pageroute.meta.CatNavList = pagedoc.CatNavList
              }
              let routes = router.getRoutes()
              let onroute = routes.find(obj => obj.name === pageroute.name)
              //console.log('onroute',onroute)
              if(!onroute){
                router.addRoute(pageroute) 
              }              
              }
              
            })
            
          }
        })
          if(process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID){
            let EntityCollectionSampleRoute = {
              path: '/TableViewer/:slug/:id',
              name: 'TableViewer',
              component: DataCollectionViewComponent,
              props: true,
              meta: {
                RouteEventID: 3001,
                type: 'Collection Sample',
                EntityCollectionSampler: true              
                },
        }
        router.addRoute(EntityCollectionSampleRoute)
        let EntitySingleSampleRoute = {
          path: '/EntryViewer/:slug/:id',
          name: 'EntryViewer',
          component: DataSingleCall,
          props: true,
          meta: {
            RouteEventID: 3002,
            type: 'Data Single Sample',
            SubColEntryViewer: true,
            // DataSingleBuilder: true,           
            },
        }
        router.addRoute(EntitySingleSampleRoute)
          }
          firebase.auth().onAuthStateChanged(function(user) {
                     //this will in time disappear
            if(user && moduledb.Database_Builder && moduledb.Database_Builder.Active){
              DBPath.collection('entities').onSnapshot(res => {
                let routestoadd = []
                let CustomRolesDefault =  
                [
                {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
                {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
                {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
                {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
                {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
                ]
                let BURolesDefault = [
                  {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
                  {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
                  {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
                  {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
                  {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
                  {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
                  {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
                  {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
                  {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
                  {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
                  {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
                  {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
                  {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
                  {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
                  {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
                  {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
                  {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
                  {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
                  {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
                  {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
                ]
                let entitieslength = res.docs.length
                let counter = 0
                const changes = res.docChanges();                 
                changes.forEach(change => {                 
                  if (change.type === 'added') {
                    let entitydoc = {                 
                      ...change.doc.data(),                 
                      id: change.doc.id                 
                    }
                    let displaynamesplit = entitydoc.DisplayName.split(' ').join('')
                    let singlenamesplit = entitydoc.SingleName.split(' ').join('')
                    let entitysinglerouteobj = {
                      path: '/'+singlenamesplit+'/:id',
                      name: singlenamesplit.toLowerCase(),
                      component: DataSingleCall,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 1030,
                        type: 'DB Records',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        Collection: displaynamesplit.toLowerCase(),
                        DataSingleBuilder: true,
                        }
                    }
                    if(displaynamesplit === 'BusinessUnits'){
                      entitysinglerouteobj.component = BusinessUnit
                    }
                    else if(displaynamesplit === 'Users'){
                      entitysinglerouteobj.component = User
                    }
                    else if(displaynamesplit === 'Activities'){
                      entitysinglerouteobj.component = Activity
                    }
                    
                    if(entitydoc.Primary_Field_Name){
                      entitysinglerouteobj.meta.identifier = entitydoc.Primary_Field_Name
                    }
                    else if(entitydoc.RecordPrimaryField){
                      entitysinglerouteobj.meta.identifier = entitydoc.RecordPrimaryField.Name
                    }
                    if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'Store Product'){
                     let stockmovmentroute = {
                      path: '/StockMovement/'+entitydoc.id.split('_').join('')+'/:slug/:id',
                      name: 'StockMovement',
                      component: StockMovement,
                      meta: {
                        RouteEventID: 1054,
                        id: entitydoc.id,
                        icon: 'mdi-cube-send',
                        type: entitydoc.id+' Stock Movement',
                        subcat: entitydoc.id+'StockMovement',
                        viewicon: 'mdi-cube-send',
                        IsTopic: true,
                        TopicCategory: 'Stock Movement',
                        TopicName: 'Stock Movement',
                        EntityID: entitydoc.id,
                        }
                    }
                    router.addRoute(stockmovmentroute) 
                    }
                    
                    let entitycollectionrouteobj = {
                      path: '/'+displaynamesplit,
                      name: displaynamesplit.toLowerCase(),
                      component: TableMain,
                      meta: {
                        id: entitydoc.id,
                        icon: entitydoc.Table_Icon,
                        RouteEventID: 1029,
                        type: 'Data Tables',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        singlecat: entitydoc.SingleName,
                        viewicon: 'mdi-file-table-box',
                        IsTopic: true,
                        TopicCategory: 'Database',
                        TopicName: entitydoc.SingleName,
                        Collection: displaynamesplit.toLowerCase(),
                        TableBuilder: true
                        
                        },
                    }
                    if(displaynamesplit === 'BusinessUnits'){
                      entitycollectionrouteobj.component = BusinessUnits
                    }
                    else if(displaynamesplit === 'Users'){
                      entitycollectionrouteobj.component = Users
                    }
                    else if(displaynamesplit === 'Activities'){
                      entitycollectionrouteobj.component = Activities
                    }
                    if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'Entity Builder'){
                      entitycollectionrouteobj.meta.EntityBuilder = true
                    }
                    else if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'System Builder'){
                      entitycollectionrouteobj.meta.SystemBuilder = true
                      entitysinglerouteobj.meta.SystemBuilder = true
                      entitycollectionrouteobj.meta.SystemConfigBuilder = true
                    }
                    if(entitydoc.SingleRecordNavMenuView){
                      entitycollectionrouteobj.meta.SingleRecordNavMenuView = true
                    }
                    if(entitydoc.HasRecordTemplates){
                      entitycollectionrouteobj.meta.HasRecordTemplates = true
                    }
                    
                    if(entitydoc.Primary_Field_Name){
                      entitycollectionrouteobj.meta.identifier = entitydoc.Primary_Field_Name
                    }
                    else if(entitydoc.RecordPrimaryField){
                      entitycollectionrouteobj.meta.identifier = entitydoc.RecordPrimaryField.Name
                    }
                    let SecurityType = ''
                    if(entitydoc.datasetsecuritystructure){
                      SecurityType = entitydoc.datasetsecuritystructure
                    }
                    else if(entitydoc.Security_Structure){
                      SecurityType = entitydoc.Security_Structure.Name
                    }
                    if(SecurityType === 'Warehouse'){
                      entitycollectionrouteobj.meta.Security = {
                        SecurityType: 'Warehouse',
                      }
                      router.addRoute(entitycollectionrouteobj)
                    }
                    else if(SecurityType === 'Custom Roles'){
                      
                      entitycollectionrouteobj.meta.Security = {
                        SecurityType: 'Custom Roles',
                      }
                      CustomRolesDefault.map((defrole,i) => {
                        if(entitydoc.id === 'Inventory_Articles'){
                          //console.log(i)
                        }
                        let array = entitydoc[defrole.Name]
                        let prop = entitydoc[defrole.Prop]
                        let boolean = entitydoc[defrole.Boolean]
                        
                        if(array){
                          entitycollectionrouteobj.meta.Security[defrole.Name] = array
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Name] = []
                        }
                        if(prop){
                          entitycollectionrouteobj.meta.Security[defrole.Prop] = prop
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Prop] = 'Custom Role'
                        }
                        if(boolean){
                          entitycollectionrouteobj.meta.Security[defrole.Boolean] = boolean
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Boolean] = false
                        }
                        if(i === 4){
                          router.addRoute(entitycollectionrouteobj)
                        }
                        
                      })
          
                    }
                    else if(SecurityType === 'Business Unit'){
                      entitycollectionrouteobj.meta.Security = {
                        SecurityType: 'Business Unit',
                        AllgetRoles: [],
                        UnitDowngetRoles: [],
                        UserUnitgetRoles: [],
                        OwnergetRoles: [],
                        AlllistRoles: [],
                        UnitDownlistRoles: [],
                        UserUnitlistRoles: [],
                        OwnerlistRoles: [],
                        AllcreateRoles: [],
                        UnitDowncreateRoles: [],
                        UserUnitcreateRoles: [],
                        OwnercreateRoles: [],
                        AllupdateRoles: [],
                        UnitDownupdateRoles: [],
                        UserUnitupdateRoles: [],
                        OwnerupdateRoles: [],
                        AlldeleteRoles: [],
                        UnitDowndeleteRoles: [],
                        UserUnitdeleteRoles: [],
                        OwnerdeleteRoles: [],
                      }
                      BURolesDefault.map((defrole,i) => {
                        let array = entitydoc[defrole.Name]
                        if(array){
                          entitycollectionrouteobj.meta.Security[defrole.Name] = array
                        }
                        else{
                          entitycollectionrouteobj.meta.Security[defrole.Name] = []
                        }
                        if(i === 15){
                          router.addRoute(entitycollectionrouteobj)
                        }
                      })
                      
                    }
                   
                   
                    router.addRoute(entitysinglerouteobj)
                    //router.addRoute(entitycollectionrouteobj)
                    //entity type specials...
                    if(entitydoc.Has_Wiki){
                    let wikiingleeditrouteobj = {
                      path: '/WikiPreview/'+singlenamesplit+'/:id',
                      name: singlenamesplit.toLowerCase()+'wikibuilder',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 6000,
                        type: 'Wiki Editor',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'wiki',
                        WikiListingBuilder: true,
                        }
                    }
                    router.addRoute(wikiingleeditrouteobj)
          
                    let wikisingleviewerrouteobj = {
                      path: '/'+singlenamesplit+'-Wiki/:id',
                      name: singlenamesplit.toLowerCase()+'wikisingle',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 6001,
                        type: 'Wiki Viewer',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'wiki',
                        }
                    }
                    //console.log(wikisingleviewerrouteobj)
                    router.addRoute(wikisingleviewerrouteobj)
                  }
                    if(entitydoc.Entity_Type && entitydoc.Entity_Type.Name === 'Classified Listing'){
                      let clsfdlistingsingleeditrouteobj = {
                      path: '/ClassifiedPreview/'+singlenamesplit+'/:id',
                      name: singlenamesplit.toLowerCase()+'classifiedbuilder',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 5000,
                        type: 'Classified Listing Editor',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'listings',
                        ClassifiedListingBuilder: true,
                        SingleName: entitydoc.SingleName
                        }
                    }
                    router.addRoute(clsfdlistingsingleeditrouteobj)
                    let clsfdlistingsingleviewerrouteobj = {
                      path: '/'+singlenamesplit+'-Classified/:id',
                      name: singlenamesplit.toLowerCase()+'classifiedsingle',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: entitydoc.Table_Icon,              
                        RouteEventID: 5001,
                        type: 'Classified Listing Viewer',
                        subcat: entitydoc.DisplayName,
                        EntityID: entitydoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: displaynamesplit.toLowerCase(),
                        Collection: displaynamesplit.toLowerCase()+'listings',
                        }
                    }
                    router.addRoute(clsfdlistingsingleviewerrouteobj)
                   }
                    // console.log('router.getRoutes()',router.getRoutes())
                    // console.log('router',router.options.routes)
                    let storeentityobj = {
                      ArrayName: displaynamesplit+'Array',
                      QueryName: displaynamesplit+'Query'
                    }
                    // store.state[storeentityobj.ArrayName] = []
                    // store.state[storeentityobj.QueryName] = ''
                    let newobj = {Name: storeentityobj.ArrayName,Data: []}
                    store.state.TableData.push(newobj)
                    store.dispatch('AddEntityObj', storeentityobj)
                    counter++
                    if(counter === entitieslength){
                      // firebase.auth().onAuthStateChanged(function(user) {
                        
                        if (!app) {
                          /* eslint-disable no-new */
                          app = new Vue({
                            router,
                            store,
                            vuetify,
                            render: h => h(App)
                          }).$mount('#app');
                        }
                      // });
                    }
                  }
                })
              })
            }
            else{
              //get wikientities and classifiedentities
              db.collection('Libraries').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('wikientities').where('PublishType','==','Public').onSnapshot(wikires => {
                let wikientitieslength = wikires.docs.length
                let wikicounter = 0
                const wikichanges = wikires.docChanges(); 
                if(wikientitieslength === 0){
                  db.collection('Libraries').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('classifiedentities').where('PublishType','==','Public').onSnapshot(clsfdres => {
                    let classifiedentitieslength = clsfdres.docs.length
                    let clsfdcounter = 0
                    if(classifiedentitieslength === 0){
                      if (!app) {
                        /* eslint-disable no-new */
                        app = new Vue({
                          router,
                          store,
                          vuetify,
                          render: h => h(App)
                        }).$mount('#app');
                      }
                    }
                    const changes = clsfdres.docChanges();                  
                    changes.forEach(change => {                 
                      if (change.type === 'added') {
                        let clsfddoc = {                  
                          ...change.doc.data(),                 
                          id: change.doc.id                 
                        }
                        let clsfddisplaynamesplit = clsfddoc.id.split('_').join('')
                        let clsfdsinglenamesplit = clsfddoc.SingleName.split(' ').join('')
                        let clsfdlistingsingleviewerrouteobj = {
                          path: '/'+clsfdsinglenamesplit+'-Classified/:id',
                          name: clsfdsinglenamesplit.toLowerCase()+'classifiedsingle',
                          component: ClassifiedSingleViewComp,
                          props: true,
                          meta: {
                            icon: clsfddoc.Table_Icon,              
                            RouteEventID: 5001,
                            type: 'Classified Listing Viewer',
                            subcat: clsfddoc.DisplayName,
                            EntityID: clsfddoc.id,
                            viewicon: 'mdi-file-table-box',
                            DataCollection: clsfddisplaynamesplit.toLowerCase(),
                            Collection: clsfddisplaynamesplit.toLowerCase()+'listings',
                            }
                        }
                        router.addRoute(clsfdlistingsingleviewerrouteobj)
                        clsfdcounter++
                      }
                    if(clsfdcounter === classifiedentitieslength){
                      if (!app) {
                        /* eslint-disable no-new */
                        app = new Vue({
                          router,
                          store,
                          vuetify,
                          render: h => h(App)
                        }).$mount('#app');
                      }
                    }
                  })
                })
                }               
                wikichanges.forEach(wikichange => {                 
                  if (wikichange.type === 'added') {
                    let wikidoc = {                 
                      ...wikichange.doc.data(),                 
                      id: wikichange.doc.id                 
                    }
                    let wikidisplaynamesplit = wikidoc.id.split('_').join('')
                    let wikisinglenamesplit = wikidoc.SingleName.split(' ').join('')
                    let wikisingleviewerrouteobj = {
                      path: '/'+wikisinglenamesplit+'-Wiki/:id',
                      name: wikisinglenamesplit.toLowerCase()+'wikisingle',
                      component: ClassifiedSingleViewComp,
                      props: true,
                      meta: {
                        icon: wikidoc.Table_Icon,              
                        RouteEventID: 6001,
                        type: 'Wiki Viewer',
                        subcat: wikidoc.DisplayName,
                        EntityID: wikidoc.id,
                        viewicon: 'mdi-file-table-box',
                        DataCollection: wikidisplaynamesplit.toLowerCase(),
                        Collection: wikidisplaynamesplit.toLowerCase()+'wiki',
                        }
                    }
                    //console.log(wikisingleviewerrouteobj)
                    router.addRoute(wikisingleviewerrouteobj)
                    wikicounter = wikicounter-1+2
                  }
                  if(wikicounter === wikientitieslength){
                    db.collection('Libraries').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('classifiedentities').where('PublishType','==','Public').onSnapshot(clsfdres => {
                        let classifiedentitieslength = clsfdres.docs.length
                        if(classifiedentitieslength === 0){
                          if (!app) {
                            /* eslint-disable no-new */
                            app = new Vue({
                              router,
                              store,
                              vuetify,
                              render: h => h(App)
                            }).$mount('#app');
                          }
                        }
                        let clsfdcounter = 0
                        const changes = clsfdres.docChanges();                  
                        changes.forEach(change => {                 
                          if (change.type === 'added') {
                            let clsfddoc = {                  
                              ...change.doc.data(),                 
                              id: change.doc.id                 
                            }
                            let clsfddisplaynamesplit = clsfddoc.id.split('_').join('')
                            let clsfdsinglenamesplit = clsfddoc.SingleName.split(' ').join('')
                            let clsfdlistingsingleviewerrouteobj = {
                              path: '/'+clsfdsinglenamesplit+'-Classified/:id',
                              name: clsfdsinglenamesplit.toLowerCase()+'classifiedsingle',
                              component: ClassifiedSingleViewComp,
                              props: true,
                              meta: {
                                icon: clsfddoc.Table_Icon,              
                                RouteEventID: 5001,
                                type: 'Classified Listing Viewer',
                                subcat: clsfddoc.DisplayName,
                                EntityID: clsfddoc.id,
                                viewicon: 'mdi-file-table-box',
                                DataCollection: clsfddisplaynamesplit.toLowerCase(),
                                Collection: clsfddisplaynamesplit.toLowerCase()+'listings',
                                }
                            }
                            router.addRoute(clsfdlistingsingleviewerrouteobj)
                            clsfdcounter++
                          }
                        if(clsfdcounter === classifiedentitieslength){
                          if (!app) {
                            /* eslint-disable no-new */
                            app = new Vue({
                              router,
                              store,
                              vuetify,
                              render: h => h(App)
                            }).$mount('#app');
                          }
                        }
                      })
                    })
                  }
                })            
            })
            }
          })
          
        }
      })
      })
    })
  })
})
// firebase.auth().onAuthStateChanged(() => {
//   if (!app) {
//     /* eslint-disable no-new */
//     app = new Vue({
//       router,
//       store,
//       vuetify,
//       render: h => h(App)
//     }).$mount('#app');
//   }
// });

export default db;
