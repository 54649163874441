<template>

      <v-layout class="justify-center"  :style="$route.name !== 'MyAccount'? '' : 'margin-top:-30px;'"> 
        
        <v-dialog persistent v-if="ActiveEvent" v-model="BookingDialog" width="400">
        <v-card  width="400" class="transition-fast-in-fast-out" style="height: 100%;bottom: 0;position: absolute;right: 0;">
          <v-card-title class="recordtoolbar white--text">
            {{UserBooked(ActiveEvent) ? 'Cancel Booking?' : 'Confirm Booking'}} - {{ActiveEvent.Title}}
          </v-card-title>
          <v-card-text style="padding:20px;">
            <FieldsForm v-if="!UserBooked(ActiveEvent)" :FormFields="BookingHeaders" :DataTableView="true"  :DataObject="NewBookingObj" :tab="{}" @UploadFileSelect="UploadFileSelect" />
          </v-card-text>
          <v-card-actions>
            <v-btn :color="UserBooked(ActiveEvent)? 'success' : 'warning'" dark @click="BookingDialogDeactivate()">{{UserBooked(ActiveEvent) ? 'Keep me Booked' : 'Cancel'}}</v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn :color="UserBooked(ActiveEvent)? 'warning' : 'success'" @click="BookUser(ActiveEvent,userLoggedIn,true)">
              {{UserBooked(ActiveEvent) ? 'Yes Cancel' : 'Save'}}
            </v-btn>
          </v-card-actions>
        </v-card>
     </v-dialog>
      <v-card flat tile class="my-3 transparent" :style="$route.name === 'MyAccount'? 'padding-bottom:200px;' : 'padding-bottom:100px;'" :width="$route.name !== 'MyAccount'? '100%' : '100%'">
          <v-sheet height="120" class="primary v-pagination" dark v-if="!CurrentEntity.Hide_by_Day_Filter">
            <v-btn style="margin-left:15px;" small fab color="links" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <ul class="transparent flat" style="align-items: center;
              display: inline-flex;
              list-style-type: none;
              justify-content: center;
              margin: 0;
              max-width: 100%;
              width: 100%;">
              <li>
            
              
              <v-row dense>
                <v-btn-toggle style="padding-right:20px;" active-class="links--text" group v-model="WeekDay" ><span  v-for="(weekday,i) in WeekDays" :key="weekday.itemObjKey">
                 <span style="position:absolute;padding-left:8px;margin-top:-25px;">{{weekday.Short}}</span>
                <v-btn outlined x-small style="height:25px;font-size:0.725rem;" @click="updatefocus(i)">
                  {{weekday.Day}}
                </v-btn></span>
                </v-btn-toggle>
              </v-row>
              </li>
          </ul>
              <v-btn style="margin-right:15px;" fab small color="links" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
      </v-sheet>      
      <v-sheet class="transparent" :height="MiniView? 300 : 450">
               <v-btn width="100%" v-if="CanCreate" dark color="links"  @click="Editing = !Editing">{{Editing ? 'Cancel' : 'Edit Calendar'}}
              </v-btn>
        <!-- <v-btn color="green" dark fab icon @click="ActivateNewEvent()"><v-icon>mdi-plus-thick</v-icon></v-btn> -->
        <!-- style="display:none;" -->
          <v-calendar
            :style="Editing ? 'visibility: visible;' : 'display: none'"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="AllEventsViewFormatted"
            :event-color="getEventColor"
            :now="today"
            :type="type"
            :event-ripple="false"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseup:event="CheckDrag"
            @mouseleave.native="cancelDrag"
            @change="updateRange"
             @click:event="OpenEventEditor"
          >
          <template v-slot:event="{ event, timed, eventSummary }">
            <v-btn x-small @click="OpenEventEditor(event)">Edit</v-btn>
            <v-btn x-small @click="DeleteEvent(event)">Delete</v-btn>
            <div
              class="v-event-draggable"
              v-html="eventSummary()"
            >
            </div>
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
          </v-calendar>
            <v-chip-group v-if="!Editing" active-class="primary--text">
            <v-chip @click="ScheduleTypefilter && ScheduleTypefilter.ID === type.ID? DeactivateTypefilter() : ActivateTypeFilter(type)" :color="type.Color" v-for="type in TypeOptions" :key="type.itemObjKey">
              {{type.Name}}
            </v-chip>
          </v-chip-group>
          <v-card-title v-if="Business_UnitArray && !Editing">
            <v-autocomplete return-object item-text="Name" v-model="RegionFilter" :items="Business_UnitArray"/>
          </v-card-title>
             <v-card-title class="mediumoverline" v-if="!CurrentEntity.Hide_by_Day_Filter && !Editing">
            {{ datetitle }}
          </v-card-title>
            <v-list class="transparent my-3" outlined three-line v-if="!Editing">
              <!-- <v-list class="my-3" outlined three-line v-for="item in AllEventsViewFormatted" :key="item.itemObjKey"> -->
             
              <v-virtual-scroll 
                width="100%"
                  class="transparent"
                    :items="createEvent ? [createEvent] : AllEventsViewFormatted"
                    :item-height="Editing ? 300 : 250"
                    :height="Editing ? 350 : 550"
                  >
                  <template v-slot:default="{ item }">
                    <RSVPTableItem :item="item" :CurrentEntity="CurrentEntity" @OpenEventEditor="OpenEventEditor"
                    @BookUser="BookUser" @BookingDialogActivate="BookingDialogActivate"
                    />
                  </template>
              </v-virtual-scroll>
              
          </v-list>
          <v-dialog
          max-width="800"
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
               <v-toolbar height="100px" flat class="transparent">
                <v-text-field v-model="selectedEvent.From"  label="From" readonly />
                <v-icon @click="ViewItem(selectedEvent)" v-if="selectedEvent.CanView">mdi-database</v-icon>
              </v-toolbar>

              <v-card-text>
                <span v-html="selectedEvent.Description"></span>
              </v-card-text>
              <v-list-item v-if="selectedEvent.Location_Street_Number && selectedEvent.Location_Street_Name && selectedEvent.Location_Suburb && selectedEvent.Location_City && selectedEvent.Location_Postal_Code && selectedEvent.Location_State_Province && selectedEvent.Location_Country_Region">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                        
                          <v-btn icon @click="ViewMapItem(selectedEvent.Location_Street_Number,selectedEvent.Location_Street_Name,selectedEvent.Location_Suburb,selectedEvent.Location_City,selectedEvent.Location_Postal_Code,selectedEvent.Location_State_Province,selectedEvent.Location_Country_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
                <!-- actually only if HasCalendarEntryResponse -->
                <v-spacer>
                </v-spacer>
                <v-dialog max-width="800" v-model="ConfirmationMessageDynamic">
                  <template v-slot:activator="{ on }">
                     <v-btn v-on="on"  v-if="!selectedEvent.ConfirmationStatus || selectedEvent.ConfirmationStatus && selectedEvent.ConfirmationStatus.ID === 1000001">
                      Confirm
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Confirmation Message
                    </v-card-title>
                    <v-layout class="justify-center">
                    <v-card width="90%" contenteditable class="postinputbox" id="ConfirmationMessage">
                    </v-card>
                    </v-layout>
                    <v-card-actions>
                      <v-btn>Cancel</v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="ConfirmCalendarEntry()"  v-if="!selectedEvent.ConfirmationStatus || selectedEvent.ConfirmationStatus && selectedEvent.ConfirmationStatus.ID === 1000001">
                      Confirm
                    </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
               
                <v-btn @click="CloseCalendarEntry()" v-if="selectedEvent.ConfirmationStatus && selectedEvent.ConfirmationStatus.ID === 1000002">
                  Close
                </v-btn>
                <!-- actually only if HasCalendarEntryResponse -->
              </v-card-actions>

            </v-card>
          </v-dialog>
        </v-sheet>
      </v-card>
    </v-layout>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/FieldsForm';	
import RSVPTableItem from '@/components/SuitePlugins/CalendarTables/RSVPTableItem';	
export default {
    props: ['CurrentEntity','Data','Editing','CanCreate','CalendarEntryCompleted'],
    components: {
      FieldsForm,
      RSVPTableItem
    },
    data(){
        return{
          TableData: [],
          EditedNewEventIndex: -1,
          newevents: [],
          NewBookingObj: {UploadFiles: []},
          BookingHeaders: [],
          BookingDialog: false,
          ActiveEvent: '',
          GotBookings: false,
          TypeOptions: [],
          RegionFilter: '',
          dragEvent: null,
          createEvent: null,
          createStart: null,
          extendOriginal: null,
      colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
  ScheduleTypefilter: '',
            focus: '',
            WeekDay: 0,
            type: 'week',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            ConfirmationMessageDynamic: false,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
        }
    },
    computed:{
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      BookingshaveDialog(){
        if(this.CurrentEntity){
          return this.CurrentEntity.Bookings_have_Dialog
        }
      },
      BookedUsersSubCollection(){
        if(this.CurrentEntity && this.CurrentEntity.SubCollections){
            let subcol = this.CurrentEntity.SubCollections.find(obj => obj.id === 'BookedUsers')
            return subcol
          }
      },
        BookingDialogHeaders(){
          if(this.BookedUsersSubCollection){
            return this.BookedUsersSubCollection.Headers
            .map(header => {
              if(header.value === 'Full_Name'){
                header.Response = this.userLoggedIn.Full_Name
              }
              //always has user field. so always map that. Should inf act always be mapped and crap but we could do static run on it
              return header
            })
          }
        },
        WeekDays(){
          //console.log(this.start,this.end)
    let array= []
    if(this.start && this.end){
    let start = new Date(this.start.date)
    let sunday = start
    let monday = new Date(sunday)
    monday.setDate(sunday.getDate()-1+2)
    let tuesday = new Date(monday)
    tuesday.setDate(monday.getDate()-1+2) 
    let wednesday = new Date(tuesday)
    wednesday.setDate(tuesday.getDate()-1+2)
    let thursday = new Date(wednesday)
    thursday.setDate(wednesday.getDate()-1+2)
    let friday = new Date(thursday)
    friday.setDate(thursday.getDate()-1+2)  
    let end = new Date(this.end.date)
    end.setHours(23,59,59,999)
       array= [
        {
          Short: 'Su',
          Day: sunday.getDate(),
          DateObject: sunday,
          DateString: format(sunday,'yyyy-MM-dd')
        },
        {
          Short: 'Mo',
          Day: monday.getDate(),
          DateObject: monday,
          DateString: format(monday,'yyyy-MM-dd')
        },
        {
          Short: 'Tu',
          Day: tuesday.getDate(),
          DateObject: tuesday,
          DateString: format(tuesday,'yyyy-MM-dd')
        },
        {
          Short: 'We',
          Day: wednesday.getDate(),
          DateObject: wednesday,
          DateString: format(wednesday,'yyyy-MM-dd')
        },
        {
          Short: 'Th',
          Day: thursday.getDate(),
          DateObject: thursday,
          DateString: format(thursday,'yyyy-MM-dd')
        },
        {
          Short: 'Fr',
          Day: friday.getDate(),
          DateObject: friday,
          DateString: format(friday,'yyyy-MM-dd')
        },
        {
          Short: 'Sa',
          Day: end.getDate(),
          DateObject: end,
          DateString: format(end,'yyyy-MM-dd')
        }
      ]
    }
    
    
    return array
  },
        userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  UsersStore(){
    return this.UsersArray
  },
  BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
  UsersStore(){
      return this.$store.state.UsersArray
    },
  UsersStore(){
      return this.$store.state.UsersArray
    },
        UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
        NewEventsPrepped(){
          return this.newevents.map(session => {
            //console.log(session)
            let end = session.end
            let start = session.start
            let enddate = new Date(end)
            let startdate = new Date(start)
            let newenddate = new Date(end)
            let newstartdate = new Date(start)
            session.name = session.Title
            session.BookedUsers = []
            session.BookedUserIDs = []
            session.EndObject = new Date(enddate)
            session.End = format(session.EndObject,'yyyy-MM-dd')
            session.EndTime = newenddate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
            session.StartObject = new Date(startdate)
            session.StartTime = newstartdate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
            session.Start = format(session.StartObject,'yyyy-MM-dd')
            return session
          }).filter(session => {
            //return !this.createEvent || this.createEvent && session.name !== this.createEvent.name
            return session
          })
        },
        DataPrepped(){
            return this.TableData.concat(this.NewEventsPrepped)
            .map(event => {
          if(!event.BookedUsers){
            event.BookedUsers = []
            event.BookedUserIDs = []       
          }
          return event
        }).map(session => {
      if(session.Created_On && session.Created_On.toDate){
      session.Created_OnObject = session.Created_On.toDate()
      session.Created_On = format(session.Created_On.toDate(),'yyyy-MM-dd')
      }
      return session
    }).map(session => {
      if(typeof session.end === 'number'){
        
        let enddate = new Date(session.end)
        session.EndTime = enddate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
        session.EndObject = enddate
        session.End = format(enddate,'yyyy-MM-dd')
        //console.log(session.end)
      }
      return session
    }).map(session => {
      if(typeof session.start === 'number'){
        let startdate = new Date(session.start)
        session.StartTime = startdate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
        session.StartObject = startdate
        session.Start = format(startdate,'yyyy-MM-dd')
      
      }
      return session
    }).map(session => {
        return session
        }).map(session => {
        if(this.PageView && this.PageFilter){
          if(this.PageFilter.FieldType === 'Option Set'){
            if(session[this.PageFilter.FieldName]){
              this.PageFilter.Filter.map(filter => {
                if(filter.ID === session[this.PageFilter.FieldName].ID){
                  session.Render = true
                }
              })
            }
          }
        }
        else{
          session.Render = true
        }
        return session
      })
      .filter(session => {
        return session.Render === true
      })
      .map(event => {
          var diff = Math.abs(event.EndObject - event.StartObject);
          event.Duration = Math.floor((diff/1000)/60)+'M';
          let greencolor = Math.round(100-Math.round((event.BookedUsers.length/event.User_Limit)*100))*2.55
          let redcolor = Math.round(Math.round((event.BookedUsers.length/event.User_Limit)*100)*2.55)                 
          event.LimitColor = 'rgb('+redcolor+','+greencolor+',0)'
          return event
        })
        .map(event => {      
          if(event.id){
            if(this.UserisGuest && this.CurrentEntity.Guests_Only || !this.CurrentEntity.Guests_Only && this.userLoggedIn){  
            if(this.NotTooLate(event) && this.NotTooEarly(event) && !this.UserBooked(event) && event.BookedUsers.length < event.User_Limit){
              event.BookingEnabled = true
            }
            else if(this.UserBooked(event)){
              event.BookingEnabled = true
            }
            else{
              event.BookingEnabled = false
            }   
          }
          else{
            event.BookingEnabled = false
          }
        if(event.Type){
          let typeobj = this.TypeOptions.find(obj => obj.ID === event.Type.ID)
          if(typeobj){
            event.TypeColor = typeobj.Color
          }
        }
          }
          
        return event
      })
      .filter(event => {
      if(this.ScheduleTypefilter){
        return event.Type && event.Type.ID === this.ScheduleTypefilter.ID
      }
      else{
        return event
      }
    })
    .filter(event => {
      if(this.RegionFilter){
        return event.Business_Unitid === this.RegionFilter.id
      }
      else{
        return event
      }
    })
        },
        AllEventsViewFormatted(){
      if(this.DataPrepped && this.GotBookings){
      return this.DataPrepped.map(event => {
          let eventobj = Object.assign({},event) 
          eventobj.name = eventobj.Title 
         
        //console.log(eventobj)  
        return eventobj
        
      })
      .filter(event => {
        //console.log(event,this.focus)
    if(this.focus){
      let start = new Date(this.focus)
      let end = new Date(this.focus)
      end.setHours(23,59,59,999)
      //console.log(event.StartObject,start,event.EndObject,end)
      //return event.StartObject >= this.WeekDays[0].DateObject && event.EndObject <= this.WeekDays[6].DateObject
      // works when not interacting with table to create drag events return this.CurrentEntity.Hide_by_Day_Filter? event : event.StartObject >= start && event.EndObject <= end
      return this.CurrentEntity.Hide_by_Day_Filter? event : event.StartObject >= this.WeekDays[0].DateObject && event.EndObject <= this.WeekDays[6].DateObject
    }
    else{
      return event
    }
    
  }).sort((a, b) => {
          //console.log('sorting again')
          //console.log(a.start)
          var key1 = a.StartObject;
          var key2 = b.StartObject;

          if (key1 < key2) {
              return -1;
          } else if (key1 == key2) {
              return 0;
          } else {
              return 1;
          }
    })
      }
    },
    datetitle(){
    if(this.focus){
      return new Date(this.focus)
    }
    else{
      return new Date()
    }
  },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
    },
    watch: {
      CalendarEntryCompleted(v){
        if(v && this.EditedNewEventIndex !== -1){
          this.newevents.splice(this.EditedNewEventIndex,1)
          this.EditedNewEventIndex = -1
        }
      },
      Data: {
        handler: function(newvalue, oldvalue) { 
            if(newvalue !== oldvalue){
              this.PrepareData()
            }    
            },
            deep: true
      },
    },
    created(){
      this.PrepareData()
      var d = new Date();
      var n = d.toISOString();
      this.today = n.split('T')[0]
      this.setToday()
      this.TypeOptions = this.CurrentEntity.AllFields.find(obj => obj.id === 'Type').Options
      this.GotBookings = false
      this.GetBookings()
    },
    methods:{
      PrepareData(){
        this.TableData = this.Data.map(item => {
          let itemobj = Object.assign({},item)
          //console.log(itemobj,item)
          return itemobj
        })
      },
      startDrag ({ event, timed }) {
        if (event && !event.id) {    
          this.dragEvent = this.newevents.find(obj => obj.name === event.name)
          this.dragTime = null
          this.extendOriginal = null          
        }
        else{
          this.dragEvent = this.TableData.find(obj => obj.id === event.id)
          this.dragTime = null
          this.extendOriginal = null     
        }
      },
      startTime (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start

          this.dragTime = mouse - start
        } else {
          this.createStart = this.roundTime(mouse)
          this.createEvent = {
            Title: `Event #${this.AllEventsViewFormatted.length}`,
            name: `Event #${this.AllEventsViewFormatted.length}`,
            color: this.rndElement(this.colors),
            start: this.createStart,
            end: this.createStart,
            timed: true,
          }
      //console.log(this.newevents)
          this.newevents.push(this.createEvent)
        } 
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
      extendBottom (event) {
        //console.log(event)
        if(!event.id){
          this.createEvent = this.newevents.find(obj => obj.name === event.name)
          this.createStart = event.start
          this.extendOriginal = event.end
        }
        else{
          this.createEvent = this.TableData.find(obj => obj.id === event.id)
          this.createStart = event.start
          this.extendOriginal = event.end
        }
        
        
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
        }
      },
      CheckDrag({ event, timed }){
        //console.log(event)
        if(event.id){
          this.OpenEventEditor({event})
        }
      },
      endDrag () {
        this.cancelDrag(true)
        this.dragTime = null
        this.dragEvent = null
        //this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag (skip) {
        //console.log('mouse left',this.createEvent,this.extendOriginal)
        if (this.createEvent) {
          if (this.extendOriginal) {
            //this.createEvent.end = this.extendOriginal
          } else if(!skip) {
            const i = this.newevents.indexOf(this.createEvent)
            if (i !== -1) {
              this.newevents.splice(i, 1)
            }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },

      UploadFileSelect(file,field,Record){
      console.log(file,field,Record,this.BookingHeaders)
      field.UploadFile = file
      field.FileName = file.name
      let uploadfileobj = {
        id: field.value,
        UploadFile: file,
        FieldObj: field
      }
      let oncheck = Record.UploadFiles.find( obj => obj.id === uploadfileobj.id)
      if(!oncheck){
        Record.UploadFiles.push(uploadfileobj)
      }
      else{
        let index = Record.UploadFiles.indexOf(oncheck)
        Record.UploadFiles[index] = Object.assign({},uploadfileobj)
      }
      
    },
      BookingDialogActivate(event){
        this.ActiveEvent = event
        this.BookingHeaders = JSON.parse(JSON.stringify(this.BookingDialogHeaders))
        this.BookingDialog = true
      },
      BookingDialogDeactivate(){
        this.BookingDialog = false
        this.NewBookingObj = {UploadFiles: []}
        this.ActiveEvent = ''
        this.BookingHeaders = []
        this.BookingDialogHeaders.map(header => {
          if(header.value !== 'Full_Name'){
          header.Response = ''
          }
        })
      },
      GetBookings(){
        if(this.TableData.length === 0){
            this.GotBookings = true
          }
        this.TableData.map(event => {
          if(!event.BookedUsers){
            event.BookedUsers = []
            event.BookedUserIDs = []       
          }
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(event.id).collection('BookedUsers').onSnapshot(res => {
            let listlength = res.docs.length
            if(listlength === 0){
              this.GotBookings = true
            }
            const changes = res.docChanges();
            changes.forEach(change => {
              let changeobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              
            if (change.type === 'added') {
              let oncheck = event.BookedUsers.find(obj => obj.id === changeobj.id)
              if(!oncheck){
               //console.log('change added ',event.id,changeobj)
                event.BookedUsers.push(changeobj)
                event.BookedUserIDs.push(changeobj.id)
                this.ToggleGotBookings() 
              }              
            }
            else if (change.type === 'modified') {
              let oncheck = event.BookedUsers.find(obj => obj.id === changeobj.id)
              if(oncheck){
              let index = event.BookedUsers.indexOf(oncheck)
              event.BookedUsers[index] = Object.assign({},changeobj)
              //console.log('change modified ',event.id,changeobj)
              this.ToggleGotBookings()  
              }              
            }
            else if (change.type === 'removed') {
              let oncheck = event.BookedUsers.find(obj => obj.id === changeobj.id)
              if(oncheck){
                //console.log('change removed',event.id,changeobj)
                let index = event.BookedUsers.indexOf(oncheck)
                event.BookedUsers.splice(index,1)
                event.BookedUserIDs.splice(index,1)
                this.ToggleGotBookings()
              }
              
            }
            if(event.BookedUsers.length === listlength){
              this.GotBookings = true
            }
          })
            })
        })
      },
      ToggleGotBookings(){
        if(this.GotBookings){
          this.GotBookings = false
          setTimeout(() => {
              this.GotBookings = true
          }, 50);
        }
      },
        updatefocus(index){
      this.focus = this.WeekDays[index].DateString
      //console.log(this.WeekDays,this.focus)
    },
        ActivateTypeFilter(type){
        this.ScheduleTypefilter = type
      },
      DeactivateTypefilter(){
        this.ScheduleTypefilter = ''
      },
      BookUser(event,user,withheaders){
        let vm = this
        let today = new Date()
      let newbooking = 
      {
        Eventid: event.id,
        BookingDateNumber: today.getTime(),
        Booking_Byid: user.id,
        Userid: user.id,
        Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Created_Byid: this.userLoggedIn.id,
        Created_OnDateNumber: today.getTime(),
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid:  this.userLoggedIn.id,
        Modified_OnDateNumber: today.getTime(),
        Business_Unit: this.userLoggedIn.Business_Unit,
        Business_Unitid: this.userLoggedIn.Business_Unitid,
        Full_Name: this.userLoggedIn.Full_Name
      }
      if(withheaders){
        this.BookingHeaders.map(header => {
          if(header.Response){
            newbooking[header.value] = header.Response
          }
        })
      }
      this.$emit('ActivateProcessing',true)
      if(this.NewBookingObj.UploadFiles.length > 0){
        this.NewBookingObj.UploadFiles.map((file,index) => {
          this.UploadFilesandBook(file,file.FieldObj.value,event.id).then(fileobj => {
            newbooking[file.FieldObj.value] = fileobj
            if(index-1+2 === vm.NewBookingObj.UploadFiles.length){
              vm.ProcessBooking(newbooking)
            }
          })
          
        })
      }
      else{
        this.ProcessBooking(newbooking)        
      }      
    },
    ProcessBooking(newbooking){
      this.BookingDialogDeactivate()
      let payload = {
        data: newbooking,
        collection: this.CurrentEntity.id.split('_').join('').toLowerCase(),
        LimitFieldName: 'User_Limit',
        siteid: this.$store.state.ActiveSuiteid
      }
      const functions = firebase.functions();
      const CreateBooking = functions.httpsCallable('CreateBookingSubCol');
        
        CreateBooking(payload).then(result => {
          console.log(result,typeof result, typeof result.data)
          if(result && typeof result.data === 'object' && result.data !== null && !result.data.error){
            this.$emit('ActivateProcessing',false)
          }
          else if(result && result.data === null){
            this.$emit('ActivateProcessing',false)
          }
          else if(result && result.data && result.data.error){
            this.$emit('ActivateProcessing',false)
            let snackbarcontent = {
              snackbartimeout: 10000,
              snackbartext: result.data.error,
              snackbartop: true,
              snackbarpath: ''
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
          }
        })
    },
    UploadFilesandBook(fileobj,fieldname,eventid){
      let vm = this
      return new Promise(function(resolve, reject) {
      let file = fileobj.UploadFile
      let filename = fileobj.UploadFile.name
        let storepath = vm.CurrentEntity.DisplayName.split(' ').join('')+'/'+eventid+'/BookedUsers/'+fieldname+'/'+vm.userLoggedIn.Full_Name+'/'+new Date()+'/'+vm.userLoggedIn.Full_Name+'_'+fieldname+'_'+filename
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            resolve(UploadObject)
          })   
      })       
    },
    CancelUserBooking(event,user){
      let newbooking = 
      {
        Eventid: event.id,
        BookingDate: new Date(),
        Booking_Byid: user.id,
        Userid: user.id,
        siteid: this.$store.state.ActiveSuiteid
      }
      let payload = {
        data: newbooking,
        collection: this.CurrentEntity.id.split('_').join('').toLowerCase(),
      }
      // let BookedUsers = JSON.parse(JSON.stringify(event.BookedUsers))
      // let BookedUserIDs = JSON.parse(JSON.stringify(event.BookedUserIDs))
      // let objindex = BookedUsers.find(obj => obj.Userid == newbooking.Userid)
      // let stringindex = BookedUserIDs.indexOf(newbooking.Userid) 
      // BookedUsers.splice(objindex,1)
      // BookedUserIDs.splice(stringindex,1)
      // db.collection(payload.collection).doc(payload.data.Eventid).update({
      //   BookedUsers: BookedUsers,
      //   BookedUserIDs: BookedUserIDs
      // }) 
      const functions = firebase.functions();
      const CancelUserBooking = functions.httpsCallable('CancelUserBooking');
      this.$emit('ActivateProcessing',true)
      CancelUserBooking(payload).then(result => {
        if(result){
          this.$emit('ActivateProcessing',false)
        }
      })
    },
    
    NotTooLate(event){
      let today = new Date()
      let latersvp = event.RSVP_Type.Name
      //console.log(latersvp)
      let latedate = new Date(event.StartObject)
      if(latersvp === 'Minutes'){
        let minute = event.StartObject.getMinutes()       
        latedate.setMinutes(minute-event.RSVP_Amount)
        return today <= latedate
      }
      else if(latersvp === 'Hours'){
        let hour = event.StartObject.getHours()       
        latedate.setHours(hour-event.RSVP_Amount)
        return today <= latedate
      }
      else if(latersvp === 'Days'){        
        latedate.setDate(event.StartObject.getDate()-event.RSVP_Amount)        
        return today <= latedate
      }
    },
    NotTooEarly(event){
      let today = new Date()
      let earlyrsvp = event.RSVP_Advance_Type.Name
      let earlydate = new Date(event.StartObject)
      if(earlyrsvp === 'Minutes'){
        let minute = event.StartObject.getMinutes()       
        earlydate.setMinutes(minute-event.RSVP_Advance_Amount)
        return today >= earlydate
      }
      else if(earlyrsvp === 'Hours'){
        let hour = event.StartObject.getHours()       
        earlydate.setHours(hour-event.RSVP_Advance_Amount)
        return today >= earlydate
      }
      else if(earlyrsvp === 'Days'){      
        let eventday = event.StartObject.getDate()  
        earlydate.setDate(eventday-event.RSVP_Advance_Amount)
        //console.log(earlydate,today >= earlydate)
          return today >= earlydate
      }
    },

    UserBookingAction(event){      
    if(this.CurrentEntity.Guests_Only && this.UserisGuest || !this.CurrentEntity.Guests_Only && this.userLoggedIn){  
      if(this.UserBooked(event)){
        return 'Cancel'
      }
      else if(event.BookedUsers.length === event.User_Limit){
        return 'Full'
      }
      else if(!this.NotTooLate(event)){
        return 'Too Late'
      }
      else if(!this.NotTooEarly(event)){
        return 'Too Early'
      }
      else{
        return 'Book'
      }
    }    
    else{
      return 'Guests Only'
    }

    },
    UserBooked(event){
      //console.log(event.BookedUserIDs.includes(this.userLoggedIn.id),event.BookedUserIDs)
      return event.BookedUserIDs.includes(this.userLoggedIn.id)
    },
        ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
    CloseCalendarEntry(){
      let newstatus = {ID: 1000003,
                                Name: 'Closed'}
              //What was the point behind this?!!!!!!! Why "sites"???
              // db.collection('sites').doc(this.ActiveTemplateSite.id).collection('entities').doc(this.ActiveEntity.id).collection('datarecords').doc(this.selectedEvent.id).update({
              //   Status_Reason: newstatus
              // })
              this.selectedEvent.Status_Reason = newstatus
    },
    ConfirmCalendarEntry(){
      //send email to selectedEvent.From
      //Render static, or let user input message, depending  ActiveEntity.RAStylesheet.CalendarEntryResponseisStatic
            let elmnt = document.getElementById('ConfirmationMessage')
            let message = elmnt.innerHTML
            let EmailBody = `<html>
<body>
<head></head>
<h2>Booking Confirmed</h2>`+message+`
</body>
</html>`
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: this.selectedEvent.From,
                EmailSubject: 'Booking Confirmed',
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
              let confirmedstatus = {ID: 1000002,
                                Name: 'Confirmed'}
              //What was the point behind this? why "sites"???
              // db.collection('sites').doc(this.ActiveTemplateSite.id).collection('entities').doc(this.ActiveEntity.id).collection('datarecords').doc(this.selectedEvent.id).update({
              //   Status_Reason: confirmedstatus,
              //   ConfirmationMessage: message
              // })
              this.selectedEvent.Status_Reason = confirmedstatus
              this.ConfirmationMessageDynamic = false
    },
    updateRange ({ start, end }) {
      //console.log('change')
        this.start = start
        this.end = end
        
    },
    getEventColor (event) {
      if(event.id && event.Status_Reason.ID === 1000001){
        return 'orange'
      }
      else if(event.id && event.Status_Reason.ID === 1000002){
        return 'green'
      }
      else{
        return 'grey'
      }
        
      },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
    nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      DeleteEvent(event){
        if(!event.id){
          let eventobj = this.newevents.find(obj => obj.Title === event.Title)
          let index = this.newevents.indexOf(eventobj)
          this.newevents.splice(index,1)
        }
      },
      OpenEventEditor({ nativeEvent, event }){
       this.$emit('OpenEventEditor',event)
          if(!event.id){
            let eventobj = this.newevents.find(obj => obj.Title === event.Title)
            this.EditedNewEventIndex = this.newevents.indexOf(eventobj)
            console.log(this.EditedNewEventIndex,event,this.newevents)
          } 
        
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      setToday () {
        
        this.focus = this.today
        setTimeout(() => {
         let todayofweek = this.WeekDays.find(obj => obj.DateString === this.today)
        //console.log(todayofweek,this.WeekDays,this.today)
        let todayfweekindex = this.WeekDays.indexOf(todayofweek)
        this.WeekDay = todayfweekindex
        }, 100);
        
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
    }
}
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>