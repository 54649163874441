<template>
  <span v-if="navitem.Field">
    <v-list-item class="overline"  v-if="$route.name === 'DirectoryBuilder'">
    {{navitem.Title}} <v-icon @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon>
    
    </v-list-item>
    <v-list-item>
        <v-text-field clearable  @keydown.enter="ActivateFilter(navitem,navitem.ActiveFilter)" @click:clear="ActivateFilter(navitem,navitem.ActiveFilter)" v-model="navitem.ActiveFilter" :label="navitem.Field.DisplayName"/><v-btn @click="ActivateFilter(navitem,navitem.ActiveFilter)" small>Search</v-btn>
    </v-list-item>
        <!-- SLIDER FILTER - NUMBER --></span>
</template>

<script>
export default {
    props: ['navitem','navitemindex'],
    components: {

    },
    data(){
        return {
            ActiveFilter: '',
        }
    },
    created(){

    },
    computed:{

    },
    methods: {
        ActivateFilter(navitem,value){
            this.$emit('ActivateFilter',navitem,'Text Search',value)
        },
        EditNavItem(navitem,navitemindex){
            this.$emit('EditNavItem',navitem,navitemindex)
        },
        
    }
}
</script>

<style>

</style>