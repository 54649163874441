<template>
  <div>
     <v-bottom-sheet persistent hide-overlay v-if="EditedTab" v-model="ElementStylingDialog" >
        <v-card width="100%" height="100%" flat tile style="background-color: rgba(255,255,255,0.9)"> 
            <v-card-subtitle class="background darken-1 mediumoverline" style="padding:15px;">
                {{EditedTab.Name}}
             </v-card-subtitle>
              <v-btn right absolute top outlined dark @click="CancelElementStylingDialog()" color="red" style="z-index: 2;">Cancel
            </v-btn> 
        <v-tabs v-model="StylingMenuIndex" class="transtabs transparent" centered>
            
            <v-tab v-if="BoxStylingActive">Box Styling
            </v-tab>
            <v-tab-item v-if="BoxStylingActive">
                 <BoxStylingComponent :System="System" Name="Box Styling"
                :SystemEntities="SystemEntities" :EditedTab="EditedTab" @Onepropemit="Onepropemit"
                />
            </v-tab-item>
              <v-tab v-if="BoxWrappingActive">Box Wrapping
            </v-tab>
            <v-tab-item v-if="BoxWrappingActive">
                 <BoxWrappingComponent :System="System" Name="Box Wrapping"
                :SystemEntities="SystemEntities" :EditedTab="EditedTab" @Twopropemit="Twopropemit"
                />
            </v-tab-item>           
            
            <v-tab>Background
            </v-tab>
            <v-tab-item>
             <v-row class="justify-center">
                <v-col cols="6">
                <v-list-item v-if="EditedTab.SectionType">
                    <v-switch   v-model="EditedTab.HasBGIMG" label="Has BG IMG"/>
                    <v-switch   v-model="EditedTab.BGIMGFixed" label="BG IMG Fixed"/>
                    <v-switch   v-model="EditedTab.BGIMGCentered" label="BG IMG Centered"/>
                </v-list-item>
                <v-card flat tile class="background" v-if="EditedTab.HasBGIMG">
                <v-card-subtitle class="background darken-1 mediumoverline">
                        BG Image
                    </v-card-subtitle>
                    <v-img v-if="EditedTab.SectionType && EditedTab.BGIMG" contain :src="EditedTab.BGIMG" height="200">
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                        <v-chip @click="ActivateBGIMGGalleryDialog()" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </v-row>
                    </v-img>
                <v-img v-if="!EditedTab.BGIMG" contain :src="$vuetify.theme.dark ? require('@/assets/ImageHolder_dark.png') : require('@/assets/ImageHolder.png')" height="200">
                <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                        <v-chip @click="ActivateBGIMGGalleryDialog()" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </v-row>
                </v-img>
                </v-card>
                </v-col> 
                <v-col cols="6">
                    <v-list class="transparent" v-if="EditedTab.Name !== 'HTML Box'">									
                        <v-list-item>			                
                    <v-switch dense label="Transparent" @change="SetRowTransparency(EditedTab)" v-model="EditedTab.Transparent" />	
                    <v-menu      									
                        :close-on-content-click="false"									
                        :nudge-right="40"									
                        transition="scale-transition"									
                        offset-y									
                        min-width="200px"									
                        >									
                        <template v-slot:activator="{ on }">									
                    <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                        </template>									
                    <v-color-picker v-model="EditedTab.RowColor"></v-color-picker>									
                    </v-menu>									
                        </v-list-item>									
                    </v-list>	
                     <v-list-item >
                            <v-switch
                            label="Has BG Gradient"
                            v-model="EditedTab.HasBGGradient"
                            />
                        </v-list-item>
                        <v-list-item  v-if="EditedTab.HasBGGradient">
                            <v-text-field
                            label="Gradient"
                            v-model="EditedTab.BGGradient"
                            />
                            <a v-if="EditedTab.HasBGGradient" href="https://cssgradient.io/" target="_blank">Get Code</a>
                        </v-list-item>
                       
                </v-col>
             </v-row>
            </v-tab-item>
              <v-tab v-if="ClippingActive">Clipping
            </v-tab>
            <v-tab-item v-if="ClippingActive">
                 <ClippingConfigurationComponent :System="System" Name="Waves"
                :SystemEntities="SystemEntities" :EditedTab="EditedTab" @Onepropemit="Onepropemit"
                />
            </v-tab-item>
            <v-tab>Waves
            </v-tab>
            <v-tab-item>
                <WavesConfigComponent :System="System" Name="Waves"
                :SystemEntities="SystemEntities" :EditedTab="EditedTab"
                />
            </v-tab-item>
            <v-tab>Advanced
            </v-tab>
            <v-tab-item class="transparent">
            <v-row class="justify-center">
                <v-col cols="6" class="justify-center">
                    <v-list-item v-if="EditedTab.SectionType">
                    <v-select clearable v-model="EditedTab.RowBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
                </v-list-item>
                 <v-list-item v-if="EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'Clipped Tab' && !EditedTab.DataViewName && EditedTab.Type !== 'Magazine Style'">
                    <v-text-field  v-model="EditedTab.CustomStyle" label="Custom Style"/>
                </v-list-item>
                <v-list-item v-if="EditedTab.Name === 'Title' || EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Image'">
                              <v-select clearable v-model="EditedTab.CustomClass" label="CustomClass" :items="['ScrollRevealLeftWithReverse','ScrollRevealRightWithReverse','ScrollRevealLeft','ScrollRevealRight','ScrollRevealLeftStick','ScrollRevealRightStick']"/>
                          </v-list-item>		
                </v-col>
                <v-col cols="6" class="justify-center">
                <v-list-item v-if="EditedTab.SectionType">
                    <v-switch   v-model="EditedTab.Floating" label="Floating"/>
                </v-list-item>
                  <v-list-item @click="ToggleElementAnimationDialog(EditedTab)" v-if="EditedTab.Name !== 'Parallax' && !EditedTab.DataViewName && EditedTab.Type !== '3D Carousel' && EditedTab.Type !== 'Magazine Style'" >
                    <v-icon left>mdi-animation-play</v-icon> Animation
                    </v-list-item>
                     <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.FlipCard || EditedTab.Name === 'Card Row' && EditedTab.HoverCard || 
           EditedTab.Name === 'Card Row Single' && EditedTab.FlipCard || EditedTab.Name === 'Card Row Single' && EditedTab.HoverCard">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Label BG						
                    </template>									
                <v-color-picker v-model="EditedTab.LabelBGColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
                </v-col>
            </v-row>
            </v-tab-item>
        </v-tabs>
        </v-card>
     </v-bottom-sheet>
     <v-bottom-sheet persistent hide-overlay v-if="EditedTab" v-model="SizeandPositionDialog">
        <v-card width="100%" height="100%" flat tile style="background-color: rgba(255,255,255,0.9)">
            <v-btn right absolute top outlined dark @click="CancelSizeandPosition()" color="red" >Cancel
            </v-btn>
             <v-card-subtitle class="background darken-1 mediumoverline" style="padding:15px;">
                {{EditedTab.Name}}
             </v-card-subtitle>
              <v-tabs v-model="SizeandPositionMenuIndex" class="transtabs transparent" centered>
            <v-tab>Sizing
            </v-tab>
            <v-tab-item class="transparent">
                 <v-row class="justify-center mx-3 my-3">
                  <v-btn-toggle class="justify-center" light >
                    <v-btn @click="ActivateWindowPreset(item,flexind)" fab icon v-for="(item,flexind) in ComputedWindowPresets" :key="item.iemObjKey">
                        <v-icon color="#466ca4" :style="item.Rotate? 'transform: rotate(90deg);' : ''">
                            {{item.Icon}}
                        </v-icon>
                    </v-btn>
                </v-btn-toggle>
                 </v-row>
                 <v-row class="justify-center mx-3 my-3">
                  <v-slider 
                    v-model="EditedTab[ComputedWindowPresets[FlexIndex].FlexProp]"
                    :label="WindowPreset.Name+'('+WindowPreset.Dimensions+')'"
                    min="1"
                    max="12" thumb-label="always"
                    ></v-slider>
                 </v-row>
            </v-tab-item>
            <v-tab>Margins
            </v-tab>
            <v-tab-item class="transparent">
                  <v-list-item>									
                    <v-select :items="['justify-start','justify-center','justify-end','justify-space-between']" v-model="EditedTab.Justify" 									
                        label="Horizontal Align"/>									
                    </v-list-item>									
                <v-list-item>									
                        <v-select clearable :items="['flex-start','center','flex-end']" v-model="EditedTab.Alignment" 									
                        label="Vertical Align"/>									
                    </v-list-item>
                    <v-list-item v-if="EditedTab.Name === 'Title'">									
                        <v-select clearable :items="['flex-start','center','flex-end']" v-model="EditedTab.TextAlign" 									
                        label="Text Align"/>									
                    </v-list-item>
                 <v-row class="justify-center">
                 <v-list class="transparent" v-for="opt in MarginOptions" :key="opt.itemObjKey">								
                        <v-list-item>
                            <v-list-item-content>
                                Margin {{opt.Pos}} ({{EditedTab[opt.Prop]}})
                            </v-list-item-content>
                            <v-list-item-action> <v-btn x-small @click="FigureMarginMethod(opt,EditedTab,'down')">									
                            <v-icon>mdi-chevron-down</v-icon>									
                        </v-btn>
                            </v-list-item-action>
                            <v-list-item-action><v-btn x-small @click="FigureMarginMethod(opt,EditedTab,'up')">									
                            <v-icon>mdi-chevron-up</v-icon>									
                        </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                 </v-row>
            </v-tab-item>
              </v-tabs>
        </v-card>
     </v-bottom-sheet>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import BoxStylingComponent from '@/components/WebPages/ConfigComponents/BoxStylingComponent';
import BoxWrappingComponent from '@/components/WebPages/ConfigComponents/BoxWrappingComponent';
import WavesConfigComponent from '@/components/WebPages/ConfigComponents/WavesConfigComponent';
import ClippingConfigurationComponent from '@/components/WebPages/ConfigComponents/ClippingConfigurationComponent';
export default {
    props: ['System','SystemEntities','AnimationTab','ElementStylingDialog','SizeandPositionDialog','EditedTab','WindowPreset',
    'ComputedWindowPresets','FlexIndex','GalleryLibraries'],
    components: {BoxStylingComponent,BoxWrappingComponent,WavesConfigComponent,ClippingConfigurationComponent},
    data() {
        return {            
            AnimationTab: '',
            SizeandPositionMenuIndex: 0,
            StylingMenuIndex: 0,
            MarginOptions: [
                {Pos: 'Top',Prop: 'MarginTop',Method: 'RowTopMargin'},
                {Pos: 'Bottom',Prop: 'MarginBottom',Method: 'RowBottomMargin'},
                {Pos: 'Left',Prop: 'MarginLeft',Method: 'RowLeftMargin'},
                {Pos: 'Right',Prop: 'MarginRight',Method: 'RowRightMargin'},
            ],
            ClippingConfigComp:   {
                Name: 'Clipping Config',
                compname: 'ClippingConfigurationComponent',
                Children: ['Parallax','Image','Clipped Tab'],
                Conditions: [],
                Icon: 'mdi-drawing'
                },
            BoxWrappingComp: {
                Name: 'Box Wrapping',
                compname: 'BoxWrappingComponent',
                Children: ['Image'],
                Conditions: [
                    {
                    Child: 'Image',
                    Condition: 'HasTextBox',
                    ConditionType: 'Prop'
                    },
                ],
                Icon: 'mdi-format-align-center'
                },
            BoxStylingComp: {
                Name: 'Box Styling',
                compname: 'BoxStylingComponent',
                Children: ['HTML Box','Image','Parallax','Title','Gallery','FAQ','Wiki Collection','HTML Table','Description List','Skills List','Highlight Writeup','Info Card','Web Form','Documents Table',
                'Clipped Tab','Card Block Single','Card Row Single','Timeline Item','Counter Single','Store Product Single','Tiled Titles','Groups Feed',
                'Site Articles','Site Articles','Site Blogs','Site Events','Site Meetups','Groups Feed'],
                Conditions: [
                    {
                    Child: 'Image',
                    Condition: 'HasTextBox',
                    ConditionType: 'Prop'
                    },
                    {
                    Child: 'Parallax',
                    Condition: 'HasTextBox',
                    ConditionType: 'Prop'
                    }
                ],
                Icon: 'mdi-border-style'
                },
        }
    },	
    computed:{
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      IMGProp(){
        return this.$store.state.IMGProp
      },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ClippingActive(){
            let item = this.ClippingConfigComp
            let conditionalchild = item.Conditions.find(cond => {return cond.Child === this.EditedTab.Name})
               
            return !conditionalchild && !item.DisqualifyingProp || !conditionalchild && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp]
            || conditionalchild && conditionalchild.ConditionType === 'Prop' && this.EditedTab[conditionalchild.Condition] && !item.DisqualifyingProp || 
            conditionalchild && conditionalchild.ConditionType === 'Prop' && this.EditedTab[conditionalchild.Condition] && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp] ||
            conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](this.EditedTab) && !item.DisqualifyingProp || 
            conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](this.EditedTab) && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp]
            || item.GlobalQualifier && this[item.GlobalQualifier]
        },
        BoxWrappingActive(){
            let item = this.BoxWrappingComp
            let conditionalchild = item.Conditions.find(cond => {return cond.Child === this.EditedTab.Name})
            let pass = !conditionalchild && !item.DisqualifyingProp || !conditionalchild && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp]
            || conditionalchild && conditionalchild.ConditionType === 'Prop' && this.EditedTab[conditionalchild.Condition] && !item.DisqualifyingProp || 
            conditionalchild && conditionalchild.ConditionType === 'Prop' && this.EditedTab[conditionalchild.Condition] && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp] ||
            conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](this.EditedTab) && !item.DisqualifyingProp || 
            conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](this.EditedTab) && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp]
            || item.GlobalQualifier && this[item.GlobalQualifier]
            pass = item.Children.includes(this.EditedTab.Name)
            return pass
        },
        BoxStylingActive(){
            let item = this.BoxStylingComp
            let conditionalchild = item.Conditions.find(cond => {return cond.Child === this.EditedTab.Name})
            let pass = !conditionalchild && !item.DisqualifyingProp || !conditionalchild && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp]
            || conditionalchild && conditionalchild.ConditionType === 'Prop' && this.EditedTab[conditionalchild.Condition] && !item.DisqualifyingProp || 
            conditionalchild && conditionalchild.ConditionType === 'Prop' && this.EditedTab[conditionalchild.Condition] && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp] ||
            conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](this.EditedTab) && !item.DisqualifyingProp || 
            conditionalchild && conditionalchild.ConditionType === 'Method' && this[conditionalchild.Condition](this.EditedTab) && item.DisqualifyingProp && !this.EditedTab[item.DisqualifyingProp]
            || item.GlobalQualifier && this[item.GlobalQualifier]
            pass = item.Children.includes(this.EditedTab.Name)
            return pass
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignImageandCloseTab']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        AssignImageandCloseTab(){
          //console.log('AssignImageandCloseTab',this.SelectedImage)
          if(this.IMGProp === 'LibraryAsset'){
            //console.log('as LibraryAsset')
            this.AssignGalleryAssets(this.EditedTab,this.SelectedImage)
              this.NoPropsemit('DeactivateTabDialog')
          }
          else{
            //console.log('as else')
              this.AssignImage(this.EditedTab,this.IMGProp,this.SelectedImage)
              this.NoPropsemit('DeactivateTabDialog')
          }
         
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        Onepropemit(method,prp1){
            this.$emit('Twopropemit',method,prp1)
        },
        Twopropemit(method,prp1,prp2){
            this.$emit('Twopropemit',method,prp1,prp2)
        },
        SetRowTransparency(tab){
            this.$emit('SetRowTransparency',tab)
        },
        ActivateWindowPreset(item,flexind){
            this.$emit('ActivateWindowPreset',item,flexind)
        },
        CancelAnimationTab(){
            this.$emit('CancelAnimationTab')
        },
        ToggleElementAnimationDialog(EditedTab){
            this.$emit('ToggleElementAnimationDialog',EditedTab)
            //this.AnimationTab = EditedTab
        },
        ActivateBGIMGGalleryDialog(){
        this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
        this.$store.commit('setGalleryAssets',this.SystemAssets)
        this.$store.commit('setEditedTab',this.EditedTab)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp','BGIMG')
        
        //GalleryLibraries
        //methods ? SelectGalleryAssets
      },
        FigureMarginMethod(opt,tab,direction){
            this.$emit('FigureMarginMethod',opt,tab,direction)
            //this[opt.Method](tab,direction)
        },
        CancelElementStylingDialog(){
            this.StylingMenuIndex = 0,
           this.$emit('CancelElementStylingDialog')
        },
        ActivateElementStylingDialog(elmnt){
            this.$emit('ActivateElementStylingDialog')
        },
        CancelSizeandPosition(){
            this.SizeandPositionMenuIndex = 0
            this.$emit('CancelSizeandPosition')
        },
        ActivateSizeandPosition(elmnt){
            this.$emit('ActivateSizeandPosition')
        },
        
    }
}
</script>

<style>

</style>



