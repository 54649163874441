<template>
  <div>
    <DataSingleRender :System="System" :SystemEntities="SystemEntities" :CurrentEntity="CurrentEntity"
    :Record="RecordData" :CanEdit="CanEdit" :CanDelete="CanDelete" :RouteID="RouteID" :RADB="RADB"
    :SystemPhotoLibraries="SystemPhotoLibraries" :EntityCollectionRef="EntityCollectionRef"
    :MyActivitiesArray="MyActivitiesArray" :UnreadNotifications="UnreadNotifications"
    />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DataSingleRender from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/DataSingleRender';
export default {
    props: ['System','SystemEntities','RouteID','SystemPhotoLibraries','Directories',
    'MyActivitiesArray','UnreadNotifications','RADB'],
    components: {DataSingleRender},
    data() {
        return {
          CurrentEntity: '',
          RecordData: '',
          CanEdit: false,
          CanDelete: false,          
            UserRecord: {},
            UserBusinessUnitID: '',
            UserRoles: [],
            UserBusUnitChildren: [],
            UserBusUnitParents: [],
            RelatedObj: {},
      FeatureNavList: '',
      FeatureNavListLookupEntry: '',
        }
    },	
    computed:{
        
      MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
        ActiveSuiteid(){
            return this.$store.state.ActiveSuiteid
        },
        roottableentities(){
            return this.$store.state.roottableentities
        },
        OperationalDB(){
            return this.CurrentEntity ? this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.RouteID) : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.RouteID) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.RouteID) : ''
        },
        FunctionsRef(){
        return this.$store.state.ActiveSuiteid !== process.env.VUE_APP_RA_SYSTEM_ID ? 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions') : 
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions')
        },
        ConfigEntityID(){
            //this.FunctionEntityid??
            return this.$route.meta && this.$route.meta.EntityID ? this.$route.meta.EntityID : ''
        },
        EntityCollectionRef(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.ConfigEntityID)
        },
        RouteID(){
        if(this.PushedID){
            return this.PushedID
        }
        else{
            return this.$route.params.id
        }
        },
      AppsDataBase(){
              return this.$store.state.AppsDataBase
          },
      CurrentEntityApp(){
              return this.CurrentEntity ? this.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid) : ''
      },
      ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userBU () {
        return this.$store.state.buobj
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        RouteObj(){
            return this.$route
        },
        HasEntityandRecord(){
            return this.CurrentEntity && this.DynamicRecordData
        },
    },
    watch: {
        HasEntityandRecord (value) {
        if(value){
          //console.log('this.CurrentEntity',this.CurrentEntity)
            this.RelatedObj = {id: this.RouteID, [this.CurrentEntity.Primary_Field_Name]: this.DynamicRecordData[this.CurrentEntity.Primary_Field_Name],identifier: this.CurrentEntity.Primary_Field_Name,collection: this.$route.meta.Collection,Link: this.CurrentEntity.SingleName.split(' ').join('') }
            if(this.CurrentEntity.Table_Icon){
              this.RelatedObj.Icon = this.CurrentEntity.Table_Icon  
            }
            if(this.$route.meta && this.$route.meta.RecordisSystem && !this.SingleRecordNavMenuItem){
              this.FinalizerecordLoadingdialog()
              if(this.SingleRecordNavMenu){
                this.EntityReady = true
              }             
            }
        }
      },
        CurrentEntity(value) {
          if(value && this.$route.meta && this.$route.meta.DataSingleBuilder){
            this.$store.commit('SetCurrentEntity',value) 
          }
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    //alert('well at least there is that...')
      this.CurrentEntity = this.SystemEntities.find(obj => obj.id === this.ConfigEntityID)
      this.PrepareSuiteApp()
      this.GetRequestingUser()
    },
    methods:{
        FinalizerecordLoadingdialog(){
            this.$emit('ActivateRecordLoadingDialog',true,100)
            let featurenavlist = this.Directories.find(obj => typeof obj.FeaturesListRelatedBuildID !== 'undefined' && obj.FeaturesListRelatedBuildID === this.CurrentEntity.id)
                //console.log('featurenavlist',featurenavlist,this.Directories,this.CurrentEntity,this.OperationalDB)
                if(featurenavlist){
                  this.FeatureNavList = featurenavlist
                  if(this.FeatureNavList.FeatureListType === 'Lookup'){
                      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).onSnapshot(snapshot => {
                        let data = snapshot.data()
                        if(!data){
                          // db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID)
                        }
                        else{
                          this.FeatureNavListLookupEntry = data
                          this.FeatureNavListLookupEntry.id = this.RouteID
                        }
                        //console.log('this.FeatureNavListLookupEntry',this.FeatureNavListLookupEntry)
                      })
                    }
                }
                else{
                  featurenavlist = this.Directories.find(obj => obj.id === this.CurrentEntity.id)
                  if(featurenavlist && featurenavlist.FeatureListType === 'Standard'){
                    this.FeatureNavList = featurenavlist
                  }
                }
            setTimeout(() => {
              //Moved  this.EntityReady = true to HasEntityandRecord when systemconfig
                  if(this.$route.meta && !this.$route.meta.RecordisSystem || !this.SingleRecordNavMenu){
                    this.EntityReady = true
                    this.$emit('PushRecordName',this.RecordData[this.CurrentEntity.Primary_Field_Name])
                  }
                  //alert('recordfinalcalled')
                  this.$emit('ActivateRecordLoadingDialog',false,100)
            }, 1000);
        }, 
        GetExporttoPDFFunctions(){
    //console.log(this.FunctionsRef)
    this.FunctionsRef.where('FunctionEntity','==',this.ConfigEntityID).onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.ExporttoPDFFunctions.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
            })
    })
    },
    BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
    let vm = this
    let PermissionLevel = 0
    let Roleslength = vm.UserRoles.length
    //console.log(Roleslength)
    return new Promise(function(resolve, reject) {
    vm.UserRoles.map((role,i) => {
        //console.log(i)
            let Alltest = AllRoles.find(obj => obj == role)
            let Peertest = PeerRoles.find(obj => obj == role)
            let Parenttest = ParentRoles.find(obj => obj == role)
            let Childtest = ChildRoles.find(obj => obj == role)
            if(Alltest){
                PermissionLevel = 4
            }                
            else if(Peertest){
                if(PermissionLevel<3){
                PermissionLevel = 3
                }
            }                  
            else if(Parenttest){
                if(PermissionLevel<2){
                PermissionLevel = 2
                }
            } 
            else if(Childtest){
                if(PermissionLevel<1){
                PermissionLevel = 1
                }
                
            } 
            if(i-1+2 === Roleslength){
                resolve(PermissionLevel)
            }
            })

    })
    },
    CheckRouting(){
        //nevermind
    },
        GetRequestingUser(){      
        var vm = this;
        if (this.userLoggedIn) {
              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
                vm.$emit('ActivateRecordLoadingDialog',true,75)
                
                vm.CheckRouting()
                vm.GetSystemRecord()
            //console.log(vm.userBU)
            vm.UserBusinessUnitID = vm.userBU.id
            //console.log(vm.UserBusinessUnitID)
            vm.UserBusUnitChildren = vm.userBU.childarrayQuery
            vm.UserBusUnitParents = vm.userBU.parentarray
            //console.log(vm.UserBusUnitChildren)
              vm.UserRoles = vm.userLoggedIn.rolesarrayQuery
              vm.UserBusinessUnitID = vm.userLoggedIn.Business_Unitid
              //console.log(vm.UserBusinessUnitID)
              db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             //console.log(vm.UserRoles)
             let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }   
                if(!vm.$route.meta.RecordisSystem && vm.CurrentEntity && vm.CurrentEntity.datasetsecuritystructure === 'Business Unit'){
                   vm.BURolesCheck(vm.CurrentEntity.AllgetRoles,vm.CurrentEntity.UnitDowngetRoles,vm.CurrentEntity.UserUnitgetRoles,vm.CurrentEntity.OwnergetRoles).then(PermissionLevel => {
                    //console.log(PermissionLevel)
                    if(PermissionLevel === 4 || vm.IsAdmin === true){
                      vm.listAll = true
                      //console.log('Permissions for Systems is All')
                      //vm.GetSystemBUCheck()
                    }
                    else if(PermissionLevel === 3){
                      vm.listUnitDown = true
                      //console.log('Permissions for Systems is UnitDown')
                    // vm.GetSystemBUCheck()
                    }
                    else if(PermissionLevel === 2){
                      vm.listUserUnit = true
                      //console.log('Permissions for Systems is UserUnit')
                      //vm.GetSystemBUCheck()
                    }
                    else if(PermissionLevel === 1 || vm.IsAdmin){
                      vm.listOwner = true                   
                      //console.log('Permissions for Systems is Owner')
                      //vm.GetSystemBUCheck()
                    }
                    else {
                      vm.RoutetoErrorPage()
                        }
                  })  
                }           
             
              })
          }
      else {
        vm.RoutetoErrorPage()
          }
      },
      RoutetoErrorPage(){
    this.$router.push('/PermissionError')
    },
        GetSystemRecord() {
        let vm = this
        if(!this.PushedID && this.$route.meta && this.ConfigEntityID){
          this.GetExporttoPDFFunctions()  
        }
        
        if(vm.IsAdmin){
          vm.CanEdit = true
          vm.$store.commit('SetCurrentItemModerator',true) 
        }
        console.log(this.OperationalDB)
            this.OperationalDB.onSnapshot((snapshot) => {
            var systemrecord = snapshot.data()
            if(!systemrecord){
              this.$emit('ActivateRecordLoadingDialog',false,100)
              this.$router.push('/404')
            }        
            //console.log(systemrecord)
            systemrecord.id = this.RouteID
            if(systemrecord.Status){
              this.Status = systemrecord.Status
            }
            if(systemrecord.Status_Reason){
              this.Status_Reason = systemrecord.Status_Reason
            }
            this.RecordData = systemrecord  
            if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'){
            let wh = this.$store.state.WarehousesArray.find(obj => obj.id === systemrecord.Warehouseid)
            //let called = false
            if(wh){
             let WarehouseActions = [
              {Display: 'Inbound',Prop: 'InboundTransaction', ArrayName: 'Inbound_Transactions'},
              {Display: 'Outbound',Prop: 'OutboundTransaction', ArrayName: 'Outbound_Transactions'},
            ]
            if(this.userLoggedIn.Warehouses[wh.id]['Receiving'] || this.userIsAdmin){
              this.$store.commit('SetCanReceive',true)
            }
              WarehouseActions.map(act => {
                //console.log('WarehouseActions',wh)
                if(this.userIsAdmin){
                  this.$store.commit('SetCanViewMovementHistory',true)
                }
                else if(this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id]){
                  for(var prop in this.userLoggedIn.Warehouses[wh.id]) {
                    if (this.userLoggedIn.Warehouses[wh.id].hasOwnProperty(prop)) {
                      if(this.userLoggedIn.Warehouses[wh.id][prop] || this.userIsAdmin){
                        if(prop === 'Receiving'){
                         this.$store.commit('SetCanReceive',true)
                        }
                        this.$store.commit('SetCanViewMovementHistory',true)
                        //called = true
                      }
                    }
                  }
                }
              }) 
            }
            
          
        }          
            // if(this.CurrentEntity && this.CurrentEntity.HasActivities){
            //  this.GetActivityQueries() 
            // }     
            
            //console.log('this.RecordData',this.RecordData,systemrecord,this.RelatedDataTabs,this.ComputedSingleBuildTabs)
            this.RecordData.RelatedTables = []
            if(this.RelatedDataTabs){
              this.RecordData.RelatedTables = this.RelatedDataTabs.map(elmnt => {
                
                let entity = this.SystemEntities.find(obj => obj.id === elmnt.Entityid)
                elmnt.RelationshipField = Object.assign({},entity.AllFields.find(obj => obj.id === elmnt.RelationshipField.id))
                if(elmnt.RelationshipField.MappedValues){
                  elmnt.RelationshipField.RecordData = this.RecordData
                }
                let fieldid = elmnt.RelationshipField.id+'id'
                let tablename = elmnt.Entityid.split('_').join('')
                let storequery = tablename+'Query'
                let storearray = tablename+'Array'
                let relatedtable = {
                  id: elmnt.Entityid,
                  TableName: elmnt.Entityid.split('_').join(''),
                  StoreQuery: tablename+'Query',
                  StoreArrayName: tablename+'Array',
                  StoreData: []
                }
                
                
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  //console.log('this.$store.state[storearray]',this.$store.state[storearray],this.RouteID)
                  relatedtable.StoreData = this.$store.state[storearray].filter(record => {
                    return record[elmnt.RelationshipField.id+'id'] === this.RouteID
                  })
                }
                else if(this.$store.state[storequery]){
                  this.GetRelatedData(relatedtable,this.$store.state[storequery],elmnt.RelationshipField.id)
                  // let storepayload = {
                  //         query: this.$store.state[storequery],
                  //         arrayname: storearray
                  //       }
                  //       console.log(storepayload,vm.$store.state,storearray)
                  //       this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                          // relatedtable.StoreData = vm.$store.state[storearray]
                        // })
                }
                //console.log('RelatedTables this.RecordData.RelatedTables relatedtable',relatedtable)
                return relatedtable
              })
              //console.log('this.RecordData.RelatedTables',this.RecordData.RelatedTables)
            }
            //console.log('this.$store.state',this.$store.state)
               if(!this.userIsAdmin){
                 //maybe unnceessary but seems we forgot custom roles check...
              if(this.$route.meta && this.$route.meta.subcat){
                if(this.$store.state[this.$route.meta.subcat.split(' ').join('')+'CanEdit']){
                  this.CanEdit = true
                  this.$store.commit('SetCurrentItemModerator',true)   
                }
              }
              if(this.CurrentEntity && this.CurrentEntity.datasetsecuritystructure === 'Warehouse' && systemrecord.Warehouseid && this.userLoggedIn.Warehouses){
                   this.CanEdit = this.userLoggedIn.Warehouses[systemrecord.Warehouseid].Update
                   if(this.CanEdit){
                     this.$store.commit('SetCurrentItemModerator',true)
                   }
              }
              if(this.editAll){
                  this.CanEdit = true       
                  this.$store.commit('SetCurrentItemModerator',true)   
                }
                else if(this.editUnitdown){
                  if(this.userBUChildren.includes(systemrecord.Business_Unitid)){
                    this.CanEdit = true  
                    this.$store.commit('SetCurrentItemModerator',true)
                    }
                }
                else if(this.editUserUnit){
                  if(systemrecord.Business_Unitid === this.userBUID){
                    this.CanEdit = true  
                    this.$store.commit('SetCurrentItemModerator',true)
                    }
                }
                else if(this.editOwner){
                  if(systemrecord.Ownerid === this.UserRecord.id){
                    this.CanEdit = true 
                    this.$store.commit('SetCurrentItemModerator',true) 
                    }
                }
            }
            this.selectedRecord = {id: this.RouteID, Name: systemrecord.Name }
                   
            //this.GetEntitiesSubColArray()
            //Moved RouteObj apply to HasEntityandRecord watcher
            if(this.$route.meta && this.$route.meta.SystemBuilder && !this.$route.meta.RecordisSystem){
              this.GetSystemConfig(systemrecord)
            }
            else if(this.$route.meta && this.$route.meta.RecordisSystem){
             //this.FinalizerecordLoadingdialog() 
             //console.log('this.SystemEntities,this.ConfigSystemEntities',this.SystemEntities,this.ConfigSystemEntities)
            }
            else{
              //alert('is else')
             
              this.FinalizerecordLoadingdialog()
            }
            
            this.RecordData = systemrecord
            this.Status_Reason = systemrecord.Status_Reason
            this.RecordData.id = this.RouteID
            this.RecordObj = this.RecordData
            this.$emit('PushRecordName',this.RecordData[this.CurrentEntity.Primary_Field_Name])
            if(typeof systemrecord.Owner !== 'undefined'){
              let tempOwner = systemrecord.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof systemrecord.Owner !== 'undefined'){
              this.Business_Unit = systemrecord.Business_Unit
            }
                
            //this.Name = systemrecord.Name
            //this.editedItem.Name = systemrecord.Name
            if(this.CurrentEntity && this.CurrentEntity.AllFields){
              let lookupfields = this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Lookup' || field.Type === 'Social Lookup'  
              })
              lookupfields.map(field => {
                let storequery = field.RelatedBuildID.split('_').join('')+'Query'
                let storearray = field.RelatedBuildID.split('_').join('')+'Array'
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  field.Array = this.$store.state[storearray]
                  if(field.IsMultiple){
                   field.Array = field.Array.map(item => {                      
                        let itemobj = {
                          [field.LookupFieldName]: item[field.LookupFieldName],
                          id: item.id
                        }
                          return itemobj
                    })
                   }
                  if(this.RecordData[field.Name+'id']){
                    let lookupobj = field.Array.find(obj => obj.id === this.RecordData[field.Name+'id'])
                    if(lookupobj){
                      this.RecordData[field.Name] = lookupobj
                    } 
                  } 
                }
                else if(this.$store.state[storequery]){
                  let storepayload = {
                    query: this.$store.state[storequery],
                    arrayname: field.RelatedBuildID.split('_').join('')+'Array'
                  }
                  //console.log(storepayload,vm.$store.state,storearray)
                  vm.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                    //console.log('store GetCollectionArray',result,storearray,vm.$store.state[storearray])
                    field.Array = vm.$store.state[storearray]
                    if(field.IsMultiple){
                   field.Array = field.Array.map(item => {                      
                        let itemobj = {
                          [field.LookupFieldName]: item[field.LookupFieldName],
                          id: item.id
                        }
                          return itemobj
                    })
                   }
                    if(vm.RecordData[field.Name+'id']){
                      let lookupobj = field.Array.find(obj => obj.id === vm.RecordData[field.Name+'id'])
                      if(lookupobj){
                        vm.RecordData[field.Name] = lookupobj
                      } 
                    }
                  })
                }
              })
            }
        }, (error) => {
              //console.log("error",error)
              });
    },
      PrepareSuiteApp(){
        if(!this.ActiveSuiteApp && this.CurrentEntityApp){
          this.$store.commit('setActiveSuiteApp',this.CurrentEntityApp)
        }
        else{
        }
      },
    }
}
</script>

<style>

</style>



