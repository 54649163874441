<template>
  <v-main>
   <v-card-title style="border-radius: 15px;"
    :class="$vuetify.theme.dark ? 'SoftBuilderGradient justify-center mediumoverline' : 'SoftBuilderGradient justify-center mediumoverline'">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      My Sites (in Progress)
      <v-spacer>
      </v-spacer>
      <v-btn absolute v-if="userLoggedIn.IsSiteCreator || userIsAdmin" right style="right: 100px;" @click="ActivateSuiteTemplateDialog()" outlined>
        <v-icon>mdi-plus</v-icon>
        New
      </v-btn>
    </v-card-title>
    <v-row class="justify-center"
        no-gutters>
        <v-col :cols="12" xl="3" lg="4" md="6" sm="6" xs="12" v-for="item in MyComputedSuiteTemplates" :key="item.itemObjKey">
    <v-card width="320" height="350" elevation="6" class="siteconfigcard" >
    <!-- <v-card v-if="!Refreshing && ActiveTemplate && !Editing" height="100%" width="100%"> -->
        <!-- <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
                    <v-img contain src="@/assets/BnPFullLogo.png"/>
                </v-avatar>
            Subscriptions for your Suite
            <v-spacer>
            </v-spacer>
            {{CurrencyFormatter(SuiteTotal,$store.state.DefaultCurrency.Currency)}}
            </v-card-title> -->
        <v-card-subtitle class="title-ribbon purple mediumoverline white--text justify-center" style="border-radius: unset!important;margin-top:10px;">
           {{item.Name}}
        </v-card-subtitle>
        <v-list-item  v-if="item.Client" style="margin-top: 70px;">
          <v-list-item-avatar class="blue" size="50">
              <img v-if="item.Client.Profile_Photo "
              :src="item.Client.Profile_Photo "    
              style="object-fit: cover;"          
              >
              <img v-if="!item.Client.Profile_Photo "
                src="@/assets/BlankProfilePic.png"   
                style="object-fit: cover;"           
              >                      
              </v-list-item-avatar>
            <!-- </v-list-item-content> -->
            <v-list-item-content >
            <v-chip>{{item.Client.Full_Name}}</v-chip>   
            </v-list-item-content>
        </v-list-item>
       <v-card-text>        
        <div v-html="smart_substr(item.Description,300)"/>
        </v-card-text>
         <v-card-actions style="position: absolute;bottom:10px;">   
          <v-chip>{{item.PublishStatus}}</v-chip>           
                <v-spacer>
                </v-spacer>
                <v-btn right dark outlined color="green" :to="'/Your-Domain/'+item.id+'/Main'">
                    View
                </v-btn>
            </v-card-actions>
    </v-card>
        </v-col>
    </v-row>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
      MyComputedSuiteTemplates(){
        return this.RelatedSuiteTemplates.map(temp => {
          let tempobj = Object.assign({},temp)
          if(tempobj.Client){
            let clientuserobj = this.UsersStore.find(obj => obj.id === tempobj.Clientid)
            if(clientuserobj){
              tempobj.Client = clientuserobj
            }
          }
          else if(tempobj.ClientInviteID){
            tempobj.Client = {Full_Name: 'Invited User'}
          }
          else{
            tempobj.Client = {Full_Name: 'No Client'}
          }
          return tempobj
        })
      },
      RelatedSuiteTemplates(){
      return this.MySuiteTemplates.concat(this.ClientSuiteTemplates) 
    },
    SuiteTemplates(){
    return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
    },
    OtherSuiteTemplates(){
    return this.$store.state.OtherSuiteTemplates
    },
    MySuiteTemplates(){
    return this.$store.state.MySuiteTemplates
    },
    ClientSuiteTemplates(){
    return this.$store.state.ClientSuiteTemplates
    },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.$store.commit('setBuilderView','')
      //this.FixuserSites()
    },
    methods:{
      FixuserSites(){
        let users = []
        let templates = []
        this.SuiteTemplates.map(temp => {
          let oncheck = templates.find(obj => obj.id === temp.id)
          if(!oncheck){
            templates.push(temp)
          }
        })
      console.log('templates',templates)
      // templates.map(temp => {
      //   let owneruser = {id: temp.Ownerid, Sites: [temp.id]}
      //   let clientuser = {id: temp.Clientid, Sites: [temp.id]}
      //   let oncheck = users.find(obj => obj.id == owneruser.id)
      //   if(!oncheck && owneruser.id){
      //     users.push(owneruser)
      //   }
      //   else if(oncheck && owneruser.id){
      //     let siteoncheck = oncheck.Sites.find(obj => obj === temp.id)
      //     if(!siteoncheck){
      //       oncheck.Sites.push(temp.id)
      //     }
      //   }
      //   let cloncheck = users.find(obj => obj.id == clientuser.id)
      //   if(!cloncheck && clientuser.id){
      //     users.push(clientuser)
      //   }        
      //   else if(cloncheck && clientuser.id){
      //     let siteoncheck = cloncheck.Sites.find(obj => obj === temp.id)
      //     if(!siteoncheck){
      //       cloncheck.Sites.push(temp.id)
      //     }
      //   }
      // })
      // console.log('users',users)
      // users.map(user => {
      //   db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(user.id).update({
      //     Sites: user.Sites
      //   })
      // })
      // templates.map(temp => {
      //   db.collection('systems').doc(temp.id).set(temp).then(sysdoc => {
      //     this.RAPlugins.map(plug => {
      //       let plugobj = Object.assign({},temp.PluginDataBase[plug.id])
      //       if(plugobj.Active){
      //       db.collection('systems').doc(temp.id).collection('plugins').doc(plug.id).set(plugobj)
      //       }
      //       //apps can be worried about later this is a restructure fix anyway
      //     })
          
      //   })
      // })
      templates.map(temp => {
       let buref = db.collection('SystemConfig').doc(temp.id).collection('businessunits')
        let HeadofficeObj = {
          Name: 'Head Office',
          Description: 'The default, main Business Unit',
          NameQuery: [
            'h',
            'he',
            'hea',
            'head',
            'heado',
            'headof',
            'headoff',
            'headoffi',
            'headoffic',
            'headoffice'
          ],
          childarrayDBRules: {
            Head_Office: true
          },
          childarrayQuery: ['Head_Office'],
          Top: true
        }
        let GuestObj = {
          Name: 'Guest',
          Description: 'A Business Unit completely unrelated to others meant to give basic Signed In Permissions to any Guest Users',
          NameQuery: [
            'g',
            'gu',
            'gue',
            'gues',
            'guest',
          ],
          childarrayDBRules: {
            Guest: true
          },
          childarrayQuery: ['Guest'],
          IsGuestBU: true,
          FinalStructure: true
        }
        buref.doc('Guest').set(GuestObj)
        buref.doc('Head_Office').set(HeadofficeObj)
        
       let userroleref = db.collection('SystemConfig').doc(temp.id).collection('userroles')
       let userroleobj = {
        Business_Unit: {Name: HeadofficeObj.Name,id: 'Head_Office'},
        Business_Unitid: 'Head_Office',
        Created_On: new Date(),
        IsFeaturedMember: false,
        IsSiteCreator: false,
        IsWebDeveloper: false,
        MonetizationOfficer: false,
        IsEagleViewer: false,
        BusinessProfileAdmin: false,
        SiteContentAdmin: false,
        IsGuestUser: false,
        IsSystemAdmin: false,
        IsTeamActivityManager: false,
        CanInviteUsers: false,
        InviteGroups: [],
        CanExport: false,
        CanImport: false,
      }
      let ownermatch = this.UsersStore.find(obj => obj.id === temp.Ownerid)
       let owneruser = {
        id: temp.Ownerid,
        ...temp.Owner,
        ...userroleobj,
        rolesarrayDBRules: {SystemAdmin: true},
        rolesarrayQuery: ['System Admin'],
        Subordinatearrayquery: [temp.Ownerid],
        SubordinateArrayDBrules: {[temp.Owner.id]: true},
        IsSystemAdmin: true,        
        Name: ownermatch.Name,
        Surname: ownermatch.Surname,
        Full_Name: ownermatch.Full_Name,
        Email: ownermatch.Email,
        Title: ownermatch.Title,
        Apps: []
        }
        if(ownermatch.Profile_Photo){
              owneruser.Profile_Photo = ownermatch.Profile_Photo
            }
        userroleref.doc(owneruser.id).set(owneruser).then(ownerusedoc => {
          if(temp.Client && temp.Clientid !== temp.Ownerid){            
        let clientmatch = this.UsersStore.find(obj => obj.id === temp.Clientid)
          let clientuser = {
            id: temp.Clientid,
            ...temp.Client,
            ...userroleobj,
            rolesarrayDBRules: {},
            rolesarrayQuery: [],
            Subordinatearrayquery: [temp.Clientid],
            SubordinateArrayDBrules: {[temp.Clientid]: true},
            IsEagleViewer: true,                 
            Name: clientmatch.Name,
            Surname: clientmatch.Surname,
            Full_Name: clientmatch.Full_Name,
            Email: clientmatch.Email,
            Title: clientmatch.Title,
             Apps: []
            }
            if(clientmatch.Profile_Photo){
              clientuser.Profile_Photo = clientmatch.Profile_Photo
            }
            userroleref.doc(clientuser.id).set(clientuser)
          }
        })
        
      })
      },
      ActivateSuiteTemplateDialog(){
        
        if(this.userLoggedIn.IsSiteCreator || this.userIsAdmin){
          this.$store.commit('SetSocialItemInteractMethodProp','') 
          this.$store.commit('SetSocialItemInteractMethod','ActivateSuiteTemplateDialog')
        }
        else{
          let payload = [{Prop: 'WebDevelopers',Value: true}]
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateFeaturedMembersOverlay')
        }
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        }
    }
}
</script>

<style>

</style>



