<template>
  <v-main class="background">
    <v-layout class="justify-center">
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="d-flex">
    <v-card width="350" elevation="6" class="siteconfigcard" v-if="SuiteTemplate">
        
    <!-- <v-card v-if="!Refreshing && ActiveTemplate && !Editing" height="100%" width="100%"> -->
        <!-- <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
                    <v-img contain src="@/assets/BnPFullLogo.png"/>
                </v-avatar>
            Subscriptions for your Suite
            <v-spacer>
            </v-spacer>
            {{CurrencyFormatter(SuiteTotal,$store.state.DefaultCurrency.Currency)}}
            </v-card-title> -->
        <v-card-subtitle class="title-ribbon purple mediumoverline white--text justify-center" style="border-radius: unset!important;margin-top:10px;">
           {{SuiteTemplate.Name}}
        </v-card-subtitle>
       <v-card-text style="margin-top: 70px;" v-if="Editing">
            <v-text-field label="Name" v-model="SuiteTemplate.Name"/>
            <v-switch @change="CheckInviteData()" v-model="SuiteTemplate.SkipClientInfo" label="Skip Client for Now"/>
            <v-switch v-if="!SuiteTemplate.SkipClientInfo" @change="CheckInviteData()" v-model="SuiteTemplate.ClientisNonMember" label="Client is not a Member yet"/>
            <UserLookup :UsersLookupArray="UsersArray"  :ModelProp="'Client'" :RecordObj="SuiteTemplate" @UpdateUserLookupProp="UpdateUserLookupProp"
            :FieldLabel="'Client'" :Rules="[$store.state.formrules.required]" v-if="!SuiteTemplate.ClientisNonMember && !SuiteTemplate.SkipClientInfo"
            />
                <UserInviteForm :System="System" :SystemEntities="SystemEntities" v-if="SuiteTemplate.ClientisNonMember && SuiteTemplate.InviteData && !SuiteTemplate.SkipClientInfo"
            :editedInvite="SuiteTemplate.InviteData" @SendInvitetoUser="PrepareInvitetoUser" @CancelInvitingUserEmail="CancelInvitingUserEmail"
            :SystemTableFilters="SystemTableFilters" :InviteText="'Send'" :ShowCancel="false" :PersonalizedMessageAllowed="true"
            />
            
                <ContentEditableField style="padding:15px;"
            :FieldObject="SuiteTemplate" :FieldName="'Description'"
            :FieldValue="SuiteTemplate.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            
        </v-card-text>
        <v-card-actions v-if="Editing">            
            <v-select label="Publish Status" :items="['Draft','Published']" v-model="SuiteTemplate.PublishStatus" />
        </v-card-actions>
         <v-card-actions v-if="Editing" style="padding-bottom:20px;">
              
                <v-spacer>
                </v-spacer>
                <v-btn dark outlined color="green" @click="SaveSuiteTemplate()">
                    Save
                </v-btn>
            </v-card-actions>
            <v-card-text v-if="!Editing" style="margin-top: 70px;">
                <v-list-item  v-if="SuiteTemplate.Client">
                    <v-list-item-avatar class="blue" size="50">
                        <img v-if="SuiteTemplate.Client.Profile_Photo "
                        :src="SuiteTemplate.Client.Profile_Photo "    
                        style="object-fit: cover;"          
                        >
                        <img v-if="!SuiteTemplate.Client.Profile_Photo "
                            src="@/assets/BlankProfilePic.png"   
                            style="object-fit: cover;"           
                        >                      
                        </v-list-item-avatar>
                        <!-- </v-list-item-content> -->
                        <v-list-item-content >
                        <v-chip>{{SuiteTemplate.Client.Full_Name}}</v-chip>   
                        </v-list-item-content>
                    </v-list-item>
                <div v-html="SuiteTemplate.Description"/>
            </v-card-text>
            <v-card-actions v-if="userLoggedIn.id === SuiteTemplate.Ownerid">
                <v-spacer>
                </v-spacer>
                
                <v-switch  v-model="Editing" label="Edit">
            </v-switch>
            </v-card-actions>
    </v-card>
    </div>
    </v-layout>
    <v-row class="justify-center"
        no-gutters v-if="ActiveSuiteTemplate">
        <v-col :cols="12" xl="3" lg="4" md="6" sm="6" xs="12" v-for="item in ComputedModules" :key="item.itemObjKey">
            <v-card max-width="400" elevation="6" class="siteconfigcard" >
        <v-card-subtitle class="title-ribbon purple mediumoverline white--text justify-center" style="border-radius: unset!important;margin-top:10px;">
           {{item.DisplayName}}
        </v-card-subtitle>
            <v-card-text style="margin-top: 70px;padding: 0px;height: 250px;overflow: hidden;">
            
                <!-- inert mini render here -->
                <!-- :style="AltBar ? 'margin-top: 106px;' : 'margin-top: 236px;'" -->
                 <LandingPageSingle v-if="ActiveSuiteTemplate && PrimaryWebTemplate && item.id === 'Website_Builder'" :ActiveTemplate="PrimaryWebTemplate" :System="System" :SystemEntities="SystemEntities"
                    :SitePages="WebsiteSitePages" @TogglePreviewingMode="TogglePreviewingMode"
                    />
                <LandingPageSingle v-if="ActiveSuiteTemplate && PrimarySocialNetworkTemplate && item.id === 'Social_Network_Builder'" :ActiveTemplate="PrimarySocialNetworkTemplate" :System="System" :SystemEntities="SystemEntities"
                :SitePages="SocialNetworkPages" @TogglePreviewingMode="TogglePreviewingMode"
                />
                            <!-- <v-carousel
                    :cycle="!item.Disabled"
                    :height="MiniView ? 200 : 200"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in item.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  >
                 
                  <v-sheet v-if="item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel> -->
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn :to="item.id === 'Website_Builder' ? '/Your-Domain/'+$route.params.id+'/Website' : 
                item.id === 'Social_Network_Builder' ? '/Your-Domain/'+$route.params.id+'/Social-Network' : 
                item.id === 'Database_Builder' ? '/Your-Domain/'+$route.params.id+'/Social-Network' : 
                item.id === 'Documentation_Builder' ? '/Your-Domain/'+$route.params.id+'/Social-Network' : ''" v-if="item.Active" outlined dark color="success">
                    View
                </v-btn>
                <v-btn depressed plain v-if="!item.Active" outlined dark color="grey">
                    Not Installed (INSTALL)
                </v-btn>
                <v-btn
                    color="teal"
                    text
                    small
                    :to="item.RoutePath"
                  >
                    Explore
                  </v-btn>
            </v-card-actions>
            </v-card>
        </v-col>
    </v-row>    
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RecordLiaison from '@/components/General/RecordLiaison';
import UserLookup from '@/components/General/UserLookup'
import UserInviteForm from '@/components/General/UserInviteForm';
import RecordChatRoom from '@/components/General/RecordChatRoom';
import ContentEditableField from '@/components/Database/ContentEditableField'	
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';		
export default {
    props: ['System','SystemEntities','Notifications'],
    components: {RecordLiaison,UserLookup,UserInviteForm,RecordChatRoom,ContentEditableField,LandingPageSingle},
    data() {
        return {
            SocialNetworkPages: [],
            WebsitePages: [],
            ActiveTemplate: '',
            Editing: false,
            // Modules: [
            //     {id: 'Website_Builder',Name: 'Website',Icon: 'mdi-search-web',Active: false},
            //     {id: 'Social_Network_Builder',Name: 'Social Network',Icon: 'mdi-home-group',Active: false},
            //     {id: 'Database_Builder',Name: 'Database Apps',Icon: 'mdi-database',Active: false},
            //     {id: 'Documentation_Builder',Name: 'Documentation Builder',Icon: 'mdi-book-education',Active: false}
            // ],
            LiaisonDialogActive: false,
            // ActiveProcess: {},
            CurrentEntity: {
                //it's okay undderscore caps below fixes it
                id: 'Suite_Templates',
                collection: 'suitetemplates',
                DisplayName: 'Suite Templates',
                //SingleName: 'Suite Template', actually now the purpose of it is purely routing so
                SingleName: 'Your-Domain',
                //getting WAY too many errrs here
                ClientLookupField: {Name: 'Client',DisplayName: 'Client',id: 'Client',LookupFieldName: 'Full_Name'},
                DigitizedForms: [],
                DocumentRegisters: [],
                //hell yeah!!! let's get real, how do we put Assets in here?
                Galleries: [{id: 'Assets',DisplayName: 'Assets'}],
                RecordPrimaryField: {Name: 'Name',DisplayName: 'Name',id: 'Name'}
            },
            SuiteTemplate: ''
        }
    },	
    computed:{
        PurchasedPlugins(){
          return this.ActiveSuiteTemplate ? this.PluginList.filter(plug => {
            return this.ActiveSuiteTemplate.PluginDataBase[plug.id] && this.ActiveSuiteTemplate.PluginDataBase[plug.id].Active
          }) : []
        },
        PluginList(){
              return this.ActiveSuiteTemplate ? this.DataSingleBuilder ? this.AppPluginsMatched(['Database Builder','DBB']) : this.RAPluginsMatched(['Website Builder','WB','Module']) : []
          },
        SuiteTotal(){
              return this.ActiveSuiteTemplate ? 0 : this.PurchasedPlugins.map(plug => {
                  return plug.Price
              }).reduce((a, b) => a + b, 0)
        },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        PluginDataBase(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.PluginDataBase : ''
        },
        Modules(){
            return this.RAPlugins.filter(plug => {
                return plug.Module_Category.Name === 'Module'
            })
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ComputedModules(){
            return this.Modules.map(mod => {
                let modobj = Object.assign({},mod)
                modobj.DisplayName = modobj.Name.split(' Builder')[0]
                let match = ''
                if(this.PluginDataBase){
                    match = this.PluginDataBase[mod.id]
                    if(match && match.Active){
                        modobj.Active = true
                    }
                }
                return modobj
            }).sort(function (a,b){
            return (b.Name > a.Name) ? 1 : -1;
            })
        },
        ChatDialog(){
            return this.$store.state.ChatDialog
        },
        DataRecord(){
            let record = Object.assign({},this.SuiteTemplate)
            record.Notifications = this.Notifications.filter(not => {
                //need to improve right
                return not
            })
            record.NotificationCount = record.Notifications.length
            return record
        },
        OperationalDB(){
            return db.collection('SystemConfig').doc(this.ActiveSuiteTemplate.id)
        },
        CanLiaise(){
            return this.CanEdit || this.SuiteTemplate && this.SuiteTemplate.Clientid === this.userLoggedIn.id
        },
        CanEdit(){
            return  this.userLoggedIn && this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Ownerid === this.userLoggedIn.id
        },
        ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
        AltBar(){
            return this.SuiteWebsite && this.SuiteWebsite.PublicNavbarStyle === 'STD Bar'
        },
        SocialNetworkPages(){
            return this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Pages : []
        },
        WebsiteSitePages(){
            return this.SuiteWebsite ? this.WebsitePages : []
        },
        PrimarySocialNetworkTemplate(){
            //could do default page later right?
            //console.log('SuiteSocialNetwork',this.SuiteSocialNetwork,this.SuiteSocialNetwork.Pages)
            return this.SuiteSocialNetwork && this.SuiteSocialNetwork.Social_Landing_Page ? 
            this.SocialNetworkPages.find(obj => obj.Name === this.SuiteSocialNetwork.Social_Landing_Page.split('/').join('').split('-').join(' ').split('_').join(' ')) : 
            this.SuiteSocialNetwork ? this.SocialNetworkPages[0] : ''
        },
        
        PrimaryWebTemplate(){
            //could do default page later right?
            //console.log('SuiteWebsite',this.SuiteWebsite,this.SuiteWebsite.Pages)
            return this.SuiteWebsite && this.SuiteWebsite.Public_Landing_Page ? 
            this.WebsitePages.find(obj => obj.Name === this.SuiteWebsite.Public_Landing_Page.split('/').join('').split('-').join(' ').split('_').join(' ')) : 
            this.SuiteWebsite ? this.WebsitePages[0] : ''
        },
        SuiteWebsite(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.Website : ''
        },
        SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
        // SuiteWebTemplates(){
        //     return this.RelevantSuiteTemplates.filter(temp => {
        //         return temp.Secondary_Category.Name === 'Website'
        //     })
        // },
        // RelevantSuiteTemplates(){
        //     return this.ActiveSuiteTemplate.templates
        // },
        SuiteTemplates(){
        return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
        },
        OtherSuiteTemplates(){
        return this.$store.state.OtherSuiteTemplates
        },
        MySuiteTemplates(){
        return this.$store.state.MySuiteTemplates
        },
        ClientSuiteTemplates(){
        return this.$store.state.ClientSuiteTemplates
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
      SocialNetworkPages: {
            handler: function(newvalue, oldvalue) {
                this.$store.commit('setActiveSuiteSocialNetworkPages',newvalue)
            },
            deep: true
      },
      WebsitePages: {
            handler: function(newvalue, oldvalue) {
                this.$store.commit('setActiveSuiteWebsitePages',newvalue)
            },
            deep: true
      },      
        ActiveSuiteTemplate: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id){
                     this.GetSuiteTemplate()
                }
            },deep: true
        }, 
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignInviteid']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        //mainly the purpose for this comp
        //1. Set the details like the who is "Client"
        //2. Perhaps in time a dashbaord of sorts right? This may set a standard for std marketpalce Templates once again BTW
        //3. Perhaps, MAYBE...the "client" user can get a "dashbaord" or something, could see "total cost" and "cards" of modules, disabled if not activated, clicking on reroutes...right?
        //so on the last point though, this may set a standard for std marketpalce sure, but it's NOT going to share a comp with it, it's too different
         if(this.ActiveSuiteTemplate){
            this.GetSuiteTemplate()
         }
         this.$store.commit('setBuilderView','')
    },
    methods:{
        GetActiveSuiteTemplatePages(temp){
        //let temp = JSON.parse(JSON.stringify(tempobj))
        
        let colchecks = [
          {Prop: 'SocialNetworkPages', Array: 'SocialNetworks',Single: 'SocialNetwork',SubCollection: 'Pages'},
          {Prop: 'WebsitePages', Array: 'Websites',Single: 'Website',SubCollection: 'Pages'},
        //   {Array: 'Libraries',Single: 'Library',SubCollections: []},
        //   {Array: 'Databases',Single: 'Database',SubCollections: []}
          ]
        colchecks.map((col,colindex) => {
            let subcol = col.SubCollection
           if(this[col.Prop]){
                this[col.Prop] = []
              }
              let subcolref = db.collection(col.Array).doc(temp.id).collection(col.SubCollection)
              subcolref.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let subcolitem = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      //console.log(subcolitem)
                      let oncheck = this[col.Prop].find(obj => obj.id === subcolitem.id)
                      if(!oncheck){
                      this[col.Prop].push(subcolitem)
                      }
                  }
                  if (change.type === 'modified') {
                    let subcolitem = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      //console.log(subcolitem)
                      let oncheck = this[col.Prop].find(obj => obj.id === subcolitem.id)
                      if(!oncheck){
                        let index = this[col.Prop].indexOf(oncheck)
                      this[col.Prop].splice(index,1,subcolitem)
                      }
                  }
                  if (change.type === 'removed') {
                    let subcolitem = {
                          ...change.doc.data(),
                          id: change.doc.id
                      }
                      //console.log(subcolitem)
                      let oncheck = this[col.Prop].find(obj => obj.id === subcolitem.id)
                      if(!oncheck){
                        let index = this[col.Prop].indexOf(oncheck)
                      this[col.Prop].splice(index,1)
                      }
                  }
                  
                }) 
                console.log('this[col.Prop]',this[col.Prop])
              })
        })
        //console.log('this.$store.state.ActiveSuiteTemplate',this.$store.state,this.$store.state.ActiveSuiteTemplate)
      },
        AppPluginsMatched(categorymatches){
            let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
        RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
            if(this.DataSingleBuilder){
                return item.Exclusively_as_Extension
            }
            else{
                return item
            }
        })
      },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
        CheckInviteData(){
            if(!this.SuiteTemplate.InviteData){
                this.SuiteTemplate.InviteData = {}
            }
            if(this.SuiteTemplate.SkipClientInfo){
                delete this.SuiteTemplate.InviteData
            }
        },
        ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean) 
        },
        ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
        },
        ToggleLiaisonDialog(){
        this.LiaisonDialogActive = !this.LiaisonDialogActive
        },
        ActivateLiaisonDialog(){
        this.LiaisonDialogActive = true
        },
        PrepareInvitetoUser(editedInvite){
            this.SuiteTemplate.InviteData = editedInvite
            this.SuiteTemplate.InviteData.RelatedObj = {
                id: this.SuiteTemplate.id,
                Name: this.SuiteTemplate.Name,
                identifier: 'Name',
                collection: 'suitetemplates',
                Link: '/Your-Domain/'+this.SuiteTemplate.id+'/Main'
            }
            this.SuiteTemplate.InviteData.RelatedField = 'Client'
            //console.log('this.SuiteTemplate',this.SuiteTemplate)
            this.$emit('ActivateProcessing',true)
            this.SendInvitetoUser(this.SuiteTemplate.InviteData)
        },
          SendInvitetoUser(editedInvite){
            //lol, technicall first complete the frigging data
            this.$store.commit('SetSocialItemInteractMethodProp',editedInvite) 
            this.$store.commit('SetSocialItemInteractMethod','SendInvitetoUser')
        },
        AssignInviteid(result){
          this.OperationalDB.update({
            InviteData: this.SuiteTemplate.InviteData,
            ClientInviteID: result.InviteID,
            SkipClientInfo: false,
            ClientisNonMember: true
          })
          this.$emit('ActivateProcessing',false)
        },
        SaveSuiteTemplate(){
            let updatedsuite = {}
            let client = this.SuiteTemplate.Client
            let array = ['Name','SkipClientInfo','ClientisNonMember','Description','PublishStatus']
            array.map(prp => {
                if(typeof this.SuiteTemplate[prp] !== 'undefined'){
                    updatedsuite[prp] = this.SuiteTemplate[prp]
                }
            })
            if(client){
                updatedsuite.Clientid = client.id
                updatedsuite.Client = {
                    id: client.id,
                    Name: client.Name,
                    Surname: client.Surname,
                    Full_Name: client.Full_Name,
                }
                if(client.Email){
                    updatedsuite.Client.Email = client.Email
                }
                if(client.Mobile){
                    updatedsuite.Client.Mobile = client.Mobile
                }
            }
            //Client LU
            //yes please snackbar!
            //you assign the ENTIRE ActiveSuiteTemplate including Assets and Templates, do NOT straight "set" here, ONLY update
            this.OperationalDB.update(updatedsuite)
        },
        GetSuiteTemplate(){
            this.SuiteTemplate = Object.assign({},this.ActiveSuiteTemplate)
            //console.log('this.SuiteTemplate',this.SuiteTemplate)
            if(this.SuiteTemplate){
                this.GetActiveSuiteTemplatePages(this.SuiteTemplate)
                //console.log('this.SuiteTemplate',this.SuiteTemplate)
                //alert('thought')
                //this.GetMarketplacetemplate()
            }
        },
    }
}
</script>

<style>

</style>



