<template>
<span>
    <v-btn @click="ActivateViewportBuilder(item)" v-if="$route.name === 'DirectoryBuilder' && !ViewPortBuilder" icon><v-icon>mdi-cogs</v-icon></v-btn>

   <v-card flat tile
            :class="StoreCard ? 'mx-auto storecard transparent' : 'mx-auto'"
            :dark="AppisDarkMode"
            max-width="600"
            :style="MiniView ? 'padding:5px;' :'padding:5px;'"
            >
        <v-img v-if="item.Photo && !IMGIsCarousel" :class="StoreCard ? 'item__overlayup black' : 'black'" 
                :src="item.Photo.ThumbURL"
                contain
                :height="ImageHeight"
                >
                <v-btn depressed style="position:absolute;top:20px;" tile color="links" dark v-if="TagField"><FieldViewerComponent :field="TagField" :Record="item"
                /></v-btn>
                </v-img>
               
                    <v-img v-if="!item.Photo && !IMGIsCarousel"
                    src="@/assets/ImageHolder.png"
                contain  :class="StoreCard ? 'item__overlayup black' : ''" 
                :height="ImageHeight"
                >
                <v-btn depressed style="position:absolute;top:20px;" tile color="links" dark v-if="TagField" ><FieldViewerComponent :field="TagField" :Record="item"
                /></v-btn>
                </v-img>
                <v-row :class="StoreCard ? 'mx-3 my-3 item__overlayup black' : 'mx-3 my-3'" v-if="IMGIsCarousel">              
                    <v-img :src="item.Photo.ThumbURL" width="60%" contain>
                <v-btn depressed style="position:absolute;top:20px;" tile color="links" dark v-if="TagField" ><FieldViewerComponent :field="TagField" :Record="item"
                /></v-btn>
                </v-img>
                <div style="width: 27%;float: right;">
                    <img v-for="img in GalleryAssets.slice(1,4)" :key="img.itemObjKey" style="width: 90%;float: right;" :src="img.ThumbURL">
                </div>
                </v-row>
                <v-card :class="StoreCard ? 'item__overlaydown' : ''" rounded elevation="4">
                <v-list dense class="transparent">
                <v-list-item :dense="MiniView" v-if="!HidePrice"  :class="MiniView ? 'links--text' : 'mediumoverline links--text'">
                    {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                </v-list-item>
                    <v-list-item :dense="MiniView" v-if="!HideTitle" :class="MiniView ? 'caption primary--text' : 'overline primary--text'">
                    {{item.Title}}
                    <!-- {{item.DisplayTitle}} -->
                </v-list-item>
                </v-list>
    
        <v-card-subtitle v-if="TaggedFields && TaggedFields.length > 0" class="caption primary white--text mx-1 my-1" >
            <span v-for="(tag,tagindex) in TaggedFields" :key="tag.itemObjKey">
                <FieldViewerComponent :field="tag" :Record="item" :DarkIcons="true"
                /><span v-if="SemiColonActive(tagindex,TaggedFields)"> || </span>
            </span>
        </v-card-subtitle>
        <v-card  flat class="transparent background mx-3 my-2" height="100" v-if="!HideDescription">
        <v-card-subtitle  class="caption mx-1" >
            <div :id="item.id+'smartdescription'">
                <span  v-html="item.Description ? smart_substr(item.Description,SmartSubtrLength) : ''">
                </span>
            </div>            
        </v-card-subtitle>
        </v-card>
            <v-card-actions class="justify-end" v-if="HasLocation && item.Location">
            <v-btn icon @click="ViewMapItem(item.Location.AddressLine1,item.Location.AddressLine2,item.Location.AddressLineCity,item.Location.AddressLinePostalCode,item.Location.AddressLineProvince,item.Location.AddressLineCountry)"><v-icon>mdi-google-maps</v-icon></v-btn>{{item.Location.Name}}
            <v-btn icon :href="'tel:'+item.Location.Telephone"><v-icon>mdi-phone</v-icon></v-btn>{{item.Location.Telephone}}
        </v-card-actions>
            <v-card-actions class="justify-center">
                <v-btn icon v-if="CurrentEntity.CategoricalListType === 'Store Products' && !CurrentEntity.HideWishlist" dark color="red" @click="AddtoWishlist(item)" small>
                  <v-icon >{{IteminWishlist(item) ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
                    </v-btn>
                    
                <v-spacer>
                </v-spacer>
                <v-btn v-if="CurrentEntity.CategoricalListType === 'Store Products' && !CurrentEntity.HideCart" dark :color="ItemCartColor(item)" @click="PushActiveProduct(item)" small>
                    <span v-if="!MiniView">
                    Add to Cart
                    </span>
                    <v-icon v-if="MiniView">
                        {{ItemCartIcon(item)}}
                    </v-icon>
                    </v-btn>
            <v-btn v-if="FeatureList && FeatureList.length > 0 && CurrentEntity.CategoricalListType !== 'Social Groups' && CurrentEntity.CategoricalListType !== 'Business Members'" dark small :disabled="OnCompare(item)" @click="AddtoCompare(item)">Add to Compare</v-btn>
            <v-btn color="warning" @click="editItem(item)" outlined dark small v-if="CurrentEntity.DataRoute && item.CanEdit"
            >Edit</v-btn>
            <v-btn v-if="!CurrentEntity.DataRoute" dark small @click="item.Path? RoutetoStorePath(item) : ''" :to="item.Path? '' : '/'+CurrentEntity.SingleName.split(' ').join('')+'-Classified/'+item.Combined_Title">View</v-btn>
            
        </v-card-actions>
                </v-card>
    </v-card>
</span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';

export default {
    props: ['AppisDarkMode','item','ViewPortBuilder','HasLocation','TaggedFields','TagField','HidePrice','HideTitle','HideDescription','CurrentEntity','FeatureList','CompareItems','IMGIsCarousel','CartItems',
    'FlexXLRG','FlexLarge','FlexMedium','FlexSmall','FlexXSmall'],
    components: {
        FieldViewerComponent
    },
    data(){
        return {
            GalleryAssets: [],
            WindowWidth: 0,
            WindowHeight: 0,
            DescriptionWidth: 0,
        }   
    },
    computed:{
        FlexSize(){
            if(this.$vuetify.breakpoint.xs){
                return 'FlexXSmall'
            }
            else if(this.$vuetify.breakpoint.sm){
                return 'FlexSmall'
            }
            else if(this.$vuetify.breakpoint.md){
                return 'FlexMedium'
            }
            else if(this.$vuetify.breakpoint.lg){
                return 'FlexLarge'
            }
            else if(this.$vuetify.breakpoint.xl){
                return 'FlexXLRG'
            }
        },
        SmartSubtrLength(){
            //elmnt of 220, fits 39 char as 1 line
            var ratio = 390/39
            //ratio 10
            var y = (this.DescriptionWidth+12)/ratio
            //we need 4 lines
            return y*4
            // if(this.$vuetify.breakpoint.xs){
            //     return 55
            // }
            // else if(this.$vuetify.breakpoint.sm){
            //     return 110
            // }
            // else if(this.$vuetify.breakpoint.md){
            //     return 75
            // }
            // else if(this.$vuetify.breakpoint.lg){
            //     return 120
            // }
            // else if(this.$vuetify.breakpoint.xl){
            //     return 120
            // }
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
        DefaultBillingAccount(){
          return this.UserBillingAccounts && this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) : ''
      },
        
        WishListItems(){
            return this.DefaultBillingAccount && this.DefaultBillingAccount.WishList ? this.DefaultBillingAccount.WishList : []
        },
        
        ImageHeight(){
            if(this.MiniView){
                return 150
            }
            else{
                return 200
            }
        },
        StoreCard(){
            return this.CurrentEntity.CategoricalListType ==='Store Products' && !this.MiniView || 
            this.CurrentEntity.CategoricalListType ==='Web Apps' && !this.MiniView
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ImagesSlice(){
        if(this.$vuetify.breakpoint.xl){
          return 3
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.md){
          return 3
        }
        else {
          return 3
        }
      },
    },
    created(){
        window.onload = setTimeout(() => {
        this.ResizeWindow()
        }, 250)
         window.addEventListener("resize", this.ResizeWindow);
        if(!this.CurrentEntity.id === 'Group_Categories'){
        this.item.ClassifiedTabs.map(tab => {
            if(tab.Elements){
                tab.Elements.map(elmnt => {
                    if(elmnt.Galleryid === 'Classified_Gallery'){
                        this.GalleryAssets = this.GalleryAssets.concat(elmnt.Assets)
                    }
                })
            }
            if(tab.Galleryid === 'Classified_Gallery'){
                this.GalleryAssets = this.GalleryAssets.concat(tab.Assets)
            }
        })    
        }
        
    },
    methods:{
        editItem(item){
            this.$emit('editRecomputedItem',item)
        },
        ResizeWindow(event){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
        let elmnt = document.getElementById(this.item.id+'smartdescription')
        if(elmnt){
            this.DescriptionWidth = elmnt.offsetWidth
        }
      },
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        IteminWishlist(item){
            return item && item.id ? this.WishListItems.find(obj => obj.id === item.id) : ''
        },
        AddtoWishlist(item){
            let WishListProduct = {
                
            }
            WishListProduct.id = item.id
            WishListProduct.Photo = item.Photo.ThumbURL
            WishListProduct.IMG = item.IMG
            WishListProduct.DescriptiveText = item.DescriptiveText
            WishListProduct.Title = item.Title,
            WishListProduct.Added_On = new Date(),
            WishListProduct.StoreCollection = this.CurrentEntity.id
            //console.log(WishListProduct)
            if(this.DefaultBillingAccount){
             let WishList = []
            if(this.DefaultBillingAccount && this.DefaultBillingAccount.WishList){
                WishList = this.DefaultBillingAccount.WishList
            }
            let oncheck = WishList.find(obj => obj.id === WishListProduct.id)
            if(!oncheck){
                WishList.push(WishListProduct)
            }
            else{
                let index = WishList.indexOf(oncheck)
                WishList.splice(index,1)
            }
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.DefaultBillingAccount.id).update({
                WishList: WishList
            })   
            }
            
        },
        ItemCartColor(item){
            let boolean = this.CartItems.find(obj => obj.id === item.id)
            if(!boolean){
                return 'links'
            }
            else{
                return 'success'
            }
        },
        ItemCartIcon(item){
            let boolean = this.CartItems.find(obj => obj.id === item.id)
            if(!boolean){
                return 'mdi-cart-plus'
            }
            else{
               return 'mdi-cart-check' 
            }
        },
        RoutetoStorePath(item){
            if(this.CurrentEntity.CategoricalListType === 'Store Products' && !this.CurrentEntity.DataRoute){
              this.$router.push({ name: 'StoreItemSingle', params: {slug: this.CurrentEntity.id, id: item.id }})  
            }
            else if(this.CurrentEntity.CategoricalListType === 'Web Apps' && !this.CurrentEntity.DataRoute){
                
              this.$store.commit('setActiveSuiteid',item.id) 
              this.$router.push('/') 
            }
            else{
                if(this.$store.state.FeaturedMembersOverlay){
                    this.$store.commit('setFeaturedMembersOverlay',false)
                }
                this.$router.push(item.Path)
            }
        },
        PushActiveProduct(item){
            this.$store.commit('SetViewOrderNavbar',true)
            this.$emit('PushActiveProduct',item)
        },
        SemiColonActive(tagindex,TaggedFields){
            let hasicons = TaggedFields.filter(tag => {
                return tag.Icon
            })
            return hasicons.length === 0 && tagindex-1+2 < TaggedFields.length
        },
        GetImageColumns(array){
        let length = array.length
        let int = 0
        if(length<5){
          int = 6
          
        }
        else if(length<7){
          int = 4
        }
        else if(length<13){
          int = 4
        }
        else{
          int = 2
        }
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
            return int*2
          }
          else{
            return int
          }
        
      },
        OnCompare(item){
            //console.log(this.CompareItems)
            let match = this.CompareItems.find(obj => obj.id === item.id)
            if(!match){
                return false
            }
            else{
                return true
            }
        },
        AddtoCompare(item){
            this.$emit('AddtoCompare',item)
        },
        ActivateViewportBuilder(item){
            this.$emit('ActivateViewportBuilder',item)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
    }

}
//item__overlaydown item__overlayup
</script>

<style>
.storecard:hover .item__overlayup{
    transform: translate3d(0, 20%, 0);
    animation: fill 500ms forwards    
}
.item__overlayup {
width: 100%;
  transition: transform 500ms;
  transform: translate3d(4%, 20%, 0);
    
}
.storecard:hover .item__overlaydown{
    transform: translate3d(0, 5%, 0);
}
.item__overlaydown {
width: 100%;
  transition: transform 500ms;
  transform: translate3d(0, -10%, 0);
}
@keyframes fill {
  0% {
    background-color: rgba(#0e3876, 0);
  }
  
  
  100% {
    opacity: .7;
    border-width: 1px;
    background-color: rgba(#0e3876, 0.5);
  }
}
</style>