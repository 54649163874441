<template>
  <div>
   <v-list-item class="justify-center">
    <!-- <span :style="'--col:'+col.Color" style="text-transform: uppercase;" class="mediumoverline texbtn btnhover elevation-1">
        <v-icon class="btnhover" :style="'--col:'+col.Color" small>{{col.Icon}}</v-icon>{{col.ButtonText}}
        </span> -->
         <v-btn dark tile style="padding: 30px;" :color="col.Color">
          <v-icon dark>{{col.Icon}}</v-icon>
    {{col.ButtonText}}
   </v-btn><v-btn dark tile style="padding: 30px;" :color="col.Color">
          <v-icon dark>{{col.Icon}}</v-icon>
    {{col.ButtonText}}
   </v-btn>
   </v-list-item>
  
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



