
<template>
<div >
    <!-- style webkit only if banner style="text-align: -webkit-center;"-->
    <!-- <div :style="`content: '';position: absolute;display: block;bottom: 0em;border: 3.5em solid #ff9702;z-index: 0;left: 1.5em;border-right-width: 1.5em;border-left-color: transparent;`">
        
    </div> -->
   <v-card-title :class="tab.Justify || tab.Center" :id="tab.ElementID+'_Title'"
   :style="tab.TitleTextShadow? TitleStyle(tab)+'position: relative;text-shadow: 1px 2px 3px #373737aa;' : TitleStyle(tab)+'position: relative;'">
    <!-- <span v-if="!tab.DisplayasChip" >            
            {{tab.Title}}</span> -->
            <div style="display: flex;z-index: 2;" dense :class="tab.Justify">
                <v-avatar :tile="tab.Tile" v-if="tab.HasHeaderImage" :style="tab.ContainIMG ? 'object-fit: contain;' : 'object-fit:cover;'" :size="tab.HeaderFontSize ? tab.HeaderFontSize : 50">
                <img  v-if="tab.HasHeaderImage && tab.HeaderImage"
             
                :height="tab.HeaderFontSize ? tab.HeaderFontSize : 50"
                    :src="tab.HeaderImage"
                     :style="tab.IMGBlendMode ? 'mix-blend-mode: '+tab.IMGBlendMode+tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab) : 
                    tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)"                     
                    />
                    <img :style="tab.IMGBlendMode ? 'mix-blend-mode: '+tab.IMGBlendMode+tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab) : 
                    tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)" v-if="tab.HasHeaderImage && !tab.HeaderImage"
                :height="tab.HeaderFontSize ? tab.HeaderFontSize : 50"
                    src="@/assets/ImageHolder.png"
                    />
            </v-avatar> 
                <div :style="tab.HeaderFontSize ? 'padding-right: 10px;display: inline-flex;height: '+tab.HeaderFontSize+'px;' : 'padding-right: 10px;display: inline-flex;height: 50px;'">
                {{tab.Title}}
                </div>
               <div v-if="!$vuetify.breakpoint.xs && tab.FancyTitle !== false"  :style="tab.HeaderFontSize ? 
                'display: flex; flex-wrap: wrap;flex: 1 1 0%;align-items: center;height: '+tab.HeaderFontSize+'px;' :
                'display: flex; flex-wrap: wrap;flex: 1 1 0%;align-items: center;height: 50px;'">
                    <div v-if="tab.SubTitleTop" :style="SubtitleTopStyle(tab)">{{tab.SubTitleTop}}</div>
                    <div v-if="tab.SubTitleBottom" :style="SubtitleBTMStyle(tab)">{{tab.SubTitleBottom}}</div>
                </div>
              
            </div>
            <v-card-title v-if="$vuetify.breakpoint.xs && tab.FancyTitle !== false" :style="tab.HeaderFontSize ? 
                'align-items: center;height: 100%;z-index: 2;padding: 0px;' :
                'align-items: center;height: 100%;z-index: 2;padding: 0px;'">
                    <div v-if="tab.SubTitleTop" :style="SubtitleTopStyle(tab)">{{tab.SubTitleTop}}</div>
                    <div v-if="tab.SubTitleBottom" :style="SubtitleBTMStyle(tab)">{{tab.SubTitleBottom}}</div>
            </v-card-title>
          <i v-if="tab.Ribbon" :style="tab.RibbonInnerColor ? 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-right-color: '+tab.RibbonInnerColor.hex+';top: 54px;left: -30px;animation: edge forwards 850ms;animation-delay: 900ms;' : 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-right-color: '+tab.HeaderBGColor+';top: 54px;left: -30px;animation: edge forwards 850ms;animation-delay: 900ms;'"></i>
            <i v-if="tab.Ribbon" :style="tab.RibbonInnerColor ? 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-left-color: '+tab.RibbonInnerColor.hex+';top: 54px;right: -30px;animation: edge forwards 850ms;animation-delay: 900ms;' : 
            'transform: scaleX(0);position: absolute;z-index:1; border: 30px solid transparent;border-left-color: '+tab.HeaderBGColor+';top: 54px;right: -30px;animation: edge forwards 850ms;animation-delay: 900ms;'"></i>
            <i v-if="tab.Ribbon" :style="tab.RibbonOuterColor ? 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+tab.RibbonOuterColor.hex+';border-left-color: transparent;left: -15px;animation: edgetiplft forwards 850ms;animation-delay: 1300ms;' : 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+tab.HeaderBGColor+';border-left-color: transparent;left: -15px;animation: edgetiplft forwards 850ms;animation-delay: 1300ms;'"></i>
            <i v-if="tab.Ribbon" :style="tab.RibbonOuterColor ? 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+tab.RibbonOuterColor.hex+';border-right-color: transparent;right: -15px;animation: edgetirght forwards 850ms;animation-delay: 1300ms;' : 
            'transform: scaleX(0);position: absolute;width: 30px;top: 34px;z-index: 0;border: 40px solid '+tab.HeaderBGColor+';border-right-color: transparent;right: -15px;animation: edgetirght forwards 850ms;animation-delay: 1300ms;'"></i>
             <div v-if="tab.Ribbon" :style="'border-top-left-radius: 3px;border-top-right-radius: 3px;z-index:1;position:absolute; width:100%;height:105%;background-color: '+tab.HeaderBGColor+';animation: edge 950ms;box-shadow: 0px 3px 3px #373737aa;'">
            </div>
            <!-- lol I have to say, give the last tweo icons border radius of 150px, toggle between 149px and 150px becomes pacman :) -->
   </v-card-title>
   <!-- <div :style="`content: '';position: absolute;display: block;bottom: 0em;border: 3.5em solid #ff9702;z-index: 0;right: 1.5em;border-right-width: 1.5em;border-left-color: transparent;transform: rotate(180deg)`">
        
    </div> -->
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex'],
    components: {

        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        TitleAnimStyle(tab){
          return tab.AnimationCSS && tab.AnimationCSS.Title && tab.Animations.Title && tab.AnimationsActive ? tab.AnimationCSS.Title : ''
        },
        TitleStyle(tab){
            let style = 'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;padding-bottom: 20px;color:'+tab.HeaderColor.hex+';align-self:'+tab.Alignment+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)+'text-align:'+tab.TextAlign+';'
           //ONLY IF BANNER
           //style = style+'background: #ff9702;padding:30px;width: 90%;box-shadow: -2px 5px 5px #88888888;'
           if(tab.HeaderBGColor && !tab.Ribbon){
                style = style+'background-color: '+tab.HeaderBGColor+';'
            }
            return style+this.TitleAnimStyle(tab)
        },
        SubtitleTopStyle(tab){
            let style = 'flex: 1 1 100%;font-size: 40%;line-height: 0.3em;'
            if(this.$vuetify.breakpoint.xs){
                style = 'flex: 1 1 100%;'
            }
            if(tab.SubTitleTopInline && tab.SubTitleTopColor){
                return  'color:'+tab.SubTitleTopColor.hex+';' 
            }
            else{
              if(tab.SubTitleTopColor){
                    style = style+'color:'+tab.SubTitleTopColor.hex+';' 
                }
                if(tab.SubTitleBottom){
                    style = style+'align-self: self-end;'
                }
                else{
                    style = style+'align-self: center;'
                }
                if(tab.SubTitleTopTextAlign){
                    style = style+'text-align: '+tab.SubTitleTopTextAlign+';'
                }
                return style  
            }
            
        },
        SubtitleBTMStyle(tab){
            // text-align: center;
            let style = 'flex: 1 1 100%;font-size: 60%;line-height: 0.5em;'
            if(this.$vuetify.breakpoint.xs){
                style = 'flex: 1 1 100%;font-size: 160%;'
            }
            if(tab.SubTitleBottomColor){
                style = style+'color:'+tab.SubTitleBottomColor.hex+';'
            }
            if(tab.SubTitleBottomTextAlign){
                    style = style+'text-align: '+tab.SubTitleBottomTextAlign+';'
                }
            return style
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(tabHeaderFontFamily){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:50px;'  
               }
               else{
                return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
               }
               
           }
           else{
            return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
           }
       },

    }    
}
</script>

<style>


</style>

        

    