<template>
  <div>
     <v-card tile flat class="transparent" 
            :height="150" :width="150" tile dark>
            <v-card-title class="justify-center" style="padding-bottom: 0px;">
                <v-icon size="70">{{col.Icon}}</v-icon>
            </v-card-title>
            <v-list-item class="justify-center">
                <v-list-item-content>
                <v-list-item-title style="text-align: center;">
                    {{col.Name}}
                </v-list-item-title>
                <v-list-item-subtitle style="text-align: center;">
                    35
                </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <!-- <v-card-title class="font-weight-thin justify-center white--text" style="padding-top: 10px;text-align: center;font-size: 0.9em;">
                {{col.Name}}
            </v-card-title>
            <v-card-title>
                35
            </v-card-title> -->
            </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



