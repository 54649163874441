<template>
  <div style="margin-top:50px;" >
    <v-dialog v-model="IncompleteCabinetDialog" width="400">
      <v-card tile flat>
      <v-card-title>Incomplete Cabinets</v-card-title>
      <v-card-text>
        <v-list-item v-for="rule in IncompletedRules" :key="rule.itemObjKey">
          <v-list-item-content>
            <v-list-item-title>
              {{rule.Title}}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{rule.SubTitle}}
            </v-list-item-subtitle>
            <v-list-item-subtitle >
              <span v-html="rule.Description">
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="EditElementDialog" width="400" persistent>
      <v-card v-if="EditElementDialog" tile flat>
        <v-card-title>
          <v-text-field class="smallinput" label="DisplayName" v-model="EditedElement.DisplayName"></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-select label="Type"  @change="UpdateTypeValidation(EditedElement)" item-text="id" v-model="EditedElement.Type" :items="['Field','Gallery','Document Register']">
          </v-select>
          <v-select v-if="EditedElement.Type === 'Field'" label="PropID" item-text="id" v-model="EditedElement.PropID" :items="CurrentEntity.AllFields">
          </v-select>
          <v-select v-if="EditedElement.Type === 'Gallery'" label="PropID" item-text="id" v-model="EditedElement.PropID" :items="CurrentEntity.Galleries">
          </v-select>
          <v-select  @change="UpdateTypeValidation(EditedElement)" v-if="EditedElement.Type === 'Document Register'" label="PropID" item-text="id" v-model="EditedElement.PropID" :items="CurrentEntity.DocumentRegisters">
          </v-select>
          <v-text-field  v-if="EditedElement.Type === 'Gallery'" v-model.number="EditedElement.ValidateCount" label="Validate Count"/>
          <v-list  v-if="EditedElement.Type === 'Document Register'">
            Below you can define Mandataory entries for the Document Register
            <v-list-item v-for="rule in EditedElement.DogRegValRules" :key="rule.itemObjKey">
              <v-list-item-content>
              <v-list-item-title>
                {{rule.Name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{rule.ValidateCountProp}}
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-text-field v-model.number="rule.ValidateCount" label="Validate Count"/>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="DeactivateElementDialog(EditedStep)">Close</v-btn>
          <v-spacer>
          </v-spacer>
           <v-btn @click="ProcessElement(EditedStep,EditedElement)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <v-stepper v-model="e1" vertical>
          <template v-for="n in ProcedureSteps.length">
            <v-stepper-step :editable="CanEditBuild"
              :key="`${n}-step`"
              :step="n"
              :complete="e1 > n" class="specialstepper"             
            >
            <span>
              {{ProcedureSteps[n-1].DisplayName}}</span><br>
      <small v-if="ProcedureSteps[n-1].Caption">{{ProcedureSteps[n-1].Caption}}</small>
      <v-card class="transparent" tile flat>
                <v-card
                    color="background"
                    class="mb-12"
                    min-height="200px"
                >
                <v-btn v-if="CanEditBuild" @click="EditingMode = !EditingMode">{{EditingMode ? 'Preview Mode' : 'Editing Mode'}}</v-btn>
                <v-card-text v-if="EditingMode">
                     <v-list>
                    <v-btn @click="ActivateElementDialog(ProcedureSteps[n-1])">Add Element</v-btn>
                    <v-list-item  :draggable="true" @dragstart="stepStepdragStart(elmntindex, $event)" @dragend="stepStepdragEnd()" @dragover.prevent @drop="stepStepdragFinish(elmntindex, $event)" v-for="(elmnt,elmntindex) in ProcedureSteps[n-1].Elements" :key="elmnt.itemObjKey">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{elmnt.DisplayName}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{elmnt.Type}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon @click="ActivateElementDialog(ProcedureSteps[n-1],elmnt,elmntindex)">mdi-cogs</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-text v-if="!EditingMode">
                  
                  <v-form
                        ref="ProcessBuilderForm"
                        v-model="valid"
                        lazy-validation
                    >
                  <span  v-for="elmnt in ComputedProcedureSteps[n-1].Elements" :key="elmnt.itemObjKey">
                
                      <FieldValueEditerComponent v-if="elmnt.Type === 'Field'" :FullWidth="true" @onPhotoFileselected="onPhotoFileselected" @onPhotoUpload="onPhotoUpload"
                       @UploadFileSelect="UploadFileSelect" @UpdateEditableField="UpdateEditableField" :Record="Record" :field="elmnt.FieldObj" :CurrentEntity="CurrentEntity"
                      />
                      <DataSingleImageGallery  :EntityName="EntityName" :EntityCollectionRef="EntityCollectionRef"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="EntityDataRef"
                             @UpdateEditableField="UpdateEditableField"
                              :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                              @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex"
                              :PrimaryFieldName="CurrentEntity.Primary_Field_Name" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                              :Record="Record" v-if="elmnt.Type === 'Gallery' && !CanEditBuild && elmnt.section" :AdditionalSaveMethod="AdditionalSaveMethod"
                              :section="elmnt.section" :tab="tab" :Fields="[]" :AppisDarkMode="AppisDarkMode" :CanEdit="elmnt.section.CanEdit" :CanCreate="elmnt.section.CanCreate" :CanDelete="elmnt.section.CanDelete"
                              />
                          <span v-if="elmnt.Type === 'Gallery' && CanEditBuild">
                            The Gallery will be displayed in the Reocrd, not in preview mode
                            </span>
                        <DataSingleDocumentRegister :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
                          :Record="Record" v-if="elmnt.Type === 'Document Register' && !CanEditBuild && elmnt.section"
                          :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"
                          @OpenDialog="OpenDialog" :sectionindex="sectionindex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                          :Business_Unit="Business_Unit" :PrimaryFieldName="CurrentEntity.Primary_Field_Name" :RelatedObj="RelatedObj"
                          :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true"
                          :section="elmnt.section" :tab="tab" :Fields="elmnt.section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="elmnt.section.CanEdit" :CanCreate="elmnt.section.CanCreate" :CanDelete="elmnt.section.CanDelete"
                              />
                          <span v-if="elmnt.Type === 'Document Register' && CanEditBuild">
                            The Document Register will be displayed in the Reocrd, not in preview mode
                            </span>
                  </span>
                  </v-form>
                </v-card-text>
                </v-card>
                <v-card-actions>
                    <v-btn
                    :disabled="e1 === 1"
                    color="primary"
                    @click="e1--"
                >
                    Back
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn :disabled="CanEditBuild" text @click="ValidateForm(n)">
                    {{ProceedingText}}
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-stepper-step>
           <v-stepper-content
            :step="n"
            :key="`${n}-content`"
          >
            <v-card class="transparent" tile flat>
                <v-card
                    color="background"
                    class="mb-12"
                    min-height="200px"
                >
                <v-btn v-if="CanEditBuild" @click="EditingMode = !EditingMode">{{EditingMode ? 'Preview Mode' : 'Editing Mode'}}</v-btn>
                <v-card-text v-if="EditingMode">
                     <v-list>
                    <v-btn @click="ActivateElementDialog(ProcedureSteps[n-1])">Add Element</v-btn>
                    <v-list-item  :draggable="true" @dragstart="stepStepdragStart(elmntindex, $event)" @dragend="stepStepdragEnd()" @dragover.prevent @drop="stepStepdragFinish(elmntindex, $event)" v-for="(elmnt,elmntindex) in ProcedureSteps[n-1].Elements" :key="elmnt.itemObjKey">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{elmnt.DisplayName}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{elmnt.Type}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon @click="ActivateElementDialog(ProcedureSteps[n-1],elmnt,elmntindex)">mdi-cogs</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-text v-if="!EditingMode">
                  
                  <v-form
                        ref="ProcessBuilderForm"
                        v-model="valid"
                        lazy-validation
                    >
                  <span  v-for="elmnt in ComputedProcedureSteps[n-1].Elements" :key="elmnt.itemObjKey">
                      <FieldValueEditerComponent v-if="elmnt.Type === 'Field'" :FullWidth="true" @onPhotoFileselected="onPhotoFileselected" @onPhotoUpload="onPhotoUpload"
                       @UploadFileSelect="UploadFileSelect" @UpdateEditableField="UpdateEditableField" :Record="Record" :field="elmnt.FieldObj" :CurrentEntity="CurrentEntity"
                      />
                      <DataSingleImageGallery  :EntityName="EntityName" :EntityCollectionRef="EntityCollectionRef"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="EntityDataRef"
                             @UpdateEditableField="UpdateEditableField"
                              :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                              @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex"
                              :PrimaryFieldName="CurrentEntity.Primary_Field_Name" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                              :Record="Record" v-if="elmnt.Type === 'Gallery' && !CanEditBuild && elmnt.section" :AdditionalSaveMethod="AdditionalSaveMethod"
                              :section="elmnt.section" :tab="tab" :Fields="[]" :AppisDarkMode="AppisDarkMode" :CanEdit="elmnt.section.CanEdit" :CanCreate="elmnt.section.CanCreate" :CanDelete="elmnt.section.CanDelete"
                              />
                          <span v-if="elmnt.Type === 'Gallery' && CanEditBuild">
                            The Gallery will be displayed in the Reocrd, not in preview mode
                            </span>
                        <DataSingleDocumentRegister :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
                          :Record="Record" v-if="elmnt.Type === 'Document Register' && !CanEditBuild && elmnt.section"
                          :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"
                          @OpenDialog="OpenDialog" :sectionindex="sectionindex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                          :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                          :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true"
                          :section="elmnt.section" :tab="tab" :Fields="elmnt.section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="elmnt.section.CanEdit" :CanCreate="elmnt.section.CanCreate" :CanDelete="elmnt.section.CanDelete"
                              />
                          <span v-if="elmnt.Type === 'Document Register' && CanEditBuild">
                            The Document Register will be displayed in the Reocrd, not in preview mode
                            </span>
                  </span>
                  </v-form>
                </v-card-text>
                </v-card>
                <v-card-actions>
                    <v-btn
                    :disabled="e1 === 1"
                    color="primary"
                    @click="e1--"
                >
                    Back
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn :disabled="CanEditBuild" text @click="ValidateForm(n)">
                    {{ProceedingText}}
                </v-btn>
                </v-card-actions>
            </v-card>
          </v-stepper-content>
          </template>
          
     
      </v-stepper>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import DataSingleImageGallery from '@/components/Database/DataSingleImageGallery';
import DataSingleDocumentRegister from '@/components/Database/DataSingleDocumentRegister';

export default {
    props: ['ProcessStep','StageID','ActiveProcessDocid','ProcessStages','ActiveProcess','System','SystemEntities','ProcedureSteps','CanEditBuild','EditElementDialog','EditedStep','EditedElement','EditedElementIndex','CurrentEntity','Record','EntityCollectionRef','EntityDataRef','RelatedObj','SingleBuildTabs'],
    components: {FieldValueEditerComponent,DataSingleImageGallery,DataSingleDocumentRegister},
    data() {
        return {
          dragging: -1,
          IncompleteCabinetDialog: false,
          IncompletedRules: [],
          valid: false,

            // ProcedureSteps: [
            //     {
            //     Number: 1,
            //     Title: 'Photos on Arrival',
            //     Description: 'Loermipsum this is one'
            //     },
            //     {
            //     Number: 2,
            //     Title: 'Notes',
            //     Description: 'Loermipsum this is two'
            //     },
            //     {
            //     Number: 3,
            //     Title: 'Photos after',
            //     Description: 'Loermipsum this is threee'
            //     },
            //      {
            //     Number: 4,
            //     Title: 'Sign Off',
            //     Description: 'Loermipsum this is threee'
            //     },
            // ],
            EditingMode: false,
            ActiveStep: 1,
            e1: 1,
            steps: 2,
        }
    },
    watch: {
      steps (val) {
        if (this.e1 > val) {
          this.e1 = val
        }
      },
    },

    computed:{
      ProceedingText(){
        if(this.ProceedingUpdatesProcess){
          return 'Progress Process'
        }
        else if(this.CanProceedProcedure){
          return 'Next'
        }
        else{
          return 'Save'
        }
      },
      CanProceedProcedure(){
       return this.ComputedProcedureSteps[this.e1]
      },
      CanProceed(){
        if(this.ComputedProcedureSteps[this.e1]){
          return true
        }
        else{
          if(this.ProcessStages[this.StageID]){
            return true
          }
        }
      },
      ProceedingUpdatesProcess(){
        return !this.CanProceedProcedure ? this.ProcessStages[this.StageID] : ''
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      EntityName(){
        return this.CurrentEntity.DisplayName
      },
      BaseStorageRef(){
            return this.EntityName.split(' ').join('')+'/'
        },
      ComputedProcedureSteps(){
        return this.ProcedureSteps.map(step => {
          let stepobj = Object.assign({},step)
          stepobj.Elements = stepobj.Elements.map(elmnt => {
            if(elmnt.Type === 'Field'){
              let fieldid = elmnt.PropID === 'Primary_Field_Value' ? this.CurrentEntity.Primary_Field_Name : elmnt.PropID
              elmnt.FieldObj = Object.assign({},this.CurrentEntity.AllFields.find(obj => obj.id === fieldid))
              elmnt.FieldObj.DisplayName = elmnt.DisplayName
              elmnt.FieldObj.IsMandatory = true
            }
            else if(elmnt.Type === 'Gallery' && !this.CanEditBuild){
              console.log('this.SingleBuildTabs',this.SingleBuildTabs)
              elmnt.section = this.SingleBuildTabs.filter(tab => {
                return tab.Elements.find(obj => obj.Entityid === elmnt.PropID)
              }).map(tab => {
                return tab.Elements.find(obj => obj.Entityid === elmnt.PropID)
              })[0]
            }
            else if(elmnt.Type === 'Document Register' && !this.CanEditBuild){
              //console.log('this.SingleBuildTabs',this.SingleBuildTabs)
              elmnt.section = this.SingleBuildTabs.filter(tab => {
                return tab.Elements.find(obj => obj.Entityid === elmnt.PropID)
              }).map(tab => {
                return tab.Elements.find(obj => obj.Entityid === elmnt.PropID)
              })[0]
            }
            console.log('elmnt.FieldObj',elmnt.FieldObj,elmnt,this.CurrentEntity.AllFields)
            return elmnt
          })
          return stepobj
        })
      },
        RelatedElementProps(){
          switch (this.EditedElement && this.EditedElement.Type) {
                case 'Field': return this.CurrentEntity.AllFields
                case 'Gallery': return this.CurrentEntity.Galleries
                case 'Document Register': return this.CurrentEntity.DocumentRegisters
                default: return []
        }
        }
    },
    created(){
      console.log(this.ComputedProcedureSteps)
      if(this.ActiveProcess && this.ActiveProcess && this.ProcessStep && this.ActiveProcess[this.ProcessStep.Number+'Step']){
        this.e1 = this.ActiveProcess[this.ProcessStep.Number+'Step']
      }
    },
    methods:{
      stepStepdragStart(which, ev) {
        // ev.dataTransfer.setData('Text', this.id);
        // ev.dataTransfer.dropEffect = 'move'
        this.dragging = which;
      },

      stepStepdragEnd(ev) {
        this.dragging = -1
      },
      stepStepdragFinish(to, ev) {
        this.stepmoveItem(this.dragging, to);
        // ev.target.style.marginTop = '2px'
        // ev.target.style.marginBottom = '2px'
      },
      stepmoveItem(from, to) {
        //console.log('this.ProcedureSteps[n-1].Elements',this.ProcedureSteps[this.e1-1].Elements)
          this.ProcedureSteps[this.e1-1].Elements.splice(to, 0, this.ProcedureSteps[this.e1-1].Elements.splice(from, 1)[0]);
          this.ProcedureSteps[this.e1-1].Elements.map(step => {
            let key = this.ProcedureSteps[this.e1-1].Elements.indexOf(step)+1
            step.ID = 1000000+key
          })
      },
      onPhotoUpload(field){
        console.log(field)
        let file = field.UploadFile
        let filename = file.name
        let storepath = this.CurrentEntity.DisplayName.split(' ').join('')+'/'+this.Record.id+'/'+field.Name+'/'+filename  
        this.$emit('onPhotoUpload',filename,file,storepath,field)      
      },
      onPhotoFileselected(field,file){
        field.localURL = URL.createObjectURL(file)
        field.UploadFile = file
        // this.Record[field.Name+'localURL'] = 
        console.log(this.Record,field,file)
      },
      UpdateTypeValidation(EditedElement){
        let DocumentRegisters = this.CurrentEntity.DocumentRegisters
        console.log(EditedElement,DocumentRegisters)
        if(EditedElement.Type === 'Document Register' && EditedElement.PropID){
          let reg = DocumentRegisters.find(obj => obj.id === EditedElement.PropID)
          EditedElement.DogRegValRules = []
          reg.PrimaryOptions.map(opt => {
            EditedElement.DogRegValRules.push({ValidateCountProp: opt.ID,Name: opt.Name, ValidateCount: 0})
          })                   
        }
        else if(EditedElement.Type === 'Gallery'){
          delete EditedElement.DogRegValRules
        }
        else if(EditedElement.Type === 'Field'){
          delete EditedElement.DogRegValRules
        }
         
      },
      ValidateDocumentRegisters(array,n){
        let vm = this        
        let length = array.length      
        return new Promise(function(resolve, reject) {	
          if(length === 0){
            resolve('Checked Doc Register Empty')
          }
          else{
            array.map((docreg,docregindex) => {
              let entries = docreg.section.DataArray
              //let ruleslength = docreg.DogRegValRules.length
              docreg.DogRegValRules.map((valrule,valindex) => {
                let matchentries = entries.filter(entry => {return entry.PrimarySelectedOption.ID === valrule.ValidateCountProp})
                if(valrule.ValidateCount > matchentries.length){
                  let incompleterule = {
                    Title: docreg.section.DisplayName,
                    SubTitle: valrule.Name,
                    Description: `You need `+valrule.ValidateCount+` `+valrule.Name+` in `+docreg.section.DisplayName
                  }
                  vm.IncompletedRules.push(incompleterule)
                }
              })
              if(docregindex-1+2){
                resolve('Checked Doc Register')
              }
            })
          }     
        })
      },
      ValidateGalleries(array,n,dialogmessage){
        let vm = this
        console.log('array',array)
        let length = array.length
        return new Promise(function(resolve, reject) {	
          if(length === 0){
            resolve('Checked Galleries Empty')
          }
          else{
            array.map((galobj,galobjindex) => {
              let matchentries = galobj.section.DataArray
              if(galobj.ValidateCount > matchentries.length){
                  let incompleterule = {
                    Title: galobj.section.DisplayName,
                    Description: `You need `+galobj.ValidateCount+` image in `+galobj.section.DisplayName
                  }
                  vm.IncompletedRules.push(incompleterule)
                }
              if(galobjindex-1+2){
                resolve('Checked Galleries')
              }
            })
          }     
        })
      },
      Progress(n){
        if(this.ProceedingText === 'Next'){
          this.nextStep(n)
        }    
        else if(this.ProceedingText === 'Save'){
          this.UpdateRecord(n)
        } 
        else if(this.ProceedingText === 'Progress Process'){
          this.UpdateRecordProcess(n)
        } 
      },
      ValidateNonFieldelementTypes(n){
        let vm = this
        let step = this.ProcedureSteps[n-1]
        let docregs = step.Elements.filter(elmnt => {return elmnt.Type === 'Document Register'})
        let galobjs = step.Elements.filter(elmnt => {return elmnt.Type === 'Gallery'})
        this.IncompletedRules = []
        this.ValidateDocumentRegisters(docregs,n).then(docregresult => {
          if(docregresult){
            vm.ValidateGalleries(galobjs,n,docregresult).then(galresult => {
              if(vm.IncompletedRules.length === 0){
                 vm.Progress(n)
              }
              else{
                vm.IncompleteCabinetDialog = true
              }               
            })
          }
            
          })          
      },
      ValidateForm(n){
           if(this.$refs['ProcessBuilderForm'][n-1].validate()){
               if(this.userLoggedIn){
                 this.ValidateNonFieldelementTypes(n)                              
               }
               else{
                   this.VerifyCaptcha(n)
               }
               
           }
           else{
               alert('Please complete all Form Fields')
           }
       },
       UpdateRecordProcess(n){
         this.UpdateRecord(n)
         let stage = this.ProcessStages[this.StageID]
         console.log(stage)
         this.$emit('updateProcessStage',stage,this.ActiveProcessDocid)
       },
       UpdateRecord(n){
         let stepobject = this.ComputedProcedureSteps[n-1]
          console.log(stepobject)
          stepobject.Elements.map(elmnt => {
            if(elmnt.Type === 'Field'){
              let field = elmnt.FieldObj
               if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
                  field.FieldBreakdown.map(breakdown => {
                      this.UpdateEditableField(breakdown.Name,this.Record[breakdown.Name],'',this.Record)
                  })
              }
              else{
                this.UpdateEditableField(elmnt.PropID,this.Record[elmnt.PropID],'',elmnt.FieldObj)
              }              
            }
          })
       },
       nextStep (n) { 
          this.UpdateRecord(n)
        if (n === this.ProcedureSteps.length) {
          this.e1 = 1
        } else {
          this.e1 = n + 1
          this.$emit('UpdateProcedureStep',this.e1)
        }
      },
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean) 
          },
      ActivateMultipleFilesUploadNotification(boolean){
          this.$emit('ActivateMultipleFilesUploadNotification',boolean) 
          },
          UploadFileSelect(file,field,Record){
            this.$emit('UploadFileSelect',file,field,Record)
        },
        UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList){
          console.log(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList)
          this.$emit('UpdateEditableField',prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList)
        },
        ActivateElementDialog(step,elmnt,elmntindex){
          this.$emit('ActivateElementDialog',step,elmnt,elmntindex)
        },
        ProcessElement(step,elmnt){
          this.$emit('ProcessElement',step,elmnt)
        },
         DeactivateElementDialog(step,elmnt){
          this.$emit('DeactivateElementDialog',step,elmnt)
        },
        
    }
}
</script>

<style>
.specialstepper .v-stepper__label{
  display: block!important;;
}
</style>
