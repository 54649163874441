<template>
  <div>
    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Subscriptions for your Suite
      <v-spacer>
      </v-spacer>
       {{CurrencyFormatter(SuiteTotal,$store.state.DefaultCurrency.Currency)}}
      </v-card-title>
      <v-btn v-if="!SystemisRA && $store.state.PrimaryUserObj.IsSystemAdmin" @click="CopyRAPlugins()">
        Copy RA Plugins
      </v-btn>
       <v-card v-for="mod in InactiveModules" :key="mod.itemObjKey" elevation="0" class="siteconfigcard">
        <v-card-title class="mediumoverline">
          {{mod.Name}}  <v-icon large>{{mod.Icon}}</v-icon>
          <v-spacer>
          </v-spacer>
         <v-btn @click="PickPlugin(mod)" outlined>Activate</v-btn>
      </v-card-title>
    
       </v-card>
       <v-card v-for="mod in BaseSubscriptions" :key="mod.itemObjKey" elevation="0" class="siteconfigcard">
        <v-card-title class="mediumoverline">
          {{mod.Name}}
          <v-spacer>
          </v-spacer>
          <v-icon large>{{mod.Icon}}</v-icon>
      </v-card-title>
      <v-card-subtitle v-if="mod.Module_Category.Name === 'Module'">
          <v-btn @click="DetermineModuleName(mod)" outlined>Configure</v-btn>
      </v-card-subtitle>
      <v-card-text >
          <v-list class="transparent">
              <v-list-item>
                  <v-list-item-content>
                  {{mod.Name}}
                  </v-list-item-content>
                  <v-list-item-content>
                  {{mod.Date}}
                  </v-list-item-content>
                  <v-list-item-content>
                     {{CurrencyFormatter(mod.Price,$store.state.DefaultCurrency.Currency)}}
                  </v-list-item-content>
                  <v-list-item-actions>
                       <v-btn @click="DeactivatePlugin(mod)" v-if="mod.Active" large outlined class="BoldBuilderGradient" color="white" style="border-color: black;">Deactivate</v-btn>
                      <v-btn @click="ActivatePlugin(mod)" v-if="!mod.Active"  large outlined color="white" style="border-color: black;">Activate</v-btn>
                  </v-list-item-actions>
              </v-list-item>
              <v-list-item v-for="plug in mod.Plugins" :key="plug.itemObjKey">
                   <v-list-item-content>
                  {{plug.Name}}
                  </v-list-item-content>
                   <v-list-item-content>
                   {{plug.Date}}
                  </v-list-item-content>
                  <v-list-item-content>
                     {{CurrencyFormatter(plug.Price,$store.state.DefaultCurrency.Currency)}}
                  </v-list-item-content>
                   <v-list-item-actions>
                      <v-btn @click="DeactivatePlugin(plug)" v-if="plug.Active"  class="BoldBuilderGradient" large outlined color="white" style="border-color: black;">Deactivate</v-btn>
                      <v-btn @click="ActivatePlugin(plug)" v-if="!plug.Active"  large outlined color="white" style="border-color: black;">Activate</v-btn>
                  </v-list-item-actions>
              </v-list-item>
              <v-list-item class="mediumoverline justify-end">
                   {{CurrencyFormatter(mod.Total,$store.state.DefaultCurrency.Currency)}}
              </v-list-item>
          </v-list>
      </v-card-text>
      </v-card>
      <v-card v-for="app in AppSubscriptions" :key="app.itemObjKey" elevation="0" class="siteconfigcard">
        <v-card-title class="mediumoverline">
          {{app.Name}}<v-spacer>
          </v-spacer>
          <v-icon large>{{app.Icon}}</v-icon>
      </v-card-title>
      <v-card-text >
          <v-list class="transparent">
              <v-list-item v-for="plug in app.Plugins" :key="plug.itemObjKey">                
                   <v-list-item-content>
                  {{plug.Name}}
                  </v-list-item-content>
                   <v-list-item-content>
                   {{plug.Date}}
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <v-tooltip bottom max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon :color="plug.LevelColor" v-on="on" v-bind="attrs">{{plug.LevelIcon}}
                        </v-icon>
                    </template>
                        {{plug.LevelDescription}}
                    </v-tooltip>
                    </v-list-item-avatar>
                  <v-list-item-content>
                     {{CurrencyFormatter(plug.Price,$store.state.DefaultCurrency.Currency)}}                     
                  </v-list-item-content>                   
                   <v-list-item-actions>
                       <v-btn @click="DeactivatePlugin(plug,app)" v-if="plug.Active"  class="BoldBuilderGradient" large outlined color="white" style="border-color: black;">Deactivate</v-btn>
                      <v-btn @click="ActivatePlugin(plug,app)" v-if="!plug.Active"  large outlined color="white" style="border-color: black;">Activate</v-btn>
                  </v-list-item-actions>
              </v-list-item>  
          </v-list>
          <v-list-item class="mediumoverline justify-end">
                   {{CurrencyFormatter(app.Total,$store.state.DefaultCurrency.Currency)}}
              </v-list-item>
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
      
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
export default {
    props: ['System','SystemEntities'],
    components: {InfoSheet},
    data() {
        return {
            CustomRolesDefault: 
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
      BURolesDefault: [
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
      ],
            SystemTickets: [],
            RoadmapItems: [],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	
        }
    },	
    computed:{
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        SuiteTotal(){
            let appstotal = this.AppSubscriptions.map(plug => {
                    return plug.Total
                })
                .reduce((a, b) => a + b, 0)
            let modstotal = this.BaseSubscriptions.map(plug => {
                    return plug.Total
                })
                .reduce((a, b) => a + b, 0)
                return appstotal+modstotal
        },
        AppSubscriptions(){
            return this.AppsDataBase.map(app => {
                let appobj = Object.assign({},app)
                
                appobj.Plugins = app.Plugins.map(plug => {
                    let plugobj = Object.assign({},this.RAPlugins.find(obj => obj.id === plug.id))
                    //console.log(plug.id,plugobj,this.RAPlugins)
                    plugobj.Date = this.TimestampFormatterSTRINGDayText(new Date)
                    return plugobj
                }).map(plug => {
                    let plugobj = Object.assign({},plug)
                    plugobj.Active = app.Plugins.find(obj => obj.id === plug.id).Active
                    return plugobj
                })
                .map(plug => {
                    if(plug.Level.Name !== 'App'){
                        plug.Price = 0
                        plug.LevelIcon = 'mdi-cash-refund'
                        plug.LevelColor = 'blue'
                        plug.LevelDescription = 'No App Charge - Charged on Suite Level'
                    }
                    else{
                        plug.LevelIcon = 'mdi-cash-check'
                        plug.LevelColor = 'green'
                        plug.LevelDescription = 'App Charge as indicated'
                    }
                    return plug
                })
                appobj.Total = appobj.Plugins.map(plug => {
                    return plug.Price
                })
                .reduce((a, b) => a + b, 0)
                return appobj
            })
        },
        ModulePlugins(){
            return this.RAPlugins.filter(plug => {
                return plug.Module_Category && plug.Module_Category.Name === 'Module'
            })
        },
        InactiveModules(){
            return this.ModulePlugins.filter(plug => {
                return !this.ActivePlugins.find(obj => obj.id === plug.id)
            })
        },
        BaseSubscriptions(){
            let modules = this.ActivePlugins.filter(plug => {
                return plug.Module_Category && plug.Module_Category.Name === 'Module'
            })
            let modulenames = modules.map(mod => {
                return mod.Name
            })
            let nomodules = this.ActivePlugins.filter(plug => {
                return plug.Module_Category && plug.Module_Category.Name !== 'Module'
            }).filter(plug => {
                    return plug.Module_Category && !modulenames.includes(plug.Module_Category.Name)
                })
            return modules.map(mod => {
                let modobj = Object.assign({},mod)
                modobj.Date = this.TimestampFormatterSTRINGDayText(new Date)
                modobj.Plugins = this.ActivePlugins.filter(plug => {
                    return plug.Module_Category && plug.Module_Category.Name === modobj.Name
                }).map(plug => {
                    let plugobj = Object.assign({},plug)
                    plugobj.Date = this.TimestampFormatterSTRINGDayText(new Date)
                    return plugobj
                }) 
                 modobj.Total = 0
                 if(modobj.Active){
                     modobj.Total = modobj.Price
                 }
                return modobj
            }).concat(nomodules.map(mod => {
                let modobj = Object.assign({},mod)
                modobj.Date = this.TimestampFormatterSTRINGDayText(new Date)
                modobj.Plugins = this.ActivePlugins.filter(plug => {
                    return plug.Module_Category && plug.Module_Category.Name === modobj.Name
                }).map(plug => {
                    let plugobj = Object.assign({},plug)
                    plugobj.Date = this.TimestampFormatterSTRINGDayText(new Date)
                    return plugobj
                })             
                 modobj.Total = 0
                 if(modobj.Active){
                     modobj.Total = modobj.Price
                 }
                return modobj
            }))
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        ActivePlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && this.PluginDataBase[plug.id].Active
          }).map(plug => {
              let plugobj = Object.assign({},plug)
              plugobj.Active = this.PluginDataBase[plug.id].Active
              return plugobj
          })
      },
       UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
         ActiveDB(){
            return db
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    },
    methods:{
        PickPlugin(plugin){
          this.EmitMarketDialog(plugin)
        },
        EmitMarketDialog(SelectedPlugin,prop){
        console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
      },
        DetermineModuleName(mod){          
        this.PrepareSelectSuiteBuilderNavItem(mod.Name)
        },
        PrepareSelectSuiteBuilderNavItem(name){
            this.$store.commit('SetSocialItemInteractMethodProp',name)
            this.$store.commit('SetSocialItemInteractMethod','PrepareSelectSuiteBuilderNavItem')
        },
        CopyRAEntities(array,baseref){
            let totaltables = array.length
            array.map((tableobj,tableindex) => {
                let subcols = ['AllFields','SubCollections','EntityProcesses','DigitizedForms','DocumentRegisters','Galleries','automations']
                let finaltable = Object.assign({},tableobj)
                delete finaltable.id
                subcols.map(prop => {
                delete finaltable[prop]
                })
                let roles = tableobj.datasetsecuritystructure === 'Custom Roles' ? this.CustomRolesDefault : this.BURolesDefault
                roles.map(role => {
                    finaltable[role.Name] = []
                })
                let tableref = baseref.collection('entities').doc(tableobj.id)
                tableref.set(finaltable).then(newtable => {
                    let subcolentries = subcols.map(subcol => {
                      return tableobj[subcol]
                    }).flat()
                    let totalsubcolentries = subcolentries.length
                    let entrycounter = 0
                    subcols.map((subcol,subcolindex) => {  
                        tableobj[subcol].map(subcolentry => {
                        
                        this.AddTableSubCol(tableref,tableobj,subcol,subcolentry).then(newdoc => {
                        entrycounter++
                          if(tableindex-1+2 === totaltables && entrycounter === totalsubcolentries){
                            //DONE?
                        let text = `Added a total of `+totalsubcolentries+` subcols.`
                        let snackbarcontent = {
                        snackbartimeout: 4000,
                        snackbartext: text,
                        snackbartop: true,
                        snackbarmultiLine: true
                        }
                        this.$emit('ActivateSnackbar',true,snackbarcontent)
                            this.$emit('ActivateSnackbar',true,snackbarcontent)
                          }
                        })
                      })
                    })
                })
            })
        },
        MakeClientMaster(){
            let RAApp = firebase.apps.find(obj => obj.name === 'RapidApps')
            let RADB = RAApp.firestore()
            let raref = RADB.collection('systems').doc(this.$store.state.ActiveSuiteid)
            let clientref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
            raref.collection('apps').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach((change,i) => {
                    if (change.type === 'added') {
                    let appobj = {
                        ...change.doc.data(),
                            id: change.doc.id
                    }
                    clientref.collection('apps').doc(appobj.id).set(appobj).then(appdoc => {
                        raref.collection('apps').doc(appobj.id).collection('plugins').onSnapshot(appplugres => {
                            const applugchanges = appplugres.docChanges();
                            applugchanges.forEach((applugchange,i) => {
                                if (applugchange.type === 'added') {
                                let applugobj = {
                                    ...applugchange.doc.data(),
                                        id: applugchange.doc.id
                                }
                                clientref.collection('apps').doc(appobj.id).collection('plugins').doc(applugobj.id).set(applugobj)
                                }
                            })
                        })
                    })
                    }
                })
            })
             raref.collection('plugins').onSnapshot(plugres => {
                    const plugchanges = plugres.docChanges();
                    plugchanges.forEach((plugchange,i) => {
                        if (plugchange.type === 'added') {
                        let plugobj = {
                            ...plugchange.doc.data(),
                                id: plugchange.doc.id
                        }
                        clientref.collection('plugins').doc(plugobj.id).set(plugobj)
                        }
                    })
                })
        },
        CopyRAPlugins(){
            let length = this.RAPlugins.length
            //console.log('will MakeClientMaster')
            //this.MakeClientMaster()
            //console.log('this.$store.state.EntitiesArray',this.$store.state.EntitiesArray)
            // this.RAPlugins.map((plug,index) => {
            //     console.log('plug',plug)
            //     db.collection('plugins').doc(plug.id).set(plug).then(newdoc => {
            //         if(index-1+2 === length){
            //             let text = `Added a total of `+length+` Plugins.`
            //             let snackbarcontent = {
            //             snackbartimeout: 4000,
            //             snackbartext: text,
            //             snackbartop: true,
            //             snackbarmultiLine: true
            //             }
            //             this.$emit('ActivateSnackbar',true,snackbarcontent)
            //         }
            //     })
            // })
            //this.CopyRAEntities(this.$store.state.ConfigEntitiesArray,db.collection('Databases').doc(process.env.VUE_APP_RA_SYSTEM_ID))
            //this.CopyRAEntities(this.$store.state.EntitiesArray,db)
        },
        AddTableSubCol(tableref,tableobj,subcol,subcolentry){
        let vm = this
        return new Promise(function(resolve, reject) {
         tableref.collection(subcol).doc(subcolentry.id).set(subcolentry).then(newsubcoldoc => {
            resolve(newsubcoldoc)
          })
        })
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



