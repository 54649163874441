
<template>
<v-card flat class="transparent" width="100%" height="100%" style="padding-bottom:60px;">
<!-- <v-layout column class="createnotice-container" v-if="userisContentAdmin">
    <v-icon @click="AddSocialItemtoNotice()" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
  </v-layout> -->
<SocialSharing :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="UserRecord" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>
    <v-parallax
            height="150"
            :src="$route.name !== 'GroupForum' ? '@/assets/RABaseBG.jpeg' : GroupData.logo"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{forum.title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
            <v-card flat class="transparent" height="60">
                <v-btn color="accent" :to="CollectionRoute" v-if="$route.name !== 'GroupForum'">Back to Forums</v-btn>
                <v-btn color="accent" :to="'/Group/'+$route.params.id"  v-if="$route.name === 'GroupForum'">Back to Group</v-btn>
                 <v-btn @click="ShareBlog()">Share<v-icon>mdi-share-circle</v-icon></v-btn>
            </v-card>
<v-layout class="justify-center">
    <v-flex lg8 md10>
<v-card flat class="mx-3" width="100%" v-if="forum">
    <v-card-title class="headline justify-space-between">
        <v-list three-line class="transparent">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><v-chip v-if="forum.RelatedObj && forum.RelatedObj.collection === 'systemtickets'" :to="'/'+forum.RelatedObj.Link+'/'+forum.RelatedObj.id"><v-icon v-if="forum.RelatedObj.Icon">{{forum.RelatedObj.Icon}}</v-icon>View Ticket</v-chip></v-list-item-title>
             <v-chip-group v-if="forum.Class"><v-chip>{{forum.Class.Name}}</v-chip><v-chip v-if="forum.Category">{{forum.Category.Name}}</v-chip></v-chip-group>
            <v-list-item-title class="mediumoverline">{{forum.title}}</v-list-item-title>
           <v-switch @change="UpdateGuestsIncluded()" v-if="!$store.state.UserisGuest && System.Guests_can_Social && UserisCreator || System.Guests_can_Social && GroupData && UserisCreator" v-model="GuestsIncluded" :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
            <v-list-item-subtitle>Created by <router-link :to="'/SiteMember/'+forum.Creatorid" >{{forum.Creator.Full_Name}}</router-link></v-list-item-subtitle>
            <v-list-item-subtitle>{{forum.createdon.toDate()}}</v-list-item-subtitle>            
            </v-list-item-content>
        </v-list-item>
        </v-list>
          <v-avatar
            size="100"
          >
          <v-img style="object-fit: cover;" v-if="forum.creatorimg" :src="forum.creatorimg">
          </v-img>
          <v-img v-if="!forum.creatorimg" style="object-fit: cover;" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            id="PublishDate">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" v-if="$store.state.TemplateView && UserisTemplateOwner">Change Owner</v-btn>
            </template>
            <v-card>
              <UserLookup v-if="$store.state.TemplateView" :UsersLookupArray="TemplateUsers"  :ModelProp="'DiscussionCreator'" :RecordObj="this" @UpdateUserLookupProp="UpdateUserLookupProp"
                    :FieldLabel="'Creator'" :Rules="[$store.state.formrules.required]"
                    /> 
            </v-card>
          </v-menu>
          
        </v-card-title>
        <v-layout class="justify-center">
    <v-card flat tile width="85%" class="dbcard">
      <v-card-text v-html="forum.post">
        
      </v-card-text>
     
    </v-card>
        </v-layout>
    <v-card flat class="transparent" height="60">
            </v-card>
            <v-card-actions v-if="!$store.state.TemplateView">
                  <TaggingComponent :AppisDarkModeCard="AppisDarkModeCard" :UsercanTag="UserisCreator" :record="forum" />
                </v-card-actions>

            <v-card flat id="postwrapper" class="transparent">
                <v-card-title class="mediumoverline"><v-avatar size="50">
            <img :src="UserRecord.Profile_Photo" style="object-fit: cover;" v-if="UserRecord.Profile_Photo"/>
            <img src="@/assets/BlankProfilePic.png" style="object-fit: cover;"  v-if="!UserRecord.Profile_Photo"/>
          </v-avatar>Participate in Discussion?</v-card-title>
      <v-layout row class="justify-center my-1">
          <v-btn-toggle dark id="PostInsertButtons" style="display:none">
                <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" @change="getImage()" hidden ref="postimage">           
              <v-btn icon small @click="$refs.postimage.click()">
                <v-icon small>mdi-file-image</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="link()">mdi-link</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="addphone()">mdi-phone</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="addemail()">mdi-email</v-icon>
              </v-btn>
              <v-btn icon small onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon small>mdi-format-list-bulleted</v-icon>
              </v-btn>
        </v-btn-toggle>
    </v-layout>                        
      <v-layout row class="justify-center my-1">  
        <v-card flat :width="inputwidth"  class="transparent">                      
          <div flat contenteditable="true" class="postinputbox background"  id="postinput">
          </div> 
          <v-card-actions class="my-1">
              
            <v-spacer></v-spacer>
            <v-btn @click="CommenttoPost()" style="display:none" id="CreatePostButton" text>Post</v-btn><v-btn @click="CancelPost()"  style="display:none" id="CancelPostButton" icon ><v-icon color="red">mdi-cancel</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-card>
  <v-card flat class="transparent" height="60">
            </v-card>
    <v-layout class="justify-end"  v-for="comment in ComputedSocialComments" :key="comment.itemObjkey">
  <v-card elevation="1"  width="70%"  class="dbcard my-3 mx-10">
       
        <v-list three-line class="transparent">
            <v-list-item>
                <v-list-item-avatar>
              <img v-if="comment.creatorimg" style="object-fit: cover;"
                :src="comment.creatorimg"
                :alt="comment.creatorname"
                 :href="'/SiteMember/'+comment.Creatorid"
            >
            <img v-if="!comment.creatorimg" style="object-fit: cover;"
                src='@/assets/BlankProfilePic.png'
                :alt="comment.creatorname"
                 :href="'/SiteMember/'+comment.Creatorid"
            >
            
          </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title>{{comment.creatorname}}</v-list-item-title>
                </v-list-item-content>
               
                <v-list-item-action style="font-size:12px">
                    <span v-if="comment.createdon.toDate">{{comment.createdon}}</span>
                    <span v-else>{{comment.createdon}}</span>
                </v-list-item-action>
            
                
            </v-list-item>
            <v-list-item>
                <v-card flat class="transparent">
                    <v-card-text  v-html="comment.content">
                    </v-card-text>
                </v-card>
            </v-list-item>
            <v-list-item :id="comment.id+'replyboxbreak'"  style="display:none">
                      <!-- <v-list> -->
                        <!-- <v-list-item :id="i+'commentreply'" style="display:none"> -->
                          <v-avatar size="35" :id="comment.id+'replyinputavatar'">
                            <img v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo" style="object-fit: cover;"/>
                            <img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png"  style="object-fit: cover;"/>
                          </v-avatar><input @blur="ReplyBoxDeactivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')" class="Chipinput mx-3" type="text" @keydown.enter="ReplytoComment(comment)" placeholder="Reply Here" :id="comment.id+'replyinputchip'">
                          
                        <!-- </v-list-item>
                      </v-list>                       -->
                      </v-list-item>
                      <v-layout class="justify-end"  v-for="reply in comment.Replies" :key="reply.itemObjKey">
                      <v-list three-line class="transparent" width="80%">
                      <v-list-item class="commentblock background">
                                <v-list-item-avatar>
                            <img v-if="reply.creatorimg" style="object-fit: cover;"
                                :src="reply.creatorimg"
                                :alt="reply.creatorname"
                                :href="'/SiteMember/'+reply.Creatorid"
                            >
                            <img v-if="!reply.creatorimg" style="object-fit: cover;"
                                src='@/assets/BlankProfilePic.png'
                                :alt="reply.creatorname"
                                :href="'/SiteMember/'+reply.Creatorid"
                            >
                            
                        </v-list-item-avatar>

                                <v-list-item-content>
                                <v-list-item-title>{{reply.creatorname}}</v-list-item-title>
                                <v-list-item-subtitle v-html="reply.content"></v-list-item-subtitle>
                                </v-list-item-content>
                            
                                <v-list-item-action style="font-size:12px">
                                    <span v-if="reply.createdon.toDate">{{reply.createdon}}</span>
                                    <span v-else>{{reply.createdon}}</span>
                                </v-list-item-action>
                            
                                <v-list-item-action style="font-size:12px">
                                 
                                <v-btn icon small>
                                <v-badge dark overlap>
                                  <v-icon small color="blue" @click="LikeReply(reply)">mdi-thumb-up-outline</v-icon>
                                  <span slot="badge"> {{reply.Likes.length}} </span>
                                </v-badge>
                                
                              </v-btn>
                                </v-list-item-action>
                                <v-list-item-action style="font-size:12px">
                                   <v-btn icon small>
                                <v-badge dark overlap>
                                  <v-icon small color="blue" @click="ReplyBoxActivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')">mdi-reply</v-icon>
                                  <span slot="badge"> {{reply.Replies.length}} </span>
                                </v-badge>
                                
                              </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                                  <v-list-item :id="reply.id+'replyboxbreak'" style="display:none">
                                  <v-avatar size="35">
                                    <img v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo" style="object-fit: cover;"/>
                                    <img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png"  style="object-fit: cover;"/>
                                  </v-avatar><input @blur="ReplyBoxDeactivate(reply.id+'replyboxbreak',reply.id+'replytoreplyinputchip')" class="Chipinput mx-3" type="text"  @keydown.enter="ReplytoReply(reply,comment)"  placeholder="Reply to reply" :id="reply.id+'replytoreplyinputchip'">
                                 
                              </v-list-item>
                      </v-list>
                      </v-layout>
        </v-list>    
            <v-spacer></v-spacer>
                        <v-btn icon small>
                        <v-badge dark overlap>
                          <v-icon small color="blue" @click="LikeComment(comment)">mdi-thumb-up-outline</v-icon>
                          <span slot="badge"> {{comment.Likes.length}} </span>
                        </v-badge>
                        
                      </v-btn>
                      <v-btn icon small>
                        <v-badge dark overlap>
                          <v-icon small color="blue" @click="ReplyBoxActivate(comment.id+'replyboxbreak',comment.id+'replyinputchip')">mdi-reply</v-icon>
                          <span slot="badge"> {{comment.Replies.length}} </span>
                        </v-badge>
                        
                      </v-btn>
    </v-card>
    
    </v-layout>
    
</v-card>
    </v-flex>
</v-layout>
</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SocialSharing from '@/components/SocialNetwork/SocialSharing'
import TaggingComponent from '@/components/SocialNetwork/TaggingComponent';
import UserLookup from '@/components/General/UserLookup'
export default {
    props: ['FriendsList','System'],
    components: {
      SocialSharing,
      TaggingComponent,
      UserLookup
        },
    data() {
        return {
          GuestsIncluded: false,
          ForumCommentsCollection: '',
          ForumCommentsCollectionQuery: '',
          ForumRepliesCollection: '',
          ForumRepliesCollectionQuery: '',
          DocID: '',
          ShareItemDialog: false,
          InternalLinkType: '',
          SharedCollection: '',
          LinkComponent: '',
          LinkID: '',
          NoticeCoverIMGProp: 'image',
            GroupData: '',
            forum: '',
            DiscussionTitle: '',
            DiscussionContent: '',
            DiscussionCreator: '',
            UserisCreator: false,
            Forums: [],
          NewCommentInput: '',
          NewReplyInput: '',
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
        }
        
    },
    watch: {
      UserisCreator(value) {
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)             
            }
        },
        SocialItemInteractMethod(v){  
        if(v){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
        'postelmnt' (value){
             if (value !== null && typeof value !== "undefined") {
                 
            }
        }
    },

    created(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;      
        let col = ''
        let docid = ''
        let dbref = ''
        if(this.$store.state.TemplateView){
          col = 'samplesiteforumdiscussions'
          docid = this.$route.params.slug  
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        //this.GetForumDiscussion(db.collection(col).doc(docid),docid)
        dbref = this.TemplateRootPath.collection(col).doc(docid)
          this.DocID = this.$route.params.slug        
          this.ForumCommentsCollectionQuery = this.TemplateRootPath.collection('samplesiteforumcomments').where('relatedid','==',this.DocID)
          this.ForumCommentsCollection = this.TemplateRootPath.collection('samplesiteforumcomments')
          this.ForumRepliesCollectionQuery = this.TemplateRootPath.collection('samplesiteforumreplies').where('forumid','==',this.DocID)
          this.ForumRepliesCollection = this.TemplateRootPath.collection('samplesiteforumreplies')           
          this.InternalLinkType = this.ActiveSuiteTemplate ? 'Your-Domain/'+this.$route.params.id+'/Social-Network/Forum' : 'MarketplaceTemplate/'+this.$route.params.id+'/Forum'
          this.LinkComponent = 'MarketTemplateForumShared'
          this.SharedCollection = 'samplesiteforumdiscussions'
          this.LinkID = this.$route.params.slug 
      }
      else{
        this.IntranetViewToggle()  
        if(this.$route.name === 'GroupForum'){
          this.GetGroup()
          col = 'groupforumdiscussions'
          docid = this.$route.params.slug  
          dbref = this.ContentRootPath.collection(col).doc(docid)
          this.DocID = this.$route.params.slug        
          this.ForumCommentsCollectionQuery = this.ContentRootPath.collection('groupforumcomments').where('groupid','==',this.$route.params.id).where('relatedid','==',this.DocID)
          this.ForumCommentsCollection = this.ContentRootPath.collection('groupforumcomments')
          this.ForumRepliesCollectionQuery = this.ContentRootPath.collection('groupforumreplies').where('groupid','==',this.$route.params.id).where('forumid','==',this.DocID)
          this.ForumRepliesCollection = this.ContentRootPath.collection('groupforumreplies')           
          this.InternalLinkType = 'Group-Forum/'+this.$route.params.id+'/Forum'
          this.LinkComponent = 'GroupForumShared'
          this.SharedCollection = 'groupforumdiscussions'
          this.LinkID = this.$route.params.slug       
        }
        else{
          col = 'siteforumdiscussions'
          docid = this.$route.params.id
          dbref = this.ContentRootPath.collection(col).doc(docid)
          this.DocID = this.$route.params.id
          this.ForumCommentsCollectionQuery = this.ContentRootPath.collection('siteforumcomments').where('relatedid','==',this.DocID)
          this.ForumCommentsCollection = this.ContentRootPath.collection('siteforumcomments')
          this.ForumRepliesCollectionQuery = this.ContentRootPath.collection('siteforumreplies').where('forumid','==',this.DocID)
          this.ForumRepliesCollection = this.ContentRootPath.collection('siteforumreplies')
          this.InternalLinkType = 'Forum',
          this.LinkComponent = 'SiteForumShared'
          this.SharedCollection = 'siteforumdiscussions'
          this.LinkID = this.$route.params.id
          
        }
      }
        this.GetForumDiscussion(dbref,docid)
         //console.log(this.$route.params)
      
      
      
      
        window.onload = setTimeout(() => {
          document.addEventListener("focusin", function(e) {
            var x = document.activeElement.id
          let stylebuttons = document.getElementById('PostInsertButtons')
          let createpostbtn = document.getElementById('CreatePostButton')
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let postwrapper = document.getElementById('postwrapper')
          if(x === 'postinput'){
              stylebuttons.style.display = 'block'
              createpostbtn.style.display = 'block'
              cancelpostbtn.style.display = 'block'
              postwrapper.style.outlineStyle = 'solid'
              postwrapper.style.outlineColor = '#dee6ec'
              postwrapper.style.outlineWidth = "1px"
              document.getElementById("postinput").addEventListener("paste", function(e) {
            console.log('someone is pasting')
            e.preventDefault();
            if (e.clipboardData && e.clipboardData.getData) {
                var text = e.clipboardData.getData("text/plain");
                document.execCommand("insertHTML", false, text);
            } else if (window.clipboardData && window.clipboardData.getData) {
                var text = window.clipboardData.getData("Text");
                insertTextAtCursor(text);
            }
            });
          }
          
        });
          
          this.postelmnt = document.getElementById("postinput")          
            //console.log(this.postelmnt)
            
        
      }, 2000);
      
    },

    

    computed: {
      ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
      TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userisContentAdmin(){
        return this.userIsAdmin
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },  
      CollectionRoute(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Forums'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Forums'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Forums' : '/Group/'+this.$route.params.id
        }
      },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupForum' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Forum' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        }, 
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },    
      UserRecord(){
            return this.$store.state.userobj
          },
      ComputedSocialReplies(){
        return this.SocialReplies.map(mainreply => {
          this.SocialReplies.map(reply => {
                    if(reply.relatedid === mainreply.id){
                      let replyonmainreplycheck = mainreply.Replies.find(obj => obj.id == reply.id)
                      if(!replyonmainreplycheck){
                        mainreply.Replies.push(reply)
                      }
                    }
                  })
          return mainreply
        }).map(mainreply => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === mainreply.id){
                      let likeonmainreplycheck = mainreply.Likes.find(obj => obj.id == like.id)
                      if(!likeonmainreplycheck){
                        mainreply.Likes.push(like)
                      }
                    }
                  })
                  if(typeof mainreply.createdon.toDate !== 'undefined'){
                  mainreply.createdon = this.TimestampFormatterSTRING(mainreply.createdon.toDate())
                  }
          return mainreply
        })
      },
      ComputedSocialComments(){
        return this.SocialComments.map(comment => {
          this.ComputedSocialReplies.map(reply => {
                    if(reply.relatedid === comment.id){
                      let replyoncommentcheck = comment.Replies.find(obj => obj.id == reply.id)
                      if(!replyoncommentcheck){
                        if(reply.Replies){
                          comment.Replies = comment.Replies.concat(reply.Replies)
                        }
                        comment.Replies.push(reply)
                      }
                    }
                  })
          return comment
        }).map(comment => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === comment.id){
                      let likeoncommentcheck = comment.Likes.find(obj => obj.id == like.id)
                      if(!likeoncommentcheck){
                        comment.Likes.push(like)
                      }
                    }
                  })
                  if(typeof comment.createdon.toDate !== 'undefined'){
                  comment.createdon = this.TimestampFormatterSTRING(comment.createdon.toDate())
                  }
          return comment
        }).sort((a, b) => {
            console.log('sorting again')
            console.log(a.createdon)
            var key1 = this.DateFormatterwithTime(b.createdon);
            var key2 = this.DateFormatterwithTime(a.createdon);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },
      PostsTimeline () {
        return this.Posts.map(post => {
          this.ComputedSocialComments.map(comment => {
            if(comment.relatedid === post.id){
              let oncheck = post.Comments.find(obj => obj.id == comment.id)
                if(!oncheck){
                  post.Comments.push(comment)
                }
              }   
          })
          return post
        }).map(post => {
          this.SocialLikes.map(like => {
            if(like.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == like.id)
                if(!oncheck){
                  post.Likes.push(like)
                }
              }   
          })
          return post
        }).map(post => {
          this.ComputedSocialReplies.map(reply => {
            if(reply.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == reply.id)
                if(!oncheck){
                  post.Likes.push(reply)
                }
              }   
          })
          return post
        }).map(post => {          
          if(typeof post.createdon.toDate !== 'undefined'){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        }).slice().reverse()
      },

    },

    methods: {
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
            if(Prop === 'DiscussionCreator' && this[Prop]){
              let Creator = {Full_Name: this[Prop].Full_Name,id: this[Prop].id,Name: this[Prop].Name,Surname: this[Prop].Surname}
              let MaskedUser = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
              let dbref = ''
              let col = ''
              let docid = ''
              if(this.$store.state.TemplateView){
                col = 'samplesiteforumdiscussions'
                docid = this.$route.params.slug 
                dbref = this.TemplateRootPath.collection(col).doc(docid)
              }
              else if(this.$route.name === 'GroupForum'){
              col = 'groupforumdiscussions'
              docid = this.$route.params.slug  
              dbref = this.ContentRootPath.collection(col).doc(docid)
              }
              else{
                col = 'siteforumdiscussions'
                docid = this.$route.params.id
                dbref = this.ContentRootPath.collection(col).doc(docid)
              }
              dbref.update({
                creatorimg: Creator.Profile_Photo,
                Creator: Creator,
                Creatorid: Creator.id,
                MaskedUser: MaskedUser,
                MaskedUserid: MaskedUser.id
              })
            }
        },
      UpdateGuestsIncluded(){
        if(this.$route.name === 'GroupForum'){
          this.ContentRootPath.collection('groupforumcomments').update({
            GuestsIncluded: this.GuestsIncluded
          })
        }
        else{
          this.ContentRootPath.collection('siteforumcomments').update({
            GuestsIncluded: this.GuestsIncluded
          })
        }
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.LinkID,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this[this.NoticeCoverIMGProp]
        }      
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      DateFormatterwithTime(date){
      if(date){
        console.log(date)
        console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},
        IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
        GetGroup(){
           this.GroupData = this.ActiveGroup
        },
        GetForumDiscussion(dbref,docid){
            dbref.onSnapshot(snapshot => {
                var discussiondata = snapshot.data()
                //console.log(discussiondata)
                this.forum = discussiondata
                this.forum.id = docid
                if(discussiondata.GuestsIncluded){
                  this.GuestsIncluded = discussiondata.GuestsIncluded
                }
          
                this.DiscussionTitle = discussiondata.title,
                this.$emit('PushRecordName',discussiondata.title)
            this.DiscussionContent = discussiondata.post
            this.DiscussionCreator = discussiondata.Creator
            if(this.DiscussionCreator.id === this.UserRecord.id){
                this.UserisCreator = true
            }
            this.GetSocialComments()
            this.GetSocialReplies()
            this.GetSitePosts()
            this.GetSocialLikes()
            },
        error => {
          if(error.code === 'permission-denied'){
            if(!this.userLoggedIn){
              this.$router.push('/404')  
            }
            else{
              this.$router.push('/PermissionError')
            }            
          }
        })
        },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      ReplyBoxDeactivate(replyboxbreak,input){
        let replyboxbreakelmnt = document.getElementById(replyboxbreak)
        let inputelmnt = document.getElementById(input)
        inputelmnt.value = ''
        replyboxbreakelmnt.style.display = 'none'
      },
      ReplyBoxActivate(replyboxbreak,input){
        let replyboxbreakelmnt = document.getElementById(replyboxbreak)
        let inputelmnt = document.getElementById(input)
        replyboxbreakelmnt.style.display = ''
        inputelmnt.focus()
      },
      GetSocialLikes(){
        let dbref = ''
        if(this.$store.state.TemplateView){
          dbref = this.TemplateRootPath.collection('samplesocialsitelikes')
        }
        else{
          dbref = this.ContentRootPath.collection('socialsitelikes')
        }
          dbref.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialLikes.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        GetSocialReplies(){
          this.ForumRepliesCollectionQuery.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialReplies.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
      GetSocialComments(){
          this.ForumCommentsCollectionQuery.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SocialComments.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        GetSitePosts(){
          this.Posts  [this.forum]
        },
        CancelPostActive(){
          var x = document.activeElement.id      
          let postwrapper = document.getElementById('postwrapper')  
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let createpostbtn = document.getElementById('CreatePostButton')
          let stylebuttons = document.getElementById('PostInsertButtons')     
              cancelpostbtn.style.display = 'none'
              createpostbtn.style.display = 'none'
              stylebuttons.style.display = 'none'
              postwrapper.style.outlineStyle = 'none'
              postwrapper.style.outlineColor = 'black'
              postwrapper.style.outlineWidth = "0px"
      },
      getImage() {
        var canvas=document.createElement("canvas"); 
        var ctx=canvas.getContext("2d");
        var cw=canvas.width;
        var ch=canvas.height;
        var maxW=400;
        var maxH=400;
      let elementid = 'postinput'
  
      var file = document.querySelector("input[type=file]").files[0];
      var fileSize = file.size;
      console.log(fileSize/1000000+'mb')
      var img = new Image;
        img.onload = function() {
          var iw=img.width;
          var ih=img.height;
          var scale=Math.min((maxW/iw),(maxH/ih));
          var iwScaled=iw*scale;
          var ihScaled=ih*scale;
          canvas.width=iwScaled;
          canvas.height=ihScaled;
          
          ctx.drawImage(img,0,0,iwScaled,ihScaled);
          
      let basetext = canvas.toDataURL("image/jpeg",0.6);
        console.log(basetext)
        let NEWeditorContent = document.getElementById(elementid)
        let newimg = document.createElement("img")
        newimg.src = basetext;
        newimg.width = 400
        newimg.style.marginLeft = "auto"
        newimg.style.marginRight = "auto"    
        newimg.style.display = 'block'
        document.execCommand('insertHTML', false, '<br>');
        NEWeditorContent.appendChild(newimg);
        document.execCommand('insertHTML', false, '<br>');
        }
        img.src = URL.createObjectURL(file);
      
    },

    ProcessImage(){
      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;
          const img = document.createElement("img");          
          img.src = dataURI;
          img.width = 400
          img.style.marginLeft = "auto"
          img.style.marginRight = "auto"    
          img.style.display = 'block'    
          let NEWeditorContent = document.getElementById(elementid)
          document.execCommand('insertHTML', false, '<br>');               
          NEWeditorContent.appendChild(img);
          document.execCommand('insertHTML', false, '<br>');
          
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },
    

      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("createLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
      
      LikePost(post){
        let UserhasLiked = post.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
            relatedtype: 'Social Post',
            relatedid: post.id,
            relatedowner: post.Creatorid
        }
        // post.Likes.push(newlike)
        let likedbref = ''
        if(this.$store.state.TemplateView){
          likedbref = this.TemplateRootPath.collection('samplesocialsitelikes')
        }
        else{
          likedbref = this.ContentRootPath.collection('socialsitelikes')
        }
        likedbref.add(newlike).then(doc => {
          if(newlike.Likedbyid !== post.Creatorid){
            let path = ''
            if(this.$route.name === 'GroupForum'){
              path = '/Group-Forum/'+this.$route.params.id+'/Discussion/'+this.$route.params.slug
            }
            else{
              path = '/Forum/'+this.$route.params.id
            }
            this.PrepareNotification('Like','likes your post',path,'postid',post)
          }
        })
        }
        else{
          alert('you already liked this item')
        }
      },
      CommentReplyActivate(i,comment){
        // comment.Replying = true
        setTimeout(() => {
          let elmnt = document.getElementById(i+'commentreply')
        elmnt.style.display = 'block'
        console.log(comment)
        console.log(elmnt)
        }, 300);
        
      },
      
      ReplytoComment(comment,i){
          if(!comment.Replies){
            comment.Replies = []
          }
          let inputelmnt = document.getElementById(comment.id+'replyinputchip')
          let NewReply = 
          {
            content: inputelmnt.value,
            Likes: [],
            Shares: [],
            tags: [],
            Replies: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Comments: [],
            relatedtype: 'Social Comment',
            relatedid: comment.id,
            relatedowner: comment.Creatorid,
            forumid: this.DocID
            
          }
          
          if(this.UserRecord.Profile_Photo){
            NewReply.creatorimg = this.UserRecord.Profile_Photo
            }
            if(this.$route.name === 'GroupForum'){
              NewReply.groupid = this.$route.params.id
            }
           this.ForumRepliesCollection.add(NewReply).then(doc => {
          if(NewReply.Creatorid !== comment.Creatorid){
            let path = ''
            if(this.$route.name === 'GroupForum'){
              path = '/Group-Forum/'+this.$route.params.id+'/Discussion/'+this.$route.params.slug
            }
            else{
              path = '/Forum/'+this.$route.params.id
            }
            this.PrepareNotification('Reply','replied to your commentt',path,'commentid',comment)
          }
        })
          comment.Replying = false
          this.NewReplyInput = ''
          inputelmnt.value = ''
        },
CancelPostActive(){
          var x = document.activeElement.id      
          let postwrapper = document.getElementById('postwrapper')  
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let createpostbtn = document.getElementById('CreatePostButton')
          let stylebuttons = document.getElementById('PostInsertButtons')     
              cancelpostbtn.style.display = 'none'
              createpostbtn.style.display = 'none'
              stylebuttons.style.display = 'none'
              postwrapper.style.outlineStyle = 'none'
              postwrapper.style.outlineColor = 'black'
              postwrapper.style.outlineWidth = "0px"
      },
        CommenttoPost(){
          if(!this.forum.Comments){
            this.forum.Comments = []
          }
          let inputelmnt = document.getElementById('postinput')
          let NewComment = 
          {
            content: inputelmnt.innerHTML,
            Likes: [],
            Shares: [],
            tags: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Replies: [],
            relatedtype: 'Site Forum Discussion',
            relatedid: this.DocID,
            relatedowner: this.forum.Creatorid,
            forumid: this.DocID
            
          }
        //   this.forum.Comments.push(NewComment)
          console.log(NewComment)
          console.log(this.forum)
          if(this.UserRecord.Profile_Photo){
          NewComment.creatorimg = this.UserRecord.Profile_Photo
        }
          if(this.$route.name === 'GroupForum'){
            NewComment.groupid = this.$route.params.id
          }
          this.ForumCommentsCollection.add(NewComment).then(doc => {
          if(NewComment.Creatorid !== this.forum.Creatorid){
            let path = ''
            if(this.$route.name === 'GroupForum'){
              path = '/Group-Forum/'+this.$route.params.id+'/Discussion/'+this.$route.params.slug
            }
            else{
              path = '/Forum/'+this.$route.params.id
            }
            this.PrepareNotification('Comment','commented on your Forum Discussion',path)
          }
        })
        inputelmnt.innerHTML = ''
        this.CancelPostActive()
          this.forum.NewCommentInput = ''
          inputelmnt.value = ''
        },
      LikeReply(reply){
        console.log('reply',reply)
        let UserhasLiked = reply.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
          relatedtype: 'Social Reply',
            relatedid: reply.id,
            relatedowner: reply.Creatorid,
            forumid: this.DocID
        }
        // post.Likes.push(newlike)
        let likedbref = ''
        if(this.$store.state.TemplateView){
          likedbref = this.TemplateRootPath.collection('samplesocialsitelikes')
        }
        else{
          likedbref = this.ContentRootPath.collection('socialsitelikes')
        }
        likedbref.add(newlike).then(doc => {
          if(newlike.Likedbyid !== reply.Creatorid){
            let path = ''
            if(this.$route.name === 'GroupForum'){
              path = '/Group-Forum/'+this.$route.params.id+'/Discussion/'+this.$route.params.slug
            }
            else{
              path = '/Forum/'+this.$route.params.id
            }            
            this.PrepareNotification('Like','likes your comment',path,'replyid',reply)
          }
                
        })
        }
        else{
          alert('you already liked this item')
        }
      },
      ReplytoReply(reply,comment){
        if(!reply.Replies){
            reply.Replies = []
          }
          console.log(reply)
          let inputelmnt = document.getElementById(reply.id+'replytoreplyinputchip')
          let NewReply = 
          {
            content: inputelmnt.value,
            Replies: [],
            Likes: [],
            Shares: [],
            tags: [],
            Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            Creatorid: this.UserRecord.id,
            creatorname: this.UserRecord.Full_Name,
            createdon: new Date(),
            Comments: [],
            relatedtype: 'Forum Reply',
            relatedid: comment.id,
            relatedowner: reply.Creatorid,
            forumid: this.DocID
            
          }
          
          if(this.UserRecord.Profile_Photo){
            NewReply.creatorimg = this.UserRecord.Profile_Photo
            }
            if(this.$route.name === 'GroupForum'){
              NewReply.groupid = this.$route.params.id
            }
           this.ForumRepliesCollection.add(NewReply).then(doc => {
          if(NewReply.Creatorid !== reply.Creatorid){
            let path = ''
            if(this.$route.name === 'GroupForum'){
              path = '/Group-Forum/'+this.$route.params.id+'/Discussion/'+this.$route.params.slug
            }
            else{
              path = '/Forum/'+this.$route.params.id
            }            
            this.PrepareNotification('Reply','replied to your comment',path,'replyid',reply)
          }
        })
          reply.Replying = false
          this.NewReplyInput = ''
          inputelmnt.value = ''
      },
      PrepareNotification(type,HeaderSuffix,path,objidprop,object){
        
        let NewNotification = {
        Type: 'New '+type,
        Header: this.UserRecord.Full_Name+' '+HeaderSuffix,
        Content: 'Click to view '+type,
        CreatorFullName: this.UserRecord.Full_Name,
        CreatorID: this.UserRecord.id,
        Read: false,
        Path: path,
        Message: 'Click to view '+type,
        Date: new Date(),
        forumid: this.DocID
        //postid: this.SocialItem.id,
        }
        if(objidprop && object && object.id){
          NewNotification[objidprop] = object.id
          NewNotification.Owner = object.Creator.Full_Name
          NewNotification.Ownerid = object.Creatorid
        }
        else{
          NewNotification.Owner = this.forum.Creator.Full_Name
          NewNotification.Ownerid = this.forum.Creatorid
        }
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {

        })
      },
      LikeComment(comment){
        let UserhasLiked = comment.Likes.find(obj => obj.Likedbyid === this.UserRecord.id)
        if(!UserhasLiked){
        let newlike = {
          Likedby: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Likedbyid: this.UserRecord.id,
          createdon: new Date(),
          relatedtype: 'Social Comment',
            relatedid: comment.id,
            relatedowner: comment.Creatorid,
                    forumid: this.DocID
        }
        // post.Likes.push(newlike)
         let likedbref = ''
        if(this.$store.state.TemplateView){
          likedbref = this.TemplateRootPath.collection('samplesocialsitelikes')
        }
        else{
          likedbref = this.ContentRootPath.collection('socialsitelikes')
        }
        likedbref.add(newlike).then(doc => {
          if(newlike.Likedbyid !== comment.Creatorid){
            let path = ''
            if(this.$route.name === 'GroupForum'){
              path = '/Group-Forum/'+this.$route.params.id+'/Discussion/'+this.$route.params.slug
            }
            else{
              path = '/Forum/'+this.$route.params.id
            }
            this.PrepareNotification('Like','likes your comment',path,'commentid',comment)
          }
        })
        }
        else{
          alert('you already liked this item')
        }
      },
      CancelPost(){
        this.postelmnt.innerHTML = ''
        this.CancelPostActive()
      },
      CreatePost(){
        // let postelmnt = document.getElementById('postinput')
        let postinput = this.postelmnt.innerHTML
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          creatorname: this.UserRecord.Full_Name,
          post: postinput,
          createdon: new Date(),
          Comments: []
        }
        if(this.UserRecord.Profile_Photo){
          NewPost.creatorimg = this.UserRecord.Profile_Photo
        }
        this.ContentRootPath.collection('socialsiteposts').add(NewPost)
        console.log(NewPost.post)
        this.CancelPost()
      },
    },
}

</script>

<style>
.postinputbox{
  outline: rgb(111, 204, 247);
  outline-width: 1px;
  outline-style: solid;
  background-color: white;
  min-height: 80px;
}
.postoutline {
  outline: 1px solid #dedee9;
  background-color: grey;
}
.postoutline:focus {
  outline: 1px solid #dedee9;
  background-color: white;
}
.Chipinput{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.commentblock {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #f0f2f5
}
.commentpost{
  background-color: #87B6E6  
}
.greyBG{
  background-color: #f0f2f5;
}
</style>
    