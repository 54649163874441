<template>
  <div>
    <v-card width="350" v-if="PhotoGalleryUploadsDialog"
          :style="MiniView ? 'position: fixed;z-index: 400;bottom:15px !important;right: 0px !important;' : 'position: fixed;z-index: 400;bottom:15px !important;right: 25px !important;'">
              <v-card-title class="blue-grey darken-2 white--text subtleoverline">
                {{UploadingPhotoGalleryItems ? 'Uploaded ' : 'Proceed '}} {{CompletedUploadFiles.length}}/{{ComputedUploadFiles.length}} Files {{UploadingPhotoGalleryItems? '' : '?'}}
                <v-spacer>
                </v-spacer>
                <v-btn v-if="UploadingPhotoGalleryItems" dark fab icon :disabled="CompletedUploadFiles.length<ComputedUploadFiles.length" @click="ClosePhotoGalleryUploadDialog()">
                <v-icon color="red">mdi-close</v-icon>
                </v-btn>
                <v-btn v-if="!UploadingPhotoGalleryItems" dark fab icon @click="UploadLibraryAssets()">
                <v-icon color="success">mdi-content-save</v-icon>
                </v-btn>
                <v-btn v-if="!UploadingPhotoGalleryItems" dark fab icon @click="CancelUploadLibraryAssets(),ClosePhotoGalleryUploadDialog()">
                <v-icon color="red">mdi-cancel</v-icon>
                </v-btn>
              </v-card-title>
               <v-menu
               :nudge-top="40"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px" 
                >
                    <template v-slot:activator="{ on }">
                        <v-card-subtitle v-on="on" v-if="IllegalFiles.length > 0" class="blue-grey darken-2 white--text subtleoverline">
                            {{IllegalFiles.length}} Files will not be uploaded.
                        </v-card-subtitle>
                    </template>
                        <v-virtual-scroll 
                    style="overflow-x: hidden;padding:4px;"
                        class="transparent"
                            :items="IllegalFiles"
                            :item-height="35"
                            :height="IllegalFiles.length > 3 ? 200 : IllegalFiles.length*65"
                        >
                        <template v-slot:default="{ item }">
                        <v-list>
                        <v-list-item >
                        <v-list-item-avatar rounded size="25">
                        <v-icon x-large color="red">
                            mdi-cancel</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                                <v-list-item-title>
                                 ...{{item.name.substr(item.name.length-10,item.name.length)}}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="grey--text">
                            {{item.MBSize}}.MB
                            </v-list-item-action>
                            <v-list-item-avatar v-if="item.Uploaded" color="success" size="20">
                            <v-icon x-small dark color="white">
                                mdi-check</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-avatar v-if="!item.Uploaded" size="20">
                            <v-progress-circular color="blue" :indeterminate="UploadingPhotoGalleryItems">
                            </v-progress-circular>
                            </v-list-item-avatar>
                        </v-list-item>
                        </v-list>
                        </template>
                    </v-virtual-scroll>
                </v-menu>
              <v-card-text>
                <v-list dense class="transparent">
                  <v-virtual-scroll 
                  style="overflow-x: hidden;padding:4px;"
                      class="transparent"
                        :items="ComputedUploadFiles"
                        :item-height="35"
                        :height="200"
                      >
                      <template v-slot:default="{ item }">
                      <v-list-item >
                      <v-list-item-avatar rounded size="25">
                        <v-img :src="item.url"/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                                ...{{item.name.substr(item.name.length-10,item.name.length)}}
                              </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="grey--text">
                          {{item.MBSize}}.MB
                        </v-list-item-action>
                        <v-list-item-avatar v-if="item.Uploaded" color="success" size="20">
                          <v-icon x-small dark color="white">
                            mdi-check</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-if="!item.Uploaded" size="20">
                         <v-progress-circular color="blue" :indeterminate="UploadingPhotoGalleryItems">
                         </v-progress-circular>
                        </v-list-item-avatar>
                      </v-list-item>
                      </template>
                  </v-virtual-scroll>
                </v-list>
              </v-card-text>
            </v-card>
     <v-dialog v-model="GalleryDialog" :fullscreen="$vuetify.breakpoint.xs" :width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '100%' : '60%'">
         <v-card-title :style="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'position: fixed;width: 100%;z-index:2;' : 'position: fixed;width: 60%;z-index:2;'" class="recordtoolbar white--text elevation-4" >
         Choose an item <v-spacer>
        </v-spacer>
          <v-icon class="soloactionicon" @click="ToggleGalleryDialog()" dark color="red">mdi-close</v-icon>
        </v-card-title>
         <v-card-title v-if="AssetsTab !== 2" style="margin-top:65px!important;" height="100%" class="white">
              <v-chip v-if="!MiniView" @click="UploadingGalleryAssets = !UploadingGalleryAssets" dark class="mx-3 blue white--text">
                <v-icon dark>mdi-plus</v-icon>
                Upload Media
              </v-chip>
              <input type="file" name="foo" @change="PreviewAddingLibraryAssets($event)" multiple :accept="AllAllowedFileTypes">
              </v-card-title>
              <v-card-text  v-if="UploadingGalleryAssets">
                <v-card v-if="UploadFileListFiles.length === 0" flat width="60%" @dragover.prevent @drop.prevent="PreviewAddingLibraryAssets($event, FilterType)" id="drop-area">
                    <v-layout class="justify-center">
                      <v-card-title class="grey--text">
                          Drop any attachment files in here
                      </v-card-title>
                    </v-layout>
                  </v-card>
                  <v-row  v-if="UploadFileListFiles.length > 0">
                    <v-col
                      v-for="n in UploadFileListFiles"
                      :key="n.itemObjKey"
                      class="d-flex child-flex"
                      cols="3"
                    >
                      <v-img
                        :src="n"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
              </v-card-text>
        <v-tabs v-model="AssetsTab">
           
          <v-tab>Images
          </v-tab>
          <v-tab-item>
            <SiteImageAssetsComponent :System="System" :SystemEntities="SystemEntities"
            @ToggleGalleryDialog="ToggleGalleryDialog"
            />
      <v-card style="overflow-x: hidden;" height="100%"
          class="mx-auto" id="GalleryContainerDiv"
        >
       
        <!-- <v-card-text>      -->
            <!-- If testing required when anything acts funny UploadingGalleryAssets {{UploadingGalleryAssets}}
            UploadFileListFiles {{UploadFileListFiles && UploadFileListFiles.length > 0 ? UploadFileListFiles.length : 'None'}}
            UseProvidedAssets {{UseProvidedAssets}}
            1. GalleryAssets {{GalleryAssets && GalleryAssets.length > 0 ? GalleryAssets.length : 'None'}}
            2. EditedTab {{EditedTab ? EditedTab.Name : 'None'}}
            3. IMGProp {{IMGProp}} -->
            <!-- Not here,  -->
        <!-- </v-card-text> -->
        <!-- iphone/apple drops flex-wrap prop for "row" class...each instance of "row" needs reviewing?...or is it when row is within dialog only? -->
        
          <v-layout row class="justify-center fill-height background" :style="'flex-wrap: wrap;'+AssetDialogHeight"  v-if="UseProvidedAssets">
            <v-col :cols="12" style="padding-bottom: 0px;" v-if="SelectedImage">
              <!-- <v-list height="100%" class="transparent my-1">
              <v-chip @click="UploadingGalleryAssets = !UploadingGalleryAssets" dark class="mx-3 blue white--text">
                <v-icon dark>mdi-plus</v-icon>
                Upload Media
              </v-chip>
              <input type="file" name="foo" :accept="AllAllowedFileTypes"> -->
              <v-list class="transparent" v-if="SelectedImage">
              <v-list-item v-if="ActiveGroup">
                {{ActiveGroup.name}}
              </v-list-item>
               
              <v-list-item class="background" style="padding:0px;top: 0%;">
                <v-img height="200" contain v-if="SelectedImage" :src="SelectedImage.fileurl"
                :lazy-src="SelectedImage.ThumbURL"
                />
                </v-list-item>
                <v-list-item class="justify-center" style="padding:0px;top: 0%;">
                  {{SelectedImageDimensions}} 
                </v-list-item>
                <v-list-item class="justify-center background darken-1" @click="ForGallery && !TemplateSampleProps.includes(IMGProp) ? '' : AssignImageandCloseTab()" style="padding:0px;top: 0%;" v-if="SelectedImage && EditedTab ||
                SelectedImage && IMGProp === 'PageBGAsset' || SelectedImage && IMGProp === 'Profile_Photo' || SelectedImage && IMGProp === 'AppStylingIMG' || SelectedImage && PublicNavbarEditing
                 || SelectedImage && TemplateSampleProps.includes(IMGProp)">
                  <v-chip :large="MiniView" :style="MiniView ? 'width:20%;place-content: center;' : ''" color="warning" dark @click="ForGallery && !TemplateSampleProps.includes(IMGProp) ? SelectGalleryItem(SelectedImage) : ''">
                    Select
                  </v-chip>
                </v-list-item>
              </v-list>
              <!-- </v-list> -->
            </v-col>
            <v-col :cols="12" style="padding:40px;">
              
              <AssetsDirectory :EditedTab="EditedTab" :Assets="GalleryAssets" :Cols="ImageCols" :HoverPreview="false" @SelectImage="SelectImage" v-if="!UploadingGalleryAssets" 
              :PublicNavbarEditing="PublicNavbarEditing" :UseProvidedAssets="UseProvidedAssets" :ProvidedAssetsDBRef="ProvidedAssetsDBRef"
              />
              
              
            </v-col>
          </v-layout>
           <v-layout row class="justify-center fill-height transparent" v-if="!UseProvidedAssets"> 
                       
              <v-col cols="4" v-if="!$vuetify.breakpoint.xs">
                <v-list height="100%" class="background my-1">
                <v-chip v-if="!DocLibisRADB" @click="ToggleNewPhotoLibraryDialog('Photo Library')" dark class="mx-3 blue white--text">
                  <v-icon dark>mdi-plus</v-icon>
                  Add Library
                </v-chip>
                <v-list-item @click="ActivateLib(libindex)" v-for="(lib,libindex) in GalleryLibraries"
                :key="lib.itemObjKey">
                    {{lib.name}}
                </v-list-item>
                <v-list-item class="black" style="padding:0px;top: 20%;">
                <v-img height="200" contain v-if="SelectedImage" :src="SelectedImage.fileurl"/>
                </v-list-item>
                  <v-list-item class="justify-center" v-if="SelectedImageDimensions" style="padding:0px;top: 20%;" >
                  {{SelectedImageDimensions}}
                </v-list-item>
                <v-list-item @click="AssignImageandCloseTab()" style="padding:0px;top: 20%;" v-if="SelectedImage && EditedTab
                || SelectedImage && ForGallery 
                || SelectedImage && IMGProp === 'PageBGAsset' || SelectedImage && IMGProp === 'AppStylingIMG'">
                
                  <v-chip color="warning"  dark @click="ForGallery && !TemplateSampleProps.includes(IMGProp) ? SelectGalleryItem(SelectedImage) : ''">
                    Select
                  </v-chip>
                </v-list-item>
                
                </v-list>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xs ? 12 : 8" class="my-10">
                <PhotoLibraryViewer v-if="GalleryLibraries[activelibraryindex]"
                  :FullGallery="true"
                  :TableCollectionRef="GalleryLibraries[activelibraryindex].TableCollectionRef"
                  :TableName="GalleryLibraries[activelibraryindex].name"
                  @AssignGalleryAssets="SelectGalleryAssets" :EntityCollectionRef="GalleryLibraries[activelibraryindex].EntityCollectionRef"
                  :IMGProp="''"
                  :CurrentEntity="GalleryLibraries[activelibraryindex]"
                  :AppisDarkMode="true"
                  :tab="EditedTab" :CanDelete="true"
              />	
              </v-col>
            </v-layout>
      </v-card>
          </v-tab-item>
           <v-tab v-if="UseProvidedAssets">Documents
          </v-tab>
          <v-tab-item v-if="UseProvidedAssets">
            <!-- DocumentLibraryViewer comp no on AppNotifications, also no need for it. This is a simple list fitting  -->
            <v-list>
                <v-list-item v-for="doc in SiteDocuments" :key="doc.itemObjKey">
                    <v-list-item-avatar>
                    <v-img v-if="doc.type === 'application/pdf'" :src="require('@/assets/PDF_DOC.png')"/>
                    <v-img v-else :src="require('@/assets/access_denied.png')"/>                
                    </v-list-item-avatar>
                    <v-list-item-content>
                    <v-list-item-title>
                        ...{{doc.Name}}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="grey--text">
                    <v-btn small class="blue" @click="GetShortLivedURL(doc)"
                        fab dark >
                            <v-icon>mdi-eye
                            </v-icon>
                        </v-btn>
                </v-list-item-action>
                <v-list-item-action class="grey--text">
                    <v-btn small class="red" @click="RemoveItemConfirmation(doc)"
                        fab dark >
                            <v-icon>mdi-delete
                            </v-icon>
                        </v-btn>
                </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-tab-item>
          <v-tab v-if="UseProvidedAssets">Videos
          </v-tab>
          <v-tab-item v-if="UseProvidedAssets">
            <!-- This is a simple list of video links, with outube link verificaiton. or even other. Stores view link, and auto embed link (if no embed link provided) some config will still come -->
          </v-tab-item>
          <!-- the main value would be if the relevant content block could add to these. Fact is though, doc can be called by action button or doc table. video??? anything with writeup comp and also iframe comp -->
        </v-tabs>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';	
import SiteImageAssetsComponent from '@/components/General/SiteImageAssetsComponent';	

export default {
    props: ['System','SystemEntities','SystemPhotoLibraries'],
    components: {AssetsDirectory,PhotoLibraryViewer,SiteImageAssetsComponent},
    data() {
        return {
            TemplateSampleProps: ['SampleGrouplogo','SampleNoticeCoverIMG','SampleSiteArticleCoverIMG','SampleSiteUserPic','SampleSiteSocialNavbarIMG','SampleSiteSocialNavbarBGIMG',
            'SampleSiteBlogCoverIMG','SampleSiteClassifiedCoverIMG','SampleSiteEventCoverIMG','SitePollOptionIMG','SiteLogo',
            'SampleSiteEventCoverIMG','SampleSiteMeetupCoverIMG'],
            AssetsTab: 0,
            IllegalFiles: [],
            AllAllowedFileTypesArray: [
                'application/msword',' application/vnd.ms-excel','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/plain','application/pdf','image/*'
            ],
            GalleryDialog: false,
          activelibraryindex: 0,
            SelectedImage: '',
            SelectedImageDimensions: '',
            WindowWidth: 0,
            WindowHeight: 0,
            ForGallery: false,
            AddedAssets: [],
            UploadingPhotoGalleryItems: false,
            PhotoGalleryUploadsDialog: false,
            UploadingGalleryAssets: false,
            UploadFileListFiles: [],
            UploadFiles: [],
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        }
    },	
    computed:{
        ProvidedAssetsDBRef(){
            if(this.UseFeaturedMemberAssets){
                return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.ActiveFeaturedMember.id).collection('Assets')
            }
            else if(this.ActiveMarketTemplate && !this.ActiveSuiteTemplate){
                return db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id).collection('Assets')
            }
            else if(this.ActiveSuiteTemplate){
                return db.collection('Libraries').doc(this.ActiveSuiteTemplate.id).collection('PhotoLibraries').doc('System_Assets').collection('Assets')
            }
            else if(this.UseGroupAssets){
                return db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.ActiveGroup.id).collection('pageassets')
            }
        },
        IllegalFileNames(){
            return this.IllegalFiles.map(file => {
                return file.name
            })
        },
        AllAllowedFileTypes(){
            let val = ``
            let length = this.AllAllowedFileTypesArray.length
            this.AllAllowedFileTypesArray.map((typ,index) => {
                val = val+``+typ
                if(index-1+2 <length){
                    val=val+`,`
                }
            })
            return val
        },
        PhotoTagObjects(){
        return this.$store.state.PhotoTagObjects
      },
      UploadFilesList(){
        return this.$store.state.UploadFilesList
      },
      UploadFileStorageRef(){
        return this.$store.state.UploadFileStorageRef
      },
      UploadFilesDBStorageRef(){
        return this.$store.state.UploadFilesDBStorageRef
      },
      ComputedUploadFiles(){
        return this.UploadFiles.filter(file => {
            //let fileobj = Object.assign({},file)
            //console.log(file,this.IllegalFileNames)
            // if(fileobj.type === 'application/pdf'){
            //     fileobj.url = require('@/assets/PDF_DOC.png')
            // }
            return !file.IsIllegal
        })
      },
      CompletedUploadFiles(){
        return this.UploadFiles.filter(file => {
          return file.Uploaded === true
        })
      },
      
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      IsGroupView(){
        return this.$store.state.IsGroupView
    },
      BuilderView(){
      return this.$store.state.BuilderView
      },
      WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
      },
      DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
      },
      NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
      },
      EditedPageProperties(){
        return this.$store.state.EditedPageProperties
      },
      EditedTab(){
        return this.$store.state.EditedTab
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveMarketTemplateChild(){
            return this.$store.state.ActiveMarketTemplateChild
        },
      ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
      GalleryLibraries(){
        if(this.ForGallery){
          return this.SystemPhotoLibraries.map(lib => {
            let routes = this.$router.getRoutes()
            let libobj = Object.assign({},lib)
            let path = Object.assign({},routes.find(obj => obj.name === lib.Collection))
            libobj.meta = path.meta
            libobj.EntityCollectionRef = db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('PhotoLibraries').doc(lib.id)
            libobj.TableCollectionRef = libobj.EntityCollectionRef.collection('Assets')
            // if(lib.CustomTags){
            //   path.CustomTags
            // }
            return libobj
          })
        }
        else{
           return this.DocLibisRADB ? [
          {compname: 'SystemAssets',name: 'System Assets',TableCollectionRef: this.RADB.collection('Libraries').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('PhotoLibraries').doc('System_Assets').collection('Assets'),CustomTags: [],Assets: this.$store.state.RASystemAssets}
        ]
        : this.$store.state.GalleryLibraries
        }
       
      },
        ActiveGroup(){
            return this.$store.state.ActiveGroup
        },
        IMGProp(){
            return this.$store.state.IMGProp
        },
        EditedPageProperties(){
            return this.$store.state.EditedPageProperties
        },
        EditedTab(){
            return this.$store.state.EditedTab
        },
        ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
        DocLibisRADB(){
            return this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.Database && this.ActiveDocumentationLibrary.Database === this.RADB
        },
        ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 4
        }
        else if(this.$vuetify.breakpoint.sm){
          return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return 3
        }
        else if(this.$vuetify.breakpoint.lg){
          return 2
        }
        else if(this.$vuetify.breakpoint.xl){
          return this.GalleryAssets && this.GalleryAssets.length > 12 ? 1 : 2 
        }
      },
      ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
        GalleryAssets(){
        //we use ActiveMarketTemplate nice part of it being all assets are in one place, which is great. for installation purposes and transfer purposes yes.
        //on the other end if you use ActiveMarketTemplateChild then each page would have its own assets, later making it possible to transfer page between Templates. So just consider this?
        //However if we do
        return this.UseFeaturedMemberAssets && this.ActiveFeaturedMember ? this.ActiveFeaturedMemberGalleryAssets : 
        this.UseMarketTemplateAssets && this.ActiveMarketTemplate ? this.ActiveMarketTemplate.Assets : 
        this.UseSuiteTemplateAssets && this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.Assets : this.$store.state.GalleryAssets
      },
        
        ActiveMarketTemplateChild(){
            return this.$store.state.ActiveMarketTemplateChild
        },
        IsFeaturedMember(){
            return this.$route.name === 'FeaturedMemberEditor' || this.ActiveMarketTemplate && this.FeaturedMemberTemplate
        },
        SiteDocuments(){
            let array = []
            if(this.IsFeaturedMember){
                array = this.ActiveFeaturedMemberDocuments
            }
            return array
        },
        ActiveFeaturedMemberDocuments(){
            return this.UseFeaturedMemberAssets && this.ActiveFeaturedMember ? this.ActiveFeaturedMember.Assets.filter(asset => {
                return asset.FileType && !asset.FileType.includes('image/')
            }) : []
        },
        ActiveFeaturedMemberGalleryAssets(){
            return this.UseFeaturedMemberAssets && this.ActiveFeaturedMember ? this.ActiveFeaturedMember.Assets.filter(asset => {
                return asset.FileType && asset.FileType.includes('image/')
            }) : []
        },
        UseProvidedAssets(){
        return this.UseFeaturedMemberAssets || this.UseGroupAssets || this.UseMarketTemplateAssets || this.UseSuiteTemplateAssets
        },
        UseSuiteTemplateAssets(){
          return this.SuiteTemplateView
        },
        SuiteTemplateMain(){
          return this.$route.name === 'Suite Template Main'
        },
        SuiteTemplateWebsite(){
          return this.$route.name === 'Suite Template Website'
        },
        SuiteTemplateSocialNetwork(){
          return this.$route.name === 'Suite Template Social Network' || this.$route.name.includes('SuiteTemplate')
        },
        SuiteTemplateDatabase(){
          return this.$route.name === 'Suite Template Database App'
        },
        SuiteTemplatePreview(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        SuiteTemplateView(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        UseMarketTemplateAssets(){
            return this.$route.name.includes('MarketplaceTemplate') && this.ActiveMarketTemplateChild
        },
        FeaturedMemberTemplate(){
            return  this.$route.name === 'MyMarketplaceTemplates' && this.ActiveFeaturedMember 
            && this.ActiveMarketTemplate && this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Featured Member'
            },
        UseFeaturedMemberAssets(){
            return this.$route.name === 'FeaturedMemberEditor' && this.ActiveFeaturedMember || 
            this.FeaturedMemberTemplate
            //or you need 
        },
        UseGroupAssets(){
            return this.$route.name === 'GroupPageBuilder' && this.ActiveGroup || this.$route.name === 'GroupPageEditor' && this.ActiveGroup
        },
        AssetDialogHeight(){
        let height = this.WindowHeight*0.75
        return 'height: '+height+'px;'
      },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SelectedImage : {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue && newvalue){
                let vm = this
                const img = new Image();
                  img.onload = function() {
                    vm.SelectedImageDimensions = img.naturalWidth + 'x' + img.naturalHeight
                  }
                  img.src = newvalue.fileurl;

              }
            },deep: true
        },
        UploadFilesList  : {
            handler: function(newvalue, oldvalue) { 
              if(newvalue !== oldvalue && newvalue.length > 0){
                this.UploadFileListFiles = Array.from(Array(this.UploadFilesList.length).keys()).map(x => {
                let file = this.UploadFilesList[x]
                let tmpsrc = ''
                if(file.type === 'application/pdf'){
                    tmpsrc = require('@/assets/PDF_DOC.png')
                }
                else if(file.type.includes('image/')){
                 tmpsrc = URL.createObjectURL(this.UploadFilesList[x])  
                }                
              // console.log(this.UploadFilesList[x].File)
                    let newfile = {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        Uploaded: false,
                        MBSize: ''
                    }
                    if(this.IllegalFileNames.includes(file.name) && !file.type.includes('image/')){
                        newfile.IsIllegal = true
                    }
                    this.UploadFiles.push(newfile)
                  return tmpsrc
                })
                this.UploadFiles.map((file,fileindex) => {
                    file.url = this.UploadFileListFiles[fileindex]                
                  let mbsize = file.size/1000000
                  file.MBSize = mbsize.toFixed(2)
                })
                this.PhotoGalleryUploadsDialog = true
              }            
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ToggleGalleryDialog','UploadLibraryAssets']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        window.onload = setTimeout(() => {
        this.ResizeWindow()
        }, 250)
        window.addEventListener("resize", this.ResizeWindow);
    },
    methods:{
      ActivateLib(libindex){
        this.activelibraryindex = -1
        setTimeout(() => {
        this.activelibraryindex = libindex  
        }, 100);
        
      },
        RemoveItemConfirmation(n){
            confirm('Do you want to remove this item?') && this.ProcessRemoveItem(n)
        },
        ProcessRemoveItem(n){
            if(!this.UseProvidedAssets && this.EditedTab){
            let index = this.EditedTab.Assets.indexOf(n)
            this.EditedTab.Assets.splice(index,1)
            }
            else if(this.ProvidedAssetsDBRef){
                this.ProvidedAssetsDBRef.doc(n.id).delete()
            }
        },
        GetShortLivedURL(item){
      //console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
      ScrolltoGalleryTop(){
            var myDiv = document.getElementById('GalleryContainerDiv');
            myDiv.scrollTop = 0;
        }, 
        ClosePhotoGalleryUploadDialog(){
        this.PhotoGalleryUploadsDialog = false
        this.UploadingPhotoGalleryItems = false
        this.UploadFiles = []
        this.AssetsTab = 0
        this.CancelUploadLibraryAssets()
      },
      CancelUploadLibraryAssets(){
            this.$store.commit('setUploadFilesList',[])
            this.$store.commit('setPhotoTagObjects',[])            
            //clear these by any chance?
            // this.$store.commit('setUploadFileStorageRef','')
            // this.$store.commit('setUploadFilesDBStorageRef','')
            this.UploadFileListFiles = []
            this.UploadingGalleryAssets = false
            this.AddedAssets = []
            this.IllegalFiles = []
      },
      AssignSocialItemInteractMethod(item,SecondProp){
      //console.log('AssignSocialItemInteractMethod',item,SecondProp)
      this.$store.commit('SetSocialItemInteractMethodProp','') 
      this.$store.commit('SetSocialItemInteractMethod','')
      if(SecondProp){
        this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
      else if(item){
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
    },
      UploadLibraryAssets(){
        //IsIllegal
        //IllegalFileNames
        this.AddedAssets = []
        this.UploadingPhotoGalleryItems = true
        //this.AssignSocialItemInteractMethod('ReceiveAddedAssets',this.AddedAssets)
        //this may as well be called from store...Correct? yep
        let finallist = []
        for(var x=0;x<this.UploadFilesList.length;x++){
            let file = this.UploadFilesList[x]
             if(!this.IllegalFileNames.includes(file.name) || file.type.includes('image/')){
                finallist.push(file)
            }
        }
        let listlength = finallist.length
        finallist.map((file,index) => {
            if(file.type.includes('image/')){
                this.PreparePhotosThumbnail(file,index,listlength)
            }
            else{
                let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
                let ref = this.UploadFileStorageRef+'/'+filename
                let dbrecordref = this.UploadFilesDBStorageRef
                this.AddLibraryAssetsSingle(file,index,listlength,'',ref,dbrecordref)
            }
        })
        //  Array.from(Array(this.UploadFilesList.length).keys()).map(x => {
        //   this.PreparePhotosThumbnail(this.UploadFilesList[x],x,this.UploadFilesList.length)
        // })
      },
      PreparePhotosThumbnail(file,index,listlength){
        let vm = this
        this.PhotoGalleryUploadsDialog = true
        //this.$emit('ActivateProcessing',true)
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
     
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 150
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            let ref = vm.UploadFileStorageRef+'/'+filename
            let dbrecordref = vm.UploadFilesDBStorageRef
            
            // var storageRef = firebase.storage().ref(ref)
            var thumbstorageRef = firebase.storage().ref(ref+'_thumb')
            //console.log(vm.UploadFilesList,vm.UploadFileStorageRef,vm.UploadFilesDBStorageRef,thumbstorageRef,ref)
                document.body.removeChild(img)
                var uploadTask = thumbstorageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      // console.log(thumburl)
                      vm.AddLibraryAssetsSingle(file,index,listlength,thumburl,ref,dbrecordref)                    
                    })
              })
      }
      },

      AddLibraryAssetsSingle(file,index,listlength,thumburl,ref,TableCollectionRef) {
        var storageRef = firebase.storage().ref(ref)
        var uploadTask = storageRef.put(file);
        let vm = this
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                 vm.UploadFiles[index].Uploaded = true
                 vm.$store.commit('SetAlertSound','pop_sound')
                // console.log(vm.UploadFiles)
                //ACTUALLY ONLY ADD THUMBURL IF thumburl IOTERHWISE THIS USED FOR DOCS AS WELL 
                      let newphoto = {
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Created_Byid: this.userLoggedIn.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.lastModifiedDate,
                              Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Modified_Byid: this.userLoggedIn.id,
                              Modified_On: new Date(),
                              Name: file.name,
                              path: url,
                              Path: url,
                              // DataTags: vm.PhotoTagObjects,
                              // DataTagsid: vm.PhotoTagObjectIDs,
                              StorageRef: ref
                              
                              }
                              if(thumburl){
                                newphoto.ThumbURL = thumburl
                              }
                              if(vm.PhotoTagObjects){
                                console.log(vm.PhotoTagObjects)
                                newphoto.DataTags = vm.PhotoTagObjects
                                newphoto.DataTagsid = vm.PhotoTagObjects.map(tag => {
                                  if(tag.id){
                                    return tag.id
                                  }
                                  else{
                                    return tag.ID
                                  }          
                                })
                              }
                              this.AddedAssets.push(newphoto)
                              //console.log(newphoto)
                              if(TableCollectionRef){
                                TableCollectionRef.add(newphoto).then(photo => {
                                  if(vm.ComputedUploadFiles.length === vm.CompletedUploadFiles.length){
                                    vm.CancelUploadLibraryAssets()
                                  }
                              })
                              }
                              else{
                                
                                if(vm.ComputedUploadFiles.length === vm.CompletedUploadFiles.length){
                                  //console.log('no db ref','vm.ComputedUploadFiles.length === vm.CompletedUploadFiles.length',vm.AddedAssets)
                                  //adding from a table or single, finish the adding or updating or the record how?
                                  vm.AssignSocialItemInteractMethod('ReceiveAddedAssets',vm.AddedAssets)
                                  console.log(vm.$store.state.SocialItemInteractMethod,vm.$store.state.SocialItemInteractMethodProp)
                                  setTimeout(() => {
                                   vm.CancelUploadLibraryAssets() 
                                  }, 500);
                                  
                                }
                              }
                              
                      })              
      },
        SelectGalleryItem(SelectedImage){
        //console.log(SelectedImage,this.ForGallery)
        if(this.ForGallery){
          this.$store.commit('SetSocialItemInteractMethodProp',SelectedImage)
          this.$store.commit('SetSocialItemInteractMethod','SelectSingleGalleryItem')
        }
      },
      SelectImage(tab,prop,asset,IsThis){
          this.ScrolltoGalleryTop()
          //AssignImage(tab,prop,asset,IsThis)
          this.SelectedImage = asset
          this.$store.commit('setSelectedGalleryAsset',asset)
          if(this.IMGProp !== 'PageBGAsset' && this.IMGProp !== 'AppStylingIMG' && this.IMGProp !== 'BGIMG' && !this.PublicNavbarEditing
          && this.IMGProp !== 'MaskIMG' && this.IMGProp !== 'FirstIMG' && this.IMGProp !== 'RevealIMG' && this.IMGProp !== 'Profile_Photo' &&
          !this.TemplateSampleProps.includes(this.IMGProp)){
          this.$store.commit('setIMGProp',prop)
          }
          
        },
      SelectGalleryAssets(EditedTab,asset){
         this.SelectedImage = asset 
         this.$store.commit('setSelectedGalleryAsset',asset)
         if(this.IMGProp !== 'BGIMG' && !this.TemplateSampleProps.includes(this.IMGProp)){          
         this.$store.commit('setIMGProp','LibraryAsset')
         }
        }, 
        ToggleNewPhotoLibraryDialog(type){
            this.$emit('ToggleNewPhotoLibraryDialog',type)
        },
        PreviewAddingLibraryAssets(event){
      let vm = this
      vm.IllegalFiles = []
     // console.log('vm.ActiveFeaturedMember',vm.ActiveFeaturedMember)
     //aha!!!! so over here I would like to do 2 things BEFORE I commit
     //1. Check all files against AllAllowedFileTypesArray
     //2. if any "illegal" just put "illegal" shit in there
     let filelist = event.target.files || event.dataTransfer.files
     for(var x=0;x<filelist.length;x++){
        let file = filelist[x]
        if(!this.AllAllowedFileTypesArray.includes(file.type) && !file.type.includes('image/')){
            //console.log(file,filelist)
            //filelist[x].remove()
            vm.IllegalFiles.push(file)
        }
     }
      this.$store.commit('setUploadFilesList',filelist)
      let filestorageref = ''
      let dbrecordref = ''
        if(vm.IsGroupView){
          filestorageref = 'Group/'+vm.ActiveGroup.id+'/PageAssets/'+new Date()
          dbrecordref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(vm.ActiveGroup.id).collection('pageassets')
        }
        else if(vm.UseFeaturedMemberAssets){
            filestorageref = 'UserAssets/'+vm.ActiveFeaturedMember.id+'/'+new Date()
            dbrecordref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(vm.ActiveFeaturedMember.id).collection('Assets')
        }
        else if(this.UseMarketTemplateAssets){
          filestorageref = 'UserAssets/'+vm.ActiveMarketTemplateChild.Ownerid+'/'+new Date()
          dbrecordref = db.collection('marketplacetemplates').doc(vm.$route.params.id).collection('Assets')
          //$route.params.id === ActiveMarketTemplate.id, again if we use ActiveMarketTemplateChild.id instead of route.id each page could keep assets
        }
        else if(this.UseSuiteTemplateAssets){
          filestorageref = 'UserAssets/'+vm.ActiveMarketTemplateChild.Ownerid+'/'+new Date()
          dbrecordref = db.collection('Libraries').doc(this.ActiveSuiteTemplate.id).collection('PhotoLibraries').doc('System_Assets').collection('Assets')
          //$route.params.id === ActiveMarketTemplate.id, again if we use ActiveMarketTemplateChild.id instead of route.id each page could keep assets
        }
        
        else if(vm.WebsiteBuilderView || vm.NetworkBuilderView){
          //still untrue child. we need to refer to the active gallery which we got don't we?
          filestorageref = ''
          dbrecordref = ''
          //GalleryLibraries
          //GalleryAssets
        }
        //console.log(filestorageref)
        this.$store.commit('setUploadFileStorageRef',filestorageref)
        this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
        //console.log(this.UploadFilesList,this.UploadFileStorageRef,this.UploadFilesDBStorageRef)
      },
        ResizeWindow(){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
        
      },
        AssignImageandCloseTab(){
        if(this.IMGProp === 'PageBGAsset'){
          this.$store.commit('SetSocialItemInteractMethod','UpdatePageBGAsset')
        }
        else if(this.PublicNavbarEditing || this.IsFeaturedMember && this.IMGProp === 'Profile_Photo'){
          this.$store.commit('SetSocialItemInteractMethod','AssignPublicNavbarIMG')
        }
        else if(this.IMGProp === 'SampleSiteSocialNavbarIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteSocialNavbarIMG')
        }
        else if(this.IMGProp === 'SiteLogo'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSiteLogo')
        }
        
        else if(this.IMGProp === 'SampleSiteSocialNavbarBGIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteSocialNavbarBGIMG')
        }
        else if(this.IMGProp === 'SitePollOptionIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSitePollOptionIMG')
        }
        
        else if(this.IMGProp === 'SampleGrouplogo'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleGrouplogo')
        }
        else if(this.IMGProp === 'SampleNoticeCoverIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleNoticeCoverIMG')
        }
         else if(this.IMGProp === 'SampleSiteArticleCoverIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteArticleCoverIMG')
        } 
        else if(this.IMGProp === 'SampleSiteUserPic'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteUserPic')
        }    
             
         else if(this.IMGProp === 'SampleSiteBlogCoverIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteBlogCoverIMG')
        }
         else if(this.IMGProp === 'SampleSiteClassifiedCoverIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteClassifiedCoverIMG')
        }
        else if(this.IMGProp === 'SampleSiteEventCoverIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteEventCoverIMG')
        }
        else if(this.IMGProp === 'SampleSiteMeetupCoverIMG'){
          this.$store.commit('SetSocialItemInteractMethod','AssignSampleSiteMeetupCoverIMG')
        } 
        else{
          this.$store.commit('SetSocialItemInteractMethod','AssignImageandCloseTab')
        }
        //alert('eureka '+this.SocialItemInteractMethod)
        this.ToggleGalleryDialog()
      }, 
      ToggleGalleryDialog(ForGallery){
        this.GalleryDialog = !this.GalleryDialog
        if(!this.GalleryDialog){
          this.SelectedImageDimensions = ''
          this.SelectedImage = ''
          //this.$store.commit('setSelectedGalleryAsset','')
        }
        if(ForGallery){
          this.ForGallery = true
        }
        else{
          this.ForGallery = false
        }
        if(!this.GalleryDialog){
            this.ClosePhotoGalleryUploadDialog()
        }
      },
    }
}
</script>

<style>

</style>



