<template>
<div>
   <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="onPhotoUpload(field)" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn>
   <v-list-item class="justify-center subtleoverline">
        {{field.DisplayName}}
    </v-list-item>
  <v-layout class="justify-center">
    <v-avatar v-if="!Editing && !Refreshing"
            color="grey"
            size="150" 
            tile>           
            <v-btn @click="GetShortLivedURL(Record[field.Name])" v-if="Record[field.Name]" class="blue white--text" icon small absolute top right><v-icon>mdi-eye</v-icon></v-btn>
            <img style="object-fit: cover;" v-if="localURL" :src="localURL" height="150px">
            <img style="object-fit: cover;" v-if="Record[field.Name] && !localURL" :src="Record[field.Name].ThumbURL ? Record[field.Name].ThumbURL : Record[field.Name]" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && $vuetify.theme.dark" src="@/assets/ImageHolder_dark.png" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && !$vuetify.theme.dark" src="@/assets/ImageHolder.png" height="150px">
        </v-avatar>
         <v-avatar v-if="Editing && !Refreshing"
            color="grey"
            size="150" 
            tile>
            <input
            style="display: none"
            type="file"
            @change="onPhotoFileselected($event)"
            ref="fileInputer">
            <!-- <v-btn v-if="localURL" @click="onPhotoUpload(field)" class="green white--text" icon small absolute bottom right><v-icon>mdi-content-save</v-icon></v-btn> -->
            <v-btn v-if="localURL" @click="closeUpload()" class="red white--text" icon small absolute bottom left><v-icon>mdi-close</v-icon></v-btn>
            <v-btn @click="$refs.fileInputer.click()" class="blue white--text" icon small absolute top right><v-icon>mdi-pencil</v-icon></v-btn>
           
           <img v-if="localURL" :src="localURL" height="150px">
            <img style="object-fit: cover;" v-if="Record[field.Name] && !localURL" :src="Record[field.Name].ThumbURL ? Record[field.Name].ThumbURL : Record[field.Name]" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px">
        </v-avatar>
  </v-layout>
  <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit'],
    components: {},
    data() {
        return {
          Editing: false,
          localURL: '',
          uploadfile: '',
          Refreshing: false,
        }
    },	
    computed:{
      field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.col.Field.id] ? 
        this.Record[this.col.Field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        onPhotoUpload(field){
            let payload = {
                filename: this.uploadfile.name,
                file: this.uploadfile,
                field: field
            }
            //console.log('payload',payload)
            this.$emit('onPhotoUpload',payload)
            this.closeUpload()
        },
        closeUpload(){
            this.uploadfile = ''
            this.localURL = ''
            this.Editing = false
            this.Refresh()
        },
        onPhotoFileselected(event){
        this.uploadfile = event.target.files[0];
        this.localURL = URL.createObjectURL(this.uploadfile)
        //this.Record[field.Name] = {UploadFile: file, FileName: file.name}
        //field.UploadFile = file
        // this.Record[field.Name+'localURL'] = 
        //console.log(this.Record,field,file)
        //console.log('this.localURL',this.localURL)
        this.Refresh()
        },
        Refresh(){
            //alert('yay')
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 10);
        },
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },
      GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            console.log(item)
            GetSignedInURL(item).then(result => {
            let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
            })
        },

    }
}
</script>

<style>

</style>



