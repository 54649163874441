<template>
<!-- <v-card tile flat height="100%" width="100%" class="rmgradient"> -->
<!-- <v-container > -->
    <v-row
        class="fill-height rmgradient justify-center"
    >
      <v-col style="flex-grow: 0;"   
        lg="5" xl="4"
      >
        <v-card class="white" :style="$vuetify.breakpoint.xs ? 'padding:35px;' : 'padding:70px;'" max-width="800" elevation="6">
          
            <v-card-title :style="ErrorFontSize" class="justify-center links--text">404</v-card-title>
              <v-card-title :style="NotFoundFontSize"  class="my-3 justify-center primary--text">
                  <b>Oops! Page Not Found</b>
              </v-card-title>
             
              <v-card-text :class="MessageFontSize">
                  Sorry, the page you are looking for does not exist. Go to "Home" or "Log In".
              </v-card-text>
               <v-card-subtitle v-if="!$vuetify.breakpoint.xs">
          <v-img height="100" contain src="@/assets/logo.png"/>
              </v-card-subtitle>
              <v-card-actions class="justify-center">
                 <v-btn
                        outlined
                        rounded
                        width="45%" 
                        color="primary"
                        :to="userLoggedIn ? '/MyProfile' : '/'"
                        >
                        Home
                        </v-btn>
                         <v-btn
                        outlined
                        rounded
                        width="45%" 
                        color="primary" v-if="!userLoggedIn"
                        :to="'/Login'"
                        >
                        Login
                        </v-btn>
                  <!-- <v-spacer></v-spacer>
                   <v-btn
                        dark
                        rounded
                        width="45%" 
                        color="primary"
                        :to="'/'"
                        >
                        Report Problem
                        </v-btn> -->
              </v-card-actions>
              
                  </v-card>
      </v-col>
    </v-row>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    computed:{
      MessageFontSize(){
        if(this.$vuetify.breakpoint.xs){
          return 'grey--text justify-center caption'
        }
        else{
          return 'grey--text justify-center text-h7'
        }
      },
      NotFoundFontSize(){
        if(this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl){
          return 'font-size: 2em;text-align: center;'
        }
        else if(this.$vuetify.breakpoint.md){
          return 'font-size: 2em;text-align: center;'
        }
        else if(this.$vuetify.breakpoint.sm){
          return 'font-size: 1.5em;text-align: center;'
        }
        else if(this.$vuetify.breakpoint.xs){
          return 'font-size: 1.2em;text-align: center;'
        }
      },
      ErrorFontSize(){
        if(this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl){
          return 'font-size: 8.0em;'
        }
        else if(this.$vuetify.breakpoint.md){
          return 'font-size: 7.0em;'
        }
        else if(this.$vuetify.breakpoint.sm){
          return 'font-size: 5.0em;'
        }
        else if(this.$vuetify.breakpoint.xs){
          return 'font-size: 3.0em;'
        }
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
    created(){
      // if(!this.userLoggedIn){
      //   this.$router.push('/login')
      // }
    }
    }
}
</script>

<style>

</style>