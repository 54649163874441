<template>
  <div>
     <v-tooltip bottom max-width="200">
        <template v-slot:activator="{ on, attrs }">
            <v-progress-circular v-on="on" v-bind="attrs"
            :rotate="360" @click="ActivateProgressSheet()"
            :size="80"
            :width="15"
            :indeterminate="!ComputedProgressItem" 
            :value="progressvalue"
            color="purple"
            >
            {{ComputedProgressItem ? ComputedProgressItem.StepsCompleted+'/'+ComputedProgressItem.Steps : ''}}
            </v-progress-circular>
        </template>
        <span v-html="ComputedProgressItem ? ComputedProgressItem.Toolip : 'Select an item'">
        </span>
    </v-tooltip>
    <v-bottom-sheet persistent v-model="ProgressDialog" hide-overlay>
                     <v-card width="100%" height="100%" flat tile style="background-color: rgba(255,255,255,0.9)"> 
                    <v-card-title class="justify-center background darken-1" style="padding:5px;font-weight: 300!important;">                        
                        {{ProgressStepItem ? 'Step '+(ProgressStepItem.Check)+' of '+ComputedProgressItem.Steps : ''}}
                        <svg v-if="ProgressDialog && ProgressStepItem && ProgressStepItem.Completed" style="width:40px;display: block;margin: 15px;" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle class="progressiconpath circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <polyline class="progressiconpath check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                        </svg>
                     <svg v-if="ProgressDialog && ProgressStepItem && !ProgressStepItem.Completed" style="width:40px;display: block;margin: 15px;" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                       <circle class="progressiconpath circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <line class="progressiconpath line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                        <line class="progressiconpath line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                        </svg>
                    </v-card-title>
                     <v-card-title class="progresstooltip justify-center background darken-1" style="padding:5px;font-weight: 300!important;"
                     >    
                     
                     <span v-html="ProgressStepItem ? ProgressStepItem.Toolip : 'Select an item'">
                     </span>   
                    </v-card-title>
                    <v-btn right absolute top outlined dark @click="CancelProgressSheet()" color="red" style="z-index: 2;">Cancel
                    </v-btn> 
                    <v-card-text>
                        <!-- AutoStep {{AutoStep}} -->
                        <!-- step {{step}} -->
                        <v-window v-model="MinusStep">
                        <v-window-item v-for="(child,stepi) in Steps" :key="child.itemObjKey">
                          <!-- step {{step}} stepi {{stepi}} -->
                            <v-card-text >
                            <!-- here we wimply need to let you toggle "Site Menu & Pages" -->
                                <div v-if="!child.Prop.ChildProps">
                                    <!-- 1 {{child.Prop}} -->
                                    <!-- really it's about time for a new component 
                                    by example. "image" jsut does not play well here, the new "change" verison is much better. Unless we update the old?-->
                                 

                                    <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="ActiveProgressItem[child.ParentObj]" 
                                    :field="child.Prop" :NewImageType="true"
                                :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="'Name'" :RelatedObj="''" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                    />
                                </div>
                                <div v-if="child.Prop.ChildProps">
                                    <!-- 2 {{child.Prop.ChildProps}} -->
                                    <!-- as you can see if "Array" there is no response. Fact is, not all props are editable -->
                                    <div v-for="prop in child.Prop.ChildProps" :key="prop.itemObjKey">
                                   <!-- ActiveProgressItem[child.ParentObj][prop.Name] {{ActiveProgressItem[child.ParentObj][prop.Name]}} -->
                                    <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="ActiveProgressItem[child.ParentObj]" 
                                    :field="prop" :NewImageType="true"
                                :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="'Name'" :RelatedObj="''" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                    />
                                    </div>
                                </div>
                                <!-- or 
                                One could say it's always ChildProps anyway, consdiering there is a seciton anda  seiton header it may have 1 "field" or multiple
                                Does not matter either way it's good-->

                            </v-card-text>
                        </v-window-item>
                        </v-window>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            :disabled="step === 1"
                            text
                            @click="RegressStep()"
                        >
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <!--  :disabled="step === 3" -->
                        <v-btn dark
                            :color="step === ActiveProgressItem.StepRequirements.length ? 'success' : 'blue'"
                            depressed :disabled="CurrentStepFail"
                            @click="AdvanceSetupStep()"
                        >
                            {{step === ActiveProgressItem.StepRequirements.length ? 'Save' : 'Next'}}
                        </v-btn>
                        </v-card-actions>
                     </v-card>
                </v-bottom-sheet>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
export default {
    props: ['System','SystemEntities','ActiveProgressItem','step'],
    components: {FieldValueEditerComponent},
    data() {
        return {
            ProgressDialog: false,
        }
    },	
    computed:{
        MinusStep(){
            return this.step-1
        },
        Steps(){
            return this.ActiveProgressItem.StepRequirements
        },
        ComputedStep(){
            return this.Steps[this.MinusStep]
        },
        CurrentStepFail(){
            let fail = false
            //this.AutoStep <= this.step
            return fail
        },
        AutoStep(){
            return this.ComputedProgressItem && this.ComputedProgressItem.IncompletedSteps[0] ? this.ComputedProgressItem.IncompletedSteps[0].Check : this.Steps.length
        },
        ProgressStepItem(){
            return this.ComputedProgressItem && this.ComputedProgressItem.StepRequirements && this.ComputedProgressItem.StepRequirements[this.step-1] ? 
            this.ComputedProgressItem.StepRequirements[this.step-1] : ''
        },
        ComputedProgressItem(){
            let item = Object.assign({},this.ActiveProgressItem)
            item.Steps = item.StepRequirements.length
            item.IncompletedSteps = item.StepRequirements.filter(req => {
            let incomplete = false
            //console.log('item',item)
            //ONE FINAL BAM SMACK OOPS....
            //SEQUENTIAL LOGIC...RIGHT NOW I GET "4 OUT OF 6", BUT I STILL NEED TO COMPLETE STEP 3..
            //KIND OF NEED TO "BREAK" THE LOOP once anything is incomplete, you check?
            //OR we jsut assign "Check" value of the first incomplete right? 
                //basically StepRequirements has changed from initial. 
                //1. Prop was not object now it is, reference .Name
                //2. 1 Check was considered 1 prop. Now, if prop is "Type: Object" it must reference ChildProps, same logic this means
                //2.1. Firstly ensure prop check EXCLUDES Type "Object"
                //2.2. Now, check IF Prop is Object, as all values matching, based on child prop types mind you
            //return !this[req.ParentObj] || this[req.ParentObj] && !this[req.ParentObj][req.Prop.Name] && req.Prop.Type !== 'Object'
                if(!this.ActiveProgressItem[req.ParentObj] && req.Prop.Type !== 'Object'){
                    incomplete = true
                }
                else if(this.ActiveProgressItem[req.ParentObj] && !this.ActiveProgressItem[req.ParentObj][req.Prop.Name] && req.Prop.Type !== 'Object'){
                    incomplete = true
                }
                else if(req.Prop.Type === 'Object'){
                    //console.log(req)
                    req.Prop.ChildProps.map(childprp => {
                        //very raw and undefined not checking types Array "Checking" e.g. "Length"
                        if(!this.ActiveProgressItem[req.ParentObj][childprp.Name] && !childprp.Optional){
                            // if(childprp.Type === 'Array' && childprp.Checking === 'Length'){
                            //     incomplete = true
                            // }
                            // else{
                            //  incomplete = true   
                            // }
                            incomplete = true   
                        }
                        else if(this.ActiveProgressItem[req.ParentObj][childprp.Name] && 
                        this.ActiveProgressItem[req.ParentObj][childprp.Name].length === 0 && childprp.Type === 'Array' && childprp.Checking === 'Length'&& !childprp.Optional){
                            incomplete = true
                        }
                    })
                }
            return incomplete
            })
            item.StepRequirements = item.StepRequirements.map(req => {
                let incompletecheck = item.IncompletedSteps.find(obj => obj.Check === req.Check)
                if(!incompletecheck){
                    req.Completed = true
                }
                return req
            })
            let Incomplete = item.IncompletedSteps.length 
            item.StepsCompleted = item.Steps-Incomplete     
            item.Progress = Math.floor((item.StepsCompleted/item.Steps)*Math.floor(100))
            item.Toolip = `All done`
            if(item.Progress < 100){
                //this was wrong why start with last step right?
                //item.Toolip = item.IncompletedSteps[Incomplete-1].Toolip
            item.Toolip = item.IncompletedSteps[0].Toolip
            }
            return item
        },
        progressvalue(){
        return this.ComputedProgressItem ? this.ComputedProgressItem.Progress : 0
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        AutoStep: {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    this.UpdateAutoStep(newvalue)
                    
                }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.UpdateAutoStep(this.AutoStep)
    },
    methods:{
        onPhotoUpload(payload){
            this.$emit('onPhotoUpload',payload)
        },
        onPhotoFileselected(field,file){
            this.$emit('onPhotoFileselected',field,file)
        },
        UpdateAutoStep(val){
            this.$emit('UpdateAutoStep',val)
            //console.log('UpdateAutoStep',val)
        },
        CancelProgressSheet(){
            this.ProgressDialog = false
            this.$emit('CancelProgressSheet')
        },
        ActivateProgressSheet(){
            this.ProgressDialog = true
            this.$emit('ActivateProgressSheet')
        },
        AdvanceSetupStep(){
            this.$emit('AdvanceSetupStep')
        },
        RegressStep(){
            this.$emit('RegressStep')
        }
    }
}
</script>

<style>

</style>



