<template>
  <div>
    <v-dialog v-model="SpecialElementDialog" persistent width="400">
      <v-card tile flat>
      <v-card-title>
        {{selectedSpecialElement}}
      </v-card-title>
      <v-card-text>
        
        <v-list v-if="selectedSpecialElement === 'Public Navbar'">
          <v-list-item>
            <v-switch v-model="PublicNavbarDark" label="Bar is Dark"
          />
          </v-list-item>
           <v-list-item>
          <v-select label="Type" :items="['Default','STD Bar']" v-model="PublicNavbarStyle"/>
           </v-list-item>
            <v-list-item>
           Navbar Text
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            >
            <template v-slot:activator="{ on }">
                <v-icon right v-on="on">mdi-palette-advanced</v-icon>
            </template>
            <v-color-picker v-model="PublicNavbarText"></v-color-picker>
            </v-menu>
            </v-list-item>
             <v-list-item>
           Selected Navbar Text
           <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            >
            <template v-slot:activator="{ on }">
                <v-icon right v-on="on">mdi-palette-advanced</v-icon>
            </template>
            <v-color-picker v-model="PublicNavbarSelectedText"></v-color-picker>
            </v-menu>
             </v-list-item>
             <v-list>
             <v-btn small @click="AddActionItem('PrependedPublicNavbarActions')">Add Action Item (Before)</v-btn>
             <v-list v-for="act in PrependedPublicNavbarActions" :key="act.itemObjKey">
             <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-text-field v-model="act.title" />
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field v-model="act.icon" />
                </v-list-item-subtitle>
              </v-list-item-content>
             </v-list-item>
              <v-list-item>
              <v-list-item-action>
                <v-select return-object item-text="id" clearable label="Action Buttons (Appended)"  :items="PublicNavbarActionOptions" v-model="act.Action"/>
              </v-list-item-action>
             </v-list-item>
             </v-list>
             </v-list>
             <v-list>
             <v-btn small  @click="AddActionItem('AppendedPublicNavbarActions')">Add Action Item (After)</v-btn>
              <v-list v-for="act in AppendedPublicNavbarActions" :key="act.itemObjKey">
             <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-text-field v-model="act.title" />
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field v-model="act.icon" />
                </v-list-item-subtitle>
              </v-list-item-content>
             </v-list-item>
              <v-list-item>
              <v-list-item-action>
                <v-select return-object item-text="id" clearable label="Action Buttons (Appended)"  :items="PublicNavbarActionOptions" v-model="act.Action"/>
              </v-list-item-action>
             </v-list-item>
             </v-list>
             </v-list>
        </v-list>
        <v-list v-if="selectedSpecialElement === 'System Bar'">
          
          <v-switch v-model="SystemBarEnabled" label="Bar is Enabled"
          />
          <v-switch v-if="HasPublicSignup" v-model="SystemBarHasSignup" label="Bar has Signup"
          />
          
          <v-switch v-model="SystemBarDark" label="Bar is Dark"
          />
          <!-- we need to be able to place web forms -->
           <v-text-field full-width dense v-model="SiteSlogan" label="Slogan"></v-text-field>
           <v-switch v-model="SloganMarquee" label="Slogan as Marquee"
          />
          <v-color-picker v-model="SystemBarColor"></v-color-picker>
         <v-text-field full-width dense @keydown.space.prevent @input="removeWhiteSpace(email)" type="text"
         v-model.trim="SiteEmail" placeholder="Email" size="12" :rules="[$store.state.formrules.email]"/>
           <v-text-field full-width dense :rules="[$store.state.formrules.telnr]" v-model="SiteTelephoneNumber" label="Telephone Number"></v-text-field>
           <v-text-field full-width dense v-model="SiteTelephoneLabel" label="Telephone Label"></v-text-field>
        </v-list>
      <v-list v-if="selectedSpecialElement === 'Social Header'">
            <v-list-item>
              <v-text-field label="Gradient" v-model="SocialHeaderGradient"/>
            </v-list-item>
            <v-list-item>
              <v-menu     									
                  :close-on-content-click="false"									
                  :nudge-right="40"									
                  transition="scale-transition"									
                                  
                  min-width="200px"									
                  >									
                  <template v-slot:activator="{ on }">									
                      <v-btn v-on="on" icon>
                          <v-icon>mdi-palette-advanced</v-icon>
                      </v-btn>								
                  </template>									
                <v-color-picker v-model="SocialHeaderFont.hex"></v-color-picker>									
              </v-menu>
              <v-menu     									
                  :close-on-content-click="false"									
                  :nudge-right="40"									
                  transition="scale-transition"									
                                  
                  min-width="200px"									
                  >									
                  <template v-slot:activator="{ on }">									
                      <v-btn v-on="on" icon>
                          <v-icon>mdi-palette-advanced</v-icon>
                      </v-btn>								
                  </template>									
                <v-color-picker v-model="SocialHeaderBorder.hex"></v-color-picker>									
              </v-menu>
            </v-list-item>
          </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="CancelSpecialElementDialog()"  small dark color="warning">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="UpdateSpecialElementFull(selectedSpecialElement)" small dark color="success">
          Save
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="SystemAssetsDialog" :width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '80%' : '50%'">
      <v-row>
    <v-col
      v-for="img in SystemAssets"
      :key="img.itemObjKey"
      class="d-flex child-flex"
      cols="4"
    >
    <v-card @click="ChooseImage(img)" tile class="transparent" flat>
      <v-img
        
        :src="img.ThumbURL"
       
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-card>
    </v-col>
  </v-row>
    </v-dialog> -->
    <!-- <v-dialog v-model="NavItemsDialog" max-width="800">
      <v-card>
        <v-card-title>Nav Items</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-menu  									
            :close-on-content-click="false"									
            :nudge-right="40"									
            transition="scale-transition"									
            								
            min-width="200px"									
            >									
            <template v-slot:activator="{ on }">									
               <v-btn v-on="on">Add New</v-btn>								
            </template>					
            <v-card>
              <v-card-title>
                New Item
              </v-card-title>
              <v-card-text>
                <v-text-field v-model="NewItemTitle" label="Title"/>
                <v-text-field v-model="NewItemIcon" label="Icon"/>
                <v-switch v-if="ActiveNavbar === 'Public Navbar'" v-model="NavItemisDropdown" label="Drop Down"/>
                <v-select v-if="ActiveNavbar !== 'Public Navbar' && ActiveNavbar !== 'Guest Navbar' || NavItemisDropdown" multiple v-model="NewItemRoutes" :items="ItemRouteOptions" return-object label="Routes" item-text="title"/>
                 <v-select v-if="ActiveNavbar === 'Public Navbar' && !NavItemisDropdown" v-model="NewItemSingleRoute" :items="ItemRouteOptions" label="Route Single" item-text="title"/>
                 <v-select v-if="ActiveNavbar === 'Guest Navbar'" v-model="NewItemSingleRoute" :items="ItemRouteOptions" label="Route Single" item-text="title"/>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="AddNavItem()">Add</v-btn>
              </v-card-actions>
            </v-card>									
        </v-menu>
              
            </v-list-item>
      
          <v-list-item v-for="(item,index) in NavItems" :key="item.itemObjKey">
            <v-text-field v-model="item.title" label="Title" />
            <v-text-field v-model="item.icon" label="Icon" />
            <v-switch v-if="ActiveNavbar === 'Public Navbar'" v-model="item.isDropdown" label="Drop Down"/>
            <v-select v-if="ActiveNavbar !== 'Public Navbar' || item.isDropdown" multiple v-model="item.Children" :items="ItemRouteOptions" return-object label="Routes" item-text="title"/>
            <v-select v-if="ActiveNavbar === 'Public Navbar' && !item.isDropdown" v-model="item.Path" :items="ItemRouteOptions" label="Route Single" item-text="title"/>
            <v-list-item-action>
              <v-menu  
              v-model="DeleteNaviItemMenu"									
                :close-on-content-click="false"									
                :nudge-right="40"									
                transition="scale-transition"									
                                
                min-width="200px"									
                >									
                <template v-slot:activator="{ on }">									
                  <v-icon v-on="on">mdi-delete-forever</v-icon>							
                </template>
                <v-card>
                  <v-card-title>
                    Delete Menu Item?
                  </v-card-title>
                  <v-card-actions>
                    <v-btn @click="DeleteNaviItemMenu = !DeleteNaviItemMenu">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="DeleteMenuItem(item,index)">Delete</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>              
            </v-list-item-action>
          </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelNavItemsDialog()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="UpdateNavItems()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- <v-card class="overflow-hidden"> -->
      <v-list class="transparent" dense>
        <v-list-item dense>
           <v-btn @click="TestThemeColor(selectedClass)" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
            <v-btn @click="CancelColors()" icon>
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn @click="SaveColors()" icon>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn v-if="ActiveNavbar === 'DB Navbar'" :to="'/DBNavlists'" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item dense>
          
        <v-select v-if="NavigationView && NavigationView !== 'App Fonts'" :items="['View Elements','Navbar Colors','Navbar Gradients','Graphics','Special Elements']" v-model="StylingOption" label="Styling Option" />
        </v-list-item>
        <v-list-item dense>
           <v-autocomplete dense v-if="NavigationView && NavigationView === 'App Fonts'"  v-model="System.AppDefaultHeader" label="Headers Font" return-object                               
                            :items="FontOptions">
                            <template v-slot:item="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                    </p>
                                    </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}</span>
                            </template>
          </v-autocomplete>
             <v-autocomplete dense v-if="NavigationView && NavigationView === 'App Fonts'"  v-model="System.AppDefaultBody" label="Body Font"  return-object                                  
            :items="FontOptions">
                            <template v-slot:item="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                    </p>
                                    </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}</span>
                            </template>
             </v-autocomplete>
          <v-select dense v-if="StylingOption === 'View Elements' || StylingOption === 'Navbar Colors'"  item-text="Element_Name" :items="ModeFilteredDefaultThemeColors" v-model="selectedClass" label="Element" return-object />          
          <v-select dense v-if="StylingOption === 'Navbar Gradients'" item-text="Name" :items="ComputedGradientElements" v-model="selectedGradient" label="Element" return-object />
          <v-select dense v-if="StylingOption === 'Graphics'" @change="ToggleSystemAssetsDialog()" clearable item-text="Name" :items="ComputedGraphicalElements" v-model="selectedGraphic" label="Element" return-object />
          <!-- <v-btn @click="NavItemsDialogActivate()" v-if="StylingOption === 'Nav Items'">Nav Items</v-btn> -->
          <v-select dense @change="CheckifSpecialElement()" v-if="StylingOption === 'Special Elements'" item-text="Name" :items="ComputedSpecialElements" v-model="selectedSpecialElement" label="Element" return-object />
        </v-list-item>
        <v-list-item dense>
          <!-- and voila, here we smack that which we need -->
          <v-color-picker width="200" v-if="selectedClass" v-model="selectedClass.DefaultColorObject.hex"></v-color-picker>
          <v-text-field  v-if="selectedGradient" label="Gradient" @change="UpdateBGGradient(selectedGradient)" clearable v-model="selectedGradient.Value"/>
        </v-list-item>
        <a v-if="selectedGradient" href="https://cssgradient.io/" target="_blank">Get Code</a>	
       
        <v-img class="black" v-if="selectedGraphic && System[selectedGraphic.Prop]"
        :src="System[selectedGraphic.Prop].Path"
        />
         <AssetsDirectory v-if="SystemAssetsDialog" :Assets="SystemAssets" :Cols="6" :HoverPreview="false" @ActivateFullGallery="ActivateFullGallery" :ViewFullGalleryBottom="false"
              :HideSelect="false" :Slice="''"
                :ViewFullGalleryText="''" @SelectImage="SelectImage"
              />
      </v-list>
    <!-- <v-app-bar
    fixed
      :style="{zIndex: 5, marginTop: PublicPreview? '110px' : '60px'}"
      color="#6A76AB"
      dark
      prominent
      class="recordtoolbar2"
      src="@/assets/RapidappsParallax.jpeg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <v-app-bar-nav-icon>{{ActiveNavbar}}</v-app-bar-nav-icon>
      <v-app-bar-title>Styling
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-app-bar-title>
          <v-btn @click="TestThemeColor(selectedClass)" icon>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
        <v-btn @click="CancelColors()" icon>
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
      <v-btn @click="SaveColors()" icon>
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn v-if="ActiveNavbar === 'DB Navbar'" :to="'/DBNavlists'" icon>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
        </v-app-bar-title>
       	
      <v-spacer></v-spacer>
        <v-menu v-if="selectedClass"     									
            :close-on-content-click="false"									
            :nudge-right="40"									
            transition="scale-transition"									
            								
            min-width="200px"									
            >									
            <template v-slot:activator="{ on }">									
                <v-btn v-on="on" v-if="selectedClass" icon>
                    <v-icon>mdi-palette-advanced</v-icon>
                </v-btn>								
            </template>									
        <v-color-picker v-model="selectedClass.DefaultColorObject.hex"></v-color-picker>									
        </v-menu>
       
      <v-menu  v-if="selectedGradient" 								
          :close-on-content-click="false"                            									
          transition="scale-transition"                            									
          >									
          <template v-slot:activator="{ on }">	
        <v-btn v-if="selectedGradient" 
          v-on="on"
          text
        >
          <v-icon>mdi-format-color-fill</v-icon>
        </v-btn>
          </template>
          <v-list>
          <v-list-item>
            <v-text-field  v-if="selectedGradient" label="Gradient" @change="UpdateBGGradient(selectedGradient)" clearable v-model="selectedGradient.Value"/>
          </v-list-item>
          <v-list-item>									
            <a href="https://cssgradient.io/" target="_blank">Get Code</a>	
          </v-list-item>
          </v-list>
       </v-menu>
      <v-btn plain @click="ClearselectedGraphic(selectedGraphic)" text v-if="System[selectedGraphic.Prop]">Clear</v-btn>
      <template v-slot:extension>

        <v-select v-if="!DisableNavbarSelect" @change="UpdateNavigationalView()" :items="NavigationalViews" v-model="NavigationView" label="Navigation View" />
        <v-select v-if="NavigationView && NavigationView !== 'App Fonts'" :items="['View Elements','Navbar Colors','Navbar Gradients','Nav Items','Graphics','Special Elements']" v-model="StylingOption" label="Styling Option" />
        
          <v-autocomplete v-if="NavigationView && NavigationView === 'App Fonts'"  v-model="System.AppDefaultHeader" label="Headers Font" return-object                               
                            :items="FontOptions">
                            <template v-slot:item="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                    </p>
                                    </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}</span>
                            </template>
          </v-autocomplete>
             <v-autocomplete v-if="NavigationView && NavigationView === 'App Fonts'"  v-model="System.AppDefaultBody" label="Body Font"  return-object                                  
            :items="FontOptions">
                            <template v-slot:item="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                    </p>
                                    </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span :style="'font-family:'+item.Name">{{item.Name}}</span>
                            </template>
             </v-autocomplete>
          <v-select v-if="StylingOption === 'View Elements' || StylingOption === 'Navbar Colors'"  item-text="Element_Name" :items="ModeFilteredDefaultThemeColors" v-model="selectedClass" label="Element" return-object />          
          <v-select v-if="StylingOption === 'Navbar Gradients'" item-text="Name" :items="ComputedGradientElements" v-model="selectedGradient" label="Element" return-object />
          <v-select v-if="StylingOption === 'Graphics'" @change="ToggleSystemAssetsDialog()" clearable item-text="Name" :items="ComputedGraphicalElements" v-model="selectedGraphic" label="Element" return-object />
          <v-btn @click="NavItemsDialogActivate()" v-if="StylingOption === 'Nav Items'">Nav Items</v-btn>
          <v-select  @change="CheckifSpecialElement()" v-if="StylingOption === 'Special Elements'" item-text="Name" :items="['Social Header']" v-model="selectedSpecialElement" label="Element" return-object />
         
      </template>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="600"
    >
      <v-container style="height: 1000px;"></v-container>
    </v-sheet> -->
  <!-- </v-card> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	
export default {
    props: ['ActiveNavbar','System','SystemEntities','PublicPreview','GraphicalElements','GradientElements','SystemPhotoLibraries','Directories','FontOptions','DisableNavbarSelect'],
    components: {
      AssetsDirectory
    },
    data(){
        return{
          rules: {
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
          SystemBarEnabled: false,
          SystemBarHasSignup: false,
          SystemBarDark: true,
          v: false,
          SiteSlogan: '',
          SloganMarquee:false,
          SiteEmail: '',
          SiteTelephoneNumber: '',
          SiteTelephoneLabel: '',
          SystemBarColor: '',
          PrependedPublicNavbarActions: [],
          AppendedPublicNavbarActions: [],
          PublicNavbarText: '',
          PublicNavbarSelectedText: '',
          PublicNavbarDark: false,
          PublicNavbarStyle: '',
          SpecialElementDialog: false,
          SocialHeaderGradient: 'background: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));',
          SocialHeaderFont: {hex: '#adff2f'},
          SocialHeaderBorder: {hex: '#adff2f'},
          DeleteNaviItemMenu: false,
          SystemAssetsDialog: false,
          SystemAssets: [],
          SystemAssetLibrary: '',
          EyeDropperTool: false,
          NavigationView: '',
          NavigationalViews: [
            'Guest',
            'DB',
            'Social',
            'Public',
            'App Fonts'
          ],
          NewItemTitle: '',
          NewItemIcon: '',
          NewItemRoutes: [],
          NavItemisDropdown: false,
          NewItemSingleRoute: '',
            selectedClass: '',
            selectedSpecialElement: '',
            selectedGradient: '',
            selectedGraphic: '',
            StylingOption: '',  
            CatNavlist: '',          
            NavItemsDialog: false,
            GuestNavbarItems: [],
            DBNavbarItems: [],
            SocialNavbarItems: [],
            PublicNavbarItems: []
        }
    },
    computed:{
      DefaultThemes(){
        return this.$store.state.DefaultThemes
      },
      HasPublicSignup(){
        return this.$store.state.PluginDataBase && this.$store.state.PluginDataBase.Public_Signup && this.$store.state.PluginDataBase.Public_Signup.Active
      },
      WebFormEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'
            })
            // .map(entity => {
            //     return entity.WebTab
            // })
        },
      PublicNavbarActionOptions(){
        return this.WebFormEntities.map(ent => {
          let actobj = {
            id: ent.id,
            Type: 'Web Form',
            WebFormTab: ent.WebFormTab,
            WebFormType: ent.WebFormType,
            SingleName: ent.SingleName,
            DisplayName: ent.DisplayName
          }
          actobj.WebFormTab = JSON.parse(JSON.stringify(ent.WebFormTab))
          actobj.WebFormTab.FormFields = actobj.WebFormTab.FormFields.map(field => {
            let fieldobj = Object.assign({},field)
            delete fieldobj.Response
            return fieldobj
          })
          if(ent.ParentEntity){
            actobj.ParentEntity = ent.ParentEntity
          }
          return actobj
        })
      },
      AppBuilderView(){
        return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView
      },
      BuilderView(){
      return this.$store.state.BuilderView
      },
      WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
      },
      DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
      },
      NavItemsPropName(){
        switch (this.ActiveNavbar) {
          case 'Guest Navbar': return 'GuestNavbarItems'
          case 'DB Navbar': return 'DBNavbarItems'
          case 'Social Navbar': return 'SocialNavbarItems'
          case 'Public Navbar': return 'PublicNavbarItems'
          default: return ''
          }  
      },
      ComputedGradientElements(){
        return this.GradientElements.filter(elmnt => {
          return elmnt[this.ActiveNavbar.split(' ').join('')]
        })
      },
      ComputedSpecialElements(){
        let array = [
          {Name: 'Social Header',Conditions: []},
          {Name: 'System Bar',Conditions: []},
          {Name: 'Public Navbar',Conditions: []},
          
          ]
        return array.map(item => {
          return item.Name
        })
      },
      ComputedGraphicalElements(){
        return this.GraphicalElements.filter(elmnt => {
          return elmnt[this.ActiveNavbar.split(' ').join('')]
        })
      },
        ItemRouteOptions(){
          let routes = this.$router.getRoutes()
            return routes.filter(route => {
                    if(this.ActiveNavbar === 'DB Navbar'){
                    return route.meta && route.meta.type === 'Data Tables'
                    }
                    else if(this.ActiveNavbar === 'Social Navbar' || this.ActiveNavbar === 'Guest Navbar'){
                    return route.meta && route.meta.RouteEventID === 1027
                    }
                    else if(this.ActiveNavbar === 'Public Navbar'){
                    return route.meta && route.meta.RouteEventID === 1028
                    }
            }).map(route => {
              let mapobj = {}
                if(this.ActiveNavbar === 'DB Navbar'){
                  mapobj.icon = route.meta.icon,
                  mapobj.title = route.meta.subcat,
                  mapobj.to = '/'+route.meta.subcat.split(' ').join(''),
                  mapobj.target = 'self',
                  mapobj.QueryName = route.meta.subcat.split(' ').join('')+'Query'
                }
                else if(this.ActiveNavbar === 'Social Navbar' || this.ActiveNavbar === 'Guest Navbar'){
                  mapobj.title = route.meta.subcat
                  mapobj.Path = route.path
                }
                else if(this.ActiveNavbar === 'Public Navbar'){
                  mapobj.title = route.meta.subcat
                  mapobj.Path = route.path
                }
                return mapobj
            })
        },
        NavItems(){
          if(this.ActiveNavbar === 'Guest Navbar'){
            return this.GuestNavbarItems
          }
          else if(this.ActiveNavbar === 'DB Navbar'){
            return this.DBNavbarItems
          }
          else if(this.ActiveNavbar === 'Social Navbar'){
            return this.SocialNavbarItems
          }
          else{
            return this.PublicNavbarItems
          }
        },
        ThemeMode(){
            if(this.$vuetify.theme.dark){
                return 'dark'
            }
            else{
                return 'light'
            }
        },
        ModeFilteredDefaultTheme(){
            let themes = JSON.parse(JSON.stringify(this.DefaultThemes))
            let lighttheme = themes[0]
            let darktheme = themes[1]
            if(this.$vuetify.theme.dark){
                return darktheme
            }
            else{
                return lighttheme
            }
        },
        ModeFilteredDefaultThemeColors(){
            return this.ModeFilteredDefaultTheme.Colors.filter(color => {
              if(this.$route.meta && this.$route.meta.type){
                return color[this.ActiveNavbar.split(' ').join('')] && color[this.$route.meta.type.split(' ').join('')]
              }
              else{
               return color[this.ActiveNavbar.split(' ').join('')] 
              }              
            }).filter(item => {
              if(this.ActiveNavbar === 'DB Navbar'){
                  if(this.StylingOption === 'View Elements'){
                    if(this.$route.meta && this.$route.meta.type === 'Data Tables'){
                      return item.DataTables
                    }
                    else if(this.$route.meta && this.$route.meta.type === 'DB Records'){
                      return item.DBRecords
                    }
                    else{
                      return !item.StriclyNav
                    }
                  }
                  else if(this.StylingOption === 'Navbar Colors'){
                    return item.StriclyNav
                  }      
              }
              else if(this.ActiveNavbar === 'Social Navbar'){
                  if(this.StylingOption === 'View Elements'){
                    return !item.StriclyNav
                  }
                  else if(this.StylingOption === 'Navbar Colors'){
                    return item.StriclyNav
                  }           
              }
              else{
                  if(this.StylingOption === 'View Elements'){
                    return !item.StriclyNav
                  }
                  else if(this.StylingOption === 'Navbar Colors'){
                    return item.StriclyNav
                  }  
              }
            })
        }
    },
    watch: {
      EyeDropperTool (value) {
        if (value) {
         //this.CheckAuth()
         window.addEventListener("click", this.ColorPicker);
        }
        else{
          window.removeEventListener("click", this.ColorPicker);
        }
      },
    },
    created(){
      console.log(this.ActiveNavbar)
      console.log(this.System)
      this.NavigationView = this.ActiveNavbar.split(' Navbar').join('')
      if(this.System.DBNavbarItems){
        this.DBNavbarItems = this.System.DBNavbarItems
      }
      if(this.System.GuestNavbarItems){
        this.GuestNavbarItems = this.System.GuestNavbarItems
      }
      if(this.System.SocialNavbarItems){
        this.SocialNavbarItems = this.System.SocialNavbarItems
      }
      if(this.System.PublicNavbarItems){
        this.PublicNavbarItems = this.System.PublicNavbarItems
      }
      let specialitemsarray = ['SocialHeaderGradient','SocialHeaderFont','SocialHeaderBorder','PrependedPublicNavbarActions','AppendedPublicNavbarActions',
      'SystemBarEnabled','SystemBarHasSignup','SystemBarDark','SiteEmail','SiteTelephoneNumber','SiteTelephoneLabel','SiteSlogan','SloganMarquee','SystemBarColor','PublicNavbarText','PublicNavbarSelectedText',
      'PublicNavbarDark','PublicNavbarStyle']
      specialitemsarray.map(item =>  {
        if(typeof this.System[item] !== 'undefined'){
          this[item] = this.System[item]
        }
      })
      
      this.GetSystemAssets()
      
    },
    methods:{
      AddActionItem(array){
        let newitem = {
          title: 'Action',
          icon: 'mdi-form-select'
        }
        this[array].push(newitem)
      },
      removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
      SelectImage(tab,prop,asset,IsThis){
            this.ChooseImage(asset)
        },
      CancelSpecialElementDialog(){
        this.selectedSpecialElement = ''
        this.StylingOption = ''
        this.SpecialElementDialog = false
      },
      UpdateSpecialElementFull(elmnt){
        let array = []
        let updobj = {}
        if(elmnt === 'Social Header'){
          array = ['SocialHeaderGradient','SocialHeaderFont','SocialHeaderBorder']
        }
        else if(elmnt === 'System Bar'){
          array = ['SystemBarEnabled','SystemBarHasSignup','SystemBarDark','SiteEmail','SiteTelephoneNumber','SiteTelephoneLabel','SiteSlogan','SloganMarquee','SystemBarColor']
        }
        else if(elmnt === 'Public Navbar'){
          array = ['PublicNavbarDark','PublicNavbarStyle','PublicNavbarText','PublicNavbarSelectedText','PrependedPublicNavbarActions','AppendedPublicNavbarActions']
        }
        
        let length = array.length
        array.map((item,index) => {
            if(typeof this[item] !== 'undefined'){
              //console.log(item,this[item])
              updobj[item] = this[item]
              //this.UpdateSpecialElement(item,elmnt)
            }
            // if(index-1+2 === length){
            //   this.CancelSpecialElementDialog()
            // }
          })
        let ref = ''
         if(elmnt === 'Social Header'){
          ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
        }
        else if(elmnt === 'System Bar' || elmnt === 'Public Navbar'){
          ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        }
        //console.log(updobj)
         ref.update(updobj).then(newdoc => {
          this.CancelSpecialElementDialog()
         })
        //this.CheckObjectforUndefined(1,updobj,'(updobj)')
          
      },
      CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
      CheckifSpecialElement(){
        if(this.StylingOption === 'Special Elements'){
          this.SpecialElementDialog = true
        }
        else{
          this.SpecialElementDialog = false
        }
      },
      UpdateSpecialElement(Prop,elmnt){
        let ref = ''
         if(elmnt === 'Social Header'){
          ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
        }
        else if(elmnt === 'System Bar' || elmnt === 'Public Navbar'){
          ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        }
        ref.update({
              [Prop]: this[Prop]
            })
      },
      DeleteMenuItem(item,index){
        this.NavItems.splice(index,1)
      },
      UpdateBGGradient(selectedGradient){
        //process.env.VUE_APP_RA_SYSTEM_ID
        //this.$store.state.ActiveSuiteid
        //ModeFilteredDefaultThemeColors
        //ModeFilteredDefaultTheme
        //ActiveNavbar ONLY? yes when this.StylingOption === 'Navbar Colors' what about this.StylingOption === 'View Elements'?
        //gotta dissdct gradients
        //Public Navbar Guest Social Navbar DB Navbar
        //  {Name: 'DB Sidebar Gradient',Prop: 'DB_SideBar_Gradient',DBNavbar: true},
        // {Name: 'Guest Sidebar Gradient',Prop: 'Guest_SideBar_Gradient',GuestNavbar: true},
        // {Name: 'Social Navbar Gradient',Prop: 'Social_NavBar_Gradient',SocialNavbar: true},
        // {Name: 'Social Parallax Gradient',Prop: 'Social_Parallax_Gradient',SocialNavbar: true},
        // {Name: 'Public Navbar Gradient',Prop: 'Public_NavBar_Gradient',PublicNavbar: true},
        let ref = ''
        if(this.selectedGradient.Prop === 'Public_NavBar_Gradient'){
          ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.selectedGradient.Prop === 'Guest_SideBar_Gradient'){
          ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.selectedGradient.Prop === 'Social_NavBar_Gradient' || this.selectedGradient.Prop === 'Social_Parallax_Gradient'){
          ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.selectedGradient.Prop === 'DB_SideBar_Gradient'){
          ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        }
        ref.update({
              [selectedGradient.Prop]: selectedGradient.Value
            })
      },
      ToggleSystemAssetsDialog(){
        if(this.selectedGraphic){
          this.SystemAssetsDialog = true
        }
      },
      ChooseImage(img){
        console.log(img)
        // this.GraphicalElements.filter(elmnt => {
        //   return elmnt[this.ActiveNavbar.split(' ').join('')]
        // })
      //   GraphicalElements: [
      //   {Name: 'DB Sidebar Image',Prop: 'DB_SideBar_IMG',DBNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},
      //   {Name: 'Guest Sidebar Image',Prop: 'Guest_SideBar_IMG',GuestNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},
      //   {Name: 'Social Navbar Image',Prop: 'Social_NavBar_IMG',SocialNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},        
      //   {Name: 'Social Parallax',Prop: 'Social_Parallax',SocialNavbar: true,path: require('@/assets/RapidappsParallax.jpeg')},
      //   {Name: 'Social Navbar Header Image',Prop: 'Social_NavBar_Header_IMG',SocialNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},        
      //   {Name: 'Public Navbar Image',Prop: 'Public_NavBar_IMG',PublicNavbar: true,path: require('@/assets/BnPFullLogo_tiny.png')},
      // ],
      let ref = ''
      if(this.selectedGraphic.Prop === 'DB_SideBar_IMG'){
        ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
      }
      else if(this.selectedGraphic.Prop === 'Guest_SideBar_IMG'){
        ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
      }
      else if(this.selectedGraphic.Prop === 'Social_NavBar_IMG' || this.selectedGraphic.Prop === 'Social_Parallax' || this.selectedGraphic.Prop === 'Social_NavBar_Header_IMG'){
        ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      }
      else if(this.selectedGraphic.Prop === 'Public_NavBar_IMG'){
        ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
      }
        this.SystemAssetsDialog = false
          ref.update({
              [this.selectedGraphic.Prop]: img
            })
      },
      ClearselectedGraphic(selectedGraphic){
        
        let ref = ''
        if(this.selectedGraphic.Prop === 'DB_SideBar_IMG'){
          ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.selectedGraphic.Prop === 'Guest_SideBar_IMG'){
          ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.selectedGraphic.Prop === 'Social_NavBar_IMG' || this.selectedGraphic.Prop === 'Social_Parallax' || this.selectedGraphic.Prop === 'Social_NavBar_Header_IMG'){
          ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.selectedGraphic.Prop === 'Public_NavBar_IMG'){
          ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        }
        ref.update({
              [selectedGraphic.Prop]: ''
            })
      },
      GetSystemAssets(){
        let vm = this
        if(this.SystemPhotoLibraries){
        this.SystemAssetLibrary = this.SystemPhotoLibraries.find(obj => obj.id === 'System_Assets')
        let array = this.$store.state[this.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array']
        let query = this.$store.state[this.SystemAssetLibrary.DisplayName.split(' ').join('')+'Query']
        if(array && array.length > 0){
          this.SystemAssets = array
        }
        else if(query){
          let payload = {                
            query: query,
            arrayname: this.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array'
          }
          
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                  vm.SystemAssets = vm.$store.state[vm.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array']
              })
        }
        console.log(this.SystemAssetLibrary,this.SystemAssets,vm.$store.state[vm.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array'],vm.$store.state,vm.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array')
      }
      },
      ColorPicker(event){
        console.log(event)
        let x = event.clientX
        let y = event.clientY
        //this.getPixelColor(x, y)
      },
      getPixelColor(x, y) {
        var pxData = ctx.getImageData(x,y,1,1);
        return("rgb("+pxData.data[0]+","+pxData.data[1]+","+pxData.data[2]+")");
    },
      ActivateEyeDropper(){
        this.EyeDropperTool = !this.EyeDropperTool
      },
      UpdateNavigationalView(){
        if(this.NavigationView === 'Social'){
          this.$emit('TogglePublicPreview',false)
          this.$emit('IntranetViewToggle',true,true)
        }
        else if(this.NavigationView === 'DB'){
          this.$emit('IntranetViewToggle',false)
          this.$emit('TogglePublicPreview',false)
        }
        else if(this.NavigationView === 'Public'){
          this.$emit('IntranetViewToggle',false)
          this.$emit('TogglePublicPreview',true)
        }
        else if(this.NavigationView === 'Guest'){
          this.$router.push('/MyAccount')
        }
      },
      CancelNavItemsDialog(){
        this.NavItemsDialog = false
        this.NewItemIcon = ''
        this.NewItemTitle = ''
        this.NewItemRoutes = []
      },
      UpdateNavItems(){
        //this.$store.state.ActiveSuiteid
        //process.env.VUE_APP_RA_SYSTEM_ID
        //ActiveNavbar
        let ref = ''
        if(this.ActiveNavbar === 'Guest Navbar'){
          ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
        }        
        else if(this.ActiveNavbar === 'DB Navbar'){
          ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.ActiveNavbar === 'Social Navbar'){
          ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
        }
        else if(this.ActiveNavbar === 'Public Navbar'){
          ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
        }
        ref.update({
              [this.NavItemsPropName]: this[this.NavItemsPropName]
            })
            this.CancelNavItemsDialog()
      },
        AddNavItem(){
          if(this.ActiveNavbar === 'Public Navbar'){
            let newitem = {
                    icon: this.NewItemIcon,
                    title: this.NewItemTitle,
                    Path: this.NewItemSingleRoute.split(' ').join('-'),
                    // Children: this.NewItemRoutes
                  }
                  if(this.NavItemisDropdown){
                    newitem.Children = this.NewItemRoutes
                    newitem.isDropdown = true
                  }
              this.PublicNavbarItems.push(newitem)
            this.UpdateNavItems()
          }
          else if(this.ActiveNavbar === 'Guest Navbar'){
            let newitem = {
                    icon: this.NewItemIcon,
                    title: this.NewItemTitle,
                    Path: this.NewItemSingleRoute.split(' ').join('-'),
                    // Children: this.NewItemRoutes
                  }
              this.GuestNavbarItems.push(newitem)
            this.UpdateNavItems()
          }
          else{
             let newitem = {
                    icon: this.NewItemIcon,
                    title: this.NewItemTitle,
                    Children: this.NewItemRoutes
                  }
              this[this.NavItemsPropName].push(newitem)
            this.UpdateNavItems() 
          }
        },
        NavItemsDialogActivate(){
          this.NavItemsDialog = true
        },
        SaveColors(){
            //console.log(this.ModeFilteredDefaultThemeColors,this.$vuetify.theme.themes,this.System.AppDefaultHeader,this.System.AppDefaultBody)
            //I BELIEVE THIS ONE IS TRICKY WE WILL HAVE TO KEEP IT ALL SYSTEM CONFIG, EXCEPT FOR THE PUB NAVBARS. THUS
            let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
            // if(this.ActiveNavbar === 'Guest Navbar'){
            //   ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid)
            // }        
            // else if(this.ActiveNavbar === 'DB Navbar'){
            //   ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
            // }
            // else if(this.ActiveNavbar === 'Social Navbar'){
            //   ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
            // }
            // else if(this.ActiveNavbar === 'Public Navbar'){
            //   ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
            // }
            //in theory ABOVE would have made sense but we doing the entire array, and item would only be "site based" if "StriclyNav"
            //thus
            this.ModeFilteredDefaultThemeColors.map(color => {
              //GuestNavbar still 'SystemConfig'
              if(color.StriclyNav && color.PublicNavbar){
                ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
              }
              else if(color.StriclyNav && color.DBNavbar){
                ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
              }
              else if(color.StriclyNav && color.SocialNavbar){
                ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
              }
              else if(color.StriclyNav && color.PublicNavbar){
                ref = db.collection('Websites').doc(this.$store.state.ActiveSuiteid)
              }
                let colorobj = {hex: this.$vuetify.theme.themes[color.Theme][color.Name]}
                //console.log(colorobj)
              ref.update({
                   [color.SystemProp]: colorobj
               })               
            })   
            if(this.System.AppDefaultHeader){
                
                  db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                    AppDefaultHeader: this.System.AppDefaultHeader
                })
               }
               if(this.System.AppDefaultBody){
                  db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                    AppDefaultBody: this.System.AppDefaultBody
                })
               }         
        },
        CancelColors(){
            this.SetColorstoSystemColors()
            this.RefreshColors()
        },
        SetColorstoSystemColors(){
            this.$emit('SetColorstoSystemColors',this.System)
        },
        RefreshColors(){
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            setTimeout(() => {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            }, 50);
        },
        TestThemeColor(color){
            this.$vuetify.theme.themes[this.ThemeMode][color.Name] = color.DefaultColorObject.hex
            // this.RefreshColors()
            // console.log(this.ModeFilteredDefaultThemeColors)
            // console.log(this.DefaultThemes)
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            setTimeout(() => {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            }, 50);
        }
    }
}
</script>

<style>

</style>