<template>
<div>
   <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveDocumentField()" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn>
   <v-list-item class="justify-center subtleoverline">
        {{field.DisplayName}}
    </v-list-item>
  <v-layout class="justify-center">
      
        <v-avatar
            :color="AvatarStyle.Color"
            size="150" 
            tile>  
            <v-icon v-if="!Editing" size="100" dark>{{AvatarStyle.Icon}}
            </v-icon>  
            <div v-if="Editing">
                <input :id="field.Name" 
                    @change="UploadFileSelect($event)" style="visibility: hidden;display: none;"
                type="file">
                <label :for="field.Name"><span v-if="Record[field.Name]" style="--col: #04AA6D"  class="texbtn btnhover elevation-1">
                    <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Change File
                    </span><span v-if="!Record[field.Name]" style="--col: #04AA6D"  class="texbtn btnhover elevation-1">
                    <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Select File
                    </span>
                </label>
                <v-btn @click="ClearSelectedFile()" v-if="UploadFile">Clear</v-btn>
                <!-- <span v-if="UploadFile" style="--col: #04AA6D"  class="texbtn btnhover elevation-1">
                    <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Clear Temp
                    </span> -->
                <span v-if="Record[field.Name] && Record[field.Name].UploadFile">
                    {{Record[field.Name].UploadFile.name}}
                </span>
                <!-- <v-btn :href="localURL" v-if="UploadFile" @click="OpenTempDoc(UploadFile)" class="orange white--text" small>View {{field.FileName}}</v-btn> -->
                <!-- <v-btn v-if="UploadFile" @click="SaveDocumentField(UploadFile,field.Name)" class="green white--text"  small>Save</v-btn> -->
            </div>      
            <!-- <v-btn @click="GetShortLivedURL(Record[field.Name])" v-if="Record[field.Name]" class="blue white--text" icon small absolute top right><v-icon>mdi-eye</v-icon></v-btn> -->
            <!-- <img style="object-fit: cover;" v-if="localURL" :src="localURL" height="150px">
            <img style="object-fit: cover;" v-if="Record[field.Name] && !localURL" :src="Record[field.Name].ThumbURL" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && $vuetify.theme.dark" src="@/assets/ImageHolder_dark.png" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !localURL && !$vuetify.theme.dark" src="@/assets/ImageHolder.png" height="150px"> -->
           <v-card-title class="subtleoverline" style="position: absolute;bottom: 0px;color: white;">
             {{AvatarStyle.Name}}
           </v-card-title>
        </v-avatar>
        <!-- <v-list-item>
            <v-btn :href="localURL" v-if="UploadFile" @click="OpenTempDoc(UploadFile)" class="orange white--text" small>View {{field.FileName}}</v-btn>
            <v-btn color="links" dark small @click="GetShortLivedURL(Record[field.Name])">{{Record[field.Name].filename.substr(0,20)}}</v-btn>
        </v-list-item> -->
  </v-layout>
<v-layout class="justify-center" style="margin-top: 20px;">
     <v-btn v-if="UploadFile" @click="OpenTempDoc(UploadFile)" class="orange white--text" small>{{smart_substr(UploadFile.name,15)}}</v-btn>
    <v-btn v-if="Record[field.Name]" color="links" dark small @click="GetShortLivedURL(Record[field.Name])">{{smart_substr(Record[field.Name].filename,15)}}</v-btn>
</v-layout>
  <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit'],
    components: {},
    data() {
        return {          
          localURL: '',
          UploadFile: '',
          Refreshing: false,
          Editing: false,
          AllAllowedFileTypesArray: [
                'application/msword',' application/vnd.ms-excel','application/vnd.ms-powerpoint','application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/plain','text/csv','application/pdf','image/*','application/x-zip-compressed','text/x-vcard','audio/mpeg'
            ],
            FileTypesandIcons: [
                {Type: 'application/msword',Icon: 'mdi-file-word',Color: 'blue',Name: 'Word Document'},
                {Type: 'application/vnd.ms-excel',Icon: 'mdi-file-excel',Color: 'success',Name: 'Spreadsheet'},
                {Type: 'application/vnd.ms-powerpoint',Icon: 'mdi-file-powerpoint',Color: 'deep-orange',Name: 'Presentation'},
                {Type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',Icon: 'mdi-file-word',Color: 'blue',Name: 'Word Document'},
                {Type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',Icon: 'mdi-file-excel',Color: 'success',Name: 'Spreadsheet'},
                {Type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',Icon: 'mdi-file-powerpoint',Color: 'deep-orange',Name: 'Presentation'},
                {Type: 'text/plain',Icon: 'mdi-note-text',Color: 'amber',Name: 'Text Note'},
                {Type: 'application/pdf',Icon: 'mdi-file-pdf-box',Color: 'red',Name: 'PDF'},
                {Type: 'text/csv',Icon: 'mdi-comma',Color: 'green',Name: 'CSV'},                
                {Type: 'image',Icon: 'mdi-file-image',Color: 'grey',Name: 'Image'},
                {Type: 'application/x-zip-compressed',Icon: 'mdi-folder-zip',Color: 'grey',Name: 'Zip'},
                {Type: 'text/x-vcard',Icon: 'mdi-smart-card',Color: 'blue',Name: 'VCard'}, 
                {Type: 'audio/mpeg',Icon: 'mdi-waveform',Color: 'blue',Name: 'Sound File'}, 
                
            ]
        }
    },	
    computed:{
        AvatarStyle(){
            let obj = {
                Icon: 'mdi-help',
                Color: 'grey',
                Name: 'No File'
            }
            let file = this.UploadFile ? {...this.UploadFile,FileType: this.UploadFile.type} : this.Record[this.field.Name] ? this.Record[this.field.Name] : ''

           if(file){
                let match = this.FileTypesandIcons.find(obj => file.FileType.includes(obj.Type))
                obj = {Icon: match.Icon,Color: match.Color,Name: match.Name}   
            }
        return obj
        },
      field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.col.Field.id] ? 
        this.Record[this.col.Field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        ClearSelectedFile(){            
            this.UploadFile = ''
            this.localURL = ''
        },
         OpenTempDoc(file){
            //console.log(file)
            let tempurl = URL.createObjectURL(file)
            //console.log(tempurl)
            window.open(tempurl, '_blank')
        },
        SaveDocumentField(){
            //SaveRecord({Record: Record,Fields: [field]})
            let payload = {
                file: this.UploadFile,
                fieldname: this.field.id
            }
            this.$emit('SaveDocumentField',payload)
            this.ClearSelectedFile()
        },
        Refresh(){
            //alert('yay')
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 10);
        },
        UploadFileSelect(event){
        //console.log(file,field,Record)
        this.UploadFile = event.target.files[0];
        if(!this.AllAllowedFileTypesArray.includes(this.UploadFile.type) && !this.UploadFile.type.includes('image/')){
            //console.log('this.UploadFile.type',this.UploadFile.type)
            alert(this.UploadFile.type+' type files not allowed')
            this.UploadFile = ''
        }
        else{
          this.localURL = URL.createObjectURL(this.UploadFile)
         this.Refresh()  
        }        
        },
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },
      GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            console.log(item)
            GetSignedInURL(item).then(result => {
            let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
            })
        },

    }
}
</script>

<style>

</style>



