<template>
        <span>
         <!-- to insert AFTER backgroundImage on section car
         <span v-if="SystemObj.StyleImageswithThemeColors">linear-gradient(to right, {{SystemThemes[0].Colors[0].Pick.hex}}cc, {{SystemThemes[0].Colors[1].Pick.hex}}cc),</span>
         e.g.
         { backgroundImage: 'linear-gradient(to right, #E53935cc, #FFCDD2cc),url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' } -->
       <!-- <v-card v-if="!SingleElementView" :id="section.Name ? section.Name.split(' ').join('') : ''" shaped dark flat :width="!$route.meta || $route.meta && !$route.meta.DataSingleBuilder ? '100%' : '90%'"
       :class="!$route.meta || $route.meta && !$route.meta.DataSingleBuilder ? 'secondary text-xs-center elevatedsectionheader'
       : 'mx-4 secondary  text-xs-center elevatedsectionheader'" 
       elevation="6" :style="GetHeaderGraphics(section)">    
              <v-card-title :class="MobileView ? 'overline align-center' : 'align-center'">
                {{section.Name}}<v-spacer></v-spacer>
                <v-btn outlined fab :x-small="MobileView" :small="!MobileView" class="elevation-6 mx-1" dark  v-if="CanEdit" @click="UpdateEditing()">
                  <v-icon :small="MobileView">mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="Editing" outlined fab small class="elevation-6 mx-1" dark @click="UpdateEditableFields()">
                  <v-icon :small="MobileView">mdi-content-save</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle v-if="!MobileView">
               Edit
              </v-card-subtitle>             
            </v-card> -->
             <v-card-subtitle class="mediumoverline" v-if="section.Name">
                        {{section.Name}}
                    </v-card-subtitle>
                   
            <v-card :class="SingleElementView && MiniView ? 'siteconfigcardsections settingsfont' : SingleElementView ? 'siteconfigcardsections' :
            !$route.meta && MiniView || $route.meta && !$route.meta.DataSingleBuilder && MiniView ? 'siteconfigcardsections settingsfont' : 
            !$route.meta || $route.meta && !$route.meta.DataSingleBuilder ? 'siteconfigcardsections' : ''" :height="SingleElementView ? '' : ''"
             flat :dark="AppisDarkMode" :style="SingleElementView ? '' : ''" style="padding:20px;">
                <!-- <v-card v-if="!SingleElementView" flat height="60" :class="SingleElementView ? 'transparent' : 'overlayed transparent'">
                  </v-card> --> <v-btn style="top:5px;" absolute outlined fab :x-small="MobileView" color="blue" :small="!MobileView" class="elevation-6 mx-1"  v-if="CanEdit && !Editing" @click="UpdateEditing()">
                  <v-icon :small="MobileView">mdi-pencil</v-icon>
                </v-btn>
                <v-btn style="top:5px;" absolute outlined fab :x-small="MobileView" :small="!MobileView" color="red" class="elevation-6 mx-1"  v-if="Editing" @click="UpdateEditing()">
                  <v-icon :small="MobileView">mdi-cancel</v-icon>
                </v-btn>
                <v-btn style="top:5px;left: 70px;" absolute outlined fab :x-small="MobileView" :small="!MobileView" class="elevation-6 mx-1" color="green" v-if="Editing" @click="UpdateEditableFields()">
                 <v-icon :small="MobileView">mdi-content-save</v-icon>
                </v-btn>
                  <span  v-for="field in Fields" :key="field.itemObjKey">
                    <v-row :class="MiniView ? 'settingsfont' : ''" style="align-items: baseline;" v-if="RenderField(field)">
                       <v-col style="width: 3%;" cols="1"><v-icon :dark="DarkIcons" :small="MiniView" v-if="field.Icon">{{field.Icon}}</v-icon> 
                       </v-col>
                    <v-col  style="padding:6px;">
                      
                      <v-tooltip bottom v-if="field.Tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs"
                             v-on="on">
                            <h4 :class="HeaderDarkLightStyle">{{field.DisplayName}}</h4>
                            </span>
                          </template>
                          <v-card max-width="200" v-html="field.Tooltip" tile class="transparent white--text">
                          </v-card>
                      </v-tooltip>
                      <h4 v-else :class="HeaderDarkLightStyle">{{field.DisplayName}}</h4>
                    </v-col>
                    <!-- <v-spacer></v-spacer> -->
                    <v-col style="padding:6px;">
                        <!-- okay not EXACTLY
                        if it's a website it's a hyperlink, if it's an email... if lookup return lookupfieldname, and if option set...blablabla. This needs a new component -->
                      <!-- removed datatext--text -->
                      <h4 v-if="!Editing" style="text-align:left" class="font-weight-light">
                          <FieldViewerComponent :AppisDarkMode="AppisDarkMode" :CurrentEntity="CurrentEntity" @RefreshField="RefreshField"
                            :Record="Record" :field="field" @GetShortLivedURL="GetShortLivedURL" v-if="!field.Refreshing" @UpdateRecordCalculation="UpdateRecordCalculation"
                          />
                        
                          </h4>
                      <!-- new component for view only ends here mind you -->
                      <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" v-if="Editing" :Record="Record" :field="field" @onPhotoFileselected="onPhotoFileselected"
                      :CurrentEntity="CurrentEntity" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                      :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                        />
                    </v-col>
                  </v-row>
                  <span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' && AddressRenderField(field) || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address' && AddressRenderField(field)">
                  <v-row class=" mx-2">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">{{field.DisplayName}}</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                    <v-list class="transparent mx-4" outlined v-if="!Editing">
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="">
                                {{Record[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                     <FieldValueEditerComponent @UploadFileSelect="UploadFileSelect" @UpdateEditableField="UpdateEditableField" v-if="Editing" :Record="Record" :field="field" :CurrentEntity="CurrentEntity"
                        /></span>
                      <span v-else-if="field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.CommonFieldType === 'Physical Address' && field.RelatedRecord
                      || field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.CommonFieldType === 'Postal Address' && field.RelatedRecord">
                  <v-row class="mx-2">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">{{field.DisplayName}}</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                    <v-list class="transparent mx-4" outlined v-if="!Editing">
                        <v-list-item dense v-for="breakdownfield in field.ParentFieldObj.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="" v-if="Record[field.RelatedRecord.RelationshipField.Name]">
                                {{Record[field.RelatedRecord.RelationshipField.Name][breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.ParentFieldObj.Type === 'Common Field' && field.ParentFieldObj.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.ParentFieldObj.Type === 'Common Field' && field.ParentFieldObj.CommonFieldType === 'Physical Address' && !Editing">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.ParentFieldObj.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                     <FieldValueEditerComponent style="padding-left:15px;" @UploadFileSelect="UploadFileSelect" @UpdateEditableField="UpdateEditableField" v-if="Editing" :Record="Record" :field="field" :CurrentEntity="CurrentEntity"
                        />
                        </span>
                    </span>
                </v-card>
        </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';

export default {
    props: ['tab','section','Fields','AppisDarkMode','CanEdit','Record','Cols','SingleElementView','System',
    ,'AdditionalSaveMethod','SystemEntities','PrimaryFieldName','RelatedObj','SingleFormEditing','sectionindex','SampleOnly','CurrentEntity'],
    components: {
        FieldValueEditerComponent,
        FieldViewerComponent
    },
    data(){
        return{
          UploadingFile: false,
            UploadingFileProgress: 0,
            // MutatedRecord: '',
            Editing: false,
        }
    },
    computed:{
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userBU () {
        return this.$store.state.buobj
      },
      EntityName(){
            let entity = this.SystemEntities.find(obj => obj.id === this.ActiveEntityID)
            return entity.DisplayName
        },
      BaseStorageRef(){
          return this.EntityName.split(' ').join('')+'/'
      },
      HeaderDarkLightStyle(){
        //removed configtext--text 
          if(this.AppisDarkMode){
              return 'font-weight-light'
          }
          else{
              return 'font-weight-light'
          }
          },
    },
    created(){
      if(this.SingleElementView){
        this.UpdateEditing()
      }
        //alert('yayayayaya')
        // this.MutatedRecord = Object.assign({},this.Record)
        // console.log(this.MutatedRecord)
    },
    methods:{
      
      GetShortLivedURL(fileobject){
        this.$emit('GetShortLivedURL',fileobject)
      },
      onPhotoUpload(field){
        console.log(field)
        let file = field.UploadFile
        let filename = file.name
        let storepath = this.CurrentEntity.DisplayName.split(' ').join('')+'/'+this.Record.id+'/'+field.Name+'/'+filename  
        this.$emit('onPhotoUpload',filename,file,storepath,field)      
      },
      onPhotoFileselected(field,file){
        field.localURL = URL.createObjectURL(file)
        field.UploadFile = file
        // this.Record[field.Name+'localURL'] = 
        console.log(this.Record,field,file)
      },
      AddressRenderField(field){
        if(field.IsConditionalRender){
          return this.ConditionalRender(field)
        }
        else{
          return field
        }
      },
      RenderField(field){
        if(field.IsConditionalRender){
          return this.ConditionalRender(field)
        }
        else{
          return !field.CommonFieldType && field.Type !== 'Parent Field' && !field.ParentFieldObj || field.CommonFieldType && field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'
          || field.Type === 'Parent Field' && field.ParentFieldObj && !field.ParentFieldObj.CommonFieldType|| field.Type === 'Parent Field' && field.ParentFieldObj
          && field.ParentFieldObj.CommonFieldType && field.ParentFieldObj.CommonFieldType !== 'Physical Address' && field.ParentFieldObj.CommonFieldType !== 'Postal Address'
        }
      },
      ConditionalRender(field){
        if(field.RenderConditionType === 'Boolean Match'){
          return this.ConditionalBooleanRender(field)
        }
        else if(field.RenderConditionType === 'Option Set Match'){
          return this.ConditionalOptionsRender(field)
        }        
      },
      ConditionalOptionsRender(field){
        let options = field.ConditionalOptions
        //console.log(options)
        let recordoption = this.Record[field.ConditionalOptionField]
        //console.log('recordoption',recordoption,options)
        return recordoption && options.includes(recordoption.ID)
      },
      ConditionalBooleanRender(field){
        let render = true
          field.ConditionalBooleans.map(bool => {
            if(this.Record[bool.Field] !== bool.Value){
              render = false
            }
          })
        return render
      },
      SystemObj(){
        return this.$store.state.SystemObj
      },
      GetHeaderGraphics(section){
        // /.HasParallaxGradient
        //.ParallaxGradient
        //console.log('this.SystemObj',this.SystemObj,this.$store.state)
        if(!section.HasParallaxGradient || !section.ParallaxGradient){
          let gradient = ''
          if(this.$vuetify.theme.dark){
            gradient = 'linear-gradient(338deg, '+this.$store.state.SystemObj.Dark_Record_Toolbar.hex+'CC 0%, '+this.$store.state.SystemObj.Dark_Record_Toolbar.hex+'EE 100%)'
          }
          else{
            gradient = 'linear-gradient(338deg, '+this.$store.state.SystemObj.Light_Record_Toolbar.hex+'CC 0%, '+this.$store.state.SystemObj.Light_Record_Toolbar.hex+'EE 100%)'
          }
          return section.HasImage && section.IMG? { backgroundImage: gradient+',url('+section.IMG+')',backgroundSize: 'cover' } : { backgroundImage: gradient+',url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }
        }
        else{
          //background: linear-gradient(338deg, rgba(0,0,0,0.9528186274509804) 0%, rgba(207,48,48,0.5382528011204482) 0%, rgba(21,101,184,0.639093137254902) 100%);
          //hex version to use app primary colors 'linear-gradient(338deg, #B987D8 0%, #B987D8 100%);'
          let gradient = section.ParallaxGradient.split('background: ')[1].split(';').join(',')
          return section.HasImage && section.IMG? { backgroundImage: gradient+'url('+section.IMG+')',backgroundSize: 'cover' } : { backgroundImage: gradient.split(';')[0]+',url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }
        }
        
      },
      UploadFileSelect(file,field,Record){
      console.log(file,field,Record)
      field.UploadFile = file
      field.FileName = file.name
    },
      SaveDocumentField(file,fieldname){
        let vm = this
        let filename = file.name
        let storepath = this.Basestorageref+'/'+fieldname+'/'+filename+'/'+new Date()
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        
        vm.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+filename);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingFile = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
              });
            });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            console.log('UploadObject',UploadObject)
              this.$emit('UpdateEditableField',fieldname,UploadObject,this.AdditionalSaveMethod,this.Record)
              vm.$emit('ActivateUploadingSingleFileNotification',false)
          })
          }, 1000);
      },
      RollupValue(Record,field){
           let table = ''
           if(field.TableType === 'Related Data'){
                table =  this.Record.RelatedTables.find(obj => obj.id === field.RelatedTable)
                if(table){
                    return table.StoreData.map(item => {                
                    return this.PerformedCalculation(item,field,true)
                    }).reduce((a, b) => a + b, 0)
                } 
           }
           else{
               table = this.Record.SubCollections.find(obj => obj.id === field.RelatedTable)
                if(table){
                    //console.log('table,table.Data,Record',table,table.Data,this.Record)
                    return table.Data.map(item => {                
                    return this.PerformedCalculation(item,field,true)
                    }).reduce((a, b) => a + b, 0)
                }
           }
          
        },
      PerformedCalculation(Record,field){
            console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map((formula,i) => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            console.log(formula.integer)
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = Record[formulas[i-1].fieldName.Name]*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                            console.log(formula.integer)
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
      UpdateEditableFields(){
        //console.log('calling updating editable field from section',this.Fields)
        // console.log(this.MutatedRecord,this.Record)
        let length = this.Fields.length
        this.Fields.map((field,fieldindex) => {
          //console.log("field.Type",field.Type,field.IsCurrency)
          if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
               field.FieldBreakdown.map(breakdown => {
                 if(this.Record[breakdown.Name]){
                   this.$emit('UpdateEditableField',breakdown.Name,this.Record[breakdown.Name],this.AdditionalSaveMethod,this.Record)
                 }                    
                })
              //   let obj = {}
              //  field.FieldBreakdown.map((breakdown,index) => {
              //    if(this.Record[breakdown.Name]){
              //      obj[[breakdown.Name]] = this.Record[breakdown.Name]
              //      if(index === 6){
              //        this.$emit('UpdateEditableField',field.Name,obj,this.AdditionalSaveMethod,this.Record)
              //      }                   
              //    }                    
              //   })                 
            }
            else if(field.Type === 'Lookup' && this.Record[field.Name] && !field.IsMultiple){
                let lookupobj = {
                    [field.LookupFieldName]: this.Record[field.Name][field.LookupFieldName],
                    id: this.Record[field.Name].id
                }
                this.$emit('UpdateEditableField',field.Name,lookupobj,this.AdditionalSaveMethod,this.Record)
                let lookupobjid = this.Record[field.Name].id
                this.$emit('UpdateEditableField',field.Name+'id',lookupobjid,this.AdditionalSaveMethod,this.Record)
            }
            else if(field.Type === 'Lookup' && this.Record[field.Name] && field.IsMultiple){
              let lookupobj = this.Record[field.Name]
                this.$emit('UpdateEditableField',field.Name,lookupobj,this.AdditionalSaveMethod,this.Record)
            }
            else if(field.Type === 'Social Lookup' && this.Record[field.Name]){
                let lookupobj = {
                    [field.LookupFieldName]: this.Record[field.Name][field.LookupFieldName],
                    id: this.Record[field.Name].id
                }
                this.$emit('UpdateEditableField',field.Name,lookupobj,this.AdditionalSaveMethod,this.Record)
                let lookupobjid = this.Record[field.Name].id
                this.$emit('UpdateEditableField',field.Name+'id',lookupobjid,this.AdditionalSaveMethod,this.Record)
            }
            else if(field.Type === 'Parent Field' && field.RelatedRecord && field.RelatedRecord.RelationshipField && field.ParentFieldObj){
              let parentobj = this.Record[field.RelatedRecord.RelationshipField.Name]
              let parentfield = field.ParentFieldObj
              console.log('parentobj',parentobj,'parentfield',parentfield)
              if(parentobj && parentfield){
                if(parentfield.Type !== 'Common Field' || parentfield.Type === 'Common Field' && parentfield.CommonFieldType !== 'Physical Address' && parentfield.CommonFieldType !== 'Postal Address'){
                  let value = parentobj[parentfield.Name]
                  this.$emit('UpdateEditableField',field.Name,value,this.AdditionalSaveMethod,this.Record)
                }
                else if(parentfield.Type === 'Common Field'){
                    parentfield.FieldBreakdown.map(breakdown => {
                      let value = parentobj[breakdown.Name]
                      let breakdownname = breakdown.Name.replace(parentfield.Name,field.Name)
                      this.$emit('UpdateEditableField',breakdownname,value,this.AdditionalSaveMethod,this.Record)
                  })
                }
              }
            }
            else if(field.Type === 'Calculated Field'){
              this.Record[field.Name] = this.PerformedCalculation(this.Record,field)
              if(field.IsCurrency){
                this.Record[field.Name] = Number(this.Record[field.Name].toFixed(2))
              }
              this.$emit('UpdateEditableField',field.Name,this.Record[field.Name],this.AdditionalSaveMethod,this.Record)
            }
            else if(field.Type === 'Number Field'){
              this.Record[field.Name] = Number(this.Record[field.Name])
              if(field.IsCurrency){
                this.Record[field.Name] = Number(this.Record[field.Name].toFixed(2))
              }
              this.$emit('UpdateEditableField',field.Name,this.Record[field.Name],this.AdditionalSaveMethod,this.Record)
            }
            else if(field.Type === 'Rollup Calculation'){
              console.log('this.RollupValue(this.Record,field)',this.RollupValue(this.Record,field))
              if(this.RollupValue(this.Record,field)){
                this.Record[field.Name] = this.RollupValue(this.Record,field) 
                if(field.IsCurrency){
                this.Record[field.Name] = Number(this.Record[field.Name].toFixed(2))
              }
                this.$emit('UpdateEditableField',field.Name,this.Record[field.Name],this.AdditionalSaveMethod,this.Record)
              }
              
            }
            else if(field.Type === 'Date' && this.Record[field.Name]){
              // console.log(this.Record[field.Name])
              this.$emit('UpdateEditableField',field.Name,new Date(this.Record[field.Name]),this.AdditionalSaveMethod,this.Record)
            }
            else if(field.Type !== 'QR Link'){
              if(this.section.DataViewName === 'Data Single Feature List'){
                      this.$emit('UpdateEditableField',field.Name,this.Record[field.Name],this.AdditionalSaveMethod,this.Record,true)
                }
                else{
                  this.$emit('UpdateEditableField',field.Name,this.Record[field.Name],this.AdditionalSaveMethod,this.Record)
                }
                
                
            }
            if(fieldindex-1+2 === length){
              this.UpdateEditing()
            }
        })
      },
      UpdateRecordCalculation(Record,field,value){
        this.$emit('UpdateRecordCalculation',Record,field,value)
      },
        // UpdateEditableField(prop,value,FieldObject){
        //     //console.log(this.Record,FieldObject)
        //     this.MutatedRecord[prop] = value
        //     this.$emit('UpdateEditableField',prop,value,this.AdditionalSaveMethod,FieldObject)
        //     this.UpdateEditing()
        // },
        UpdateEditing(){
          if(!this.SingleFormEditing){
            if(this.Editing){
              this.Fields.map(field => {
                if(field.Type === 'Single File Upload'){
                  delete field.UploadFile
                  delete field.localURL
                  delete field.FileName                 
                }
              })
            }
            this.Editing = !this.Editing
          }
          else{
            this.$emit('OpenDialog',this.section,this.sectionindex,true)
          }
            
            //console.log(this.Record,this.MutatedRecord)
            //this.$emit('UpdateEditableField',this.FieldName,FieldValue,this.AdditionalSaveMethod,this.MutatedRecord)
        },
        ViewMapItem(FieldBreakdown) {
        let combinedlink = ''
        FieldBreakdown.map((breakdown,i) => {
            combinedlink = combinedlink+this.Record[breakdown.Name]
            if(i-1+2 < FieldBreakdown.length){
                combinedlink = combinedlink+'+'
            }
        })
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        
        window.open(FinalLink, '_blank')
      },
    }
}
</script>

<style>

</style>