<template>
<nav>
    
    <v-app-bar app  >
       <!-- <v-app-bar-nav-icon @click="storedrawer = !storedrawer" class="red--text"></v-app-bar-nav-icon>  -->
       <span v-if="!MiniView">Store</span>
       <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="ToggleAppDarkMode()"><v-icon >mdi-invert-colors</v-icon></v-btn>                            
            </template>
                Toggle Dark Mode
            </v-tooltip>
            <v-spacer>
       </v-spacer>
            <!-- <v-btn text plain> -->
       <v-text-field @keydown.enter="ActivateStoreSearch()" style="padding-top:25px;" clearable @input="ClearSearch()" dense outlined v-model="StoreSearch" label="Search" :class="$vuetify.theme.dark ? 'blacklabel' : 'whitelabel'" append-icon="mdi-search"
       />
       <v-btn @click="ActivateStoreSearch()" v-if="StoreSearch" dark color="success" x-small>Go</v-btn>
            <!-- </v-btn> -->
       <v-spacer>
       </v-spacer>
       
       
                        <v-menu
                           width="400"
                          bottom
                          left
                          offset-y
                          :close-on-content-click="false"
                      >
                          <template v-slot:activator="{ on }">
                      <v-btn v-on="on" plain >
                      <v-badge :color="NotificationsColor" overlap
                              dark>
                              <v-icon
                              dark
                              >
                              mdi-cart
                            </v-icon>

                            <span slot="badge"> {{CartItems.length}} </span>
                            </v-badge><span  v-if="!MiniView">My Cart</span></v-btn>
                          </template>
                          <v-card width="400">
                            <v-card-title class="mediumoverline" v-if="!MiniView">
                              My Cart
                            </v-card-title>
                            <v-card-text>
                              <v-list>
                                <v-list-item>                                  
                                        <v-list-item-content class="overline" v-for="header in CartHeaders" :key="header.itemObjKey">
                                            {{header.text}}
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-for="item in CartItemsRender" :key="item.itemObjKey">
                                   
                                        <v-list-item-content v-for="header in CartHeaders" :key="header.itemObjKey">
                                          <v-avatar tile v-if="header.Type === 'Featured Photo'">
                                            <img :src="item.IMG"/>
                                          </v-avatar>
                                           <span  v-if="header.Type !== 'Featured Photo'">{{item[header.value]}}</span>
                                        </v-list-item-content>
                                        <v-icon color="red" small>mdi-delete-forever</v-icon>
                                    </v-list-item>
                              </v-list>
                            </v-card-text>
                            
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn :to="'/CheckOut/'"> Checkout</v-btn>
                            </v-card-actions>
                          </v-card>
                      </v-menu>
                      <v-menu
                           width="150"
                          bottom
                          left
                          offset-y
                          :close-on-content-click="false"
                      >
                      <template v-slot:activator="{ on }">
                        
                      <v-btn icon v-on="on"><v-icon>mdi-account</v-icon></v-btn>
                      </template>
                       <v-card tile>
                           <v-card-text>
                       <v-list class="transparent">
                            <v-list-item>
                            <v-btn text @click="ActivateStoreView(false)"><v-icon>mdi-account</v-icon>My Account</v-btn>
                            </v-list-item>
                            <v-list-item>
                            <v-btn v-bind="attrs" v-on="on" text @click="ConfirmlogoutFromFirebase()"><v-icon>mdi-exit-to-app</v-icon> Sign Out</v-btn> 
                            </v-list-item>
                       </v-list>
                           </v-card-text>
                       </v-card>
                      </v-menu>
    </v-app-bar>
<!--     
<v-navigation-drawer  v-model="storedrawer" app :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
          <v-card
            class="transparent pa-2"
            flat
            tile
          >
            <v-card  class="transparent" flat :dark="AppisDarkMode" height="100%">
                  <v-list-item class="overline" @click="DetermineAllProductsAction()">
                      All Products
                      </v-list-item>
                        <v-treeview
                        :style="NavFont"
                        open-all
                        dense
                        return-object
                        @update:active="onUpdateActiveProductFilter"
                        activatable
                            :items="ProductsClassesNEW"
                        ></v-treeview>
                        <v-divider>
                        </v-divider>
                        <v-list dense style="font-size:14px;">
                            <v-list-item class="overline">
                            Average Reviews
                            </v-list-item>
                            <v-list dense style="font-size:12px;">
                                <v-list-item>
                                    <v-rating
                                value="4"
                                readonly
                                background-color="orange lighten-3"
                                color="orange"
                                x-small
                                ></v-rating> & up
                                </v-list-item>
                                <v-list-item>
                                    <v-rating
                                value="3"
                                readonly
                                background-color="orange lighten-3"
                                color="orange"
                                x-small
                                ></v-rating> & up
                                </v-list-item>
                                <v-list-item>
                                    <v-rating
                                value="2"
                                readonly
                                background-color="orange lighten-3"
                                color="orange"
                                x-small
                                ></v-rating> & up
                                </v-list-item>
                                <v-list-item>
                                    <v-rating
                                value="1"
                                readonly
                                background-color="orange lighten-3"
                                color="orange"
                                x-small
                                ></v-rating> & up
                                </v-list-item>
                            </v-list>
                            <v-divider>
                        </v-divider>
                            <v-list dense style="font-size:14px;">                                          
                            <v-list-item  class="overline">
                            Brands
                            <v-icon v-if="ActiveBrandFilter" @click="ActiveBrandFilter = ''">mdi-close</v-icon>
                            </v-list-item>
                                <v-list dense style="font-size:12px;">
                                <v-list-item v-for="brand in StoreBrands" :key="brand.itemObjKey" @click="ActivateActiveBrandFilter(brand)">
                                    {{brand.Name}}
                                </v-list-item>
                                </v-list>
                        </v-list>
                            <v-divider>
                                    </v-divider>
                                        <v-list-item class="overline" v-if="pricemax && pricemin">
                                        Prices
                                        </v-list-item>
                                        <v-list-item>
                                            <v-range-slider
                                        
                                            v-model="pricerange"
                                            :max="1000"
                                            :min="200"
                                            hide-details
                                            class="align-center"
                                        >
                                            <template v-slot:prepend>
                                            <span class="caption">
                                                {{pricerange[0]}}</span>
                                            </template>
                                            <template v-slot:append>
                                            <span class="caption">
                                                {{pricerange[1]}}</span>
                                            </template>
                                        </v-range-slider>
                                        </v-list-item>
                                        <v-divider>
                                    </v-divider>
                                    <v-list dense style="font-size:14px;"  v-for="filter in StoreFilters" :key="filter.itemObjKey">
                                    
                                        <v-list-item  class="overline">
                                        {{filter.DisplayName}}
                                        <v-icon v-if="ActiveStoreFilters" @click="DeactivateStoreFilter(filter,option)">mdi-close</v-icon>
                                        </v-list-item>
                                            <v-list dense style="font-size:12px;">
                                            <v-list-item v-for="option in filter.Options" :key="option.itemObjKey" @click="ActivateStoreFilter(filter,option)">
                                                {{option.Name}}
                                            </v-list-item>
                                            </v-list>
                                    </v-list>
                                    </v-list>
                                </v-card>
                                
          </v-card>
</v-navigation-drawer> -->
<v-list height="90%" color="primary" dark v-if="!MiniView && MiniView">
   <v-icon @click="DeactivateActiveProduct()" class="soloactionicon red">mdi-close</v-icon>
        <!-- <v-list-item class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Price</span>
        </v-list-item> -->
         <v-list-item v-if="$route.name !== 'StoreItemSingle'" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Title</span>
        </v-list-item>
         <v-list-item v-if="$route.name !== 'StoreItemSingle'" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span >{{ThisProduct.Title}}</span>
        </v-list-item>
        <v-list-item >
            {{CurrencyFormatter(ThisProduct.Price,$store.state.DefaultCurrency.Currency)}}
        </v-list-item>
        <!-- <v-list-item>
            + X Shipping Fees
        </v-list-item> -->
        
        <!-- <v-list-item v-if="ActiveProductAvailableQty < 1">
            Arrives Feb 18 - March 16
        </v-list-item> -->
        <v-list-item class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">{{EstimatedArrivalName}}</span>
        </v-list-item>
        <!-- ActiveStore.StoreEstimatedArrivalFormula
        {{ActiveStore.StoreEstimatedArrivalFormula}} -->
        <v-list-item class="caption">            
            {{EstimatedArrival}}            
        </v-list-item>
         <v-list-item class="caption">
            <span class="yellow--text">{{ActiveStore.StoreEstimatedArrivalFormula}}</span>
        </v-list-item>
        <v-list-item class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Availability</span>
        </v-list-item>
         <v-list-item class="caption">
            <v-select :items="Warehouses" item-text="WarehouseName" v-model="ActiveWarehouse" return-object label="Warehouse" />
        </v-list-item>
        <v-list-item class="caption">
            {{Availability}}
        </v-list-item>
        <v-list-item v-if="Availability === 'In Stock'" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Stock On Hand</span>
        </v-list-item>
        <v-list-item v-if="Availability === 'In Stock'" class="caption">
            {{ActiveProductAvailableQty}}
        </v-list-item>
        <v-list-item>
            <v-text-field type="number" v-model.number="Qty" label="Order Qty"/>
        </v-list-item>
        <v-list-item v-if="ActiveWarehouse">
            <v-spacer></v-spacer>
            <v-btn color="#e67a00" @click="AddtoStoreCart()">
            
            {{IteminCart ? IteminCart.Qty+' in Cart' : 'Add to Cart'}}
            </v-btn>
        </v-list-item>
        <!-- <v-list-item >
            <v-spacer></v-spacer>
            <v-btn color="recordtoolbar" @click="AddtoWishlist()">
            
            {{IteminWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'}}
            </v-btn>
        </v-list-item> -->
        <!-- <v-list-item>
            <v-btn  color="#e67a00">
            Buy Now
            </v-btn>
        </v-list-item> -->
</v-list>
<!-- color="#323b44" -->
<!-- :style="{ backgroundImage: 'url(' + require('@/assets/RASolidA.jpeg') + ')' ,backgroundSize: 'cover'}"
 :app="!MiniView || $route.name !== 'StoreItemSingle'" -->
<v-navigation-drawer dark color="recordtoolbar" v-if="OrderNavbar" permanent :app="!MiniView || $route.name !== 'StoreItemSingle'" :style="OrderNavBarTemp ? 'z-index:400;position: fixed;' : ''" :absolute="MiniView && $route.name === 'StoreItemSingle'" right>
    <v-icon style="position: absolute;right: 5px;top:5px;z-index:401;" @click="DeactivateActiveProduct()" class="soloactionicon red">mdi-close</v-icon>
    <v-list height="90%">
        <!-- <v-list-item class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Price</span>
        </v-list-item> -->
        <v-list-item :dense="MiniView" v-if="$route.name !== 'StoreItemSingle'" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Title</span>
        </v-list-item>
         <v-list-item :dense="MiniView" v-if="$route.name !== 'StoreItemSingle'" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span >{{ThisProduct.Title}}</span>
        </v-list-item>
        <v-list-item :dense="MiniView" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Price</span>
        </v-list-item>
        <v-list-item :dense="MiniView" >
            {{CurrencyFormatter(ThisProduct.Price,$store.state.DefaultCurrency.Currency)}}
        </v-list-item>
        <!-- <v-list-item>
            + X Shipping Fees
        </v-list-item> -->
        
        <!-- <v-list-item v-if="ActiveProductAvailableQty < 1">
            Arrives Feb 18 - March 16
        </v-list-item> -->
        <v-list-item :dense="MiniView" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">{{EstimatedArrivalName}}</span>
        </v-list-item>
        <!-- ActiveStore.StoreEstimatedArrivalFormula
        {{ActiveStore.StoreEstimatedArrivalFormula}} -->
        <v-list-item :dense="MiniView" class="caption">            
            {{EstimatedArrival}}            
        </v-list-item>
         <v-list-item :dense="MiniView" class="caption">
            <span class="yellow--text">{{ActiveStore.StoreEstimatedArrivalFormula}}</span>
        </v-list-item>
        <v-list-item :dense="MiniView" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Availability</span>
        </v-list-item>
        <v-list-item :dense="MiniView" class="caption">
            <v-select :items="Warehouses" item-text="WarehouseName" v-model="ActiveWarehouse" return-object label="Warehouse" />
        </v-list-item>
        <v-list-item :dense="MiniView" class="caption">
            {{Availability}}
        </v-list-item>
        <v-list-item :dense="MiniView" v-if="Availability === 'In Stock'" class="subtleoverline recordtoolbar" style="text-transform:uppercase;">
            <span class="white--text">Stock On Hand</span>
        </v-list-item>
        <v-list-item v-if="Availability === 'In Stock'" class="caption">
            {{ActiveProductAvailableQty}}
        </v-list-item>
        <v-list-item :dense="MiniView">
            <v-text-field type="number" v-model.number="Qty" label="Order Qty"/>
        </v-list-item>
        <v-list-item :dense="MiniView" v-if="ActiveWarehouse">
            <v-spacer></v-spacer>
            <v-btn :small="MiniView" color="#e67a00" @click="AddtoStoreCart()">
            
            {{IteminCart ? IteminCart.Qty+' in Cart' : 'Add to Cart'}}
            </v-btn>
        </v-list-item>
        <!-- <v-list-item :dense="MiniView" >
            <v-spacer></v-spacer>
            <v-btn :small="MiniView" color="recordtoolbar" @click="AddtoWishlist()">
           <v-icon :small="MiniView">{{IteminWishlist ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
            {{IteminWishlist ? '(In Wishlist)' : 'Add Wishlist'}}
            </v-btn>
        </v-list-item> -->
        <!-- <v-list-item>
            <v-btn  color="#e67a00">
            Buy Now
            </v-btn>
        </v-list-item> -->
</v-list>
<!-- <v-list>
    <v-list-item>
        <v-spacer></v-spacer>
        <v-btn :to="'/Checkout'" class="mx-2" color="success" @click="AddtoStoreCart()">
           Checkout<v-badge :color="NotificationsColor" overlap
                              dark>

                              <v-icon
                              dark
                              v-on="on">
                              mdi-cash-register
                            </v-icon>

                            <span slot="badge"> {{CartItems.length}} </span>
                            </v-badge>
            </v-btn>
    </v-list-item>
</v-list> -->

</v-navigation-drawer>
</nav>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import GalleryTab from '@/components/WebPages/RenderComponents/GalleryTabReadOnly';
export default {
    props: ['ActiveStore','SystemEntities','ActiveProduct','CartItems','Directories','SitePages'],
    components: {
        GalleryTab
    },
    data(){
        return {
            StoreSearch: '',
            ActiveWarehouse: '',
        //     ActiveStore: {
        //     StoreCurrency: {
        //         Name: 'ZAR',
        //         Symbol: 'R'
        //     }
        // },
        // StoreProductsEntities: [],
        PageBGGradient: '',
            CartHeaders: [
              { text: 'Image', value: 'Image',class: "overline",Type: 'Featured Photo'},
              { text: 'Title', value: 'Item_Name',class: "overline",Type: 'Single Line Text'},
              { text: 'Qty', value: 'Qty',class: "overline",Type: 'Number Field'},
              { text: 'SubTotal', value: 'SubTotalDisplay',class: "overline",Type: 'Calculated Field',IsCurrency: true,Currency: 'ZAR'},
          ],
            Qty: 1,
            storedrawer: false,
            alignments: [
        'start',
        'center',
        'end',
      ],

            AllStoreProducts: [],
            pricerange: [0,5000],
            ActiveStoreFilters: {},
            ActiveBrandFilter: '',  
            ActiveProductFilter: '',
        }
    },
    computed: {
        StorePage(){
            return this.$route.name === 'StoreItemSingle'? this.SitePages.find(obj => obj.CatNavList === this.$route.params.slug) : ''
        },
        StorePagePath(){
            if(this.StorePage && this.StorePage.PageRoute){
                return this.StorePage.PageRoute
            }
            else if(this.StorePage){
                return '/'+this.StorePage.Name.split(' ').join('-')
            }
        },
        OrderNavBarTemp(){
            return this.$route.name === 'StoreItemSingle' && this.MiniView
        },
        OrderAutoBarActive(){
            return this.$route.name === 'StoreItemSingle' && !this.MiniView
        },
        ViewOrderNavbar(){
            return this.$store.state.ViewOrderNavbar
        },
        OrderNavbar(){
            return this.ThisProduct && this.ViewOrderNavbar
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        VuexStoreCatNavlist(){
        if(this.$route.meta && this.$route.meta.RouteEventID === 1045){
        return this.$store.state.CatNavlists.find(obj => obj.EntityID === this.$route.params.slug)
        }
    },
        VuexStoreStateProducts(){
            if(this.$route.meta && this.$route.meta.RouteEventID === 1045){
              let list = this.$store.state.CatNavlists.find(obj => obj.EntityID === this.$route.params.slug)
              return list.Data    
            }
            else{
                return []
            }
             
            
        },
        PurchasedWith(){
            return this.VuexStoreStateProducts.filter(prod => {
                if(this.ThisProduct){
                    //PurchasedWith

                }
                return prod
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        RouteObj(){
    return this.$route
  },
        WishListProduct(){
            let obj = {
            }
            if(this.ThisProduct && this.ThisProduct.id){
                obj.id = this.ThisProduct.id
                obj.Photo = this.ThisProduct.Photo.ThumbURL
                obj.IMG = this.ThisProduct.IMG
                obj.DescriptiveText = this.ThisProduct.DescriptiveText
                obj.Title = this.ThisProduct.Title,
                obj.Added_On = new Date(),
                obj.StoreCollection = this.$route.params.slug
            }
            return obj
        },
        WishListItems(){
            return this.DefaultBillingAccount && this.DefaultBillingAccount.WishList ? this.DefaultBillingAccount.WishList : []
        },
        IteminWishlist(){
            return this.ThisProduct && this.ThisProduct.id ? this.WishListItems.find(obj => obj.id === this.ThisProduct.id) : ''
        },
        IteminCart(){
            return this.ThisProduct && this.ThisProduct.id ? this.CartItems.find(obj => obj.id === this.ThisProduct.id) : ''
        },
        CartItemsRender(){
      return this.CartItems
      .map(item => {
        item.SubTotal = item.Unit_Price*item.Qty
        if(this.ActiveStore.StoreCurrency){
        item.SubTotalDisplay = this.CurrencyFormatter(item.Unit_Price*item.Qty,this.$store.state.DefaultCurrency.Currency)   
        }
        return item
      })
    },
        ActiveEntity(){
            return this.Directories.find(obj => obj.id === this.$route.params.slug)
        },
        Warehouses(){
            return this.ThisProduct ? this.ThisProduct.Warehouses : []
        },
        Availability(){
            return this.ActiveProductAvailableQty > 0 ? 'In Stock' : 'No Stock'
        },
        UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
      DefaultBillingAccount(){
          return this.UserBillingAccounts && this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) : ''
      },
        ActiveProductAvailableQty(){
            if(this.ActiveWarehouse){
                return this.ThisProduct.Warehouses
                .filter(wh => {
                    return wh.id === this.ActiveWarehouse.id
                })
                .map(wh => {
                    if(!wh.Available){
                                wh.Available = 0
                            }
                            return wh.Available
                }).reduce((a, b) => a + b, 0)
            } 
            else{
                return this.ThisProduct.Stock_Qty
            }
        },
        EstimatedArrivalName(){
            return this.ActiveStore.StoreEstimatedArrivalFormula === 'Based on Delivery Date' ? 'Estimated Delivery' : 'Collection Date'
        },
        EstimatedArrival(){
            if(this.ActiveEntity && this.ActiveEntity.StoreEstimatedArrivalFormula === 'Based on Delivery Date'){
            let today = new Date()
            let fromstocksupplydate = new Date()
            fromstocksupplydate.setDate(fromstocksupplydate.getDate() + this.ThisProduct.Supply_Leadtime)
            let fromordersupplydate = new Date()
            let totaldays = Number(this.ThisProduct.Supply_Leadtime)+Number(this.ThisProduct.Purchase_Leadtime)
            fromordersupplydate.setDate(fromordersupplydate.getDate() + totaldays)
            //this.ThisProduct
            return this.Availability === 'In Stock' && this.ActiveProductAvailableQty >= this.Qty ? fromstocksupplydate : fromordersupplydate    
            }
            else if(this.ActiveEntity && this.ActiveEntity.StoreEstimatedArrivalFormula === 'Based on Collection Date'){
            let today = new Date()
            let fromstocksupplydate = new Date()
            //fromstocksupplydate.setDate(fromstocksupplydate.getDate() + this.ThisProduct.Supply_Leadtime)
            let fromordersupplydate = new Date()
            //console.log(fromordersupplydate)
            let totaldays = Number(this.ThisProduct.Purchase_Leadtime)
            //console.log(this.ThisProduct,totaldays)
            fromordersupplydate.setDate(fromordersupplydate.getDate() + totaldays)
            //this.ThisProduct
            return this.Availability === 'In Stock' && this.ActiveProductAvailableQty >= this.Qty ? fromstocksupplydate : fromordersupplydate    
            }
            else if(this.ActiveWarehouse){
                //no formula
                let warehousentry = this.ThisProduct.Warehouses.find(obj => obj.id === this.ActiveWarehouse.id)
            let today = new Date()
            let fromstocksupplydate = new Date()
            fromstocksupplydate.setDate(fromstocksupplydate.getDate() + warehousentry.Supply_Leadtime)
            let fromordersupplydate = new Date()
            let totaldays = Number(warehousentry.Supply_Leadtime)+Number(warehousentry.Purchase_Leadtime)
            fromordersupplydate.setDate(fromordersupplydate.getDate() + totaldays)
            //this.ThisProduct
            return this.Availability === 'In Stock' && this.ActiveProductAvailableQty >= this.Qty ? fromstocksupplydate : fromordersupplydate
            }
            
        },
        ThisProductEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ThisProduct.EntityID)
        },
        ProductFinalClass(){
            return this.ThisProductEntity.Levels(lvl => {
                return lvl
            })
        },
        ProductFinalClassUp(){
            return this.ThisProductEntity.Levels(lvl => {
                return lvl
            })
        },
        ThisProductClassCrumbs(){
            return [{
                   text: 'BusinessUnits',
                    disabled: false,
                    to: '/BusinessUnits',
               },
               {
                   text: 'BusinessUnits2',
                    disabled: false,
                    to: '/BusinessUnits2',
               }]
        },  
        pricemax(){
            if(this.AllStoreProductsRendered.length > 0){
             let Prices = this.AllStoreProductsRendered
            .map(prod => {
                return prod.Price
            })
            //console.log(Prices,Math.max(...Prices))
            return Math.max(...Prices)   
            }
            
        },
        pricemin(){
            if(this.AllStoreProductsRendered.length > 0){
            let Prices = this.AllStoreProductsRendered
            .map(prod => {
                return prod.Price
            })
            //console.log(Prices)
            return Math.min(...Prices)
            }
        },
      NavFont(){
        if(this.NavStyleFont){
          return 'font-family: '+this.NavStyleFont+';font-size: 14px;'
        }
        else{
          return 'font-size: 14px;'
        }
        
      },
    //   StoreProductsEntities(){
    //         return this.SystemEntities.filter(entity => {
    //             return entity.IsStoreProduct
    //         })
    //     },
        StoreBrands(){
          return this.AllStoreProductsRendered
          .filter(prod => {
                return typeof prod.Brand !== 'undefined'
            })
            .map(entity => {
              return entity.Brand
            }).flat()
        },
        StoreFilters(){
          return this.ActiveStore.StoreProductsEntities
          .filter(entity => {
                return entity.StorehasFilter && entity.StoreFilters.length > 0
            })
            .map(entity => {
              return entity.StoreFilters
              .map(filter => {
                filter.EntityID = entity.id
                return filter
              })
            }).flat()
        },
        ProductsClassesNEW(){
            let entities = this.SystemEntities.filter(ent => {
                return ent.Entity_Type && ent.Entity_Type.Name === 'Store Product'
            }).map(ent => {
                let entityobj = Object.assign({},ent)
                ent.HierarchyFieldid = ent.AllFields.find(obj => obj.id === 'Product_Group')
                return entityobj
            })
            return entities
            .filter(entity => {
                return entity.HierarchyField
            })
            .map(entity => {
                let HierarchyField = entity.HierarchyField
                 if(HierarchyField && HierarchyField.Levels && HierarchyField.Options){
                let array = JSON.parse(JSON.stringify(HierarchyField.Options))      
                return array
                .map(option => {
                    //console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    id: option.ID,
                    EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                })
                
            }
            }).flat()  
        },
        AllStoreProductsRendered(){
            return this.VuexStoreStateProducts
            .filter(prod => {
                return prod.Price >= this.pricerange[0] && prod.Price <= this.pricerange[1]
            })
            .filter(prod => {              
              return typeof prod.FilterObstruction === 'undefined' || prod.FilterObstruction  === false
            })
            .filter(product => {
                if(this.ActiveProductFilter && this.ActiveProductFilter.EntityID){
                    let entityobj = this.ActiveStore.StoreProductsEntities.find(obj => obj.id === this.ActiveProductFilter.EntityID)
                    let HierarchyField = entityobj.HierarchyField
                    let level = HierarchyField.Levels.find(obj => obj.id === this.ActiveProductFilter.Levelid)
                    //console.log(this.ActiveProductFilter,HierarchyField)
                    return product.EntityID === this.ActiveProductFilter.EntityID && product[level.FieldName] && product[level.FieldName].ID === this.ActiveProductFilter.id
                }
                else{
                    return product
                }
            })
            .filter(prod => {
              if(this.ActiveBrandFilter){
                return prod.Brand.ID === this.ActiveBrandFilter.ID
              }
              else{
                return prod
            }
            })
            
            
        },
        ThisProduct(){
            return this.$route.meta && this.$route.meta.RouteEventID === 1045 ? this.VuexStoreStateProducts.find(obj => obj.id === this.$route.params.id) : this.ActiveProduct
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
    },
    watch: {
        OrderAutoBarActive(v){
        if(v){
          this.$store.commit('SetViewOrderNavbar',true)
        }
        else{
            this.$store.commit('SetViewOrderNavbar',false)
        }
        },
        ThisProduct: {
            handler: function(newvalue, oldvalue) {
                //console.log(newvalue, oldvalue,this.$route.meta.RouteEventID)
                if(newvalue && this.$route.name === 'StoreItemSingle' && this.ThisProduct.Warehouses){
                    let ent = this.SystemEntities.find(obj => obj.id === newvalue.EntityID)
                    let app = this.AppsDataBase.find(obj => obj.id === ent.Primary_Appid)
                    if(app.Default_Warehouseid){
                        this.ActiveWarehouse = this.ThisProduct.Warehouses.find(obj => obj.id === app.Default_Warehouseid)
                    }
                    else{                        
                        this.ActiveWarehouse = this.ThisProduct.Warehouses[0]
                    }
                    }
                    else if(newvalue){
                        let ent = this.SystemEntities.find(obj => obj.id === newvalue.EntityID)
                        //console.log(ent,this.AppsDataBase,this.$store.state.PluginDataBase)
                        let app = this.AppsDataBase.find(obj => obj.id === ent.Primary_Appid)
                        //console.log(app)
                        if(app.Default_Warehouseid){
                            this.ActiveWarehouse = this.ThisProduct.Warehouses.find(obj => obj.id === app.Default_Warehouseid)
                        }
                        else{                        
                            this.ActiveWarehouse = this.ThisProduct.Warehouses[0]
                        }
                    }
            },
            deep: true
        },
        RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    // alert('ohaohaoha')
                    if(this.$route.name !== 'StoreItemSingle'){
                        this.ActiveWarehouse = ''
                    }
                 
                 if(this.$route.params.slug && this.VuexStoreStateProducts.length === 0){
                    //this.GetAllStoreProducts()
                 }
                }
            },
            deep: true
        },
        Warehouses: {
            handler: function(newvalue, oldvalue) {
                //console.log(newvalue, oldvalue,this.Warehouses,this.ThisProduct)
                if(oldvalue !== newvalue){
                 if(this.Warehouses && this.Warehouses.length === 1){
                        //this.ActiveWarehouse = this.Warehouses[0]
                    }
                }
            },
            deep: true
        },
    },
    created(){
        //console.log('ThisProduct',this.ThisProduct,this.VuexStoreCatNavlist)
        //console.log('this.ActiveEntity',this.ActiveEntity)
        if(this.VuexStoreCatNavlist && !this.VuexStoreCatNavlist.Query){
                    this.GetAllStoreProducts()
                 }
        
        // if(this.Warehouses && this.Warehouses.length === 1){
        //     this.ActiveWarehouse = this.Warehouses[0]
        // }
        //console.log(this.Warehouses,this.ThisProduct)
    },
    methods: {
        ClearSearch(){
            if(!this.StoreSearch){
               this.$emit('ActivateStoreSearch',this.StoreSearch) 
            }
        },
        ActivateStoreSearch(){
            if(this.StorePagePath){
                this.$emit('ActivateStoreSearch',this.StoreSearch)
                this.$router.push(this.StorePagePath)                
            }
            else{
                this.$emit('ActivateStoreSearch',this.StoreSearch)
            }
        },
        ConfirmlogoutFromFirebase(){
        confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
        },
        logoutFromFirebase () {
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).update({
            onlinestatus: 'offline'
        })
        setTimeout(() => {
        this.$emit('SignoutNotifications')
        this.$router.replace('/Login')
        this.$store.dispatch('signOutAction')
        }, 500);      
        },
        ToggleAppDarkMode(){
        this.$emit('ToggleAppDarkMode',!this.$vuetify.theme.dark)
        
        },
        DetermineAllProductsAction(){
            this.ActiveProductFilter = ''
            if(this.$route.name === 'StoreItemSingle'){
                this.$router.push('/StoreProducts')
            }
        },
        onUpdateActiveProductFilter(selection) {
            let filter = selection[0]
            this.$router.push({ name: 'StoreProducts', params: {PreFilter: filter }})
            this.DeactivateActiveProduct()
    },
        DeactivateActiveProduct(){
            this.$store.commit('SetViewOrderNavbar',false)
            this.$emit('PushActiveProduct','')
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        AddtoWishlist(){
            if(this.DefaultBillingAccount){
             let WishList = []
            if(this.DefaultBillingAccount && this.DefaultBillingAccount.WishList){
                WishList = this.DefaultBillingAccount.WishList
            }
            let oncheck = WishList.find(obj => obj.id === this.WishListProduct.id)
            if(!oncheck){
                WishList.push(this.WishListProduct)
            }
            else{
                let index = WishList.indexOf(oncheck)
                WishList.splice(index,1)
            }
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.DefaultBillingAccount.id).update({
                WishList: WishList
            })   
            }
            
        },
        AddtoStoreCart(){
            let storeobj = {}
            //let warehousentry = this.ThisProduct.Warehouses.find(obj => obj.id === this.ActiveWarehouse.id)
            //These values cannot simply be assigned now,
            //We must structure the assigning to include Field Name and field Type in order to pass accordingly
            //when we submit the formdata
            // storeobj.
            //console.log(this.ThisProduct)
            storeobj.Item_Name = this.ThisProduct.Title
            storeobj.Description = this.ThisProduct.DescriptiveText
            storeobj.Warehouse = this.ActiveWarehouse
            //storeobj.Discount = this.ThisProduct.Discount
            storeobj.Discounted_Price = storeobj.Warehouse.Discounted_Price
            if(!storeobj.Discounted_Price){
                storeobj.Discounted_Price = storeobj.Warehouse.Price
            }
            storeobj.Price = storeobj.Warehouse.Price
            if(!storeobj.Price){
                storeobj.Price = 0
                storeobj.Discounted_Price = 0
            }
            //storeobj.Brand = this.ThisProduct.Brand
            storeobj.EntityID = this.ThisProduct.EntityID
            storeobj.Unit_Price = storeobj.Warehouse.Price
            if(!storeobj.Unit_Price){
                storeobj.Unit_Price = 0
            }
            // storeobj.Siteid = this.ThisProduct.Siteid
            storeobj.Product_Class = this.ThisProduct.Product_Class
            storeobj.Product_Group = this.ThisProduct.Product_Group
            storeobj.Product_Type = this.ThisProduct.Product_Type
            //console.log(this.EstimatedArrival)
            storeobj.Estimated_Arrival = format(this.EstimatedArrival,'yyyy-MM-dd')
            storeobj.ItemShips = this.ThisProduct.ItemShips
            storeobj.Stock_Qty = this.ActiveProductAvailableQty            
            storeobj.Qty = this.Qty,
            storeobj.Availability = this.Availability,
            
            storeobj.Time_Added = format(new Date(),'yyyy-MM-dd') //Time??   
            storeobj.IMG = this.ThisProduct.IMG     
            storeobj.id = this.ThisProduct.id
            //console.log(storeobj)
            this.$emit('AddItemtoCart',storeobj)
            this.Qty = 1
        },
        ActivateStoreView(boolean){
           this.$emit('ActivateStoreView',boolean)
        },
        GetAllStoreProducts(){
            let query = db.collection(this.$route.params.slug.split('_').join('').toLowerCase()+'store')
            if(this.VuexStoreStateProducts && this.VuexStoreStateProducts.length > 0){
                console.log('got it from store')
                this.AllStoreProducts = this.VuexStoreStateProducts
            }
            else{
                console.log('could not it from store')
                    let storeobjpayl = {
                        EntityID: this.$route.params.slug,
                        Collection: '',
                        Data: [],
                        Query: query,
                        CategoricalListType: 'Store Products'
                    }
                    this.$store.commit('SetCatNavlistTableQuery',storeobjpayl)
                    if(!this.VuexStoreStateProducts || this.VuexStoreStateProducts && this.VuexStoreStateProducts.length === 0){
                     let storedatapayload = {
                    EntityID: this.$route.params.slug,
                    Query: query,
                    CategoricalListType: 'Store Products'
                    }
                    this.$store.dispatch('GetCatNavlistDataArray',storedatapayload).then(result => {
                    this.AllStoreProducts = this.VuexStoreStateProducts
                    })   
                    }
                    else{
                        this.AllStoreProducts = this.VuexStoreStateProducts
                    }
            }
        },
    }
}
</script>