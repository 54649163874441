          
<template>
  <v-flex lg12 :style="userLoggedIn? 'padding-top:12px;' : 'margin-top: -31px;'">
    <v-dialog max-width="800" v-model="ComparingDialog">
            
                  <v-card class="white">                   
                    <v-card-title class="primary white--text overline"> Comparing {{CompareItems.length}} Items
                      <v-spacer></v-spacer>
                     
                      <v-btn @click="ComparingDialog = !ComparingDialog" dark icon><v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="primary white--text overline">
                      <v-btn small  @click="ClearComparisons()" dark text>
                        Clear <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-subtitle>
                    <v-card-text class="my-3">
                      <v-row>
                      <v-col
                          v-for="item in CompareItems" :key="item.itemObjKey"
                          :cols="12/CompareItems.length"
                          class="d-flex child-flex"
                          >
                          <ClassifiedListingListItem :TaggedFields="[]" :TagField="CurrentEntity.TagField" :CurrentEntity="CurrentEntity"
                            :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription"
                            :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                            :FeatureList="FeatureList" :CompareItems="CompareItems" :CartItems="CartItems"
                            />
                            </v-col>
                      </v-row>
                      <v-row>
                        <v-tabs centered>
                                <v-tab v-if="CurrentEntity.CategoricalListType !== 'Social Groups'">Overview
                                </v-tab>
                                <v-tab-item v-if="CurrentEntity.CategoricalListType !== 'Social Groups'">
                                  <v-row>
                                      <v-col
                                        v-for="item in CompareItems" :key="item.itemObjKey"
                                        class="d-flex child-flex"
                                        >
                                      <v-list>
                                        <!-- <span v-for="filter in NavList" :key="filter.itemObjKey"> -->
                                          <v-list-item class="detailslistoutline" v-for="filter in CompareNavList" :key="filter.itemObjKey">
                                            <v-list-item-content>
                                                {{filter.DisplayName}}
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                <FieldViewerComponent :field="filter" :Record="item"
                                                />
                                            </v-list-item-content>
                                          </v-list-item>                                    
                                        <!-- </span> -->
                                      </v-list>
                                      </v-col>
                                  </v-row>
                                  
                                </v-tab-item>
                                <v-tab>Features
                                </v-tab>
                                <v-tab-item>
                                  <v-row>
                                  <v-col
                                    v-for="item in CompareItems" :key="item.itemObjKey"
                                    class="d-flex child-flex"
                                    >
                                  <v-list>
                                    <v-list-item v-for="feature in FeatureList" :key="feature.itemObjKey">
                                      <v-list-item-content>
                                        {{feature.DisplayName}}
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-icon v-if="item[feature.DisplayName]" color="green">mdi-check</v-icon>
                                        <v-icon v-else color="red">mdi-close</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-action>
                                      <v-menu offset-y :close-on-content-click="false" bottom left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-if="feature.HoverDescription" small v-on="on">mdi-information-outline</v-icon>
                                        </template>                         
                                          <v-card width="200" shaped elevation="6">
                                            <v-card-title>
                                              {{feature.DisplayName}}
                                            </v-card-title>
                                            <v-img v-if="!feature.DescriptionIMG"
                                              src="@/assets/ImageHolder.png"
                                              height="100px"
                                            ></v-img>
                                            <v-img v-if="feature.DescriptionIMG"
                                              :src="feature.DescriptionIMG"
                                              height="100px"
                                            ></v-img>
                                            
                                            <v-card-text v-html="feature.HoverDescription"/>
                                          </v-card>
                                        </v-menu>
                                      </v-list-item-action>

                                    </v-list-item>
                                     <v-card-actions class="justify-end" v-if="item.Location">
                                        <v-btn icon @click="ViewMapItem(item.Location.AddressLine1,item.Location.AddressLine2,item.Location.AddressLineCity,item.Location.AddressLinePostalCode,item.Location.AddressLineProvince,item.Location.AddressLineCountry)"><v-icon>mdi-google-maps</v-icon></v-btn>{{item.Location.Name}}
                                        <v-btn icon :href="'tel:'+item.Location.Telephone"><v-icon>mdi-phone</v-icon></v-btn>{{item.Location.Telephone}}
                                    </v-card-actions>
                                  </v-list>
                                  </v-col>
                                  </v-row>
                                </v-tab-item>
                              </v-tabs>
                      </v-row>
                      </v-card-text>
                  </v-card>
              </v-dialog>
              <div v-if="FilterListDialog" class="flex xl2 lg3 md4 sm12 xs12">
              <v-card :dark="AppisDarkMode" class="background" height="100%">
                  <v-card-title class="mediumoverline">
                    <v-icon>mdi-feature-search-outline</v-icon>Search
                    </v-card-title>
                    <v-card-title v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Social Groups'">
                      <v-combobox
                      :disabled="KeyWords.length === 0"
                          v-model="KeyWord"
                          :items="KeyWords"
                          label="KeyWords/ Products"                        
                          chips
                          >
                          <template v-slot:selection="data">
                              <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                              >
                              <v-avatar
                                  class="accent white--text"
                                  left
                                  v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}
                              </v-chip>
                              </template>
                      </v-combobox>
                    </v-card-title>
                    <v-card-title v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members'">
                      <v-combobox
                      :disabled="KeyWords.length === 0"
                          v-model="KeyWordObj"
                          :items="KeyWords"
                          item-text="Name"
                          return-object
                          label="KeyWords/ Products"                        
                          chips
                          >
                          <!-- <template v-slot:selection="data">
                              <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                              >
                              <v-avatar
                                  class="accent white--text"
                                  left
                                  v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}
                              </v-chip>
                              </template> -->
                      </v-combobox>
                    </v-card-title>
                    
                    <v-list-item v-if="WebAppBuilderActive && CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members'" @click="WebDevelopers = !WebDevelopers">
                      <v-list-item-avatar v-if="WebDevelopers" size="40">
                        <v-icon  size="25">mdi-close</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-avatar color="pink" size="40">
                        <v-icon dark size="25">mdi-apps</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        Web Developers
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-subtitle v-if="$route.name.includes('DirectoryBuilder')">
                        <v-btn @click="SaveNavList()">Save</v-btn>
                        <v-btn @click="ActivateNewNavItemDialog()">Add New</v-btn>
                    </v-card-subtitle>
                    <v-card-subtitle v-if="$route.name.includes('DirectoryBuilder') && this.CurrentEntity && this.CurrentEntity.CategoricalListType === 'Store Products'">
                        <v-btn @click="SaveNavList()">Save</v-btn>
                        <v-btn @click="ActivateNewNavItemDialog()">Add New</v-btn>
                    </v-card-subtitle>
                  <!-- <v-card :dark="AppisDarkMode" tile flat class="transparent"> -->
                      <v-divider>
                    </v-divider><span v-for="(navitem,navitemindex) in ComputedNavList" :key="navitem.itemObjKey">
                        <TreeViewComp v-if="navitem.Type === 'TreeView'" :navitem="navitem" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        @ActivateTreeNode="ActivateTreeNode" :KeyWord="KeyWord"
                        />
                        <SingleOptionFilterComp v-if="navitem.Type === 'Single Option Filter' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        />
                        <TextSearchComp v-if="navitem.Type === 'Text Search' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        />
                        <SingleNumberFilterComp v-if="navitem.Type === 'Single Number Filter' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        />
                        <FilterListComp v-if="navitem.Type === 'Filter List' && navitem.Filters && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        @EditNavItemSubItem="EditNavItemSubItem"
                        />
                        <RatingsComp v-if="navitem.Type === 'Rating' && navitem.Filters && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        />
                        <v-divider v-if="navitem.Type !== 'Text Search'">
                    </v-divider></span>
                  </v-card>
        </div>
        <!-- <v-layout column style="position: absolute;top: 20px;left: 20px;z-index: 100;" class="hidden-md-and-up">
                  <v-icon @click="FilterListDialog = !FilterListDialog" class="soloactionicon" color="warning">mdi-view-list</v-icon>
                </v-layout>  -->
      <v-row v-if="!MiniView" style="height: 100%;" >
          <!-- <v-row> -->
            
              <v-col cols="3" :style="IntranetView? 'margin-top:90px;' : 'padding: 0px;'">
                   <!-- <v-row> -->
                    
                <v-card :dark="AppisDarkMode" class="background" height="100%">
                <v-card-title class="mediumoverline">
                  <v-icon>mdi-feature-search-outline</v-icon>Search
                  </v-card-title>
                  <v-card-title v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Social Groups'">
                     <v-combobox
                     :disabled="KeyWords.length === 0"
                        v-model="KeyWord"
                        :items="KeyWords"
                        label="KeyWords/ Products"                        
                        chips
                        >
                        <template v-slot:selection="data">
                            <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                            >
                            <v-avatar
                                class="accent white--text"
                                left
                                v-text="data.item.slice(0, 1).toUpperCase()"
                            ></v-avatar>
                            {{ data.item }}
                            </v-chip>
                            </template>
                    </v-combobox>
                  </v-card-title>
                  <v-card-title v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members' || 
                  CurrentEntity && CurrentEntity.CategoricalListType === 'Web Apps'">
                      <v-combobox
                      :disabled="KeyWords.length === 0"
                          v-model="KeyWordObj"
                          :items="KeyWords"
                          item-text="Name"
                          return-object
                          label="KeyWords/ Products"                        
                          chips
                          >
                          <!-- <template v-slot:selection="data">
                              <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                              >
                              <v-avatar
                                  class="accent white--text"
                                  left
                                  v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}
                              </v-chip>
                              </template> -->
                      </v-combobox>
                    </v-card-title>
                    <v-list-item v-if="WebAppBuilderActive && CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members'" @click="WebDevelopers = !WebDevelopers">
                       <v-list-item-avatar v-if="WebDevelopers" size="40">
                        <v-icon  size="25">mdi-close</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-avatar color="pink" size="40">
                        <v-icon dark size="25">mdi-apps</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        Web Developers
                      </v-list-item-content>
                    </v-list-item>
                  <v-card-subtitle v-if="$route.name.includes('DirectoryBuilder')">
                      <v-btn @click="SaveNavList()">Save</v-btn>
                      <v-btn @click="ActivateNewNavItemDialog()">Add New</v-btn>
                      <v-btn v-if="ProvidedCurrentEntity && ProvidedCurrentEntity.id === 'Group_Categories'
                      || ProvidedCurrentEntity && ProvidedCurrentEntity.id === 'Business_Members'
                      || ProvidedCurrentEntity && ProvidedCurrentEntity.id === 'Featured_Members'" @click="ActivateNewFieldDialog()">Add Field</v-btn>
                  </v-card-subtitle>
                    <v-card-subtitle v-if="$route.name.includes('DirectoryBuilder') && this.CurrentEntity && this.CurrentEntity.CategoricalListType === 'Store Products'">
                       <v-switch v-model="CurrentEntity.Has_Ratings" label="Has Ratings" />                       
                       <v-select @change="UpdateRatingModerators()" multiple v-model="CurrentEntity.RatingModerators" :items="ModeratorUsers" label="Rating Moderators" return-object :item-text="'Full_Name'"/>
                    </v-card-subtitle>
                <!-- <v-card :dark="AppisDarkMode" tile flat class="transparent"> -->
                    <v-divider>
                  </v-divider><span v-for="(navitem,navitemindex) in ComputedNavList" :key="navitem.itemObjKey" >
                      <TreeViewComp v-if="navitem.Type === 'TreeView'" :navitem="navitem" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      @ActivateTreeNode="ActivateTreeNode" :KeyWord="KeyWord"
                      />
                      <SingleOptionFilterComp v-if="navitem.Type === 'Single Option Filter' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      />
                       <TextSearchComp v-if="navitem.Type === 'Text Search' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        />
                      <SingleNumberFilterComp v-if="navitem.Type === 'Single Number Filter' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      />
                      <FilterListComp v-if="navitem.Type === 'Filter List' && navitem.Filters && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      @EditNavItemSubItem="EditNavItemSubItem"
                      />
                      <RatingsComp v-if="navitem.Type === 'Rating' && navitem.Filters && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      />
                      <v-btn @click="MoveItem(navitem,navitemindex,'Up')" v-if="$route.name.includes('DirectoryBuilder') && ComputedNavList[navitemindex-1]" x-small>Move Up<v-icon>mdi-chevron-up</v-icon></v-btn>
                      <v-btn @click="MoveItem(navitem,navitemindex,'Down')" v-if="$route.name.includes('DirectoryBuilder') && ComputedNavList[navitemindex-1+2]" x-small>Move Down<v-icon>mdi-chevron-down</v-icon></v-btn>
                       <v-divider v-if="navitem.Type !== 'Text Search'">
                  </v-divider></span>
                </v-card>
              </v-col>
              <v-col  :cols="9" style="padding: 0px;height: 100%;">
                  <StoreProducts v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Web Store Products'"
                    :NavList="NavList"
                   />
                   <SocialClassifieds v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Social Classifieds' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :System="System" 
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Classified Listing' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :CurrentEntity="CurrentEntity"
                    @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="FeatureList" @AddtoCompare="AddtoCompare" :CompareItems="CompareItems"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Store Products' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView" :CartItems="CartItems"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :CurrentEntity="CurrentEntity" @PushActiveProduct="PushActiveProduct"
                    @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="FeatureList" @AddtoCompare="AddtoCompare" :CompareItems="CompareItems" :System="System" @ActivateTreefromChild="ActivateTreefromChild"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Wiki Listing' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :CurrentEntity="CurrentEntity"
                    @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="FeatureList" @AddtoCompare="AddtoCompare" :CompareItems="CompareItems" :Record="Record"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Social Groups'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Business Members'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                   />
                    <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                    :KeyWordObj="KeyWordObj" :WebDevelopers="WebDevelopers"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Web Apps'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                    :KeyWordObj="KeyWordObj"
                   />
              </v-col>
              <v-btn v-if="$route.name.includes('DirectoryBuilder')" @click="ActivateFeaturesListConfigDialog()" fab style=" position: fixed;top: 110px;right: 50px;z-index: 5" dark color="red">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>                
              <v-dialog width="400" v-model="FeatureDialog">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" v-if="FeatureList && FeatureList.length > 0" fab style=" position: fixed;top: 180px;right: 50px;z-index: 5" dark color="green">
                  <v-icon>mdi-format-list-checks</v-icon>
                </v-btn>                
              </template>
                  <v-card class="white">
                    <v-card-title class="primary white--text overline">Feature Filter
                      <v-spacer></v-spacer>
                      <v-btn @click="FeatureDialog = !FeatureDialog" dark icon><v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="primary white--text overline">
                      <v-btn small @click="ClearFeatures()" dark text>
                        Clear <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-chip-group
                            active-class="primary--text"
                            column
                            multiple
                        >
                          <span class="featureitem" v-for="item in FeatureList" :key="item.itemObjKey" >
                        <v-chip small filter                         
                          v-model="item.Filter"
                          >                          
                            {{item.DisplayName}}
                          </v-chip>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                        <template v-slot:activator="{ on }">
                          <v-icon v-if="item.HoverDescription" small v-on="on">mdi-information-outline</v-icon>
                        </template>                         
                          <v-card width="200" shaped elevation="6">
                            <v-card-title>
                              {{item.DisplayName}}
                            </v-card-title>
                            <v-img v-if="!item.DescriptionIMG"
                              src="@/assets/ImageHolder.png"
                              height="100px"
                            ></v-img>
                            <v-img v-if="item.DescriptionIMG"
                              :src="item.DescriptionIMG"
                              height="100px"
                            ></v-img>
                            
                            <v-card-text v-html="item.HoverDescription"/>
                          </v-card>
                        </v-menu>
                        </span>
                         </v-chip-group> 
                      </v-card-text>
                  </v-card>
              </v-dialog>
                <v-btn v-if="CompareItems && CompareItems.length > 1" fab @click="ComparingDialog = !ComparingDialog" style=" position: fixed;top: 250px;right: 50px;z-index: 5" dark color="warning">
                  <v-icon>mdi-compare</v-icon> {{CompareItems.length}}
                </v-btn>     
      </v-row>
<v-row v-if="MiniView" style="margin-top:2px;">
          <!-- <v-row> -->
             
              <v-col :cols="12" :style="IntranetView? 'margin-top:90px;margin-bottom:100px;' : 'margin-bottom:20px;'">
                <v-card-title v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members' || 
                  CurrentEntity && CurrentEntity.CategoricalListType === 'Web Apps'">
                      <v-combobox
                      :disabled="KeyWords.length === 0"
                          v-model="KeyWordObj"
                          :items="KeyWords"
                          item-text="Name"
                          return-object
                          label="KeyWords/ Products"                        
                          chips
                          >
                          <!-- <template v-slot:selection="data">
                              <v-chip
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                              >
                              <v-avatar
                                  class="accent white--text"
                                  left
                                  v-text="data.item.slice(0, 1).toUpperCase()"
                              ></v-avatar>
                              {{ data.item }}
                              </v-chip>
                              </template> -->
                      </v-combobox>
                    </v-card-title>
                <span        
                    class="mb-2 justify-center" v-for="(navitem,navitemindex) in ComputedNavList" :key="navitem.itemObjKey"
                  >
                  <TreeViewComp v-if="navitem.Type === 'TreeView'" :navitem="navitem" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      @ActivateTreeNode="ActivateTreeNode" :KeyWord="KeyWord" :ListPrimaryField="ListPrimaryField"
                      />
                      <SingleOptionFilterComp v-if="navitem.Type === 'Single Option Filter' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      />
                       <TextSearchComp v-if="navitem.Type === 'Text Search' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                        />
                      <SingleNumberFilterComp v-if="navitem.Type === 'Single Number Filter' && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      />
                      <FilterListComp v-if="navitem.Type === 'Filter List' && navitem.Filters && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      @EditNavItemSubItem="EditNavItemSubItem"
                      />
                      <RatingsComp v-if="navitem.Type === 'Rating' && navitem.Filters && !Refreshing" :navitem="navitem" @ActivateFilter="ActivateFilter" :navitemindex="navitemindex" @EditNavItem="EditNavItem"
                      />
                </span>
              </v-col>
              <v-col  :cols="12">
                
                  <StoreProducts v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Web Store Products'"
                    :NavList="NavList"
                   />
                   <SocialClassifieds v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Social Classifieds' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :System="System" 
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Classified Listing' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :CurrentEntity="CurrentEntity"
                    @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="FeatureList" @AddtoCompare="AddtoCompare" :CompareItems="CompareItems"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Store Products' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView" :CartItems="CartItems"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :CurrentEntity="CurrentEntity" @PushActiveProduct="PushActiveProduct"
                    @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="FeatureList" @AddtoCompare="AddtoCompare" :CompareItems="CompareItems" :System="System" @ActivateTreefromChild="ActivateTreefromChild"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Wiki Listing' && ClassifiedsClasses" @IntranetViewToggle="IntranetViewToggle"
                    :NavList="NavList" :ClassifiedsClasses="ClassifiedsClasses" :mainclass="level1" :subclass="level2" :thirdclass="level3" :IntranetView="IntranetView"
                    :ActiveFilters="ActiveFilters" @ActivateViewPortFilter="ActivateViewPortFilter" :AdditionalFilters="AdditionalFilters" :CurrentEntity="CurrentEntity"
                    @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="FeatureList" @AddtoCompare="AddtoCompare" :CompareItems="CompareItems" :Record="Record"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Social Groups'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Business Members'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                   />
                      <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Featured Members'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                    :KeyWordObj="KeyWordObj" :WebDevelopers="WebDevelopers"
                   />
                   <ClassifiedListing v-if="CurrentEntity && CurrentEntity.CategoricalListType === 'Web Apps'" :AdditionalFilters="AdditionalFilters" :mainclass="level1" :subclass="level2" :thirdclass="level3"
                   :KeyWords="KeyWords" :KeyWord="KeyWord" @UpdateComputedKeyWords="UpdateComputedKeyWords" @AddtoCompare="AddtoCompare"
                    :NavList="NavList" :CurrentEntity="CurrentEntity" :ActiveFilters="ActiveFilters"  @ActivateViewportBuilder="ActivateViewportBuilder" :FeatureList="CurrentEntity.FeatureList" :CompareItems="CompareItems"
                    :KeyWordObj="KeyWordObj"
                   />
              </v-col>
              <v-btn v-if="$route.name.includes('DirectoryBuilder')" @click="ActivateFeaturesListConfigDialog()" fab style=" position: fixed;top: 110px;right: 50px;z-index: 5" dark color="red">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>                
              <v-dialog width="400" v-model="FeatureDialog">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" v-if="FeatureList && FeatureList.length > 0" fab style=" position: fixed;top: 180px;right: 50px;z-index: 5" dark color="green">
                  <v-icon>mdi-format-list-checks</v-icon>
                </v-btn>                
              </template>
                  <v-card class="white">
                    <v-card-title class="primary white--text overline">Feature Filter
                      <v-spacer></v-spacer>
                      <v-btn @click="FeatureDialog = !FeatureDialog" dark icon><v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="primary white--text overline">
                      <v-btn small @click="ClearFeatures()" dark text>
                        Clear <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-chip-group
                            active-class="primary--text"
                            column
                            multiple
                        >
                          <span class="featureitem" v-for="item in FeatureList" :key="item.itemObjKey" >
                        <v-chip small filter                         
                          v-model="item.Filter"
                          >                          
                            {{item.DisplayName}}
                          </v-chip>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                        <template v-slot:activator="{ on }">
                          <v-icon v-if="item.HoverDescription" small v-on="on">mdi-information-outline</v-icon>
                        </template>                         
                          <v-card width="200" shaped elevation="6">
                            <v-card-title>
                              {{item.DisplayName}}
                            </v-card-title>
                            <v-img v-if="!item.DescriptionIMG"
                              src="@/assets/ImageHolder.png"
                              height="100px"
                            ></v-img>
                            <v-img v-if="item.DescriptionIMG"
                              :src="item.DescriptionIMG"
                              height="100px"
                            ></v-img>
                            
                            <v-card-text v-html="item.HoverDescription"/>
                          </v-card>
                        </v-menu>
                        </span>
                         </v-chip-group> 
                      </v-card-text>
                  </v-card>
              </v-dialog>
                <v-btn v-if="CompareItems && CompareItems.length > 1" fab @click="ComparingDialog = !ComparingDialog" style=" position: fixed;top: 250px;right: 50px;z-index: 5" dark color="warning">
                  <v-icon>mdi-compare</v-icon> {{CompareItems.length}}
                </v-btn>     
      </v-row>
  </v-flex>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ClassifiedListingListItem from '@/components/Directories/ClassifiedListingListItem';
import ClassifiedListing from '@/components/Directories/ClassifiedListing';
import SocialClassifieds from '@/components/SuitePlugins/Classifieds/Classifieds';
import TreeViewComp from '@/components/Directories/ListComponents/TreeViewComp';
import TextSearchComp from '@/components/Directories/ListComponents/TextSearchComp';
import SingleOptionFilterComp from '@/components/Directories/ListComponents/SingleOptionFilterComp';
import SingleNumberFilterComp from '@/components/Directories/ListComponents/SingleNumberFilterComp';
import FilterListComp from '@/components/Directories/ListComponents/FilterListComp';
import RatingsComp from '@/components/Directories/ListComponents/RatingsComp';
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';

export default {
  props: ['ProvidedNavList','ProvidedCurrentEntity','ProvidedFeaturesList','IntranetView','ProvidedAdditionalFilters','Record','CartItems','System','FeaturedMemberFilters','PageData'],
  components: {
    ClassifiedListingListItem,
    ClassifiedListing,
    SocialClassifieds,
    TreeViewComp,
    TextSearchComp,
    SingleOptionFilterComp,
    SingleNumberFilterComp,
    FilterListComp,
    RatingsComp,
    FieldViewerComponent
  },
  data() {
    return {
      PrimaryFilterApplied: false,
      WebDevelopers: false,
      FilterListDialog: false,
      FeatureDialog: false,
      ComparingDialog: false,
      Refreshing: false,
        AdditionalFilters: [],
        ActiveFilters: [],
        CompareItems: [],
        ActiveTreeNode: '',
        ListPrimaryField: '',
        CurrentEntity: '',
        NavList: [],
        ClassifiedsClasses: [],
        level1: '',
        level2: '',
        level3: '',
        KeyWords: [],
        KeyWord: '',
        KeyWordObj: '',
    }
  },

  mounted() {

  },
  
  created(){  
    if(this.FeaturedMemberFilters){
      if(this.FeaturedMemberFilters.find(obj => obj.Prop === 'WebDevelopers')){
        this.WebDevelopers = true
      }
    }
    //console.log('state',this.$store.state.CatNavlists,this.ProvidedCurrentEntity)
    if(this.ProvidedCurrentEntity){
      //console.log('this.ProvidedCurrentEntity',this.ProvidedCurrentEntity)
      this.NavList = this.ProvidedNavList
      this.CurrentEntity = this.ProvidedCurrentEntity
      this.FeatureList  = this.CurrentEntity.FeatureList
      // if(this.FeatureList.FeatureListType === 'Lookup'){
      //   this.GetFeatureListEntries(db.collection('Libraries').doc(this.ActiveSuiteid).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID),this.FeatureList)
      // }
      // /FeatureListType === 'Lookup
      if(this.CurrentEntity.CategoricalListType === 'Social Classifieds'){
        this.ClassifiedsClasses = this.CurrentEntity.ListPrimaryField.Options
      }
      if(this.CurrentEntity.CategoricalListType === 'Classified Listing' && this.CurrentEntity.ListPrimaryField){
        this.ClassifiedsClasses = this.CurrentEntity.ListPrimaryField.Options
      }
      if(this.CurrentEntity.CategoricalListType === 'Social Groups'){
        this.ListPrimaryField = this.CurrentEntity.ListPrimaryField
        //console.log('this.ListPrimaryField',this.ListPrimaryField)
      }
      
      if(this.CurrentEntity.CategoricalListType === 'Store Products'){        
        if(this.CurrentEntity.Cart_Entity){
         this.$store.commit('SetCartEntity',this.CurrentEntity.Cart_Entity) 
        }        
        this.ActivateStoreView(true)
        this.ListPrimaryField = this.CurrentEntity.ListPrimaryField
        this.ClassifiedsClasses = this.CurrentEntity.ListPrimaryField.Options
        //console.log('this.ListPrimaryField',this.ListPrimaryField)
        //console.log(this.CurrentEntity)
        this.CurrentEntity.NavList = this.CurrentEntity.NavList.map(navlist => {
          //JUST KNOW THIS WILL NEVER BE ALLOWED ON STOREACTIVETEMPLATE SO PREVENT THESE FIELD TYPES PLEASE!
          if(navlist.Type === 'Single Option Filter' && navlist.Field && navlist.Field.Type === 'Lookup'){
            this.GetFilterOptions(db.collection('Libraries').doc(this.ActiveSuiteid).collection('Directories').doc(this.CurrentEntity.id).collection(navlist.Field.RelatedBuildID),navlist.Field)
          }
          if(navlist.Type === 'Filter List' && navlist.Filters){
            navlist.Filters = navlist.Filters.map(field => {
              if(field.Type === 'Lookup'){
                this.GetFilterOptions(db.collection('Libraries').doc(this.ActiveSuiteid).collection('Directories').doc(this.CurrentEntity.id).collection(field.RelatedBuildID),field)
              }
              
              return field
            })
            
          }
          //console.log(navlist)
          return navlist
        })
      }
    }

    else if(this.$route.name === 'Classifieds' || this.$route.name === 'MarketplaceTemplateClassifieds'){
      this.GetDirectory('Classifieds')
    }
    else if(this.$route.name === 'StoreProducts'){
      this.GetDirectory('Store_Products')
    }
    else if(this.$route.name === 'Groups'){
      this.GetDirectory('Groups')
    }
    else if(this.$route.name === 'Group Featured Members'){
      this.GetDirectory('Group_Featured_Members')
    }
    else if(this.$route.name === 'Featured Members'){
      this.GetDirectory('Featured_Members')
    }
   
    if(this.PrimaryCategoryFilter && !this.PrimaryFilterApplied){
    
      this.ApplyPrimaryfilter()
      
    }
    //console.log(this.FeatureList,this.ProvidedFeatureList,this.CurrentEntity)
    // console.log(this.FeatureList,this.NavList)
    // //   alert('something')
    //   this.FilterPropCheck()
    //   this.ViewOptionsAssign()
  },
  computed: {
    PrimaryCategoryFilter(){
      return this.PageData && this.PageData.Primary_Category ? this.PageData.Primary_Category : ''
    },
    PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
    WebAppBuilderActive(){
        return this.PluginDataBase && this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active
      },
      ActiveSuiteid(){
          return this.$store.state.ActiveSuiteid
        },
        Dirid(){
            return this.ActiveMarketTemplate ? this.$route.params.slug : this.$route.params.id
        },
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
        },
        ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
        ConfigPath(){
            return this.ActiveSuiteTemplate ? db.collection('Libraries').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
        },
    
    UsersArray(){
      return this.$store.state.UsersArray
    },
    ModeratorUsers(){
      return this.NonGuestUsers.map(user => {
        let userobj ={
          id: user.id,
          Full_Name: user.Full_Name
        }
        return userobj
      })
    },
    NonGuestUsers(){
      return this.UsersArray.filter(item => {
        return !item.IsGuestUser && item.Business_Unitid && item.Business_Unitid !== 'Guest'
      })
    },
    MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    userLoggedIn () {
      return this.$store.getters.user
    },
    CompareNavList(){
      let arr1 =  this.NavList.filter(filter => {
        return filter.Field && !filter.Filters && filter.Type !== 'TreeView'
      })
      .map(filter => {
        return filter.Field
      })
      let arr2 = this.NavList.filter(filter => {
        return filter.Filters
      }).map(filter => {
        return filter.Filters
      }).flat()
      .filter(filter => {
        
        return !arr1.find(obj => obj.Name === filter.Name)
      })
      return arr1.concat(arr2)
    },
    ComputedNavList(){
      if(this.$route.name.includes('DirectoryBuilder')){
        return this.NavList.concat(this.AdditionalFilters)
        //.reverse()
      }
      else{
        return this.NavList.concat(this.AdditionalFilters).map(navlist => {
          if(navlist.Type === 'TreeView'){
            navlist.TreeList = navlist.TreeList.sort((a, b) => {
                  var key1 = a.name;
                  var key2 = b.name;

                  if (key1 < key2) {
                      return -1;
                  } else if (key1 == key2) {
                      return 0;
                  } else {
                      return 1;
                  }
            })
            navlist.TreeList = navlist.TreeList.map(top => {
              if(top.children){
                top.children = top.children.sort((a, b) => {
                      var key1 = a.name;
                      var key2 = b.name;

                      if (key1 < key2) {
                          return -1;
                      } else if (key1 == key2) {
                          return 0;
                      } else {
                          return 1;
                      }
                })
                top.children = top.children.map(child => {
                  if(child.children){
                    child.children = child.children.sort((a, b) => {
                      var key1 = a.name;
                      var key2 = b.name;

                      if (key1 < key2) {
                          return -1;
                      } else if (key1 == key2) {
                          return 0;
                      } else {
                          return 1;
                      }
                })
                  }
                  return child
                })
              }
              return top
            })
          }
          return navlist
        })
        //.reverse()
      }
      
    },
      NavFont(){
        if(this.NavStyleFont){
          return 'font-family: '+this.NavStyleFont+';font-size: 14px;'
        }
        else{
          return 'font-size: 14px;'
        //   border: 0.2px solid;border-color:rgba(107, 107, 107, 0.042);
        }
        
      },
      
    FilterItem(){
        return this.$route.query.Record
      },
    },
  watch: {
    
    ProvidedNavList: {
            handler: function(oldvalue, newvalue) {
              //console.log(oldvalue,newvalue)
              this.NavList = this.ProvidedNavList
              //this.FilterPropCheck()
            },
            deep: true
        },
     ProvidedAdditionalFilters: {
            handler: function(oldvalue, newvalue) {
              //console.log(oldvalue,newvalue)
              this.AdditionalFilters = this.ProvidedAdditionalFilters
              //console.log(this.AdditionalFilters)
              //this.FilterPropCheck()
            },
            deep: true
        },
        
    //   ActiveTreeNode (value){
    //       if(value && typeof value !== 'undefined'){
    //           this.ActivateTreeNode()
    //       }
    //   },
     FilterItem: {
            handler: function(oldvalue, newvalue) {
              console.log(oldvalue,newvalue)
              this.FilterPropCheck()
            },
            deep: true
        },
    },
  methods: {
    ApplyPrimaryfilter(){
      let newfield = this.CurrentEntity.ListPrimaryField.Options.find(opt => opt.ID === this.PrimaryCategoryFilter.ID)
      let navitem = this.CurrentEntity.NavList.find(obj => obj.NavTreeField === this.CurrentEntity.ListPrimaryField.Name)
      //console.log(newfield,navitem,this.CurrentEntity.ListPrimaryField.Name,this.CurrentEntity.ListPrimaryField)
      
      
                //console.log(newfield,navitem,this.CurrentEntity.ListPrimaryField.Name,this.CurrentEntity.ListPrimaryField)
                let levelitem = navitem.TreeList.find(obj => obj.id === this.PrimaryCategoryFilter.ID)
                this.ActivateTreeNode(levelitem)
                //console.log(this.level1)
                this.CurrentEntity.ListPrimaryField = newfield
      let array = JSON.parse(JSON.stringify(newfield.Options))      
                navitem.TreeList = array
                .map(option => {
                    console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    // id: option.ID+'Record:'+option.LevelFieldName,
                    id: option.ID,
                    //EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                if(option.Icon){
                    treeobj.Icon = option.Icon
                }
                if(option.AppendedFilters){
                    navitem.AppendedFilters = option.AppendedFilters
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        //console.log('secondoption',secondoption)
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        //EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Icon){
                            secondtreeobj.Icon = secondoption.Icon
                        }
                        if(secondoption.AppendedFilters){
                            secondtreeobj.AppendedFilters = secondoption.AppendedFilters
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                           // console.log('thirdoption',thirdoption)
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            //EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Icon){
                                thirdtreeobj.Icon = thirdoption.Icon
                            }
                            if(thirdoption.AppendedFilters){
                                thirdtreeobj.AppendedFilters = thirdoption.AppendedFilters
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                //EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                }) 
    },
    UpdateRatingModerators(){
      if(!this.CurrentEntity.RatingModerators){
        this.CurrentEntity.RatingModerators = []
      }
      console.log(this.CurrentEntity.RatingModerators)
      let ratingmoderatorids = this.CurrentEntity.RatingModerators.map(mod => {return mod.id})
      let dbref = this.ActiveMarketTemplate ? 
      this.ConfigPath.collection('Directories').doc(this.Dirid) : 
      db.collection('Libraries').doc(this.ActiveSuiteid).collection('Directories').doc(this.Dirid)
      dbref.doc(this.Dirid).update({
        RatingModerators: this.CurrentEntity.RatingModerators,
        RatingModeratorsIDs: ratingmoderatorids
      })
    },
    ActivateStoreView(boolean){
      this.$emit('ActivateStoreView',boolean)
    },
    PushActiveProduct(item){
            this.$emit('PushActiveProduct',item)
        },
    GetFilterOptions(query,field){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
            if (change.type === 'added') {
              let docdata = change.doc.data()
            //   let featureitem = {
            //     Name: docdata[field.LookupFieldName],
            //     ID: change.doc.id
            // }
            field.Options.push(docdata)
            }
          })
      })
    },
    MoveItem(navitem,navitemindex,direction){
      let to = -1
      if(direction = 'Up'){
        to = navitemindex-1
      }
      else{
        to = navitemindex-1+2
      }
      this.NavList.splice(to, 0, this.NavList.splice(navitemindex, 1)[0])
    },
    ActivateNewFieldDialog(){
        this.$emit('ActivateNewFieldDialog')
    },
    UpdateComputedKeyWords(keywords){
      this.KeyWords = keywords
    },
    GetFeatureListEntries(FeatureList,query){
      FeatureList.LookupEntries = []
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
            if (change.type === 'added') {
              let featureitem = {
                ...change.doc.data(),
                id: change.doc.id
            }
            FeatureList.LookupEntries.push(featureitem)
            }
          })
      })
    },
    ActivateFeaturesListConfigDialog(){
      this.$emit('ActivateFeaturesListConfigDialog')
    },
    ActivateViewportBuilder(item,itemtype){
            this.$emit('ActivateViewportBuilder',item,itemtype)
        },
    IntranetViewToggle(boolean){
      this.$emit('IntranetViewToggle',boolean)
    },
    GetDirectory(id){
      let dbref = this.ActiveMarketTemplate ? this.ConfigPath.collection('Directories').doc(id) : 
      db.collection('Libraries').doc(this.ActiveSuiteid).collection('Directories').doc(id)
      dbref.onSnapshot(snapshot => {
            let data = snapshot.data()
            if(data){
              this.ClassifiedsClasses = data.ListPrimaryField.Options
              //console.log(this.ClassifiedsClasses)
              this.CurrentEntity = data
              this.CurrentEntity.id = id
              this.ListPrimaryField = this.CurrentEntity.ListPrimaryField
              this.CurrentEntity.CategoricalListType = 'Social Classifieds'
              this.CurrentEntity.AllFields = []
              this.NavList = this.CurrentEntity.NavList
              //console.log(this.CurrentEntity,this.NavList)
              
            }
          })
        },
        ActivateTreefromChild(item){
          console.log(item)
          this.ActivateTreeNode(item)
        },
      ActivateTreeNode(ActiveTreeNode){         
        this.level1 = ''
        this.level2 = ''
        this.level3 = ''
        this.AdditionalFilters = []
        this.ActiveTreeNode = ActiveTreeNode
          // ActiveTreeNode.Active = true
          //console.log(this.ActiveTreeNode,this.CurrentEntity,'this.ListPrimaryField')
          if(this.$route.name.includes('DirectoryBuilder') && this.CurrentEntity.id !== 'Group_Categories' && this.CurrentEntity.CategoricalListType !== 'Store Products'
           && this.CurrentEntity.CategoricalListType !== 'Social Classifieds'){
           this.$emit('ActivateTreeNode',this.ActiveTreeNode) 
          }
          else{
            if(!this.ListPrimaryField){
              this.ListPrimaryField = this.CurrentEntity.ListPrimaryField 
            }
            //console.log('okay so else')
            if(this.ActiveTreeNode){
              if(ActiveTreeNode.Levelid === 1){
              this.level1= this.ListPrimaryField.Options.find(opt => opt.ID === ActiveTreeNode.id)
            }
            else if(ActiveTreeNode.Levelid === 2){
              this.ListPrimaryField.Options.map(opt => {
                if(opt.Options){
                  opt.Options.map(subopt => {
                  if(subopt.ID === ActiveTreeNode.id){
                    this.level1 = opt
                    this.level2 = subopt
                  }
                })
                }                
              })
            
            }
            else if(ActiveTreeNode.Levelid === 3){
              this.ListPrimaryField.Options.map(opt => {
                if(opt.Options){
                opt.Options.map(subopt => {
                  if(subopt.Options){
                    subopt.Options.map(thirdopt => {
                    if(thirdopt.ID === ActiveTreeNode.id){
                      this.level1 = opt
                      this.level2 = subopt
                      this.level3 = thirdopt
                    }
                  })
                  }
                })
                }
              })
            
            }
            if(ActiveTreeNode.AppendedFilters){
                this.AdditionalFilters = ActiveTreeNode.AppendedFilters.map(filter => {
                  filter.IsAdditionalFilter = true
                  return filter
                })
            }

            }
            //now you gotta assign level1, subclass and stuff
            //console.log('now you gotta assign level1, subclass and stuff',this.ListPrimaryField)
           // console.log(ActiveTreeNode,this.NavList,this.level1,this.level2)
            
          }
          
      },
      EditNavItem(navitem,navitemindex){
          this.$emit('EditNavItem',navitem,navitemindex)
      },
      EditNavItemSubItem(navitem,navitemindex,child,childindex,prop){
          this.$emit('EditNavItemSubItem',navitem,navitemindex,child,childindex,prop)
      },
      ActivateNewNavItemDialog(){
          this.$emit('ActivateNewNavItemDialog')
      },
      SaveNavList(){
          this.$emit('SaveNavList')
      },
    LoadFilter(cls){
      //this.ClassFilter = cls
      // console.log(this.ViewType,this.ClassFilter)
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;  
      //this.ViewOptionsAssign('ClassifiedsView')
      this.$router.push({ name: 'vehicleswikipublic', params: {FilterProp: 'Brand',FilterRecord: cls,FilterField: {LookupFieldName: 'Name',IsClassField: true}, slug: cls.Name,ViewType: 'ClassifiedsView',LinksGroupsearch: ''}})
    },
    
    
    FilterPropCheck(){
      if(this.FilterProp){
      //console.log(this.FilterProp,this.FilterField)
      let filterfield = ''
      let filteroptions = ''
      let match = ''
      if(!this.FilterField.IsClassField && !this.FilterField.IsSubClassField){
        filterfield = this.FilterProp+'Filter'
        this[filterfield] = ''
        filteroptions = this.FilterProp+'Options'
        match = this[filteroptions].find(obj => obj.Name === this.FilterItem.split('-').join(''))
      }
      else if(this.FilterField.IsClassField){
        filterfield = 'ClassFilter'
        this[filterfield] = ''
        filteroptions = 'ClassifiedClasses'
        match = this[filteroptions].find(obj => obj[this.FilterField.LookupFieldName] === this.FilterItem.split('-').join(''))
        if(!match){
          match = this.FilterRecord
          match.CountName = this.FilterRecord[this.FilterField.LookupFieldName]
          match.Count = 0
        }
      }
      else if(this.FilterField.IsSubClassField){
        filterfield = 'SubClassFilter'
        this[filterfield] = ''
        filteroptions = 'ClassifiedSubClasses'
        
        match = this[filteroptions].find(obj => obj[this.FilterField.LookupFieldName] === this.FilterItem.split('-').join(''))
        if(!match){
          match = this.FilterRecord
          match.CountName = this.FilterRecord[this.FilterField.LookupFieldName]
          match.Count = 0
        }
      }
      if(match){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this[filterfield] = match
      }
      else{
        // this[filterfield] = match
      }     
      //console.log(this[filteroptions].length,this.FilterField.LookupFieldName,this.FilterItem.split('-').join(''),match) 
      }
    },
    ViewMapItem(PhysicalAddressAddressLine1,PhysicalAddressAddressLine2,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressAddressLine1+'+'+PhysicalAddressAddressLine2+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
    AddtoCompare(item){
      item.OnCompare = true
      if(this.CompareItems.length < 3){
        this.CompareItems.push(item)
      }
      else{
        alert('you can only compare 4 at a time')
      }
      
    },
    ClearComparisons(){
      this.CompareItems = []
      this.ComparingDialog = false
      // this.VehiclesLinksGroupClassifieds.map(record => {
      //   record.OnCompare = false
      // })
    },
    ClearFeatures(){
      this.FeatureList.map(feature => {
        feature.Filter = false
      })
    },
    
    CurrencyFormatter(value,currency){
      const currencyformatter = this.PrepareCurrency(currency)
      let formalformat = currencyformatter.format(value)
      return formalformat
      },
      PrepareCurrency(currency){			
      let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
      let lang = this.$store.state.DefaultCurrency.LangForm
      if(currobj){
          lang = currobj.LangForm
      }
      return new Intl.NumberFormat(lang, {			
              style: 'currency',			
              currency: currency,			
              minimumFractionDigits: 2			
          })
      
      },	
      ViewOptionsAssign(newdefprop){
          let Options = [
            'ClassifiedsView',
            'ClassifiedsClassFilterView'
            ]
            
          let obj = {
              prop: 'VehiclesWikiViewbyOptions',
              options: Options,
              defaultprop: 'VehiclesWikiViewType'
          }
          if(newdefprop){
            obj.DefaultView = newdefprop
          }
          else if(this.DefaultView){
              obj.DefaultView = this.DefaultView
          }
          this.$emit('AssignViewbyOptions',obj)
      },
      ActivateViewPortFilter(PropName,Value){
        this.AdditionalFilters.map(item => {
            if(item.Filters){
              let match = item.Filters.find(obj => obj.id === PropName)
              if(match){
                //console.log('match',match,item)
                match.ActiveFilter = Value
                this.Refreshing = true
                 setTimeout(() => {
                    this.Refreshing = false
                }, 100);
                //item.Refreshing = true
                this.ActivateFilter(match,true)
                // setTimeout(() => {
                //     item.Refreshing = false
                // }, 100);
              }
            }
            else{
              // let match = item.Field
              if(item.Field){

              }
            }
            return item
        })
        //console.log(this.ComputedNavList,PropName,Value)
      },
      ActivateFilter(filter,navitemtype,value){
         //console.log(this.ActiveFilters,filter,navitemtype,value)
        let item = ''
        
        if(navitemtype === 'Single Option Filter' || navitemtype === 'Text Search'){
          item = this.ActiveFilters.find(obj => obj.PropName === filter.Field.Name)
          //filter.ActiveFilter = value
        }
        else{
          item = this.ActiveFilters.find(obj => obj.PropName === filter.Name)
        }
         if(navitemtype === 'Single Option Filter'){
              if(filter.ActiveFilter && filter.ActiveFilter.ID === value.ID){
                filter.ActiveFilter = ''
              }
              else{
                filter.ActiveFilter = value
              }              
            }
          else if(navitemtype === 'Text Search' && item && filter.ActiveFilter){
             filter.ActiveFilter = ''            
            }
        // console.log(item)
          if(!item && filter.ActiveFilter){
            if(filter.ActiveFilter){
              let newfilter = ''
              
              if(navitemtype === 'Text Search'){
                newfilter = Object.assign({},filter)
              }
              else{
               newfilter = Object.assign({},filter.ActiveFilter)
              }              
              if(filter.id === 'Store_Product_Brand' && this.CurrentEntity.CategoricalListType === 'Store Products'){
                newfilter.BrandFilter = true
              }
              newfilter.Type = filter.Type
              if(navitemtype === 'Filter List'){
                newfilter.IsOptionList = true  
              }
              if(navitemtype === 'Single Option Filter' || navitemtype === 'Text Search'){
                newfilter.PropName = filter.Field.Name
                // if(filter.Field.Type === 'Lookup' || filter.Field.Type === 'Radio Group'){
                //   newfilter.Field
                // }
                newfilter.Field = filter.Field
              }
              else{
                newfilter.PropName = filter.Name
              }
              
              if(newfilter.Type === 'Number' && newfilter.IsOptionList){
                newfilter.Range = filter.Range
              }
              if(navitemtype === 'Text Search'){
                  newfilter.ActiveFilter = value
                }
              this.ActiveFilters.push(newfilter)
              //console.log(this.ActiveFilters)
            }
          }    
          else if(!item && filter.Range){
            this.ActiveFilters.push(filter)
          }  
          // else if(!item && navitemtype === 'Single Option Filter'){

          //   this.ActiveFilters.push(filter)
          // }  
          else if(item && filter.Range){
            let itemindex = this.ActiveFilters.indexOf(item)
            this.ActiveFilters[itemindex]
          }    
          else{
            let itemindex = this.ActiveFilters.indexOf(item)
            //console.log(itemindex)
            if(typeof itemindex !== 'undefined'){
              this.ActiveFilters.splice(itemindex,1)
            }
           
            if(filter.ActiveFilter){
              let newfilter = Object.assign({},filter.ActiveFilter)
              newfilter.Type = filter.Type
              if(navitemtype === 'Filter List'){
                newfilter.IsOptionList = true  
              }
              if(navitemtype === 'Single Option Filter' || navitemtype === 'Text Search'){
                newfilter.PropName = filter.Field.Name                
              }
              else{
                newfilter.PropName = filter.Name
              }
              
              if(newfilter.Type === 'Number' && newfilter.IsOptionList){
                newfilter.Range = filter.Range
              }
              if(filter.id === 'Store_Product_Brand' && this.CurrentEntity.CategoricalListType === 'Store Products'){
                newfilter.BrandFilter = true
              }
              this.ActiveFilters.push(newfilter)
            }
            //console.log(this.ActiveFilters)
          }
          this.Refreshing = true
                 setTimeout(() => {
                    this.Refreshing = false
                }, 100);
         //console.log(this.ActiveFilters)
          // this.Filter = type
          // console.log(this.Filter)
          // this.FilteredView = true
      },
   
  
  }
}

</script>

 <style>
 .hovercard {
     max-height: 200px;
 }
.hovercard:hover {
    background-color: #eb371b;
    max-height: 350px;
}
.hovercard .hovercardstitle {
 color: #eb371b; 
 font-family: 'Roboto', sans-serif;
 text-transform: uppercase; 
 font-size: 1em;
 letter-spacing: 0.1666666667;
   
}
.hovercard:hover .hovercardstitle {
 color: white; 
 font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;  
}
.hovercard .hovercardbutton {
 visibility: hidden 
}
.hovercard .hovercardimage{
height: 70px;
}
.hovercard:hover .hovercardimage{
height: 200px;
}
.hovercard:hover .hovercardbutton {
 visibility: visible;   
}

</style>
<style scoped>

</style>
          
            
        