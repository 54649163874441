<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
      <!-- ActiveSession {{ActiveSession}} -->
      <v-row class="justify-center" style="padding-top:50px;">
          
         <v-flex xl3 lg3 md4 sm6 xs12 class="my-2" v-for="page in Groups" :key="page.itemObjKey" style="padding:20px;">
           <v-card class="mx-"  round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                <v-card-title class="justify-center" style="align:center;">
            <v-avatar tile size="70" class="transparent mx-3" >
                <v-img v-if="page.logo"  :src="page.logo"/>
            </v-avatar>
                </v-card-title>
            </v-card>
            <v-card rounded >
                <v-card-title class="primary white--text justify-end overline">
                     {{page.name}}
                </v-card-title>
                 <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                   {{page.groupmembers.length}} Members                   
                </v-card-title>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.newlikecolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-thumb-up-outline
                            </v-icon>
                            <span slot="badge"> {{page.new_like_count}} </span>
                            </v-badge> New Likes                   
                </v-card-subtitle>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.unreadnotifycolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-bell-check
                            </v-icon>
                            <span slot="badge"> {{page.unread_notif_count}} </span>
                            </v-badge> Unread Notifications                   
                </v-card-subtitle>
                <v-divider>
                </v-divider>
                <v-card-actions>
                  <v-btn :to="'/GroupDashboard/'+page.id" target="_blank">
                           <v-icon color="blue">mdi-google-analytics</v-icon>Measurements
                         </v-btn>
                    <v-spacer>
                    </v-spacer>
               
                         <v-btn :to="'/Group/'+page.id" class="blue--text" target="_blank">
                           <v-icon color="blue">{{page.providericon}}</v-icon>View Page
                         </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
      </v-row>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','UsersArray'],
    components: {

    },
    data(){
        return{
            Groups: []
        }
    },
    computed:{
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
    },
    created(){
        this.GetMyGroups(this.userLoggedIn.groups)
    },
    methods:{
        GetallGroupMembers(group){
          
            this.UsersArray.map(user => {
                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(user.id).collection('groups').doc(group.id).onSnapshot(snapshot => {
                let groupmemberdata = snapshot.data()
                if(typeof groupmemberdata !== 'undefined' && groupmemberdata.Status  && groupmemberdata.Status === 'Active'){
                    groupmemberdata.id = this.$route.params.id
                    group.groupmembers.push(groupmemberdata)
                    //console.log(groupmemberdata,group)
                }
                })
            })
        },
        GetMyGroups(groups){
            console.log(groups)
            let admingroups = groups.filter(group => {return group.Administrator || group.IsTeamMember})
            admingroups.map(group => {
                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(group.id).onSnapshot(snapshot => {
                    let groupobj = snapshot.data()
                    if(groupobj){
                     groupobj.id = group.id
                        groupobj.groupmembers = []
                        this.Groups.push(groupobj)
                        this.GetallGroupMembers(groupobj)   
                    }
                    
                })
            })
        }
    }

}
</script>

<style>

</style>


    