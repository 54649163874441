<template>
  <div >
     
      <div v-if="!DrillDown" style="padding:20px;">
       <v-row       
            :class="'mb-6 '+StatsCounterTab.Justify"
            no-gutters
            :style="'margin-top:'+StatsCounterTab.MarginTop+'px;margin-bottom:'+StatsCounterTab.MarginBottom+'px;margin-left:'+StatsCounterTab.MarginLeft+'px;margin-right:'+StatsCounterTab.MarginRight+'px;'"            
            >  
             <v-col
                v-for="(elmnt,index) in StatsCounterTab.Elements"
                :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                cols="12"    
                :class="elmnt.Justify+' '+ColMY(elmnt)"  
                :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
            >   
                <CounterTab 
                v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog" @EmitDrillDown="EmitDrillDown"
                />
             </v-col>
        </v-row>
      </div>
      <div v-if="DrillDown" style="margin-top:20px;">
      <!-- PublicWebFormSubMissions {{PublicWebFormSubMissions}} -->
      
        <v-parallax
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{DrillDownItem.Title}}</h1>
                <h4 class="headline">DrillDown</h4>
                <h5><v-btn small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
                
                </h5>                
              </v-col>
            </v-row>
            </v-parallax>
            
        <!-- Context on DrillDown? -->
       <!-- <v-btn style="position: absolute;z-index:5;" small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn> -->
       
        <DashboardBuilder  style="padding-top:40px;" :PushedRows="ComputedStatisticCharts" :SystemEntities="ValidatorSystemEntities" :PushedElmnt="DrillDownItem"
        />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder';
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
export default {
    props: ['System','SystemEntities','GroupLandingPageViews','ThemeColor','ThemeHeaderColor'],
    components: {DashboardBuilder,CounterTab},
    data() {
        return {
            groupmembers: [],
            DrillDown: false,
            DrillDownItem: '',
            DefaultCounterElmnt: {									
                CounterIcon: 'mdi-excavator',									
                CounterInteger: 0,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Counter Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                Tile: true,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Title',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:2,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,
                // HideActions: true,								
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,						
            },
            DefaultCounterTab: {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Counter',									
            Elements: [
                {}
            ],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-counter',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },
            TimeLineStatsSample: {
                    HideActions: true,
                    AccumulativeGraph: true,			
                    ShowSummary: true,			
                    ShowSummaryOnly: true,			
                    HasDataContext: true,
                    DataContextStyle: 'List',
                    Sort: 'Descending (Value)',			
                    PrimaryLabelType: 'From Timeline',
                    PrimaryEntity: 'Users',
                    LookupArrays: [],
                    Data: [],
                    ChartLabelsfromTimeline: true,
                    ChartLabels: [],
                    PrimaryDateFilter: 
                        {
                            Name: 'Created_On',
                            Type: 'Date'
                        },
                    TimeFilterOption:   {			
          ID: 1000000,			
          FilterName: 'This Day (Per 2 Hrs)',			
          FilterDescription: 'When viewing, this filter returns the result of Day, by every 2 Hours',			
          GiveName: 'Per 2 Hrs',			
          Name: 'This Day',			
          StartDateVariable: 'ThisToday',			
          EndDateVariable: 'ThisTodayEnd',			
          ChartLabels: ['0AM - 2AM','2AM - 4AM','4AM - 6AM','6AM - 8AM','8AM - 10AM','10AM - 12PM','12PM - 14PM','14PM - 16PM','16PM - 18PM','18PM - 20PM','20PM - 22PM','22PM - 0AM'],			
          ChartLabelsVariables: ['ThisToday','Todayby2AM','Todayby4AM','Todayby6AM','Todayby8AM','Todayby10AM','Todayby12PM','Todayby14PM','Todayby16PM','Todayby18PM','Todayby20PM','Todayby22PM'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'Endof2Hours',			
        },				
                    ChartData: [],	
                    ChartType: 'bar',	
                    ColumnWidth: 'Full Column',	
                    HasChart: true,		
                    Title: 'New Users',			
                    Type: '',			
                    Sort: 'Descending (Value)',			
                    Description: 'Lorem Ipsum this is good!',			
                    ValueField: 'Full_Name',			
                    ValueType: 'Count',			
                    ValueCurrency: '',			
                    EntityRelatedfield: '',			
                    Entity: '',			
                    ChartBGObj: {hex: this.ThemeColor},	
                    ChartPosition: 'Top',	
                    ShowSummaryOnly: true,	
                    Content: [			
                                    
                    ],	
                    Context: [
                        {
                            ContextData: []
                        }
                    ],
                    
                    // Tile: 'ad',
                    // ShowTitle: true,		
                    HasData: true,	
                    DashboardEntity: {
                        id: 'Users',
                        AllFields: [
                            {
                                Name: 'Full_Name',
                                Type: 'Single Line Text'
                            },
                            {
                                Name: 'Created_On',
                                Type: 'Date'
                            }
                        ]
                    }	
                },
            AppValidatorEntities: [
                {id: 'Site_Analytics',AllFields: [
                        {
                            Name: 'Path',
                            Type: 'Single Line Text',
                            Primary: true
                        },
                        {
                            Name: 'Date',
                            Type: 'Date'
                        },
                        {
                            Name: 'Page',
                            DisplayName: 'Page',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'PageName'
                        },
                        {
                            Name: 'Most_Viewed_From_Page',
                            DisplayName: 'Most Viewed From Page',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'PageName'
                        }
                    ],
                GoalMeasurements: [
                    {
                        id: 'Session_Conversions',
                        Targets: []
                    }
                ]
                },
                {id: 'Group_Members',AllFields: [
                        {
                            Name: 'UserName',
                            DisplayName: 'Member Name',
                            Type: 'Single Line Text',
                            Primary: true
                        },
                        {
                            Name: 'MembershipDate',
                            Type: 'Date'
                        },
                        {
                            Name: 'UserRole',
                            DisplayName: 'User Role',
                            Type: 'Single Line Text',
                        },
                    ],
                GoalMeasurements: [
                    {
                        id: 'Session_Conversions',
                        Targets: []
                    }
                ]
                },
                {id: 'Public_Web_Forms',AllFields: [
                        {
                            Name: 'Form_Page_Obj',
                            DisplayName: 'Form_Page_Obj',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'Page_Name'
                        },
                        {
                            Name: 'Related_Session',
                            DisplayName: 'Related Session',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Analytics',
                            LookupFieldName: 'Path'
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
                {id: 'Site_Pages',AllFields: [
                        {
                            Name: 'Page_Name',
                            DisplayName: 'Page Name',
                            Type: 'Single Line Text',
                            Primary: true,
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
                {id: 'Landing_Pages',AllFields: [
                        {
                            Name: 'Page_Name',
                            DisplayName: 'Page Name',
                            Type: 'Single Line Text',
                            Primary: true,
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
            ]
        }
    },
    computed:{
        UsersArray(){
            return this.$store.state.UsersArray
        },
        ComputedStatisticCharts(){
            return this.DrillDownItem? this.DrillDownItem.Charts
            .map(chart => {
                console.log('this.DrillDownItem',this.DrillDownItem)
                let chartobj = ''
                if(chart.Preset === 'Static Timeline Filter'){
                    chartobj = Object.assign({},this.TimeLineStatsSample)
                   // chartobj.PrimaryEntity = chart.PrimaryEntity
                    chartobj.PrimaryDateFilter = chart.PrimaryDateFilter
                    chartobj.DashboardEntity = chart.DashboardEntity
                    chartobj.ValueField = chart.ValueField
                    chartobj.ValueType = chart.ValueType
                    chartobj.ValueCurrency = chart.ValueCurrency
                    chartobj.ChartType = chart.ChartType
                    chartobj.ColumnWidth = chart.ColumnWidth
                    chartobj.HasChart = chart.HasChart
                    chartobj.Title = chart.Title 
                }
                 else if(chart.Preset === 'Pie Chart'){
                    chartobj = Object.assign({},this.BasicPieChart)
                    chartobj.PrimaryLabelType = chart.PrimaryLabelType
                    //chartobj.PrimaryEntity = chart.PrimaryEntity
                    chartobj.PrimaryDateFilter = chart.PrimaryDateFilter
                    // chartobj.DashboardEntity = chart.DashboardEntity
                    chartobj.ValueField = chart.ValueField
                    chartobj.ValueType = chart.ValueType
                    chartobj.ValueCurrency = chart.ValueCurrency
                    chartobj.ChartType = chart.ChartType
                    chartobj.ColumnWidth = chart.ColumnWidth
                    chartobj.HasChart = chart.HasChart
                    chartobj.Title = chart.Title
                    if(chart.RelatedBuildID){
                        chartobj.RelatedBuildID = chart.RelatedBuildID
                    }
                    
                 }
                 if(chart.SublabelField){
                     chartobj.SublabelField = chart.SublabelField
                 }
                 if(chart.PrimaryLabelField){
                        chartobj.PrimaryLabelField = chart.PrimaryLabelField
                    }
                    if(chart.PrimaryFilterType){
                        chartobj.PrimaryFilterType = chart.PrimaryFilterType
                    }
                     if(chart.PrimaryFilterField){
                        chartobj.PrimaryFilterField = chart.PrimaryFilterField
                    }
                    if(chart.ConfigName){
                        chartobj.ConfigName = chart.ConfigName
                    }
                if(this.DrillDownItem.Name === 'Counter Single'){
                chartobj.Data = this.DrillDownItem.Data
                }
                console.log('chartobj',chartobj)
                //later advanced
                // EntityRelatedfield: '',			
                // Entity: '',
                
                return chartobj
            }) 
            : this.StatisticCharts.map(elmnt => {
                elmnt = this.PrepareElementComponents(elmnt)
                return elmnt
            })
            .map(elmnt => {
                if(elmnt.DashboardEntity.id === 'Users'){
                    elmnt.Data = this.$store.state.UsersArray
                    console.log('elmnt.FullArray',elmnt.Data)
                }
                return elmnt
            })
           
        },
        ValidatorSystemEntities(){
            return this.PreparedAppValidatorEntities
        },
        PreparedAppValidatorEntities(){
            return this.AppValidatorEntities
        },
        StatsCounterTab(){
            let tab = this.DefaultCounterTab
            let elements = this.GroupLandingPageViewPageObjects.map(pageobj => {
                let obj = {CounterIcon: 'mdi-search-web',Data: this.GroupLandingPageViews.filter(item => item.Page_Obj.id === pageobj.id),CounterInteger: this.GroupLandingPageViews.filter(item => item.Page_Obj.id === pageobj.id).length, Title: pageobj.Page_Name+' Views',BGColor: {hex: this.ThemeColor},HeaderColor: {hex: this.ThemeHeaderColor},FontColor: {hex: this.ThemeHeaderColor},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: this.ThemeColor,
                Charts: [
                {Preset: 'Static Timeline Filter',Title: pageobj.Page_Name+' Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true,
                BGColor: {hex: this.ThemeColor},HeaderColor: {hex: this.ThemeHeaderColor},FontColor: {hex: this.ThemeHeaderColor},ThemeColor: this.ThemeColor,},
                // {Preset: 'Pie Chart',Title: 'Views by Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                // PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                // ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                // LookupArrays: [{
                //                     Field: 'Most_Viewed_From_Page',
                //                     Array: this.PublicPageViewPageObjects
                //                 }]
                }
                return obj
            })
            let membersobj = {CounterIcon: 'mdi-face-agent',Data: this.groupmembers,CounterInteger: this.groupmembers.length, Title: 'Total Members',BGColor: {hex: this.ThemeColor},HeaderColor: {hex: this.ThemeHeaderColor},FontColor: {hex: this.ThemeHeaderColor},
            HasAnalysisDrillDown: true,PrimaryEntity: 'Group_Members',HasCounterData: true,ThemeColor: this.ThemeColor,
            Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Members by Join Date',PrimaryDateFilter: {Name: 'MembershipDate',Type: 'Date'},
                ValueField: 'UserName',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true,
                BGColor: {hex: this.ThemeColor},HeaderColor: {hex: this.ThemeHeaderColor},FontColor: {hex: this.ThemeHeaderColor},ThemeColor: this.ThemeColor}
            ]
            }
            elements.push(membersobj)
            tab.Elements = elements.map(elmnt => {
                // let ranint = Math.floor(Math.random() * Math.floor(this.colors.length))
                // alert(ranint+' '+this.colors[ranint])	
                let baseelmnt = Object.assign({},this.DefaultCounterElmnt)
                let requiredprops = ['BGColor','HeaderColor','FontColor','ChartBGObj','CounterIcon','CounterInteger','Title','HasCounterData','Data','Charts','HasAnalysisDrillDown',
                'ThemeColor','PrimaryEntity','AnalysisDrillDownMethod','LookupArrays','NumberFields']
                requiredprops.map(prop => {
                    if(elmnt[prop]){
                        baseelmnt[prop] = elmnt[prop]
                    }
                })
                console.log('baseelmnt',baseelmnt)
                return baseelmnt
            })
            return tab
        },
        // ComputedGroupLandingPageViews(){
        //     return this.GroupLandingPageViewPageObjects.map(view => {
        //         let obj = {CounterIcon: 'mdi-search-web',Data: this.ComputedPublicPageViews,CounterInteger: this.ComputedPublicPageViews.length, Title: 'Public Page Views',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
        //         HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: 'primary',
        //         Charts: [
        //         {Preset: 'Static Timeline Filter',Title: 'Public Page Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
        //         ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
        //         {Preset: 'Pie Chart',Title: 'Views by Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
        //         PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
        //         // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
        //         ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
        //         ],
        //         // LookupArrays: [{
        //         //                     Field: 'Most_Viewed_From_Page',
        //         //                     Array: this.PublicPageViewPageObjects
        //         //                 }]
        //         }
        //         return obj
        //     })
        // },
        GroupLandingPageViewPageObjects(){
            let arr = []
            this.GroupLandingPageViews.map(view => {
                console.log(view.Page_Obj)
                let oncheck = arr.find(obj => obj.id === view.Page_Obj.id)
                if(!oncheck){
                    arr.push(view.Page_Obj)
                }
            })
            return arr
        },
    },
    created(){
        this.GetGroupMembers()
    },
    methods:{
        GetGroupMembers(){
            this.UsersArray.map((user) => {
                //.where("Status", "==", "Active")
                db.collection("groupmembers").doc(user.id).collection("groups").where("Groupid", "==", this.$route.params.id).onSnapshot((res) => {
                    let listlength = res.docs.length;
                    const changes = res.docChanges();
                    changes.forEach((change) => {
                    if (change.type === "added") {
                        this.groupmembers.push({
                        ...change.doc.data(),
                        id: change.doc.id,
                        });
                    }
                    })
                })
            })
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        EmitDrillDown(MethodName, tab){
            console.log(MethodName,tab)
            this.DrillDown = true
            this.DrillDownItem = tab
        },
    }
}
</script>

<style>

</style>
