<template>
  <div>
    <v-snackbar top timeout="4000"
      v-model="snackbar"
    >
    {{snackbartext}}
      </v-snackbar>
    <v-card-subtitle class="mediumoverline" style="padding-top:30px;">
                        Basic Information<v-text-field v-if="UserisCompanyAdmin" :readonly="!UsercanEditCreditLimit" v-model.number="Credit_Limit" label="Credit_Limit"/>
                    </v-card-subtitle>
                    <v-card  class="siteconfigcard"  width="100%">
                    <v-card-text >    
                        
                        <v-row class="justify-space-between"
                            no-gutters
                        >
                            <v-col  :cols="MiniView ? 12: 12" style="align-self: flex-start;"
                            >           
                        <div>
                        <FieldValueEditerComponent v-for="field in BillingAccountHeaders" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="ActiveAccount" :field="field" @onPhotoFileselected="onPhotoFileselected"
                        :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                        @UploadFileSelect="UploadFileSelect"
                            />
                        </div>
                            </v-col>
                             <v-col :cols="MiniView ? 12: 12" style="align-self: center;">
                                  <!-- <FieldsForm :FormFields="[Invoicing_Address]" :Validating="Validating" :DataTableView="false" @save="ProgressWindow"
                            @UploadFileSelect="UploadFileSelect" :DataObject="ActiveAccount" :tab="{}" /> -->
                                   <FieldValueEditerComponent style="padding: 0px;" v-for="field in [Invoicing_Address]" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="ActiveAccount" :field="field" @onPhotoFileselected="onPhotoFileselected"
                        :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                        @UploadFileSelect="UploadFileSelect"
                            />
                             </v-col>
                        </v-row>
                    </v-card-text>
                    <!-- <v-card-text >            
                        <div>
                      
                        </div>
                    </v-card-text>
                     -->
                    <v-card-actions>
                        <!-- <v-btn dark color="warning">
                            Cancel
                        </v-btn> -->
                        <v-spacer>
                        </v-spacer>
                        <v-btn @click="UpdateBillingAccount()" dark color="success">
                            Save
                        </v-btn>
                    </v-card-actions>
                    <v-card-text v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Group Account'">
          
        </v-card-text>
                    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
export default {
    props: ['System','SystemEntities','ActiveAccount'],
    components: {FieldValueEditerComponent},
    data() {
        return {
            Credit_Limit: 0,
            snackbar: false,
          snackbartext: '',
            Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
        }
    },	
    computed:{
        MyCompany(){
            return this.$store.state.MyCompany
        },
        UsercanEditCreditLimit(){
            return this.UserisCompanyAdmin && this.MyCompany && this.MyCompany.Account_Limits_by_Admin
        },
        UserisCompanyAdmin(){
            return this.$store.state.UserisCompanyAdmin
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        ActiveAccount: {
           handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.Credit_Limit = this.ActiveAccount.Credit_Limit
              }
           },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
         this.PrepareAddressField('Invoicing_Address')
         this.Credit_Limit = this.ActiveAccount.Credit_Limit
    },
    methods:{
        PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : '',
            [field.Name+'_Street_Name'] : '',
            [field.Name+'_Suburb'] : '',
            [field.Name+'_City'] : '',
            [field.Name+'_Postal_Code'] : '',
            [field.Name+'_State_Province'] : '',
            [field.Name+'_Country_Region'] : ''
        }
        //console.log(field)
        },  
        UpdateBillingAccount(){
            //console.log(this.Invoicing_Address,this.ActiveAccount,this.UsercanEditCreditLimit,this.MyCompany,this.Credit_Limit)
            let brklength = this.Invoicing_Address.FieldBreakdown.length 
            let updobj = {}
            if(this.UsercanEditCreditLimit && typeof this.Credit_Limit !== 'undefined'){
                updobj.Credit_Limit = Number(this.Credit_Limit)
            }
            let stdprops = ['Account_Name','Account_Email','Account_Phone','Client_Reference','Account_Website']
            stdprops.map(prp => {
                if(typeof this.ActiveAccount[prp] !== 'undefined'){
                    updobj[prp] = this.ActiveAccount[prp]
                }
            })
            this.Invoicing_Address.FieldBreakdown.map((brk,brkindex) => {
            updobj[brk.Name] = this.ActiveAccount[brk.Name]
            if(brkindex-1+2 === brklength){
                db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.ActiveAccount.id).update(updobj).then(updateddoc => {
                        this.snackbartext = 'Updated Billing Account '+this.ActiveAccount.id
                        this.snackbar = true
                    })
            }
            })
        },
    }
}
</script>

<style>

</style>



