
<template>
<v-content>
  <v-layout v-if="ReadyforPublish.length > 0" column class="fab-container">
            <v-btn icon x-large @click="PublishNewVersionDialog()"><v-icon  color="blue" x-large>mdi-publish</v-icon></v-btn>
    </v-layout>
    
<v-dialog v-model="NewVersionDialog" width="400">
  <v-card tile flat>
  <v-card-title>
    New Release - {{NewVersion.Version_Number}}
  </v-card-title>
  <v-card-text>
  This Package will be released with the following notes:
    <ContentEditableField style="padding:15px;"
      :FieldObject="NewVersion" :FieldName="'Version_Release_Note'"
        :FieldValue="NewVersion.Version_Release_Note" @UpdateContentEditableField="updateVersionReleaseNote" :AdditionalSaveMethod="''" />
     
      <v-dialog v-model="NewversionAssetdialog" max-width="800">
         <template v-slot:activator="{ on }">									
            <v-btn small v-on="on">Select Asset</v-btn>								
        </template>
          <v-row>
          <v-col
            v-for="img in SystemAssets"
            :key="img.itemObjKey"
            class="d-flex child-flex"
            cols="4"
          >
          <v-card @click="ChooseImage(img)" tile class="transparent" flat>
            <v-img
              
              :src="img.ThumbURL"
            
              aspect-ratio="1"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
          </v-col>
      </v-row>
    </v-dialog>
    <v-img        
      v-if="NewVersion.Asset.fileurl"      
      :src="NewVersion.Asset.ThumbURL"    
      aspect-ratio="1"
      contain
      class="grey lighten-2"
    />
  </v-card-text>
  <v-card-actions>
    <v-btn @click="CancelVersionRelease()">Cancel</v-btn>
    <v-spacer>
    </v-spacer>
    <v-btn v-if="NewVersion.Asset.fileurl" @click="releaseNewVersion()">Publish</v-btn>
  </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-if="dragging > -1" v-model="ReleaseNoteDialog" width="400">
  <v-card tile flat>
  <v-card-title>
    Release Note - {{tasks[dragging].Name}}
  </v-card-title>
  <v-card-text>
    Add a Release Note so when you Release a new version the Notes would rollup into one packaged release
    <v-textarea v-model="tasks[dragging].Release_Note" label="Release Note"/>
  </v-card-text>
  <v-card-actions>
    <v-btn @click="taskdragEnd()">Cancel</v-btn>
    <v-spacer>
    </v-spacer>
    <v-btn v-if="tasks[dragging].Release_Note" @click="UpdateTaskStatus(tasks[dragging],'Publish',tasks[dragging].Release_Note)">Save</v-btn>
  </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="AddTaskDialog" max-width="400px">
  <v-navigation-drawer height="100%" right v-model="FeatureNavlist" app width="400">
    <v-tabs>
      <v-tab>In Scope</v-tab>
      <v-tab-item>
        <SystemGuideComponent :FavoriteFeatures="CurrentDocumentFavoriteFeatures" @ToggleRoadmapItemScope="ToggleRoadmapItemScope"
        :AppisDarkMode="AppisDarkMode" class="my-3" :ListHeight="WindowHeight-100" :GuideList="CurrentDocumentSystemGuides" :tab="systemguideelmnt" @guidedragstart="guidedragstart" />
      </v-tab-item>
       <v-tab>Add</v-tab>
      <v-tab-item>
        <SystemGuideComponent :FavoriteFeatures="CurrentDocumentFavoriteFeatures" @ToggleRoadmapItemScope="ToggleRoadmapItemScope"
        :AppisDarkMode="AppisDarkMode" class="my-3" :ListHeight="WindowHeight-100" :GuideList="NonCurrentDocumentSystemGuides" :tab="systemguideelmnt" @guidedragstart="guidedragstart" />
      </v-tab-item>
    </v-tabs>
      </v-navigation-drawer>
        <v-layout class="justify-center">
        <v-card width="100%">
            <v-card-title class="blue white--text">{{formTitle}}<v-layout class="justify-end" v-if="editedIndex !== -1">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :nudge-top="200"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                              <template v-slot:activator="{ on }">
                                <v-btn small v-on="on"  v-if="Comments.length>0" icon color="pop"><v-icon>mdi-comment-alert-outline</v-icon></v-btn><v-btn x-small v-on="on"  v-else icon color="grey"><v-icon>mdi-comment-alert-outline</v-icon></v-btn>
                              </template>
                       <v-card flat>
                                  <v-card-title>Comments and History</v-card-title>
                                  <v-timeline dense clipped align-top>
                                  <v-timeline-item 
                                    fill-dot
                                    class="white--text mb-12"
                                    color="blue"
                                    large
                                  >
                                    <template v-slot:icon>
                                      <span>{{UserRecord.Name.substr(0,1)}}{{UserRecord.Surname.substr(0,1)}}</span>
                                    </template>
                                    <v-text-field
                                      v-model="CommentInput"
                                      hide-details
                                      flat
                                      label="Leave a comment..."
                                      solo
                                      @keydown.enter="Postscomment"
                                    >
                                      <template v-slot:append>
                                        <v-btn
                                          class="mx-0"
                                          depressed
                                          @click="Postscomment"
                                        >
                                          Post
                                        </v-btn>
                                      </template>
                                    </v-text-field>
                                  </v-timeline-item>

                                  <v-slide-x-transition
                                    group
                                  >
                                    <v-timeline-item
                                      v-for="post in Comments" :key="post.id"
                                      class="mb-4 mx-3"
                                      color="red"
                                      small
                                    >
                                    <template v-slot:icon>
                                        <v-btn v-if="post.User.id !== UserRecord.id" fab text :href="'/User/'+post.User.id" target="_blank" class="pop white--text" x-small>{{post.User.Name.substr(0,1)}}{{post.User.Surname.substr(0,1)}}</v-btn>
                                      <v-btn v-if="post.User.id === UserRecord.id" fab text :href="'/User/'+post.User.id" target="_blank" class="accent white--text" x-small>{{post.User.Name.substr(0,1)}}{{post.User.Surname.substr(0,1)}}</v-btn>

                                    </template>

                                      <v-row justify="space-between">
                                        <v-col cols="7" v-html="post.text"></v-col>
                                   
                                        <v-col v-if="post.time.toDate" class="text-right" cols="5" v-html="TimestampFormatter(post.time.toDate())"></v-col>
                                        <v-col v-if="typeof post.time.toDate === 'undefined'" class="text-right" cols="5" ><p style="font-size:12px;color:grey;">{{post.time}}</p></v-col>
                                      </v-row>
                                    </v-timeline-item>
                                  </v-slide-x-transition>
                                </v-timeline>
                              </v-card>
                </v-menu><v-icon @click="deleteTask(editedIndex)" color="red">mdi-delete-forever</v-icon></v-layout></v-card-title>
            <v-layout row class="mx-3">
                <v-text-field v-model="TaskName" label="Name"></v-text-field>
            </v-layout>
            <!-- <v-layout row class="mx-3">
                <v-select v-model="TaskStatusReason" label="Status" :items="StatusReasonTypes"></v-select>
            </v-layout> -->
            <v-layout row class="mx-3">
                <v-select v-model="TaskPriority" label="Priority" :items="['Low','Medium','High']"></v-select>
            </v-layout>
            
            <v-layout row class="mx-3">
             <b>Description: </b>
              <ContentEditableField style="padding:15px;"
                :FieldObject="NewTask" :FieldName="'Overview'"
                  :FieldValue="NewTask.Overview" @UpdateContentEditableField="updateVersionReleaseNote" :AdditionalSaveMethod="''" />
            </v-layout>
            <v-layout row class="mx-3">
                <v-select  :items="STDActionTypes" item-text="Name" v-model="TaskType" label="Type" return-object></v-select>
            </v-layout>
                     
            <v-layout row class="mx-3">
                <v-menu 
                v-model="TaskDueDatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                     
                    >
                    <template v-slot:activator="{ on }">
                <v-text-field
                    label="Due Date"
                    v-model="TaskDueDate"
                    prepend-icon="mdi-calendar-month"
                     
                    v-on="on"
                ></v-text-field>
                    </template>
                   <v-date-picker   v-model="TaskDueDate" @input="TaskDueDatemenu = false"></v-date-picker>
                </v-menu>               
            </v-layout>
         
          <v-btn color="success" dark @click="AddTask()">Save</v-btn>
            <v-btn @click="CancelAddTask()" color="warning" dark>Cancel</v-btn>
        </v-card>
        </v-layout>
    </v-dialog>

    <v-snackbar v-model="RollupSnackbar" :timeout="4000" top color="success">
      <span>Awesome! the rollup is complete.</span>
     <v-btn color="white" @click="RollupSnackbar = false">Close</v-btn>
    </v-snackbar>
      <!-- <v-navigation-drawer height="100%" permanent right v-model="FeatureNavlist" app width="400">
        <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :ListHeight="WindowHeight-100" :GuideList="RASystemGuides" :tab="systemguideelmnt" @guidedragstart="guidedragstart" />
      </v-navigation-drawer> -->
                   <v-card flat tile height="100%" width="100%" class="transparent" style="padding:20px;" v-if="!RoadmapApprovalView && !DatesMode && !ProjectScopeView &&!ProjectScopeTicketsView && !BillableView">
                  <!-- <v-card-title class="white"> -->
                   
                      <!-- <v-tabs centered>
                          <v-tab v-for="map in DefaultMaps" :key="map.itemObjKey" @click="AssignSelectedBoard(map)">
                             {{map.Name}}
                          </v-tab>
                          <v-tab-item  v-for="map in DefaultMaps" :key="map.itemObjKey"> -->
                       
                                 <v-layout row class="justify-center mx-3">
                                  <v-chip-group
                                        dark
                                        v-model="selectedPriority"
                                        active-class="green white--text"
                                        column
                                      >
                                      <v-chip color="red"  :value="1">
                                      High
                                      </v-chip>
                                      <v-chip color="orange" dark :value="2">
                                      Medium
                                      </v-chip>
                                      <v-chip color="blue" dark :value="3">
                                      Low
                                      </v-chip>
                                    </v-chip-group>
                                 </v-layout>
                                <v-layout row class="justify-center mx-3">
                              <v-chip-group
                                        v-if="TasksFilterMode == 'Type'"
                                        v-model="selectedType"
                                        active-class="deep-purple--text text--accent-4"
                                        column
                                        
                                    >
                                    <v-chip v-for="type in STDActionTypesbyBoard" :key="type.Name" :value="type">
                                    {{ type.Name }}
                                    </v-chip>
                                        <!-- <v-chip @click="LogselectedType()" v-for="type in STDActionTypes" :key="type.itemObjKey">{{type.Name}}</v-chip> -->
                                        
                                    </v-chip-group>
                                    
                                    </v-layout>
                              <v-row
                              
                                class="fill-height"
                                align="start"
                                justify="center"
                                >
                                <!-- <v-flex lg2 md3 sm4 class="mx-1 my-3"> 
                                  
                                </v-flex> -->
                                <v-flex lg2 md3 sm4 class="mx-1 my-3" v-for="status in StatusReasonTypesTasks" :key="status.itemObjKey">                    
                                        <v-card color="indigo lighten-5" @dragover.prevent @drop="guidedragging? '' : taskdragFinish(status.Name, $event)">
                                        <v-card-title style="font-size:12px;">{{status.Name}}</v-card-title>                     
                                        <v-list dense class="transparent mx-2"
                                                min-height="100px">
                                                <v-card  v-for="chip in status.Chips" :key="chip.itemObjKey" :id="chip.id"  :class="`chip ${chip.status} my-1`" width="100%">
                                                <v-row
                                                        class="fill-height"
                                                        align="center"
                                                        justify="start"
                                                        >
                                                <table :draggable="true" @dragstart="taskdragstart(chip, $event)" v-if="chip.Status !== 'Complete'">
                                                        <td>
                                                            <span v-if="!chip.Priority" style="color:grey;font-size:14px;font-weight: bold;" class="mx-1">0</span>
                                                            <span v-if="chip.Priority && chip.Priority === 1" style="color:red;font-size:14px;font-weight: bold;" class="mx-1">{{chip.Priority}}</span>
                                                            <span v-if="chip.Priority && chip.Priority === 2" style="color:orange;font-size:14px;font-weight: bold;" class="mx-1">{{chip.Priority}}</span>
                                                            <span v-if="chip.Priority && chip.Priority === 3" style="color:blue;font-size:14px;font-weight: bold;" class="mx-1">{{chip.Priority}}</span>
                                                        </td>
                                                        <td>
                                                            <v-icon  class="mx-1" :color="chip.Type.Color">{{chip.Type.Icon}}</v-icon>
                                                        </td>
                                                        <td style="font-size:12px;">
                                                            <span class="mx-1">{{chip.Name.substr(0,26)}}</span>
                                                        </td>
                                                        
                                                        <td>
                                                            <v-icon class="mx-1" :id="chip.id+'click'" @click="PrepareData(chip)" x-small>mdi-cogs</v-icon>
                                                        </td>
                                                        </table>
                                                         <table :draggable="true" @dragstart="taskdragstart(chip, $event)" v-if="chip.Status === 'Complete'">
                                                        <td>
                                                            <span v-if="!chip.Priority" style="color:grey;font-size:14px;font-weight: bold;" class="mx-1">0</span>
                                                            <span v-if="chip.Priority && chip.Priority === 1" style="color:red;font-size:14px;font-weight: bold;" class="mx-1">{{chip.Priority}}</span>
                                                            <span v-if="chip.Priority && chip.Priority === 2" style="color:orange;font-size:14px;font-weight: bold;" class="mx-1">{{chip.Priority}}</span>
                                                            <span v-if="chip.Priority && chip.Priority === 3" style="color:blue;font-size:14px;font-weight: bold;" class="mx-1">{{chip.Priority}}</span>
                                                        </td>
                                                        <td style="text-decoration: line-through">
                                                            <v-icon  class="mx-1" :color="chip.Type.Color">{{chip.Type.Icon}}</v-icon>
                                                        </td>
                                                        <td style="font-size:12px; text-decoration: line-through">
                                                            <span class="mx-1">{{chip.Name.substr(0,26)}}</span>
                                                        </td>
                                                        
                                                        <td>
                                                            <v-icon class="mx-1" :id="chip.id+'click'" @click="PrepareData(chip)" x-small>mdi-cogs</v-icon>
                                                        </td>
                                                        </table>
                                                </v-row>                                       
                                                </v-card>
                                                <v-btn v-if="status.Name === 'Incoming'" text @click="OpenAddTaskDialog()" class="grey--text" style="font-size:10px;"><v-icon small>mdi-plus</v-icon>Add Another</v-btn>
                                        </v-list>
                                        </v-card>
                                </v-flex>
                                <!-- <v-flex lg2 md3 sm4 class="mx-1 my-3">                    
                                        <v-card color="indigo lighten-5" @dragover.prevent @drop="taskdragdeleteFinish(status, $event)">
                                        <v-card-title style="font-size:12px;">Delete</v-card-title>                     
                                       
                                        </v-card>
                                </v-flex> -->
                                </v-row>
                              <!-- </v-tab-item>
                      </v-tabs> -->

              </v-card>
  
</v-content>


</template>

<script>
import firebase from 'firebase';
import format from 'date-fns/format'
import db from '@/main'
import SystemGuideComponent from '@/components/WebPages/RenderComponents/SystemGuideComponent';
import ContentEditableField from '@/components/Database/ContentEditableField'
export default {
    props: ['SystemRoadmapsBuilt','LastRoadmapTaskID','System','Active','SystemGuides','SystemPhotoLibraries'],
  components: {
    SystemGuideComponent,
    ContentEditableField
  },
    data() {
        return {
          NewversionAssetdialog: false,
          SystemAssets: [],
          NewVersion: {
            Version_Number: 1.01,
            Version_Release_Note: '',
            Asset: {}
          },
          NewVersionDialog: false,
          ReleaseNoteDialog: false,
          guidedragging: '',
          FeatureNavlist: true,
          systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            UserRecord: '',
            RollupSnackbar: false,
            RollupDialog: false,
            ProjectPlannedEnd: '',
            ProjectPlannedEndmenu: false,
            mode: 'stack',
            modes: ['stack', 'column'],
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            today: format(new Date(),'yyyy-MM-dd'),
            rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        DefaultMaps: [
            {
            Name: 'All',
            Items: [],
            Icon: 'mdi-iframe-variable-outline'
        },
        {
            Name: 'System General',
            Items: [],
            Icon: 'mdi-information-outline'
        },
        {
            Name: 'CRM',
            Items: [],
            Icon: 'mdi-hand'
        },
        {
            Name: 'Intranet',
            Items: [],
            Icon: 'mdi-publish'
        },
        {
            Name: 'Documentation',
            Items: [],
            Icon: 'mdi-file-document'
        },
        {
            Name: 'Team Manager',
            Items: [],
            Icon: 'mdi-human-male-female'
        },
        {
            Name: 'Website',
            Items: [],
            Icon: 'mdi-spider-web'
        },
        {
            Name: 'WIP Management',
            Items: [],
            Icon: 'mdi-progress-alert'
        },
        ],
        
        Status: {
          Name: 'Status',
          DisplayName: 'Status',
          lastassignedinteger: 1000002,
          Options: [
            {ID: 1000001,Name: 'Open'},
            {ID: 1000002,Name: 'Closed'},
          ]
        },
        Status_Reason: {
          Name: 'Status_Reason',
          DisplayName: 'Status Reason',
          lastassignedinteger: 1000008,
          Options: [
            {ID: 1000003,Name: 'Incoming'},
            {ID: 1000004,Name: 'Executing'},
            {ID: 1000005,Name: 'QA'},
            {ID: 1000006,Name: 'Publish'},
            {ID: 1000007,Name: 'Complete'},
            {ID: 1000008,Name: 'Cancelled'}
          ]
        },
        StatusReasonTypes: [
            'Incoming',
            'Executing',
            'QA',
            'Publish',
            // 'Complete',
            // 'Closed',
        ],
        dragging: -1,
        editedIndex: -1,
        headers: [
        { text: 'Type', value: 'Type'},
        { text: 'Name', value: 'Name'},
        { text: 'Map', value: 'MapName'},
        { text: 'Due_Date', value: 'Due_Date'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
        ProjectScope: [],
        SystemScopeItem:  {
                Name: 'Main',
                Description: 'This is the root System Component',
                Map: 'System General',
                Type: {
                    Name: 'System',
                    Icon: 'mdi-information-outline',
                },

            },
        ProjectScopeDialog: false,
        ProjectScopeForm: false,
        AddTaskDialog: false,
        ScopeItemMap: '',
        ScopeItemName: '',
        ScopeItemDescription: '',
        ScopeItemHasActivities: false,
        ScopeItemHasWiki: false,
        ScopeItemHasSingleView: false,
        ScopeItemHasCollectionView: false,
        ScopeItemHasAutomation: false,
        ScopeItemHasCharts: false,
        ScopeItemDueDate: '',
        ScopeItemType: '',
        ScopeItemTypes: [
            {Name: 'Page', Icon: 'mdi-book-open-page-variant'},
            {Name: 'DataSet', Icon: 'mdi-database'},
            {Name: 'Shared Content', Icon: 'mdi-library'},
            {Name: 'Forms', Icon: 'mdi-card-text-outline'},
            {Name: 'System', Icon: 'mdi-information-outline'},
            ],
        ScopeItemDueDatemenu: false,
        TaskCategory: '',
        TaskName: '',
        TaskStatusReason: 'Incoming',
        TaskDuration: 0,
        TaskPriority: '',
        selectedProjectScopeItem: '',
        selectedScope: '',
        TasksFilterMode: 'Type',
        TasksFilterModes: ['Type'],
        TaskDescription: '',
        NewTask: {},
        TaskType: '',
        selectedType: '',
        selectedPriority: '',
        STDActionTypes: [
            {
                Name: 'Bug Fixes',
                Icon: 'mdi-bug',
                Color: 'red'
            },
            {
                Name: 'Additional Features',
                Icon: 'mdi-plus',
                Color: 'green'
            },
            {
                Name: 'Field Management',
                Icon: 'mdi-clipboard-list',
                Color: 'warning'
            },
            {
                Name: 'Style and Layout',
                Icon: 'mdi-palette-advanced',
                Color: 'purple'
            },
            {
                Name: 'Dashboards and Reporting',
                Icon: 'mdi-desktop-mac-dashboard',
                Color: 'indigo'
            },   
            {
                Name: 'Content Management',
                Icon: 'mdi-content-copy',
                Color: 'red'
            },   
            {
                Name: 'Data Management',
                Icon: 'mdi-database',
                Color: 'blue'
            }, 
            {
                Name: 'System Setup',
                Icon: 'mdi-view-dashboard',
                Color: 'red'
            },       
            {
                Name: 'Navigation',
                Icon: 'mdi-sign-direction',
                Color: 'pink'
            },
            {
                Name: 'Security',
                Icon: 'mdi-security-network',
                Color: 'cyan'
            },
        ],
        UpdateTaskmenu: false,
        TaskDueDatemenu: false,
        TaskDueDatemenu2: false,
        DateUpdated: false,
        TaskDueDate: '',
        Comments: [],
        CurrentDocument: '',
        CommentInput: '',
        Commentnonce: 0,
        SampleChips: [
            'Finish Account',
            'Create Home Page'
        ],
        BoardSelected: false,
        SelectedBoard: '',
        TaskDialog: false,
        tasks: [],
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        DatesMode: false,
        ProjectScopeView: false,
        ProjectEndDate: '',
            
        }
    },
// <v-chip-group
    //     v-model="selection"
    //     active-class="deep-purple--text text--accent-4"
    //     mandatory
    //   >
    //     <v-chip>Extra Soft</v-chip>
    //     <v-chip>Soft</v-chip>
    //     <v-chip>Medium</v-chip>
    //     <v-chip>Hard</v-chip>
    //   </v-chip-group>
        computed: {
          LatestVersionNumber(){
            if(this.System.Version_Number){
              return this.System.Version_Number
            }
            else{
              return 1.01
            }
          },
          ReadyforPublish(){
            return this.tasks.filter(task => {
              return task.Status_Reason.Name === 'Publish'
            })
          },
          CurrentDocumentSystemGuides(){
            return this.CurrentDocumentFavoriteFeatures? this.RASystemGuides
            .filter(guide => {
              return this.CurrentDocumentFavoriteFeatures.includes(guide.id)
            }) : []
          },
          NonCurrentDocumentSystemGuides(){
            return this.CurrentDocumentFavoriteFeatures? this.RASystemGuides
            .filter(guide => {
              return !this.CurrentDocumentFavoriteFeatures.includes(guide.id)
            }) : []
          },
          CurrentDocumentFavoriteFeatures(){
            if(this.CurrentDocument){
              if(this.CurrentDocument.FavoriteFeatures){
                return this.CurrentDocument.FavoriteFeatures
              }
              else{
                return []
              }
            }
          },
          WindowHeight(){
            return window.innerHeight
          },
          RASystemGuides(){
            return this.SystemGuides.filter(guide => {
                if(this.$route.meta && this.$route.meta.SystemBuilder){
                    return guide
                }
                else{
                 return guide.Provider && guide.Provider.Name === 'RapidApps'   
                }                
            })
        },
          StatusReasonTypesTasks(){
            return this.StatusReasonTypes.map(type => {
              let typeobj = {Name: type}
                typeobj.Chips = []
                typeobj.Chips = this.BoardTasks.filter(task => {
                  return task.Status_Reason && task.Status_Reason.Name === type
              })
              return typeobj
            })
          },
          userLoggedIn () {
            return this.$store.getters.user
          },
          ProjectScopebyBoard(){
            let NewArray = Array.from(this.BoardTasks)
            console.log(NewArray)
              let BoardTaskTypes = NewArray.map(task => {
                return task.ScopeItem
              }).flat()
              console.log(BoardTaskTypes)
            return this.ProjectScope.filter(project => {
              let projobj = BoardTaskTypes.find(obj => obj.Name === project.Name)
              return BoardTaskTypes.includes(projobj)
              
            })
          },
          STDActionTypesbyBoard(){
            let NewArray = Array.from(this.BoardTasks)
            console.log(NewArray)
              let BoardTaskTypes = NewArray.map(task => {
                return task.Type
              }).flat()
              console.log(BoardTaskTypes)
            return this.STDActionTypes.filter(type => {
              let typeobj = BoardTaskTypes.find(obj => obj.Name === type.Name)
              return BoardTaskTypes.includes(typeobj)
              
            })
          },
          ProjectScopewithRollup(){
                return this.ProjectScope.map(scope => {
                    scope.MapName = scope.Map.Name
                    if(typeof scope.Due_Date.toDate !== 'undefined'){
                      console.log(scope)
                      scope.Due_Date = this.TimestampFormatterSTRING(scope.Due_Date.toDate())
                    }
                    return scope
                })
            },
            monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
            datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
            ProjectScopeformTitle () {
                    return this.editedIndex === -1 ? 'New Scope Item' : 'Edit Scope Item'
                },
            formTitle () {
                    return this.editedIndex === -1 ? 'New Task' : 'Edit Task - '+this.TaskName
                },
            BoardTasks(){
                return this.tasks
                .filter(task => {
                    if(this.selectedType){
                        return task.Type.Name === this.selectedType.Name
                    }
                    else {
                        return task
                    }
                }).filter(task => {
                  if(this.selectedPriority){
                    return task.Priority === this.selectedPriority
                  }
                  else{
                    return task
                  }
                }).map(task => {
                    if(task.Due_Date){
                        if(typeof task.Due_Date.toDate !== 'undefined'){
                        let dateobject = task.Due_Date.toDate()
                        if(dateobject){
                        let FinalDueDate = this.DateFormatter(format(dateobject,'yyyy-MM-dd'))
                        let Today = new Date()
                        let yearstring = format(Today,'yyyy-MM-dd').split('-')[0]
                        let monthstring = format(Today,'yyyy-MM-dd').split('-')[1]
                        let daystring = format(Today,'yyyy-MM-dd').split('-')[2]
                        let yearnumber = Number(yearstring)
                        let monthnumber = Number(monthstring)
                        let daynumber = Number(daystring)+Number(7)
                    
                        let WeekfromNow = new Date(yearnumber, monthnumber-1, daynumber)
            
                        if(Today > FinalDueDate){
                            task.status = 'Overdue'
                        }
                        else if(WeekfromNow > FinalDueDate){
                            task.status = 'DueThisWeek'
                        }
                        else{
                            task.status = 'Normal'
                        }
                        
                        }
                        }
                    }
                    return task
                })
            },
          
    },
    created(){
      this.GetSystemAssets()
      this.getTodoLists()
      this.getProjectScopeItems()
      this.GetUser()
      setTimeout(() => {
          console.log('this.BoardTasks',this.BoardTasks)
      }, 1000);
        
// this gives an object with dates as keys



    },
    mounted () {
        if(this.DatesMode){
      this.$refs.calendar.checkChange()
        }
        if(this.Active){
        
        setTimeout(() => {
            let elmnt = document.getElementById(this.Active);
            let elmntbtn = document.getElementById(this.Active+'click');
            console.log(elmnt)
          elmnt.scrollIntoView();
          elmntbtn.click()
          elmnt.setAttribute('class','blob')
          setTimeout(() => {
            
            }, 300);
          }, 1000);
      }
    },
    methods: {
      ChooseImage(img){
        console.log(img)
        this.NewVersion.Asset = img
        this.NewversionAssetdialog = false
      },
      GetSystemAssets(){
        let vm = this
        if(this.SystemPhotoLibraries){
        this.SystemAssetLibrary = this.SystemPhotoLibraries.find(obj => obj.id === 'System_Assets')
        let array = this.$store.state[this.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array']
        let query = this.$store.state[this.SystemAssetLibrary.DisplayName.split(' ').join('')+'Query']
        if(array && array.length > 0){
          this.SystemAssets = array
        }
        else if(query){
          let payload = {                
            query: query,
            arrayname: this.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array'
          }
          
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                  vm.SystemAssets = vm.$store.state[vm.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array']
              })
        }
        console.log(this.SystemAssetLibrary,this.SystemAssets,vm.$store.state[vm.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array'],vm.$store.state,vm.SystemAssetLibrary.DisplayName.split(' ').join('')+'Array')
      }
      },
      CancelVersionRelease(){
        this.NewVersionDialog = false
        this.NewVersion.Version_Number = this.LatestVersionNumber
        this.NewVersion.Version_Release_Note = '' 
        this.NewVersion.Asset = {}
      },
      releaseNewVersion(){
        let length = this.ReadyforPublish.length
        let versionnumber = Number(this.NewVersion.Version_Number)
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
          Version_Number: this.NewVersion.Version_Number,
          Version_Release_Note: this.NewVersion.Version_Release_Note,
          Version_Release_Date: new Date()
        })        
        this.ReadyforPublish.map(item => {
          this.UpdateTaskStatus(item,'Complete')
        })
        //this.CancelVersionRelease()
        let url = this.NewVersion.Asset.fileurl
        let thumburl = this.NewVersion.Asset.ThumbURL
        let caption = 'Version '+versionnumber+' contains a few updates. Read here for more details.'
        this.CreateNewNotice(this.NewVersion.Version_Number,this.NewVersion.Version_Release_Note,url,thumburl,caption)
      },
      CreateNewNotice(Version_Number,Version_Release_Note,url,thumburl,caption){ 
        let vm = this
        let Moderators = [
          {
            Full_Name: this.userLoggedIn.Full_Name,
            id: this.userLoggedIn.id,
            Name: this.userLoggedIn.Name,
            Surname: this.userLoggedIn.Surname,
            ModerationPermissions: this.userLoggedIn.ModerationPermissions
          }
        ]
        let ModeratorRolesObject = {}
        let ModeratorRolesArray= []
        Moderators.map(mod => {
          ModeratorRolesObject[mod.id] = true
          ModeratorRolesArray.push(mod.id)
        })
        const article = { 
              title: 'Version Release - '+Version_Number,
              description: Version_Release_Note,
              caption: caption,
              author: vm.userLoggedIn.Name+' '+vm.userLoggedIn.Surname,
              authorid: vm.userLoggedIn.id,
              coverimage: url,
              coverimageThumbURL: thumburl,
              PublishStatus: 'Draft',
              Moderators: Moderators,
              moderatorrolesarrayDBRules: ModeratorRolesObject,
              moderatorrolesarrayQuery: ModeratorRolesArray,
              Likes: [],
              Shares: [],
              tags: [],
              Category: {
                ID: 1000001,
                Name: 'General'
              },
              createdon: new Date(),
              Creator: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},          
              Creatorid: vm.userLoggedIn.id,
              Owner: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},          
              Ownerid: vm.userLoggedIn.id,  
              PublishDate: new Date()
            }  
        db.collection('notices').add(article).then(function(doc) {
          const systemactivity = {
            user: vm.userLoggedIn,
            contentvar: 'created a new Notice',
            location: '/NoticeSingle/',
            docname: article.title,
            docid: doc.id,
            type: 'New Notice',
          }                          
          vm.$store.dispatch('createSystemActivity',systemactivity)
            vm.$router.push('/NoticeSingle/'+doc.id)
        })
      },
      updateVersionReleaseNote(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(prop,value,AdditionalSaveMethod,FieldObject)     
    },
      PublishNewVersionDialog(){        
        let number = (Number(this.LatestVersionNumber)+0.01).toFixed(2)
        //alert(typeof number+' '+number)
        this.NewVersion.Version_Number = Number(number)
        this.NewVersion.Version_Release_Note = `
        <ul>This update includes:`
        this.ReadyforPublish.map(item => {
          this.NewVersion.Version_Release_Note = this.NewVersion.Version_Release_Note+
        `<li>`+item.Release_Note+`</li>`
        })
        this.NewVersion.Version_Release_Note = this.NewVersion.Version_Release_Note+`          
        </ul>`
        this.NewVersionDialog = true
      },
      ToggleRoadmapItemScope(item){
        let array = JSON.parse(JSON.stringify(this.CurrentDocumentFavoriteFeatures))
        let oncheck = array.find(obj => obj === item.id)
        if(!oncheck){
          array.push(item.id)
        db.collection('roadmapitems').doc(this.CurrentDocument.id).update({
          FavoriteFeatures: array
        })
        }
        else{
          let index = array.indexOf(oncheck)
          array.splice(index,1)
          db.collection('roadmapitems').doc(this.CurrentDocument.id).update({
          FavoriteFeatures: array
        })

        }
      },
      guidedragstart(guide){
        console.log(guide)
        this.guidedragging = guide
      },
      getProjectScopeItems(){
        db.collection('systemscope').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ProjectScope.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
  
      })
      })
    
      
    },
      TimestampFormatterStartTimeSTRING(d,task){
        // var date = d.getDate();
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]   
         if(task.Duration === 20){ 
        //console.log('%c working date start', 'background: #eb371b; color: #ffffff')
         }
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        let date = d
        let dateday = date.getDate()
        //console.log(dateday)
        let taskduration = task.Duration
        let taskdurationtype = task.Duration
          
        let daystarthoursstring = ''.split(':')[0]
        var hr = Number(daystarthoursstring);
        let dayendhoursstring = ''.split(':')[0]
        let daystartminutesstring = ''.split(':')[1]
        var min = Number(daystartminutesstring);
        
        var dayendhr = Number(dayendhoursstring);
        let dayendminutesstring = ''.split(':')[1]
        var dayendmin = Number(dayendminutesstring);
        // let starthourminutestring = task.start.split(' ')[1]
        // let starthourstring = starthourminutestring.split(':')[0]
        // let startminutesstring = starthourminutestring.split(':')[1]
        let starthoursnumber = Number(daystarthoursstring)
        let startminutesnumber = Number(daystartminutesstring)
        let hoursinday = dayendhr-starthoursnumber
        let hourstoadd = taskduration-hoursinday
        if(hourstoadd>0){
          //console.log('%c this yu want', 'background: #eb371b; color: white')
          //console.log('this one has longer duration than day is long',taskduration,task.id)
          let workingdays = task.Duration/hoursinday
          // let workinghours = hr-dayendhr
          //console.log(task.Duration,hoursinday,workingdays)
          let daystoadd = Math.floor(workingdays)
          console.log(daystoadd)
          // let workinghoursstring = workinghours.toString()
          let daystoaddstring = workingdays.toString()
          //console.log(daystoaddstring)
          let hourstoaddstring = '0.'+daystoaddstring.split('.')[1]
          //console.log(hourstoaddstring)
          let hourstoaddnumber = Number(hourstoaddstring)
          //console.log(hourstoaddnumber)
          let temphours = hoursinday*hourstoaddnumber
          let finalhourstoaddnumber = temphours.toFixed(0)
          // hr = starthoursnumber+finalhourstoaddnumber
          hr = dayendhr-finalhourstoaddnumber
          //console.log(hr)
          dateday = dateday-Number(daystoadd)
        
          //console.log(dateday)
        }
        if(dateday < 0){
            let daysovermonth = 31+dateday
            let monthsdivide = daysovermonth/31 
            let monthaddtest = Math.round(monthsdivide)
            let monthstoadd = -1+monthaddtest
            //console.log('%c this yu want', 'background: #eb371b; color: white')
            //console.log('monthstoadd',monthstoadd, 'background: #222; color: #bada55')
            let monthindex = months.indexOf(month)
            console.log('monthindex',monthindex, 'background: #222; color: #bada55')
            let newmonthindex = monthindex+monthstoadd
            //console.log('newmonthindex',newmonthindex, 'background: #222; color: #bada55')
            month = months[newmonthindex];
            dateday = 32+dateday
          }
        //console.log(min)
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        
        let datestring = year+'-'+month+'-'+dateday+'T'+hr+ ":" + min
        //console.log(datestring, task.Duration)
        let dateobj = new Date(datestring)
        //console.log(dateobj)
        let finaldate = format(dateobj,'yyyy-MM-dd')+'T'+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },

      TimestampFormatterwithDurationHoursSTRING(d,task){
        if(task.Duration === 20){
        //console.log('%c this yu want', 'background: #eb371b; color: white')
        }
        let taskduration = task.Duration
        let taskdurationtype = task.Duration
        if(task.Duration === 20){
        //console.log('taskduration',taskduration,task)
        }
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
        let test = task.start
        
        let dayendhoursstring = ''.split(':')[0]
        var dayendhr = Number(dayendhoursstring);
        let dayendminutesstring = ''.split(':')[1]
        var dayendmin = Number(dayendminutesstring);
        let starthourminutestring = ''
        let starthourstring = starthourminutestring.split(':')[0]
        let startminutesstring = starthourminutestring.split(':')[1]
        let starthoursnumber = Number(starthourstring)
        let startminutesnumber = Number(startminutesstring)
        let hoursinday = dayendhr-starthoursnumber
        //console.log(typeof starthourstring,starthourstring,typeof startminutesstring,startminutesstring)
        
        var min = d.getMinutes();
        min = min+startminutesnumber
        if(min > 60){
          let minutesoverflow = min/60
          hr = hr+Math.round(minutesoverflow)
          min = minutesoverflow.toString().split('.')[1]
        }
        let total = hr+taskduration+starthoursnumber
        hr = Math.round(total)
        let totalhour = Math.floor(total)
        let totalall = total.toString()
        
        let totalhourstring = total.toString()
        let totalminutes = '0.'+totalall.split('.')[1]
        let totalminutesnumber = 60*Number(totalminutes)
        // var date = d.getDate();
        var month = months[d.getMonth()];
        let date = d
        let dateday = new Date(task.start).getDate()
        //console.log(dateday)
        var year = d.getFullYear();
        if(totalminutesnumber){
          min = min+totalminutesnumber
        }
        if (min < 10) {
            min = "0" + min;
        }
        //console.log(hr,dayendhr)
        if(hr>dayendhr){
          //console.log('%c this one is extending day end hours', 'background: #222; color: #bada55')
          //console.log(hr,taskduration,hoursinday)
          let workingdays = taskduration/hoursinday
          let workinghours = hr-dayendhr
          //console.log(taskduration,hoursinday,workingdays)
          let daystoadd = Math.floor(workingdays)
          //console.log(daystoadd)
          // let workinghoursstring = workinghours.toString()
          let daystoaddstring = workingdays.toString()
          //console.log(daystoaddstring)
          let hourstoaddstring = '0.'+daystoaddstring.split('.')[1]
          //console.log(hourstoaddstring)
          let hourstoaddnumber = Number(hourstoaddstring)
          //console.log(hourstoaddnumber)
          let temphours = hoursinday*hourstoaddnumber
          let finalhourstoaddnumber = temphours.toFixed(0)
          // hr = starthoursnumber+finalhourstoaddnumber
          hr = starthoursnumber+workinghours
          //console.log(hr)
          dateday = dateday+Number(daystoadd)
          //console.log(dateday)
          if(dateday > 31){
            let daysovermonth = dateday-31
            let monthsdivide = daysovermonth/31 
            let monthaddtest = Math.round(monthsdivide)
            let monthstoadd = 1+monthaddtest
            //console.log('%c this yu want', 'background: #eb371b; color: white')
            //console.log('monthstoadd',monthstoadd, 'background: #222; color: #bada55')
            let monthindex = months.indexOf(month)
            //console.log('monthindex',monthindex, 'background: #222; color: #bada55')
            let newmonthindex = monthindex+monthstoadd
            //console.log('newmonthindex',newmonthindex, 'background: #222; color: #bada55')
            month = months[newmonthindex];
            dateday = dateday-31
          }
        }
        if(hr < 10){
          hr = "0"+hr
        }
        if(hr > dayendhr){
          hr = dayendhr
          min = dayendmin
        }
        
        
        
        
        if(task.IsProjectEnd){
          hr = '23'
          min = '59'
        }
        let datestring = year+'-'+month+'-'+dateday
        //console.log(datestring, task.Duration)
        let dateobj = new Date(datestring)
        //console.log(dateobj)
        let finaldate = format(dateobj,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },

      async GetUser(){
        let vm = this
        await firebase.auth().onAuthStateChanged(function(user) {
          if (user) {    
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()
              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
            })
          }
          else{
            vm.RoutetoErrorPage()
          }
        })
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
        DeactivateActiveFilter(){
            this.selectedType = ''
            this.selectedScope = ''
        },
        addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },

    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = this.ContentBlockObject.Name.split(' ').join('').split('(').join('').split(')').join('')+'editor'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },
        TimestampFormatter(d){
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        var day = days[d.getDay()];
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "am";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "pm";
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        return `<p style="font-size:12px;color:grey;">`+day + " " + date + " " + month + " " + year + " "+ hr + ":" + min + ampm + " </p>";
      },
        Postscomment () {
    //   this.TempComments = Array.from(this.Comments)
      let User = {Full_Name: this.UserRecord.Full_Name, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, id: this.UserRecord.id}
        this.Comments.push({
          id: this.Commentnonce++,
          text: this.CommentInput,
          time: new Date(),
          User: User,
          Userid: User.id
          }),
        

        this.CommentInput = null
        this.UpdatechipComments()
        },
        UpdatechipComments(){
            console.log('oh yes I call',this.Commentnonce)
            this.Commentnonce = this.Commentnonce++
            if(typeof this.Comments !== 'undefined'){
              db.collection('roadmapitems').doc(this.editedIndex).update({
                Comments: this.Comments
              })
              }                   
            if(typeof this.Commentnonce !== 'undefined'){
              db.collection('roadmapitems').doc(this.editedIndex).update({
                Commentnonce: this.Commentnonce
              })
            }
        },
        PrepareScopeData(scope){
            this.editedIndex = scope.id
            this.ScopeItemName = scope.Name
            this.ScopeItemMap = scope.Map
            this.ScopeItemDescription = scope.Description
            this.ScopeItemDueDate = this.TimestampFormatterSTRING(scope.Due_Date.toDate())
            this.ScopeItemType = this.ScopeItemTypes.find(obj => obj.Name == scope.Type.Name)
            this.ScopeItemHasActivities = scope.HasActivities
            this.ScopeItemHasWiki = scope.HasWiki
            this.ScopeItemHasSingleView = scope.SingleView
            this.ScopeItemHasCollectionView = scope.Collectionview
            this.ScopeItemHasCharts = scope.HasCharts
            this.ScopeItemHasAutomation = scope.HasAutomation
            this.ActivateProjectScopeForm()
        },
        nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
        setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
        showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
    //   updateRange ({ start, end }) {
    //     const events = []

    //     const min = new Date(`${start.date}T00:00:00`)
    //     const max = new Date(`${end.date}T23:59:59`)
    //     const days = (max.getTime() - min.getTime()) / 86400000
    //     const eventCount = this.rnd(days, days + 20)

    //     for (let i = 0; i < eventCount; i++) {
    //       const allDay = this.rnd(0, 3) === 0
    //       const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //       const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //       const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //       const second = new Date(first.getTime() + secondTimestamp)

    //       events.push({
    //         name: this.names[this.rnd(0, this.names.length - 1)],
    //         start: this.formatDate(first, !allDay),
    //         end: this.formatDate(second, !allDay),
    //         color: this.colors[this.rnd(0, this.colors.length - 1)],
    //       })
    //     }

    //     this.start = start
    //     this.end = end
    //     this.events = events
    //   },
      formatDate (a, withTime) {
          console.log(a)
        return withTime
          ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
      },
 
        updateRange ({ start, end }) {
        this.start = start
            this.end = end
        },
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return 'red'
        },
        LogselectedType(){
            console.log(this.selectedType)
        },
        AssignSelectedBoard(map){
            this.SelectedBoard = map
        },
        ActivateProjectScopeForm(){
            console.log('activating additional scope form')
            this.ProjectScopeForm = true
        },
        AddProjectScope(){
            if(this.editedIndex === -1){
            let NewProjectScope = {
                Name: this.ScopeItemName,
                Map: this.ScopeItemMap,
                Description: this.ScopeItemDescription,
                Due_Date: this.DateFormatter(this.ScopeItemDueDate),
                Type: this.ScopeItemType,
                Systemid: this.System.id,
                HasActivities: this.ScopeItemHasActivities,
                HasWiki: this.ScopeItemHasWiki,
                SingleView: this.ScopeItemHasSingleView,
                Collectionview: this.ScopeItemHasCollectionView,
                HasCharts: this.ScopeItemHasCharts,
                HasAutomation: this.ScopeItemHasAutomation,
            }
            let vm = this
            db.collection('systemscope').add(NewProjectScope).then(doc => {
                vm.CancelProjectScope()
            })  
            }
            else{
                this.UpdateScopeItem()
            }
        },
        UpdateScopeItem(){
            db.collection('systemscope').doc(this.editedIndex).update({
                Name: this.ScopeItemName,
                Map: this.ScopeItemMap,
                Description: this.ScopeItemDescription,
                Due_Date: new Date(this.ScopeItemDueDate),
                Type: this.ScopeItemType,
                Systemid: this.System.id,
                HasActivities: this.ScopeItemHasActivities,
                HasWiki: this.ScopeItemHasWiki,
                SingleView: this.ScopeItemHasSingleView,
                Collectionview: this.ScopeItemHasCollectionView,
                HasCharts: this.ScopeItemHasCharts,
                HasAutomation: this.ScopeItemHasAutomation,
            })
            // setTimeout(() => {
                this.CancelProjectScope()
            // }, 500);  
            
        },
        CancelProjectScope(){
            this.ScopeItemMap = ''
            this.ScopeItemName = ''
            this.ScopeItemDescription = ''
            this.ScopeItemDueDate = ''
            this.ScopeItemType = ''
            this.ScopeItemHasActivities = false,
            this.ScopeItemHasWiki = false,
            this.ScopeItemHasSingleView = false,
            this.ScopeItemHasCollectionView = false,
            this.ScopeItemHasAutomation = false,
            this.ScopeItemHasCharts = false,
            this.ProjectScopeDialog = false
            this.editedIndex = -1
        },
        ActivateProjectPlan(){
            this.ProjectScopeView = true
        },
        deleteTask(chipid){
            let chipobj = this.tasks.find(obj => obj.id === chipid)
            let chipindex = this.tasks.indexOf(chipobj)
            // confirm('Are you sure you want to delete this item?') && 
            db.collection('roadmapitems').doc(chipid).delete() && this.tasks.splice(chipindex, 1)
            this.CancelUpdateTask()
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
      TimestampFormatterwithHoursSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
        hr = hr-1+2
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        UpdateTask(){
            // let CurrentDocumentVerify = 
            // if(this.DateUpdated){
            let NewPostText = ''
           if(typeof this.CurrentDocument !== 'undefined' && this.CurrentDocument !== ''){
            NewPostText = '<a href="User/'+this.UserRecord.id+'" target="_blank">'+this.UserRecord.Full_Name+'</a>'+' updated the task'
            }
            // else{
            // NewPostText = this.UserRecord.Full_Name+' linked a a new document '+'<a href="'+LinkDocument.URL+'" target="_blank">'+LinkDocument.Name+'</a>'
            // }
            let User = {Full_Name: this.UserRecord.Full_Name, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, id: this.UserRecord.id}
            this.Comments.push({
            id: this.Commentnonce++,
            text: NewPostText,
            time: new Date(),
            User: User,
            Userid: User.id
            })
            //console.log(this.TaskDueDate)
            //console.log(new Date(this.TaskDueDate))
            let statusreason = this.Status_Reason.Options.find(obj => obj.Name === this.TaskStatusReason)
            let status = ''
            if(this.TaskStatusReason === 'Closed'){
              status = this.Status.Options[1]
            }
            else{
              status = this.Status.Options[0]
            }
            this.TaskDescription = this.NewTask.Overview
            db.collection('roadmapitems').doc(this.editedIndex).update({
                Due_Date: new Date(this.TaskDueDate),
                Name: this.TaskName,
                Status: status,
                Status_Reason: statusreason,
                Duration: Number(this.TaskDuration),
                Priority: this.TaskPriority,
                Overview: this.TaskDescription,
                Type: this.TaskType
            })
            
            // let UpdatedTask = {
            //     id: this.editedIndex,
            //     Name: this.TaskName,
            //     Overview: this.TaskDescription,
            //     Status: this.TaskStatusReason,
                    Priority: this.TaskPriority,
            //     Type: this.TaskType,
            //     Category: this.TaskCategory,
            //     Due_Date: this.DateFormatter(this.TaskDueDate),

            // }
            // let taskobj = this.tasks.find(obj => obj.id == UpdatedTask.id)
            // let taskindex = this.tasks.indexOf(taskobj)
            // this.tasks[taskindex] = UpdatedTask
            console.log('this.tasks',this.tasks)
            let tempboard = this.SelectedBoard
            this.SelectedBoard = ''
            setTimeout(() => {
                this.SelectedBoard = tempboard
            }, 200);
            this.UpdatechipComments()
            this.CancelUpdateTask()
                
            // }
            // else{
               
           
            // db.collection('roadmapitems').doc(this.editedIndex).update({
            //     Name: this.TaskName,
            //     Status: this.TaskStatusReason,
            //    Priority: this.TaskPriority,
            //     Overview: this.TaskDescription,
            //     Type: this.TaskType
            // })
            // this.CancelUpdateTask()
            // }
            
            
        },
        CancelUpdateTask(){
            this.TaskDueDate = ''
            this.TaskName = ''
            this.TaskStatusReason = 'Incoming'
            this.TaskDuration = 0
            this.TaskPriority = ''
            this.TaskDescription = ''
            this.TaskType = ''
            this.selectedProjectScopeItem = ''
            this.editedIndex  = -1
            this.AddTaskDialog = false
            this.CurrentDocument = ''
            this.FeatureNavlist = false
            this.NewTask = {}
        },
        PrepareData(chip){
          console.log('preparedata chip',chip)
            this.CurrentDocument = chip
            this.NewTask = Object.assign({},chip)
            if(typeof chip.Due_Date.toDate !== 'undefined'){
            this.TaskDueDate = this.TimestampFormatterSTRING(chip.Due_Date.toDate())
            }
            else{
                this.TaskDueDate = chip.Due_Date
            }
            this.selectedProjectScopeItem = chip.ScopeItem
            this.TaskName = chip.Name
            this.TaskStatusReason = chip.Status_Reason.Name
            this.TaskDuration = chip.Duration
            if(chip.Priority){
            this.TaskPriority = chip.Priority
            }
            this.TaskDescription = chip.Overview
            this.TaskType = chip.Type
            this.editedIndex  = chip.id
            setTimeout(() => {
             this.FeatureNavlist = true 
            }, 300);
            
            this.AddTaskDialog = true
            if(chip.Comments){
                this.Comments = chip.Comments
            }
            if(chip.Commentnonce){
                this.Commentnonce = chip.Commentnonce
            }
        },
        UpdateFormattedDate(chip){
            chip.Due_Date = this.DateFormatter(this.TaskDueDate)
        },
        OpenAddTaskDialog(){
            this.AddTaskDialog = true
            this.TaskCategory = this.SelectedBoard.Name
        },
        CancelAddTask(){
             if(this.editedIndex === -1){
        this.AddTaskDialog = false
        this.TaskDueDate = ''
            this.TaskName = ''
            this.TaskStatusReason = ''
            this.TaskDuration = ''
            this.TaskPriority = ''
            this.TaskDescription = ''
            this.TaskType = ''
             }
             else{
                 this.CancelUpdateTask()
             }
        },
        DateFormatter(date){
            if(date){
                // console.log(date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },
        AddTask(){
            if(this.editedIndex === -1){
              this.TaskDescription = this.NewTask.Overview
              console.log(this.Status_Reason,this.TaskStatusReason)
              let statusreason = this.Status_Reason.Options.find(obj => obj.Name === this.TaskStatusReason)
              let status = ''
              if(this.TaskStatusReason === 'Closed'){
                status = this.Status.Options[1]
              }
              else{
                status = this.Status.Options[0]
              }
            let NewTask = {
                // ID: this.LastRoadmapTaskID-1+2,
                Name: this.TaskName,
                Overview: this.TaskDescription,
                Status: status,
                Status_Reason: statusreason,
                Priority: this.TaskPriority,
                // ScopeItem: this.selectedProjectScopeItem,
                Type: this.TaskType,
                //Category: this.TaskCategory,
                Due_Date: new Date(this.TaskDueDate),
                Systemid: this.System.id,
                // Duration: Number(this.TaskDuration),
                // DurationType: 'Hours'
            }
            let User = {Full_Name: this.userLoggedIn.Full_Name, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, id: this.userLoggedIn.id}
            NewTask.Comments = [{
            id: this.Commentnonce++,
            text: '<a href="/User/'+this.userLoggedIn.id+'" target="_blank">'+this.userLoggedIn.Full_Name+'</a>'+' created the task',
            time: new Date(),
            User: User,
            Userid: User.id
            }]
            NewTask.Commentnonce = this.Commentnonce
            let vm = this
            console.log(NewTask)
            db.collection('roadmapitems').add(NewTask).then(doc => {
                
                vm.CancelAddTask()
                vm.$emit('UpdateSystemLastRoadmapTaskID',NewTask.ID)
            })
            }
            else{
                this.UpdateTask()
            }
            
        },
        UpdateTasks(){
            console.log('imagine I did udate')
        },
        
        taskdragEnd(ev) {
      this.dragging = -1
      this.ReleaseNoteDialog = false
    },

    taskdragstart(which, ev) {
        console.log('which',which)
    let taskobj = this.tasks.find(obj => obj.id == which.id)
    this.dragging = this.tasks.indexOf(taskobj)
    console.log("this.dragging",this.dragging)
    },
    taskdragdeleteFinish(to, ev){
        let docid = this.tasks[this.dragging].id
     
        this.deleteTask(docid)    
    },
    
    taskdragFinish(to, ev) {
        console.log('I finish to make status - ',to)
      //this.tasks[this.dragging].Status = to
      if(to !== 'Publish'){
       this.UpdateTaskStatus(this.tasks[this.dragging],to)
       this.taskdragEnd() 
      }
      else{
        this.ReleaseNoteDialog = true
      }
      

    },
    UpdateTaskStatus(task,StatusReason,Release_Note){
      let statusreason = this.Status_Reason.Options.find(obj => obj.Name === StatusReason)
      let status = ''
      if(StatusReason === 'Cancelled' | StatusReason === 'Completed'){
        status = this.Status.Options[1]
      }
      else{
        status = this.Status.Options[0]
      }
        db.collection('roadmapitems').doc(task.id).update({
            Status: status,
            Status_Reason: statusreason
        })
        if(Release_Note){
          db.collection('roadmapitems').doc(task.id).update({
            Release_Note: Release_Note,
        })
        this.taskdragEnd() 
        }
    },
        Match(BoardTasks,status){
            return this.BoardTasks.filter(task => {
                return task.Status === status
            })
        },
        ActivateMapBoard(item){
            this.BoardSelected = true
            this.SelectedBoard = item
        },
      

      getTodoLists() {
        db.collection('roadmapitems').onSnapshot(res => {
            console.log(this.tasks)
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.tasks.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        } 
        if (change.type === 'modified') {
            this.selectedType = {Name: ''}
            let taskitem = this.tasks.find(obj => obj.id == change.doc.id)
            let taskindex = this.tasks.indexOf(taskitem)
            console.log(taskitem,taskindex)
            let taskobj = {}
            taskobj = change.doc.data()
            taskobj.id = change.doc.id
            //this.tasks.splice(taskindex,1)
            this.tasks[taskindex] = taskobj
            console.log(this.tasks)
            this.selectedType = ''
        } 
  
      })
      })
    
      },
      
    },
}
</script>

<style>
/* .toolbar {
  background-color: aqua
} */
custommargin{
  margin-left: 1px;
  margin-right: 1px;
}
hr.new4 {
  border: 0.5px dotted orange;
}
hr.new5 {
  border: 1px solid #012840;
}
.v-chip.max-width-chip {
  max-width: '150px';
}

.v-chip__content.max-width-chip {
  line-height: 32px;
  padding-right: 30px !important;
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.v-chip__close.max-width-chip {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
}
.task.Marketing{
  border-left: 4px solid #3CD1C2;
}
.task.Sales{
  border-left: 4px solid orange
}
.task.Project{
  border-left: 4px solid tomato;
}
.task.General{
  border-left: 4px solid peachpuff;
}
/* .taskgroup.Finances{
  color:#3CD1C2;
}
.taskgroup.Systems{
  color:orange;
}
.taskgroup.Training{
  color:tomato;
}
.taskgroup.WIP{
  color: peachpuff;
}
.taskgroup.Strategy{
  color: whitesmoke;
} */
h2 {
    color:whitesmoke;
}
.alignleft {
  text-align: left;
}
td:nth-child(3) {
  text-align: right;
}
.chip{
    background-color: white;
}
.chip.Normal {

    border-left: 4px solid #3cd1c2;
    height: 35px;
   /* max-width: 250px; */
}
.chip.Overdue {

    border-left: 4px solid red;
    height: 35px;
   /* max-width: 250px; */
}
.chip.DueThisWeek {

    border-left: 4px solid #e46b08;
    height: 35px;
   /* max-width: 250px; */
}

.blob {
	
	
	margin: 10px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.chip.complete{
  border-left: 2px solid #3cd1c2;
}
.chip.ongoing{
  border-left: 4px solid #ffaa2c;
}
.chip.overdue{
  border-left: 4px solid #f83e70;
}
</style>

    