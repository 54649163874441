<template>
  <div :style="PreviewMode ? '' : 'margin-top: 128px;'">
    
    <div v-if="!PreviewMode && !ActiveTemplatechild" style="margin-top:236px;">
        <v-card-title v-if="ActiveTemplate">
          <v-btn dark outlined color="error" @click="$route.name === 'MarketplaceTemplate' ? RoutetoAll() : CancelTemplateView()">
            <v-icon>mdi-keyboard-return
            </v-icon>
            All My Templates
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn v-if="userIsAdmin" @click="ConfirmDeleteTemplate(ActiveTemplate)" dark outlined color="error">
                <v-icon>mdi-delete-forever
                </v-icon>Delete
            </v-btn>
            <v-btn v-if="RAUserObj && TransfertoRA && !ActiveTemplate.RATemplateid" @click="ConfirmTransfertoRA(ActiveTemplate)" dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Transfer to RA
            </v-btn>  
            <v-btn v-if="RAUserObj && TransfertoRA && ActiveTemplate.RATemplateid" disabled dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Already on RA
            </v-btn>          
        </v-card-title>
        <v-card-title v-if="ActiveTemplate && !ActiveTemplatechild">
            <v-btn @click="TogglePreviewingMode()" dark outlined color="blue" >
                Preview
            </v-btn>
            <v-spacer>
            </v-spacer>
        </v-card-title>
    </div>
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl10 lg10 md10 sm10 xs10" v-if="!ActiveTemplatechild" >
    <v-card elevation="2" class="siteconfigcard">
        <v-list>
          
            <v-card-subtitle class="justify-end mediumoverline">
                Pages
                    <v-btn right absolute outlined @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-btn>
            </v-card-subtitle>
            <v-list-item>
                <v-select :items="RoutedPages" @change="UpdateTemplateSocialLandingPage()"
                v-model="SuiteSocialNetwork.Social_Landing_Page" label="Landing Page"
                />
            </v-list-item>
            <v-list-item @click="ActivateTemplateChild(child)" class="detailslistoutline" v-for="child in ComputedTemplateChildren" :key="child.itemObjKey">
                {{child.Name}}
            </v-list-item>
        </v-list>
    </v-card>
    <SocialNetworkConfig v-if="SuiteSocialNetwork"
    :Directories="Directories" :RADB="RADB" style="margin-top: 20px;" :ActiveTemplate="ActiveTemplate"
    :SiteModerators="SiteModerators" :System="SuiteSocialNetwork" :SystemEntities="SystemEntities"
    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
    />
    
    </div>
      <LandingPageSingle v-if="ActiveSuiteTemplate && ActiveTemplatechild && PreviewMode"
     :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities"
        :SitePages="SitePages" :style="TemplatePreviewStyle" @TogglePreviewingMode="TogglePreviewingMode" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
        />
        <NewLandingPage v-if="ActiveSuiteTemplate && ActiveTemplatechild && !Refreshing && !PreviewMode"
        :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities" style="margin-top: 0px;" :PublicNavbarEditing="PublicNavbarEditing"
        @RouteBack="Refresh" @TogglePreviewingMode="TogglePreviewingMode" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :SitePages="SitePages"
                />
       <!-- </v-card-text> -->
               
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import SocialNetworkConfig from '@/components/SuiteBuilder/Main/SocialNetworkConfig'	
export default {
    props: ['System','SystemEntities','Page'],
    components: {NewLandingPage,LandingPageSingle,SocialNetworkConfig},
    data() {
        return {
          PreviewingMode: false,
            Refreshing: false,
            ActiveTemplate: '',
            ActiveTemplatechild: '',
            SuiteTemplate: '',
        }
    },	
    computed:{
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ActiveSuiteWebsitePages(){
            return this.$store.state.ActiveSuiteWebsitePages
        },
        ActiveSuiteSocialNetworkPages(){
            return this.$store.state.ActiveSuiteSocialNetworkPages
        },
      PrimarySocialNetworkTemplate(){
            //could do default page later right?
            //console.log('SuiteSocialNetwork',this.SuiteSocialNetwork,this.ActiveSuiteSocialNetworkPages)
            return this.SuiteSocialNetwork && this.SuiteSocialNetwork.Social_Landing_Page ? 
            this.ActiveSuiteSocialNetworkPages.find(obj => obj.Name === this.SuiteSocialNetwork.Social_Landing_Page.split('/').join('').split('-').join(' ').split('_').join(' ')) : 
            this.SuiteSocialNetwork ? this.ActiveSuiteSocialNetworkPages[0] : ''
        },
      RoutedPages(){
            return this.ComputedTemplateChildren.map(page => {
                return '/'+page.Name.split(' ').join('-').split('_').join('-')
            })
        },
      ComputedTemplateChildren(){
            return this.SuiteSocialNetwork && this.ActiveSuiteSocialNetworkPages ? this.ActiveSuiteSocialNetworkPages :
            []
        },
      SuiteTemplates(){
      return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
      },
      OtherSuiteTemplates(){
      return this.$store.state.OtherSuiteTemplates
      },
      MySuiteTemplates(){
      return this.$store.state.MySuiteTemplates
      },
      ClientSuiteTemplates(){
      return this.$store.state.ClientSuiteTemplates
      },
      UserCanEdit(){
            return this.userLoggedIn && this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Ownerid === this.userLoggedIn.id
            //for support must add  || this.userIsAdmin once past testing
        },
      PreviewMode(){
            return !this.UserCanEdit || this.UserCanEdit && this.PreviewingMode
        },
      
      TemplatePreviewStyle(){
            //social network done propertly from app routing
            return this.PreviewingMode ? 'margin-top: 212px;' : ''
        },
        ActiveSuiteTemplate () {
        return this.$store.state.ActiveSuiteTemplate
        },
        SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['PerformSitePageAddition','RoutetoTemplatePage'] 
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.ActiveSuiteTemplate && this.$route.params.id === this.ActiveSuiteTemplate.id){
            this.ActivateActiveTemplateComponent(this.ActiveSuiteTemplate)
            if(this.Page){
                //again more tricky gonna dpend on "webb or SNB etc could proporgate but too damn lazy for now"
                this.RoutetoTemplatePage(this.Page,true)
            }
        }
        else{
          this.GetSuiteTemplate()
        }
    },
    methods:{
      CancelTemplateView(){
            this.ActiveTemplate = ''
            this.ActiveTemplatechild = ''
            this.TransfertoRA = false
        },
      RoutetoAll(){
            this.$router.push('/MyMarketplaceTemplates')
            this.CancelTemplateView()
            this.$store.commit('setActiveTemplateSite','')
        },
      ActivateTemplateChild(child){
            console.log(child)
            this.ActiveTemplatechild = child
            //this.TogglePreviewingMode()
            //okay so if Guest, then activate the Builderappbarview...
        },
      CheckDefaultRoute(){
            //console.log('all the way to this CheckDefaultRoute',this.SuiteSocialNetwork,this.ActiveSuiteTemplate)
            let defpageprop = ''
                let defpage = ''
                let defpageroute = 'Social_Landing_Page'
                let pagechild = ''
                //console.log(defpageprop,this.ActiveSuiteTemplate,this.ActiveSuiteTemplate.TemplateProps[defpageprop])
                if(this.PrimarySocialNetworkTemplate){
                  defpageroute = this.SuiteSocialNetwork.Social_Landing_Page.split('/').join('').split('-').join(' ').split('_').join(' ')
                  pagechild = this.PrimarySocialNetworkTemplate
                  if(pagechild){
                        defpage = pagechild
                    }
                }
                if(defpage){
                    this.ActivateTemplateChild(pagechild)
                    this.ActiveTemplatechild = pagechild
                }
                else if(this.ComputedTemplateChildren[0]){
                    this.ActivateTemplateChild(this.ComputedTemplateChildren[0])
                }
                //console.log('this.ActiveTemplatechild',this.ActiveTemplatechild,this.ComputedTemplateChildren)
        },
      TogglePreviewingMode(fromload){
            this.PreviewingMode = !this.PreviewingMode
           // console.log('this.PreviewingMode ',this.PreviewingMode )
            if(this.PreviewingMode){
                this.$store.commit('setBuilderView','')
                this.$store.commit('setSiteDialog',false)
                //this.TogglePublicNavbarEditing()
                //okay here is another thing. not 1. Check if type, like if website template check ActiveSuiteTemplate.TemplateProps.Public_Landing_Page. Get matching form computed children
                //2. If found use that, otherwise child[0] will do
                if(!fromload){
                this.CheckDefaultRoute()
                }
                //still need to set others, think group has def root, feat mem not, because it's "route.id" AND "slug"...
            }
            else{
                this.SetBuilderView()
                //this.TogglePublicNavbarEditing()               
                //this.DeactivateTemplateChild()
            }
        },
      SetBuilderView(){
            if(this.UserCanEdit){
                //for support must add  || this.userIsAdmin once past testing
                this.$store.commit('setBuilderView',{Name: 'Marketplace Site'})
            }                
        },
      RoutetoTemplatePage(page,fromload){
           //console.log(page)
            let child = this.ComputedTemplateChildren.find(obj => obj.id === page.id)
            if(!child){
                child = this.ComputedTemplateChildren.find(obj => obj.Name === page.title)
            }
            //console.log(this.ComputedTemplateChildren,child)
            this.ActiveTemplatechild = ''
            setTimeout(() => {
            this.ActiveTemplatechild = child
            //console.log('so here is Page ',this.Page)
            //console.log('this.Page.id !== child.id?',this.Page.id !== child.id)
            if(fromload){
                this.TogglePreviewingMode(fromload)
            }
            else if(this.Page && this.Page.id !== child.id){
                //we assuming you route to the page in preview mode so yeah
                //stupid right? No change that's all?
            }
            }, 20);
            
            //this.ActiveTemplatechild = child
            //console.log(this.ActiveTemplatechild,this.UserCanEdit,this.PreviewingMode,this.PreviewMode,page,this.ComputedTemplateChildren)
        },
      GetSuiteTemplate(){
            this.SuiteTemplate = this.SuiteTemplates.find(obj => obj.id === this.$route.params.id)
            console.log('this.SuiteTemplate',this.SuiteTemplate,'this.ActiveSuiteTemplate',this.ActiveSuiteTemplate)
            if(this.ActiveSuiteTemplate){
                //console.log('this.ActiveSuiteTemplate',this.ActiveSuiteTemplate)
                //alert('thought')
                this.GetMarketplacetemplate()
                this.CheckDefaultRoute()
            }
        },
      ActivateActiveTemplateComponent(temp,toRA){
        this.ActiveTemplate = temp
        this.TransfertoRA = toRA
       this.$store.commit('setBuilderView','')
      },
      GetMarketplacetemplate(){
            let template = ''
            console.log('this.ActiveSuiteTemplate,',this.ActiveSuiteTemplate)
            if(this.SuiteTemplateView){
            let cat = this.SuiteTemplateWebsite ? 'Website' : this.SuiteTemplateSocialNetwork ? 'SocialNetwork' : this.SuiteTemplateDatabase ? 'Suite App' : ''  
            console.log('cat',cat,this.ActiveSuiteTemplate[cat])
            template = this.ActiveSuiteTemplate[cat]
            }
            else{
                template = this.MarketplaceTemplates.find(obj => obj.id === this.$route.params.id)
            }
            if(!template){
                 if(!this.userLoggedIn){
                this.$router.push('/404')  
                }
                else{
                    //Just to emphasize here is the problem. If dude don't have access sure send him 4040, but maybe he has access it just needs to be called still, as we only checking from entire array.
                    //Problem is this ain't the only route calling it. need to do from ApppluginsRoutes
                //this.$router.push('/404')
                } 
            }
            else{
                
                //undid if(template.Ownerid !== this.userLoggedIn.id && !this.userIsAdmin){ as admin also get to view preview mode first right
                if(template.Ownerid !== this.userLoggedIn.id){
                    //this.$router.push('/PermissionError')
                    this.ActiveTemplate = template
                    //I know this is not right but give me a second
                    this.PreviewingMode = true
                    this.CheckDefaultRoute()  
                }
                else{
                    //is owner
                 this.ActiveTemplate = template   
                 if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                    this.SetBuilderView()
                    this.$store.commit('setActiveTemplateSite',template)    
                    this.CheckDefaultRoute()                
                }
                }
                
            }
        }
    }
}
</script>

<style>

</style>



