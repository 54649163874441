    <template>
  <nav>    
    <v-dialog max-width="400" v-model="NewTaskDialog">
                        <v-card>
                          <v-card-title class="recordtoolbar white--text">
                            <v-icon dark>mdi-clipboard-list</v-icon>Create Activity
                           
                          </v-card-title>
                          <v-card-title v-if="NewTask.Regarding_Type">
                             <v-chip>
                              {{NewTask.Regarding_Record[NewTask.Regarding_Record.identifier]}}
                            </v-chip>
                          </v-card-title>
                          <v-card-text>
                            <v-text-field label="Subject" v-model="NewTask.Subject" />
                             <v-card-title class="justify-start">
                                Description: 
                              </v-card-title>
                              <v-card-subtitle class="caption">
                                Write the value using the editor below, and remember to click on the "save" to capture the value. 
                              </v-card-subtitle>
                              <v-card-text>
                                <v-layout row class="justify-center" >
                              <v-col cols="12" >
                            <ContentEditableField style="padding:15px;"
                            :FieldObject="NewTask" :FieldName="'Description'"
                            :FieldValue="NewTask.Description" @UpdateContentEditableField="UpdateTaskDescription" :AdditionalSaveMethod="''" />
                              </v-col>
                                </v-layout>
                              </v-card-text>
                            <!-- <v-textarea label="Description" v-model="NewTask.Description" /> -->
                           <v-menu
                                        v-model="NewTaskDueDatemenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                       <template v-slot:activator="{ on }">
                                         <v-text-field
                                            v-model="NewTask.Due_Date"
                                            label="Due Date"
                                            prepend-icon="mdi-calendar-month"
                                            readonly
                                            v-on="on"
                                          ></v-text-field>
                                       </template>
                                       <v-date-picker :allowed-dates="disablePastDates" v-model="NewTask.Due_Date" @input="NewTaskDueDatemenu = false"></v-date-picker>
                                     </v-menu>
                            <v-switch label="Set Reminder" v-model="NewTask.Has_Reminder" />
                            <v-menu
                                        v-model="NewTaskSnoozed_UntilDatemenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                       <template v-slot:activator="{ on }">
                                         <v-text-field v-if="NewTask.Has_Reminder"
                                            v-model="NewTask.Snoozed_Until"
                                            label="Reminder Date"
                                            prepend-icon="mdi-calendar-month"
                                            readonly
                                            v-on="on"
                                          ></v-text-field>
                                       </template>
                                       <v-date-picker :allowed-dates="disablePastDates" :max="NewTask.Due_Date" v-model="NewTask.Snoozed_Until" @input="NewTaskSnoozed_UntilDatemenu = false"></v-date-picker>
                                     </v-menu>
                            <v-text-field label="QuickLink (Optional)" :rules="[$store.state.formrules.url]" v-model="NewTask.QuickLink" />
                            
                            <v-select item-text="Name" label="Type" v-model="NewTask.Type" :items="ActivityTypes" return-object />
                            <!-- <v-select label="Owner" item-text="Full_Name" v-model="NewTask.Owner" :items="UsersStore" return-object /> -->
                             <UserLookup :UsersLookupArray="UsersStore"  :ModelProp="'Owner'" :RecordObj="NewTask" @UpdateUserLookupProp="UpdateUserLookupProp"
                                      :FieldLabel="'Owner'" :Rules="[$store.state.formrules.required]"
                                      />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="CloseTaskDialog()">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="CreateNewTask()">Save</v-btn>
                          </v-card-actions>
                        </v-card>
                  </v-dialog>
    <v-dialog v-model="StopWatchDialog"  max-width="400px">
      <Stopwatch :System="System" :SystemEntities="SystemEntities" @CancelStopWatch="CancelStopWatch" :UserRecord="UserRecord" :PushedRelatedObj="TimesheetRelated" />
    </v-dialog>
  <v-dialog v-model="InviteUserDialog" max-width="500">
      <v-card>
        <v-card-title class="recordtoolbar white--text">
          Invite User
        </v-card-title>
        <v-card-text>
          <v-switch v-model="IsGuestUser" :readonly="!userIsAdmin" label="Guest User" />
          <v-text-field v-model="InvitingUserEmail" label="Email" :rules="[$store.state.formrules.required,$store.state.formrules.email]" />
          <v-select v-model="InviteUserBU" :items="BusinessUnitsArray" :readonly="!userIsAdmin" label="Business Unit" :rules="[$store.state.formrules.required]" item-text="Name" return-object/>
          <v-select v-model="InvitingUserRoles" :items="AllSecurityRoles" label="Roles" item-text="Name" multiple/>
           <v-select v-if="!IsGuestUser && userIsAdmin" v-model="PrimaryApp" :items="$store.state.AppsDataBase" label="Primary App" item-text="id"/>
          <v-select multiple label="TableFilters" :items="SystemTableFilters" return-object dense item-text="Name" clearable
          v-model="InviteUserTableFilters"></v-select>
          <!-- <div contenteditable id="previewsendemail" class="postinputbox" v-html="PresetEmailBody"/> -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelInvitingUserEmail()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="SendInvitetoUser()">Invite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
      <PrimaryNavigation v-if="PrimaryNavigationActive" :style="PrimaryNavstyle" :BottomNavigationItems="PrimaryNavigationItems" :ThemeColor="BottomNavThemeColor" :ThemeBGColor="BottomNavClass"
    :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView" :DBNavbar="DBNavbar" :UserRecord="UserRecord"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :ReadNotifications="ReadNotifications"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB" :UnreadNotifications="UnreadNotifications"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements" :UserPicture="UserPicture"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters" :BottomMenu="true" :TopMenu="false"
    />
    <SecondaryNavigation v-if="SecondaryNavigationActive" :style="SecondaryNavstyle" :BottomNavigationItems="SecondaryNavigationItems" :ThemeColor="BottomNavThemeColor" :ThemeBGColor="BottomNavClass"
    :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView" :DBNavbar="false" :UserRecord="UserRecord"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :ReadNotifications="ReadNotifications"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB" :UnreadNotifications="UnreadNotifications"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements" :UserPicture="UserPicture" :BottomMenu="false" :TopMenu="true"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
    
    <v-navigation-drawer :class="UserDBNavList? 'dbnavbar' : 'dbnavbar'" :dark="NavDark" temporary app v-model="drawer" :style="RenderNavStyling">
      <v-layout column align-center>
      <v-btn v-if="CanToggleSocialModule" @click="IntranetViewToggle" text>
          <v-icon dark>
              mdi-repeat
            </v-icon>Switch to {{System.Social_Module_Name}}
        </v-btn>
      <div class="cloudbg" :style="NavbarStyling"/>
      <!-- <v-flex class="mt-5 hidden-sm-and-down"> -->
        <v-flex>
          <v-avatar
            class="profile"
            size="220"
            tile
          >
            <v-img contain src="@/assets/logo.png"></v-img>
          </v-avatar>
          <!-- <p class="white--text subheading mt-1">RapidApps</p> -->
        </v-flex>
      </v-layout>
      <v-list v-if="!UserDBNavList">        
        <v-divider></v-divider>
        <div v-for="link in Computeditems" :key="link.title">
          <v-list-item active-class="dbnavlist--text" v-if="!link.Children || link.Children.length<0" :to="link.to" :target="link.target">
          <v-list-item-action>
          <v-icon large class="actionicon" color="accent">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="navoverline" :to="link.to" :target="link.target" >{{ link.title}}
            </v-list-item-title>
            </v-list-item-content>
             <v-list-item-action v-if="link.Page">
              <v-btn small fab tile class="primary"><v-icon class="secondary--text">mdi-sitemap
           </v-icon></v-btn>
           </v-list-item-action>
          </v-list-item>
            <v-list-group active-class="dbnavlist--text"
              v-if="link.Children && link.Children.length"
              >
                <template v-slot:activator>
                  <v-list-item-action>
                  <v-icon large class="actionicon" color="accent">{{ link.icon }}</v-icon>
                </v-list-item-action>
                  <v-list-item-title class="navoverline">{{link.title}}</v-list-item-title>
                </template>
                <div v-for="subsub in link.Children"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title !== 'Dashboard Builder'"
                         active-class="dbnavlist--text"
                        :to="subsub.to" :target="subsub.target"
                    >          
                    <v-list-item-action>
                     <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="mx-3" v-if="!subsub.Children && subsub.title === 'Dashboard Builder' "
                        
                        @click="OpenDashboardBuilder()"
                    >          
                    <v-list-item-action>
                    <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-group
                    sub-group active-class="dbnavlist--text"
                      v-if="subsub.Children && subsub.Children.length"
                      >
                        <template v-slot:activator>
                          <!-- <v-list-item-action>
                         <v-icon class="actionicon" color="accent">{{ subsub.icon }}</v-icon>
                        </v-list-item-action> -->
                          <v-list-item-title class="navoverline">{{subsub.title}}</v-list-item-title>
                        </template>
                        <v-list-item class="mx-3" v-for="subchild in subsub.Children" :key="subchild.itemObjKey"
                          :to="subchild.to" :target="subchild.target"
                      >          
                      <v-list-item-action>
                      <v-icon class="actionicon" color="accent">{{ subchild.icon }}</v-icon>
                          
                        </v-list-item-action>                          
                          <v-list-item-title class="navoverline">{{ subchild.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                </div>
              </v-list-group>
          </div>
      </v-list>
      <v-list v-if="UserDBNavList"> 
      <div v-for="item in Computeditems" :key="item.ObjKey">
            <v-divider>
             </v-divider>
              <span class="mediumoverline mx-3 guestnavlist--text" style="font-size:16px;font-family: , sans-serif">{{item.title}}</span>
              <v-list
            dense            
            class="transparent" flat :dark="AppisDarkMode">              
               <v-list-item v-for="child in item.Children"
                    :key="child.itemObjKey" :to="child.to" :target="child.target"
                    >
                    <v-list-item-avatar>
                        <v-icon class="soloactionicon guestnavlist--text">{{child.icon}}</v-icon>
                    </v-list-item-avatar>
                     <v-list-item-action>
                        <span  class="body guestnavlist--text" style="font-size:14px;">{{child.title}}</span>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-badge 
                        
                        :color="child.NotifyColor"
                          v-if="item.ID === 1004"
                      > <span slot="badge"> {{child.NotificationCount}}</span>
                      
                      </v-badge>
                    </v-list-item-action>
                   
                    </v-list-item>                      
            </v-list>
             
          </div>    
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
        <!-- removed :RelatedObj="RelatedObj" -->
        <SystemTicketsLogging :db="CurrentDB" :SystemEntities="SystemEntities" @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray"  :View="'Single'" />
      </v-card>
    </v-dialog>

  </nav>
</template>

<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
import ContentEditableField from '@/components/Database/ContentEditableField'
import Stopwatch from '@/components/SuitePlugins/Timesheets/Stopwatch'
import UserLookup from '@/components/General/UserLookup'
import PrimaryNavigation from '@/components/Navigation/BottomNavigation'
import SecondaryNavigation from '@/components/Navigation/BottomNavigation'
export default {
  props: ['UserDBNavList','AppisDarkMode','CurrentRecordName','Notifications','TimesheetRelated','ActivityRegarding','SitePages',
  'RADB','SystemEntities','System','AppStylingView','SystemPhotoLibraries','AppQuickStartDialog','ActivityStatusField','SystemTableFilters','TimesheetDialogActive'],
  components: {
  BreadCrumbs,
  SystemTicketsLogging,
  ContentEditableField,
  Stopwatch,
  UserLookup,
  PrimaryNavigation,
  SecondaryNavigation
  },
  data() {
    return {
      SystemDashboardTabs: [
        {
            DisplayName: 'Dashboard',
            Icon: 'mdi-view-dashboard',
            SystemDashboard: true,
            Elements: [
             
          {
            Icon: 'mdi-alert',
            DisplayName: 'Alerts',
            Name: 'Alerts',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Alerts',           
            Elements: [],
            Children: [
              {
            Icon: 'mdi-database',
            DisplayName: 'Database Module',
            Name: 'Database Module',
            SystemDashboard: true,
            DashboardType: 'Database Module'
          },
          {
            Icon: 'mdi-share-variant',
            DisplayName: 'Social Module',
            Name: 'Social Module',
            SystemDashboard: true,
            DashboardType: 'Social Module'
          },
          {
            Icon: 'mdi-search-web',
            DisplayName: 'Website Module',
            Name: 'Website Module',
            SystemDashboard: true,
            DashboardType: 'Website Module'
          },
           
            ]
          },
          {
            Icon: 'mdi-chart-box',
            DisplayName: 'Statistics',
            Name: 'Statistics',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Statistics',
             Elements: []
          },
            ]
        }
        
      ],
      Refreshing: false,
      AppModes: [
        {ID: 1000001, Name: 'Bird View',icon: 'mdi-bird'},
        {ID: 1000002, Name: 'Team',icon: 'mdi-account-group'},
        {ID: 1000003, Name: 'Admin',icon: 'mdi-file-cabinet'},
        {ID: 1000004, Name: 'Community',icon: 'mdi-home-group'},
        {ID: 1000005, Name: 'Super Admin',icon: 'mdi-shield-key'}
      ],
      StopWatchDialog: false,
      ActivityTypes: [
        {
          ID: 1000001,
          Name: 'Task'
        },
        {
          ID: 1000002,
          Name: 'Appointment'
        },
      ],
      NewTaskDialog: false,
      NewTaskSnoozed_UntilDatemenu: false,
      NewTaskDueDatemenu: false,
      NewTask: {
        Subject: '',
        Description: '',
        Type: '',
        Has_Reminder: true,
        Owner: '',
        Due_Date: '',
      },
      DefaultNewTask: {
        Subject: '',
        Description: '',
        Type: '',
        Has_Reminder: true,
        Owner: '',
        Due_Date: '',
      },
      CurrentDB: '',
      InviteUserisAdmin: false,
      rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },

      InvitedUsersArray: [],
      IsGuestUser: true,
      Modules : [
      {
        Name: 'RapidApps',
        URL: 'https://rapidapps-7d6e7.web.app'
      },
      {
        Name: 'RapidApps',
        URL: 'https://rapidapps-7d6e7.web.app'
      },
      ],
      ModulePath: {
        Name: 'RapidApps',
        URL: 'https://rapidapps-7d6e7.web.app'
      },
      InviteUserBU: '',
      PrimaryApp: '',
      GuestBusinessUnitArray : [],
      InviteUserDialog: false,
      InvitingUserEmail: '',
      InvitingUserRoles: [],
      UserDashboards: [],
      RecordName: '',
      TicketLog: false,
      UsersArray: [],
      drawer: false,
      items: [],
      UserFullName: '',
      UserPicture: '',
      UserAssignedRoles: {},
      hover: false,
      
      ViewingNotificaitons: false,
      UserRecord: {},
      InviteUserTableFilters: [],
      BUsecurityroles: [],
      Customsecurityroles: [],
      RMsecurityroles: [],
      NavRoutes: [
        {Name: 'DataSingle',Topic: '',Type: 'Single',Single: 'Data Record',WIPandStats: true,HasList: true,HasCreate: true,DropdownCreate: true,HasEdit: true,
        },        
        {Name: 'ClassifiedWiki',Topic: 'Data Classified',Type: 'Single',Single: 'Listing',HasBack: true,HasList: true,DropdownList: true,},
        {Name: 'DataTable',Topic: 'Data Table',Type: 'Collection',Single: 'Table',WIPandStats: true,HasCreate: true,DropdownCreate: false,CreateMethod : 'ActivateRelevantdialog',ActionList: true,ActionListIcon: 'mdi-clipboard-list'},
         {Name: 'MyAccount',Topic: 'MyAccount',Type: 'System'},
      ],
      // DashboardComponents: [            
      //     { title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
      //     { title: 'Events',Pluginid: 'Site_Events', icon: 'mdi-calendar-star',ComponentName: 'MyEvents',sitecollectionname: 'siteevents',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupevents',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
      //     { title: 'Blogs',Pluginid: 'Site_Blogs', icon: 'mdi-newspaper',ComponentName: 'MyBlogs',sitecollectionname: 'siteblogs',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupblogs',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
      //     { title: 'Articles',Pluginid: 'Site_Articles', icon: 'mdi-newspaper',ComponentName: 'MyArticles',sitecollectionname: 'sitearticles',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouparticles',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
      //     { title: 'Classifieds',Pluginid: 'Classifieds', icon: 'mdi-monitor-star',ComponentName: 'MyClassifieds',sitecollectionname: 'classifieds',sitecolfield: 'Created_Byid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupclassifieds',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
      //     { title: 'Forums',Pluginid: 'Site_Forums', icon: 'mdi-forum',ComponentName: 'MyForumThreads',sitecollectionname: 'siteforumdiscussions',sitecolfield: 'Creatorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupforumdiscussions',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
      //     { title: 'Polls',Pluginid: 'Site_Polls', icon: 'mdi-vote',ComponentName: 'MyPolls',sitecollectionname: 'sitepolls',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouppolls',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
      //     { title: 'Notices',Pluginid: 'Digital_NoticeBoard', icon: 'mdi-vote',ComponentName: 'MyNoticeBoardPosts',sitecollectionname: 'notices',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupnotices',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
      //     ],
      EntityIndex0Items: [
         
          {
            title: 'Automations',
            icon: 'mdi-auto-fix',
            Method: 'ToggleAutomationDialog'
          },
          {
            title: 'Receive Goods',
            icon: 'mdi-warehouse',
            Method: 'NoPropEmit',
            Prop: 'ActivateReceivingDialog'
          },
          {
            title: 'Assign Process',
            icon: 'mdi-swap-vertical-variant',
            Method: 'NoPropEmit',
            Prop: 'AssignProcess'
          },
          

        ],
      EntityIndex2Items: [
          
          
          {
            title: 'Dashboard',
            icon: 'mdi-monitor-dashboard',
            Method: 'NoPropEmit',
            Prop: 'ToggleHelp'
          },
           {
            title: 'Movement History',
            icon: 'mdi-timeline-clock-outline',
            Method: 'NoPropEmit',
            Prop: 'ActivateWarehouseMovementDialog'
            
          },
          {
            title: 'Export to PDF',
            icon: 'mdi-file-pdf-box',
            Method: 'NoPropEmit',
            Prop: 'ToggleExportingtoPDFDialog'
          },
          {
            title: 'Help',
            icon: 'mdi-help',
            Method: 'NoPropEmit',
            Prop: 'ToggleHelp'
          },

        ],
      EntityIndex1Items: [
          //systemtickets
          //systemconfig
           {
            title: 'Quick Links',
            icon: 'mdi-arrow-top-right-thin-circle-outline',
          },
          {
            title: 'Manage',
            icon: 'mdi-controller-classic',
            Children: [
               {
                title: 'Assign Process',
                icon: 'mdi-swap-vertical-variant',
                Method: 'NoPropEmit',
                Prop: 'AssignProcess'
              },
               {
                title: 'Automations',
                icon: 'mdi-auto-fix',
                Method: 'ToggleAutomationDialog'
              },
              {
                title: 'Receive Goods',
                icon: 'mdi-warehouse',
                Method: 'NoPropEmit',
                Prop: 'ActivateReceivingDialog'
              },
              {
                title: 'Edit Ownership',
                icon: 'mdi-tag-faces',
                Method: 'NoPropEmit',
                Prop: 'EditOwnership'
              },
              {
                title: 'StatusToggleName',
                icon: 'mdi-power',
                Method: 'NoPropEmit',
                Prop: 'ToggleChangeStatusDialog'
              },
              
            ]
          },
           {
            title: 'Publish',
            icon: 'mdi-publish',
            Children: [
               {
                title: 'Preview Classified',
                icon: 'mdi-certificate-outline',
                IsRoute: true,
              },
              {
                title: 'Preview Wiki',
                icon: 'mdi-language-html5',
                IsRoute: true,
              },
            ]
           },

        ],
      EntityIndex3Items: [
          //entitybuilder 
          
          {
            title: 'Edit Form',
            icon: 'mdi-format-line-style',
            Method: 'NoPropEmit',
            Prop: 'EditSingleForm'
          },
          {
            title: 'Manage Automations',
            icon: 'mdi-auto-fix',
            Method: 'ActivatePipelineworkflowdialog'
          },
          {
            title: 'Manage Pipelines',
            icon: 'mdi-pipe',
            IsRoute: true
          },
          {
            title: 'Manage Table State',
            icon: 'mdi-power-settings',
            Method: 'NoPropEmit',
            Prop: 'EditTableState'
          },
          {
            title: 'Portal Item',
            icon: 'mdi-comment-outline',
            Method: 'ActivateLiaisonTopicDialog'
          },
          
          

        ],
    }
  },
  computed: {
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        AllAppPlugins(){
        return this.AppsDataBase.map(app => {
            let appobj = Object.assign({},app)
            return appobj.Plugins
        }).flat()
        },
        SuitehasWarehouse(){
        return this.AllAppPlugins.find(obj => obj.id === 'Warehouse_&_Products')
        },
    SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
    PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
    WarehousesArray(){
            return this.$store.state.WarehousesArray
        },
    ActiveRoute(){
      let routeobj = {}
      if(this.$route.meta && this.$route.meta.DataSingleBuilder && this.CurrentEntity){
        routeobj = Object.assign({},this.NavRoutes.find(obj => obj.Name === 'DataSingle'))
        routeobj.Topic = this.CurrentEntity.DisplayName
        routeobj.ListPath = '/'+routeobj.Topic.split(' ').join('')
        routeobj.ListTitle = routeobj.Topic.split(' ')[routeobj.Topic.split(' ').length-1]
      }
      else if(this.$route.meta && this.$route.meta.TableBuilder && this.CurrentEntity){
        routeobj = Object.assign({},this.NavRoutes.find(obj => obj.Name === 'DataTable'))
      }
      else if(this.$route.meta && this.$route.meta.ClassifiedListingBuilder && this.CurrentEntity){
        routeobj = Object.assign({},this.NavRoutes.find(obj => obj.Name === 'ClassifiedWiki'))
        routeobj.Topic = this.CurrentEntity.DisplayName
        routeobj.BackTitle = 'Back'
        routeobj.BackIcon = 'mdi-chevron-left'
        routeobj.BackPath = '/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+this.$route.params.id
        routeobj.ListChildren = [
              {
                title: 'All '+routeobj.Topic,
                icon: 'mdi-view-list',
                ItemPass: true,
                IsRoute: true,                    
                Path: '/'+this.CurrentEntity.DisplayName.split(' ').join('')
              },
              {
                title: 'Edit',
                icon: 'mdi-pencil',
                ItemPass: true,
                Method: 'AssignSocialItemInteractMethod',
                Prop: 'CancelEditing',
              },
              {
                title: 'Publish',
                icon: 'mdi-publish',
                ItemPass: true,
                Method: 'AssignSocialItemInteractMethod',
                Prop: 'PublishClassifiedRecord',
              },
          //edit
          //full list
        ]
        //DBNavbarItems
      }
      
      else if(this.NavRoutes.find(obj => obj.Name === this.$route.name)){
        routeobj = Object.assign({},this.NavRoutes.find(obj => obj.Name === this.$route.name))
      }
      return routeobj      
    },
    RouteID(){
      return this.$route.params.id
    },
    RelatedObj(){
      if(this.ActiveRoute && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity){
        return {id: this.RouteID, [this.CurrentEntity.Primary_Field_Name]: this.CurrentRecordName,identifier: this.CurrentEntity.Primary_Field_Name,collection: this.$route.meta.Collection,Link: this.CurrentEntity.SingleName.split(' ').join('') }
      }
      else{
        return ''
      }
    },
    CanModerateWiki(){
      return this.CurrentEntity && this.CurrentEntity.Has_Wiki && this.UserisWikiModerator || this.CurrentEntity && this.CurrentEntity.Has_Wiki && this.userIsAdmin
    },
    CanModerateClassified(){
      return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.UserisClassifiedModerator
    },
    UserisClassifiedModerator(){
      return this.userIsAdmin || this.CurrentEntity && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === this.CurrentEntity.id.split('_').join(' ')+' Classified Listings')
    },
    UserisWikiModerator(){
      return this.userIsAdmin || this.CurrentEntity && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Wiki')
    },
    ActiveAppMode(){
      return this.$store.state.AppMode.Name
    },
    ActiveAppModeIcon(){
      return this.$store.state.AppMode.icon
    },
    ComputedAppModes(){
      let arr1 = [{
          title: 'All '+this.ActiveRoute.ListTitle,
          icon: 'mdi-view-list',
          ItemPass: this.ActiveRoute.HasList && !this.ActiveRoute.DropdownList,
          IsRoute: true,                    
          Path: this.ActiveRoute.ListPath
        }]
      let arr2 = this.AppModes.map(mode => {
        let modeobj = {
          title: mode.Name,
          icon: mode.icon,
          Method: 'ToggleAppMode',
          Prop: mode,
          ItemPass: true,
        }
        return modeobj
      })
      return arr1.concat(arr2).filter(mode => {
                if(mode.title === 'Super Admin'){
                    return this.userIsAdmin
                }
                else{
                    return mode
                }
            })
    },
    DBNavbar(){
      //return !this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm || this.$route.meta && this.$route.meta.DataSingleBuilder
      return true
    },
    BottomNavClass(){
      if(this.IntranetView){
        return 'socialappnavbar'
      }
      else{
        return 'dbappbar'
      }
    },
    BottomNavThemeColor(){
      if(this.IntranetView){
        return 'socialmenufont'
      }
      else{
        return 'dbnavlist'
      }
    },
    PrimaryNavstyle(){
      //return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'position: fixed;bottom: 50px;z-index: 5;' : 'position: fixed;top: 165px;z-index: 5;'
      return 'position: fixed;top: 0px;z-index: 5;'
    },
    SecondaryNavstyle(){
      //return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'position: fixed;bottom: 50px;z-index: 5;' : 'position: fixed;top: 165px;z-index: 5;'
      return 'position: fixed;bottom: 50px;z-index: 5;'
    },
    BottomNavstyle(){
      if(this.IntranetView){
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'position: fixed;bottom: 50px;z-index: 9;' : 'position: fixed;top: 165px;z-index: 9;'
      }
      // else if(this.$route.meta && this.$route.meta.DataSingleBuilder){
      //   return 'position: fixed;top: 0px;z-index: 9;'
      // }
      // else{
      //   return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? 'position: fixed;bottom: 50px;z-index: 9;' : 'position: fixed;top: 0px;z-index: 9;'
      // }
      else{
        return 'position: fixed;top: 0px;z-index: 9;'
      }
    },
    DBNavbarItems(){
      let array = []
      if(this.System.DBNavbarItems){
        array = JSON.parse(JSON.stringify(this.System.DBNavbarItems))
            return array.map(item => {
              item.Children = item.Children.filter(child => {
                //console.log(child,vm.$store.state[child.QueryName])
                return this.$store.state[child.QueryName]
              }).map(child => {
                let entity = this.SystemEntities.find(obj => obj.id === child.title.split(' ').join('_'))
                //console.log('entity',entity,child.title.split(' ').join('_'),this.SystemEntities)
                if(entity){
                  child.icon = entity.Table_Icon     
                  child.EntityRecord = entity    
                  child.Path = '/'+entity.id.split('_').join('')         
                }
                
                return child
              })
              return item
            })
          }
       return array
    },
    AppBarActive(){
      //return this.BottomNavigationActive && !this.$route.meta || this.BottomNavigationActive && this.$route.meta && !this.$route.meta.DataSingleBuilder
       return false
    },
    PrimaryNavigationActive(){
      return this.userLoggedIn 
    },
    SecondaryNavigationActive(){
      return this.userLoggedIn && !this.Refreshing && this.$route.name !== 'MyDashboard' && this.$route.name !== 'GroupDashboard'
    },
    PrimaryNavigationItems(){
      return this.BottomNavigationItems
    },
    SecondaryNavigationItems(){
      if(this.ActiveRoute.Name === 'DataSingle' && !this.$route.meta.RecordisSystem){
        return this.DataSingleActions
      }
      else if(this.ActiveRoute.Name === 'DataSingle' && this.$route.meta.RecordisSystem){
        return this.SystemConfigTabs
      }
      else{
        return this.UserActions
      }
      
    },
    BottomNavigationItems(){
            return this.DBBottomNavigationItems
        },
    
    CurrentItemModerator(){
      return this.$store.state.CurrentItemModerator
    },
    CurrentStoreItemPublisher(){
      return this.$store.state.StoreItemPublisher
    },
    CurrentEntity(){
      return this.$store.state.CurrentEntity
    },
    DashboardComponents(){
      return []
    },
    ActiveRouteEntityDashboards(){
      let items = [
        {
          icon: 'mdi-plus',
          title: 'New Dashboard',
          Method: 'OpenDashboardBuilder',
          Prop: ''
        }
      ]
      return items.concat(this.UserDashboards.filter(dashboard => {return dashboard.DashboardEntity === this.CurrentEntity.id}).map(dashboard => {
              let dashboardobj = {
                icon: 'mdi-desktop-mac-dashboard',
                title: dashboard.DashboardName,
                IsRoute: true,
                Path: '/CustomDashboard/'+dashboard.id,
              }
              return dashboardobj
            }))
    },
    EntityStoreArticle(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
      },
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
    EntityStoreActive(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.EntityisAppStore
      },
    CurrentEntityApp(){
            return this.CurrentEntity ? this.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid) : ''
    },
    MovementHistoryActive(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.$store.state.CanViewMovementHistory
      },
      CanReceiveGoods(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.CanEdit && this.BypassInboundWIP
      },
    BypassInboundWIP(){
        let appplugins = []
        if(this.CurrentEntityApp){
          appplugins = this.CurrentEntityApp.Plugins.map(plug => {return plug.id})
        }
        return this.CurrentEntityApp && !appplugins.includes('Warehouse_Inbound')
      },
    PublishItems(){
      if(this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity){
        let items = [
        {
                title: 'Preview Classified',
                icon: 'mdi-certificate-outline',
                ItemPass: this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.CanModerateClassified,
                IsRoute: true,
                Path: '/ClassifiedPreview/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+this.$route.params.id
              },
              {
                title: 'Preview Wiki',
                icon: 'mdi-language-html5',
                IsRoute: true,
                Path: '/WikiPreview/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+this.$route.params.id,
                ItemPass: this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && this.CurrentEntity.Has_Wiki && this.CanModerateWiki,
              },
              
      ]
        return items.filter(item => {
          return item.ItemPass
        })
      }
      else{
        return []
      }
      
    },
    CurrentEntitySelectedTabs(){
      return this.$store.state.SelectedDataTabs
    },
    CurrentEntityTabs(){
      //console.log('CurrentEntityTabs',this.$store.state.CurrentDataTabs)
      return this.$store.state.CurrentDataTabs
    },
    SingleRecordNavTabs(){
      return this.$store.state.SingleNavTabs
    },
    WebPages(){
      return this.SitePages.filter(page => {
        return page.PublishType === 'Public'
      })
    },
    RenderedWebPages(){
      return this.WebPages.map(page => {
        let pageobj = {
          title: page.Name,
          icon: page.Icon,
          IsRoute: true,
          Path: '/PageEditor/'+page.id
        }
        return pageobj
      })
    },
    SocialPages(){
      return this.SitePages.filter(page => {
        return page.PublishType === 'Members Only'
      })
    },
    RenderedSocialPages(){
      return this.SocialPages.map(page => {
        let pageobj = {
          title: page.Name,
          icon: page.Icon,
          IsRoute: true,
          Path: '/PageEditor/'+page.id
        }
        return pageobj
      })
    },
    RenderedPhotoLibraries(){
      return this.SystemPhotoLibraries.map(lib => {
         let libobj = {
          title: lib.DisplayName,
          icon: lib.Icon,
          IsRoute: true,
          Path: '/'+lib.DisplayName.split(' ').join(''),
        }
        return libobj
      })
    },
    SystemConfigTabs(){
      //        Method: 'AssignSocialItemInteractMethod',
      //        Prop: 'ActivateCabinetDialog',
      //        SecondProp: elmnt.DisplayName
      let createitems = [
          {
            title: 'Create Web Form',
            icon: 'mdi-form-select',
            ItemPass: true,
            IsRoute: true,
            Path: '/WebFormBuilder-New',
          },
          {
            title: 'Create Website Page',
            icon: 'mdi-search-web',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPageDialog',
            SecondProp: 'Public'
          },
          {
            title: 'Create Social Page',
            icon: 'mdi-search-web',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPageDialog',
            SecondProp: 'Members Only'
          },
          {
            title: 'Create Photo Library',
            icon: 'mdi-folder-multiple-image',
            ItemPass: true,
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleNewPhotoLibraryDialog',
            SecondProp: 'Photo Library',
          },
          
      ]
      let storeproductentities = this.SystemEntities.filter(entity => {
        return entity.Entity_Type && entity.Entity_Type.Name === 'Inventory Article'
      }).map(entity => {
        let menuobj = {
                  title: entity.DisplayName,
                  icon: entity.Table_Icon,
                  ItemPass: true,
                  id: entity.id,
                  IsRoute: true,
                  Path: '/DirectoryBuilder/'+entity.id
                }
        return menuobj
      })
      let classifiedentities = this.SystemEntities.filter(entity => {
        return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
      }).map(entity => {
        let menuobj = {
                  title: entity.DisplayName,
                  icon: entity.Table_Icon,
                  ItemPass: true,
                  id: entity.id,
                  IsRoute: true,
                  Path: '/DirectoryBuilder/'+entity.id
                }
        return menuobj
      })
      let socialclassifiedobj = 
      {
        title: 'Classifieds',
        icon: 'mdi-newspaper-variant-outline',
        ItemPass: this.System.Site_Has_Classifieds,
        IsRoute: true,
        Path: '/DirectoryBuilder/Classifieds'
      }
      let groupcategoriesobj = {
        title: 'Group Categories',
        icon: 'mdi-account-group',
        ItemPass: this.System.Site_Has_Groups,
        IsRoute: true,
        Path: '/DirectoryBuilder/Group_Categories'
      }
      let featuredmembersobj = {
        title: 'Group Featured Members',
        icon: 'mdi-account-star-outline',
        ItemPass: true,
        IsRoute: true,
        Path: '/DirectoryBuilder/Group_Featured_Members'
      }
      let businessmembersobj = {
        title: 'Business Members',
        icon: 'mdi-smart-card',
        ItemPass: true,
        IsRoute: true,
        Path: '/DirectoryBuilder/Business_Members'
      }
      let datanavlists = storeproductentities.concat(classifiedentities)
      datanavlists.push(socialclassifiedobj)
      datanavlists.push(groupcategoriesobj)
      datanavlists.push(featuredmembersobj)
      datanavlists.push(businessmembersobj)
      let listitems = [
         
          
          {
            icon: 'mdi-account-question',
            title: 'FAQ',
            ItemPass: true,
            IsRoute: true,
            Path: '/FAQAdmin'            
          },
          {
            icon: 'mdi-file-pdf-box',
            title: 'PDF Templates',
            IsRoute: true,
            Path: '/PDFExportTemplates'            
          },
          {
          title: 'Web Forms',
          icon: 'mdi-form-select',
          ItemPass: true,
          IsRoute: true,
          Path: '/WebForms',
          },
          {
          title: 'Web Pages',
          icon: 'mdi-search-web',
          ItemPass: true,
          Children: this.RenderedWebPages
          },
          {
          title: 'Social Pages',
          icon: 'mdi-search-web',
          ItemPass: true,
          Children: this.RenderedSocialPages
          },
          {
          title: 'Photo Libraries',
          icon: 'mdi-folder-multiple-image',
          ItemPass: true,
          Children: this.RenderedPhotoLibraries
          },
          {
            icon: 'mdi-menu',
            title: 'Data Navlists',
            ItemPass: datanavlists.length > 0,
            Children: datanavlists
          }
          
          
      ]
      let items = [
        {
          title: 'Create',
          icon: 'mdi-plus',
          ItemPass: true,
          Children: createitems
        },
        {
          title: 'View',
          icon: 'mdi-view-list',
          ItemPass: true,
          Children: listitems
        },
         {
            title: 'Quick Nav',
            icon: 'mdi-arrow-top-right-thin-circle-outline',
            ItemPass: this.CurrentEntitySelectedTabs.length === 0,
            Children: this.SystemConfigDataTabsElementQuickselect
         },
         
         {
            title: 'Quick Nav',
            icon: 'mdi-arrow-top-right-thin-circle-outline',
            ItemPass: this.CurrentEntitySelectedTabs.length > 0,
            Children: this.DataTabsQuickActions.concat([
              {
                  title: 'Section Select',
                  icon: 'mdi-select',
                  ItemPass: true,
                  Children: this.SystemConfigDataTabsElementQuickselect
                },
                {
                  title: 'Cancel Selection',
                  icon: 'mdi-cancel',
                  ItemPass: this.CurrentEntitySelectedTabs.length > 0,
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'ActivateSelectedElement',
                  SecondProp: ''
                },
            ])
           },
           {
            title: 'Dashboard',
            icon: 'mdi-monitor-dashboard',
            ItemPass: true,
            Children: this.SystemDashboardElementsQuickSelect
         },
      ]
      return items.filter(item => {
        return item.ItemPass
      })
    },
    SingleRecordNavTabsRendered(){
      //console.log('this.SingleRecordNavTabs',this.SingleRecordNavTabs)
      return this.SingleRecordNavTabs
    },
    SelectedDataTabsElements(){
      return this.CurrentEntitySelectedTabs.map(tab => {
        return tab.Elements
      }).flat()
    },
    DataTabsQuickActions(){
      let array = []
      if(this.SelectedDataTabsElements.length > 0){
        array = this.SelectedDataTabsElements
      }
      else{
        array = this.DataTabsElements
      }
      return array.map((elmnt,elmntindex) => {
          if(elmnt.DataViewName === 'Data Single Related Data' && elmntindex === 0){
            //elmnt.CanCreate = true
          }
          return elmnt
        })
        .filter(elmnt => {
          return elmnt.DisplayName
        })
        .map(elmnt => {
          //console.log('elmnt',elmnt)
          let elmntobj = {}
          if(elmnt.MultipleFileUploadType === 'Images' || elmnt.MultipleFileUploadType === 'Document Register' || elmnt.MultipleFileUploadType === 'Gallery'){
            elmntobj = {
                title: 'Add '+elmnt.DisplayName,
                icon: 'mdi-plus',
                ItemPass: elmnt.CanCreate,
                Method: 'AssignSocialItemInteractMethod',
                Prop: 'ActivateCabinetDialog',
                SecondProp: elmnt.DisplayName
                }
          }
          else if(elmnt.DataViewName === 'Data Single Related Data'){
            elmntobj = {
                title: 'Add '+elmnt.DisplayName,
                icon: 'mdi-plus',
                ItemPass: elmnt.CanCreate,
                Method: 'AssignSocialItemInteractMethod',
                Prop: 'ActivateCabinetDialog',
                SecondProp: elmnt.DisplayName
                }
          }
          return elmntobj
        }).filter(elmnt => {
          return elmnt.ItemPass
        })
    },
    DataTabsElements(){
      //console.log('CurrentEntityTabs',this.CurrentEntityTabs)
      if(this.CurrentEntityTabs){
        return this.CurrentEntityTabs.map(tab => {          
          return tab.Elements.map((elmnt) => {
            let parenttabobj = Object.assign({},tab)            
            let elmntobj = Object.assign({},elmnt)
            elmntobj.FlexXLRG = 12								
            elmntobj.FlexLarge = 12									
            elmntobj.FlexMedium = 12									
            elmntobj.FlexSmall = 12									
            elmntobj.FlexXSmall = 12
            parenttabobj.Elements = [elmntobj]
            elmntobj.ParentTab = parenttabobj
            return elmntobj
          })
        }).flat()
        
      }
      else{
        return []
      }      
    },
    SystemDashboardElements(){
      return this.SystemDashboardTabs.map(tab => {          
          return tab.Elements.map((elmnt) => {
            let parenttabobj = Object.assign({},tab)            
            let elmntobj = Object.assign({},elmnt)
            elmntobj.FlexXLRG = 12								
            elmntobj.FlexLarge = 12									
            elmntobj.FlexMedium = 12									
            elmntobj.FlexSmall = 12									
            elmntobj.FlexXSmall = 12
            parenttabobj.Elements = [elmntobj]
            elmntobj.ParentTab = parenttabobj
            return elmntobj
          })
        }).flat()
    },
    SystemDashboardElementsQuickSelect(){
      return this.SystemDashboardElements.map(elmnt => {
         let elmntobj = {
              title: elmnt.DisplayName,
              icon: elmnt.Icon,
              ItemPass: true,
              Method: 'AssignSocialItemInteractMethod',
              Prop: 'ActivateSingleRecordNavMenuItem',
              SecondProp: elmnt
            }
            return elmntobj
      })
    },
    SystemConfigDataTabsElementQuickselect(){
      
      let generalobj = this.DataTabsElementQuickselect.find(obj => obj.title === 'General' && obj.icon === 'mdi-book-information-variant')
      let mailingobj = this.DataTabsElementQuickselect.find(obj => obj.title === 'Mailing')
      let entitiesobj = this.DataTabsElementQuickselect.find(obj => obj.title === 'Entities')
      let socialchildren = [{
            icon: 'mdi-cash-register',
            title: 'Monetization',
            ItemPass: true,
            IsRoute: true,
            Path: '/Monetization'            
          },].concat(this.DataTabsElementQuickselect.filter(obj => {
                return obj.title === 'Blogs' || obj.title === 'Articles' || obj.title === 'Classifieds' || obj.title === 'Events' || obj.title === 'Forums' || obj.title === 'Groups'
                || obj.title === 'Meetups'  || obj.title === 'Polls' 
              }))
      let socialobj = {
              title: 'Social Module',
              icon: 'mdi-home-group',
              ItemPass: true,
              Children: socialchildren,
            }
      let items = [        
        socialobj,
        // entitiesobj,
        // mailingobj,
        // generalobj,
      ]
      return items
    },
    DataTabsElementQuickselect(){
      return this.DataTabsElements.map(elmnt => {
        let elmntobj = {
              title: elmnt.DisplayName,
              icon: elmnt.Icon,
              ItemPass: true,
              Method: 'AssignSocialItemInteractMethod',
              Prop: 'ActivateSelectedElement',
              SecondProp: elmnt
            }
            //SecondProp has to be parent tab as well, in order to reutrn parent tab and childtab and boom
            return elmntobj
      })
    },
    DataSingleActions(){
      let centeritem = this.UserActions.find(obj => obj.title === this.ActiveAppMode)
      let teamitems = [
        {
          title: 'Edit Ownership',
          icon: 'mdi-tag-faces',
          Method: 'AssignSocialItemInteractMethod',
          Prop: 'EditOwnership',
          ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle'
        },
        {
          title: 'Create Task',
          icon: 'mdi-clipboard-check-outline',
          ItemPass: this.ActiveRoute.Name === 'DataSingle' && this.CurrentItemModerator,
          Method: 'AssignSocialItemInteractMethod',
          Prop: 'SetActivityRegarding',
          SecondProp: 'Task'
        },
        centeritem,
         {
            title: 'Liaison',
            icon: 'mdi-comment-edit',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ActivateLiaisonDialog',
            ItemPass: this.ActiveRoute.Name === 'DataSingle',
            },
        {
        title: 'Start Timesheet',
        icon: 'mdi-account-clock',
        Method: 'AssignSocialItemInteractMethod',
        Prop: 'ToggleTimesheetDialog',
        ItemPass: this.ActiveRoute.Name === 'DataSingle' && this.CurrentItemModerator,
        },
      ]
      let adminitems = [
        {
            title: 'Status',
            icon: 'mdi-power',
            ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.$route.meta && !this.$route.meta.RecordisSystem,
            Children: [{
            title: 'Publish',
            icon: 'mdi-publish',
            ItemPass: this.PublishItems && this.PublishItems.length > 0,
            Children: this.PublishItems
           },
         {
            title: 'Toggle Status',
            icon: 'mdi-power',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleChangeStatusDialog',
            ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.$route.meta && !this.$route.meta.RecordisSystem
          },
          {
            title: 'View SO',
            icon: 'mdi-cash-register',
            IsRoute: true,                    
            Path: '/SalesOrder/'+this.CurrentEntity.id+'/'+this.$route.params.id,
            ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.$route.meta && !this.$route.meta.RecordisSystem && this.StoreProductEntity
          },          
          {
            title: 'Receive Goods',
            icon: 'mdi-warehouse',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ActivateReceivingDialog',
            ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && 
            this.CanReceiveGoods
          },
          {
            title: 'Movement History',
            icon: 'mdi-timeline-clock-outline',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ActivateWarehouseMovementDialog',
            ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && 
            this.MovementHistoryActive
            
          },
           {
            title: 'Add to Store',
            icon: 'mdi-cart',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'AddtoStore',
            ItemPass: this.CurrentStoreItemPublisher && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && this.EntityStoreActive
            
          },
          {
            title: 'Add Store Article',
            icon: 'mdi-cart',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'AddStoreArticle',
            ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && this.EntityStoreArticle
            
          },
                  ]
           },
           
          //  {
          //   title: 'Options',
          //   icon: 'mdi-cogs',
          //   ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.$route.meta && this.$route.meta.RecordisSystem,
          //   Children: this.SingleRecordNavTabsRendered
          // // },
          // {
          //   title: 'Options',
          //   icon: 'mdi-cogs',
          //   ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.$route.meta && this.$route.meta.RecordisSystem,
          //   Children: this.SystemConfigTabs
          // },        
          centeritem,
          {
            title: 'Quick Nav',
            icon: 'mdi-arrow-top-right-thin-circle-outline',
            ItemPass: this.CurrentEntitySelectedTabs.length === 0,
            Children: this.DataTabsElementQuickselect.concat([
              
            ])
            // Children: [
            //    {
            //       title: 'All '+this.ActiveRoute.ListTitle,
            //       icon: 'mdi-view-list',
            //       ItemPass: this.ActiveRoute.HasList && !this.ActiveRoute.DropdownList,
            //       IsRoute: true,                    
            //       Path: this.ActiveRoute.ListPath
            //     },
            //     {
            //       title: 'Section Select',
            //       icon: 'mdi-select',
            //       ItemPass: true,
            //       Children: this.DataTabsElementQuickselect
            //     },
            //      {
            //       title: 'Quick Create',
            //       icon: 'mdi-plus',
            //       ItemPass: true,
            //       Children: this.DataTabsQuickActions
            //     },
            // ]
          },
           {
            title: 'Quick Nav',
            icon: 'mdi-arrow-top-right-thin-circle-outline',
            ItemPass: this.CurrentEntitySelectedTabs.length > 0,
            Children: this.DataTabsQuickActions.concat([
              
              {
                  title: 'Section Select',
                  icon: 'mdi-select',
                  ItemPass: true,
                  Children: this.DataTabsElementQuickselect
                },
                {
                  title: 'Cancel Selection',
                  icon: 'mdi-cancel',
                  ItemPass: this.CurrentEntitySelectedTabs.length > 0,
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'ActivateSelectedElement',
                  SecondProp: ''
                },
            ])
           },
      ]
      let birdviewitems = [
           {
            title: 'Timesheets',
            icon: 'mdi-folder-clock-outline',
            Method: 'AssignSocialItemInteractMethod',
            Prop: 'ToggleTimesheetlistdialog',
            ItemPass: this.ActiveRoute.Name === 'DataSingle',
            },
             {
              title : 'Activities',
              icon: 'mdi-clipboard-list',
              Method: 'AssignSocialItemInteractMethod',
              Prop: 'ViewActivities',
              ItemPass: this.ActiveRoute.Name === 'DataSingle',
            },
            centeritem,
             {
              title : 'Dashboards',
              icon: 'mdi-monitor-dashboard',
              Method: 'AssignSocialItemInteractMethod',
              Prop: 'ViewActivities',
              ItemPass: this.ActiveRoute.Name === 'DataSingle',
              Children: [
                {
                ItemPass: this.ActiveRoute.WIPandStats && this.CurrentEntity && this.CurrentEntity.EntityProcesses && this.CurrentEntity.EntityProcesses.length>0,
                title: this.CurrentEntity.DisplayName+' Pipelines',
                icon: 'mdi-pipe',
                Method: 'TriggerDashboardbyNotification',
                Prop: '',                    
                },
                {
                ItemPass: this.ActiveRoute.WIPandStats && this.CurrentEntity && this.ActiveRouteEntityDashboards && this.ActiveRouteEntityDashboards.length>0,
                title: 'Custom Dashboards',
                icon: 'mdi-monitor-dashboard',
                Children: this.ActiveRouteEntityDashboards,                
                },                               
              ]
            },
             {
                  title: 'Export to PDF',
                  icon: 'mdi-file-pdf-box',
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'ToggleExportingtoPDFDialog',
                  ItemPass: this.CurrentEntity && this.ActiveRoute.Name === 'DataSingle'
                }, 
                 
      ]
      if(this.ActiveAppMode === 'Bird View'){
        return birdviewitems.filter(item => {
          return item.ItemPass
        })
      }
      else if(this.ActiveAppMode === 'Team'){
        return teamitems.filter(item => {
          return item.ItemPass
        })
      }
      else if(this.ActiveAppMode === 'Admin'){
        return adminitems.filter(item => {
          return item.ItemPass
        }).map(item => {
          if(item.Children){
            item.Children = item.Children.filter(child => {
              return child.ItemPass
            })
          }
          return item
        })
      }
      else if(this.ActiveAppMode === 'Super Admin'){
        return adminitems.filter(item => {
          return item.ItemPass
        }).map(item => {
          if(item.Children){
            item.Children = item.Children.filter(child => {
              return child.ItemPass
            })
          }
          return item
        })
      }
    },
    StoreProductEntity(){
            return this.ActiveEntity && this.ActiveEntity.Entity_Type.Name === 'Store Product'
        },
    UserActions(){
      //console.log('this.ActiveRoute SetCurrentItemModerator',this.ActiveRoute,this.CurrentItemModerator,this.CurrentEntity)
      //goled AssignSocialItemInteractMethod as method, child comp method as Prop and additiona prop as SecondProp
          let items = [
             {
              title: this.ActiveRoute.BackTitle,
              icon: this.ActiveRoute.BackIcon,
              ItemPass: this.ActiveRoute.HasBack,
              IsRoute: true,                    
              Path: this.ActiveRoute.BackPath
            },
            {
              title: 'Edit',
              icon: 'mdi-pencil',
              ItemPass: this.ActiveRoute.HasEdit && this.CurrentItemModerator,
              Children: [
                    {
                    title: 'Assign Process',
                    icon: 'mdi-swap-vertical-variant',
                    Method: 'AssignSocialItemInteractMethod',
                    Prop: 'AssignProcess',
                    ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && this.CurrentEntity.EntityProcesses.length > 0
                  },
                  {
                    title: 'Automations',
                    icon: 'mdi-auto-fix',
                    Method: 'AssignSocialItemInteractMethod',
                    Prop: 'ToggleAutomationDialog',
                    ItemPass: this.userIsAdmin && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && this.CurrentEntity.automations
                     && this.CurrentEntity.automations.length > 0
                  },
                  
                  {
                    title: 'Edit Ownership',
                    icon: 'mdi-tag-faces',
                    Method: 'AssignSocialItemInteractMethod',
                    Prop: 'EditOwnership',
                    ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle'
                  },
                  {
                    title: 'Toggle Status',
                    icon: 'mdi-power',
                    Method: 'AssignSocialItemInteractMethod',
                    Prop: 'ToggleChangeStatusDialog',
                    ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle'
                  },
                 //EntityIndex1Items
                 
              ]
            },
             {
                title: 'Create',
                icon: 'mdi-plus',
                ItemPass: this.ActiveRoute.HasCreate && !this.ActiveRoute.DropdownCreate && this.CurrentItemModerator,
                Method: 'AssignSocialItemInteractMethod',
                Prop: this.ActiveRoute.CreateMethod
             },
            
            {
                title: 'Create',
                icon: 'mdi-plus',
                ItemPass: this.ActiveRoute.HasCreate && this.ActiveRoute.DropdownCreate && this.CurrentItemModerator,
                Children: [
                  {
                  title: 'Create Task',
                  icon: 'mdi-clipboard-check-outline',
                  ItemPass: this.ActiveRoute.Name === 'DataSingle' && this.CurrentItemModerator,
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'SetActivityRegarding',
                  SecondProp: 'Task'
                },
                 {
                  title: 'Start Timesheet',
                  icon: 'mdi-account-clock',
                  Method: 'AssignSocialItemInteractMethod',
                  Prop: 'ToggleTimesheetDialog',
                  ItemPass: this.ActiveRoute.Name === 'DataSingle' && this.CurrentItemModerator,
                  },
                  {
                    title: 'Receive Goods',
                    icon: 'mdi-warehouse',
                    Method: 'AssignSocialItemInteractMethod',
                    Prop: 'ActivateReceivingDialog',
                    ItemPass: this.CurrentItemModerator && this.ActiveRoute.Name === 'DataSingle' && this.CurrentEntity && 
                    this.CanReceiveGoods
                  },
                ]
              },
             
            {
            title: this.ActiveAppMode,
            icon: this.ActiveAppModeIcon,
            ItemPass: true,
            Children: this.ComputedAppModes
            },
          // Children: [
          //    {
          //     title: 'Export to PDF',
          //     icon: 'mdi-file-pdf-box',
          //     Method: 'AssignSocialItemInteractMethod',
          //     Prop: 'ToggleExportingtoPDFDialog',
          //     ItemPass: this.CurrentEntity && this.ActiveRoute.Name === 'DataSingle'
          //   },
          //   {
          //   ItemPass: this.ActiveRoute.WIPandStats && this.CurrentEntity && this.CurrentEntity.EntityProcesses && this.CurrentEntity.EntityProcesses.length>0,
          //   title: this.CurrentEntity.DisplayName+' Pipelines',
          //   icon: 'mdi-monitor-dashboard',
          //   Method: 'TriggerDashboardbyNotification',
          //   Prop: '',                    
          //   },
          //   {
          //   ItemPass: this.ActiveRoute.WIPandStats && this.CurrentEntity && this.ActiveRouteEntityDashboards && this.ActiveRouteEntityDashboards.length>0,
          //   title: 'Custom Dashboards',
          //   icon: 'mdi-monitor-dashboard',
          //   Children: this.ActiveRouteEntityDashboards,                
          //   },
          //   {
          //   title: 'View Timesheets',
          //   icon: 'mdi-folder-clock-outline',
          //   Method: 'AssignSocialItemInteractMethod',
          //   Prop: 'ToggleTimesheetlistdialog',
          //   ItemPass: this.ActiveRoute.Name === 'DataSingle',
          //   // && this.ActiveAppMode === 'Admin'
          //   },
          //    {
          //     title : 'View Activities',
          //     icon: 'mdi-clipboard-list',
          //     Method: 'AssignSocialItemInteractMethod',
          //     Prop: 'ViewActivities',
          //     ItemPass: this.ActiveRoute.Name === 'DataSingle',
          //     // && this.ActiveAppMode === 'Admin'
          //   },
          //   {
          //   title: 'Liaison',
          //   icon: 'mdi-comment-edit',
          //   Method: 'AssignSocialItemInteractMethod',
          //   Prop: 'ActivateLiaisonDialog',
          //   ItemPass: this.ActiveRoute.Name === 'DataSingle',
          //   },
          //    {
          //   title: 'Switch Mode',
          //   icon: this.ActiveAppModeIcon,
          //   Children: this.ComputedAppModes,
          //   ItemPass: true,
          //   },
            
          // ],
           {
              title: 'Actions',
              icon: this.ActiveRoute.ActionListIcon,
              ItemPass: this.ActiveRoute.ActionList,
              Children: [
                {
                title: 'Create Activity',
                icon: 'mdi-clipboard-list',
                Method: 'ToggleNewTaskDialog',
                Prop: '',
                ItemPass: true
                },
                {
                title: 'Timesheet',
                icon: 'mdi-ticket-confirmation',
                Method: 'ToggleStopWatchDialog',
                Prop: '',
                ItemPass: true
                },
              
              ],
              },
              {
                title: 'All '+this.ActiveRoute.ListTitle,
                icon: 'mdi-view-list',
                ItemPass: this.ActiveRoute.HasList && !this.ActiveRoute.DropdownList,
                IsRoute: true,                    
                Path: this.ActiveRoute.ListPath
              },
              {
                title: 'All '+this.ActiveRoute.ListTitle,
                icon: 'mdi-view-list',
                ItemPass: this.ActiveRoute.HasList && this.ActiveRoute.DropdownList,
                Children: this.ActiveRoute.ListChildren
              },
              {
            title: 'Publish',
            icon: 'mdi-publish',
            ItemPass: this.PublishItems && this.PublishItems.length > 0,
            Children: this.PublishItems
           },
            
                 //EntityIndex0Items
                 //EntityIndex2Items
                 //EntityIndex3Items
            // {
            //   title: 'Create '+this.ActiveRoute.Single,
            //   icon: 'mdi-plus',
            //   ItemPass: this.ActiveRoute.HasCreate && this.UserisItemModerator(this.ActiveRoute.Moderator) && this.ActiveRoute.CreatePath,
            //   IsRoute: true,
            //   Path: this.ActiveRoute.CreatePath
            //   },
          ]
          return items
          .map(item => {
            if(item.Children){
              item.Children = item.Children.filter(child => {
                return child.ItemPass
              })
            }
            return item
          })
          .filter(item => {
            return item.ItemPass
          })
        },
        PurchasingItems(){
      let purchasingent = this.SystemEntities.filter(ent => {
        return ent.Entity_Type
      }).filter(ent => {
        return ent.Entity_Type.Name === 'Store Supplier' || ent.Entity_Type.Name === 'Purchase Invoice'
      }).map(ent => {
        let navobj = {
          icon: ent.Table_Icon,
          title: ent.DisplayName,
          target: 'self',
          QueryName: ent.DisplayName.split(' ').join('')+'Query',
          EntityRecord: ent,
          Path: '/'+ent.id.split('_').join(''),
          ItemPass: this.$store.state[ent.DisplayName.split(' ').join('')+'Query']
        }
        return navobj
      }).filter(child => {
        return child.ItemPass
      })
      return [{
              title: 'Purchase Orders',
              icon: 'mdi-cash-minus',
              ItemPass: this.userisPurchaseOfficer,
              target: '',
              Path: '/PurchaseOrders'
              
        }].concat(purchasingent)
    },
    AccountingItems(){
      let basic = [
            {
                  title: 'General Ledger',
                  icon: 'mdi-cash-register',
                  //or wh check
                  ItemPass: this.userIsEagleViewer || this.userIsAdmin,
                  target: '',
                  Path: '/Ledgers/General'
                  
              }
          ]
      return basic
    },
    DBBottomNavigationItems(){
      let standarditems = [
        
        {
        title: 'Actions',
        icon: 'mdi-run-fast',
        Children: [
           {
          title: 'Invite User',
          icon: 'mdi-share-circle',
          Method: 'AcceptInviteUserDialog',
          Prop: ''
          },
           {
          title: 'Create Activity',
          icon: 'mdi-clipboard-list',
          Method: 'ToggleNewTaskDialog',
          Prop: ''
          },
           {
          title: 'Timesheet',
          icon: 'mdi-ticket-confirmation',
          Method: 'ToggleStopWatchDialog',
          Prop: ''
          },
           {
          title: 'Styling',
          icon: 'mdi-palette-advanced',
          Method: 'ToggleAppStylingView',
          Prop: ''
          //only if admin
          },
            {
        title: 'Sign Out',
        icon: 'mdi-exit-to-app',
        Method: 'ConfirmlogoutFromFirebase',
        Prop: ''
        },
         
        ],
        },
          {
        title: 'Favorites',
        icon: 'mdi-star',
        Children: [
           {
          title: 'Add as Favorite',
          icon: 'mdi-star-outline',
          Method: 'AddPageAsFavorite',
          Prop: ''
           },
           {
          title: 'My Favorites',
          icon: 'mdi-star',
          Children: this.NavUserFavorites
          },
        ],
         },
        {
        title: 'Menu',
        icon: 'mdi-menu',
        Children: [
            {
          title: 'My Account',
          icon: 'mdi-star-face',
          IsRoute: true,
          Path: '/MyAccount'
           },
           {
        title: 'Records',
        icon: 'mdi-database',
        Children: this.DBNavbarItems
        },
        {
          icon: 'mdi-library',
          title: 'Libraries',
          Path: '/Libraries',
          target: 'self',
          Boolean: 'Libraries',
          Children: this.SystemPhotoLibraries.map(item => {
              // item.Children = item.Children.filter(child => {
              //   return vm.$store.state[child.QueryName]
              // })
              let libraryitem = {
                Path: '/'+item.DisplayName.split(' ').join(''),
                target: '',
                icon: item.Icon,
                title: item.DisplayName

              }
              return libraryitem
            })
          },
       
        {
          title: 'Warehouses',
          icon: 'mdi-warehouse',
          target: 'self',
          to: '/Warehouses',
          Boolean: 'Warehouses',
          ItemPass: true,
          Children: this.WarehousesArray.map(wh => {
              let whobj ={
                  title: wh.Name,
                  icon: 'mdi-warehouse',
                  //or wh check
                  ItemPass: true,
                  target: '',
                  Path: '/Warehouse/'+wh.Business_Unitid+'/'+wh.id
                  
              }
              return whobj
          })
      },
       {
          title: 'Purchasing',
          icon: 'mdi-cash-minus',
          target: 'self',
          to: '/Purchasing',
          Children: this.PurchasingItems
        },
        {
          title: 'Accounting',
          icon: 'mdi-bank',
          target: 'self',
          to: '/Accounting',
          Children: this.AccountingItems
        },
        
         
            
             {
          title: 'Suite Admin',
          icon: 'mdi-cogs',
          IsRoute: true,
          Path: '/Getting-Started'
          //only if admin
          },
           
        
         
          
        ],
        },
          
        // {
        //   title: 'My Favorites',
        //   icon: 'mdi-star',
        //   Children: this.NavUserFavorites
        //   },
       
         
       
         
         
        //  {
        //   title: 'Social Module',
        //   icon: 'mdi-repeat',
        //   }
        
        // {
        // title: 'Dark Mode',
        // icon: 'mdi-invert-colors',
        // Method: 'ToggleAppDarkMode',
        // Prop: ''
        // },
        //  {
        // title: 'Sign Out',
        // icon: 'mdi-exit-to-app',
        // Method: 'ConfirmlogoutFromFirebase',
        // Prop: ''
        // },
        //invite user, timesheet,log ticket, create activity
       
      ]
        return  standarditems
        .filter(item => {
          if(item.title === 'Social Module'){
            return this.CanToggleSocialModule
          }
          else{
            return item
          }
        })
          .map(item => {
            if(item.title === 'Menu' || item.title === 'Switch Mode'){
              item.Children = item.Children.filter(child => {
                if(child.title === 'Social Module'){
                return this.CanToggleSocialModule
                }
                else{
                  return child
                }
              })
              item.Children = item.Children.map(child => {
                //  if(child.title === 'Social Module'){
                //   child.title = this.System.Social_Module_Name
                //   child.Method = 'IntranetViewToggle'
                //   child.Prop = true
                // }
                // else
                if(child.title === 'Styling' || item.title === 'System Config'){
                  return this.userIsAdmin
                }
                else if(child.title === 'Add as Favorite' || child.title === 'Remove Favorite'){
                
                if(this.CurrentisFavorite){
                  child.title = 'Remove Favorite'
                  child.icon = 'mdi-star-off'
                }
                else{
                  child.title = 'Add as Favorite'
                  child.icon = 'mdi-star-outline'
                }
              }
                return child
              })
            }
            if(item.title === 'Social Module'){
                  item.title = this.System.Social_Module_Name
                  item.Method = 'IntranetViewToggle'
                  item.Prop = true
                }
            else if(item.title === 'Actions'){
              item.Children = item.Children.filter(child => {
                if(child.title === 'Styling' || item.title === 'System Config'){
                  return this.userIsAdmin
                }
                else if(child.title === 'Invite User'){
                  return this.userLoggedIn.CanInviteUsers || this.userIsAdmin
                }
                else{
                  return item
                }
              })
            }
              return item
          })
    },
    UserFavorites(){
      return this.userLoggedIn.FavoriteLinks ? this.userLoggedIn.FavoriteLinks : []
    },
    NavUserFavorites(){
      return this.UserFavorites.map(fav => {
        let favobj = {
          title: fav.DisplayName,
          icon: '',
          Path: fav.Path,
          IsRoute: true,
          RouteBlank: false
        }
        return favobj
      })
    },
    DefaultFields(){
      return [{Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
          {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
          {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
          {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
      ]
    },
    NeedsRASync(){
       return this.$store.state.NeedsRASync           
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    NavDark(){
      if(this.UserDBNavList){
        return true
      }
      else{
        return true
      }
    },
    NavbarStyling(){
      if(this.UserDBNavList){

      }
      else{
        return { backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' ,opacity: 0.2,zIndex:-1}
      }
    },
    CanToggleSocialModule(){
      if(this.UserDBNavList){
        return this.System && this.SocialNetworkActive && this.System.Social_Landing_Page && this.UserDBNavList.Options.SocialModule
      }
      else{
        return this.System && this.SocialNetworkActive && this.System.Social_Landing_Page
      }
    },
    Computeditems(){
    return this.items.filter(item => {
      
      if(this.UserDBNavList){
        return this.UserDBNavList.Options[item.Boolean]
      }
      else{
        return item
      }
    })
    .map(item => {
      if(this.UserDBNavList){
        let itemobj = Object.assign({},item)
        if(item.Children && item.Children.length > 0){
         itemobj.Children = item.Children
         .map(child => {
           if(child.Children){
             return child.Children
           }
           else{
             return child
           }
        }).flat() 
        }
        return itemobj
      }
      else{
        return item
      }
    })
    .map(item => {
      //console.log('item',item)
      if(item.title === 'Dashboards' && this.UserDBNavList){
        item.Children = item.Children.filter(child => {         
          return this.UserDBNavList.Options.DashboardOptions.includes(child.title)
          || child.to.includes('/CustomDashboard') && this.UserDBNavList.Options.DashboardOptions.includes('Shared Dashboards')
        })
      }
      return item
    })
    
  },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    RenderNavStyling(){
      if(this.UserDBNavList){
        return this.NavStyling
      }
      else{
        return this.NavStyling
      }
    },
    NavStyling(){

      if(this.System.DB_SideBar_IMG && this.System.DB_SideBar_Gradient){
                return { backgroundImage: this.System.DB_SideBar_Gradient.split('background: ')[1].split(';')[0]+`,url('`+this.System.DB_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.DB_SideBar_IMG && !this.System.DB_SideBar_Gradient){
                return { backgroundImage: `url('`+this.System.DB_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.DB_SideBar_Gradient){
                return { backgroundImage: this.System.DB_SideBar_Gradient.split('background: ')[1].split(';')[0]}
            }
            else if(this.PageBGColor){
                return { backgroundColor: this.PageBGColor+ `!important`}
            }
            else{
                return ''
            }
    },
    AllSecurityRoles(){
      if(this.userIsAdmin && !this.IsGuestUser){
        return this.BUsecurityroles.concat(this.Customsecurityroles,this.RMsecurityroles)
      }
      else{
        return this.BUsecurityroles.concat(this.Customsecurityroles,this.RMsecurityroles).filter(role => {
          return role.IsGuestSafe
        })
      }
    },
    BusinessUnitsArray(){
      //console.log('this.$store.state',this.$store.state)
      return this.$store.state.BusinessUnitsArray
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userPurchaseWarehouses(){
    return this.WarehousesArray.filter(wh => {
      return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Ordering || 
      this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Auditing
    })
  },
  userisPurchaseOfficer(){
    return this.userPurchaseWarehouses.length > 0 || this.userIsEagleViewer || this.userIsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
  UserFavoriteLinks(){
    if(this.UserRecord.FavoriteLinks){
      let array = JSON.parse(JSON.stringify(this.UserRecord.FavoriteLinks))   
      array.Views = []   
      array.map(favlink => {
        //console.log(favlink.Path.split('/')[1].toLowerCase())
        let routeitem = this.$router.options.routes.find(obj => obj.name === favlink.Path.split('/')[1].toLowerCase())
        //console.log(routeitem)
        if(routeitem && routeitem.meta && routeitem.meta.icon){
          favlink.icon = routeitem.meta.icon
          favlink.viewicon = routeitem.meta.viewicon
          //console.log(routeitem)
          if(routeitem.meta.type === 'Data Tables' && routeitem.meta.subcat){
            let DataTableObj = array.Views.find(obj => obj.Name === 'Data Tables')
            //console.log("DataTableObj",DataTableObj)
            if(!DataTableObj){
              let DataTablePushObj = {
                Name: 'Data Tables',
                Links: [],
                Icon: 'mdi-file-table-box'
              }
              //console.log(DataTablePushObj)

              DataTablePushObj.Links.push(favlink)
              array.Views.push(DataTablePushObj)
              //console.log(array.Views)
            }
            else{
                DataTableObj.Links.push(favlink)
              }              
            }
            else if(routeitem.meta.type === 'Pages' && routeitem.meta.subcat){
            let PagesObj = array.Views.find(obj => obj.Name === 'Pages')
            //console.log("PagesObj",PagesObj)
            if(!PagesObj){
              let PagesPushObj = {
                Name: 'Pages',
                Links: [],
                Icon: 'mdi-book-open-page-variant'
              }
              //console.log(PagesPushObj)

              PagesPushObj.Links.push(favlink)
              array.Views.push(PagesPushObj)
              //console.log(array.Views)
            }
            else{
                PagesObj.Links.push(favlink)
              }              
            }
            else if(routeitem.meta.type === 'DB Records' && routeitem.meta.subcat){
            let DBRecordObj = array.Views.find(obj => obj.Name === 'DB Records')
            //console.log("DBRecordObj",DBRecordObj)
            if(!DBRecordObj){
              let DBRecordObjPush = {
                Name: 'DB Records',
                Items: [],
                Icon: 'mdi-database'
              }
              //console.log(DBRecordObjPush)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              //console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              //console.log(SubCatItem)
              DBRecordObjPush.Items.push(SubCatItem)
              //console.log(DBRecordObjPush)
              array.Views.push(DBRecordObjPush)
              //console.log(array.Views)
            }
            else{
              //console.log(DBRecordObj)
              let subcatcheck = DBRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                DBRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Wiki Pages' && routeitem.meta.subcat){
            let WikiPageObj = array.Views.find(obj => obj.Name === 'Wiki Pages')
            //console.log("WikiPageObj",WikiPageObj)
            if(!WikiPageObj){
              let WikiPageObjPush = {
                Name: 'Wiki Pages',
                Items: [],
                Icon: 'mdi-wikipedia'
              }
              //console.log(WikiPageObjPush)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              //console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              //console.log(SubCatItem)
              WikiPageObjPush.Items.push(SubCatItem)
              //console.log(WikiPageObjPush)
              array.Views.push(WikiPageObjPush)
              //console.log(array.Views)
            }
            else{
              let subcatcheck = WikiPageObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                WikiPageObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Library Records' && routeitem.meta.subcat){
            let LibraryRecordObj = array.Views.find(obj => obj.Name === 'Library Records')
            //console.log("LibraryRecordObj",LibraryRecordObj)
            if(!LibraryRecordObj){
              let LibraryRecordPushObj = {
                Name: 'Library Records',
                Items: [],
                Icon: 'mdi-library'
              }
              //console.log(LibraryRecordPushObj)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              //console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              //console.log(SubCatItem)
              LibraryRecordPushObj.Items.push(SubCatItem)
              //console.log(LibraryRecordPushObj)
              array.Views.push(LibraryRecordPushObj)
              //console.log(array.Views)
            }
            else{
              let subcatcheck = LibraryRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                LibraryRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        else if(routeitem.meta.type === 'Photo Libraries' && routeitem.meta.subcat){
            let LibraryRecordObj = array.Views.find(obj => obj.Name === 'Photo Libraries')
            //console.log("LibraryRecordObj",LibraryRecordObj)
            if(!LibraryRecordObj){
              let LibraryRecordPushObj = {
                Name: 'Photo Libraries',
                Items: [],
                Icon: 'mdi-camera-image'
              }
              //console.log(LibraryRecordPushObj)
              let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: [],
                  icon: routeitem.meta.icon
                }
              //console.log(SubCatItem)
              SubCatItem.Links.push(favlink)
              //console.log(SubCatItem)
              LibraryRecordPushObj.Items.push(SubCatItem)
              //console.log(LibraryRecordPushObj)
              array.Views.push(LibraryRecordPushObj)
              //console.log(array.Views)
            }
            else{
              let subcatcheck = LibraryRecordObj.Items.find(obj => obj.Name === routeitem.meta.subcat.split(' ').join(''))
              if(!subcatcheck){
                let SubCatItem = {
                  Name: routeitem.meta.subcat.split(' ').join(''),
                  Links: []
                }
                SubCatItem.Links.push(favlink)
                LibraryRecordObj.Items.push(SubCatItem)
              }
              else{
                subcatcheck.Links.push(favlink)
              }
            }          
        }
        
        else if(routeitem.meta.type === 'Libraries' && routeitem.meta.subcat){
            let LibraryObj = array.Views.find(obj => obj.Name === 'Libraries')
            //console.log("LibraryObj",LibraryObj)
            if(!LibraryObj){
              let LibraryPushObj = {
                Name: 'Libraries',
                Links: [],
                Icon: 'mdi-library'
              }
              //console.log(LibraryPushObj)

              LibraryPushObj.Links.push(favlink)
              array.Views.push(LibraryPushObj)
              //console.log(array.Views)
            }
            else{
                LibraryObj.Links.push(favlink)
              }             
        }
        
            
          }
          else if(routeitem){
            let OtherObj = array.Views.find(obj => obj.Name === 'Other')
            //console.log("OtherObj",OtherObj)
            if(!OtherObj){
              let OtherPushObj = {
                Name: 'Other',
                Links: [],
                Icon: 'mdi-help'
              }
              //console.log(OtherPushObj)

              OtherPushObj.Links.push(favlink)
              array.Views.push(OtherPushObj)
              //console.log(array.Views)
            }
            else{
                OtherObj.Links.push(favlink)
              }              
            }
          
        return favlink
      })
      return array.Views
    }
    },
    CurrentisFavorite(){
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          return true
        }
        else {
          return false
        }
      }
      else{
        return false
      }
    },
      userLoggedIn () {
      return this.$store.getters.user
    },
    userBUID () {
      return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    userBU () {
    return this.$store.state.buobj
    },

    UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read
      })
    },
    ReadNotifications(){
      return this.Notifications.filter(notificaton => {
        return notificaton.Read === true
      })
    },
    SystemTicketsEntity(){
      return this.SystemEntities.find(obj => obj.id === 'System_Tickets')
    }
    },

    watch: {
      
      ActivityRegarding(value){        
            if(value){
              console.log('value',value)
              this.NewTask.Type = value.Type
              this.NewTask.Regarding_Type = value.Regarding.Regarding_Type
              this.NewTask.Regarding_Record = value.Regarding.Regarding_Record
              this.NewTask.Regarding_Topic = value.Regarding.Regarding_Topic
              this.NewTaskDialog = true
            }
      },
      TimesheetRelated(value){
            if(value){
              //console.log(value)
            }
      },
      StopWatchDialog (value){
            if(value){
            }
            else{
              this.$emit('ToggleTimesheetDialog',value)  
            }            
        },
      TimesheetDialogActive  (value) {
        if(value){
          this.StopWatchDialog = true
        }
      },
      userLoggedIn (value) {
        if (value === null || value === undefined) {
          if(this.$route.path.includes('UserInvite')){    
          }
          else{
          this.$router.push('/login')
          }
        }
        //if (value !== null && value !== undefined) {
        //  this.$router.push('/')
        //}
      }
    },

  created() {
    //console.log('this.UserDBNavList',this.UserDBNavList)
    this.getUserAuthStateChange()
    this.GetGuestBU()
    if(this.userIsAdmin || this.userLoggedIn.CanInviteUsers){
      this.GetSystemSecurityRoles('BUsecurityroles')
      this.GetSystemSecurityRoles('Customsecurityroles')
      this.GetSystemSecurityRoles('RMsecurityroles')
    }
    this.CurrentDB = db
    //console.log('this.CurrentDB',this.CurrentDB)
  },

  methods: {
    
    TriggerDashboardbyNotification(){
      //this.CurrentEntity
        let payload = {
            ActiveSessionid: this.CurrentEntity.topicid,
            IsLiaison: true,  
          }
          if(this.$route.params.id){
            payload.ActiveRecord = this.$route.params.id
          }
          this.$emit('TriggerDashboardbyNotification',payload)
    },
    AssignSocialItemInteractMethod(item,SecondProp){
      //console.log('AssignSocialItemInteractMethod',item,SecondProp)
      this.$store.commit('SetSocialItemInteractMethodProp','') 
      this.$store.commit('SetSocialItemInteractMethod','')
      if(SecondProp){
        this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
      else if(item){
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
    },
    ToggleAppMode(mode){
      if(mode.Name === 'Community'){
        this.IntranetViewToggle(true) 
      }
      else{
        this.$store.commit('SetAppMode',mode)
      }
    },
    ToggleNewTaskDialog(){
      this.NewTaskDialog = !this.NewTaskDialog
    },
    ToggleStopWatchDialog(){
      this.StopWatchDialog = !this.StopWatchDialog
    },
    SelectBottomNavMenuItem(item){
      if(!item.Children && !item.Method && !item.RouteBlank){
        this.$router.push(item.Path)
      }
      else if(item.Method){
        if(item.Method === 'IntranetViewToggle' && item.Prop === true){
          this[item.Method](item.Prop,true)
        }
        else if(item.SecondProp){
          this[item.Method](item.Prop,item.SecondProp)
        }
        else{
          this[item.Method](item.Prop)
        }
      }
    },
    UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
    CancelStopWatch(){
      this.StopWatchDialog = false
    },
    FixDefaultfields(){
      let entitieslength = this.SystemEntities.length
      let fieldlength = this.DefaultFields.length
      if(this.NeedsRASync){
        this.SystemEntities.map((entity,entityindex) => {
          this.DefaultFields.map((field,fieldindex) => {
            this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(entity.id).collection('AllFields').doc(field.Name).set(field).then(rafielddoc => {
              db.collection('Databases').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(entity.id).collection('AllFields').doc(field.Name).set(field).then(clientdbfielddoc => {
                if(entityindex-1+2 === entitieslength && fieldindex-1+2 === fieldlength){
                  this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                    DefaultFieldsFixed: true
                  })
                  db.collection('Databases').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                    DefaultFieldsFixed: true
                  })
                }
              })
            })
          })          
        })        
      }
      else{
       this.SystemEntities.map((entity,entityindex) => {
          this.DefaultFields.map((field,fieldindex) => {
              db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(entity.id).collection('AllFields').doc(field.Name).set(field).then(clientdbfielddoc => {
                if(entityindex-1+2 === entitieslength && fieldindex-1+2 === fieldlength){
                  db.collection('DataBases').doc(this.$store.state.ActiveSuiteid).update({
                    DefaultFieldsFixed: true
                  })
                }
              })
          })          
        })     
      }
    },
    UpdateTaskDescription(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(prop,value,AdditionalSaveMethod,FieldObject)     
    },
    disablePastDates(val) {
       return val >= new Date().toISOString().substr(0, 10)
    },
    CloseTaskDialog(){
      this.NewTask = Object.assign({}, this.DefaultNewTask)
      this.NewTaskDialog = false
      this.$emit('SetActivityRegarding')
    },
    CreateNewTask(){
      const TasktoAdd = {
            Comments: [],
            Subject: this.NewTask.Subject,
            Description: this.NewTask.Description,
            Type: this.NewTask.Type,
            Due_Date: new Date(this.NewTask.Due_Date),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Created_On: new Date(),
            Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Modified_Byid: this.userLoggedIn.id,
            Modified_On: new Date(),
            Status: this.ActivityStatusField.DefaultOption,
            Status_Reason: this.ActivityStatusField.DefaultLevel2Option,
            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}]
      }
      if(this.NewTask.Regarding_Type){
        TasktoAdd.Regarding_Type = this.NewTask.Regarding_Type
      }
      if(this.NewTask.Regarding_Topic){
        TasktoAdd.Regarding_Topic = this.NewTask.Regarding_Topic
      }
      if(this.NewTask.Regarding_Record){
        TasktoAdd.Regarding_Record = this.NewTask.Regarding_Record
      }
      let FollowingUserIDStrings = []
      let FollowingUserIDKeys = {}
       TasktoAdd.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       TasktoAdd.FollowingUserIDKeys = FollowingUserIDKeys
       TasktoAdd.FollowingUserIDStrings = FollowingUserIDStrings
      if(this.NewTask.QuickLink){
        TasktoAdd.QuickLink = this.NewTask.QuickLink
      }
      if(this.NewTask.Has_Reminder){
        TasktoAdd.Has_Reminder = this.NewTask.Has_Reminder
      }
      if(this.NewTask.Snoozed_Until){
        TasktoAdd.Snoozed_At = new Date()
        TasktoAdd.Snoozed_Until = new Date(this.NewTask.Snoozed_Until)
      }
      if(typeof this.NewTask.Owner.Business_Unit !== 'undefined'){
        TasktoAdd.Business_Unitid = this.NewTask.Owner.Business_Unit.id
        TasktoAdd.Business_Unit = {Name: this.NewTask.Owner.Business_Unit.Name, id: this.NewTask.Owner.Business_Unit.id}
      }
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(TasktoAdd).then(doc => {
        console.log(TasktoAdd)

             if(TasktoAdd.Type.Name === 'Task' && TasktoAdd.Ownerid !== TasktoAdd.Created_Byid){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Subject,
              Content: TasktoAdd.Description,
              CreatorFullName: TasktoAdd.Created_By.Full_Name,
              CreatorID: TasktoAdd.Created_Byid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/'+doc.id,
              Message: 'a New Task has been assigned to you by '+TasktoAdd.Created_By.Full_Name
            }
            if(TasktoAdd.Regarding_Topic){
              NewNotification.topicid = TasktoAdd.Regarding_Topic
            }
            if(TasktoAdd.Regarding_Record){
              NewNotification.clientrecords = TasktoAdd.Regarding_Record.id
            }
            //console.log(TasktoAdd)
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {
              console.log('notedoc',notedoc)
            })
             }


          this.CloseTaskDialog()
          })
    },
    GetSystemSecurityRoles(collection){ 
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection(collection).onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this[collection].push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
        })
      })
    },
    ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
    GetGuestBU(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc('Guest').onSnapshot(snapshot => {
        let budata = snapshot.data()
        this.InviteUserBU = budata
        this.InviteUserBU.id = 'Guest'
        this.GuestBusinessUnitArray.push(budata)
      })
    },
    SendInvitetoUser(){
      if(this.InviteUserisAdmin){
        confirm('Are you sure you want to create the User with Administrative permissions? It would give the user access to everything, including adding and removing administrators. Click okay to continue') && this.ProcessInvitetoUser()
      }
      else{
        this.ProcessInvitetoUser()
      }
    },
    ProcessInvitetoUser(){
      //console.log('will invite user on '+this.InvitingUserEmail)
      let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc()
      let newdocid = ref.id
      let NewUserObj = {
        Email: this.InvitingUserEmail,
        Status: {
          ID: 1000001,
          Name: 'Sent'
        },
        Business_Unit: {
          id: this.InviteUserBU.id,
          Name: this.InviteUserBU.Name
        },
        Business_Unitid: this.InviteUserBU.id,
        Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
        Created_Byid: this.UserRecord.id,
        Created_On: new Date(),
        IsGuestUser: this.IsGuestUser
      }
      NewUserObj.Expired = false
      if(this.PrimaryApp){
        NewUserObj.Primary_Appid = this.PrimaryApp
      }
      if(this.InviteUserTableFilters){
        NewUserObj.UserTableFilters = this.InviteUserTableFilters
      }
      if(this.InvitingUserRoles && this.InvitingUserRoles.length > 0){
        NewUserObj.rolesarrayQuery = []
        NewUserObj.rolesarrayDBRules = {}
        this.InvitingUserRoles.map(role => {
          NewUserObj.rolesarrayDBRules[role.split(' ').join('')] = true
          NewUserObj.rolesarrayQuery.push(role)
        })
        
      }
      if(this.InviteUserisAdmin){
        NewUserObj.rolesarrayQuery = ['System Admin']
        NewUserObj.rolesarrayDBRules = {SystemAdmin: true}
      }
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userinvites').doc(newdocid).set(NewUserObj).then(doc => {
        //console.log('created user invite record')
      })

      let path = this.System.ClientAppURL+'/UserInvite/'+newdocid
      this.EmailBody = `Good Day<div><br></div><div>You have been invited to join `+this.System.Name+`. </div><div>Please click on the below link to accept and process your invite.</div><div><br></div>
      <a href="`+path+`">Open your Invite</a><br>
      <div>Please note that this link is PRIVATE and you can NOT share the link with anybody else, lest you leave your pending profile at risk for attacks</div>
      <div>Thank you in advance</div>`
      //console.log(this.EmailBody)
        let EmailObject = {          
          EmailFrom: 'notification@rapidapps.co.za',
          EmailTo: this.InvitingUserEmail,
          EmailSubject: 'Invite to join - '+this.System.Name,
          EmailBody: this.EmailBody,

        }
            //alert('email sending')
            //console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              //console.log(result)
              let snackbarcontent = {
                  snackbartimeout: 4000,
                  snackbartext: 'Invite Sent to  - '+this.InvitingUserEmail,
                  snackbartop: true,
                }
                this.$emit('ActivateSnackbar',true,snackbarcontent)
              this.CancelInvitingUserEmail()
            })
    },
    CancelInvitingUserEmail(){
      this.InviteUserDialog = false
      this.InvitingUserEmail = ''
      this.InviteUserTableFilters = []
      this.PrimaryApp = ''
      // this.InviteUserisAdmin = false
      // this.InviteUserBU = ''
      // this.ModulePath = ''
    },
    AcceptInviteUserDialog(){
      this.InviteUserDialog = true
    },
    OpenDashboardBuilder(){
      let ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').doc()
      let newdocid = ref.id
      this.$router.push('/DashboardBuilder/'+newdocid)
    },
  RemoveFavorite(pathcheck){
      let FavoriteLinks = this.UserRecord.FavoriteLinks
      let FavoriteIndex = FavoriteLinks.indexOf(pathcheck)
      FavoriteLinks.splice(FavoriteIndex,1)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
        FavoriteLinks: FavoriteLinks
      })
    },
    AddPageAsFavorite(){
      let routeitem = this.$router.options.routes.find(obj => obj.name === 'myprofile')
      //console.log("routeitem",routeitem)
      //console.log(this.$route.meta)
      let path = this.$route.path
      if(this.UserRecord.FavoriteLinks){
      let pathcheck = this.UserRecord.FavoriteLinks.find(obj => obj.Path === path)
        if(pathcheck){
          confirm('This item is already a favorite. Would you like to remove it from Favorites?') && this.RemoveFavorite(pathcheck)
        }
        else{
        let display = ''
        
        if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        
        }
      }
      else{
        let display = ''
      if(this.$route.params.id){
          this.RecordName = prompt("Please confirm a name for the favorite", this.CurrentRecordName)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
          
        }
        else if(this.$route.params.slug){
          display = this.$route.params.slug+ '('+path.split('/')[1]+')'
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }
        else{
          display = path.replace('/','')
          this.RecordName = prompt("Please confirm a name for the favorite", display)
          if (this.RecordName != '') {
            this.StoreFavorite(this.RecordName,path)
          }
        }      
      }
      
      // this.UserRecord.FavoriteLinks.find
    },
    StoreFavorite(display,path){
      let FavoriteLinks = []
        if(this.UserRecord.FavoriteLinks){
          FavoriteLinks = this.UserRecord.FavoriteLinks
        } 
        FavoriteLinks.push({
          DisplayName: display,
          Path: path
        })
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
          FavoriteLinks: FavoriteLinks
        })
    },
  ToggleAppQuickStartDialog(){
      if(this.AppQuickStartDialog){
        this.$emit('ToggleAppQuickStartDialog',false)
      }
      else{
         this.$emit('ToggleAppQuickStartDialog',true)
      }
      
    },
  ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
  IntranetViewToggle(){
      //confirm('This will take you to the Intranet version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',true,true)
      },
  CancelSubmitSystemTicket(){
      this.TicketLog = false
    },
      ActivateTicketLog(){
        this.UsersArray = [this.UserRecord]
        this.TicketLog = true
      },
    GetUserNotifications(query){
      query.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.Notifications.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
    },


     NotificationRead(notification){
      if(notification.Read === false){
       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notification.id).update({
        Read: true
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = true
      this.$router.push(notification.Path)

      }

    },
    ViewItem(notification){
      this.$router.push(notification.Path)
    },
    NotificationRead(notification){
      this.$emit('NotificationRead',notification)
    },
    MarkasUnread(notification){
      this.$emit('MarkasUnread',notification)
    },


    ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.$emit('SignoutNotifications')
      this.UserRecord = {}
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
    GetAllUserDashboards(){
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
    },
    GetUserDashboards(query){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.UserDashboards.push({
              ...change.doc.data(),
              id: change.doc.id
            })
            let dashboardsitem = this.items.find(obj => obj.title === 'Dashboards')
        this.UserDashboards.map(dashboard => {
          let dashboarditem = {
            icon: 'mdi-desktop-mac-dashboard',
            title: dashboard.DashboardName,
            to: '/CustomDashboard/'+dashboard.id,
            target: 'self',
            id: dashboard.id,
          }
          let oncheck = dashboardsitem.Children.find(obj => obj.id === dashboarditem.id)
          if(!oncheck){
            dashboardsitem.Children.push(dashboarditem)
          }          
        })
          }
        })
      })
    },
    async getUserAuthStateChange() {
        var vm = this;
         if(this.userLoggedIn){
            vm.GetAllUserDashboards()
        this.UserRecord = this.userLoggedIn
        vm.UserFullName = this.UserRecord.Name+' '+this.UserRecord.Surname
          vm.UserPicture = this.UserRecord.Profile_Photo
          
          
           vm.items = [
          
          {
            icon: 'mdi-desktop-mac-dashboard',
            title: 'Dashboards',
            to: '/Dashboards',
            target: 'self',
            Boolean: 'Dashboards',
            Children: [
               
              
              {
                icon: 'mdi-desktop-mac-dashboard',
                title: 'Dashboard Builder',
                to: '/DashboardBuilder',
                target: 'self',
              },
              ]
          },
          
          {
            icon: 'mdi-sitemap',
            title: 'Pages',
            to: '/Pages',
            target: 'self',
            Children: [
            ]
          },
          {
            icon: 'mdi-library',
            title: 'Libraries',
            to: '/Libraries',
            target: 'self',
            Boolean: 'Libraries',
            Children: [
              
              ]
            },
          {
            icon: 'mdi-database',
            title: 'Records',
            to: '/Records',
            target: 'self',
            Boolean: 'Records',
            Children: [
              ]
            },
            
        ]
        let warehousewip = {
          title: 'Warehouses',
          icon: 'mdi-warehouse',
          target: 'self',
          to: '/Warehouses',
          Boolean: 'Warehouses',
          ItemPass: true,
          Children: vm.WarehousesArray.map(wh => {
              let whobj ={
                  title: wh.Name,
                  icon: 'mdi-warehouse',
                  //or wh check
                  ItemPass: true,
                  target: '',
                  to: '/Warehouse/'+wh.Business_Unitid+'/'+wh.id
                  
              }
              return whobj
          })
      }
      if(vm.SuitehasWarehouse && vm.WarehousesArray.length > 0){
        //alert('special')
        vm.items.push(warehousewip)
      }
        if(vm.userIsAdmin){
          vm.items.push({
            icon: 'mdi-information-outline',
            title: 'System',
            to: '/System',            
            target: 'self',
            Boolean: 'System',
            Children: [
            
              {
              icon: 'mdi-security-network',
              title: 'Security',
              to: '/Security',
              target: 'self',
              Children: [
                
                  {
                  icon: 'mdi-road-variant',
                  title: 'Users',
                  to: '/Users',
                  target: 'self'
                },
                {
                  icon: 'mdi-road-variant',
                  title: 'BusinessUnits',
                  to: '/BusinessUnits',
                  target: 'self'
                },
                 {
                    icon: 'mdi-security-network',
                    title: 'Security Overview',
                    to: '/SecurityOverview',
                    target: 'self'

                      },
                  ]
            },
              ]
            })
        }
      let LibrariesItem = vm.items.find(obj => obj.title === 'Libraries')
      let LibrariesItemIndex = vm.items.indexOf(LibrariesItem)
          if(vm.SystemPhotoLibraries){
            vm.SystemPhotoLibraries.map(item => {
              // item.Children = item.Children.filter(child => {
              //   return vm.$store.state[child.QueryName]
              // })
              let libraryitem = {
                to: '/'+item.DisplayName.split(' ').join(''),
                target: '',
                icon: item.Icon,
                title: item.DisplayName

              }
              vm.items[LibrariesItemIndex].Children.push(libraryitem)
            })
          }

      let RecordsItem = vm.items.find(obj => obj.title === 'Records')
          let RecordItemIndex = vm.items.indexOf(RecordsItem)
          if(vm.System.DBNavbarItems){
            let array = JSON.parse(JSON.stringify(vm.System.DBNavbarItems))
            array.map(item => {
              item.Children = item.Children.filter(child => {
                //console.log(child,vm.$store.state[child.QueryName])
                return vm.$store.state[child.QueryName]
              }).map(child => {
                let entity = this.SystemEntities.find(obj => obj.id === child.title.split(' ').join('_'))
                if(entity){
                  child.icon = entity.Table_Icon
                }
                return child
              })
              vm.items[RecordItemIndex].Children.push(item)
            })
          }
          // let CRMgroupobject = {
          //           icon: 'mdi-office-building',
          //           title: 'CRM',
          //           Children: [
          //             ]
          //         }
          //   vm.items[RecordItemIndex].Children.push(CRMgroupobject)
          //    let CRMitemsobject = vm.items[RecordItemIndex].Children.find(obj => obj.title === 'CRM')
          // let CRMitemsobjectindex = vm.items[RecordItemIndex].Children.indexOf(CRMitemsobject)
          // //now, let us run through SystemEntities and push what we have query on
          // vm.SystemEntities.map(entity => {
          //   let entitysplitname = entity.DisplayName.split(' ').join('')
          //   let entitystorename = entitysplitname+'Array'
          //   if(vm.$store.state[entitystorename]){
          //     let entityitem =  {
          //           icon: entity.dataseticon,
          //           title: entity.DisplayName,
          //           to: '/'+entitysplitname,
          //           target: 'self'
          //           }
          //     vm.items[RecordItemIndex].Children[CRMitemsobjectindex].Children.push(entityitem)
          //   }
          // })

          //let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').where('Ownerid', '==', vm.UserRecord.id)
          //vm.GetUserNotifications(query)
         

          if(typeof this.UserRecord.rolesarrayQuery !== 'undefined'){
          vm.UserAssignedRoles = this.UserRecord.rolesarrayQuery
          
          let AdminTest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
          let EagleTest = vm.UserAssignedRoles.find(obj => obj === 'Eagle View')
                if(typeof AdminTest !== 'undefined'){
                  let SystemItem = vm.items.find(obj => obj.title === 'System')
                  let SystemItemIndex = vm.items.indexOf(SystemItem)
                  let SystemChildrenArray = [
                    {
                    icon: 'mdi-robot',
                    title: 'Data Admin',
                    to: '/DataAdministration',
                    target: 'self',
                    Children: [
                
                     {
                    icon: 'mdi-application-import',
                    title: 'RM Admin',
                    to: '/RMAdministration',
                    target: 'self'

                      },
                  {
                    icon: 'mdi-application-import',
                    title: 'Data Imports',
                    to: '/DataImports',
                    target: 'self'

                      },
                      ]

                      },
                    {
                    icon: 'mdi-help',
                    title: 'Support',
                    to: '/Support',
                    target: 'self',
                    Children: [
                
                      {
                    icon: 'mdi-robot',
                    title: 'ChatbotBuilder',
                    to: '/ChatbotBuilder',
                    target: 'self'

                      },
                   {
                    icon: 'mdi-ticket-confirmation',
                    title: 'System Tickets',
                    to: '/SystemTickets',
                    target: 'self'

                      },
                      {
                    icon: 'mdi-road-variant',
                    title: 'Roadmap',
                    to: '/Roadmap',
                    target: 'self'
                  },
                      ]

                      },
                    {
                    icon: 'mdi-whistle',
                    title: 'CampaignBuilder',
                    to: '/CampaignBuilder',
                    target: 'self'

                      },
                      {
                    icon: 'mdi-satellite-uplink',
                    title: 'Campaigns',
                    to: '/Campaigns',
                    target: 'self'

                      },
                      {
                    icon: 'mdi-satellite-uplink',
                    title: 'Suite Admin',
                    to: '/Getting-Started',
                    target: 'self'

                      },
                    
                    
                  ]
                  vm.items[SystemItemIndex].Children = vm.items[SystemItemIndex].Children.concat(SystemChildrenArray)
                }
                
         }
         }
else {
        //console.log('AuthchangeLoggedOut')
        vm.pushLoggedOutItems

      vm.items = [

        {
          icon: 'mdi-login-variant',
          title: 'Login',
          to: '/Login',
          target: 'self'
        },
      ]

     }
    }

  }

}
</script>

<style>
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */

</style>
    