<template>
  <div :style="PreviewMode || ActiveTemplatechild || MiniView ? 'margin-top: 60px;' : 'margin-top: 60px;margin-left:100px;'">
     <LandingPageSingle v-if="ActiveMarketTemplate && ActiveTemplatechild && PreviewMode && !ActiveSuiteTemplate || ActiveTemplatechild && PreviewMode && ActiveSuiteTemplate"
     :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities"
        :SitePages="SitePages" :style="TemplatePreviewStyle" @TogglePreviewingMode="TogglePreviewingMode" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
        />
        
    <div v-if="!PreviewMode">
        <v-card-title v-if="ActiveTemplate && !ActiveTemplatechild">
          <v-btn dark outlined color="error" @click="$route.name === 'MarketplaceTemplate' ? RoutetoAll() : CancelTemplateView()">
            <v-icon>mdi-keyboard-return
            </v-icon>
            All My Templates
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn v-if="userIsAdmin" @click="ConfirmDeleteTemplate(ActiveTemplate)" dark outlined color="error">
                <v-icon>mdi-delete-forever
                </v-icon>Delete
            </v-btn>
            <v-btn v-if="RAUserObj && TransfertoRA && !ActiveTemplate.RATemplateid" @click="ConfirmTransfertoRA(ActiveTemplate)" dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Transfer to RA
            </v-btn>  
            <v-btn v-if="RAUserObj && TransfertoRA && ActiveTemplate.RATemplateid" disabled dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Already on RA
            </v-btn>          
        </v-card-title>
        <v-card-title v-if="!ActiveSuiteTemplate">
            <v-btn v-if="ActiveTemplate" @click="TogglePreviewingMode()" dark outlined color="blue" >
                Preview
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn v-if="CanConverttoSuiteTemplate" @click="ActivateSuiteTemplateDialog()" dark outlined  class="RAWebBtnGraddark">
                <v-icon>mdi-cart</v-icon> Suite Template
            </v-btn>
            <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-btn v-if="!ActiveTemplate" v-on="on" outlined>Add Template</v-btn>
                  </template>
                  <v-card tile>
                    <v-card-title class="black white--text">
                        Choose Below
                    </v-card-title>
                    <v-card-text>
                        <v-select return-object v-model="NewTempPrimCat" label="Primary Category" :items="PrimaryCategories" item-text="Name"/>
                        <v-select return-object v-model="NewTempSecCat" label="Secondary Category" :items="SecondaryCategories" item-text="Name"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn dark outlined color="warning">Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="ActivateMarketTemplateDialog()" dark outlined color="success">Process</v-btn>
                    </v-card-actions>
                  </v-card>  	
                </v-menu>
        </v-card-title>
    </div>
    <v-layout row class="justify-center background" v-if="!Refreshing && ActiveTemplate && !ActiveTemplatechild && !PreviewMode">
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl6 lg6 md5 sm11 xs11">
    <v-card elevation="0" class="siteconfigcard">
        <v-card-title>
           <v-text-field v-model="ActiveTemplate.Name" label="Name"/>
        </v-card-title>
        <v-card-subtitle>
            
        <ContentEditableField style="padding:15px;"
                :FieldObject="ActiveTemplate" :FieldName="'Description'"
                :FieldValue="ActiveTemplate.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-subtitle>
         <v-card-text>
            <v-switch v-if="userIsAdmin" :label="'As '+System.Name" v-model="ActiveTemplate.As_Platform" />
            <v-switch v-if="userIsAdmin" label="Featured" v-model="ActiveTemplate.Featured" append-icon="mdi-star-outline" />
            <v-text-field label="Price" v-model.number="ActiveTemplate.Price" />
          Your Portion  {{CurrencyFormatter(PayoutPortion,$store.state.DefaultCurrency.Currency)}}
            <v-select label="Publish Status" :items="['Draft','Published']" v-model="ActiveTemplate.PublishStatus" />
        </v-card-text>
       <v-card-text>			
               <v-img class="black" height="200" contain v-if="!SampleCoverIMG && ActiveTemplate.Picture"									
                    :src="ActiveTemplate.Picture"									
                    >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>									
                    <v-img height="200" contain v-if="SampleCoverIMG"									
                    :src="SampleCoverIMG"									
                    >
                   <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>										
                    <v-img height="200" contain v-if="!ActiveTemplate.Picture && !SampleCoverIMG"									
                    src="@/assets/ImageHolder.png"									
                   >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>									
                <v-btn  outlined color="sucess" v-if="SampleCoverIMG" @click="SaveSampleCoverIMG()">									
                    Save Picture									
                </v-btn>									
            </v-card-text>	
         <v-btn @click="UpdateMarketTemplate()">									
                    Save									
                </v-btn>
    </v-card>    
    </div>
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl6 lg6 md7 sm11 xs11" v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site'">
    <v-card elevation="0" class="siteconfigcard">
        <v-list v-if="!ActiveTemplatechild">
            <v-card-subtitle class="justify-end mediumoverline">
                Pages
                    <v-btn right absolute outlined @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-btn>
            </v-card-subtitle>
            <v-list-item v-if="SocialNetworkTemplate">
                <v-select :items="RoutedPages" @change="UpdateTemplateSocialLandingPage()"
                v-model="ActiveTemplate.TemplateProps.Social_Landing_Page" label="Landing Page"
                />
            </v-list-item>
             <v-list-item v-if="GroupSiteTemplate">
                <v-select item-text="Name" :items="RoutedGroupPages" @change="UpdateTemplateGroupLandingPage()"
                v-model="ActiveTemplate.TemplateProps.DefaultPage" return-object label="Default Page"
                />
            </v-list-item>
            <v-list-item @click="ActivateTemplateChild(child)" class="detailslistoutline" v-for="child in ComputedTemplateChildren" :key="child.itemObjKey">
                {{child.Name}}
            </v-list-item>
        </v-list>
    </v-card>
    <SocialNetworkConfig v-if="ActiveTemplate && ActiveTemplate.Secondary_Category.Name === 'Social Network'"
    :Directories="Directories" :RADB="RADB" style="margin-top: 20px;" :ActiveTemplate="ActiveTemplate"
    :SiteModerators="SiteModerators" :System="ActiveTemplate.TemplateProps" :SystemEntities="SystemEntities"
    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
    />
    
    </div>
    </v-layout>
    <NewLandingPage :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities" style="margin-top: 0px;" :PublicNavbarEditing="PublicNavbarEditing"
    @RouteBack="Refresh" @TogglePreviewingMode="TogglePreviewingMode" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                :SitePages="SitePages"v-if="ActiveMarketTemplate && ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site' && ActiveTemplatechild && !Refreshing && ActiveTemplatechild && !PreviewMode"
                />
       <!-- </v-card-text> -->
                <NewLandingPage :ActiveTemplate="ActiveTemplate" :System="System" :SystemEntities="SystemEntities" style="margin-top: 0px;" :PublicNavbarEditing="PublicNavbarEditing"
                :SitePages="SitePages" v-if="ActiveMarketTemplate && ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Page' && !Refreshing && ActiveTemplatechild && !PreviewMode"
                @RouteBack="Refresh" @TogglePreviewingMode="TogglePreviewingMode" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';	
import ContentEditableField from '@/components/Database/ContentEditableField'	
import SocialNetworkConfig from '@/components/SuiteBuilder/Main/SocialNetworkConfig'
export default {
    props: ['System','SystemEntities','SitePages','RADB','SystemTableFilters','PublicNavbarEditing','SiteModerators','Page'],
    components: {NewLandingPage,LandingPageSingle,ContentEditableField,SocialNetworkConfig},
    data() {
        return {
            PrimaryCategories: [
                {ID:1000001,Name: 'Site'},
                {ID:1000002,Name: 'Page'}
            ],
            SecondaryCategories: [
                {ID: 1000001, Name: 'Website'},
                {ID: 1000002, Name: 'Social Network'},
                {ID: 1000003, Name: 'Group Site'},
                {ID: 1000004, Name: 'Featured Member'}
            ],
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            TransfertoRA: false,
            PreviewingMode: false,
            NewCoverIMG: '',
            SampleCoverIMG: '',
            Refreshing: false,
            ActiveTemplate: '',
            ActiveTemplatechild: '',
            SuiteTemplate: '',
        }
    },	
    computed:{
        SuiteWebsite(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.Website : ''
        },
        PayoutPortion(){
            let price = 0
            let royaltyportion = 0
            if(this.ActiveTemplate && typeof this.ActiveTemplate.Price === 'number'){
                price = this.ActiveTemplate.Price
            }
            if(this.AppMonetizeBU &&  typeof this.System.Base_App_Royalties === 'number' && price > 0){
                royaltyportion = price*(this.System.Base_App_Royalties/100)
            }
            let finalprice = Number(price)-Number(royaltyportion)
            return finalprice
            //AppMonetizeBU
        },
        TemplatePreviewStyle(){
            //social network done propertly from app routing
            return this.SocialNetworkTemplate ? '' : this.AltBar ? 'margin-top: 106px;' : 'margin-top: 236px;'
        },
        BasicSuiteTemplatePass(){
            return this.ActiveTemplate && this.ActiveMarketTemplate && this.ActiveMarketTemplate.Secondary_Category.Name === 'Website' || 
            this.ActiveTemplate && this.ActiveMarketTemplate && this.ActiveMarketTemplate.Secondary_Category.Name === 'Social Network' 
        },
        CanConverttoSuiteTemplate(){
            return this.BasicSuiteTemplatePass && this.userIsAdmin || this.BasicSuiteTemplatePass && this.userLoggedIn.IsSiteCreator
        },
        MarketTemplatesDBRef(){
            return this.SuiteTemplate ? db.collection('suitetemplates').doc(this.SuiteTemplate.id).collection('templates') :
            db.collection('marketplacetemplates')
        },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        AltBar(){
            return this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps.PublicNavbarStyle === 'STD Bar'
        },
        //When viewing a "site" template, viewing for instnace a "page", then the Primary Parent is ActiveMarketTemplate, while the page I am viewing is ActiveMarketTemplateChild
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
        },      
        ActiveMarketTemplateChild(){
            return this.$store.state.ActiveMarketTemplateChild
        },
        SiteTemplate(){
            return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Primary_Category.Name === 'Site'
        },
        WebsiteTemplate(){
            return this.ActiveSuiteTemplate ? this.SuiteWebsite : this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Website'
        },
        SocialNetworkTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Social Network'
        },
        FeaturedMemberTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Group Site'
        },
        PreviewMode(){
            return !this.UserCanEdit || this.UserCanEdit && this.PreviewingMode
        },
        UserCanEdit(){
            return this.ActiveTemplate && this.ActiveTemplate.Ownerid === this.userLoggedIn.id || 
            this.$route.name === 'MyMarketplaceTemplates'
            //for support must add  || this.userIsAdmin once past testing
        },
        BuilderView(){
      return this.$store.state.BuilderView
      },
      NetworkBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
       UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
        RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
        TemplateCols(){
            let cols = 12
            if(this.$vuetify.breakpoint.xl){
                cols = 2
            }
            else if(this.$vuetify.breakpoint.lg){
                cols = 3
            }
            else if(this.$vuetify.breakpoint.md){
                cols = 4
            }
            else if(this.$vuetify.breakpoint.sm){
                cols = 6
            }
            return cols
        },
        RoutedGroupPages(){
            return this.ComputedTemplateChildren.map(item => {
                let itemobj = {
                    Name: item.Name,
                    Type: 'Group Page'
                }
                return itemobj
            })
        },
        RoutedPages(){
            return this.ComputedTemplateChildren.map(page => {
                return '/'+page.Name.split(' ').join('-').split('_').join('-')
            })
        },
        ComputedTemplateChildren(){
            return this.ActiveTemplate && this.ActiveTemplate.Children ? 
            this.MarketplaceTemplates.filter(temp => {
                return this.ActiveTemplate.Children.includes(temp.id)
            }) :
            []
        },
        MarketplaceTemplatePackages(){
            return this.MyMarketplaceTemplates.filter(temp => {
                return !temp.Parentid
            }).map(temp => {
                let temppbj = Object.assign({},temp)
                if(typeof temppbj.Price === 'undefined'){
                    temppbj.Price = 'Free'
                }
                return temppbj
            })
        },
        SuiteTemplateMain(){
        return this.$route.name === 'Suite Template Main'
        },
        SuiteTemplateWebsite(){
        return this.$route.name === 'Suite Template Website'
        },
        SuiteTemplateSocialNetwork(){
        return this.$route.name === 'Suite Template Social Network'
        },
        SuiteTemplateDatabase(){
        return this.$route.name === 'Suite Template Database App'
        },
        SuiteTemplateView(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
        SuiteTemplates(){
        return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
        },
        OtherSuiteTemplates(){
        return this.$store.state.OtherSuiteTemplates
        },
        MySuiteTemplates(){
        return this.$store.state.MySuiteTemplates
        },
        ClientSuiteTemplates(){
        return this.$store.state.ClientSuiteTemplates
        },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        NewSitePageid(){
        return this.$store.state.NewSitePageid
      },
    },
    watch: {
        ActiveSuiteTemplate: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id){
                     this.GetMarketplacetemplate()
                }
            },deep: true
        }, 
        ActiveTemplate: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue){   
                    if(!newvalue.id && this.BuilderView){
                        this.$store.commit('setBuilderView','')
                    }   
                    if(newvalue.id){
                        //this.$store.commit('setActiveMarketTemplate',newvalue)
                        if(newvalue.Primary_Category && newvalue.Primary_Category.Name === 'Site'){
                            this.$store.commit('setActiveTemplateSite',newvalue)
                            this.SetBuilderView()
                        }
                        
                    }         
                    
                    if(newvalue && newvalue.Secondary_Category && newvalue.Secondary_Category.Name === 'Featured Member'){
                        //okay it's a fet member do we have one active?
                        if(!this.ActiveFeaturedMember){
                            //we need to set active member how do we do that?
                            let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.userLoggedIn.id)
                            query.onSnapshot(snapshot => {
                                let FeaturedMember = snapshot.data()
                                FeaturedMember.id = this.userLoggedIn.id
                                FeaturedMember.Query = query
                                console.log('this.FeaturedMember ',FeaturedMember)
                                this.$store.dispatch('GetActiveFeaturedMember',FeaturedMember) 
                                console.log(this.$store.state.ActiveFeaturedMember)
                            })
                        }
                    }
                }
            },deep: true
        },
        // ActiveTemplatechild: {
        //     handler: function(newvalue, oldvalue) {
        //         if(newvalue !== oldvalue){
        //             if(newvalue.id){
        //                 this.$store.commit('setActiveMarketTemplate',newvalue)
        //                 console.log(this.$store.state.ActiveMarketTemplate)
        //             }   
        //         }
        //     },deep: true
        //decided not to because now the ActiveMarketTemplate is parent and could receive nabar items etc!
        // },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['PerformSitePageAddition','RoutetoTemplatePage']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        //console.log('this.Page',this.Page,this.ActiveMarketTemplate,this.$route.params.id)
        if(this.ActiveMarketTemplate && this.$route.params.id === this.ActiveMarketTemplate.id){
            this.ActivateActiveTemplateComponent(this.ActiveMarketTemplate)
            if(this.SuiteTemplateView){
                if(this.Page){
                    //again more tricky gonna dpend on "webb or SNB etc could proporgate but too damn lazy for now"
                    this.RoutetoTemplatePage(this.Page,true)
                }
            }
            else{
                if(this.Page){
                    this.RoutetoTemplatePage(this.Page,true)
                }
            }
        }
        else{
            if(this.SuiteTemplateView){
                this.GetSuiteTemplate()
            }
            else{
                this.GetMarketplacetemplate()
            }
        }
        
    },
    methods:{
        ActivateMultipleFilesUploadNotification(boolean){
            this.$emit('ActivateMultipleFilesUploadNotification',boolean)
        },
        UpdateTemplateGroupLandingPage(){
            //Social_Landing_Page
            this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                TemplateProps: this.ActiveTemplate.TemplateProps
            })
        },
        UpdateTemplateSocialLandingPage(){
            //Social_Landing_Page
            this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                TemplateProps: this.ActiveTemplate.TemplateProps
            })
        },
        ActivateSuiteTemplateDialog(){
            //TEchnically we also need a "push to Suite"
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','ActivateSuiteTemplateDialog')

        },
        RoutetoAll(){
            this.$router.push('/MyMarketplaceTemplates')
            this.CancelTemplateView()
            this.$store.commit('setActiveTemplateSite','')
        },
        RoutetoTemplatePage(page,fromload){
            //console.log(page)
            let child = this.ComputedTemplateChildren.find(obj => obj.id === page.id)
            if(!child){
                child = this.ComputedTemplateChildren.find(obj => obj.Name === page.title)
            }
            //console.log(this.ComputedTemplateChildren,child)
            this.ActiveTemplatechild = ''
            setTimeout(() => {
            this.ActiveTemplatechild = child
            //console.log('so here is Page ',this.Page)
            //console.log('this.Page.id !== child.id?',this.Page.id !== child.id)
            if(fromload){
                this.TogglePreviewingMode(fromload)
            }
            else if(this.Page && this.Page.id !== child.id){
                //we assuming you route to the page in preview mode so yeah
                //stupid right? No change that's all?
            }
            }, 20);
            
            //this.ActiveTemplatechild = child
            //console.log(this.ActiveTemplatechild,this.UserCanEdit,this.PreviewingMode,this.PreviewMode,page,this.ComputedTemplateChildren)
        },
        TogglePublicNavbarEditing(){
            //Just take one note here not sure it's true for SuiteTemplate, we leave as is...but perhaps PreviewingMode and Editing Mode should rather just be one boolean.
            //because if you editing, you not previewing, and if you previewing you not Editing...
            //console.log('TogglePublicNavbarEditing')
            this.$emit('TogglePublicNavbarEditing')
        },
        TogglePreviewingMode(fromload){
            this.PreviewingMode = !this.PreviewingMode
            //console.log('this.PreviewingMode ',this.PreviewingMode )
            if(this.PreviewingMode){
                this.$store.commit('setBuilderView','')
                this.$store.commit('setSiteDialog',false)
                this.TogglePublicNavbarEditing()
                //okay here is another thing. not 1. Check if type, like if website template check ActiveMarketTemplate.TemplateProps.Public_Landing_Page. Get matching form computed children
                //2. If found use that, otherwise child[0] will do
                if(!fromload){
                this.CheckDefaultRoute()
                }
                //still need to set others, think group has def root, feat mem not, because it's "route.id" AND "slug"...
            }
            else{
                this.SetBuilderView()
                this.TogglePublicNavbarEditing()               
                //this.DeactivateTemplateChild()
            }
        },
        CheckDefaultRoute(){
            console.log('all the way to this CheckDefaultRoute',this.WebsiteTemplate)
            let defpageprop = ''
                let defpage = ''
                let defpageroute = ''
                let pagechild = ''
                if(this.WebsiteTemplate){
                    defpageprop = 'Public_Landing_Page'
                }
                else if(this.SocialNetworkTemplate){
                    defpageprop = 'Social_Landing_Page'
                }
                //console.log(defpageprop,this.ActiveMarketTemplate,this.ActiveMarketTemplate.TemplateProps[defpageprop])
                if(defpageprop && this.ActiveTemplate && this.ActiveTemplate.TemplateProps && this.ActiveTemplate.TemplateProps[defpageprop] && !this.ActiveSuiteTemplate){
                    defpageroute = this.ActiveTemplate.TemplateProps[defpageprop].split('/').join('').split('-').join(' ').split('_').join(' ')
                    pagechild = this.ComputedTemplateChildren.find(obj => obj.TemplateObj.Name === defpageroute)
                    if(pagechild){
                        defpage = pagechild.TemplateObj
                    }
                }
                else if(defpageprop && this.WebsiteTemplate[defpageprop] && this.ActiveSuiteTemplate){
                    defpageroute = this.WebsiteTemplate[defpageprop].split('/').join('').split('-').join(' ').split('_').join(' ')
                    pagechild = this.WebsiteTemplate.Pages.find(obj => obj.Name === defpageroute)
                    if(pagechild){
                        defpage = pagechild
                    }
                    console.log(pagechild,defpage)
                }
                if(defpage){
                    this.ActivateTemplateChild(pagechild)
                    this.ActiveTemplatechild = pagechild
                }
                else if(this.ComputedTemplateChildren[0]){
                    this.ActivateTemplateChild(this.ComputedTemplateChildren[0])
                }
                //console.log('this.ActiveTemplatechild',this.ActiveTemplatechild,this.ComputedTemplateChildren)
        },
        PerformSitePageAddition(newpage){
            //console.log(newpage,this.NewSitePageid)
            let children = []
            if(this.ActiveTemplate.Children){
                children = this.ActiveTemplate.Children
            }
            children.push(this.NewSitePageid)
            this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                Children: children
            }).then(updatedparetn => {
                this.$store.commit('setNewPageSite','')
                this.$store.commit('setNewSitePageid','')
                let acttemp = this.ActiveTemplate
                this.ActiveTemplate = ''
                setTimeout(() => {
                    this.ActiveTemplate = acttemp
                }, 50);
            })
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
        AddNewPage(navbar,SubPage,parentindex){
            //console.log(navbar,SubPage,parentindex)
          let type = 'Public'
          if(this.ActiveTemplate.Secondary_Category.Name === 'Social Network'){
            type = 'Members Only'
          }
          this.$store.commit('setNewPageSite',this.ActiveTemplate)
          //console.log(this.$store.state.NewPageSite)
          this.AssignSocialItemInteractMethod('ToggleNewPageDialog',type)
        },
        ActivateTemplateDialog(primcat,seccat){
            console.log(primcat,seccat)
            //the main issue being the route params assigned here, that's it
            //that equates to
            //1.RouteName WTf it means don't even care don't think it matters
            //2. the "array" values below meaning jack shit, if social network, but yes applicable if website or feat mem
            //3.Templateid like ???
            //4. Components for site will be empty
            //was passing primcat,index
            //ComputedSitePages
            //console.log('index',index,this.ComputedNavbarPages[index])
            let ref = this.MarketTemplatesDBRef.doc()
            let Templateid = ref.id
            let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            let systemurl = this.System.ClientAppURL
            let defobj = {
                Templateid: '',
                Systemid: systemid,
                ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                // TemplateProps: {},
                TemplateObj: {},
                Primary_Category: primcat,
                Secondary_Category: seccat
            } 
            let payload = Object.assign({},defobj)
            payload.Primary_Category = primcat
            if(primcat.Name === 'Site'){
                payload.Templateid = Templateid
                //yes because slug is the page
                payload.TemplateProps = {
                    //Children: this.ComputedNavbarPages,
                    //ComputedSitePages but yeah gonna need simply template ids right?
                     //[this.SelectedNavbar.Prop]: this.NavListArray
                     //should cover for all I guess?
                     Navlist: [],
                     NavlistProp: ''
                }
                let array = ['PublicNavbarDark','PublicNavbarStyle','PublicNavbarText','PublicNavbarSelectedText','PrependedPublicNavbarActions','AppendedPublicNavbarActions','Public_NavBar_IMG','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG']
                if(seccat.Name === 'Featured Member'){
                    payload.TemplateProps.NavlistProp = 'MenuItems'
                }
                else if(seccat.Name === 'Website'){
                    payload.TemplateProps.NavlistProp = 'PublicNavbarItems'
                }
                else if(seccat.Name === 'Social Network'){
                    payload.TemplateProps.NavlistProp = 'SocialNavbarItems'
                    array = []
                    //??????
                }
                //IRRELEVANT THERE IS NO SITE!!!

                // array.map(prp => {
                //     if(typeof this.Site[prp] !== 'undefined'){
                //         payload.TemplateProps[prp] = this.Site[prp]
                //     }
                // })
                // payload.Components = this.ComputedNavbarPages.map(page => {
                //     let childtempobj = Object.assign({},defobj)
                //     let childpage = this.ComputedSitePages.find(obj => obj.Name === page.Name)
                //     if(childpage){
                //         childtempobj.Templateid = childpage.id
                //     }
                //     else{
                //         childtempobj.Templateid = page.Name.split(' ').join('_')
                //     }
                //     childtempobj.TemplateObj = page
                //     childtempobj.Icon = page.PageIcon
                //     childtempobj.Name = page.Name
                //     return childtempobj
                // })
                //?
            }
            else{
                Templateid = Templateid
                //yes because id is the member id
                let temppage = this.ComputedNavbarPages[index]
                TemplateObj = {
                    Icon: temppage.PageIcon,
                    //how do we get entire page on here?
                    ...temppage
                }
            }
            this.$store.commit('SetSocialItemInteractMethodProp',payload) 
            this.$store.commit('SetSocialItemInteractMethod','ActivateMarketplaceTemplateDialog')
        },
        ActivateMarketTemplateDialog(){
            this.ActivateTemplateDialog(this.NewTempPrimCat,this.NewTempSecCat)
            this.NewTempPrimCat = ''
            this.NewTempSecCat = ''
        },
        ChangeSampleCoverIMGselected(){
            this.$refs('SampleCoverIMGfile')
        },
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
        // This should be in AppNotifications
        //comes from C:\RANEWApps\DNetGmailAccount\businessandpeople\src\components\SuitePlugins\Blogs\Single.vue
        ActivateTokenPurchaseDialog(){     
        let vm = this
         if(!this.UserMarketAccount && this.AppMonetizeBU){
           vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
           vm.$store.commit('setCustomProcessingDialog',true)
           const functions = firebase.functions();
           const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
           let payload = {
             Data: vm.userLoggedIn,
             AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
           }
           CreateMarketAccount(payload).then(result => {
             console.log(result)
             vm.$store.commit('setCustomProcessingDialogText','Done Creating')
             vm.ProcesstokenPurchase()
           })
         }
         else{
           vm.ProcesstokenPurchase()
         }
        console.log('definitley going to pass app notification push token packages etc. refer EmitMarketDialog()')
        let transaction = {
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          ROE: Number(this.System.Credit_Tokens_ROE),
          Date: new Date(),
          Type: {ID: 1000001, Name: 'User Token Purchase'},
          
        }
        },
         // This should be in AppNotifications
        CancelTemplateView(){
            this.ActiveTemplate = ''
            this.ActiveTemplatechild = ''
            this.TransfertoRA = false
        },
        ConfirmTransfertoRA(ActiveTemplate){
            confirm('Do you want to place this Template into the RapidApps platform marketplace?') && this.ProcessTransfertoRA(ActiveTemplate)
        },
        ProcessTransfertoRA(ActiveTemplate){
             let path = this.RADB.collection('marketplacetemplates')
                let parentref = path.doc()
            let ratemplate = Object.assign({},ActiveTemplate)
            ratemplate.id = parentref.id
             let clientuser = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let user = {Full_Name: this.RAUserObj.Full_Name,id: this.RAUserObj.id}
            let date = new Date()
             let children = this.MarketplaceTemplates.filter(temp => {
                    return ActiveTemplate.Children.includes(temp.id)
                }).map(child => {
                    let childref = path.doc()
                    let childobj = Object.assign({},child)
                    childobj.ClientSuiteTemplateid = childobj.id
                    childobj.ClientOwner = clientuser
                    childobj.ClientOwnerid = clientuser.id
                    childobj.Owner = user          
                    childobj.Ownerid = user.id
                    childobj.Created_By = user     
                    childobj.Created_Byid = user.id
                    childobj.Modified_By = user         
                    childobj.Modified_Byid = user.id
                    childobj.Created_On = date
                    childobj.Modified_On = date
                    childobj.id = childref.id
                    childobj.Original_Parentid = childobj.Parentid
                    childobj.Parentid = ratemplate.id
                    return childobj
                })
            let total = children.length
            let running = 0
            ratemplate.ClientSuiteTemplateid = ratemplate.id
            ratemplate.ClientOwner = clientuser
            ratemplate.ClientOwnerid = clientuser.id
            ratemplate.Owner = user          
            ratemplate.Ownerid = user.id
            ratemplate.Created_By = user     
            ratemplate.Created_Byid = user.id
            ratemplate.Modified_By = user         
            ratemplate.Modified_Byid = user.id
            ratemplate.Created_On = date
            ratemplate.Modified_On = date
            ratemplate.Original_Children = ratemplate.Children
            ratemplate.Children = children.map(child => {
                return child.id
            })
            if(children.length > 0){
                children.map(child => {
                path.doc(child.id).set(child).then(childnew => {
                        running++
                        if(running === total){
                            path.doc(ratemplate.id).set(ratemplate).then(parentnew => {
                                this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                                    RATemplateid: ratemplate.id
                                }).then(updateddoc => {
                                  this.CancelTemplateView()  
                                })                                
                            })
                        }
                    })
                })
            }
            else{
                path.doc(ratemplate.id).set(ratemplate).then(parentnew => {
                    this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update({
                        RATemplateid: ratemplate.id
                    }).then(updateddoc => {
                        this.CancelTemplateView()  
                    })
                })
            }

        },
        ConfirmDeleteTemplate(ActiveTemplate){
            confirm('This will delete the Template forever and there is no undo, are you sure?') && this.ProcessDeleteTemplate(ActiveTemplate)
        },
        ProcessDeleteTemplate(ActiveTemplate){
            if(!ActiveTemplate.Children){
            this.MarketTemplatesDBRef.doc(ActiveTemplate.id).delete().then(deldoc => {
                this.CancelTemplateView()
            })
            
            }
            else{
                let children = this.MarketplaceTemplates.filter(temp => {
                    return ActiveTemplate.Children.includes(temp.id)
                })
                let total = children.length
                let running = 0
                children.map(child => {
                    this.MarketTemplatesDBRef.doc(child.id).delete().then(childdel => {
                        running++
                        if(running === total){
                            this.MarketTemplatesDBRef.doc(ActiveTemplate.id).delete().then(deldoc => {
                                this.CancelTemplateView()
                            })
                        }
                    })                    
                })
            }
        },
        SetBuilderView(){
            if(this.UserCanEdit){
                //for support must add  || this.userIsAdmin once past testing
                this.$store.commit('setBuilderView',{Name: 'Marketplace Site'})
            }                
        },
        ActivateActiveTemplateComponent(temp,toRA){
        this.ActiveTemplate = temp
        this.TransfertoRA = toRA
        if(this.$route.name === 'MyMarketplaceTemplates'){
            if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                this.SetBuilderView()
                
            }
            else if(this.BuilderView){
                this.$store.commit('setBuilderView','')
            }
        }
      },
        SaveSampleCoverIMG(){									
            if(this.NewCoverIMG){		
                this.$emit('ActivateProcessing',true)								
                let vm = this                									
                var storageRef = 'UserAssets/'+vm.ActiveTemplate.Ownerid+'/Assets/'+new Date()+'/'+vm.NewCoverIMG.name									
                vm.PrepareSampleCoverThumbnail(vm.NewCoverIMG.name,vm.NewCoverIMG,storageRef)									
                									
            }									
        },
        PrepareSampleCoverThumbnail(filename,file,storageref){									
        let vm = this									
        return new Promise(function(resolve, reject) {									
        file.tmpsrc = URL.createObjectURL(file)									
              //this.OutboundPhotosforUpload.push(file)									
              var img = document.createElement("IMG");									
                img.setAttribute('width',150)									
                img.setAttribute('src',file.tmpsrc)									
                document.body.appendChild(img)									
                									
                img.onload = function() {									
                var c = document.createElement("canvas");									
                var ctx = c.getContext("2d");									
                var canvasRatio = img.naturalHeight / img.naturalWidth									
                c.width = 400									
                c.height = c.width*canvasRatio									
                ctx.drawImage(img, 0, 0, c.width, c.height);									
                c.toBlob(blob => {									
                  let blobfile = new File([blob], filename, {									
              type: file.type,									
            });									
            //console.log(blobfile)									
            var storageRef = firebase.storage().ref(storageref+'_thumb')									
                document.body.removeChild(img)									
                var uploadTask = storageRef.put(blobfile)									
                uploadTask									
                  .then(snapshot => snapshot.ref.getDownloadURL())									
                    .then((thumburl) => {									
                      //console.log(thumburl)	
                      vm.$emit('ActivateProcessing',false)									
                      vm.ActiveTemplate.Picture = thumburl	
                      vm.SampleCoverIMG = ''
                      vm.NewCoverIMG = ''								
                      //vm.CancelEditTestimony()									
                    })									
              })									
                }									
        })									
      },	
        NewSaveSampleCoverIMGselected(event){								
            this.NewCoverIMG = event.target.files[0]
            console.log('this.NewCoverIMG',this.NewCoverIMG)									
            this.SampleCoverIMG = URL.createObjectURL(this.NewCoverIMG)									
        },		
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
        UpdateMarketTemplate(){
            let updateobj = {
                PublishStatus: this.ActiveTemplate.PublishStatus,
                Name: this.ActiveTemplate.Name,
                Description: this.ActiveTemplate.Description,
                BillingAccount: {Client_Reference: this.UserMarketAccount.Client_Reference, id: this.UserMarketAccount.id},
                BillingAccountid: this.UserMarketAccount.id
            }
            if(this.ActiveTemplate.Picture){
                updateobj.Picture = this.ActiveTemplate.Picture
            }
            if(typeof this.ActiveTemplate.Price !== 'undefined'){
                updateobj.Price = this.ActiveTemplate.Price
            }
            if(typeof this.ActiveTemplate.As_Platform !== 'undefined'){
                updateobj.As_Platform = this.ActiveTemplate.As_Platform
            }
            if(typeof this.ActiveTemplate.Featured !== 'undefined'){
                updateobj.Featured = this.ActiveTemplate.Featured
            }
            
            this.MarketTemplatesDBRef.doc(this.ActiveTemplate.id).update(updateobj).then(updateddoc => {
                if(this.ComputedTemplateChildren.length > 0){
                    this.ComputedTemplateChildren.map(childtemp => {
                        this.MarketTemplatesDBRef.doc(childtemp.id).update({
                            PublishStatus: this.ActiveTemplate.PublishStatus,
                        })
                    })
                }
            })
            //and really some snackbar inbetween and shit
        },
        ActivateTemplateChild(child){
            console.log(child)
            this.ActiveTemplatechild = child
            //okay so if Guest, then activate the Builderappbarview...
        },
        DeactivateTemplateChild(){
            this.ActiveTemplatechild = ''
        },
        Refresh(){
            if(this.ActiveSuiteTemplate){
                this.$router.push('/Your-Domain/'+this.$route.params.id+'/Main')
            }
            else{
             this.Refreshing = true
            if(this.ActiveTemplatechild){
                this.ActiveTemplatechild = ''
            }
            else{
                
            }
            setTimeout(() => {
                this.Refreshing = false
            }, 300);   
            }
            
        },
        // RoutetoTemplateComponentItem(item){
        //     //this is going to be hella difficult!!!!
        //     //essentially now I need to edit the item "as is"
        //     //with the first case being featuredmembereditot, this is what I need to do...
        //     //challenge being...there is no route I can use
        //     //if I used FeaturedMember Editor it won't work because the item won't exist, cannot be called on, cannot push an entire page
        //     //additionally, this route is now unique, what route would I even use right?
        //     //Do I need to get a "page preview" route now? And now what happens with things like data table, would I get sample routes for that as well?
        //     //did we even think this through?
        //     //No of course we clearly did not think this through. 
        //     //nothing is going to render unless we undertake a MAMMOTH exercise to get everything "preview ready"
        //     //Relax...what if we used landingpagesingle? or Newlandingpage?
        //     this.ActiveTemplateComponent = item
        // },
        GetSuiteTemplate(){
            this.SuiteTemplate = this.SuiteTemplates.find(obj => obj.id === this.$route.params.id)
            console.log('this.SuiteTemplate',this.SuiteTemplate)
            if(this.ActiveSuiteTemplate){
                console.log('this.ActiveSuiteTemplate',this.ActiveSuiteTemplate)
                //alert('thought')
                this.GetMarketplacetemplate()
            }
        },
        GetMarketplacetemplate(){
            let template = ''
            ''
            if(this.SuiteTemplateView){
            let cat = this.SuiteTemplateWebsite ? 'Website' : this.SuiteTemplateSocialNetwork ? 'SocialNetwork' : this.SuiteTemplateDatabase ? 'Suite App' : ''  
            template = this.ActiveSuiteTemplate[cat]
            }
            else{
                template = this.MarketplaceTemplates.find(obj => obj.id === this.$route.params.id)
            }
            if(!template){
                 if(!this.userLoggedIn){
                this.$router.push('/404')  
                }
                else{
                    //Just to emphasize here is the problem. If dude don't have access sure send him 4040, but maybe he has access it just needs to be called still, as we only checking from entire array.
                    //Problem is this ain't the only route calling it. need to do from ApppluginsRoutes
                this.$router.push('/404')
                } 
            }
            else{
                
                //undid if(template.Ownerid !== this.userLoggedIn.id && !this.userIsAdmin){ as admin also get to view preview mode first right
                if(template.Ownerid !== this.userLoggedIn.id){
                    //this.$router.push('/PermissionError')
                    this.ActiveTemplate = template
                    //I know this is not right but give me a second
                    this.PreviewingMode = true
                    this.CheckDefaultRoute()  
                }
                else{
                    //is owner
                 this.ActiveTemplate = template   
                 if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                    this.SetBuilderView()
                    this.$store.commit('setActiveTemplateSite',template)    
                    this.CheckDefaultRoute()   
                                
                }
                }
                
            }
        }
    }
}
</script>

<style>

</style>



