<template>
  <div :style="PDFBuilderActive ? '' : 'margin-top:50px;'">
    <v-list v-if="!PDFBuilderActive">
        <v-btn outlined @click="CreateExportTemplate()">Create Export Template</v-btn>
        <v-list-item>
            <v-list-item-content>
                Name
            </v-list-item-content>
            <v-list-item-content>
                Page Count
            </v-list-item-content>
             <v-list-item-action>
                View
             </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="func in PDFFunctions" :key="func.itemObjKey">
            <v-list-item-content>
                <v-list-item-title>
            {{func.FunctionDisplayName}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{func.FunctionEntity}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="func.FunctionOutput">
                    {{func.FunctionOutput.DisplayName}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
                {{func.Pages.length}}
            </v-list-item-content>
            <v-list-item-action>
                <v-btn @click="ActivateFunctionBuilder(func.id)">View</v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
    <!-- PDFBuilderActive {{PDFBuilderActive}} NewFunctionID {{NewFunctionID}} -->
    <PDFExportFunctionBuilder :System="System" :SystemEntities="SystemEntities"
    :CurrentEntity="CurrentEntity" :NewFunctionID="NewFunctionID" v-if="PDFBuilderActive"
    />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PDFExportFunctionBuilder from '@/components/SuitePlugins/PDFBuilder/PDFExportFunctionBuilder'
export default {
    props: ['System','SystemEntities','CurrentEntity'],
    components: {PDFExportFunctionBuilder},
    data() {
        return {
            PDFFunctions: [],
            NewFunctionID: ''
        }
    },
    computed:{
        FunctionsRef(){
      return this.$store.state.ActiveSuiteid !== process.env.VUE_APP_RA_SYSTEM_ID ? 
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions') : 
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions')
    },
        PDFBuilderActive(){
            return this.$store.state.PDFBuilderActive
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
    },
    created(){
        if(!this.userIsAdmin){
            //reroute
        }
        else{
          this.GetPDFExportFunctions()
        }
        
    },
    methods:{
        CreateExportTemplate(){
        let ref = this.FunctionsRef.doc()
        if(this.$store.state.ActiveSuiteid !== process.env.VUE_APP_RA_SYSTEM_ID){
            ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc()
        }
        let newdocid = ref.id
        //this.$router.push('/PDFExportFunctionBuilder/'+newdocid)
        this.ActivateFunctionBuilder(newdocid)
    },
    ActivateFunctionBuilder(docid){
        this.NewFunctionID = docid
        this.$store.commit('setPDFBuilderActive',true)
    },
       GetPDFExportFunctions(){
            this.FunctionsRef.onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                          let buobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        this.PDFFunctions.push(buobj)                          
                        }
                    }) 
            })
       }
    }
}
</script>

<style>

</style>
