
<template>
 <v-img
    class="" @click="Editing? ActivateElementConfigComp('ImageConfiguringComponent',tab,i): ''"
    :id="tab.ElementID+'_IMG'"
    v-if="tab.IMG && TabReady"
    :style="IMGStyle(tab)"
    :contain="!tab.Clipped && !tab.DisableContain"
    :height="tab.Height"
    :src="tab[IMGProp] ? tab[IMGProp] : require('@/assets/ImageHolder.png')"
  >
    <v-overlay
      v-if="IMGGradient"
      class="gradoverlay"
      color="#ffffff00"
      absolute
      :style="tab.ParallaxGradient"
    >
    </v-overlay>
  </v-img>

</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";

export default {
  props: [
    "Editing",
    "AppisDarkMode",
    "tab",
    "PageHeadersFont",
    "PageContentFont",
    "PageHeadersFontSize",
    "i",
    "IsElement",
    "tabindex",
    "rowindex",
    "IMGGradient",
    "IMGProp",
  ],
  components: {},
  data() {
    return {
      TabReady: false,
      UserRecord: "",
      rules: {
        youtubeurl: (value) => {
          const pattern =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
          return pattern.test(value) || "Not a Youtube Video Link.";
        },
        min8Chars: (value) => value.length >= 8 || "Min. 8 characters",
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const urlpattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          );
          return urlpattern.test(value) || "Invalid Link.";
        },
      },
    };
  },
  computed: {
    
    MiniView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    DefaultPageFont() {
      return `font-family: "` + this.PageContentFont + `", sans-serif;`;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
  },
  created() {
    //this.fetchRSS()
    this.CheckifParallaxClipped(this.tab);
  },

  methods: {
    ActivateElementConfigComp(compname,tab,i){
      this.$emit('ActivateElementConfigComp',compname,tab,i)
    },
    IMGStyle(tab) {
      let style =
        "margin-top:" +
        tab.MarginTop +
        "px;margin-bottom:" +
        tab.MarginBottom +
        "px;margin-left:" +
        tab.MarginLeft +
        "px;margin-right:" +
        tab.MarginRight +
        "px;"
      if (tab.IMGBlendMode) {
        style = "mix-blend-mode: " + tab.IMGBlendMode + ";";
      }
      if (tab.AnimationCSS && tab.AnimationCSS.IMG && tab.Animations.IMG && tab.AnimationsActive) {
        style = style + tab.AnimationCSS.IMG;
      }
      
        if(tab.IMGCustomStyle){
          style = style+tab.IMGCustomStyle
        }
      style = style+this.CheckifClipped(tab)
      //console.log(style)
      return style
    },
    GetBlob(asset){
            let vm = this
        // console.log(asset)
        return new Promise(function(resolve, reject) {
            
        var img = document.createElement("IMG");
        img.setAttribute('crossOrigin','anonymous')
        img.setAttribute('src',asset)
        document.body.appendChild(img)
        img.onload = function() {
          //console.log(img.naturalWidth)
        var c = document.createElement("canvas");
        c.setAttribute('crossOrigin','anonymous')
        var ctx = c.getContext("2d");
        c.width = img.naturalWidth
        c.height = img.naturalHeight
        ctx.drawImage(img, 0, 0);
        var dataURL = c.toDataURL("image/png");
        //console.log('dataURL',dataURL)
        document.body.removeChild(img)
        resolve(dataURL)
        //vm.TabReady = true
        //.replace(/^data:image\/(png|jpg);base64,/, "");    
        }
        })
        },
    CheckifParallaxClipped(tab) {
      //console.log(tab)
      if (tab.Clipped && tab.ClippingIMG) {
        this.GetBlob(tab.ClippingIMG).then((result) => {
          tab.ClippingStyle =
            `-webkit-mask-image: url(` +
            result +
            `);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`;
          // was -webkit-mask-size: auto;
          // console.log('clippingstyle',clippingstyle)
          //console.log(tab)
          this.TabReady = true;
        });
      } else {
        this.TabReady = true;
      }
    },
    CheckifClipped(tab) {
      if (tab.Clipped) {
        if (tab.ClipAnimated) {
          this.TabReady = true;
          if (tab.ClipAnimation === "Spotlight Search") {
            return (
              `animation: spotlightsearch 3s ` + tab.CircleAnimationRepeat + `;`
            );
          }
        } else if (tab.ClippedbyPath) {
          this.TabReady = true;
          if (tab.ClippedPathOption) {
            if (tab.ClippedPathOption === "elipse") {
              return (
                `clip-path: ellipse(` +
                tab.ElipseX +
                ` ` +
                tab.EliseY +
                ` at ` +
                tab.ElipseXPosition +
                ` ` +
                tab.ElipseYPosition +
                `);`
              );
            }
            if (tab.ClippedPathOption === "circle") {
              return (
                `clip-path: circle(` +
                tab.CircleRadius +
                ` at ` +
                tab.CircleXPosition +
                ` ` +
                tab.CircleYPosition +
                `);`
              );
            }
            if (tab.ClippedPathOption === "polygon") {
              return (
                `clip-path: polygon(` +
                tab.TopLeftCrnrX +
                ` ` +
                tab.TopLeftCrnrY +
                `, ` +
                tab.TopRightCrnrX +
                ` ` +
                tab.TopRightCrnrY +
                `, ` +
                tab.BottomRightCrnrX +
                ` ` +
                tab.BottomRightCrnrY +
                `, ` +
                tab.BottomLeftCrnrX +
                ` ` +
                tab.BottomLeftCrnrY +
                `);`
              );
            }
            // if(tab.ClippedPathOption === 'beizer'){
            //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);`
            //     d="M 0,1
            //          L 0,0
            //          L 1,0
            //          L 1,1
            //          C .65 .8, .35 .8, 0 1
            //          Z"
            // }
          }
        } else if (tab.ClippingIMG) {
          return tab.ClippingStyle;
        }
      } else {
        this.TabReady = true;
      }
    },
  },
};
</script>

<style>
</style>

        

    