<template>
  <div>
    <!-- <v-list-item>
        <v-btn outlined @click="AcceptInviteUserDialog()">
            Invite User
        </v-btn>
    </v-list-item> -->
    <v-list-item v-if="ComputedBillingAccounts.length === 0">
        There are no Billing Accounts
    </v-list-item>
       <v-list-item dense v-for="ba in ComputedBillingAccounts" :key="ba.itemObjKey" >
        <!-- <v-list-item-icon>
        <v-icon small>mdi-blogger</v-icon>
        </v-list-item-icon> -->
        <v-list-item-avatar size="40" class="mx-2">
            <v-img contain v-if="ba.UserProfileIMG" :src="ba.UserProfileIMG"/>
            <v-img contain v-if="!ba.UserProfileIMG" src="@/assets/ImageHolder.png"/>
        </v-list-item-avatar>
        <v-list-item-content>            
            <v-list-item-title :class="ba.class">{{ ba.Client_Reference }}</v-list-item-title>            
            <v-list-item-subtitle :class="ba.class">{{ba.id}}</v-list-item-subtitle>
             <v-list-item-subtitle :class="ba.class">({{ ba.User.Full_Name }})</v-list-item-subtitle>
            <v-list-item-subtitle v-if="ba.Company" :class="ba.class"><v-chip dark color="blue" x-small>{{ ba.Company.Company_Name }}</v-chip></v-list-item-subtitle>
        </v-list-item-content>
        <!-- <v-list-item-action>
            {{CurrencyFormatter(ba.Total_Invoiced,$store.state.DefaultCurrency.Currency)}}
        </v-list-item-action>
         <v-list-item-action>
            {{CurrencyFormatter(ba.Total_Paid,$store.state.DefaultCurrency.Currency)}}
         </v-list-item-action>
         <v-list-item-action-text>
            {{CurrencyFormatter(ba.Outstanding_Amount,$store.state.DefaultCurrency.Currency)}}
         </v-list-item-action-text> -->
          <v-list-item-action style="font-size: 1.4em;font-weight: 300;">
            {{CurrencyFormatter(ba.Outstanding_Amount,$store.state.DefaultCurrency.Currency)}}
         </v-list-item-action>
        <!-- <v-list-item-content>
            <v-switch dense v-model="ba.IsGuestUser" label="Guest User" disabled />
            
        </v-list-item-content> -->
        <!-- <v-list-item-action>
            <v-btn x-small @click="CopyInviteToClipBoard('/BillingAccount/'+ba.id)">Copy Invite</v-btn>
        </v-list-item-action>
        <v-list-item-action>
            <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y :disabled="ba.StatusDisabled"
              width="200px"
              >
                <template v-slot:activator="{ on }">
            <v-chip dark v-on="on" :color="ba.StatusColor"><v-icon>{{ba.StatusIcon}}</v-icon>{{ba.Status ? ba.Status.Name : ''}}</v-chip>
                </template>
                <v-card width="200px">
                    <v-card-title>
                        Cancel?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn small dark color="success">No</v-btn>
                        <v-spacer>
                        </v-spacer>
                        <v-btn small @click="UpdateCancelBA(ba)" dark color="error">Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-list-item-action> -->
            <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                {{ba.monthcreated}}
                    <br>
                {{ba.daycreated}}
        </v-list-item-avatar>                    
        <!-- <v-list-item-content>
            {{ba.pubDate}} 
        </v-list-item-content> -->
    </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            BillingAccounts: []
        }
    },
    computed:{
        UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        userBU () {
        return this.$store.state.buobj
        },
        InvitebyCompany(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties && this.ActiveMemberGroup.AdditionalProperties.CompanyInviteOnly && this.userLoggedIn.Company
      },
      userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        BAColRef(){
            let ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts')
            if(this.InvitebyCompany){
                ref = ref.where('Companyid','==',this.userLoggedIn.Companyid)
            }
            else if(!this.userIsAdmin){
                ref = ref.where('Userid','==',this.userLoggedIn.id)
            }
            return ref
        },
        ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        ComputedBillingAccounts(){
            return this.BillingAccounts.map(inv => {
                let baobj = Object.assign(inv)
                let userobj = this.UsersArray.find(obj => obj.id === baobj.Userid)
                if(userobj && userobj.Profile_Photo){
                    baobj.UserProfileIMG = userobj.Profile_Photo
                }
                baobj.monthcreated = this.TimestampFormatterNoticeBoard(baobj.Created_On.toDate(),'month')
                    baobj.daycreated = this.TimestampFormatterNoticeBoard(baobj.Created_On.toDate(),'date')        
                    baobj.createddate = this.TimestampFormatterNoticeBoard(baobj.Created_On.toDate(),'full')
                    if(baobj.Status){
                    if(baobj.Status.Name === 'Sent'){
                        baobj.StatusColor = 'blue'
                        baobj.StatusIcon = 'mdi-help'
                    }
                    else if(baobj.Status.Name === 'Processing'){
                        baobj.StatusColor = 'warning'
                        baobj.StatusIcon = 'mdi-timer-sand'
                    }
                    else if(baobj.Status.Name === 'Completed'){
                        baobj.StatusColor = 'success'
                        baobj.StatusIcon = 'mdi-account-check'
                        baobj.StatusDisabled = true
                        //Can check InviteID prop on susers to find match
                    }
                    else if(baobj.Status.Name === 'Cancelled'){
                        baobj.StatusColor = 'error'
                        baobj.StatusIcon = 'mdi-cancel'
                        baobj.StatusDisabled = true
                    }
                    }
                    let finvalprops = ['Total_Invoices','Total_Paid','Outstanding_Amount']
                    finvalprops.map(prp => {
                        if(typeof baobj[prp] === 'undefined'){
                            baobj[prp] = 0
                        }
                    })
                return baobj
            })
        },
    },
    created(){
        this.GetBillingAccounts()
    },
    methods:{
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        AcceptInviteUserDialog(){
            this.$store.commit('SetSocialItemInteractMethod','AcceptInviteUserDialog')
        },
        CopyInviteToClipBoard(text){
            let path = ''            
            if(window.location.port.length > 0){
                
                path = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+text
            }
            else{
                path = window.location.protocol+'//'+window.location.hostname+text
            }
            navigator.clipboard.writeText(path).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
            console.error('Async: Could not copy text: ', err);
            });
        },
        UpdateCancelBA(ba){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(ba.id).update({
                    Status: {ID: 1000004,Name: 'Cancelled'}
                }) 
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetBillingAccounts(){
            this.BAColRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    this.BillingAccounts.push({
                    ...change.doc.data(),
                    id: change.doc.id
                    })
                }
                if (change.type === 'modified') {
                    let invobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.BillingAccounts.find(obj => obj.id === invobj.id)
                    if(oncheck){
                        let index = this.BillingAccounts.indexOf(oncheck)
                       this.BillingAccounts.splice(index,1,invobj)
                    }
                }
                if(change.type === 'removed'){
                    let invobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.BillingAccounts.find(obj => obj.id === invobj.id)
                    if(oncheck){
                        let index = this.BillingAccounts.indexOf(oncheck)
                        this.BillingAccounts.splice(index,1)
                    }
                }
                
                //console.log('this.BillingAccounts',this.BillingAccounts)
                })
            })
        },
    }
}
</script>

<style>

</style>
