
<template>
<div>
    
  <v-dialog width="80%" v-model="AttendeeSelectDialog">
      <v-card flat width="100%" height="100%">
        <v-card-title class="headline primary white--text">Email Preview - <h3 class="headline primary pop--text"> Validate The Email before sending!</h3></v-card-title>
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item class='basicoutlined'>
                      <v-list-item-content>               
                      <strong>Record:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="EventRecordFilter" @change="CheckifUser()" label="Record" autocomplete="off" :items="['Accounts','Users','Contacts']" item-text="companyemail"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item class='basicoutlined' v-if="EventRecordFilter !== 'Users'">
                      <v-list-item-content>               
                      <strong>Field:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="MailFieldFilter" label="Email Field" autocomplete="off" :items="MailRecordFilterEmailFields" item-text="Name"></v-combobox>  
                      </v-list-item-content>              
                   </v-list-item>              
                   
                  </v-list>
                   </v-layout>
                  <v-layout class="justify-center">
                  <v-card width ="80%" outlined flat>
                    <v-data-table
                      :headers="RelatedFilterHeaders"
                      :items="RelatedFilterRecords"
                      class="elevation-1"
                    >
                      <template v-slot:top>
                    <v-toolbar flat color="white">
                      <v-toolbar-title>{{EventRecordFilter}} records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-checkbox light
                        v-model="relatedsearches"
                        label="Prefilter to Related"
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-text-field light
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>
                                     
                    </v-card>
                </v-layout>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSelection()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="AddSelection()">OK <v-icon>mdi-check</v-icon></v-btn>
                </v-layout>
        </v-card>
      </v-dialog>
    <v-dialog v-model="NewEventDialog" max-width="800px">
      <v-card>
        <!-- <v-card-title class="primary white--text">       -->
        <v-toolbar flat dark height="100px">           
          <v-toolbar-title>New Event</v-toolbar-title>
         
        </v-toolbar>
            <!-- <v-list>          
          
        </v-list> -->
        <!-- </v-card-title> -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
          <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" v-model="AppointmentOwner" return-object></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
            <div class="hovercontent mx-3"><v-icon @click="InvitingAttendees = !InvitingAttendees">mdi-account-multiple-plus<p class="tooltiptext">Invite Attendees</p></v-icon></div>
          
          <v-list-item v-if="InvitingAttendees">
            <v-list-item-avatar tile >               
                      <v-btn small @click="ToggleSelectDialog()" color="pop" dark>To:</v-btn>
                      </v-list-item-avatar>
            <v-list-item-content>               
                      <v-combobox v-model="Attendees" multiple  label="To" readonly :rules="[$store.state.formrules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content> 
          </v-list-item>
          <v-list-item> 
            <v-list-item-content>             
              <v-text-field v-model="EventName" label="Subject" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
              </v-list-item-content>
              </v-list-item>
               <!-- <v-list-item>
              <v-list-item-content>             
              <v-text-field v-model="EventName" label="Name"
            ></v-text-field>
              </v-list-item-content>
              </v-list-item> -->
              <v-list-item>
              <v-list-item-content>
                <v-menu
          v-model="EventstartdateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
        </v-menu>             
              </v-list-item-content>
              <v-list-item-content> 
                <v-menu v-if="EventStartDate"
          v-model="EventStartTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartTimeMenu"
        
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartTime"
              label="Start Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventStartTime"
             @input="EventStartTimeMenu = false"
            type="month"
            width="290"
            class="ml-4"
          ></v-time-picker>
        </v-menu>            
              </v-list-item-content>
              <v-list-item-content>
                <v-checkbox v-model="AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>  
                <v-menu
        v-if="EventStartDate"
          v-model="EventenddateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndDate"
              label="End Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
        </v-menu>           
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventEndDate && EventStartDate && EventStartTime"
          v-model="EventEndTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndTimeMenu"
          
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndTime"
              label="End Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventEndTime"
             @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
            type="month"
            width="290"
            class="ml-4"
            
          :allowed-minutes="AllowedEndTimeMinutes"
          :allowed-hours="AllowedEndTimeHours"
          ></v-time-picker>
        </v-menu>           
                           
              </v-list-item-content>
          </v-list-item>
         
            <v-list-item>
              <v-list-item-content>  
                 <v-layout class="justify-center">
          <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <!-- <v-select @click="getDocumentSelection()" :items="[2,4,6,8,10,12,14,16,18,20]" label="Size" @change="ChangefontSize()" v-model="fontsize">                        
                          </v-select> -->
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>                      
                        <!-- </v-btn-toggle>
                        <v-btn-toggle> -->
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <!-- <v-btn>
                            <v-icon>mdi-format-align-center</v-icon>
                          </v-btn> -->
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
                 </v-layout>
              </v-list-item-content>
            </v-list-item>
                        <v-list-item>
              <v-list-item-content>  
                 <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          <v-btn onclick="document.execCommand('undo',false,'')">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('redo',false,'')">
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                          
                          <v-btn onclick="document.execCommand('bold', false, '');">
                            <v-icon>mdi-format-bold</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('italic', false, '');">
                            <v-icon>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('underline', false, '')">
                            <v-icon>mdi-format-underline</v-icon>
                          </v-btn>
                          <v-btn @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <v-btn  onclick="document.execCommand('selectAll',false,'')">
                            <v-icon>mdi-select-all</v-icon>
                          </v-btn>
                                                
                          <v-btn>
                            <!-- <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" style="display: none;" @change="getImage()">

      <label for="file" class="tool-items fa fa-file-image-o"></label> -->
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>                      
                          <v-btn>
                            <v-icon @click="link()">mdi-link</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                            <v-icon>mdi-format-strikethrough</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                 </v-layout>
              </v-list-item-content>
                        </v-list-item>  
         
               <v-list-item>
              <v-list-item-content>
                <v-card width="90%" min-height="300px" outlined flat v-html="EventDetails" contenteditable id="previewsendemail">             
                </v-card>
              </v-list-item-content>
              </v-list-item>
               
               <v-list-item>
                 <v-list-item-content>
                    </v-list-item-content>
                 </v-list-item>
          
        </v-list>
        
            
        
        
        
     
        
        <v-card-actions>
          <v-btn @click="AddNewEvent()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toolbar flat dark>
             <v-select :items="SubordinateUsersArray" return-object item-text="Full_Name" v-model="UserFilter" label="Select User"></v-select>
         </v-toolbar>
    <v-sheet height="64" class="accent" dark style="margin-top:-20px;">
         
        <v-toolbar flat dark>
            
          <!-- <v-btn outlined class="mr-4" color="secondary" @click="setToday">
            Today
          </v-btn> -->
          <v-btn fab text small color="secondary" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ datetitle }}</v-toolbar-title>
          <v-btn fab text small color="secondary" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                color="secondary"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-toolbar>
      </v-sheet>
      <v-sheet min-width="350px" :height="FullHeight? '100%': 600">
          
        <v-btn color="green" dark fab icon @click="ActivateNewEvent()"><v-icon>mdi-plus-thick</v-icon></v-btn>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="AllEventsViewFormatted"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              color="pop"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.Name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>              
            </v-toolbar>
            <v-toolbar height="100px">
              <v-list>
                <v-list-item>
              <v-combobox v-model="selectedEvent.Attendees" multiple  label="To" readonly :rules="[$store.state.formrules.required]" autocomplete="off"></v-combobox>
                </v-list-item>
              </v-list>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['RelatedObj','RelatedParentObj','RelatedGroupFilter','UserRecord','Business_Unit','FullHeight'],
    data() {
        return {
          rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            AllEvents: [],
            TempAllEvents: [],
            NewEventDialog: false,
            EventstartdateMenu: false,
            AppointmentOwner: '',
            InvitingAttendees: false,
            AttendeeSelectDialog: false,
            EventRecordFilter: '',
            MailFieldFilter: '',
            UserHeaders:[
        { text: 'Selected', value: 'selected',sortable: false},
        { text: 'Title', value: 'Title'},
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Gender', value: 'Gender'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
            ArrayFilter: 'To',
            relatedsearches: [],
            search: '',
            Attendees: [],
            EventenddateMenu: false,
            EventEndDate: '',
            EventStartTime: '',
            EventStartTimeMenu: false,
            AllDayEvent: false,
            EventEndTime: '',
            EventEndTimeMenu: false,
            EventStartDate: '',
            EventName: '',
            EventSubject: '',
            EventDetails: '',
            UsersArray: [],
            SubordinateUsers: [],
            UserFilter: '',
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            today: format(new Date(),'yyyy-MM-dd')
        }
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },

    computed: {
      userLoggedIn () {
      return this.$store.getters.user
      },
    SubordinateUsersArray(){
        return this.UsersArray.filter(user => {
            let usercheck = this.SubordinateUsers.find(obj => obj === user.id)
            if(usercheck){
                return user
            }
        })
    },
    //     SubordinateUsers(){
    //     return this.UsersArray.filter(user => {
    //         return user.Managerid === this.UserRecord.id
    //     })
    // },
    // SubordinateUsersIDs(){
    //     return this.SubordinateUsers.map(user => {
    //         return user.id
    //     })
    // },
      RelatedFilterHeaders(){
      if(this.EventRecordFilter === 'Users'){
        return this.UserHeaders
      }
      if(this.EventRecordFilter === 'Accounts'){
        return this.AccountsHeaders
      }
      if(this.EventRecordFilter === 'Service Job Requests'){
        return this.ServiceJobRequestsHeaders
      }
    },
    MailRecordFilterEmailFields(){
      if(this.EventRecordFilter === 'Users'){
        return ['Email']
      }
      if(this.EventRecordFilter === 'Accounts'){
        return ['Business_Email']
      }
      if(this.EventRecordFilter === 'ServiceJobRequests'){
        return ['Customer_EMail_Address,']
      }
      if(this.EventRecordFilter === 'Contacts'){
        return ['emailaddress']
      }
    },
    RelatedMailingList(){
      // if(this.ArrayFilter === 'To'){
        return this.Attendees
      // }
      // if(this.ArrayFilter === 'CC'){
      //   return this.EmailCC
      // }
      // if(this.ArrayFilter === 'BCC'){
      //     return this.EmailBCC
      // }      
    },
    RelatedRecordfilterArray(){
      if(this.EventRecordFilter === 'Users'){
        return this.UsersArray
      }
      if(this.EventRecordFilter === 'Accounts'){
        return this.AccountsArray
      }
      if(this.EventRecordFilter === 'Service Job Requests'){
        return this.ServiceJobRequestsArray
      }
    },
    RelatedRecordPrimaryField(){
      if(this.EventRecordFilter === 'Users'){
        return 'Full_Name'
      }
      if(this.EventRecordFilter === 'Accounts'){
        return 'Company_Name'
      }
      if(this.EventRecordFilter === 'Service Job Requests'){
        return 'Request_Number'
      }
    },
      RelatedFilterRecords(){
      if(this.EventRecordFilter && this.ArrayFilter){
        return this.RelatedRecordfilterArray.filter(record => {
          return record[this.RelatedRecordPrimaryField].toLowerCase().includes(this.search.toLowerCase()
          )}).map(record => {
            let ToCheck = this.RelatedMailingList.find(to => to == record[this.MailFieldFilter])
            if(typeof ToCheck !== 'undefined'){
              record.selected = true
            }
            else{
              record.selected = false
            }
            return record            
          })
    }
     else{
      return []
    }
         
    },
        AllowedEndTimeHours(){

        let Hours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
      return Hours.filter(hr => {
        if(this.EventStartTime){
          return hr >= Number(this.EventStartTime.split(':')[0])
        }
        else{
          return []
        }
        })
       console.log(this.AllowedEndTimeHours)
      },
      AllowedEndTimeMinutes(){
        let Minutes = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        return Minutes.filter(hr => {
          if(this.EventStartTime && this.EventEndTime){
            let starttimehour = Number(this.EventStartTime.split(':')[0])
            let endtimehour = Number(this.EventEndTime.split(':')[0])
            console.log(this.EventEndTime)
            if(starttimehour === endtimehour){
          return hr >= Number(this.EventStartTime.split(':')[1])
            }
            else{
              return Minutes
            }
          }
          else{
            return []
          }
        })
        
        console.log(this.AllowedEndTimeMinutes)
      },
    AllEventsViewFormatted(){
      return this.AllEvents.map(event => {
          let eventobj = Object.assign({},event)    
        if(typeof eventobj.start.seconds !== 'undefined'){

        eventobj.start = this.TimestampFormatterSTRING(eventobj.start.toDate())
        }
        else{

        }
  
        
        if(typeof eventobj.end.seconds !== 'undefined'){
  
        eventobj.end = this.TimestampFormatterSTRING(eventobj.end.toDate())
        }
        else{
      
        }
    
        return eventobj
        
      }).filter(app => {
          if(this.UserFilter){
              console.log(this.UserFilter)              
              return app.AttendeeUserIDStrings.includes(this.UserFilter.id)
          }
          else{
              return app
          }
      })
    },
    AllJavaFormatEvents(){
      let NewArray = Array.from(this.AllEventsViewFormatted)
      return NewArray.map(event => {
        event.start = new Date(event.start)
        event.end = new Date(event.end)
        return event
      })
    },
    datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
    },

    methods: {
        GetUsers(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    this.GetAppointments()
    },
      AddSelection(){
     
      let ToPush = this.RelatedFilterRecords.filter(record => {return record.selected}).map(record => {return record[this.MailFieldFilter]})
      this.Attendees = ToPush
      
      this.CancelSelection()
    },
      CancelSelection(){
      this.AttendeeSelectDialog = false
      this.ArrayFilter = ''
      this.RecordFilter = ''
      this.MailFieldFilter = ''
    },
      CheckifUser(){
      this.MailFieldFilter = ''
      if(this.EventRecordFilter === 'Users'){
        this.MailFieldFilter = 'Email'
      }
    },
      ToggleSelectDialog(){
    
      this.AttendeeSelectDialog = true
    },
    GetAppointments(){
        //console.log(this.SubordinateUsers)
    db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('activitytype','==','Appointment').where('AttendeeUserIDStrings','array-contains-any',this.SubordinateUsers).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.AllEvents.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
      
      changes.forEach(change => {
        if (change.type === 'added') {
          this.TempAllEvents.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    //console.log(this.AllEvents)
        },
        TimestampFormatter(d){
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        var day = days[d.getDay()];
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "am";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "pm";
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        return `<p style="font-size:12px;color:grey;">`+day + " " + date + " " + month + " " + year + " "+ hr + ":" + min + ampm + " </p>";
      },

      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        console.log(finaldate)
        return finaldate
      },

        updateRange ({ start, end }) {
      this.start = start
        this.end = end
    },
    viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      ActivateNewEvent(){
        this.Attendees = []
        this.Attendees.push(this.UserRecord.Email)
        this.NewEventDialog = true
      },
      AssignEventEndDate(){
        console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
      CheckAllowedMinutes(){
       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
     
      AddNewEvent(){
        console.log('this.AllJavaFormatEvents right before we push a new one')
        console.log(this.AllJavaFormatEvents)
        console.log(this.EventStartTime)
        let regardingrecord = {}
        let NEWeditorContent = document.getElementById('previewsendemail')
        this.EventDetails = NEWeditorContent.innerHTML;
        console.log(this.EventDetails)
        let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
        let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
         let NewJavaStartDate = new Date(NewStartDateString)
        let NewJavaEndDate = new Date(NewEndDateString)
        if(typeof this.$route.params.id !== 'undefined'){
        regardingrecord = {
              Link:'/'+this.RelatedObj.Link+'/'+this.$route.params.id, Name:[this.RelatedObj.identifier], [this.RelatedObj.identifier]: this.RelatedObj[this.RelatedObj.identifier],id: this.$route.params.id
            }
            console.log(regardingrecord)
        }
        let NewEvent = {
            Owner: {Full_Name: this.AppointmentOwner.Full_Name,Name: this.AppointmentOwner.Name,Surname: this.AppointmentOwner.Surname,id: this.AppointmentOwner.id},
            Ownerid: this.AppointmentOwner.id,
            Attendees: this.Attendees,
          name: this.EventName,
          subject: this.EventSubject,
          details: this.EventDetails,
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          color: 'pop',
          activitytype: 'Appointment',
        creatorfullname: this.UserRecord.Full_Name,
        creatorid: this.UserRecord.id,
        status: 'Open',
        AllDayEvent: this.AllDayEvent
        
        }
        if(typeof this.$route.params.id !== 'undefined'){
        NewEvent.regardingrecord = regardingrecord
        NewEvent.regardingtype = this.RelatedObj.Link
        }
        if(this.UserRecord.id === this.AppointmentOwner.id){
            NewEvent.FollowingUsers = [{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        else{
            NewEvent.FollowingUsers = [{id: this.AppointmentOwner.id, Name: this.AppointmentOwner.Name, Surname: this.AppointmentOwner.Surname, Full_Name: this.AppointmentOwner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        if(this.Attendees.length > 0){
          NewEvent.AttendeeUserIDStrings = []
          this.Attendees.map(attendee => {
            let userobj = this.UsersArray.find(obj => obj.Email === attendee)
            if(userobj){
              let attendeeobj = {id: userobj.id, Name: userobj.Name, Surname: userobj.Surname, Full_Name: userobj.Full_Name}
              NewEvent.AttendeeUserIDStrings.push(attendeeobj.id)
              let followingobj = NewEvent.FollowingUsers.find(obj => obj.id === attendeeobj.id)
              if(!followingobj){
                NewEvent.FollowingUsers.push(attendeeobj)
              }
            }
          })
        }
         if(this.Business_Unit && typeof this.Business_Unit.id !== 'undefined'){
        NewEvent.Business_Unitid = this.Business_Unit.id
      }
      let FollowingUserIDStrings = []
       let FollowingUserIDKeys = {}
       NewEvent.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       NewEvent.FollowingUserIDKeys = FollowingUserIDKeys
       NewEvent.FollowingUserIDStrings = FollowingUserIDStrings

       
        console.log(NewJavaStartDate)
        console.log(NewJavaEndDate)
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(NewEvent).then(res => {

        })
        this.AllDayEvent = false
        this.EventSubject = ''
        this.EventName = ''
        this.EventDetails = ''
        this.EventStartDate = ''
        this.EventStartTime = ''
        this.EventEndDate = ''
        this.EventEndTime = ''
        this.NewEventDialog = false        
      },
     
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      CheckRouting(){
      
        if(typeof this.$route.params.id !== 'undefined'){
          // console.log('this.RelatedObj')
          // console.log(this.RelatedObj)
          // console.log('this.RelatedParentObj')
          // console.log(this.RelatedParentObj)
            //   this.defaultItem[this.RelatedObj.identifier] = this.RelatedObj
            //   this.editedItem = Object.assign({}, this.defaultItem)
            //   let queryfield = this.RelatedObj.identifier+'id'
              
            //   if(typeof this.RelatedParentObj !== 'undefined'){
            //     this.defaultItem[this.RelatedParentObj.identifier] = this.RelatedParentObj
            //     let queryfield = this.RelatedParentObj.identifier+'id'
            //     this.CollectionRef = this.CollectionRef.where(queryfield,'==',this.RelatedParentObj.id)
            //   }
            //   else{
            //     let queryfield = this.RelatedObj.identifier+'id'
            //     this.CollectionRef = this.CollectionRef.where(queryfield,'==',this.RelatedObj.id)
            //   }
            this.GetRequestingUser()
             
          }
          // else{
          //   this.GetRequestingUser()
           
          // }
        },
        async GetRequestingUser(){
          let vm = this
          vm.UserRecord = this.userLoggedIn
              // vm.UserRecord.id = this.userLoggedIn.uid
              vm.SubordinateUsers = vm.UserRecord.Subordinatearrayquery
              vm.GetUsers()
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              if(this.userLoggedIn.Business_Unitid){
                  vm.UserBusinessUnitID = this.userLoggedIn.Business_Unitid
                  //  console.log(vm.UserBusinessUnitID)
                  db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = businessunitdata.childarrayQuery
                  vm.UserBusUnitParents = businessunitdata.parentarray
                  //console.log(vm.UserRoles)
                  })
              }
              
              
              
              
              
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              vm.UserBusinessUnitID = this.userLoggedIn.Business_Unitid
              //console.log(vm.UserBusinessUnitID)
              db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             //console.log(vm.UserRoles)
             let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              })
        },
    },
    

    created(){
      this.GetRequestingUser()
        this.CheckRouting()
    },

}
</script>

<style>

</style>
 