<template>
  <div>
     
      <v-layout class="justify-center" row style="margin-top:60px;">
     <v-flex lg10 md10>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       <v-toolbar-title>Purchase Orders</v-toolbar-title>
      
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field 
          v-model="POSearch"
          append-icon="mdi-file-find-outline"
          label="Search PO"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="POSearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
          <!-- <v-dialog v-model="WarehouseDialog" width="400">
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="recordtoolbar white--text">
                New Warehouse
              </v-card-title>
              <v-card-text>
                <FieldsForm :FormFields="OpenPRHeaders" :Validating="Validating" :DataTableView="true" @save="SaveWarehouse" :DataObject="editedWarehouse" :tab="{}" />
               
              </v-card-text>
               <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="CloseWarehouseDialog()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
     </v-toolbar>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       
       <v-list-item dense>
         <v-list-item-content>
            <v-select :items="WarehousesArray" clearable item-text="Name" return-object label="Warehouse" v-model="SelectedWarehouse"
       />
        </v-list-item-content>
          <v-list-item-content>
            <v-select :items="SelectableSuppliers" clearable item-text="Supplier_Name" return-object label="Supplier" v-model="SelectedSupplier"
       />
         </v-list-item-content>
         <v-list-item-action>
          <!-- <v-btn :disabled="!EnableSelect || SelectedLineItems.length === 0" @click="ActivateProcessPickingSlipDialog()" >Process</v-btn> -->
          <!-- what if cancel or return? -->
         </v-list-item-action>
       </v-list-item>      
     </v-toolbar>
     </v-flex>
   </v-layout>
    <v-layout class="justify-center" row>
     <v-flex lg10 md10>
       <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="POHeaders"
      :items="POArraySearched"   
     :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
      >
       <!-- <template v-slot:[`item.Warehouse.Name`]="{ item }">
        <v-btn outlined fab small :to="'/Warehouse/'+item.Warehouse.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.Warehouse.Name}}
      </template> -->
      <template v-slot:[`item.id`]="{ item }">
        <v-btn outlined fab small :to="'/PurchaseOrder/'+item.Warehouseid+'/'+item.id" class="elevation-6 accent--text"><v-icon small >mdi-cash-register</v-icon></v-btn>
        {{item.id}}
      </template>
      <template v-slot:[`item.Supplier.Supplier_Name`]="{ item }">
        <v-btn outlined fab small :to="'/StoreSuppliers/'+item.id" class="elevation-6 accent--text"><v-icon small >mdi-cash-register</v-icon></v-btn>
        {{item.Supplier.Supplier_Name}}
      </template>
       </v-data-table>
      </v-flex>
  </v-layout>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LineItemsComponent from '@/components/SuitePlugins/Warehousing/LineItemsComponent';

export default {
    props: ['System','SystemEntities'],
    components: {LineItemsComponent},
    data() {
        return {
          SelectedSupplier: '',
            SelectedWarehouse: '',
            PurchaseOrders: [],
            SelectedPickingSlip: '',
            POSearch: '',
            SelectedLineItems: [],
            POHeaders: [
                {text: 'id',value: 'id',class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Delivery Date',propvalue: 'Delivery_Date',value: 'Delivery_Date',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',value: 'Sales_Order.Name',class: 'overline'}
            ],
            ProcessPickingSlipDialog: false,
        }
    },
    computed:{
        
        POArraySearched(){
          return this.PurchaseOrders
          .filter(pr => {
            if(this.POSearch){
              return pr.Supplier && pr.Supplier.Supplier_Name.toLowerCase().includes(this.POSearch.toLowerCase()) || pr.Supplier && pr.Supplier.id.toLowerCase().includes(this.POSearch.toLowerCase())
               || pr.PO_Number.includes(this.POSearch.toLowerCase())
            }
            else{
              return pr
            }
          }).filter(po => {
            if(this.SelectedSupplier){
              return po.Supplierid === this.SelectedSupplier.id
            }
            else{
              return po
            }
          })
          .filter(po => {
            if(this.SelectedWarehouse){
              return po.Warehouseid === this.SelectedWarehouse.id
            }
            else{
              return po
            }
          })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        UserWarhouseObj(){
            return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
        },
        SelectableSuppliers(){
        return this.POArraySearched.map(pr => {
            let supplobj = pr.Supplier
            return supplobj
          })
      },
        WarehousesArray(){
            return this.$store.state.WarehousesArray
            },
        ActiveWarehouse(){
            return this.WarehousesArray.find(obj => obj.id === this.$route.params.id)
        },
        UserWarehouseObj(){
            return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
        },
        OrderEntity(){
            return this.SelectedPickingSlip ? this.SystemEntities.find(obj => obj.id === this.SelectedPickingSlip.LookupObj.list.split(' ').join('_')) : ''
        },
        OrderEntityInvoiceCycle(){
            return this.OrderEntity && this.OrderEntity.Warehouse_Dispatch_After ? this.OrderEntity.Warehouse_Dispatch_After.Name : ''
        },
        TransactionTriggersDispatching(){
            if(this.OrderEntityInvoiceCycle){
                if(this.OrderEntityInvoiceCycle === 'Picking'){
                    return true
                }
            }
        },
        ProgressItems(){
            return [
                {ID: 1000001,Name: 'Issued'},
                {ID: 1000002,Name: 'In Progress'},
                {ID: 1000003,Name: 'Returned'},
                {ID: 1000004,Name: 'Cancelled'},
                {ID: 1000005,Name: 'Completed'}
            ]
        },
        LineItemDBBase(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.id).collection('PickingSlipsLine_Items')
        },
        ParentDBBase(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.id).collection('PickingSlips') 
        },
        ProcessingDescription(){
            if(this.SelectedPickingSlip){
              let msg = `Processing this document will complete the below items for `+this.SelectedPickingSlip.LookupObj.id+`.`
            if(this.TransactionTriggersDispatching){
                msg = msg+`
                as "Ready for Dispatching"`
            }
            msg = msg+`.<br>
                Do you want to continue?`
            return msg  
            }
            else{
                return ''
            }
            
        }
    },
    created(){
        this.GetPurchaseOrders()
    },
    methods:{
        
        
        ResetSearch(){
            this.POSearch = ''
        },
        GetPurchaseOrders(){
          let vm = this
          if(this.$route.params.id){
            this.SelectedWarehouse = this.WarehousesArray.find(obj => obj.id === this.$route.params.id)
          }
          let finalwarehousesarray = this.WarehousesArray.filter(wh => {
            if(this.$route.params.id){
              return wh.id === this.$route.params.id
            }
            else{
              return wh
            }
          }).filter(wh => {
            return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Ordering || 
            this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Auditing
          })
          finalwarehousesarray.map(wh => {
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(wh.id).collection('PurchaseOrders').where('Warehouseid','==',wh.id).onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    //   vm.GetInventoryArticle(probj).then(result => {
                        vm.PurchaseOrders.push(probj)
                    //   })
                  
                  }
                  if (change.type === 'modified') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    //   vm.GetInventoryArticle(probj).then(result => {
                        let oncheck = vm.PurchaseOrders.find(obj => obj.id === probj.id)
                          if(oncheck){
                            let index = vm.PurchaseOrders.indexOf(oncheck)
                            vm.PurchaseOrders.splice(index, 1, probj);
                          }
                    //   })
                  
                  }
                  if (change.type === "removed") {                      

                      let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = vm.PurchaseOrders.find(obj => obj.id === probj.id)
                      if(oncheck){
                        let index = vm.PurchaseOrders.indexOf(oncheck)
                        vm.PurchaseOrders.splice(index, 1);
                      }
                  }
                })  
              })
          })
        }
    }
}
</script>

<style>

</style>


