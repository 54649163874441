<template>
  <v-list-item @click="PrepareSecurityRules()"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Publish Security Rules
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-security-network</v-icon>
          </v-list-item-action>
        </v-list-item>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
    props: ['System','SystemEntities','Directories'],
    components: {

    },
    data(){
        return {

        }
    },
    computed:{
        CustomRoleEntities(){
            return this.ComputedSystemEntities.filter(ent => {
                return ent.datasetsecuritystructure === 'Custom Roles'
                //BuildswithBUSecurity
            })
        },
        SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        ComputedSystemEntities(){
            return this.SystemEntities.map(ent => {
                let entityobj = Object.assign({},ent)
                if(ent.Entity_Type && ent.Entity_Type.Name === 'Web Form'){
                    //entityobj.datasetsecuritystructure = 'Custom Roles'
                }
                return entityobj
            })
            .filter(ent => {
                return ent.Entity_Type && ent.Entity_Type.Name !== 'Billing Account' && ent.id !== 'System_Tickets'
            }).map(ent => {
                console.log('ent',ent.id)
                return ent
            })
        },
        StoreEntities(){
            return this.Directories.filter(navlist => {
                return navlist.CategoricalListType === 'Store Products'
            })
        },
        EntitiesWithActivities(){
            return this.ComputedSystemEntities.filter(entity => {
                return entity.HasActivities && entity.id !== 'Users' && entity.id !== 'Business_Units'
            })
        }
    },
    created(){
                //console.log('thsis.SystemisRA',this.SystemisRA,process.env.VUE_APP_RA_SYSTEM_ID,process.env.VUE_APP_RA_PROVIDER_ID)  
        //REMOVED BUT MAY RECYCLE
        // function UnPublishedGroupPass(){
        // return existingData().IsPaid == true && request.resource.data.GroupPublishStatus != 'Published' && request.resource.data.MonetizingScheduleID == resource.data.MonetizingScheduleID && request.resource.data.MonetizationPackage == resource.data.MonetizationPackage
        // }    

        // function PublishedGroupPass(){
        // return existingData().IsPaid == true && resource.data.GroupPublishStatus == 'Published' && request.resource.data.MonetizingScheduleID == resource.data.MonetizingScheduleID && request.resource.data.MonetizationPackage == resource.data.MonetizationPackage
        // }  

        // function UserisAuthorizedWHVisitor(whid){
        // return getUserRoleData().Warehouses.includes(whid)
        // }  
        
    },
    methods:{
       
        PrepareStoreEntities(securityRules){
            let vm = this
            let entitieslength = this.StoreEntities.length
            return new Promise(function (resolve, reject) {
                if(entitieslength === 0){
                    resolve(securityRules)
                }
                else{
                    securityRules = securityRules+`
        //STORE Entities Start`
                    vm.StoreEntities.map((entity,entityindex) => {
                        let entityrule = ``
                        let pathstring = entity.id.split('_').join('').toLowerCase()+'store'
                        entityrule = entityrule+`
        match /`+pathstring+`/{document=**} {
            allow read: if true
            }
        match /`+pathstring+`/{inventoryarticleid} {
            allow create: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles')+`
            allow update: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles')+`
            }
        match /`+pathstring+`/{inventoryarticleid}/Warehouses/{storeproductid} {
            allow create: if WarehouseModerator(request.resource.id,'Publish')
            allow update: if WarehouseModerator(resource.id,'Publish')
            }`
                        securityRules = securityRules+``+entityrule
                        if(entityindex-1+2 === entitieslength){
                        securityRules = securityRules+`
        // STORE Entities End
        `
                        resolve(securityRules)
                        }
                    })
                }
            })
        },
        PrepareEntitiesWiki(securityRules,Type){
            let vm = this
            let entitieslist = []
            let suffix = ''            
            if(Type === 'Classified Listings'){
                entitieslist = vm.ComputedSystemEntities.filter(entity => {
                    return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
                })
                suffix = 'listings'
            }
            else if(Type === 'Wiki'){
                entitieslist = vm.ComputedSystemEntities.filter(entity => {
                    return entity.Has_Wiki
                })
                //console.log(entitieslist)
                suffix = 'wiki'
            }
            let entitieslength = entitieslist.length
            //alert(Type + entitieslength)
            return new Promise(function (resolve, reject) {
                if(entitieslength === 0){
                    resolve(securityRules)
                }
                else{
                    securityRules = securityRules+`
        //`+Type+` Entities Start`
                    entitieslist.map((entity,entityindex) => {
                        let typestring = Type
                        let entityrule = ``
                        if(Type === 'Classified Listings'){
                        typestring = entity.id.split('_').join(' ')+' '+Type
                        }
                        //space here for future consideration of "ClientWiki"
            //             <span v-if="build.IsClientWiki">
            //also PublishType or PublishStatus??
            // allow read: if useroles().hasAny(['EagleView']) || existingData().PublishStatus == ('Published (Public)') || isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && existingData().{{build.ClientLookupField.Name}}id == request.auth.uid || GlobalItemModerator('Wiki')</span>
        entityrule = entityrule+`
        match /`+entity.id.split('_').join('').toLowerCase()+suffix+`/{`+entity.SingleName.split(' ').join('').toLowerCase()+`id} {
            allow create: if useroles().hasAny(['System Admin']) || GlobalItemModerator('`+typestring+`',siteid)
            allow update: if useroles().hasAny(['System Admin']) || GlobalItemModerator('`+typestring+`',siteid)
            allow read: if resource.data.PublishType == 'Public' || isSignedIn()
            }`
            securityRules = securityRules+``+entityrule
            if(entityindex-1+2 === entitieslength){
                        securityRules = securityRules+`
        //`+Type+` Entities End
        `
                        resolve(securityRules)                    
                    }
                    })
                }
            })
        },
        PrepareEntitiesSTD(securityRules){
            let vm = this
            return new Promise(function (resolve, reject) {
                resolve(securityRules)
            })
        },
        
        PrepareSecurityRules(){
            let vm = this
            vm.$store.commit('setCustomProcessingDialog',true)
            vm.$store.commit('setCustomProcessingDialogText','Preparing Config')
            let securityRules = `rules_version = '2';
    service cloud.firestore {
    match /databases/{database}/documents {
        match /{document=**} {
        allow read, write: if useroles(SystemID()).hasAny(['System Admin']);
        }

        //System Config Start
        match /SystemConfig/{suitetemplateid}/{document=**} {  
        allow get: if  UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow list: if UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow update: if UserisSiteAuth(suitetemplateid,'Ownerid')
        allow create: if UserisNEWrecordOwner() && UserRoleBooleanPass(suitetemplateid,'IsSiteCreator') || UserisSiteAuth(suitetemplateid,'Ownerid')
        allow delete: if UserisSiteAuth(suitetemplateid,'Ownerid')
        }

        match /Websites/{suitetemplateid}/{document=**} {  
            //had  && KeyUnaffected('Published') on update rule but fails, also makes no sense plugin disable is enough
        allow get: if  UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow list: if UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow update: if UserisSiteAuth(suitetemplateid,'Ownerid') && KeyUnaffected('Is_Directory_Listing')
        allow create: if UserisNEWrecordOwner() && UserRoleBooleanPass(suitetemplateid,'IsSiteCreator') || UserisSiteAuth(suitetemplateid,'Ownerid')
        allow delete: if UserisSiteAuth(suitetemplateid,'Ownerid')
         match /sitekeywords/{sitekeywordid} {
                allow get: if true;
                allow list: if true;
                allow update: if useroles(suitetemplateid).hasAny(['SystemAdmin'])
                allow create: if useroles(suitetemplateid).hasAny(['SystemAdmin']) || incomingData().Type.Name == 'Featured Member' || incomingData().Type.Name == 'Site'
                allow delete: if useroles(suitetemplateid).hasAny(['SystemAdmin'])
            }
        }

        match /SocialNetworks/{suitetemplateid}/{document=**} {  
        allow get: if  UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow list: if UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow update: if UserisSiteAuth(suitetemplateid,'Ownerid')
        allow create: if UserisNEWrecordOwner() && UserRoleBooleanPass(suitetemplateid,'IsSiteCreator') || UserisSiteAuth(suitetemplateid,'Ownerid')
        allow delete: if UserisSiteAuth(suitetemplateid,'Ownerid')
        }

        match /Databases/{suitetemplateid}/{document=**} {  
        allow get: if  UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow list: if UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow update: if UserisSiteAuth(suitetemplateid,'Ownerid')
        allow create: if UserisNEWrecordOwner() && UserRoleBooleanPass(suitetemplateid,'IsSiteCreator') || UserisSiteAuth(suitetemplateid,'Ownerid')
        allow delete: if UserisSiteAuth(suitetemplateid,'Ownerid')
        }

        match /Libraries/{suitetemplateid}/{document=**} {  
        allow get: if  UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow list: if UserisSiteAuth(suitetemplateid,'Ownerid') || UserisSiteAuth(suitetemplateid,'Clientid')
        allow update: if UserisSiteAuth(suitetemplateid,'Ownerid')
        allow create: if UserisNEWrecordOwner() && UserRoleBooleanPass(suitetemplateid,'IsSiteCreator') || UserisSiteAuth(suitetemplateid,'Ownerid')
        allow delete: if UserisSiteAuth(suitetemplateid,'Ownerid')
        }

        match /SystemConfig/{siteid} {  
        allow get: if siteid == 'ConfigMaster' && isSignedIn() || siteid == '`+this.System.id+`' || SiteReadPass(siteid)
        allow list: if isSignedIn()
        allow update: if useroles(siteid).hasAny(['System Admin'])
        allow create: if useroles(siteid).hasAny(['System Admin'])  

        
        match /notifications/{notificationid} {
            allow read: if UserisCreator() || UserisOwner() || useroles(siteid).hasAny(['SystemAdmin']) || useroles(siteid).hasAny(['EagleView'])
            allow write: if UserisCreator() || UserisOwner() || useroles(siteid).hasAny(['SystemAdmin'])
            allow create: if isSignedIn() || useroles(siteid).hasAny(['SystemAdmin'])
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
        }
        match /defaultchatbotresponses/{defaultchatbotresponseid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles(siteid).hasAny(['SystemAdmin'])
            allow create:  if useroles(siteid).hasAny(['SystemAdmin'])
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            match /Responses/{Responseid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create:  if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
        }
        match /chatbotmessages/{chatbotresponseid} {  
            allow get: if useroles(siteid).hasAny(['SystemAdmin']) || request.auth.uid == existingData().recipientid || request.auth.uid == existingData().senderid
            allow list: if useroles(siteid).hasAny(['SystemAdmin']) || request.auth.uid == existingData().recipientid || request.auth.uid == existingData().senderid
            allow update: if useroles(siteid).hasAny(['SystemAdmin'])
            allow create:  if true
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
        }

        match /chatbotresponses/{chatbotresponseid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles(siteid).hasAny(['SystemAdmin'])
            allow create:  if useroles(siteid).hasAny(['SystemAdmin'])
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            match /Responses/{Responseid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create:  if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
             }
        }

        match /friendrequests/{friendrequestid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn()
            }        
        match /friendships/{friendshipid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn()
        }       

        
        match /users/{userroleid} {  
            allow create: if useroles(siteid).hasAny(['SystemAdmin'])
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles(siteid).hasAny(['SystemAdmin']) || RecordisUser() &&  !('Market_Accountid' in existingData()) || RecordisUser() && ('Market_Accountid' in existingData()) && incomingData().Market_Accountid == existingData().Market_Accountid
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            match /subscriptions/{subscriptionid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow update: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow create:  if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                }
                match /contentpurchases/{tokenpurchaseid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow update: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow create:  if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                }
                match /credittokens/{tokenpurchaseid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow create:  if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                }
                match /credits/{creditid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                }
                match /debits/{debitid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer(siteid)
                }
        }

        match /userroles/{userroleid} {  
            allow read: if isSignedIn()      
        }
        
        match /featuredmembers/{featuredmemberid} {
            allow get: if true;
            allow list: if true;
            allow update: if useroles(siteid).hasAny(['SystemAdmin']) || request.auth.uid == featuredmemberid
            allow create: if useroles(siteid).hasAny(['SystemAdmin'])
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            match /Pages/{Pageid} {
                allow get: if true;
                allow list: if true;
                allow update: if request.auth.uid == featuredmemberid || useroles(siteid).hasAny(['SystemAdmin'])
                allow create:if request.auth.uid == featuredmemberid || useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            match /storedpagepresets/{presetid} {
                allow get: if request.auth.uid == featuredmemberid;
                allow list: if request.auth.uid == featuredmemberid;
                allow update: if request.auth.uid == featuredmemberid || useroles(siteid).hasAny(['SystemAdmin'])
                allow create:if request.auth.uid == featuredmemberid || useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            
            match /Assets/{Assetid} {
                allow get: if request.auth.uid == featuredmemberid;
                allow list: if request.auth.uid == featuredmemberid;
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create:if request.auth.uid == featuredmemberid || useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            match /Enquiries/{Enquiryid} {
                allow get: if request.auth.uid == featuredmemberid;
                allow list: if request.auth.uid == featuredmemberid;
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create: if request.auth.uid == featuredmemberid || useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
        }    

        
        match /userinvites/{userinviteid} {  
            allow create: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && PrimarySiteGuestUser() == false && UserCanInvite(siteid) || isSignedIn() && UserCanInvite(siteid) && PrimarySiteGuestUser() && incomingData().IsGuestUser 
            allow get: if existingData().Status.Name != 'Cancelled' && existingData().Expired == false   
            allow list: if useroles(siteid).hasAny(['SystemAdmin']) || UserCanInvite(siteid) && existingData().Created_Byid == request.auth.uid || UserCanInvite(siteid) && GetConfigRootParentRecord(siteid,'usercompanies',existingData().Companyid).Userid == request.auth.uid
            allow update: if useroles(siteid).hasAny(['SystemAdmin']) || UserCanInvite(siteid) && existingData().Created_Byid == request.auth.uid && existingData().Status.Name != 'Completed' && existingData().Status.Name != 'Cancelled'
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
        }     
        match /membergroups/{membergroupid} {
            allow get: if ActiveMemberGroup(siteid).AdditionalProperties.InviteGroups.hasAny([membergroupid]);
            allow list: if ActiveMemberGroup(siteid).AdditionalProperties.InviteGroups.hasAny([membergroupid]);
            allow update: if useroles(siteid).hasAny(['SystemAdmin']) || ActiveMemberGroup(siteid).ManagesInviteGroups && ActiveMemberGroup(siteid).AdditionalProperties.InviteGroups.hasAny([membergroupid])
            allow create: if useroles(siteid).hasAny(['SystemAdmin'])
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
        }
        match /businessunits/{businessunitid} { 
            allow read: if isSignedIn() && PrimarySiteGuestUser() == false || existingData().IsPublicLocation || isSignedIn() && PrimarySiteGuestUser() && getBusinessUnitData(siteid).childarrayQuery.hasAny([businessunitid]) || isSignedIn()
            allow update: if getUserRoleData(siteid).BusinessProfileAdmin
        }

        match /businessmembers/{businessmemberid} {
            allow get: if true;
            allow list: if true;
            allow update: if useroles(siteid).hasAny(['SystemAdmin'])
            allow create: if useroles(siteid).hasAny(['SystemAdmin'])
            allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
        }

        match /bannerads/{banneradid} {  
            allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Public Banner Ads',siteid) || GlobalItemModerator('Banner Ads',siteid) && incomingData().PublishType == 'Members Only'
            allow get: if isSignedIn() || existingData().PublishType == 'Public'
            allow list: if isSignedIn() || existingData().PublishType == 'Public'
            allow update: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Public Banner Ads',siteid) || GlobalItemModerator('Banner Ads',siteid) && existingData().PublishType == 'Members Only'
            allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Public Banner Ads',siteid) || GlobalItemModerator('Banner Ads',siteid) && existingData().PublishType == 'Members Only'
        }
    
        match /landingpages/{landingpageid} {  
            allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Public Landing Pages',siteid) || GlobalItemModerator('Landing Pages',siteid) && incomingData().PublishType == 'Members Only'
            allow get: if isSignedIn() || existingData().PublishType == 'Public'
            allow list: if isSignedIn() || existingData().PublishType == 'Public'
            allow update: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Public Landing Pages',siteid) || GlobalItemModerator('Landing Pages',siteid) && existingData().PublishType == 'Members Only'
            allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Public Landing Pages',siteid) || GlobalItemModerator('Landing Pages',siteid) && existingData().PublishType == 'Members Only'                
        }
        match /SubscriptionPackages/{packid} {  
            allow get: if isSignedIn() || existingData().Type.Name == 'App Membership'
            allow list: if isSignedIn() || existingData().Type.Name == 'App Membership'
            }
        }        
        match /userroles/{userroleid} { 
            allow read: if isSignedIn() 
            allow create: if UserisSiteAuth(siteid,'Ownerid') && !SiteisSystem(siteid) || UserisSiteAuth(suitetemplateid,'Clientid') && !SiteisSystem(siteid)
            allow update: if UserisSiteAuth(siteid,'Ownerid') && !SiteisSystem(siteid) || UserisSiteAuth(suitetemplateid,'Clientid') && !SiteisSystem(siteid) || request.auth.uid == userroleid && isAllowedSiteUserSelfUpdate() && !SiteisSystem(siteid)
        }
        //maybe in time match /userinvites/{userinviteid} {  
        match /siteanalytics/{analyticid} {  
            allow get: if isGroupAdmin(existingData().RecordId) && existingData().RouteEventID == 1015
            allow list: if isGroupAdmin(existingData().RecordId) && existingData().RouteEventID == 1015
        }
        //SPECIAL ENTITY TYPE RULES - START
        //right now these are mostly ba related. create is not needed these currently financial docs. creating in bg
        //update however, required for creditor or debtor clerks as relevant.
        //There are still oustanding special entity rules for base "Reocrds" items, but they have dynamic ids, being ent types Store Product, Store Order, and Cash Loan
        match /Databases/{siteid}/entities/Sales_Invoices/Records {
            //list and get is debtos clerrk, or BA Read Pass
            //take note, BAReadPass does NOT call BA checking owner, it was written for BA assuming thus existingdata.Userid so we need to call ba
            //cheathed with "Clientid" need more elegant flow to check ba though
            allow read: if BAReadPass(siteid) || UserisRecordAuth('Clientid')
            allow update: if IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
            //technicall should still work on ensuring only 'Amount_Paid' && 'Progress' updated
        }
        match /Databases/{siteid}/entities/Sales_Quotes/Records {            
            allow read: if BAReadPass(siteid) || UserisRecordAuth('Clientid')
            allow update: if IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
            //technicall should still work on ensuring only 'allowed props' updated
        }
        match /Databases/{siteid}/entities/Purchase_Invoices/Records {
            allow read: if BAReadPass(siteid) || UserisRecordAuth('Clientid')  
            allow update: if IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid)    
            //technicall should still work on ensuring only 'allowed props' updated      
        }
        match /Databases/{siteid}/entities/Store_Suppliers/Records {
            //this one being the exception, not ba related at all
            allow read: if IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid) 
            allow update: if IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid)    
            //technicall should still work on ensuring only 'allowed props' updated      
        }
        
        //we still need something to manage store orders, cash loans, store products. 
        //these could be caught in the "entity create" and "entityupdatepass" rules though, as the table names are dynamic
        //SPECIAL ENTITY TYPE RULES - END
        match /Databases/{siteid} {
            allow read: if isSignedIn() && SiteReadPass(siteid)
            
            match /clientrecords/{clientrecordid} {  
                allow get: if isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && request.auth.uid == existingData().Clientid || existingData().relatedtype == 'Suite Templates' && request.auth.uid == GetRootParentRecord('SystemConfig',existingData().relatedid).Ownerid
                allow list: if isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && request.auth.uid == existingData().Clientid || existingData().relatedtype == 'Suite Templates' && request.auth.uid == GetRootParentRecord('SystemConfig',existingData().relatedid).Ownerid
                allow update: if isSignedIn() && PrimarySiteGuestUser() == false || request.auth.uid == GetRootParentRecord('SystemConfig',existingData().relatedid).Ownerid
                allow create:  if isSignedIn() && PrimarySiteGuestUser() == false || request.auth.uid == GetRootParentRecord('SystemConfig',incomingData().relatedid).Ownerid
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            
            match /clientliaison/{clientliaisonid} {  
                allow get: if isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && request.auth.uid == existingData().recipientid || PrimarySiteGuestUser() && request.auth.uid == existingData().senderid || existingData().relatedtype == 'Suite Templates' && request.auth.uid == GetRootParentRecord('SystemConfig',existingData().relatedid).Ownerid
                allow list: if isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && request.auth.uid == existingData().recipientid || PrimarySiteGuestUser() && request.auth.uid == existingData().senderid || existingData().relatedtype == 'Suite Templates' && request.auth.uid == GetRootParentRecord('SystemConfig',existingData().relatedid).Ownerid
                allow update: if isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && request.auth.uid == existingData().recipientid
                allow create:  if isSignedIn() && PrimarySiteGuestUser() == false || PrimarySiteGuestUser() && request.auth.uid == incomingData().senderid || incomingData().relatedtype == 'Suite Templates' && request.auth.uid == GetRootParentRecord('SystemConfig',incomingData().relatedid).Ownerid
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
                

            match /pdfexportfunctions/{pdfexportfunctionid} {  
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create:  if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }


             match /activities/{activityid} {
                allow create: if isSignedIn()
                allow read: if existingData() == request.auth.uid || UserisOwner() || UserisFollower()
                || getUserRoleData(siteid).ActivitiesRegardingUnitStructure == true && getBusinessUnitData(siteid).childarrayDBRules.keys().hasAny([existingData().Regarding_Record.Business_Unitid])
                || getUserRoleData(siteid).ActivitiesRegardingUnit == true && existingData().Regarding_Record.Business_Unitid == getBusinessUnitID(siteid)
                || getUserRoleData(siteid).ActivitiesRegardingOwner == true && existingData().Regarding_Record.Ownerid == request.auth.uid
                || useroles(siteid).hasAny(['EagleView']) || UserisAttendee() || UserisAttendeeManager(siteid) || CreatedByUser() || usersubordinates(siteid).hasAny([RecordOwner()]) || useroles(siteid).hasAny(['SystemAdmin'])
                allow update: if isSignedIn()
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            match /pdfexportfunctions/{pdfexportfunctionid} {  
                allow get: if isSignedIn()
                allow list: if isSignedIn()
            }
             match /AutoNumbers/{autonumberid} {  
                //is this needed?
                allow read: if isSignedIn() && SiteReadPass(siteid)
                }
             match /Ledgers/{ledgerid} {  
                allow get: if useroles(siteid).hasAny(['SystemAdmin']) || useroles(siteid).hasAny(['EagleView']) || IsAccountingClerk(siteid,'Debtor','Head_Office') || IsAccountingClerk(siteid,'Creditor','Head_Office')
                allow list: if useroles(siteid).hasAny(['SystemAdmin']) || useroles(siteid).hasAny(['EagleView']) || IsAccountingClerk(siteid,'Debtor','Head_Office') || IsAccountingClerk(siteid,'Creditor','Head_Office')
                allow update: if useroles(siteid).hasAny(['System Admin'])
                allow create: if useroles(siteid).hasAny(['System Admin'])
                }
            match /dataimports/{dataimportid} {
                allow get: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserisGuest(siteid) == false && SiteReadPass(siteid)
                allow list: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserisGuest(siteid) == false && SiteReadPass(siteid)
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanImport() && SiteReadPass(siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || useroles(siteid).hasAny(['SystemAdmin'])
            }
            match /customdashboards/{customdashboardid} {      
                allow get,list: if isSignedIn() && request.auth.uid == existingData().Ownerid || isSignedIn() && existingData().WhocanView.hasAny([request.auth.uid]) || isSignedIn() && resource == null
                allow create:  if isSignedIn() && request.auth.uid == incomingData().Ownerid
                allow update: if isSignedIn() && request.auth.uid == existingData().Ownerid || isSignedIn() && existingData().WhocanEdit.hasAny([request.auth.uid])
                allow delete: if isSignedIn() && request.auth.uid == existingData().Ownerid
            }
             match /BUsecurityroles/{roleid} {  
                allow get: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite(siteid);
                allow list: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite(siteid);
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
            match /Customsecurityroles/{roleid} {  
                allow get: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite(siteid);
                allow list: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite(siteid);
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
            match /RMsecurityroles/{roleid} {  
                allow get: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite(siteid);
                allow list: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite(siteid);
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }            
            match /entities/{entityid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                match /AllFields/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
                match /DocumentRegisters/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /Galleries/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /SubCollections/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /DigitizedForms/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /LoanPackages/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /EntityProcesses/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /automations/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                match /Sample_Records/{recordid} {
                    allow get: if isSignedIn() && SiteReadPass(siteid)
                    allow list: if isSignedIn() && SiteReadPass(siteid)
                    allow update:  if useroles(siteid).hasAny(['System Admin'])
                    allow create:  if useroles(siteid).hasAny(['System Admin'])                    
                }
                
                match /Records/{recordid} {
                    allow create: if EntityCreatePass(siteid,entityid)
                    allow get: if EntityGetPass(siteid,entityid,recordid)
                    allow list: if EntityListPass(siteid,entityid,recordid)
                    allow update: if EntityUpdatePass(siteid,entityid,recordid)
                    allow delete: if EntityDeletePass(siteid,entityid,recordid)
                    //for all subcols what happened to ParentUserTableFilterKeysPass(siteid,entityid,'UserFilter'+entityid,recordid)?
                    match /DocumentRegisters/{cabinetid}/Items/{itemid} {
                        //DocumentRegisters ONLY - the "cabinetid" doc would also be empty. So yet again we could do stats per record, as well as top level     
                        allow create: if CabinetActionPass(siteid,entityid,'DocumentRegisters',cabinetid,'CreatePermission','CustomCreateRoles') || !CabinetActionisCustom(siteid,entityid,'DocumentRegisters',cabinetid,'CustomCreateRoles') && EntityCreatePass(siteid,entityid)
                        allow get: if CabinetActionPass(siteid,entityid,'DocumentRegisters',cabinetid,'GetPermission','CustomGetRoles') || !CabinetActionisCustom(siteid,entityid,'DocumentRegisters',cabinetid,'CustomGetRoles') && EntityGetPass(siteid,entityid,recordid)
                        allow list: if CabinetActionPass(siteid,entityid,'DocumentRegisters',cabinetid,'ListPermission','CustomListRoles') || !CabinetActionisCustom(siteid,entityid,'DocumentRegisters',cabinetid,'CustomListRoles') && EntityListPass(siteid,entityid,recordid)
                        allow update: if CabinetActionPass(siteid,entityid,'DocumentRegisters',cabinetid,'UpdatePermission','CustomUpdateRoles') || !CabinetActionisCustom(siteid,entityid,'DocumentRegisters',cabinetid,'CustomUpdateRoles') && EntityUpdatePass(siteid,entityid,recordid)
                        allow delete: if CabinetActionPass(siteid,entityid,'DocumentRegisters',cabinetid,'DeletePermission','CustomDeleteRoles') || !CabinetActionisCustom(siteid,entityid,'DocumentRegisters',cabinetid,'CustomDeleteRoles') && EntityDeletePass(siteid,entityid,recordid)
                    }
                    match /Galleries/{cabinetid}/Items/{itemid} {
                        //Galleries ONLY -  the "cabinetid" doc would also be empty. So yet again we could do stats per record, as well as top level
                        allow create: if CabinetActionPass(siteid,entityid,'Galleries',cabinetid,'CreatePermission','CustomCreateRoles') || !CabinetActionisCustom(siteid,entityid,'Galleries',cabinetid,'CustomCreateRoles') && EntityCreatePass(siteid,entityid)
                        allow get: if CabinetActionPass(siteid,entityid,'Galleries',cabinetid,'GetPermission','CustomGetRoles') || !CabinetActionisCustom(siteid,entityid,'Galleries',cabinetid,'CustomGetRoles') && EntityGetPass(siteid,entityid,recordid)
                        allow list: if CabinetActionPass(siteid,entityid,'Galleries',cabinetid,'ListPermission','CustomListRoles') || !CabinetActionisCustom(siteid,entityid,'Galleries',cabinetid,'CustomListRoles') && EntityListPass(siteid,entityid,recordid)
                        allow update: if CabinetActionPass(siteid,entityid,'Galleries',cabinetid,'UpdatePermission','CustomUpdateRoles') || !CabinetActionisCustom(siteid,entityid,'Galleries',cabinetid,'CustomUpdateRoles') && EntityUpdatePass(siteid,entityid,recordid)
                        allow delete: if CabinetActionPass(siteid,entityid,'Galleries',cabinetid,'DeletePermission','CustomDeleteRoles') || !CabinetActionisCustom(siteid,entityid,'Galleries',cabinetid,'CustomDeleteRoles') && EntityDeletePass(siteid,entityid,recordid)
                    }
                    match /SubCollections/{subcolid}/Items/{itemid} {
                        // SubCollections will still get Custom Role option once we get to analyse it
                        allow create: if EntityCreatePass(siteid,entityid)
                        allow get: if EntityGetPass(siteid,entityid,recordid)
                        allow list: if EntityListPass(siteid,entityid,recordid)
                        allow update: if EntityUpdatePass(siteid,entityid,recordid)
                        allow delete: if EntityDeletePass(siteid,entityid,recordid)
                    }
                    //right so subcol,docreg, gal all 3 very very bad. go with for now but do you see the isse?
                    //once again if internal liaison was removed...and if processes was removed we could do match /Tables/{entityid}/Records/{recordid}/{cabinetid}/{itemid}
                    match /InternalLiaison/{InternalLiaisonid} {
                        // SubCollections will still get Custom Role option once we get to analyse it
                        allow create: if EntityCreatePass(siteid,entityid)
                        allow get: if EntityGetPass(siteid,entityid,recordid)
                        allow list: if EntityListPass(siteid,entityid,recordid)
                        allow update: if EntityUpdatePass(siteid,entityid,recordid)
                        allow delete: if EntityDeletePass(siteid,entityid,recordid)
                    }
                    match /processes/{processid} {
                        // SubCollections will still get Custom Role option once we get to analyse it
                        allow create: if EntityCreatePass(siteid,entityid)
                        allow get: if EntityGetPass(siteid,entityid,recordid)
                        allow list: if EntityListPass(siteid,entityid,recordid)
                        allow update: if EntityUpdatePass(siteid,entityid,recordid)
                        allow delete: if EntityDeletePass(siteid,entityid,recordid)
                    }
                }
                }
            match /SystemTableFilters/{filterid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
            match /DBNavlists/{navlistid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
            match /LiaisonTopics/{liasontopicid} {
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['SystemAdmin'])
                allow create:  if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
                }
            
            match /notes/{noteid} {  
                allow create: if isSignedIn() && UserisGuest(siteid) == false
                allow read: if isSignedIn() && UserisGuest(siteid) == false
                allow write: if isSignedIn() && UserisGuest(siteid) == false
            }
            
            match /timesheets/{timesheetid} {  
                allow create: if request.resource.data.Userid == request.auth.uid
                allow get: if isSignedIn() && UserisGuest(siteid) == false
                allow list: if isSignedIn() && UserisGuest(siteid) == false
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            
            match /goals/{goalid} {
                allow get: if isSignedIn() && UserisGuest(siteid) == false
                allow list: if isSignedIn() && UserisGuest(siteid) == false
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
                match /Targets/{targetid} {
                    allow get: if isSignedIn() && UserisGuest(siteid) == false
                    allow list: if isSignedIn() && UserisGuest(siteid) == false
                    allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                    allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                    allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
                }
            }
            match /Warehouses/{warehouseid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                allow read: if isSignedIn() && PrimarySiteGuestUser() == false || isSignedIn() && existingData().Guests_Allowed == true || existingData().Is_Public == true
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])            
                
                match /Inbound_Transactions/{Outbound_Transactioniid} {
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                }
                match /Outbound_Transactions/{Outbound_Transactionid} {
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                }
                match /PurchaseRequisitions/{PurchaseRequisitionid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,incomingData().Warehouseid,'Requisition')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                }
                
                match /PurchaseOrders/{PurchaseOrderid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                }
                match /GoodsReceivingVouchers/{GoodsReceivingVoucherid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                }
                match /PickingSlips/{PickingSlipid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                }
                match /PackingLists/{PackingListid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing')
                }
                match /DispatchInstructions/{DispatchInstructionid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                }
                match /PurchaseRequisitionsLine_Items/{PurchaseRequisitionlineitemid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                }
                match /PurchaseOrdersLine_Items/{PurchaseOrderlineitemid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Ordering')
                }
                match /GoodsReceivingVouchersLine_Items/{GoodsReceivingVoucherlinteitemid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Receiving')
                }
                match /PickingSlipsLine_Items/{PickingSliplinteitemid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                }
                match /PackingListsLine_Items/{PackingListlineitemid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Picking')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Packing')
                } 
                match /DispatchInstructionsLine_Items/{DispatchInstructionid} {
                    allow create: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                    allow update: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                    allow read: if isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(siteid,existingData().Warehouseid,'Dispatching')
                }
                
            }
            
            match /billingaccounts/{billingaccountid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || incomingData().Userid == request.auth.uid || IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid) || EntityCreatePass(siteid,'Billing_Accounts') && UserTableFilterKeysPass(siteid,'UserFilterBilling_Accounts')
                allow get: if useroles(siteid).hasAny(['SystemAdmin']) || BAReadPass(siteid) || UserisOwner() || EntityGetBUPass(siteid,'Billing_Accounts',billingaccountid) && UserTableFilterKeysPass(siteid,'UserFilterBilling_Accounts') || useroles(siteid).hasAny(['EagleView'])
                allow list: if useroles(siteid).hasAny(['SystemAdmin']) || BAReadPass(siteid) || UserisOwner() || EntityListBUPass(siteid,'Billing_Accounts',billingaccountid) && UserTableFilterKeysPass(siteid,'UserFilterBilling_Accounts') || useroles(siteid).hasAny(['EagleView'])
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || BAUpdatePass(siteid) || EntityUpdateBUPass(siteid,'Billing_Accounts',billingaccountid) && UserTableFilterKeysPass(siteid,'UserFilterBilling_Accounts') && BAOwnerProtection() && BATypeProtection()
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || EntityDeleteBUPass(siteid,'Billing_Accounts',billingaccountid) && UserTableFilterKeysPass(siteid,'UserFilterBilling_Accounts')
                match /processes/{processid} {
                    //siteid,entityid,tableid,parentid
                        allow create: if EntityCreatePass(siteid,'Billing_Accounts') && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid)
                        allow get: if useroles(siteid).hasAny(['SystemAdmin']) || EntityGetBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid) || useroles(siteid).hasAny(['EagleView'])
                        allow list: if useroles(siteid).hasAny(['SystemAdmin']) || EntityListBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid) || useroles(siteid).hasAny(['EagleView'])
                        allow update: if useroles(siteid).hasAny(['SystemAdmin']) || EntityUpdateBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid)
                        allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || EntityDeleteBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid)
                    }
                match /InternalLiaison/{InternalLiaisonid} {
                        allow create: if EntityCreatePass(siteid,'Billing_Accounts') && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid)
                        allow get: if useroles(siteid).hasAny(['SystemAdmin']) || EntityGetBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid) || useroles(siteid).hasAny(['EagleView'])
                        allow list: if useroles(siteid).hasAny(['SystemAdmin']) || EntityListBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid) || useroles(siteid).hasAny(['EagleView'])
                        allow update: if useroles(siteid).hasAny(['SystemAdmin']) || EntityUpdateBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid)
                        allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || EntityDeleteBUPass(siteid,'Billing_Accounts',billingaccountid) && DBRootParentUserTableFilterKeysPass(siteid,'Billing_Accounts','UserFilterBilling_Accounts',billingaccountid)
                    }
                
                    match /subscriptions/{subscriptionid} {
                        allow get: if BASubcolsReadPass(siteid,billingaccountid)
                        allow list: if BASubcolsReadPass(siteid,billingaccountid)
                        allow update: if existingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || UserisMonetizationOfficer(siteid) && existingData().Account_Type.Name == 'Group Account'
                        allow create:  if incomingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || UserisMonetizationOfficer(siteid) && incomingData().Account_Type.Name == 'Group Account'
                    }
                    match /contentpurchases/{tokenpurchaseid} {
                        allow get: if BASubcolsReadPass(siteid,billingaccountid)
                        allow list: if BASubcolsReadPass(siteid,billingaccountid)
                        allow update: if existingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || UserisMonetizationOfficer(siteid) && existingData().Account_Type.Name == 'Group Account'
                        allow create:  if incomingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || UserisMonetizationOfficer(siteid) && incomingData().Account_Type.Name == 'Group Account'
                    }
                    match /credittokens/{tokenpurchaseid} {
                        allow get: if BASubcolsReadPass(siteid,billingaccountid)
                        allow list: if BASubcolsReadPass(siteid,billingaccountid)
                        allow update: if existingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || UserisMonetizationOfficer(siteid) && existingData().Account_Type.Name == 'Group Account'
                        allow create:  if incomingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || UserisMonetizationOfficer(siteid) && incomingData().Account_Type.Name == 'Group Account'
                    }
                    match /credits/{creditid} {
                        allow get: if BASubcolsReadPass(siteid,billingaccountid)
                        allow list: if BASubcolsReadPass(siteid,billingaccountid)
                    }
                    match /debits/{debitid} {
                        allow get: if BASubcolsReadPass(siteid,billingaccountid)
                        allow list: if BASubcolsReadPass(siteid,billingaccountid)
                    }
            }
            match /journalentries/{journentryid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                allow get: if useroles(siteid).hasAny(['EagleView']) || useroles(siteid).hasAny(['SystemAdmin'])
                allow list: if useroles(siteid).hasAny(['EagleView']) || useroles(siteid).hasAny(['SystemAdmin'])
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }
            
        }

        match /Libraries/{siteid}{
            //understand well now we could have a library moderator
            //typically a person that manages all directories or directory feeds
            allow read: if isSignedIn() && SiteReadPass(siteid)
             match /DocumentationLibraries/{document=**} {  
                allow get: if resource.data.PublishType == 'Public' || isSignedIn() && SiteReadPass(siteid)
                allow list: if resource.data.PublishType == 'Public' || isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin']) || GlobalItemModerator(resource.id.split('_').join(' ')+' Classified Listings',siteid)
                allow create:  if useroles(siteid).hasAny(['System Admin']) || GlobalItemModerator(request.resource.id.split('_').join(' ')+' Classified Listings',siteid)
                }
            match /Directories/{document=**} {  
                allow get: if true;
                allow list: if true;
                allow update:  if useroles(siteid).hasAny(['System Admin']) || GlobalItemModerator(resource.id.split('_').join(' ')+' Classified Listings',siteid)
                allow create:  if useroles(siteid).hasAny(['System Admin']) || GlobalItemModerator(request.resource.id.split('_').join(' ')+' Classified Listings',siteid)
                }
            match /PhotoLibraries/{document=**} {  
                allow get: if isSignedIn() && SiteReadPass(siteid);
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
            match /DocumentLibraries/{document=**} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                } 
            match /classifiedentities/{classifiedentityid} {  
                allow get:  if resource.data.PublishType == 'Public' || isSignedIn() && SiteReadPass(siteid)
                allow list:  if resource.data.PublishType == 'Public' || isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin']) || GlobalItemModerator(classifiedentityid.split('_').join(' ')+' Classified Listings',siteid)
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
            match /wikientities/{wikientityid} {  
                allow get:  if resource.data.PublishType == 'Public' || isSignedIn() && SiteReadPass(siteid)
                allow list:  if resource.data.PublishType == 'Public' || isSignedIn() && SiteReadPass(siteid)
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
                }
        }

        match /SocialNetworks/{siteid} {
            //config inclusive Pages,ContentCategories,SocialChannelRecords,glossary,groups,socialrecords(comments,likes etc),systemactivities
            //okay hold up sitearticles donot play like that nor do other channels need specification here
            allow read: if isSignedIn() && SiteReadPass(siteid)

            match /systemactivities/{systemactivityid} {  
            allow create: if isSignedIn()&& SiteReadPass(siteid)
            allow read: if isSignedIn()&& SiteReadPass(siteid)
            allow write: if isSignedIn()&& SiteReadPass(siteid)
            }
            match /notices/{noticeid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid)
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid)
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid)
                match /responses/{responseid} {
                    allow get: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid) || responseid == request.auth.uid || useroles(siteid).hasAny(['EagleView'])
                    allow list: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid) || responseid == request.auth.uid || useroles(siteid).hasAny(['EagleView'])
                    allow update:  if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid) || responseid == request.auth.uid
                    allow create:  if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid) || responseid == request.auth.uid
                    allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Notices',siteid)
                }      
            }

            match /ArticlesCategories/{catid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if GlobalItemModerator('Articles',siteid)
                allow create: if GlobalItemModerator('Articles',siteid)
            }
            match /BlogsCategories/{catid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if GlobalItemModerator('Blogs',siteid)
                allow create: if GlobalItemModerator('Blogs',siteid)
            }
            match /MeetupsCategories/{catid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if GlobalItemModerator('Meetups',siteid)
                allow create: if GlobalItemModerator('Meetups',siteid)
            }
            match /NoticesCategories/{catid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if GlobalItemModerator('Notices',siteid)
                allow create: if GlobalItemModerator('Notices',siteid)
            }
            match /PollsCategories/{catid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if GlobalItemModerator('Polls',siteid)
                allow create: if GlobalItemModerator('Polls',siteid)
            } 
            match /Pages/{pageid} {  
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if useroles(siteid).hasAny(['System Admin'])
                allow create: if useroles(siteid).hasAny(['System Admin'])
            }
                
            match /sitearticles/{sitearticleid} {  
                // && SiteReadPass(siteid) perhaps insert into SocialReadPass?
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Articles',siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Articles',siteid) && ItemModerator()
                allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass(siteid,'Site Articles') || isSignedIn() && GlobalItemModerator('Articles',siteid) && SocialReadPass(siteid,'Site Articles') || existingData().PublishStatus == ('Published (Public)')
            }
            match /siteblogs/{siteblogid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Blogs',siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Blogs',siteid) && ItemModerator()
                allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass(siteid,'Site Blogs') || isSignedIn() && GlobalItemModerator('Blogs',siteid) && SocialReadPass(siteid,'Site Blogs') || existingData().PublishStatus == ('Published (Public)')
            }        
            match /classifieds/{classifiedid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Classifieds',siteid) || isSignedIn() && SiteReadPass(siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Classifieds',siteid) && request.auth.uid == existingData().Created_Byid
                allow read: if true;
            }
            match /classifiedenquiries/{classifiedenquiryid} {  
                allow create: if incomingData().EnquiryingUserid == request.auth.uid || incomingData().Ownerid == request.auth.uid
                allow update: if existingData().EnquiryingUserid == request.auth.uid || existingData().Ownerid == request.auth.uid
                allow read: if isSignedIn()
            }
            match /siteevents/{siteeventid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Events',siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Events',siteid) && ItemModerator()
                allow read: if isSignedIn()
                match /Attending_Users/{Attending_Userid} {
                    allow get: if isSignedIn()
                    allow list: if NonGuestSocial(siteid) || GuestSocial(siteid)
                    allow update: if GlobalItemModerator('Events',siteid) || isSignedIn() && existingData().recordid == request.auth.uid
                    allow create:  if GlobalItemModerator('Events',siteid) || isSignedIn() && incomingData().recordid == request.auth.uid
                    allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Events',siteid) 
                    }
            }
            match /siteforumdiscussions/{siteforumid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Forums',siteid)
                allow read: if SocialReadPass(siteid,'Site Forums') || existingData().PublishStatus == ('Published (Public)')
                allow write: if isSignedIn() && UserisOwner()
            }
            match /siteforumcomments/{siteforumcommentid} {  
                allow create: if isSignedIn()
                allow read: if isSignedIn()
                allow write: if isSignedIn() && UserisCreator()
            }            
            match /siteforumreplies/{siteforumreplyid} {  
                allow create: if isSignedIn()
                allow read: if isSignedIn()
                allow write: if isSignedIn() && UserisCreator()
            }
            match /sitemeetups/{sitemeetupid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Meetups',siteid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups',siteid) && ItemModerator()
                allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass(siteid,'Site Meetups') || isSignedIn() && GlobalItemModerator('Meetups',siteid) && SocialReadPass(siteid,'Site Meetups') || existingData().PublishStatus == ('Draft') && ItemModerator() || existingData().PublishStatus == ('Published (Public)')
                match /checkins/{checkinid} { 
                    allow create: if IncomingRecordisUser()
                    allow get: if isSignedIn()
                    allow list: if isSignedIn()
                    allow update: if RecordisUser() || ItemModerator()
                    allow delete:  if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Meetups',siteid)
                }
            }
            match /sitepolls/{sitepollid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Polls',siteid)
                allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass(siteid,'Site Polls') || GlobalItemModerator('Polls',siteid) && request.auth.uid == existingData().Ownerid && SocialReadPass(siteid,'Site Polls') || existingData().PublishStatus == ('Published (Public)')
                allow write: if isSignedIn() && request.auth.uid == existingData().Ownerid
                match /votes/{voteid} {  
                    allow create: if isSignedIn()
                    allow read: if NonGuestSocial(siteid) && GetParentSocialRecord(siteid,'sitepolls',existingData().Pollid).PublishStatus != 'Draft' || GuestSocial(siteid) && GetParentSocialRecord(siteid,'sitepolls',existingData().Pollid).PublishStatus != 'Draft' || GetParentSocialRecord(siteid,'sitepolls',existingData().Pollid).PublishStatus == ('Published (Public)') || GlobalItemModerator('Polls',siteid) && request.auth.uid == GetParentSocialRecord(siteid,'sitepolls',existingData().Pollid).Ownerid
                    allow write: if isSignedIn() && request.auth.uid == GetParentSocialRecord(siteid,'sitepolls',existingData().Pollid).Voterid
                    allow delete: if isSignedIn() && request.auth.uid == GetParentSocialRecord(siteid,'sitepolls',existingData().Pollid).Ownerid
                }
            }
            match /sitemoderators/{sitemoderatorid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
            }

            match /socialsiteposts/{socialsitepostid} {  
                allow create: if isSignedIn() && SiteReadPass(siteid)
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow update: if isSignedIn() && SiteReadPass(siteid)
                allow delete: if isSignedIn() && request.auth.uid == existingData().Creatorid && SiteReadPass(siteid) || isSignedIn() && isGroupAdmin(existingData().WallID)
            }
            match /socialsiteimages/{socialsiteimageid} {
                allow create: if isSignedIn() && SiteReadPass(siteid)
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow update: if isSignedIn() && SiteReadPass(siteid)
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid && SiteReadPass(siteid)
            }
            match /sitemaptags/{sitemaptagid} {  
                allow create: if isSignedIn() && request.auth.uid == incomingData().PostOwnerid && SiteReadPass(siteid)
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow update: if isSignedIn() && request.auth.uid == existingData().Created_Byid || isSignedIn() && request.auth.uid == existingData().PostOwnerid
                allow delete: if isSignedIn() && request.auth.uid == existingData().Created_Byid || isSignedIn() && request.auth.uid == existingData().PostOwnerid
            }
            match /socialsitelikes/{socialsitelikeid} {  
                allow create: if isSignedIn() && SiteReadPass(siteid)
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow update: if isSignedIn() && SiteReadPass(siteid)
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Likedbyid
            }
            match /socialsitecomments/{socialsitecommentid} {  
                allow create: if isSignedIn() && SiteReadPass(siteid)
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow update: if isSignedIn() && SiteReadPass(siteid)
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
            }
            match /socialsitereplies/{socialsitereplyid} {  
            // && !incomingData().GroupID || incomingData().GroupID && UserisGroupMember(incomingData().GroupID)
                allow create: if isSignedIn() && SiteReadPass(siteid)
                allow read: if isSignedIn() && SiteReadPass(siteid)
                allow update: if isSignedIn() && SiteReadPass(siteid)
                allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
            }

            match /glossary/{glossaryid} {          
                allow get: if isSignedIn() && SiteReadPass(siteid)
                allow list: if isSignedIn() && SiteReadPass(siteid)
                allow update: if GlobalItemModerator('Glossary',siteid) || useroles(siteid).hasAny(['SystemAdmin'])
                allow create: if GlobalItemModerator('Glossary',siteid) || useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if GlobalItemModerator('Glossary',siteid) || useroles(siteid).hasAny(['SystemAdmin'])
            }

           
            //This definitely needs updating, if group featured members are public..
            match /featuredgroupmembers/{featuredmemberid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
                match /grouptypes/{grouptypeid} {
                    allow get: if isSignedIn()
                    allow list: if isSignedIn()
                    allow update: if useroles(siteid).hasAny(['SystemAdmin']) || isGroupAdmin(existingData().groupid)
                    allow create: if useroles(siteid).hasAny(['SystemAdmin']) || isGroupAdmin(existingData().groupid)
                    allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isGroupAdmin(existingData().groupid)
                }
            }

            match /frequentedsitemeetups/{frequentedsitemeetupid} {  
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups',siteid) || GroupMeetupsModerator(request.auth.uid,incomingData().groupid)
                allow write: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups',siteid) && ItemModerator() || GroupMeetupsModerator(request.auth.uid,existingData().groupid)
                allow read: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups',siteid) || isSignedIn() && GroupMeetupsModerator(request.auth.uid,existingData().groupid)
            }

            
            match /frequentlyaskedquestions/{faqid} {  
                allow get:  if resource.data.PublishType == 'Public' || isSignedIn()
                allow list:  if resource.data.PublishType == 'Public' || isSignedIn()
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
            }

            match /groups/{groupid}/{document=**} { 
                //could add  && SiteisSystem(siteid)
                allow create: if isGroupAdmin(groupid)
                allow get: if isGroupAdmin(groupid) || isGroupTeamMember(groupid) || UserisMonetizationOfficer(siteid)
                allow list: if isGroupAdmin(groupid) || isGroupTeamMember(groupid) || UserisMonetizationOfficer(siteid)
                allow update: if isGroupAdmin(groupid) && UserisGuest(siteid) == false || UserisMonetizationOfficer(siteid) || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().BillingAccountid).Userid == request.auth.uid
            }
            match /groups/{groupid} {       
                allow read: if useroles(siteid).hasAny(['EagleView']) || isSignedIn() && existingData().GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && existingData().GroupPrivacy == 'Non Guest Site Members' || existingData().GroupPrivacy == 'Public' || existingData().Invitees.hasAny([request.auth.uid]) || existingData().GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow create: if GlobalItemModerator('Groups',siteid) || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().BillingAccountid).Userid == request.auth.uid
                allow delete: if isGroupAdmin(groupid)
                match /adminonly/{adminonlyid} {
                    allow create: if isGroupAdmin(groupid)
                    allow delete: if isGroupAdmin(groupid)
                }
                match /invites/{inviteid} {
                    allow get: if RecordisUser()
                    allow list: if RecordisUser()
                    allow update: if RecordisUser()
                    allow create: if isGroupAdmin(groupid)
                    allow delete: if isGroupAdmin(groupid)
                }
                match /pages/{pageid} {
                    //could add  && SiteisSystem(siteid)
                    allow get: if useroles(siteid).hasAny(['EagleView']) || isSignedIn() && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Public' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && GetParentSocialRecord(siteid,'groups',groupid).Invitees.hasAny([request.auth.uid]) || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                    allow list: if useroles(siteid).hasAny(['EagleView']) || isSignedIn() && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Public' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && GetParentSocialRecord(siteid,'groups',groupid).Invitees.hasAny([request.auth.uid]) || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                    allow create: if isGroupAdmin(groupid) && SiteisSystem(siteid)
                    allow update: if isGroupAdmin(groupid)
                    allow delete: if isGroupAdmin(groupid)
                }
                match /pageassets/{pageassetid} {
                    //could add  && SiteisSystem(siteid)
                    allow create: if isGroupAdmin(groupid)
                    allow delete: if isGroupAdmin(groupid)
                }
                //cancelled as is overkill right?
                // match /entities/{entityid}/datarecords/{recordid} {
                //     //could add  && SiteisSystem(siteid)
                //     allow update: if isGroupTeamMember(groupid) || isGroupAdmin(groupid)
                //     allow create: if isGroupAdmin(groupid) || isGroupTeamMember(groupid)
                //     allow delete: if isGroupAdmin(groupid)
                // } 
                match /ArticlesCategories/{catid} {  
                    allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isSignedIn() && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Public' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                    allow write: if isSignedIn() && GroupArticlesModerator(request.auth.uid,groupid)
                    }
                match /BlogsCategories/{catid} {  
                    allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isSignedIn() && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Public' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                    allow write: if isSignedIn() && GroupBlogsModerator(request.auth.uid,groupid)
                    }
                match /MeetupsCategories/{catid} {  
                    allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isSignedIn() && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Public' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                    allow write: if isSignedIn() && GroupMeetupsModerator(request.auth.uid,groupid)
                    }
                match /PollsCategories/{catid} {  
                    allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isSignedIn() && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && UserisGuest(siteid) == false && GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Public' || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentSocialRecord(siteid,'groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                    allow write: if isSignedIn() && GroupPollsModerator(request.auth.uid,groupid)
                    }   
                match /SubscriptionPackages/{packageid} {  
                    //could add  && SiteisSystem(siteid)
                    allow read: if isSignedIn()
                    }            
            }

            match /groupmembers/{groupmemberid} {  
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles(siteid).hasAny(['SystemAdmin'])
                allow create: if useroles(siteid).hasAny(['SystemAdmin'])
                allow delete: if useroles(siteid).hasAny(['SystemAdmin'])
                match /groups/{membergroupid} {
                    allow get: if isSignedIn()
                    allow list: if isSignedIn()
                    allow update: if isGroupAdmin(membergroupid) || isSignedIn() && UserisGuest(siteid) == false && GroupPrivacyType(membergroupid,'Non Guest Site Members') && ExistingBasicPermissionsOnly() && request.auth.uid == groupmemberid || GroupPrivacyType(membergroupid,'All Site Members') && ExistingBasicPermissionsOnly() && request.auth.uid == groupmemberid  || GroupPrivacyType(membergroupid,'Invite Only') && UserisGroupMember(membergroupid) && request.auth.uid == groupmemberid
                    allow create:  if isGroupCreator(membergroupid) && request.auth.uid == groupmemberid || isGroupAdmin(membergroupid) || isSignedIn() && UserisGuest(siteid) == false && GroupPrivacyType(membergroupid,'Non Guest Site Members') && BasicPermissionsOnly() && request.auth.uid == groupmemberid || GroupPrivacyType(membergroupid,'All Site Members') && BasicPermissionsOnly() && request.auth.uid == groupmemberid || UseronGroupInviteList(membergroupid) && request.auth.uid == groupmemberid
                    allow delete: if isSignedIn()
                    match /subscriptions/{subscriptionid} {
                        //could add  && SiteisSystem(siteid)
                        allow get: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow list: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow update: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow create:  if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow delete: if UserisMonetizationOfficer(siteid)
                    }
                    match /credits/{creditid} {
                        //could add  && SiteisSystem(siteid)
                        allow get: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow list: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow update: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow create:  if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow delete: if UserisMonetizationOfficer(siteid)
                    }
                    match /debits/{debitid} {
                        //could add  && SiteisSystem(siteid)
                        allow get: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow list: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow update: if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow create:  if groupmemberid == request.auth.uid || UserisMonetizationOfficer(siteid) || isGroupAdmin(membergroupid)
                        allow delete: if UserisMonetizationOfficer(siteid)
                    }
                }
            }

            match /grouparticles/{grouparticleid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupArticlesGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupArticlesGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && UserisGuest(siteid) == false || existingData().PublishStatus == 'Published (Public)' || GroupArticlesModerator(request.auth.uid,existingData().groupid)
                allow update: if isGroupAdmin(existingData().groupid) || GroupArticlesModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow create:  if isGroupAdmin(incomingData().groupid) || GroupArticlesModerator(request.auth.uid,incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            }

             match /groupclassifieds/{groupclassifiedid} {  
                //still need to make work for groups, per groupmoderator
                allow create: if useroles(siteid).hasAny(['SystemAdmin']) || UserisGroupMember(incomingData().groupid)
                allow update: if useroles(siteid).hasAny(['SystemAdmin']) || UserisGroupMember(existingData().groupid) && request.auth.uid == existingData().authorid
                allow read: if isSignedIn();
            }

            match /groupblogs/{groupblogid} {
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupBlogsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupBlogsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && UserisGuest(siteid) == false || existingData().PublishStatus == 'Published (Public)' || GroupBlogsModerator(request.auth.uid,incomingData().groupid)
                allow update: if isGroupAdmin(existingData().groupid) || GroupBlogsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow create:  if isGroupAdmin(incomingData().groupid) || GroupBlogsModerator(request.auth.uid,incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            }

            match /groupevents/{groupeventid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupEventsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupEventsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && UserisGuest(siteid) == false || existingData().PublishStatus == 'Published (Public)' || GroupEventsModerator(request.auth.uid,existingData().groupid)
                allow update: if isGroupAdmin(existingData().groupid) || GroupEventsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Ownerid
                allow create:  if isGroupAdmin(incomingData().groupid) || GroupEventsModerator(request.auth.uid,incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
                match /Attending_Users/{Attending_Userid} {
                    allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupEventsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && GetParentSocialRecord(siteid,'groupevents',groupeventid).GuestsIncluded || GroupEventsModerator(request.auth.uid,existingData().groupid) && existingData().Ownerid == request.auth.uid
                    allow update: if isGroupAdmin(existingData().groupid) || isSignedIn() && UserisGuest(siteid) == false && GroupEventsPrivacy(existingData().groupid,'Non Guest Site Members') && existingData().recordid == request.auth.uid || isSignedIn() && GroupEventsPrivacy(existingData().groupid,'All Site Members') && existingData().recordid == request.auth.uid || GroupEventsPrivacy(existingData().groupid,'Public') && existingData().recordid == request.auth.uid || GroupEventsPrivacy(existingData().groupid,'Team Members Only') && isGroupTeamMember(existingData().groupid) && existingData().recordid == request.auth.uid || GroupEventsPrivacy(existingData().groupid,'Members Only') && UserisGroupMember(existingData().groupid) && existingData().recordid == request.auth.uid || GroupEventsModerator(request.auth.uid,existingData().groupid) && existingData().Ownerid == request.auth.uid
                    allow create:  if isGroupAdmin(incomingData().groupid) || isSignedIn() && UserisGuest(siteid) == false && GroupEventsPrivacy(incomingData().groupid,'Non Guest Site Members') && incomingData().recordid == request.auth.uid || isSignedIn() && GroupEventsPrivacy(incomingData().groupid,'All Site Members') && incomingData().recordid == request.auth.uid || GroupEventsPrivacy(incomingData().groupid,'Public') && incomingData().recordid == request.auth.uid || GroupEventsPrivacy(incomingData().groupid,'Team Members Only') && isGroupTeamMember(incomingData().groupid) && incomingData().recordid == request.auth.uid || GroupEventsPrivacy(incomingData().groupid,'Members Only') && UserisGroupMember(incomingData().groupid) && incomingData().recordid == request.auth.uid || GroupEventsModerator(request.auth.uid,incomingData().groupid) && incomingData().Ownerid == request.auth.uid
                    allow delete: if isGroupAdmin(existingData().groupid) || GroupEventsModerator(request.auth.uid,existingData().groupid) && existingData().Ownerid == request.auth.uid
                }
            }
    
            match /groupforumdiscussions/{groupforumdiscussionid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(siteid,existingData().groupid) && existingData().GuestsIncluded || GroupForumsGuestSocial(siteid,existingData().groupid) && isSignedIn() && UserisGuest(siteid) == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow update: if isGroupAdmin(existingData().groupid) || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow create:  if isGroupAdmin(incomingData().groupid) || GroupForumsModerator(request.auth.uid,incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            }

            match /groupforumreplies/{groupforumreplyid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(siteid,existingData().groupid) && GetParentSocialRecord(siteid,'groupforumdiscussions',existingData().forumid).GuestsIncluded || GroupForumsGuestSocial(siteid,existingData().groupid) && isSignedIn() && UserisGuest(siteid) == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow update: if isGroupAdmin(existingData().groupid) || request.auth.uid == existingData().Creatorid
                allow create:  if isGroupAdmin(incomingData().groupid) || UserisGroupMember(incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            }
                
            match /groupforumcomments/{groupforumcommentid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(siteid,existingData().groupid) && GetParentSocialRecord(siteid,'groupforumdiscussions',existingData().forumid).GuestsIncluded || GroupForumsGuestSocial(siteid,existingData().groupid) && isSignedIn() && UserisGuest(siteid) == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow update: if isGroupAdmin(existingData().groupid) || request.auth.uid == existingData().Creatorid
                allow create:  if isGroupAdmin(incomingData().groupid) || UserisGroupMember(incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            }

            match /groupforumlikes/{groupforumlikeid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(siteid,existingData().groupid) && GetParentSocialRecord(siteid,'groupforumdiscussions',existingData().forumid).GuestsIncluded || GroupForumsGuestSocial(siteid,existingData().groupid) && isSignedIn() && UserisGuest(siteid) == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow update: if isGroupAdmin(existingData().groupid) || request.auth.uid == existingData().Likedbyid
                allow create:  if isGroupAdmin(incomingData().groupid) || UserisGroupMember(incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            }
                    
            match /groupmeetups/{groupmeetupid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupMeetupsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupMeetupsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && UserisGuest(siteid) == false || GroupMeetupsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow update: if isGroupAdmin(existingData().groupid) || GroupMeetupsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
                allow create:  if isGroupAdmin(incomingData().groupid) || GroupMeetupsModerator(request.auth.uid,incomingData().groupid)
                allow delete: if isGroupAdmin(existingData().groupid)
            match /checkins/{checkinid} { 
                    allow create: if IncomingRecordisUser()
                    allow get: if isSignedIn()
                    allow list: if isSignedIn()
                    allow update: if RecordisUser() || GroupMeetupsModerator(request.auth.uid,incomingData().groupid)
                    allow delete:  if useroles(siteid).hasAny(['SystemAdmin']) || GlobalItemModerator('Meetups',siteid)
                }
            }
        
            match /grouppolls/{grouppollid} {  
                allow read: if isGroupAdmin(existingData().groupid) || GroupPollsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Ownerid || useroles(siteid).hasAny(['EagleView']) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupPollsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupPollsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && UserisGuest(siteid) == false
                allow write: if isGroupAdmin(existingData().groupid) || GroupPollsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Ownerid
                allow create:  if isGroupAdmin(incomingData().groupid) || GroupPollsModerator(request.auth.uid,incomingData().groupid)
                match /votes/{grouppollvoteid} {  
                    allow read: if isGroupAdmin(existingData().groupid) || useroles(siteid).hasAny(['EagleView']) || useroles(siteid).hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupPollsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && GetParentSocialRecord(siteid,'grouppolls',existingData().Pollid).GuestsIncluded || GroupPollsGuestSocial(siteid,existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && UserisGuest(siteid) == false || GroupPollsModerator(request.auth.uid,existingData().groupid)
                    allow update: if isGroupAdmin(existingData().groupid) || GroupPollsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Voterid
                    allow create:  if isGroupAdmin(incomingData().groupid) ||  request.auth.uid == incomingData().Voterid && UserisGroupMember(incomingData().groupid)
                    allow delete: if isGroupAdmin(existingData().groupid)
                }
            }
        }
        match /Websites/{siteid}/{document=**}{
            //config inclusive Pages,ContentCategories,SocialChannelRecords which is only here under "Websites" if public
            allow read: if SiteReadPass(siteid)
            //allow write: if getUserRoleData(siteid).SitesAdmin.hasany([siteid])
        }
        match /systemguides/{systemguideid} {  
            allow get:  if isSignedIn()
            allow list:  if isSignedIn()
            allow update:  if useroles(siteid).hasAny(['System Admin'])
            allow create:  if useroles(siteid).hasAny(['System Admin'])
            match /carouselitems/{carouselitemid} {
                allow get:  if isSignedIn()
                allow list:  if isSignedIn()
                allow update:  if useroles(siteid).hasAny(['System Admin'])
                allow create:  if useroles(siteid).hasAny(['System Admin'])
             }            
            }      

        //System Config End

        //System Public Start
        match /systemdialogs/{systemdialogid} {  
            allow read: if true
        }
        match /passwordresets/{passwordresetid} {  
            allow create: if useroles(SystemID()).hasAny(['SystemAdmin'])
            allow get: if existingData().Expired == false      
            allow list: if useroles(SystemID()).hasAny(['SystemAdmin'])
            allow update: if useroles(SystemID()).hasAny(['SystemAdmin'])
            allow delete: if useroles(SystemID()).hasAny(['SystemAdmin'])
        }
        match /systems/{siteid}/plugins/{pluginid} {  
            allow get: if true      
            allow list: if true
            allow update: if UserisParentOwner('systems',siteid) && incomingData().Active == false && existingData().Active || UserisParentOwner('systems',siteid) && incomingData().Active == existingData().Active
            allow create: if UserisParentOwner('systems',siteid)
        }
        match /systems/{siteid}/apps/{document=**} {  
            allow get: if true      
            allow list: if true
            allow update: if UserisParentOwner('systems',siteid) && incomingData().Active == false && existingData().Active || UserisParentOwner('systems',siteid) && incomingData().Active == existingData().Active
            allow create: if UserisParentOwner('systems',siteid)
        }
        match /entities/{document=**} {  
            allow get: if isSignedIn()      
            allow list: if isSignedIn()
        }`
        if(this.SystemisRA){
            securityRules = securityRules+`
        match /SystemConfig/{siteid}/plugins/{document=**} {  
            allow get: if true      
            allow list: if true
        }
        match /pagepresets/{document=**} {  
            allow get: if true      
            allow list: if true
        }
        match /SystemConfig/{siteid}/apps/{document=**} {  
            allow get: if true      
            allow list: if true
        }`
        }
        securityRules = securityRules+`

        match /Libraries/{siteid}/PhotoLibraries/System_Assets/Assets/{systemassetid} {  
            allow create: if useroles(siteid).hasAny(['SystemAdmin'])
            allow get: if isSignedIn() || existingData().PublishType == 'Public'
            allow list: if isSignedIn() || existingData().PublishType == 'Public'
            allow update: if isSignedIn()
            allow delete: if isSignedIn()                
        }

        
        // match /subscriptionorders/{subscriptionid} {
        //     allow get: if existingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        //     allow list: if existingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        //     allow update: if existingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        //     allow create:  if incomingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',incomingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        // }
        // match /tokentransactions/{tokentransactionid} {
        //     allow get: if existingData().Userid == request.auth.uid || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().CreditedAccountid).Userid == request.auth.uid || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().DebitedAccountid).Userid == request.auth.uid || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().BillingAccountid).Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        //     allow list: if existingData().Userid == request.auth.uid || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().CreditedAccountid).Userid == request.auth.uid || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().DebitedAccountid).Userid == request.auth.uid || GetDBRootParentRecord(siteid,'Billing_Accounts',existingData().BillingAccountid).Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        //     allow update: if UserisMonetizationOfficer(siteid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        //     allow create:  if incomingData().Userid == request.auth.uid || UserisDBRootParentOwner(siteid,'billingaccounts',incomingData().BillingAccountid) || UserisMonetizationOfficer(siteid) || isGroupAdmin(existingData().Groupid) || IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid)
        // }
        //these are temporary to prevent fallover during upgrade, once all upgraded will be removed
        // match /SystemConfig/{siteid}/Pages/{pageid} {  
        //     allow get: if resource.data.PublishType == 'Public' || isSignedIn()
        //     allow list: if resource.data.PublishType == 'Public' || isSignedIn()
        //     allow update: if useroles().hasAny(['System Admin'])
        //     allow create: if useroles().hasAny(['System Admin'])
        // }
        // match /SystemConfig/{siteid}/wikientities/{pageid} {  
        //     allow get: if resource.data.PublishType == 'Public' || isSignedIn()
        //     allow list: if resource.data.PublishType == 'Public' || isSignedIn()
        //     allow update: if useroles().hasAny(['System Admin'])
        //     allow create: if useroles().hasAny(['System Admin'])
        // }
        // match /SystemConfig/{siteid}/classifiedentities/{pageid} {  
        //     allow get: if resource.data.PublishType == 'Public' || isSignedIn()
        //     allow list: if resource.data.PublishType == 'Public' || isSignedIn()
        //     allow update: if useroles().hasAny(['System Admin'])
        //     allow create: if useroles().hasAny(['System Admin'])
        // }
        //these are temporary to prevent fallover during upgrade, once all upgraded will be removed
        //System Public End

        
        `
        vm.$store.commit('setCustomProcessingDialogText','Preparing System Tools')
        securityRules = securityRules+this.PrepareSystemTools()
        //if(vm.SocialNetworkActive){
            vm.$store.commit('setCustomProcessingDialogText','Preparing Social Module')
            securityRules = securityRules+this.PrepareSystemSocialBasic()
        //}        
        //we ignored "featuredmemebrs re public and "businessmemebrs areprivate
        //also FeaturedMembersPublic and System.Guests_can_Social
        vm.$store.commit('setCustomProcessingDialogText','Preparing Data Tables')
                this.PrepareEntitiesSTD(securityRules).then(stdentitiesresult=> {
                    if(stdentitiesresult){
                           vm.$store.commit('setCustomProcessingDialogText','Preparing Wiki')  
                        }                       
                    vm.PrepareEntitiesWiki(securityRules,'Wiki').then(wikientitiesresult=> {
                        securityRules = wikientitiesresult+``
                        if(wikientitiesresult){
                        vm.$store.commit('setCustomProcessingDialogText','Preparing Classified Listings')
                        }
                        vm.PrepareEntitiesWiki(securityRules,'Classified Listings').then(clasfdentitiesresult=> {
                            securityRules = clasfdentitiesresult
                            if(clasfdentitiesresult){
                            vm.$store.commit('setCustomProcessingDialogText','Preparing Store Entities')
                            }
                            vm.PrepareStoreEntities(securityRules).then(storeentitiesresult=> {
                                securityRules = storeentitiesresult
                                if(storeentitiesresult){
                                vm.$store.commit('setCustomProcessingDialogText','Uploading to Server')
                                }
                                vm.ClosingArguments(securityRules)
                            })
                
                        })
                    })
                })          
                    
            },
            PrepareSystemTools(){
                return`

        //System Tools Start 
        match /systemtickets/{systemticketid} {
            allow create: if isSignedIn() && request.auth.uid == incomingData().Created_Byid || EntityCreateCRPass(SystemID(),'System_Tickets')
            allow get: if UserisCreator() || EntityGetCRPass(SystemID(),'System_Tickets') 
            allow list: if UserisCreator() || EntityListCRPass(SystemID(),'System_Tickets')  
            allow update: if EntityUpdateCRPass(SystemID(),'System_Tickets')
        }

        match /RMDocuments/{document=**} { 
            allow read,write: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        match /monetizationpaymentschedule/{scheduleid}/{document=**} { 
            allow get: if isGroupAdmin(existingData().Related_Recordid)
            allow list: if isGroupAdmin(existingData().Related_Recordid)
        }

        
        //System Tools End
        
        `

            },
            PrepareSystemSocialBasic(){
            return`

        //Wide Social Start
        
        match /storedpagepresets/{document=**} {  
            allow get: if true      
            allow list: if true
        }
        match /usermessages/{usermessageid} {  
            allow create: if isSignedIn() && incomingData().senderid == request.auth.uid || isSignedIn() && isGroupTeamMember(incomingData().groupid) 
            allow read: if isSignedIn() && existingData().senderid == request.auth.uid || isSignedIn() && existingData().recipientid == request.auth.uid || isSignedIn() && isGroupTeamMember(existingData().groupid) || isSignedIn() && existingData().IsProductRating == true || existingData().IsGroupRating == true || existingData().IsSiteRating == true || isSignedIn() && isGroupAdmin(existingData().groupid)
            allow write: if isSignedIn() && existingData().senderid == request.auth.uid || isSignedIn() && existingData().recipientid == request.auth.uid || isSignedIn() && isGroupTeamMember(existingData().groupid) || isSignedIn() && isGroupAdmin(existingData().groupid)
        }

        match /friendrequests/{friendrequestid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn()
        }
        match /samplefriendrequests/{samplefriendrequestid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if isSignedIn() && request.auth.uid == existingData().Creatorid || isSignedIn() && isGroupAdmin(existingData().WallID)
        }
        match /samplesocialsiteposts/{socialsitepostid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if isSignedIn() && request.auth.uid == existingData().Creatorid || isSignedIn() && isGroupAdmin(existingData().WallID)
        }
        match /samplesocialsiteimages/{socialsiteimageid} {
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
        }

        match /samplesitemaptags/{sitemaptagid} {  
            allow create: if isSignedIn() && request.auth.uid == incomingData().PostOwnerid
            allow read: if isSignedIn()
            allow update: if isSignedIn() && request.auth.uid == existingData().Created_Byid || isSignedIn() && request.auth.uid == existingData().PostOwnerid
            allow delete: if isSignedIn() && request.auth.uid == existingData().Created_Byid || isSignedIn() && request.auth.uid == existingData().PostOwnerid
        }

        match /samplesocialsitelikes/{socialsitelikeid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Likedbyid
        }

        match /samplesocialsitecomments/{socialsitecommentid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
        }

        match /samplesocialsitereplies/{socialsitereplyid} {  
        // && !incomingData().GroupID || incomingData().GroupID && UserisGroupMember(incomingData().GroupID)
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles(siteid).hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
        }

        //also need for match /storedpagepresets
        match /marketplacetemplates/{templateid}/{document=**} { 
            allow get: if UserisOwner() || existingData().PublishStatus == 'Published' || UserisParentOwner('marketplacetemplates',templateid) && UserRoleBooleanPass(SystemID(),'IsWebDeveloper') || isSignedIn() && GetRootParentRecord('marketplacetemplates',templateid).PublishStatus == 'Published'
            allow list: if UserisOwner() || existingData().PublishStatus == 'Published' || UserisParentOwner('marketplacetemplates',templateid) && UserRoleBooleanPass(SystemID(),'IsWebDeveloper') || isSignedIn() && GetRootParentRecord('marketplacetemplates',templateid).PublishStatus == 'Published'
            allow update: if UserisOwner() && UserRoleBooleanPass(SystemID(),'IsWebDeveloper') || UserisParentOwner('marketplacetemplates',templateid) && UserRoleBooleanPass(SystemID(),'IsWebDeveloper')
            allow create: if UserisNEWrecordOwner() && UserRoleBooleanPass(SystemID(),'IsWebDeveloper') || UserisParentOwner('marketplacetemplates',templateid) && UserRoleBooleanPass(SystemID(),'IsWebDeveloper')
            allow delete: if UserisOwner() && UserRoleBooleanPass(SystemID(),'IsWebDeveloper') || UserisParentOwner('marketplacetemplates',templateid) && UserRoleBooleanPass(SystemID(),'IsWebDeveloper')
        }
          match /plugins/{pluginid} {  
            allow get: if isSignedIn()      
            allow list: if isSignedIn()
             match /carouselitems/{carouselitemid} {  
                allow get: if isSignedIn()      
                allow list: if isSignedIn()
            }
        }
        //Wide Social End`
            },
            
            ClosingArguments(securityRules){
            let vm = this
            //Do not get confused here. NonGuestSocial && GuestSocial simply disappears where SocialReadPass occurs. 
        securityRules = securityRules+`
        /// Function ///
        function SocialReadPass(siteid,channeltype){
            return GuestSocial(siteid) && ActiveMemberGroup(siteid) == null || NonGuestSocial(siteid) && ActiveMemberGroup(siteid) == null ||            
            MemberGroupCanSocial(siteid) && MemberGroupExistingSocialPass(siteid,channeltype)
        }
        function MemberGroupExistingSocialPass(siteid,channeltype){
            return ActiveMemberGroup(siteid).SocialRestrictions == false ||
            ActiveMemberGroup(siteid).SocialRestrictions && ActiveMemberGroup(siteid).SocialChannels.hasAny([channeltype]) && ActiveMemberGroup(siteid).CompanySocial == false ||
            ActiveMemberGroup(siteid).SocialRestrictions && ActiveMemberGroup(siteid).SocialChannels.hasAny([channeltype]) && ActiveMemberGroup(siteid).CompanySocial && existingData().Companyid == MemberCompany(siteid)
        }
        // function MemberGroupIncomingSocialPass(siteid,channeltype){
        //     return ActiveMemberGroup(siteid).SocialRestrictions == false ||
        //     ActiveMemberGroup(siteid).SocialRestrictions && ActiveMemberGroup(siteid).SocialChannels.hasAny([channeltype]) && ActiveMemberGroup(siteid).CompanySocial == false ||
        //     ActiveMemberGroup(siteid).SocialRestrictions && ActiveMemberGroup(siteid).SocialChannels.hasAny([channeltype]) && ActiveMemberGroup(siteid).CompanySocial && incomingData().Companyid == MemberCompany(siteid)
        // }
        function MemberCompany(siteid){
            return getUserRoleData(siteid).Companyid
        }
        function MemberGroupCanSocial(siteid){
            return ActiveMemberGroup(siteid).CanSocial
        }
        function ActiveMemberGroup(siteid){
            //need to also do siteid here soon
            return getUserRoleData(siteid).MemberGroup
        }
        function EntityCreateCRPass(siteid,entityid){
            return GetEntityData(siteid,entityid).CustomCreateRoles.hasAny(useroles(siteid))
        }
        function EntityGetCRPass(siteid,entityid){
            return GetEntityData(siteid,entityid).CustomGetRoles.hasAny(useroles(siteid))
        }
        function EntityListCRPass(siteid,entityid){
            return GetEntityData(siteid,entityid).CustomListRoles.hasAny(useroles(siteid))
        }
        function EntityUpdateCRPass(siteid,entityid){
            return GetEntityData(siteid,entityid).CustomUpdateRoles.hasAny(useroles(siteid))
        }
        function EntityDeleteCRPass(siteid,entityid){
            return GetEntityData(siteid,entityid).CustomDeleteRoles.hasAny(useroles(siteid))
        }
        function EntityWHPass(siteid,entityid,action){
            return EntitySecuritybyWH(siteid,entityid) && WarehouseModerator(siteid,incomingData().Warehouseid,action)
        }
        function CabinetActionPass(siteid,entityid,cabinettype,cabinetid,permission,permissionroles){
            return CabinetActionisCustom(siteid,entityid,cabinettype,cabinetid,permission) &&
            GetCabinetData(siteid,entityid,cabinettype,cabinetid)[permissionroles].hasAny(useroles(siteid))
        }
        function GetCabinetData(siteid,entityid,cabinettype,cabinetid){
            return get(/databases/$(database)/documents/Databases/$(siteid)/entities/$(entityid)/$(cabinettype)/$(cabinetid)).data
        }
        function CabinetActionisCustom(siteid,entityid,cabinettype,cabinetid,permission){
            return GetCabinetData(siteid,entityid,cabinettype,cabinetid)[permission] == 'Custom Role'
        }
        function EntityCreatePass(siteid,entityid){
            return EntitySecuritybyBU(siteid,entityid) && EntityCreateBUPass(siteid,entityid)
            || EntitySecuritybyCR(siteid,entityid) && EntityCreateCRPass(siteid,entityid) || EntityWHPass(siteid,entityid,'Create')
        }
        function EntityGetPass(siteid,entityid,recordid){
            return EntitySecuritybyBU(siteid,entityid) && EntityGetBUPass(siteid,entityid,recordid)
            || EntitySecuritybyCR(siteid,entityid) && EntityGetCRPass(siteid,entityid) || EntityWHPass(siteid,entityid,'Get')
        }
        function EntityListPass(siteid,entityid,recordid){
            return EntitySecuritybyBU(siteid,entityid) && EntityListBUPass(siteid,entityid,recordid)
            || EntitySecuritybyCR(siteid,entityid) && EntityListCRPass(siteid,entityid) || EntityWHPass(siteid,entityid,'List')
        }
        function EntityUpdatePass(siteid,entityid,recordid){
            return EntitySecuritybyBU(siteid,entityid) && EntityUpdateBUPass(siteid,entityid,recordid)
            || EntitySecuritybyCR(siteid,entityid) && EntityUpdateCRPass(siteid,entityid) || EntityWHPass(siteid,entityid,'Update')
        }
        function EntityDeletePass(siteid,entityid,recordid){
            return EntitySecuritybyBU(siteid,entityid) && EntityDeleteBUPass(siteid,entityid,recordid)
            || EntitySecuritybyCR(siteid,entityid) && EntityDeleteCRPass(siteid,entityid) || EntityWHPass(siteid,entityid,'Delete')
        }
        function EntityCreateBUPass(siteid,entityid){
            return EntityCreateALLBUPass(siteid,entityid) || EntityCreateUNITDOWNBUPass(siteid,entityid) || EntityCreateUSERUNITBUPass(siteid,entityid) || EntityCreateOWNERBUPass(siteid,entityid)
        }
        function EntityCreateALLBUPass(siteid,entityid){
            return GetEntityData(siteid,entityid).AllcreateRoles.hasAny(useroles(siteid))
        }
        function EntityCreateUNITDOWNBUPass(siteid,entityid){
            return NEWRecordWithinBusinessUnitStructure(siteid) && GetEntityData(siteid,entityid).UnitDowncreateRoles.hasAny(useroles(siteid))
        }
        function EntityCreateUSERUNITBUPass(siteid,entityid){
            return NEWRecordBusinessunit() && GetEntityData(siteid,entityid).UserUnitcreateRoles.hasAny(useroles(siteid))
        }
        function EntityCreateOWNERBUPass(siteid,entityid){
            return UserisNEWrecordOwner() && GetEntityData(siteid,entityid).OwnercreateRoles.hasAny(useroles(siteid))
        }
        function EntityGetBUPass(siteid,entityid,recordid){
            return EntityGetALLBUPass(siteid,entityid) || EntityGetUNITDOWNBUPass(siteid,entityid,recordid) || EntityGetUSERUNITBUPass(siteid,entityid,recordid) || EntityGetOWNERBUPass(siteid,entityid,recordid)
        }
        function EntityGetALLBUPass(siteid,entityid){
            return GetEntityData(siteid,entityid).AllgetRoles.hasAny(useroles(siteid))
        }
        function EntityGetUNITDOWNBUPass(siteid,entityid,recordid){
            return RecordWithinBusinessUnitStructure(siteid) && GetEntityData(siteid,entityid).UnitDowngetRoles.hasAny(useroles(siteid))
            || ParentRecordWithinBusinessUnitStructure(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UnitDowngetRoles.hasAny(useroles(siteid))
        }
        function EntityGetUSERUNITBUPass(siteid,entityid,recordid){
            return RecordInUserBusinessUnit(siteid) && GetEntityData(siteid,entityid).UserUnitgetRoles.hasAny(useroles(siteid))
            || ParentRecordInUserBusinessUnit(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UserUnitgetRoles.hasAny(useroles(siteid))
        }
        function EntityGetOWNERBUPass(siteid,entityid,recordid){
            return UserisOwner() && GetEntityData(siteid,entityid).OwnergetRoles.hasAny(useroles(siteid))
            || UserisDBParentOwner(siteid,entityid,recordid) && GetEntityData(siteid,entityid).OwnergetRoles.hasAny(useroles(siteid))
        }
        function EntityListBUPass(siteid,entityid,recordid){
            return EntityListALLBUPass(siteid,entityid) || EntityListUNITDOWNBUPass(siteid,entityid,recordid) || EntityListUSERUNITBUPass(siteid,entityid,recordid) || EntityListOWNERBUPass(siteid,entityid,recordid)
        }
        function EntityListALLBUPass(siteid,entityid){
            return GetEntityData(siteid,entityid).AlllistRoles.hasAny(useroles(siteid))
        }
        function EntityListUNITDOWNBUPass(siteid,entityid,recordid){
            return RecordWithinBusinessUnitStructure(siteid) && GetEntityData(siteid,entityid).UnitDownlistRoles.hasAny(useroles(siteid))
            || ParentRecordWithinBusinessUnitStructure(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UnitDownlistRoles.hasAny(useroles(siteid))
        }
        function EntityListUSERUNITBUPass(siteid,entityid,recordid){
            return RecordInUserBusinessUnit(siteid) && GetEntityData(siteid,entityid).UserUnitlistRoles.hasAny(useroles(siteid))
            || ParentRecordInUserBusinessUnit(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UserUnitlistRoles.hasAny(useroles(siteid))
        }
        function EntityListOWNERBUPass(siteid,entityid,recordid){
            return UserisOwner() && GetEntityData(siteid,entityid).OwnerlistRoles.hasAny(useroles(siteid))
            || UserisDBParentOwner(siteid,entityid,recordid) && GetEntityData(siteid,entityid).OwnerlistRoles.hasAny(useroles(siteid))
        }
        function EntityUpdateBUPass(siteid,entityid,recordid){
            return EntityUpdateALLBUPass(siteid,entityid) || EntityUpdateUNITDOWNBUPass(siteid,entityid,recordid) || EntityUpdateUSERUNITBUPass(siteid,entityid,recordid) || EntityUpdateOWNERBUPass(siteid,entityid,recordid)
        }
        function EntityUpdateALLBUPass(siteid,entityid){
            return GetEntityData(siteid,entityid).AllupdateRoles.hasAny(useroles(siteid))
        }
        function EntityUpdateUNITDOWNBUPass(siteid,entityid,recordid){
            return RecordWithinBusinessUnitStructure(siteid) && GetEntityData(siteid,entityid).UnitDownupdateRoles.hasAny(useroles(siteid))
            || ParentRecordWithinBusinessUnitStructure(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UnitDownupdateRoles.hasAny(useroles(siteid))
        }
        function EntityUpdateUSERUNITBUPass(siteid,entityid,recordid){
            return RecordInUserBusinessUnit(siteid) && GetEntityData(siteid,entityid).UserUnitupdateRoles.hasAny(useroles(siteid))
            || ParentRecordInUserBusinessUnit(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UserUnitupdateRoles.hasAny(useroles(siteid))
        }
        function EntityUpdateOWNERBUPass(siteid,entityid,recordid){
            return UserisOwner() && GetEntityData(siteid,entityid).OwnerupdateRoles.hasAny(useroles(siteid))
            || UserisDBParentOwner(siteid,entityid,recordid) && GetEntityData(siteid,entityid).OwnerupdateRoles.hasAny(useroles(siteid))
        }
        function EntityDeleteBUPass(siteid,entityid,recordid){
            return EntityDeleteALLBUPass(siteid,entityid) || EntityDeleteUNITDOWNBUPass(siteid,entityid,recordid) || EntityDeleteUSERUNITBUPass(siteid,entityid,recordid) || EntityDeleteOWNERBUPass(siteid,entityid,recordid)
        }
        function EntityDeleteALLBUPass(siteid,entityid){
            return GetEntityData(siteid,entityid).AlldeleteRoles.hasAny(useroles(siteid))
        }
        function EntityDeleteUNITDOWNBUPass(siteid,entityid,recordid){
            return RecordWithinBusinessUnitStructure(siteid) && GetEntityData(siteid,entityid).UnitDowndeleteRoles.hasAny(useroles(siteid))
            || ParentRecordWithinBusinessUnitStructure(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UnitDowndeleteRoles.hasAny(useroles(siteid))
        }
        function EntityDeleteUSERUNITBUPass(siteid,entityid,recordid){
            return RecordInUserBusinessUnit(siteid) && GetEntityData(siteid,entityid).UserUnitdeleteRoles.hasAny(useroles(siteid))
            || ParentRecordInUserBusinessUnit(siteid,entityid,recordid) && GetEntityData(siteid,entityid).UserUnitdeleteRoles.hasAny(useroles(siteid))
        }
        function EntityDeleteOWNERBUPass(siteid,entityid,recordid){
            return UserisOwner() && GetEntityData(siteid,entityid).OwnerdeleteRoles.hasAny(useroles(siteid))
            || UserisDBParentOwner(siteid,entityid,recordid) && GetEntityData(siteid,entityid).OwnerdeleteRoles.hasAny(useroles(siteid))
        }
        function GetEntityData(siteid,entityid){
            return get(/databases/$(database)/documents/Databases/$(siteid)/entities/$(entityid)).data
        }
        function EntitySecurityStructure(siteid,entityid){
            return GetEntityData(siteid,entityid).datasetsecuritystructure
        }
        function EntitySecuritybyBU(siteid,entityid){
            return EntitySecurityStructure(siteid,entityid) == 'Business Unit'
        }
        function EntitySecuritybyCR(siteid,entityid){
            return EntitySecurityStructure(siteid,entityid) == 'Custom Role'
        }
         function EntitySecuritybyWH(siteid,entityid){
            return EntitySecurityStructure(siteid,entityid) == 'Warehouse'
        }
        function getUserSiteRoleData(siteid) {
        return get(/databases/$(database)/documents/SocialNetworks/$(siteid)/userroles/$(request.auth.uid)).data
        }
        function getUserRoleData(siteid) {
        return get(/databases/$(database)/documents/SystemConfig/$(siteid)/userroles/$(request.auth.uid)).data
        }
        // function getUserRoleData(siteid) {
        // return SiteisSystem(siteid) ?
        // get(/databases/$(database)/documents/userroles/$(request.auth.uid)).data : 
        // get(/databases/$(database)/documents/Databases/$(siteid)/userroles/$(request.auth.uid)).data
        // }
        function isSignedIn() {
        return request.auth != null
        }
        function UserCanInvite(siteid){
        return getUserRoleData(siteid).CanInviteUsers
        }

        function WarehouseModerator(siteid,whid,accesstype) {
        return getUserRoleData(siteid).Warehouses[whid][accesstype]
        }

        function UserisMonetizationOfficer(siteid){
        return getUserRoleData(siteid).MonetizationOfficer
        }
    
        function RecordisUser(){
        return request.auth.uid == resource.id
        }

        function IncomingRecordisUser(){
        return request.resource.id == request.auth.uid
        }

        function existingData() {
        return resource.data
        }

        function incomingData() {
        return request.resource.data
        }

        function useroles(siteid){
        return getUserRoleData(siteid).rolesarrayQuery
        }

        function isAllowedSiteUserSelfUpdate(){
            //we actually should not allow changing name and surname, email etc from subsites
            //although it could be opportunity for user to "mask" for whatever reason.
            return KeyUnaffected('Disabled') && KeyUnaffected('rolesarrayDBRules') && KeyUnaffected('rolesarrayQuery') && KeyUnaffected('Business_Unit')
            && KeyUnaffected('Business_Unitid') && KeyUnaffected('Subordinatearrayquery') && KeyUnaffected('SubordinateArrayDBrules') && KeyUnaffected('ModerationPermissions')
            && KeyUnaffected('Primary_Appid') && KeyUnaffected('MemberGroup') && KeyUnaffected('MemberGroupid') && KeyUnaffected('Sites')
            && KeyUnaffected('IsSystemAdmin') && KeyUnaffected('IsEagleViewer') && KeyUnaffected('IsGuestUser')
            && KeyUnaffected('CanInviteUsers') && KeyUnaffected('InviteGroups') && KeyUnaffected('CanExport') && KeyUnaffected('CanImport')
            && KeyUnaffected('MonetizationOfficer') && KeyUnaffected('BusinessProfileAdmin')
            && KeyUnaffected('IsTeamActivityManager') && KeyUnaffected('SiteContentAdmin')
            && KeyUnaffected('IsSiteCreator') && KeyUnaffected('IsWebDeveloper')
            && KeyUnaffected('CanSocial') && KeyUnaffected('SocialRestrictions') && KeyUnaffected('SocialChannels') && KeyUnaffected('CompanySocial')
            && KeyUnaffected('UserTableFilters') && KeyUnaffected('ActivityQueries') && KeyUnaffected('SecurityRoles')
        }

        // function AllowedSingleKey(key){
        // // This rule only allows updates where "name" is the only field affected, had "key" been "name"
        // return request.resource.data.diff(resource.data).affectedKeys().hasOnly([key]);
        // }

        // function AllowedKeys(keys){
        // //checking multiple keys, but restricting to MUST update ALL given keys same time
        // return request.resource.data.keys().hasOnly([keys]) &&
        // request.resource.data.keys().hasAll([keys]) 
        // }

        // function OneTimeKeyUpdate(key){
        // return isNotExisting(key) || !(key in resource.data));
        // }

        function KeyUnaffected(key){
        return isUnmodified(key) || isNotExisting(key)
        }

        function isUnmodified(key) {
        return request.resource.data[key] == resource.data[key]
        }

        function isNotExisting(key) {
        return !(key in request.resource.data)
        // && (!exists(resource))
        // could add OneTimeKeyUpdate() but that would restrict possible intentions, keep separate
        }

        function UserTableFilterKeysPass(siteid,tableid){
        return (!(tableid in getUserRoleData(siteid) )) || resource.data[getUserRoleData(siteid)[tableid]] == request.auth.uid
        }

        function ParentUserTableFilterKeysPass(siteid,entityid,tableid,parentid){
        return (!(tableid in getUserRoleData(siteid) )) || GetParentDBRecord(siteid,entityid,parentid)[getUserRoleData(siteid)[tableid]] == request.auth.uid
        }

        function DBRootParentUserTableFilterKeysPass(siteid,entityid,tableid,parentid){
        return (!(tableid in getUserRoleData(siteid) )) || GetDBRootParentRecord(siteid,entityid,parentid)[getUserRoleData(siteid)[tableid]] == request.auth.uid
        }

        function UserisGuest(siteid){
        return siteid == SystemID() && PrimarySiteGuestUser() || siteid != SystemID() && !getUserRoleData(siteid).hasAny([siteid])
        }

        function PrimarySiteGuestUser(){
            //Technically should run by site, if siteid == SystemID() then bwlow, otherwise a "Guest" is "non member" of site (site not within userroles "Sites")
        return getUserRoleData(SystemID()).IsGuestUser
        }

        function IsAccountingClerk(siteid,accountingclerkprop,BUid){
        return getUserRoleData(siteid).BusinessAccounting[BUid][accountingclerkprop]
        }

        function getModeratordata(siteid) {
        return get(/databases/$(database)/documents/SocialNetworks/$(siteid)/sitemoderators/$(request.auth.uid)).data
        }

        function GlobalItemModerator(item,siteid){
        return getModeratordata(siteid).ModerationPermissions.hasAny([item])
        }

        function usersubordinates(siteid){
        return getUserRoleData(siteid).SubordinateArrayDBrules.keys()
        }

        function GetRootParentRecord(collectionname,parentid){
        return get(/databases/$(database)/documents/$(collectionname)/$(parentid)).data
        }

        function GetConfigRootParentRecord(siteid,collectionname,parentid){
        return get(/databases/$(database)/documents/SystemConfig/$(siteid)/$(collectionname)/$(parentid)).data
        }

        function GetDBRootParentRecord(siteid,collectionname,parentid){
        return get(/databases/$(database)/documents/Databases/$(siteid)/$(collectionname)/$(parentid)).data
        }

        function GetParentSocialRecord(siteid,collectionname,parentid){
        return get(/databases/$(database)/documents/SocialNetworks/$(siteid)/$(collectionname)/$(parentid)).data
        }

        function GetParentDBRecord(siteid,entityid,parentid){
        return get(/databases/$(database)/documents/Databases/$(siteid)/entities/$(entityid)/Records/$(parentid)).data
        }

        // function ActivityRegardingType()
        // return existingData().Regarding_Type
        // }

        function getBusinessUnitData(siteid) {
        return get(/databases/$(database)/documents/SystemConfig/$(siteid)/businessunits/$(getBusinessUnitID(siteid))).data
        }

        function getBusinessUnitID(siteid) {
        return getUserRoleData(siteid).Business_Unitid
        }

        function ParentRecordWithinBusinessUnitStructure(siteid,entityid,parentid){
        return getBusinessUnitData(siteid).childarrayDBRules.keys().hasAny([GetParentDBRecord(siteid,entityid,parentid).Business_Unitid])
        }

        function ParentRecordInUserBusinessUnit(siteid,entityid,parentid){
        return GetParentDBRecord(siteid,entityid,parentid).Business_Unitid == getBusinessUnitID(siteid)
        }

        function UserisParentOwner(collectionname,parentid){
        return ParentRecordOwner(collectionname,parentid) == request.auth.uid
        }
        
        function UserisDBRootParentOwner(siteid,collectionname,parentid){
        return DBRootParentRecordOwner(siteid,collectionname,parentid) == request.auth.uid
        }

        function UserisDBParentOwner(siteid,entityid,parentid){
        return ParentDBRecordOwner(siteid,entityid,parentid) == request.auth.uid
        }

        function ParentDBRecordOwner(siteid,entityid,parentid){
        return GetParentDBRecord(siteid,entityid,parentid).Ownerid
        }

        function ParentRecordOwner(collectionname,parentid){
        return GetRootParentRecord(collectionname,parentid).Ownerid
        }

        function DBRootParentRecordOwner(siteid,collectionname,parentid){
        return GetDBRootParentRecord(siteid,collectionname,parentid).Ownerid
        }

        function RecordWithinBusinessUnitStructure(siteid){
        return getBusinessUnitData(siteid).childarrayDBRules.keys().hasAny([existingData().Business_Unitid])
        }

        function NEWRecordWithinBusinessUnitStructure(siteid){
        return getBusinessUnitData(siteid).childarrayDBRules.keys().hasAny([incomingData().Business_Unitid])
        }

        function RecordBusinessunit(){
        return existingData().Business_Unitid
        }

        function NEWRecordBusinessunit(){
        return incomingData().Business_Unitid
        }

        function RecordOwner(){
        return existingData().Ownerid
        }

        function NEWRecordOwner(){
        return incomingData().Ownerid
        }    

        function RecordCreator(){
        return existingData().CreatorID
        }

        function UserRoleBooleanPass(siteid,bool){
        return getUserRoleData(siteid)[bool]
        } 

        function UserisOwner(){
        return RecordOwner() == request.auth.uid
        }       

        function UserCanImport(){
        return getUserRoleData(siteid).CanImport
        }

        function UserisNEWrecordOwner(){
        return NEWRecordOwner() == request.auth.uid
        }   
        function CreatedByUser(){
        return existingData().Created_Byid == request.auth.uid
        }
        function UserisCreator(){
        return RecordCreator() == request.auth.uid
        }
        function UserisFollower(){
        return request.auth.uid in existingData().FollowingUserIDStrings
        }

        function UserisAttendee(){
        return request.auth.uid in existingData().AttendeeUserIDStrings
        }    

        function UserisAttendeeManager(siteid){
        return existingData().AttendeeUserIDStrings.hasAny(usersubordinates(siteid))
        } 

        function RecordInUserBusinessUnit(siteid){
        return RecordBusinessunit() == getBusinessUnitID(siteid)
        }`
        if(vm.SocialNetworkActive){
            securityRules = securityRules+`
        function ItemModerator(){
        return existingData().moderatorrolesarrayQuery.hasAny([request.auth.uid])
        }
        //function ParentModerator(collectionname,parentid){
        //return GetParentDBRecord(collectionname,parentid).moderatorrolesarrayQuery.hasAny([request.auth.uid])
        //}              
        function GuestSocial(siteid){
            //Technically should run by site, if siteid !== SystemID() then a "Guest" is "non member" of site
        return UserisGuest(siteid) && GuestsCanSocial(siteid) && existingData().GuestsIncluded
        }

        function NonGuestSocial(siteid){
        return isSignedIn() && UserisGuest(siteid) == false
        }
        function SiteSocialNetwork(siteid){
        return get(/databases/$(database)/documents/SocialNetworks/$(siteid)).data  
        }
        function GuestsCanSocial(siteid){
        return SiteSocialNetwork(siteid).Guests_can_Social
        }
        function isGroupAdmin(groupid){
        return get(/databases/$(database)/documents/groupmembers/$(request.auth.uid)/groups/$(groupid)).data.Administrator    
        }
        function GroupArticlesGuestSocial(siteid,groupid){
        return isSignedIn() && UserisGuest(siteid) == false && GroupArticlesPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupArticlesPrivacy(groupid,'All Site Members') ||
        GroupArticlesPrivacy(groupid,'Public') ||
        GroupArticlesPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupBlogsGuestSocial(siteid,groupid){
        return isSignedIn() && UserisGuest(siteid) == false && GroupBlogsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupBlogsPrivacy(groupid,'All Site Members') ||
        GroupBlogsPrivacy(groupid,'Public') ||
        GroupBlogsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupEventsGuestSocial(siteid,groupid){
        return isSignedIn() && UserisGuest(siteid) == false && GroupEventsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupEventsPrivacy(groupid,'All Site Members') ||
        GroupEventsPrivacy(groupid,'Public') ||
        GroupEventsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupForumsGuestSocial(siteid,groupid){
        return isSignedIn() && UserisGuest(siteid) == false && GroupForumsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupForumsPrivacy(groupid,'All Site Members') ||
        GroupForumsPrivacy(groupid,'Public') ||
        GroupForumsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupMeetupsGuestSocial(siteid,groupid){
        return isSignedIn() && UserisGuest(siteid) == false && GroupMeetupsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupMeetupsPrivacy(groupid,'All Site Members') ||
        GroupMeetupsPrivacy(groupid,'Public') ||
        GroupMeetupsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupPollsGuestSocial(siteid,groupid){
        return isSignedIn() && UserisGuest(siteid) == false && GroupPollsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupPollsPrivacy(groupid,'All Site Members') ||
        GroupPollsPrivacy(groupid,'Public') ||
        GroupPollsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }    
        function isGroupTeamMember(groupid){
        return get(/databases/$(database)/documents/groupmembers/$(request.auth.uid)/groups/$(groupid)).data.IsTeamMember    
        }
        function isGroupCreator(groupid){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.Created_Byid == request.auth.uid    
        }
        function UseronGroupInviteList(groupid){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.Invitees.hasAny([request.auth.uid])    
        }
        function GroupPrivacyType(groupid,item){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.WhocanJoin == item  
        }
        function UserisGroupMember(groupid){
        return get(/databases/$(database)/documents/groupmembers/$(request.auth.uid)/groups/$(groupid)).data.Status == 'Active'   
        }
        function GroupArticlesModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.ArticlesModerator
        }
        function GroupBlogsModerator(userid,groupid){      
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.BlogsModerator
        }
        function GroupEventsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.EventsModerator
        }
        function GroupForumsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.ForumsModerator
        }
        function GroupMeetupsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.MeetupsModerator
        }
        function GroupPollsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.PollsModerator
        }
        function GroupBlogsPrivacy(groupid,item){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.BlogPrivacy == item
        }
        function GroupArticlesPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.ArticlesPrivacy == item
        }
        function GroupForumsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.ForumPrivacy == item
        }
        function GroupPollsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.PollsPrivacy == item
        }
        function GroupEventsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.EventsPrivacy == item
        }
        function GroupMeetupsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.MeetupsPrivacy == item
        }
        function BasicPermissionsOnly(){
        return incomingData().Administrator != true && 
        incomingData().ForumsModerator != true  && 
        incomingData().MeetupsModerator != true  && 
        incomingData().BlogsModerator != true  && 
        incomingData().EventsModerator != true && 
        incomingData().ArticlesModerator != true && 
        incomingData().PollsModerator != true
        }
        function ExistingBasicPermissionsOnly(){
        return existingData().Administrator != true && 
        existingData().ForumsModerator != true  && 
        existingData().MeetupsModerator != true  && 
        existingData().BlogsModerator != true  && 
        existingData().EventsModerator != true && 
        existingData().ArticlesModerator != true && 
        existingData().PollsModerator != true
        }
        function UserisUser(){
        return existingData().Userid == request.auth.uid
        }
        function UserisBAUser(siteid,billingaccountid){
        return GetDBRootParentRecord(siteid,'billingaccounts',billingaccountid).Userid == request.auth.uid
        }
        function CompanyPOC(siteid){
        return GetConfigRootParentRecord(siteid,'usercompanies',existingData().Companyid).Userid == request.auth.uid
        }
        function BAOwnerProtection(){
        return existingData().Userid == incomingData().Userid && existingData().Account_Type.Name == incomingData().Account_Type.Name
        }
        function BACreditProtection(){
            //or could do !('Credit_Limit' in existingData()) || existingData().Credit_Limit == incomingData().Credit_Limit ?
        return existingData().Credit_Limit == incomingData().Credit_Limit
        }
        function BACreditOverride(siteid){
        return GetConfigRootParentRecord(siteid,'usercompanies',existingData().Companyid).Account_Limits_by_Admin &&
        CompanyPOC(siteid)
        }
        function BATypeProtection(){
        return existingData().Billing_Type.Name == incomingData().Billing_Type.Name
        }
        function BAUpdatePass(siteid){
        return BAUserCreditUpdate() || BACompanyCreditUpdate(siteid) || BANONCreditUpdate(siteid)
        }
        function BAUserCreditUpdate(){
        return existingData().Billing_Type.Name == 'Credit' && 
        BACreditProtection() &&
        UserisUser() && BAOwnerProtection()
        }
        function BACompanyCreditUpdate(siteid){
        return existingData().Billing_Type.Name == 'Credit' && 
        BACreditOverride(siteid)
        }
        function BANONCreditUpdate(siteid){
        return existingData().Billing_Type.Name != 'Credit' && 
        UserisUser() || existingData().Billing_Type.Name != 'Credit' &&
        CompanyPOC(siteid)
        }
        function BAReadPass(siteid){
        return UserisUser() || 
        CompanyPOC(siteid) || IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid) ||
        IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid) || 
        IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid)
        }
        function BASubcolsReadPass(siteid,billingaccountid){
        return ParentBACompanyPOC(siteid,billingaccountid) || 
        UserisBAUser(siteid,billingaccountid) || UserisDBRootParentOwner(siteid,'billingaccounts',billingaccountid) || 
        UserisMonetizationOfficer(siteid) && existingData().Account_Type.Name == 'Group Account' || 
        IsAccountingClerk(siteid,'Debtor',existingData().Business_Unitid) || 
        IsAccountingClerk(siteid,'Creditor',existingData().Business_Unitid)
        }
        function SiteData(siteid){
        return get(/databases/$(database)/documents/SystemConfig/$(siteid)).data
        }
        function WebsiteData(siteid){
        return get(/databases/$(database)/documents/Websites/$(siteid)).data
        }
        function UserisRecordAuth(auth){
        return request.auth.uid == exitingData()[auth]
        }
        function UserisSiteAuth(siteid,auth){
        return request.auth.uid == SiteData(siteid)[auth]
        }
        function SiteReadPass(siteid){
        //There really should be a DBReadPass as well    
        //and if you not authorative person you can only read if it is PublishStatus == 'Published'
        return SiteisSystem(siteid) || UserisSiteAuth(siteid,'Clientid') || UserisSiteAuth(siteid,'Ownerid') || WebsiteData(siteid).Is_Directory_Listing || existingData().Is_Directory_Listing
        }
        function ParentBACompanyPOC(siteid,billingaccountid){
        return GetConfigRootParentRecord('usercompanies',GetParentDBRecord(siteid,'Billing_Accounts',billingaccountid).Companyid).Userid == request.auth.uid
        }
        function SiteisSystem(siteid){
        return  siteid ==  SystemID()
        }
        function SystemID(){
        return '`+process.env.VUE_APP_RA_SYSTEM_ID+`'  
        }
        function SystemisRA(){
        return SystemID() == '`+process.env.VUE_APP_RA_PROVIDER_ID+`'
        }
        `        
            // }
        }

        securityRules = securityRules+
`        
    }
}`
//STD

//getBusinessUnitData() STD CONFIG
//UserisOwner STD TOOLS
//UserisCreator STD TOOLS (activities)
//RecordOwner STD TOOLS (activities)
//UserCanImport STD TOOLS (imports)

//ItemModerator if has socialcomp

//if groups...
//isGroupAdmin if groups 
//UserisGroupMember if groups
//GroupEventsModerator if groups
//GroupPollsModerator
//GroupBlogsModerator
//GroupArticlesModerator
//UseronGroupInviteList
//GroupForumsModerator


//** featuredgroupmembers only if groups

            //console.log(securityRules)
            
            this.FinalPublish(securityRules)
        },
        
        FinalPublish(securityRules){
            let vm = this
            let req = {
                securityRules: securityRules
            }
            console.log(securityRules)
            const functions = firebase.functions();
            const UpdateSystemSecurityRules = functions.httpsCallable('UpdateSystemSecurityRules');
            UpdateSystemSecurityRules(req).then(result => {
            console.log(result)
            let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Updated the Server with all new Configuration.',
              snackbartop: true,
              snackbarmultiLine: true
            }
            vm.$store.commit('setCustomProcessingDialogText','Done')
            vm.$store.commit('setCustomProcessingDialog',false)
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            }).catch(error => {
                if(error){
                    vm.$store.commit('setCustomProcessingDialogText','Something Went Wrong...try again?')
                    vm.$store.commit('setAllowCloseCustomProcessingDialog',true)
                }
        });
        },
    }
}
</script>

<style>

</style>