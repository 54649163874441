<template>
<div>
<v-list-item>
    <v-list-item-content style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action v-if="!Editing" style="font-weight: 300;">
        {{FieldDisplayValue}}
    </v-list-item-action>
    <v-list-item-action v-if="Editing">
       <v-menu 
                    v-model="DateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field 
                        :label="field.DisplayName"
                        prepend-icon="mdi-calendar-month"
                        readonly  :rules="field.IsMandatory? [$store.state.formrules.required] : []"
                        v-on="on"
                        v-model="DateString"
                        ></v-text-field>
                        </template>
                    <v-date-picker  v-model="DateString" :readonly="field.ReadOnly"
                            @input="DateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-menu v-if="field.HasTimeInput"
                        v-model="TimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        id="fieldTimeMenu"
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                            v-model="TimeResponse"
                            :label="field.Name+' Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            :id="field.Name+'Time'"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="TimeResponse"
                            @input="TimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
    </v-list-item-action>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {            
          DateMenu: false,
          TimeMenu: false,
          DateString: '',
          TimeResponse: '',
        }
    },	
    computed:{
        DateObject(){
        let dateobject = ''
        if(!this.field.HasTimeInput){
            dateobject = new Date(this.DateString)
        }
        else if(this.field.HasTimeInput){                       
            dateobject = new Date(this.DateString+' '+this.TimeResponse)
        }
        return dateobject
        },
    FieldDisplayValue(){
        return this.FieldValue.toDate ? this.DateFieldValue(this.FieldValue.toDate(),this.field) :
        this.DateFieldValue(this.FieldValue,this.field)
    },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        Record: {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    this.AssignDateValue()
                }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.AssignDateValue()
    },
    methods:{
        DateFieldValue(javadate,field,direct,asobject){
            let date = ''
            if(typeof javadate === 'string'){
                date = new Date(javadate)
            }
            else{
                date = javadate
            }
            if(!direct){
             if(field.HasTimeInput){
                return date
                }
                else{
                    return date.toDateString()
                }   
            }
            else{
                if(field.HasTimeInput){
                    return date+this.TimeResponse
                }
                else{
                    return date.toDateString()
                }
            }
            
        },
        AssignDateValue(){
            if(this.FieldValue){
                let dateobject = this.FieldValue.toDate ? this.FieldValue.toDate() : this.FieldValue
                this.DateString = format(dateobject,'yyyy-MM-dd')
                if(this.field.HasTimeInput){
                    let hours = dateobject.getHours()
                    let mins = dateobject.getMinutes()
                    if(hours === 0){
                    hours = '00'
                    }
                    if(mins === 0){
                    mins = '00'
                    }
                    this.TimeResponse = hours+':'+mins
                    //this.Record[field.Name+'TimeResponse'] = field.TimeResponse
                }
            }
        },
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
    }
}
</script>

<style>

</style>



