<template>
  <div>
      <v-btn @click="AddStoreArticles(Articles)" color="purple" dark style="width: 100%;">Publish Items</v-btn>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','CurrentEntity','Articles'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        AddStoreArticles(items){
            items.map(item => {
                this.AddStoreArticle(item)
            })
        },
        AddStoreArticle(item){
      let record = item
      let StoreGalleryItems = item.Gallery
      //console.log(record)
      let img = ''
      if(record.Image){
          img = record.Image
      }
      let storeobj = {  
        // Backorder: this.RecordData.Backorder,
        //Inbound: this.RecordData.Inbound,
        FullScreenDialog: true,
          EntityID: this.CurrentEntity.id,               
          recordid: record.id,                  
          IsDataRecord: true,                 
          Center: 'justify-center',                 
          CenteredHeader: true,                 
          Name: 'Store Product Single',                 
          Photo: img,                                    
          show: false,                  
          HasBGIMG: true,                 
          BGIMGisIMG: true,                 
          ShowsPrice: true,                 
          SubHeaderFontFamily: 'Roboto',                  
          SubHeaderFontSize: 18,                  
          SubHeaderColor: {                 
              hex: '#FF0000FF'                  
          },                  
          HeaderFontFamily: 'Roboto',                 
          HeaderFontSize: 14,                 
          HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },                 
          FontSize: 10,                 
          FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },                 
          HasActionButton: true,                  
          ActionButtonName: 'Add to Cart',                  
          ActionBtnDark: true,                  
          ActionButtonType: 'Add Cart Item',                  
          ButonColor: {                 
              hex: '#FF0000FF'                  
          },                  
          ButtonAlignment: 'justify-end',                 
          Title: record['Product_Code'],                 
          Description: `<div style="text-align: center;">`+record['Descriptive_Text']+`</div>`,                 
                                              
          FlexXLRG:3,                 
          FlexLarge: 3,                 
          FlexMedium: 3,                  
          FlexSmall: 6,                 
          FlexXSmall: 12,                 
          Cols: 3,                  
          Justify: 'justify-center',                  
          RowColor: '#00ffff',                  
          ShowComponents: true,                 
          Icon: 'mdi-cards',                  
          MarginTop: 0,                 
          MarginBottom: 0,                  
          MarginLeft: 0,                  
          MarginRight: 0,                 
          BGColor: {                  
                        hex: '#ffffff',     
                        hexa: '#ffffffff',            
                        rgba: {r: 255,g: 255,b: 255}                  
                },
          IsDark: true,               
          } 
          if(record.Purchased_Counter){
            storeobj.Purchased_Counter = record.Purchased_Counter
          }
          else{
            storeobj.Purchased_Counter = 0
          }
          if(record.Purchased_With){
            storeobj.Purchased_With = record.Purchased_With
          }
          else{
            storeobj.Purchased_With = {}
          }
          storeobj.Description = record['Product_Code'] 
          //console.log(this.CurrentEntity.StorePriceField,record[this.CurrentEntity.StorePriceField.Name])
          // if(this.CurrentEntity.StorehasFilter && this.CurrentEntity.StoreFilters){
          //   this.CurrentEntity.StoreFilters.map(filter => {
          //     storeobj[filter.Name] = filter.Response
          //     storeobj[filter.Name].IsStoreFilter = true
          //   })
          // } 
          if(this.CurrentEntity.StoreImageField && record[this.CurrentEntity.StoreImageField.Name]){                  
              storeobj.HasImage = true                  
              storeobj.IMG = record['Image']['ThumbURL']                  
          } 
          
          //console.log(this.CurrentEntity.StorePriceField)                
          if(record.Store_Price){    
                          
              storeobj.Price = record.Store_Price                 
          }
          if(this.CurrentEntity.StoreShips){
            storeobj.ItemShips = true
          }
          if(this.CurrentEntity.StoreAllowsRating){
            storeobj.AllowsRating = true
          }
          if(this.CurrentEntity.StorehasUsed && this.CurrentEntity.StoreUsedField && record[this.CurrentEntity.StoreUsedField.Name]){
            storeobj.Condition = record[this.CurrentEntity.StoreUsedField.Name]
          }   
          let brandfield = this.CurrentEntity.AllFields.find(obj => obj.id === 'Store_Product_Brand')
          if(brandfield && record[brandfield.Name]){
            // && record[brandfield.Name] previously omitted but with reason get back to it when it applies again
            //console.log(record[brandfield.Name])
            if(brandfield.Type === 'Lookup' && record[brandfield.Name]){
              storeobj.Store_Product_Brand = {
                ID: record[brandfield.Name].id,
                Name: record[brandfield.Name][brandfield.LookupFieldName]
              }
              
            let brandentity = this.SystemEntities.find(obj => obj.id === brandfield.RelatedBuildID)
            //console.log(brandentity)
            if(brandentity){
              //has it got brand logo field? Any single file upload with featured photo can be marked as such but will only take one
              let brandlogofield = brandentity.AllFields.find(obj => obj.Store_Brand_Logo)
              if(brandlogofield && record[brandfield.Name][brandlogofield.Name]){
                storeobj.Store_Product_Brand.Logo = record[brandfield.Name][brandlogofield.Name].fileurl
              }
              // if(brandentity.Has_Wiki){
              //   let singlenamesplit = brandentity.SingleName.split(' ').join('')
              //   storeobj.Store_Product_Brand.WikiPath = '/'+singlenamesplit+'-Wiki/'+record[brandfield.Name].id
              // }
              let Store_Read_Morefield = brandentity.AllFields.find(obj => obj.Store_Read_More)
              if(Store_Read_Morefield && record[brandfield.Name][Store_Read_Morefield.Name]){
                storeobj.Store_Product_Brand.Store_Read_MoreLink = record[brandfield.Name][Store_Read_Morefield.Name]
              }
              let Store_Writeupfield = brandentity.AllFields.find(obj => obj.Store_Writeup)
              if(Store_Writeupfield && record[brandfield.Name][Store_Writeupfield.Name]){
                storeobj.Store_Product_Brand.Store_Writeup = record[brandfield.Name][Store_Writeupfield.Name]
              }
              
            }
            let payl = {
                Navlistid: this.CurrentEntity.id,
                NavListSubColId: brandfield.RelatedBuildID,
                Data: storeobj.Store_Product_Brand,
                siteid: this.$store.state.ActiveSuiteid
              }
              const functions = firebase.functions();
                const AppendCatNavlistLookupFilter = functions.httpsCallable('AppendCatNavlistLookupFilter');
                AppendCatNavlistLookupFilter(payl).then(result => {
                  //  vm.DeactivateReceivingDialog()
                    console.log(result)
                  }) 
            }
            else{
              storeobj.Store_Product_Brand = record[brandfield.Name]
              let payl = {
                Navlistid: this.CurrentEntity.id,
                NavListSubColId: brandfield.RelatedBuildID,
                Data: storeobj.Store_Product_Brand,
                siteid: this.$store.state.ActiveSuiteid
              }
              const functions = firebase.functions();
                const AppendCatNavlistLookupFilter = functions.httpsCallable('AppendCatNavlistLookupFilter');
                AppendCatNavlistLookupFilter(payl).then(result => {
                  //  vm.DeactivateReceivingDialog()
                    console.log(result)
                  }) 
            }
          }
          
          //maybe simple enough. get brandfield, if brandfield it has "brand". 
          //check for brandfield entity while you at it. find and check if has wiki
          //if has wiki and publish type same as this (we skipped that step anyway but ja), then...Hmm autoamtically?? add wiki path
          //store single at this stage is static. We can just add it there. Like a button. And IF the item is in fact slotted with "wikiPath" then it's active. takes you to it
          //now just make sure don't activate social navbar in the process, IF not gustscansocial
          if(this.CurrentEntity.StorehasBrands && this.CurrentEntity.StoreBrandField && record[this.CurrentEntity.StoreBrandField.Name]){
            if(this.CurrentEntity.StoreBrandField.Type === 'Lookup'){
              storeobj.Store_Product_Brand = {
                ID: record[this.CurrentEntity.StoreBrandField.Name].id,
                Name: record[this.CurrentEntity.StoreBrandField.Name][this.CurrentEntity.StoreBrandField.LookupFieldName]
              }
            }
            else{
              storeobj.Store_Product_Brand = record[this.CurrentEntity.StoreBrandField.Name]
            }
            
          }    
          
          //storeobj.Site = {Site: this.ActiveStore.Name,id: this.ActiveStore.id}
         // storeobj.Siteid = this.ActiveStore.id
         let HeirarchyField = this.CurrentEntity.AllFields.find(obj => obj.id === 'Product_Group')
          if(HeirarchyField){
            let fieldobj = Object.assign({},HeirarchyField)
            
            fieldobj.Levels.map(lvl => {
              delete lvl.children
              if(record[lvl.FieldName]){
                storeobj[lvl.FieldName] = {
                  ID: record[lvl.FieldName].ID,
                  EntityID: this.CurrentEntity.id,
                  Name: record[lvl.FieldName].Name,
                  Level: lvl,
                  LevelFieldName: record[lvl.FieldName].LevelFieldName,
                }
              }
            })            
          }
          let descrtextelmnt = document.getElementById('DescriptiveText')
          if(descrtextelmnt){
            storeobj.DescriptiveText = descrtextelmnt.innerHTML
          }
          storeobj.DescriptiveText = record.Descriptive_Text
          //console.log(record)
          if(record.Stock_Qty){
            storeobj.Stock_Qty = record.Stock_Qty
          }
          if(record.Discounted_Price){
            storeobj.Discounted_Price = record.Discounted_Price
          }
          if(record.Discount){
            storeobj.Discount = record.Discount
          }
          if(record.Purchase_Leadtime){
            storeobj.Purchase_Leadtime = record.Purchase_Leadtime
          }
          if(record.Supply_Leadtime){
            storeobj.Supply_Leadtime = record.Supply_Leadtime
          }
          //console.log(record)
          if(StoreGalleryItems && StoreGalleryItems.length > 0){
            storeobj.Assets = StoreGalleryItems.map(image => {
              let obj = {
                ThumbURL: image.ThumbURL,
                URL: image.fileurl
              }
              return obj
            })
          }
          if(storeobj.Photo){
             storeobj.IMG = storeobj.Photo.ThumbURL  
          }         
            //console.log(storeobj,record)
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()+'store').doc(record.id).set(storeobj)
    },
    }
}
</script>

<style>

</style>



