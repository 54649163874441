<template>
  <v-main style="margin-top: 60px;">
      <v-dialog max-width="800" v-model="ProcessPickingSlipDialog">
          <v-card v-if="SelectedPickingSlip">
              <v-card-title class="justify-space-between mediumoverline recordtoolbar white--text">
                  Process Picking Slip {{SelectedPickingSlip.id}}
                  <v-spacer>
                      </v-spacer>
                      <v-chip v-if="SelectedPickingSlip.LookupObj">
                      {{SelectedPickingSlip.LookupObj.id}}
                  </v-chip>
                  
              </v-card-title>
              <v-card-text>
              <v-card-text>
                  <span v-html="ProcessingDescription">
                  </span>
              </v-card-text>
                   <v-card-text style="padding:30px;">
                    <LineItemsComponent :Line_Items="SelectedLineItems" :ParentDisabled="true" :System="System" :SystemEntities="SystemEntities" :Type="'Picking'"
                    @UpdateProgress="UpdateProgress"/>
                </v-card-text>
              </v-card-text>
              <v-card-actions>
                  <v-btn @click="DeactivateProcessPickingSlipDialog()" dark color="warning">Cancel</v-btn>
                  <v-spacer>
                  </v-spacer>
                  <v-btn @click="PreparePickingSlipProcessing()" dark color="success">Process</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <v-card-title  :class="!MiniView ? 'mediumoverline recordtoolbar white--text' : 'subtleoverline recordtoolbar white--text'">
              <v-icon :size="MiniView ? 30 : 45" dark>mdi-hand-okay</v-icon>Picking <v-spacer></v-spacer> {{ActiveWarehouse.Name}}
          </v-card-title>
      <v-layout  class="justify-center" row style="margin-top:60px;">
     <v-flex lg11 md11>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       <v-toolbar-title>Picking Slips</v-toolbar-title>
      
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        Select Picking Slip
       > Process Picking
        <v-spacer></v-spacer>
        <v-text-field 
          v-model="PickingSlipSearch"
          append-icon="mdi-file-find-outline"
          label="Search PR"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="PickingSlipSearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
          <!-- <v-dialog v-model="WarehouseDialog" width="400">
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="recordtoolbar white--text">
                New Warehouse
              </v-card-title>
              <v-card-text>
                <FieldsForm :FormFields="OpenPRHeaders" :Validating="Validating" :DataTableView="true" @save="SaveWarehouse" :DataObject="editedWarehouse" :tab="{}" />
               
              </v-card-text>
               <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="CloseWarehouseDialog()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
     </v-toolbar>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       
       <v-list-item dense>
         <v-list-item-content>
            <v-select :items="SelectablePickingSlips" @change="ClearSelectedLineItems()" clearable item-text="id" return-object label="Slip Number" v-model="SelectedPickingSlip"
       />
        </v-list-item-content>
         <!--  <v-list-item-content>
            <v-select :items="SelectableSuppliers" @change="ClearSelectedLineItems(),CheckForSupplier()" clearable item-text="Supplier_Name" return-object label="Supplier" v-model="SelectedSupplier"
       />
         </v-list-item-content> -->
         <v-list-item-action>
          <v-btn :disabled="!EnableSelect || SelectedLineItems.length === 0" @click="ActivateProcessPickingSlipDialog()" >Process</v-btn>
          <!-- what if cancel or return? -->
         </v-list-item-action>
       </v-list-item>      
     </v-toolbar>
     </v-flex>
   </v-layout>
    <v-layout class="justify-center" row>
     <v-flex lg10 md10>
       <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="PickingSlipHeaders"
      :items="PickingSlipsSearched"
      :show-select="EnableSelect"      
      item-key="id"
      v-model="SelectedLineItems"
     
     :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
      >
       <!-- <template v-slot:[`item.Warehouse.Name`]="{ item }">
        <v-btn outlined fab small :to="'/Warehouse/'+item.Warehouse.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.Warehouse.Name}}
      </template> -->
      <template v-slot:[`item.StockObj.Item_Name`]="{ item }">
        <v-btn outlined fab small :to="'/'+item.StockObj.single.split(' ').join('')+'/'+item.StockObj.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.StockObj.Item_Name}}
      </template>
      <template v-slot:[`item.Sales_Order.Name`]="{ item }">
        <v-btn outlined fab small :to="item.Sales_Order.Path ? item.Sales_Order.Path : ''" class="elevation-6 accent--text"><v-icon small >mdi-cash-register</v-icon></v-btn>
        {{item.Sales_Order.Name}}
      </template>
       </v-data-table>
      </v-flex>
  </v-layout>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LineItemsComponent from '@/components/SuitePlugins/Warehousing/LineItemsComponent';

export default {
    props: ['System','SystemEntities'],
    components: {LineItemsComponent},
    data() {
        return {
            PickingSlipsLineItems: [],
            SelectedPickingSlip: '',
            PickingSlipSearch: '',
            SelectedLineItems: [],
            PickingSlipHeaders: [
              {text: 'Stock Obj',value: 'StockObj.Item_Name',class: 'overline'},
              {text: 'Qty',value: 'Qty',class: 'overline'},
              {text: 'Sales Order',value: 'Sales_Order.Name',class: 'overline'}
            ],
            ProcessPickingSlipDialog: false,
        }
    },
    computed:{
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        WarehouseApp(){           
            return this.AppsDataBase.find(obj => obj.id === this.ViewWarehouse.Primary_Appid)
        },
        ViewWarehouse(){
          return this.Warehouses.find(obj => obj.id === this.$route.params.id)
        },        
        ActiveWarehouse(){
            return this.$store.state.ActiveWarehouse
        },        
        Warehouses(){
            return this.$store.state.WarehousesArray
        },
        SelectablePickingSlips(){
        return this.PickingSlipsSearched.map(pr => {
            let pickginobj = {id: pr.Transaction_Docid,LookupObj: pr.LookupObj}
            return pickginobj
          })
      },
        EnableSelect(){
        return this.SelectedPickingSlip && this.SelectedPickingSlip.id
      },
        PickingSlipsSearched(){
          return this.PickingSlipsLineItems
          .map(pr => {
              let so = {Name: ''}
                let today = new Date()
                if(pr.LookupObj){
                so.Name = pr.LookupObj[pr.LookupObj.identifier]
                so.Path = '/'+pr.LookupObj.single.split(' ').join('')+'/'+pr.LookupObj.id
                }
                pr.Sales_Order = so
                return pr
            })
          .filter(pr => {
            if(this.PickingSlipSearch){
              return pr.LookupObj && pr.LookupObj[pr.LookupObj.identifier] && pr.LookupObj[pr.LookupObj.identifier].toLowerCase().includes(this.PickingSlipSearch.toLowerCase())
            }
            else{
              return pr
            }
          })
          .filter(item => {
              if(this.SelectedPickingSlip && this.SelectedPickingSlip.id){
                  return item.Transaction_Docid === this.SelectedPickingSlip.id
              }
              else{
                  return item
              }
          })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        UserWarhouseObj(){
            return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
        },
        UserWarehouseObj(){
            return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
        },
        OrderEntity(){
            return this.SelectedPickingSlip ? this.SystemEntities.find(obj => obj.id === this.SelectedPickingSlip.LookupObj.list.split(' ').join('_')) : ''
        },
        OrderEntityInvoiceCycle(){
            return this.OrderEntity && this.OrderEntity.Warehouse_Dispatch_After ? this.OrderEntity.Warehouse_Dispatch_After.Name : ''
        },
        TransactionTriggersDispatching(){
            if(this.OrderEntityInvoiceCycle){
                if(this.OrderEntityInvoiceCycle === 'Picking'){
                    return true
                }
            }
        },
        ProgressItems(){
            return [
                {ID: 1000001,Name: 'Issued'},
                {ID: 1000002,Name: 'In Progress'},
                {ID: 1000003,Name: 'Returned'},
                {ID: 1000004,Name: 'Cancelled'},
                {ID: 1000005,Name: 'Completed'}
            ]
        },
        LineItemDBBase(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.id).collection('PickingSlipsLine_Items')
        },
        ParentDBBase(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.id).collection('PickingSlips') 
        },
        ProcessingDescription(){
            if(this.SelectedPickingSlip){
              let msg = `Processing this document will complete the below items for `+this.SelectedPickingSlip.LookupObj.id+`.`
            if(this.TransactionTriggersDispatching){
                msg = msg+`
                as "Ready for Dispatching"`
            }
            msg = msg+`.<br>
                Do you want to continue?`
            return msg  
            }
            else{
                return ''
            }
            
        }
    },
    watch: {
        WarehouseApp(v){
            if(v){
                if(!this.ActiveSuiteApp){
                this.$store.commit('setActiveSuiteApp',this.WarehouseApp)   
                }
            }
        }
    },
    created(){
      if(!this.ActiveSuiteApp && this.WarehouseApp){
         this.$store.commit('setActiveSuiteApp',this.WarehouseApp) 
         this.$store.commit('setActiveWarehouse',this.ViewWarehouse)  
        }
        this.GetPickingSlips()
    },
    methods:{
        PreparePickingSlipProcessing(){            
            let AdvancingStage = 'Packing'
            if(this.TransactionTriggersDispatching){
                AdvancingStage = 'Dispatching'
            }
            let status = this.ProgressItems.find(obj => obj.Name === 'Completed')
            //but basically here we only dissect if the full picking slip have been picked. If all line items in their full qty update picking slip, then line items
            let fullarray = this.PickingSlipsLineItems.filter(item => {return item.Transaction_Docid === this.SelectedPickingSlip.id})
            if(fullarray.length === this.SelectedLineItems.length){
                this.ParentDBBase.doc(this.SelectedPickingSlip.id).update({
                    Progress: status
                }).then(updatedparentdoc => {
                    this.SelectedLineItems.map((item,itemindex) => {
                        if(this.TransactionTriggersDispatching && status.Name === 'Completed'){
                        this.LineItemDBBase.doc(item.id).update({
                            Progress: status,
                            Modified_Byid: this.userLoggedIn.id,
                            Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},
                            Ready_for_Dispatching: this.TransactionTriggersDispatching,
                            AdvancingStage: AdvancingStage
                        })  .then(updatedchilddoc => {
                            if(itemindex-1+2 === this.SelectedLineItems.length){
                                this.DeactivateProcessPickingSlipDialog()
                            }
                        }) 
                        }
                        else{
                            this.LineItemDBBase.doc(item.id).update({
                            Progress: status,
                            Modified_Byid: this.userLoggedIn.id,
                            Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},
                            AdvancingStage: AdvancingStage
                        }).then(updatedchilddoc => {
                            if(itemindex-1+2 === this.SelectedLineItems.length){
                                this.DeactivateProcessPickingSlipDialog()
                            }
                        })
                        }
                    })
                })
            }
            else{
                //only line items, picking slip still ahve otehr lines
                this.SelectedLineItems.map((item,itemindex) => {
                        if(this.TransactionTriggersDispatching && status.Name === 'Completed'){
                        this.LineItemDBBase.doc(item.id).update({
                            Progress: status,
                            Modified_Byid: this.userLoggedIn.id,
                            Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},
                            Ready_for_Dispatching: this.TransactionTriggersDispatching,
                            AdvancingStage: AdvancingStage
                        })  .then(updatedchilddoc => {
                            if(itemindex-1+2 === this.SelectedLineItems.length){
                                this.DeactivateProcessPickingSlipDialog()
                            }
                        }) 
                        }
                        else{
                            this.LineItemDBBase.doc(item.id).update({
                            Progress: status,
                            Modified_Byid: this.userLoggedIn.id,
                            Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},
                            AdvancingStage: AdvancingStage
                        }).then(updatedchilddoc => {
                            if(itemindex-1+2 === this.SelectedLineItems.length){
                                this.DeactivateProcessPickingSlipDialog()
                            }
                        })
                        }
                    })
            }

        },
        ActivateProcessPickingSlipDialog(){
            this.ProcessPickingSlipDialog = true
        },
        DeactivateProcessPickingSlipDialog(){
            this.ProcessPickingSlipDialog = false
            this.SelectedPickingSlip = ''
            this.ClearSelectedLineItems()
        },
        ClearSelectedLineItems(){
            this.SelectedLineItems = []
            
        },
        ResetSearch(){
            this.PickingSlipSearch = ''
        },
        GetPickingSlips(){
          let vm = this
          let whref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).collection('PickingSlipsLine_Items')
          console.log(whref)
          whref.where('Warehouseid','==',this.ActiveWarehouse.id).where('Progress.Name','==','Issued').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    //   vm.GetInventoryArticle(probj).then(result => {
                        vm.PickingSlipsLineItems.push(probj)
                    //   })
                  
                  }
                  if (change.type === 'modified') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    //   vm.GetInventoryArticle(probj).then(result => {
                        let oncheck = vm.PickingSlipsLineItems.find(obj => obj.id === probj.id)
                          if(oncheck){
                            let index = vm.PickingSlipsLineItems.indexOf(oncheck)
                            vm.PickingSlipsLineItems.splice(index, 1, probj);
                          }
                    //   })
                  
                  }
                  if (change.type === "removed") {                      

                      let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = vm.PickingSlipsLineItems.find(obj => obj.id === probj.id)
                      if(oncheck){
                        let index = vm.PickingSlipsLineItems.indexOf(oncheck)
                        vm.PickingSlipsLineItems.splice(index, 1);
                      }
                  }
                })  
              })
        }
    }
}
</script>

<style>

</style>


