<template>
  <div>
      <v-card elevation="0"  :class="CurrentModuleActive ? 'siteconfigcard' : 'purple lighten-3 siteconfigcard'" >
        <v-card-title :class="CurrentModuleActive ? 'BuilderGradient' : ''">
          <span  class="mediumoverline">
          {{CurrentModuleActive ? 'Installed' : ModuleName}}
          </span>
          <v-spacer>
          </v-spacer>
           <v-btn outlined dark class="BoldBuilderGradient" @click="ActivatePluginConfigComponent('components/SuiteBuilder/Main/SocialNetworkConfig')" v-if="CurrentModuleActive && ModuleName === 'Social Network Builder'">
            Configure
          </v-btn>
          <v-btn  dark color="success" @click="SelectBuilderView(Module)" v-if="CurrentModuleActive && ModuleName !== 'Database Builder'">
            Build Now
          </v-btn>
          <v-btn @click="EmitMarketDialog(Module)" outlined color="white" dark v-if="!CurrentModuleActive">
            Not Installed
          </v-btn>
          <v-btn 
            :color="CurrentModuleActive ? 'black' : 'teal'" outlined
            :to="Module.RoutePath"
          >
            Explore
          </v-btn>
      </v-card-title>
                <!-- <SuiteLibraries v-if="Module.id === 'Library_Builder'"
        :System="System" :SystemEntities="SystemEntities"
         :Directories="Directories" :DocumentationLibraries="DocumentationLibraries"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
        />  -->
            <v-card-text style="padding-top: 20px;" v-if="CurrentModuleActive">
              <div v-if="ActiveModulePlugins.length === 0">
                You do not have any plugins activated for this Module.
              </div>
              <v-list  dense class="transaprent" style="padding: 5px;" v-for="plug in ActiveModulePlugins" :key="plug.itemObjKey">
                <div style="border: 0.25px solid #cdcdcd;border-radius:15px;padding:15px;">
                 <v-list-item dense>
                   <!-- <v-btn width="50%" class="" outlined color="grey" >{{plug.Level.Name}}
                       </v-btn> -->
                   <v-btn @click="ActivateTypeFilter(plug.Type)" :small="MiniView" width="100%" class="SoftBuilderGradient" outlined color="grey" >{{plug.Type.Name.substr(0,19)}}
                       </v-btn>
                </v-list-item>
              <v-list-item  class="justify-start" dense>
               <v-list-item-avatar v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="MiniView ? '' : 'align-self: center;padding-right: 10px;'">
                    <v-icon :size="40">{{plug.Icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-action>
                    <v-list-item-title style="align-self: start;" class="smallbanneroverline">
                      <v-icon v-if="MiniView" :size="20">{{plug.Icon}}</v-icon>
                      {{plug.Name}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="subtleoverline" style="align-self: start;">
                      {{plug.Module_Category.Name}}
                      <!-- <v-btn outlined x-small  v-if="MiniView">
                      {{plug.Level.Name}}
                      </v-btn> -->
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="!MiniView" style="align-self: start;padding:4px;" class="subtleoverline">
                      <v-btn outlined x-small>
                      {{plug.Level.Name}}
                      </v-btn><v-btn :to="plug.RoutePath"
                      outlined x-small>
                      Read More
                      </v-btn>
                    </v-list-item-subtitle>
                    <!-- <v-list-item-subtitle>
                       <v-btn outlined color="grey" small>{{plug.Type.Name}}
                       </v-btn>
                    </v-list-item-subtitle> -->
                    </v-list-item-action>
                    <!-- <v-card-text class="caption" v-html="smart_substr(plug.Description,MiniView ? 60 : 160)">
                    </v-card-text> -->
                    <v-list-item-content style="align-self: start;padding-left:20px;padding-top: 12px;" v-if="!MiniView" class="caption">
                      <span  v-html="smart_substr(plug.Description,MiniView ? 30 : 230)">
                      </span>
                    </v-list-item-content>
                    <v-list-item-action v-if="!MiniView">
                        <v-menu 
                        :close-on-content-click="false"
                        :nudge-right="40"
                        nudge-top="40"
                        transition="scale-transition"
                        offset-y
                        width="200">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!plug.ConfigComponentPath && !plug.ConfigComponentRoute && !plug.ComponentPath && !plug.ComponentRoute"
                                outlined class="BuilderGradient">
                                  Options
                                </v-btn>
                            </template>
                            <v-list>
                              <v-list-item dense v-if="plug.ConfigComponentPath || plug.ConfigComponentRoute">
                                <v-btn width="100%" outlined @click="plug.ConfigComponentRoute ? RoutetoPluginConfigComponent(plug.ConfigComponentRoute) : ActivatePluginConfigComponent(plug.ConfigComponentPath)" class="BuilderGradient">Configure</v-btn>
                              </v-list-item>
                              <v-list-item dense v-if="plug.ComponentPath || plug.ComponentRoute">
                                <v-btn width="100%" outlined @click="plug.ComponentRoute ? RoutetoPluginConfigComponent(plug.ComponentRoute) : ActivatePluginConfigComponent(plug.ComponentPath)" class="BuilderGradient">Go Now</v-btn>
                              </v-list-item>
                              <!-- <v-list-item dense v-if="plug.ManagingComponentPath">
                                <v-btn width="100%" outlined @click="ActivatePluginConfigComponent(plug.ManagingComponentPath)" class="BuilderGradient">Monitor</v-btn>
                              </v-list-item> -->
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="MiniView">
                 <span class="caption" v-html="smart_substr(plug.Description,MiniView ? 60 : 230)">
                      </span>
              </v-list-item>
              <v-list-item dense v-if="MiniView" class="justify-end">
                <v-btn :to="plug.RoutePath" small
                      outlined>
                      Learn
                      </v-btn>
                      <v-menu 
                        :close-on-content-click="false"
                        :nudge-right="40"
                        nudge-top="40"
                        transition="scale-transition"
                        offset-y
                        width="200">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!plug.ConfigComponentPath && !plug.ConfigComponentRoute && !plug.ComponentPath && !plug.ComponentRoute"
                                outlined class="BuilderGradient">
                                  Options
                                </v-btn>
                            </template>
                            <v-list>
                              <v-list-item dense v-if="plug.ConfigComponentPath || plug.ConfigComponentRoute">
                                <v-btn width="100%" outlined @click="plug.ConfigComponentRoute ? RoutetoPluginConfigComponent(plug.ConfigComponentRoute) : ActivatePluginConfigComponent(plug.ConfigComponentPath)" class="BuilderGradient">Configure</v-btn>
                              </v-list-item>
                              <v-list-item dense v-if="plug.ComponentPath || plug.ComponentRoute">
                                <v-btn width="100%" outlined @click="plug.ComponentRoute ? RoutetoPluginConfigComponent(plug.ComponentRoute) : ActivatePluginConfigComponent(plug.ComponentPath)" class="BuilderGradient">Go Now</v-btn>
                              </v-list-item>
                              <!-- <v-list-item dense v-if="plug.ManagingComponentPath">
                                <v-btn width="100%" outlined @click="ActivatePluginConfigComponent(plug.ManagingComponentPath)" class="BuilderGradient">Monitor</v-btn>
                              </v-list-item> -->
                            </v-list>
                        </v-menu>
              </v-list-item>
                </div>
              </v-list>
            </v-card-text>
      <v-card-text v-if="!CurrentModuleActive">
        <v-carousel
                    cycle
                    :height="MiniView ? 150 : 250"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in Module.CarouselItems"
                    :key="i"
                    :src="slideitem.fileurl"
                  >
                  <v-btn @click="ToggleRoadmapItemScope(Module)" v-if="$route.name === 'Roadmap'" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmapItem(Module)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmapItem(Module)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="Module.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                    <v-sheet v-if="!Module.Disabled" height="100%" style="background: linear-gradient(45deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.7) 100%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>
      </v-card-text>
        <v-card-text v-if="!CurrentModuleActive" :class="Module.Disabled ? 'caption grey--text' : 'caption'" >
            <span v-html="Module.Description">
            </span>
        </v-card-text>
        <!-- <v-card-actions class="justify-end" >
                   
                  <v-spacer></v-spacer>
                  <v-btn
                    color="teal"
                    text
                    small
                    :to="Module.RoutePath"
                  >
                    Explore
                  </v-btn>
                </v-card-actions> -->
              
      </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import SuiteLibraries from '@/components/SuiteBuilder/Main/SuiteLibraries';
export default {
    props: ['System','SystemEntities','Module'],
    components: {RAPluginCarousel,SuiteLibraries},
    data() {
        return {
          RefreshingPlugins: false,
            SelectedModule: '',
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            ActiveTypeFilter: '',
        }
    },	
    computed:{
      IsPrimarySite(){
        return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
      },
      RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
      ActiveModuleCategories(){
          if(this.ModuleName === 'Social Network Builder'){
            return ['SNB']
          }
        },
        ActiveModulePlugins(){
          let array = []
         if(this.Module.Name === 'Database Builder'){
              array = this.InstalledRAPluginsMatched(['Database Builder','DBB'])
            }
            else if(this.Module.Name === 'Social Network Builder'){
              array = this.InstalledRAPluginsMatched(['Social Network Builder','SNB'])
            }
            else if(this.Module.Name === 'Website Builder'){
              array = this.InstalledRAPluginsMatched(['Website Builder','WB'])
            }
            else if(this.Module.Name === 'Documentation Builder'){
              array = this.InstalledRAPluginsMatched(['Documentation Builder','DBB'])
            }
            return array.filter(plug => {
              if(this.ActiveTypeFilter){
                return plug.Type.ID === this.ActiveTypeFilter.ID
              }
              else{
                return plug
              }
            })
        },
      SelectedModulePlugins(){
          let plugins = []
          if(this.Module){
            if(this.Module.Name === 'Database Builder'){
              return this.RAPluginsMatched(['Database Builder','DBB'])
            }
            else if(this.Module.Name === 'Social Network Builder'){
              return this.RAPluginsMatched(['Social Network Builder','SNB'])
            }
            else if(this.Module.Name === 'Website Builder'){
              return this.RAPluginsMatched(['Website Builder','WB'])
            }
            else if(this.Module.Name === 'Documentation Builder'){
              return this.RAPluginsMatched(['Documentation Builder','DBB'])
            }
          }
          return plugins
        },
      RelevantPlugins(){
        return this.SelectedModulePlugins
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      CurrentModuleActive(){
        //return false
        return this.PluginDataBase[this.ModuleName.split(' ').join('_')] && this.PluginDataBase[this.ModuleName.split(' ').join('_')].Active
      },
      NoActiveModule(){
        return !this.WebsiteisActive && !this.SocialNetworkisActive && !this.DatabaseisActive && !this.DocumentationisActive
      },
      WebsiteisActive(){
        return this.$store.state.WebsiteisActive
      },
      SocialNetworkisActive(){
        return this.$store.state.SocialNetworkisActive
      },
      DatabaseisActive(){
        return this.$store.state.DatabaseisActive
      },
      DocumentationisActive(){
        return this.$store.state.DocumentationisActive
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ModuleName(){
            return this.Module.Name
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      ActivateTypeFilter(type){
        if(this.ActiveTypeFilter && this.ActiveTypeFilter.ID === type.ID){
          this.ActiveTypeFilter = ''
        }
        else{
          this.ActiveTypeFilter = Object.assign({},type)
        }
        console.log(this.ActiveTypeFilter,type)
      },
      ActivatePluginConfigComponent(path){
        this.$emit('ActivateActiveConfigPath',path)
      },
      RoutetoPluginConfigComponent(route){
        this.$router.push(route)
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      InstalledRAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          && plug.Level.Name === 'Suite'
        })
        .filter(plug => {
          return this.PluginDataBase[plug.id]
        })
      },
      SelectBuilderView(Module){
        this.$store.commit('setBuilderView',{Name: Module.Name})
      },
      RAPluginsMatched(categorymatches){
        //here actually check if mod is "Disabled" it means mod is installed, meaning plugin not disabled
        //option if needed elsehwere but yeah 
        //let primmod = categorymatches[0].split(' ').join('_')
        //let modactive = this.PluginDataBase[primmod] && this.PluginDataBase[primmod].Active
        //if(!modactive) child plugins disabled at least fromt his view
        //but much more easily this called only if module active so...
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || plug.Module_Category.Name === 'General'
        }).map(plug => {
          let plugobj = Object.assign({},plug)
          if(!this.CurrentModuleActive){
            //Module would be disabled if installed, so if not installed cannot install children
            plugobj.RequiresParent = true
          }
          return plugobj
        }).filter(item => {
          return item.Office_Exclusive && this.RAAdmin || !item.Office_Exclusive
        })
      },
      RefreshPlugins(){
        this.RefreshingPlugins = true
        setTimeout(() => {
          this.RefreshingPlugins = false
        }, 10);
      },
        DeslectModule(){
        this.SelectedModule = ''
        this.RefreshPlugins()
        },
        ActivateModulePlugins(item){
            this.SelectedModule = item
            this.RefreshPlugins()
            
        },
        EmitMarketDialog(SelectedPlugin,prop){
        console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
      },
    }
}
</script>

<style>

</style>



