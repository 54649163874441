
<template>
<v-card flat :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent' : 'transparent'" width="100%" height="100%">
     <!-- <v-card  :flat="NoticeSelect" -->
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">Meetups</h4>
              </v-col>
            </v-row>
            </v-parallax>
    <v-layout class="justify-end" v-if="!NoticeSelect">
    <v-btn outlined color="accent"  :to="SingleRouteNewPrefix" v-if="UserModerator || CanCreate || UserisTemplateOwner"><v-icon>mdi-plus</v-icon>New Meetup</v-btn>
    </v-layout>
    

  <v-tabs :dark="AppisDarkMode" :background-color="AppisDarkModeCard" centered v-if="!NoticeSelect" class="bottomspace transparent">
    <v-tab>Upcoming</v-tab>
    <v-tab-item ><v-spacer>
                   </v-spacer><v-btn  outlined color="accent" @click="OpenAboutDialog('AboutSiteMeetups')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Events</v-btn>
      <v-list :dark="AppisDarkMode" :class="AppisDarkModeCard">
        <v-list-item  v-for="article in UpcomingMeetups" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                 <v-card-title v-if="article.PlannedStatus" :class="PlannedStatusClass(article.PlannedStatus)">
                           <v-icon color="white">{{PlannedStatusIcon(article.PlannedStatus)}}</v-icon>{{article.PlannedStatus}}</v-card-title>  
                <v-img
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
               
                        <v-card-title class="headline">{{ article.title }}
                          <v-spacer>
                          </v-spacer>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip></v-card-title>
                        <div class="mx-2" >
                             <v-chip color="error">{{MeetupStatus(article)}}</v-chip>
                           <v-chip-group v-if="article.start">
                             <span><v-chip color="success">Starting</v-chip>{{ article.start.toDate() }}</span>
                           </v-chip-group>
                           <v-chip-group v-if="article.end">
                             <span><v-chip color="warning">Ending</v-chip>{{ article.end.toDate() }}</span>
                           </v-chip-group>
                           </div>

                
                <v-card-text v-html="article.description">
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="SingleRoutePrefix+article.id">
                     <v-icon v-if="MiniView">mdi-glasses</v-icon>
                     {{MiniView? '' : 'Read More'}}
                     </v-btn></v-btn>
                </v-card-actions>
                <!-- <v-card-actions style="font-size:10px">
                  {{article.createdon.toDate()}}
                </v-card-actions> -->
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
    </v-tab-item>
    <v-tab>Passed</v-tab>
    <v-tab-item><v-spacer>
                   </v-spacer><v-btn  outlined color="accent" @click="OpenAboutDialog('AboutSiteMeetups')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Meetups</v-btn>
      <v-list :dark="AppisDarkMode" :class="AppisDarkModeCard">
        <v-list-item  v-for="article in MeetupsPassed" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                 <v-card-title v-if="article.PlannedStatus" :class="PlannedStatusClass(article.PlannedStatus)">
                           <v-icon color="white">{{PlannedStatusIcon(article.PlannedStatus)}}</v-icon>{{article.PlannedStatus}}</v-card-title>  
                <v-img
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
               
                        <v-card-title class="headline">{{ article.title }}
                          <v-spacer>
                          </v-spacer>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                        </v-card-title>
                        <div class="mx-2" >
                             <v-chip color="error">{{MeetupStatus(article)}}</v-chip>
                           <v-chip-group v-if="article.start">
                             <span><v-chip color="success">Starting</v-chip>{{ article.start.toDate() }}</span>
                           </v-chip-group>
                           <v-chip-group v-if="article.end">
                             <span><v-chip color="warning">Ending</v-chip>{{ article.end.toDate() }}</span>
                           </v-chip-group>
                           </div>

                
                <v-card-text v-html="article.description">
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="SingleRoutePrefix+article.id">
                     <v-icon v-if="MiniView">mdi-glasses</v-icon>
                     {{MiniView? '' : 'Read More'}}
                     </v-btn></v-btn>
                </v-card-actions>
                <!-- <v-card-actions style="font-size:10px">
                  {{article.createdon.toDate()}}
                </v-card-actions> -->
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
    </v-tab-item>
  </v-tabs>
     
        <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="groupmeetups" item-text="title" v-model="selectedSocialItem" return-object label="Meetup"/>
    </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','CanCreate','NoticeSelect','AppisDarkMode','AppisDarkModeCard','System','GroupData'],
    components: {
        //FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
            InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
            groupmeetups: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupNewMeetup' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Meetup' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },  
      SingleRouteNewPrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/NewMeetup'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/NewMeetup'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/NewMeetup' : '/Group/NewMeetup/'+this.$route.params.id
        }
      },
      SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Meetup/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Meetup/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Article/' : '/Group-Article/'+this.$route.params.id+'/Article/'
        }
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },      
      CollectionRef(){
        if(this.TemplateRootPath){
          return this.TemplateRootPath.collection('samplesitemeetups')
        }
        else if(this.NonGroupRoute){
         return this.userLoggedIn ? this.ContentRootPath.collection('sitemeetups') : db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('sitemeetupswiki') 
        }
        else{
          return this.ContentRootPath.collection('groupmeetups').where('groupid','==',this.$route.params.id)
        }
        
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
       UserisTeamMember(){
          return this.GroupData && this.GroupData.TeamMembers ? this.GroupData.TeamMembers.find(obj => obj.id === this.userLoggedIn.id) : false
        },
        UserisGroupMember(){
          return this.GroupData && this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.find(obj => obj.id === this.GroupData.id) : false
        },
      Computedgroupmeetups(){
        return this.groupmeetups
        .map(meetup => {
            meetup.startdate = meetup.start.toDate()  
            meetup.enddate = meetup.end.toDate()          
          return meetup
        })
      },
      UpcomingMeetups(){
        let today = new Date()
        return this.Computedgroupmeetups
        .filter(meetup => {
          return meetup.enddate >= today
        }).map(article => {
          if(!article.pubDate){
            //still need to convert this.$store.dispatch('FullSTDDate',article.PublishDate.toDate()).then(res => {
            article.pubDate = this.TimestampFormatterSTRINGDayText(article.start.toDate())
          }
          
      
          return article
        })        
        .sort((a, b) => {
            //console.log('sorting again')
            //console.log(a.start)
            var key1 = a.startdate
            var key2 = b.startdate

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },
      MeetupsPassed(){
        let today = new Date()
        return this.Computedgroupmeetups
        .filter(meetup => {
          return meetup.enddate < today
        }).map(article => {
          if(!article.pubDate){
            //still need to convert this.$store.dispatch('FullSTDDate',article.PublishDate.toDate()).then(res => {
            article.pubDate = this.TimestampFormatterSTRINGDayText(article.start.toDate())
          }
          
      
          return article
        })
        .sort((a, b) => {
            //console.log('sorting again')
            //console.log(a.start)
            var key1 = b.startdate
            var key2 = a.startdate

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserModerator(){
        if(this.userLoggedIn.ModerationPermissions){
          let match = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Meetups')
          if(match){
            return true
          }
          else{
            return this.userIsAdmin
          }
        }
        else{
          return this.userIsAdmin
        }
      },
        RelatedMeetups () {
            return this.groupmeetups.filter(meetup => {
              if(this.search){
                return meetup.title.toLowerCase().includes(this.search.toLowerCase()) && meetup.Groupid === this.groupid && meetup.PublishStatus === 'Published' || meetup.content.toLowerCase().includes(this.search.toLowerCase()) && meetup.Groupid === this.groupid && meetup.PublishStatus === 'Published' || meetup.content.toLowerCase().includes(this.search.toLowerCase()) && meetup.authorid === this.userid  && meetup.Groupid === this.Groupid && meetup.PublishStatus === 'Draft'
              }
              else{
                return meetup
                }
            })
        },

        MeetupExcerpt () {
          return this.RelatedMeetups.filter(meetup => {
            return meetup.content === this.meetup.content
          })
        },
       
    },

    created() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.GetSamples(this.TemplateRootPath.collection('samplesitemeetups'),'groupmeetups')
      }
      else{
      if(this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew'){
        this.InternalLinkType = 'Meetup'
        this.SharedCollection = 'sitemeetups'
        this.LinkComponent = 'SiteMeetupShared'
        let colref = this.CollectionRef
        if(this.$store.state.UserisGuest){
          this.getGroupMeetups(colref.where('PublishStatus','==','Published (Public)'))
          if(!this.CompanyOnlyFilter){
          this.getGroupMeetups(colref.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
          }
          else{
            this.getGroupMeetups(colref.where('PublishStatus','==','Published (Internal)').where('Companyid','==',this.UserCompanyid))
          }
        }
        else{
          if(!this.CompanyOnlyFilter || this.userIsAdmin){
          this.getGroupMeetups(colref.where('PublishStatus','!=','Draft'))
          }
          else{
            this.getGroupMeetups(colref.where('PublishStatus','!=','Draft').where('Companyid','==',this.UserCompanyid))
          }
        }
      }
      else{
        this.InternalLinkType = 'Group-Meetup/'+this.$route.params.id+'/Meetup'
        this.SharedCollection = 'groupmeetups'
        this.LinkComponent = 'GroupMeetupShared'
        let colref = this.CollectionRef
        if(this.UserisTeamMember){
          this.getGroupMeetups(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
        }
        else if(this.userLoggedIn && !this.$store.state.UserisGuest){
          this.getGroupMeetups(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
        }
        else{                
          this.getGroupMeetups(colref.where('GuestsIncluded','==',true).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
        } 
      }
   
        console.log(this.userid)
      }
},
    methods: {
      OpenAboutDialog(dialprop){      
        this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
        this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      PlannedStatusIcon(PlannedStatus){
        if(PlannedStatus === 'Still Happening'){
          return 'mdi-calendar'
        }
        else if(PlannedStatus === 'On Hold'){
          return 'mdi-calendar-question'
        }
        else if(PlannedStatus === 'Cancelled'){
          return 'mdi-calendar-remove'
        }
      },
      PlannedStatusClass(PlannedStatus){
        if(PlannedStatus === 'Still Happening'){
          return 'success headline white--text'
        }
        else if(PlannedStatus === 'On Hold'){
          return 'warning headline white--text'
        }
        else if(PlannedStatus === 'Cancelled'){
          return 'error headline white--text'
        }
      },
      MeetupStatus(article){
        let start = article.start
        let end = article.end
        let today = new Date()
        if(end && end.toDate){
          let endobj = end.toDate()
          //console.log(endobj)
          if(endobj < today){
            return 'Already Passed'
          }
          else{
            if(start && start.toDate){
              let startobj = start.toDate()
              //console.log(startobj)
              if(startobj < today){
                return 'Already Started'
              }
              else{
                return 'Not Yet Started'
              }
            }
          }
        }
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
         getGroupMeetups(query) {
           //col.where('PublishStatus','==','Published (Internal)')
           query.onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupmeetups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }  
            })
          })
          // col.where('PublishStatus','==','Published (Public)').onSnapshot(res => {
          //   const changes = res.docChanges();
          //   changes.forEach(change => {
          //     if (change.type === 'added') {
          //       this.groupmeetups.push({
          //         ...change.doc.data(),
          //         id: change.doc.id
          //       })
          //     }  
          //   })
          // })
          // col.where('PublishStatus','==','Draft').where('moderatorrolesarrayQuery','array-contains',this.userLoggedIn.id).onSnapshot(res => {

          //   const changes = res.docChanges();
          //   changes.forEach(change => {
          //     if (change.type === 'added') {
          //       this.groupmeetups.push({
          //         ...change.doc.data(),
          //         id: change.doc.id
          //       })
          //     }
          //   })
          // })

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Meetup_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const meetup = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        this.ContentRootPath.collection('groupmeetups').add(meetup).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>