<template>
  <v-form ref="form">
    <!-- <v-btn @click="ResetForm()"> Reset</v-btn> -->
      <v-switch v-if="userIsAdmin && $route.meta && $route.meta.TableBuilder && !HideAdmin" v-model="AdminTesting" label="Admin Test Only" />
      <!-- <v-list-item style="width: 100%;padding: 0px;display: block;" dense v-for="field in FormFields" :key="field.itemObjKey"> -->
      <v-list-item :style="field.Tooltip ? 'width: 100%;padding: 0px;display: flex;': 'width: 100%;padding: 0px;display: block;'" dense v-for="field in FormFields" :key="field.itemObjKey">
       <span v-if="field.Type === 'Text Array'">
         <v-combobox
          v-model="field.Response"
          :items="field.Response"
          :search-input.sync="search"
          hide-selected
          :placeholder="'Add some '+field.DisplayName"
          multiple
          persistent-hint
          small-chips
          :readonly="FieldIsReadOnly(field)" :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required]: []"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Type and Press <kbd>enter</kbd> to add.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
       </span>
        <span v-if="field.Type === 'Boolean'">
                    <v-switch  class="smallinput" :readonly="field.ReadOnly"  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []" v-model="field.Response" :label="field[FieldNameProp]"></v-switch></span><span v-if="field.Type === 'Option Set' && !field.TextOnly">
                    <v-select class="smallinput" return-object :readonly="FieldIsReadOnly(field)" :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required]: []" v-model="field.Response" :items="field.Options" :placeholder="field[FieldNameProp]" item-text="Name"></v-select><span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1]">
                    <v-select class="smallinput" v-if="field.Response" return-object :readonly="FieldIsReadOnly(field)" :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []" v-model="field.Level1Response" :items="field.Response.Options" :placeholder="field.Levels[1].name" item-text="Name"></v-select></span><span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[2]">
                    <v-select class="smallinput" v-if="field.Level1Response && field.Level1Response.Options && field.Level1Response.Options.length > 0" return-object :readonly="FieldIsReadOnly(field)" :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []" v-model="field.Level2Response" :items="field.Level1Response.Options" :placeholder="field.Levels[2].name" item-text="Name"></v-select></span></span><span v-if="field.Type === 'Option Set' && field.TextOnly">
                    <v-select class="smallinput" :readonly="FieldIsReadOnly(field)" :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required]: []" v-model="field.Response" :items="field.Options" :placeholder="field[FieldNameProp]" item-text="Name"></v-select></span><span v-if="field.Type === 'Single Line Text'">
                    <v-text-field class="smallinput"  :readonly="FieldIsReadOnly(field)"  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []" v-model="field.Response" :placeholder="field[FieldNameProp]"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'">
        <v-text-field class="smallinput"
  persistent-placeholder  :placeholder="field[FieldNameProp]"
 @keydown.space.prevent @input="removeWhiteSpace(field.Response)" :readonly="FieldIsReadOnly(field)"  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required,$store.state.formrules.email] : [$store.state.formrules.email]" v-model.trim="field.Response"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'URL Link'">
        <v-text-field class="smallinput"  :readonly="FieldIsReadOnly(field)"  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required,$store.state.formrules.url] : [$store.state.formrules.url]" v-model="field.Response" :placeholder="field[FieldNameProp]"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Telephone Number'">
        <v-text-field class="smallinput"  :readonly="FieldIsReadOnly(field)"  :rules="CommonFieldRules(field)" v-model="field.Response" :placeholder="field[FieldNameProp]"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-list dense >
                            <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title>
                            <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-text-field dense clearable class="smallinput"  :readonly="FieldIsReadOnly(field)" v-model="field.Response[breakdownfield.Name]" :placeholder="breakdownfield.DisplayName"/>
                            </v-list-item>
                        </v-list>
                        </span><span v-if="field.Type === 'Number Field'">
                    <v-text-field class="smallinput"  :readonly="FieldIsReadOnly(field)"  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.numberfield,$store.state.formrules.required] : [$store.state.formrules.numberfield]" v-model.number="field.Response" type="number" :placeholder="field[FieldNameProp]"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text' && !field.IsContentEditable">
                    <v-textarea :readonly="FieldIsReadOnly(field)"  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []" v-model="field.Response"  :placeholder='field[FieldNameProp]'></v-textarea></span><span v-if="field.Type === 'Multiple Lines Text' && field.IsContentEditable">
                   <v-card tile elevation="0" style="margin-bottom:15px;">
                   <v-card-title class="justify-start">
                     {{field.text}}: 
                   </v-card-title>
                   <!-- <v-card-subtitle class="caption">
                     Remember to click on the "save" to capture the value. 
                   </v-card-subtitle> -->
                   <v-card-text>
                    <v-layout row class="justify-center" >
                   <v-col cols="12" >
                    <ContentEditableField style="padding:5px;"
                       :FieldObject="field" :FieldName="'Response'"
                       :FieldValue="field.Response" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                       
                   </v-col> </v-layout>
                   </v-card-text>
                   </v-card>
                       </span><span v-if="field.Type === 'Lookup' || field.Type === 'Social Lookup'"><span v-if="field.RelatedBuildID !== 'Users'">
                    <v-autocomplete v-if="!field.Has_Selection_Dial && field.IsMultiple"
                    :class="'smallinput'" multiple :filter="fieldFilter"
                    :readonly="FieldIsReadOnly(field)" :items="field.Array"
                    :item-text="field.LookupFieldName" v-model="field.Response" return-object :placeholder='field.Name'
                    :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []">
                    <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="data.item[field.LookupFieldName]"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                           <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title  v-html="data.item[field.LookupFieldName]"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.id"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                     <template v-slot:prepend-item>
                            <v-list v-if="userLoggedIn && field.Type === 'Lookup'">                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingLookup()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewLookupDialog(field)" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="!field.Has_Selection_Dial && !field.IsMultiple"
                    :class="'smallinput'" :filter="fieldFilter"
                    :readonly="FieldIsReadOnly(field)" :items="field.Array"
                    :item-text="field.LookupFieldName" v-model="field.Response" return-object :placeholder='field.Name'
                    :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []">
                    <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="data.item[field.LookupFieldName]"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                           <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title  v-html="data.item[field.LookupFieldName]"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.id"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                           <template v-slot:prepend-item>
                            <v-list v-if="userLoggedIn && field.Type === 'Lookup'">                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingLookup()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewLookupDialog(field)" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="field.Has_Selection_Dial && field.Response"
                    :class="field.Has_Selection_Dial ? 'smallinput' : 'smallinput'"
                    :readonly="FieldIsReadOnly(field)" :items="field.Array"
                    :item-text="field.LookupFieldName" v-model="field.Response" return-object :placeholder='field.Name'
                    :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []">
                     <template v-slot:item="data">
                        <template v-if="!data && field.Has_Selection_Dial">
                          <v-list-item>
                            <v-list-item-title class="caption">
                              There is no selection available
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                     <template v-else-if="field.Has_Selection_Dial">
                       <v-list-item-avatar tile size="100" v-if="field.LookupAvatarField">
                            <img v-if="data.item[field.LookupAvatarField.Name]" :src="data.item[field.LookupAvatarField.Name]" style="object-fit: cover;">
                            <img v-if="!data.item[field.LookupAvatarField.Name] && $vuetify.theme.dark"  src="@/assets/ImageHolder_dark.png" style="object-fit: cover;">
                             <img v-if="!data.item[field.LookupAvatarField.Name] && !$vuetify.theme.dark"  src="@/assets/ImageHolder.png" style="object-fit: cover;">
                          </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                         {{data.item[field.LookupFieldName]}}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="field.LookupSubtitleField">
                              <span v-if="field.LookupSubtitleField.IsCurrency">
                                {{CurrencyFormatter(data.item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                              </span>
                              <span v-else>
                                {{data.item[field.LookupSubtitleField.Name]}}
                              </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="caption" v-html="data.item[field.LookupCaptionField.Name]" v-if="field.LookupCaptionField.Name">
                            </v-list-item-subtitle>
                            <!-- {{CurrencyFormatter(PerformedCalculation,$store.state.DefaultCurrency.Currency)}} -->
                          </v-list-item-content>
                      </template>
                     </template>
                    </v-autocomplete>
                    <!-- <v-btn v-if="field.Response && field.Has_Selection_Dial" plain text>Selected ({{field.Response[field.LookupFieldName]}})</v-btn> -->
                    <!-- I would actullay prefer "onclick" of the autocomplete to then open dialog of option -->
                     <v-dialog v-model="SelectionLookupDialog"  :class="field.DialClass"  v-if="field.Has_Selection_Dial" fullscreen>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" class="caption" v-if="field.Has_Selection_Dial">{{field.Response ? 'Change' : 'Select'}} {{field.DisplayName}}</v-btn>
                          
                        </template>
                         <v-container style="width:100%;" v-if="field.Has_Selection_Dial" class="justify-center" :class="field.DialClass">
                        <v-card tile :flat="field.CardFlat" :class="field.CardClass" :dark="field.CardDark" style="padding: 0px;" :width="field.CardWidth" :height="field.CardHeight" v-if="field.DialType === 'Lookup Cards'">
                          <v-card-title v-if="field.DialHeader">{{field.DialHeader}}<v-spacer>
                            </v-spacer><v-icon dark style="position: absolute;right:15px;top: 15px;" large @click="SelectionLookupDialog = !SelectionLookupDialog" class="soloactionicon red">mdi-close</v-icon>
                          </v-card-title>
                           <v-card-text v-if="field.DialCaption" v-html="field.DialCaption"></v-card-text>
                          <v-card-text style="padding:25px;">
                         <v-row class="justify-center fill-height">
                          <div class="flex xs12 sm6 md4 lg3 xl2" v-for="item in field.Array" :key="item.itebObjKey">
                                <v-card>
                                  <v-card-title>
                                    {{item[field.LookupFieldName]}}
                                  </v-card-title>
                                  <v-card-subtitle v-if="field.LookupSubtitleField">
                                    <span v-if="field.LookupSubtitleField.IsCurrency">
                                    {{CurrencyFormatter(item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                                  </span>
                                  <span v-else>
                                    {{item[field.LookupSubtitleField.Name]}}
                                  </span>
                                  </v-card-subtitle>
                                  <v-card-text v-if="field.LookupAvatarField">
                                    <img width="100%" v-if="item[field.LookupAvatarField.Name] && item[field.LookupAvatarField.Name].ThumbURL" :src="item[field.LookupAvatarField.Name].ThumbURL" style="object-fit: cover;">
                                    <img width="100%" v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && $vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && $vuetify.theme.dark"  src="@/assets/ImageHolder_dark.png" style="object-fit: cover;">
                                    <img width="100%" v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && !$vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && !$vuetify.theme.dark"  src="@/assets/ImageHolder.png" style="object-fit: cover;">
                                  </v-card-text>
                                  <v-card-text v-if="field.LookupCaptionField" v-html="item[field.LookupCaptionField.Name]">
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-btn @click="SelectLookupResponse(field,DataObject,item)">Select</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </div>
                         </v-row>
                          </v-card-text>
                        </v-card>
                         </v-container>
                     </v-dialog>
                    
                    </span><span v-if="field.RelatedBuildID === 'Users'">
                      <UserLookup :UsersLookupArray="field.Array" :ModelProp="'Response'" :RecordObj="field" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="field.DisplayName" :readonly="FieldIsReadOnly(field)"
                      :Rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []"
                      />
                      </span></span><span v-if="field.Type === 'Date'">
                    <v-menu 
                    v-model="field.DateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y  class="smallinput"
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field  class="smallinput"
                        :placeholder="field[FieldNameProp]"
                        prepend-icon="mdi-calendar-month"
                        readonly  :rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []"
                        v-on="on"
                        v-model="field.Response"
                        ></v-text-field>
                        </template>
                    <v-date-picker  :readonly="FieldIsReadOnly(field)"  v-model="field.Response"
                            @input="field.DateMenu = false"></v-date-picker>
                    </v-menu>
                    <v-menu v-if="field.HasTimeInput"
                        v-model="field.TimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y  class="smallinput"
                        min-width="290px" 
                        id="fieldTimeMenu"
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                            v-model="field.TimeResponse"
                            :placeholder="field.Name+' Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"  class="smallinput"
                            :id="field.Name+'Time'"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="field.TimeResponse"
                            @input="field.TimeMenu = false"
                            type="month" :readonly="FieldIsReadOnly(field)" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu></span><span v-if="field.Type === 'Single File Upload'">
                          <!-- <v-btn> -->
                              <label v-if="!field.Response">
                              Select {{field.text}}:
                              <input type="file" @change="UploadFileSelect($event,field,DataObject)"/>
                            </label>
                            <span v-if="field.Response">
                              View {{field.text}}:
                            <v-btn x-small @click="GetShortLivedURL(field.Response)">
                              {{field.Response.filename}}
                            </v-btn>
                            </span>
                          <!-- </v-btn> -->
            <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> --></span>
                        <span v-if="field.IsFeaturedPhoto && field.localURL">
                        <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> -->
                        <!-- <v-avatar
                        color="grey"
                        size="150" 
                        tile> -->
                      <v-img contain v-if="field.localURL" :src="field.localURL" height="150px"/>
                    <!-- </v-avatar> -->
                        </span><span v-if="field.Type === 'Radio Group' && $route.name !== 'MyDashboard'">
    {{field.DisplayName}}: 
<v-radio-group row :value="field.Response" :disabled="field.ReadOnly" multiple>
<!-- field.Type {{field.Type}} -->
                <v-radio
                @click="toggleMultipleRadio(field,option)"
                    v-for="option in field.Options"
                    :key="option.itemObjKey"
                    :label="option.Name"
                    :value="field.TextOnly? option.Name : option.Name" class="smallinput"
                ></v-radio>
                </v-radio-group></span><span v-if="field.Type === 'Radio Group' && $route.name === 'MyDashboard'">
                <v-select :readonly="field.ReadOnly" class="smallinput" item-text="Name" dense filled multiple return-object :items="field.Options" v-model="field.Response" />
                </span>
                <v-tooltip bottom  v-if="field.Tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs" v-on="on" fab icon 
              >
              <v-icon small>mdi-help</v-icon>
              </v-btn>
            </template>
            <v-card max-width="200" v-html="field.Tooltip" tile class="transparent white--text">
            </v-card>
        </v-tooltip>
                </v-list-item>
  </v-form>
</template>

<script>
import ContentEditableField from '@/components/Database/ContentEditableField'
import UserLookup from '@/components/General/UserLookup'
export default {
    props: ['FormFields','tab','DataObject','DataTableView','Validating','HideAdmin'],
    components: {
        ContentEditableField,
        UserLookup
    },
    data(){
        return {
          ParentFieldid: '',
          SelectionLookupDialog: false,
          ValidationFailed: false,
          AdminTesting: false,
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    watch: {
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['UpdateNewChild']   
        //console.log(this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v) && this.$route.meta && this.$route.meta.TableBuilder || 
        v && acceptedmethods.includes(v) && this.tab.Entityid === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      Validating (value) {
          if(value){  
            if(this.ValidationFailed){
              console.log('validating changed after previous failure, next try')              
              this.resetValidation() 
            }
            else{
              console.log('validating changed after NO failure, first try')
              this.ValidateForm()
            }   
          }
          else{
            this.resetValidation()
          }
        }
      // Validating: {
      //       handler: function(oldvalue, newvalue) {
      //         if(oldvalue !== newvalue){
      //           if(this.Validating){
      //             console.log('validating changed')
      //             if(this.ValidationFailed){
      //               console.log('validating changed after previous failure')
      //               this.ValidationFailed = false
      //              this.resetValidation() 
      //             }
      //             else{
      //               console.log('validating changed after NO failure')
      //               this.ValidateForm()
      //             }                  
      //           }
      //         }
      //       },deep: true
      //   },
    },
    computed:{
       SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
        FieldNameProp(){
            return !this.DataTableView ? 'DisplayName' : 'text'
        },
    },
    created(){

    },
    methods:{
      UpdateNewChild(parentobj){
        console.log(parentobj,this.ParentFieldid)
        if(this.ParentFieldid){
          
          let field = this.FormFields.find(obj => obj.id === this.ParentFieldid)
          if(field){
            field.Response = parentobj[field.id.split('_').join('')]
            console.log(field)
            //this.$emit('UpdateNewChild',field,parentobj)
          }          
        }
      },
      ActivateAddNewLookupDialog(field){
        this.ParentFieldid = field.id
        this.$emit('ActivateAddNewLookupDialog',field)
      },
      fieldFilter (item, queryText, itemText) {
          let field = this.FormFields.find(obj => obj.id === itemText)
          const Primary = item[field.LookupFieldName].toLowerCase()
          const searchText = queryText.toLowerCase()
          return Primary.indexOf(searchText) > -1
    },
      SelectLookupResponse(field,Record,item){
        let lookuppbj = {
          [field.LookupFieldName]: item[field.LookupFieldName],
          id: item.id
        }
        field.Response = lookuppbj
        this.SelectionLookupDialog = false
        // Record[field.Name] = lookuppbj
        // Record[field.Name+'id'] = lookupobj.id
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      toggleMultipleRadio(field,_id) {
        let optobj = _id
        if(field.TextOnly && _id.Name){
          optobj = _id.Name
        }
            
            if (field.Response.includes(optobj)) {
            field.Response = field.Response.filter((i) => {
            return i !== optobj;
            });
        } else {
            field.Response = field.Response.concat(optobj);
        }
        //console.log(field.Response)
            
        },
      resetValidation(){
        //console.log('resetting')
        this.ValidationFailed = false
        //console.log('resetting before resubmit ',this.ValidationFailed)
        this.$refs.form.resetValidation()
        if(this.Validating){
          this.ValidateForm()
        }
         
        
      },
      removeWhiteSpace(text) {
        return text ? text.replace(/[\s\/]/g, '') : ''
      },
      CommonFieldRules(field){
        if(field.IsMandatory && !this.FieldIsReadOnly(field)){
          if(field.CommonFieldType === 'Email'){
            return [this.$store.state.formrules.required,this.$store.state.formrules.email]
          }
          else if(field.CommonFieldType === 'Telephone Number'){
            return [this.$store.state.formrules.required,this.$store.state.formrules.telnr]
          }
          else if(field.CommonFieldType === 'URL Link'){
            return [this.$store.state.formrules.required,this.$store.state.formrules.url]
          }          
        }
        else {
          if(field.CommonFieldType === 'Email'){
            return !this.FieldIsReadOnly(field) ? [this.$store.state.formrules.email] : []
          }
          else if(field.CommonFieldType === 'Telephone Number'){
            return !this.FieldIsReadOnly(field) ? [this.$store.state.formrules.telnr] : []
          }
          else if(field.CommonFieldType === 'URL Link'){
            return !this.FieldIsReadOnly(field) ? [this.$store.state.formrules.email] : []
          }
        }

      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
        userBUID () {
        return this.$store.getters.userBUID
        },
        userBUChildren () {
        return this.$store.getters.userBUChildren
        },
        userBUParents () {
        return this.$store.getters.userBUParents
        },
      FieldIsReadOnly(field){
        return field.ReadOnly || field.RelatedReadOnly || this.tab.FormProcessing || field.MappedField && this.userLoggedIn || field.MappedField && !this.userLoggedIn && !field.MappedField.LoggedInUserValue
      },
      ValidateForm(){     
        if(this.$refs.form.validate() || this.AdminTesting){
          this.$emit('save')
        }
        else{
          this.ValidationFailed = true
          alert('Please complete all Form Fields')
        }
      },
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
      GetShortLivedURL(fileobject){
        this.$emit('GetShortLivedURL',fileobject)
      },
      UploadFileSelect(event,field,Record){        
        this.$emit('UploadFileSelect',event.target.files[0],field,Record)
        //this.Refresh()
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(this.field,FieldObject,this.Record)
    //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
    //   alert('should have passed updateemit')
    },
    }
}
</script>

<style>

</style>