<template>
  <span>
      <v-btn v-if="tab.ActionButtonType === 'URL' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200"  :outlined="tab.Outlined" :href="tab.ActionButtonRoute" target="_blank" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
    <v-btn v-if="tab.ActionButtonType === 'Route' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined" :to="'/'+tab.ActionButtonRoute" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
    <v-btn v-if="tab.ActionButtonType === 'File Download' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined"
      @click="DownloadFile(tab.DownloadFile)"
    :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
       {{tab.ActionButtonName}}
    </v-btn>
    <v-btn v-if="tab.ActionButtonType === 'Web Form Dialog' && tab.Fancy || tab.ActionButtonType === 'Content Dialog' && tab.Fancy" @click="WebFormDialogFancy = !WebFormDialogFancy"
     :dark="tab.ActionBtnDark" x-large width="200"  :outlined="tab.Outlined" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
    <v-dialog v-model="WebFormDialogFancy" width="400">
            <v-card class="transition-fast-in-fast-out" width="400"
                style="height: 100%;bottom: 0;position: absolute;right: 0;">
                <v-btn  v-if="tab.ActionButtonType === 'Content Dialog'" @click="OpenDialog(tab.ContentDialogBox,i)">Edit</v-btn>
            <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"										
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"					
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :DialogName="'WebFormDialogFancy'"									
                />
                 <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" @OpenDialog="OpenDialog"								
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" @DeleteTabAsset="DeleteTabAsset"								
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :System="System"							
                />
            </v-card>
    </v-dialog>
        <v-btn v-if="tab.ActionButtonType === 'URL' && !tab.Fancy" :href="tab.ActionButtonRoute" target="_blank"
        :dark="tab.ActionBtnDark" :outlined="!tab.Outlined" :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
          <!-- <a :href="tab.ActionButtonRoute" target="_blank">  {{tab.ActionButtonName}}</a> -->
           {{tab.ActionButtonName}}
        </v-btn>
        <v-btn v-if="tab.ActionButtonType === 'Route' && !tab.Fancy" :dark="tab.ActionBtnDark" :to="'/'+tab.ActionButtonRoute" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
        <v-btn v-if="tab.ActionButtonType === 'File Download' && !tab.Fancy" :dark="tab.ActionBtnDark" :outlined="!tab.Outlined"
          @click="DownloadFile(tab.DownloadFile)"
        :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
           {{tab.ActionButtonName}}
        </v-btn>
         <v-btn v-if="tab.ActionButtonType === 'Web Form Dialog' && !tab.Fancy || tab.ActionButtonType === 'Content Dialog' && !tab.Fancy"
         :dark="tab.ActionBtnDark" @click="WebFormDialogNonFancy = !WebFormDialogNonFancy"
          :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
    <v-dialog v-model="WebFormDialogNonFancy" width="400">
            <template v-slot:activator="{ on }">
        <v-btn v-if="tab.ActionButtonType === 'Web Form Dialog' && !tab.Fancy || tab.ActionButtonType === 'Content Dialog' && !tab.Fancy" :dark="tab.ActionBtnDark" v-on="on" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
            </template>
            <v-card class="transition-fast-in-fast-out" width="400"
                style="height: 100%;bottom: 0;position: absolute;right: 0;">
                  <v-btn  v-if="tab.ActionButtonType === 'Content Dialog'" @click="OpenDialog(tab.ContentDialogBox,i)">Edit</v-btn>
                <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"										
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :DialogName="'WebFormDialogNonFancy'"				
                />
                 <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" @OpenDialog="OpenDialog"								
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" @DeleteTabAsset="DeleteTabAsset"								
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"	 :System="System"						
                />
            </v-card>
    </v-dialog>
  </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTab';

export default {
    props: ['System','SystemEntities','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {WebFormTab},
    data() {
        return {
            WebFormDialogFancy: false, 
            WebFormDialogNonFancy: false,
        }
    },
    computed:{
        ContentDialogBoxType() {
            if(this.tab.ContentDialogType){
              return () => import(`@/components/WebPages/RenderComponents/`+this.tab.ContentDialogType.ComponentName.split(' ').join(''));  
            }
        
        
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
    },
    created(){
        
    },
    methods:{
        DownloadFile(file){
            this.GetShortLivedURL(file)
        },
        GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            GetSignedInURL(item).then(result => {
            let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
            })
        },
         DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
         DeactivateWebDialog(DialogName){
            this[DialogName] = false
        },
        OpenDialog(tab,tabindex){
            // always only for content dialog so tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,
            this.$emit('OpenDialog',tab,tabindex,'','','','',true)
            this.WebFormDialogNonFancy = false
            this.WebFormDialogFancy = false
        }
    }
}
</script>

<style>

</style>
