<template>
  <div> 
    <v-card style="padding:20px;" :color="MatchColor(RelevantTab)" :class="RelevantTab.RowTransparency" tile >
        <v-card-title class="text-h4 mx3" v-if="!SummmaryOnly">
            {{RelevantTab.DisplayName}}
        </v-card-title>
            <ValidatorSummaryTab v-if="SummmaryOnly" :RelevantTab="RelevantTab" @InvokeConfigureAction="InvokeConfigureAction"
            />
        <v-tabs v-if="!SummmaryOnly" centered>
            <v-tab>
                Overview
            </v-tab>
            <v-tab-item>
                    <ValidatorSummaryTab :RelevantTab="RelevantTab" @InvokeConfigureAction="InvokeConfigureAction"
            />
            </v-tab-item>
                <v-tab>
                <v-badge
                        color="pop"
                        
                        :content="RelevantTab.IncompleteItems.length"
                        >
                        Incomplete
                        </v-badge>
            </v-tab>
            <v-tab-item>
                    <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                class="mx-auto my-10"
                height="100%"
                width="100%">
            <v-card-title class="pink--text mediumoverline">
            {{RelevantTab.DisplayName}} - Incomplete Items ({{RelevantTab.IncompleteItems.length}})
            </v-card-title>
        <v-card-text>
            <v-expansion-panels>
                <v-expansion-panel class="my-1"  v-for="item in RelevantTab.IncompleteItems" :key="item.itemObjKey">
                    <v-expansion-panel-header>
                        <v-badge
                        color="pop"
                        overlap
                        :content="item.OutstandingActions"
                        >
                        <v-icon color="blue">
                            {{item.ItemIcon}}
                            </v-icon>
                        {{item.Title}}
                        </v-badge>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card-text>
                            <v-list dense three-line>
                                <v-list-item v-for="elmnt in RelevantElements(item,RelevantTab.Elements)" :key="elmnt.itemObjKey">
                                    <v-list-item-action>
                                        <v-icon>{{elmnt.Icon}}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{elmnt.Title}}</v-list-item-title>
                                        <v-list-item-subtitle v-html="elmnt.Brief"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-icon color="green" v-if="item[elmnt.Title]">
                                            mdi-check
                                        </v-icon>
                                        <v-icon color="red" v-if="!item[elmnt.Title]">
                                            mdi-close
                                        </v-icon>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                            <v-btn :to="item.DocumentationRoute" small>
                                            Read More {{item.DocumentationRoute}}
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        </v-card>
            </v-tab-item>
            <v-tab>
                Complete
            </v-tab>
            <v-tab-item>
                <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                class="mx-auto my-10"
                height="100%"
                width="100%">
            <v-card-title class="pink--text mediumoverline">
            {{RelevantTab.DisplayName}} - Complete ({{RelevantTab.ReadyItems.length}})
            </v-card-title>
        <v-card-text>
            <v-expansion-panels>
                <v-expansion-panel class="my-1"  v-for="item in RelevantTab.ReadyItems" :key="item.itemObjKey">
                    <v-expansion-panel-header>
                        {{item.Title}}
                        <template v-slot:actions>
                            <v-icon color="blue">
                            {{item.ItemIcon}}
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card-text>
                            <v-list dense three-line>
                                <v-list-item  v-for="elmnt in RelevantElements(item,RelevantTab.Elements)" :key="elmnt.itemObjKey">
                                        <v-list-item-action>
                                        <v-icon>{{elmnt.Icon}}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{elmnt.Title}}</v-list-item-title>
                                        <v-list-item-subtitle v-html="elmnt.Brief"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-icon color="green" v-if="item[elmnt.Title]">
                                            mdi-check
                                        </v-icon>
                                        <v-icon color="red" v-if="!item[elmnt.Title]">
                                            mdi-close
                                        </v-icon>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        </v-card>
            </v-tab-item>
        </v-tabs>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import SystemGuideComponent from '@/components/WebPages/RenderComponents/SystemGuideComponent';
import DashboardElmtChart from '@/components/SuitePlugins/CustomDashboard/DashboardElmtChart';
import DashboardElmtDataContext from '@/components/SuitePlugins/CustomDashboard/DashboardElmtDataContext';
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder';
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
import ValidatorSummaryTab from '@/components/SuiteBuilder/Support/ValidatorSummaryTab';
export default {
    props: ['System','SingleRecordNavMenuItem','ActiveDB','SystemGuides','RADB','RAApp','Module','SystemEntities','Directories','SitePages','SummmaryOnly'],
    components: {InfoSheet,SystemGuideComponent,DashboardElmtChart,DashboardElmtDataContext,DashboardBuilder,CounterTab,ValidatorSummaryTab},
    data() {
        return {
            DirectoryEntries: [],
            SystemUsers: [],
            SystemBusinessUnits: [],
            PublicPageViews: [],
            GroupLandingPageViews: [],
            LandingPageViews: [],
            PublicLandingPages: [],
            InternalLandingPages: [],
            SocialPageViews: [],
            colors: [									
                    'red',									
                    'pink',									
                    'purple',									
                    'deep-purple',									
                    'indigo',									
                    'blue',									
                    'light-blue',									
                    'cyan',									
                    'teal',									
                ],
            systemguideelmnt: {			
              HideGuidesCarousel: true,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            defaulttab: {									
                Open: true,									
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Name: 'blank',	
                DisplayName: 'blank',									
                Elements: [],									
                ShowComponents: true,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                },
            GotEntities: false,
            SiteModerators: [],
            SitePages: [],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,								
            },	
            SystemTickets: [],
            RoadmapItems: [],
        }
    },
    computed:{
        ReadinessStyle(){
            return this.MiniView ? 'position: absolute;width: 75px;height: 75px;border-radius:50px;animation: spin 4   s linear infinite;' :
            'position: absolute;width: 150px;height: 150px;border-radius:100px;animation: spin 4   s linear infinite;'
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        RelevantTab(){
            if(this.Module.Name === 'Database Builder'){
                return this.StoredDataBaseModuleTab
            }
            else if(this.Module.Name === 'Website Builder'){
                return this.StoredWebsiteModuleTab
            }
            else if(this.Module.Name === 'Documentation Builder'){
                return this.StoredSocialModuleTab
            }
            else if(this.Module.Name === 'Social Network Builder'){
                return this.StoredSocialModuleTab
            }
        },
        BUSystemEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Security_Structure.Name === 'Business Unit'
            })
        },
        CustomRoleSystemEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Security_Structure.Name === 'Custom Roles'
            })
        },
        DataNavlists(){
            let storeproductentities = this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Inventory Article'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let classifiedentities = this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let datanavlists = storeproductentities.concat(classifiedentities)
            // socil validator only suite level,[
            //     {
            //     title: 'Classifieds',
            //     icon: 'mdi-newspaper-variant-outline',
            //     ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Classifieds'),
            //     IsRoute: true,
            //     Path: '/DirectoryBuilder/Classifieds'
            // },{
            //     title: 'Group Categories',
            //     icon: 'mdi-account-group',
            //     ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Site_Groups'),
            //     IsRoute: true,
            //     Path: '/DirectoryBuilder/Group_Categories'
            // },{
            //     title: 'Group Featured Members',
            //     icon: 'mdi-account-star-outline',
            //     ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Group_Featured_Members'),
            //     IsRoute: true,
            //     Path: '/DirectoryBuilder/Group_Featured_Members'
            // },{
            //     title: 'Business Members',
            //     icon: 'mdi-smart-card',
            //     ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Digital_Business_Cards'),
            //     IsRoute: true,
            //     Path: '/DirectoryBuilder/Business_Members'
            // }
            // ])
            return datanavlists.filter(item => {
                return item.ItemPass
            })
        },
        StoredDataBaseModuleTab(){
            let itemtab = Object.assign({},this.defaulttab)
            itemtab.DisplayName = 'Database Module'
            itemtab.Alerts = true
            let baseelements = [
                    {
                    Name: 'Data Tables have Single Forms?',
                    Check: 'SingleBuildTabs',
                    array: 'SystemEntities',
                    Brief: 'We need to check if the Data Table has Single Forms. Without Single Forms being configured it means opening a record from a Table would show a blank page.',
                    Icon: 'mdi-database',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },
                {
                    Name: 'BU Securty roles assigned?',
                    Check: 'BU Security Roles',
                    array: 'BUSystemEntities',
                    Brief: 'Configuring a Data Table to a Security Structure is good preparation, but without creating security roles and assigning to a table, nobody but the Administrator would ever be able to access these tables.',
                    Icon: 'mdi-security-network',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs',
                    ActionName: 'Configure'
                },
                {
                    Name: 'Custom Security Roles assigned?',
                    Check: 'Custom Security Roles',
                    array: 'CustomRoleSystemEntities',
                    Brief: 'Configuring a Data Table to a Security Structure is good preparation, but without creating security roles and assigning to a table, nobody but the Administrator would ever be able to access these tables.',
                    Icon: 'mdi-security-network',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs',
                    ActionName: 'Configure'
                },
                {
                    Name: 'Listing Type has Tables?',
                    Check: 'Classified Table Entries',
                    array: 'ClassifiedListingEntities',
                    Brief: 'A Classified Listing type Data Table requires a Classified Table.',
                    Icon: 'mdi-table-cog',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },
                {
                    Name: 'Listing Type has NavLists?',
                    Check: 'Classified NavList',
                    array: 'ClassifiedListingEntities',
                    Brief: 'A Classified Listing type Data Table requires a Classified Navigational List in order to be viewable from Pages.',
                    Icon: 'mdi-menu',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },
                {
                    Name: 'Listing Type has Combined Title?',
                    Check: 'Classified Combined Title',
                    array: 'ClassifiedListingEntities',
                    Brief: 'A Classified Listing type Data Table requires a Combined Title in order for the page link to have a conventional name, e.g. Vehicle/Mercedes-Benx-C180_2 as opposed to Vehicle/sLN8ibP71ojJa77lFNnE.',
                    Icon: 'mdi-format-title',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },
                {
                    Name: 'Wiki Type has Tables?',
                    Check: 'Wiki Table Entries',
                    array: 'WikiEntities',
                    Brief: 'A Wiki Entity requires a Wiki Table.',
                    Icon: 'mdi-table-cog',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },
                {
                    Name: 'Directory Created?',
                    Check: 'Directories Setup',
                    array: 'DataNavlists',
                    Brief: 'A Directory needs to be configured.',
                    Icon: 'mdi-table-cog',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs',
                    ActionName: 'Configure'
                }, 
                {
                    Name: 'Directory Listings?',
                    Check: 'Directory Entries',
                    array: 'DataNavlists',
                    Brief: 'The Directory needs entries in order to display items.',
                    Icon: 'mdi-table-cog',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs',
                    ActionName: 'Configure'
                },               
                    
                ]
            itemtab.Elements = this.GetItemElements(baseelements)            
            let datatablestd = this.PrepItemElements(itemtab.Elements)                
            let datatabletablistlength = datatablestd.length
            itemtab.Total = datatabletablistlength*100
            itemtab.AllItems = this.SystemEntities.map(ent => {
                let entobj = Object.assign({},ent)
                return entobj
            })
            let finaltab = this.FinalPrep(itemtab,datatablestd,datatabletablistlength,'DisplayName','Table_Icon')
            return finaltab
        },
        RelevantDirectoryEntries(){
            return this.DirectoryEntries.filter(item => {
                return this.SystemEntities.find(obj => obj.id === item.Entityid)
            })
        },
        StoredWebsiteModuleTab(){
            let itemtab = Object.assign({},this.defaulttab)
            itemtab.DisplayName = 'Website Module'
            itemtab.WebsiteModule = true
            itemtab.Alerts = true
            let baseelements = [
                {
                    Name: 'Website has Default Landing Page?',
                    Check: 'Website Landing Page',
                    array: '',
                    Brief: 'We need to add a Default Landing Page when toggling to the Social Module within the App.',
                    Icon: 'mdi-page-next-outline',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                }, 
                {
                    Name: 'Web Pages have Tabs?',
                    Check: 'WebPageTabs',
                    array: 'PublicPages',
                    Brief: 'We to add Tabs for a Page or else it will display as empty.',
                    Icon: 'mdi-page-next-outline',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },     
                {
                    Name: 'Web Pages are on Menu?',
                    Check: 'Web Pages on Menu',
                    array: 'PublicPages',
                    Brief: 'We need to add Tabs Social Pages on the menu, OR mark them as "Non-menu" items.',
                    Icon: 'mdi-menu',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                }, 
                    
                ]
            
            itemtab.Elements = this.GetItemElements(baseelements)
            let webmodstd = this.PrepItemElements(itemtab.Elements)                
            let websitemoduletablistlength = webmodstd.length
            itemtab.Total = websitemoduletablistlength*100
            itemtab.AllItems = this.WebModuleItems.map(ent => {
                let entobj = Object.assign({},ent)
                return entobj
            })
            let finaltab = this.FinalPrep(itemtab,webmodstd,websitemoduletablistlength,'Name','PageIcon')
            return finaltab
        },
        WebModuleItems(){
            return this.PublicPages
        },
        PublicPages(){
            return this.SitePages.filter(page => {
                return page.PublishType === 'Public'
            })
        },
        StoredSocialModuleTab(){
            let itemtab = Object.assign({},this.defaulttab)
            itemtab.DisplayName = 'Social Module'
            itemtab.SocialModule = true
            itemtab.Alerts = true
            let baseelements = [
                {
                    Name: 'Social Module has Default Landing Page?',
                    Check: 'Social Module Landing Page',
                    array: '',
                    Brief: 'We need to add a Default Landing Page when toggling to the Social Module within the App.',
                    Icon: 'mdi-page-next-outline',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                }, 
                {
                    Name: 'Social Pages have Tabs?',
                    Check: 'SocialPageTabs',
                    array: 'SocialPages',
                    Brief: 'We to add Tabs for a Page or else it will display as empty.',
                    Icon: 'mdi-page-next-outline',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },     
                {
                    Name: 'Social Pages are on Menu?',
                    Check: 'Social Pages on Menu',
                    array: 'SocialPages',
                    Brief: 'We need to add Tabs Social Pages on the menu, OR mark them as "Non-menu" items.',
                    Icon: 'mdi-menu',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                }, 
                {
                    Name: 'Component Internal Moderators Assigned?',
                    Check: 'Internal Moderators Assigned',
                    array: 'SocialComponents',
                    Brief: 'We need to assign Moderators who can create Social Component Entries.',
                    Icon: 'mdi-page-next-outline',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                },
                {
                    Name: 'Component Categories Created?',
                    Check: 'Social Component Categories',
                    array: 'SocialComponents',
                    Brief: 'We need to create Categories that can be assigned when creating Social Component Entries.',
                    Icon: 'mdi-page-next-outline',
                    DocumentationRoute: '/Documentation/Suite_Builder/Data-Record-Tabs'
                }, 
              
                       
                    
                ]
                
            itemtab.Elements = this.GetItemElements(baseelements)
            let socialmodstd = this.PrepItemElements(itemtab.Elements)                
            let socialmoduletablistlength = socialmodstd.length
            itemtab.Total = socialmoduletablistlength*100
            itemtab.AllItems =  this.SocialModuleItems.map(ent => {
                let entobj = Object.assign({},ent)
                return entobj
            })
            let finaltab = this.FinalPrep(itemtab,socialmodstd,socialmoduletablistlength,'Name','PageIcon')
            return finaltab
        },
        SocialModuleItems(){
            return this.SocialPages.concat(this.SocialComponents)
        },
        SocialPages(){
            return this.SitePages.filter(page => {
                return page.PublishType !== 'Public'
            })
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },       
        SocialComponents(){
            let array = [
                {id: 'Site_Articles',Name: 'Site Articles',DisplayName: 'Site Articles',PluralName: 'Articles',SingleName: 'Article',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Articles',HasCategories: true,CatProp: 'Article_Categories'},
                {id: 'Site_Blogs',Name: 'Site Blogs',DisplayName: 'Site Blogs',PluralName: 'Blogs',SingleName: 'Blog',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Blogs',HasCategories: true,CatProp: 'Blog_Categories'},
                {id: 'Site_Classifieds',Name: 'Site Classifieds',DisplayName: 'Site Classifieds',PluralName: 'Classifieds',SingleName: 'Classified',PageIcon: 'mdi-newspaper',Pluginid: 'Classifieds'},
                {id: 'Site_Events',Name: 'Site Events',DisplayName: 'Site Events',PluralName: 'Events',SingleName: 'Event',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Events'},
                {id: 'Site_Forums',Name: 'Site Forums',DisplayName: 'Site Forums',PluralName: 'Forums',SingleName: 'Forum',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Forums'},
                {id: 'Site_Groups',Name: 'Site Groups',DisplayName: 'Site Groups',PluralName: 'Groups',SingleName: 'Group',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Groups',HasCategories: true,CatProp: 'Group_Categories'},
                {id: 'Site_Meetups',Name: 'Site Meetups',DisplayName: 'Site Meetups',PluralName: 'Meetups',SingleName: 'Meetup',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Meetups'},
                {id: 'Site_Polls',Name: 'Site Polls',DisplayName: 'Site Polls',PluralName: 'Polls',SingleName: 'Poll',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Polls'},
            ]
            return array.filter(item => {
                return this.PluginDataBase[item.Pluginid] && this.PluginDataBase[item.Pluginid].Active
            })
        }, 
        ConfigSystemid(){
            if(this.$route.meta && this.$route.meta.SystemBuilder){
                return this.$route.params.id
            }
            else{
                return this.System.id
            }  
        },
        RASystemGuides(){
            return this.SystemGuides.filter(guide => {
                if(this.$route.meta && this.$route.meta.SystemBuilder){
                    return guide
                }
                else{
                 return guide.Provider && guide.Provider.Name === 'RapidApps'   
                }                
            })
        },
        
        ClassifiedListingEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            })
        },
        WikiEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Has_Wiki
            })
        },
        
    },
    
    created(){
        this.SystemEntities.map(ent => {
            if(ent.Entity_Type.Name === 'Inventory Article'){
                let query = db.collection(ent.id.split('_').join('').toLowerCase()+'store').limit(1)
                query.onSnapshot(res => {
                    const changes = res.docChanges();
                        changes.forEach(change => {
                            if (change.type === 'added') {
                            let recordobj = {
                                ...change.doc.data(),
                                id: change.doc.id
                            }
                            recordobj.Entityid = ent.id
                            this.DirectoryEntries.push(recordobj)
                            }
                        })
                })
            }
        })
        //console.log(this.ActiveDB)
        //let systemquery = this.ActiveDB.collection('SystemConfig').doc(this.ConfigSystemid)
    },
    methods:{
         InvokeConfigureAction(elmnt){
            this.$emit('InvokeConfigureAction',elmnt)
        },
        FinalPrep(itemtab,children,childrenlength,childnameprop,childiconprop){
            itemtab.AllItems.map(item => {
                    item.OutstandingActions = 0
                    children.map(elmnt => {
                        let ValidationRule = elmnt.Title
                        let oncheck = elmnt.AllItems.find(obj => obj.id === item.id)
                        //console.log('ValidationRule',ValidationRule,item.id,oncheck,elmnt.IncompleteItems)
                        if(oncheck){
                            
                            let incompletecheck = elmnt.IncompleteItems.find(obj => obj.id === item.id)
                            if(incompletecheck){
                                item.Incomplete = true
                                item[ValidationRule] = false
                                item.OutstandingActions++
                            }
                            else{
                                item[ValidationRule] = true
                            }    
                        }
                        else{
                            item[ValidationRule] = 'N/A'
                        }
                    })
                    item.Title = item[childnameprop]
                    item.ItemIcon = item[childiconprop]
                    return item
                })
                itemtab.ReadyItems = itemtab.AllItems.filter(item => {return !item.Incomplete})
                itemtab.Progress = children.map(item => {return item.Progress}).reduce((a, b) => a + b, 0)/childrenlength
                itemtab.IncompleteItems = itemtab.AllItems.filter(item => {return item.Incomplete})
                itemtab.Incomplete = children.map(item => {return item.IncompletePercentage}).reduce((a, b) => a + b, 0)/childrenlength
                return itemtab
        },
        PrepItemElements(Elements){
            return Elements.map(object => {
                    object = this.GetTableProgress(object)
                if(object.Progress === 100){
                        object.Color = 'green'
                        object.Description = `All in Good Standing<p>Ready to Use</p>`
                        object.AlertIcon = 'mdi-cloud-check'
                    }
                    else if(object.Progress >= 50){
                        object.Color = 'warning'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else if(object.Complete === 0){
                        object.Color = 'red'
                        object.Description = `NONE in Good Standing <p class="red--text">All `+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else{
                        object.Color = 'red'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }    
                    return object
                })
        },
        GetItemElements(baseelements){
            return baseelements.map(item => {
                let object = Object.assign({},this.elmnt)
                if(item.ActionName){
                    object.ActionName = item.ActionName
                }
                object.Title = item.Name
                object.Brief = item.Brief
                if(item.Check === 'BU Security Roles' || item.Check === 'Custom Security Roles'){
                    if(this.DataNavlists.length > 0){
                     object.Brief = object.Brief+`<br>This would also impact the access to the Directory Page`
                    }                    
                }
                object.Icon = item.Icon
                object.DocumentationRoute = item.DocumentationRoute
                //object.SystemGuides = [item.SystemGuides[0]]
                object.Check = item.Check
                object.array = item.array
                return object
            }).filter(object => {
                return this[object.array] && this[object.array].length > 0
            })
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
        SaveStoredValidatorTabs(){
            alert('Still To Do. Save to Ra, Maintain on RA, call from Ra from then')
        },
        RelevantElements(item,Elements){
            return Elements.filter(elmnt => {
                return item[elmnt.Title] !== 'N/A'
            })
        },
        GetElmntReadiness(tab){
            if(tab.Progress === 100){
                return 'border-left: 6px solid green;'
            }
             else if(tab.Progress >= 50){
                return 'border-left: 6px solid orange;'
            }
            else{
                return 'border-left: 6px solid red;'
            }
        },
        TabReadinessIcon(tab){
            if(tab.Progress === 100){
                return 'mdi-cloud-check'
            }
            else if(tab.Progress >= 50){
                return 'mdi-alert'
            }
            else{
                return 'mdi-alert'
            }
        },
        TabReadinessTitleColor(tab){
            if(tab.Progress === 100){
                return 'green'
            }
            else if(tab.Progress >= 50){
                return 'pink'
            }
            else{
                return 'red'
            }
        },
        TabReadinessProgressColor(tab){
            if(tab.Progress === 100){
                return 'blue lighten-4'
            }
            else if(tab.Progress >= 50){
                return 'warning'
            }
            else{
                return 'red'
            }
        },
        TabReadiness(tab){
            if(tab.Progress === 100){
                return 'Ready'
            }
            else{
                return 'Incomplete'
            }
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        MatchColor(tab){
            if(tab.RowColor){
                return tab.RowColor.hexa
            }
            
        },
        
        GetTableProgress(object){            
            object.AllItems = []
            object.ReadyItems = []
            object.IncompleteItems = []

            if(object.Check === 'SingleBuildTabs'){
                object.AllItems = this[object.array]
                object.ReadyItems = object.AllItems.filter(entity => {
                    return entity.SingleBuildTabs && entity.SingleBuildTabs.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.SingleBuildTabs || entity.SingleBuildTabs && entity.SingleBuildTabs.length === 0
                })                
            }            
            else if(object.Check === 'Directories Setup'){
                object.AllItems = this[object.array].filter(entity => {
                    return entity
                })
                object.ReadyItems = object.AllItems.filter(entity => {
                    return this.Directories.find(obj => obj.id === entity.id)
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !this.Directories.find(obj => obj.id === entity.id)
                })
            }
            else if(object.Check === 'Directory Entries'){
                object.AllItems = this[object.array].filter(entity => {
                    return entity
                })
                object.ReadyItems = object.AllItems.filter(entity => {
                    return this.RelevantDirectoryEntries.find(obj => obj.Entityid === entity.id)
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !this.RelevantDirectoryEntries.find(obj => obj.Entityid === entity.id)
                })
            }
            
            else if(object.Check === 'BU Security Roles'){
                object.AllItems = this[object.array].filter(entity => {
                    return entity.Security_Structure && entity.Security_Structure.Name === 'Business Unit'
                })
                object.ReadyItems = object.AllItems.filter(entity => {
                    return entity.AllistRoles
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.AllistRoles
                })
            }
            else if(object.Check === 'Custom Security Roles'){
                object.AllItems = this[object.array].filter(entity => {
                    return entity.Security_Structure && entity.Security_Structure.Name === 'Custom Roles'
                })
                object.ReadyItems = object.AllItems.filter(entity => {
                    return entity.AllistRoles
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.AllistRoles
                })
            }
            else if(object.Check === 'Classified Table Entries'){
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.ClassifiedEntity
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.ClassifiedEntity
                })
            }
            else if(object.Check === 'Wiki Table Entries'){
                object.AllItems = this[object.array]
                //console.log(object.AllItems)
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.WikiEntity
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.WikiEntity
                })
            }            
            else if(object.Check === 'Classified NavList'){
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.EntityNavList
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.EntityNavList
                })
            }
            else if(object.Check === 'Classified Combined Title'){
                
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.EntityNavList && entity.EntityNavList.CombinedTitleMap
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.EntityNavList || entity.EntityNavList && !entity.EntityNavList.CombinedTitleMap
                })
            }
            
            else if(object.Check === 'Website Landing Page'){
                
                object.AllItems = [
                    {
                        id: 'Public_Landing_Page',
                        Name: 'Public Landing Page'
                    }
                ]
                let check = this.System[object.AllItems[0].id]
                if(check){
                    object.ReadyItems = object.AllItems
                    object.IncompleteItems = []    
                }
                else{
                    object.ReadyItems = []
                    object.IncompleteItems = object.AllItems
                }
            }
            else if(object.Check === 'WebPageTabs'){
                
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(page => {
                    return page.PageTabs && page.PageTabs.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(page => {
                    return !page.PageTabs || page.PageTabs && page.PageTabs.length === 0
                })
            }
            else if(object.Check === 'Web Pages on Menu'){
                
                object.AllItems = this[object.array].filter(page => {return !page.NonMenuItem})
                .map(page => {
                    if(this.System.PublicNavbarItems){
                     let paths = this.System.PublicNavbarItems.map(child => {return child.title})
                     if(paths.includes(page.Name)){
                            page.OnMenu = true
                        } 
                    }                    
                    return page
                })  
                object.ReadyItems =  object.AllItems.filter(page => {                    
                    return page.OnMenu
                })
                object.IncompleteItems = object.AllItems.filter(page => {                    
                    return !page.OnMenu
                })
            }
            else if(object.Check === 'SocialPageTabs'){
                
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(page => {
                    return page.PageTabs && page.PageTabs.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(page => {
                    return !page.PageTabs || page.PageTabs && page.PageTabs.length === 0
                })
            }
            else if(object.Check === 'Social Pages on Menu'){
                
                object.AllItems = this[object.array].filter(page => {return !page.NonMenuItem})
                .map(page => {
                    if(this.System.SocialNavbarItems){
                     this.System.SocialNavbarItems.map(item => {
                        let paths = []
                        if(item.Children){
                            paths = item.Children.map(child => {return child.title})
                        if(paths.includes(page.Name)){
                            page.OnMenu = true
                        }
                        }
                    })   
                    }                    
                    return page
                })  
                object.ReadyItems =  object.AllItems.filter(page => {                    
                    return page.OnMenu
                })
                object.IncompleteItems = object.AllItems.filter(page => {                    
                    return !page.OnMenu
                })
            }
            else if(object.Check === 'Social Module Landing Page'){
                
                object.AllItems = [
                    {
                        id: 'Social_Landing_Page',
                        Name: 'Social Landing Page'
                    }
                ]
                let check = this.System[object.AllItems[0].id]
                if(check){
                    object.ReadyItems = object.AllItems
                    object.IncompleteItems = []    
                }
                else{
                    object.ReadyItems = []
                    object.IncompleteItems = object.AllItems
                }
            }
            else if(object.Check === 'Social Component Categories'){
                object.AllItems = this[object.array].filter(comp => {return comp.HasCategories})
                object.ReadyItems =  object.AllItems.filter(comp => {                    
                    return this.System[comp.CatProp] && this.System[comp.CatProp].Options.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(comp => {                    
                    return !this.System[comp.CatProp] || this.System[comp.CatProp] && this.System[comp.CatProp].Options.length === 0
                })
            }
            else if(object.Check === 'Internal Moderators Assigned'){
                let moderatoritems = this.SiteModerators.map(mod => {return mod.ModerationPermissions}).flat()
                
                object.AllItems = this[object.array]                
                object.ReadyItems = object.AllItems.filter(item => {                    
                    let check = moderatoritems.find(obj => obj === item.PluralName)
                    return check
                })
                
                object.IncompleteItems = object.AllItems.filter(item => {
                    let check = moderatoritems.find(obj => obj === item.PluralName)
                    return !check
                })
                
                
                
            }
            //console.log('object ',object)
            let total = object.AllItems.length
            let progress =  object.ReadyItems.length
            object.Progress = (progress/total)*100
            object.Total = total
            object.Incomplete = total-progress
            object.IncompletePercentage = (object.Incomplete/object.Total)*100
            object.Complete = progress
            return object
            
        },
        GetEntityNavList(build,subcol){
            let vm = this
            // console.log('calling props')
            return new Promise(function(resolve, reject) {
            vm.ActiveDB.collection('SystemConfig').doc(vm.ConfigSystemid).collection('Directories').doc(build.id).onSnapshot(clsfdsnapshot => {
                let clsfddata = clsfdsnapshot.data()
                build.EntityNavList = clsfddata
                resolve(build)
            })
            })
        },
        
        					
    }
}
</script>

<style>

</style>
