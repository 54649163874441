<template>
   <v-card flat tile class="transparent">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
           <!-- <v-list  v-if="WikiType && $route.meta.ClassifiedListingBuilder">                             
                <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Title' || EditedTab.Name === 'Image' || EditedTab.Name === 'Parallax'">									
                    <v-switch v-model="EditedTab.ContentFromField" label="From Field"/>									
                </v-list-item>
           </v-list> -->
           <v-list-item>
            <v-switch @change="CheckWaves(EditedTab,'Top')" v-model="EditedTab.HasTopWaves" label="Has Top Waves"/>
             <v-switch @change="CheckWaves(EditedTab,'Bottom')" v-model="EditedTab.HasBottomWaves" label="Has Bottom Waves"/>
           </v-list-item> 
           <v-list-item v-if="EditedTab.HasBottomWaves || EditedTab.HasTopWaves">
            <v-select @change="Refresh()" v-model="EditingWave" label="Select Wave" :items="TabOptions"/>
           </v-list-item>    
           <v-tabs>
           <v-tab>
            Colors
           </v-tab>
            <v-tab-item>
               <QuickEditComponent v-if="EditingWave && !Refreshing" :System="System" :SystemEntities="SystemEntities" :Record="EditedTab" :QuickEditSections="ColorProps"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            />
            </v-tab-item>
             <v-tab>
            Timing
           </v-tab>
            <v-tab-item>
                 <QuickEditComponent v-if="EditingWave && !Refreshing" :System="System" :SystemEntities="SystemEntities" :Record="EditedTab" :QuickEditSections="TimingProps"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            />
            </v-tab-item>
            <v-tab>
            Paths
           </v-tab>
            <v-tab-item>
                 <QuickEditComponent v-if="EditingWave && !Refreshing" :System="System" :SystemEntities="SystemEntities" :Record="EditedTab" :QuickEditSections="PathProps"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            />
            </v-tab-item>
             <v-tab>
            Positions
           </v-tab>
            <v-tab-item>
                 <QuickEditComponent v-if="EditingWave && !Refreshing" :System="System" :SystemEntities="SystemEntities" :Record="EditedTab" :QuickEditSections="PosProps"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            />
            </v-tab-item>
            
             <v-tab>
            Settings
           </v-tab>
            <v-tab-item>
                 <QuickEditComponent v-if="EditingWave && !Refreshing" :System="System" :SystemEntities="SystemEntities" :Record="EditedTab" :QuickEditSections="SettingsProps"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            />
            </v-tab-item>
           </v-tabs> 
               <!-- <QuickEditComponent v-if="EditingWave && !Refreshing" :System="System" :SystemEntities="SystemEntities" :Record="EditedTab" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            /> -->
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import QuickEditComponent from '@/components/Database/QuickEditComponent'
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {QuickEditComponent},
    data() {
        return {
            EditingWave: '',
            DefaultProps: [
                {Name: 'TopWaveCount',DisplayName: 'Count',Type: 'Number Field',Default: 4},{Name: 'TopWaveDirection',Default: 'Left',Type: 'Option Set',TextOnly: true,Options: ['Left','Right']},
                {Name: 'TopWaveAspectRatio',DisplayName: 'Aspect Ratio',Type: 'Option Set',Default: 'xMaxYMax slice',TextOnly: true,
                Options: ['none','xMinYMin slice','xMidYMin slice','xMaxYMin slice','xMinYMid slice','xMidYMid slice','xMaxYMid slice','xMinYMax slice','xMidYMax slice','xMaxYMax slice',
                'xMinYMin meet','xMidYMin meet','xMaxYMin meet','xMinYMid meet','xMidYMid meet','xMaxYMid meet','xMinYMax meet','xMidYMax meet','xMaxYMax meet']},
                {Name: 'TopWaveRestrictHeight',DisplayName: 'Restrict Height',Type: 'Boolean',Default: true},
                {Name: 'TopWaveHeight',DisplayName: 'Height',Type: 'Number Field',Default: 300},
                {Name: 'TopWave1Path',DisplayName: 'Wave 1 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'TopWave2Path',DisplayName: 'Wave 2 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'TopWave3Path',DisplayName: 'Wave 3 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'TopWave4Path',DisplayName: 'Wave 4 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'TopWave1XPos',DisplayName: 'Wave 1 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'TopWave1YPos',DisplayName: 'Wave 1 Y Pos',Type: 'Number Field',Slider: true,Default: 3},
                {Name: 'TopWave2XPos',DisplayName: 'Wave 2 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'TopWave2YPos',DisplayName: 'Wave 2 Y Pos',Type: 'Number Field',Slider: true,Default: 0},
                {Name: 'TopWave3XPos',DisplayName: 'Wave 3 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'TopWave3YPos',DisplayName: 'Wave 3 Y Pos',Type: 'Number Field',Slider: true,Default: 9},
                {Name: 'TopWave4XPos',DisplayName: 'Wave 4 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'TopWave4YPos',DisplayName: 'Wave 4 Y Pos',Type: 'Number Field',Slider: true,Default: 6},
                {Name: 'TopWave1Still',DisplayName: 'Wave 1 Still',Type: 'Boolean',Default: false},
                {Name: 'TopWave2Still',DisplayName: 'Wave 2 Still',Type: 'Boolean',Default: false},
                {Name: 'TopWave3Still',DisplayName: 'Wave 3 Still',Type: 'Boolean',Default: false},
                {Name: 'TopWave4Still',DisplayName: 'Wave 4 Still',Type: 'Boolean',Default: false},
                {Name: 'TopWave1Color',DisplayName: 'Wave 1 Clr',Type: 'Color Picker',Default: { "alpha": 0.66, "hex": "#FF6633", "hexa": "#FF6633A8", "hsla": { "h": 15, "s": 1, "l": 0.6, "a": 0.66 }, "hsva": { "h": 15, "s": 0.8, "v": 1, "a": 0.66 }, "hue": 15, "rgba": { "r": 255, "g": 102, "b": 51, "a": 0.66 } }},
                {Name: 'TopWave2Color',DisplayName: 'Wave 2 Clr',Type: 'Color Picker',Default: { "alpha": 0.4, "hex": "#52C2E7", "hexa": "#52C2E767", "hsla": { "h": 194.8993288590604, "s": 0.7563451776649746, "l": 0.6137254901960785, "a": 0.4 }, "hsva": { "h": 194.8993288590604, "s": 0.6450216450216449, "v": 0.9058823529411765, "a": 0.4 }, "hue": 194.8993288590604, "rgba": { "r": 82, "g": 194, "b": 231, "a": 0.4 } }},
                {Name: 'TopWave3Color',DisplayName: 'Wave 3 Clr',Type: 'Color Picker',Default: { "alpha": 0.47, "hex": "#4F7AE6", "hexa": "#4F7AE677", "hsla": { "h": 222.91390728476821, "s": 0.7512437810945274, "l": 0.6058823529411765, "a": 0.47 }, "hsva": { "h": 222.91390728476821, "s": 0.6565217391304348, "v": 0.9019607843137255, "a": 0.47 }, "hue": 222.91390728476821, "rgba": { "r": 79, "g": 122, "b": 230, "a": 0.47 } }},
                {Name: 'TopWave4Color',DisplayName: 'Wave 4 Clr',Type: 'Color Picker',Default: { "alpha": 0.6, "hex": "#F2F4C0", "hexa": "#F2F4C098", "hsla": { "h": 62.30769230769233, "s": 0.7027027027027029, "l": 0.8549019607843138, "a": 0.6 }, "hsva": { "h": 62.30769230769233, "s": 0.2131147540983607, "v": 0.9568627450980393, "a": 0.6 }, "hue": 62.30769230769233, "rgba": { "r": 242, "g": 244, "b": 192, "a": 0.6 } }},
                {Name: 'TopWave1Speed',DisplayName: 'Wave 1 Spd',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s'],Default: '10s'},
                {Name: 'TopWave2Speed',DisplayName: 'Wave 2 Spd',Default: '8s',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s']},
                {Name: 'TopWave3Speed',DisplayName: 'Wave 3 Spd',Default: '6s',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s']},
                {Name: 'TopWave4Speed',DisplayName: 'Wave 4 Spd',Default: '4s',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s']},
                {Name: 'BottomWaveCount',DisplayName: 'Count',Type: 'Number Field',Default: 4},{Name: 'BottomWaveDirection',Default: 'Left',Type: 'Option Set',TextOnly: true,Options: ['Left','Right']},
                {Name: 'BottomWaveAspectRatio',DisplayName: 'Aspect Ratio',Type: 'Option Set',Default: 'xMaxYMax slice',TextOnly: true,
                 Options: ['none','xMinYMin slice','xMidYMin slice','xMaxYMin slice','xMinYMid slice','xMidYMid slice','xMaxYMid slice','xMinYMax slice','xMidYMax slice','xMaxYMax slice',
                'xMinYMin meet','xMidYMin meet','xMaxYMin meet','xMinYMid meet','xMidYMid meet','xMaxYMid meet','xMinYMax meet','xMidYMax meet','xMaxYMax meet']},
                {Name: 'BottomWaveRestrictHeight',DisplayName: 'Restrict Height',Type: 'Boolean',Default: true},
                {Name: 'BottomWaveHeight',DisplayName: 'Height',Type: 'Number Field',Default: 300},
                {Name: 'BottomWave1Path',DisplayName: 'Wave 1 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'BottomWave2Path',DisplayName: 'Wave 2 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'BottomWave3Path',DisplayName: 'Wave 3 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'BottomWave4Path',DisplayName: 'Wave 4 Path',Type: 'Multiple Lines Text',TextOnly: true,Default: `<path d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />`},
                {Name: 'BottomWave1XPos',DisplayName: 'Wave 1 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'BottomWave1YPos',DisplayName: 'Wave 1 Y Pos',Type: 'Number Field',Slider: true,Default: 3},
                {Name: 'BottomWave2XPos',DisplayName: 'Wave 2 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'BottomWave2YPos',DisplayName: 'Wave 2 Y Pos',Type: 'Number Field',Slider: true,Default: 0},
                {Name: 'BottomWave3XPos',DisplayName: 'Wave 3 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'BottomWave3YPos',DisplayName: 'Wave 3 Y Pos',Type: 'Number Field',Slider: true,Default: 9},
                {Name: 'BottomWave4XPos',DisplayName: 'Wave 4 X Pos',Type: 'Number Field',Slider: true,Default: 50},
                {Name: 'BottomWave4YPos',DisplayName: 'Wave 4 Y Pos',Type: 'Number Field',Slider: true,Default: 6},
                {Name: 'BottomWave1Still',DisplayName: 'Wave 1 Still',Type: 'Boolean',Default: false},
                {Name: 'BottomWave2Still',DisplayName: 'Wave 2 Still',Type: 'Boolean',Default: false},
                {Name: 'BottomWave3Still',DisplayName: 'Wave 3 Still',Type: 'Boolean',Default: false},
                {Name: 'BottomWave4Still',DisplayName: 'Wave 4 Still',Type: 'Boolean',Default: false},
                {Name: 'BottomWave1Color',DisplayName: 'Wave 1 Clr',Type: 'Color Picker',Default: { "alpha": 0.66, "hex": "#FF6633", "hexa": "#FF6633A8", "hsla": { "h": 15, "s": 1, "l": 0.6, "a": 0.66 }, "hsva": { "h": 15, "s": 0.8, "v": 1, "a": 0.66 }, "hue": 15, "rgba": { "r": 255, "g": 102, "b": 51, "a": 0.66 } }},
                {Name: 'BottomWave2Color',DisplayName: 'Wave 2 Clr',Type: 'Color Picker',Default: { "alpha": 0.4, "hex": "#52C2E7", "hexa": "#52C2E767", "hsla": { "h": 194.8993288590604, "s": 0.7563451776649746, "l": 0.6137254901960785, "a": 0.4 }, "hsva": { "h": 194.8993288590604, "s": 0.6450216450216449, "v": 0.9058823529411765, "a": 0.4 }, "hue": 194.8993288590604, "rgba": { "r": 82, "g": 194, "b": 231, "a": 0.4 } }},
                {Name: 'BottomWave3Color',DisplayName: 'Wave 3 Clr',Type: 'Color Picker',Default: { "alpha": 0.47, "hex": "#4F7AE6", "hexa": "#4F7AE677", "hsla": { "h": 222.91390728476821, "s": 0.7512437810945274, "l": 0.6058823529411765, "a": 0.47 }, "hsva": { "h": 222.91390728476821, "s": 0.6565217391304348, "v": 0.9019607843137255, "a": 0.47 }, "hue": 222.91390728476821, "rgba": { "r": 79, "g": 122, "b": 230, "a": 0.47 } }},
                {Name: 'BottomWave4Color',DisplayName: 'Wave 4 Clr',Type: 'Color Picker',Default: { "alpha": 0.6, "hex": "#F2F4C0", "hexa": "#F2F4C098", "hsla": { "h": 62.30769230769233, "s": 0.7027027027027029, "l": 0.8549019607843138, "a": 0.6 }, "hsva": { "h": 62.30769230769233, "s": 0.2131147540983607, "v": 0.9568627450980393, "a": 0.6 }, "hue": 62.30769230769233, "rgba": { "r": 242, "g": 244, "b": 192, "a": 0.6 } }},
                {Name: 'BottomWave1Speed',DisplayName: 'Wave 1 Spd',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s'],Default: '10s'},
                {Name: 'BottomWave2Speed',DisplayName: 'Wave 2 Spd',Default: '8s',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s']},
                {Name: 'BottomWave3Speed',DisplayName: 'Wave 3 Spd',Default: '6s',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s']},
                {Name: 'BottomWave4Speed',DisplayName: 'Wave 4 Spd',Default: '4s',Type: 'Option Set',TextOnly: true,Options: ['2s','4s','6s','8s','10s','12s','16s','20s','24s']},
            ],
            Refreshing: false,
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        TabOptions(){
            let full = ['Top','Bottom']
            return full.filter(item => {
                if(!this.EditedTab.HasTopWaves){
                    return item !== 'Top'
                }
                else{
                    return item
                }
            }).filter(item => {
                if(!this.EditedTab.HasBottomWaves){
                    return item !== 'Bottom'
                }
                else{
                    return item
                }
            })
        },
        TopWavesProps(){
            return this.DefaultProps.filter(prp => {
                return prp.Name.includes('Top')
            })
        },
        BottomWavesProps(){
            return this.DefaultProps.filter(prp => {
                return prp.Name.includes('Bottom')
            })
        },
        
        ColorProps(){
            let arr = this.EditingWave === 'Top' ?
            this.TopWavesProps : this.BottomWavesProps
            return [{Header: 'Colors',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave1Color') 
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'},{Header: 'Colors',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave2Color')
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'},{Header: 'Colors',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave3Color')
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'},{Header: 'Colors',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave4Color') 
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'}]
        },
        TimingProps(){
            let arr = this.EditingWave === 'Top' ?
            this.TopWavesProps : this.BottomWavesProps            
            return [{Header: 'Timing',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Speed')
            }),Class: 'flex xl12 lg12 md12 sm12 xs12'}]
        },
        PathProps(){
            let arr = this.EditingWave === 'Top' ?
            this.TopWavesProps : this.BottomWavesProps
            return [{Header: 'Paths',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave1Path')
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'},{Header: 'Paths',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave2Path')
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'},{Header: 'Paths',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave3Path')
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'},{Header: 'Paths',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Wave4Path')
            }),Class: 'flex xl3 lg3 md3 sm3 xs3'}]
        },
        PosProps(){
            let arr = this.EditingWave === 'Top' ?
            this.TopWavesProps : this.BottomWavesProps            
            return [{Header: 'Positions',Type: 'Fields',Fields: arr.filter(item => {
                return item.Name.includes('Pos')
            }),Class: 'flex xl12 lg12 md12 sm12 xs12'}]
        },
        SettingsProps(){
            let arr = this.EditingWave === 'Top' ?
            this.TopWavesProps : this.BottomWavesProps
            return [{Header: 'Settings',Type: 'Fields',Fields: arr.filter(item => {
                return item.Type === 'Boolean'
            }),Class: 'flex xl6 lg6 md6 sm6 xs6'},{Header: 'Settings',Type: 'Fields',Fields: arr.filter(item => {
                return item.Type === 'Number Field' && !item.Slider || item.DisplayName === 'Count' || item.Type === 'Option Set' && !item.Name.includes('Speed')
            }),Class: 'flex xl6 lg6 md6 sm6 xs6'}]
        },
        QuickEditSections(){
            // return this.EditingWave === 'Top' ? 
            // [{Header: 'Settings',Type: 'Fields',Fields: this.TopWavesProps,Class: 'flex xl12 lg12 md12 sm12 xs12'}] : 
            // [{Header: 'Settings',Type: 'Fields',Fields: this.BottomWavesProps,Class: 'flex xl12 lg12 md12 sm12 xs12'}]

        },
        
    },
    created(){

    },
    methods:{
        Refresh(){
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 200);
        },
        CheckWaves(tab,Type){
            tab.ShowComponents = false
            let finalprops = this.DefaultProps.filter(prp => {
                return prp.Name.includes(Type)
            })
            //console.log(finalprops)
            finalprops.map(prp => {
                if(typeof tab[prp.Name] === 'undefined'){
                    tab[prp.Name] = prp.Default
                }
            })
            this.EditedTab.TopPathsHTML = ``
            this.EditedTab.BottomPathsHTML = ``
            setTimeout(() => {
                tab.ShowComponents = true
            }, 50);
        },
        ImageHeight(tab,prop,direction,int){
            if(tab[prop] === 'undefined' || isNaN(tab[prop])){
                tab[prop] = Number(50)
            }
            else{
                if(direction === 'down'){
                    tab[prop] = Number(tab[prop])-Number(int)
                }
                else{
                    tab[prop] = Number(tab[prop])+Number(int)
                }
            }
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 50);
            //console.log(tab)
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
