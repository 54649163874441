
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
            <v-card :class="tab.BoxTransparency+' mx-3'" v-if="tab.ListItems" :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
              <v-list :dark="tab.Dark" class="transparent" dense v-if="!tab.WrapInExpansionPanel">
                <span class="mediumoverline">{{tab.Title}} </span>   
                <v-switch dense v-if="$route.name === 'PageEditor' || $route.name === 'FeaturedMemberEditor'" label="PreviewMode" v-model="PreviewMode"/>                    
                <v-list-item class="detailslistoutline" v-for="listitem in tab.ListItems" :key="listitem.itemObjKey">
                    <v-list-item-content>
                    {{listitem.DisplayName}}
                    </v-list-item-content>
                    <v-list-item-content>
                        <FieldViewerComponent v-if="PreviewMode" :field="listitem" :Record="listitem.Record"
                        />
                         <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" v-if="!PreviewMode" :Record="listitem.Record" :field="listitem" @onPhotoFileselected="onPhotoFileselected"
                                    :CurrentEntity="{}" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
                                    :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                        />
                    </v-list-item-content>
                </v-list-item>  
            </v-list>
                        <v-expansion-panels  :dark="tab.Dark" class="mb-6" v-if="tab.WrapInExpansionPanel">
                            <v-expansion-panel
                                v-for="panel in tab.Panels"
                                :key="panel.itemObjKey"
                            >
                                <v-expansion-panel-header expand-icon="mdi-menu-down">
                                    <v-card-subtitle style="font-size:  1.15em;">
                                <v-icon color="primary">mdi-plus</v-icon> {{panel.Title}}
                                    </v-card-subtitle>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list dense>                    
                                        <v-list-item class="detailslistoutline" v-for="listitem in panel.ListItems" :key="listitem.itemObjKey">
                                            <v-list-item-content>
                                            {{listitem.DisplayName}}
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                <FieldViewerComponent :field="listitem" :Record="listitem.Record"
                                                />
                                            </v-list-item-content>
                                        </v-list-item>  
                                    </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
                <v-card-text v-if="tab.HasImage">
                                 <v-layout :class="tab.Justify" row>
                                 <ImageRenderComp
                                    :tab="tab" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                                    :IMGProp="'IMG'"/>
                                 </v-layout>
                             </v-card-text>
        </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import ImageRenderComp from '@/components/WebPages/RenderComponents/ImageRenderComp';
export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex'],
    components: {
        FieldViewerComponent,
        FieldValueEditerComponent,
        ImageRenderComp
        },
    data() {
    return {     
        ImageRecord: '',
        ImageField: '',
        PreviewMode: true, 
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){

    },
    
    methods: {
        onPhotoFileselected(field,file,files,Record){ 
            this.$emit('onPhotoFileselected',field,file,files,Record,this.tab)
            
        },
        UploadFileSelect(file,field,Record){
            Record[field.Name] = URL.createObjectURL(file)  
            let fileList = this.UploadFilesList.concat([file])
            this.$store.commit('setUploadFilesList',fileList)
            let filestorageref = ''
            if(this.$route.name === 'PageEditor'){
                filestorageref = 'Pages/'+this.$route.params.id+'/'+new Date()+'/'
            }            
            //let dbrecordref = this.TableCollectionRef
            this.$store.commit('setUploadFileStorageRef',filestorageref)
            this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
            console.log(file,field,Record)
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    