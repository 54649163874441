<template>
  <span>
    <v-list-item class="overline">
        {{navitem.Title}} <v-icon  v-if="$route.name === 'DirectoryBuilder'" @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon>
        </v-list-item>
        <v-list class="transparent" dense style="font-size:12px;">
            <v-list-item>
                <v-rating
            value="4"
            readonly
            background-color="orange lighten-3"
            color="orange"
            x-small
            ></v-rating> & up
            </v-list-item>
            <v-list-item>
                <v-rating
            value="3"
            readonly
            background-color="orange lighten-3"
            color="orange"
            x-small
            ></v-rating> & up
            </v-list-item>
            <v-list-item>
                <v-rating
            value="2"
            readonly
            background-color="orange lighten-3"
            color="orange"
            x-small
            ></v-rating> & up
            </v-list-item>
            <v-list-item>
                <v-rating
            value="1"
            readonly
            background-color="orange lighten-3"
            color="orange"
            x-small
            ></v-rating> & up
            </v-list-item>
        </v-list>
    </span>
</template>

<script>
export default {
    props: ['navitem','navitemindex'],
    components: {

    },
    data(){
        return {

        }
    },
    created(){

    },
    computed:{

    },
    methods: {
        EditNavItem(navitem,navitemindex){
            this.$emit('EditNavItem',navitem,navitemindex)
        },
        ActivateFilter(opt){
            this.$emit('ActivateFilter',opt)
        }
    }
}
</script>

<style>

</style>