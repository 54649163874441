<template>
  <v-row       
        :class="'mb-6 '+StatsCounterTab.Justify"
        no-gutters
        :style="'margin-top:'+StatsCounterTab.MarginTop+'px;margin-bottom:'+StatsCounterTab.MarginBottom+'px;margin-left:'+StatsCounterTab.MarginLeft+'px;margin-right:'+StatsCounterTab.MarginRight+'px;'"            
        >  
            <v-col @click="CanInteract && !elmnt.ExtendedFilterField ? InteractMethod(elmnt) : ''"
            v-for="(elmnt,index) in StatsCounterTab.Elements"
            :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
            cols="12"    
            :class="elmnt.Justify+' '+ColMY(elmnt)"  
            :style="CanInteract ? 'cursor: pointer;padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment : 
            'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
        >   
            <CounterTab
            v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
            :tab="elmnt" @OpenDialog="OpenDialog" @InteractMethod="InteractMethod"
            />
            
            </v-col>
    </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
export default {
    props: ['System','SystemEntities','Counters','CanInteract','DashFilterField','DashFilterOption'],
    components: {CounterTab},
    data() {
        return {
            DefaultCounterElmnt: {	
                Outlined: false,								
                CounterIcon: 'mdi-excavator',									
                CounterInteger: 0,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Counter Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                Tile: true,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Title',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:2,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,
                // HideActions: true,								
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: 'rgb(198,126,183)',
                    hexa: 'rgb(198,126,183)'
            },									
                IsDark: true,						
            },
            DefaultCounterTab: {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Counter',									
            Elements: [
                {}
            ],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-counter',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:11,									
            FlexLarge: 11,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },
            colors: [									
                    'red',									
                    'pink',									
                    'purple',									
                    'deep-purple',									
                    'indigo',									
                    'blue',									
                    'light-blue',									
                    'cyan',									
                    'teal',									
                ],
        }
    },
    computed:{
        StatsCounterTab(){
            let tab = this.DefaultCounterTab
            let counters = JSON.parse(JSON.stringify(this.Counters))
            let totalelmtns = counters.length
            tab.Elements = counters.map(elmnt => {
                // let ranint = Math.floor(Math.random() * Math.floor(this.colors.length))
                // alert(ranint+' '+this.colors[ranint])	
                let baseelmnt = JSON.parse(JSON.stringify(this.DefaultCounterElmnt))
                let colorprops = ['BGColor','HeaderColor','FontColor']
                let requiredprops = ['BGColor','HeaderColor','FontColor','CounterIcon','CounterInteger','Title','HasCounterData','Data','Charts','HasAnalysisDrillDown',
                'ThemeColor','PrimaryEntity','AnalysisDrillDownMethod','LookupArrays','NumberFields','Outlined','Tile','IsDark','Elevation','IsCurrency','ElementID','SummaryID','SubTitle','FilterID']
                requiredprops.map(prop => {
                    if(typeof elmnt[prop] !== 'undefined'){
                        baseelmnt[prop] = elmnt[prop]
                    }
                })
                if(baseelmnt.CounterInteger === 0){                    
                    baseelmnt.Elevation = 1
                    colorprops.map(prop => {
                        //console.log(baseelmnt.Title,prop,baseelmnt[prop].hex,'has zero',baseelmnt[prop] && baseelmnt[prop].hex && baseelmnt[prop].hex.includes('#') && baseelmnt[prop].hex.length === 7)
                        if(baseelmnt[prop] && baseelmnt[prop].hex && baseelmnt[prop].hex.includes('#') && baseelmnt[prop].hex.length === 7){
                            baseelmnt[prop].hex = baseelmnt[prop].hex+'99'
                            //console.log(baseelmnt[prop].hex)
                        }
                    })
                }
                if(baseelmnt.CounterInteger > 0){
                    baseelmnt.Elevation = 4
                    colorprops.map(prop => {
                        if(baseelmnt[prop] && baseelmnt[prop].hex && baseelmnt[prop].hex.includes('#') && baseelmnt[prop].hex.length === 9){
                            if(baseelmnt.CounterInteger > 0){
                                baseelmnt[prop].hex = baseelmnt[prop].hex.slice(0,7)
                            }
                        }
                    })
                }
                
                if(elmnt.ExtendedFilterField){
                    //console.log('elmnt.ExtendedFilterField',elmnt.ExtendedFilterField)
                    if(elmnt.RelatedTableFilter){
                        baseelmnt.RollupItems = elmnt.RollupItems
                    }
                    baseelmnt.ExtendedFilterField = elmnt.ExtendedFilterField
                    baseelmnt.ExtendedFilters = elmnt.ExtendedFilterField.Options.map(opt => {
                        let optobj = Object.assign({},opt)
                        optobj.Count = elmnt.Data.filter(record => {
                            if(elmnt.RelatedTableFilter){
                                //console.log(record[elmnt.DataField+'id'],elmnt.DataField+'id')
                                let lumatch = elmnt.RollupItems.find(obj =>obj.id === record[elmnt.DataField+'id'])
                                return lumatch && lumatch[elmnt.ExtendedFilterField.Name] && lumatch[elmnt.ExtendedFilterField.Name].ID === opt.ID
                            }
                            else{
                                return record[elmnt.ExtendedFilterField.Name] && record[elmnt.ExtendedFilterField.Name].ID === opt.ID
                            }
                        }).length
                        return optobj
                    })                   
                }
                if(elmnt.ExtendedChildFilterFields){
                    //console.log('elmnt.ExtendedChildFilterFields',elmnt.ExtendedChildFilterFields)
                    if(elmnt.RelatedTableFilter){
                        baseelmnt.RollupItems = elmnt.RollupItems
                    }
                    baseelmnt.ExtendedChildFilterFields = elmnt.ExtendedChildFilterFields
                        baseelmnt.ExtendedFilters = elmnt.ExtendedChildFilterFields.map(opt => {
                            let optobj = Object.assign({},opt)
                            let ent = this.SystemEntities.find(obj => obj.id === optobj.ChildEntityid)
                            optobj.Name = ent.DisplayName
                            let array = this.$store.state[optobj.ChildEntityid.split('_').join('')+'Array']
                            //console.log(optobj.ChildEntityid+'Array',array)
                            let childdata = array.filter(record => {
                            return record[opt.id] && elmnt.Data.find(data => data.id === record[opt.id].id)
                            })
                            if(opt.PrefilterField && opt.Prefilter){
                                optobj.FilterbyRelatedChildTable = true
                                childdata = childdata.filter(data => {
                                    return data[opt.PrefilterField.Name] && data[opt.PrefilterField.Name].ID === opt.Prefilter.ID
                                })
                                optobj.Data = elmnt.Data.filter(item => {
                                    //console.log(opt.id)
                                    return childdata.find(obj => obj[opt.id] && obj[opt.id].id === item.id)
                                })
                            }
                //             PrefilterField">
                //   <v-select v-model="child.Prefilter
                           
                            optobj.Count = childdata.length
                            return optobj
                        })
                    
                }
                //console.log(baseelmnt)
                //console.log('bcaseelmnt',baseelmnt.Title,baseelmnt.HeaderColor.hex,baseelmnt.CounterInteger > 0)
                return baseelmnt
            }).filter(elmnt => {
                //console.log('this.DashFilterField',this.DashFilterField)
                if(this.DashFilterField){
                    return elmnt.CounterInteger > 0
                }
                else{
                    return elmnt
                }
            }) 
            .map(elmnt => {
                if(totalelmtns < 3){
                    elmnt.FlexXLRG = 6
                    elmnt.FlexLarge = 6
                }
                else if(totalelmtns < 4){
                    elmnt.FlexXLRG = 4
                    elmnt.FlexLarge = 4
                }
                else if(totalelmtns < 5){
                    elmnt.FlexXLRG = 3
                    elmnt.FlexLarge = 3
                }
                return elmnt
            })
            return tab
        },
    },
    created(){

    },
    methods:{
        InteractMethod(elmnt,ExtendedFilterField,filter){
            //console.log(elmnt,ExtendedFilterField,filter)
            if(!ExtendedFilterField && filter && filter.ChildEntityid && elmnt.ExtendedChildFilterFields){
                //possibly this means it's a drilldown on a child entity "progress field" but how to apply filter remains a mystery
            }
            this.$emit('InteractMethod',elmnt,ExtendedFilterField,filter)
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
    }
}
</script>

<style>

</style>
