<template>
  <div>
      <v-navigation-drawer :app="!MiniView" hide-overlay
                    mini-variant v-model="SiteDialog"
                    :permanent="!MiniView || MiniView && SiteDialog" mini-variant-width="100"
                    :style="!$route.meta.DataSingleBuilder? 'z-index:8;height: 100%;top:65px;position: fixed;'
                    : 'z-index:10;height: 100%;top:60px;position: fixed;'"
                   >	
                    <v-list-item class="px-2">
                    <v-list-item-avatar>
                    <v-img :src="ActiveGroup && ActiveGroup.logo ? ActiveGroup.logo : require('@/assets/logo.png')"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
                <v-divider></v-divider>
                <v-list dense>
                    
                    <v-list-item
                    v-for="main in FilteredTopItems"
                    :key="main.itemObjKey"
                    link
                    >
                     <v-btn @click="ActivateSelectedMain(main)">
                        <v-icon>{{main.icon}}</v-icon>
                            <v-badge color="warning" v-if="main.notifications" left>
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-btn>
                    </v-list-item>
                    <v-list-item
                    v-for="main in SystemDashboardElementsQuickSelect"
                    :key="main.itemObjKey"
                    link
                    >
                     <v-btn @click="ActivateSelectedMain(main)">
                        <v-icon>{{main.icon}}</v-icon>
                            <v-badge color="warning" v-if="main.notifications" left>
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-btn>
                    </v-list-item>
                    <v-list-item
                    v-for="main in DataTabsElementQuickselect"
                    :key="main.itemObjKey"
                    link
                    >
                     <v-btn @click="ActivateSelectedMain(main)">
                        <v-icon>{{main.icon}}</v-icon>
                            <v-badge color="warning" v-if="main.notifications" left>
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-btn>
                    </v-list-item>
                   <v-list-item
                    v-for="main in Computedsitenavitems"
                    :key="main.itemObjKey"
                    link
                    >
                     <v-btn @click="ActivateSelectedMain(main)">
                        <v-icon>{{main.icon}}</v-icon>
                            <v-badge color="warning" v-if="main.notifications" left>
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-btn>
                    </v-list-item>
                    
                    
                </v-list>
                </v-navigation-drawer>
             <v-navigation-drawer :right="MainDialRight"  :app="MiniView || SelectedMain.title === 'Plugins'" :class="SelectedMain.title === 'Plugins' ? 'background elevation-2' : 'elevation-2'" 
             :style="MainDialStyling" v-if="SelectedMainDialog" v-model="SelectedMainDialog">
                <v-list v-if="SelectedMain">
                    <v-card flat v-if="SelectedMain.title === 'Data Navlists'">
                         <v-card-title class="settingsfont">
                                {{SelectedMain.title}}<v-spacer></v-spacer><v-icon @click="SelectedMain = ''">mdi-chevron-left</v-icon>
                            </v-card-title>
                         <v-card-text class="settingsfont">
                                 <v-list class="transparent">
                                    <!-- <v-list-item style="padding-left: 0px;">
                                        <v-chip @click="NavigatetoWebFormBuilder()" >Add Web Form <v-icon>mdi-plus</v-icon></v-chip>
                                    </v-list-item> -->
                                    <v-list-item 
                                    dense v-for="navlist in DataNavlists"
                                    :key="navlist.itemObjKey" :to="navlist.Path"
                                    :style="'border: 1px solid #89bcd9 !important;border-radius: 6px;cursor: all-scroll !important;color: links !important;'"
                                    >
                                    {{navlist.title}}
                                    </v-list-item>
                                 </v-list>
                         </v-card-text>
                    </v-card>
                    <v-card flat v-else-if="SelectedMain.title === 'Plugins'">
                         <v-card-title class="settingsfont">
                                {{SelectedMain.title}}<v-spacer></v-spacer><v-icon @click="SelectedMain = ''">mdi-chevron-left</v-icon>
                            </v-card-title>
                            <v-card-text class="settingsfont">
                                <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Database Builder','DBB'])" :tab="systemguideelmnt"
                                :ListPreviewOnly="true"
                                 />
                            </v-card-text>
                    </v-card>
                        <v-card flat v-else-if="!SelectedMain.SecondProp">
                            <v-card-title class="settingsfont">
                                {{SelectedMain.title}}<v-spacer></v-spacer><v-icon @click="SelectedMain = ''">mdi-chevron-left</v-icon>
                            </v-card-title>                            
                            <v-card-text class="settingsfont" v-if="SelectedMain.IMG">
                                <v-img :src="SelectedMain.IMG"/>
                            </v-card-text>
                            <v-card-text class="settingsfont" v-html="SelectedMain.Writeup">
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                                <v-btn @click="ActivateSelectedMain(SelectedMain,true)">
                                Go
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card flat  v-else-if="SelectedMain.SecondProp">
                            <v-card-title class="settingsfont">
                                {{SelectedMain.title}}<v-spacer></v-spacer><v-icon @click="SelectedMain = ''">mdi-chevron-left</v-icon>
                            </v-card-title>                            
                            <v-card-text class="settingsfont" v-if="SelectedMain.SecondProp.IMG">
                                <v-img :src="SelectedMain.SecondProp.IMG"/>
                            </v-card-text>
                            <v-card-text class="settingsfont" v-html="SelectedMain.SecondProp.Writeup">
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer>
                                </v-spacer>
                               <v-btn @click="ActivateSelectedMain(SelectedMain,true)">
                                Go
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-list>
                </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import AppStylingComponent from '@/components/SuiteBuilder/AppStylingComponent';	
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
export default {
    props: ['System','SystemEntities','SitePages','SocialEntities','SystemPhotoLibraries','WebFormEntities','GroupEntities','GraphicalElements','GradientElements'],
    components: {AppStylingComponent,RAPluginCarousel},
    data() {
        return {
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            PredialQuickSelectItems: [
                {title: 'General', Writeup: `<p>General Database settings for your App</p>`,IMG: require('@/assets/logo.png'),},
                {title: 'entities', Writeup: `<p>Create Data Tables, choosing from our predefinined templates.</p>`,IMG: require('@/assets/logo.png')},
                {title: 'Mailing', Writeup: `<p>Setup your Mailing configuration, defining the email address that communication is sent from on the App.</p>`,IMG: require('@/assets/logo.png')},
            ],
            TopItems: [
                { title: 'Main', icon: 'mdi-subdirectory-arrow-left',Menu: false,Method: 'SelectSuiteBuilder',
                
                },
            {
            icon: 'mdi-road-variant',
            title: 'Roadmap',
            Route: 'Roadmap',
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>The Roadmap component let's you keep a board of outstanding configuration plans on your App</p>`
            },],
            SystemDashboardTabs: [
        {
            DisplayName: 'Dashboard',
            Icon: 'mdi-view-dashboard',
            SystemDashboard: true,
            
            Elements: [
             
          {
            Icon: 'mdi-alert',
            DisplayName: 'Alerts',
            Name: 'Alerts',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Alerts', 
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>The Alerts Section serves as a configuration required for both published and unpublished changes.</p>`,          
            Elements: [],
            Children: [
              {
            Icon: 'mdi-database',
            DisplayName: 'Database Module',
            Name: 'Database Module',
            SystemDashboard: true,
            DashboardType: 'Database Module'
          },
          {
            Icon: 'mdi-share-variant',
            DisplayName: 'Social Module',
            Name: 'Social Module',
            SystemDashboard: true,
            DashboardType: 'Social Module'
          },
          {
            Icon: 'mdi-search-web',
            DisplayName: 'Website Module',
            Name: 'Website Module',
            SystemDashboard: true,
            DashboardType: 'Website Module'
          },
           
            ]
          },
          {
            Icon: 'mdi-chart-box',
            DisplayName: 'Statistics',
            Name: 'Statistics',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Statistics',
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>The Dashboard Section gives statistics on page views and so forth.</p>`,
             Elements: []
          },
            ]
        }
        
      ],
            sitenavitems: [
           
             { title: 'Security Overview', icon: 'mdi-server-security',Menu: false,Route: 'SecurityOverview',
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>Configure Security Roles and access to data tables created on your App.</p>`,},
             { title: 'Business Units', icon: 'mdi-security-network',Route: 'BusinessUnits',
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>Configure the Business Units, their hierarchy, and Warehouses in them (if Store required).</p>`,},
             { title: 'Users', icon: 'mdi-tag-faces',Route: 'Users',
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>View the Users and assign Social Moderation Permissions, Eagle Viewers (view permission on everything), User Table Filters etc.</p>`,},
             { title: 'Plugins', icon: 'mdi-apps' ,Menu: false},       
             { title: 'Goals', icon: 'mdi-bullseye-arrow',Route: 'Goals' ,ORPlugins: ['Goals'],
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>Set up Goals for your team that can be measured from the respective User and/or User's manager Dashboard section.</p>`,},
             { title: 'Chatbot', icon: 'mdi-robot',Route: 'ChatbotBuilder' ,ORPlugins: ['Chatbot'],
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>Capitalize on FAQ and configure chatbot for responses. Further expand the chatbot with "virtual assist" responses such as "How many Orders were placed last week".</p>`,},
            { title: 'PDF Templates', icon: 'mdi-file-pdf-box',Route: 'PDFExportTemplates' ,ORPlugins: ['PDF_Builder'],
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>Configure PDF Templates to export records to PDF Format, using our PDF Builder interface.</p>`,},
             {title: 'Data Navlists',icon: 'mdi-menu',IMG: require('@/assets/logo.png'),PreDialog: true}
            ],
            SocialIcons: [
                {Name: 'Articles',Icon: 'mdi-email-newsletter'},{Name: 'Blogs',Icon: 'mdi-typewriter'},{Name: 'Classifieds',Icon: 'mdi-newspaper'},
                {Name: 'Events',Icon: 'mdi-calendar-outline'},{Name: 'Forums',Icon: 'mdi-forum-outline'},{Name: 'Meetups',Icon: 'mdi-human-greeting-proximity'},
                {Name: 'Polls',Icon: 'mdi-vote'},{Name: 'General',Icon: 'mdi-cogs'},
            ],
            SelectedMain: '',
            SelectedMainDialog: false,
        }
    },	
    computed:{
        DataNavlists(){
            let storeproductentities = this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Inventory Article'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let classifiedentities = this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let socialclassifiedobj = 
            {
                title: 'Classifieds',
                icon: 'mdi-newspaper-variant-outline',
                ItemPass: this.PluginDataBase.Classifieds,
                IsRoute: true,
                Path: '/DirectoryBuilder/Classifieds'
            }
            let groupcategoriesobj = {
                title: 'Group Categories',
                icon: 'mdi-account-group',
                ItemPass: this.PluginDataBase.Site_Groups,
                IsRoute: true,
                Path: '/DirectoryBuilder/Group_Categories'
            }
            let featuredmembersobj = {
                title: 'Group Featured Members',
                icon: 'mdi-account-star-outline',
                ItemPass: true,
                IsRoute: true,
                Path: '/DirectoryBuilder/Group_Featured_Members'
            }
            let businessmembersobj = {
                title: 'Business Members',
                icon: 'mdi-smart-card',
                ItemPass: true,
                IsRoute: true,
                Path: '/DirectoryBuilder/Business_Members'
            }
            let sitefeaturedmembersobj = {
                title: 'Featured Members',
                icon: 'mdi-account-star',
                ItemPass: true,
                IsRoute: true,
                Path: '/DirectoryBuilder/Featured_Members'
            }
            let datanavlists = storeproductentities.concat(classifiedentities)
            datanavlists.push(socialclassifiedobj)
            datanavlists.push(groupcategoriesobj)
            datanavlists.push(featuredmembersobj)
            datanavlists.push(sitefeaturedmembersobj)
            datanavlists.push(businessmembersobj)
            return datanavlists
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        GroupidRoutes(){
            return this.$store.state.GroupidRoutes
        },
        GroupslugRoutes(){
            return this.$store.state.GroupslugRoutes
        },
        GroupID(){
            if(this.GroupidRoutes.includes(this.$route.name) && this.ActiveGroup.id === this.$route.params.id){
                return this.$route.params.id
            }
            else if(this.$route.query && this.$route.query.GroupID && this.ActiveGroup.id === this.$route.query.GroupID){
                return this.$route.query.GroupID
            }
            else if(this.GroupslugRoutes.includes(this.$route.name) && this.ActiveGroup.id === this.$route.params.slug){
                return this.$route.params.slug
            }
        },
        IsGroupView(){
        return this.$store.state.IsGroupView
    },
        SiteDialog(){
            return this.$store.state.SiteDialog
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        SystemConfigTabs(){
        //console.log('SystemConfigTabs',this.$store.state.SystemConfigTabs)
        return this.$store.state.SystemConfigTabs
        },
        CurrentEntityTabs(){
        //console.log('CurrentEntityTabs',this.$store.state.CurrentDataTabs)
        return this.$store.state.CurrentDataTabs
        },
        SystemDashboardElements(){
      return this.SystemDashboardTabs.map(tab => {          
          return tab.Elements.map((elmnt) => {
            let parenttabobj = Object.assign({},tab)            
            let elmntobj = Object.assign({},elmnt)
            elmntobj.FlexXLRG = 12								
            elmntobj.FlexLarge = 12									
            elmntobj.FlexMedium = 12									
            elmntobj.FlexSmall = 12									
            elmntobj.FlexXSmall = 12
            parenttabobj.Elements = [elmntobj]
            elmntobj.ParentTab = parenttabobj
            return elmntobj
          })
        }).flat()
    },
    SystemDashboardElementsQuickSelect(){
      return this.SystemDashboardElements.map(elmnt => {
         let elmntobj = {
              title: elmnt.DisplayName,
              icon: elmnt.Icon,
              ItemPass: true,
              Method: 'AssignSocialItemInteractMethod',
              Prop: 'ActivateSingleRecordNavMenuItem',
              SecondProp: elmnt
            }
            return elmntobj
      }).filter(item => {
          if(this.$route.name === 'SystemConfig'){
              return item
          }
          else{
              return false
          }
      })
    },
        DataTabsElementQuickselect(){
        return this.DataTabsElements.map(elmnt => {
            let elmntobj = {
                title: elmnt.DisplayName,
                icon: elmnt.Icon,
                ItemPass: true,
                Method: 'AssignSocialItemInteractMethod',
                Prop: 'ActivateSelectedElement',
                SecondProp: elmnt
                }
                //SecondProp has to be parent tab as well, in order to reutrn parent tab and childtab and boom
                return elmntobj
        }).filter(item => {
          if(this.$route.name === 'SystemConfig'){
              return item
          }
          else{
              return false
          }
      })
        },
        DataTabsElements(){
            let arr = []
        //console.log('SystemConfigTabs',this.SystemConfigTabs)
        if(this.CurrentEntityTabs){
            arr = this.CurrentEntityTabs
            .filter(tab => {
                return this.NetworkBuilderView ? tab.Name === 'Social Components' :  tab.Name !== 'Social Components'
            })
            .filter(tab => {
                if(tab.Name === 'Data Tables'){
                    //HEH?
                    return this.PluginDataBase.Data_Tables && this.PluginDataBase.Data_Tables.Active
                }
                else{
                    return tab
                }
            })
            if(this.DBBuilderView){
              arr = arr.map(tab => {          
            return tab.Elements
            .map((elmnt) => {
                let parenttabobj = Object.assign({},tab)            
                let elmntobj = Object.assign({},elmnt)
                if(this.NetworkBuilderView){
                    let iconitem = this.SocialIcons.find(obj => obj.Name === elmnt.Name)
                    if(iconitem){
                      elmntobj.Icon = iconitem.Icon   
                    }                   
                }
                elmntobj.FlexXLRG = 12								
                elmntobj.FlexLarge = 12									
                elmntobj.FlexMedium = 12									
                elmntobj.FlexSmall = 12									
                elmntobj.FlexXSmall = 12
                parenttabobj.Elements = [elmntobj]
                elmntobj.ParentTab = parenttabobj
                return elmntobj
            })
            }).flat()  
            }
            else{
                let socialgeneral = arr.map(tab => {
                    let parenttabobj = Object.assign({},tab)
                    parenttabobj.Elements = parenttabobj.Elements.slice(0,1)
                    parenttabobj.ParentTab = parenttabobj
                    parenttabobj.Icon = 'mdi-cogs'
                    return parenttabobj
                })
                let socialscope = arr.map(tab => {
                    let parenttabobj = Object.assign({},tab)
                    parenttabobj.Elements = parenttabobj.Elements.slice(1,parenttabobj.Elements.length)                    
                    parenttabobj.ParentTab = parenttabobj
                    parenttabobj.Icon = 'mdi-toggle-switch'
                    return parenttabobj
                })
                arr = socialgeneral.concat(socialscope)
            }
            
        }
            return arr.map(item => {
                let itemobj = Object.assign({},item)
                
                let responseitem = this.PredialQuickSelectItems.find(obj => obj.title === itemobj.Name)
                //console.log(itemobj,responseitem,this.PredialQuickSelectItems)
                if(responseitem){
                    itemobj.PreDialog = true
                    itemobj.IMG = responseitem.IMG
                    itemobj.Writeup = responseitem.Writeup
                }
                return itemobj
            })
        },
        FilteredTopItems(){
            return this.TopItems.filter(item => {
                if(this.$route.name === 'SystemConfig' || this.IsGroupView && this.$route.name === 'GroupEdit'){
                    return item.title !== 'Main'
                }
                else{
                    return item
                }
            }).map(item => {
                if(this.IsGroupView && item.title === 'Main'){
                    item.Route = 'Group/Edit/'+this.GroupID
                }
                return item
            })
        },
        Computedsitenavitems(){
            return this.sitenavitems.filter(item => {
                if(this.IsGroupView){
                    //no roadmap maybe wishlist of components? I think users should change to members mind you
                    return false
                }
                else{
                    return item
                }
            }).filter(item => {
                if(item.ORPlugins){
                    let pass = false
                    item.ORPlugins.map(plug => {
                        if(this.PluginDataBase[plug] && this.PluginDataBase[plug].Active){
                            pass = true
                        }
                    })
                    return pass
                }
                else if(item.ANDPlugins){
                    let pass = true
                    return pass
                }
                else{
                    return item
                }
            })
            
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        ComputedBuilders(){
            return this.Builders.filter(builder => {
            return this.PluginDataBase[builder.Name.split(' ').join('_')] && this.PluginDataBase[builder.Name.split(' ').join('_')].Active
            })
        },
        WebsiteisActive(){
            return this.$store.state.WebsiteisActive
        },
        SocialNetworkisActive(){
            return this.$store.state.SocialNetworkisActive
        },
        DatabaseisActive(){
            return this.$store.state.DatabaseisActive
        },
        DocumentationisActive(){
            return this.$store.state.DocumentationisActive
        },
        MainDialRight(){
            return this.SelectedMain && this.SelectedMain.title === 'Plugins'
        },
        MainDialStyling(){
            if(this.SelectedMain && this.SelectedMain.title === 'Plugins'){
                if(this.$vuetify.breakpoint.xs){
                    return 'z-index: 6;width:300px;top:125px;padding-bottom:50px;'
                }
                else if(this.$vuetify.breakpoint.sm){
                    return 'z-index: 6;width:600px;top:125px;padding-bottom:50px;'
                }
                else{
                    return 'z-index: 6;width:65%;top:55px;padding-bottom:50px;'
                }
            }
            else{
                return 'position: fixed;left: 88px;top:125px;z-index:6;height:60%;'
            }
        },
        BuilderView(){
        return this.$store.state.BuilderView
        },
        WebsiteBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        DBBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Database Builder'
        },
        NetworkBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      SelectedMain (value){
            if(value){
                this.SelectedMainDialog = true
            }
            else{
                this.SelectedMainDialog = false
            }
        },
    },
    created(){

    },
    methods:{
        SelectSuiteBuilder(){
            let path = '/Getting-Started'
            if(this.$route.path !== 'Getting-Started'){
                this.$router.push(path)
            }
            else{
                this.$store.commit('setBuilderView',{Name: 'Suite Builder'})
            }
        },
        RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || plug.Module_Category.Name === 'General'
        })
      },
        ActivateSelectedMain(main,frompredial){
            //console.log(main)
            if(main.PreDialog && !frompredial){
                this.SelectedMain = main
            }
            else if(main.SecondProp && main.SecondProp.PreDialog && !frompredial){
                this.SelectedMain = main
            }
            else if(main.Method && main.Method === 'SelectSuiteBuilder'){
                this.SelectSuiteBuilder()
            }
            else if(main.Method){
                this.SelectedMainDialog = false
                this.SelectedMain = ''
             this.AssignSocialItemInteractMethod(main.Prop,main.SecondProp)   
            }
            else if(main.Route){
                this.$router.push('/'+main.Route)
            }
            else{
                this.SelectedMain = main
            }
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
    }
}
</script>

<style>

</style>



