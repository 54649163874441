<template>
   <v-card flat tile class="transparent">
        <v-card-subtitle style="padding-bottom: 20px;" class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
          <v-card-text>
       <v-list  v-if="EditedTab.Name !== 'HTML Box' && EditedTab.Name !== 'Web Form' && !EditedTab.DataViewName">		
        							<v-list-item v-if="EditedTab.MagType">
                                        <v-select @change="UpdateMagType(EditedTab)" v-model="EditedTab.MagType" label="Type"
                                        :items="['Type 1','Type 2','Type 3','Type 4']"
                                        />
                                        </v-list-item>
                                        <v-list-item v-if="EditedTab.MagType">
                                          <v-switch v-model="EditedTab.NewLine" label="New Line"/>
                                        </v-list-item>
                                    <v-list-item>									
                                    <v-select :items="['justify-start','justify-center','justify-end']" v-model="EditedTab.Justify" 									
                                        label="Horizontal Align"/>									
                                    </v-list-item>									
                                <v-list-item>									
                                        <v-select clearable :items="['flex-start','center','flex-end']" v-model="EditedTab.Alignment" 									
                                        label="Vertical Align"/>									
                                    </v-list-item>
                                    <v-list-item v-if="EditedTab.Name === 'Title'">									
                                        <v-select clearable :items="['flex-start','center','flex-end']" v-model="EditedTab.TextAlign" 									
                                        label="Text Align"/>									
                                    </v-list-item>									
                                </v-list>
                                <div >									
                                <span class="caption">{{EditedTab.FlexXLRG}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexXLRG"									
                            min="1"									
                            max="12"									
                            label="X Large"									
                                ></v-slider>									
                               									
                          <span class="caption">{{EditedTab.FlexLarge}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexLarge"									
                            min="1"									
                            max="12"									
                            label="Large"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexMedium}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexMedium"									
                            min="1"									
                            max="12"									
                            label="Medium"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexSmall}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexSmall"									
                            min="1"									
                            max="12"									
                            label="Small"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.FlexXSmall}}</span>									
                          <v-slider 									
                          dense									
                            v-model="EditedTab.FlexXSmall"									
                            min="1"									
                            max="12"									
                            label="X Small"									
                                ></v-slider>	
                                </div>
          </v-card-text>
   </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        UpdateMagType(tab){
        if(tab.MagType === 'Type 1'){
          tab.FlexXLRG = 6
          tab.FlexLarge = 6
          tab.FlexMedium = 6							
          tab.FlexSmall = 6							
          tab.FlexXSmall = 12
        }
        else if(tab.MagType === 'Type 2'){
          tab.FlexXLRG = 4
          tab.FlexLarge = 4
          tab.FlexMedium = 4							
          tab.FlexSmall = 4							
          tab.FlexXSmall = 12
        }
        else if(tab.MagType === 'Type 3'){
          tab.FlexXLRG = 6
          tab.FlexLarge = 6
          tab.FlexMedium = 6							
          tab.FlexSmall = 6							
          tab.FlexXSmall = 12
        }
        else if(tab.MagType === 'Type 4'){
          tab.FlexXLRG = 4
          tab.FlexLarge = 4
          tab.FlexMedium = 4							
          tab.FlexSmall = 4							
          tab.FlexXSmall = 12
        }
      },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
