
<template>
  <div :style="ClippedTabStyle(tab)">
      
    <v-card v-if="!tab.HasContent" :class="tab.BoxTransparency" :color="tab.BGColor.hex" flat :height="tab.Height" tile :style="CheckifClipped(tab)+';z-index:0;margin-top:'+tab.RowMarginTop+';margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'">
     </v-card>
     <v-card v-if="tab.HasContent" :elevation="tab.Elevation" :color="tab.BGColor.hex" :style="ComputedStyleObject" @mouseover="SetStyleObject(tab,true)"
      @mouseleave="SetStyleObject(tab)">
      <v-img  v-if="TabHasIMG" height="100%"
                         :src="TabIMGURL"
                        >
      <!-- <div class="red">
      HelloHelloHelloHelloHelloHello
      </div> -->
      <v-list-item v-if="tab.Title" class="justify-center mediumoverline white--text"
      :style="ComputedTitleStyle">
         {{tab.Title}}
      </v-list-item>
      <v-list-item v-if="tab.HasActionButton" class="justify-center" style="align-items: center;height: 50%;">
        <v-btn style="width:80%;" >{{tab.ActionButtonName}}
        </v-btn>
      </v-list-item>
      </v-img>
      <v-list-item v-if="tab.Title && !TabHasIMG" class="justify-center mediumoverline white--text" :style="'height: 50%;align-items: end;text-align: -webkit-center;text-transform: uppercase;'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
         {{tab.Title}}
      </v-list-item>
      <v-list-item v-if="tab.HasActionButton && !TabHasIMG" class="justify-center" style="align-items: center;height: 50%;">
        <v-btn style="width:80%;" >{{tab.ActionButtonName}}
        </v-btn>
      </v-list-item>
      </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i'],
    components: {

        },
    data() {
    return {      
      StyleObject: {}, 
      OnHover: false,
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      ComputedTitleStyle(){
        return this.MiniView ? 'height: 50%;align-items: end;text-align: -webkit-center;text-transform: uppercase;'+this.HeaderFontFamily(this.tab,this.tab.HeaderFontFamily)+'font-size: large !important;'
        : 'height: 50%;align-items: end;text-align: -webkit-center;text-transform: uppercase;'+this.HeaderFontFamily(this.tab,this.tab.HeaderFontFamily)+'font-size: xxx-large !important;'
      },
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      ComputedStyleObject(){
        return this.MiniView ? this.StyleObject+'height: 200px;width:200px;' : this.StyleObject+'height: 350px;width:350px;'
      },
      TabIMGURL(){
        return this.tab.IMG ? this.tab.IMG : require('@/assets/logo.png')
      },
      TabHasIMG(){
        return this.tab.HasImage && this.tab.IMG
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
      this.SetStyleObject(this.tab)

    },
    
    methods: {
      ClippedTabStyle(tab){
        let style = ''
        if(tab.Justify === 'justify-center'){
          style = 'text-align: -webkit-center;'
        }
        else if(tab.Justify === 'justify-start'){
          style = 'text-align: -webkit-left;'
        }
        else{
          style = 'text-align: -webkit-right;'
        }
        if(tab.AnimationCSS && tab.AnimationCSS.Tab){
          style = style+tab.AnimationCSS.Tab
        }
        return style
      },
      SetStyleObject(tab,onhover){
        this.OnHover = onhover
        let radius = Number(tab.CircleRadius.split('%').join(''))
        let xpos = Number(tab.CircleXPosition.split('%').join(''))
        let ypos = Number(tab.CircleYPosition.split('%').join(''))
        if(onhover || !tab.Animated){
          this.StyleObject = 'border-radius: 50%;clip-path: circle(100%); transition: 0.5;z-index:2;'
        }
        else{
          this.StyleObject = 'border-radius: 50%;transition: 0.5;clip-path: circle('+radius+'% at '+xpos/2+'% '+ypos+'%);transition: 0.5s;z-index:2;'
          
        }        
      },
     OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex){
         this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex)
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    