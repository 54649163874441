<template>
<v-main>
  <v-card class="transparent" tile flat height="100%" width="100%" style="margin-top: 65px;">
      <v-tabs background-color="transparent" class="transparent">
           <v-tab class="largeoverline">Summary
          </v-tab>
          <v-tab-item class="transparent"  style="padding:20px;">
            <v-layout class="justify-center">
              <v-btn  @click="FilterbyType(type)" v-for="type in FilterTypes" :key="type.itemObjKey">
               <v-icon v-if="TypeFilter">mdi-close</v-icon> {{type}}
              </v-btn>
            </v-layout>
             <!-- If you wanted GP, then try another way. And you cannot show GP without cshowing liabilities, but you cannot include liabilites int eh total rollup
             and if you wanted cashflow then show cash account. until you figure all this out this little formula is useless, UNLESS TypeFilter is active
             -->
             <v-layout v-if="TypeFilter" class="justify-center">
                {{CurrencyFormatter(BalanceTotal,$store.state.DefaultCurrency.Currency)}}
            </v-layout>
           
              <v-data-iterator
      :items="EntriesbyAccount"
      :items-per-page.sync="itemsPerPage"
      hide-default-footer
      :page.sync="page"
    >
      <template v-slot:header>
        <v-toolbar
          class="mb-2"
          color="recordtoolbar darken-2"
          dark
          flat
        >
          <v-toolbar-title class="largeoverline">{{$route.params.id}} Ledger</v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.itemObjKey"
            cols="12"
            sm="12"
            md="6"
            lg="4"
          >
            <v-card>
              <v-card-title class="recordtoolbar lighten-1 white--text subheading font-weight-bold justify-space-between">
              <span class="overline">{{item.CodeTitle}}</span><v-spacer></v-spacer>
              <span class="articleoverline">
                {{item.Title}}
              </span>
              <!-- <v-list-item-content style="padding: 0px;" class="justify-start">
                <v-list-item-title class="articleoverline">
                  {{item.Title}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Type
                </v-list-item-subtitle>
              </v-list-item-content> -->
              <v-spacer></v-spacer><span class="overline">Balance</span>
              </v-card-title>
              <v-card-title style="padding-top: 0px;" class="recordtoolbar lighten-1 white--text justify-center">
                 <v-btn @click="FilterbyType(item.Type)" width="50%" dark color="links" outlined style="padding-top: 0px;">{{item.Type}}</v-btn>
              </v-card-title>
              <v-divider ></v-divider>
              <v-list>
                  <v-list-item>
                      <v-list-item-content></v-list-item-content>
                  <v-list-item-content class="justify-center" style="padding-right: 2px;">
                    Debits
                  </v-list-item-content>
                  <v-list-item-content class="justify-center" style="border-left: 1px solid green;padding-left: 2px;"> Credits</v-list-item-content>
                  <v-list-item-content>
                  </v-list-item-content>
                  </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list style="overflow: auto;" height="400" dense v-if="item.Entries">
                
                 <v-list-item class="caption" v-for="entry in item.Entries" :key="entry.id">
                      <v-list-item-content class="justify-center">{{entry.DateString}}</v-list-item-content>
                      <v-tooltip top >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content  v-bind="attrs" v-on="on" style="border-left: 1px solid grey;padding-left: 2px;" class="blue--text justify-center">
                          {{CurrencyFormatter(entry.Debit,$store.state.DefaultCurrency.Currency)}}
                      </v-list-item-content>
                      </template>
                      <v-card v-if="entry.Debit" flat tile max-width="300">
                          <v-card-text >
                              <span v-html="entry.Reason">
                              </span>
                          </v-card-text>
                      </v-card>
                   </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content  v-bind="attrs" v-on="on" style="border-left: 1px solid grey;padding-left: 2px;" class="green--text justify-center">
                          {{CurrencyFormatter(entry.Credit,$store.state.DefaultCurrency.Currency)}}
                      </v-list-item-content>
                      </template>
                      <v-card v-if="entry.Credit" flat tile max-width="300">
                          <v-card-text >
                              <span v-html="entry.Reason">
                              </span>
                          </v-card-text>
                      </v-card>
                   </v-tooltip>
                  <!-- <v-list-item-content class="green--text justify-center" style="border-left: 1px solid grey;padding-left: 2px;"> {{CurrencyFormatter(entry.Credit,$store.state.DefaultCurrency.Currency)}}</v-list-item-content> -->
                  <v-list-item-content class="grey--text justify-center" style="border-left: 1px solid grey;padding-left: 2px;">
                      {{CurrencyFormatter(entry.Balance,$store.state.DefaultCurrency.Currency)}}
                  </v-list-item-content>
                  </v-list-item>

                
              </v-list>
              <v-card-actions class="recordtoolbar white--text">
                Balance
                <v-spacer>
                </v-spacer>
                 {{CurrencyFormatter(item.Balance,$store.state.DefaultCurrency.Currency)}}
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
        >
          <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class="mr-4
            grey--text"
          >
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="links"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color="links"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
          </v-tab-item>
          <v-tab class="largeoverline"> Details
          </v-tab>
          <v-tab-item style="padding:20px;">
              <v-data-table
    :headers="JournalHeaders"
    :items="ComputedJournalEntries"
    item-key="id"
    sort-by="name"
    group-by="Type"
    class="elevation-1"
 
  ></v-data-table>
          </v-tab-item>
         
      </v-tabs>
      
  
  </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            itemsPerPageArray: [1,3,6,12],
            itemsPerPage: 12,
            page: 1,
            JournalEntries: [],
            JournalHeaders: [
                {text: 'id',value: 'id'},
                {text: 'Account',value: 'Account.Name'},
                {text: 'Reason',value: 'Reason'},
                {text: 'Code',value: 'Account.Code'},
                {text: 'Debit',value: 'Debit'},
                {text: 'Credit',value: 'Credit'}
            ],
            LedgerBook: '',
            TypeFilter: ''

        }
    },
    computed:{
      BalanceTotal(){
        return this.EntriesbyAccount.map(entry => {
          return entry.Balance
        }).reduce((a,b) => a + b ,0)
      },
      FilterTypes(){
        let arr = this.EntriesbyAccount.map(acct => {
          return acct.Type
        })
        return [...new Set(arr)]
      },
        EntriesbyAccount(){
            if(this.LedgerBook){
                let assetaccounts = this.LedgerBook.Assets.map(acct => {
                  acct.Type = 'Assets'
                  return acct})
                let expenseaccounts = this.LedgerBook.Expenses.map(acct => {
                  acct.Type = 'Expenses'
                  return acct})
                let liabilityaccounts = this.LedgerBook.Liabilities.map(acct => {
                  acct.Type = 'Liabilities'
                  return acct})
                let revenueaccounts = this.LedgerBook.Revenue.map(acct => {
                  acct.Type = 'Revenue'
                  return acct})
                let combined = assetaccounts.concat(expenseaccounts,liabilityaccounts,revenueaccounts)
                combined = combined.map(accnt => {
                    let actobj = {CodeTitle: 'Acct '+accnt.Code, Title: accnt.Name,Entries: [],Code: accnt.Code, Type: accnt.Type}
                    actobj.Entries = this.ComputedJournalEntries.filter(entry => {
                        return entry.Account && entry.Account.Code === accnt.Code
                    }).map(entry => {
                        let date = entry.Date.toDate()
                        entry.DateString = date.toDateString()
                        if(entry.Debit){
                            entry.Balance = entry.Debit
                        }
                        else{
                           entry.Balance = -entry.Credit
                          if(accnt.Type === 'Liabilities'){
                            entry.Balance = -entry.Balance
                          }
                            
                        }
                        return entry
                    })
                    actobj.Balance = actobj.Entries.map(entr => {
                      return entr.Balance
                    }).reduce((a,b) => a + b ,0)
                    return actobj
                })
                return combined.filter(item => {
                  if(this.TypeFilter){
                    return item.Type === this.TypeFilter
                  }
                  else{
                    return item
                  }
                })
            }
            else{
                return []
            }
        },
        ComputedJournalEntries(){
            return this.JournalEntries.map(entry => {
                entry.AccountName = entry.Account.Name
                return entry
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        numberOfPages(){
            return Math.ceil(this.EntriesbyAccount.length/this.itemsPerPage)
        },
    },
    created(){
        this.GetJournalBook()
        this.GetJournalEntries()
    },
    methods:{
      FilterbyType(type){
        if(this.TypeFilter){
          this.TypeFilter = ''
        }
        else{
          this.TypeFilter = type
        }
        
      },
        updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
        nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
        CurrencyFormatter(value,currency){
            if(value){
             const currencyformatter = this.PrepareCurrency(currency)
                let formalformat = currencyformatter.format(value)
                return formalformat   
            }
            else{
                return ''
            }
        
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        GetJournalBook(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Ledgers').doc(this.$route.params.id).onSnapshot(snapshot => {
                let docdata = snapshot.data()
                this.LedgerBook = docdata
            })
        },
        GetJournalEntries(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('journalentries').where('Ledger','==',this.$route.params.id).onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.JournalEntries.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }
                        if (change.type === "removed") {                      

                      let journalobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.JournalEntries.find(obj => obj.id === journalobj.id)
                      if(oncheck){
                        let index = this.JournalEntries.indexOf(oncheck)
                        this.JournalEntries.splice(index, 1);
                      }
                  }
                    })
            })
        }
    }
}
</script>

<style>

</style>


