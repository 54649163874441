<template>
   <v-layout style="margin-top:160px;">
  <v-card flat width="100%" :dark="AppisDarkMode">
    <v-card-title>
      Audit History
    </v-card-title>
    <v-expansion-panels>
      <v-expansion-panel v-for="change in ComputedChangesMade" :key="change.itemObjKey">
        <v-expansion-panel-header>
          {{change.Changed_By.Full_Name}}<v-spacer></v-spacer>{{change.Changed_On.toDate()}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item>
              <v-list-item-title>{{change.Changed_By.Full_Name}}</v-list-item-title>
              <v-list-item-subtitle>{{change.Changed_On.toDate()}}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
             
              <v-list-item-content>
                <b>Before</b>
                
                <v-list dense >
                <v-list-item v-for="prop in change.BeforeProps" :key="prop.itemObjKey">
                  <v-list-item-content class="blue--text">
                    {{prop.Name}}
                  </v-list-item-content><span v-if="prop.Props && prop.Props.length > 0">
                  <v-list-item-content class="blue--text" v-for="subprop in prop.Props" :key="subprop.itemObjKey">
                    {{change.before[prop.Name][subprop]}}
                  </v-list-item-content></span><span v-if="!prop.Props || prop.Props.length === 0">
                  <v-list-item-content class="blue--text">
                    {{change.before[prop.Name]}}
                  </v-list-item-content></span>
                  </v-list-item>
                </v-list>
              </v-list-item-content> 
              <v-list-item-content>
                <b>After</b>
                <v-list dense>
                <v-list-item v-for="prop in change.AfterProps" :key="prop.itemObjKey">
                  <v-list-item-content class="green--text">
                    {{prop.Name}}
                  </v-list-item-content ><span v-if="prop.Props && prop.Props.length > 0">
                  <v-list-item-content class="green--text" v-for="subprop in prop.Props" :key="subprop.itemObjKey">
                    {{change.after[prop.Name][subprop]}}
                  </v-list-item-content></span><span v-if="!prop.Props || prop.Props.length === 0">
                  <v-list-item-content class="green--text">
                    {{change.after[prop.Name]}}
                  </v-list-item-content></span>
                  </v-list-item>
                </v-list>
              </v-list-item-content>            
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-card>
  <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  </v-layout>
</template>

<script>
export default {
props: ['ComputedChangesMade','AppisDarkMode']
}
</script>

<style>

</style>