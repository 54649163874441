    <template>
  <v-content>
     <v-card width ="100%" flat>
       <v-img :src="require('@/assets/RABaseBG.jpeg')" height="150px">
       <h1 align="center" class="white--text">{{UserFullName}} Activities</h1>
     <v-layout class="justify-center"><span  v-for="item in TeamItems" :key="item.itemObjKey">
       <v-badge  v-if="item.count>0" color="pop" overlap dark class="mx-3">
       <v-icon color="white" large>{{item.icon}}</v-icon>
       <span slot="badge"> {{item.count }} </span>
        </v-badge>
        <v-icon color="white" v-if="item.count===0" large>{{item.icon}}</v-icon></span>
       </v-layout>
       </v-img>
       <v-layout row wrap class="justify-start">
       <v-flex xs6 sm6 md3 lg3 class="my-1" v-for="item in TeamItems" :key="item.itemObjKey">
       <v-card class="mx-2" :href="'/TeamMemberActivities/'+$route.params.id+'/'+item.countname">

             <v-layout row class="justify-start">
               <v-card-title>
            <v-avatar tile color="blue">
              <v-icon dark>{{item.icon}}</v-icon>
            </v-avatar>

            <p v-if="item.count === 0">{{item.title}}</p>
            <p v-if="item.count > 0" class="pop--text"><strong>{{item.title}} ({{item.count}})</strong></p>
                </v-card-title>
            </v-layout>

       </v-card>
       </v-flex>
       </v-layout>
     </v-card>
  </v-content>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'

export default {
  components: { BreadCrumbs },
  data() {
    return {
      Icons: [],
      drawer: false,
      items: [],
      itemsCount: [],
      UserFullName: '',
      UserAssignedRoles: {},
      MyTeamActivitiesArray: [],
      UserRecord: {},
      SubordindatesArray:[]
    }
  },
  computed: {
      userLoggedIn () {
      return this.$store.getters.user
    },

    MyTeamMemberActivities(){
        return this.MyTeamActivitiesArray.filter(activity => {
            return activity.Ownerid === this.$route.params.id
        })
    },

    TeamItems(){
        return this.items.map(item => {
        let tempcount = this.MyTeamActivitiesArray.filter(activity => {return activity.regardingtype === item.countname && activity.Ownerid === this.$route.params.id && activity.status === 'Open'}).length
        item.count = tempcount
        return item
      })
    }

    // itemsCount(){
    //   return this.items.map(item => {
    //     item.count = this.MyActivitiesArray.filter(activity => {
    //       return activity.regardingtype === item.countname
    //     })
    //     return item
    //   })
    // },
    },

    watch: {
      userLoggedIn (value) {
        if (value === null || value === undefined) {
          this.$router.push('/login')
        }
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },

  created() {


    this.GetRequestingUser()
  },

  methods: {
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserRecord = userdetails
          vm.UserRecord.id = user.uid
          vm.SubordindatesArray = userdetails.Subordinatearrayquery

          vm.getUserActivityRelated()
          })
        }
      })
    },

    GetActivities(){

     db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Ownerid','==',this.$route.params.id).onSnapshot(res =>{
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyTeamActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })

    //   this.itemsCount = []
        console.log('this.MyTeamActivitiesArray')
        console.log(this.MyTeamActivitiesArray)
      this.items.map(item => {
          console.log('item')
          console.log(item)

        let tempcount = this.MyTeamActivitiesArray.filter(activity => {return activity.regardingtype === item.countname && activity.Ownerid === this.$route.params.id}).length
        console.log('tempcount')
        console.log(tempcount)
        item.count = tempcount
        // this.itemsCount.push(item)
      })
    })
    },


    async getUserActivityRelated() {
        var vm = this;

//       await firebase.auth().onAuthStateChanged(function(user) {
//   if (user) {
        //console.log('AuthchangeLoggedIn but not dispatching userstate')
        // console.log(user.uid)
        // vm.$store.dispatch('UserState',user)
      vm.items = []
        db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users').doc(this.$route.params.id).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserAssignedRoles = userdetails.rolesarrayQuery
          vm.UserFullName = userdetails.Name+' '+userdetails.Surname
          if(typeof vm.UserAssignedRoles !== 'undefined'){
          let SystemAdminroletest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
            if(typeof SystemAdminroletest !== 'undefined'){
              let BusinessUnitsitemobject = {
              icon: 'mdi-security-network',
              title: 'BusinessUnits',
              to: '/MyActivities/BusinessUnit',
              countname: 'BusinessUnit'
            }
            let BusinessUnitsitemscheck = vm.items.find(obj => obj.title === 'BusinessUnits')
              if(typeof BusinessUnitsitemscheck === 'undefined'){
              vm.items.push(BusinessUnitsitemobject)
              }
              let Usersitemobject = {
              icon: 'mdi-face',
              title: 'Users',
              to: '/MyActivities/User',
              countname: 'User'
            }
            let Usersitemscheck = vm.items.find(obj => obj.title === 'Users')
              if(typeof Usersitemscheck === 'undefined'){
              vm.items.push(Usersitemobject)
              }
              vm.GetActivities()
            }

            else{
              let BusinessUnitsitemobject = {
                icon: 'mdi-security-network',
                title: 'BusinessUnits',
                to: '/MyActivities/BusinessUnit',
                countname: 'BusinessUnit'
              }
              let BusinessUnitsitemscheck = vm.items.find(obj => obj.title === 'BusinessUnits')
              if(typeof BusinessUnitsitemscheck === 'undefined'){
              vm.items.push(BusinessUnitsitemobject)
              }
              let Usersitemobject = {
                icon: 'mdi-face',
                title: 'Users',
                to: '/MyActivities/User',
                countname: 'User'
              }
              let Usersitemscheck = vm.items.find(obj => obj.title === 'Users')
              if(typeof Usersitemscheck === 'undefined'){
              vm.items.push(Usersitemobject)
              }
            let DefaultlistAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list All')
            if(typeof DefaultlistAllroletest !== 'undefined'){
                
              }
            let DefaultlistUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UserUnit')
            if(typeof DefaultlistUserUnitroletest !== 'undefined'){
                
              }
            let DefaultlistUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UnitDown')
            if(typeof DefaultlistUnitDownroletest !== 'undefined'){
                
              }
            let DefaultOwnerroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Owner')
            if(typeof DefaultOwnerroletest !== 'undefined'){
                
              }
            let DefaultAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default All')
            if(typeof DefaultAllroletest !== 'undefined'){
                
              }
            let DefaultgetUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UnitDown')
            if(typeof DefaultgetUnitDownroletest !== 'undefined'){
                
              }
            let Defaultcreateunitdownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create unitdown')
            if(typeof Defaultcreateunitdownroletest !== 'undefined'){
                
              }
            let DefaultupdateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update UserUnit')
            if(typeof DefaultupdateUserUnitroletest !== 'undefined'){
                
              }
            let DefaultUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit')
            if(typeof DefaultUnitroletest !== 'undefined'){
                
              }
            let DefaultupdateALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update ALL')
            if(typeof DefaultupdateALLroletest !== 'undefined'){
                
              }
            let DefaultdeleteUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UserUnit')
            if(typeof DefaultdeleteUserUnitroletest !== 'undefined'){
                
              }
            let DefaultdeleteUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UnitDown')
            if(typeof DefaultdeleteUnitDownroletest !== 'undefined'){
                
              }
            let DefaultdeleteALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete ALL')
            if(typeof DefaultdeleteALLroletest !== 'undefined'){
                
              }
            let DefaultgetAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get All')
            if(typeof DefaultgetAllroletest !== 'undefined'){
                
              }
            let DefaultCreateAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Create All')
            if(typeof DefaultCreateAllroletest !== 'undefined'){
                
              }
            let DefaultcreateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create UserUnit')
            if(typeof DefaultcreateUserUnitroletest !== 'undefined'){
                
              }
            let DefaultupdateUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update Unit Down')
            if(typeof DefaultupdateUnitDownroletest !== 'undefined'){
                
              }
            let DefaultUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit Down')
            if(typeof DefaultUnitDownroletest !== 'undefined'){
                
              }
            let DefaultgetUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UserUnit')
            if(typeof DefaultgetUserUnitroletest !== 'undefined'){
                
              }
              vm.GetActivities()
            }
                       
          }
          else{
              let BusinessUnitsitemobject = {
            icon: 'mdi-security-network',
            title: 'BusinessUnits',
            to: '/MyActivities/BusinessUnit',
            countname: 'BusinessUnit'
          }
          let BusinessUnitsitemscheck = vm.items.find(obj => obj.title === 'BusinessUnits')
          if(typeof BusinessUnitsitemscheck === 'undefined'){
          vm.items.push(BusinessUnitsitemobject)
          }
              let Usersitemobject = {
            icon: 'mdi-face',
            title: 'Users',
            to: '/MyActivities/User',
            countname: 'User'
          }
          let Usersitemscheck = vm.items.find(obj => obj.title === 'Users')
          if(typeof Usersitemscheck === 'undefined'){
          vm.items.push(Usersitemobject)
          } 
              vm.GetActivities()            
            }             
          })
      }

  }

}
</script>

<style>
</style>


  