            
<template>
<v-card class="transparent" width="100%" height="100%" flat>
  <v-dialog width="400" v-model="TagsDialog" row class="justify-center">
               <!-- What was tagsdial  v-if="!FullGallery" -->
        <v-card v-if="EntityCollectionRef" flat>
          <v-card-title class="overline recordtoolbar white--text">
            {{TableName}} Tags
            <v-spacer></v-spacer>
            <v-dialog v-model="NewTagDialog" width="400"> 				
                  <template v-slot:activator="{ on }">									
                      <v-btn dark v-on="on">Add</v-btn>			
                      </template>	
                      <v-card width="100%" tile>
                        <v-card-title class="overline recordtoolbar white--text">
                          New Tag
                        </v-card-title>
                          <v-card-text>
                            <v-combobox v-model="EditedTag.tagtype" label="Category" :items="CustomTagCategories" small-chips />
                            <v-text-field v-model="EditedTag.tagname" label="Name" />
                            
                          </v-card-text>
                          <v-card-actions>
                            <v-btn fab icon @click="CancelAddKeyWord()">Cancel</v-btn>	
                            <v-spacer></v-spacer>
                            <v-btn fab icon @click="AddKeyWord()">Add</v-btn>			
                          </v-card-actions>
                      </v-card>
               </v-dialog>
          </v-card-title>
            <v-card-text>
              <v-expansion-panels>
              <v-expansion-panel v-for="cat in TagCategories" :key="cat.itemObjKey">
                <v-expansion-panel-header class="detailslistoutline">
                 {{cat.Name}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip-group
                          active-class="primary--text"
                          column
                        >
                         <div  v-for="tag in cat.Classes"
                            :key="tag.itemObjKey">
                         <!-- <v-menu offset-y :close-on-content-click="false" bottom left>
                              <template v-slot:activator="{ on }"> -->
                               
                          <v-chip small
                           v-on="on"
                          >
                            {{ tag.tagname }}
                          </v-chip>
                              <!-- </template>
                              <v-card>
                                <v-card-text>
                                  <v-text-field label="Name" v-model="tag.tagname" />            
                                  <v-textarea label="Description" v-model="tag.Description" />                                  
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn small @click="DeleteKeyWord(tag)">Delete</v-btn>
                                    <v-spacer></v-spacer>
                                  <v-btn small @click="SaveKeyWord(tag)">Save</v-btn>
                                </v-card-actions>
                              </v-card>
                         </v-menu> -->
                         </div>
                        </v-chip-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
              </v-expansion-panels>
               
            </v-card-text>
          </v-card>
              </v-dialog>
   <v-layout row class="justify-center">
   <v-flex lg10>
    <v-card min-height="500px" >
         <v-toolbar  rounded elevation="12"
        :style="{ zIndex:1 }"
          >
        <v-toolbar-title>My {{TableName}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <span v-if="SearchTagName">{{SearchTagName.tagname}}</span>
        <v-spacer></v-spacer>
        <v-text-field dark
            v-model="stringsearch"
            
            label="Name Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="stringsearch === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="stringsearch !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
        <v-autocomplete :dark="AppisDarkMode"
           v-if="typeof $route.params.id === 'undefined' || FullGallery"
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
          item-text="tagfiltername"
          :items="PhotoTagNamesCount"
          return-object
        ></v-autocomplete><v-icon @click="ResetSearch()" v-if="search !== '' && typeof $route.params.id === 'undefined' || search !== '' && FullGallery" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <!-- WHY was tagsdial  v-if="!FullGallery"??? -->
        <v-btn color="success" dark class="mb-2" v-on="on" v-if="EntityCollectionRef" @click="TagsDialog = !TagsDialog">Manage Key Words</v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Photo</v-btn>
          </template>
          <v-card>
            <v-card-title class="recordtoolbar white--text">
              <span class="headline">New Photo</span><input
                          style="display: none"
                          type="file"
                          @change="AddLibraryAssets($event)"
                          ref="LibraryAssetsfileInputer"
                          :multiple="true"
                          accept="image/*">
                  <v-spacer></v-spacer>          
                <v-btn :disabled="UploadFilesList.length > 0" @click="$refs.LibraryAssetsfileInputer.click()">{{UploadFilesList.length === 0 ? 'Select Photos' : UploadFilesList.length+' Selected'}}</v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="n in UploadFileListFiles"
                  :key="n.itemOvjKey"
                  class="d-flex child-flex"
                  cols="3"
                >
                  <v-img
                    :src="n"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
                
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <!-- <v-text-field v-model="PhotoTitle" label="Photo Name"></v-text-field>
                    <v-textarea v-model="PhotoDescription" label="Description"></v-textarea> -->
                 
                      <v-autocomplete
                        v-model="PhotoTags"
                        :items="TagsArray"
                        item-text="tagname"
                        hide-selected
                        label="Search Tags"
                        small-chips
                        solo
                        flat
                        multiple
                        return-object
                      ></v-autocomplete>
                      
                  </v-col>
                </v-row>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="blue darken-1" text @click="CancelUploadLibraryAssets()">Cancel</v-btn> -->
              <!-- <v-btn color="blue darken-1" text @click="UploadLibraryAssets()">Save</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>        
      </v-toolbar>
      <v-layout row class="justify-center my-1">
            <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="tag in PhotoTagTypes"
              :key="tag"
              @click="ActivateTagTypeFilter(tag)"
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-layout>
      <v-layout row class="justify-center">
            <v-chip-group
            v-if="selectedtagtype"
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="tag in PhotoTagNamesCount"
              :key="tag"
              @click="ActivateTagTypeSearch(tag)"
            >
              {{ tag.tagfiltername }}
            </v-chip>
          </v-chip-group>
        </v-layout>

      <v-layout row class="justify-center">
      <v-dialog :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-view-carousel</v-icon>
                        </template>
                    <v-card color='rgba(0,0,0,0.5)' height="100%" width="100%">
                        <v-card dark>
                        <v-card-title>
                            Preview {{TableName}}
                        </v-card-title>
                        </v-card>
                    <v-carousel>
                        
                        <v-carousel-item
                        v-for="(photo,i) in LibraryAssetsArrayPagination"
                        :key="i"
                        :src="photo.path"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                        contain
                        >
                        <v-chip-group>
                                <v-chip
                                v-for="tag in photo.DataTags" :key="tag.itemObjKey"
                                :to="tag.taglocation">
                                <!-- <router-link> -->
                                    {{tag.tagname}}
                                <!-- </router-link> -->
                                </v-chip>
                            </v-chip-group>
                        <v-btn icon :href="photo.path" target="_blank">
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                        </v-carousel-item>
                    </v-carousel>
                    </v-card>
                </v-dialog>
      </v-layout>
      <v-layout row class="justify-center">
        <v-card width="90%" max-width="1200px" flat style="padding-bottom:50px">
        <v-pagination
                    :total-visible="9"
                      v-model="LibraryAssetsArrayPage"
                      circle
                      :length="Math.ceil(LibraryAssetsArraySearched.length/this.displaylimit)"
                    ></v-pagination>

          <v-layout row wrap class="justify-center">
           <!-- <v-col v-for="photo in LibraryAssetsArrayPagination"
        :key="photo.itemObjKey"
        :cols="ImageCols" style="padding: 4px;"
        > -->
        <v-hover v-for="photo in LibraryAssetsArrayPagination"
             :key="photo.itemObjKey"
            v-slot="{ hover }"
            open-delay="200" 
        >
        <v-card flat tile class="mx-3 my-3"
               :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
              max-width="200px"
              
            >    
            <v-btn v-if="CanDelete" small class="red" @click="DeletePhoto(photo)"
        fab absolute top left dark style="left:0px;top:-10px;">
            <v-icon>mdi-delete
            </v-icon>
        </v-btn>
                <v-img
                  :src="photo.ThumbURL"
                  aspect-ratio="1"
                  width="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                   <v-expand-transition>
                      <div
                          v-if="hover && !HideSelect"
                          class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal white--text"
                          style="height: 100%;"
                      >
                          <v-chip @click="AssignGalleryAssets(tab,photo)" style="cursor: pointer;" dark>{{SelectName ? SelectName : 'Select'}}</v-chip>
                          <v-dialog max-width="800">
                    <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="!FullGallery"
                            fab
                            right
                            absolute
                            bottom
                            x-small
                            ><v-icon
                            color="red"
                            v-on="on" small>
                            mdi-tag
                            </v-icon>
                        </v-btn>
                        </template>
                    <v-card>
                      <v-card dark>
                        <v-card-title class="primary white--text">
                            Edit Photo
                             <v-btn
                        fab
                        right
                        absolute
                        x-small
                        :href="photo.path"
                        target="_blank"
                        
                        dark
                        ><v-icon
                         small>
                        mdi-eye
                        </v-icon>
                    </v-btn>
                        </v-card-title>
                        <v-img
                            max-height="400"
                            contain
                            :src="photo.path"
                            >
                        </v-img>
                        </v-card>
                      <v-card-text>
                    <v-autocomplete
                            v-model="photo.DataTags"
                            :items="TagsArray"
                            item-text="tagname"
                            hide-selected
                            label="Search Data Tags"
                            small-chips
                            solo
                            flat
                            multiple
                            return-object
                        ></v-autocomplete>
                        <v-switch v-model="photo.Featured" label="Feature Public" @change="UpdatePublicFeature(photo)"/>
                      </v-card-text>
                        <v-card-text>
                            Tag Links
                            <v-chip-group>
                                <v-chip
                                v-for="tag in photo.DataTags" :key="tag.itemObjKey"
                                :to="tag.taglocation">
                                <!-- <router-link> -->
                                    {{tag.tagname}}
                                <!-- </router-link> -->
                                </v-chip>
                            </v-chip-group>
                        </v-card-text>

                        <v-card-actions>
                        <v-btn  color="red" @click="DeletePhoto(photo)">Delete</v-btn>
                            <v-btn @click="UpdatePhoto(photo)" color="green" dark>Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                      </div>
                      </v-expand-transition>
                </v-img>
                 <!-- <v-btn v-if="FullGallery" dark color="green" icon
                 @click="AssignGalleryAssets(tab,photo)">
                             <v-icon>
                               mdi-check
                             </v-icon>
                         </v-btn> -->
                    
            </v-card>
            </v-hover>
        <!-- </v-col> -->
            
        </v-layout>
        </v-card>
      </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
 </v-card>  
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
  props: ['RouteID','AppisDarkMode','FullGallery','tab','IMGProp','TableCollectionRef','TableName','CurrentEntity','EntityCollectionRef','IsThis','CanDelete'],
    data() {
        return{
          stringsearch: '',
          UploadFileListFiles: [],
          UploadFilesList: [],
          counter: 0,
          TagsDialog: false,
          NewTagDialog: false,
          DefaultEditedTag: {tagname: 'New Tag'},
          EditedTag: {tagname: 'New Tag',tagtype: ''},
          EditedTagIndex: -1,
          CustomTagsInteger: 1000000,
          CustomTags: [],
          LibraryAssetsArrayPage: 1,
          displaylimit: 42,
          selectedtagtype: '',
            dialog: false,
            LibraryAssetsArray: [],
            activePhoto: {title: '',path: '',src: '',thumbnail: ''},
            PhotoTitle: '',
            PhotoThumbnail: '',
            PhotoDescription: '',
            PhotoTags: [],
            StandardTags: ['Machine','Computer','Test'],
            search: {},
            CreatedOn: format(new Date(),'yyyy-MM-dd'),
            EquipmentManufacturersArray: [],
            ModelsArray: [],
            UserRecord: {},
            UserRoles: [],
            UserBusinessUnitID: '',
            CollectionRef: this.TableCollectionRef,

        }
    },
    computed: {
      ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 4
        }
        else if(this.$vuetify.breakpoint.sm){
          return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return 3
        }
        else if(this.$vuetify.breakpoint.lg){
          return 2
        }
        else if(this.$vuetify.breakpoint.xl){
          return this.GalleryAssets && this.GalleryAssets.length > 12 ? 1 : 2 
        }
      },
      TagCategories(){
        let cats = []
        this.CustomTags.map(cat => {
          let oncheck = cats.find(obj => obj.Name === cat.tagtype)
          if(!oncheck){
            cats.push({
              Name: cat.tagtype,
              Classes: [
                cat
              ]
            })
          }
          else{
            let index = cats.indexOf(oncheck)
            cats[index].Classes.push(cat)
          }
        })
        return cats
      },
      CustomTagCategories(){
        return this.CustomTags.map(tag => {
          return tag.tagtype
        })
      },
      LibraryAssetsArrayPagination(){
            let displayrange = this.displaylimit*this.LibraryAssetsArrayPage-this.displaylimit
        return this.LibraryAssetsArraySearched.slice(displayrange, displayrange+this.displaylimit)        
        },
      PhotoTagTypes(){
            let tags = this.LibraryAssetsArray.map(photo => {
                return photo.DataTags.map(tag => {
                  return tag.tagtype
                }).flat()
            }).flat()
            let uniq = [...new Set(tags)];
            return uniq
        },
      SearchTagName(){
        if(typeof this.search.id !== 'undefined'){
        let tagobj = this.LibraryAssetsArray.find(obj => obj.id == this.search.id)
        return tagobj
        }
        
      },
      PhotoTagObjects(){
        return this.PhotoTags.map(tag => {
          let obj = {tagname: tag.tagname, taglocation: tag.taglocation,tagtype: tag.tagtype}
          if(tag.id){
            obj.id = tag.id
          }
          else{
            obj.ID = tag.ID
          }
          return obj
        })
      },
      PhotoTagObjectIDs(){
        return this.PhotoTags.map(tag => {
          if(tag.id){
            return tag.id
          }
          else{
            return tag.ID
          }          
        })
      },
        TagsArray(){
            let MergedArray = []
            MergedArray = this.CustomTags.concat(this.ModelsTagsArray,this.EquipmentManufacturersTagsArray)
            return MergedArray
        },
        EquipmentManufacturersTagsArray(){
          return this.EquipmentManufacturersArray.map(oem => {
                oem.tagname = oem.Name
                oem.tagtype = 'Equipment Manufacturers'
                oem.taglocation = '/OEM-Wiki/'+oem.Name
                return oem
            })
        },
        ModelsTagsArray(){
          return this.ModelsArray.map(model => {
                model.tagname = model.Model_Number
                model.tagtype = 'Models'
                model.taglocation = '/Model-Wiki/'+model.Model_Number
                return model
            })
        },
        PhotoTagNames(){
            return this.LibraryAssetsArray.map(photo => {
                return photo.DataTags.map(tag => {
                  return tag.tagname
                }).flat()
            }).flat()
        },

        PhotoTagIDs(){
            return this.LibraryAssetsArray.map(photo => {
                return photo.DataTags.map(tag => {
                  if(tag.id){
                    return tag.id
                  }
                  else{
                    return tag.ID
                  }
                }).flat()
            }).flat()
        },

        PhotoTagNamesCount(){
          let newarray = JSON.parse(JSON.stringify(this.LibraryAssetsArray))
          //console.log('newarray',newarray)
          // let MutatedLibraryAssetsArray = this.LibraryAssetsArray
          let uniq = [];
            let filterarray = newarray.filter(photo => {
            if(typeof this.selectedtagtype !== 'undefined'){
            let phototagtypematch = photo.DataTags.map(tag => {
                  return tag.tagtype
                }).flat()
                //console.log(phototagtypematch,this.selectedtagtype)
                if(phototagtypematch.includes(this.selectedtagtype)){
                  return photo
                }
            }
            else{
              return photo
            }
          }).map(photo => {
                return photo.DataTags.map(tag => {
                  var re = ''
                  if(tag.ID){
                    re = new RegExp(tag.ID, 'g')
                  }
                  else{
                    re = new RegExp(tag.id, 'g')
                  }                  
                    tag.tagfiltername = tag.tagname+' ('+this.PhotoTagIDs.toString().match(re).length+')'
                    return tag
                })
            }).flat().filter(tagname => {
              if(typeof this.selectedtagtype !== 'undefined'){
                
                return tagname.tagtype === this.selectedtagtype
                
              }
              else{
                return tagname
              }
            }).map(tagname => {
              //console.log(tagname)
              let oncheck = ''
              if(tagname.ID){
                oncheck = uniq.find(obj => obj.ID === tagname.ID)
              }
              else{
                oncheck = uniq.find(obj => obj.id === tagname.id)
              }              
              if(!oncheck){
                uniq.push(tagname)
              }
              return tagname
            })
          // console.log(filterarray)
            // let uniq = [];
            // console.log(uniq)
            return uniq
        },
       
        LibraryAssetsArraySearched(){
          return this.LibraryAssetsArray
          .filter(photo => {
            if(this.selectedtagtype){
            let phototagtypematch = photo.DataTags.map(tag => {
                  return tag.tagtype
                }).flat()
                //console.log(phototagtypematch,this.selectedtagtype)
                if(phototagtypematch.includes(this.selectedtagtype)){
                  return photo
                }
            }
            else{
              return photo
            }
          })
          .filter(photo => {
            let idprop = ''
            if(typeof this.search.id !== 'undefined'){
              idprop = 'id'
            }
            else if(typeof this.search.ID !== 'undefined'){
              idprop = 'ID'
            }
            ///alert('idprop is '+typeof idprop)
            if(idprop){
            let photoidstringmatch = []
            if(photo.DataTags && photo.DataTags.length > 0){
              photoidstringmatch = photo.DataTags
              .map(tag => {
                  return tag[idprop]
                }).flat()
            }
            //console.log(photoidstringmatch)
                if(photoidstringmatch.includes(this.search[idprop])){
                  return photo
                }
            }
            else{
              return photo
            }
          }).filter(photo => {
           // console.log(photo)
            return photo.Name.toLowerCase().includes(this.stringsearch.toLowerCase())
          })
        },
    },

    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
        this.CheckRouting()
        if(this.CurrentEntity.CustomTags){
          this.CustomTags = this.CurrentEntity.CustomTags
        }
        if(this.CurrentEntity.CustomTagsInteger){
          this.CustomTagsInteger = this.CurrentEntity.CustomTagsInteger
        }
        this.GetLibraryAssets()
        // this.GetEquipmentManufacturersArray()
        // this.GetModelsArray()
        this.GetRequestingUser()
        this.IntranetViewToggle()
    },

    methods:{
      
      
    DeleteKeyWord(keyword){
      confirm('This will remove the option from all other Company Documents, are you sure?') && this.ProcessDeleteKeyWord(keyword)
    },
    
    ProcessDeleteKeyWord(keyword){
      let index = this.TagsArray.indexOf(keyword)
      this.TagsArray.splice(index,1)
      db.collection('floatingphotoskeywords').doc(keyword.id).delete()
    },
    SaveKeyWord(keyword){
      db.collection('floatingphotoskeywords').doc(keyword.id).update({
        tagname: keyword.tagname,
        Description: keyword.Description
      })
    },
    CancelAddKeyWord(){
      this.EditedTag = Object.assign({},this.DefaultEditedTag)
      this.NewTagDialog = false
    },
      AddKeyWord(){
        this.CustomTagsInteger = this.CustomTagsInteger-1+2
        let newtag = {tagname: this.EditedTag.tagname,taglocation: '',tagtype: this.EditedTag.tagtype,ID: this.CustomTagsInteger}        
        this.CustomTags.push(newtag)
        console.log(this.EntityCollectionRef,this.TableCollectionRef,this.CustomTagsInteger)
        this.EntityCollectionRef.update({
          CustomTags: this.CustomTags,
          CustomTagsInteger: this.CustomTagsInteger
          })
          this.CancelAddKeyWord()
         
      },
      
      AssignGalleryAssets(tab,photo){
            if(this.IMGProp){
                this.$emit('AssignImage',tab,this.IMGProp,photo,this.IsThis)
            }
            else{
              this.$emit('AssignGalleryAssets',tab,photo)  
            }
        
      },
      UpdatePublicFeature(photo){
        this.TableCollectionRef.doc(photo.id).update({
          Featured: photo.Featured
        })
      },
      ActivateTagTypeFilter(tag){
            this.selectedtagtype = tag
        },
        ActivateTagTypeSearch(tag){
        this.search = tag
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
        CheckRouting(){
        if(typeof this.$route.params.id !== 'undefined' && !this.FullGallery){
          this.search ={}
          this.CollectionRef = this.CollectionRef.where('datatagsid','array-contains',this.RouteID)
            if(this.ViewLimit){
              if(this.ViewLimitInt !== 'None'){              
                this.CollectionRef = this.CollectionRef.limit(this.ViewLimitInt)
              }
            }           
          }
          else if(this.FullGallery){
            this.displaylimit = 10
          }
        },
        GetEquipmentManufacturersArray(){
         db.collection('equipmentmanufacturerswiki').onSnapshot(res => {
             const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.EquipmentManufacturersArray.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
              })
            })
        },
        GetModelsArray(){
         db.collection('modelswiki').onSnapshot(res => {
             const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.ModelsArray.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
              })
            })
        },
        contains(a, obj) {
            for (var i = 0; i < a.length; i++) {
                if (a[i].includes(obj)) {
                    return true;
                }
            }
            return false;
        },
        ResetSearch(){
          this.stringsearch = ''
            this.search = {}
            this.selectedtagtype = ''
        },
        async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.UserRoles = userdetails.rolesarrayQuery
              vm.UserBusinessUnitID = userdetails.businessunitid
            })
            }
          })
        },
        GetLibraryAssets(){
          //console.log(this.TableCollectionRef)
            this.TableCollectionRef.onSnapshot(res => {
             const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                   let assetobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                  if(!assetobj.DataTags){
                  assetobj.DataTags = []
                }
                this.LibraryAssetsArray.push(assetobj)
                }
                if (change.type === 'removed') {
                  let assetobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                let oncheck = this.LibraryAssetsArray.find(obj => obj.id === assetobj.id)
                if(oncheck){
                  let index = this.LibraryAssetsArray.indexOf(oncheck)
                  this.LibraryAssetsArray.splice(index,1)
                }
                }


              })
            })
        },
        AssignFirstPhoto(){
            this.activePhoto = this.LibraryAssetsArray[0]
        },

        GetAccountsArray(){
            db.collection('accounts').onSnapshot(res => {
             const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.AccountsArray.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
      })
    })
        },
        GetContactsArray(){
            db.collection('contacts').onSnapshot(res => {
             const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.ContactsArray.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
      })
    })
        },
        AddLibraryAssets(){
          let fileList = event.target.files || e.dataTransfer.files
      // this.UploadFilesList = event.target.files || e.dataTransfer.files
      // this.UploadFileListFiles = Array.from(Array(this.UploadFilesList.length).keys()).map(file => {
      //   let tmpsrc = URL.createObjectURL(this.UploadFilesList[file])
      //   return tmpsrc
      // })
      // console.log(this.PhotoTags)
      //console.log(this.PhotoTagObjects,this.PhotoTags)
        this.$store.commit('setUploadFilesList',fileList)
        this.$store.commit('setPhotoTagObjects',this.PhotoTagObjects)
        let filestorageref = 'LibraryAssets/'+this.CurrentEntity.id+'/'+new Date()
        let dbrecordref = this.TableCollectionRef
        this.$store.commit('setUploadFileStorageRef',filestorageref)
        this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
      },
      CancelUploadLibraryAssets(){
            this.PhotoTitle = ''
            this.PhotoThumbnail = ''
            this.PhotoDescription = ''
            this.PhotoTags = []
            this.UploadFilesList = []
            this.UploadFileListFiles = []
            this.dialog = false
            this.counter = 0
            //this.UploadsPreviews = []
      },

      UploadLibraryAssets(){
        let fileList = this.UploadFilesList
        this.$store.commit('setUploadFilesList',fileList)
        this.$store.commit('setPhotoTagObjects',this.PhotoTagObjects)
        let filestorageref = 'LibraryAssets/'+vm.CurrentEntity.id+'/'+new Date()+'/'
        let dbrecordref = this.TableCollectionRef
        this.$store.commit('setUploadFileStorageRef',filestorageref)
        this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
      },
      PreparePhotosThumbnail(file,index,listlength){
        this.$emit('ActivateProcessing',true)
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
     
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            let ref = 'LibraryAssets/'+vm.CurrentEntity.id+'/'+new Date()+'/'+ file.name
            // var storageRef = firebase.storage().ref(ref)
            var thumbstorageRef = firebase.storage().ref(ref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = thumbstorageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      vm.AddLibraryAssetsSingle(file,index,listlength,thumburl,ref)
                    })
              })
      }
      },

      AddLibraryAssetsSingle(file,index,listlength,thumburl,ref) {        
        
        var storageRef = firebase.storage().ref(ref)
        var uploadTask = storageRef.put(file);
        let vm = this
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                 
                      const newphoto = {
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Created_Byid: this.UserRecord.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.lastModifiedDate,
                              Modified_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                              Modified_Byid: this.UserRecord.id,
                              Modified_On: new Date(),
                              Name: file.name,
                              path: url,
                              Path: url,
                              DataTags: vm.PhotoTagObjects,
                              DataTagsid: vm.PhotoTagObjectIDs,
                              StorageRef: ref
                              
                              }
                              console.log(newphoto)
                    this.TableCollectionRef.add(newphoto).then(photo => {                      
                        vm.$emit('ActivateStandbyDialog',false)
                        let snackbarcontent = {
                          timeout: 4000,
                          text: 'Successfully added the item '+file.name,
                          title: 'Added '+file.name+' to '+this.TableName,
                          show: true,
                          thumbnail: thumburl
                        }
                        vm.$emit('ActivateNotificationSnackbar',true,snackbarcontent)
                        vm.counter = vm.counter-1+2
                        if(vm.counter === listlength){
                          vm.CancelUploadLibraryAssets()
                          vm.$emit('ActivateProcessing',false)
                        }
                      })
                      })              
      },
      DeletePhoto(photo){
            confirm('This will remove the item from your library, and cannot be undone. Are you sure?') && this.ProcessDeletePhoto(photo)
        },
        ProcessDeletePhoto(photo){
            let vm = this
            let photoindex = this.LibraryAssetsArray.find(obj => obj.id === photo.id)
                let thumstorageRef = firebase.storage().ref(photo.StorageRef+'_thumb');
                
                    let storageRef = firebase.storage().ref(photo.StorageRef);	
                        storageRef.delete().then(function() {									
                            // File deleted successfully
                                    console.log('actual file successfully deleted')
                                thumstorageRef.delete().then(function() {									
                                // File deleted successfully		
                                console.log('thumb file successfully deleted')
                                vm.TableCollectionRef.doc(photo.id).delete().then(function() {
                                    //vm.LibraryAssetsArray.splice(photoindex,1)
                                }).catch(function(error) {									
                                // Uh-oh, an error occurred!									
                                });
                                }).catch(function(error) {									
                                // Uh-oh, an error occurred!									
                                });							
                        }).catch(function(error) {									
                            // Uh-oh, an error occurred!									
                        });
        },
      UpdatePhoto(photo){
        console.log(this.TableCollectionRef.doc(photo.id),photo)
        let tagsid = photo.DataTags.map(tag => {
          if(tag.id){
            return tag.id
          }
          else{
            return tag.ID
          }
        })
        let tagsStringArray = photo.DataTags.map(tag => {return tag.tagname.toLowerCase()})        
        let SearchQuery = this.GetSearchQueryfromArray(tagsStringArray)
          this.TableCollectionRef.doc(photo.id).update({
              DataTags: photo.DataTags,
              DataTagsid: tagsid,
              SearchQuery: SearchQuery
          })
      },
      GetSearchQueryfromArray(ProvidedArray){
        let SearchQuery = []
        var lowertext = "";                
        var p;
        var y;
        let buildup = ''
        console.log(ProvidedArray)
        for (p = 0; p < ProvidedArray.length; p++) {
              for (y = 0; y < ProvidedArray[p].length; y++) {
                if(y === 0){
                  buildup = ProvidedArray[p][y]
                  lowertext += buildup.toLowerCase() + ",";
                }
                else{
                  buildup = buildup+ ProvidedArray[p][y]
                lowertext += buildup.toLowerCase() + ",";
                }
                
                }
            }
            SearchQuery = lowertext.split(',')  
            SearchQuery.length = SearchQuery.length-1
            return SearchQuery
      },
    }
}
</script>

<style>
  
</style>