
<template>
    
             <v-card :style="tab ? BoxStyle(tab) : UserView ? 'margin-top:50px;' : ''" :class="tab ? tab.BoxTransparency+' '+tab.CustomClass+' mx-3 footermargin' : 'transparent footermargin'" :color="tab && tab.BGColor ? tab.BGColor.hexa : ''"   
             :elevation="tab ? tab.Elevation : 0" :tile="tab ? tab.Tile : true" :outlined="tab ? tab.Outlined : false" :shaped="tab ? tab.Shaped : false"
             :flat="!tab"
             >
            
                <v-btn outlined v-if="UserisTemplateOwner"
              class="green--text" @click="Activatedialog()" fab small absolute style="margin-right:40px;" right><v-icon>mdi-plus</v-icon>
              </v-btn>  
              <v-btn class="red--text" outlined @click="OpenAboutDialog('AboutSiteArticles')" fab small absolute  right><v-icon>mdi-help</v-icon>
              </v-btn>
              <v-dialog persistent v-model="dialog"	max-width="400"		
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                            									
                    >		
                    <v-card v-if="$store.state.TemplateView" tile>
                      <v-card-title class="black white--text">
                          New Sample Article
                      </v-card-title>
                      <v-card-text>
                        <v-form  ref="form">
                          <v-text-field :rules="[$store.state.formrules.required]" v-model="editedItem.title" label="Title"></v-text-field>
                          <UserLookup v-if="$store.state.TemplateView" :UsersLookupArray="TemplateUsers"  :ModelProp="'Creator'" :RecordObj="editedItem" @UpdateUserLookupProp="UpdateUserLookupProp"
                            :FieldLabel="'Author'" :Rules="[$store.state.formrules.required]"
                            />                       
                          <v-textarea :rules="[$store.state.formrules.required]" v-model="editedItem.caption" label="caption"></v-textarea>
                          <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog" :PushedElementid="tab ? 'articlewriteup-tab-'+tab.ElementID : 'articlewriteup'"
                   @BlurWriteup="BlurWriteup"/>
                            <v-img class="black" height="200" contain									
                                :src="editedItem.coverimage ? editedItem.coverimage : require('@/assets/ImageHolder.png')"									
                                >
                                <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                            
                                    <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteArticleCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                                </v-row>
                          </v-img>	
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn dark @click="Closedialog()" outlined color="warning">Cancel</v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="save()" dark outlined color="success">Save</v-btn>
                      </v-card-actions>
                    </v-card>  	
            </v-dialog>
               <div v-if="UserRecord">
              <div  v-if="SliderView">
                <v-card flat v-if="RelatedArticles.length === 0">
                  <v-card-subtitle>
                    There are no Articles for this item at this stage
                  </v-card-subtitle>
                </v-card>
                  <v-carousel cycle v-model="model" height="400"  v-if="RelatedArticles.length !== 0">
                      <v-carousel-item
                        v-for="article in RelatedArticles.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          :style="tab && tab.OverlayGradient ? tab.OverlayGradient : 'background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);'"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" 
                              :style="tab && tab.ArticleTitleColor ? 'padding-left:25px;color: '+tab.ArticleTitleColor.hexa+'!important;' : 'padding-left:25px;'">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text :style="ArticleCaptionStyle+'padding-left:25px;'" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="ItemPath+'/Article/'+article[ItemProp]"
                              :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'black'"
                              class="links--text"
                              :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list class="transparent">
     <v-list-item  v-for="article in RelatedArticles" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img :style="tab && tab.IMGBGColor ? 'background-color: '+tab.IMGBGColor.hexa : ''"
                :contain="!tab || tab && !tab.WideIMG"
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title :style="NonSliderTitleStyle" class="headline">
                           <v-icon class="soloactionicon" left :style="NonSliderIconStyle">mdi-email-newsletter</v-icon>
                          {{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  <v-chip small v-if="article.PublishDate && article.PublishDate.toDate">
                  {{article.PublishDate.toDate().toDateString()}}</v-chip>
                  <v-spacer>
                  </v-spacer>
                   <v-chip v-if="!MiniView" small :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'secondary'" class="white--text">
                    {{article.author}} 
                  </v-chip>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text :style="ArticleCaptionStyle" class="white--text overline" v-if="article.caption">
                  {{article.caption.substr(0,150)}}
                </v-card-text>
                 <v-card-text v-html="smart_substr(article.description,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn  :small="MiniView" :to="ItemPath+'/Article/'+article[ItemProp]"
                     class="links--text" :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'black'">
                     <v-icon v-if="MiniView">mdi-glasses</v-icon>
                     {{MiniView? '' : 'Read More'}}
                     </v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedArticles.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.description.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
              
               <div v-if="!UserRecord">
                  <div  v-if="SliderView">
                    <v-card flat v-if="RelatedArticles.length === 0">
                  <v-card-subtitle>
                    There are no Articles for this item at this stage
                  </v-card-subtitle>
                </v-card>
                  <v-carousel v-model="model" height="400" cycle  v-if="RelatedArticles.length > 0">
                      <v-carousel-item
                        v-for="article in RelatedArticles.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient+'font-size: 10px;padding-left:25px;' : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);font-size: 10px;padding-left:25px;'" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="ItemPath+'/Article/'+article[ItemProp]"  color="links black--text" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list class="transparent">
     <v-list-item  v-for="article in RelatedArticles" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  <v-chip small >
                  {{article.pubDate}}</v-chip>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="smart_substr(article.description,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="ItemPath+'/Article/'+article[ItemProp]">
                  <v-icon v-if="MiniView">mdi-glasses</v-icon>
                  {{MiniView? '' : 'Read More'}}
                  </v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedArticles.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.description.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
import UserLookup from '@/components/General/UserLookup'
export default {
  props: ['System','UserRecord','Origin','clientDB','Filter','Excerpt','Slice','UserView','UserID','SliderView','TagFilter','TagsID','Editor','tab'],
    components: {
      ContentEditable,
      UserLookup
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {

            grouparticles: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            dialog: false,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
      SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Article/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Article/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Article/' : '/Group-Article/'+this.$route.params.id+'/Article/'
        }
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      NonSliderIconStyle(){
        let style = ''
        if(this.tab && this.tab.ArticleTitleColor){
          style = style+'color: '+this.tab.ArticleTitleColor.hexa+'!important;'
        }
        return style
      },
      NonSliderTitleStyle(){
        let style = ''
        if(this.tab && this.tab.OverlayGradient){
          style = style+this.tab && this.tab.OverlayGradient
        }
        if(this.tab && this.tab.ArticleTitleColor){
          style = style+'color: '+this.tab.ArticleTitleColor.hexa+'!important;'
        }
        return style
      },
      ArticleCaptionStyle(){
        let style = 'font-size: 10px;'
        if(this.tab && this.tab.ArticleCaptionGradient){
          style = style+this.tab.ArticleCaptionGradient
        }
        else if(this.System && this.System.Social_Parallax_Gradient){
          style = style+System.Social_Parallax_Gradient
        }
        else{
          style = style+'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'
        }
        return style
      }, 
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      userIsAdmin () {
    return this.$store.state.IsAdmin
  },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      CollectionQuery(){
        let ref = this.CollectionRef
        if(this.CompanyOnlyFilter){
          ref = ref.where('Companyid','==',this.UserCompanyid)
        }
        return ref
      },
      ItemProp(){
        if(this.NonGroupRoute){
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
        else{
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
      },
      ItemPath(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '' : '/Group-Article/'+this.$route.params.id
        }
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      CollectionRef(){
        //ContentRootPath
        //ContentColPath
        if(this.TemplateRootPath){
          return this.ContentRootPath.collection('samplesitearticles')
        }
        else if(this.NonGroupRoute){
         return this.userLoggedIn ? this.ContentRootPath.collection('sitearticles') : db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('sitearticleswiki') 
        }
        else{
          return this.ContentRootPath.collection('grouparticles').where('groupid','==',this.$route.params.id)
          //.where('GuestsIncluded','==',true)
        }
        
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
        RelatedArticles () {
            return this.grouparticles.map(article => {
              if(article.PublishDate){
               article.pubdate = this.TimestampFormatterSTRING(article.PublishDate.toDate()) 
              }
              else if(article.pubDate){
                article.pubdate = this.TimestampFormatterSTRING(article.pubDate.toDate()) 
              }
            return article
          }).sort((a, b) => {
            // console.log('sorting again')
            // console.log(a.pubdate)
            var key1 = this.DateFormatter(b.pubdate);
            var key2 = this.DateFormatter(a.pubdate);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
            // .filter(article => {
            //     let articlegroup = this.groups.find(obj => obj.id === article.Groupid)
            //     article.category = articlegroup.GroupCategory
            //     console.log(article.grouptype)
            //     let articlematch = this.Filter.find(obj => obj.Name === article.grouptype)
            //     if(articlematch){
            //         return article
            //     }
            // })
        },

    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleSiteArticleCoverIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {      
        this.UserRecord = this.userLoggedIn
      if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        if(this.UserView && this.UserID){
        this.GetSamples(this.CollectionQuery.where('authorid','==',this.UserID),'grouparticles')
        }
        else{
          this.GetSamples(this.CollectionQuery,'grouparticles')
        }
      }
      else if(this.UserView){
        if(this.UserID === this.UserRecord.id){
          //If I am moderator then I should call all my drafts as well
          if(this.$store.state.UserisGuest){
          let CollectionRef = this.CollectionQuery
          this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
          if(!this.ChannelRestricted || this.userIsAdmin){
          this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
          }
          }
          else{
            let CollectionRef = this.CollectionQuery
            if(!this.ChannelRestricted || this.userIsAdmin){
            this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
            }
            else{
              this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            }
          }
        }       
         else{          
          if(this.$store.state.UserisGuest){
          let CollectionRef = this.CollectionQuery
          this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            if(this.$route.name !== 'GroupPage'){
              if(!this.ChannelRestricted || this.userIsAdmin){
                this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
              }              
            }
            else{
              this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)'))
            }          
          }
          else{
            let CollectionRef = this.CollectionQuery
            this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
          }
        }
      }
      else{
        if(this.UserRecord){
          if(this.TagFilter){          
          let CollectionRef = this.CollectionQuery.where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('DataTagsid','array-contains',this.TagsID)
          this.getGroupArticles(CollectionRef)
        }
        else{
          let CollectionRef = this.CollectionQuery
          if(this.$store.state.UserisGuest && this.$route.name !== 'GroupPage'){
            this.getGroupArticles(CollectionRef.where('PublishStatus','==','Published (Public)'))
            if(!this.ChannelRestricted || this.userIsAdmin){
            this.getGroupArticles(CollectionRef.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))     
            }   
          }
          else{
            if(!this.ChannelRestricted || this.userIsAdmin){
            this.getGroupArticles(CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
            }
            else{
              this.getGroupArticles(CollectionRef.where('PublishStatus','==','Published (Public)'))
            }
          }
        }
          
        }
        else{
          if(this.TagFilter){
          
          let CollectionRef = this.CollectionQuery.where('PublishStatus','==','Published (Public)').where('DataTagsid','array-contains',this.TagsID)
          this.getGroupArticles(CollectionRef)
        }
        else{
          //console.log('calling public')
          let CollectionRef = this.CollectionQuery.where('PublishStatus','==','Published (Public)')
        this.getGroupArticles(CollectionRef)
        }          
        }        
      }
        //console.log(this.UserID)
},
    methods: {
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
      BoxStyle(tab){
        if(tab){
        if(tab.FullHeight){
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'
        }
        }
        else{
          return ''
        }
       },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      BlurWriteup(){
        let elementid = this.tab ? 'articlewriteup-tab-'+this.tab.ElementID : 'articlewriteup'
        let blogcontentelmnt = document.getElementById(elementid)
        this.editedItem.description = blogcontentelmnt.innerHTML
      },
      RefreshDialog(){
        this.dialog = false
        setTimeout(() => {
            this.dialog = true
        }, 20);
      },
      AssignSampleSiteArticleCoverIMG(){
        this.editedItem.coverimage = this.SelectedImage.fileurl
       this.RefreshDialog()
        this.$store.commit('setIMGProp','')
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        // console.log(finaldate)
        return finaldate
      },
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        // console.log(new Date(yearnumber, monthnumber-1, daynumber))
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
         getGroupArticles(CollectionRef) {     
           //console.log('this.CollectionQuery',this.CollectionQuery)
           CollectionRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.grouparticles.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     Activatedialog(){
        this.dialog = true
      },
      OpenEditdialog() {
        this.dialog = true
        setTimeout(() => {          
        let elementid = this.tab ? 'articlewriteup-tab-'+this.tab.ElementID : 'articlewriteup'
        let blogcontentelmnt = document.getElementById(elementid)
        console.log('blogcontentelmnt',blogcontentelmnt)
        blogcontentelmnt.innerHTML = this.editedItem.description
        }, 300);
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
      ToggleEdit(item,dialprop){
      if(this.$store.state.TemplateView && this.Editor && this.UserisTemplateOwner){
        this.editedItem = Object.assign({},item)
        this.OpenEditdialog()
      }
      else if(this.$store.state.TemplateView && !this.Editor){
        //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
        this.OpenAboutDialog(dialprop)
      }
      
    } ,  
      OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
    
    Closedialog() {
        this.dialog = false
        this.editedItem = Object.assign({},this.defaultItem)
        this.selectedFile = null
    },
      save() {
           if(this.$refs.form.validate()) {  
            let Creator = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
            let MaskedUser = ''
            if(this.$store.state.TemplateView && this.editedItem.Creator){
              Creator = {Full_Name: this.editedItem.Creator.Full_Name,id: this.editedItem.Creator.id,Name: this.editedItem.Creator.Name,Surname: this.editedItem.Creator.Surname}
              MaskedUser = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
            }
            let NewArticle = {
                Likes: [],
                Shares: [],
                tags: [],
                caption: this.editedItem.caption,
                title: this.editedItem.title,
                Creator: Creator,          
                Creatorid: Creator.id,
                creatorname: Creator.Full_Name,
                description: this.editedItem.description,
                
                Comments: [],
                coverimage: this.editedItem.coverimage,
                moderatorrolesarrayDBRules: {},
                moderatorrolesarrayQuery: [],
              } 
              if(MaskedUser){
                NewArticle.MaskedUser = MaskedUser
                NewArticle.MaskedUserid = MaskedUser.id
              }
              NewArticle.author = NewArticle.creatorname
               if(this.groupid){
                  NewArticle.groupid = this.groupid
                }
                if(!NewArticle.groupid && vm.userLoggedIn && vm.userLoggedIn.Company){
                  NewArticle.Company = vm.userLoggedIn.Company
                  NewArticle.Companyid = vm.userLoggedIn.Companyid
                }
                  
                if(Creator.Profile_Photo){
                  NewArticle.creatorimg = Creator.Profile_Photo
                }
              this.$store.dispatch('CreateSearchTitle',NewArticle.title).then(searchtitle => {
              NewArticle.titleQuery = searchtitle
                  if(this.editedItem.id){
                    this.CollectionRef.doc(this.editedItem.id).set(NewArticle).then(() => {
                        this.Closedialog()
                        })
                  }
                  else{
                    NewArticle.createdon = new Date()
                    NewArticle.PublishDate = NewArticle.createdon
                    this.CollectionRef.add(NewArticle).then(() => {
                        this.Closedialog()
                        })
                  }
              })
                
                }
      }
      },


}
</script>