
          <template>

<v-card :dark="AppisDarkMode" flat tile class="red">
<v-breadcrumbs style="z-index:3;" v-if="Ready" :dark="AppisDarkMode" :class="AppisDarkModeCard"  :items="items" divider="-"></v-breadcrumbs>
</v-card>
</template>

<script>
import router from '@/router/index.js'
import { setTimeout } from 'timers'

export default {
props: ['CurrentRecordName','AppisDarkMode','AppisDarkModeCard','System','SystemEntities'],
    data() {
        return{
        Ready: true,
          items: [],
          defaultitems: [],
        }
    },


    watch:{
        '$route' (){
            this.GetNavigation()
        },
        CurrentRecordName (value){
            if(value){
                this.GetNavigation()
            }
        }
    },

    created() {
        this.GetNavigation()
    },
    methods: {

       GetNavigation: function (){
           //console.log('it changed')
            this.items = []
           this.items = Object.assign([],this.defaultitems)
          setTimeout(() => {

        let checkingurl = this.$route.path
         //console.log(checkingurl)
           if(checkingurl === '/BusinessUnits'){
               let NewItem = {
                   text: 'BusinessUnits',
                    disabled: false,
                    to: '/BusinessUnits',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/BusinessUnit/'+this.$route.params.id) {


            let NewItem = {
                   text: 'BusinessUnits',
                    disabled: false,
                    to: '/BusinessUnits',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/BusinessUnit/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }else if(checkingurl === '/Users'){
               let NewItem = {
                   text: 'Users',
                    disabled: false,
                    to: '/Users',
               }
               this.items.push(NewItem)
           }
            else if(checkingurl === '/User/'+this.$route.params.id) {


            let NewItem = {
                   text: 'Users',
                    disabled: false,
                    to: '/Users',
               }
               this.items.push(NewItem)

             let NewItem2 = {
                   text: this.CurrentRecordName,
                    disabled: false,
                    to: '/User/'+this.$route.params.id,
               }
               this.items.push(NewItem2)
            }
            else{
                this.SystemEntities.map(entity => {
                    if(checkingurl === '/'+entity.DisplayName.split(' ').join('')){
                        let NewItem = {
                            text: entity.DisplayName,
                            disabled: false,
                            to: '/'+entity.DisplayName.split(' ').join(''),
                        }
                        let oncheck = this.items.find(obj => obj.to === NewItem.to)
                            if(!oncheck){
                                this.items.push(NewItem) 
                            }
                    }
                    else if(checkingurl === '/'+entity.SingleName.split(' ').join('')+'/'+this.$route.params.id) {


                    let NewItem = {
                            text: entity.DisplayName,
                            disabled: false,
                            to: '/'+entity.DisplayName.split(' ').join(''),
                        }
                    let oncheck = this.items.find(obj => obj.to === NewItem.to)
                    if(!oncheck){
                        this.items.push(NewItem) 
                    }

                    let NewItem2 = {
                        text: this.CurrentRecordName,
                            disabled: false,
                            to: '/'+entity.SingleName.split(' ').join('')+'/'+this.$route.params.id,
                    }
                    let oncheck2 = this.items.find(obj => obj.to === NewItem2.to)
                        if(!oncheck2){
                            this.items.push(NewItem2) 
                        }
                    }
                })
            }

        }, 500)
       }
       }
    }

</script>

<style>
.fixed {
position: fixed;
}
</style>
    