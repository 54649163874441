<template>
  <v-main>
    <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[POPDFFunctionData]"		:PassedFunction="POPDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
    <v-dialog style="z-index:10 !important;" max-width="800" v-model="NEWPODialog" v-if="SelectedWarehouse && SelectedSupplier">
      	
      <v-card id="NewPOElement">
        <v-card-title class="elevation-3 recordtoolbar white--text justify-center">New PO Review
        </v-card-title>
        <v-card-text>
        <v-card-text>
          <v-row class="justify-space-between"
            no-gutters
          >
            <v-col cols="6" style="align-self: center;"
            >
             <v-img width="80%"             
                contain
                src="@/assets/logo.png">
              </v-img>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title>
                    Bill To
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
             <v-list-item dense v-html="ComputedPOObject.PO_Bill_To_Address_Text">
              </v-list-item>
            </v-col>
            <v-col cols="6" >
               <v-card-title class="mediumoverline"> PO Number here
                  </v-card-title>
                <v-list class="background">
                 
                   <v-list-item dense v-for="field in ComputedPORightHeaderFields" :key="field.itemObjKey">
                      <v-list-item-content  :style="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address' ? 'height: 350px;padding-left: 5px;' : 'height: 100%;padding-left: 5px;'">
                          {{field.DisplayName}}
                      </v-list-item-content>
                      <!-- <v-divider></v-divider> -->
                      <v-list-item-content  style="padding-left: 5px;justify-self: end;" v-if="field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'">
                       
                      <FieldViewerComponent  :field="field" :Record="ComputedPOObject"
                      />
                      </v-list-item-content>
                      <v-list-item-content class="background" v-if="field.CommonFieldType === 'Physical Address' || field.CommonFieldType === 'Postal Address'">
                         
                    <v-list class="transparent mx-4" outlined>
                        <v-list-item dense v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                <v-list-item-content class="configtext--text">
                                {{breakdownfield.DisplayName}}:
                                </v-list-item-content>
                                 <v-list-item-content class="datatext--text">
                                {{ComputedPOObject[breakdownfield.Name]}}
                                </v-list-item-content>
                             
                            </v-list-item>
                    </v-list>
                    <br v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                  <v-list-item  v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(field.FieldBreakdown)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                      </v-list-item-content>
                      
                  </v-list-item>
                    <v-list-item dense>
                <v-switch @change="RefreshDialog()" :disabled="CollectionorDeliveryDisabled" v-model="OrderforDelivery" label="Delivery?" />
              </v-list-item>
                </v-list>
            </v-col>
          </v-row>
        
        </v-card-text>
        <v-card-text>
           <v-row v-if="ComputedPOObject.Supplier"
            no-gutters
          >
            <v-col>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Vendor
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ComputedPOObject.Supplier.Supplier_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- {{SelectedSupplier.Invoice_Address_Country_Region}} -->
             <v-list-item v-if="SelectedSupplier" dense v-html="ComputedPOObject.Supplier_Invoice_Address_Text">
              </v-list-item>
            </v-col>
            <v-col >
            
              <v-list-item v-if="OrderforDelivery">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Ship To:
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{SelectedWarehouse.Company_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                <v-list-item v-if="OrderforDelivery" dense v-html="ComputedPOObject.PO_Delivery_Address_Text">
              </v-list-item>
              <v-list-item v-if="!OrderforDelivery">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    For Collection at:
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{SelectedSupplier.Supplier_Name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                <v-list-item v-if="!OrderforDelivery" dense v-html="ComputedPOObject.Supplier_Collection_Address_Text">
              </v-list-item>
            </v-col>
           </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <!-- hide-default-footer -->
        <!-- <v-card-text>
          <span  v-html="ComputedNewPOLineItemsTable">
          </span>
        </v-card-text> -->
        <v-card-text style="padding:30px;border: 1px solid grey;">
          <v-form ref="polineitemsform">
          <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="POLineHeadersforTable"
      :items="ComputedNewPOLineItems"
      
     
     class="transparent"
      >
      <template v-slot:[`item.Qty`]="{ item }">
        <v-text-field style="font-size:13px;" :rules="PRQuantityRules(item)" v-model.number="item.Qty"  type="number"/>
      </template>
      <template v-slot:[`item.Price`]="{ item }">
        <v-text-field style="font-size:13px;" :value="CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)" v-model.number="item.Price" :label="CurrencyFormatter(item.Last_Quoted_Price,$store.state.DefaultCurrency.Currency)+' (Q)'"  type="number"/>
      </template>
      <template v-slot:[`item.Delivery_Date`]="{ item }">
        {{item.Delivery_Date.toDateString()}}
         <!-- <v-menu 
            v-model="item.Delivery_DateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            >
            <template v-slot:activator="{ on }">
                <v-text-field class="smallinput"
                label="Delivery Date"
                prepend-icon="mdi-calendar-month"
                readonly  :rules="[]"
                v-on="on"
                v-model="item.Delivery_Date_Input"
                ></v-text-field>
                </template>
            <v-date-picker  v-model="item.Delivery_Date_Input"
                    @input="item.Delivery_DateMenu = false"></v-date-picker>
            </v-menu> -->
      </template>
      <template v-slot:[`item.Sub_Total`]="{ item }">
         {{CurrencyFormatter(item.Sub_Total,$store.state.DefaultCurrency.Currency)}}
      </template>
          </v-data-table>
          </v-form>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-text>
           <v-row
            no-gutters
            style="padding-top: 10px;"
          >
            <v-col cols="6">
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Notes
                  </v-list-item-subtitle>
                  <span class="caption" v-html="ComputedPOObject.Footer_Note">
                  </span>
                </v-list-item-content>
              </v-list-item>
           
            </v-col>
            <v-col cols="6">
              <v-list-item  dense>
                <v-list-item-content>
                <v-list-item-subtitle>
                 SubTotal
                </v-list-item-subtitle>
                </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(ComputedPOObject.Sub_Total,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense>
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Discount ({{SelectedSupplier.Default_Discount}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(DiscountValue,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="ComputedPOObject.Delivery">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Freight
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(ShippingTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="SelectedSupplier.Tax_Percentage">
                 <v-list-item-content>
                <v-list-item-subtitle>
                 Tax ({{SelectedSupplier.Tax_Percentage}}%)
                </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-content>
                   {{CurrencyFormatter(TaxTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="border: 2px solid black;">
                 <v-list-item-content>
                <v-list-item-title>
                  Total:
                </v-list-item-title>
                 </v-list-item-content>
                <v-list-item-content>
                  {{CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency)}}
                </v-list-item-content>
              </v-list-item>
            </v-col>
           </v-row>
        </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelProcessNewOrder()" dark color="warning">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
           <v-btn dark class="blue" @click="DownloadPO(true)">
            <v-icon>mdi-download</v-icon>Download
          </v-btn>
          <v-btn dark class="success" @click="ProcessPO()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-card class="transparent" style="margin-top:60px;" flat tile>
          <v-card-title class="recordtoolbar white--text largeoverline">
              Open Purchase Requisitions
          </v-card-title>
      <v-layout class="justify-center" row style="margin-top:60px;">
     <v-flex lg10 md10>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       <v-toolbar-title>Purchase Requisitions</v-toolbar-title>
      
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        {{$route.params.id ? `Select Supplier
       > Create PO` : `Select WH
       > Select Supplier
       > Create PO`}}
        <v-spacer></v-spacer>
        <v-text-field 
          v-model="prsearch"
          append-icon="mdi-file-find-outline"
          label="Search PR"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="prsearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
          <!-- <v-dialog v-model="WarehouseDialog" width="400">
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="recordtoolbar white--text">
                New Warehouse
              </v-card-title>
              <v-card-text>
                <FieldsForm :FormFields="OpenPRHeaders" :Validating="Validating" :DataTableView="true" @save="SaveWarehouse" :DataObject="editedWarehouse" :tab="{}" />
               
              </v-card-text>
               <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="CloseWarehouseDialog()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
     </v-toolbar>
     <v-toolbar rounded dark color="recordtoolbar darken-1">
       
       <v-list-item dense>
         <v-list-item-content>
            <v-select :disabled="$route.params.id" :items="WarehousesArray" @change="ClearSelectedPrLineItems()" clearable item-text="Name" return-object label="Warehouse" v-model="SelectedWarehouse"
       />
         </v-list-item-content>
         <v-list-item-content>
            <v-select :items="SelectableSuppliers" @change="ClearSelectedPrLineItems(),CheckForSupplier()" clearable item-text="Supplier_Name" return-object label="Supplier" v-model="SelectedSupplier"
       />
         </v-list-item-content>
         <v-list-item-action>
          <v-btn :disabled="!EnableSelect || SelectedPRLineItems.length === 0" @click="PrepareNewPOObject()" >Create PO</v-btn>
         </v-list-item-action>
       </v-list-item>      
     </v-toolbar>
     </v-flex>
   </v-layout>
    <v-layout class="justify-center" row>
     <v-flex lg10 md10>
       <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="OpenPRHeaders"
      :items="OpenPRsSearched"
      :show-select="EnableSelect"      
      item-key="id"
      v-model="SelectedPRLineItems"
     
     :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
      >
       <!-- <template v-slot:[`item.Warehouse.Name`]="{ item }">
        <v-btn outlined fab small :to="'/Warehouse/'+item.Warehouse.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.Warehouse.Name}}
      </template> -->
      <template v-slot:[`item.StockObj.Item_Name`]="{ item }">
        <v-btn outlined fab small :to="'/'+item.StockObj.single.split(' ').join('')+'/'+item.StockObj.id" class="elevation-6 accent--text"><v-icon small >mdi-warehouse</v-icon></v-btn>
        {{item.StockObj.Item_Name}}
      </template>
      <template v-slot:[`item.Sales_Order.Name`]="{ item }">
        <v-btn outlined fab small :to="item.Sales_Order.Path ? item.Sales_Order.Path : ''" class="elevation-6 accent--text"><v-icon small >mdi-cash-register</v-icon></v-btn>
        {{item.Sales_Order.Name}}
      </template>
      <template v-slot:[`item.Supplier.Supplier_Name`]="{ item }">
        <v-btn outlined fab small :to="'/StoreSupplier/'+item.Supplier.id" class="elevation-6 accent--text"><v-icon small >mdi-factory</v-icon></v-btn>
        {{item.Supplier.Supplier_Name}}
      </template>
       <!-- <template v-slot:[`item.actions`]="{ item }">
     <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item.CanEdit">
       
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item.CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>         
      </template>-->
       </v-data-table>
      </v-flex>
  </v-layout>
      </v-card>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
export default {
    props: ['System','SystemEntities'],
    components: {FieldViewerComponent,PDFExporter},
    data() {
        return {
          NewPO_Number: '',
          PDFDownloadOnly: false,
          ExportingtoPDFDialog: false,
          rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
          },
          Bill_To_Address: {Name: 'Bill_To_Address',FieldBreakdown: []},
          Delivery_Address: {Name: 'Delivery_Address',FieldBreakdown: []},
          Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
          Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
          PORightHeaderFields: [
            {Name: 'Created_On',DisplayName: 'PO Date',Type: 'Date'},
            {Name: 'Payment_Terms',DisplayName: 'Payment Terms',text: 'Payment Terms',value: 'Payment_Terms',Type: 'Single Line Text'}, 
            {Name: 'Shipping_Method',DisplayName: 'Shipping Method',text: 'Shipping Method',value: 'Shipping_Method',Type: 'Option Set',Options:[{ID: 1000001,Name: 'FOB'}]},
            {Name: 'Promised_Date',DisplayName: 'Promised Date',text: 'Promised Date',value: 'Promised_Date',Type: 'Date'},
          ],
          NewPOObject: {Supplier: {},Footer_Note: `<p>The amount of the Purchase Order is the agreed fixed price and shall not be exceeded without advanced written consent from the Bill To contact identified on the Order</p>`},
          DefaultNewPOObject: {Supplier: {},Footer_Note: `<p>The amount of the Purchase Order is the agreed fixed price and shall not be exceeded without advanced written consent from the Bill To contact identified on the Order</p>`},
          NewPOLineItems: [],
          NEWPODialog: false,
            prsearch: '',
            POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Warehouse',Type: 'Lookup',LookupFieldName: 'Name',propvalue: 'Warehouse',value: 'Warehouse.Name',class: 'overline',TableHidden: true},
              {text: 'Item',propvalue: 'StockObj',value: 'StockObj.Item_Name',Type: 'Lookup',LookupFieldName: 'Item_Name',class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Delivery Date',propvalue: 'Delivery_Date',value: 'Delivery_Date',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',propvalue: 'Sales_Order',value: 'Sales_Order.Name',class: 'overline',TableHidden: true},
            ],
            OpenPRHeaders: [
              {text: 'Warehouse',value: 'Warehouse.Name',class: 'overline'},
              {text: 'Stock Obj',value: 'StockObj.Item_Name',class: 'overline'},
              {text: 'Qty',value: 'Qty',class: 'overline'},
              {text: 'Price',value: 'Price',class: 'overline'},
              {text: 'Supplier',value: 'Supplier.Supplier_Name',class: 'overline'},
              {text: 'Outstanding Qty',value: 'Outstanding_Qty',class: 'overline'},
              {text: 'Sales Order',value: 'Sales_Order.Name',class: 'overline'}
            ],
            PurchaseRequisitions: [],
            SelectedPRLineItems: [],
            SelectedWarehouse: '',
            SelectedSupplier: '',
            OrderforDelivery: true,
            PDFExportFunctionData: ''
        }
    },
    computed:{
      PDFPages(){
        return this.POPDFFunctionData.Pages
      },
      POPDFFunctionData(){
        let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //ComputedNewPOLineItemsTable
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'ComputedNewPOLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.ComputedNewPOLineItemsTable
        newelemnt.Justify = 'center'
        if(this.ComputedNewPOLineItems.length <= 3){
          newelemnt.PaddingTop = 450
        }
        else if(this.ComputedNewPOLineItems.length <= 6){
          newelemnt.PaddingTop = 425
        }
        else if(this.ComputedNewPOLineItems.length <= 9){
          newelemnt.PaddingTop = 400
        }
        else if(this.ComputedNewPOLineItems.length <= 12){
          newelemnt.PaddingTop = 375
        }
        else {
          newelemnt.PaddingTop = 350
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata
      },
      PDFPOData(){
        let sampleobj = JSON.parse(JSON.stringify(this.ComputedPOObject))
        console.log('sampleobj',sampleobj.Created_On)
        sampleobj.SubCollections = []
        // this.PDFPages.map(page => {
          // if(page.Elements){
          //   page.Elements.map(elmnt => {
          //     //console.log('elmnt',elmnt)  
          //     if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type !== 'Option Set' && elmnt.DataField.Type !== 'Lookup' && elmnt.DataField.Type !== 'Radio Group'){
          //       sampleobj[elmnt.DataField.Name] = elmnt.DataField.DisplayName
          //     }
          //     else if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type === 'Option Set'){
          //       sampleobj[elmnt.DataField.Name] = {ID: 1000001, Name: elmnt.DataField.DisplayName}
          //     }
          //     //console.log('sampleobj',sampleobj)
          //   })
          // }
        // })
        return sampleobj
      },
      POLineHeadersforTable(){
        return this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
      },
      ComputedNewPOLineItemsTable(){
        let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;padding:5px;border: 1px solid grey;">
                    <tr>`
        this.POLineHeadersforTable.map(head => {
          html = html+`
          <th class="overline" style="text-align: left;">`+head.text+`</th>`
        })
        html = html+`
        </tr>`
        this.ComputedNewPOLineItems.map(lineitem => {
          html = html+`
          <tr class="detailslistoutline">`
          this.POLineHeadersforTable.map((head,headnindex) => {
            console.log(head.propvalue,lineitem,lineitem[head.propvalue])
            let value = lineitem[head.propvalue]
            if(head.Type === 'Number Field' && head.IsCurrency){
              value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
            }
            else if(head.Type === 'Date' && !head.HasTimeInput){
              value = value.toDateString()
            }
            else if(head.Type === 'Lookup'){
              value = value[head.LookupFieldName]
            }
            html = html+`
            <td style="text-align: left;">`+value+`</td>`
            if(headnindex-1+2 === headerslength){
              html = html+`
              </tr>`
            }
          })
        })
        html = html+`
        </table>`
        return html
      },
      ComputedNewPOLineItems(){
        let arr = this.NewPOLineItems.map((item,index) => {
          if(typeof item.Delivery_DateMenu === 'undefined'){
            item.Delivery_DateMenu = false
          }
          if(typeof item.Delivery_Date_Input === 'undefined'){
            item.Delivery_Date_Input = item.Delivery_Date.toISOString().substring(0,10)
                     
          }
          
          item.Nr = index-1+2
          return item
        }).map(item => {
          item.Delivery_Date = new Date(item.Delivery_Date_Input)    
          item.Sub_Total = Number(item.Qty)*Number(item.Price)
          return item
        })
        return arr
        //.concat(arr,arr,arr,arr)
      },
      CollectionorDeliveryDisabled(){
        let disabled = false
        if(this.SelectedSupplier.Collection){
          disabled = true
        }
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(typeof this.ComputedPOObject[newname] === 'undefined'){
            disabled = true
          }
        })
        return disabled
      },
      POSubTotal(){
        let total = this.NewPOLineItems.map(polineitem => {
          return polineitem.Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      POFinaldeliveryDate(){
         let sorteddates = this.ComputedNewPOLineItems.map(polineitem => {
          return polineitem.Delivery_Date
          }).sort((a, b) => {
              var key1 = a.TimestampSort;
              var key2 = b.TimestampSort;

              if (key1 < key2) {
                  return -1;
              } else if (key1 == key2) {
                  return 0;
              } else {
                  return 1;
              }
        })
        return sorteddates[this.NewPOLineItems.length-1]
      },
      ComputedSOLineItems(){
        return this.SelectedPRLineItems.map(prlineitem => {
          let solineobj = prlineitem.LookupObj
          solineobj.Sales_Orderid = solineobj.id
          delete solineobj.id
          solineobj.Purchase_Requisitionid = prlineitem.Transaction_Docid
          solineobj.PRLine_Itemid = prlineitem.id
          solineobj.Qty = prlineitem.Qty
          solineobj.Outstanding_Qty = prlineitem.Outstanding_Qty
          solineobj.Open_Allocations = prlineitem.Open_Allocations
          solineobj.Created_On = prlineitem.Created_On
          solineobj.Modified_On = prlineitem.Modified_On
          solineobj.Warehouse = prlineitem.Warehouse
          solineobj.Warehouseid = prlineitem.Warehouseid
          solineobj.StockObj = prlineitem.StockObj

          return solineobj
        })
      },
      ComputedPOObject(){
        let POObject = {}
        if(this.NEWPODialog){
          if(this.OrderforDelivery){
          POObject.Delivery = true
        }
        else{
          POObject.Delivery = false
        }
        let capass = true
        POObject.PO_Number = 'PO Order #0000001'
        if(this.NewPO_Number){
          POObject.PO_Number = this.NewPO_Number
        }
        POObject.Progress = {ID: 1000001,Name: 'Draft'}
        POObject.RecordPrimaryFieldName = 'PO_Number'
        POObject.Company_Name = this.SelectedWarehouse.Company_Name
        this.Collection_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Collection_Address_').join('Supplier_Collection_Address_')
          if(this.SelectedSupplier[brd.Name]){
            POObject[newname] = this.SelectedSupplier[brd.Name]
          }
          else{
            capass = false
          }
        })
        let dapass = true
        this.Delivery_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Delivery_Address_').join('PO_Delivery_Address_')
          if(this.SelectedWarehouse[brd.Name]){
            POObject[newname] = this.SelectedWarehouse[brd.Name]
          }
          else{
            dapass = false
          }
        })
        let btapass = true
        this.Bill_To_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Bill_To_Address_').join('PO_Bill_To_Address_')
          let brdname = brd.Name.split('Bill_To_Address_').join('Address_')
          if(this.SelectedBusinessUnit[brdname]){
            POObject[newname] = this.SelectedBusinessUnit[brdname]
          }
          else{
            btapass = false
          }
        })
        let iapass = true
        this.Invoice_Address.FieldBreakdown.map(brd => {
          if(this.SelectedSupplier[brd.Name]){
            let newname = brd.Name.split('Invoice_Address_').join('Supplier_Invoice_Address_')
            POObject[newname] = this.SelectedSupplier[brd.Name]
          }
          else{
            iapass = false
          }
        })
        POObject.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Created_Byid = this.userLoggedIn.id
        POObject.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        POObject.Modified_Byid = this.userLoggedIn.id
        POObject.Created_On = new Date()
        POObject.Modified_On = new Date()
        POObject.Supplier = {Supplier_Name: this.SelectedSupplier.Supplier_Name,id: this.SelectedSupplier.id}
        POObject.Supplierid = this.SelectedSupplier.id
        if(iapass){
        POObject.Supplier_Invoice_Address_Text = ``+POObject.Supplier_Invoice_Address_Street_Number+` `+POObject.Supplier_Invoice_Address_Street_Name+`, `+POObject.Supplier_Invoice_Address_Suburb+`, `+POObject.Supplier_Invoice_Address_Postal_Code+`<br>
        `+POObject.Supplier_Invoice_Address_City+`, `+POObject.Supplier_Invoice_Address_State_Province+`,<br>
        `+POObject.Supplier_Invoice_Address_Country_Region  
        }
        if(capass){        
        POObject.Supplier_Collection_Address_Text = ``+POObject.Supplier_Collection_Address_Street_Number+` `+POObject.Supplier_Collection_Address_Street_Name+`, `+POObject.Supplier_Collection_Address_Suburb+`, `+POObject.Supplier_Collection_Address_Postal_Code+`<br>
        `+POObject.Supplier_Collection_Address_City+`, `+POObject.Supplier_Collection_Address_State_Province+`,<br>
        `+POObject.Supplier_Collection_Address_Country_Region
        }
        if(dapass){
        POObject.PO_Delivery_Address_Text = ``+POObject.PO_Delivery_Address_Street_Number+` `+POObject.PO_Delivery_Address_Street_Name+`, `+POObject.PO_Delivery_Address_Suburb+`, `+POObject.PO_Delivery_Address_Postal_Code+`<br>
        `+POObject.PO_Delivery_Address_City+`, `+POObject.PO_Delivery_Address_State_Province+`,<br>
        `+POObject.PO_Delivery_Address_Country_Region
        }
        if(btapass){        
        POObject.PO_Bill_To_Address_Text = ``+POObject.PO_Bill_To_Address_Street_Number+` `+POObject.PO_Bill_To_Address_Street_Name+`, `+POObject.PO_Bill_To_Address_Suburb+`, `+POObject.PO_Bill_To_Address_Postal_Code+`<br>
        `+POObject.PO_Bill_To_Address_City+`, `+POObject.PO_Bill_To_Address_State_Province+`,<br>
        `+POObject.PO_Bill_To_Address_Country_Region
        }
        POObject.Warehouse = {Name: this.SelectedWarehouse.Name,id: this.SelectedWarehouse.id}
        POObject.Warehouseid = this.SelectedWarehouse.id
        POObject.Business_Unit = {Name: this.SelectedWarehouse.Business_Unit.Name, id: this.SelectedWarehouse.Business_Unitid}
        POObject.Business_Unitid = this.SelectedWarehouse.Business_Unitid
        POObject.Footer_Note = `<p>The amount of the Purchase Order is the agreed fixed price and shall not be exceeded without advanced written consent from the Bill To contact identified on the Order</p>`
        POObject.Sub_Total = this.POSubTotal  
        POObject.Supplier_Account_Type = this.SelectedSupplier.Billing_Type
        //alert(this.SelectedSupplier.Billing_Type.Name)
        POObject.Payment_Terms = ''
        if(this.SelectedSupplier.Billing_Type && this.SelectedSupplier.Billing_Type.Name === 'COD'){
          POObject.Payment_Terms = 'COD'
          POObject.Payment_Period = 0
        }
        else if(this.SelectedSupplier.Billing_Type && this.SelectedSupplier.Billing_Type.Name === 'Credit'){          
          POObject.Payment_Period = this.SelectedSupplier.Days_for_Payment
          POObject.Payment_Terms = 'Net '+this.SelectedSupplier.Days_for_Payment
        }
        POObject.Supplier_Account_Type = this.SelectedSupplier.Billing_Type
        POObject.Shipping_Method = {ID: 1000001,Name: 'FOB'}
        POObject.Promised_Date = this.POFinaldeliveryDate
        POObject.Tax_Total = this.TaxTotal
        POObject.Tax_Percentage = this.TaxPercentage
        POObject.Discount_Total = this.DiscountValue
        POObject.Freight_Total = this.ShippingTotal
        if(typeof POObject.Delivery === ' undefined'){
          POObject.Delivery = !this.SelectedSupplier.Collection
        }
        POObject.Grand_Total = this.GrandTotal
        
        return POObject
        }
        else{
          return ''
        }
        
      },
      ComputedPORightHeaderFields(){
        return this.OrderforDelivery ? this.PORightHeaderFields : this.PORightHeaderFields.filter(field => {
          return field.Name !== 'Shipping_Method'
        })
      },
      GrandTotal(){
        let total = this.POSubTotal
        if(this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        if(this.TaxTotal !== 0){
          total = total+this.TaxTotal
        }
        return Number(total.toFixed(2))
      },
      ShippingTotal(){
        if(this.SelectedSupplier.Collection){
          return 0
        }
        else{
          return 0
        }
      },
      TaxPercentage(){
        return this.SelectedSupplier && typeof this.SelectedSupplier.Tax_Percentage !== 'undefined' ? this.SelectedSupplier.Tax_Percentage : 0
      },
      TaxTotal(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          if(this.SelectedSupplier.Tax_Percentage !== 0){            
            let total = this.POSubTotal*(this.SelectedSupplier.Tax_Percentage/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      DiscountValue(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          if(typeof this.SelectedSupplier.Default_Discount !== 'undefined' && this.SelectedSupplier.Default_Discount !== 0){
            let total = this.POSubTotal*(this.SelectedSupplier.Default_Discount/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
      TextSupplierInvoiceAddress(){        
        return this.SelectedSupplier && this.SelectedSupplier.id ? ``+this.SelectedSupplier.Invoice_Address_Street_Number+` `+this.SelectedSupplier.Invoice_Address_Street_Name+`, `+this.SelectedSupplier.Invoice_Address_Suburb+`, `+this.SelectedSupplier.Invoice_Address_Postal_Code+`<br>
        `+this.SelectedSupplier.Invoice_Address_City+`, `+this.SelectedSupplier.Invoice_Address_State_Province+`,<br>
        `+this.SelectedSupplier.Invoice_Address_Country_Region : ''
      },
      TextBillToAddress(){        
        return this.ComputedPOObject? ``+this.ComputedPOObject.PO_Bill_To_Address_Street_Number+` `+this.ComputedPOObject.PO_Bill_To_Address_Street_Name+`, `+this.ComputedPOObject.PO_Bill_To_Address_Suburb+`, `+this.ComputedPOObject.PO_Bill_To_Address_Postal_Code+`<br>
        `+this.ComputedPOObject.PO_Bill_To_Address_City+`, `+this.ComputedPOObject.PO_Bill_To_Address_State_Province+`,<br>
        `+this.ComputedPOObject.PO_Bill_To_Address_Country_Region : ''
      },
      TextDeliveryAddress(){        
        return ``+this.ComputedPOObject.PO_Delivery_Address_Street_Number+` `+this.ComputedPOObject.PO_Delivery_Address_Street_Name+`, `+this.ComputedPOObject.PO_Delivery_Address_Suburb+`, `+this.ComputedPOObject.PO_Delivery_Address_Postal_Code+`<br>
        `+this.ComputedPOObject.PO_Delivery_Address_City+`, `+this.ComputedPOObject.PO_Delivery_Address_State_Province+`,<br>
        `+this.ComputedPOObject.PO_Delivery_Address_Country_Region
      },
      SelectableSuppliers(){
        if(this.SelectedWarehouse){
          return this.OpenPRsSearched.map(pr => {
            let supplobj = pr.Supplier
            return supplobj
          })
        }
        else{
          return []
        }
      },
      EnableSelect(){
        return this.SelectedWarehouse && this.SelectedWarehouse.id && this.SelectedSupplier && this.SelectedSupplier.id
      },
      SelectedBusinessUnit(){
        return this.SelectedWarehouse && this.SelectedWarehouse.id ? this.$store.state.BusinessUnitsArray.find(obj => obj.id === this.SelectedWarehouse.Business_Unitid) : ''
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        OpenPRsSearched(){
          return this.OpenPurchaseRequisitions.filter(pr => {
            if(this.SelectedWarehouse){
              return pr.Warehouseid === this.SelectedWarehouse.id
            }
            else{
              return pr
            }
          }).filter(pr => {
            if(this.SelectedSupplier){
              return pr.Supplierid === this.SelectedSupplier.id
            }
            else{
              return pr
            }
          })
        },
        OpenPurchaseRequisitions(){
          return this.PurchaseRequisitions.map(pr => {
            let so = {Name: ''}
            let today = new Date()
            if(pr.LookupObj){
              so.Name = pr.LookupObj[pr.LookupObj.identifier]
              so.Path = '/'+pr.LookupObj.single.split(' ').join('')+'/'+pr.LookupObj.id
            }
            pr.Sales_Order = so
            if(!pr.Supplier){
              pr.Supplier = pr.Preferred_Supplier
              pr.Supplierid = pr.Preferred_Supplier.id
            }
            if(!pr.Price){
              pr.Price = pr.Last_Quoted_Price
            }
            if(pr.Purchase_Leadtime){
              pr.Delivery_Date = new Date(today.getFullYear(),today.getMonth(),today.getDate()+pr.Purchase_Leadtime)
            }
            
            return pr
          })
        },
        WarehousesArray(){
          return this.$store.state.WarehousesArray
        }
    },
    created(){
      this.GetPOPDFFunction()
      this.GetPurchaseRequisitions()
      
      this.PrepareAddressField('Bill_To_Address')
      this.PrepareAddressField('Delivery_Address')
      this.PrepareAddressField('Invoice_Address')
      this.PrepareAddressField('Collection_Address')
    },
    methods:{
      QuickAssignAutoNumberMethod(newstringprefix,nrstring,docdata,index){    
          return new Promise(function(resolve, reject) {
          let finalstring = ''
          let nrstring = '0'
            if(docdata.NumberingString){
              nrstring = docdata.NumberingString
            }      
          let newnr = index-1+2
          let newnrlength = newnr.toString().length
          let newstringcount = docdata.Digits-newnrlength
          for(let x = 0; x < newstringcount; x++){ 
            newstringprefix = newstringprefix+nrstring
            if(x-1+2 === newstringcount){
              finalstring = newstringprefix+newnr.toString()
              resolve({
                Last_Number: newnr,
                Last_Value: finalstring
              })
            }
          }
          })
        },
      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      PRQuantityRules(item){
        return  [ 
              v => !!v || "This field is required",
              v => ( v && v >= item.Min_Qty ) || "Qty should be no less "+item.Min_Qty,
          ]
      },
      DeactivateExportingtoPDFDialog(doc){        
        if(!this.PDFDownloadOnly){
         this.ProcessNewOrder(doc) 
        }        
        this.ExportingtoPDFDialog = false
        this.PDFDownloadOnly = false
      },
      DownloadPO(downloadonly){
        this.ExportingtoPDFDialog = true
        this.PDFDownloadOnly = downloadonly
      },
      ProcessPO(){
        if(this.$refs.polineitemsform.validate()){
          let vm = this
          vm.$store.commit('setCustomProcessingDialog',true)
          vm.$store.commit('setCustomProcessingDialogText','Registering PO')
          this.PDFDownloadOnly = false
          const functions = firebase.functions();
          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
          let payload = {
            Docid: 'Purchase_Orders_PO_Number',
            Data: vm.ComputedPOObject,
            siteid: vm.$store.state.ActiveSuiteid
          }
          AssignAutoNumber(payload).then(result => {
          //console.log(result)
          //console.log(result.data)
          vm.NewPO_Number = result.data
          vm.$store.commit('setCustomProcessingDialogText','Creating PDF')
          vm.DownloadPO(vm.PDFDownloadOnly)
          })
          //AssignAutoNumber function to get new nr assign to "this.NewPO_Number"
          //THEN this.DownloadPO(this.PDFDownloadOnly)  
        }
        else{
          alert('Please check all the Order line values')
        }
        
      },
      
      ProcessNewOrder(doc){
        let vm = this
        vm.$store.commit('setCustomProcessingDialogText','Uploading PDF')
        //console.log(this.NewPOLineItems,this.SelectedPRLineItems,this.ComputedPOObject)
        let NewPOObject = this.ComputedPOObject
        //so actually we need to get autonumber. But first let's create autonumber field, see it's logic and take it from there see if it can be used
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        //console.log(blobpdf,bloburl)
        let storagelocation = 'Purchase_Orders/'+NewPOObject.PO_Number+'/'+new Date()
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(blobpdf);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Preparing Purchase Order')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: NewPOObject.PO_Number
                          }
                          PDF_File.filename = PDF_File.Name
                          NewPOObject.Owner = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                          NewPOObject.Ownerid = vm.userLoggedIn.id,
                          NewPOObject.Latest_PDF_File = PDF_File
                          delete NewPOObject.RecordPrimaryFieldName
                          console.log(NewPOObject,vm.ComputedNewPOLineItems,vm.SelectedPRLineItems,vm.ComputedSOLineItems)
                          // db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('Warehouses').doc(vm.SelectedWarehouse.id).collection('PurchaseOrders').doc(NewPOObject.PO_Number).set(NewPOObject).then(newpodoc => {
                            vm.$store.commit('setCustomProcessingDialogText','Preparing PO Lines')
                            let polineslength = vm.ComputedNewPOLineItems.length
                            //so basically now, we need to generate autonumbers, we got it for PO, and quickassign happens in consume bulk stock
                            //which also means no need for polineitem.id NOt TransactionDoc_id bunch of stuff
                            //however, there is a nagging reminder here, I mean does the entire array finish before bulk upload is assigned?


                            // let AutoNumberConfig = {
                            //     Last_Number: 0,
                            //     Last_Value: '',
                            //     Digits: 4,
                            //     NumberingString: nrstring
                            //   } 
                            // let newstringprefix = NewPOObject.PO_Number+'_'
                            // let nrstring = '0'
                            let finalpolines = vm.ComputedNewPOLineItems.map((polineitem,polineindex) => {
                              
                              
                              // vm.QuickAssignAutoNumberMethod(newstringprefix,nrstring,AutoNumberConfig,polineindex).then(autoresult => {
                              //   let newdocid = autoresult.Last_Value
                              //   polineitem.id = newdocid
                                let prlineitems = polineitem.PurchaseRequisitions
                                delete polineitem.PurchaseRequisitions
                                // polineitem.Transaction_Docid = NewPOObject.PO_Number
                                polineitem.Progress = NewPOObject.Progress
                                polineitem.Modified_By = NewPOObject.Modified_By
                                polineitem.Modified_Byid = NewPOObject.Modified_Byid
                                polineitem.Created_By = NewPOObject.Modified_By
                                polineitem.Created_Byid = NewPOObject.Created_Byid
                                // polineitem.Created_On = new Date()
                                // polineitem.Modified_On = new Date()
                                polineitem.Delivered = 0
                                polineitem.Received = 0
                                polineitem.Returned = 0
                                polineitem.Invoiced = 0
                                // db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('Warehouses').doc(vm.SelectedWarehouse.id).collection('PurchaseOrdersLine_Items').doc(polineitem.id).set(polineitem).then(newsodoc => {
                                  let solineitems = vm.ComputedSOLineItems.filter(soitem => { 
                                    return prlineitems.includes(soitem.PRLine_Itemid) && soitem.Qty > 0
                                  })
                                  polineitem.SalesOrdersLine_Items = solineitems.map((solineitem,solineindex) => {
                                      // solineitem.Transaction_Docid = polineitem.Transaction_Docid
                                      solineitem.Progress = polineitem.Progress
                                      solineitem.Modified_By = polineitem.Modified_By
                                      solineitem.Modified_Byid = polineitem.Modified_Byid
                                      solineitem.Created_By = polineitem.Created_By
                                      solineitem.Created_Byid = polineitem.Created_Byid
                                      solineitem.Created_On = new Date()
                                      solineitem.Modified_On = new Date()
                                      // sollineitem.Line_Itemid = Line_Itemid?????????
                                    // db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('Warehouses').doc(vm.SelectedWarehouse.id).collection('PurchaseOrdersLine_Items').doc(polineitem.id).collection('SalesOrdersLine_Items').doc(solineitem.PRLine_Itemid).set(solineitem).then(newsolinedoc => {
                                    //   if(solineindex-1+2 === solineitems.length && polineindex-1+2 === polineslength){
                                    //       vm.$store.commit('setCustomProcessingDialogText','Finalizing')
                                    //       setTimeout(() => {
                                    //           vm.$store.commit('setCustomProcessingDialog',false)
                                    //           vm.$router.push('/PurchaseOrder/'+vm.SelectedWarehouse.id+'/'+NewPOObject.PO_Number)
                                    //         }, 1000);
                                    //     }
                                    // })
                                    return solineitem
                                  })      
                                                            
                                // })
                              // })
                              return {Data: polineitem}
                            })
                            vm.$store.commit('setCustomProcessingDialogText','Sending to Server')
                            let bulkoutboundpayload = {
                                    Collection: 'PurchaseOrders',
                                    Data: [],
                                    ParentObj: NewPOObject,
                                    Warehouse: NewPOObject.Warehouseid
                                  }
                                  bulkoutboundpayload.Data = finalpolines
                                  console.log('bulkoutboundpayload',bulkoutboundpayload)
                                  let solines = bulkoutboundpayload.Data
                                  .map(poline => {
                                    return poline.Data.SalesOrdersLine_Items
                                  }).flat()
                                  console.log('solines',solines,solines.length)                                  
                                  const functions = firebase.functions();
                                  const ConsumeBulkStockQty = functions.httpsCallable('ConsumeBulkStockQty');
                                  ConsumeBulkStockQty(bulkoutboundpayload).then(result => {
                                    vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
                                      setTimeout(() => {
                                        vm.$store.commit('setCustomProcessingDialog',false)
                                        setTimeout(() => {
                                            vm.$router.push('/PurchaseOrder/'+vm.SelectedWarehouse.id+'/'+NewPOObject.PO_Number)
                                          }, 500)
                                      }, 500);
                                  })
                          //  })
                          
                          //now I got the Po object, but I still need 1. PO Line items, and 2.SO Line items...
                        })
      },
      GetPOPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Purchase_Orders').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.PDFExportFunctionData = functiondata
        })
      },
      ToggleDialog(dialogname){					
      this[dialogname] = !this[dialogname]					
    },		
      RefreshDialog(){
        this.NEWPODialog = false
        setTimeout(() => {
          this.NEWPODialog = true
        }, 10);
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
      
      CancelProcessNewOrder(){
        this.NEWPODialog = false
        this.NewPOLineItems = [] 
      },
      
      ClearSelectedPrLineItems(){
        this.SelectedPRLineItems = []
      },
      CheckForSupplier(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          this.GetSupplierInformation(this.SelectedSupplier.id)
        }
      },
      GetSupplierInformation(supplierid){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('storesuppliers').doc(supplierid).onSnapshot(snapshot => {
          let supplierdata = snapshot.data()
          console.log('supplierdata',supplierdata.Default_Discount,supplierdata.Tax_Percentage,this.Invoice_Address.FieldBreakdown)
          if(supplierdata){
            this.SelectedSupplier.Default_Delivery_Terms = supplierdata.Default_Delivery_Terms
            if(supplierdata.Default_Delivery_Terms && supplierdata.Default_Delivery_Terms.Name === 'Collection'){
              this.SelectedSupplier.Collection = true
              this.OrderforDelivery = false
            }
            this.SelectedSupplier.Billing_Type = supplierdata.Billing_Type
            this.SelectedSupplier.Days_for_Payment = supplierdata.Days_for_Payment
            
            if(typeof supplierdata.Tax_Percentage !== 'undefined'){
              this.SelectedSupplier.Tax_Percentage = Number(supplierdata.Tax_Percentage)
            }
            else{
              this.SelectedSupplier.Tax_Percentage = 0
            }
            if(typeof supplierdata.Default_Discount !== 'undefined'){
              this.SelectedSupplier.Default_Discount = Number(supplierdata.Default_Discount)
            }
             else{
              this.SelectedSupplier.Default_Discount = 0
            }
            this.Invoice_Address.FieldBreakdown.map(brd => {
              if(supplierdata[brd.Name]){
                this.SelectedSupplier[brd.Name] = supplierdata[brd.Name]
              }
            })
        this.Collection_Address.FieldBreakdown.map(brd => {
              //console.log(brd.Name,supplierdata[brd.Name],this.SelectedSupplier)
              if(supplierdata[brd.Name]){
                this.SelectedSupplier[brd.Name] = supplierdata[brd.Name]
              }
            })
            // this.PrepareNewPOObject()
          }
          else{
            alert('Supplier not Found')
          }
          
        })
      },
      PrepareNewPOObject(){
        this.CancelProcessNewOrder()
        this.SelectedPRLineItems.map(prlineitem => {
          let newprlineitemobj = {}
          newprlineitemobj.StockObj = Object.assign({},prlineitem.StockObj)
          newprlineitemobj.Warehouse = Object.assign({},prlineitem.Warehouse)
          newprlineitemobj.Warehouseid = prlineitem.Warehouse.id
          newprlineitemobj.Qty = prlineitem.Qty
          newprlineitemobj.Min_Qty = prlineitem.Qty
          newprlineitemobj.Supplier = {Supplier_Name: this.SelectedSupplier.Supplier_Name,id: this.SelectedSupplier.id}
          newprlineitemobj.Supplierid = this.SelectedSupplier.id
          newprlineitemobj.Last_Quoted_Price = prlineitem.Last_Quoted_Price
          newprlineitemobj.Price = prlineitem.Price
          // newprlineitemobj.Sub_Total = prlineitem.Price*prlineitem.Qty
          newprlineitemobj.Preferred_Supplier = {Supplier_Name: prlineitem.Preferred_Supplier.Supplier_Name,id: prlineitem.Preferred_Supplier.id}
          newprlineitemobj.Purchase_Leadtime = prlineitem.Purchase_Leadtime
          newprlineitemobj.Delivery_Date = prlineitem.Delivery_Date
          newprlineitemobj.PurchaseRequisitions = [prlineitem.id]
          let oncheck = this.NewPOLineItems.find(obj => obj.StockObj.id === prlineitem.StockObj.id)
          if(!oncheck){
            this.NewPOLineItems.push(newprlineitemobj)
          }
          else{
            oncheck.Qty = oncheck.Qty+prlineitem.Qty
            oncheck.Min_Qty = oncheck.Qty
            oncheck.PurchaseRequisitions = oncheck.PurchaseRequisitions.concat(newprlineitemobj.PurchaseRequisitions)
            // oncheck.Sub_Total = Number(oncheck.Sub_Total)+Number(newprlineitemobj.Sub_Total)
          }
        })
        
        setTimeout(() => {
         this.NEWPODialog = true 
        }, 300);
        
        console.log(this.SelectedPRLineItems,this.SelectedWarehouse,this.SelectedSupplier,this.NewPOLineItems,this.NewPOObject)
      },
      CreatePO(){
        this.CancelProcessNewOrder()
        //actually only get suppl info if suppl array not present
        this.GetSupplierInformation(this.SelectedSupplier.id)
        
      },
        ResetSearch(){
            this.prsearch = ''
        },
        GetInventoryArticle(probj){
          return new Promise(function(resolve, reject) {
            db.collection(probj.StockObj.StoreCollection.split('_').join('').toLowerCase()).doc(probj.StockObj.WarehouseDoc).onSnapshot(snapshot => {
              let invartdata = snapshot.data()
              probj.Preferred_Supplier = invartdata.Preferred_Supplier
              probj.Last_Quoted_Price = invartdata.Last_Quoted_Price
              probj.Purchase_Leadtime = invartdata.Purchase_Leadtime
              resolve(probj)
            })
          })
        },
        GetPurchaseRequisitions(){
          let vm = this
          if(this.$route.params.id){
            this.SelectedWarehouse = this.WarehousesArray.find(obj => obj.id === this.$route.params.id)
          }
          let finalwarehousesarray = this.WarehousesArray.filter(wh => {
            if(this.$route.params.id){
              return wh.id === this.$route.params.id
            }
            else{
              return wh
            }
          })
          finalwarehousesarray.map(wh => {
            if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Ordering || 
            this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].Auditing){
              //.orderBy('Created_On', 'desc')
              //.where('Qty','>=',1)
              db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(wh.id).collection('PurchaseRequisitionsLine_Items').where('Warehouseid','==',wh.id).where('Outstanding_Order','>',0).onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                      probj.Warehouse.Name = wh.Name
                      vm.GetInventoryArticle(probj).then(result => {
                        vm.PurchaseRequisitions.push(result)
                      })
                  
                  }
                  if (change.type === 'modified') {
                    let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                      probj.Warehouse.Name = wh.Name
                      vm.GetInventoryArticle(probj).then(result => {
                        let oncheck = vm.PurchaseRequisitions.find(obj => obj.id === result.id)
                          if(oncheck){
                            let index = vm.PurchaseRequisitions.indexOf(oncheck)
                            vm.PurchaseRequisitions.splice(index, 1, result);
                          }
                      })
                  
                  }
                  if (change.type === "removed") {                      

                      let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = vm.PurchaseRequisitions.find(obj => obj.id === probj.id)
                      if(oncheck){
                        let index = vm.PurchaseRequisitions.indexOf(oncheck)
                        vm.PurchaseRequisitions.splice(index, 1);
                      }
                  }
                })  
              })
            }
          })
        }
    }
}
</script>

<style>

</style>


