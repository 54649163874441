<template>
  <div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            groupid: '',
            NewSubOrderid: '',
            selectedPackage: '',
            Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Billing_Address: {Name: 'Billing_Address',FieldBreakdown: [],DisplayName: 'Billing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            GroupidRoutes: ['GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll',
            'GroupSingle','GroupEdit','GroupNewArticle','GroupNewMeetup','GroupNewBlog','GroupPageEditor','GroupPageBuilder','GroupNewPoll'],
            GroupslugRoutes: ['FeaturedMember','FeaturedMemberEdit'],
        }
    },
    computed:{
        UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
        SocialItemInteractMethod(){
    return this.$store.state.SocialItemInteractMethod
    },
    SocialItemInteractMethodProp(){
      return this.$store.state.SocialItemInteractMethodProp
    },
        PDFDataRecord(){
        return this.$store.state.PDFDataRecord
        },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
        GroupBillingAccount(){
        if(this.ActiveGroup.BillingAccount){
            return this.UserBillingAccounts.find(obj => obj.id === this.ActiveGroup.BillingAccount.id)
        }
        },
        UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
        groupmembers(){
            return this.ActiveGroup && this.ActiveGroup.GroupMembers ? this.ActiveGroup.GroupMembers : []
        },
        GroupForbidden(){
            return this.$store.state.GroupForbidden
        },
        IsActiveGroup(){
            if(this.ActiveGroup){
              if(this.GroupidRoutes.includes(this.$route.name) && this.ActiveGroup.id === this.$route.params.id){
                  return true
              }
              else if(this.$route.query && this.$route.query.GroupID && this.ActiveGroup.id === this.$route.query.GroupID){
                  return true
              }
              else if(this.GroupslugRoutes.includes(this.$route.name) && this.ActiveGroup.id === this.$route.params.slug){
                  return true
              }
            }
            
        },
        ActiveGroup(){
            return this.$store.state.ActiveGroup
        },
        UserMemberObj(){
        return this.groupmembers.find(obj => obj.Userid === this.userLoggedIn.id)
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        UsersArray() {
        return this.$store.state.UsersArray;
        },
        RouteObj(){
            return this.$route
        },
        GroupModerators(){
            return this.groupmembers
            .filter(groupmember => {
                let modbooleans = ['ArticlesModerator','BlogsModerator','EventsModerator','ForumsModerator','MeetupsModerator','PollsModerator']
                let moderator = false
                modbooleans.map(bool => {
                    if(groupmember[bool]){
                        moderator = true
                    }
                })
                return moderator
            })
            .map(groupmember => {
                let modobj = {
                    Full_Name: userobj.Full_Name,
                    id: userobj.id,
                    ModerationPermissions: [],
                    Name: userobj.Name,
                    Surname: userobj.Surname
                } 
            })
        },
        MarketDialogType(){
            return this.$store.state.MarketDialogType
        }
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['SelectPackage','PreparePackageSignup','ProcessPackageSignup']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
        RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.ScrolltoTop()
                    console.log(this.$route)
                  if(!this.IsActiveGroup){
                      console.log('clearing Group')
                    this.$store.commit('setActiveGroup','')
                    this.$store.commit('setGroupForbidden',false)
                    this.GetGroupInformation()
                  }
                }
            },
            deep: true
    },
    UsersArray: {
            handler: function(newvalue, oldvalue) {
                console.log(newvalue, oldvalue,this.ActiveGroup)
                if(this.userLoggedIn && this.UsersArray.length > 0 && !this.ActiveGroup){
                    console.log('users array changed')
                    this.GetGroupInformation()
                }
            },
            deep: true
    },
    UserMemberObj: {
            handler: function(newvalue, oldvalue) {
                console.log(newvalue, oldvalue)
            if(newvalue !== oldvalue){
          //console.log(v)
            this.$store.commit('setUserMemberObj',this.UserMemberObj)
            if(this.ActiveGroup.GroupPrivacy === 'Invite Only'){
            //this.GetGroupContentCategories(this.ActiveGroup) 
            }
      }
    },deep :true
    }
    },
    created(){
        // Purpose of this component is to eliminat previous design needs where a Group and it's members got called for every route applicable to that route
        //this component therefore also serves as means to interact with a group so each route component can be much smaller and focused around UI
        //the tricky part is mainly ensuring all needed calls are made, by example not just groupdata, but groupblogscateorgies, sitemoderators, etc. 
        //the focus is to NOt make this a SUPERCALL component, rather keep to basics. It will:
        //1.) call the group and neccesary information that the other components need from group calls relcaing that only on them
        //2.) serve as the monetization component when monetization applies
        //..it will NOT replace things like "storeblog" or "updateblog" or "assign team member" etc.
        //It may become a baseline, or even the master for all social channels service for "sitearticles" or custom site contnet like "fashionbloggers"etc.
        this.ScrolltoTop()
        if(this.userLoggedIn && this.UsersArray.length > 0){
         this.GetGroupInformation()   
        }
        else if(!this.userLoggedIn){
           this.GetGroupInformation()  
        }
        this.PrepareAddressField('Company_Address')
        this.PrepareAddressField('Billing_Address')
    },
    methods:{
        SelectGroupPackageandSignup(pack){
        console.log(pack)
        let vm = this
        const GroupMember = { 
          Subscription_Package: {Name: pack.Name,id: pack.id},
          Subscription_Packageid: pack.id,
          Userid: vm.userLoggedIn.id,
          Groupid: this.$route.params.id,
          Follower: true,
          Administrator: true,
          BlogsModerator: false,
          ArticlesModerator: false,
          EventsModerator: false,
          PollsModerator: false,
          MeetupsModerator: false,
          ForumsModerator: false,
          UserRole: 'Member',
          Status: 'Active',
          GroupPrivacy: vm.GroupData.GroupPrivacy,
          UserName: vm.userLoggedIn.Full_Name
          }
          console.log(GroupMember)
          GroupMember.MembershipDate = new Date()
              db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(vm.userLoggedIn.id).collection('groups').doc(GroupMember.Groupid).set(GroupMember).then(doc => {
                this.CloseMarketDialog()
              })
        
      },
      ProceedSelectPackage(subpack){
          //refer SelectGroupPackageandSignup for 'Group Membership'
          let billingaccount = ''
            if(this.GroupBillingAccount){
                billingaccount = this.GroupBillingAccount
                billingaccount.Customer_Contact = this.userLoggedIn.Full_Name
                billingaccount.Customer_ID = this.GroupBillingAccount.id
            }
            else{
                billingaccount = this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account')
               
                //the major challenge here is that we don't ahve half the crap we need, we create the ba witht he address now, no longer the "group"
                //mainly just 1.ensure create of group stores address on group as well as account and 2. ensure the account name is there as well. uness happy with group name
                
                billingaccount.Account_Name = this.ActiveGroup.name
                billingaccount.Customer_Contact = 'Invoice to '+this.userLoggedIn.Full_Name+', on behalf of:'
                 this.Billing_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
                let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
                if(this.ActiveGroup[brdname]){
                    billingaccount[newname] = this.ActiveGroup[brdname]
                }
                })
            }
            let subordertypes = this.$store.state.SubscriptionPackageTypes
            let invtype = {ID: 1000002,Name: 'Subscription Order'}
            let type = subordertypes.find(obj => obj.Name === this.MarketDialogType)
            console.log(type)
            let vm = this
            this.selectedPackage = subpack
            vm.$store.commit('setCustomProcessingDialog',true)
            let invpayload = {
            Docid: 'DispatchInstructions_Number',
            Data: {Invoice_Type: invtype}
            }
            vm.$store.commit('setCustomProcessingDialogText','Creating Invoice Number')
            const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            AssignAutoNumber(invpayload).then(result => {
                let suborderpayload = {
                Docid: 'Subscription_Orders_Order_Number',
                Data: {
                Type: type
                },
                siteid: vm.$store.state.ActiveSuiteid
                }
                vm.$store.commit('setCustomProcessingDialogText','Creating Order Number')
            AssignAutoNumber(suborderpayload).then(subordresult => {
                vm.NewSubOrderid = subordresult.data
                console.log(vm.NewSubOrderid)
            vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
            console.log(subpack,vm.AppMonetizeBU)
            //okay simply put, if this.MarketDialogType, then address details somehwat change
            let PDFHeaderObj = {
                Company_Name: vm.AppMonetizeBU.Company_Name,
                Company_Address_Prop: 'PO_Bill_To_Address_',
                Billing_Address_Prop: 'SO_Delivery_Address_',
                Primary_Field_Name: 'Invoice_Number',
                Date: new Date(),        
                Customer_ID: billingaccount.Customer_ID,
                Business_Unit: vm.AppMonetizeBU,
                Business_Unitid: vm.AppMonetizeBU.id,
                Reference_Number: billingaccount.id,
                BillingAccount: {Client_Reference: billingaccount.Client_Reference,Customer_ID: billingaccount.Customer_ID,id: billingaccount.id},
                BillingAccountid: billingaccount.id,
                Client: {
                    id: vm.userLoggedIn.id,
                    Full_Name: vm.userLoggedIn.Full_Name,
                    Name: vm.userLoggedIn.Name,
                    Surname: vm.userLoggedIn.Surname
                },
                Customer_Company: billingaccount.Account_Name,
                Customer_Contact: billingaccount.Customer_Contact,
                Clientid: vm.userLoggedIn.id,
                Recordid: result.data,
                Delivery: false,
                Shipping_Method: {ID: 1000001,Name: 'FOB'},
                Due_Date: new Date(),
            }
            if(this.MarketDialogType === 'Group Membership'){
                
                PDFHeaderObj.Customer_Company = billingaccount.Account_Name
            }
            vm.Company_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name.split('Company_Address_').join(PDFHeaderObj.Company_Address_Prop)
                let brdname = brd.Name
                if(vm.System[brdname]){
                    PDFHeaderObj[brdname] = vm.System[brdname]
                }
                })
                vm.Billing_Address.FieldBreakdown.map(brd => {
                let newname = brd.Name
                let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
                if(billingaccount[brdname]){
                    PDFHeaderObj[newname] = billingaccount[brdname]
                }
                })
            
            let PDFDocLineItems = []
            let descr = `<p>`+subpack.PaymentSchedule+` subscription to our `+subpack.Name+` Package</p>`
            descr = descr+`<br>`+subpack.Description
            let item = {
                Nr: 1,
                Name: subpack.Name,
                Description: descr,
                Price: subpack.Price,
                Qty: 1,
                Group: {
                    name: vm.ActiveGroup.name,
                    id: vm.$route.params.id
                }
            }
            
            PDFDocLineItems.push(item)
            let taxtotal = 0
            let taxperc = 0
            let total = 0
            if(vm.AppMonetizeBU && typeof vm.AppMonetizeBU.Tax_Percentage !== 'undefined'){
                taxperc = vm.AppMonetizeBU.Tax_Percentage
            }
            if(taxperc > 0){
                total = subpack.Price*(taxperc/100)
            }
            taxtotal = Number(total.toFixed(2))
            let PDFFooterObj = {
                Footer_Note: '',
                Sub_Total: subpack.Price,
                Discount_Total: 0,
                Freight_Total: 0,
                Tax_Total: taxtotal,
                Tax_Percentage: taxperc,
                Grand_Total: Number(taxtotal)+Number(subpack.Price),
                TotalFieldProp: 'Invoice_Total',
                TotalFieldValue: subpack.Price
            }
            let note = `<p>This is a Subscripton Order, and the grand total value reflecting on the invoice will be payable on a recurring basis. <br>The subscrition plan you opted for will make this grand total payable on a `
            if(subpack){
                note = note+` `+subpack.PaymentSchedule+` `
            }
            note = note+` basis.</p>`
            if(this.MarketDialogType === 'Group Membership'){
                note = note+`
                <p>This plan is a contract between yourself and `+billingaccount.Account_Name+`, that is only <b>facilitated</b> by `+vm.AppMonetizeBU.Company_Name+`</p>`
                //we cannot assume group owner or group is vat registered. the only VAT applicable is VAT payable for our "royalty invoice" to the group owner
                PDFFooterObj.Tax_Total = 0
                PDFFooterObj.Grand_Total = Number(subpack.Price)
                //but Invoice_Total is always vat exclusive to record revenue with
            }
            PDFFooterObj.Footer_Note = note
            PDFHeaderObj.Payment_Terms = ''
            if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'COD'){
                PDFHeaderObj.Payment_Terms = 'COD'
                PDFHeaderObj.Payment_Period = 0
            }
            else if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'Credit'){          
                PDFHeaderObj.Payment_Period = billingaccount.Days_for_Payment
                PDFHeaderObj.Payment_Terms = 'Net '+billingaccount.Days_for_Payment
            }

            vm.$store.commit('setPDFFunctionName','Sales_Invoices')
            vm.$store.commit('setPDFHeaderObj',PDFHeaderObj)
            vm.$store.commit('setPDFFooterObj',PDFFooterObj)
            vm.$store.commit('setPDFDocLineItems',PDFDocLineItems)
            vm.$store.commit('EmitMarketDialogInteractMethod','PreparePackageSignup')
            vm.$store.commit('setPDFDownloadOnly',false)
            //  DO NOT do this, appnot picks up value cahnge in function, otherwise funciton may be uncalled this.$store.commit('setExportingtoPDFDialog',true)
            })
            })
      },
        SelectPackage(subpack){
            
            let vm = this
            if(!this.UserMarketAccount && this.AppMonetizeBU){
                vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
                vm.$store.commit('setCustomProcessingDialog',true)
                const functions = firebase.functions();
                const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
                let payload = {
                    Data: vm.userLoggedIn,
                    AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
                }
                CreateMarketAccount(payload).then(result => {
                    console.log(result)
                    vm.$store.commit('setCustomProcessingDialogText','Done Creating')
                    vm.ProceedSelectPackage(subpack)
                })
                }
                else{
                vm.ProceedSelectPackage(subpack)
                }
            //the types here correspond to subscription order types though
            
            
    },
    ProcessPackageSignup(){
    let doc = this.$store.state.PDFExportDoc
        let PDF_File = new Blob([doc.output('blob')],{type: 'application/pdf'})
      let vm = this
      let invtype = {ID: 1000002,Name: 'Subscription Order'}
      let subordertypes = this.$store.state.SubscriptionPackageTypes
        let subordertype = subordertypes.find(obj => obj.Name === this.MarketDialogType)
      vm.$store.commit('setCustomProcessingDialogText','Processing Order')
      vm.$store.commit('setCustomProcessingDialog',true)
      let NewPOObject = Object.assign({},this.PDFDataRecord)
      NewPOObject.Recurring = true
      NewPOObject.PaymentSchedule = vm.selectedPackage.PaymentSchedule
      NewPOObject.Invoice_Type = invtype
      NewPOObject.Subscription_Type = subordertype
      NewPOObject.IsFinal = false
      NewPOObject.Progress = {ID: 1000002, Name: 'Invoiced'}
      NewPOObject.Latest_PDF_File = PDF_File
      NewPOObject.Invoice_Date = new Date(),
      NewPOObject.Group = {
          name: vm.ActiveGroup.name,
          id: vm.$route.params.id
      }
      if(this.MarketDialogType === 'Group Membership'){
          NewPOObject.BillingAccount = {Client_Reference: this.UserMarketAccount.Client_Reference,Customer_ID: this.UserMarketAccount.Customer_ID,id: this.UserMarketAccount.id,Account_Name: this.UserMarketAccount.Account_Name}
          NewPOObject.BillingAccountid = this.UserMarketAccount.id
          NewPOObject.Royalty_Percentage = this.System.Base_App_Royalties
          NewPOObject.Group.BillingAccount = vm.ActiveGroup.BillingAccount
          NewPOObject.Group.BillingAccountid = vm.ActiveGroup.BillingAccountid
          NewPOObject.Group.Ownerid = vm.ActiveGroup.Ownerid 
          NewPOObject.Group.Owner = vm.ActiveGroup.Owner 
      }
      NewPOObject.Groupid = vm.$route.params.id
      NewPOObject.Subscription_Package = {
        id: vm.selectedPackage.id,Name: vm.selectedPackage.Name,Price: vm.selectedPackage.Price,Date: new Date(),Client: NewPOObject.Client,Clientid: NewPOObject.Clientid,
        Order_Number: vm.NewSubOrderid,User: NewPOObject.Client,Userid: NewPOObject.Clientid,BillingAccount: NewPOObject.BillingAccount,BillingAccountid: NewPOObject.BillingAccountid,
        Progress: NewPOObject.Progress, Type: vm.selectedPackage.Type, Document_Type: NewPOObject.Invoice_Type,Group: NewPOObject.Group,Groupid: NewPOObject.Groupid,
        Total_Invoiced: NewPOObject.Grand_Total,Total_Paid: 0,Active: true}
      if(this.MarketDialogType === 'Group Directory Listing'){
        NewPOObject.Subscription_Package.DirectoryPages = vm.selectedPackage.DirectoryPages
      }
      NewPOObject.Subscription_Packageid = vm.selectedPackage.id
      NewPOObject.LookupObj = {id: vm.NewSubOrderid,list: 'Subscription_Orders',Order_Number: vm.NewSubOrderid,single: 'Subscription Order',identifier: 'Order_Number'}
      if(NewPOObject.Progress && NewPOObject.Progress.Name === 'Paid'){
        NewPOObject.Subscription_Package.Total_Paid = NewPOObject.Grand_Total
      }
      let PrimaryString = NewPOObject.Invoice_Number
                var lowertext = "";                
                var p;
                let buildup = ''
                //console.log(PrimaryString.length,PrimaryString)
                for (p = 0; p < PrimaryString.length; p++) {
                    buildup = buildup+ PrimaryString[p]
                    if(p === PrimaryString.length-1){
                        lowertext += buildup.toLowerCase();
                    }
                    else{
                        lowertext += buildup.toLowerCase() + ",";
                    }
                } 
                NewPOObject.SearchQuery = []
                NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(NewPOObject.Invoice_Number.trim().split(' '))         
                NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(lowertext.split(','))  
                NewPOObject.SearchQuery = NewPOObject.SearchQuery.map(entry => {
                    return entry.toLowerCase()
                })
      let findocpayload = {
        Collection: 'Sales_Invoices',
        ParentObj: NewPOObject,
        IDProp: 'Invoice_Number',
        siteid: this.$store.state.ActiveSuiteid
        }
        console.log(findocpayload)
        this.CheckObjectforUndefined(1,findocpayload,'SubscriptionOrder')
        const functions = firebase.functions();
        const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
        CreateFinancialDocument(findocpayload).then(result => {
            vm.$store.commit('setPDFExportDoc','')
            vm.$store.commit('setPDFDataRecord','')
            vm.$store.commit('setOrderConfirmationPDF','')
              vm.$store.commit('setPDFDataRecord','')
            vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
            setTimeout(() => {
                vm.$store.commit('setCustomProcessingDialogText','Done')
                vm.$store.commit('setCustomProcessingDialog',false)
                vm.$store.commit('setOrderConfirmationDialog',false)
            },500)
        })
    },
    CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
    PreparePackageSignup(){
       let vm = this
       let doc = this.$store.state.PDFExportDoc
        vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
        let NewPOObject = this.PDFDataRecord
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        let storagelocation = 'Sales_Invoices/'+NewPOObject.BillingAccountid+'/'+NewPOObject.Invoice_Number
        var storageRef = firebase.storage().ref(storagelocation);
        var uploadTask = storageRef.put(blobpdf);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {     
              let PDF_File = {
                StorageRef: storagelocation,
                Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                fileurl: url,
                FileType: 'application/pdf',
                ModifiedDate: new Date(),
                Name: NewPOObject.Invoice_Number,
                filename: NewPOObject.Invoice_Number
              }
              vm.$store.commit('setOrderConfirmationPDF',PDF_File)
              vm.$store.commit('setCustomProcessingDialog',false)
              vm.$store.commit('setOrderConfirmationDialog',true)
              vm.$store.commit('EmitMarketDialogInteractMethod','ProcessPackageSignup')
            })
      
    },
        PrepareAddressField(fieldname){
        let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
      },
        PrepareGroup(docref,groupdata){
            let vm = this
            groupdata.Pages = []
            groupdata.GroupAssets = []
            if(!groupdata.GroupNavbarItems){
                groupdata.GroupNavbarItems = []
            }
            vm.GetGroupPages(docref,groupdata).then(result => {
                
               vm.GetGroupContentCategories(groupdata).then(result => {
                   if(vm.userLoggedIn){
                       
            vm.GetGroupAssets(docref.collection('pageassets'),groupdata).then(result => {
                    groupdata.SubscriptionPackages = []
                    vm.GetGroupSubscriptionPackages(docref,groupdata).then(result => {
                        console.log('groupdata',groupdata)
                            vm.$store.commit('setActiveGroup',groupdata)
                    })   
                    }) 
                   }
                   else{
                       vm.$store.commit('setActiveGroup',groupdata)
                   }                   
            }) 
            })
            
            
        },
        GetGroupAssets(query,groupdata){
            return new Promise(function(resolve, reject) {
            //console.log('this.GroupAssets',this.GroupAssets)
                query.onSnapshot(res => {
                    const changes = res.docChanges();	
                    let assetslength = res.docs.length	
                    if(assetslength === 0){
                        resolve('Got Assets - EMPTY')
                    }							
                    changes.forEach(change => {									
                        if (change.type === 'added') {									
                            groupdata.GroupAssets.push({									
                            ...change.doc.data(),									
                            id: change.doc.id									
                        })
                    }	
                    if(groupdata.GroupAssets.length === assetslength){
                        resolve('Got Assets')
                    }	
                })
                })
            })
        },	
        GetGroupInformation(){
            let vm = this
            let groupid = ''
            
            if(this.GroupidRoutes.includes(this.$route.name)){
                groupid = this.$route.params.id
            }
            else if(this.$route.query && this.$route.query.GroupID){
                groupid = this.$route.query.GroupID
            }
            else if(this.GroupslugRoutes.includes(this.$route.name) && this.ActiveGroup.id === this.$route.params.slug){
                groupid = this.$route.params.slug
            }
            this.groupid = groupid
            let groupcollection = db.collection("groups");
            let docref = groupcollection.doc(this.groupid);
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(groupid).onSnapshot(snapshot => {
                let groupdata = snapshot.data()     
                console.log('groupdata',groupdata)   
                if(groupdata){
                    this.$emit("PushRecordName", groupdata.name);
                    groupdata.id = groupid   
                    groupdata.GroupMembers = []                 
                    this.GetGroupMembersSub(groupdata).then(groupmemresult => {
                        
                        
                        if(groupdata.GroupPrivacy !== 'Invite Only'){
                            vm.$store.commit('setUserMemberObj',this.UserMemberObj)
                            vm.PrepareGroup(docref,groupdata)
                        }
                        else if(vm.UserMemberObj){
                            vm.$store.commit('setUserMemberObj',this.UserMemberObj)
                            vm.PrepareGroup(docref,groupdata)
                        } 
                        else{
                            //dude is not a member and this privacy is invite only. so he wil be blocked, in fact he wont' reach this, he will get aught in "error"
                        }
                    })
                    //GetGroupMembersSub CHECK
                    //GetGroupRatings
                
                //GetGroupPages CHECK
                //this.$emit("PushRecordName", groupdata.name); CHECK
                    
                }                
            },
                (error) => {
                console.log(typeof error, error, error.message);
                if (error.message === "Missing or insufficient permissions.") {
                    this.$store.commit('setGroupForbidden',true)
                }
                }
            );
        },
        GetGroupSubscriptionPackages(docref,group){
        let vm = this
        return new Promise(function(resolve, reject) {
          docref.collection('SubscriptionPackages').onSnapshot(res => {
              let docslength = res.docs.length
              if(docslength === 0){
                  resolve('Got Subscription Packages')
              }
              else{
                  const changes = res.docChanges()
                    changes.forEach(change => {
                        if(change.type === 'added'){
                        let newobj = {
                            id: change.doc.id,
                            ...change.doc.data()
                        }
                        group.SubscriptionPackages.push(newobj)
                        } 
                        if(change.type === 'modified') {
                        let newobj = {
                            id: change.doc.id,
                            ...change.doc.data()
                        }
                        let oncheck = group.SubscriptionPackages.find(obj => obj.id === newobj.id)
                        if(oncheck){
                            let index = group.SubscriptionPackages.indexOf(oncheck)
                            group.SubscriptionPackages.splice(index,1,newobj)
                        }
                        }
                        if(change.type === 'removed') {
                        let newobj = {
                            id: change.doc.id,
                            ...change.doc.data()
                        }
                        let oncheck = group.SubscriptionPackages.find(obj => obj.id === newobj.id)
                        if(oncheck){
                            let index = group.SubscriptionPackages.indexOf(oncheck)
                            group.SubscriptionPackages.splice(index,1)
                        }
                        }
                        if(group.SubscriptionPackages.length === docslength){
                            resolve('Got Subscription Packages')
                        }
                    });
              }
            
          })
        })
        },
        GetGroupPages(docref,groupdata){
            let vm = this
            return new Promise(function(resolve, reject) {
            docref.collection('pages').onSnapshot(res => {
                let length = res.docs.length
                if(length === 0){
                resolve('Got Pages')
                }
                const changes = res.docChanges();
                changes.forEach((change) => {
                if (change.type === "added") {
                    let pageobj = {
                    ...change.doc.data(),
                    id: change.doc.id,
                    }
                    groupdata.Pages.push(pageobj);                   
                }
                if (change.type === "modified") {
                    let pageobj = {
                    ...change.doc.data(),
                    id: change.doc.id,
                    }
                    let oncheck = groupdata.Pages.find(obj => obj.id === pageobj.id)
                    if(oncheck){
                        let index = groupdata.Pages.indexOf(oncheck)
                        groupdata.Pages.splice(index,1,pageobj)
                    }                
                }
                if (change.type === "removed") {
                    let pageobj = {
                    ...change.doc.data(),
                    id: change.doc.id,
                    }
                    let oncheck = groupdata.Pages.find(obj => obj.id === pageobj.id)
                    if(oncheck){
                        let index = groupdata.Pages.indexOf(oncheck)
                        groupdata.Pages.splice(index,1)
                    }                 
                }
                 if(groupdata.Pages.length === length){
                    resolve('Got Pages')
                    }
                });
            })
            })
        },
        GetGroupMembersSub(groupdata) {
             let vm = this
            return new Promise(function(resolve, reject) {
                let userlength = vm.UsersArray.length
                console.log(userlength)
                if(userlength === 0){
                    console.log(vm.UsersArray)
                     resolve('Done Group Members on users to call on')
                }
                else{
                    let total = 0
                     vm.UsersArray.map((user,userindex) => {
                        db
                        .collection("groupmembers")
                        .doc(user.id)
                        .collection("groups")
                        .where("Groupid", "==", vm.groupid)
                        //.where("Status", "==", "Active")
                        .onSnapshot((res,resindex) => {
                            //alert('askasdkasd')
                            //there either is, or there is not a group member registration per user
                            //but you cannot resolve this way                            
                            let listlength = res.docs.length;
                            total = total+listlength
                            if(listlength === 0 && userindex-1+2 === userlength){
                                resolve('Done Group Members')
                            }
                            //alert(listlength)
                            const changes = res.docChanges();
                            changes.forEach((change) => {
                            if (change.type === "added") {
                                // console.log('groupmembers push ',{
                                //   ...change.doc.data(),
                                //   id: change.doc.id,
                                // })
                                groupdata.GroupMembers.push({
                                ...change.doc.data(),
                                id: change.doc.id,
                                });
                            }
                            if (change.type === "modified") {
                                let changeobj = {
                                ...change.doc.data(),
                                id: change.doc.id,
                                };
                                let oncheck = groupdata.GroupMembers.find(obj => obj.Userid === changeobj.Userid)
                                if(oncheck){
                                    let index = groupdata.GroupMembers.indexOf(oncheck)
                                    groupdata.GroupMembers.splice(index,1,changeobj)
                                }
                            }
                            if (change.type === "removed") {
                                let changeobj = {
                                ...change.doc.data(),
                                id: change.doc.id,
                                };
                                let oncheck = groupdata.GroupMembers.find(obj => obj.Userid === changeobj.Userid)
                                if(oncheck){
                                    let index = groupdata.GroupMembers.indexOf(oncheck)
                                    groupdata.GroupMembers.splice(index,1)
                                }
                            }
                            console.log(userlength,userindex-1+2)
                            if(userindex-1+2 === userlength){
                                resolve('Done Group Members')
                            }
                        })
                        })
                    })
                }
           
            })
        },
        GetGroupContentCategories(groupdata){
            let vm = this
            return new Promise(function(resolve, reject) {                
            let array = [
                'ArticlesCategories',
                'BlogsCategories',
                'MeetupsCategories',
                'PollsCategories'
                ]
                let arraylength = array.length
                array.map((item,itemindex) => {
                        groupdata[item] = []
                            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(groupdata.id).collection(item).onSnapshot(res => {
                                let docslength = res.docs.length
                                if(docslength === 0 && itemindex-1+2 === arraylength){                                    
                                    resolve('done content categories')
                                }
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                if (change.type === 'added') {
                                groupdata[item].push({
                                    ...change.doc.data(),
                                    id: change.doc.id
                                })
                                }
                                else if (change.type === 'modified') {
                                    let catobj = {
                                    ...change.doc.data(),
                                    id: change.doc.id
                                }
                                let oncheck = groupdata[item].find(obj => obj.id === catobj)
                                if(oncheck){
                                    let index = groupdata[item].indexOf(oncheck)
                                    groupdata[item].splice(index,1,catobj)
                                }
                                }
                                else if (change.type === 'removed') {
                                        let catobj = {
                                        ...change.doc.data(),
                                        id: change.doc.id
                                    }
                                    let oncheck = groupdata[item].find(obj => obj.id === catobj)
                                    if(oncheck){
                                        let index = groupdata[item].indexOf(oncheck)
                                        groupdata[item].splice(index,1)
                                    }
                                }
                                if(itemindex-1+2 === arraylength){                                    
                                    resolve('done content categories')
                                }
                            })
                        })
                })
            })
        },
        
        ScrolltoTop(){
            setTimeout(() => {
             document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;   
            }, 50);
            
        },
    }
}
</script>

<style>

</style>


