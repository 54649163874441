
          <template>
  <v-card flat :class="userLoggedIn ? 'transparent' : 'transparent'">
    <v-snackbar
      v-model="FriendRequestSnackbar"
      :timeout="4000"
      top
      color="success"
    >
      <span
        >Your friendship request to {{ UserData.Full_Name }} has been
        sent!...</span
      >
      <v-btn color="white" @click="FriendRequestSnackbar = false">Close</v-btn>
    </v-snackbar>
    <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" dark color="red" @click="helpsheet = !helpsheet"
          >close</v-btn
        >
        <div>
          This is the My Profile help sheet. What would you want to know?
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <div>
      <v-layout class="justify-center" style="margin-top: 40px">
        <v-flex lg8 md10>
          <v-parallax
            v-if="!UserData.Parallax"
            height="200"
            :src="
              System.Social_Parallax
                ? System.Social_Parallax.path
                : require('@/assets/RapidappsParallax.jpeg')
            "
            class="mx-3"
          >
          </v-parallax>
          <v-parallax
            v-if="UserData.Parallax"
            height="200"
            :src="UserData.Parallax"
            class="mx-3"
          >
          </v-parallax>

          <v-avatar
            style="margin-top: -220px"
            tile
            color="grey"
            size="164"
            class="mx-10"
          >
            <v-img
              v-if="UserData.Profile_Photo"
              :src="UserData.Profile_Photo"
            ></v-img>
            <v-img
              v-if="!UserData.Profile_Photo"
              src="@/assets/BlankProfilePic.png"
            ></v-img>
          </v-avatar>
          <v-row
            class="mb-6 mx-3 justify-center"
            style="margin-top: -25px"
            no-gutters
          >
            <v-col
              cols="12"
              :xl="3"
              :lg="3"
              :md="4"
              :sm="4"
              :xs="12"
              class="primary"
            >
              <v-card-title class="mediumoverline white--text">{{
                UserData.Full_Name
              }}</v-card-title>
              <p class="centertext grey--text mx-2 my-2">
                {{ UserData.FavoriteQuote }}
              </p>
              <v-card-title class="headline white--text">Skills</v-card-title>
              <v-divider class="pop"></v-divider>
              <v-chip-group
                class="mx-3"
                active-class="blue--text"
                column
                v-if="UserData.tags"
              >
                <v-chip v-for="tag in UserData.tags" :key="tag">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              <WebFormTab
                :PageHeadersFont="PageHeadersFont"
                :PageContentFont="PageContentFont"
                :RelatedEntity="'groupmemberenquiries'"
                :PageHeadersFontSize="PageHeadersFontSize"
                @ActivateOptionSetDialog="ActivateOptionSetDialog"
                @ConfirmSubmission="ConfirmSubmission"
                :PageName="'Group Featured Member - ' + $route.params.slug"
                @ProcessingData="ProcessingData"
                :OptionNameSelect="OptionNameSelect"
                :IsElement="true"
                :tabindex="0"
                :rowindex="0"
                :tab="MappedClassifiedTab"
                :i="0"
              />
            </v-col>
            <v-col cols="12" :xl="9" :lg="9" :md="8" :sm="8" :xs="12">
              <v-card
                tile
                :dark="AppisDarkMode"
                width="100%"
                height="100%"
                class="white text-xs-center"
                v-if="UserData.WriteupSections"
              >
                <v-spacer></v-spacer>
                <div
                  v-for="(section, i) in UserData.WriteupSections"
                  :key="section.itemObjKey"
                >
                  <v-card-title
                    :style="
                      isOdd(i)
                        ? 'background: linear-gradient(300deg,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 90%)'
                        : 'background: linear-gradient(60deg,rgba(255,255,255,0) 5%,rgba(255,255,255,1) 90%)'
                    "
                    :class="
                      isOdd(i)
                        ? 'justify-end headline background links--text'
                        : 'justify-start headline background links--text'
                    "
                    >{{ section.Title }}</v-card-title
                  >

                  <v-divider
                    :width="isOdd(i) ? '100%' : '70%'"
                    class="grey"
                    :inset="isOdd(i)"
                  ></v-divider>
                  <v-card-text
                    style="padding: 20px"
                    v-for="child in section.Children"
                    :key="child.itemObjKey"
                  >
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <span>{{ child.SubheaderOne }}</span>
                        </v-list-item-content>
                        <v-list-item-content>
                          <span>{{ child.SubheaderTwo }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <span v-html="child.SubText"></span>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>




<script>
import firebase from "firebase";
import db from "@/main";
import format from "date-fns/format";
import WebFormTab from "@/components/WebPages/RenderComponents/WebFormTabReadOnly";
export default {
  props: ["AppisDarkMode", "System", "SystemEntities", "Directories"],
  middleware: "auth",
  components: {
    WebFormTab,
  },
  data() {
    return {
      FormProcessing: false,
      AutomationCreatedSnackbar: false,
      AutomationSnackbarDetails: "",
      ClassifiedTab: {
        ButtonAlignment: "justify-end",
        HeightRestrictions: true,
        HideFormName: true,
        Tile: true,
        BoxTransparency: "transparent",
        Name: "Web Form",
        FormName: "Group Member Enquiry",
        FromDatabase: true,
        DarkForm: true,
        BGColor: {
          hexa: "#ffffff",
        },
        HeaderColor: { hex: "#ffffff" },
        EntityForm: true,
        RelatedEntity: { id: "groupmemberenquiries" },
        FormSubmitted: false,

        SingleName: "Group Member Enquiry",
        FormFields: [
          {
            Type: "Lookup",
            Name: "Enquirying User",
            OnWebForm: true,
            RelatedBuildID: "Users",
            UsedonForm: true,
            Primary: false,
            RelatedRecord: "Users",
            LookupFieldName: "Full_Name",
            DisplayName: "Enquirying User",
            RelatedLocation: "User",
            id: "Client",
            IsHeader: true,
            Created: false,
            StrictlyLoggedIn: true,
            MappedField: {
              Primary: false,
              Name: "Enquirying_User",
              LoggedInUser: true,
            },
          },
          {
            Type: "Lookup",
            Name: "Group",
            OnWebForm: true,
            RelatedBuildID: "Groups",
            UsedonForm: true,
            Primary: false,
            RelatedRecord: "Groups",
            LookupFieldName: "name",
            DisplayName: "Group",
            RelatedLocation: "Group",
            id: "group",
            IsHeader: true,
            Created: false,
            MappedField: {
              Primary: false,
              Name: "group",
            },
          },
          {
            Type: "Common Field",
            CommonFieldType: "Email",
            Name: "Email",
            OnWebForm: true,
            UsedonForm: true,
            Primary: false,
            DisplayName: "Email",
            IsHeader: true,
            Created: false,
            MappedField: {
              Primary: false,
              Name: "Email",
              LoggedInUserValue: true,
            },
          },
          {
            Type: "Option Set",
            Name: "Group_Type",
            OnWebForm: true,
            UsedonForm: true,
            Primary: false,
            DisplayName: "Group Type",
            IsHeader: true,
            Created: false,
            MappedField: {
              Primary: false,
              Name: "grouptype",
            },
          },
          {
            Type: "Common Field",
            CommonFieldType: "Telephone Number",
            Name: "Mobile_Number",
            OnWebForm: true,
            UsedonForm: true,
            Primary: false,
            DisplayName: "Mobile Number",
            IsHeader: true,
            Created: false,
            MappedField: {
              Primary: false,
              Name: "Mobile_Number",
              LoggedInUserValue: true,
            },
          },
          {
            DisplayName: "Featured Group Member",
            RelatedBuildID: "Featured_Group_Members",
            Type: "Lookup",
            Created: false,
            LookupFieldName: "Full_Name",
            OnWebForm: true,
            Primary: false,
            Name: "Featured Group Member",
            RelatedRecord: "Featured Group Members",
            UsedonForm: true,
            RelatedLocation: "Featured Group Member",
            IsHeader: true,
            id: "Featured_Group_Member",
            MappedField: {
              Name: "Title",
              Primary: true,
            },
          },
          {
            Type: "Multiple Lines Text",
            IsHeader: true,
            id: "Enquiry",
            Name: "Enquiry",
            DisplayName: "Enquiry",
            OnWebForm: true,
            Primary: false,
            UsedonForm: true,
            Created: false,
          },
          {
            Type: "Date",
            IsHeader: true,
            id: "Date_Required",
            Name: "Date_Required",
            DisplayName: "Date Required",
            OnWebForm: true,
            Primary: false,
            UsedonForm: true,
            Created: false,
          },
        ],
        StatusDefault: {
          HasDeepHierarchy: true,
          Level: {
            id: 1,
            FieldName: "Status",
            children: [
              {
                FieldName: "Status_Reason",
                id: 2,
                children: [],
                name: "Status Reason",
              },
            ],
            name: "Status",
          },
          Name: "Active",
          LevelFieldName: "Status",
          ID: 1000001,
          Options: [
            {
              Level: {
                id: 2,
                children: [],
                FieldName: "Status_Reason",
                name: "Status Reason",
              },
              Name: "Open",
              LevelFieldName: "Status_Reason",
              ID: 1000003,
            },
          ],
        },
        Status_ReasonDefault: {
          ID: 1000003,
          Name: "Open",
          Level: {
            id: 2,
            children: [],
            name: "Status Reason",
            FieldName: "Status_Reason",
          },
          LevelFieldName: "Status_Reason",
        },
      },
      rules: {
        min8Chars: (value) => value.length >= 8 || "Min. 8 characters",
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      UserData: {
        tags: [],
        WriteupSections: [],
      },
    };
  },

  created() {
    this.GetUser();
    if (this.userLoggedIn) {
      this.IntranetViewToggle(true);
    }
  },
  computed: {
    GroupCategoryTypes() {
      return this.System.Group_Categories
        ? this.System.Group_Categories.Options
        : [];
    },
    CardLinks() {
      return this.NavlistFields
        ? this.NavlistFields.map((field) => {
            let fieldobj = {
              Name: field.DisplayName,
              URL: this.UserData[field.Name],
              FullField: field,
            };
            return fieldobj;
          })
        : [];
    },
    NavlistEntity() {
      return this.Directories.find(
        (obj) => obj.id === "Business_Members"
      );
    },
    NavlistFields() {
      return this.NavlistEntity ? this.NavlistEntity.AdditionalFields : [];
    },
    usercanEdit() {
      return this.userIsAdmin;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    MappedClassifiedTab() {
      this.ClassifiedTab.FormFields = this.ClassifiedTab.FormFields.filter(
        (field) => {
          return this.userLoggedIn ? field : !field.StrictlyLoggedIn;
        }
      ).map((field) => {
        if (field.MappedField) {
          let mappedfield = field.MappedField.Name;
          //alert(mappedfield+'id')
          if (field.Type === "Lookup") {
            field.Array = [];
            if (!field.MappedField.Primary && !field.MappedField.LoggedInUser) {
              field.Response = this.UserData[mappedfield];
            } else if (
              !field.MappedField.Primary &&
              field.MappedField.LoggedInUser &&
              this.userLoggedIn
            ) {
              field.Response = {
                Full_Name: this.userLoggedIn.Full_Name,
                id: this.userLoggedIn.id,
              };
            } else {
              field.Response = {
                [field.LookupFieldName]: this.UserData.Full_Name,
                id: this.$route.params.id,
              };
            }

            field.Array.push(field.Response);
          } else if (
            field.MappedField &&
            field.MappedField.LoggedInUserValue &&
            this.userLoggedIn
          ) {
            console.log("this.userLoggedIn", this.userLoggedIn);
            field.Response = this.userLoggedIn[field.MappedField.Name];
          } else if (field.Type !== "Date") {
            field.Response = this.UserData[mappedfield];
          } else {
            field.Response = this[mappedfield].toDate();
          }
          if (field.Type === "Option Set") {
            field.Options = [this.UserData[mappedfield]];
          }
        }
        return field;
      });

      this.ClassifiedTab.DefaultOwner = {
        id: this.$route.params.id,
        Full_Name: this.UserData.Full_Name,
      };
      this.ClassifiedTab.DefaultOwner.Email = this.UserData.Email;

      return this.ClassifiedTab;
    },
    FinalBuAddressMapped() {
      return this.FinalBUAddress + "&t=&z=13&ie=UTF8&iwloc=&output=embed";
    },
    FinalBUAddress() {
      let combinedlink =
        this.UserData.AddressLine1 +
        "+" +
        this.UserData.AddressLine2 +
        "+" +
        this.UserData.AddressLineCity +
        "+" +
        this.UserData.AddressLinePostalCode +
        "+" +
        this.UserData.AddressLineProvince +
        "+" +
        this.UserData.AddressLineCountry;
      let FinalLink =
        "https://maps.google.com/?q=" + combinedlink.split(" ").join("+");
      return FinalLink;
    },
    FinalBUAddressName() {
      return (
        `<p>` +
        this.UserData.AddressLine1 +
        `<br>
      ` +
        this.UserData.AddressLine2 +
        `<br>
      ` +
        this.UserData.AddressLineCity +
        `<br>
      ` +
        this.UserData.AddressLinePostalCode +
        `<br>
      ` +
        this.UserData.AddressLineProvince +
        `<br>
      ` +
        this.UserData.AddressLineCountry +
        `</p>`
      );
    },
  },
  methods: {
    UpdateUserValues() {
      this.NavlistFields.map((field) => {
        if (typeof this.UserData[field.Name] !== "undefined") {
          db.collection("businessmembers")
            .doc(this.$route.params.id)
            .update({
              [field.Name]: this.UserData[field.Name],
            });
        }
      });
    },
    ProcessingData(boolean) {
      this.FormProcessing = boolean;
    },
    ConfirmSubmission(tab, obj, docid) {
      console.log(tab, obj, docid);
      tab.FormSubmitted = true;
      this.AutomationCreatedSnackbar = true;
      this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record here`;
    },
    isOdd(num) {
      return num % 2;
    },
    IntranetViewToggle(boolean) {
      this.$emit("IntranetViewToggle", boolean);
    },

    ToggleHelp() {
      this.helpsheet = true;
    },

    ResetFilters() {},

    ResetSearch() {
      this.search = "";
    },

    RoutetoLoginPage() {
      this.$router.push("/login");
    },
    RoutetoErrorPage() {
      this.$router.push("/PermissionError");
    },

    GetUser() {
      let vm = this;
      //let grouptype = this.$route.query.GroupType.split("-").join(" ")
      let grouptype = this.$route.params.slug.split("-").join(" ")
      console.log(this.GroupCategoryTypes,'grouptype'+grouptype)
      let grouptypeid = this.GroupCategoryTypes.find(
        (obj) => obj.Name === grouptype
      );
      let grouptypeidstring = ""
      if(grouptypeid){
         grouptypeidstring =  grouptypeid.ID.toString()
      };
      db.collection("featuredgroupmembers")
        .doc(this.$route.params.id)
        .collection("grouptypes")
        .doc(grouptypeidstring)
        .onSnapshot((featured) => {
          let featuredmember = featured.data();
          this.UserData = featuredmember;
          this.UserData.tags = featuredmember.tags;
          this.UserData.WriteupSections = featuredmember.WriteupSections;
          console.log('this.UserData',this.UserData)
          db.collection("groups")
            .doc(featuredmember.groupid)
            .onSnapshot((groupsnap) => {
              let groupdata = groupsnap.data();
              db.collection("groupmembers")
                .doc(this.$route.params.id)
                .collection("groups")
                .doc(featuredmember.groupid)
                .onSnapshot((groupmembersnap) => {
                  let groupmemberdata = groupmembersnap.data();
                  if (groupmemberdata && groupmemberdata.Administrator) {
                    this.UserisFeaturingGroupAdmin = true;
                  }
                });
            });
          console.log(this.UserData, this.UserData.tags);
        });
    },
  },
};
</script>

<style>
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 170px;
  width: 200px;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 170px;
  width: 200px;
}
</style>
    

    