
<template>
<v-virtual-scroll v-if="GuideList"
  class="transparent"
    :items="GuideList"
    :item-height="!tab.HideGuidesCarousel ? 390 : 200"
    :height="ListHeight? ListHeight : 400"
  >
  <template v-slot:default="{ item }">
 <v-card @dragstart="guidedragstart(item, $event)" :draggable="$route.name === 'Roadmap'" class="background mx-2" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
   <v-sheet :class="item.Disabled ? 'grey lighten-4' : 'background'">
                <v-carousel v-if="!tab.HideGuidesCarousel"
                    :cycle="!item.Disabled"
                    height="150"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in item.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  >
                  <v-btn @click="ToggleRoadmapItemScope(item)" v-if="$route.name === 'Roadmap'" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmapItem(item)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmapItem(item)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>

                <v-card-subtitle :class="item.Disabled ? 'pb-0 grey--text' : 'pb-0 pop--text'">
                  {{item.Name}} <v-chip class="mx-2" small dark :color="item.DisabledColor" v-if="item.Disabled">{{item.DisabledName}} <v-icon>{{item.DisabledIcon}}</v-icon></v-chip>
                </v-card-subtitle>
                   
                <v-card-text class="text--primary">
                  <h5 :class="item.Disabled ? 'grey--text' : 'text--primary'" >{{item.Category.Name}}</h5>

                  <div  :class="item.Disabled ? 'caption grey--text' : 'caption'" v-html="item.Description"></div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="pink"
                    text
                    small
                    v-if="item.HasActionButton"
                    :disabled="item.Disabled"
                    @click="ActivateSingleRecordNavMenuItem(item)"
                  >
                    Add Now 
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="teal"
                    text
                    small
                    target="_blank"
                    :href="item.RoutePath"
                  >
                    Explore
                  </v-btn>
                </v-card-actions>
              </v-sheet>
 </v-card>
  </template>
</v-virtual-scroll>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','GuideList','ListHeight','FavoriteFeatures'],
    components: {

        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
      OnRoadmapItem(item){
      console.log('thisd.FavoriteFeatures',this.FavoriteFeatures,item)
      let oncheck = this.FavoriteFeatures.find(obj => obj === item.id)
      return oncheck
    },
    ToggleRoadmapItemScope(item){
      this.$emit('ToggleRoadmapItemScope',item)
      // db.collection('favoritefeatures').
    },
      guidedragstart(guide){
        this.$emit('guidedragstart',guide)
      },
        GetTabClass(tab){
            if(tab.BoxTransparency && tab.CustomClass){
              return tab.BoxTransparency+' '+tab.CustomClass+' mx-3'  
            }
            
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        


    