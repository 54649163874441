<template>
  <div>
      <v-card :class="SingleElementView ? 'transparent' : 'folderyellow  yellow lighten-4'" shaped id="Documents" style="z-index:1;" v-if="!tab.HideHeader && !SingleElementView">    
              <v-card-title class="align-center accent white--text" :style="GetHeaderGraphics(tab)" v-if="!SingleElementView">
                <span class="headline" v-if="!tab.DisplayName">{{tab.Name}}</span><span class="headline" v-else>{{tab.DisplayName}}</span>
              </v-card-title>
            </v-card>
             <v-card :dark="AppisDarkMode" :flat="tab.HideHeader" :class="SingleElementView? 'transparent' : 'dbcard'" :outlined="!tab.HideHeader" :style="!tab.HideHeader && !SingleElementView ? 'margin-top:-40px;padding-top:40px;' : ''">
                <v-card v-if="!SingleElementView" flat height="60" class="overlayed transparent">
                  </v-card>
                   <v-tabs v-if="!SingleElementView" background-color="transparent" :centered="tab.TabsCentered" color="white" :style="!tab.HideHeader ? 'padding-top:-40px;' : ''">
                       <v-tab v-for="section in tab.Elements" :key="section.itemObjKey" class="foldertab dbcard" :style="GetHeaderGraphics(section)"><span class="hidden-sm-and-down">{{section.DisplayName}}</span><span class="hidden-md-and-up">{{section.DisplayName}}</span></v-tab>
                         <!-- was class="paddedtab" -->
                         
                         <v-tab-item :style="section.DataViewName !== 'Data Single Fields' ? 'padding-bottom:40px;' : 'padding:40px;'" :class="$vuetify.theme.dark? 'blue-grey darken-3' : 'blue-grey lighten-5'" v-for="(section,sectionindex) in tab.Elements" :key="section.itemObjKey">
                             <v-card flat width="100%" @dragover.prevent @drop.prevent="NewFieldDragfinish(NewFieldDragging,NewFieldDraggingIndex,section,sectionindex, $event)" v-if="NewFieldDragging && section.DataViewName === 'Data Single Fields'">									
                            <v-layout class="justify-center">									
                                <v-card-title class="grey--text">									
                                <v-icon>mdi-plus-thick</v-icon> Add Field here									
                                </v-card-title>									
                            </v-layout>									
                            </v-card>
                      
                             <DataSingleImageGallery  :EntityName="EntityName" :EntityCollectionRef="EntityCollectionRef"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="EntityDataRef"
                             @UpdateEditableField="UpdateEditableField" :SystemPhotoLibraries="SystemPhotoLibraries"
                            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :System="System"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex"
                            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                            :Record="Record" v-if="section.MultipleFileUploadType === 'Gallery' || section.MultipleFileUploadType === 'Images'" :AdditionalSaveMethod="AdditionalSaveMethod"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="section.CanEdit" :CanCreate="section.CanCreate" :CanDelete="section.CanDelete"
                            />
                            <DataSingleDocumentRegister :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
                            :Record="Record" v-if="section.MultipleFileUploadType === 'Document Register'"
                            :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                            :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                            :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true" :System="System"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="section.CanEdit" :CanCreate="section.CanCreate" :CanDelete="section.CanDelete"
                            />
                            <TableEmbedTab 									
                                v-if="section.MultipleFileUploadType === 'Related Table' && section.Entityid && section.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"		
                                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"					
                                :tab="section" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="Record" :System="System"									
                                />
                                <TableEmbedTab 									
                                v-if="section.MultipleFileUploadType === 'SubCollection' && section.Entityid && section.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"	
                                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"					
                                :tab="section" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="Record" :RADB="RADB" :System="System"									
                                />
                                <SingleTabFieldsSection @UpdateEditableField="UpdateEditableField" @GetShortLivedURL="GetShortLivedURL"
                            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" @UpdateRecordCalculation="UpdateRecordCalculation"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex" @onPhotoUpload="onPhotoUpload"
                            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" :System="System"
                            :Record="Record" v-if="section.DataViewName === 'Data Single Fields'" :AdditionalSaveMethod="AdditionalSaveMethod"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
                            />
                            <SingleTabActivitiesSection 
                            :Record="Record" v-if="section.DataViewName === 'Data Single Activities'"
                            :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex"
                            :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                            :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                            :ActivitiesArray="ActivitiesArray" :System="System"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
                            />
            
                         </v-tab-item>
                   </v-tabs>
                   <div class="justify-center flex row xl12 lg12 md12 sm12" v-if="SingleElementView">
                       <v-btn dark color="success" width="100%" style="margin-top:50px;" v-if="FilteredSystemTabs" @click="UpdateSystem()">Save</v-btn>
                    <div :class="'col-sm-12 col-md-12 col-12'" :cols="12" :sm="12"  v-for="(section,sectionindex) in TabElements" :key="section.itemObjKey">
                        <DataSingleImageGallery  :EntityName="EntityName" :EntityCollectionRef="EntityCollectionRef"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="EntityDataRef"
                             @UpdateEditableField="UpdateEditableField" :System="System"
                            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :SingleElementView="SingleElementView"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex"
                            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
                            :Record="Record" v-if="section.MultipleFileUploadType === 'Gallery' || section.MultipleFileUploadType === 'Images'" :AdditionalSaveMethod="AdditionalSaveMethod"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="section.CanEdit" :CanCreate="section.CanCreate" :CanDelete="section.CanDelete"
                            />
                            <DataSingleDocumentRegister :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
                            :Record="Record" v-if="section.MultipleFileUploadType === 'Document Register'" :SingleElementView="SingleElementView"
                            :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                            :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :System="System"
                            :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="section.CanEdit" :CanCreate="section.CanCreate" :CanDelete="section.CanDelete"
                            />
                            <TableEmbedTab 									
                                v-if="section.MultipleFileUploadType === 'Related Table' && section.Entityid && section.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"		
                                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef" :System="System"				
                                :tab="section" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="Record" :SingleElementView="SingleElementView"								
                                />
                                <TableEmbedTab 									
                                v-if="section.MultipleFileUploadType === 'SubCollection' && section.Entityid && section.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"	
                                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef" :System="System"					
                                :tab="section" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="Record" :RADB="RADB" :SingleElementView="SingleElementView"									
                                />
                                <SingleTabFieldsSection @UpdateEditableField="UpdateEditableField" @GetShortLivedURL="GetShortLivedURL"
                            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" @UpdateRecordCalculation="UpdateRecordCalculation"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex" @onPhotoUpload="onPhotoUpload" :System="System"
                            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" :SingleElementView="SingleElementView"
                            :Record="Record" v-if="section.DataViewName === 'Data Single Fields'" :AdditionalSaveMethod="AdditionalSaveMethod"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
                            />
                            <SingleTabActivitiesSection 
                            :Record="Record" v-if="section.DataViewName === 'Data Single Activities'"
                            :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing"
                            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :System="System"
                            :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                            :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                            :ActivitiesArray="ActivitiesArray" :SingleElementView="SingleElementView"
                            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
                            />
                    </div>
                   </div>
             </v-card>
    </div>
</template>

<script>
// import DataSingleDocumentRegister from '@/components/Database/DataSingleFolderRegister';
import DataSingleDocumentRegister from '@/components/Database/DataSingleDocumentRegister';
import DataSingleImageGallery from '@/components/Database/DataSingleImageGallery';
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';	
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import SingleTabActivitiesSection from '@/components/Database/SingleTabActivitiesSection';
import db from '@/main'		
export default {
    props: ['tab','AppisDarkMode','CanEdit','CanCreate','CanDelete','CanGet','Record','CurrentEntity','RADB','SingleElementView','SystemPhotoLibraries','System',
    'Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','TasksQuery','EmailsQuery','NotesQuery','PhoneCallsQuery','ActivitiesArray','SystemEntities','AdditionalSaveMethod',
    'SingleFormEditing','tabindex','NewTableDragging','NewTableDraggingIndex','ActiveEntityID','EntityCollectionRef','EntityDataRef','NewFieldDragging','NewFieldDraggingIndex'],
    components: {
        DataSingleDocumentRegister,
        DataSingleImageGallery,        
        TableEmbedTab,
        SingleTabFieldsSection,
        SingleTabActivitiesSection
    },
    data(){
        return{
            
        }
    },
    computed:{
        FilteredSystemTabs(){
            return this.CurrentEntity && this.CurrentEntity.id === 'Systems' && this.Record && this.Record.id === process.env.VUE_APP_RA_SYSTEM_ID && !this.Record.SystemBuilder && this.$route.name !== 'SystemConfig'
        },
        AppBuilderView(){
        return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView
        },
        BuilderView(){
        return this.$store.state.BuilderView
        },
        WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
        },
        NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        TabElements(){
            return this.tab.Elements
        },
        BaseStorageRef(){
            return this.EntityName.split(' ').join('')+'/'
        },
        EntityName(){
            let entity = this.SystemEntities.find(obj => obj.id === this.ActiveEntityID)
            return entity.DisplayName
        },
        ActiveSuiteid(){
            return this.$store.state.ActiveSuiteid
        },
    },
    created(){
        //console.log('cabinet this.tab',this.tab)
    },
    methods:{
        UpdateSystem(){
            let elmnts = JSON.parse(JSON.stringify(this.TabElements))
            let fields = elmnts.filter(elmnt => {
                return elmnt.Fields
            }).map(elmnt => {
                    return elmnt.Fields
                }).flat()
            
            fields.map(field => {
                let prop = field.Name
                let value = this.Record[field.Name]
                if(typeof value !== 'undefined'){
                    //console.log(field.Name,this.Record[field.Name])
                    this.RADB.collection('systems').doc(this.ActiveSuiteid).update({
                        [prop]: value
                    }).then(radoc => {
                        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                        [prop]: value
                        })
                    })
                }
            })
        },
        GetShortLivedURL(fileobject){
        this.$emit('GetShortLivedURL',fileobject)
      },
        onPhotoUpload(filename,file,storepath,field){
            this.$emit('onPhotoUpload',filename,file,storepath,field)
        },
        NewFieldDragfinish(NewFieldDragging,NewFieldDraggingIndex,section,sectionindex, event){
            //console.log(NewFieldDragging,NewFieldDraggingIndex,section,sectionindex, event)
            this.$emit('NewFieldDragfinish',NewFieldDragging,section)
        },
        GetHeaderGraphics(section){
            //console.log(section)
        // /.HasParallaxGradient
        //.ParallaxGradient
        if(!section.HasParallaxGradient || !section.ParallaxGradient){
          return section.HasImage && section.IMG? { backgroundImage: 'url('+section.IMG+')',backgroundSize: 'cover' } : { backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }
        }
        else{
          //background: linear-gradient(338deg, rgba(0,0,0,0.9528186274509804) 0%, rgba(207,48,48,0.5382528011204482) 0%, rgba(21,101,184,0.639093137254902) 100%);
          let gradient = section.ParallaxGradient.split('background: ')[1].split(';').join(',')
          return section.HasImage && section.IMG? { backgroundImage: gradient+'url('+section.IMG+')',backgroundSize: 'cover' } : { backgroundImage: 'linear-gradient(to right, #E53935cc, #FFCDD2cc),url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }
        }
        
      },
        ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean) 
        },
        ActivateMultipleFilesUploadNotification(boolean){
        this.$emit('ActivateMultipleFilesUploadNotification',boolean) 
        },
        NewTableDragfinish(NewTableDragging,NewTableDraggingIndex,section,sectionindex, event){
            alert('ayayayayay')
            //console.log(NewTableDragging,NewTableDraggingIndex,section,sectionindex, event)
            this.$emit('NewTableDragfinish',NewTableDragging,NewTableDraggingIndex,section,sectionindex, event)
        },
        OpenDialog(elmnt,index,boolean){
            this.$emit('OpenDialog',elmnt,index,boolean,this.tabindex)
        },
        UpdateRecordCalculation(Record,field,value){
        this.$emit('UpdateRecordCalculation',Record,field,value)
      },
        UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            //console.log(prop,value,AdditionalSaveMethod,FieldObject)
            
            this.$emit('UpdateEditableField',prop,value,AdditionalSaveMethod,FieldObject)
    //   FieldObject[prop] = value
    //   console.log(AdditionalSaveMethod)
    //   if(AdditionalSaveMethod){
    //     this[AdditionalSaveMethod]()
    //   }
    },
    }
}
</script>

<style>

</style>