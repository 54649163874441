
<template>
<v-card
      flat
      class="transparent"
      
    >
    <v-dialog max-width="400" v-model="NewUserDialog">
      <v-card max-width="400">
        <v-card-title class="recordtoolbar white--text mediumoverline">
          New User
        </v-card-title>
        <v-card-text>
          <v-text-field :rules="[$store.state.formrules.required]" label="Name" v-model="NewUser.Name"/>
                <v-text-field :rules="[$store.state.formrules.required]" v-model="NewUser.Surname" label="Surname"/>
                 <!-- <v-text-field :rules="[$store.state.formrules.email,$store.state.formrules.required]" label="Email" v-model="NewUser.Email"/>
                  <v-text-field label="Mobile_Number" :rules="[$store.state.formrules.telnr,$store.state.formrules.required]" v-model="NewUser.Mobile_Number"/> -->
               <v-img class="black" height="200" contain									
                    :src="NewUser.Profile_Photo ? NewUser.Profile_Photo : require('@/assets/ImageHolder.png')"									
                    >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                        <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteUserPic','Profile_Photo')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change Profile Pic</v-chip>
                    </v-row>
              </v-img>
              <v-img class="black" height="200" contain									
                    :src="NewUser.Parallax ? NewUser.Parallax : require('@/assets/ImageHolder.png')"									
                    >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                        <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteUserPic','Parallax')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change Parallax</v-chip>
                    </v-row>
              </v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelNewUserDialog()" outlined dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
           <v-btn @click="CreateNewSampleUser()" outlined dark color="green">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
             <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}} Members</h1>
                <h4 class="headline">{{Computedgroupmembers.length}}</h4>
              </v-col>
            </v-row>
             
            </v-parallax> 
<v-layout class="justify-center my-6">
<v-flex xs12 s12 md11 lg11>
        <v-toolbar rounded :style="{backgroundImage: GroupsGradient}" elevation="12" color="primary white--text">
          <v-toolbar-title  class="mediumoverline">
          <v-icon  class="mx-1" x-large color="white">mdi-face</v-icon>{{groupName}}</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
        <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You changed a User Role.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
            <v-snackbar v-model="contributorsnackbar" :timeout="8000" top color="success">
      <span>Awesome! You added a Contributor, view them all and finalize their Contributor Profiles in "Member Contributors".</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-text-field dark
        v-model="search"
        append-icon="mdi-file-find-outline"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
         <v-btn outlined @click="ActivateNewUserDialog()" color="red" v-on="on" v-if="$store.state.TemplateView && UserisTemplateOwner"><v-icon>mdi-plus</v-icon>Add User</v-btn>
          </v-toolbar>
            <v-row dense class="justify-center" style="padding-top:10px;">
              <v-col 
                v-for="(member,memindex) in Computedgroupmembers" :key="member.id"
                :cols="MemberCols"
              >
              <v-card 
                  height="100%"
                  width="300"
                  tile 
                >
                <v-list-item class="justify-center" style="padding-top:0px;">
                 <SiteMemberAvatar :System="System" :SystemEntities="SystemEntities" :member="member"
          />
           
                </v-list-item>
                <v-card-title class="justify-center" style="font-weight: 400;font-size: 1em;">
                   {{member.Full_Name}}
                </v-card-title>
                <v-list-item dense class="justify-center background darken-1" >
                   <v-btn :to="member.FeaturedMemberSite" icon v-if="FeaturedMemberActive && member.FeaturedMemberSite" >
                    <v-icon color="pink" small>mdi-account-star-outline</v-icon>
                  </v-btn>
                  <v-btn :disabled="!member.GroupSite" icon >
                    <!-- member.GroupSite -->
                    <v-icon  color="orange" small>mdi-account-group</v-icon>
                  </v-btn>
                  <v-btn :href="member.FacebookLink" target="_new" :disabled="!member.FacebookLink" icon >
                    <!-- member.LinkedInLink -->
                    <v-icon color="blue darken-1" small>mdi-facebook</v-icon>
                  </v-btn>
                  <!-- <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn> -->
                  <v-btn :href="member.LinkedInLink" target="_new" :disabled="!member.LinkedInLink" icon >
                    <!-- member.LinkedInLink -->
                    <v-icon color="blue darken-4" small>mdi-linkedin</v-icon>
                  </v-btn>
                </v-list-item>
                <v-card-text v-if="member.AboutMe" style="padding-bottom:50px;">
                  <div  class="caption" style="text-align: center;" v-html="smart_substr(member.AboutMe,150)">
                  </div>
                </v-card-text>
                <v-card-text v-if="!member.AboutMe" style="text-align: center;padding-bottom:50px;">
                  <div class="caption" v-html="`Hi there!<br><br>I am a member of the `+RouteName+` Network.`">
                  </div>
                </v-card-text>
                
                <v-card-actions style="position: absolute;bottom:0px;right:0px;">                  
                  <!-- <v-btn class="fancybtn" color="grey" outlined>View Profile</v-btn> -->
                  <v-spacer>
                  </v-spacer>
                   <v-btn :to="SingleRoutePrefix+member.id" class="fancybtn " color="grey"  outlined>View Profile</v-btn>
                </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
    </v-flex>
 </v-layout>
</v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SiteMemberAvatar from '@/components/SocialNetwork/SiteMemberAvatar';
export default {
props: ['UserRecord','FriendsList','System'],
components: {SiteMemberAvatar},
    data() {
        return {
          UserPicProp: '',
          NewUserDialog: false,
          NewUser: {
            Name: '',
            Surname: '',
          },
          DefaultNewUser: {
            Name: '',
            Surname: '',
          },
          Company: 'Ignite Youth',
          hover: false,
          showerror: false,
          tags: [
                    'Auckland',
                    'Wellington',
                    'Very long string that would overflow'
                ],
          snackbar: false,
          contributorsnackbar: false,
              UserRolesType: [
      {index: 0, text: 'Follower'},
      {index: 1, text: 'Editor'},
      {index: 2, text: 'Administrator'},
        ],
        ListingContributors: false,
        EditingContributors: false,
        blogdialog: false,
        contributordialog: false,
        dialog: false,
        search: '',
        servicesearch: '',
        orgservicesearch: '',
        groupblogs: [],
        groupmembers: [],
        contributorgroups: [],
        contributingmembers: [],
        groupName: null,
        groups: [],
        users: [],
        // membersofgroup: [],
        groupid: this.$route.params.id,
        content: null,
        logo: '',
        image: '',
        description: null,
        Network: false,
        Editing: false,
        name: null,
        ServicestoOrganizations: [],
        DefaultServicestoOrganizations: [],
        ServicestoIndividuals: [],
        DefaultServicestoIndividuals: [],
        newservicetoindividual : {
          index: '', Name: '' ,checked: true
        },
        defaultservicetoindividual: {
          index: '', Name: '', check: true
        },
        editedContributor: {
              id: null,
              memberid: null,
              groupmemberid: null,
              ContributionCategory: null,
              ContributetoIndividuals: false,
              IndividualContributionDescription: null,
              OrganizationContributionDescription: null,
              ContributetoOrganizations: false,
              FreeConsultation: false,
              ServiceCategorytoIndividuals: null,
              //consulting,legal,financial,technical specialist,other
              ServiceCategorytoOrganizations: null,
              //consulting,legal,financial,technical specialist,other
              ServicestoIndividuals: [],
              ServicestoOrganizations: [],
              DefaultServicestoOrganizations: [],
              DefaultServicestoIndividuals: [],         


        },
            editedItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            defaultItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            blogtitle: null,
            blogcontent: null,
            image: null,
            //userid: this.$store.state.user.id,
            UserisMember: false,
            UserisAdmin: false,
            tetempusid: "'GNagVng6n1VgjtSXXrHDdEejjM93'"+","+"'Sk7cvSO6uXMJdN2qwJW5C9LSwzj1'", 
            Changes: false,
            GroupCategory: null,
            Contributorprofilefield: '',
            helpnewsheet: false,
            // MinistryContributionstoIndividialNames: '',
            MinistryContributionstoIndividualArr: [],
            BusinessContributionstoIndividualArr: [],
            FamilyContributionstoIndividualArr: [],
            MinistryContributionstoOrganizationsArr: [],
            BusinessContributionstoOrganizationsArr: [],
            FamilyContributionstoOrganizationsArr: [],
            HasOrgServices: false,
            HasIndServices: false,      
        
        }
    },
    computed: {
      ReferencedPluginDataBase(){
        // console.log('this.ActiveMarketTemplate',this.ActiveMarketTemplate)
        return this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.PluginDataBase ? this.ActiveSuiteTemplate.PluginDataBase : 
        this.ActiveMarketTemplate && this.ActiveMarketTemplate.PluginDataBase ? {} : this.PluginDataBase
    },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      FeaturedMemberActive(){
        return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
      },
      FeaturedMembersAllowed(){
          return this.FeaturedMemberActive && this.userLoggedIn.IsFeaturedMember || this.FeaturedMemberActive && this.userIsAdmin
          // && this.userIsAdmin || this.FeaturedMemberActive && this.System && this.System.Featured_Members_Self_Manage
      },
      SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/SiteMember/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/SiteMember/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/SiteMember/' : '/SiteMember/'
        }
      },
      GroupsGradient(){
      return this.$vuetify.theme.dark ? 'linear-gradient(338deg, '+this.System.Light_Social_App_SearchBar.hex+'EE 0%, '+this.System.Dark_Social_App_SearchBar.hex+'DD  100%)': 'linear-gradient(338deg, '+this.System.Dark_Social_App_SearchBar.hex+'DD 0%, '+this.System.Light_Social_App_SearchBar.hex+'DD  100%)' 
      },
      MemberCols(){
        if(this.$vuetify.breakpoint.xs){
          return 11
        }
        else if(this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else {
          return 2
        }
      },
      MyFriends(){
        return this.$store.state.FriendshipsArray
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      Computedgroupmembers(){
        return this.users.map(member => {
          if(member.Created_On){
            member.MembershipDate = this.TimestampFormatterSTRING(member.Created_On.toDate())
          }
           if(member.MemberAvatarRibbon && this.System.Member_Ribbon){
              let match = this.System.Member_Ribbon.Options.find(obj => obj.ID === member.MemberAvatarRibbon.ID)
              member.MemberAvatarRibbon = match
            }
          
          return member
          
        }).filter(member => {
          return member.Full_Name.toLowerCase().includes(this.search.toLowerCase())
        })
        // .filter(member => {
        //   return member.id !== this.userLoggedIn.id
        // })
        
                    
       },
       UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
       SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SystemConfig').doc(this.ActiveSuiteTemplate.id) : 
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
    },
   watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleSiteUserPic']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      MyFriends (value) {
            if (value) {
              //this.FriendsFirst()
            }
      },
      FriendsList (value) {
        console.log('FriendsList',value.length,value)
            if (value && value.length > 0) {
              //this.MyFriendsFirst(value)
            }
      },
        // MyFriends: {
        //     handler: function(oldvalue, newvalue) {
        //         if(oldvalue && newvalue && oldvalue.length !== newvalue.length){
        //            this.FriendsFirst()
        //         }
        //     },
        //     deep: true
        // },
    },
        created() {    
          if(!this.$store.state.TemplateView){
          setTimeout(() => {
            console.log(this.UserRecord)
          }, 1000); 
             this.IntranetViewToggle() 
             console.log(this.FriendsList)
              // let anybodyquery = db.collection('users').where('MyProfileViewPermissions','==','Anybody')
              // this.getUsers(anybodyquery)            
        //this.FriendsFirst()
        //let friendslength = this.FriendsList.length
        //this.MyFriendsFirst(this.FriendsList)
        let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users')
        this.getUsers(query)
          }
          else{
            this.getUsers(this.ConfigPath.collection('sampleusers'))
          }
    },

     methods: {
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      ActivateNewUserDialog(){
        this.NewUserDialog = true
      },
      CancelNewUserDialog(){
        this.NewUserDialog = false
        this.NewUser = Object.assign({},this.DefaultNewUser)
      },
      CreateNewSampleUser(){
        let dbref = this.ConfigPath.collection('sampleusers')
        let ref = dbref.doc()
        let newdocid = ref.id
        this.NewUser.Full_Name = this.NewUser.Name+' '+this.NewUser.Surname
        this.NewUser.Email = this.NewUser.Name.split(' ').join('').toLowerCase()+'@example.com'
        this.NewUser.Mobile_Number = '+27721234567'
        dbref.doc(newdocid).set(this.NewUser).then(newuse => {
          this.CancelNewUserDialog()
        })
      },
      ActivateGalleryDialog(prp,localprp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
        this.UserPicProp = localprp
      },
      AssignSampleSiteUserPic(){
        this.NewUser[this.UserPicProp] = this.SelectedImage.fileurl
       this.RefreshDialog()
       this.UserPicProp = ''
        this.$store.commit('setIMGProp','')
      },
      RefreshDialog(){
        this.NewUserDialog = false
        setTimeout(() => {
            this.NewUserDialog = true
        }, 20);
      },
       MyFriendsFirst(value){
         let length = value.length
         value.map((friend,i) => {
                let oncheck = this.users.find(obj => obj.id === friend.id)
                if(!oncheck){
                  this.users.push(friend)
                  let FOFlist = friend.FriendIDStrings
                  .filter(fof => {
                    return fof !== this.userLoggedIn.id
                  })
                  .filter(fof => {
                    let myfriend = value.find(obj => obj.id === fof)
                    if(!myfriend){
                      return fof
                    }
                  })
                  console.log('FOFlist',FOFlist)
                  if(FOFlist.length > 0){                    
                    FOFlist.map(fofid => {
                      this.GetFriendofFriend(fofid)
                    })
                  }
                  
                }
                if(length === i-1+2){
                  let anybodyquery = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').where('MyProfileViewPermissions','==','Anybody')
                  this.getUsers(anybodyquery)
                }
              })
       },           
       
       GetFriendofFriend(fofid){
         console.log(fofid)
         db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(fofid).onSnapshot(snapshot => {
           let userobj = snapshot.data()
           userobj.id = fofid
           console.log(userobj)
            let oncheck = this.users.find(obj => obj.id === fofid)
            if(!oncheck){
              this.users.push(userobj)
            }
         })
       },
       IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
       TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        ToggleHelp() {         
            this.helpnewsheet = true
          },

        editItem (item) {
        this.editedIndex = this.GroupMembersWithSearch.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        // this.Editing = true
      },

      

   
        
    GetUserBUInformation(userobj){
      let vm = this
      return new Promise(function(resolve, reject) {
      db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').doc(userobj.Business_Unit.id).onSnapshot(snapshot => {
          let buobj = snapshot.data()
          userobj.Business_Unit = buobj
          userobj.Business_Unit.id = userobj.Business_Unit.id
          resolve(userobj)
        })
      })
    },
        
    getUsers(query) {
      let vm = this
      query.onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              let userobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              let oncheck = this.users.find(obj => obj.id === userobj.id)
                if(!oncheck){
                  if(userobj.Business_Unit){
                    this.GetUserBUInformation(userobj).then(result => {
                    vm.users.push(result)
                  })
                  }
                  else{
                    this.users.push(userobj)
                  }
                }
            }  
          })
        })    
        },
          
            
     }
}
</script>

<style>

.tags-input {
    border: 1px solid #333;
    display: inline-block;
}
.UserName {
    font-size: 10px;
    font-weight: bold;
    color: #474747
}
.Memo {
      font-size: 10px;
}
.userprofileBG {
  align-self: center
}
.v-avatar.outlined {
                  border: 8px solid #048abf;
                  border-radius:100%;

}
.searchguide {
  color: #e4003b;
  font-size: 14px
}

</style>

    

    