<template>
  <v-card>
    <v-card-title class="recordtoolbar white--text">
    Invite User
</v-card-title>
<v-card-text v-if="!Has_Member_Groups">
    <v-switch v-if="userIsAdmin" v-model="InviteData.IsGuestUser" :readonly="!userIsAdmin" label="Guest User" />
    <v-text-field v-model="InviteData.Email" label="Email" :rules="[$store.state.formrules.required,$store.state.formrules.email]" />
    <v-select v-if="userIsAdmin" v-model="InviteData.Business_Unit" :items="PreparedBusinessUnitsArray" :readonly="!userIsAdmin" label="Business Unit" :rules="[$store.state.formrules.required]" item-text="Name" return-object/>
    <v-select v-if="userIsAdmin" v-model="InviteData.InvitingUserRoles" :items="AllSecurityRoles" label="Roles" item-text="Name" multiple/>
    <v-select v-if="!InviteData.IsGuestUser && userIsAdmin" v-model="InviteData.Primary_Appid" :items="$store.state.AppsDataBase" label="Primary App" item-text="id"/>
    <v-select v-if="userIsAdmin" multiple label="TableFilters" :items="SystemTableFilters" return-object dense item-text="Name" clearable
    v-model="InviteData.UserTableFilters"></v-select>
    <!-- <div contenteditable id="previewsendemail" class="postinputbox" v-html="PresetEmailBody"/> -->
</v-card-text>
    <v-card-text v-if="Has_Member_Groups">
    <v-text-field v-model="InviteData.Email" label="Email" :rules="[$store.state.formrules.required,$store.state.formrules.email]" />
    <v-select v-if="!UserisGuest" v-model="InviteData.Business_Unit" :items="PreparedBusinessUnitsArray" :readonly="!userIsAdmin" label="Business Unit" :rules="[$store.state.formrules.required]" item-text="Name" return-object/>
    <v-select v-model="InviteData.MemberGroup" return-object :items="InviteMemberGroupsArray" item-text="id" label="Invite Group"
    :disabled="!userIsAdmin && !ActiveMemberGroup.AdditionalProperties ||
    !userIsAdmin && ActiveMemberGroup.AdditionalProperties && ActiveMemberGroup.AdditionalProperties.InviteGroups.length === 1"
    ></v-select> 
    </v-card-text>
    <v-card-text v-if="PersonalizedMessageAllowed">
      Want to write a personalized Message?
      <ContentEditableField style="padding:15px;"
      :FieldObject="InviteData" :FieldName="'EmailBody'"
      :FieldValue="InviteData.EmailBody" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
    </v-card-text>
     <v-card-actions>
          <v-btn v-if="ShowCancel" @click="CancelInvitingUserEmail()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn outlined @click="SendInvitetoUser()">{{InviteText}}</v-btn>
        </v-card-actions>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'	
export default {
    props: ['System','SystemEntities','editedInvite','SystemTableFilters','InviteText','ShowCancel','PersonalizedMessageAllowed'],
    components: {ContentEditableField},
    data() {
        return {
            InviteData: {},
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        }
    },	
    computed:{
      UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      InviteMemberGroupsArray(){
        return this.MemberGroupsArray.filter(memgroup => {
          return this.userIsAdmin && memgroup.id !== 'System_Administrators' || 
          this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties && this.ActiveMemberGroup.AdditionalProperties.InviteGroups.includes(memgroup.id) && memgroup.id !== 'System_Administrators'
        })
      },
      MemberGroupsArray(){
            return this.$store.state.MemberGroupsArray
        },
        PreparedBusinessUnitsArray(){
        return this.BusinessUnitsArray.map(bu => {
          return {Name: bu.Name,id: bu.id}
        })
      },
      BusinessUnitsArray(){
      //console.log('this.$store.state',this.$store.state)
      return this.$store.state.BusinessUnitsArray
    },
        Has_Member_Groups(){
         return this.PluginDataBase && this.PluginDataBase.Member_Groups ? this.PluginDataBase.Member_Groups.Active : false
       },
       PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.InviteData = Object.assign({},this.editedInvite)
        if(this.userLoggedIn.IsGuestUser){
            this.InviteData.IsGuestUser = true
        }
         if(this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties && this.ActiveMemberGroup.AdditionalProperties.InviteGroups.length === 1){
          this.InviteData.MemberGroup = this.MemberGroupsArray.find(obj => obj.id === this.ActiveMemberGroup.AdditionalProperties.InviteGroups[0])
        }
        if(!this.userIsAdmin || this.UserisGuest){
          this.InviteData.Business_Unit = {id: 'Guest',Name: 'Guest'}
          this.InviteData.Business_Unitid = 'Guest'
        }
        if(!this.InviteData.Status){
          this.InviteData.Status = {
              ID: 1000001,
              Name: 'Sent'
            }
        }
    },
    methods:{
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
        SendInvitetoUser(){
            this.$emit('SendInvitetoUser',this.InviteData)
        },
        CancelInvitingUserEmail(){
            this.$emit('CancelInvitingUserEmail')
        },
    }
}
</script>

<style>

</style>



