
<template>
 <v-layout class="justify-center">
     <v-dialog v-model="previewsendemaildialog" max-width="1200">
        <v-card width="100%">
     <EmailComponent :PresetEmailBody="EmailBody" :PresetEmailFrom="EmailFrom" :PresetEmailTo="EmailTo" :PresetEmailCC="EmailCC" :PresetEmailBCC="EmailBCC" :PresetEmailSubject="EmailSubject" :previewsendemaildialog="previewsendemaildialog"/>
        </v-card>
     </v-dialog>
    <v-dialog v-model="ChartsView" max-width="1200">
        <v-card width="100%">
       
        <v-card-text>

        
            <v-data-table
            :dark="AppisDarkMode"
            :headers="ResponseHeadersComputed"
            :items="RepsonsesComputed"
            show-select                                               
            v-model="UsersSelected" 
            >
            <template v-slot:top>
        <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12"  color="primary white--text">
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white">mdi-message-reply-text</v-icon>Responses</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-sequential</v-icon>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-grid</v-icon>
         
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
             
                  
                <v-list-item class="listoutline" @click="ActivateEmail()">
                  <v-list-item-title class="subtleoverline">
                    Email
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="blue">mdi-chart-bar</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
            </template>
            </v-data-table>
        </v-card-text>
        </v-card>
    </v-dialog>
    <v-card class="my-3 mx-3" style="padding-bottom:50px;"  width="100%" v-if="Notice">
        <v-card-actions style="font-size:10px">
        <!-- <v-chip small color="secondary" class="white--text">
            {{Notice.creatorname}}
        </v-chip> -->
        <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisModerator"
                                 v-if="userLoggedIn.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="pop" class="white--text" v-on="on" v-if="userLoggedIn.id">
                              {{PublishStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <div  v-for="status in PublishStatusOptions" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisModerator"
                                 v-if="userLoggedIn.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateStatus(status.Name)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>      
        <v-spacer></v-spacer>
        
        <v-chip large color="links" class="white--text" v-if="Notice.Category">
            {{Notice.Category.Name}} <v-icon  class="white--text">
                 mdi-calendar
             </v-icon>
        </v-chip>
        <span  >{{Notice.createddate}}</span>
        </v-card-actions>
        <v-img
        height="350px"
        contain
        v-bind:src="Notice.coverimage"
        v-if="!Notice.ImageShy"
        ></v-img>
        
            <v-card-title class="headline">{{Notice.title}}</v-card-title>
                    
        <v-card-actions style="font-size:16px" class="mx-3 grey--text">
        {{Notice.createddate}}
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text v-if="Notice.SocialShareItem && Notice.SocialShareItem.IsLink && Notice.SocialShareItem.InternalLink && Notice.SocialShareItem.ComponentName">
          <SocialPostSharedItem :LinkID="Notice.SocialShareItem.LinkID" :collectionname="Notice.SocialShareItem.collectionname" :UserRecord="UserRecord" :Location="Notice.SocialShareItem.InternalLinkType"/>
        </v-card-text> 

        <v-card-text v-if="!Notice.SkipWriteup" v-html="Notice.description">
        </v-card-text>
        
        <v-card-text v-if="Notice.SkipWriteup">
            <v-layout class="justify-center" v-if="Notice.ContentisEmbeddedDocument && Notice.DocumentSource">
                    <embed width="80%" height="700" :src="Notice.DocumentSource"/>
                </v-layout>
        </v-card-text>
       <v-card-text>
          <v-divider></v-divider>
          <v-card-title>
          Attachments:
          </v-card-title>
          <v-layout row class="align-center mx-2"  v-if="Notice.AttachmentsArray && Notice.AttachmentsArray.length>0">
                   <v-list two-line subheader light align="left" width="100%"><span v-for="item in Notice.AttachmentsArray" :key="item.itemObjKey">                     
                      <v-list-item class="basicoutlined">
                        <v-layout row class="justify-start">
                          <v-list-item-avatar>
                            <v-icon color="blue">mdi-file-document</v-icon>
                            </v-list-item-avatar>
                           <v-list-item-content>
                                <v-list-item-title>{{item.Name}}</v-list-item-title>
                            </v-list-item-content>                             
                              <v-list-item-action>                               
                                    <v-btn icon :href="item.Path" target="_New">
                                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                                  </v-btn>                              
                              </v-list-item-action>                           
                          </v-layout>
                        </v-list-item></span>
                    </v-list>
                </v-layout>
        </v-card-text>


        <v-form>
        <v-card-text>
        You can Read More here                   
    </v-card-text>
    <v-card-actions>
        <v-btn v-if="!Notice.ContentisEmbeddedDocument" small class="links white--text" dark :to="Notice.RelatedLink">Read More</v-btn>
        <v-btn v-if="Notice.ContentisEmbeddedDocument" small class="links white--text" dark :href="Notice.RelatedLink" target="_blank">Read More</v-btn>
    </v-card-actions>

    <v-card-actions v-if="!UserConfirmed">
        <v-checkbox v-if="Notice.MustConfirm"
        v-model="Confirm"
        :rules="[v => !!v || 'You must agree to continue!']"
        label="Acknowledge and Confirm?"
        required
        ></v-checkbox>
        
    </v-card-actions>
    <v-card-actions v-if="!UserConfirmed">
        <v-spacer></v-spacer>
            <v-btn                                        
            color="success"
            class="mr-4"
            :disabled="!Confirm"
            @click="SubmitResponse()"
            >
            Submit
            </v-btn>
    </v-card-actions>
    <v-card-actions  v-if="UserConfirmed">
           You have already confirmed on {{UserconfirmationDate.toDate()}} 
    </v-card-actions>
        </v-form>
        
    </v-card>
    <v-layout column class="fab-container">
         
           <v-icon @click="ToggleChartsView()" v-if="UserisModerator" color="blue" x-large>mdi-reply</v-icon>
            <v-icon @click="OpenEditor()" v-if="UserisModerator || userIsAdmin" color="red" x-large>mdi-pencil</v-icon>


  </v-layout>
    </v-layout>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import EditArticle from '@/components/SuitePlugins/Articles/Edit/Single'
import EmailComponent from '@/components/General/EmailComponent';
import SocialPostSharedItem from '@/components/SocialNetwork/SocialPostSharedItem';
export default {
    props: ['System'],
      components: {
        EditArticle,
        EmailComponent,
        SocialPostSharedItem
  },
    data() {
        return {
          PublishStatusOptions: [
            {
              Name: 'Draft',
              Class: 'green',
              Warning: 'Unpublish the item?'
            },
            {
              Name: 'Published (Internal)',
              Class: 'warning',
              Warning: 'Make the item avialable for viewing by all Users?'
            },
            // {
            //   Name: 'Published (Public)',
            //   Class: 'error',
            //   Warning: 'Make the item available for viewing by the Public?'
            // }
          ],
            EmailBody: '',
            EmailSubject: '',
            EmailFrom: '',
            EmailTo: [],
            EmailCC: [],
            previewsendemaildialog: false,
            UsersSelected: [],
            ChartsView: false,
            UserConfirmed: false,
            UserconfirmationDate: '',
            Confirm: false,
            Notice: '',
          PublicArticles: [],
          SiteModerators: [],
         Moderators: [],
            articlequil: false,
            snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        articledialog: false,
        articlecontent: '',
        PubStatus: '',
        UserIsNOTModerator: true,
        UserisModerator: false,
        Responses: [],
        ResponseHeaders: [
            { text: 'Full Name', value: 'Full_Name',class: "overline"},
            { text: 'Read', value: 'Read',class: "overline"},
            { text: 'ReadDate', value: 'ReadDate',class: "overline"},
            { text: 'Confirmed', value: 'Confirmed',class: "overline", ConfirmedOnly: true},
            { text: 'Confirmed Date', value: 'ConfirmDate', sortable: false ,class: "overline", ConfirmedOnly: true},
        ],
        IsAuthor: false,
        author: null,
        authorid: null,
        //userid: this.$store.state.user.id,
        grouparticles: [],
        groups: [],       
        articleid: this.$route.params.id,
        content: null,
        image: '',
        ImageShy: false,
        createdon: '',
        articlepubDate: '',
        UserCanEdit: false,
        ArticlePrivacy: null,
        PublishStatus: null,
        
        PublishStatusTypes: [
        {index: 0, text: 'Draft'},
        {index: 1, text: 'Published'},
          ],  
          imagethumb: '',
        caption: '',
        title: null,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                ArticlePrivacy: null,
                PublishStatus: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                ArticlePrivacy: null,
                PublishStatus: null,                
            },
            UserRecord: {},
            GroupData: {},
            EditingArticle: false,    
        }
    },
    computed: {
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
    },
     SocialItemInteractMethodProp(){
      return this.$store.state.SocialItemInteractMethodProp
    },
    SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupNotice' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Notice' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
       userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        ResponseHeadersComputed(){
          return this.ResponseHeaders.filter(header => {
            if(!this.Notice.MustConfirm){
              if(header){
                return !header.ConfirmedOnly
              }
            }
            else{ 
              return header
              }
          })
        },
        UsersStore(){
            return this.$store.getters.getUsersArray
        },
        RepsonsesComputed(){
            this.Responses.map(not => {
                if(not.ConfirmDate && typeof not.ConfirmDate.toDate !== 'undefined'){
                    not.ConfirmDate = this.TimestampFormatterNoticeBoard(not.ConfirmDate.toDate(),'full')
                }
                if(not.ReadDate && typeof not.ReadDate.toDate !== 'undefined'){
                    not.ReadDate = this.TimestampFormatterNoticeBoard(not.ReadDate.toDate(),'full')
                }
                return not
            })
            return this.UsersStore.map(user => {
                let matchobj = this.Responses.find(obj => obj.id === user.id)
                if(matchobj){
                    user.Read = matchobj.Read
                    user.ReadDate = matchobj.ReadDate
                    user.Confirmed = matchobj.Confirmed
                    user.ConfirmDate = matchobj.ConfirmDate
                }
                return user
            }).filter(user => {
              if(!this.Notice.GuestsIncluded){
                return !user.IsGuestUser
              }
              else{
                return user
              }
            })
        },  
        userLoggedIn () {
      return this.$store.getters.user
    },
        RelatedArticles () {
            return this.grouparticles.filter(article => {
                return article.articleid === this.articleid
            })
        },
            
    },
    watch: {
          UserisModerator(value) {
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)              
            }
        },
          SocialItemInteractMethod(v){  
            if(v){
              if(this.SocialItemInteractMethodProp){
                //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
                this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
                this.$store.commit('SetSocialItemInteractMethodProp','') 
                this.$store.commit('SetSocialItemInteractMethod','')
              }
              else{ 
                this[this.SocialItemInteractMethod]()
                this.$store.commit('SetSocialItemInteractMethodProp','') 
                this.$store.commit('SetSocialItemInteractMethod','')
              }          
            }
          }
        },
    created() {
        document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
        
      this.GetRequestingUser()
        
        
        
    },
    methods: {
      ToggleChartsView(){
        this.ChartsView = !this.Chartsview
      },
      OpenEditor(){
        this.$router.push('/NoticeNew/'+this.$route.params.id)
      },
        ActivateEmail(){
            console.log(this.UsersSelected)
            this.previewsendemaildialog = true
            this.EmailTo = this.UsersSelected.map(user => {
                return user.Email
            })
            this.EmailFrom = this.UserRecord.Email
            this.EmailSubject = 'Please repsond to Notice - '+this.Notice.title
            this.EmailBody = `Good Day
            <p>You are yet to respond to the Notice <strong>`+this.Notice.title+`</strong></p>
            Please respond as there is a Due date of `+this.Notice.DueDate.toDate()+`
            <br><br>
            Please click <a href="`+window.location+`">here</a> to view the Notice`
            let UserManagerids = this.UsersSelected.filter(user => {
                return user.Manager
            }).map(user => {
                return user.Managerid
            })
            this.EmailCC = UserManagerids.map(manid => {
                let managerobj = this.UsersStore.find(obj => obj.id === manid)
                return managerobj.Email
            })
            
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        NavigatetoNoticeBackEnd(){

        },
        SubmitResponse(){
            if(this.Confirm){

                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(this.$route.params.id).collection('responses').doc(this.UserRecord.id).update({
                    ConfirmDate: new Date(),
                    Confirmed: this.Confirm,
                })
            }
        },
    
      StopEdit(){
        this.EditingArticle = false
      },
      EditItem(){
        this.EditingArticle = true
      },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
      UpdateStatus(status){
        if(this.$route.name === 'GroupNotice'){
          db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupnotices').doc(this.$route.params.slug).update({
            PublishStatus: status
          })
        }
        else{
          db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(this.$route.params.id).update({
            PublishStatus: status
          })
        }
      
    },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetGroup(){
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                this.GroupData = snapshot.data()
                // this.GetForumDiscussion()
            })
        },
        async GetRequestingUser(){
        let vm = this;
        if(this.userLoggedIn){
            this.UserRecord = this.userLoggedIn
            this.GetNoticeInformation()
            this.IntranetViewToggle(true)
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
        addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },

    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'articlecontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
            
        UpdateArticle(){
          console.log(this.editor.scrollingContainer.innerHTML)

            const UpdatedArticle = {
                content: this.editor.scrollingContainer.innerHTML
            }

            db.collection('grouparticles').doc(this.articleid).update({
                content: UpdatedArticle.content
            });
              this.snackbar = true
            this.articlequil = false
        },
                UpdateArticleSettings(){


            const UpdatedArticle = {
                PublishStatus: this.editedItem.PublishStatus,
                ArticlePrivacy: this.editedItem.ArticlePrivacy
            }

            db.collection('grouparticles').doc(this.articleid).update({
                PublishStatus: UpdatedArticle.PublishStatus,
                ArticlePrivacy: UpdatedArticle.ArticlePrivacy
            });
              this.snackbar = true
            this.articlequil = false
        },
        onEditorChange() {
// alert('change')
        },
        getPublicArticleInformation(){
          let vm = this
          return new Promise(function(resolve, reject) {
        db.collection('sitearticleswiki').where('PublishStatus','==','Published (Public)').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.PublicArticles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        }).then(function(result) {
          let articlecollection = db.collection('sitearticleswiki').where('PublishStatus','==','Published (Public)')
          
          articlecollection.onSnapshot(snapshot => {
                  // this.UpdateRequestStatus()
                  var articledata = vm.PublicArticles.find(obj => obj.PublicURL == window.location.pathname.split('/')[2])
                console.log(articledata)
                vm.caption = articledata.caption
                vm.title = articledata.title
                vm.content = articledata.content
                vm.articlecontent = articledata.content
                vm.author = articledata.author
                vm.authorid = articledata.authorid
                vm.PubStatus = articledata.PublishStatus
                if(articledata.ImageShy){
                  vm.ImageShy = articledata.ImageShy
                }
                
                vm.image = articledata.coverimage
                vm.imagethumb = articledata.coverimageThumbURL
                vm.createdon = articledata.createdon
                vm.pubdatastring = format(articledata.pubDate.toDate(),'yyyy-MM-dd')
                vm.articlepubDate = vm.TimestampFormatterSTRINGDayText(articledata.pubDate.toDate())
                vm.PublishStatus = articledata.PublishStatus
                vm.ArticlePrivacy = articledata.ArticlePrivacy
                vm.editedItem.ArticlePrivacy = articledata.ArticlePrivacy
                vm.editedItem.PublishStatus = articledata.PublishStatus
                console.log(vm.caption)
                })

        })
        },
    GetallResponses(){
        db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(this.$route.params.id).collection('responses').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
                this.Responses.push({
                ...change.doc.data(),
                id: change.doc.id
                })
            }


            })
        })
    },
    GetNoticeInformation() {
        let articlecollection = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices')
        let docref = articlecollection.doc(this.$route.params.id)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          console.log(articledata)
          var articledata = snapshot.data()
          if(articledata.PublishStatus){
            this.PublishStatus = articledata.PublishStatus
          }
            this.Notice = articledata
            let ModeratorCheck = this.Notice.Moderators.find(obj => obj.id === this.UserRecord.id)
            if(ModeratorCheck){
                this.UserisModerator = true
                this.GetallResponses()
            }
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(this.$route.params.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                let responsedocdata = responddoc.data()
                if(responsedocdata && responsedocdata.Confirmed){
                    this.UserConfirmed = true
                }
                if(responsedocdata && responsedocdata.ConfirmDate){
                    this.UserconfirmationDate = responsedocdata.ConfirmDate
                }
                
                if(!responsedocdata){
                    let ReadObj = {
                     Read: true, 
                     ReadDate: new Date(),
                     Full_Name: this.UserRecord.Full_Name 
                    }
                    db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(this.$route.params.id).collection('responses').doc(this.UserRecord.id).set(ReadObj).then(doc => {
                        console.log('updated as read ',doc)
                    })
                    
                }
            })
        // this.UserArticlerelation()
        })
    
        },
      OpenArticledialog() {
        this.articledialog = true
    },
    Closedialog() {
        this.articledialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
     save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.ArticlePrivacy = this.editedItem.ArticlePrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const article = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('grouparticles').add(article).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      },
      
   
     saveArticleSettings() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.ArticlePrivacy = this.editedItem.ArticlePrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const article = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('grouparticles').add(article).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {
align-content: center;
margin-left: 0.5em
}

</style>

    