<template>
  <div> 
   <TableMain v-if="!CurrentEntity" :System="System" :SystemEntities="SystemEntities"
            :ProvidedEntityid="col.EntityID" :TableOnly="true"
            :UnreadNotifications="UnreadNotifications"/>
  <TableEmbedTab 									
      v-if="CurrentEntity && Render" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
    :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"		
    :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef" :System="System"				
    :tab="RelatedTableTab" @OpenDialog="OpenDialog" :ParentTab="{}" :DynamicWikiData="Record" :SingleElementView="SingleElementView"	
    :RelatedTableDashView="true"							
    />
    
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TableMain from '@/components/Database/TableMain';
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';	
export default {
    props: ['System','SystemEntities','col','UnreadNotifications',
    'CurrentEntity','RelatedObj','EntityDataRef','EntityCollectionRef','Record'],
    components: {TableMain,TableEmbedTab},
    data() {
        return {
          Render: false,
          RelatedTableTab: {				
                EntitySource: 'System Routes',					
                RowColor: '#ffffff',									
                Name: '',
                DisplayName: '',
                DataViewName: 'Data Single Related Data',	
                DataSingleRelated: true,				
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-database-arrow-down-outline',									
                Height: 300,								
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 11,									
                FlexXSmall: 11,										
                Cols: 12,									
            },
        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.RelatedTableTab.Entityid = this.col.EntityID
      if(this.col.Table){
        this.RelatedTableTab.RelationshipField = this.col.Table
        this.PrepRelatedTables()
      }
    },
    methods:{
      PrepRelatedTables(){
        let ent = this.SystemEntities.find(obj => obj.id === this.RelatedTableTab.Entityid)
        let tablename = this.RelatedTableTab.Entityid.split('_').join('')
         let storequery = tablename+'Query'
                let storearray = tablename+'Array'
                this.RelatedTableTab.TableName = ent.DisplayName
                this.RelatedTableTab.StoreQuery = tablename+'Query'
                this.RelatedTableTab.StoreQuery = tablename+'Array'
                this.RelatedTableTab.StoreData = []
       
                
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  //console.log('this.$store.state[storearray]',this.$store.state[storearray],this.RouteID)
                  this.RelatedTableTab.StoreData = this.$store.state[storearray].filter(record => {
                    return record[this.RelatedTableTab.RelationshipField.id+'id'] === this.Record.id
                  })
                  this.Render = true
                  
                }
                else if(this.$store.state[storequery]){
                  this.GetRelatedData(this.RelatedTableTab,this.$store.state[storequery],this.RelatedTableTab.RelationshipField.id)
                  // let storepayload = {
                  //         query: this.$store.state[storequery],
                  //         arrayname: storearray
                  //       }
                  //       console.log(storepayload,vm.$store.state,storearray)
                  //       this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                          // relatedtable.StoreData = vm.$store.state[storearray]
                        // })
                } 
        },
        GetRelatedData(table,query,fieldid){        
        let fieldvalue = fieldid+'id'
          //alert(this.RouteID)
          //console.log('query',query)
          if(!Array.isArray(query)){
          query.where(fieldvalue,'==',this.RouteID).onSnapshot(res => {
            const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  table.StoreData.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
              })
              this.Render = true
          })
          }
          else{
            // let length = query.length
            // query.map((singlequery,singlequeryindex) => {
              query[0].where(fieldvalue,'==',this.RouteID).onSnapshot(res => {
                const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      table.StoreData.push({
                        ...change.doc.data(),
                        id: change.doc.id
                      })
                    }
                  })
              // })
            })
          }
      },
    }
}
</script>

<style>

</style>



