<template>
  <div>
      <v-autocomplete :multiple="IsMultiple" @change="UpdateUserLookupProp()" :readonly="ReadOnly" prepend-inner-icon="mdi-account-search" :filter="userFilter" :return-object="!IDSOnly" v-model="RecordObj[ModelProp]" :item-text="IDSOnly ? 'id' : 'Full_Name'" :items="UsersLookupArray" :label="FieldLabel">
                                   <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title class="caption">
                              Search by Name, E-mail or Mobile
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      <template v-slot:selection="data">
                        <v-chip small
                        >
                          <v-avatar size="50" left>
                            <img v-if="data.item.Profile_Photo" :src="data.item.Profile_Photo" style="object-fit: cover;">
                            <img v-if="!data.item.Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                          </v-avatar>
                          {{ data.item.Full_Name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="!data">
                          <v-list-item-content v-text="data"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar>
                            <img v-if="data.item.Profile_Photo" :src="data.item.Profile_Photo" style="object-fit: cover;">
                            <img v-if="!data.item.Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title >{{data.item.Full_Name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.Business_Unit" class="caption links--text" v-html="data.item.Business_Unit.Name"></v-list-item-subtitle>
                            <v-list-item-subtitle class="caption" v-html="data.item.Email"></v-list-item-subtitle>                            
                          </v-list-item-content>
                        </template>
                      </template>
                                </v-autocomplete>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','ModelProp','UsersLookupArray','FieldLabel','ReadOnly','Rules','RecordObj','IsMultiple','IDSOnly'],
    components: {},
    data() {
        return {
            rules: {
          numberfield: value => {
            const pattern = /^[0-9]+$/im;
            return pattern.test(value) || "Not a valid number.";
          },
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        UpdateUserLookupProp(){
            //console.log(this.ModelProp,this.RecordObj[this.ModelProp],this.RecordObj)
            this.$emit('UpdateUserLookupProp',this.ModelProp,this.RecordObj[this.ModelProp],this.RecordObj)
        },
        userFilter (item, queryText, itemText) {
          const Email = item.Email.toLowerCase()
          const Full_Name = item.Full_Name.toLowerCase()
          const Mobile = item.Mobile
          const searchText = queryText.toLowerCase()

          return Email.indexOf(searchText) > -1 || Full_Name.indexOf(searchText) > -1
      },
    }
}
</script>

<style>

</style>
