<template>
  <div>
     <!-- <v-card class="red" height="50" style="margin-top:50px;">
      <v-btn @click="RefreshComponent()">Refresh</v-btn>
         
     </v-card> -->
     <v-layout column class="fab-container" v-if="$route.query.Record">
         <!-- <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon> -->
     <v-btn v-if="$route.query.Record" @click="NavigatetoRouteParent()">Back to Parent</v-btn>
     </v-layout>
     <v-layout column class="fab-container" v-if="CurrentEntity && CurrentEntity.Entity_Type && CurrentEntity.Entity_Type.Name === 'Inventory Article'">
         <!-- <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon> -->
     <!-- <v-btn  @click="PublishReadyStoreItems()">Publish to Store</v-btn> -->
     </v-layout>
      <LandingPageSingle
      v-if="!SingleFormEditing && EntityReady && $route.name !== 'PDFExportFunctionBuilder' && $route.name !== 'DashboardBuilder' && $route.name !== 'CustomDashboard' && !FunctionEntityid || DashboardView && EntityReady" :DataSingleTabs="ComputedCollectionTabs"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="EntityDataRef" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"
       :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
       :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="'UpdateAccount'"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="$route.params.slug? CurrentEntity : DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
        :ActivitiesArray="ActivitiesArray" :RADB="RADB"
        :PrimaryFieldName="PrimaryFieldName"
       :ActiveEntityID="$route.meta.EntityID"/>
       
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';

export default {
    props: ['UsersArray','dashboardelementindex','IntranetView','RelatedObj','PageFilter','PageView','RADB','FunctionEntityid',
    'RelatedParentObj','RelatedGroupFilter','DashboardView','DashboardArray','CompSize','AllowsHistoric','ReferenceView',
    'DocTableCall','RenderComp','AppisDarkMode','System','SystemEntities','EntityRecord','EntityConfigSystemEntities','SystemPhotoLibraries'],
  components: {
    LandingPageSingle
  },
    data() {
        return{
            ArrayisEmpty: false,
             ConfigSystemEntities: [],
            PrimaryFieldName: '',
            ChangesMade: [],
            ViewAuditHistory: false,
            PageBGGradient: 'rgba(255,255,255,0.01)',
            SinglePageBGGradient: '',
            SingleFormEditing: false,
            OldStyle: false,
            CurrentEntity: '',
            EntityReady: false,
            SingleBuildTabs: [],
            SubCollections: [],
            DataArray: [],
            DefaultCollectionPageTabs: [
                {									
                RowColor: '#ffffff',									
                Name: 'Parallax',									
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                    Open: true,									
                    ShowComponents: true,									
                    Icon: 'mdi-image-area',									
                    Height: 300,
                    // IMG: require('@/assets/RASolidA-Light.jpeg'),
                    IMG: '',	
                    ParallaxGradient: 'background: linear-gradient(128deg, primary 0%, rgba(70,143,190,0.5438550420168067) 0%, rgba(252,252,252,1) 100%);',								
                    Elements: [{									
                        RowColor: '#00ffff',									
                        Name: 'Title',									
                        ShowComponents: true,									
                        Icon: 'mdi-format-title',									
                        Title: 'About',		
                        RowTransparency: 'transparent',							
                        Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                        MarginTop: 0,									
                        MarginBottom: 0,									
                        MarginLeft: 0,									
                        MarginRight: 0,									
                        BGColor: {
                            hex: '#ffffff',
                            hexa: '#ffffffff'
                    },									
                        FontColor :{									
                                hex: '#000000'									
                        },									
                        HeaderColor: {									
                            hex: '#ffffff'									
                        },									
                        FlexXLRG:6,									
                        FlexLarge: 8,									
                        FlexMedium: 10,									
                        FlexSmall: 12,									
                        FlexXSmall: 12,									
                        Cols: 12,									
                    },],									
                    Rows: [],									
                    BGColor: {
                        hex: '#ffffff',
                        hexa: '#ffffffff'
                },									
                    FontColor :{									
                            hex: '#000000'									
                    },									
                    HeaderColor: {									
                        hex: '#000000'									
                    },									
                    MarginTop: 0,									
                    MarginBottom: 0,									
                    MarginLeft: 0,									
                    MarginRight: 0,									
                    FlexXLRG:12,									
                    FlexLarge: 12,									
                    FlexMedium: 12,									
                    FlexSmall: 12,									
                    FlexXSmall: 12,									
                    Cols: 12,
                    Clipped: true,
                    ClippedPathOption: 'elipse',
                    ClippedbyPath: true,
                    ElipseX: '64%',
                    EliseY: '40%',
                    ElipseXPosition: '14%',
                    ElipseYPosition: '44%',
                },
                {									
                Open: true,									
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Name: 'blank',									
                Elements: [
                    {
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-center',
            RowTransparency: 'transparent',
            Name: 'Table Embed',
            Type: 'Table Embed',
            BoxTransparency: 'transparent',
            DisplayName: 'Table Embed',
            HasAlignment: true,
            EntitySource: 'System Routes',
            EntityData: [],
            Entityid: '',
            RowColor: '#00ffffff',
            ShowComponents: true,
            Icon: 'mdi-language-html5',
            Title: 'About',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:10,
            FlexLarge: 10,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },
                ],									
                ShowComponents: true,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                },
            ],
            CollectionTabs: [],
            StoreHeaders: [
    { text: 'Image', recordvalue: 'Image',value: 'Photo',Type: 'Single File Upload',FeaturedPhoto: true, sortable: false,class: "overline" },
    { text: 'Descriptive Text', recordvalue: 'Descriptive_Text',value : 'Descriptive_Text', sortable: false,Type: 'HTML',class: "overline" },        
    // { text: 'Discounted Price', recordvalue: 'Discounted_Price',value: 'Discounted_Price',Type: 'Number', sortable: false,class: "overline" },
    // { text: 'Store Price', recordvalue: 'Store_Price',value: 'Price',Type: 'Number', sortable: false,class: "overline" },
    { text: 'Description', recordvalue: 'Product_Code',value: 'Description',Type: 'Multiple Lines Text', sortable: false,class: "overline" },
    // { text: 'Brands', recordvalue: 'Brands',value: 'Brand',Type: 'Option Set', sortable: false,class: "overline" },
  ],
        }
    },
    computed:{
      ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        StoreProductsComputed(){
    let array = JSON.parse(JSON.stringify(this.$store.state[this.CurrentEntity.id.split('_').join('')+'Array']))   
    console.log('array ',this.CurrentEntity.id.split('_').join('')+'Array',array)   
      return array.map(record => {
        record.StorePassCount = 0
        this.StoreHeaders.map(header => {
        if(record[header.recordvalue]){
          // if(header.Type === 'Single File Upload' && header.FeaturedPhoto){
          //   record[header.value] = record[header.recordvalue][header.recordvalue+'ThumbURL']
          // }
          // else{
            record[header.value] = record[header.recordvalue]
          // }
          
          record.StorePassCount++
        }   
        else{
          record[header.value] = 'No '+header.recordvalue+' Value'
          record[header.value+'Class'] = 'red--text'
        }       
      })
      return record
    })
  },
  StoreProductsReady(){
    return this.StoreProductsComputed
    .filter(record => {
      return record.StorePassCount === this.StoreHeaders.length
    })
  },
  StoreProductsNOTReady(){
    return this.StoreProductsComputed
    .filter(record => {
      return record.StorePassCount !== this.StoreHeaders.length
    })
  },
         EntityDataRef(){
             if(!this.$route.params.slug && this.$route.name !== 'MyAccount' && this.$route.name !== 'MyDashboard'){
                 return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.$route.meta.EntityID)
             }
             else if(this.$route.name === 'MyAccount' || this.$route.name === 'MyDashboard'){
                 return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.EntityRecord.id)
             }
             else if(this.$route.params.slug === 'Systems'){
                 console.log(this.CurrentEntity,this.$route)
                 return db.collection('systems').doc(this.$route.query.Record).collection('entities').doc(this.$route.query.TableEntity)
             }
             else{
                 return db.collection(this.$route.params.slug.split(' ').join('').toLowerCase()).doc(this.$route.query.Record)
             }
         },
         EntityCollectionRef(){
             if(!this.$route.params.slug && this.CurrentEntity.Type !== 'Photo Library'){
             return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.$route.meta.EntityID)
             }
             else if(!this.$route.params.slug && this.CurrentEntity.Type === 'Photo Library'){
             return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('PhotoLibraries').doc(this.$route.meta.EntityID)
             }
             else{
                 return db.collection(this.$route.params.slug.split(' ').join('').toLowerCase()).doc(this.$route.params.id)
             }
         },
        ComputedCollectionTabs(){
            return this.CollectionTabs.filter(tab => {
                return tab.Name !== 'Parallax'
            }).map(tab => {
                if(!this.IntranetView && tab.Name === 'blank'){
                    tab.MarginTop = 50
                }      
                if(this.ActiveSuiteApp){
                  tab.FlexXLRG = 12
                  tab.FlexLarge = 12
                  tab.FlexMedium = 12
                  tab.FlexSmall = 12
                  tab.FlexXSmall = 12
                  if(tab.Elements){
                    tab.Elements = tab.Elements.map(elmnt => {
                      elmnt.FlexXLRG = 12
                      elmnt.FlexLarge = 12
                      elmnt.FlexMedium = 12
                      elmnt.FlexSmall = 12
                      elmnt.FlexXSmall = 12
                      return elmnt
                    })
                  }
                }          
                return tab
            })
        },
        PushedEntity(){
          return this.$route.name === 'EntityCollectionSample' || this.FunctionEntityid && this.$route.name === 'PDFExportFunctionBuilder' || this.$route.name === 'DashboardBuilder' || this.$route.name === 'CustomDashboard' || typeof this.dashboardelementindex !== 'undefined'
        },
        EntityDisplayName(){
            if(!this.PushedEntity && this.$route.meta && this.$route.meta.subcat){
              return this.$route.meta.subcat  
            }
            else if(this.PushedEntity){
                return this.CurrentEntity.DisplayName
            }
        },
        EntityArrayName(){
            return this.EntityDisplayName? this.EntityDisplayName.split(' ').join('')+'Array' : '' 
        },
        EntityQueryName(){
            return this.EntityDisplayName? this.EntityDisplayName.split(' ').join('')+'Query' : '' 
        },
        DataStoreArray() {
        return this.$store.state[this.EntityArrayName]
            },
        DataStoreQuery() {
        return this.$store.state[this.EntityQueryName]
            },
            
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        UsersStore(){
            return this.UsersArray
        },
        BusinessUnitsStore(){
            return this.$store.state.BusinessUnitsArray
            },
        UsersStore(){
            return this.$store.state.UsersArray
            },
        RouteObj(){
            return this.$route
        },
        FinalDashboardArray(){
        return this.EntityReady && this.CurrentEntity && this.DataArray ? this.DataArray
            .map(datarecord => {
            // let lookupobject = this.Business_UnitArray.find(Business_Unit => Business_Unit.id === datarecord.Business_Unitid)
            // if(lookupobject){
            // datarecord.Business_Unit = lookupobject
            // }          
            return datarecord
        }) : ''
        },
       
    },
    watch: {
        
        CurrentEntity(value) {
        if(value && this.$route.meta && this.$route.meta.TableBuilder){
          this.$store.commit('SetCurrentEntity',value) 
        }
      },
        DataStoreArray: {
            handler: function(newvalue, oldvalue) {
              console.log('this.EntityArrayName',this.EntityArrayName,newvalue, oldvalue)
                //alert(';sadasda')
                let vm = this
                if(oldvalue !== newvalue && !this.DashboardView && this.DataStoreArray){
                    //alert(';sadasda')
                    vm.DataArray = vm.DataStoreArray
                    if(vm.DataArray.length === 0){
                        vm.ArrayisEmpty = true
                    }
                    vm.GetEntityCollectionTabs(vm.EntityCollectionRef)
                }
            },
            deep: true
        },
        RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    console.log('watcher RouteObj',oldvalue, newvalue)
                    this.EntityReady = false
                    this.ConfigSystemEntities = []
                    this.CurrentEntity = ''
                    this.CollectionTabs = []
                    this.ArrayisEmpty = false
                    this.$emit('ActivateRecordLoadingDialog',true,25)
                    setTimeout(() => {
                     this.CheckRouting()   
                    }, 1000);
                    
                }
            },
            deep: true
        },
        FinalDashboardArray: {
            handler: function(newvalue, oldvalue) {
                    if(newvalue && newvalue.length > 0 || oldvalue && oldvalue.length > 0 || this.ArrayisEmpty){
                    let vm = this
                    if(typeof this.dashboardelementindex !== 'undefined'){
                        //alert('whoohoosss')
                            let lookuparrays = this.CurrentEntity.AllFields.filter(field => {
                                return field.Type === 'Lookup'
                            }).map(header => {
                                //console.log('field',header)
                                let fieldobj = {
                                    Field: header.Name,
                                    Array: []
                                }
                                if(header.RelatedBuildID === 'Users'){
                                    fieldobj.Array = this.$store.state.UsersArray
                                }
                                else{
                                    let storearrayname = ''
                                    if(header.RelatedBuildID === 'Users'){
                                        storearrayname = 'Users'
                                    }
                                    else{
                                        storearrayname = header.RelatedBuildID.split('_').join('')+'Array'
                                    }
                                    if(!this.$store.state[storearrayname] || this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
                                        let vm = this
                                        let storequery = header.RelatedBuildID.split('_').join('')+'Query'
                                        if(this.$store.state[storequery]){
                                            let payload = {                
                                            query: vm.$store.state[storequery],
                                            arraymutation: 'set'+storearrayname,
                                            getter: 'get'+storearrayname,
                                            arrayname: storearrayname
                                            }
                                            vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
                                                fieldobj.Array = vm.$store.state[storearrayname]
                                            })
                                        }
                                    }
                                    else if(this.$store.state[storearrayname]){
                                        fieldobj.Array = this.$store.state[storearrayname]
                                    }
                                }
                                return fieldobj
                            })
                            console.log('lookuparrays',lookuparrays)
                            //activitiesarray
                            vm.$emit('updatearray',this.dashboardelementindex,this.FinalDashboardArray,lookuparrays,this.CurrentEntity.HasActivities)
                        }
                    }
                    
            },
            deep: true
      },
    },
    
    created(){
        this.$emit('ActivateRecordLoadingDialog',true,25)
        //console.log(this.DefaultCollectionPageTabs,this.$route)
         if(this.PageView){
      this.itemsPerPage = 5
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.CompSize){
      this.$vuetify.breakpoint.width = this.CompSize
      //console.log(this.$vuetify.breakpoint)
    }
    
  if(typeof this.dashboardelementindex === 'undefined'){
// setTimeout(() => {
//   document.getElementById('accountsskeleton').style.display = 'none'
//   document.getElementById('accountsdata').style.display = 'block'      
//   },1500)
  }


window.onload = setTimeout(() => {this.CheckRouting()},1500)
    },
    methods:{
        PublishReadyStoreItems(){
        this.StoreProductsReady.map(record => {
          console.log(record)
          let storeobj = {  
          EntityID: this.CurrentEntity.id,               
          recordid: record.id,                  
          IsDataRecord: true,                 
          Center: 'justify-center',                 
          CenteredHeader: true,                 
          Name: 'Store Product Single',                 
          Photo: record.Photo,                                    
          show: false,                  
          HasBGIMG: true,                 
          BGIMGisIMG: true,                 
          ShowsPrice: true,                 
          SubHeaderFontFamily: 'Roboto',                  
          SubHeaderFontSize: 18,                  
          SubHeaderColor: {                 
              hex: '#FF0000FF'                  
          },                  
          HeaderFontFamily: 'Roboto',                 
          HeaderFontSize: 14,                 
          HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },                 
          FontSize: 10,                 
          FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },                 
          HasActionButton: true,                  
          ActionButtonName: 'Add to Cart',                  
          ActionBtnDark: true,                  
          ActionButtonType: 'Add Cart Item',                  
          ButonColor: {                 
              hex: '#FF0000FF'                  
          },                  
          ButtonAlignment: 'justify-end',                 
          Title: record['Product_Code'],                 
          Description: `<div style="text-align: center;">`+record['Descriptive_Text']+`</div>`,                 
                                              
          FlexXLRG:3,                 
          FlexLarge: 3,                 
          FlexMedium: 3,                  
          FlexSmall: 6,                 
          FlexXSmall: 12,                 
          Cols: 3,                  
          Justify: 'justify-center',                  
          RowColor: '#00ffff',                  
          ShowComponents: true,                 
          Icon: 'mdi-cards',                  
          MarginTop: 0,                 
          MarginBottom: 0,                  
          MarginLeft: 0,                  
          MarginRight: 0,                 
          BGColor: {                  
                        hex: '#ffffff',     
                        hexa: '#ffffffff',            
                        rgba: {r: 255,g: 255,b: 255}                  
                },
          IsDark: true,               
          } 
          storeobj.Description = record['Product_Code'] 
          //console.log(this.CurrentEntity.StorePriceField,record[this.CurrentEntity.StorePriceField.Name])
          // if(this.CurrentEntity.StorehasFilter && this.CurrentEntity.StoreFilters){
          //   this.CurrentEntity.StoreFilters.map(filter => {
          //     storeobj[filter.Name] = filter.Response
          //     storeobj[filter.Name].IsStoreFilter = true
          //   })
          // } 
          if(this.CurrentEntity.StoreImageField && record[this.CurrentEntity.StoreImageField.Name]){                  
              storeobj.HasImage = true                  
              storeobj.IMG = record['Image']['ThumbURL']                  
          } 
          
          console.log(this.CurrentEntity.StorePriceField)                
          if(this.CurrentEntity.StorePriceField && record[this.CurrentEntity.StorePriceField.Name]){    
                          
              storeobj.Price = record[this.CurrentEntity.StorePriceField.Name]                 
          }
          if(this.CurrentEntity.StoreShips){
            storeobj.ItemShips = true
          }
          if(this.CurrentEntity.StoreAllowsRating){
            storeobj.AllowsRating = true
          }
          if(this.CurrentEntity.StorehasUsed && this.CurrentEntity.StoreUsedField && record[this.CurrentEntity.StoreUsedField.Name]){
            storeobj.Condition = record[this.CurrentEntity.StoreUsedField.Name]
          }    
          if(this.CurrentEntity.StorehasBrands && this.CurrentEntity.StoreBrandField && record[this.CurrentEntity.StoreBrandField.Name]){
            if(this.CurrentEntity.StoreBrandField.Type === 'Lookup'){
              storeobj.Brand = {
                ID: record[this.CurrentEntity.StoreBrandField.Name].id,
                Name: record[this.CurrentEntity.StoreBrandField.Name][this.CurrentEntity.StoreBrandField.LookupFieldName]
              }
            }
            else{
              storeobj.Brand = record[this.CurrentEntity.StoreBrandField.Name]
            }
            
          }    
          
          //storeobj.Site = {Site: this.ActiveStore.Name,id: this.ActiveStore.id}
         // storeobj.Siteid = this.ActiveStore.id
          if(this.CurrentEntity.HierarchyField){
            let fieldobj = Object.assign({},this.CurrentEntity.HierarchyField)
            fieldobj.Options = this.Product_GroupOptions
            fieldobj.Levels =  [
  {
    "name": "Product Group",
    "FieldName": "Product_Group",
    "children": [
      {
        "FieldName": "Product_Type",
        "children": [
          {
            "children": [],
            "id": 3,
            "name": "Product Class",
            "FieldName": "Product_Class"
          }
        ],
        "id": 2,
        "name": "Product Type"
      }
    ],
    "id": 1
  },
  {
    "id": 2,
    "children": [
      {
        "id": 3,
        "name": "Product Class",
        "FieldName": "Product_Class",
        "children": []
      }
    ],
    "FieldName": "Product_Type",
    "name": "Product Type"
  },
  {
    "children": [],
    "id": 3,
    "FieldName": "Product_Class",
    "name": "Product Class"
  }
]
            fieldobj.Levels.map(lvl => {
              delete lvl.children
              if(record[lvl.FieldName]){
                storeobj[lvl.FieldName] = {
                  ID: record[lvl.FieldName].ID,
                  EntityID: this.CurrentEntity.id,
                  Name: record[lvl.FieldName].Name,
                  Level: lvl,
                  LevelFieldName: record[lvl.FieldName].LevelFieldName,
                }
              }
            })            
          }
          let descrtextelmnt = document.getElementById('DescriptiveText')
          if(descrtextelmnt){
            storeobj.DescriptiveText = descrtextelmnt.innerHTML
          }
          storeobj.DescriptiveText = record.Descriptive_Text
          console.log(record)
          if(record.Stock_Qty){
            storeobj.Stock_Qty = record.Stock_Qty
          }
          if(record.Discounted_Price){
            storeobj.Discounted_Price = record.Discounted_Price
          }
          if(record.Discount){
            storeobj.Discount = record.Discount
          }
          if(record.Purchase_Leadtime){
            storeobj.Purchase_Leadtime = record.Purchase_Leadtime
          }
          if(record.Supply_Leadtime){
            storeobj.Supply_Leadtime = record.Supply_Leadtime
          }
          console.log(record)
          if(record.StoreGalleryItems){
            storeobj.Assets = record.StoreGalleryItems.map(image => {
              let obj = {
                ThumbURL: image.ThumbURL,
                URL: image.fileurl
              }
              return obj
            })
          }
          storeobj.IMG = record.ImageThumbURL
            console.log(storeobj,record)
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()+'store').doc(record.id).set(storeobj)
          // db.collection('storeproducts').doc(record.id).set(storeobj)
        //   db.collection('products').doc(this.$route.params.id).update({
        //     DescriptiveText: storeobj.DescriptiveText,
        //     PublishedtoStore: true
        //   })
          this.BulkStorePublishDialog = false
        })
      },
        ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      }, 
         ActivateNotificationSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateNotificationSnackbar',boolean,snackbarcontent)
        },
        NavigatetoRouteParent(){
            console.log(this.$route)
        let routes = this.$router.getRoutes()
        let colroute = routes.find(obj => obj.name === this.$route.params.slug.toLowerCase())
        console.log(colroute)
        let newroute = routes.find(obj => obj.name === colroute.meta.singlecat.toLowerCase())
        console.log(newroute)
        newroute.query = {}
        newroute.params = {id: this.$route.query.Record}
        console.log(newroute)
        this.$router.push(newroute)
        },
        RefreshComponent(){
            this.EntityReady = false
            setTimeout(() => {
                this.EntityReady = true
            }, 2000);
        },
        FinalizerecordLoadingdialog(){
            this.$emit('ActivateRecordLoadingDialog',true,100)
            setTimeout(() => {
                    this.$emit('ActivateRecordLoadingDialog',false,100)
            }, 200);
        },
        GetEntityCollectionTabs(query){
            
          if(this.CurrentEntity.CollectionTabs){
              this.CollectionTabs = this.CurrentEntity.CollectionTabs
              this.EntityReady = true
              this.FinalizerecordLoadingdialog()
          }
          else{
              this.CollectionTabs = this.DefaultCollectionPageTabs.map(tab => {
                  if(tab.Name === 'Parallax'){
                      tab.Elements[0].Title = this.EntityDisplayName
                      tab.Elements[0].Elevation = 0
                  }
                  else if(tab.Name === 'blank'){
                      if(this.$route.query && this.$route.query.Record){
                          tab.Elements[0].EntitySource = 'SubCollection'
                          tab.Elements[0].Entityid = this.$route.params.id
                          tab.Elements[0].TableEntryPath = this.CurrentEntity.SingleName
                          tab.Elements[0].EntityData = []
                      }
                      else if(this.$route.meta && this.$route.meta.photolibrary){
                          tab.Elements[0].EntitySource = 'Photo Library'
                          tab.Elements[0].Entityid = this.CurrentEntity.id
                          tab.Elements[0].EntityData = this.DataArray
                          tab.Elements[0].DisplayName = this.EntityDisplayName
                      }
                      else{
                        tab.Elements[0].Entityid = this.CurrentEntity.id
                        tab.Elements[0].EntityData = this.DataArray
                      }
                      
                      
                  }
                  return tab
              })
              if(this.$route.name === 'MyAccount' || this.$route.name === 'MyDashboard'){
                this.CollectionTabs.splice(0,1)
                this.CollectionTabs[0].FlexXLRG = 12
                this.CollectionTabs[0].FlexLarge = 12
                this.CollectionTabs[0].FlexMedium = 12
                this.CollectionTabs[0].FlexSmall = 12
                this.CollectionTabs[0].FlexXSmall = 12
                this.CollectionTabs[0].Elements[0].FlexXLRG = 12
                this.CollectionTabs[0].Elements[0].FlexLarge = 12
                this.CollectionTabs[0].Elements[0].FlexMedium = 12
                this.CollectionTabs[0].Elements[0].FlexSmall = 12
                this.CollectionTabs[0].Elements[0].FlexXSmall = 12
              }
              this.EntityReady = true
              this.FinalizerecordLoadingdialog()
              //console.log('this.CollectionTabs',this.CollectionTabs)
          }
        },
        GetRequestingUser(filteredcall){
            //console.log(this.$store.state,this.EntityQueryName,this.EntityDisplayName,this.$route.meta)
            //alert('get user')
            this.$emit('ActivateRecordLoadingDialog',true,75)
            let vm = this
            //alert(typeof vm.$store.state[vm.EntityArrayName])
            vm.ConfigSystemEntities = vm.SystemEntities
            if(vm.$route.meta && vm.$route.meta.photolibrary){
                //console.log(vm.SystemPhotoLibraries,vm.$route.meta.EntityID,vm.EntityDisplayName)
                vm.CurrentEntity = vm.SystemPhotoLibraries.find(obj => obj.id === vm.$route.meta.EntityID)
            }
            else if(this.FunctionEntityid){
                vm.CurrentEntity = vm.SystemEntities.find(obj => obj.id === vm.FunctionEntityid)
                //console.log(vm.CurrentEntity,vm.DataStoreArray,vm.DataStoreQuery,vm.EntityDisplayName)
            }
            else{
                vm.CurrentEntity = vm.ConfigSystemEntities.find(obj => obj.id === vm.$route.meta.EntityID)
                if(!vm.ActiveSuiteApp && vm.CurrentEntity.Primary_Appid){
                  let app = vm.$store.state.AppsDataBase.find(obj => obj.id === vm.CurrentEntity.Primary_Appid)
                  if(app){
                    vm.$store.commit('setActiveSuiteApp',app)
                  }
                }
            }            
            
            
            if(vm.DataStoreQuery && vm.DataStoreArray && vm.DataStoreArray.length === 0){
                let payload = {                
                      query: vm.DataStoreQuery,
                      arrayname: vm.EntityArrayName
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        if(vm.$route.name !== 'CustomDashboard' && vm.$route.name !== 'DashboardBuilder' && !vm.FunctionEntityid){
                            vm.GetEntityCollectionTabs(vm.EntityCollectionRef)
                        }
                        })
            }
            else if(vm.DataStoreQuery){
                if(vm.$route.name !== 'CustomDashboard' && vm.$route.name !== 'DashboardBuilder' && !vm.FunctionEntityid){
                    vm.DataArray = vm.DataStoreArray
                    vm.GetEntityCollectionTabs(vm.EntityCollectionRef)
                }
            }
            else if(!vm.DataStoreQuery){
              console.log(vm.FunctionEntityid,vm.CurrentEntity,vm.EntityDisplayName,vm.EntityArrayName,vm.EntityQueryName,vm.PushedEntity)
                this.$emit('ActivateRecordLoadingDialog',false,100)
                alert(vm.EntityQueryName)
                vm.RoutetoErrorPage()
            }
            
            
        },
        RoutetoLoginPage(){
        this.$router.push('/login')
        },
        RoutetoErrorPage(){
        this.$router.push('/PermissionError')
        },
        CheckRouting(){    
            this.$emit('ActivateRecordLoadingDialog',true,50)  
            if(this.DashboardView){
            this.DataArray = this.DashboardArray
            this.CurrentEntity = this.SystemEntities.find(obj => obj.id === this.FunctionEntityid)
            //console.log('DashboardView',this.CurrentEntity,this.FunctionEntityid)
            let deftabs = this.DefaultCollectionPageTabs.map(tab => {
                  if(tab.Name === 'Parallax'){
                      tab.Elements[0].Title = this.EntityDisplayName
                      tab.Elements[0].Elevation = 0
                  }
                  else if(tab.Name === 'blank'){
                      if(this.$route.query && this.$route.query.Record){
                          tab.Elements[0].EntitySource = 'SubCollection'
                          tab.Elements[0].Entityid = this.$route.params.id
                          tab.Elements[0].TableEntryPath = this.CurrentEntity.SingleName
                          tab.Elements[0].EntityData = []
                      }
                      else if(this.$route.meta && this.$route.meta.photolibrary){
                          tab.Elements[0].EntitySource = 'Photo Library'
                          tab.Elements[0].Entityid = this.CurrentEntity.id
                          tab.Elements[0].EntityData = this.DataArray
                          tab.Elements[0].DisplayName = this.EntityDisplayName
                      }
                      else{
                        tab.Elements[0].Entityid = this.FunctionEntityid
                        tab.Elements[0].EntityData = this.DataArray
                      }
                      
                      
                  }
                  return tab
              })
            this.CollectionTabs = deftabs.filter(tab => {return tab.Name !== 'Parallax'})
            //console.log(this.CurrentEntity,this.DataArray)
            this.EntityReady = true
            }
            else if(typeof this.$route.params.id !== 'undefined' && typeof this.dashboardelementindex === 'undefined' && !this.$route.params.slug){
            //this.defaultItem[this.RelatedObj.identifier] = this.RelatedObj
                //this.editedItem = Object.assign({}, this.defaultItem)
                let queryfield = this.RelatedObj.identifier+'.DocumentID'
                
                if(typeof this.RelatedParentObj !== 'undefined'){
                    //this.defaultItem[this.RelatedParentObj.identifier] = this.RelatedParentObj
                    let queryfield = this.RelatedParentObj.identifier+'.DocumentID'
                    this.ParentFilterField = this.RelatedParentObj.identifier
                    this.CollectionRef = this.AccountsQuery.where(queryfield,'==',this.RelatedParentObj.id)
                    this.GetRequestingUser(true)
                }
                
                else{
                    let queryfield = this.RelatedObj.identifier+'.DocumentID'
                    this.ParentFilterField = this.RelatedObj.identifier
                    this.CollectionRef = this.AccountsQuery.where(queryfield,'==',this.RelatedObj.id)
                    this.GetRequestingUser(true)
                }
            }
            else if(this.EntityRecord){
                console.log(this.EntityRecord)
                //okay we actually need to get the entity reocrd. But that's a seirous pain in the arse
                //cause that would require getting subcol of subcol and subcol and blablabla
                this.ConfigSystemEntities = [this.EntityRecord]
                this.CurrentEntity = this.EntityRecord
                this.GetEntityCollectionTabs(this.EntityCollectionRef)
            }
           
            else{            
            this.GetRequestingUser()
            }
        },
    }
}
</script>

<style>

</style>