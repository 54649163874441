<template>
  <v-card flat tile :dark="AppisDarkMode" class="transparent" width="100%" height="100%">
      <v-dialog width="400" v-model="RecurringMeetupDialog">
          <v-card v-if="RecurringRecord">
              <v-card-title class="recordtoolbar white--text mediumoverline">
                  {{RecurringRecord.title}}
              </v-card-title>
              <v-card-text>
                   <!-- AdvanceInteger, GuestsIncluded, title, caption, description, RecurranceFrequency, RecurrancePattern -->
                   <v-select :items="['Every Day','Every Week','Every Month']" v-model="RecurringRecord.RecurranceFrequency" label="Frequency"/>
                   <v-list-item v-if="RecurringRecord.RecurranceFrequency === 'Every Month'">                
                  <v-select :items="['Every First','Every Last','Day of Month']" v-model="RecurringRecord.RecurranceMonthlyPattern" label="Days of Month"/>
                  <v-select v-if="RecurringRecord.RecurranceMonthlyPattern" :items="RecurranceMonthlyPatternOptions" v-model="RecurringRecord.RecurrancePattern" :label="RecurringRecord.RecurranceMonthlyPattern"/>   
                </v-list-item>
                  <v-list-item v-if="RecurringRecord.RecurranceFrequency === 'Every Day'">
                  
                  <v-list-item-content>
                    <v-menu
                      v-model="EventStartTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px" 
                      id="EventStartTimeMenu"
                    
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="EventStartTime"
                          label="Start Time"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          id="EventStartTime"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-time-picker
                      dense
                        v-model="EventStartTime"
                        @input="EventStartTimeMenu = false"
                        type="month"
                        width="290"
                        class="ml-4"
                      ></v-time-picker>
                    </v-menu>            
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventStartTime"
                  v-model="EventEndTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px" 
                  id="EventEndTimeMenu"
                  
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="EventEndTime"
                      label="End Time"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-on="on"
                      id="EventEndTime"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="EventEndTime"
                    @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                    type="month"
                    width="290"
                    class="ml-4"
                    dense
                  :allowed-minutes="AllowedEndTimeMinutes"
                  :allowed-hours="AllowedEndTimeHours"
                  ></v-time-picker>
                </v-menu>           
                                  
                      </v-list-item-content>      
                </v-list-item>
                <v-list-item v-if="RecurringRecord.RecurranceFrequency === 'Every Week'">
                    <v-list-item-content>
                  <v-select multiple :items="['Monday','Tuesday','Wednesday','Thursday','Friday','Staurday','Sunday']" v-model="RecurringRecord.RecurrancePattern" label="Days of Week"/>
                    </v-list-item-content>
                  <v-list-item-content>
                    <v-menu
                      v-model="EventStartTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px" 
                      id="EventStartTimeMenu"
                    
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="EventStartTime"
                          label="Start Time"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          id="EventStartTime"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-time-picker
                      dense
                        v-model="EventStartTime"
                        @input="EventStartTimeMenu = false"
                        type="month"
                        width="290"
                        class="ml-4"
                      ></v-time-picker>
                    </v-menu>            
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventStartTime"
                  v-model="EventEndTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px" 
                  id="EventEndTimeMenu"
                  
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="EventEndTime"
                      label="End Time"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-on="on"
                      id="EventEndTime"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="EventEndTime"
                    @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                    type="month"
                    width="290"
                    class="ml-4"
                    dense
                  :allowed-minutes="AllowedEndTimeMinutes"
                  :allowed-hours="AllowedEndTimeHours"
                  ></v-time-picker>
                </v-menu>           
                                  
                      </v-list-item-content>          
                </v-list-item>
                <v-list-item v-if="RecurringRecord.RecurranceFrequency === 'Every Month'">
                
                  <v-select :items="['Every First','Every Last','Day of Month']" v-model="RecurringRecord.RecurranceMonthlyPattern" label="Days of Month"/>
                  <v-select v-if="RecurranceMonthlyPattern" :items="RecurranceMonthlyPatternOptions" v-model="RecurringRecord.RecurrancePattern" :label="RecurranceMonthlyPattern"/>
   
                </v-list-item>
                 <v-list-item >
                 <v-select :items="AdvanceOptions" v-model="RecurringRecord.AdvanceInteger" :label="AdvanceIntegerLabel"/>         
                </v-list-item>
                <v-menu
                    v-model="EventstartdateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px" 
                    id="EventStartDatemenu"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="EventStartDate"
                        label="Start Date"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-on="on"
                        id="EventStartDate"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker dense v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
                  </v-menu> 
                  <v-menu
                  v-if="EventStartDate"
                    v-model="EventenddateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px" 
                    id="EventEndDatemenu"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="EventEndDate"
                        label="End Date"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-on="on"
                        id="EventEndDate"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker dense v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
                  </v-menu>
              </v-card-text>
              <v-card-actions>
                  <v-btn @click="DeactivateRecurringMeetupDialog()">Cancel</v-btn>
                  <v-spacer>
                  </v-spacer>
                  <v-btn @click="SaveRecurringMeetup()">Save</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <v-tabs centered v-if="!ActiveRecord">
          <v-tab>In Progress</v-tab>
          <v-tab-item>
               <v-list
                :dark="AppisDarkMode"
                dense                
                nav
                
                v-for="item in computeditems"
                :key="item.title"
                >
                
                <v-list-item class="overline">
                     <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                <!-- <v-list-item-icon>
                    <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                    </v-list-item-content> -->
                </v-list-item>
                <v-divider/>
                <v-list :dark="AppisDarkMode"  v-if="GetMatchingStatus(item).length>0">
                <v-list-item  v-for="meetup in GetMatchingStatus(item)" :key="meetup.itemObjKey" @click="PushItem(meetup)">
                    <!-- <v-list-item-icon>
                    <v-icon small>mdi-blogger</v-icon>
                    </v-list-item-icon> -->
                    <v-list-item-avatar size="80" class="mx-2" tile>
                        <v-img contain v-if="meetup.coverimage" :src="meetup.coverimage"/>
                        <v-img contain v-if="!meetup.coverimage" src="@/assets/ImageHolder.png"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="meetup.class">{{ meetup.title }}</v-list-item-title>
                        <v-list-item-subtitle :class="meetup.class">{{ meetup.caption }}</v-list-item-subtitle>
                    </v-list-item-content>
                     <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                            {{meetup.monthcreated}}
                                <br>
                            {{meetup.daycreated}}
                    </v-list-item-avatar>                    
                    <!-- <v-list-item-content>
                       {{blog.pubDate}} 
                    </v-list-item-content> -->
                </v-list-item>
            </v-list>
            <v-list :dark="AppisDarkMode" v-else>
                <v-list-item >
                    <v-list-item-icon>
                    <v-icon color="red" small>mdi-cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title  style="font-weight:300;"><i>There are no items for this Status at the moment</i></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-list>
          </v-tab-item>
          <v-tab>Recurring</v-tab>
          <v-tab-item>
              <v-list>
                  <v-list-item v-for="meetup in RecurringMeetups" :key="meetup.itemObjKey">
                     <v-list-item-avatar size="80" class="mx-2" tile>
                        <v-img contain v-if="meetup.coverimage" :src="meetup.coverimage"/>
                        <v-img contain v-if="!meetup.coverimage" src="@/assets/ImageHolder.png"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="meetup.class">{{ meetup.title }}</v-list-item-title>
                        <v-list-item-subtitle :class="meetup.class">{{ meetup.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-switch v-model="meetup.Recurring" label="Recurring" />
                    </v-list-item-content>
                    <v-list-item-actions>
                        <v-btn @click="ActivateRecurringMeetupDialog(meetup)">
                            Edit
                        </v-btn>                       
                    </v-list-item-actions>
                  </v-list-item>
              </v-list>
          </v-tab-item>
          
          
          <!-- <v-tab>Stats</v-tab>
          <v-tab-item>
              
          </v-tab-item> -->
      </v-tabs>
      <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        <v-btn :to="'/Article/'+ActiveRecord.id">View</v-btn>
        <v-spacer></v-spacer>
         <v-icon color="red">mdi-delete-forever</v-icon>
        </v-card-title>
        <!-- {{ActiveRecord}} -->
        <div v-if="ActiveRecord"> 
            <v-card>
       
        <v-card-text>
          <v-toolbar  rounded src="@/assets/RABaseBG.jpeg" elevation="12" color="primary white--text">
        <v-toolbar-title>
          Checked In Users
        </v-toolbar-title>
        <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field dark
            v-model="checkinsearch"            
            label="Search"
            class="mediumoverline"
            single-line
            hide-details
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-layout row class="justify-center" style="padding-top: 20px;">
          <v-flex  xs12 sm6 md4 lg3 style="padding:10px;" v-for="member in CheckedInUsersSearched" :key="member.id">
            <v-card  max-width="300px" >
            <v-layout class="justify-center">
          
           <v-avatar
              color="grey"
              size="80"                     
            >
            <v-img v-if="member.UserIMG" :src="member.UserIMG"></v-img>
            <v-img v-if="!member.UserIMG"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>         
          </v-layout>
          <v-layout class="justify-center mx-3">
              <h4 class="font-weight-thin black--text centertext my-1"><router-link :to="'/SiteMember/'+member.id" target="_blank">{{member.Full_Name}}</router-link></h4>
          </v-layout>
          <v-layout class="justify-center mx-3">
          <span class="caption">
              Checked in at {{member.Time.toDate().toLocaleTimeString('en-US')}}
              </span>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small @click="ConfirmCheckin(member)" dark color="success" v-if="!member.Confirmed">
              Confirm</v-btn>
              <span v-if="member.Confirmed" class="caption">Confirmed by {{member.Confirmed_By.Full_Name}}</span>
          </v-card-actions>
            </v-card>
          </v-flex>
      </v-layout>
        </v-card-text>
      </v-card>
        </div>

  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import ItemNotifications from '@/components/Dashboards/ItemNotifications'
import format from 'date-fns/format'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem','ViewQuery','UserisTeamMember','GroupAdmin'],
    components: {
        ItemNotifications
    },
    data(){
        return{
            EventenddateMenu: false,
            EventEndDate: '',
            EventStartDate: '',
            EventStartTime: '',
            EventStartTimeMenu: false,
            EventstartdateMenu: false,
            RecurringMeetupDialog: false,
            RecurringMeetups: [],
            ActiveRecord: '',
            RecurringRecord: '',
            requestitems: [
                {
                    icon: 'mdi-blogger',
                    title: 'UnAssigned'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Assigned to Me'
                },
            ],
            items: [
                {
                    icon: 'mdi-blogger',
                    title: 'Draft'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published (Internal)'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published (Public)'
                },
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            headers: [
                { text: 'Title', value: 'title', Type: 'Single Line Text'},
                { text: 'Created On', value: 'createdon', Type: 'Single Line Text'},
                { text: 'author', value: 'author', Type: 'Single Line Text'},
                { text: 'Publish Status', value: 'PublishStatus', Type: 'Single Line Text'},
                { text: 'coverimage', value: 'coverimage', Type: 'Single Filed Upload',IsFeaturedPhoto: true},
                
            ],
            SiteMeetups: [],
            CheckedInUsers: [],
            checkinsearch: '',
            
        }
    },
    computed:{
        RecurranceMonthlyPatternOptions(){
            if(this.RecurringRecord && this.RecurringMeetupDialog && this.RecurringRecord.RecurranceMonthlyPattern){
                let daysofweek = ['Monday','Tuesday','Wednesday','Thursday','Friday','Staurday','Sunday']
                let daysinmonth = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
                if(this.RecurringRecord.RecurranceMonthlyPattern){
                if(this.RecurringRecord.RecurranceMonthlyPattern === 'Every First'){
                    return daysofweek
                }
                else if(this.RecurringRecord.RecurranceMonthlyPattern === 'Every Last'){
                    return daysofweek
                }
                else if(this.RecurringRecord.RecurranceMonthlyPattern === 'Day of Month'){
                    return daysinmonth
                }
                }
            }
            else{
                return []
            }
      },
      AdvanceIntegerLabel(){
        if(this.RecurringRecord && this.RecurringMeetupDialog && this.RecurringRecord.RecurranceFrequency){
          if(this.RecurranceFrequency === 'Every Day'){
            return 'Days in Advance'
          }
          else if(this.RecurringRecord.RecurranceFrequency === 'Every Week'){
            return 'Weeks in Advance'
          }
          else if(this.RecurringRecord.RecurranceFrequency === 'Every Month'){
            return 'Months in Advance'
          }

        }
      },
      AdvanceOptions(){
        if(this.RecurringRecord && this.RecurringMeetupDialog && this.RecurringRecord.RecurranceFrequency){
          if(this.RecurringRecord.RecurranceFrequency === 'Every Day'){
            return [
              7,14
            ]
          }
          else if(this.RecurringRecord.RecurranceFrequency === 'Every Week'){
            return [
              2,4
            ]
          }
          else if(this.RecurringRecord.RecurranceFrequency === 'Every Month'){
            return [
              1,2,3
            ]
          }

        }
      },
        CheckedInUsersSearched(){
        return this.CheckedInUsers
        .filter(user => {
          if(this.checkinsearch){
            return user.Full_Name.toLowerCase().includes(this.checkinsearch.toLowerCase())
          }
          else{
            return user
          }
        })
      },
        computeditems(){
            return this.items
        },
        SiteMeetupsFilteredandSearched(){
            return this.SiteMeetups.map(article => {
                if(!article.pubDate && article.start){
                    article.pubDate = this.TimestampFormatterSTRINGDayText(article.start.toDate())
                }
                    article.monthcreated = this.TimestampFormatterNoticeBoard(article.start.toDate(),'month')
                    article.daycreated = this.TimestampFormatterNoticeBoard(article.start.toDate(),'date')        
                    article.createddate = this.TimestampFormatterNoticeBoard(article.start.toDate(),'full')                
          return article
        })
        },
        ComputedHeaders(){
            return this.headers
        },
        ComputedNotificationsQuery(){
            return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').where('blogid','==',this.ActiveRecord.id)
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
      
    },
    watch: {
      ActiveRecord(value){
          if (value !== null && value !== undefined ) {
              //this.Attending_Users = []
            let docref = ''
            let meetupcollection = ''
            if(this.$route.name === 'MyDashboard'){
                meetupcollection = db.collection('sitemeetups')
            }
            else{
                meetupcollection = db.collection('groupmeetups')
            }
            docref = meetupcollection.doc(this.ActiveRecord.id)
            this.GetCheckedInUsers(docref)
          }
      },
    },
    created(){
        //console.log('this.MenuItem',this.MenuItem)
        this.GetQuery(this.MenuItem)
        this.GetRecurringMeetups()
        
    },
    methods:{
        AssignEventEndDate(){
        console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
      CheckAllowedMinutes(){       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
        ActivateRecurringMeetupDialog(meetup){
            this.RecurringRecord = meetup            
            //EventStartDate,EventEndDate,EventEndTime,EventStartTime            
            let startdateobject = this.RecurringRecord.start
            this.EventStartDate = format(startdateobject,'yyyy-MM-dd')
            let starthours = startdateobject.getHours()
            let startmins = startdateobject.getMinutes()
            if(starthours === 0){
            starthours = '00'
            }
            if(startmins === 0){
            startmins = '00'
            }
            this.EventStartTime = starthours+':'+startmins
            let enddateobject = this.RecurringRecord.end
            this.EventEndDate = format(enddateobject,'yyyy-MM-dd')
            let endhours = enddateobject.getHours()
            let endmins = enddateobject.getMinutes()
            if(endhours === 0){
            endhours = '00'
            }
            if(endmins === 0){
            endmins = '00'
            }
            this.EventEndTime = endhours+':'+endmins
            this.RecurringMeetupDialog = true
        },
        DeactivateRecurringMeetupDialog(){
            this.RecurringMeetupDialog = false
            this.EventEndTime = ''
            this.EventStartTime = ''
            this.EventEndDate = ''
            this.EventStartDate = ''
            this.RecurringRecord = ''
        },
        SaveRecurringMeetup(){
            let RecurringRecord = Object.assign({},this.RecurringRecord)
            RecurringRecord.start = new Date(this.EventStartDate+' '+this.EventStartTime)
            RecurringRecord.end = new Date(this.EventEndDate+' '+this.EventEndTime)
            db.collection('frequentedsitemeetups').doc(this.RecurringRecord.id).set(RecurringRecord).then(doc => {
                this.DeactivateRecurringMeetupDialog()
            })
        },
        GetRecurringMeetups(){
            let query = db.collection('frequentedsitemeetups')
            if(this.$route.name === 'GroupDashboard'){
                query = query.where('groupid','==',this.$route.params.id)
            }
            query.onSnapshot(res => {       
            const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                        let frequentedmeetup = {
                        ...change.doc.data(),
                        id: change.doc.id
                        } 
                        frequentedmeetup.start = frequentedmeetup.start.toDate()
                        frequentedmeetup.end = frequentedmeetup.end.toDate()
                        this.RecurringMeetups.push(frequentedmeetup)
                    }   
                })
            })        
        },
        ConfirmCheckin(member){
        let meetupcollection = db.collection('sitemeetups')
        let docid = this.ActiveRecord.id
        if(this.$route.name === 'GroupDashboard'){
          meetupcollection = db.collection('groupmeetups')
        }
        let docref = meetupcollection.doc(docid)
        docref.collection('checkins').doc(member.id).update({
          Confirmed: true,
          Confirmed_By: {id: this.userLoggedIn.id, Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname},
          confirmed_On: new Date()
        })
      },
        GetCheckedInUsers(docref){ 
            this.CheckedInUsers = []     
      docref.collection('checkins').where('groupid','==',this.$route.params.id).onSnapshot(res => {       
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let checkineduser = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.CheckedInUsers.find(obj => obj.id === checkineduser.id)
                if(!oncheck){
                 this.CheckedInUsers.push(checkineduser) 
                }                
              }
              else if (change.type === 'modified') {
                let checkineduser = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.CheckedInUsers.find(obj => obj.id === checkineduser.id)
                if(!oncheck){
                 this.CheckedInUsers.push(checkineduser) 
                }
                else{
                  let index = this.CheckedInUsers.indexOf(oncheck)
                  // alert(index)
                  this.CheckedInUsers.splice(index,1,checkineduser)
                }                
              }
            })
      })
    },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetQuery(item){
            if(this.$route.params.id && item && item.groupcollectionname){
                let query = db.collection(item.groupcollectionname).where(item.groupcolfield,'==',this.$route.params.id)
                //console.log('Get '+item.groupcollectionname+' where '+item.groupcolfield+' == '+this.$route.params.id,this.userLoggedIn.id)
                this.GetSiteMeetups(query)
            }
            else if(item && item.sitecollectionname){
               let query = db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
               //console.log(item.sitecollectionname,'where ',item.sitecolfield,'==',this.userLoggedIn.id)
               this.GetSiteMeetups(query)
            }
        },
        PushItem(item){
            this.ActiveRecord = item
        },
        GetMatchingStatus(item){
            return this.SiteMeetupsFilteredandSearched
            .filter(blog => {
                return blog.PublishStatus === item.title
            })
            .map(blog => {
                if(item.title === 'Draft'){
                    blog.class = 'warning--text'
                }
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                return blog
            })
            
        },
        GetSiteMeetups(query){
            //console.log(this.ActiveSession)
            query.onSnapshot(res => {
                let listlength = res.docs.length
                const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      this.SiteMeetups.push({
                      ...change.doc.data(),
                      id: change.doc.id
                      })
                  }
                  if(this.SiteMeetups.length === listlength){
                      this.$emit('PushArray',this.MenuItem,this.SiteMeetups)
                  }
              })
            })
        },
    }

}
</script>

<style>

</style>


    