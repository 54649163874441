<template>
  <div>
    
     <v-pagination style="margin-bottom:50px;"
    v-model="tempaltepage"
    :length="Math.ceil(Templates.length/ItemsperPage)"
    :total-visible="ItemsperPage"
    ></v-pagination>
    <v-row dense>          
            <v-col
            v-for="temp in TemplatesPagination" :key="temp.itemObjKey"
              :cols="TemplateCols"
            >
    <v-card flat tile width="300" style="padding:10px;" class="mx-auto storecard transparent">
            <v-hover v-slot="{ hover }">
                <div class="item__overlayup" style="height:200px;">
                    <div v-if="SitePrimaryPage(temp)">
                    <LandingPageSingle style="max-height: 800px;width:1000px;margin-top: 0px;transform: scale(0.25);transform-origin: top left;overflow: hidden;"  
                 :ActiveTemplate="SitePrimaryPage(temp)" :System="System" :SystemEntities="SystemEntities"
                    :SitePages="WebsiteSitePages" @TogglePreviewingMode="TogglePreviewingMode" :TemplateCarousel="true"
                    />
                <v-expand-transition>
                    <v-card tile
                    v-if="hover"
                        class="transition-fast-in-fast-out white--text"
                        style="height: 100%;background-color: rgba(40,50,60,0.7);position: absolute;top:0px;width:100%;"
                    >
                    <v-card-subtitle style="padding-bottom:0px;font-size: 1.2em;" class="white--text">
                            {{temp.Name}}
                        </v-card-subtitle>
                        <v-card-subtitle style="padding-top:10px;font-weight: bold;" class="white--text">
                            <v-avatar size="30" class="blue" >
                            <img v-if="temp.Profile_Photo && !temp.As_Platform"
                            :src="temp.Profile_Photo "    
                            style="object-fit: cover;"          
                            >
                            <img v-if="!temp.Profile_Photo && !temp.As_Platform"
                                src="@/assets/BlankProfilePic.png"   
                                style="object-fit: cover;"           
                            >    
                            <img v-if="temp.As_Platform" contain src="@/assets/logo.png" style="object-fit: cover;">                  
                            </v-avatar>
                            {{temp.As_Platform ? System.Name : temp.Owner.Full_Name}}
                        </v-card-subtitle>
                        <v-card-text class="white--text">
                        <div :id="temp.id+'smartdescription'">
                                <span  v-html="temp.Description ? smart_substr(temp.Description,200) : ''">
                                </span>
                            </div>  
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
                    </div>
              <v-img class="black" v-if="!SitePrimaryPage(temp)" height="200" contain :src="temp.Picture">
                <v-expand-transition>
                    <v-card tile
                    v-if="hover"                       
                        class="transition-fast-in-fast-out white--text"
                        style="height: 100%;background-color: rgba(40,50,60,0.7);width:100%;"
                    >
                    <v-card-subtitle style="padding-bottom:0px;font-size: 1.2em;" class="white--text">
                            {{temp.Name}}
                        </v-card-subtitle>
                        <v-card-subtitle style="padding-top:10px;font-weight: bold;" class="white--text">
                            <v-avatar size="30" class="blue" >
                            <img v-if="temp.Profile_Photo && !temp.As_Platform"
                            :src="temp.Profile_Photo "    
                            style="object-fit: cover;"          
                            >
                            <img v-if="!temp.Profile_Photo && !temp.As_Platform"
                                src="@/assets/BlankProfilePic.png"   
                                style="object-fit: cover;"           
                            >    
                            <img v-if="temp.As_Platform" contain src="@/assets/logo.png" style="object-fit: cover;">                  
                            </v-avatar>
                            {{temp.As_Platform ? System.Name : temp.Owner.Full_Name}}
                        </v-card-subtitle>
                        <v-card-text class="white--text">
                        <div :id="temp.id+'smartdescription'">
                                <span  v-html="temp.Description ? smart_substr(temp.Description,200) : ''">
                                </span>
                            </div>  
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
              </v-img>
                </div>
            </v-hover>
            <v-card class="item__overlaydown" rounded elevation="8" :style="'background-image: radial-gradient(circle, rgba(252,70,107,0.1) 0%, rgba(63,94,251,0.1) 100%);'">
                <v-list dense class="transparent">
                    <div :class="temp.PublishStatus === 'Draft' ? 'title-ribbon grey' : 'title-ribbon purple'">
                        <v-list-item v-if="!UserPurchases.includes(temp.id)">
                            <v-list-item-title class="mediumoverline white--text">
                              <v-card-subtitle  class="mediumoverline white--text">
                            
                             {{temp.Price === 'Free' ? temp.Price : CurrencyFormatter(temp.Price,$store.state.DefaultCurrency.Currency)}}
                             <v-icon v-if="temp.Featured" style="" class="goldtext">mdi-star</v-icon>
                        </v-card-subtitle>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="temp.PublishStatus === 'Draft'">
                                <v-chip color="lime">
                                     {{temp.PublishStatus}}
                                </v-chip>
                            </v-list-item-subtitle>
                        </v-list-item>
                         <v-list-item v-if="UserPurchases.includes(temp.id)">
                            <v-list-item-title class="mediumoverline white--text">
                              <v-card-subtitle  class="mediumoverline white--text">                            
                            Already Purchased                             
                        </v-card-subtitle>
                            </v-list-item-title><v-icon dark color="green accent-2">
                            mdi-cart-check
                        </v-icon>
                            </v-list-item>
                    </div>
                     <div style="position: absolute; right: 0px;top: 0px;">
                      <v-avatar size="80">
                        <div v-if="temp.Price === 'Free'">
                      <v-img height="70" contain :src="$vuetify.theme.dark ? require('@/assets/Gift_Box_Dark.png') : require('@/assets/Gift_Box_Light.png')"/>
                    </div>
                      </v-avatar>
                    </div>
                    </v-list>
                <v-card-actions class="justify-center" style="padding-top:60px;padding-bottom:15px;">
                    <v-btn color="RAWebBtnGraddark" dark v-if="RAUserObj && !temp.RATemplateid" @click="ActivateActiveTemplateComponent(temp,true)">
                        <span v-if="!MiniView">
                        Add to RA
                        </span>
                        <v-icon>
                            mdi-cart
                        </v-icon>
                        </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn :disabled="UserPurchases.includes(temp.id)" outlined dark color="blue" @click="ActivateActiveTemplateComponent(temp)">
                        <span v-if="!MiniView">
                        View
                        </span>
                    <v-icon v-if="MiniView">mdi-eye</v-icon>
                        </v-btn>  
                </v-card-actions>
            </v-card>
          </v-card>
            </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';	
export default {
    props: ['System','SystemEntities','Templates','temp'],
    components: {LandingPageSingle},
    data() {
        return {
            tempaltepage: 1,
        }
    },	
    computed:{
        ItemsperPage(){
            return this.MiniView ? 6 : 12
        },
        UserContentPurchases(){
        return this.userLoggedIn && this.userLoggedIn.ContentPurchases ? this.userLoggedIn.ContentPurchases : []
      },
      UserPurchases(){
        return this.UserContentPurchases.map(item => {
          return item.Contentid
        })
      },
        TemplatesPagination(){
            let displaylimit = this.ItemsperPage
            let displayrange = displaylimit*this.tempaltepage-displaylimit
            return this.Templates.slice(displayrange, displayrange+displaylimit)
        },
        TemplateCols(){
            let cols = 12
            if(this.$vuetify.breakpoint.xl){
                cols = 2
            }
            else if(this.$vuetify.breakpoint.lg){
                cols = 3
            }
            else if(this.$vuetify.breakpoint.md){
                cols = 4
            }
            else if(this.$vuetify.breakpoint.sm){
                cols = 6
            }
            return cols
        },
        RAUserObj(){
            return this.$store.state.RAUserObj
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        SuiteTemplateMain(){
        return this.$route.name === 'Suite Template Main'
        },
        SuiteTemplateWebsite(){
        return this.$route.name === 'Suite Template Website'
        },
        SuiteTemplateSocialNetwork(){
        return this.$route.name === 'Suite Template Social Network'
        },
        SuiteTemplateDatabase(){
        return this.$route.name === 'Suite Template Database App'
        },
        SuiteTemplateView(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.ActiveSuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        ActivateActiveTemplateComponent(temp,toRA){
            this.$emit('ActivateActiveTemplateComponent',temp,toRA)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
        SitePrimaryPage(temp){
            let childtemplates = this.MarketplaceTemplates.filter(child => {
                return child.Parentid === temp.id
            })
            let defpageprop = ''
            if(temp.Secondary_Category.Name === 'Website' || temp.Secondary_Category.Name === 'Featured Member'){
                    defpageprop = 'Public_Landing_Page'
                }
                else if(this.SocialNetworkTemplate){
                    defpageprop = 'Social_Landing_Page'
                }
            if(temp.TemplateProps[defpageprop]){
                return childtemplates.find(obj => obj.TemplateObj.Name.split('/').join('').split(' ').join('').split('-').join('').split('_').join('') === temp.TemplateProps[defpageprop])
            }
            else if(childtemplates[0]){
                return childtemplates[0]
            }
        },
    }
}
</script>

<style>

</style>



