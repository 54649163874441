<template>
  <v-card flat tile :dark="AppisDarkMode" class="transparent" width="100%" height="100%">
      <v-tabs centered v-if="!ActiveRecord">
          <v-tab>In Progress</v-tab>
          <v-tab-item>
               <v-list
                :dark="AppisDarkMode"
                dense                
                nav
                
                v-for="item in computeditems"
                :key="item.title"
                >
                
                <v-list-item class="overline">
                     <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                <!-- <v-list-item-icon>
                    <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                    </v-list-item-content> -->
                </v-list-item>
                <v-divider/>
                <v-list :dark="AppisDarkMode"  v-if="GetMatchingStatus(item).length>0">
                <v-list-item  v-for="blog in GetMatchingStatus(item)" :key="blog.itemObjKey" @click="PushItem(blog)">
                    <!-- <v-list-item-icon>
                    <v-icon small>mdi-blogger</v-icon>
                    </v-list-item-icon> -->
                    <v-list-item-avatar size="80" class="mx-2" tile>
                        <v-img contain v-if="blog.coverimage" :src="blog.coverimage"/>
                        <v-img contain v-if="!blog.coverimage" src="@/assets/ImageHolder.png"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="blog.class">{{ blog.title }}</v-list-item-title>
                        <v-list-item-subtitle :class="blog.class">{{ blog.caption }}</v-list-item-subtitle>
                    </v-list-item-content>
                     <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                            {{blog.monthcreated}}
                                <br>
                            {{blog.daycreated}}
                    </v-list-item-avatar>                    
                    <!-- <v-list-item-content>
                       {{blog.pubDate}} 
                    </v-list-item-content> -->
                </v-list-item>
            </v-list>
            <v-list :dark="AppisDarkMode" v-else>
                <v-list-item >
                    <v-list-item-icon>
                    <v-icon color="red" small>mdi-cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title  style="font-weight:300;"><i>There are no items for this Status at the moment</i></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-list>
          </v-tab-item>
          <v-tab>Tickets</v-tab>
          <v-tab-item>
              Because people may request for Blogs to be written, and ehre is where that is viewed. It may be moderator specific or not
              <v-list
                :dark="AppisDarkMode"
                dense                
                nav
                
                v-for="item in requestitems"
                :key="item.title"
                >
                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list  :dark="AppisDarkMode"  v-if="GetMatchingStatus(item).length>0">
                <v-list-item v-for="blog in GetMatchingStatus(item)" :key="blog.itemObjKey"   @click="PushItem(blog)">
                    <v-list-item-icon>
                    <v-icon small>mdi-blogger</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="blog.class">{{ blog.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list :dark="AppisDarkMode" v-else>
                <v-list-item >
                    <v-list-item-icon>
                    <v-icon color="red" small>mdi-cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title  style="font-weight:300;"><i>There are no items for this Status at the moment</i></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-list>
          </v-tab-item>
          
          
          <!-- <v-tab>Stats</v-tab>
          <v-tab-item>
              
          </v-tab-item> -->
      </v-tabs>
      <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        <v-btn :to="'/Article/'+ActiveRecord.id">View</v-btn>
        <v-spacer></v-spacer>
         <v-icon color="red">mdi-delete-forever</v-icon>
        </v-card-title>
        <!-- {{ActiveRecord}} -->
        <v-tabs v-if="ActiveRecord" centered>            
            <v-tab>
                Notifications
            </v-tab>
            <v-tab-item>
               <ItemNotifications :query="ComputedNotificationsQuery"
               />
            </v-tab-item>
            <v-tab>
                Stats
            </v-tab>
            <v-tab-item>
                TBD
            </v-tab-item>
            
        </v-tabs>   

  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import ItemNotifications from '@/components/Dashboards/ItemNotifications'
import format from 'date-fns/format'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem','ViewQuery'],
    components: {
        ItemNotifications
    },
    data(){
        return{
            ActiveRecord: '',
            requestitems: [
                {
                    icon: 'mdi-blogger',
                    title: 'UnAssigned'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Assigned to Me'
                },
            ],
            items: [
                {
                    icon: 'mdi-blogger',
                    title: 'Draft'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published (Internal)'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published (Public)'
                },
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            headers: [
                { text: 'Title', value: 'title', Type: 'Single Line Text'},
                { text: 'Created On', value: 'createdon', Type: 'Single Line Text'},
                { text: 'author', value: 'author', Type: 'Single Line Text'},
                { text: 'Publish Status', value: 'PublishStatus', Type: 'Single Line Text'},
                { text: 'coverimage', value: 'coverimage', Type: 'Single Filed Upload',IsFeaturedPhoto: true},
                
            ],
            SiteBlogs: [],
            
        }
    },
    computed:{
        computeditems(){
            if(this.$route.params.id){
                return [
                {
                    icon: 'mdi-blogger',
                    title: 'Draft'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published'
                },
            ]
            }
            else{
                return this.items
            }
        },
        SiteBlogsFilteredandSearched(){
            return this.SiteBlogs.map(article => {
                if(!article.pubDate && article.PublishDate){
                    article.pubDate = this.TimestampFormatterSTRINGDayText(article.PublishDate.toDate())
                }
                    article.monthcreated = this.TimestampFormatterNoticeBoard(article.PublishDate.toDate(),'month')
                    article.daycreated = this.TimestampFormatterNoticeBoard(article.PublishDate.toDate(),'date')        
                    article.createddate = this.TimestampFormatterNoticeBoard(article.PublishDate.toDate(),'full')                
          return article
        })
        },
        ComputedHeaders(){
            return this.headers
        },
        ComputedNotificationsQuery(){
            return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').where('blogid','==',this.ActiveRecord.id)
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
      
    },
    watch: {
      ActiveRecord(value){
          if (value !== null && value !== undefined ) {
              //this.Attending_Users = []
          
          
          }
      },
    },
    created(){
        //console.log('this.MenuItem',this.MenuItem)
        this.GetQuery(this.MenuItem)
        
    },
    methods:{
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetQuery(item){
            if(this.$route.params.id && item && item.groupcollectionname){
                let query = db.collection(item.groupcollectionname).where(item.groupcolfield,'==',this.$route.params.id)
                //console.log('Get '+item.groupcollectionname+' where '+item.groupcolfield+' == '+this.$route.params.id,this.userLoggedIn.id)
                this.GetSiteBlogs(query)
            }
            else if(item && item.sitecollectionname){
               let query = db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
               //console.log(item.sitecollectionname,'where ',item.sitecolfield,'==',this.userLoggedIn.id)
               this.GetSiteBlogs(query)
            }
        },
        PushItem(item){
            this.ActiveRecord = item
        },
        GetMatchingStatus(item){
            return this.SiteBlogsFilteredandSearched
            .filter(blog => {
                return blog.PublishStatus === item.title
            })
            .map(blog => {
                if(item.title === 'Draft'){
                    blog.class = 'warning--text'
                }
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                return blog
            })
            
        },
        GetSiteBlogs(query){
            //console.log(this.ActiveSession)
            query.onSnapshot(res => {
                let listlength = res.docs.length
                const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      this.SiteBlogs.push({
                      ...change.doc.data(),
                      id: change.doc.id
                      })
                  }
                  if(this.SiteBlogs.length === listlength){
                      this.$emit('PushArray',this.MenuItem,this.SiteBlogs)
                  }
              })
            })
        },
    }

}
</script>

<style>

</style>


    