<template>
  <div>
      <v-list-item   :class="OnePropEmit('PageClass',page)" @dragend="OnePropEmit('CancelNavItemDrag')"
                                 @dragover.prevent  @drop.prevent="page.OnMenu ? OnePropEmit('NavItemDragFinish',page.MenuIndex, $event) : ''"									
                                :draggable="page.OnMenu" @dragstart="OnePropEmit('NavItemDragStart',page.MenuIndex, $event,pageindex)"
                                dense
                                :style="OnePropEmit('PageItemStyle',page)"
                                >	
                                 <!-- with websitebuilder this works because ,actually i dont know how it works. it can't and should not clicking item to interect or clickign to trigger "change" or 'click" same
                                    @change="CanNavigate && !MoveMenuPages ? OnePropEmit('NavigatetoPage',page) : ''"						 -->
                                    <v-list-item-avatar @click="MoveItemMenuPos('Up',page,pageindex)" v-if="MoveMenuPages && page.OnMenu" size="15" >
                                        <v-icon :disabled="pageindex === 0" x-small class="links--text actionicon">mdi-chevron-up</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-avatar @click="MoveItemMenuPos('Down',page,pageindex)"  v-if="MoveMenuPages && page.OnMenu" size="15" >
                                        <v-icon :disabled="pageindex === Navlist.length-1" x-small class="links--text actionicon">mdi-chevron-down</v-icon>	
                                    </v-list-item-avatar>	
                                <v-list-item-avatar class="sitenavitembtns" style="margin-right: 0px !important;" size="20">
                                         <v-icon  small>mdi-drag</v-icon> 
                                    </v-list-item-avatar>
                                    <v-list-item-avatar v-if="page.SubPage" style="margin-right: 0px !important;" size="20">
                                         <v-icon color="grey lighten-1" small>mdi-subdirectory-arrow-right</v-icon> 
                                    </v-list-item-avatar>
                                    <v-list-item-avatar style="margin-right: 0px !important;" size="20">
                                         <v-icon small>{{page.PageIcon}}</v-icon> 
                                    </v-list-item-avatar>
                                    <v-list-item-content style="padding: 4px;" :class="page.RenderStyling" >
                                        <span v-if="page.Name.length > 18">
                                      {{page.Name.substr(0,15)}}...
                                        </span>
                                        <span v-else>
                                             {{page.Name}}
                                        </span>
                                    </v-list-item-content>
                                    <v-list-item-actions>
                                        <v-icon v-if="page.MenuIndex === 100000" small>mdi-eye-off-outline</v-icon>
                                        <v-icon v-if="page.MenuIndex !== 100000" small>mdi-eye</v-icon>
                                        
                                    </v-list-item-actions>
                                    <v-list-item-actions v-if="CanNavigate && !MoveMenuPages">
                                    <v-btn x-small @click="OnePropEmit('NavigatetoPage',page)" fab>
                                        Go
                                    </v-btn>
                                    </v-list-item-actions>
                                    <v-list-item-actions>
                                        <v-menu nudge-right="30"								
                                            :close-on-content-click="false"                            									
                                            transition="scale-transition"                            									
                                            >									
                                            <template v-slot:activator="{ on }">	
                                            <v-btn 
                                            v-on="on"
                                            icon fab small
                                            >
                                             <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                                            </v-btn>
                                            </template>
                                            <v-card flat width="170">
                                                <v-btn v-if="!SiteTemplate" @click="TwoPropsEmit('ActivateTemplateDialog','Page',pageindex)" small outlined color="success"><v-icon>mdi-cash-register</v-icon>Market</v-btn>
                                                <v-list style="padding: 0px;" dense class="transparent">
                                                    <v-list-item v-if="!FeaturedMemberView">
                                                        <v-switch @change="UpdateClearNavbarColor(page)" v-model="page.ClearNavbarColor" label="Clear Navbar Color"/>
                                                    </v-list-item>
                                                    <v-list-item class="settingsfont" dense @click="page.MenuIndex === 100000 ? OnePropEmit('PushPagetoNavlistArray',page) : OnePropEmit('RemovePagefromNavlistArray',page)">
                                                        <v-icon style="padding:5px;" size="15">
                                                            {{page.MenuIndex === 100000 ? 'mdi-eye' : 'mdi-eye-off'}}
                                                        </v-icon>
                                                        {{page.MenuIndex === 100000 ? 'Show' : 'Hide'}}
                                                    </v-list-item>
                                                    <v-list-item @click="OnePropEmit('UpdatePublicLandingPage',page)" class="settingsfont" dense v-if="CanSetLandingPage(page)">
                                                        <v-icon style="padding:5px;" size="15">
                                                             mdi-home
                                                        </v-icon>
                                                        Make Root
                                                    </v-list-item>
                                                    <v-list-item @click="FourPropsEmit('AddNewPage',SelectedNavbar,true,page.MenuIndex)" class="settingsfont" dense v-if="!page.SubPage && page.MenuIndex !== 100000 && !FeaturedMemberView">
                                                        <v-icon style="padding:5px;" size="15">
                                                             mdi-plus
                                                        </v-icon>
                                                        Add Sub Page
                                                    </v-list-item>
                                                    <v-list-item @click="OnePropEmit('SortChildrenAlphabetically',page)" class="settingsfont" dense v-if="page.Children && page.Children.length > 0">
                                                        <v-icon style="padding:5px;" size="15">
                                                             mdi-order-alphabetical-ascending
                                                        </v-icon>
                                                        Sort 
                                                    </v-list-item>
                                                    <v-list-item @click="OnePropEmit('PushtoPreviousNavItem',page)" class="settingsfont" dense v-if="!page.SubPage && page.MenuIndex !== 100000 && !page.Children && page.MenuIndex !== 0
                                                    || !page.SubPage && page.MenuIndex !== 100000 && page.Children && page.Children.length === 0 && page.MenuIndex !== 0">
                                                        <v-icon style="padding:5px;" size="15">
                                                             mdi-subdirectory-arrow-right
                                                        </v-icon>
                                                        Subpage
                                                    </v-list-item>    
                                                     <v-list-item @click="OnePropEmit('PushPageUpwards',page)" class="settingsfont" dense v-if="page.SubPage && page.MenuIndex !== 100000">
                                                        <v-icon style="padding:5px;" size="15">
                                                             mdi-subdirectory-arrow-left
                                                        </v-icon>
                                                        Push up
                                                    </v-list-item>                                                 
                                                    <v-menu nudge-left="100"								
                                                        :close-on-content-click="false"                            									
                                                        transition="scale-transition"                            									
                                                        >									
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                <v-icon style="padding:5px;" size="15">
                                                                    mdi-pencil
                                                                </v-icon>
                                                                Rename
                                                            </v-list-item>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="settingsfont">
                                                               Rename<v-spacer></v-spacer>
                                                               <v-btn dark color="success" small @click="TwoPropsEmit('UpdatePageProp',page,'Name')">Save</v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-text-field label="Page Name" v-model="page.Name"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                    <v-menu nudge-left="100"								
                                                        :close-on-content-click="false"                            									
                                                        transition="scale-transition"                            									
                                                        >									
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                <v-icon style="padding:5px;" size="15">
                                                                    mdi-link
                                                                </v-icon>
                                                                Path
                                                            </v-list-item>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="settingsfont">
                                                               Path<v-spacer></v-spacer>
                                                               <v-btn dark color="success" small @click="TwoPropsEmit('UpdatePageProp',page,'PageRoute')">Save</v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-text-field label="Page Path" v-model="page.PageRoute"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                    <v-menu nudge-left="100"								
                                                        :close-on-content-click="false"                            									
                                                        transition="scale-transition"                            									
                                                        >									
                                                        <template v-slot:activator="{ on }">
                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                <v-icon style="padding:5px;" size="15">
                                                                    mdi-circle
                                                                </v-icon>
                                                                Icon
                                                            </v-list-item>
                                                        </template>
                                                        <v-card>
                                                            <v-card-title class="settingsfont">
                                                               Icon
                                                               <v-spacer></v-spacer>
                                                               <v-btn dark color="success" small @click="TwoPropsEmit('UpdatePageProp',page,'PageIcon')">Save</v-btn>
                                                            </v-card-title>
                                                            <v-card-text>
                                                                <v-text-field label="Page Icon" v-model="page.PageIcon"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                    <v-list-item v-if="!FeaturedMemberView" class="settingsfont" dense @click="OnePropEmit('ActivateSEOBasicsDialog',page)">
                                                        <v-icon style="padding:5px;" size="15">
                                                            mdi-search-web
                                                        </v-icon>
                                                        SEO Basics
                                                    </v-list-item>
                                                    
                                                    <v-list-item @click="OnePropEmit('ConfirmDeletePage',page)" class="settingsfont" dense>
                                                        <v-icon color="red" style="padding:5px;" size="15">
                                                            mdi-delete-forever
                                                        </v-icon>
                                                        Delete
                                                    </v-list-item>
                                                    <!-- <v-list-item @click="OnePropEmit('ConfirmPageTransfer',page)" class="settingsfont" dense>
                                                        <v-icon color="red" style="padding:5px;" size="15">
                                                            mdi-transfer
                                                        </v-icon>
                                                        Transfer
                                                    </v-list-item> -->
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                     
                                     </v-list-item-actions>
                                     <v-icon :class="NoChildrenToggle ? 'soloactioniconsmlinactive' : 'soloactioniconsml'" small @click="ShowChildren = !ShowChildren" color="blue" :disabled="NoChildrenToggle">mdi-chevron-down</v-icon>
                                </v-list-item>
                                <v-list v-if="PageChildren">
                                         <v-list-item  v-for="(childpage,childpageindex) in PageChildren" :key="childpage.itemObjKey" :class="OnePropEmit('PageClass',childpage)" @dragend="OnePropEmit('CancelNavItemDrag')"
                                                @dragover.prevent  @drop.prevent="page.OnMenu ? TwoPropsEmit('NavItemDragFinish',childpage.MenuIndex, page.MenuIndex) : ''"									
                                                :draggable="page.OnMenu" @dragstart="TwoPropsEmit('NavItemDragStart',childpage.MenuIndex, page.MenuIndex,childpageindex)"
                                                dense 
                                                :style="OnePropEmit('PageItemStyle',childpage,page)"
                                                >
                                              
                                                <v-list-item-avatar class="sitenavitembtns" style="margin-right: 0px !important;" size="20">
                                                        <v-icon  small>mdi-drag</v-icon> 
                                                    </v-list-item-avatar>
                                                    <v-list-item-avatar v-if="childpage.SubPage" style="margin-right: 0px !important;" size="20">
                                                        <v-icon color="grey lighten-1" small>mdi-subdirectory-arrow-right</v-icon> 
                                                    </v-list-item-avatar>
                                                    <v-list-item-avatar style="margin-right: 0px !important;" size="20">
                                                        <v-icon small>{{childpage.PageIcon}}</v-icon> 
                                                    </v-list-item-avatar>
                                                    <v-list-item-content style="padding: 4px;" :class="page.RenderStyling" >
                                                        <span v-if="childpage.Name.length > 18">
                                                    {{childpage.Name.substr(0,15)}}...
                                                        </span>
                                                        <span v-else>
                                                            {{childpage.Name}}
                                                        </span>
                                                    </v-list-item-content>
                                                    <v-list-item-actions>
                                                        <v-icon v-if="page.MenuIndex === 100000" small>mdi-eye-off-outline</v-icon>
                                                        <v-icon v-if="page.MenuIndex !== 100000" small>mdi-eye</v-icon>
                                                        
                                                    </v-list-item-actions>
                                                    <v-list-item-actions>
                                                        <v-menu nudge-right="30"								
                                                            :close-on-content-click="false"                            									
                                                            transition="scale-transition"                            									
                                                            >									
                                                            <template v-slot:activator="{ on }">	
                                                            <v-btn 
                                                            v-on="on"
                                                            icon fab small
                                                            >
                                                            <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                                                            </v-btn>
                                                            </template>
                                                            <v-card flat width="170">
                                                                <v-list style="padding: 0px;" dense class="transparent">
                                                                    <v-list-item class="settingsfont" dense @click="childpage.MenuIndex === 100000 ? OnePropEmit('PushPagetoNavlistArray',childpage) : OnePropEmit('RemovePagefromNavlistArray',childpage)">
                                                                        <v-icon style="padding:5px;" size="15">
                                                                            {{childpage.MenuIndex === 100000 ? 'mdi-eye' : 'mdi-eye-off'}}
                                                                        </v-icon>
                                                                        {{childpage.MenuIndex === 100000 ? 'Show' : 'Hide'}}
                                                                    </v-list-item>
                                                                    <v-list-item @click="OnePropEmit('PushtoPreviousNavItem',childpage)" class="settingsfont" dense v-if="!childpage.SubPage && childpage.MenuIndex !== 100000">
                                                                        <v-icon style="padding:5px;" size="15">
                                                                            mdi-subdirectory-arrow-right
                                                                        </v-icon>
                                                                        Subpage
                                                                    </v-list-item>    
                                                                    <v-list-item @click="OnePropEmit('PushPageUpwards',childpage)" class="settingsfont" dense v-if="childpage.SubPage && childpage.MenuIndex !== 100000">
                                                                        <v-icon style="padding:5px;" size="15">
                                                                            mdi-subdirectory-arrow-left
                                                                        </v-icon>
                                                                        Push up
                                                                    </v-list-item>                                                 
                                                                    <v-menu nudge-left="100"								
                                                                        :close-on-content-click="false"                            									
                                                                        transition="scale-transition"                            									
                                                                        >									
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                                <v-icon style="padding:5px;" size="15">
                                                                                    mdi-pencil
                                                                                </v-icon>
                                                                                Rename
                                                                            </v-list-item>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title class="settingsfont">
                                                                            Rename<v-spacer></v-spacer>
                                                                            <v-btn dark color="success" small @click="TwoPropsEmit('UpdatePageProp',childpage,'Name')">Save</v-btn>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-text-field label="Page Name" v-model="childpage.Name"
                                                                                />
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-menu>
                                                                    <v-menu nudge-left="100"								
                                                                        :close-on-content-click="false"                            									
                                                                        transition="scale-transition"                            									
                                                                        >									
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                                <v-icon style="padding:5px;" size="15">
                                                                                    mdi-link
                                                                                </v-icon>
                                                                                Path
                                                                            </v-list-item>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title class="settingsfont">
                                                                            Path<v-spacer></v-spacer>
                                                                            <v-btn dark color="success" small @click="TwoPropsEmit('UpdatePageProp',childpage,'PageRoute')">Save</v-btn>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-text-field label="Page Path" v-model="childpage.PageRoute"
                                                                                />
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-menu>
                                                                    <v-menu nudge-left="100"								
                                                                        :close-on-content-click="false"                            									
                                                                        transition="scale-transition"                            									
                                                                        >									
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-list-item v-on="on" class="settingsfont" dense>
                                                                                <v-icon style="padding:5px;" size="15">
                                                                                    mdi-circle
                                                                                </v-icon>
                                                                                Icon
                                                                            </v-list-item>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title class="settingsfont">
                                                                            Icon
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn dark color="success" small @click="TwoPropsEmit('UpdatePageProp',childpage,'PageIcon')">Save</v-btn>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-text-field label="Page Icon" v-model="childpage.PageIcon"
                                                                                />
                                                                            </v-card-text>
                                                                        </v-card>
                                                                    </v-menu>
                                                                    <v-list-item class="settingsfont" dense @click="OnePropEmit('ActivateSEOBasicsDialog',childpage)">
                                                                        <v-icon style="padding:5px;" size="15">
                                                                            mdi-search-web
                                                                        </v-icon>
                                                                        SEO Basics
                                                                    </v-list-item>
                                                                    
                                                                    <v-list-item class="settingsfont" dense>
                                                                        <v-icon color="red" style="padding:5px;" size="15">
                                                                            mdi-delete-forever
                                                                        </v-icon>
                                                                        Delete
                                                                    </v-list-item>
                                                                    <!-- <v-list-item @click="OnePropEmit('ConfirmPageTransfer',page)" class="settingsfont" dense>
                                                                        <v-icon color="red" style="padding:5px;" size="15">
                                                                            mdi-transfer
                                                                        </v-icon>
                                                                        Transfer
                                                                    </v-list-item> -->
                                                                </v-list>
                                                            </v-card>
                                                        </v-menu>
                                                    
                                                    </v-list-item-actions>
                                                </v-list-item>
                                     </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NavMenuPages from '@/components/SuiteBuilder/Navigators/NavMenuPages';	
export default {
    props: ['System','SystemEntities','page','pageindex','SelectedNavbar','CanNavigate','MoveMenuPages','Navlist'],
    components: {NavMenuPages},
    data() {
        return {
            ShowChildren: false,
        }
    },	
    computed:{
        BuilderView(){
        return this.$store.state.BuilderView
        },
        WebsiteBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        MarketplaceSiteBuilderView(){
          return this.BuilderView && this.BuilderView.Name === 'Marketplace Site'
      },
        SiteTemplate(){
            return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Primary_Category && this.ActiveMarketTemplate.Primary_Category.Name === 'Site' && this.MarketplaceSiteBuilderView
        },
        SuiteWebsite(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.Website : ''
        },
        WebsiteTemplate(){
            return this.SuiteWebsite ? this.SuiteWebsite : this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Website'
        },
        SocialNetworkTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Social Network'
        },
        FeaturedMemberTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Group Site'
        },
        ActiveMarketTemplate(){
            return this.$store.state.ActiveMarketTemplate
        },
        NoPublicPage(){
            return this.System && !this.System.Public_Landing_Page && this.WebsiteBuilderView
        },
        FeaturedMemberView(){
      return this.$route.name === 'FeaturedMemberViewer' || this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'
    },
        NoChildrenToggle(){
            return !this.page.Children || this.page.Children && this.page.Children.length === 0
        },
        PageChildren(){
            return this.ShowChildren && this.page && this.page.Children ?
            this.page.Children.sort(function (a, b) {
            var result;            
            a = a.MenuIndex.split('.'), 
            b = b.MenuIndex.split('.');
            while (a.length) {
                result = a.shift() - (b.shift() || 0);
                
                if (result) {
                return result;
                }
            }

            return -b.length;
            }) : ''
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        
        CanSetLandingPage(page){
            //this.NoPublicPage || System.Public_Landing_Page && System.Public_Landing_Page !== '/'+page.Name.split(' ').join('-') && WebsiteBuilderView || 
            let pageroute = '/'+page.Name.split(' ').join('-')
            //console.log('pageroute',pageroute)
            return this.NoPublicPage && this.WebsiteBuilderView || this.SuiteWebsite ||
            this.System.Public_Landing_Page &&  this.System.Public_Landing_Page !== pageroute && this.WebsiteBuilderView ||
            this.WebsiteTemplate && !this.ActiveMarketTemplate.TemplateProps.Public_Landing_Page || 
            this.WebsiteTemplate && this.ActiveMarketTemplate.TemplateProps.Public_Landing_Page !== pageroute || 
            this.SocialNetworkTemplate && !this.ActiveMarketTemplate.TemplateProps.Social_Landing_Page || 
            this.SocialNetworkTemplate && this.ActiveMarketTemplate.TemplateProps.Social_Landing_Page !== pageroute 
            //other types may follow
        },
        UpdateClearNavbarColor(page){
            this.OnePropEmit('UpdateClearNavbarColor',page)
        },
        MoveItemMenuPos(direction,page,pageindex){             
             let newindex = pageindex
            if(direction === 'Up'){
                newindex = newindex-1
            }
            else{
                newindex = newindex-1+2
            }
            if(page.OnMenu){
                this.OnePropEmit('NavItemDragStart',page.MenuIndex,)
                this.OnePropEmit('NavItemDragFinish',newindex)
            }
            //checking OnMenu although should disable buttons if not OnMenu
            //OnePropEmit('NavItemDragStart',page.MenuIndex, $event,pageindex)
            //OnePropEmit('NavItemDragFinish',newindex, $event)
        },
        FourPropsEmit(Method,Prop,SecondProp,ThirdProp,FourthProp){
            this.$emit('FourPropsEmit',Method,Prop,SecondProp,ThirdProp,FourthProp)
        },
        TwoPropsEmit(Method,Prop,SecondProp){
            this.$emit('TwoPropsEmit',Method,Prop,SecondProp)
        },
        OnePropEmit(Method,Prop){
            this.$emit('OnePropEmit',Method,Prop)
        },
    }
}
</script>

<style>

</style>



