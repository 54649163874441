
<template>
<v-card class="transparent" flat width="100%" height="100%" style="padding-bottom:50px;">

    <v-parallax v-if="GroupData && GroupData.logo"
            height="150"
            :src="GroupData.logo"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center"  :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
            <v-parallax v-if="!GroupData"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center"  :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax>            
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Article.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
    <v-flex xs12 s12 m10 lg10>
    <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Article</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="ArticleTitle">
                </v-text-field>
                <UserLookup v-if="$store.state.TemplateView" :UsersLookupArray="TemplateUsers"  :ModelProp="'Creator'" :RecordObj="editedItem" @UpdateUserLookupProp="UpdateUserLookupProp"
                            :FieldLabel="'Author'" :Rules="[$store.state.formrules.required]"
                            />  
                <v-combobox return-object item-text="Name" 
                  chips v-model="Category" :items="ArticlesCategories" label="Category" >
                </v-combobox>                
                <v-switch v-if="!$store.state.UserisGuest && System.Guests_can_Social || System.Guests_can_Social && GroupData" v-model="GuestsIncluded" :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
                <v-menu
                        v-model="PublishDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="PublishDate">
                        <template v-slot:activator="{ on }">
                          <v-text-field                            
                            v-model="PublishDate"
                            label="Publish Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="PublishDate"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="PublishDate" @input="PublishDatemenu = false"></v-date-picker>
                      </v-menu>
                <v-checkbox label="Is Image Shy" v-model="ImageShy"/>
                <input
                v-if="!ImageShy && !$store.state.TemplateView"
            type="file"
            @change="onarticlecoverimageselect($event)"
            ref="coverimageinputter">
           
                </v-card-text>
                <v-layout class="justify-center">
                
          <v-img v-if="coverimagelocalurl && !$store.state.TemplateView" :src="coverimagelocalurl">
          </v-img>
          <v-img class="black" height="200" contain	v-if="$store.state.TemplateView"								
              :src="coverimage ? coverimage : require('@/assets/ImageHolder.png')"									
              >
              <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
          
                  <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteArticleCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
              </v-row>
        </v-img>
                </v-layout>
          <v-card-text>
             <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text>
            <v-card-actions>    
            <v-textarea label="Caption" v-model="Caption" :rules="[$store.state.formrules.max150Chars]" />
            </v-card-actions>
            <v-card-actions>                
                <v-select multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-card-actions>
            <v-card-actions>
                
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StoreArticle()">
                    Post
                </v-btn>
                
            </v-card-actions>
        </v-card>
        </v-layout>
   
    
   </v-flex>
</v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
import UserLookup from '@/components/General/UserLookup'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'

export default {
    props: ['System'],
        middleware: 'auth',
      components: {
        ContentEditable,
        UserLookup
    // quillEditor

        
  },
  
    data() {
        return {
          coverimage: '',
          Category: '',
          ItemCategory: '',
          GuestsIncluded: false,
          TagLinkDialog: false,
          Caption: '',
          UsersArray: [],
          SiteModerators: [],
          Moderators: [],
          PublishDate: '',
          PublishDatemenu: false,
          ArticleTitle: '',
          ImageShy: false,
            GroupData: '',
        ImagelocalURL: '',
        ArticleContent: '',
        articlequil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        articledialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      articletitle: null,
        articlecontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        grouparticles: [],
        groups: [],
        articleid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        image: '',
        Youtubelink: '',
        rules: {
        max150Chars: value => value.length < 151 || "Max. 150 characters",
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            ArticlesCategories: []    
        }
    },
    computed: {
      
        ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
      groupid(){
        return this.$route.params.id && !this.$store.state.TemplateView ? this.$route.params.id : ''
      },
      TemplateUsers(){
        return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) :
        this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Users ? this.ActiveSuiteTemplate.Users.concat([this.userLoggedIn]) : [this.userLoggedIn]
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },      
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ContentCategoriesPath(){
        return this.$route.name === 'GroupNewArticle' ? this.ContentRootPath.collection('groups').doc(this.$route.params.id).collection('ArticlesCategories') : 
        this.ContentRootPath.collection('ArticlesCategories')
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      ContentColPath(){
        //quick tip here. Edit and New components reuqire collection to update, or add docs. thus, not interchangeable with CollectionRef on "feed" or "collection view"
        //the CollectionRef would add permission queries like for group aticles "where gourpid...etc"
        return this.$store.state.TemplateView? this.ContentRootPath.collection('samplesitearticles') : 
        this.$route.name === 'GroupNewArticle' ? this.ContentRootPath.collection('grouparticles') : this.ContentRootPath.collection('sitearticles')
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserIsModerator(){
          if(this.$route.name === 'GroupNewMeetup'){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.MeetupsModerator
          }
          else if(this.userLoggedIn && this.userLoggedIn.ModerationPermissions){
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Meetups')
              if(moderator){
                return true
              }
              else{
                return false
              }
          }
          else {
            return this.userIsAdmin
          }
        },
        NonModeratorRoute(){
          if(this.$route.name === 'GroupNewArticle'){
            return '/Group/'+this.$route.params.id
          }
          else{
            return '/Articles'
          }
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
       
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupArticle' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Articles' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
        ModeratorsIDStrings(){
          return this.Moderators.map(mod => {
            mod.id
          })
        },
        RelatedArticles () {
            return this.grouparticles.filter(article => {
                return article.articleid === this.articleid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleSiteArticleCoverIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {
        
          this.GetSiteArticlesCategories()
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;        
        this.UserRecord = this.userLoggedIn
        this.authorid = this.UserRecord.id
        this.author = this.UserRecord.Full_Name
        if(this.UserIsModerator && !this.$store.state.TemplateView){
          if(this.System.Social_Content_for_Guests || this.$store.state.UserisGuest){
            this.GuestsIncluded = true
          }
            this.FocusedViewToggle()
            this.IntranetViewToggle()
            let moderatorsquery = db.collection('sitemoderators')
            if(!this.$route.params.id){
              this.GetSiteModerators(moderatorsquery)
            }
            else{
              this.GetGroup()
            }
        }
        else if(this.$store.state.TemplateView){
          this.UserRecord = this.userLoggedIn
        }
        else{
          this.$router.push(this.NonModeratorRoute)
        }

    },
    
    methods: {
      UpdateUserLookupProp(Prop,Value,RecordObj){
          RecordObj[Prop] = Value
      },
      GetSiteArticlesCategories(){
        this.ContentCategoriesPath.onSnapshot(res => {
            const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  this.ArticlesCategories.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
              })
          })
      },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      AssignSampleSiteArticleCoverIMG(){
        this.coverimage = this.SelectedImage.fileurl
       //this.RefreshDialog()
        this.$store.commit('setIMGProp','')
      },
      GetGroup(){
                this.GroupData = this.ActiveGroup
                this.GuestsIncluded = true
                //let moderatorsquery = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers')
                let Moderator = {
                  Full_Name: this.userLoggedIn.Full_Name,
                  Name: this.userLoggedIn.Name,
                  Surname: this.userLoggedIn.Surname,
              ModerationPermissions: this.userLoggedIn.ModerationPermissions ? this.userLoggedIn.ModerationPermissions : [],
                  id: this.userLoggedIn.id
                }
                this.Moderators.push(Moderator)
                this.SiteModerators.push(Moderator)
        },
      ActivateTagLinkDialog(boolean){
        this.TagLinkDialog = boolean  
      },
      taglink(){
        this.TagLinkDialog = true
      },
      SaveSingleTag(tagitem){
        console.log(tagitem)
        this.TagLinkDialog = false  
        document.execCommand("createLink", false, tagitem.taglocation);
      },
      GetSiteModerators(query){
        let Moderator = {
          Full_Name: this.userLoggedIn.Full_Name,
          Name: this.userLoggedIn.Name,
          Surname: this.userLoggedIn.Surname,
          ModerationPermissions: this.userLoggedIn.ModerationPermissions ? this.userLoggedIn.ModerationPermissions : [],
          id: this.userLoggedIn.id
        }
        this.Moderators.push(Moderator)
        query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          
        })
      },
   DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
        onarticlecoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        StoreArticle(){
          let vm = this
          let col = ''
          let Creator = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
            let MaskedUser = ''
           if(this.$store.state.TemplateView && this.editedItem.Creator){
              Creator = {Full_Name: this.editedItem.Creator.Full_Name,id: this.editedItem.Creator.id,Name: this.editedItem.Creator.Name,Surname: this.editedItem.Creator.Surname}
              MaskedUser = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname}
            }
          if(vm.Category && !vm.Category.ID){
            let length = vm.ArticlesCategories.length
            let int = 1000001+length
            let newcat = {
              ID: int,
              Name: vm.Category
            }
            vm.ItemCategory = newcat
            this.ContentCategoriesPath.add(newcat)
          }
          else if(vm.Category && vm.Category.ID){
            vm.ItemCategory = vm.Category
          }
          let routepath = ''
          let routeprop = ''
          let storagepath = ''
           if(this.ActiveMarketTemplate){
              routepath = '/MarketplaceTemplate/'+this.$route.params.id+'/Article/'
              routeprop = 'id'
            }
            else if(this.ActiveSuiteTemplate){
              routepath = '/Your-Domain/'+this.$route.params.id+'/Social-Network/Article/'
              routeprop = 'id'
            }
          else if(this.$route.params.id){
            routepath = '/Group-Article/'+this.$route.params.id+'/Article/'
            routeprop = 'title'
          }
          else{
            routepath = '/Article/'
            routeprop = 'id'
          }
          let ref = this.ContentColPath.doc()
          let newdocid = ref.id
        let postelmnt = document.getElementById('NewBlogValue')
        //console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewArticle = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.ArticleTitle.trim(),
          Creator: Creator,          
          Creatorid: Creator.id,
          author: Creator.Full_Name,
          authorid: Creator.id,
          description: postinput,
          createdon: new Date(),
          PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
          caption: this.Caption,
          ImageShy: this.ImageShy,
          GuestsIncluded: this.GuestsIncluded
        }
        if(MaskedUser){
          NewArticle.MaskedUser = MaskedUser
          NewArticle.MaskedUserid = MaskedUser.id
        }
        if(vm.ItemCategory){
          NewArticle.Category = vm.ItemCategory
        }
        if(this.groupid){
          NewArticle.groupid = this.groupid
        }
        if(!NewArticle.groupid && vm.userLoggedIn && vm.userLoggedIn.Company){
          NewArticle.Company = vm.userLoggedIn.Company
          NewArticle.Companyid = vm.userLoggedIn.Companyid
        }
          
        if(Creator.Profile_Photo){
          NewArticle.creatorimg = Creator.Profile_Photo
        }
        this.$store.dispatch('CreateSearchTitle',NewArticle.title).then(searchtitle => {
        NewArticle.titleQuery = searchtitle
          if(this.selectedcoverimage && this.ArticleTitle && postinput && !this.$store.state.TemplateView){
            
          vm.$emit('ActivateProcessing',true)
          
          let filename = this.selectedcoverimage.name.split('.')[0]+'_'+this.selectedcoverimage.size+'.'+this.selectedcoverimage.name.split('.')[1]
          if(this.$route.params.id){
              storagepath = 'GroupArticles/'+this.$route.params.id+'/CoverImage/'+newdocid+'/'+new Date()+'/'+filename
            }
            else{
              storagepath = 'SiteBlogs/'+this.$route.params.id+'/CoverImage/'+new Date()+'/'+filename
            }
          this.PrepareUpdateArticleCoverImageThumbnail(this.selectedcoverimage,newdocid,NewArticle,storagepath)
          }
          else if(this.ImageShy && this.ArticleTitle && postinput || this.$store.state.TemplateView){
          
            vm.$emit('ActivateProcessing',false)
            //routeprop
            //grouparticles
            let newdocref = this.ContentColPath.doc(newdocid)
            if(this.$store.state.TemplateView){
              NewArticle.coverimage = this.coverimage
              NewArticle.PublishDate = new Date()
            }
            
        
            newdocref.set(NewArticle).then(doc => {
              NewArticle.id = newdocid
              this.$router.push(routepath+NewArticle[routeprop])
                }) 
          }
          else if(!this.selectedcoverimage && this.ArticleTitle && postinput){
              alert('You have to upload an image')
          }
          else if(!this.selectedcoverimage && !this.ArticleTitle && postinput){
              alert('You have to upload an image, and insert a title')
          }
          else if(!this.selectedcoverimage && !this.ArticleTitle && !postinput){
              alert('There is nothing to post!')
          }
          else if(!this.selectedcoverimage && this.ArticleTitle && !postinput){
              alert('There is no cover image, or post content!')
          }
          else if(this.selectedcoverimage && !this.ArticleTitle && postinput){
              alert('You have to insert a title')
          }
          else if(this.selectedcoverimage && !this.ArticleTitle && !postinput){
              alert('You have to insert a title, and content')
          }
          else if(this.selectedcoverimage && this.ArticleTitle && !postinput){
              alert('You have to insert content')
          }
        })
        
      
        },

        PrepareUpdateArticleCoverImageThumbnail(file,newdocid,NewArticle,storagepath){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 1000
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storagepath+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      vm.UpdateArticleCoverImage(file,thumburl,newdocid,NewArticle,storagepath)
                    })
              })
      }
      },
      
        UpdateArticleCoverImage(picturefile,thumburl,newdocid,NewArticle,storagepath){
      let vm = this
      console.log('did thumbnail now major',picturefile,thumburl,newdocid)

      var storageRef = firebase.storage().ref(storagepath);
      var uploadTask = storageRef.put(picturefile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          console.log(url)
       
                NewArticle.coverimage = url
                NewArticle.coverimageThumbURL = thumburl
                NewArticle.CoverStorageRef = storagepath
                vm.$emit('ActivateProcessing',false)
               vm.ContentColPath.doc(newdocid).set(NewArticle).then(doc => {
                 if(vm.$route.params.id){
                   vm.$router.push('/Group-Article/'+this.$route.params.id+'/Article/'+newdocid)
                 }
                 else{
                   vm.$router.push('/Article/'+newdocid)
                 }
                   
               })
               //console.log(NewArticle.post)
                vm.CancelNewArticleDialog()
            
                
      })
    },
        CancelNewArticleDialog(){
            this.ArticleTitle = ''
            this.selectedcoverimage = ''
        },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
      

        })
            },
      
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>