<template>
  <v-layout class="justify-center">
      <!-- <v-menu                     
            :close-on-content-click="false"
            max-width="300px"
            transition="scale-transition"
            offset-y
            >
            <template v-slot:activator="{ on }">
                <v-card v-on="on" shaped max-width="500">
                <v-card-title class="overline links--text"
                
                >
            Shoes
                </v-card-title>
                <v-img
                class="my-1"
                    contain
                     src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                    max-height="70"
                    aspect-ratio="1"
                    />
                </v-card>
            </template>
            <v-card max-width="300px" shaped>
                <v-card-title class="mediumoverline white--text"  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
            Shoes
            <v-spacer></v-spacer>
                <v-btn x-small >
                View
                </v-btn>
                </v-card-title>
                <v-img
                contain
                 src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                aspect-ratio="1"
                max-height="150"
                />
                <v-card-text class="caption">
                Some awesomeshows
                </v-card-text>                       
            </v-card>
        </v-menu> -->
        <v-card  max-width="374">
            <v-card-title class="primary ">
                    <v-list-item two-line>
                        <v-list-item-content>
                        <v-list-item-title style="font-size:1em;" class="white--text mediumoverline">
                            <!-- <v-icon color="white" small>mdi-cart</v-icon> -->
                            Green and Black Comfy Slops</v-list-item-title>
                        <v-list-item-subtitle class="overline white--text">
                            <!-- Slops -->
                            <v-btn x-small >
                            Read More
                            </v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-list-item-action>
                        <v-btn x-small >
                            View
                            </v-btn>
                        </v-list-item-action> -->
                    </v-list-item>
                
                </v-card-title>
                <!-- <v-card-subtitle class="mediumoverline">
                    R500.00
                </v-card-subtitle> -->
               <v-img
               style="padding-top:50px;"
                height="250"
                src="@/assets/StoreShoes.png"
                ></v-img>
 
                
                <v-divider>
                </v-divider>
                <v-card-text>
                    <div>The comfort of the Green and Black slops is incomparable with any other slops out there in the market today. Specifcally engineered as Physiotherapeutical slops for the elderly, mom would never complain again. </div>
                </v-card-text>
                    <v-divider>
                </v-divider>
            
        </v-card>
  </v-layout>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },
    computed:{

    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
