<template>
  <v-card tile flat width="100%" height="100%">
      <v-dialog max-width="1000" v-model="OpenFormComponent">
    <DigitizedFormComponent v-if="OpenFormComponent && ActiveForm" @SubmitForm="SubmitForm" :ActiveForm="ActiveForm" :comment="ActiveComment" :userLoggedIn="userLoggedIn"/>
  </v-dialog>
      <v-textarea :height="input && input.length > 18? Math.ceil((input.length/18))*30 : 30"
                    v-model="input"
                    hide-details
                    flat
                    label="Leave a comment..."
                    solo
                    @keydown.enter="comment(TimelineRecord,null,LiaisonUploadFile)"
                    >
                    <template v-slot:append>
                    <v-menu
                        
                        top
                        offset-y
                        :close-on-content-click="false"
                        >
                        <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-link</v-icon>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-text-field v-model="NewLink" label="link" :rules="[$store.state.formrules.url]"/>
                            </v-list-item>
                            <v-list-item-action>
                                <v-btn @click="link(NewLink,TimelineRecord)">Save</v-btn>
                            </v-list-item-action>
                        </v-list>
                        </v-menu>

                        <input v-if="!LiaisonUploadFile"
                            style="display:none"
                            type="file"
                            @change="onFileselected($event)"
                            id="fileInputer">
                        <v-icon
                        class="mx-0"
                        depressed
                        @click="FileInput('fileInputer')"
                        >
                        mdi-paperclip
                        </v-icon>
                            <v-menu
                        
                        top
                        offset-y
                        v-if="ActiveSession.DigitizedForms.length >0"
                        >
                        <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        class="mx-0"
                        depressed
                        
                        >
                        mdi-dots-vertical-circle-outline
                        </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="comment(TimelineRecord,form)" v-for="form in ActiveSession.DigitizedForms" :key="form.itemObjKey">
                                {{form.Name}}
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        class="mx-0"
                        small
                        @click="comment(TimelineRecord,null,LiaisonUploadFile)"
                        >
                        Post
                        </v-btn>
                    </template>
      </v-textarea>
      <v-timeline dense>
        
          <v-virtual-scroll 
                width="100%"
                  class="transparent"
                    :items="ByLatestLiaison"
                    :item-height="ScrollItemHeight"
                    :height="ScrollHeight"
                  >
                  <template v-slot:default="{ item }">
                      <v-timeline-item 
                   
                    :color="item.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
                        icon-color="grey lighten-2"
                        small
                        fill-dot>
                        <template v-slot:icon>
                        <span
                            class="white--text ml-0"
                            
                            >
                            {{item.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{item.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </span>
                        </template>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{item.parsecreatedate}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{item.parsetime}}
                                    </v-list-item-subtitle>
                                    <!-- <v-list-item-subtitle style="word-wrap: break-word !important;"> -->
                                      <p v-html="item.description" class="caption">
                                      </p>
                                    <!-- </v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="item.ThumbURL">
                                <v-avatar tile size="150">
                            <v-img contain
                                :src="item.ThumbURL"
                                    />
                            </v-avatar>
                            </v-list-item>
                            <v-list-item v-if="item.fileurl">
                                <v-btn small v-if="!item.TokenURL"
                            download
                            @click="GetShortLivedURL(item)"
                            class="blue--text mx-0"
                            >
                            Get View Token <v-icon small>mdi-security</v-icon>
                            </v-btn>
                            <v-btn small v-if="item.TokenURL"
                            :href="item.TokenURL" target="_blank"
                            class="orange--text mx-0"
                            >
                            View File <v-icon>mdi-download</v-icon>
                            </v-btn>
                                <v-btn small v-if="!item.Filed && ActiveSession.DocumentRegisters && ActiveSession.DocumentRegisters.length>0 || !item.Filed && ActiveSession.Galleries && ActiveSession.Galleries.length>0"
                            @click="OpenSaveFiletoRecord(item)"
                            class="success--text mx-0"
                            >
                            Save to Record <v-icon color="success">mdi-content-save-move-outline</v-icon>
                            </v-btn>
                            </v-list-item>
                            <v-list-item v-if="item.FormObj">
                                <v-btn
                            @click="OpenForm(item.FormObj,item)"
                            class="blue--text mx-0"
                            >
                            Open Form
                            </v-btn>
                            </v-list-item>
                        </v-list>
                    
                    </v-timeline-item>
                    
                  </template>
          </v-virtual-scroll>           
      <v-timeline-item>
                    <v-btn x-small :disabled="!HasMore" @click="slice = slice+4">{{HasMore? 'More' : 'End'}}</v-btn>
                    <v-btn x-small v-if="slice>4" @click="slice = slice-4">Less</v-btn>
                </v-timeline-item>
        
      </v-timeline>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import { max } from 'date-fns/esm';

export default {
    props: ['System','SystemEntities','itemsprop','TimelineRecord','ActiveSession','CommsCollection','ScrollHeight'],
    components: {},
    data() {
        return {
          input: '',
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            NewLink: '',
            slice: 4,
            FiletoRecordDialog: false,
            DocumentTable: '',
            DocumentType: '',            
            UploadingFileProgress: 0,
            LiaisonUploadFile: '',
            OpenFormComponent: false,
        }
    },
    computed:{
        HasAttachments(){
          let hasthumbs = this.ByLatestLiaison.filter(item => {
            return item.ThumbURL
          })
          let hasfiles = this.ByLatestLiaison.filter(item => {
            return item.fileurl
          })
          let hasForms = this.ByLatestLiaison.filter(item => {
            return item.FormObj
          })          
          return hasthumbs.length > 0 || hasfiles.length > 0 || hasForms.length > 0
        },
        MaxLengthHeight(){
          let descriptionlengths = this.ByLatestLiaison.map(item => {
            let desclength = 0
            if(item.description !== null){
              desclength = item.description.length
            }
            return desclength
          })
          let maxlength = Math.max(...descriptionlengths)
          if(maxlength > 50){
           return Math.ceil((maxlength/80)*100) 
          }
          else{
            return 80
          }
          
        },
        ScrollItemHeight(){
          return this.HasAttachments? 300 : this.MaxLengthHeight
        },
        HasMore(){
           return this.TimelineRecord[this.itemsprop].length > this.slice
        },
        ByLatestLiaison(){
            return this.TimelineRecord[this.itemsprop]
            .map(liase => {
                let date = ''
                    if(liase.Created_On.toDate){
                        date = liase.Created_On.toDate()
                    }
                    else{
                        date = liase.Created_On
                    }
                    liase.parsecreatedate = this.TimestampFormatterSTRINGDayText(date)
                    let hours = date.getHours()
                    let mins = date.getMinutes()
                    if(hours === 0){
                    hours = '00'
                    }
                    else if(hours < 10){
                      hours = "0"+hours
                    }
                    if(mins === 0){
                    mins = '00'
                    }
                    else if(mins < 10){
                      mins = "0"+mins
                    }
                    liase.parsetime = hours+':'+mins 
                    liase.TimestampSort = date.getTime()
                    return liase
                  })
                  .sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                  .slice(0,this.slice) 
        },
        userLoggedIn () {
      return this.$store.getters.user
      },
    },
    created(){

    },
    methods:{
        link(url,record) {
        this.input = `See this link <a href="`+url+`" target="_blank">here</a>`
        document.execCommand("createLink", false, url);
        record.TimelineDialog = false
        record.TimelineDialog = true
        this.NewLink = ''
        this.comment(record)
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        comment(record,form,LiaisonUploadFile) {
        let NewComms = {
          description: this.input,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.ActiveSession.title,
          recordlocation: '/'+this.ActiveSession.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.ActiveSession.topicid,
        }
        if(record.ActiveProcess){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        if(form){
            NewComms.FormObj = form.Tabs[0].Sections[0].Content[0]
            if(form.readonly){
              NewComms.readonly = true
            }
        }
        if(!LiaisonUploadFile){
         this.SaveNewcomms(NewComms,record)  
        }
        else{
            //first upload the file, return url and include url in NewComms
            this.UploadFileandAttahctoNewComms(NewComms,record)
        }
        
        // this.events.push({
        //   id: this.nonce++,
        //   text: this.input,
        //   time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
        //     return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
        //   }),
        // })

        this.input = null
      },
      SaveNewcomms(NewComms,record){
        let owneruser = ''
        let owner = ''
          if(this.ActiveSession.LiaisonProp === 'InternalLiaison'){
            owneruser = record.Owner
            owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
          }
          else{
           
            owneruser = record[this.ActiveSession.ClientLookupField.Name]
            owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
          }
          NewComms.recipientid = owner.id
          this.$emit('SaveNewcomms',NewComms,record)
            
      },
      UploadFileandAttahctoNewComms(NewComms,record){
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        let filename = newdocid+'_'+this.LiaisonUploadFile.name
        console.log(filename)
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = NewComms.relatedtype.split(' ').join('')+'/'+NewComms.relatedid+'/'+this.ActiveSession.LiaisonProp+'/'+ filename
        NewComms.Uploadfilename = this.LiaisonUploadFile.name
        var storageRef = firebase.storage().ref(path)
        NewComms.StorageRef = path
        NewComms.newdocid = newdocid
        NewComms.FileType = this.LiaisonUploadFile.type
        NewComms.lastModifiedDate = this.LiaisonUploadFile.FileModifiedDate
          var uploadTask = storageRef.put(this.LiaisonUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.LiaisonUploadFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                NewComms.fileurl = downloadURL
                if(NewComms.FileType.includes('image')){
                  vm.$emit('ActivateProcessing',true)
                  vm.PrepareThumbnail(NewComms.Uploadfilename,vm.LiaisonUploadFile,NewComms.StorageRef).then(thumbresult => {
                        NewComms.ThumbURL = thumbresult
                        vm.$emit('ActivateProcessing',false)
                        vm.SaveNewcomms(NewComms,record)
                    })
                }
                else{
                  vm.SaveNewcomms(NewComms,record)
                }
                
              });
            })
                
        }, 300);
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
        FileInput(elid){
            let elmnt = document.getElementById(elid)
            elmnt.click()
        },
        onFileselected(event){
        this.LiaisonUploadFile = event.target.files[0]
        this.LiaisonUploadFile.FileModifiedDate = new Date(this.LiaisonUploadFile.lastModified)
        setTimeout(() => {
         let newinput = 'Please see attached file - '+this.LiaisonUploadFile.name 
          if(this.input){
            newinput = '. '+newinput
          }
         this.input = this.input+newinput 
         console.log(this.input) 
        }, 300);
        
        
        },
        GetShortLivedURL(item){
        console.log(item)
        const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          console.log(result)
          item.TokenURL = result.data[0]
          setTimeout(() => {
                item.TokenURL = ''
                let url = item.fileurl
                item.fileurl = ''
                item.fileurl = url
          }, 120000);
          let url = item.fileurl
          item.fileurl = ''
          item.fileurl = url
        })
        },
     
    }
}
</script>

<style>

</style>

