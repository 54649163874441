<template>
  <div class="avacircle">
    <img
      class="avaimg"
      :src="
        ComputedMember.Profile_Photo
          ? ComputedMember.Profile_Photo
          : require('@/assets/BlankProfilePic.png')
      "
    />
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          class="transparent"
          width="100%"
          height="100%"
          tile
          flat
          v-on="on"
        >
          <svg
            viewBox="21 30 100 100"
            style="position: absolute"
            xml:space="preserve"
            v-if="ComputedMember.MemberAvatarRibbon"
          >
            <g>
              <path
                opacity="0.9"
                :fill="ComputedMember.MemberAvatarRibbon.Color.hexa"
                class="st1"
                d="M64.37,33.12c-3.7,0.99-7.15,2.43-10.32,4.25c24.69-0.41,47.43,15.92,54.11,40.86
c2.21,8.23,2.42,16.53,0.95,24.38c8.58-10.45,12.18-24.74,8.41-38.8C111.32,40.66,87.52,26.92,64.37,33.12z"
              />
            </g>
            <path
              fill="transparent"
              id="curve"
              class="st4"
              d="M65,36c0,0,22.87,3.33,35,20c16,22,13,36,13,36"
            />
            <text>
              <textPath
                xlink:href="#curve"
                :startOffset="ComputedMember.memavribbonoffset"
              >
                <tspan class="st6 st7" :style="ComputedMember.fontstyle">
                  {{ ComputedMember.memavribbonstring }}
                </tspan>
              </textPath>
            </text>
          </svg>
        </v-card>
      </template>
      <v-card
        v-if="ComputedMember.MemberAvatarRibbon"
        max-width="200"
        v-html="ComputedMember.MemberAvatarRibbon.Description"
        tile
        class="transparent white--text"
      >
      </v-card>
    </v-tooltip>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";

export default {
  props: ["System", "SystemEntities", "member"],
  components: {},
  data() {
    return {};
  },
  computed: {
    ComputedMember() {
      let memobj = Object.assign({}, this.member);
      if (memobj.MemberAvatarRibbon) {
        //'Seeking Opportunity' | 'Want Market Now' || 'Hiring'
        let string = memobj.MemberAvatarRibbon.Name; //13 char
        let offsetperhcar = 2.7;
        let offsetmax = 43;
        let offsetmin = 9;
        let fontsize = 10;
        let letterspacing = 1;
        if (string.length >= 15) {
          //at font size of 8 offset for 1 char is 50, MAX length is 'Seeking Opportunity' legnth of 19 offset is good at 4%
          //thus diff being 46 finally 100/46 4.6
          offsetmax = 50;
          offsetmin = 4;
          offsetperhcar = 4.6;
          fontsize = 8;
          letterspacing = 0;
        } else if (string.length > 11) {
          offsetmax = 45;
          offsetmin = 0;
          offsetperhcar = 4;
          fontsize = 8.8;
        }
        // <!-- MAX char of 13 start 9% MIN char of 1 offset 45
        // min char 1, max char 13, diff of 36 so 100/36 being 2.7 each char adds 2.7%
        //this MAX is really based on what is ideal we actually fit "Length of string " in being 17 char just not great looking-->
        let offsetval =
          offsetmax - string.length * offsetperhcar >= offsetmin
            ? offsetmax - string.length * offsetperhcar
            : offsetmin;
        memobj.memavribbonoffset = offsetval + "%";
        memobj.memavribbonstring = string;
        memobj.fontstyle =
          "fill:#FFFFFF;text-shadow: 2px 2px #00000044;letter-spacing: " +
          letterspacing +
          "px;font-size:" +
          fontsize +
          "px;";
      }

      return memobj;
    },
    UsersArray() {
      return this.$store.state.UsersArray;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
    userIsEagleViewer() {
      return this.$store.state.IsEagleView;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    userBUID() {
      return this.$store.getters.userBUID;
    },
    userBUChildren() {
      return this.$store.getters.userBUChildren;
    },
    userBUParents() {
      return this.$store.getters.userBUParents;
    },
    WindowHeight() {
      return window.innerHeight;
    },
    SocialItemInteractMethod() {
      return this.$store.state.SocialItemInteractMethod;
    },
    SocialItemInteractMethodProp() {
      return this.$store.state.SocialItemInteractMethodProp;
    },
  },
  watch: {
    SocialItemInteractMethod(v) {
      let acceptedmethods = [];
      if (v && acceptedmethods.includes(v)) {
        if (this.SocialItemInteractMethodProp) {
          this[this.SocialItemInteractMethod](
            this.SocialItemInteractMethodProp
          );
          if (this.SocialItemInteractMethod === "ActivateSelectedElement") {
            this.SingleRecordNavMenuItem = "";
          }
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        } else {
          this[this.SocialItemInteractMethod]();
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        }
      }
    },
  },
  created() {},
  methods: {},
};
</script>

<style>
</style>



