<template>
  <div>
    
    <v-list v-if="userIsAdmin">
         User Companies Default config
     <v-list-item>
       
          <v-switch @change="UpdateUserCompanyDefaultsProp('Companies_Account_Limits_by_Admin',System.Companies_Account_Limits_by_Admin)"
          v-model="System.Companies_Account_Limits_by_Admin" 
        label="Company User sets Account Limits"/>
        </v-list-item>
        <v-list-item>
          <v-select label="Loan Entities" clearable @change="UpdateUserCompanyDefaultsProp('CompaniesDefaultLoanEntities',System.CompaniesDefaultLoanEntities)" multiple :items="ActiveLoanOrderEntities"
          item-text="id" v-model="System.CompaniesDefaultLoanEntities" />
        </v-list-item>
        <v-list-item>
          <v-select label="Store Entities" clearable @change="UpdateUserCompanyDefaultsProp('CompaniesDefaultStoreEntities',System.CompaniesDefaultStoreEntities)" multiple :items="ActiveStoreOrderEntities" 
        item-text="id" v-model="System.CompaniesDefaultStoreEntities"/>
        </v-list-item>
    </v-list>
    <!-- <v-list-item>
        <v-btn outlined @click="AcceptInviteUserDialog()">
            Invite User
        </v-btn>
    </v-list-item> -->
    <v-list-item v-if="UserCompanies.length === 0">
        There are no User Companies
    </v-list-item>
       <v-list-item dense v-for="ba in UserCompanies" :key="ba.itemObjKey" >
        <!-- <v-list-item-icon>
        <v-icon small>mdi-blogger</v-icon>
        </v-list-item-icon> -->
        <v-list-item-avatar size="40" class="mx-2">
            <v-img contain v-if="ba.UserProfileIMG" :src="ba.UserProfileIMG"/>
            <v-img contain v-if="!ba.UserProfileIMG" src="@/assets/ImageHolder.png"/>
        </v-list-item-avatar>
        <v-list-item-content>            
            <v-list-item-title :class="ba.class">{{ ba.Company_Name }}</v-list-item-title>            
            <v-list-item-subtitle :class="ba.class">{{ ba.Business_Unit.Name }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="ba.Company" :class="ba.class"><v-chip dark color="blue" x-small>{{ ba.Company.Company_Name }}</v-chip></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
            <v-switch dense v-model="ba.IsGuestUser" label="Guest User" disabled />
            
        </v-list-item-content>
        <v-list-item-action>
            <v-btn x-small @click="CopyInviteToClipBoard('/BillingAccount/'+ba.id)">Copy Invite</v-btn>
        </v-list-item-action>
        <v-list-item-action>
            <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y :disabled="ba.StatusDisabled"
              width="200px"
              >
                <template v-slot:activator="{ on }">
            <v-chip dark v-on="on" :color="ba.StatusColor"><v-icon>{{ba.StatusIcon}}</v-icon>{{ba.Status.Name}}</v-chip>
                </template>
                <v-card width="200px">
                    <v-card-title>
                        Cancel?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn small dark color="success">No</v-btn>
                        <v-spacer>
                        </v-spacer>
                        <v-btn small @click="UpdateCancelBA(ba)" dark color="error">Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-list-item-action>
            <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                {{ba.monthcreated}}
                    <br>
                {{ba.daycreated}}
        </v-list-item-avatar>                    
        <!-- <v-list-item-content>
            {{ba.pubDate}} 
        </v-list-item-content> -->
    </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            UserCompanies: []
        }
    },
    computed:{
        ActiveLoanOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Cash Loan'
        })
      },
      ActiveStoreOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Store Order'
        })
      },
        UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        userBU () {
        return this.$store.state.buobj
        },
        InvitebyCompany(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties && this.ActiveMemberGroup.AdditionalProperties.CompanyInviteOnly && this.userLoggedIn.Company
      },
      userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        UCColRef(){
            let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('usercompanies')
            if(this.InvitebyCompany){
                ref = ref.where('Companyid','==',this.userLoggedIn.Companyid)
            }
            else if(!this.userIsAdmin){
                ref = ref.where('Userid','==',this.userLoggedIn.id)
            }
            return ref
        },
        ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        
    },
    created(){
        this.GetUserCompanies()
    },
    methods:{
        UpdateUserCompanyDefaultsProp(prop,value){
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
          [prop] : value
        })
      },
        AcceptInviteUserDialog(){
            this.$store.commit('SetSocialItemInteractMethod','AcceptInviteUserDialog')
        },
        CopyInviteToClipBoard(text){
            let path = ''            
            if(window.location.port.length > 0){
                
                path = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+text
            }
            else{
                path = window.location.protocol+'//'+window.location.hostname+text
            }
            navigator.clipboard.writeText(path).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
            console.error('Async: Could not copy text: ', err);
            });
        },
        UpdateCancelBA(ba){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(ba.id).update({
                    Status: {ID: 1000004,Name: 'Cancelled'}
                }) 
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetUserCompanies(){
            this.UCColRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    this.UserCompanies.push({
                    ...change.doc.data(),
                    id: change.doc.id
                    })
                }
                if (change.type === 'modified') {
                    let invobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.UserCompanies.find(obj => obj.id === invobj.id)
                    if(oncheck){
                        let index = this.UserCompanies.indexOf(oncheck)
                       this.UserCompanies.splice(index,1,invobj)
                    }
                }
                if(change.type === 'removed'){
                    let invobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.UserCompanies.find(obj => obj.id === invobj.id)
                    if(oncheck){
                        let index = this.UserCompanies.indexOf(oncheck)
                        this.UserCompanies.splice(index,1)
                    }
                }
                
                //console.log('this.UserCompanies',this.UserCompanies)
                })
            })
        },
    }
}
</script>

<style>

</style>
