
              <template>
  <v-card color="rgba(255,255,255,0.01)" style="margin-top:70px;">
    <v-dialog v-model="ReadingTaskDialog" width="400" v-if="ActivityData">
          <!-- <v-expand-transition> -->
            <v-card tile
               width="400"
                class="transition-fast-in-fast-out"
                style="height: 100%;bottom: 0;position: absolute;right: 0;"
            >
             <v-list-item>
              <v-list-item-action>
                <v-icon class="soloactionicon" color="red" dark @click="CancelReadingTaskDialog()">mdi-close</v-icon>
              </v-list-item-action>
              <v-list-item-content>
              <v-list-item-title>
                <b >{{ActivityData.Subject}}</b>
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
               Due Date - {{StringDueDate}}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="caption overline red--text" style="font-size:10px;" v-if="ActivityData.overdue">
               Overdue!!!
              </v-list-item-subtitle>
               <v-list-item-subtitle style="padding:5px;" v-if="ActivityData.QuickLink">
                  <v-btn  v-if="ActivityData.QuickLink" target="_blank" :href="ActivityData.QuickLink" small>View Link</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
             <v-card-actions>
                 
                    <TopicalLiaisonComponent :itemsprop="'Comments'" :TimelineRecord="ActivityData"
                    @SaveNewcomms="PostComment" :ScrollHeight="WindowHeight*0.65"
                    :ActiveSession="ActiveSession"
                />
                </v-card-actions>
            </v-card>
    </v-dialog>
  <div v-if="!UserhasNoAccess">
  <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          dark
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>This is the Activity help sheet. What would you want to know?</div>
      </v-sheet>
    </v-bottom-sheet>
  <v-banner two-line :sticky="true" class="topbasicoutlined" v-if="!EditingActivity" height="80px">
  <v-container fluid>
     <v-card width ="100%" flat>
           <v-btn @click="ToggleHelp()" color="red" dark
                fab
                small
                absolute
                top
                right><v-icon>mdi-help</v-icon></v-btn>
                <v-btn @click="EditActivity()" color="#012840" dark
                fab
                small
                absolute
                bottom
                right><v-icon>mdi-pencil</v-icon></v-btn>
                <v-layout row class="justify-start">
        <v-col>
          <strong>Owner: </strong><a :href="'/User/'+Owner.id" target="_blank">{{ Owner.Full_Name }}</a>
        </v-col>
        <v-col v-if="DueDate">
          <strong>Due Date: </strong> {{DueDate.toDate() }}
        </v-col>

      </v-layout>
          <v-layout row class="primary justify-center">
          
                          <v-badge color="secondary" overlap
                           dark>
                          <v-icon @click="ReadingTaskDialog = !ReadingTaskDialog" large dark absolute bottom left>mdi-message-reply-text</v-icon>
                          <span slot="badge"> {{Comments.length}} </span>
                            </v-badge>
                    

          <h2 class="white--text">Activity - {{ Name  }}</h2>
           </v-layout>
      </v-card>
    </v-container>
  </v-banner>
  <v-card height="70px">
   <v-layout row class="justify-center" v-if="ActivityType === 'Task' || ActivityType === 'Appointment'"><br><br>
      <span v-if="ActivityStatus==='Open'"><v-btn fab><v-icon v-if="ActivityStatus==='Open'" color="success" @click="ChangeActivityStatus()">mdi-check-bold</v-icon></v-btn><v-btn fab><v-icon color="red" @click="ChangeActivityStatustoCancelled()">mdi-cancel</v-icon></v-btn></span>
      <span  @click="ChangeActivityStatus()" v-if="ActivityStatus==='Completed' || ActivityStatus==='Cancelled'"><v-btn fab><v-icon color="warning">mdi-progress-clock</v-icon>ReOpen</v-btn></span>
    </v-layout>
    </v-card>

  <v-banner two-line :sticky="true" class="topbasicoutlined" v-if="EditingActivity" height="80px">
   <v-container fluid>
   <v-card width ="100%" flat>
   <v-layout row class="primary white--text justify-center">
      <h2 class="white--text">Activity- {{ Name }}</h2>
    </v-layout>
     <v-btn @click="CancelActivityEditing()" color="red" dark
                fab
                small
                top
                left><v-icon>mdi-cancel</v-icon></v-btn>
                <v-btn @click="UpdateActivity()" color="#012840" dark
                fab
                small
                absolute
                bottom
                right><v-icon>mdi-content-save</v-icon></v-btn>
                <v-layout row class="justify-start">
        <v-col>
           <strong>Owner:</strong>
          </v-col>
          <v-col>
            <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" v-model="Owner" return-object></v-autocomplete>
        </v-col>
      </v-layout>
      </v-card>
    </v-container>
  </v-banner>

   <v-layout class="justify-center">
     <v-flex lg8 md10>

       <v-dialog v-model="LookupWindowDialog" max-width="1200px">
          <v-card>
            <v-card-title>
              <span class="headline">Lookup Record</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">

                      <v-select
                        v-model="RegardingType"
                        :items="DataSets"
                        hide-selected
                        label="Record Type"
                        @change="ChangeRegardingTypeArray()"
                      ></v-select>
                      <v-data-table
                        :headers="RegardingHeaders"
                        :items="RegardingTypeArraySearched"
                        :items-per-page="5"
                        class="elevation-1"
                      >
                      <template v-slot:top>
                        <v-toolbar flat color="white">
                          <v-toolbar-title v-if="RegardingType === 'BusinessUnit'">BusinessUnits</v-toolbar-title>
                          <v-toolbar-title v-if="RegardingType === 'User'">Users</v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-spacer></v-spacer>
                          <v-text-field light
                            v-model="regardingsearch"
                            append-icon="mdi-file-find-outline"
                            label="Search"
                            single-line
                            hide-details
                          ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="selectRegarding(item)"
                      >
                        mdi-briefcase-check
                      </v-icon>
                    </template>
                  </v-data-table>

                  </v-col>
                </v-row>
              </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseLookupWindow()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="UpdateActivity()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        

           <v-layout row v-if="!EditingActivity" class="outline fieldcard">
        <v-col>
          <v-card outlined  height="100%">
              <v-card flat><span v-if="ActivityType !== 'Email'">
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Name</h3><v-spacer></v-spacer><h4>{{Name}}</h4>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Activity Type</h3><v-spacer></v-spacer><p>{{ActivityType}}</p>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row v-if="CreatedOn">
                    <h3 class="primary--text">Created On</h3><v-spacer></v-spacer><p>{{CreatedOn.toDate()}}</p>
                  </v-layout>
                  </v-card-subtitle></span><span v-if="ActivityType === 'Email'">
                  <v-expansion-panels>
                  <v-expansion-panel>
                      <v-expansion-panel-header>Header Info</v-expansion-panel-header>
                      <v-expansion-panel-content>
                          <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Name</h3><v-spacer></v-spacer><h4>{{Name}}</h4>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Activity Type</h3><v-spacer></v-spacer><p>{{ActivityType}}</p>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row v-if="CreatedOn">
                    <h3 class="primary--text">Created On</h3><v-spacer></v-spacer><p>{{CreatedOn.toDate()}}</p>
                  </v-layout>
                  </v-card-subtitle></v-expansion-panel-content>
                  </v-expansion-panel>
                  </v-expansion-panels>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Subject</h3><v-spacer></v-spacer><p>{{Subject}}</p>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">To</h3><v-spacer></v-spacer><p>{{To}}</p>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                      <p class=MsoNormal style='margin-left:120.0pt;text-indent:-120.0pt;tab-stops:
                        120.0pt;mso-layout-grid-align:none;text-autospace:none'><b><span
                        style='color:black'>From:<span style='mso-tab-count:1'>                                         </span></span></b><span
                        style='color:black'>Firebase {{firebasenoreply}};<p></p></span></p>
                    <h3 class="primary--text">From</h3><v-spacer></v-spacer><p>{{From}}</p>
                  </v-layout>
                  <v-layout row>
                      <p class=MsoNormal style='margin-left:120.0pt;text-indent:-120.0pt;tab-stops:
                        120.0pt;mso-layout-grid-align:none;text-autospace:none'><b><span
                        style='color:black'>Sent:<span style='mso-tab-count:1'>                                         </span></span></b><span
                        style='color:black'>Firebase {{firebasenoreply}};<p></p></span></p>
                    <h3 class="primary--text">Sent</h3><v-spacer></v-spacer><p>{{From}}</p>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card flat>
                      Message<br>
                      <div v-html="legacySystemHTML">
                        </div>
                  </v-card></span><span v-if="ActivityType !== 'Email'">
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Description</h3><v-spacer></v-spacer><h4>{{Description}}</h4>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row v-if="DueDate">
                    <h3 class="primary--text">Due Date</h3><v-spacer></v-spacer><p>{{DueDate.toDate()}}</p>
                  </v-layout>
                  </v-card-subtitle></span>
                  <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Regarding</h3><v-spacer></v-spacer><h4><a :href="RegardingRecordLink+RegardingRecord.id" target="_blank">{{RegardingRecord[RegardingTypePrimaryField]}}</a></h4>
                  </v-layout>
                  </v-card-subtitle>
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
        <!-- <h3 class="mx-10">Tab 2</h3>
            <v-tabs centered>
              <v-tab>Section 1</v-tab>
            <v-tab-item>
            <v-card flat min-height="400px">
            <v-layout class="justify-end"><input
                          style="display: none"
                          type="file"
                          @change="AddSection1($event)"
                          ref="Section1fileInputer"
                          :multiple="true"
                          accept="image/*"
                          ><v-icon @click="$refs.Section1fileInputer.click()">mdi-plus-thick</v-icon></v-layout><v-spacer></v-spacer>

              <v-layout row class="align-center">
                 <v-card class="mx-3 my-3" v-for="card in Section1ImageUploads" :key="card.index"
                    max-width="150" flat>
                    <v-img :src="card.path" max-height="150px" contain>
                      <v-btn :href="card.path" target="_new">View</v-btn>
                      </v-img>
                </v-card>
                </v-layout>

             </v-card>
            </v-tab-item>
              <v-tab>Section 2</v-tab>
            <v-tab-item>
            <v-card flat min-height="400px">
                            <v-layout row class="align-center">
               <v-list light align="left" width="100%"  color="#f9ffb9">
                    <v-subheader inset>{{SelectedFolder.path}}<v-layout class="justify-end"><v-icon @click="AddFolder()">mdi-folder-plus</v-icon></v-layout></v-subheader>

                      <v-btn @click="ReassignSelectedFolder()" v-if="SelectedFolder.path !== DefaultRoot"><v-icon>mdi-arrow-up-thick</v-icon>Back</v-btn>
                      <v-btn @click="SaveUploadFolders()">Save Folders</v-btn>

                      <v-subheader inset>Folders</v-subheader>
                      <v-list-item v-for="(folder,i) in RelatedFolders" :key="folder.itemObjKey">
                        <v-list-item-avatar>
                          <v-icon  @click="ActivateSelectedFolder(folder,i)"
                            :class="[folder.iconClass]"
                             v-text="folder.icon"
                          ></v-icon>
                        </v-list-item-avatar>
                        <v-layout row class="justify-start">

                          <v-list-item-content>
                              <v-list-item-title><v-text-field v-model="folder.title"></v-text-field></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title>{{folder.subtitle}}</v-list-item-title>
                            </v-list-item-content>

                          </v-layout>

                        </v-list-item>
                        <v-divider inset></v-divider>
                        <v-layout class="justify-end" v-if="SelectedFolder.path !== DefaultRoot"><input
                          style="display: none"
                          type="file"
                           @change="AddFiles($event)"
                          ref="FolderfileInputer"
                           :multiple="true"
                          ><v-icon @click="$refs.FolderfileInputer.click()">mdi-plus-thick</v-icon></v-layout>

                        <v-card flat min-height="400px">

                          <v-list-item
                            v-for="item in FolderFiles"
                             :key="item.itemObjKey"

                          >

                            <v-list-item-content>
                              <v-list-item-title>{{item.title}}</v-list-item-title>
                              <v-list-item-subtitle>{{item.subtitle}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-content>
                              <v-list-item-title>{{item.type}}</v-list-item-title>
                              <v-list-item-subtitle><v-icon
                                 :class="[item.iconClass]"
                                 v-text="item.icon"
                              ></v-icon></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon :href="item.path" target="_new">
                                <v-icon color="grey lighten-1">mdi-eye</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-card>
                    </v-list>
              </v-layout>

             </v-card>
            </v-tab-item>
           </v-tabs> -->

<v-layout row v-if="EditingActivity" class="outline fieldcard">
          <h3 class="primary--text">Tab 1</h3><br><br>
          <v-row>
        <v-col><br>
          <v-card flat>
              <v-card flat>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Name</h4><v-text-field v-model="Name"></v-text-field>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Activity Type</h4><v-combobox
                        v-model="ActivityType"


                        :items="ActivityTypeOptions"

                        item-text=""
                        hide-selected
                        label="Activity Type"
                        small-chips
                        solo
                        flat
                      ></v-combobox>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Created On</h4><v-text-field v-model="CreatedOn"></v-text-field>
                        </v-row>
                    </v-col>
                  <v-col>
                  </v-col>
                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  </v-card-subtitle><span v-if="ActivityType === 'Email'">
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Subject</h4><v-text-field v-model="Subject"></v-text-field>
                        </v-row>
                    </v-col>

                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>To</h4><v-text-field v-model="To"></v-text-field>
                        </v-row>
                    </v-col>

                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>From</h4><v-text-field v-model="From"></v-text-field>
                        </v-row>
                    </v-col>

                  </v-layout>
                  </v-card-subtitle></span>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Description</h4><v-textarea v-model="Description"></v-textarea>
                        </v-row>
                    </v-col>

                  </v-layout>
                  </v-card-subtitle>
                <v-card-subtitle>
                  <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Due Date</h4><v-text-field v-model="DueDate"></v-text-field>
                        </v-row>
                    </v-col>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle>
                    <v-layout row>
                    <v-col>
                      <v-row>
                  <h4>Regarding Type</h4><v-combobox
                        v-model="RegardingType"
                        :items="RegardingTypeOptions"
                        item-text=""
                        hide-selected
                        label="Regarding Type"
                        small-chips
                        solo
                        flat
                        @change="ChangeRegardingTypeArray()"
                      ></v-combobox>
                        </v-row>
                    </v-col>
                  </v-layout>
                  </v-card-subtitle>
                  <v-card-subtitle>
                  <v-layout row>

                  <h4>Regarding</h4><v-combobox
                        v-model="RegardingRecord"
                        :items="RegardingTypeArray"
                        :item-text="RegardingTypePrimaryField"
                        hide-selected
                        label="Regarding Record"
                        small-chips
                        solo
                        flat
                        @change="AssignRegardingRecord()"
                      ></v-combobox><v-icon @click="LookupWindow()">mdi-file-find-outline</v-icon>

                  </v-layout>
                  </v-card-subtitle>

                </v-card>
            </v-card>
          </v-col>
          </v-row>
          </v-layout>
      <v-tabs row>
      </v-tabs>
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  <v-btn fab absolute bottom left @click="DetermineFolloworUnFollow()">
    <v-icon v-if="UserisFollowing.length>0" color="red">mdi-star-circle</v-icon>
    <v-icon v-if="UserisFollowing.length===0" color="#eebb33">mdi-star-circle-outline</v-icon>
  </v-btn>
  </div>
  <div v-if="UserhasNoAccess">
      <v-img alt="Access" src="@/assets/accessdeniedBG.png">
      <v-container class="container">
              <v-layout class="justify-center">
              <img alt="Access" src="@/assets/Broken-Yolk.png">
              </v-layout>
          <v-layout class="justify-center">
              <v-card flat color="rgb(255, 255, 255, 0.05)">
              <h1><v-icon x-large color="red">mdi-link-off</v-icon>You do not have the permissions to view this page</h1>
              <v-layout class="justify-center">
              <h1><span>{{System.Name.substr(0,System.Name.indexOf(' '))}}</span><span v-if="System.Name.substr(System.Name.indexOf(' ')+1)" class="font-weight-light"> {{System.Name.substr(System.Name.indexOf(' ')+1)}}</span></h1>
              </v-layout>
              <v-layout class="justify-center">
              <p>To gain access, speak to your System Adminisrator</p>
              </v-layout><br><br>
              <v-layout class="justify-center">
              <p>Powered by</p><h4><span> Rapid</span><span class="font-weight-light">Apps©</span></h4>
              </v-layout>

              </v-card>
          </v-layout>
      </v-container>
      </v-img>

  </div>
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import TopicalLiaisonComponent from '@/components/General/TopicalLiaisonComponent'
export default {
  props: ['System','SystemEntities'],
components: {
  TopicalLiaisonComponent
},
  data() {
    return {
      ReadingTaskDialog: false,
      ActiveSession: {title: 'Activities',topicid: 'Activities',LiaisonProp: 'InternalLiaison',SingleName: 'Activity',collection: 'activities',
        DigitizedForms: [],DocumentRegisters: [],Galleries: [],RecordPrimaryField: {Name: 'Subject'}},
      Name:  '',
      Subject:  '',
      To:  '',
      From:  '',
      Description:  '',
      ActivityType:  '',
      UserhasNoAccess: false,
      ActivityStatus: '',
      Comments: [],
      NewComment: '',
      RegardingType:  '',
      RegardingRecord: '',
      RegardingRecordLink: '',
      RegardingTypePrimaryField: '',
      RegardingTypeArray: [],
      regardingsearch: '',
      RegardingHeaders: [],
      CreatedOn:  '',
      DueDate:  '',
      Owner: {id:'',Name: 'Name',Surname: 'Surname', Full_Name: 'Name Surname'},
      UsersArray:[],
      firebasenoreply: '<firebase-noreply@google.com>',
      legacySystemHTML: `
        <FONT color="#484848" size="4">
          <MARQUEE>Please enter your name to continue.</MARQUEE>
          ...
          <html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=unicode">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List
href="Firebase%20Your%20Cloud%20Firestore%20database%20has%20insecure%20rules_files/filelist.xml">
<link rel=Edit-Time-Data
href="Firebase%20Your%20Cloud%20Firestore%20database%20has%20insecure%20rules_files/editdata.mso">
<!--[if !mso]>
<style>
v\:* {behavior:url(#default#VML);}
o\:* {behavior:url(#default#VML);}
w\:* {behavior:url(#default#VML);}
.shape {behavior:url(#default#VML);}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:OfficeDocumentSettings>
  <o:AllowPNG/>
 </o:OfficeDocumentSettings>
</xml><![endif]-->
<link rel=themeData
href="Firebase%20Your%20Cloud%20Firestore%20database%20has%20insecure%20rules_files/themedata.thmx">
<link rel=colorSchemeMapping
href="Firebase%20Your%20Cloud%20Firestore%20database%20has%20insecure%20rules_files/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:Zoom>0</w:Zoom>
  <w:DocumentKind>DocumentEmail</w:DocumentKind>
  <w:TrackMoves/>
  <w:TrackFormatting/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-ZA</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:DoNotExpandShiftReturn/>
   <w:BreakWrappedTables/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
  </w:Compatibility>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="--"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face






@font-face






@font-face






 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal










a:link, span.MsoHyperlink





a:visited, span.MsoHyperlinkFollowed





p.msonormal0, li.msonormal0, div.msonormal0











.MsoChpDefault












@page WordSection1





div.WordSection1

 /* List Definitions */
 @list l0


@list l0:level1







@list l0:level2








@list l0:level3







@list l0:level4







@list l0:level5







@list l0:level6







@list l0:level7







@list l0:level8







@list l0:level9







ol

ul

-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable



















</style>
<![endif]-->
</head>

<body lang=EN-ZA link=blue vlink=purple style='tab-interval:36.0pt'>

<div class=WordSection1>

<p class=MsoNormal style='margin-left:120.0pt;text-indent:-120.0pt;tab-stops:
120.0pt;mso-layout-grid-align:none;text-autospace:none'><b><span
style='color:black'>From:<span style='mso-tab-count:1'>                                         </span></span></b><span
style='color:black'>Firebase <firebase-noreply@google.com><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:120.0pt;text-indent:-120.0pt;tab-stops:
120.0pt;mso-layout-grid-align:none;text-autospace:none'><b><span
style='color:black'>Sent:<span style='mso-tab-count:1'>                                           </span></span></b><span
style='color:black'>Saturday, 07 December 2019 22:35<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:120.0pt;text-indent:-120.0pt;tab-stops:
120.0pt;mso-layout-grid-align:none;text-autospace:none'><b><span
style='color:black'>To:<span style='mso-tab-count:1'>                                               </span></span></b><span
style='color:black'>disciplenet@gmail.com<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:120.0pt;text-indent:-120.0pt;tab-stops:
120.0pt;mso-layout-grid-align:none;text-autospace:none'><b><span
style='color:black'>Subject:<span style='mso-tab-count:1'>                                     </span></span></b><span
style='color:black'>[Firebase] Your Cloud Firestore database has insecure rules<o:p></o:p></span></p>

<p class=MsoNormal><o:p> </o:p></p>

<div style='border:solid #E0E0E0 1.0pt;mso-border-alt:solid #E0E0E0 .75pt;
padding:0cm 0cm 0cm 0cm'>

<div>

<p class=MsoNormal style='background:white'><span style='font-family:"Arial",sans-serif;
color:black;mso-color-alt:windowtext'><img id="_x0000_i1025"
src="https://www.gstatic.com/mobilesdk/190425_mobilesdk/firebase_lockup.png"
style='max-height:40px;min-height:40px;display:block;height:40px;margin-bottom:
auto;margin-left:auto;margin-right:auto;margin-top:auto'></span><span
style='font-family:"Arial",sans-serif'><o:p></o:p></span></p>

</div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%"
 style='width:100.0%;mso-cellspacing:0cm;background:#039BE5;mso-yfti-tbllook:
 1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:18.0pt 18.0pt 18.0pt 18.0pt'>
  <div>
  <p class=MsoNormal style='line-height:12.0pt'><span style='font-size:10.0pt;
  color:#B3E1F7'>Templatizer<o:p></o:p></span></p>
  </div>
  <div>
  <p class=MsoNormal style='line-height:18.0pt'><span style='font-size:15.0pt;
  color:white'>[Firebase] Your Cloud Firestore database has insecure rules<o:p></o:p></span></p>
  </div>
  </td>
  <td style='padding:18.0pt 18.0pt 18.0pt 18.0pt'>
  <p class=MsoNormal align=right style='text-align:right'><span
  style='color:black;mso-color-alt:windowtext'><img id="_x0000_i1026"
  src="https://www.gstatic.com/mobilesdk/180621_mobilesdk/firebase_database_white_24@2x.png"
  style='height:24px;width:24px'></span></p>
  </td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td style='padding:0cm 0cm 0cm 0cm'></td>
  <td style='padding:0cm 0cm 0cm 0cm'></td>
 </tr>
</table>

<div style='margin-bottom:18.0pt'>

<div style='margin-bottom:18.0pt'>

<div>

<p class=MsoNormal style='background:#FFF3E0'><span style='font-family:"Arial",sans-serif;
display:none;mso-hide:all'><o:p> </o:p></span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%"
 style='width:100.0%;mso-cellspacing:0cm;mso-yfti-tbllook:1184;mso-padding-alt:
 0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td width=40 valign=top style='width:30.0pt;padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal><img id="_x0000_i1027"
  src="https://www.gstatic.com/mobilesdk/171101_mobilesdk/images/caution_orange_24dp_@2x.png"
  style='height:24px;margin-right:16px;vertical-align:middle;width:24px'></p>
  </td>
  <td style='padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='line-height:15.0pt'><span style='font-size:10.5pt;
  color:#E65100'>We've detected the following issue(s) with your security
  rules:<o:p></o:p></span></p>
  <ul type=disc>
   <li class=MsoNormal style='color:#E65100;mso-margin-top-alt:auto;mso-margin-bottom-alt:
       auto;line-height:15.0pt;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
       style='font-size:10.5pt'>any user can read your entire database<o:p></o:p></span></li>
   <li class=MsoNormal style='color:#E65100;mso-margin-top-alt:auto;mso-margin-bottom-alt:
       auto;line-height:15.0pt;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
       style='font-size:10.5pt'>any user can write to your entire database<o:p></o:p></span></li>
  </ul>
  </td>
 </tr>
</table>

</div>

</div>

<div>

<p class=MsoNormal style='line-height:15.0pt;background:white'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212121'>Because
your project does not have strong security rules, anyone can access your entire
database. Attackers can steal, modify, or delete your data, and they can drive
up your bill.<o:p></o:p></span></p>

<div>

<p class=MsoNormal style='line-height:15.0pt;background:white'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212121'>Analysis
is run daily. If you've modified your rules in the last 24 hours that may not
be accounted for.<o:p></o:p></span></p>

</div>

</div>

</div>

<div style='margin-bottom:18.0pt'>

<div style='margin-bottom:12.0pt'>

<div>

<p class=MsoNormal style='line-height:12.0pt;background:white'><span
style='font-size:9.0pt;font-family:"Arial",sans-serif;color:#757575;text-transform:
uppercase'>Insecure Rules<o:p></o:p></span></p>

</div>

</div>

<div style='margin-bottom:12.0pt'>

<p class=MsoNormal style='line-height:18.0pt;background:white'><span
style='font-size:15.0pt;font-family:"Arial",sans-serif;color:#212121'>Templatizer<o:p></o:p></span></p>

</div>

<div style='margin-bottom:12.0pt'>

<div style='margin-bottom:12.0pt'>

<div>

<p class=MsoNormal style='line-height:15.0pt;background:white'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#757575'>Database<o:p></o:p></span></p>

</div>

<div>

<p class=MsoNormal style='line-height:15.0pt;background:white'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212121'>(default)<o:p></o:p></span></p>

</div>

</div>

</div>

<div style='margin-bottom:12.0pt'>

<p class=MsoNormal align=right style='text-align:right;line-height:12.0pt;
background:white'><span style='font-family:"Arial",sans-serif;color:black;
mso-color-alt:windowtext'><a
href="https://firebase.google.com/docs/firestore/security/insecure-rules"
style='display:inline-block;border-radius:8px'><span style='font-size:10.5pt;
color:#039BE5;letter-spacing:.2pt;border:solid #E0E0E0 1.0pt;mso-border-alt:
solid #E0E0E0 .75pt;padding:7.0pt;text-decoration:none;text-underline:none'>Learn
more</span></a><a
href="https://console.firebase.google.com/project/templatizer-9620d/database/firestore/rules"
style='display:inline-block;border-radius:8px'><span style='font-size:10.5pt;
color:white;letter-spacing:.2pt;background:#039BE5;text-decoration:none;
text-underline:none'>Edit rules</span></a></span><span style='font-family:"Arial",sans-serif'><o:p></o:p></span></p>

</div>

</div>

<div style='margin-bottom:18.0pt'>

<div>

<div>

<p class=MsoNormal style='line-height:15.0pt;background:white'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212121'>If you
have questions or feel you've received this message in error, please contact <a
href="https://firebase.google.com/support/"><span style='color:#039BE5;
text-decoration:none;text-underline:none'>Firebase support</span></a><o:p></o:p></span></p>

</div>

<div style='margin-top:18.0pt'>

<p class=MsoNormal style='line-height:15.0pt;background:white'><span
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212121'>Thanks
for using Firebase!<o:p></o:p></span></p>

</div>

</div>

</div>

<div>

<p class=MsoNormal align=center style='text-align:center;line-height:12.0pt;
background:#ECEFF1'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
color:#757575'>This email is notifying you about important service information
regarding your Firebase project<o:p></o:p></span></p>

<div style='margin-top:15.0pt'>

<p class=MsoNormal align=center style='text-align:center;line-height:12.0pt;
background:#ECEFF1'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
color:#757575'>Manage your <a
href="https://console.firebase.google.com/subscriptions/project/templatizer-9620d"><span
style='color:#039BE5;text-decoration:none;text-underline:none'>Alert settings</span></a><o:p></o:p></span></p>

</div>

</div>

<div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%"
 style='width:100.0%;mso-cellspacing:0cm;mso-yfti-tbllook:1184;mso-padding-alt:
 0cm 0cm 0cm 0cm'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
  <td style='padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal><img border=0 id="_x0000_i1028"
  src="https://www.gstatic.com/images/branding/googlelogo/2x/googlelogo_light_color_74x24dp.png"
  style='max-height:24px;min-height:24px;height:24px'></p>
  </td>
  <td style='padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal align=right style='text-align:right;line-height:10.5pt'><span
  style='font-size:7.5pt'>Google LLC<br>
  1600 Amphitheatre Pkwy<br>
  Mountain View, CA, 94043 USA<o:p></o:p></span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal><o:p> </o:p></p>

</div>

</div>

</div>

</body>

</html>

        </FONT>
      `,
      CreatedOnmenu: false,
      DueDatemenu: false,
      FollowingUsers: [],
      ActivityTypeOptions: ['Email','Phone Call','Task','Appointment'],
      ActivityTypefilter: '',
      RegardingTypeOptions: [
  "BusinessUnit",
  "User"
],
      RegardingTypefilter: '',
      Section1ImageUploads: [],
      EditingActivity: false,
      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      DefaultFolder: {title: '',path: 'Activities/'+this.$route.params.id+'/'},
      SelectedFolder: {title: '',path: 'Activities/'+this.$route.params.id+'/'},
      RelationshipType: null,
      DefaultRoot: 'Activities/'+this.$route.params.id+'/',
      UploadFolders:[],
      FolderFiles:[],
      ActivityData: '',
      headers: [
      
        { text: 'Name', value: 'name'},


        { text: 'Description', value: 'description'},


        { text: 'Activity Type', value: 'activitytype'},


        { text: 'Regarding Type', value: 'regardingtype'},


        { text: 'Created On', value: 'createdon'},


        { text: 'Due Date', value: 'duedate'},


        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedActivitysnackbar: false,
      ActivityEditdialog: false,
      newActivitydialog: false,
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: [],
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      helpsheet: false,
      LookupWindowDialog:false,
      DataSets: [
  "BusinessUnit",
  "User"
],
    }
  },

  created(){
    this.$emit('IntranetViewToggle',false)
    this.GetRequestingUser()
    this.GetUsers()
    this.GetActivity()
    window.addEventListener('scroll', this.handleScroll)

  },
  computed: {
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBUID () {
      return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    userBU () {
    return this.$store.state.buobj
    },
    StringDueDate(){
      return this.TimestampFormatterSTRINGDayText(this.ActivityData.Due_Date.toDate())
    },
    WindowHeight(){
          return window.innerHeight
      },
    RegardingTypeArraySearched(){
      return this.RegardingTypeArray.filter(record => {
        if(this.RegardingType === 'BusinessUnit'){
        return record.Name.toLowerCase().includes(this.regardingsearch.toLowerCase())
        }
        if(this.RegardingType === 'User'){
        return record.Full_Name.toLowerCase().includes(this.regardingsearch.toLowerCase())
        }
      })
    },
    UserisFollowing(){
      return this.FollowingUsers.filter(user => {
        return user.id === this.UserRecord.id
      })
    },
    FollowingUsersExcludingCurrent(){
      return this.FollowingUsers.filter(user => {
        return user.id !== this.UserRecord.id
      })
    },
    

    },
  methods: {
    TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
    CancelReadingTaskDialog(){          
          this.ReadingTaskDialog = false
          this.dragging = ''
          this.draggingstatus = ''
      },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.UserRoles = userdetails.rolesarrayQuery
              vm.UserBusinessUnitID = userdetails.Business_Unitid
              console.log(vm.UserBusinessUnitID)
              if(typeof vm.UserBusinessUnitID !== 'undefined'){
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             //console.log(vm.UserRoles)

              vm.RegardingTypePush()
            })
              }
            })
          }
      else {
          }
          })
      },

    LookupWindow(){
      this.LookupWindowDialog = true
    },

    AssignRegardingRecord(item){
      if(this.RegardingType === 'BusinessUnit'){
        this.RegardingRecord = {Name: this.RegardingRecord.Name, Link: '/BusinessUnit/'+this.RegardingRecord.id, Name: this.RegardingRecord.Name, id:this.RegardingRecord.id}
        }
      if(this.RegardingType === 'User'){
        this.RegardingRecord = {Name: this.RegardingRecord.FullName, Link: '/User/'+this.RegardingRecord.id, FullName: this.RegardingRecord.FullName, id:this.RegardingRecord.id}
        }

    },

    ChangeRegardingTypeArray(){
      this.RegardingTypeArray = []
      this.RegardingRecord = ''
      this.RegardingTypePush()
    },

    RegardingTypePush(){
      let vm = this
      setTimeout(() => {
      if(typeof this.ActivityData === 'undefined'){
            console.log('user has no access')
            this.UserhasNoAccess = true
            }
        }, 500);
        if(this.RegardingType === 'BusinessUnit'){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.RegardingTypeArray.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
          })
        })

        this.RegardingTypePrimaryField = 'name'
        this.RegardingRecordLink = '/BusinessUnit/'
        this.RegardingHeaders = [
          {
            "align": "left",
            "sortable": false,
            "text": "id",
            "value": "id"
          },
          {
            "sortable": false,
            "text": "Actions",
            "value": "action"
          }
          ]
          }
        if(this.RegardingType === 'User'){
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.RegardingTypeArray.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
          })
        })

        this.RegardingTypePrimaryField = 'fullname'
        this.RegardingRecordLink = '/User/'
        this.RegardingHeaders = [
          {
            "align": "left",
            "sortable": false,
            "text": "id",
            "value": "id"
          },
          {
            "sortable": false,
            "text": "Actions",
            "value": "action"
          }
          ]
          }
    },

    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },

    ResetFilters() {
      this.ActivityTypefilter = ''
    },

    ResetSearch() {
      this.search = ''
    },
    CloseLookupWindow(){
      this.LookupWindowDialog = false
    },

    CancelActivityEditing(){
      this.EditingActivity = false
    },
    EditActivity() {
      this.EditingActivity = true
    },

    ChangeActivityStatus(){
      if(this.ActivityStatus === 'Open'){
        this.ActivityStatus = 'Completed'
        this.SaveActivityStatus()
      }
      else if(this.ActivityStatus === 'Completed'){
        this.ActivityStatus = 'Open'
        this.SaveActivityStatus()
      }
      else if(this.ActivityStatus === 'Cancelled'){
        this.ActivityStatus = 'Open'
        this.SaveActivityStatus()
      }
    },

    ChangeActivityStatustoCancelled(){
      if(this.ActivityStatus === 'Open'){
        this.ActivityStatus = 'Cancelled'
        this.SaveActivityStatus()
      }
    },

    SaveActivityStatus(){
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(this.$route.params.id).update({
        status: this.ActivityStatus
      })

    },

    PostComment(NewComment,item) {
      if(typeof item.Comments === 'undefined'){
        item.Comments = []
      }
      item.Comments.push(NewComment)
      this.SaveCommentsandReplies(item)
    },
    DetermineFolloworUnFollow(){
      if(this.UserisFollowing.length === 0){
        this.FollowRecord()
      }
      else{
        this.UnFollowRecordConfirm()
      }
    },

    FollowRecord(){

      let FollowingUser = {
        id: this.UserRecord.id,
        name: this.UserRecord.name,
        surname: this.UserRecord.surname,
        fullname: this.UserRecord.fullname,
      }
      console.log('here is the user attempting to follow')
      console.log(FollowingUser)
      this.FollowingUsers.push(FollowingUser)
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(this.$route.params.id).update({
        FollowingUsers: this.FollowingUsers
      })

    },
    UnFollowRecordConfirm(){
      confirm('Are you sure, you want to unfollow? This means you will not get any notificaiton on changes, comments and replies to this record') && this.UnFollowRecord()
    },

    UnFollowRecord(){
      let usertoRemove = this.FollowingUsers.find(obj => obj.id === this.UserRecord.id)
      let usertoRemoveIndex = this.FollowingUsers.indexOf(usertoRemove)
      this.FollowingUsers.splice(usertoRemoveIndex,1)
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(this.$route.params.id).update({
        FollowingUsers: this.FollowingUsers
      })

    },

    NotifyFollowingUsers(NewComment){
      this.FollowingUsersExcludingCurrent.map(user => {
        this.CreateFollowerNotificaiton(user.id,user.Name,user.Surname,NewComment)
      })
    },

    CreateFollowerNotificaiton(userid,username,usersurname,NewComment){
      let NewNotification = {
              relatedid: this.$route.params.id,
              Type: 'Comment Posted',
              Header: 'New Comment - Activity',
              Content: NewComment.Content,
              CreatorFullName: NewComment.CreatorFullName,
              CreatorID: NewComment.CreatorId,
              Owner: {Name: username,Surname: usersurname,Full_Name: username+' '+usersurname,id: userid},
              Ownerid: userid,
              Read: false,
              Path: '/Activity/'+this.$route.params.id,
              Message: 'a New Comment on '+this.Name+' by '+NewComment.CreatorFullName,
                    Date: new Date()
            }

      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(doc => {
            let tempPath = '/Activity/'+this.$route.params.id
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(doc.id).update({
              Path: tempPath
            })
            })
    },

    ReplytoComment(comment){
      console.log('initiating reply')
      console.log('comment')
      console.log(comment)
      comment.Replying = true
    },

    PostReplytoComment(comment,commentindex,ReplyMenu,ReplyingContent,ReplytoReplyMenu){
      console.log('posting reply')
      console.log(comment)
      let NewReply = {Content: comment.ReplyingContent, CreatorFullName: this.UserRecord.Full_Name, Date: format(new Date(),'yyyy-MM-dd'), CreatorId: this.UserRecord.id}

      this.Comments[commentindex].Replies.push(NewReply)
      comment.ReplyingContent = ''
      ReplyingContent = ''
      this.Comments[commentindex].ReplyMenu = false
      comment.ReplyMenu = false
      ReplyMenu = false
      if(typeof ReplytoReplyMenu !== 'undefined'){
      ReplytoReplyMenu = false
      }

      this.NotifyFollowingUsers(NewReply)
      // this.ActivitiesArray[itemindex] = item
      this.SaveCommentsandReplies()
    },

    SaveCommentsandReplies(item){
      console.log(item)
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(item.id).update({
        Comments: item.Comments
      })
      item.FollowingUsers.map(user => {
        if(user.id !== this.userLoggedIn.id){
         let notification = {
            Owner: user,
            Ownerid: user.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Header: this.userLoggedIn.Full_Name+' commented on -'+item.Subject,
            Type: 'Task Comment',
            Message: this.userLoggedIn.Full_Name+' has commented on the Task '+item.Subject,
            MessageHTML: this.userLoggedIn.Full_Name+' has commented on the Task '+item.Subject,
          }
          console.log(notification)
          this.ActivityNotification(item,notification,true) 
        }
        
      })
      

    },
      ActivityNotification(item,notification,fromcomments){
      if(!fromcomments){
       const NewNotification = {
              relatedid: item.id,
              Type: notification.Type,
              Header: notification.Header,
              Content: item.Description,
              CreatorFullName: item.Created_By.Full_Name,
              CreatorID: item.Created_Byid,
              Owner: item.Owner,
              Ownerid: item.Ownerid,
              Read: false,
              Path: '/Activities/',
              Message: item.Message,
              Date: new Date()
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(doc => {
              console.log(doc.id,doc)
            }) 
      }
      else{
        const NewNotification = {
              relatedid: item.id,
              Type: notification.Type,
              Header: notification.Header,
              Content: notification.Message,
              CreatorFullName: notification.Created_By.Full_Name,
              CreatorID: notification.Created_Byid,
              Owner: notification.Owner,
              Ownerid: notification.Ownerid,
              Read: false,
              Path: '/Activity/'+item.id,
              Message: notification.Message,
              Date: new Date()
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(doc => {
              console.log(doc.id,doc)
            })
      }
      
    },

      GetActivity() {
        this.mainrecordid = this.$route.params.id
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(this.$route.params.id).onSnapshot(snapshot => {
            var activity = snapshot.data()
            console.log('activity',activity)
            this.ActivityData = activity
            this.ActivityData.id = this.$route.params.id
            this.ActivityStatus = activity.status
            this.Name = activity.Subject
            this.editedItem.Name = activity.Subject
            this.Subject = activity.subject
            this.editedItem.subject = activity.subject
            this.To = activity.to
            this.editedItem.to = activity.to
            this.From = activity.from
            this.editedItem.from = activity.from
            this.Description = activity.Description
            this.editedItem.description = activity.Description
            this.ActivityType = activity.Type.Name
            this.editedItem.activitytype = activity.Type.Name
            this.RegardingType = activity.Regarding_Type
            //this.RegardingTypePush()
            if(this.RegardingType === 'BusinessUnit'){
              this.RegardingTypePrimaryField = 'name'
              this.RegardingRecordLink = '/BusinessUnit/'
            }
            if(this.RegardingType === 'User'){
              this.RegardingTypePrimaryField = 'fullname'
              this.RegardingRecordLink = '/User/'
            }

            this.editedItem.regardingtype = activity.Regarding_Type
            this.CreatedOn = activity.Created_On
            this.editedItem.createdon = activity.Created_On
            this.DueDate = activity.Due_Date
            this.editedItem.duedate = activity.Due_Date
            this.editedItem.duedate = activity.Due_Date
              if(activity.Comments){
                this.Comments = activity.Comments
              }
              if(activity.uploadfolders){
                this.UploadFolders = activity.uploadfolders
              }
              if(activity.section1imagesuploads){
                this.Section1ImageUploads = activity.section1imagesuploads
              }
              if(activity.Regarding_Record){
                this.RegardingRecord = activity.Regarding_Record
              }
              if(activity.FollowingUsers){
                this.FollowingUsers = activity.FollowingUsers
              }
              if(typeof activity.Owner !== 'undefined'){
              let tempOwner = activity.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            })
        },

        GetUsers(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },





      ViewItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      selectRegarding(item){
        if(this.RegardingType === 'BusinessUnit'){
        this.RegardingRecord = {Name: item.Name, Link: '/BusinessUnit/'+item.id, Name: item.Name, id: item.id}
        }
        if(this.RegardingType === 'User'){
        this.RegardingRecord = {Name: item.FullName, Link: '/User/'+item.id, FullName: item.FullName, id: item.id}
        }
        this.CloseLookupWindow()
      },

      close () {
        this.dialog = false

      },

      UpdateActivity(){
      const EditedActivity = {
        Name: this.Name,
        subject: this.Subject,
        to: this.To,
        from: this.From,
        description: this.Description,
        activitytype: this.ActivityType,
        regardingtype: this.RegardingType,
        createdon: this.CreatedOn,
        duedate: this.DueDate,
        regardingrecord: this.RegardingRecord,
        Owner: {id: this.Owner.id, Name: this.Owner.Name, Surname: this.Owner.Surname, Full_Name: this.Owner.Name+' '+this.Owner.Surname},
        Ownerid: this.Owner.id,
        }

      const EditedUser = this.Owner

      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(this.$route.params.id).update({
        Name: EditedActivity.Name,
        // subject: EditedActivity.subject,
        // to: EditedActivity.to,
        // from: EditedActivity.from,
        description: EditedActivity.description,
        activitytype: EditedActivity.activitytype,
        regardingtype: EditedActivity.regardingtype,
        createdon: EditedActivity.createdon,
        duedate: EditedActivity.duedate,
        regardingrecord: EditedActivity.regardingrecord,
        Owner: EditedActivity.Owner,
        Ownerid: EditedActivity.Ownerid,
              });




              this.closeActivityeditDialog
              this.EditedActivitysnackbar = true




        this.CancelActivityEditing()
      },

  }
}


</script>

<style>
.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outlinefont{
  color: red
}

.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}
.commentbasicoutlined{
  background: #f8f8f8;
  padding-left: 1px;
  border: 0.1px solid #dedee9;

}
.replybasicoutlined{

  border: 0.1px solid #dedee9;

}
</style>

    