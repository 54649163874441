<template>
   <v-card  :max-width="NoticeSample ? 500 : 800" tile flat style="overflow-x: hidden!important;">
    <v-expansion-panels v-if="userIsAdmin">
        <v-expansion-panel>
            <v-expansion-panel-header>Edit {{ActiveDialogid}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>                
                <v-text-field label="Title" v-model="ActiveDialog.Title"/>
                <v-text-field label="Icon" v-model="ActiveDialog.Icon"/>
                  <ContentEditableField style="padding:15px;" v-if="!Updating"
                :FieldObject="ActiveDialog" :FieldName="'Description'"
                :FieldValue="ActiveDialog.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                <v-text-field label="Special Component" v-model="ActiveDialog.SpecialComponent"/>
                   <ContentEditableField style="padding:15px;" v-if="!Updating"
                :FieldObject="ActiveDialog" :FieldName="'FinalNotes'"
                :FieldValue="ActiveDialog.FinalNotes" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-expansion-panel-content>
            <v-expansion-panel-content>
                <v-btn @click="ConfirmStoreSystemDialog()" dark outlined color="success">
                    Save</v-btn>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-card-title  v-if="!NoticeSample" style="padding: 0px;" class="BoldBuilderGradient justify-start mediumoverline white--text">        
            <v-avatar
              :size="50"
              tile
            >              
              <v-icon dark>{{ActiveDialog.Icon}}
              </v-icon>
              </v-avatar>            
            {{ActiveDialog.Title ? ActiveDialog.Title : 'About'}}<v-spacer></v-spacer>
            <v-icon color="red" @click="CloseSystemDialog()"
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text  v-if="!NoticeSample">
            <div v-html="ActiveDialog.Description"/>
          </v-card-text>
          <v-card-text v-if="ActiveDialog.SpecialComponent">
            {{ActiveDialog.SpecialComponent}}
          </v-card-text>
          <v-card-text style="padding: 0px;" v-if="ActiveDialogid === 'AboutDigitalNoticeboard'">
                <NoticesCycleComp :mustreadnotices="[samplenotice]" 
                :SampleOnly="true" @ToggleNoticeSample="ToggleNoticeSample" :NoticeSample="NoticeSample"
                />
                
            </v-card-text>
            <v-row class="justify-center my-5" v-if="ActiveDialogid === 'AboutGroupsDialog'">
              <v-card width="100" elevation="6" class="mx-1 my-1" height="100" v-for="item in GroupScopeOptions" :key="item.itemObjKey">
                <v-card-subtitle class="justify-center white--text BoldBuilderGradient" style="text-align: center;">                  
                    <span class="subheading text-left" style="font-size:12px;font-weight: 300;">{{ item.DisplayName }}</span>
                </v-card-subtitle>
                <v-card-title style="position: absolute;top:40px;left:12px;" class="justify-center">
                  <v-icon color="blue" x-large left>{{item.Icon}}</v-icon>
                </v-card-title>
              </v-card>
          </v-row>
          <v-card-text v-if="ActiveDialog.FinalNotes && !NoticeSample">
            <div v-html="ActiveDialog.FinalNotes"/>
          </v-card-text>
         <v-row class="justify-center my-5" v-if="ActiveDialogid === 'AboutGroupsDialog'">
            <v-card width="150" elevation="6" class="mx-1 my-1" height="100" v-for="item in GroupPluginOptions" :key="item.itemObjKey">
                <v-card-subtitle class="justify-center white--text BoldBuilderGradient"  style="text-align: center;height: 100%;">                  
                    <span class="subheading text-left" style="font-size:12px;font-weight: 300;">{{ item.DisplayName }}</span>
                </v-card-subtitle>
                <v-card-title style="position: absolute;top:40px;left:36px;" class="caption justify-center">
                  <v-icon color="white" x-large left>{{item.Icon}}</v-icon>
                </v-card-title>
              </v-card>
          </v-row>
   </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
import NoticesCycleComp from '@/components/SuitePlugins/DigitalNoticeBoard/NoticesCycleComp'	
export default {
    props: ['System','SystemEntities','SystemDialogid'],
    components: {ContentEditableField,NoticesCycleComp},
    data() {
        return {
            NoticeSample: false,
            Updating: false,
            ActiveDialog: {},
            ActiveDialogid: '',
            GroupScopeOptions: [
            {
              Icon: 'mdi-email-newsletter',
              DisplayName: "Articles",
            },
            {
              Icon: 'mdi-typewriter',
              DisplayName: "Blogs",
            },
            {
              //consider icon mdi-monitor-star?
              Icon: 'mdi-newspaper',
              DisplayName: "Classifieds",
            },
            {
              Icon: 'mdi-calendar-star',
              DisplayName: "Events",
            },
            {
              Icon: 'mdi-forum',
              DisplayName: "Forums",
            },
            {
              Icon: 'mdi-human-greeting-proximity',
              DisplayName: "Meetups",
            },
            {
              Icon: 'mdi-vote',
              DisplayName: "Polls",
            },
          ],  
          GroupPluginOptions: [        
            {
              Icon: 'mdi-home-group',
              DisplayName: "Group Directories",
            },
              {
              Icon: 'mdi-star-face',
              DisplayName: "Group Featured Members",
            },
              {
              Icon: 'mdi-cash-register',
              DisplayName: "Group Monetization",
            },
          ],
        }
    },	
    computed:{
        samplenotice(){
        let dateobj = new Date()
        let not = {}
        not = {
          coverimageThumbURL: require('@/assets/GallerySample2.jpg'),
          Category: {Name: 'General',Icon: 'mdi-eye'},
          Icon: 'mdi-eye',
          caption: 'Tea break has been cancelled until futher notice',
          monthcreated: this.TimestampFormatterNoticeBoard(dateobj,'month'),
          daycreated: this.TimestampFormatterNoticeBoard(dateobj,'date'),
          createddate: this.TimestampFormatterNoticeBoard(dateobj,'full'),
          createdon: this.TimestampFormatterNoticeBoard(dateobj,'full'), 
        }
        return not
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(this.SystemDialogid){
            this.ActiveDialogid = this.SystemDialogid
            this.GetSystemDialog()
        }
        //okay is userIsAdmin dialogs can be assigned let's work from here
    },
    methods:{
        ToggleNoticeSample(){
        this.NoticeSample = !this.NoticeSample
      },
      TimestampFormatterNoticeBoard(dateobj,prop){
        console.log(dateobj,prop)
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = dateobj.getHours();
       
        var min = dateobj.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = dateobj.getDate();
        var month = months[dateobj.getMonth()];
        var year = dateobj.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(dateobj,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
            this.Updating = true
             setTimeout(() => {
                this.Updating = false
                 }, 20);
            
        },
        ConfirmStoreSystemDialog(){
            confirm('this will udpate the About dialog for everybody viewing. Are you sure you want to continue?') && this.StoreSystemDialog()
        },
        StoreSystemDialog(){
            db.collection('systemdialogs').doc(this.SystemDialogid).set(this.ActiveDialog)
        },
        CloseSystemDialog(){
            this.$emit('CloseSystemDialog')
        },
        GetSystemDialog(){
            db.collection('systemdialogs').doc(this.SystemDialogid).onSnapshot(snapshot => {
                let data = snapshot.data()  
                if(typeof data !== 'undefined'){
                  this.ActiveDialog = data  
                }  
                else if(!this.userIsAdmin){
                    setTimeout(() => {
                        this.CloseSystemDialog()
                    }, 400);
                }              
            })
        },
    }
}
</script>

<style>

</style>



