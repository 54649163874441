<template>
  <div style="margin-top: 50px;">
      <v-dialog
            v-model="NewNavlistDialog"									
            width="400"									
            >		
            <v-card>
                <v-card-title class="recordtoolbar white--text">
                    {{EditedNavList.id ? 'Edit' : 'New'}} Navlist
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="EditedNavList.Name" label="Name" />
                    <v-textarea v-model="EditedNavList.Description" label="Description" />
                    <v-switch label="Social Module" v-model="EditedNavList.Options.SocialModule" />
                    <v-switch label="Dashboards" v-model="EditedNavList.Options.Dashboards" />
                    <v-select multiple v-if="EditedNavList.Options.Dashboards" :items="['My Dashboard','Dashboard Builder','Shared Dashboards']" label="Dashboard Options" v-model="EditedNavList.Options.DashboardOptions" />
                    <v-switch label="Libraries" v-model="EditedNavList.Options.Libraries" />
                    <v-switch label="Records" v-model="EditedNavList.Options.Records" />
                    <!-- <v-switch label="System" v-model="EditedNavList.Options.System" /> -->
                </v-card-text>
                <v-card-actions>
                    <v-btn dark color="orange" @click="CancelCreateDBNavlist()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="green" @click="EditedNavList.id ? UpdateNavlist(EditedNavList) : CreateDBNavlist()">{{EditedNavList.id ? 'Update' : 'Create'}}</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
      <v-list class="transparent">   
          <v-btn @click="NewNavlistDialog = true" >Add New</v-btn>       
          <v-list-item v-for="navlist in DBNavLists" :key="navlist.itemObjKey">
              <v-list-item-content>
              <v-list-item-title>
              {{navlist.Name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                  {{navlist.Description}}
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                  <v-icon @click="EditNavlist(navlist)">mdi-cogs</v-icon>
              </v-list-item-action>
          </v-list-item>
      </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','DBNavLists'],
    components: {},
    data() {
        return {
            editedindex: -1,
            NewNavlistDialog: false,
            DefaultEditedNavList: {
                Name: '',
                Description: '',
                Options: {
                    SocialModule: false,
                    Dashboards: false,
                    Libraries: false,
                    Records: false,
                    System: false,
                }
            },
            EditedNavList: {
                Name: '',
                Description: '',
                Options: {
                    SocialModule: false,
                    Dashboards: false,
                    Libraries: false,
                    Records: false,
                    System: false,
                }
            }
        }
    },
    computed:{
        UsersStore(){
            return this.$store.getters.getUsersArray
                },
        userLoggedIn () {
            return this.$store.getters.user
            },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
    },
    created(){
        
    },
    methods:{
        EditNavlist(navlist){
            this.EditedNavList = navlist
            this.NewNavlistDialog = true
        },
        UpdateNavlist(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DBNavlists').doc(this.EditedNavList.id).set(this.EditedNavList).then(doc => {
                this.CancelCreateDBNavlist()
            })
        },
        CancelCreateDBNavlist(){
            this.NewNavlistDialog = false,
            this.EditedNavList = Object.assign({},this.DefaultEditedNavList)
        },
        CreateDBNavlist(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DBNavlists').add(this.EditedNavList).then(doc => {
                this.CancelCreateDBNavlist()
            })
        },
    }
}
</script>

<style>

</style>
