
<template>
<v-card class="transparent" flat width="100%" height="100%">

    <v-dialog v-model="PollCreatingDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Creating Poll</h2><br>
                Creating your Poll, please be patient
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="PollCreatedDialog" fullscreen>
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Creating Poll</h2><br>
                Your Poll has been created, and posted to your group with id {{NewPollID}}. You can see the poll live, or share it              
                <v-btn class="accent" dark  :to="$route.params.id ? '/Group-Poll/'+$route.params.id+'/Poll/'+NewPollID : '/SitePoll/'+NewPollID">See Poll</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  
              </v-card-text>
            
              </v-layout>
              </v-container>
              
        </v-card>
      </v-dialog>
    <v-parallax
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">New Poll</h4>
              </v-col>
            </v-row>
            </v-parallax>
            <v-layout class="justify-center">
   <v-flex lg10 md10> 
<v-card class="transparent" flat width="100%" height="100%">
    <v-dialog v-model="AddImagedialog" max-width="500">
        <v-card  max-width="500">
            <v-card-title>
                Upload an image for {{EditedOption.Name}}
            </v-card-title>
            <v-card-text>
              	
                <input
                type="file"
                @change="onoptionimageselect($event)"
                ref="coverimageinputter">
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Poll.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

      
          
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Poll</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="PollName">
                </v-text-field>
                <v-combobox return-object item-text="Name" 
                  chips v-model="Category" :items="PollsCategories" label="Category" >
                </v-combobox>
                <v-switch v-if="!$store.state.UserisGuest && System.Guests_can_Social && UserisModerator || System.Guests_can_Social && GroupData && UserisModerator" v-model="GuestsIncluded" :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
                <v-menu
                v-model="PollEndDatemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px" 
                id="EventStartDatemenu"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="PollEndDate"
                    label="Poll End Date"
                    prepend-icon="mdi-calendar-month"
                    readonly
                    v-on="on"
                    clearable
                    ></v-text-field>
                </template>
                <v-date-picker v-model="PollEndDate" @input="PollEndDatemenu = false"></v-date-picker>
                </v-menu>  
                <!-- <input
            type="file"
            @change="onarticlecoverimageselect($event)"
            ref="coverimageinputter"> -->
           
                </v-card-text>
                <!-- <v-layout class="justify-center">
                
          <v-img v-if="coverimagelocalurl" :src="coverimagelocalurl">
          </v-img>
                </v-layout> -->
          <v-card-text>
              <v-layout class="justify-center">
          <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <!-- <v-select @click="getDocumentSelection()" :items="[2,4,6,8,10,12,14,16,18,20]" label="Size" @change="ChangefontSize()" v-model="fontsize">                        
                          </v-select> -->
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>                      
                        <!-- </v-btn-toggle>
                        <v-btn-toggle> -->
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <!-- <v-btn>
                            <v-icon>mdi-format-align-center</v-icon>
                          </v-btn> -->
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
          </v-layout>
              <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          <v-btn onclick="document.execCommand('undo',false,'')">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('redo',false,'')">
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                          
                          <v-btn onclick="document.execCommand('bold', false, '');">
                            <v-icon>mdi-format-bold</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('italic', false, '');">
                            <v-icon>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('underline', false, '')">
                            <v-icon>mdi-format-underline</v-icon>
                          </v-btn>
                          <v-btn @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <v-btn  onclick="document.execCommand('selectAll',false,'')">
                            <v-icon>mdi-select-all</v-icon>
                          </v-btn>
                               <!-- <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">             
                          <v-btn v-on="on">
                            
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>    
                          
                                </template> -->
                                
                          <!-- <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" style="display: none;" @change="getImage()">

                                <label for="file" class="tool-items fa fa-file-image-o"></label> -->
                               <!-- </v-menu>                   -->
                          <v-btn>
                            <v-icon @click="link()">mdi-link</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="linkyoutubevideo()">mdi-youtube</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="addphone()">mdi-phone</v-icon>
                          </v-btn>
                          <v-btn>
                            <v-icon @click="addemail()">mdi-email</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                            <v-icon>mdi-format-strikethrough</v-icon>
                          </v-btn>
                        </v-btn-toggle> 
                        
          </v-layout>
                <div contenteditable id="NewPollDescription" class="postinputbox"/>
            </v-card-text>
            <v-card-actions>
               <v-btn @click="AddPollOption()">Add Option</v-btn>
                
            </v-card-actions>
            <v-card-text>
          <v-layout row class="justify-center"  v-if="!RefreshingOptions">
        <v-col cols="12" sm="6" md="6" lg="4" xl="3"
            v-for="(option,optindex) in PollOptions" :key="option.itemObjKey"
        >
                    <v-card flat width="80%" class="my-3" :dark="PollDark" :color="PollColor.hex">
                         <v-toolbar dark dense class="BoldBuilderGradient">
                            <v-toolbar-title><span class="white--text">{{option.Name}} - Edit</span></v-toolbar-title>
                           <v-spacer></v-spacer>
                             <v-menu      
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="200px"
                                        >
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">mdi-palette</v-icon>
                                        </template>
                                    <v-color-picker v-model="PollColor"></v-color-picker>
                                    </v-menu> 
                              <v-icon @click="option.Editing = !option.Editing">mdi-pencil</v-icon>
                              <v-icon @click="PollDark = !PollDark">mdi-invert-colors</v-icon>
                            <!-- <v-icon @click="refreshview()" color="warning">mdi-refresh</v-icon><v-icon color="green" @click="UpdatePage()">mdi-content-save</v-icon><v-icon @click="DeActivatePageEditDialog()" color="red">mdi-cancel</v-icon> --> 
                            <v-icon @click="AddImage(option)"  v-if="!$store.state.TemplateView">mdi-file-image</v-icon>
                          </v-toolbar>
                        <v-card flat width="100%" class="white" tile>
                          <p class="caption">Images Optional, Leave blank if not needed</p>
                          <v-layout class="justify-center">
                        <v-img v-if="!$store.state.TemplateView" :src="option.imagelocalurl ? option.imagelocalurl : require('@/assets/ImageHolder.png')" max-width="350" height="150" contain >
                        </v-img>
                          <v-img  height="150" contain	 v-if="$store.state.TemplateView"							
                                  :src="option.ImageURL ? option.ImageURL : require('@/assets/ImageHolder.png')"									
                                  >
                                  <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                                      <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SitePollOptionIMG'),SetOptionIndex(optindex)" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                                  </v-row>
                            </v-img>
                          </v-layout>
                        </v-card>
                        
                        <v-card-title @click="option.Editing = !option.Editing" v-if="!option.Editing">
                            {{option.Name}}
                        </v-card-title>
                        <v-card-title  v-if="option.Editing">
                            <v-text-field label="Name" v-model="option.Name"></v-text-field><v-icon @click="option.Editing =! option.Editing">mdi-content-save</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <span v-if="!option.Editing" @click="option.Editing = !option.Editing">{{option.Description}}</span>
                            <v-textarea @blur="option.Editing = !option.Editing" v-if="option.Editing" label="Description" v-model="option.Description"></v-textarea>
                        </v-card-text>
                        
                        <v-card-actions>
                            <v-spacer></v-spacer><v-btn class="success">Give My Vote</v-btn>
                        </v-card-actions>
                    </v-card>
        </v-col>
          </v-layout>
            </v-card-text>
            <v-card-actions>
                
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StorePoll()">
                    Post
                </v-btn>
                
            </v-card-actions>
        </v-card>
        </v-layout>
   
    </v-card>
   </v-flex>
</v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
//import GroupPolls from '@/components/Group/GroupPolls';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'

export default {
        props: ['System'],
        middleware: 'auth',
      components: {
    //GroupPolls,
    // quillEditor

        
  },
  
    data() {
        return {
          Category: '',
          ItemCategory: '',
          GuestsIncluded: false,
            AddImagedialog: false,
            EditedOption: '',
            IMGOptionIndex: -1,
            RefreshingOptions: false,
            PollOptions: [],
            PollCreatingDialog: false,
            PollCreatedDialog: false,
            NewPollID: '',
            PollColor: {hex: '#F0F0F0'},
            PollDark: false,
            PollName: '',
            PollDescription: '',
            PollEndDate: '',
            PollEndDatemenu: false,
            UploadImagesLengthPercentage: 0,
            UploadImagesLength: 0,
            UploadImagesCount: 0,
            UploadImagesUploadPercentage: 0,
            UploadImageRunningProgress: 0,
            GroupData: '',
        selectedFile: '',
        ImagelocalURL: '',
        PollContent: '',
        pollquil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        polldialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      polltitle: null,
        
        pollcontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        grouppolls: [],
        groups: [],
        pollid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        image: '',
        Youtubelink: '',
        rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            SiteModerators: [],
            UserisModerator: false,   
            UserRecord: '' ,
            PollsCategories: [],
        }
    },
    computed: {
      ActiveGroup(){
          return this.$store.state.ActiveGroup
        },
      groupid(){
        return this.$route.params.id && !this.$store.state.TemplateView ? this.$route.params.id : ''
      },
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ContentCategoriesPath(){
        return this.$route.name === 'GroupNewPoll' ? this.ContentRootPath.collection('groups').doc(this.$route.params.id).collection('PollsCategories') : 
        this.ContentRootPath.collection('PollsCategories')
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      ContentColPath(){
        //quick tip here. Edit and New components reuqire collection to update, or add docs. thus, not interchangeable with CollectionRef on "feed" or "collection view"
        //the CollectionRef would add permission queries like for group aticles "where gourpid...etc"
        return this.$store.state.TemplateView ? this.ContentRootPath.collection('samplesitepolls') : 
        this.$route.name === 'GroupNewPoll' ? this.ContentRootPath.collection('grouppolls') : this.ContentRootPath.collection('sitepolls')
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserIsModerator(){
          if(this.$route.name === 'GroupNewPoll'){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.PollsModerator
          }
          else if(this.userLoggedIn && this.userLoggedIn.ModerationPermissions){
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Polls')
              if(moderator){
                return true
              }
              else{
                return false
              }
          }
          else {
            return this.userIsAdmin
          }
        },
        NonModeratorRoute(){
          if(this.$route.name === 'GroupNewPoll'){
            return '/Poll/'+this.$route.params.id
          }
          else{
            return '/Polls'
          }
        },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupNewPoll' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Poll' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
      userIsAdmin () {																								
      return this.$store.state.IsAdmin																								
    },
      userLoggedIn () {
      return this.$store.getters.user
    },
    SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
        ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="560" height="315" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="560" height="315" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
        RelatedPolls () {
            return this.grouppolls.filter(poll => {
                return poll.pollid === this.pollid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    watch:{
    SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['AssignSitePollOptionIMG']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {
      this.GetSitePollCategories()
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if(this.UserIsModerator && !this.$store.state.TemplateView){
           if(this.System.Social_Content_for_Guests || this.$store.state.UserisGuest){
            this.GuestsIncluded = true
          }
        this.FocusedViewToggle()
        this.IntranetViewToggle()
        this.CheckAuth()
        }
        else if(this.$store.state.TemplateView){
          this.UserRecord = this.userLoggedIn
        }
        else{
          this.$router.push(this.NonModeratorRoute)
        }

    },
    
    methods: {
      GetSitePollCategories(){
        this.ContentCategoriesPath.onSnapshot(res => {
            const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {
                  this.PollsCategories.push({
                    ...change.doc.data(),
                    id: change.doc.id
                  })
                }
              })
          })
      },
      SetOptionIndex(optindex){ 
        this.IMGOptionIndex = optindex
      },
      AssignSitePollOptionIMG(){
        this.PollOptions[this.IMGOptionIndex].ImageURL = this.SelectedImage.fileurl
        this.IMGOptionIndex = -1
        this.RefreshOptions()
        this.$store.commit('setIMGProp','')
      },
      RefreshOptions(){
        this.RefreshingOptions = true
        setTimeout(() => {
            this.RefreshingOptions = false
        }, 20);
      },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
        onoptionimageselect(event) {
            this.EditedOption.imagefile = event.target.files[0]
            this.EditedOption.imagelocalurl = URL.createObjectURL(this.EditedOption.imagefile)
            this.AddImagedialog = false
            },
        AddImage(option){
            this.AddImagedialog = true
            this.EditedOption = option
        },
        AddPollOption(){
            let NewOption = {
                Name: 'Option 1',
                Description: 'Here you need to be as concise yet descriptive as possible, so the voters can know what they are voting for',
                OptionType: 'URL',
                OptionLink: window.url,
                Editing: false
            }
            this.PollOptions.push(NewOption)
        },
        EditOption(option){
            this.EditOptionDialog = true
            this.EditedOption = option
        },
        GetGroup(){
            this.GroupData = this.ActiveGroup
        },
        onarticlecoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        GetArticles(){
        
        this.ContentColPath.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        
        },
        DateFormatter(date){
              if(date){
              let yearstring = date.split('-')[0]
                let monthstring = date.split('-')[1]
                let daystring = date.split('-')[2]
                let yearnumber = Number(yearstring)
                let monthnumber = Number(monthstring)
                let daynumber = Number(daystring)
                return new Date(yearnumber, monthnumber-1, daynumber)
                }
              else{
                return null
              }
      },
        StorePoll(){
            let vm = this
          if(vm.Category && !vm.Category.ID){
            let length = vm.PollsCategories.length
            let int = 1000001+length
            let newcat = {
              ID: int,
              Name: vm.Category
            }
            vm.ItemCategory = newcat
            //console.log('catcollection',catcollection)
            this.ContentCategoriesPath.add(newcat)
          }
          else if(vm.Category && vm.Category.ID){
            vm.ItemCategory = vm.Category
          }
          
          let routepath = ''
          let routeprop = ''
          let storagepath = ''
          if(this.ActiveMarketTemplate){
              routepath = '/MarketplaceTemplate/'+this.$route.params.id+'/Poll/'
              routeprop = 'id'
            }
          else if(this.ActiveSuiteTemplate){
              routepath = '/Your-Domain/'+this.$route.params.id+'/Social-Network/Poll/'
              routeprop = 'id'
            }
          else if(this.$route.params.id){
            routepath = '/Group-Poll/'+this.$route.params.id+'/Poll/'
            routeprop = 'title'
          }
          else{
            routepath = '/Poll/'
            routeprop = 'id'
          }
        let ref = this.ContentColPath.doc()
        let newdocid = ref.id      
        let postelmnt = document.getElementById('NewPollDescription')
        console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        if(vm.PollName && postinput){
            vm.PollCreatingDialog = true
        let NewPoll = {
                PollColor: vm.PollColor,
                PollDark: vm.PollDark,
                PollName: vm.PollName,
                PollOptions: vm.PollOptions,
                PollDescription: postinput,
                PollEndDate: vm.DateFormatter(vm.PollEndDate),
                Created_By: {id: vm.UserRecord.id,Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name},
                Created_Byid: vm.UserRecord.id,
                Created_On: new Date(),
                Owner: {id: vm.UserRecord.id,Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name},
                Ownerid: vm.UserRecord.id,
                GuestsIncluded: vm.GuestsIncluded,
                PublishStatus: 'Draft'
                }
        if(vm.ItemCategory){
          NewPoll.Category = vm.ItemCategory
        }
        if(vm.groupid){
          NewPoll.groupid = vm.groupid
        }
        if(!vm.groupid && vm.userLoggedIn && vm.userLoggedIn.Company){
          NewPoll.Company = vm.userLoggedIn.Company
          NewPoll.Companyid = vm.userLoggedIn.Companyid
        }
        if(vm.UserRecord.Profile_Photo){
          NewPoll.creatorimg = vm.UserRecord.Profile_Photo
        }
        let OptionImages = vm.PollOptions.filter(option => {
            return option.imagefile
        })
        if(OptionImages && OptionImages.length > 0 && !this.$store.state.TemplateView){
            OptionImages.map((option,i) => {
                let filename = option.imagefile.name.split('.')[0]+'_'+option.imagefile.size+'.'+option.imagefile.name.split('.')[1]
                if(vm.$route.params.id){
                    storagepath = 'GroupPolls/'+vm.$route.params.id+'/'+newdocid+'/PollOption/'+option.Name+'/'+filename
                  }
                  else{
                    storagepath = 'SitePolls/'+newdocid+'/PollOption//'+option.Name+'/'+filename
                  }
                vm.UploadImagesLengthPercentage = OptionImages.length*100
                console.log(vm.UploadImagesLengthPercentage)
                vm.UploadImagesLength = OptionImages.length
                console.log(vm.UploadImagesLength)
                let arraytotalpercentage = OptionImages.length*100
                vm.UpdatePollOptionImage(arraytotalpercentage,OptionImages.length,OptionImages,i,option.imagefile,storagepath).then(function(result) {
            if(result){
                NewPoll.PollOptions = vm.PollOptions.map(option => {
                    delete option.imagelocalurl
                    delete option.imagefile
                    delete option.Editing
                    if(!option.OptionLink){
                        delete option.OptionLink
                    }
                    option.ImageStorageRef = storagepath
                    return option
                })
               console.log(NewPoll)
               vm.ContentColPath.doc(newdocid).set(NewPoll).then(doc => {
                    vm.PollCreatingDialog = false
                    vm.NewPollID = newdocid
                    vm.PollCreatedDialog = true
               })
                // vm.$router.push('/Group/'+vm.$route.params.id)
                 
            }          
          })  
            })
        }
        else{ 
          if(this.$store.state.TemplateView){            
          //right simply we will allow picking images from site assets so...awesome
          //it's the ImageURL on "PollOpions..."///logically though don't give a hoot just ensure assign right from dialog
          }
                NewPoll.PollOptions = vm.PollOptions.map(option => {
                    delete option.imagelocalurl
                    delete option.imagefile
                    delete option.Editing
                    if(!option.OptionLink){
                        delete option.OptionLink
                    }
                    return option
                })
                console.log(NewPoll)
                let newdocref = this.ContentColPath.doc(newdocid)
               newdocref.set(NewPoll).then(doc => {
                    vm.PollCreatingDialog = false
                    vm.PollCreatedDialog = true
                    vm.NewPollID = newdocid
                })
                // vm.$router.push('/Group/'+vm.$route.params.id)
        }
        }
        else if(!vm.PollName && postinput){
            alert('You have to give a Poll Title')
        }
        else if(vm.PollName && !postinput){
            alert('You have to put a description in the Poll')
        }
        
        
      
        },

       
        UpdatePollOptionImage(arraytotalpercentage,arraylength,array,index,picturefile,storagepath){
            let vm = this
            var storageRef = firebase.storage().ref(storagepath);
            var uploadTask = storageRef.put(picturefile);
            return new Promise(function(resolve, reject) {
            uploadTask.on('state_changed', function(snapshot){
              let singleprogress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              let singlepercentage = Number(singleprogress.toFixed(2))
                
              if(singlepercentage === 100){
                vm.UploadImagesCount = vm.UploadImagesCount-1+2
                  
                vm.UploadImageRunningProgress = vm.UploadImageRunningProgress+(singlepercentage/arraylength)
                vm.UploadImagesUploadPercentage = Number(vm.UploadImageRunningProgress.toFixed(2))
                console.log(singlepercentage)
                console.log(arraylength)
                console.log(vm.UploadImageRunningProgress)
                
                
                console.log(vm.UploadImagesUploadPercentage)
                
                
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                vm.PollOptions[index].ImageURL = downloadURL
                if(vm.UploadImagesUploadPercentage === 100){
                    resolve('Done')
                }
              });
            });
            
                
      })
    },
        CancelNewArticleDialog(){
            this.ArticleTitle = ''
            this.selectedcoverimage = ''
            this.$router.push('/Group/'+this.$route.params.id)
        },
      CheckAuth(){
      var vm = this;
      if(this.userLoggedIn){
          this.UserRecord = this.userLoggedIn
          vm.authorid = this.UserRecord.id
          vm.author = this.UserRecord.Name+' '+this.UserRecord.Surname
          let moderatorsquery = this.ContentRootPath.collection('sitemoderators')
            if(!this.$route.params.id){
              vm.CheckRouting()
            }
            else{
              this.GetGroup()
            }
          // vm.CheckRouting()
        }
     
    },
    CheckRouting(){
      
              let vm = this
        this.GetSiteModerators().then(function(result) {
          if(result){
            console.log('checking if moderator')
            let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
            if(UserModeratorobj){
              if(UserModeratorobj.ModerationPermissions.includes('Polls')){
                console.log('yes Polls included')
                vm.UserisModerator = true
              }
              else{
                if(vm.userIsAdmin){
                  vm.UserisModerator = true
                }
                else{
                   vm.$router.push('/Polls')
                }
               
              }
            }
            else{
                if(vm.userIsAdmin){
                  vm.UserisModerator = true
                }
                else{
                   vm.$router.push('/Polls')
                }
              }
            
            
          }
        })

        if(this.$route.params.id){
            console.log('has single')
        }
        },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        vm.ContentRootPath.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
console.log('this is user name man '+this.author)
      

        })
            },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
        this.ImagelocalURL = URL.createObjectURL(this.selectedFile)
      },
          
      }
      
    }
// }
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>