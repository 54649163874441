<template>
<div>
 
  <div>
      <v-list class="transparent">
      <v-list-item >
        <v-list-item-content style="font-weight: 300;">
     {{field.DisplayName}}
        </v-list-item-content>
        <v-list-item-action style="font-weight: 300;">
          {{Percentage}}%
        </v-list-item-action>
      </v-list-item>
      <v-list-item >
      <div style="width: 100%;background-color: #cccccc55;border-radius: 15px;" >
        <!-- background-color: '+item.Color+'; -->
      <div :style="'width: '+Percentage+'%; text-align: right;background-color: '+(col.Color ? col.Color : '#0099FF')+';color: '+(col.Color ? col.Color : '#0099FF')+';border-radius: 15px;'">{{Percentage}}%</div>
      </div>
      </v-list-item>
      </v-list>
    </div>
<!-- 'width: '+item.Value+'%; background-color: '+item.Color+';text-align: right; -->
  <v-text-field v-if="Editing" v-model.number="Record[field.id]" :label="field.DisplayName"/>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {
         
        }
    },	
    computed:{
      Percentage(){
        return typeof this.Record[this.field.id] === 'undefined' ? 0 : this.Record[this.field.id]
      },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>
</style>



