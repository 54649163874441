<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
        <v-list-item v-if="EditedTab.SectionType">
         <v-select clearable v-model="EditedTab.RowBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
      </v-list-item>
       <v-list-item @click="ToggleElementAnimationDialog()" v-if="EditedTab.Name !== 'Parallax' && !EditedTab.DataViewName && EditedTab.Type !== '3D Carousel' && EditedTab.Type !== 'Magazine Style'" >
           <v-icon left>mdi-animation-play</v-icon> Animation
           </v-list-item>
       <v-list-item v-if="EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'Clipped Tab' && !EditedTab.DataViewName && EditedTab.Type !== 'Magazine Style'">
          <v-text-field  v-model="EditedTab.CustomStyle" label="Custom Style"/>
      </v-list-item>
      <!-- children custom style when has elements by default that could customization - testimonials by example though not required, as entire tab gets style.
      unsure about this being a rela reuqirement put on hold - essentially when yould it be reuqired for tab but not for children? or when is that required to apply for children but ot the parent?
      <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.Name !== 'Clipped Tab' && !EditedTab.DataViewName && EditedTab.Type !== 'Magazine Style' || 
      EditedTab.Name === 'Testimonials'">
          <v-text-field  v-model="EditedTab.ChildrenCustomStyle" label="Children Custom Style"/>
      </v-list-item> -->
      <v-list-item v-if="EditedTab.SectionType">
          <v-switch   v-model="EditedTab.Floating" label="Floating"/>
      </v-list-item>
      <v-list-item v-if="EditedTab.SectionType">
          <v-switch   v-model="EditedTab.HasBGIMG" label="Has BG IMG"/>
          <v-switch   v-model="EditedTab.BGIMGFixed" label="BG IMG Fixed"/>
           <v-switch   v-model="EditedTab.BGIMGCentered" label="BG IMG Centered"/>
      </v-list-item>
      <v-card flat tile class="background" v-if="EditedTab.HasBGIMG">
       <v-card-subtitle class="background darken-1 mediumoverline">
              BG Image
          </v-card-subtitle>
        <v-img v-if="EditedTab.SectionType && EditedTab.BGIMG" contain :src="EditedTab.BGIMG" height="200">
           <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog()" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
          </v-img>
      <v-img v-if="!EditedTab.BGIMG" contain :src="$vuetify.theme.dark ? require('@/assets/ImageHolder_dark.png') : require('@/assets/ImageHolder.png')" height="200">
      <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog()" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
      </v-img>
       
            <v-list-item >
              
                <v-switch
                label="Has BG Gradient"
                v-model="EditedTab.HasBGGradient"
                />
                
                <!-- </v-col> -->
            </v-list-item>
            <v-list-item  v-if="EditedTab.HasBGGradient">
                <v-text-field
                label="Gradient"
                v-model="EditedTab.BGGradient"
               
                />
                <a v-if="EditedTab.HasBGGradient" href="https://cssgradient.io/" target="_blank">Get Code</a>
            </v-list-item>
      </v-card>
       <v-list v-if="EditedTab.Name === 'Card Row'">
                              <v-switch @change="Twopropemit('UpdateChildren','AlternateSlideIn',EditedTab.Elements)" v-model="EditedTab.AlternateSlideIn" label="Alternate Slide-In"/>
                          </v-list>	
                           <v-list v-if="EditedTab.Name === 'Title' || EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Image'">
                              <v-select clearable v-model="EditedTab.CustomClass" label="CustomClass" :items="['ScrollRevealLeftWithReverse','ScrollRevealRightWithReverse','ScrollRevealLeft','ScrollRevealRight','ScrollRevealLeftStick','ScrollRevealRightStick']"/>
                          </v-list>							
                            <v-list class="transparent" v-if="EditedTab.Name !== 'HTML Box'">									
                                <v-list-item>									
                                    									
                            <v-switch dense label="Transparent" @change="Onepropemit('SetRowTransparency',EditedTab)" v-model="EditedTab.Transparent" />	
                           								
                            <v-menu      									
                                :close-on-content-click="false"									
                                :nudge-right="40"									
                                transition="scale-transition"									
                                offset-y									
                                min-width="200px"									
                                >									
                                <template v-slot:activator="{ on }">									
                            <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                </template>									
                            <v-color-picker @change="Onepropemit('ChangeRowColor',EditedTab.RowColor)" v-model="EditedTab.RowColor"></v-color-picker>									
                            </v-menu>									
                                </v-list-item>									
                            </v-list>									
                              									
                            <v-card flat width="100%" height="20%">									
                                 									
                                  <v-card-text>									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Top									
                                      <v-btn x-small @click="Twopropemit('RowTopMargin',EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="Twopropemit('RowTopMargin',EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      <span class="caption">{{EditedTab.MarginTop}}</span>									
                                  </v-card-text>									
                                 									
                                  <v-card-text>									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Btm									
                                      <v-btn x-small @click="Twopropemit('RowBottomMargin',EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="Twopropemit('RowBottomMargin',EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      <span class="caption">{{EditedTab.MarginBottom}}</span>									
                                  </v-card-text>									
                               									
                                  <v-card-text>									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Lft									
                                      <v-btn x-small @click="Twopropemit('RowLeftMargin',EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="Twopropemit('RowLeftMargin',EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                      <span class="caption">{{EditedTab.MarginLeft}}</span>									
                                  </v-card-text>									
                                  									
                                  <v-card-text>									
                                     									
                                      									
                                      <span v-if="EditedTab.SectionType">Margin</span><span v-if="!EditedTab.SectionType">Padding</span> Rgt									
                                      <v-btn x-small @click="Twopropemit('RowRightMargin',EditedTab,'down')">									
                                          <v-icon>									
                                              mdi-chevron-down									
                                          </v-icon>									
                                      </v-btn>									
                                      <v-btn x-small @click="Twopropemit('RowRightMargin',EditedTab,'up')">									
                                          <v-icon>									
                                              mdi-chevron-up									
                                          </v-icon>									
                                      </v-btn>									
                                       <span class="caption">{{EditedTab.MarginRight}}</span>									
                                  </v-card-text>									
                              </v-card>	
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      IMGProp(){
        return this.$store.state.IMGProp
      },
    },
    watch: {
      SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['AssignImageandCloseTab']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
          ToggleElementAnimationDialog(){
            this.$emit('ToggleElementAnimationDialog',this.EditedTab)
            
        },
        AssignImageandCloseTab(){
          console.log('AssignImageandCloseTab',this.SelectedImage,this.IMGProp)
          if(this.IMGProp === 'BGIMG'){
            //console.log('as LibraryAsset')
            this.EditedTab.BGIMG = this.SelectedImage.fileurl
            //this.NoPropsemit('DeactivateTabDialog')
          }
          else{
            //console.log('as else')
             // this.AssignImage(this.EditedTab,this.IMGProp,this.SelectedImage)
              //this.NoPropsemit('DeactivateTabDialog')
          }
         
        },
        ActivateGalleryDialog(){
        this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
        this.$store.commit('setGalleryAssets',this.SystemAssets)
        this.$store.commit('setEditedTab',this.EditedTab)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp','BGIMG')
        
        //GalleryLibraries
        //methods ? SelectGalleryAssets
      },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
