<template>
  <div>
      <v-list flat  style="padding-top: 0px;">
          <v-list-item style="padding-top: 0px;" class="links white--text articlecaption" dense>
              {{ItemName}}
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="ComputedRatings.length > 0">
            <!-- <v-list-item-content> -->
               <span class="display-1 grey--text" style="padding-top:2px;">{{AvgRatings}}</span>
            <!-- </v-list-item-content> -->
             <v-list-item-content class="mx-2">
              <v-list-item-title>
               <v-rating small dense
                            :value="AvgRatings" class="justify-center"
                            background-color="amber lighten-1"
                            color="amber lighten-1"
                            readonly half-increments
                          ></v-rating>
              </v-list-item-title>
              <v-list-item-subtitle style="padding-left:5px;">
                          <span class="grey--text">{{ComputedRatings.length}} reviews</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer>
              </v-spacer>
              
              <v-btn @click="EmitReviewMethod" dark :disabled="UserHasPriorRating" v-if="CanWriteReview" small outilned><v-icon small>mdi-pencil</v-icon>Write Review</v-btn>
              <v-btn @click="EmitSendMessageMethod" dark color="success" v-if="CanSendMessage" small outilned><v-icon small>mdi-message-reply-text-outline</v-icon>Send Mesage</v-btn>
          </v-list-item>
          <v-list-item v-if="ComputedRatings && ComputedRatings.length === 0">
            There are no Ratings
            <v-spacer>
              </v-spacer>
              
              <v-btn @click="EmitReviewMethod" dark :disabled="UserHasPriorRating" v-if="CanWriteReview" small outilned><v-icon small>mdi-pencil</v-icon>Write Review</v-btn>
              <v-btn @click="EmitSendMessageMethod" dark color="success" v-if="CanSendMessage" small outilned><v-icon small>mdi-message-reply-text-outline</v-icon>Send Mesage</v-btn>
          </v-list-item>
          <v-divider></v-divider>
          <v-list flat class="transparent" width="100%" v-for="message in ComputedRatings" :key="message.itemObjKey">
               <!-- <v-list-item> -->
                  
                 
                
               <v-list-item class="justify-start">
                   <v-list-item-avatar  class="mx-2">
                        <img v-if="message.Profile_Photo" :src="message.Profile_Photo" style="object-fit: cover;" >
                        <img v-if="!message.Profile_Photo" src="@/assets/BlankProfilePic.png"  style="object-fit: cover;" >
                        </v-list-item-avatar>
                   <v-list-item-content >
                       
                          <v-list-item-title>
                              <router-link :to="'/SiteMember/'+message.senderid">{{message.UserName}}</router-link>
                              </v-list-item-title>                    
                          <v-list-item-subtitle>
                           <v-rating small dense
                            v-model="message.Rating" class="justify-center"
                            background-color="amber lighten-1"
                            color="amber lighten-1"
                            readonly
                          ></v-rating>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="caption">
                          {{message.SendTime}}
                          </v-list-item-subtitle>
                        </v-list-item-content>                 
               </v-list-item>
               <v-list-item :dark="message.dark">
                 <v-list-item-avatar size="25"  v-if="message.ReadType === 'Received'">
                   <v-img :src="message.Profile_Photo" v-if="message.Profile_Photo"/>
                   <v-img src="@/assets/BlankProfilePic.png" v-if="!message.Profile_Photo"/>
                 </v-list-item-avatar>
                 <v-list-item-content :class="message.Color">
                   <span style="padding-left:10px;padding-right:1px;font-size:14px;" v-html="message.message"></span>
                   
                 </v-list-item-content>
               </v-list-item>
               <v-list v-if="message.Replies && message.Replies.length > 0 && CanViewReplies" flat class="transparent" width="100%">
                 <v-list-item v-for="reply in message.Replies" :key="reply.itemObjKey">
                  
                    <v-list flat class="transparent" width="100%">
                    
                  <v-list-item class="justify-start">
                      <v-list-item-avatar  class="mx-2">
                            <img v-if="reply.Profile_Photo" :src="reply.Profile_Photo" style="object-fit: cover;" >
                            <img v-if="!reply.Profile_Photo" src="@/assets/BlankProfilePic.png"  style="object-fit: cover;" >
                            </v-list-item-avatar>
                      <v-list-item-content >
                          
                              <v-list-item-title>
                                  <router-link :to="'/SiteMember/'+reply.senderid">{{reply.UserName}}</router-link>
                                  </v-list-item-title>                    
                              <v-list-item-subtitle>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="caption">
                              {{reply.SendTime}}
                              </v-list-item-subtitle>
                            </v-list-item-content>                 
                  </v-list-item>
                  <v-list-item :dark="reply.dark">
                    <v-list-item-avatar size="25"  v-if="reply.ReadType === 'Received'">
                      <v-img :src="reply.Profile_Photo" v-if="reply.Profile_Photo"/>
                      <v-img src="@/assets/BlankProfilePic.png" v-if="!reply.Profile_Photo"/>
                    </v-list-item-avatar>
                    <v-list-item-content :class="reply.Color">
                      <span style="padding-left:10px;padding-right:1px;font-size:14px;" v-html="reply.message"></span>
                      
                    </v-list-item-content>
                  </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                  </v-list-item>
                 </v-list> 
                 </v-list>
                 <!-- <v-divider></v-divider>
                 
               </v-list-item>      -->
                     
             </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','Ratings','ItemName','CanWriteReview','CanSendMessage','CanViewReplies'],
    components: {},
    data() {
        return {

        }
    },
    computed:{
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserHasPriorRating(){
          //we will default the rating check on here, given it's likely going to be stock standard regardless what you rate, that the check for whather a rating exists get done as by standard we don't allow double
            return !this.userLoggedIn
        },
        AvgRatings(){
            let length = this.ComputedRatings.length
            if(length > 0){
                let total = this.ComputedRatings.map(rating => {
                    return rating.Rating
                }).reduce((a, b) => a + b, 0)
                return total/length
            }
            else{
                return 0
            }
        },
        ComputedRatings(){
            return this.Ratings.map(rating => {
                let ratingobj = Object.assign({},rating)
                let userobj = this.UsersArray.find(obj => obj.id === rating.senderid || obj.id === rating.GroupMember)
                if(userobj){
                  ratingobj.UserName = userobj.Full_Name
                  ratingobj.Profile_Photo = userobj.Profile_Photo
                }
                ratingobj.SendTime = this.TimestampFormatterSTRING(rating.Created_On.toDate())
                ratingobj.TimestampSort = this.DateFormatterwithTime(ratingobj.SendTime)
                if(ratingobj.Replies){
                  ratingobj.Replies = ratingobj.Replies.map(replyobj => {
                    let reuserobj = this.UsersArray.find(obj => obj.id === replyobj.senderid || obj.id === replyobj.GroupMember)
                   // console.log(reuserobj,this.UsersArray)
                    if(reuserobj){
                      replyobj.UserName = reuserobj.Full_Name
                      replyobj.Profile_Photo = reuserobj.Profile_Photo
                    }
                    replyobj.SendTime = this.TimestampFormatterSTRING(replyobj.Created_On.toDate())
                    replyobj.TimestampSort = this.DateFormatterwithTime(replyobj.SendTime)
                    return replyobj
                  }).sort((a, b) => {
                        var key1 = a.TimestampSort;
                        var key2 = b.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                }
                return ratingobj
            }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
        },
        UsersArray(){
            return this.$store.state.UsersArray
        }
    },
    created(){

    },
    methods:{
        EmitReviewMethod(){
            this.$emit('EmitReviewMethod')
        },
        EmitSendMessageMethod(){
            this.$emit('EmitSendMessageMethod')
        },
        DateFormatterwithTime(date){
      if(date){
        // console.log(date)
        // console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},

TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        //console.log(min,d)
        
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        if (sec < 10) {
            sec = "0" + sec;
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
    }
}
</script>

<style>

</style>
