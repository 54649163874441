<template>
  <div >
      
    <LandingPageSingle :hidden="TabsRenderMode === 'Editing Tabs'" :style="userLoggedIn ? 'margin-top:140px;' : ''" v-if="EntityReady" :DataSingleTabs="ComputedSingleBuildTabs"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       :SinglePageBGGradient="SinglePageBGGradient" :WikiEntities="WikiEntities" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
       :EntityCollectionRef="ConfigDB" :CurrentEntity="CurrentEntity"
       :System="System" :SystemEntities="SystemEntities"  :AdditionalSaveMethod="''"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
        :ActivitiesArray="ActivitiesArray" @CancelEditing="CancelEditing" :FeatureNavList="FeatureNavList" :ClsfdNavList="ClsfdNavList"
        :PrimaryFieldName="PrimaryFieldName" :Directories="Directories"
       :ActiveEntityID="ConfigEntityID"/>
       <NewLandingPage :style="userLoggedIn ? 'margin-top:140px;' : ''" v-if="TabsRenderMode === 'Editing Tabs'" :DataSingleTabs="ComputedSingleBuildTabs"
       :SinglePageBGGradient="SinglePageBGGradient" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
       :FeatureNavList="FeatureNavList" :WikiEntities="WikiEntities" :ClsfdNavList="ClsfdNavList"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" :Directories="Directories"
       :EntityCollectionRef="ConfigDB" :CurrentEntity="CurrentEntity" :SystemPhotoLibraries="SystemPhotoLibraries"
       :System="System" :SystemEntities="SystemEntities"  :AdditionalSaveMethod="''"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery"
        :ActivitiesArray="ActivitiesArray" @CancelEditing="CancelEditing"
        :PrimaryFieldName="PrimaryFieldName"
       :ActiveEntityID="ConfigEntityID"/>  
     
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';

export default {
    props: ['AppisDarkMode','PushedID','SystemEntities','System','AppisDarkModeCard','SitePages','AppStylingView','SystemPhotoLibraries','Directories','WikiEntities','FrequentlyAskedQuestions'],
    components: {
    ContentEditableField,
    NewLandingPage,
    LandingPageSingle,
    },
    data() {
        return {
            CurrentEntity: '',
            RecordData: '',
            SingleFormEditing: false,
            EntityReady: false,
            SubCollections: [],
            Galleries: [],
            ClassifiedTabs: [],
            FeatureNavList: ''
        }
    },
    computed:{
        ComputedSingleBuildTabs(){
            return this.ClassifiedTabs
        },
        ConfigEntityID(){
            return this.$route.meta.EntityID
        },
        DynamicRecordData(){
      let dataobj = {}
      //this should become OpsRecordID not routeparams, not work along with subcol entry view
      dataobj.id = this.$route.params.id
      //console.log('this.SystemEntities',this.SystemEntities)
      let entity = this.SystemEntities.find(obj => obj.id === this.ConfigEntityID)
      //console.log('entity',entity)
      if(entity){
        dataobj.Galleries = this.Galleries
        dataobj = Object.assign({},this.RecordData)
      if(entity.SubCollections){
        dataobj.SubCollections = this.SubCollections
      }
      if(entity.Galleries){
        dataobj.Galleries = this.Galleries
        this.Galleries.map(gal => {
            dataobj[gal.id] = gal.DataArray
        })
      }
      if(this.FeatureNavList){
        this.FeatureNavList.FeatureList.map(field => {
          if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]
          }
        })
      }
      if(this.RecordData.Combined_Title){
          dataobj.Combined_Title = this.RecordData.Combined_Title
      }
      console.log('dataobj',entity,this.SubCollections,dataobj)
      return dataobj    
      }
       else if(this.RecordData){
          return this.RecordData
      }
      
      
    },
        TabsRenderMode(){
        if(!this.SingleFormEditing && this.EntityReady){
            return 'Standard Tabs'
        }
        else if(this.SingleFormEditing && this.EntityReady){
            return 'Editing Tabs'
        }
        },
        WikiMode(){
            return this.$route && this.$route.meta.WikiListingBuilder || this.$route.meta && this.$route.meta.RouteEventID === 6001
        },
        BuilderMode(){
            return this.$route.meta && this.$route.meta.ClassifiedListingBuilder || this.$route && this.$route.meta.WikiListingBuilder
        },
        PublicUser(){
            return !this.userLoggedIn
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
        ConfigDB(){
            if(this.BuilderMode && !this.WikiMode){
                return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('classifiedentities').doc(this.ConfigEntityID)
            }
            else if(this.BuilderMode && this.WikiMode){
                return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('wikientities').doc(this.ConfigEntityID)
            }
            else{
                if(this.$route.meta && this.$route.meta.RouteEventID === 6001){
                    return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('wikientities').doc(this.ConfigEntityID)                   
                }
                else if(this.$route.meta && this.$route.meta.RouteEventID === 5001){
                    return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('classifiedentities').doc(this.ConfigEntityID)                 
                }
            }
        },
        OperationalDB(){
            if(this.BuilderMode){
                return db.collection(this.$route.meta.DataCollection).doc(this.$route.params.id)
            }
            else{
                //alert('public')
                if(!this.userLoggedIn && !this.WikiMode){
                    
                    return db.collection(this.$route.meta.Collection).where('PublishType','==','Public').where('Combined_Title','==',this.$route.params.id)
                }
                else if(this.WikiMode){
                    return db.collection(this.$route.meta.Collection).doc(this.$route.params.id)
                }
                else{
                    return db.collection(this.$route.meta.Collection).where('Combined_Title','==',this.$route.params.id)
                }
                
            }
        },
        RouteObj(){
            return this.$route
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['CancelEditing']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      CurrentEntity(value) {
        if(value && this.$route.meta && this.$route.meta.ClassifiedListingBuilder){
          this.$store.commit('SetCurrentEntity',value) 
        }
      },
      CanEdit(value) {
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)              
            }
        },
        RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    console.log('watcher RouteObj',oldvalue, newvalue)
                    this.CurrentEntity = ''
                    this.EntityReady = false
                    setTimeout(() => {
                      this.PrepareView()    
                    }, 100);
                                      
                }
            },
            deep: true
        },
    },
    created(){
        console.log('this.WikiEntities',this.WikiEntities)
        //console.log(this.$route)
        //alert('whhopoo')
        this.PrepareView()
    },
    methods:{
        CancelEditing(boolean){
        this.SingleFormEditing = !this.SingleFormEditing
        },
        AssignGalleryAssets(tab,asset){									
            // console.log(tab,asset)									
            if(tab.Name === 'Gallery' || tab.Name === 'Carousel'){									
                let galleryobj = {									
                Name: asset.Name,									
                URL: asset.Path									
            }									
            if(asset.Path){									
                galleryobj.URL = asset.Path									
            }									
            else if(asset.path){									
                galleryobj.URL = asset.path									
            }	
            else if(asset.url){									
                galleryobj.URL = asset.url									
            }								
            if(asset.ThumbURL){									
                galleryobj.ThumbURL = asset.ThumbURL									
            }									
            if(!asset.ThumbURL){									
                galleryobj.ThumbURL = galleryobj.URL									
            }									
            									
            tab.Assets.push(galleryobj)									
            //console.log(tab)									
             tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
            }									
            else if(tab.Name === 'Parallax' || tab.Name === 'Image' || tab.HasImage){									
                this.AssignImage(tab,'IMG',asset)									
            }									
            									
            									
        },								
        AssignImage(tab,prop,asset){	
            //console.log(tab,prop,asset)								
            if(asset.Path){									
             tab[prop] = asset.Path   									
            }									
            else if(asset.path){									
                tab[prop] = asset.path 									
            }									
            									
            //console.log(tab)									
             tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },	
        PrepareView(){
            
            if(this.$route && this.$route.meta.ClassifiedListingBuilder || this.$route && this.$route.meta.WikiListingBuilder){
                //this.$emit('IntranetViewToggle',true)
                if(this.userLoggedIn){
                    //likely need to check of classifiedmoderator as well as safeguard
                let entity = this.SystemEntities.find(obj => obj.id === this.ConfigEntityID)
                this.CurrentEntity = Object.assign({},entity)
                if(this.$route && this.$route.meta.WikiListingBuilder){
                    let wikientity = this.WikiEntities.find(obj => obj.id === this.CurrentEntity.id)
                    if(wikientity && wikientity.PublishType){
                        this.CurrentEntity.PublishType = wikientity.PublishType
                    }
                }
                this.GetTabs()
                let featurenavlist = this.Directories.find(obj => obj.FeaturesListRelatedBuildID === this.CurrentEntity.id)
                this.ClsfdNavList = this.Directories.find(obj => obj.id === this.CurrentEntity.id)
                console.log(featurenavlist,this.Directories)
                if(featurenavlist){
                  this.FeatureNavList = featurenavlist
                }
                else{
                    featurenavlist = this.Directories.find(obj => obj.id === this.CurrentEntity.id)
                    if(featurenavlist && featurenavlist.FeatureListType === 'Standard'){
                        this.FeatureNavList = featurenavlist
                    }
                }
                
                }
                else{
                    //eror page you cannot edit as public user
                }
            }
            else{
                
                if(this.userLoggedIn){
                this.$emit('IntranetViewToggle',true)
                this.$emit('ToggleCatNavlistView',false)
                }
                //get listing entity I think, single view non builder
                this.GetTabs()
            }
        },
        GetTabs(){
            //alert(this.ConfigEntityID)
            console.log(this.ConfigDB)
            this.ConfigDB.onSnapshot(snapshot => {
                let data = snapshot.data()
                if(data && data.ClassifiedTabs){
                    this.ClassifiedTabs = data.ClassifiedTabs
                    
                }
                else if(data && data.WikiTabs){
                    this.ClassifiedTabs = data.WikiTabs
                    if(data.WikiFields && this.CurrentEntity){
                        this.CurrentEntity.WikiFields = data.WikiFields
                    }
                }
                //console.log(this.ClassifiedTabs)
                this.GetSystemRecord(true)
            })
        },
        GetSystemRecord(BuilderBased){
            //alert('get reocrd')
            //in Builderbased we got entity and everything, so we need to call things liek subcoldata for gallery etc            
            //in non builder based, we call enstire object and I suspect it's really just values
            //I think this means though, "SubCollections" do not get assigned to the entity, they get assigned as array vlaues and crap. 
            //oh no
            console.log(this.OperationalDB)
            if(!this.WikiMode){
            this.OperationalDB.onSnapshot(snapshot => {
                console.log(snapshot)
                let data = ''
                if(this.BuilderMode){
                    data = snapshot.data()
                    data.id = this.$route.params.id
                }
                else{
                    data = snapshot.docs[0].data()
                    data.id = snapshot.docs[0].id
                }
                
                console.log(data)
                if(data){
                    
                    this.RecordData = data
                    if(this.CurrentEntity){
                        if(this.CurrentEntity.SubCollections){
                            //this.GetCabinetDocuments(this.OperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase()),elmnt)
                        }
                        if(this.CurrentEntity.Galleries){
                            this.Galleries = this.CurrentEntity.Galleries
                            this.Galleries.map(gal => {
                                gal.DataArray = []
                                this.GetCabinetDocuments(this.OperationalDB.collection(gal.id.split(' ').join('').split('_').join('').toLowerCase()),gal)                            
                            })
                        }
                        console.log
                        if(this.CurrentEntity.DocumentRegisters){
                            
                            this.CurrentEntity.DocumentRegisters.map(docreg => {
                                docreg.DataArray = []
                                this.GetCabinetDocuments(this.OperationalDB.collection(docreg.id.split(' ').join('').split('_').join('').toLowerCase()),docreg)                            
                            })
                        }
                    }
                    else{
                        if(data.ClassifiedTabs){
                            this.ClassifiedTabs = data.ClassifiedTabs
                            //this.GetCabinetDocuments(this.OperationalDB.collection(gal.id.split(' ').join('').split('_').join('').toLowerCase()),gal)   
                        }
                    }
                    
                }
                else{
                    //oops no data
                }
                this.EntityReady = true
                //console.log(this.OperationalDB,data)
            })
            }
            else{
                this.OperationalDB.onSnapshot(snapshot => {
                    let data = snapshot.data()
                     if(data){
                    
                    this.RecordData = data
                    this.RecordData.id = this.$route.params.id
                    if(this.CurrentEntity){
                        
                        if(this.CurrentEntity.SubCollections){
                            //this.GetCabinetDocuments(this.OperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase()),elmnt)
                        }
                        if(this.CurrentEntity.Galleries){
                            this.Galleries = this.CurrentEntity.Galleries
                            this.Galleries.map(gal => {
                                gal.DataArray = []
                                this.GetCabinetDocuments(this.OperationalDB.collection(gal.id.split(' ').join('').split('_').join('').toLowerCase()),gal)                            
                            })
                        }
                        if(this.CurrentEntity.DocumentRegisters){
                            
                            this.CurrentEntity.DocumentRegisters.map(docreg => {
                                docreg.DataArray = []
                                this.GetCabinetDocuments(this.OperationalDB.collection(docreg.id.split(' ').join('').split('_').join('').toLowerCase()),docreg)                            
                            })
                        }
                    }
                    else{
                        if(data.ClassifiedTabs){
                            this.ClassifiedTabs = data.ClassifiedTabs
                            //this.GetCabinetDocuments(this.OperationalDB.collection(gal.id.split(' ').join('').split('_').join('').toLowerCase()),gal)   
                        }
                    }
                    
                }
                else{
                    //oops no data
                }
                this.EntityReady = true
                })
               
            }
            
        },
    
    GetCabinetDocuments(query,elmnt){    
        
      query.onSnapshot(res => {
          let length = res.docs.length
          
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                elmnt.DataArray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                
              }
             
              if(elmnt.DataArray.length === length){
                    //this.FixrespectiveGalleryTabs(elmnt)
                }
            })
      })
    },
    },

}
</script>

<style>

</style>