
<template>
<div :style="{ backgroundImage: 'url(' + require('@/assets/RapidappsParallax.jpeg') + ')',height: '100%', overflowY: 'hidden'}">
<v-card tile flat height="100%" width="100%" color="rgb(255, 255, 255, 0.8)">
    <v-card-text>
    </v-card-text>
<!-- <v-img alt="Access" src="@/assets/RapidappsParallax.jpeg"> -->
<!-- <v-container class="container transparent"> -->
    <v-card-title class="justify-center" style="margin-top: 100px;">
    <h1><v-icon x-large color="red">mdi-account-alert</v-icon>You do not have the permissions to view this page</h1>
    </v-card-title>
        <v-layout class="justify-center">
         <img alt="Access" height="350" src="@/assets/access_denied.png">
        </v-layout>
    <v-layout class="justify-center">
        <v-card flat tile class="transparent" >
        
      
            <v-card-title class="display-1 justify-center">
        <span >{{System.Name.substr(0,System.Name.indexOf(' '))}}</span><span v-if="System.Name.substr(System.Name.indexOf(' ')+1)" class="font-weight-light"> {{System.Name.substr(System.Name.indexOf(' ')+1)}}</span>
            </v-card-title>
        <v-card-subtitle class="justify-center">
        <p>To gain access, speak to your System Administrator</p>
        </v-card-subtitle>
        <v-layout class="justify-center">
        <p>Powered by</p><h4><span>Rapid</span><span class="font-weight-light">Apps©</span></h4>
        </v-layout>

        </v-card>
    </v-layout>
<!-- </v-container> -->
<!-- </v-img> -->
</v-card>
</div>
</template>


<script>


export default {
    props: ['System'],
    data() {
        return {

        }
    }
}
</script>

<style>
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
</style>

    