<template>
  <div>
    <v-autocomplete
        :items="AdvancedComputedLibraryColors"
        :label="field.DisplayName"
        v-model="Record[field.id]"
    >
        <template v-slot:item="{ item }">
        <span :class="item"
            >{{ item }}
            <p style="font-size: 12px">
            The Quick brown fox jumps over the lazy dog
            </p>
        </span>
        </template>
    </v-autocomplete>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','Record','field','FullRange'],
    components: {},
    data() {
        return {
            LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
        }
    },	
    computed:{
        AdvancedComputedLibraryColors(){
      let arr1 = this.LibraryColors
      let arr2 = [
        'primary',
        'accent',
        'secondary',
        'pop',
        'morning',
        'night',
        'fieldcard',
        'links',
        'background',
      ]
      let arr3 = arr1.concat(arr2)
      let arr4 = []
      arr3.map(color => {
        arr4.push(color)
        arr4.push(color+' lighten-1')
        arr4.push(color+' lighten-2')
        arr4.push(color+' lighten-3')
        arr4.push(color+' lighten-4')
        arr4.push(color+' lighten-5')
        arr4.push(color+' darken-1')
        arr4.push(color+' darken-2')
        arr4.push(color+' darken-3')
        arr4.push(color+' darken-4')
        arr4.push(color+' darken-5')
        arr4.push(color+' accent-1')
        arr4.push(color+' accent-2')
        arr4.push(color+' accent-3')
        arr4.push(color+' accent-4')
      })
      return this.FullRange ? arr4 : arr1
    },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



