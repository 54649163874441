<template>
 <v-main>
      <v-card flat tile class="transparent" height="100%" style="margin-top: 55px;" v-if="ComputedTransactionDocumentData">
    
          <v-card-title class="largeoverline recordtoolbar white--text">
            {{TransactionTypeName}} - {{$route.params.id}}
            <v-spacer>
            </v-spacer>
            <v-btn @click="GetShortLivedURL(TransactionDocumentData.Latest_PDF_File)" v-if="TransactionDocumentData.Latest_PDF_File">View PDF</v-btn>
            <v-btn @click="CompleteDispatchInstruction()" v-if="$route.query && $route.query.TransactionType === 'DispatchInstructions' && ComputedTransactionDocumentData.Progress && ComputedTransactionDocumentData.Progress.Name !== 'Completed'" color="success" dark :disabled="!ParentEnabled">Complete</v-btn>
            <v-chip v-if="ComputedTransactionDocumentData.Progress">
                {{ComputedTransactionDocumentData.Progress.Name}}
            </v-chip>
            <!-- ParentEnabled -->
          </v-card-title>
          <v-list-item v-if="SelectedBillingAccount">
              <v-list-item-content>
                <v-list-item-title v-if="SelectedBillingAccount.User">
                 Client
              </v-list-item-title>
              <v-list-item-title v-if="SelectedBillingAccount.User">
                  {{SelectedBillingAccount.User.Full_Name}}
              </v-list-item-title>
            
              </v-list-item-content>
              <v-list-item-action  v-if="SelectedBillingAccount.Customer_ID">
                  <v-chip >
                {{SelectedBillingAccount.Customer_ID}}
            </v-chip>
              </v-list-item-action>
          </v-list-item>
          <v-list-item  :style="ListStyle">
              <v-list-item-content>
              <v-list-item-title v-if="ComputedTransactionDocumentData.Created_By">
                  {{ComputedTransactionDocumentData.Created_By.Full_Name}}
              </v-list-item-title>
              <v-list-item-subtitle v-if="ComputedTransactionDocumentData.Created_On">
                  {{ComputedTransactionDocumentData.Created_On.toDate()}}
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action  v-if="ComputedTransactionDocumentData.LookupObj">
                  <v-chip :to="'/'+ComputedTransactionDocumentData.LookupObj.single.split(' ').join('')+'/'+ComputedTransactionDocumentData.LookupObj.id">
                {{ComputedTransactionDocumentData.LookupObj[ComputedTransactionDocumentData.LookupObj.identifier]}}
            </v-chip>
              </v-list-item-action>
          </v-list-item>
          <!-- <v-container style="padding: 0px;align-items: center !important;justify-content: center !important;"> -->
          
          <v-card-text style="padding:30px;">
            <LineItemsComponent :Line_Items="ComputedTransactionDocumentDataLine_Items" :ParentDisabled="!ParentEnabled" :System="System" :SystemEntities="SystemEntities" :Type="'Warehouse Transaction'"
            @UpdateProgress="UpdateProgress"/>
          </v-card-text>
            <!-- </v-container> -->
            <v-card-title class="mediumoverline" v-if="OrderTotal">
                Total
                <v-spacer>
                </v-spacer>
                {{CurrencyFormatter(ItemTotal,$store.state.DefaultCurrency.Currency)}}
            </v-card-title>
          <v-card-actions  v-if="OldStyle" style="position: fixed; bottom: 115px;width: 100%" class="basicoutlined">              
              <v-spacer>
              </v-spacer>
              <v-btn>Cancel</v-btn>
              <v-btn color="success" dark>
                  Save
              </v-btn>
          </v-card-actions>
      </v-card>
 </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LineItemsComponent from '@/components/SuitePlugins/Warehousing/LineItemsComponent';

export default {
    props: ['System','SystemEntities'],
    components: {LineItemsComponent},
    data() {
        return {
            HasClientRecord: false,
            TransactionDocumentData: '',
            SalesOrder: '',
            SelectedBillingAccount: '',
            TransactionDocumentDataLine_Items: [],
            itemsearch: ''
        }
    },
    computed:{
        PaidinFull(){
            return this.SalesOrder && this.SalesOrder.Amount_Paid && this.SalesOrder.Grand_Total && this.SalesOrder.Amount_Paid.toFixed(2) >= this.SalesOrder.Grand_Total.toFixed(2)
        },
        ParentEnabled(){
            if(this.$route.query && this.$route.query.TransactionType === 'DispatchInstructions'){
                if(this.SelectedBillingAccount && this.SelectedBillingAccount.Billing_Type && this.SelectedBillingAccount.Billing_Type.Name === 'Credit'){
                    //check if limit reached but otherwise
                    return true
                }
                else {
                    //COD, so is it paid and does it need be paid?
                    if(this.SalesOrder && this.SalesOrder.Amount_Paid.toFixed(2) && this.SalesOrder.Amount_Paid.toFixed(2) > 0){
                        //previous payments dude
                        if(this.CurrentEntity && this.CurrentEntity.COD_Payment_Terms && this.CurrentEntity && this.CurrentEntity.COD_Payment_Terms.Name === 'Payment PRIOR Dispatch'){
                            //Well has the full amount been paid?
                            let totalinvoiced = this.SalesOrder.Amount_Invoiced.toFixed(2)
                            //this would include the current inv, already updaed rememebr...
                            if(this.SalesOrder.Amount_Paid.toFixed(2) >=  totalinvoiced){
                                //Amount_Invoiced is excl tax without tax, while Amount_Paid is inclusive, this formula is not safe
                                return true
                            }
                            
                        }
                        else if(this.CurrentEntity && this.CurrentEntity.COD_Payment_Terms && this.CurrentEntity && this.CurrentEntity.COD_Payment_Terms.Name === 'Payment PRIOR Order Release'){
                            //Well has the full amount been paid?
                            //i mean this one wants the full amount
                            return this.PaidinFull
                        }
                    }
                    else if(this.CurrentEntity && this.CurrentEntity.COD_Payment_Terms && this.CurrentEntity && this.CurrentEntity.COD_Payment_Terms.Name === 'Payment AFTER Dispatch'){
                        //only now can we release bob
                        return true
                    }

                }
            }
            else{
                return true
            }
            // || this.$route.query.TransactionType === 'Sales'
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
        ListStyle(){
            return this.$vuetify.theme.dark ? 'border: 1px solid #dedee9;outline: 1px solid #dedee9;background: #787878;' : 'border: 1px solid #dedee9;outline: 1px solid #dedee9;background: #f8f8f8;'
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        ActiveWarehouse(){
            return this.$store.state.WarehousesArray.find(obj => obj.id === this.$route.params.slug)
        },
        CurrentEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Sales_Order_Entity)
        },
        PermissionObject(){
            let WarehouseActions = [
              {Display: 'Requisitions',Prop: 'Requisitions', ArrayName: 'PurchaseRequisitions'},
              {Display: 'Ordering',Prop: 'Ordering', ArrayName: 'PurchaseOrders'},
              {Display: 'Receiving',Prop: 'Receiving', ArrayName: 'GoodsReceivingVouchers'},
              {Display: 'Picking',Prop: 'Picking', ArrayName: 'PickingSlips'},
              {Display: 'Packing',Prop: 'Packing', ArrayName: 'PackingLists'},
              {Display: 'Dispatching',Prop: 'Dispatching', ArrayName: 'DispatchInstructions'},
            ]
            let active = WarehouseActions.find(obj => obj.ArrayName === this.$route.query.TransactionType)
            return active.Prop
        },
        UserisModerator(){
            let permissioncheck = ''
            if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id]){
                this.userLoggedIn.Warehouses[this.ActiveWarehouse.id][this.PermissionObject]
            }            
            return permissioncheck ? permissioncheck || this.userIsAdmin : this.userIsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        LineItemDBBase(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection(this.$route.query.TransactionType+'Line_Items')
        },
        ProgressItems(){
            return [
                {ID: 1000001,Name: 'Issued'},
                {ID: 1000002,Name: 'In Progress'},
                {ID: 1000003,Name: 'Returned'},
                {ID: 1000004,Name: 'Cancelled'},
                {ID: 1000005,Name: 'Completed'}
            ]
        },
        TransactionInProgress(){
            switch (this.$route.query.TransactionType) {
                case 'DispatchInstructions': return 'Issued'
                case 'PickingSlips': return 'Issued'
                case 'PackingLists': return 'Issued'
                default: return 'Issued'
                }  
        },
        ItemTotal(){
            return this.ComputedTransactionDocumentDataLine_Items.map(item => {
                return item.Qty*item.Qty
            }).reduce((a, b) => a + b, 0)
        },
        ScrollHeight(){
            let length = this.ComputedTransactionDocumentDataLine_Items.length
            let relaxfit = 5
            if(length > relaxfit){
                if(!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs){
                    return this.WindowHeight*0.35
                }
                else{
                    return this.WindowHeight*0.25
                }
            }
            else{
              return (this.WindowHeight*0.35)/(relaxfit/length)
            }
        },
        WindowHeight(){
          return window.innerHeight
      },
        TransactionTypeName(){
            switch (this.$route.query.TransactionType) {
                case 'GoodsReceivingVouchers': return 'Goods Receiving Voucher'
                case 'DispatchInstructions': return 'Dispatch Instruction'
                case 'PickingSlips': return 'Picking Slip'
                case 'PackingLists': return 'Packing List'
                default: return 'Field Created'
                }  
        },
        ComputedTransactionDocumentData(){
            let obj = Object.assign({},this.TransactionDocumentData)
            if(obj){
              if(obj.Created_By){
                let obj = this.UsersArray.find(obj => obj.id === obj.Created_Byid)
                    if(obj){
                        obj.Created_By.Profile_Photo = obj.Profile_Photo
                    }
            }
            // obj.monthcreated = this.TimestampFormatterNoticeBoard(obj.Created_On.toDate(),'month')
            // obj.daycreated = this.TimestampFormatterNoticeBoard(obj.Created_On.toDate(),'date') 
            return obj  
            }
            
        },
        ComputedTransactionDocumentDataLine_Items(){
            return this.TransactionDocumentDataLine_Items.filter(item => {
                if(this.itemsearch){
                    return item.StockObj && item.StockObj.Item_Name.toLowerCase().includes(this.itemsearch.toLowerCase())
                }
                else{
                    return item
                }
            })
        },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        OrderEntity(){
            return this.SystemEntities.find(obj => obj.id === this.TransactionDocumentData.LookupObj.list.split(' ').join('_'))
        },
        OrderEntityInvoiceCycle(){
            return this.OrderEntity && this.OrderEntity.Warehouse_Dispatch_After ? this.OrderEntity.Warehouse_Dispatch_After.Name : ''
        },
        TransactionTriggersDispatching(){
            if(this.OrderEntityInvoiceCycle){
                if(this.OrderEntityInvoiceCycle === 'Picking'){
                    return this.$route.query.TransactionType === 'PickingSlips'
                }
                else if(this.OrderEntityInvoiceCycle === 'Packing'){
                    return this.$route.query.TransactionType === 'PackingLists'
                }
            }
        }
    },
    created(){
        this.GetTransactionalDocument()
    },
    methods:{
        SaveNewClientcomms(NewComms,record){
        //  commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
             // db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id)
         let clientrecordobj = {
                name: record[this.CurrentEntity.RecordPrimaryField.Name],
                topicid: this.CurrentEntity.id,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Clientid: record.Userid
            }  
          if(!this.HasClientRecord){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(record.id).set(clientrecordobj) 
          }
         
        let commscol = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientliaison')
        //console.log(commscol)
        let owneruser = record.User
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.CurrentEntity.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.CurrentEntity.RecordPrimaryField.Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
     
        CompleteDispatchInstruction(){
            let record = Object.assign({},this.SalesOrder)
            let vm = this
            //update the line items (each of them,as well as parent as "Completed"
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection(this.$route.query.TransactionType).doc(this.$route.params.id).update({
                Progress: {ID: 1000005,Name: 'Completed'}
            })
            this.TransactionDocumentDataLine_Items.map(lineitem => {
                db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection(this.$route.query.TransactionType+'Line_Items').doc(lineitem.id).update({
                    Progress: {ID: 1000005,Name: 'Completed'}
                })
            })
            
            let invtotal = this.CurrencyFormatter(this.TransactionDocumentData.Grand_Total,this.$store.state.DefaultCurrency.Currency)
            let lineslength = this.TransactionDocumentDataLine_Items.length
            let newnote = 'A Delivery for Your Order ('+record.id+') has been processed on '+this.$route.params.id+' for '+lineslength+' items at '+invtotal+'.'
            if(this.PaidinFull){
                newnote = newnote+' This is just a notice of a coming Delivery, all payments have been received in full'
            }
             let NewComms = {
                  description: newnote,
                  Created_On: new Date(),   
                  Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
                  Created_Byid: vm.userLoggedIn.id,
                  senderid: vm.userLoggedIn.id,
                  relatedid: record.id,
                  relatedtype: vm.CurrentEntity.DisplayName,
                  recordlocation: '/'+vm.CurrentEntity.SingleName.split(' ').join('')+'/'+record.id,
                  topicid: vm.CurrentEntity.id,
                }
                this.SaveNewClientcomms(NewComms,record)
        },
        GetShortLivedURL(item){
      //console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          //console.log(result)
         let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
        UpdateProgress(item,status){
            let AdvancingStage = 'Packing'
            if(this.$route.query.TransactionType === 'PackingLists' || this.TransactionTriggersDispatching){
                AdvancingStage = 'Dispatching'
            }
            //actually "NEXT STEP" because could be picking wiht next step being packing, and then picking list line update respond accordingly, capoihce?
            console.log(this.OrderEntity,this.OrderEntityInvoiceCycle,this.TransactionTriggersDispatching)
            item.Progress = status
            if(this.TransactionTriggersDispatching && status.Name === 'Completed'){
             this.LineItemDBBase.doc(item.id).update({
                Progress: status,
                Modified_Byid: this.userLoggedIn.id,
                Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},
                Ready_for_Dispatching: this.TransactionTriggersDispatching,
                AdvancingStage: AdvancingStage
            })   
            }
            else{
                this.LineItemDBBase.doc(item.id).update({
                Progress: status,
                Modified_Byid: this.userLoggedIn.id,
                Modified_By: {Full_Name: this.userLoggedIn.Full_Name,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,id: this.userLoggedIn.id},
                AdvancingStage: AdvancingStage
            })
            }
            
        },
        UpdateEditingProgress(itemobj){
            let item = this.TransactionDocumentDataLine_Items.find(obj => obj.id === itemobj.id)
            
            if(!item.EditingProgress){
                item.EditingProgress = true
            }
            else{
                item.EditingProgress = false
            }
            
            console.log(item)
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },  
      GetSalesOrder(probj){
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).colleciton('entities').doc(probj.LookupObj.list).collection('Records').doc(probj.LookupObj.id).onSnapshot(snapshot => {
                let sodata = snapshot.data()
                this.SalesOrder = sodata 
                this.SalesOrder.id = probj.LookupObj.id
                this.CheckClientRecord()
          })
      },
      CheckClientRecord(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('clientrecords').doc(this.SalesOrder.id).onSnapshot(snapshot => {
                    let clientrecordata = snapshot.data()
                    if(clientrecordata){
                      this.HasClientRecord = true
                    }
            })
      },
      GetSelectedBillingAccount(){
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.TransactionDocumentData.BillingAccountid).onSnapshot(snapshot => {
                this.SelectedBillingAccount = snapshot.data() 
                this.SelectedBillingAccount.id = this.TransactionDocumentData.BillingAccountid
          })
      } ,   
        GetTransactionalDocument(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection(this.$route.query.TransactionType).doc(this.$route.params.id).onSnapshot(snapshot => {
                this.TransactionDocumentData = snapshot.data() 
                if(this.$route.query && this.$route.query.TransactionType === 'DispatchInstructions'){
                    this.GetSelectedBillingAccount()
                    this.GetSalesOrder(this.TransactionDocumentData)
                }
                let query = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection(this.$route.query.TransactionType+'Line_Items').where('Transaction_Docid','==',this.$route.params.id)
                this.GetTransactionLineItems(query)
            })

        },
        GetTransactionLineItems(query){
            query.onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.TransactionDocumentDataLine_Items.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        
                        }
                        if (change.type === 'modified') {
                            let item = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        let match = this.TransactionDocumentDataLine_Items.find(obj => obj.id === item.id)
                        if(match){
                            let index = this.TransactionDocumentDataLine_Items.indexOf(match)
                            this.TransactionDocumentDataLine_Items.splice(index,1,item)
                        }                        
                        }
                    })                 
                    })
        }
    }
}
</script>

<style>

</style>
