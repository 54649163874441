<template>
   <v-card flat tile class="transparent">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
           <!-- :style="MobileView ? 'top:50px;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'" -->
       <v-list class="transparent" :style="MobileView ? 'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 4;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 4;'"
              dark>               
              <v-list-item v-for="sect in ActiveButtonMenuItems" :key="sect.itemObjKey" @click="ActivateButtonMenuSection(sect)" :class="sect.Class">          
                  <v-list-item-action>
                  <v-icon>{{sect.Icon}}</v-icon>
                  </v-list-item-action>          
                  <v-list-item-content>
                  {{sect.Name}}
                  </v-list-item-content>
              </v-list-item>
          </v-list>	
          <v-list v-if="ActiveButtonMenuSection.Name === 'Standard'">
             <v-card-subtitle class="background darken-1 overline" style="padding: 0px;margin-bottom:20px;text-align: center;">
            <v-icon>{{ActiveButtonMenuSection.Icon}}</v-icon> {{ActiveButtonMenuSection.Name}}
              <!-- (Fancy related) -->
          </v-card-subtitle>
           <v-text-field v-model="EditedTab.ActionButtonName" label="Display"/>		
                        							
                        <v-select v-model="EditedTab.ActionButtonType" label="Type" :items="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Row Single' ? ['Route','URL','Submission','Add Cart Item','Web Form Dialog','Content Dialog','File Download'] : ['Route','URL','Submission','Add Cart Item']"/>
                        <v-select v-if="EditedTab.ActionButtonType === 'Content Dialog'"
                        @change="CheckifContentDialog(EditedTab.ActionButtonType,EditedTab)" v-model="EditedTab.ContentDialogType" return-object item-text="Name" label="Type" :items="ContentBlockTypes"/>
                        <v-select  clearable v-if="EditedTab.ActionButtonType === 'Web Form Dialog'" @change="Twopropemit('AddEntityFields',EditedTab.RelatedEntity,EditedTab)" v-model="EditedTab.RelatedEntity" label="Entity" :items="WebFormEntities" item-text="id"/>
                         <v-text-field v-model="EditedTab.FormName" v-if="EditedTab.ActionButtonType === 'Web Form Dialog' && EditedTab.RelatedEntity" :rules="[$store.state.formrules.required]" label="Form Name"/>
                         <v-text-field v-model="EditedTab.FormEmailRecipient"  v-if="EditedTab.ActionButtonType === 'Web Form Dialog' && EditedTab.RelatedEntity" :rules="[$store.state.formrules.email]" label="Recipient Email"/>
                         <v-switch v-if="EditedTab.ActionButtonType === 'Web Form Dialog' && EditedTab.RelatedEntity"  v-model="EditedTab.DarkForm" label="Dark Form"/>		
                         <v-btn v-if="EditedTab.ActionButtonType === 'Web Form Dialog' && EditedTab.RelatedEntity" @click="NoPropsemit('FormFieldsDialogActivate')">									
                            Fields									
                        </v-btn>
                        <v-text-field v-if="EditedTab.ActionButtonType === 'URL'" v-model="EditedTab.ActionButtonRoute" label="URL"/>	
                        <!-- <v-autocomplete v-if="EditedTab.ActionButtonType === 'Route'" item-text="path" :items="SystemRoutes" v-model="EditedTab.ActionButtonRoute" label="Route"/>									 -->
                        <v-text-field v-if="EditedTab.ActionButtonType === 'Route'" v-model="EditedTab.ActionButtonRoute" label="URL"/>	
                        <span v-if="EditedTab.ActionButtonType === 'File Download'">
                                <input :id="'DonwloadFileBtn'"
                                @change="UploadDownloadFileSelect($event,EditedTab)" style="visibility: hidden;display: none;"
                            type="file">
                            <label :for="'DonwloadFileBtn'"><span v-if="EditedTab.DownloadFile" style="--col: #04AA6D"  class="texbtn btnhover elevation-1">
                            <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Change File
                            </span><span style="--col: #04AA6D"  v-if="!EditedTab.DownloadFile" class="texbtn btnhover elevation-1">
                                <v-icon style="--col: #04AA6D"  class="btnhover" small>mdi-file</v-icon>Select File
                            </span>
                            </label>
                            <span v-if="EditedTab.DownloadFile">
                            {{EditedTab.DownloadFile.name}}
                            </span>
                            <v-btn :href="FilelocalURL" target="_blank" v-if="selectedFile" @click="OpenTempDoc(selectedFile)" class="orange white--text" small>View Download ile</v-btn>
                            <v-btn v-if="selectedFile" @click="SaveDownloadFile(selectedFile,'DownloadFile')" class="green white--text"  small>Save</v-btn>
                            </span>
          </v-list>
          <v-list v-if="ActiveButtonMenuSection.Name === 'Styling'">
             <v-card-subtitle class="background darken-1 overline" style="padding: 0px;margin-bottom:20px;text-align: center;">
            <v-icon>{{ActiveButtonMenuSection.Icon}}</v-icon> {{ActiveButtonMenuSection.Name}}
              <!-- (Fancy related) -->
          </v-card-subtitle>
           <v-select label="Button Alignment" v-model="EditedTab.ButtonAlignment" :items="['justify-start','justify-center','justify-end']"/>
           <v-switch v-model="EditedTab.Fancy" label="Fancy"/>	
                           <v-switch v-model="EditedTab.Outlined" label="Outlined"/>									
                       
                         
                        <v-switch v-model="EditedTab.ActionBtnDark" label="Dark Button"/>									
                        <v-menu     									
                                    :close-on-content-click="false"									
                                    :nudge-right="40"									
                                    transition="scale-transition"									
                                    offset-y									
                                    min-width="200px"									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                <v-icon v-on="on">mdi-palette-advanced</v-icon>									
                                    </template>									
                                <v-color-picker v-model="EditedTab.ButonColor"></v-color-picker>									
                                </v-menu>	
          </v-list>
        <v-card-text>
           				
        </v-card-text>
        </v-card>
</template>





<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            ActiveButtonMenuSection: {Name: 'Standard'},
            UploadingFile: false,
            UploadingFileProgress: 0,
            FilelocalURL: '',
            selectedFile: '',
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        ActiveButtonMenuItems(){
                return [
                    {Icon: 'mdi-gesture-tap-button',Name: 'Standard',Class: 'grey'},
                    {Icon: 'mdi-palette-advanced',Name: 'Styling',Class: 'grey'},
                ]
            },
        ContentBlockTypes(){
            return [{Name: 'HTML Box',ComponentName: 'HTMLBox'},{Name: 'Gallery',ComponentName: 'GalleryTab'}]
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
    },
    created(){

    },
    methods:{
        ActivateButtonMenuSection(sect){
            this.ActiveButtonMenuSection = sect
        },
        SaveDownloadFile(file,prop){
            let vm = this
        let filename = file.name
        let storepath = ''
        //this.Basestorageref+'/'+fieldname+'/'+filename+'/'+new Date()
        if(this.$route.name === 'PageEditor'){
            storepath = 'SiteDocuments/'+this.$route.params.id+'/'+new Date()+'/'+filename
        }
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$store.commit('SetSocialItemInteractMethodProp',true) 
        vm.$store.commit('SetSocialItemInteractMethod','ActivateUploadingSingleFileNotification')
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+filename);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$store.commit('SetSocialItemInteractMethodProp',false) 
                vm.$store.commit('SetSocialItemInteractMethod','ActivateUploadingSingleFileNotification')
                //vm.UploadingFile = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
              });
            });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            vm.EditedTab.DownloadFile = UploadObject
              
            //console.log('UploadObject',UploadObject,vm.EditedTab)
              vm.CancelDownloadFileSelect()
              vm.$emit('ActivateUploadingSingleFileNotification',false)
          })
          }, 1000);
        },
        OpenTempDoc(file){
            //console.log(file)
            let tempurl = URL.createObjectURL(file)
            //console.log(tempurl)
            window.open(tempurl, '_blank')
        },
        UploadDownloadFileSelect(event,tab){
            this.selectedFile = event.target.files[0]
            this.selectedFile.FileModifiedDate = new Date(this.selectedFile.lastModified)
            this.FilelocalURL = URL.createObjectURL(this.selectedFile)
        },
        CancelDownloadFileSelect(){
            this.FilelocalURL = ''
            this.selectedFile = ''
        },
        CheckifContentDialog(Type,EditedTab){
            if(Type === 'Content Dialog'){
                this.Onepropemit('AddContentDialog',EditedTab)
            }
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
