<template>

<v-main class="grey lighten-3 fill-height">

    <v-dialog max-width="800" v-model="LeadConverterTest">
        <template v-slot:activator="{ on }">
        <v-btn class="blue--text" v-on="on">
            <v-icon color="blue"></v-icon>Dummy Leads
            </v-btn>
        </template>
        <v-card max-width="800" tile flat>
            <v-card-title>
                Conversions
            </v-card-title>
            <v-card-text>
                <v-tabs centered>
                    <v-tab>
                        Unconverted
                    </v-tab>
                    <v-tab-item>
                        <v-data-table
                        :items="UnconvertedPeople"
                        :headers="UnconvertedPeopleHeaders"
                        single-expand
                        show-expand  
                        >
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding-top:10px;padding-bottom:10px;">
                            <tr>
                                <th align="left">
                                    Likes:
                                </th>
                                <td align="right">
3
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Comments:
                                </th>
                                <td align="right">
5
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Shares:
                                </th>
                                <td align="right">
0
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Messages:
                                </th>
                                <td align="right">
11
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Total Engagements:
                                </th>
                                <td align="right">
19
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Last Engagement Date:
                                </th>
                                <td align="right">
                                    {{item.LastEngagement}}
                                </td>
                            </tr>
                            <tr>
                                <v-btn>Convert</v-btn>
                            </tr>
                            </td>
                        </template>    
                        </v-data-table>
                        <v-list>
                            <v-list-item  v-for="person in UnconvertedPeople" :key="person.itemObjKey">
                                <v-list-item-content>
                                     {{person.Name}}
                                </v-list-item-content>
                                <v-list-item-content>
                                    {{person.MessagesCount}}
                                </v-list-item-content>
                                <v-list-item-content>
                                    {{person.LastMessage}}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                    <v-tab>
                        Converted People
                    </v-tab>
                    <v-tab-item>
                        <v-list>
                            <v-list-item  v-for="person in ConvertedPeople" :key="person.itemObjKey">
                                {{person}}
                            </v-list-item>
                        </v-list>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-toolbar color="pink" height="150">        
        <v-list dense v-if="UserLinkedInProfile" max-width="300" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
        <v-list-item-subtitle class="overline">
            Linked In Session
        </v-list-item-subtitle>
            <v-list-item>
            <!-- <v-list-item-content > -->
            <v-list-item-avatar class="blue" tile size="80">
                <img v-if="UserLinkedInProfile.Profile_Photo "
                :src="UserLinkedInProfile.Profile_Photo "    
                style="object-fit: cover;"          
                >
                <img v-if="!UserLinkedInProfile.Profile_Photo "
                src="/assets/BlankProfile_Photo.png"   
                style="object-fit: cover;"           
                >                      
                </v-list-item-avatar>
            <!-- </v-list-item-content> -->
            <v-list-item-content >
                <v-list-item-title>{{UserLinkedInProfile.Full_Name}}</v-list-item-title>                    
                <v-list-item-subtitle>
                <router-link  :to="'//www.linkedin.com'">View My Profile</router-link>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{UserLinkedInProfile.Email}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                Online: Online Now
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list dense v-if="!UserLinkedInProfile">
            <v-list-item-subtitle>
            Linked In
        </v-list-item-subtitle>
        <a :href="ComputedLinkedInSignup">
            <img src="@/assets/LinkedInSignin.png"/>
        </a>
        </v-list>
        <v-list dense v-if="FacebookUser" max-width="300" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG-Soft.png') + ')' ,backgroundSize: 'cover'}">
        <v-list-item-subtitle class="overline">
            Facebook Session
        </v-list-item-subtitle>
            <v-list-item>
                
            <!-- <v-list-item-content > -->
            <v-list-item-avatar class="blue" tile size="80">
                <img v-if="FacebookUser.photoURL "
                :src="FacebookUser.photoURL "    
                style="object-fit: cover;"          
                >
                <img v-if="!FacebookUser.photoURL "
                src="/assets/BlankProfile_Photo.png"   
                style="object-fit: cover;"           
                >                      
                </v-list-item-avatar>
            <!-- </v-list-item-content> -->
            <v-list-item-content >
                <v-list-item-title>{{FacebookUser.displayName}}</v-list-item-title>                    
                <v-list-item-subtitle>
                <router-link  :to="'//Facebook'">View My Profile</router-link>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{FacebookUser.email}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                Online: Online Now
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-btn @click="SignIn()">Load Data</v-btn>
            </v-list-item>
        </v-list>
        <v-list dense v-if="!FacebookUser">
            <v-list-item-subtitle>
            Facebook
        </v-list-item-subtitle>
        <v-btn @click="SignIn()">Load Data</v-btn>
        </v-list>
    </v-toolbar>
  


<v-row class="justify-center" style="padding-top:50px;">
         <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" v-for="page in ReadyPagesArray" :key="page.itemObjKey" >
           <v-card class="mx-4"  round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                <v-card-title class="justify-center" style="align:center;">
            <v-avatar tile size="70" class="transparent mx-3" >
                <v-img v-if="page.picture" contain :src="page.picture"/>
            </v-avatar>
                </v-card-title>
            </v-card>
            <v-card rounded >
       
                <v-card-title class="primary white--text justify-end overline">
                    <v-list dense dark class="transparent white--text justify-end">
                        <v-list-item class="overline">
                            {{page.name}}
                        </v-list-item>
                        <v-list-item >
                            <v-select v-model="page.Brand" item-text="Name" :items="SocialBrands" return-object label="Brand"/>
                            
                        </v-list-item>
                    </v-list>
                     
                </v-card-title>
                 <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                   {{page.fan_count}}  Followers                   
                </v-card-title>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.newlikecolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-thumb-up-outline
                            </v-icon>
                            <span slot="badge"> {{page.new_like_count}} </span>
                            </v-badge> New Likes                   
                </v-card-subtitle>
                <v-card-subtitle class="subtleoverline justify-end">
                    <v-badge :color="page.unreadnotifycolor" overlap
                              >
                              <v-icon
                              color="blue"
                              v-on="on">
                              mdi-bell-check
                            </v-icon>
                            <span slot="badge"> {{page.unread_notif_count}} </span>
                            </v-badge> Unread Notifications                   
                </v-card-subtitle>
                <v-divider>
                </v-divider>
                <v-card-actions>
                    <v-btn  @click="StorePageUpdate(page)" class="blue--text" v-on="on">
                            <v-icon color="green">mdi-content-save</v-icon>Save
                            </v-btn>
                    <v-dialog max-width="800">
                        <template v-slot:activator="{ on }">
                        <v-btn class="blue--text" v-on="on">
                            <v-icon color="blue">{{page.providericon}}</v-icon>Leads
                            </v-btn>
                        </template>
                        <v-card max-width="800" tile flat>
                            <v-card-title>
                                Leads
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                        :items="page.socialleads"
                        :headers="UnconvertedPeopleHeaders"
                        single-expand
                        show-expand  
                        >
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding-top:10px;padding-bottom:10px;">
                            <tr>
                                <th align="left">
                                    Likes:
                                </th>
                                <td align="right">
3
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Comments:
                                </th>
                                <td align="right">
5
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Shares:
                                </th>
                                <td align="right">
0
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Messages:
                                </th>
                                <td align="right">
11
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Total Engagements:
                                </th>
                                <td align="right">
19
                                </td>
                            </tr>
                            <tr>
                                <th align="left">
                                    Last Engagement Date:
                                </th>
                                <td align="right">
                                    {{item.name}}
                                </td>
                            </tr>
                            <tr>
                                <v-dialog max-width="800" persistent v-model="SocialBrandLeadDialog">
                                    <template v-slot:activator="{ on }">
                                    <v-btn v-if="!item.SocialBrandLead && page.Brand" class="blue--text" v-on="on">
                                        Convert</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>Convert {{item.name}} to Social Brand Lead
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field v-model="NewSocialBrandLeadName" :rules="[$store.state.formrules.required]" label="Name"/>
                                            <v-text-field v-model="NewSocialBrandLeadSurname" :rules="[$store.state.formrules.required]" label="Surname"/>
                                            <v-text-field v-model="NewSocialBrandLeadEmail"  :rules="[$store.state.formrules.email]" label="Email"/>
                                            <v-text-field v-model="NewSocialBrandLeadMobile" :rules="[$store.state.formrules.telnr]" label="Mobile Number"/>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn @click="CancelNewSocialBrandLead()">Cancel</v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="CreateNewSocialBrandLead(page,item)">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </tr>
                            </td>
                        </template>    
                        </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-spacer>
                    </v-spacer>
               
                         <v-btn class="blue--text" target="_blank" :to="page.location">
                           <v-icon color="blue">{{page.providericon}}</v-icon>View Page
                         </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
      </v-row>
</v-main>
</template>

<script>
import firebase from 'firebase';
import axios from 'axios'
import db from '@/main'
import vueappfbid from '@/main' 
import FB from 'fb'; 

export default {
    data(){
        return{
            SocialBrandLeadDialog: false,
            rules: {
          numberfield: value => {
            const pattern = /^[0-9]+$/im;
            return pattern.test(value) || "Not a valid number.";
          },
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            NewSocialBrandLeadName: '',
            NewSocialBrandLeadSurname: '',
            NewSocialBrandLeadEmail: '',
            NewSocialBrandLeadMobile: '',
            SocialBrands: [],
            SocialBrandLeads: [],
            UnconvertedPeople: [
                {Name: 'Rudi Le Roux',EngagementsCount: 5,LastEngagement: new Date()}
            ],
            ConvertedPeople: [
                {Name: 'Jacueline Louw'}
            ],
            UnconvertedPeopleHeaders: [
                { text: 'Name', value: 'name',class: "overline"},
                { text: 'Engagements', value: 'EngagementsCount',class: "overline"},
                { text: 'LastEngagement', value: 'LastEngagement',class: "overline"},
                { text: 'Page Moderator', value: 'PageModerator',class: "overline"},                
                { text: 'Actions', value: 'actions', sortable: false ,class: "overline"},
            ],
            LeadConverterTest: false,
            Today: '',
            LinkedInOrganizations: [],
            UserLinkedInProfile: '',
            fooApp: '',
            FollowersCount: '',
            FBprovider: '',
            FacebookUser: '',
            AllPagesArray: [],
            // FacebookPagesArray: [],
            LinkedInTokenString: "AQSpYr_wv621WatJ-SoMfQXzN6s5rbmf0S42bQHP3Jd9YeCpDjAgMLLT2agG2xLsQd1IsrD-nAUIKl4kCeJZpSUn9JIKvyVECgKWgkA48_JIV-SxIs3uATZwsl4e8CCXLrb4AVf2ttdQ1s4HHuBZxMmoCkQHk_dnamRUvsxaNhXzf-USmr0K4a-YwuVYm2IFBDSpfllF7Tv9smrLILI"
        }
    },
    computed:{
        ComputedLinkedInSignup(){
            let protocol = window.location.protocol.split(':')[0]
            let domain = window.location.hostname
            console.log(protocol)
             if(window.location.port){
                 domain = domain+':'+window.location.port
             } 
            return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=r_liteprofile%20r_emailaddress%20r_organization_social%20r_1st_connections_size%20rw_organization_admin%20r_basicprofile%20r_ads%20rw_ads%20w_member_social%20w_organization_social%20r_ads_reporting&client_id="+process.env.VUE_APP_LINKEDIN_CLIENT_ID+"&redirect_uri="+protocol+"%3A%2F%2F"+domain+"%2FLinkedInAuth"
        },
        ReadyPagesArray(){
            let arr1 = this.AllPagesArray
            let array2 = this.LinkedInOrganizations
            .map(item => {
                let obj = {
                    name: item.localizedName,
                    picture: item.picture,
                    fan_count: item.fan_count,
                    newlikecolor: 'red',
                    new_like_count: 20,
                    unreadnotifycolor: 'green',
                    unread_notif_count: 16,
                    providericon: 'mdi-linkedin ',
                    provider: 'LinkedIn',
                    location: '//www.linkedin.com/company/'+item.id+'/admin/'

                }
                return obj
            })
            return arr1.concat(array2)
        },
    },
    created(){
        this.GetSocialBrandLeads()
        this.GetSocialBrands()
        this.GetStoredPages()
        //Linkedin works like this
        //ComputedURL determines the path to follow to get Autchode and trasnfers back to LinkedinAuth 
        //LinkedinAuth component transcodes this Authcode and assign to user store then transfers back here
        //This comp checks "oncreate" if auth code/ acces token exist,
        //if NOT access token then request access token using this authcode,
        //IF access token, otherwise get GetMyLinkedInProfile
        //the "nice" part about linkedin method is that it calls everything using same method, being "GetLinkedInInformation"
        //So to change what we call we must work on the "GetLinkedInInformation" method
        //One mention, is that the GetLinkedInInformation is a cloud function. 
        this.Today = new Date()
        console.log(this.$store.state.user)
        this.$store.state.user.getIdToken().then(token => {
            if(this.$store.state.user.LinkedInToken){
                this.LinkedInTokenString = this.$store.state.user.LinkedInToken
                console.log(this.LinkedInTokenString)
                console.log(this.$store.state.user.LinkedInAccessToken)
                if(!this.$store.state.user.LinkedInAccessToken){
                    this.GetLinkedInAccess()
                }
                else{
                    //console.log('already has token here '+this.$store.state.user.LinkedInAccessToken)
                    this.GetMyLinkedInProfile(this.$store.state.user.LinkedInAccessToken)
                }
                
            }
              
            let urldomain = window.location.protocol+'//'+window.location.hostname
            if(window.location.port){
                urldomain = urldomain+':'+window.location.port
            }
            console.log(urldomain)
        })
       
        this.FBprovider = new firebase.auth.FacebookAuthProvider();
        this.fooApp = FB.extend({appId: process.env.VUE_APP_FACEBOOK_APP_ID, appSecret: process.env.VUE_APP_FACEBOOK_APP_SECRET})
       this.FacebookUser = this.$store.state.user.providerData.find(obj => obj.providerId === 'facebook.com')
            console.log(this.$store.state.user) 
            if(this.$store.state.user.FacebookAccessToken){
                this.fooApp.setAccessToken(this.$store.state.user.FacebookAccessToken)
                this.GetFollowers()
            } 
        console.log('vueappfbid',typeof process.env.VUE_APP_FACEBOOK_APP_SECRET)
    },
    methods:{
        CreateNewSocialBrandLead(page,item){
            console.log('brandlead',page,item)
            let providers = [
                {
                    Name: 'Facebook',
                    ID: 1000001
                },
                {
                    Name: 'LinkedIn',
                    ID: 1000002
                }
            ]
            let providerobj = providers.find(obj => obj.Name === page.provider)
            let newbrandlead = {
                Name: this.NewSocialBrandLeadName,
                Surname: this.NewSocialBrandLeadSurname,
                Full_Name: this.NewSocialBrandLeadName+' '+this.NewSocialBrandLeadSurname,
                Email: this.NewSocialBrandLeadEmail,
                Pages: [{Name: page.name,id: page.id}],
                Brand: page.Brand,
                Brandid: page.Brandid,
                Providers: [providerobj],
                PageLeads: [{name: item.name,id: item.id}] 
            }
            db.collection('socialbrandleads').add(newbrandlead).then(doc => {
                console.log('created new social brand lead '+doc.id)
                db.collection('socialpages').doc(page.id).collection('socialleads').doc(item.id).update({
                    SocialBrandLeadid: doc.id,
                    SocialBrandLead: {Full_Name: newbrandlead.Full_Name,id: doc.id}
                })
            })
            this.CancelNewSocialBrandLead()
        },
        CancelNewSocialBrandLead(){
            this.SocialBrandLeadDialog = false
            this.NewSocialBrandLeadName = ''
            this.NewSocialBrandLeadSurname = ''
            this.NewSocialBrandLeadEmail = ''
            this.NewSocialBrandLeadMobile = ''
        },
        GetSocialBrandLeads(){
            db.collection('socialbrandleads').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                       this.SocialBrandLeads.push({
                           id: change.doc.id,
                           ...change.doc.data()
                       }) 
                    }
                })
            })
        },
        GetSocialBrands(){
            db.collection('socialbrands').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                       this.SocialBrands.push({
                           id: change.doc.id,
                           ...change.doc.data()
                       }) 
                    }
                })
            })
        },
        StorePageUpdate(page){
            console.log('storing page')
            let pageobj = Object.assign({},page)
            delete pageobj.access_token
            let conversations = []
            if(page.conversations){
                conversations = page.conversations
            }
            let posts = []
            if(page.posts){
                posts = page.posts
            }
            let socialleads = []
            if(page.socialleads){
                socialleads = page.socialleads
            }
            delete pageobj.conversations
            delete pageobj.posts
            delete pageobj.socialleads
            if(pageobj.Brand){
                pageobj.Brandid = pageobj.Brand.id
            }
            db.collection('socialpages').doc(pageobj.id).set(pageobj).then(doc => {
                console.log('stored page ',pageobj.id)
                conversations.map(conversation => {
                    db.collection('socialpages').doc(pageobj.id).collection('conversations').doc(conversation.id).set(conversation).then(condoc => {
                        console.log('stored conversation')
                    })
                })
                posts.map(post => {
                    db.collection('socialpages').doc(pageobj.id).collection('posts').doc(post.id).set(post).then(postdoc => {
                        console.log('stored post')
                    })
                })
                socialleads.map(lead => {
                    db.collection('socialpages').doc(pageobj.id).collection('socialleads').doc(lead.id).set(lead).then(leaddoc => {
                        console.log('stored leaddoc')
                    })
                })
            })
        },
        GetStoredPages(){
            db.collection('socialpages').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                        let page = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        page.conversations = []
                        page.posts = []
                        page.socialleads = []
                        let oncheck = this.AllPagesArray.find(obj => obj.id === page.id)
                        if(!oncheck){
                        this.AllPagesArray.push(page)
                        db.collection('socialpages').doc(page.id).collection('conversations').onSnapshot(conversres => {
                            if (change.type === 'added') {
                             const conchanges = conversres.docChanges();
                             conchanges.forEach(conchange => {
                                if (conchange.type === 'added') {
                                    let convobj = {
                                        ...conchange.doc.data(),
                                        id: conchange.doc.id
                                    }
                                    page.conversations.push(convobj)
                                }
                             })
                            }
                        })
                        db.collection('socialpages').doc(page.id).collection('posts').onSnapshot(postres => {
                            if (change.type === 'added') {
                             const posthanges = postres.docChanges();
                             posthanges.forEach(posthange => {
                                if (posthange.type === 'added') {
                                    let convobj = {
                                        ...posthange.doc.data(),
                                        id: posthange.doc.id
                                    }
                                    page.posts.push(convobj)
                                }
                             })
                            }
                        })
                        db.collection('socialpages').doc(page.id).collection('socialleads').onSnapshot(leadres => {
                            if (change.type === 'added') {
                             const leadchanges = leadres.docChanges();
                             leadchanges.forEach(leadchange => {
                                if (leadchange.type === 'added') {
                                    let leadobj = {
                                        ...leadchange.doc.data(),
                                        id: leadchange.doc.id
                                    }
                                    page.socialleads.push(leadobj)
                                }
                             })
                            }
                        })
                        }
                        else{

                        }
                    }
                })
                })
            
        },
        
        GetMyLinkedInProfile(token){
            let vm = this
            let days = 7
            let end = new Date(Date.now() - (1 * 24*60*60*1000));
            let startdate = new Date(Date.now() - (days * 24*60*60*1000));
            let start = startdate.getTime()
            console.log(start)  
            let emailobj = {
                  url: 'https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))',
                  code: token
              }            
            let profileobj = {
                  url: 'https://api.linkedin.com/v2/me',
                  code: token
              }
            let pictureobj = {
                  url: 'https://api.linkedin.com/v2/me?projection=(id,profilePicture(displayImage~digitalmediaAsset:playableStreams))',
                  code: token
              }
            let organizationsobj = {
                  url: 'https://api.linkedin.com/v2/organizationAcls?q=roleAssignee&role=ADMINISTRATOR&state=APPROVED&projection=(*,elements*(*,organization~(localizedName),organization~(logoV2),organization~(id),organization~(vanityName),organization~(primaryOrganizationType),organization~(localizedWebsite)))',
                  code: token
              }
            const functions = firebase.functions();
            const GetLinkedInInformation = functions.httpsCallable('GetLinkedInInformation');
           
            console.log('access token from store',token)            
            GetLinkedInInformation(profileobj).then(result => {
              //console.log('Final Result from cloud function',result)
              //console.log('Now going to try get the emadil')
              
              GetLinkedInInformation(emailobj).then(emailresult => {
              //console.log('email Result from cloud function',emailresult)
              //console.log('proper email is '+emailresult.data.elements[0]['handle~'].emailAddress)
              vm.UserLinkedInProfile = {
                  Name: result.data.localizedFirstName,
                  Surname: result.data.localizedLastName,
                  Email: emailresult.data.elements[0]['handle~'].emailAddress,
                  Full_Name: result.data.localizedFirstName+' '+result.data.localizedLastName
              }
              if(result.data.profilePicture){
                  GetLinkedInInformation(pictureobj).then(profpicresult => {
                      //console.log(profpicresult)
                      if(profpicresult && profpicresult.data){
                        let prop = profpicresult.data.profilePicture['displayImage~']
                        let index = prop.elements.length-1
                        let image = prop.elements[index].identifiers[0].identifier
                        //console.log(prop)
                        //console.log(image)
                        if(prop && prop.elements[index] && prop.elements[index].identifiers[0] && prop.elements[index].identifiers[0].identifier){
                            vm.UserLinkedInProfile.Profile_Photo = image
                            //console.log(vm.UserLinkedInProfile)
                        }   
                      }
                      
                  })                
              }
              

               GetLinkedInInformation(organizationsobj).then(organizationsresult => {
                //yconsole.log('sorganizationsresult',organizationsresult)
                
                let array = organizationsresult.data.elements
                //console.log(typeof array,array.length)
                //console.log(typeof array)                
                 array.map(elmnt => {
                     //console.log(elmnt['organization~'])
                    let obj = elmnt['organization~']
                    let logobj = {
                        url: 'https://api.linkedin.com/v2/organizations/'+obj.id+'?projection=(coverPhotoV2(original~:playableStreams,cropped~:playableStreams,cropInfo),logoV2(original~:playableStreams,cropped~:playableStreams,cropInfo))',
                        code: token
                    }
                    GetLinkedInInformation(logobj).then(orglogoresult => {
                        //console.log('orglogoresult',orglogoresult)
                        if(orglogoresult.data.logoV2){
                        let prop = orglogoresult.data.logoV2['original~']
                        let index = prop.elements.length-1
                        let image = prop.elements[index].identifiers[0].identifier
                        //console.log(prop)
                        //console.log(image)
                        obj.picture = image
                        }
                        let followersobj = {
                        url: 'https://api.linkedin.com/v2/networkSizes/urn:li:organization:'+obj.id+'?edgeType=CompanyFollowedByMember',
                        code: token
                        }
                        GetLinkedInInformation(followersobj).then(followersresult => {
                            //console.log('followersresult',followersresult)
                            obj.fan_count = followersresult.data.firstDegreeSize
                            // let date = new Date()   
                            
                                                  
                            let followerstatsobj = {
                                //https://api.linkedin.com/v2/organizationalEntityShareStatistics?q=organizationalEntity&organizationalEntity={organization URN}
                            //url: 'https://api.linkedin.com/v2/organizationalEntityFollowerStatistics?q=organizationalEntity&organizationalEntity=urn:li:organization:'+obj.id+'&timeIntervals=(timeRange:(start:'+start+',end:'+end+'),timeGranularityType:DAY)',
                            url: 'https://api.linkedin.com/v2/organizationalEntityFollowerStatistics?q=organizationalEntity&organizationalEntity=urn:li:organization:'+obj.id,
                            code: token
                        }
                        GetLinkedInInformation(followerstatsobj).then(followerstatsresult => {
                            console.log('followerstatsresult',followerstatsresult)
                            obj.followerbyclass = followerstatsresult.data.elements[0]
                            obj.providericon = 'mdi-linkedin'
                            obj.provider = 'LinkedIn',
                            obj.location = '//www.linkedin.com/company/'+obj.id+'/admin/'
                            //so we need to get Linkedin call methods for these arrays
                            //The real question is, how much of these are avialable throuhg Linkedin strict api?

                            obj.socialleads = []
                            //social leads it the computed result of the others
                            obj.socialadmins = []
                            //socialadmins may be a call, if linkedinapi offers to return page admins or page moderators, but rememebr array of "roles"
                            obj.conversations = []
                            //Pretty sure LinkedIn also has conversations,a nd within conversations has messages
                            //if so then we get this because we want conversations of the pages, then call messages, then per message get "from" person which we push to social leads
                            obj.posts = []
                            //the idea here is to call the posts, then call comments and likes and for each map, push the "from"
                            
                            obj.name = obj.localizedName
                            obj.newlikecolor = 'red',
                            obj.new_like_count = 20,
                            obj.unreadnotifycolor = 'green',
                            obj.unread_notif_count = 16,
                            vm.LinkedInOrganizations.push(obj)
                        })
                        })
                    })
                })   
                
               })
              })
            })
        },
        async GetLinkedInAccess(){
            const functions = firebase.functions();
            const GetLinkedInAccessToken = functions.httpsCallable('GetLinkedInAccessToken');
            // 'https://nene-motors.firebaseapp.com/LinkedInAuth'
            let urldomain = window.location.protocol+'//'+window.location.hostname
            if(window.location.port){
                urldomain = urldomain+':'+window.location.port
            }
            console.log(urldomain)
            let callobj = {
                code: this.LinkedInTokenString,
                state: 987654321,
                redirectUri: urldomain+'/LinkedInAuth',
                }
            console.log(callobj)
            GetLinkedInAccessToken(callobj).then(result => {
              console.log(result)
              if(result.data.access_token){
               let user = this.$store.state.user
                user.LinkedInAccessToken = result.data.access_token
                this.$store.commit('SetUserStateObj', user) 
                console.log('user',user)  
                this.GetMyLinkedInProfile(user.LinkedInAccessToken)
              }
            
            })
       
          
        },
        
        
    SignIn(){
        let vm = this
        firebase
        .auth().currentUser.linkWithPopup(this.FBprovider).then((result) => {
            // Accounts successfully linked.
            var credential = result.credential;
            var user = result.user;
            // ...
            let stateuser = vm.$store.state.user
            stateuser.FacebookAccessToken = credential.accessToken
            vm.$store.commit('SetUserStateObj', stateuser) 
            console.log(user,credential)
            vm.fooApp.setAccessToken(credential.accessToken)            
            }, function(error) {
                console.log(error)
                if(error.code === "auth/provider-already-linked"){
                    
                    //alert(error.message)
                    let storeuser = vm.$store.state.user
                    // console.log()
                    vm.FacebookUser = storeuser.providerData.find(obj => obj.providerId === 'facebook.com')
                    console.log(vm.FacebookUser)                    
                    firebase
                    .auth()
                     .signInWithPopup(vm.FBprovider)
                        .then((result) => {
                            /** @type {firebase.auth.OAuthCredential} */
                            var credential = result.credential;

                            // The signed-in user info.
                            var user = result.user;
                            console.log(user)

                            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                            var accessToken = credential.accessToken;
                            console.log(accessToken)
                            let stateuser = vm.$store.state.user
                            stateuser.FacebookAccessToken = accessToken
                            vm.$store.commit('SetUserStateObj', stateuser) 
                            //console.log(user,credential)
                            vm.fooApp.setAccessToken(accessToken)
                            vm.GetFollowers()

                            // ...
                        })
                        .catch((error) => {
                            console.log(error)
                            // Handle Errors here.
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            // The email of the user's account used.
                            var email = error.email;
                            // The firebase.auth.AuthCredential type that was used.
                            var credential = error.credential;

                            // ...
                        });
                }
                // An error happened.
                });
        
    },
    Unlink(){
        firebase
        .auth().currentUser.unlink('facebook.com').then(() => {
        // Auth provider unlinked from account
        // ...
        }).catch((error) => {
        // An error happened
        // ...
        });
    },
        GetFollowers(){ 
            let vm = this
            //this.SignIn()
            console.log('vm.FacebookUser',vm.FacebookUser)
            let storeuser = vm.$store.state.user
                    // console.log()
                    vm.FacebookUser = storeuser.providerData.find(obj => obj.providerId === 'facebook.com')
                    if(vm.FacebookUser){
                        console.log(vm.FacebookUser)
         
                        vm.fooApp.api('/'+vm.FacebookUser.uid+'/accounts?', 
                            'GET',
                        {"fields":"name,access_token,id,picture,new_like_count,fan_count"
                        }, function (newres) {
                            if(!newres || newres.error) {
                                console.log(!newres ? 'error occurred' : newres.error);
                                return;
                            }
                        
                            //var finalaccessToken = newres.access_token;
                            // vm.fooApp.setAccessToken(accessToken)
                            console.log(newres)
                            newres.data.map(page => {
                                page.providericon = 'mdi-facebook'
                                page.provider = 'Facebook',
                                page.location = '//www.facebook.com/'+page.name+'-'+page.id
                                page.picture = page.picture.data.url
                                page.socialleads = []
                                page.socialadmins = []
                                page.conversations = []
                                page.posts = []
                                //and within posts are comments and likes in FB structure

                                if(page.new_like_count && page.new_like_count>0){
                                    page.newlikecolor = 'pop'
                                }
                                else{
                                    page.newlikecolor = 'grey lighten-3'
                                }
                                let newfbapp = FB.extend({appId: process.env.VUE_APP_FACEBOOK_APP_ID, appSecret: process.env.VUE_APP_FACEBOOK_APP_SECRET})
                                newfbapp.setAccessToken(page.access_token)
                                newfbapp.api(
                                '/'+page.id,
                                'GET',
                                {"fields":"conversations,unread_notif_count,roles",accessToken:page.access_token},
                                function(response) {
                                    if(response.roles){
                                        page.socialadmins = response.roles.data
                                    }
                                    console.log('response',response)
                                    if(response.conversations){
                                      page.conversations = response.conversations.data  
                                    }                                 
                                    page.unread_notif_count = response.unread_notif_count
                                    if(page.unread_notif_count && page.unread_notif_count>0){
                                    page.unreadnotifycolor = 'pop'
                                        }
                                    else{
                                        page.unreadnotifycolor = 'grey lighten-3'
                                    }
                                    console.log(page.conversations)
                                    if(page.conversations){
                                        console.log('seeing conversations',page.conversations.length)
                                    page.conversations.map(convers => {
                                          newfbapp.api(
                                                "/"+convers.id+"/messages",
                                                'GET',
                                                {"fields":"from",accessToken:page.access_token},
                                                function (conmsgresponse) {
                                                    if (conmsgresponse && !conmsgresponse.error) {
                                                        /* handle the result */
                                                        console.log(conmsgresponse)
                                                        convers.Messages = conmsgresponse.data
                                                        convers.Messages.map(msg => {
                                                            if(msg.from && msg.from.id){
                                                                let leadoncheck = page.socialleads.find(obj => obj.id === msg.from.id)
                                                                if(!leadoncheck && msg.from.id !== page.id){
                                                                    let leadobj = Object.assign({},msg.from)
                                                                    console.log(page.socialadmins.length,leadobj)
                                                                    let admincheck = page.socialadmins.find(obj => obj.id === leadobj.id)
                                                                    if(admincheck){
                                                                        leadobj.PageModerator = true
                                                                        leadobj.Roles = admincheck.tasks
                                                                    }
                                                                    page.socialleads.push(leadobj)
                                                                }
                                                                
                                                                //could have liked to get publicprofile of person to obtain actual email but impossible for now
                                                                //no access by page and/or "SampleApp"
                                                            }
                                                        })
                                                    }
                                                }
                                            );
                                            return convers
                                        }) 
                                    }
                                    newfbapp.api(
                                        "/"+page.id+"/feed",
                                        'GET',
                                        {"fields":"from,id,to,message,created_time"},
                                        function (feedresponse) {
                                        if (feedresponse && !feedresponse.error) {
                                            console.log(feedresponse)
                                            page.posts = feedresponse.data
                                            .map(post => {
                                                 newfbapp.api(
                                                    "/"+post.id+"/comments",
                                                    function (commentsresponse) {
                                                    if (commentsresponse && !commentsresponse.error) {
                                                        console.log(commentsresponse)
                                                        post.Comments = commentsresponse.data.map(comment => {
                                                             if(comment.from && comment.from.id){
                                                            let leadoncheck = page.socialleads.find(obj => obj.id === comment.from.id)
                                                                if(!leadoncheck && comment.from.id !== page.id){
                                                                    let leadobj = Object.assign({},comment.from)
                                                                    console.log(page.socialadmins.length,leadobj)
                                                                    let admincheck = page.socialadmins.find(obj => obj.id === leadobj.id)
                                                                    if(admincheck){
                                                                        leadobj.PageModerator = true
                                                                        leadobj.Roles = admincheck.tasks
                                                                    }
                                                                    page.socialleads.push(leadobj)
                                                                }
                                                             }
                                                                return comment

                                                        })
                                                        /* handle the result */
                                                        }
                                                    }
                                                );
                                                newfbapp.api(
                                                    "/"+post.id+"/likes",
                                                    function (likesresponse) {
                                                    if (likesresponse && !likesresponse.error) {
                                                        console.log(likesresponse)
                                                        post.Likes = likesresponse.data
                                                        .map(like => {
                                                             if(like.from && like.from.id){
                                                            let leadoncheck = page.socialleads.find(obj => obj.id === like.from.id)
                                                                if(!leadoncheck && like.from.id !== page.id){
                                                                    let leadobj = Object.assign({},like.from)
                                                                    console.log(page.socialadmins.length,leadobj)
                                                                    let admincheck = page.socialadmins.find(obj => obj.id === leadobj.id)
                                                                    if(admincheck){
                                                                        leadobj.PageModerator = true
                                                                        leadobj.Roles = admincheck.tasks
                                                                    }
                                                                    page.socialleads.push(leadobj)
                                                                }
                                                             }
                                                                return like
                                                        })
                                                        /* handle the result */
                                                        }
                                                    }
                                                );
                                                return post
                                            })
                                            /* handle the result */
                                        }
                                        }
                                    );
                                    // Insert your code here
                                    console.log(response)
                                }
                                );
                                
                                console.log(page)
                                let oncheck = vm.AllPagesArray.find(obj => obj.id === page.id)
                                if(!oncheck){
                                    vm.AllPagesArray.push(page)
                                }
                                else{
                                    let index = vm.AllPagesArray.indexOf(oncheck)
                                    page.socialleads.map(lead =>  {
                                        let leadcheck = oncheck.socialleads.find(obj => obj.id == lead.id)
                                        if(leadcheck.SocialBrandLead){
                                            lead.SocialBrandLead = leadcheck.SocialBrandLead
                                            lead.SocialBrandLeadid = leadcheck.SocialBrandLeadid
                                        }
                                        return lead
                                    })
                                    vm.AllPagesArray.splice(index,1,page)
                                }
                                
                            })
                        });
                    }
                    
        },
       
        }
    
}
</script>

<style>

</style>
    