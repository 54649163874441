<template>
 <v-card rounded>
                      <v-card-title class="justify-space-between">
                        {{item.Title}}<v-spacer></v-spacer>
                        <v-btn v-if="item.CanEdit" @click="OpenEventEditor(item)" dark color="warning" outlined>Manage</v-btn>
                        </v-card-title>
                        <v-card-text>
                        Start: {{item.StartObject.toGMTString()}}
                            End: {{item.EndObject.toGMTString()}}
                        </v-card-text>
                        <!-- <v-list-item-subtitle style="padding-top:0;word-break: normal;"> 
                            <p v-html="item.Description">
                            </p>
                          </v-list-item-subtitle> -->
                        <v-list-item v-if="CurrentEntity.Hide_by_Day_Filter">
                          <v-list-item-content>
                              <p :class="$vuetify.breakpoint.xs? 'caption' : ''" v-html="smart_substr(item.Description,400)">
                            </p>
                          <!-- <v-list-item-subtitle>                  
                            Start: {{item.StartObject.toGMTString()}}
                            End: {{item.EndObject.toGMTString()}}
                          </v-list-item-subtitle> -->
                          <!-- <v-list-item-subtitle> 
                            End: {{item.EndObject}}
                          </v-list-item-subtitle> -->
                          </v-list-item-content>
                            <v-list-item-avatar tile size="150">
                          <img v-if="item.Image "
                                :src="item.Image.ThumbURL"
                                style="object-fit: cover;"              
                                >
                                <img v-if="!item.Image"
                                  src="@/assets/ImageHolder.png"
                                  style="object-fit: cover;"            
                                >   
                        </v-list-item-avatar> 
                        </v-list-item>
                        <v-list-item v-if="CurrentEntity.Hide_by_Day_Filter">
                          <v-list-item-content>
                            <!-- Session_Outcome should become "Caption" and possibly included all type -->
                          <p :class="$vuetify.breakpoint.xs? 'caption' : ''" v-html="item.Session_Outcome ? smart_substr(item.Session_Outcome,400) : ''">
                            </p>
                          </v-list-item-content>
                            
                        </v-list-item>
                      <v-list-item append class="mx-1" >    
                          
                        <v-list-item-avatar size="60" class="noticeboarddate primary white--text justify-center" rounded>
                          <ul style="list-style-type:none;padding: 0;">
                            <li>{{item.StartTime}}</li>
                            <li class="caption">{{item.Duration}}</li>
                          </ul>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle v-if="item.Type">                              
                            <v-chip :color="item.TypeColor">
                              {{item.Type.Name}}
                            </v-chip>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle >                                   
                            <span class="blue--text"> {{item.Name}}</span>
                          </v-list-item-subtitle>

                          <v-list-item-content class="caption grey--text" v-if="item.Presenter">
                          {{item.Presenter.Full_Name}}
                          </v-list-item-content>
                          </v-list-item-content>
                          <v-list-item-action>
                            ( {{item.BookedUsers.length}}/{{item.User_Limit}} )
                          </v-list-item-action>
                          <v-list-item-action v-if="item.id">
                            <!--  @click="UserBooked(item)? CancelUserBooking(item,userLoggedIn) : BookUser(item,userLoggedIn)" -->
                            <v-btn :disabled="!item.BookingEnabled" @click="BookingshaveDialog? BookingDialogActivate(item,userLoggedIn) : BookUser(item,userLoggedIn)" :color="UserBooked(item)? 'red' : 'grey'" :plain="!UserBooked(item)" outlined >{{UserBookingAction(item)}}</v-btn>
                          </v-list-item-action>            
                      </v-list-item>
                      <v-progress-linear :color="item.LimitColor"  :value="Math.round((item.BookedUsers.length/item.User_Limit)*100)"></v-progress-linear>
                    </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','item','CurrentEntity'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      BookingshaveDialog(){
        if(this.CurrentEntity){
          return this.CurrentEntity.Bookings_have_Dialog
        }
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      OpenEventEditor(item){
        this.$emit('OpenEventEditor',{event: item})
      },
      BookUser(event,user,withheaders){
        this.$emit('BookUser',event,user,withheaders)
      },
        BookingDialogActivate(event){
        this.$emit('BookingDialogActivate',event)
      },
        NotTooLate(event){
      let today = new Date()
      let latersvp = event.RSVP_Type.Name
      //console.log(latersvp)
      let latedate = new Date(event.StartObject)
      if(latersvp === 'Minutes'){
        let minute = event.StartObject.getMinutes()       
        latedate.setMinutes(minute-event.RSVP_Amount)
        return today <= latedate
      }
      else if(latersvp === 'Hours'){
        let hour = event.StartObject.getHours()       
        latedate.setHours(hour-event.RSVP_Amount)
        return today <= latedate
      }
      else if(latersvp === 'Days'){        
        latedate.setDate(event.StartObject.getDate()-event.RSVP_Amount)        
        return today <= latedate
      }
    },
    NotTooEarly(event){
      let today = new Date()
      let earlyrsvp = event.RSVP_Advance_Type.Name
      let earlydate = new Date(event.StartObject)
      if(earlyrsvp === 'Minutes'){
        let minute = event.StartObject.getMinutes()       
        earlydate.setMinutes(minute-event.RSVP_Advance_Amount)
        return today >= earlydate
      }
      else if(earlyrsvp === 'Hours'){
        let hour = event.StartObject.getHours()       
        earlydate.setHours(hour-event.RSVP_Advance_Amount)
        return today >= earlydate
      }
      else if(earlyrsvp === 'Days'){      
        let eventday = event.StartObject.getDate()  
        earlydate.setDate(eventday-event.RSVP_Advance_Amount)
        //console.log(earlydate,today >= earlydate)
          return today >= earlydate
      }
    },

    UserBookingAction(event){      
    if(this.CurrentEntity.Guests_Only && this.UserisGuest || !this.CurrentEntity.Guests_Only && this.userLoggedIn){  
      if(this.UserBooked(event)){
        return 'Cancel'
      }
      else if(event.BookedUsers.length === event.User_Limit){
        return 'Full'
      }
      else if(!this.NotTooLate(event)){
        return 'Too Late'
      }
      else if(!this.NotTooEarly(event)){
        return 'Too Early'
      }
      else{
        return 'Book'
      }
    }    
    else{
      return 'Guests Only'
    }

    },
    UserBooked(event){
      //console.log(event.BookedUserIDs.includes(this.userLoggedIn.id),event.BookedUserIDs)
      return event.BookedUserIDs.includes(this.userLoggedIn.id)
    },
        ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
    }
}
</script>

<style>

</style>



