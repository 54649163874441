
<template>
<div :style="EventsFeedCheck">
  <SocialSharing :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="selectedEvent.id" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="UserRecord" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>
  <v-dialog width="80%" v-model="AttendeeSelectDialog">
      <v-card flat width="100%" height="100%">
        <v-card-title class="headline primary white--text">Email Preview - <h3 class="headline primary pop--text"> Validate The Email before sending!</h3></v-card-title>
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item class='basicoutlined'>
                      <v-list-item-content>               
                      <strong>Record:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="EventRecordFilter" @change="CheckifUser()" label="Record" autocomplete="off" :items="['Accounts','Users','Contacts']" item-text="companyemail"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item class='basicoutlined' v-if="EventRecordFilter !== 'Users'">
                      <v-list-item-content>               
                      <strong>Field:</strong>
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox v-model="MailFieldFilter" label="Email Field" autocomplete="off" :items="MailRecordFilterEmailFields" item-text="Name"></v-combobox>  
                      </v-list-item-content>              
                   </v-list-item>              
                   
                  </v-list>
                   </v-layout>
                  <v-layout class="justify-center">
                  <v-card width ="80%" outlined flat>
                    <v-data-table
                      :headers="RelatedFilterHeaders"
                      :items="RelatedFilterRecords"
                      class="elevation-1"
                    >
                      <template v-slot:top>
                    <v-toolbar flat color="white">
                      <v-toolbar-title>{{EventRecordFilter}} records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-checkbox light
                        v-model="relatedsearches"
                        label="Prefilter to Related"
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-text-field light
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>
                                     
                    </v-card>
                </v-layout>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSelection()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="AddSelection()">OK <v-icon>mdi-check</v-icon></v-btn>
                </v-layout>
        </v-card>
      </v-dialog>
    <v-dialog v-model="NewEventDialog" max-width="800px">
      <v-card>
        <!-- <v-card-title class="primary white--text">       -->
        <v-toolbar flat dark height="100px">           
          <v-toolbar-title>New Event</v-toolbar-title>
         
        </v-toolbar>
            <!-- <v-list>          
          
        </v-list> -->
        <!-- </v-card-title> -->
        <v-list dense>
          <v-row style="padding: 20px;">
              <div class="flex xl6 lg6 md6 sm12 xs12">
                <v-list-item v-if="selectedEventImagelocalURL && !$store.state.TemplateView">
                <v-img :src="selectedEventImagelocalURL" height="300px" contain/>
              </v-list-item>
              
              <v-list-item v-if="!$store.state.TemplateView"> 
                          
                  <input
                        style="display: none"
                        type="file"
                        @change="onEventImageSelect($event)"
                        ref="EventImageInputter">                
                        <v-btn @click="onEventImageUpload(EditedEvent.id)" v-if="selectedEventImage">Save</v-btn>
                        <v-btn @click="closeEventImageUpload()" v-if="selectedEventImage">Cancel</v-btn>
                        <v-btn @click="$refs.EventImageInputter.click()" v-else  color="pop" dark icon><v-icon>mdi-pencil</v-icon></v-btn><v-btn :href="EventImage" target="_new" color="secondary" dark icon><v-icon>mdi-eye</v-icon></v-btn>
                
                  </v-list-item>
                  <v-list-item v-if="$store.state.TemplateView">
                    <v-img class="black" height="150" contain									
                                    :src="EditedEvent.EventImage ? EditedEvent.EventImage : require('@/assets/ImageHolder.png')"									
                                    >
                                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                                
                                        <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleSiteEventCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                                    </v-row>
                              </v-img>	
                  </v-list-item>
                    <v-list-item> 
            <v-list-item-content>             
              <v-text-field dense v-model="EventName" label="Subject" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
              </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>
                <v-menu
          v-model="EventstartdateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker dense v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
        </v-menu>             
              </v-list-item-content>
              <v-list-item-content> 
                <v-menu :disabled="!EventStartDate"
          v-model="EventStartTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartTimeMenu"
        
        >
          <template v-slot:activator="{ on }">
            <v-text-field
             :disabled="!EventStartDate"
              v-model="EventStartTime"
              label="Start Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
          dense
            v-model="EventStartTime"
             @input="EventStartTimeMenu = false"
            type="month"
            width="290"
            class="ml-4"
          ></v-time-picker>
        </v-menu>            
              </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>  
                <v-menu
        :disabled="!EventStartDate || !EventStartTime"
          v-model="EventenddateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndDate"
               :disabled="!EventStartDate || !EventStartTime"
              label="End Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker dense v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
        </v-menu>           
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu 
          v-model="EventEndTimeMenu"
           :disabled="!EventStartDate || !EventEndDate || !EventStartTime"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndTimeMenu"
          
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndTime"
              label="End Time"
              :disabled="!EventStartDate || !EventEndDate || !EventStartTime"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventEndTime"
             @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
            type="month"
            width="290"
            class="ml-4"
            dense
          :allowed-minutes="AllowedEndTimeMinutes"
          :allowed-hours="AllowedEndTimeHours"
          ></v-time-picker>
        </v-menu>           
                           
              </v-list-item-content>
          </v-list-item>
           <v-list-item>
              <v-list-item-content>
                <v-checkbox dense v-model="AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              </div>
               <div class="flex xl6 lg6 md6 sm12 xs12">
                 <v-list-item>
              <v-switch v-if="userLoggedIn && !$store.state.UserisGuest && System.Guests_can_Social && EditedEvent.id && EditedEvent.UserisModerator || userLoggedIn && !$store.state.UserisGuest && System.Guests_can_Social && !EditedEvent.id
               || System.Guests_can_Social && GroupData && EditedEvent.id && EditedEvent.UserisModerator
               || System.Guests_can_Social && GroupData && !EditedEvent.id" v-model="GuestsIncluded"  :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
              </v-list-item>
          <v-list-item v-if="!$store.state.TemplateView">
            <v-list-item-content>
          <v-autocomplete dense :items="UsersArray" label="Owner" item-text="Full_Name" v-model="AppointmentOwner" return-object></v-autocomplete>
            </v-list-item-content>
          </v-list-item >
          
            <div  v-if="!$store.state.TemplateView" class="hovercontent mx-3"><v-icon @click="InvitingAttendees = !InvitingAttendees">mdi-account-multiple-plus<p class="tooltiptext">Invite Attendees</p></v-icon></div>
           
            <ContentEditableField style="padding:15px;"
                :FieldObject="EditedEvent" :FieldName="'details'"
                :FieldValue="EditedEvent.details" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
          <v-list-item v-if="InvitingAttendees">
            <v-list-item-avatar tile >               
                      <v-btn small @click="ToggleSelectDialog()" color="pop" dark>To:</v-btn>
                      </v-list-item-avatar>
            <v-list-item-content>               
                      <v-combobox dense v-model="Attendees" multiple  label="To" readonly :rules="[$store.state.formrules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content> 
          </v-list-item>
        
              <v-list-item>
                 
                <v-list-item-content>             
                 <v-text-field dense v-model="EventLocation" label="Location" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
                </v-list-item-content>
                <v-list-item-content>             
                 <v-checkbox dense v-model="EventisMapLocation" label="Has Map Location"></v-checkbox>
                </v-list-item-content>
                <v-list-item-content v-if="EventisMapLocation">             
                 <v-text-field dense v-model="EventLocationLink" label="Location Link" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-text-field>
                </v-list-item-content>
                
              </v-list-item>
              
              <v-list-item  v-if="!$store.state.TemplateView"> 
                      
              <v-select dense return-object v-model="EventViewAccess" item-text="Name" :items="ViewAccess" label="Who can View" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-select>
             
              </v-list-item>
              <v-list-item  v-if="!$store.state.TemplateView"> 
                      
              <v-select dense v-model="EventJoinAccess" item-text="Name" :items="JoinAccess" label="Who can Join" :rules="[$store.state.formrules.required]" autocomplete="disabled"></v-select>
             
              </v-list-item>
               <v-list-item  v-if="!$store.state.TemplateView">                 
                <v-select dense multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-list-item>
              </div>
          </v-row>
             
              
         <!-- <v-card-text>
              <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text> -->

          
        </v-list>
         <!-- <v-card-actions v-if="EditedEvent && !$store.state.TemplateView">
              <TaggingComponent :AppisDarkModeCard="AppisDarkModeCard" :UsercanTag="UserisOwner" :record="EditedEvent" />
            </v-card-actions> -->
        <v-card-actions>
          <v-btn @click="CancelDialog()" dark outlined color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn dark outlined color="success" @click="$store.state.TemplateView ? save() : AddNewEvent()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tabs centered v-if="!FeedView">
        <v-tab>Upcoming</v-tab>
        <v-tab-item>
           <v-card style="padding-bottom: 100px;" class="mx-2 transparent" flat>
               <v-card-title>
                   Below are the upcoming events for {{RouteName}}
                   <v-spacer>
                   </v-spacer><v-btn  outlined color="accent" @click="OpenAboutDialog('AboutSiteEvents')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Events</v-btn>
               </v-card-title>
                <v-layout row>
                    <v-card shaped
                     v-for="event in AllEventsViewFormattednobday" :key="event.itemObjKey"
                    color="grey lighten-4"
                    class="my-3 mx-3"
                    max-width="400px"
                        >
                        <v-card-title v-if="event.id === $route.params.id" class="pop white--text announcementpulse">
                           <i>NEW</i>
                        </v-card-title>
                        <v-card-title class="my-2">
                            {{event.name}}<v-spacer>
                          </v-spacer>
                          <v-icon v-if="event.UserisModerator" @click="OpenEventeditDialog(event)">mdi-pencil</v-icon>
                           <v-icon  v-if="userisContentAdmin" @click="RouteAddSocialItemtoNotice(event)" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
                        </v-card-title>
                        <v-card-subtitle>
                            <v-img width="350px" :src="event.EventImage" max-height="150px"  style="background-color:white;"/>
                        </v-card-subtitle>
                        <v-layout row class="mx-3">
                            <v-col>
                               Location
                            </v-col>
                            <v-col>
                                {{event.Location}}
                            </v-col>
                            <!-- <v-col v-if="event.EventisMapLocation && event.AddressStreet_Number && event.AddressStreet_Name && event.AddressSuburb && event.AddressCity && event.AddressPostal_Code && event.AddressState_Province && event.AddressCountry_Region">
                                <h4>View on Google Maps</h4>
                                <v-btn icon @click="ViewMapItem(event.AddressStreet_Number,event.AddressStreet_Name,event.AddressSuburb,event.AddressCity,event.AddressPostal_Code,event.AddressState_Province,event.AddressCountry_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col> -->
                            <v-col v-if="event.EventisMapLocation">
                                <h4>View on Google Maps</h4>
                                <v-btn icon :href="event.EventLocationLink" target="_blank"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3">
                            <v-col cols="3">
                               When
                            </v-col>
                            <v-col>
                                What
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3 justify-center">
                            <v-col cols="3">
                                <v-row>
                                    <span style="font-size:12px">{{event.start}}</span>
                                </v-row>
                                <v-row>
                                    <span style="font-size:12px">{{event.end}}</span>
                                </v-row>
                            </v-col>
                            <v-col  style="font-size:8px">
                                <span  v-html="event.details.substr(0,350)"></span>
                            </v-col>
                        </v-layout>
                        <v-card-text>
                            <v-list class="transparent">
                                <v-list-item class="pop" dark>
                                    <v-combobox v-model="event.UserAttendance" @change="$store.state.TemplateView ? MockupConfirmAttendance(event,event.UserAttendance) : ConfirmAttendance(event,event.UserAttendance)" item-text="Name" :items="UserAttendanceOptions" label="Going?" autocomplete="off"></v-combobox>
                                </v-list-item>
                                <v-list-item class="pop" dark>
                                   {{event.UserAttendanceConfirmation}}
                                </v-list-item>
                                
                                <v-list-item>
                                  
                                    <v-dialog
                                    max-width="400px"
                                    offset-y>
                                        <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{event.UserGoingMessage}}</span>
                                        </template>
                                        <v-card-title class="accent white--text">
                                          {{event.name}}<v-spacer></v-spacer>
                                          <v-avatar size="50" tile v-if="event.EventImage">
                                                <v-img :src="event.EventImage" />
                                            </v-avatar>
                                            <v-avatar size="50" tile v-if="!event.EventImage">
                                                <v-img src="@/assets/ImageHolder.png" />
                                            </v-avatar>
                                        </v-card-title>
                                        <v-card-subtitle class="accent white--text">
                                          {{event.UsersGoing.length}} Attending
                                        </v-card-subtitle> 
                                        <v-list>
                                            <v-list-item>
                                                <v-text-field clearable v-model="AttendanceSearch" label="Search">
                                                </v-text-field>
                                            </v-list-item>
                                            <v-list-item v-for="user in event.UsersGoingFiltered" :key="user.id">
                                                <v-list-item-avatar>
                                                    <v-avatar size="30" v-if="user.Profile_Photo">
                                                        <v-img :src="user.Profile_Photo" />
                                                    </v-avatar>
                                                    <v-avatar size="30" v-if="!user.Profile_Photo">
                                                        <v-img src="@/assets/BlankProfilePic.png" />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    {{user.Full_Name}}
                                                </v-list-item-content>
                                                <v-list-item-content>
                                                    <router-link :to="'/SiteMember/'+user.recordid">View Profile</router-link>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-dialog>
                                </v-list-item>                                
                            </v-list>
                        </v-card-text>
                    </v-card>
            </v-layout>
              
           </v-card>
               
        </v-tab-item>
        <v-tab>Calendar View</v-tab>
        <v-tab-item><v-spacer>
                   </v-spacer><v-btn  outlined color="accent" @click="OpenAboutDialog('AboutSiteEvents')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Events</v-btn>
    <v-sheet height="64" class="accent" dark>
        
        <v-toolbar flat dark>
            
          <v-btn outlined class="mr-4" color="secondary" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="secondary" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="secondary" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ datetitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                color="secondary"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">UserIsModerator
         <v-btn outlined v-if="UserIsModerator || CanCreateEvents" class="green--text" @click="ActivateNewEvent()" fab small absolute style="margin-top:-20px;margin-right:40px;" right><v-icon>mdi-plus</v-icon>
        </v-btn>  
        <v-btn class="red--text" outlined @click="OpenAboutDialog('AboutSiteEvents')" fab small absolute style="margin-top:-20px;" right><v-icon>mdi-help</v-icon>
        </v-btn>
        <!-- <v-btn color="green" dark fab icon @click="ActivateNewEvent()" v-if="UserIsModerator || CanCreateEvents"><v-icon>mdi-plus-thick</v-icon></v-btn> -->
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="AllEventsViewFormatted"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card shaped 
                    color="grey lighten-4"
                    class="my-3 mx-3"
                    max-width="400px"
                        >
                        <v-card-title>
                            {{selectedEvent.name}}<v-icon v-if="selectedEvent.UserisModerator || UserisTemplateOwner" @click="OpenEventeditDialog(selectedEvent)">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-subtitle>
                            <v-img width="350px" :src="selectedEvent.EventImage" max-height="150px" contain  style="background-color:white;"/>
                        </v-card-subtitle>
                        <v-layout row class="mx-3">
                            <v-col>
                               Location
                            </v-col>
                            <v-col>
                                {{selectedEvent.Location}}
                            </v-col>
                            <!-- <v-col v-if="selectedEvent.EventisMapLocation && selectedEvent.AddressStreet_Number && selectedEvent.AddressStreet_Name && selectedEvent.AddressSuburb && selectedEvent.AddressCity && selectedEvent.AddressPostal_Code && selectedEvent.AddressState_Province && selectedEvent.AddressCountry_Region">
                                <h4>View on Google Maps</h4>
                                <v-btn icon @click="ViewMapItem(selectedEvent.AddressStreet_Number,selectedEvent.AddressStreet_Name,selectedEvent.AddressSuburb,selectedEvent.AddressCity,selectedEvent.AddressPostal_Code,selectedEvent.AddressState_Province,selectedEvent.AddressCountry_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col> -->
                            <v-col v-if="selectedEvent.EventisMapLocation">
                                <h4>View on Google Maps</h4>
                                <v-btn icon :href="selectedEvent.EventLocationLink" target="_blank"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3">
                            <v-col cols="3">
                               When
                            </v-col>
                            <v-col>
                                What
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3 justify-center">
                            <v-col cols="3">
                                <v-row>
                                    <span style="font-size:12px">{{selectedEvent.start}}</span>
                                </v-row>
                                <v-row>
                                    <span style="font-size:12px">{{selectedEvent.end}}</span>
                                </v-row>
                            </v-col>
                            <v-col  style="font-size:8px" v-if="selectedEvent && selectedEvent.details">
                                <span  v-html="selectedEvent.details.substr(0,350)"></span>
                            </v-col>
                        </v-layout>
                        <v-card-text v-if="!selectedEvent.IsBirthdayEvent">
                            <v-list class="transparent" >
                                <v-list-item class="pop" dark>
                                    <v-combobox v-model="UserAttendance" item-text="Name" @change="$store.state.TemplateView ? MockupConfirmAttendance(event,event.UserAttendance) : ConfirmAttendance(event,event.UserAttendance)" :items="UserAttendanceOptions" label="Going?" autocomplete="off"></v-combobox>
                                </v-list-item>
                                <v-list-item class="pop" dark>
                                   {{selectedEvent.UserAttendanceConfirmation}}
                                </v-list-item>
                                
                                                              
                            </v-list>
                        </v-card-text>

                        <v-card-actions v-if="!selectedEvent.IsBirthdayEvent">
                          
                          <v-spacer></v-spacer>
                          <v-btn icon class="red--text">
                            <v-icon small>mdi-reddit</v-icon>
                          </v-btn>
                          <v-btn icon class="light-blue--text">
                            <v-icon small>mdi-twitter</v-icon>
                          </v-btn>
                          <v-btn icon class="blue--text text--darken-4">
                            <v-icon small>mdi-facebook</v-icon>
                          </v-btn>
                          <v-btn icon class="red--text">
                            <v-icon small>mdi-google-plus</v-icon>
                          </v-btn>
                          <v-btn icon class="blue--text text--darken-4">
                            <v-icon small>mdi-linkedin</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn @click="ShareBlog()">Share<v-icon>mdi-share-circle</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
        </v-menu>
      </v-sheet>
        </v-tab-item>
    </v-tabs>
    <v-layout row v-if="FeedView && !NoticeSelect && tab.CalendarCardsView">
                    <v-card shaped
                     v-for="event in AllEventsViewFormattednobday" :key="event.itemObjKey"
                    color="grey lighten-4"
                    class="my-3 mx-3"
                    max-width="400px"
                        >
                        <v-card-title v-if="event.id === $route.params.id" class="pop white--text announcementpulse">
                           <i>NEW</i>
                        </v-card-title>
                        <v-card-title class="my-2">
                            {{event.name}}<v-spacer>
                          </v-spacer>
                          <v-icon v-if="event.UserisModerator || UserisTemplateOwner" @click="OpenEventeditDialog(event)">mdi-pencil</v-icon>
                           <v-icon  v-if="!$store.state.TemplateView && userisContentAdmin" @click="RouteAddSocialItemtoNotice(event)" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
                        </v-card-title>
                        <v-card-subtitle>
                            <v-img width="350px" :src="event.EventImage" max-height="150px"  style="background-color:white;"/>
                        </v-card-subtitle>
                        <v-layout row class="mx-3">
                            <v-col>
                               Location
                            </v-col>
                            <v-col>
                                {{event.Location}}
                            </v-col>
                            <!-- <v-col v-if="event.EventisMapLocation && event.AddressStreet_Number && event.AddressStreet_Name && event.AddressSuburb && event.AddressCity && event.AddressPostal_Code && event.AddressState_Province && event.AddressCountry_Region">
                                <h4>View on Google Maps</h4>
                                <v-btn icon @click="ViewMapItem(event.AddressStreet_Number,event.AddressStreet_Name,event.AddressSuburb,event.AddressCity,event.AddressPostal_Code,event.AddressState_Province,event.AddressCountry_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col> -->
                            <v-col v-if="event.EventisMapLocation">
                                <h4>View on Google Maps</h4>
                                <v-btn icon :href="event.EventLocationLink" target="_blank"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3">
                            <v-col cols="3">
                               When
                            </v-col>
                            <v-col>
                                What
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3 justify-center">
                            <v-col cols="3">
                                <v-row>
                                    <span style="font-size:12px">{{event.start}}</span>
                                </v-row>
                                <v-row>
                                    <span style="font-size:12px">{{event.end}}</span>
                                </v-row>
                            </v-col>
                            <v-col  style="font-size:8px">
                                <span  v-html="event.details.substr(0,350)"></span>
                            </v-col>
                        </v-layout>
                        <v-card-text>
                            <v-list class="transparent">
                                <v-list-item class="pop" dark>
                                    <v-combobox v-model="event.UserAttendance" @change="$store.state.TemplateView ? MockupConfirmAttendance(event,event.UserAttendance) : ConfirmAttendance(event,event.UserAttendance)" item-text="Name" :items="UserAttendanceOptions" label="Going?" autocomplete="off"></v-combobox>
                                </v-list-item>
                                <v-list-item class="pop" dark>
                                   {{event.UserAttendanceConfirmation}}
                                </v-list-item>
                                
                                <v-list-item>
                                  
                                    <v-dialog
                                    max-width="400px"
                                    offset-y>
                                        <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{event.UserGoingMessage}}</span>
                                        </template>
                                        <v-card-title class="accent white--text">
                                          {{event.name}}<v-spacer></v-spacer>
                                          <v-avatar size="50" tile v-if="event.EventImage">
                                                <v-img :src="event.EventImage" />
                                            </v-avatar>
                                            <v-avatar size="50" tile v-if="!event.EventImage">
                                                <v-img src="@/assets/ImageHolder.png" />
                                            </v-avatar>
                                        </v-card-title>
                                        <v-card-subtitle class="accent white--text">
                                          {{event.UsersGoing.length}} Attending
                                        </v-card-subtitle> 
                                        <v-list>
                                            <v-list-item>
                                                <v-text-field clearable v-model="AttendanceSearch" label="Search">
                                                </v-text-field>
                                            </v-list-item>
                                            <v-list-item v-for="user in event.UsersGoingFiltered" :key="user.id">
                                                <v-list-item-avatar>
                                                    <v-avatar size="30" v-if="user.Profile_Photo">
                                                        <v-img :src="user.Profile_Photo" />
                                                    </v-avatar>
                                                    <v-avatar size="30" v-if="!user.Profile_Photo">
                                                        <v-img src="@/assets/BlankProfilePic.png" />
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    {{user.Full_Name}}
                                                </v-list-item-content>
                                                <v-list-item-content>
                                                    <router-link :to="'/SiteMember/'+user.recordid">View Profile</router-link>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-dialog>
                                </v-list-item>                                
                            </v-list>
                        </v-card-text>
                    </v-card>
            </v-layout>
    <v-row
    v-if="FeedView && !NoticeSelect && !tab.CalendarCardsView"
      class="fill-height mx-3"
      align="center"
      justify="center"
    >
    <v-sheet height="64" width="100%" class="accent" dark>
        
        <v-toolbar flat dark>
            
          <v-btn outlined class="mr-4" color="secondary" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="secondary" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="secondary" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ datetitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                color="secondary"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="100%" width="100%">
        
           <v-btn outlined v-if="EventModerator || CanCreateEvents" class="green--text" @click="ActivateNewEvent()" fab small absolute style="margin-top:-20px;margin-right:40px;" right><v-icon>mdi-plus</v-icon>
        </v-btn>  
        <v-btn class="red--text" outlined @click="OpenAboutDialog('AboutSiteEvents')" fab small absolute style="margin-top:-20px;" right><v-icon>mdi-help</v-icon>
        </v-btn>
        <!-- <v-btn color="green" dark fab icon @click="ActivateNewEvent()" v-if="EventModerator || CanCreateEvents"><v-icon>mdi-plus-thick</v-icon></v-btn> -->
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="AllEventsViewFormatted"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
        >
          <v-card shaped 
                    color="grey lighten-4"
                    class="my-3 mx-3"
                    max-width="400px"
                        >
                        <v-card-title>
                            {{selectedEvent.name}}<v-icon v-if="selectedEvent.UserisModerator || UserisTemplateOwner" @click="OpenEventeditDialog(selectedEvent)">mdi-pencil</v-icon>
                        </v-card-title>
                        <v-card-subtitle>
                            <v-img width="350px" :src="selectedEvent.EventImage" max-height="150px"  style="background-color:white;"/>
                        </v-card-subtitle>
                        <v-layout row class="mx-3">
                            <v-col>
                               Location
                            </v-col>
                            <v-col>
                                {{selectedEvent.Location}}
                            </v-col>
                            <!-- <v-col v-if="selectedEvent.EventisMapLocation && selectedEvent.AddressStreet_Number && selectedEvent.AddressStreet_Name && selectedEvent.AddressSuburb && selectedEvent.AddressCity && selectedEvent.AddressPostal_Code && selectedEvent.AddressState_Province && selectedEvent.AddressCountry_Region">
                                <h4>View on Google Maps</h4>
                                <v-btn icon @click="ViewMapItem(selectedEvent.AddressStreet_Number,selectedEvent.AddressStreet_Name,selectedEvent.AddressSuburb,selectedEvent.AddressCity,selectedEvent.AddressPostal_Code,selectedEvent.AddressState_Province,selectedEvent.AddressCountry_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col> -->
                            <v-col v-if="selectedEvent.EventisMapLocation">
                                <h4>View on Google Maps</h4>
                                <v-btn icon :href="selectedEvent.EventLocationLink" target="_blank"><v-icon>mdi-google-maps</v-icon></v-btn>
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3">
                            <v-col cols="3">
                               When
                            </v-col>
                            <v-col>
                                What
                            </v-col>
                        </v-layout>
                        <v-layout row class="mx-3 justify-center">
                            <v-col cols="3">
                                <v-row>
                                    <span style="font-size:12px">{{selectedEvent.start}}</span>
                                </v-row>
                                <v-row>
                                    <span style="font-size:12px">{{selectedEvent.end}}</span>
                                </v-row>
                            </v-col>
                            <v-col  style="font-size:8px" v-if="selectedEvent && selectedEvent.details">
                                <span  v-html="selectedEvent.details.substr(0,350)"></span>
                            </v-col>
                        </v-layout>
                        <v-card-text>
                            <v-list class="transparent"  v-if="!selectedEvent.IsBirthdayEvent">
                                <v-list-item class="pop" dark>
                                    <v-combobox v-model="UserAttendance" item-text="Name" @change="$store.state.TemplateView ? MockupConfirmAttendance(selectedEvent,UserAttendance) : ConfirmAttendance(selectedEvent)" :items="UserAttendanceOptions" label="Going?" autocomplete="off"></v-combobox>
                                </v-list-item>
                                <v-list-item class="pop" dark>
                                   {{selectedEvent.UserAttendanceConfirmation}}
                                </v-list-item>
                                
                                                              
                            </v-list>
                        </v-card-text>
                        
                    </v-card>
        </v-menu>
      </v-sheet>
    </v-row>
    <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="AllEventsViewFormatted" item-text="name" v-model="selectedEvent" return-object label="Event"/>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SocialSharing from '@/components/SocialNetwork/SocialSharing'
import TaggingComponent from '@/components/SocialNetwork/TaggingComponent';
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
import ContentEditableField from '@/components/Database/ContentEditableField'
export default {
    props: ['RelatedObj','RelatedParentObj','RelatedGroupFilter','Business_Unit','datasetsecuritystructure','FeedView','FriendsList','CanCreate','NoticeSelect','tab','System','GroupData','Editor'],
    components: {
        SocialSharing,
        TaggingComponent,
        ContentEditable,
        ContentEditableField
    },
    data() {
        return {
          GuestsIncluded: false,
          AttendanceSearch: '',
          TagLinkDialog: false,
            ShareItemDialog: false,
            InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
        //   RelatedObj: {},
            AccessRoles: [
                {
                    ID: 1,
                    Name: 'Public',

                },
                {
                    ID: 2,
                    Name: 'Site Members',

                },
                {
                    ID: 4,
                    Name: 'Invite Only',

                },
                ],
            EventViewAccess: '',
            EventJoinAccess: '',
            Moderators: [],
            EventImage: '',
            UserAttendance: '',
            UserAttendanceOptions: [
                {Name: 'Going',ID: 1000001},
                {Name: 'Not Going',ID: 1000002},
                {Name: 'Maybe',ID: 1000003},
            ],
            selectedEventImage: '',
            selectedEventImagelocalURL: '',
            HasImage: false,
          UsersArray: [],
          rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            AllEvents: [],
            TempAllEvents: [],
            EditedEvent: {Attendees: []},
            NewEventDialog: false,
            EventstartdateMenu: false,
            AppointmentOwner: '',
            InvitingAttendees: false,
            AttendeeSelectDialog: false,
            EventRecordFilter: '',
            MailFieldFilter: '',
            UserHeaders:[
        { text: 'Selected', value: 'selected',sortable: false},
        { text: 'Title', value: 'Title'},
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Gender', value: 'Gender'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
            ArrayFilter: 'To',
            relatedsearches: [],
            search: '',
            Attendees: [],
            EventenddateMenu: false,
            EventEndDate: '',
            EventStartTime: '',
            EventStartTimeMenu: false,
            AllDayEvent: false,
            EventEndTime: '',
            EventEndTimeMenu: false,
            EventStartDate: '',
            EventName: '',
            EventLocation: '',
            EventisMapLocation: false,
            EventLocationLink: '',
            AddressStreet_Number:  '',
            AddressStreet_Name:  '',
            AddressSuburb:  '',
            AddressCity:  '',
            AddressPostal_Code:  '',
            AddressState_Province:  '',
            AddressCountry_Region:  '',
            EventSubject: '',
            EventDetails: '',
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            today: format(new Date(),'yyyy-MM-dd'),
            
            EventModerator: false,
            SiteModerators: [],
            Attending_Users: []
        }
    },
    mounted () {
        if(this.$refs.calendar){
      this.$refs.calendar.checkChange()
        }
    },

    computed: {
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      },
      CanCreateEvents(){
        return !this.$store.state.TemplateView && this.CanCreate || this.UserisTemplateOwner
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      ContentColPath(){
        //quick tip here. Edit and New components reuqire collection to update, or add docs. thus, not interchangeable with CollectionRef on "feed" or "collection view"
        //the CollectionRef would add permission queries like for group aticles "where gourpid...etc"
        return this.$store.state.TemplateView ? this.ContentRootPath.collection('samplesiteevents') : 
        !this.NonGroupRoute ? this.ContentRootPath.collection('groupevents') : this.ContentRootPath.collection('siteevents')
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
       ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Events') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
    },
     SocialItemInteractMethodProp(){
      return this.$store.state.SocialItemInteractMethodProp
    },
      UserisTeamMember(){
        return this.GroupData && this.GroupData.TeamMembers ? this.GroupData.TeamMembers.find(obj => obj === this.userLoggedIn.id) : false
      },
      UserisGroupMember(){
        return this.GroupData && this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.find(obj => obj.id === this.GroupData.id) : false
      },
      EventsDB(){
        if(this.NonGroupRoute){
         return this.userLoggedIn ? this.ContentColPath : this.ContentRootPath.collection('siteeventswiki') 
        }
        else{
          return this.ContentColPath
        }
      },
      EventsQuery(){
        if(this.TemplateRootPath){
          return this.ContentColPath
        }
        else if(this.NonGroupRoute){
         return this.CompanyOnlyFilter ? this.EventsDB.where('Companyid','==',this.UserCompanyid) : this.EventsDB
        }
        else{
          return this.EventsDB.where('groupid','==',this.$route.params.id) 
        }
      },
      userisContentAdmin(){
        return this.userIsAdmin
      },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupEvents' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Events' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
      UserisOwner(){
        return this.AppointmentOwner && this.AppointmentOwner.id === this.userLoggedIn.id
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserIsModerator(){
        if(this.$route.name === 'GroupSingle' && this.userLoggedIn && this.userLoggedIn.groups){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj && memberobj.Administrator || memberobj && memberobj.EventsModerator
          }
          else if(this.userLoggedIn && this.userLoggedIn.ModerationPermissions){
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Events')
              if(moderator){
                return true
              }
              else{
                return false || this.userIsAdmin
              }
          }
          else {
            return this.userIsAdmin
          }
      },
      EventsFeedCheck(){
        if(this.FeedView){
          return 'height:350px;margin-bottom: 120px;'
        }
        else if(this.$route.name === 'GroupSingle'){
          return ''
        }
        else{
          return ''
        }
      },
        UserRecord(){
            return this.$store.state.userobj
          },
        ViewAccess(){
            return this.AccessRoles
        },
        JoinAccess(){
            if(this.EventViewAccess)
           // console.log(this.EventViewAccess)
            return this.AccessRoles.filter(role => {
                return role.ID >= this.EventViewAccess.ID
            })
        },
      RelatedFilterHeaders(){
      if(this.EventRecordFilter === 'Users'){
        return this.UserHeaders
      }
      if(this.EventRecordFilter === 'Accounts'){
        return this.AccountsHeaders
      }
      if(this.EventRecordFilter === 'Service Job Requests'){
        return this.ServiceJobRequestsHeaders
      }
    },
    MailRecordFilterEmailFields(){
      if(this.EventRecordFilter === 'Users'){
        return ['Email']
      }
      if(this.EventRecordFilter === 'Accounts'){
        return ['Business_Email']
      }
      if(this.EventRecordFilter === 'ServiceJobRequests'){
        return ['Customer_EMail_Address,']
      }
      if(this.EventRecordFilter === 'Contacts'){
        return ['emailaddress']
      }
    },
    RelatedMailingList(){
      // if(this.ArrayFilter === 'To'){
        return this.Attendees
      // }
      // if(this.ArrayFilter === 'CC'){
      //   return this.EmailCC
      // }
      // if(this.ArrayFilter === 'BCC'){
      //     return this.EmailBCC
      // }      
    },
    RelatedRecordfilterArray(){
      if(this.EventRecordFilter === 'Users'){
        return this.UsersArray
      }
      if(this.EventRecordFilter === 'Accounts'){
        return this.AccountsArray
      }
      if(this.EventRecordFilter === 'Service Job Requests'){
        return this.ServiceJobRequestsArray
      }
    },
    RelatedRecordPrimaryField(){
      if(this.EventRecordFilter === 'Users'){
        return 'Full_Name'
      }
      if(this.EventRecordFilter === 'Accounts'){
        return 'Company_Name'
      }
      if(this.EventRecordFilter === 'Service Job Requests'){
        return 'Request_Number'
      }
    },
      RelatedFilterRecords(){
      if(this.EventRecordFilter && this.ArrayFilter){
        return this.RelatedRecordfilterArray.filter(record => {
          return record[this.RelatedRecordPrimaryField].toLowerCase().includes(this.search.toLowerCase()
          )}).map(record => {
            let ToCheck = this.RelatedMailingList.find(to => to == record[this.MailFieldFilter])
            if(typeof ToCheck !== 'undefined'){
              record.selected = true
            }
            else{
              record.selected = false
            }
            return record            
          })
    }
     else{
      return []
    }
         
    },
        AllowedEndTimeHours(){

        let Hours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
      return Hours.filter(hr => {
        if(this.EventStartTime){
          return hr >= Number(this.EventStartTime.split(':')[0])
        }
        else{
          return []
        }
        })
       console.log(this.AllowedEndTimeHours)
      },
      AllowedEndTimeMinutes(){
        let Minutes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        return Minutes.filter(hr => {
          if(this.EventStartTime && this.EventEndTime){
            let starttimehour = Number(this.EventStartTime.split(':')[0])
            let endtimehour = Number(this.EventEndTime.split(':')[0])
            console.log(this.EventEndTime)
            if(starttimehour === endtimehour){
          return hr >= Number(this.EventStartTime.split(':')[1])
            }
            else{
              return Minutes
            }
          }
          else{
            return []
          }
        })
        
        console.log(this.AllowedEndTimeMinutes)
      },
    AllEventsViewFormattednobday(){
      return this.AllEventsViewFormatted
      .filter(event => {
        return !event.IsBirthdayEvent
      })
    },
    AllEventsViewFormatted(){
      return this.AllEvents.map(event => {
          let eventobj = Object.assign({},event)    
        if(typeof eventobj.start.toDate !== 'undefined'){

        eventobj.start = this.TimestampFormatterSTRING(eventobj.start.toDate())
        }
        else{

        }
  
        
        if(typeof eventobj.end.toDate !== 'undefined'){
          //console.log('is timestamp end')
        eventobj.end = this.TimestampFormatterSTRING(eventobj.end.toDate())
        }
        else{
          //console.log('is NOT timestamp end')
        }
    
        return eventobj
        
      }).map(event => {
          let AttendanceCheck = event.Attending_Users.find(obj => obj.recordid === this.UserRecord.id)
          if(AttendanceCheck){
          event.UserAttendance = AttendanceCheck.Attendance_Confirmation.Name
          if(event.UserAttendance === 'Going'){
              event.UserAttendanceConfirmation = 'You are going to this Event'
          }
          else if(event.UserAttendance === 'Not Going'){
              event.UserAttendanceConfirmation = 'You opted NOT go to this Event'
          }
          else if(event.UserAttendance === 'Maybe'){
              event.UserAttendanceConfirmation = 'You opted as MAYBE go to this Event'
          }
          
          }
          else{
              event.UserAttendanceConfirmation = 'You have not yet confirmed attendance. Confirm now?'
          }
          return event
      }).sort((a, b) => {
            //console.log('sorting again')
            //console.log(a.start)
            var key1 = this.DateFormatter(a.start);
            var key2 = this.DateFormatter(b.start);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      }).map(event => {
          event.UsersGoing = event.Attending_Users.filter(attendance => {
              return attendance.Attendance_Confirmation.Name === 'Going'
          }).map(user => {
              let usercheck = this.UsersArray.find(obj => obj.id == user.id)
              console.log(usercheck)
              if(usercheck && usercheck.Profile_Photo){
                  user.Profile_Photo = usercheck.Profile_Photo
              }
              return user
          })
         event.UsersGoingFiltered = event.UsersGoing.filter(user => {
              if(this.AttendanceSearch){
                  return user.Full_Name.toLowerCase().includes(this.AttendanceSearch.toLowerCase())
              }
              else{
                  return user
              }
          })
         
          if(event.UsersGoing.length >1){
              event.UserGoingMessage = event.UsersGoing.length+' Users Going already'
          }
          if(event.UsersGoing.length ===1){
              event.UserGoingMessage = event.UsersGoing.length+' User Going already'
          }
          return event
      }).map(event => {
        let moderatorcheck  = event.moderatorrolesarrayQuery.find(obj => obj == this.UserRecord.id)
        if(moderatorcheck){
          event.UserisModerator = true
        }
        return event
      })
      
    },
   
    AllJavaFormatEvents(){
      let NewArray = Array.from(this.AllEventsViewFormatted)
      return NewArray.map(event => {
        event.start = new Date(event.start)
        event.end = new Date(event.end)
        return event
      })
    },
    datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
    },
        watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleSiteEventCoverIMG','ActivateNewEvent']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
      created(){
        if(!this.FeedView){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        }
      if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.GetSamples(this.TemplateRootPath.collection('samplesiteevents'),'AllEvents')
      }
      else{
        this.CheckRouting()
        // this.FocusedViewToggle()
      if(this.System && this.System.Social_Content_for_Guests || this.userLoggedIn && this.$store.state.UserisGuest){
            this.GuestsIncluded = true
          }
      if(this.$route.name === 'GroupSingle' || this.$route.name === 'GroupNoticeNew'){    
        this.InternalLinkType = 'Event'
        this.LinkComponent = 'GroupEventShared'
        this.SharedCollection = 'groupevents'
        this.AccessRoles.push({ID: 3,Name: 'Group Members',})
      }
      else{
        this.InternalLinkType = 'Event'
        this.LinkComponent = 'SiteEventShared'
        this.SharedCollection = 'siteevents'
        this.IntranetViewToggle()
      }
      }
    },

    methods: {
      BoxStyle(tab){
        if(tab){
        if(tab.FullHeight){
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            return 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'
        }
        }
        else{
          return ''
        }
       },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
     GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      Activatedialog(){
        this.NewEventDialog = true
      },
      OpenEditdialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
      ToggleEdit(item,dialprop){
      if(this.$store.state.TemplateView && this.Editor && this.UserisTemplateOwner){
        this.editedItem = Object.assign({},item)
        this.OpenEventeditDialog(item)
      }
      else if(this.$store.state.TemplateView && !this.Editor){
        //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
        this.OpenAboutDialog(dialprop)
      }
      else if(!this.$store.state.TemplateView){
        this.OpenEventeditDialog(item)
      }
      
    } ,  
      OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
    save(){
      // let NEWeditorContent = document.getElementById('NewBlogValue')
      // this.EventDetails = NEWeditorContent.innerHTML;
      // // console.log(this.EventDetails)
      // // console.log(this.RelatedObj)
      let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
      let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
        let NewJavaStartDate = new Date(NewStartDateString)
      let NewJavaEndDate = new Date(NewEndDateString)
            let NewEvent = {
            Owner: {Full_Name: this.UserRecord.Full_Name,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,id: this.UserRecord.id},
            Ownerid: this.UserRecord.id,
            Attendees: [],
            name: this.EventName,
            whocanview: [],
            whocanjoin: [],
            subject: this.EventSubject,
            details: this.EditedEvent.details,
            start: NewJavaStartDate,
            end: NewJavaEndDate,
            color: 'pop',
            GuestsIncluded: this.GuestsIncluded,
            //   activitytype: 'Appointment',
            //   regardingrecord: regardingrecord,
            //   regardingtype: this.RelatedObj.Link,
            Moderators: [],
            moderatorrolesarrayDBRules: {},
            moderatorrolesarrayQuery: [],
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            AllDayEvent: this.AllDayEvent,
            Location: this.EventLocation,
            EventisMapLocation: this.EventisMapLocation,
            EventImage: this.EditedEvent.EventImage,
            Attending_Users: []

        }
        //SO...SUBJECT,DETAILS,START,END,ALLDAYEVENT,LOCATION,EVENTISMAPLOCATION
        if(this.EditedEvent.id){
          //but more props to assign right?
          this.$store.dispatch('CreateSearchTitle',NewEvent.name).then(searchtitle => {
              NewEvent.nameQuery = searchtitle
              this.TemplateRootPath.collection('samplesiteevents').doc(this.EditedEvent.id).set(NewEvent).then(newdoc => {
                this.CancelDialog()
              })
          })
        }
        else{
          this.TemplateRootPath.collection('samplesiteevents').add(NewEvent).then(newdoc => {
            this.CancelDialog()
          })
        }
    },
    CancelDialog(){
      this.AllDayEvent = false
        this.EventSubject = ''
        this.EventName = ''
        this.EventDetails = ''
        this.EventStartDate = ''
        this.EventStartTime = ''
        this.EventEndDate = ''
        this.EventEndTime = ''
        this.EventViewAccess = ''
        this.EventJoinAccess = ''
        this.EditedEvent = {Attendees: []}
        
        this.NewEventDialog = false
    },
    RefreshDialog(){
        this.NewEventDialog = false
        setTimeout(() => {
            this.NewEventDialog = true
        }, 20);
      },
      AssignSampleSiteEventCoverIMG(){
        this.EditedEvent.EventImage = this.SelectedImage.fileurl
        this.$store.commit('setIMGProp','')
        this.RefreshDialog()
        //actually this is EventImage not coverimage as we had it, the coverimage prop for social interaction crap
      },
      RouteAddSocialItemtoNotice(event){
        let socialitem = {
          LinkID: event.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }      
        if(event.EventImage){
          socialitem.coverimage = event.EventImage
        }    
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          // data: this.selectedEvent,
          LinkID: this.selectedEvent.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }      
        if(this.selectedEvent.EventImage){
          socialitem.coverimage = this.selectedEvent.EventImage
        }    
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
      ActivateTagLinkDialog(boolean){
        this.TagLinkDialog = boolean  
      },
      taglink(){
        this.TagLinkDialog = true
      },
      SaveSingleTag(tagitem){
        console.log(tagitem)
        this.TagLinkDialog = false  
        document.execCommand("createLink", false, tagitem.taglocation);
      },
      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      OpenEventeditDialog(event){
        this.EditedEvent = event
        console.log(event)
        this.Attendees = event.Attendees
        this.EventName = event.name
        this.Moderators = event.Moderators
        this.EventDetails = event.details
        if(event.GuestsIncluded){
          this.GuestsIncluded = event.GuestsIncluded
        }
        if(typeof event.start === 'object'){
          this.EventStartDate = format(event.start,'yyyy-MM-dd')
          let starthr = event.start.getHours()
          let starthrstring = ''
          starthrstring = starthr.toString()
          let startmin = event.start.getHours()
          let startmintring = ''
          starthrstring = startmin.toString()
          if(starthr<10){
            starthrstring = '0'+starthr
          }
          if(startmin<10){
            startmintring = '0'+startmin
          }
          this.EventStartTime = starthrstring+':'+startmintring
        }
        else{
          this.EventStartDate = event.start.split(' ')[0]
          this.EventStartTime = event.start.split(' ')[1]
        } 
        if(typeof event.end === 'object'){
          this.EventEndDate = format(event.end,'yyyy-MM-dd')
          let endhr = event.end.getHours()
          let endhrstring = ''
          endhrstring = endhr.toString()
          let endmin = event.end.getHours()
          let endmintring = ''
          endhrstring = endmin.toString()
          if(endhr<10){
            endhrstring = '0'+endhr
          }
          if(endmin<10){
            endmintring = '0'+endmin
          }
          this.EventEndTime = endhrstring+':'+endmintring
        }
        else{
          this.EventEndDate = event.end.split(' ')[0]
          this.EventEndTime = event.end.split(' ')[1]
        } 
        this.AppointmentOwner = event.Owner
        this.EventLocation = event.Location
        this.EventisMapLocation = event.EventisMapLocation
        this.EventLocationLink = event.EventLocationLink
        this.EventViewAccess = event.whocanview
        this.EventJoinAccess = event.whocanjoin
        this.selectedEventImagelocalURL = event.EventImage
        if(event.EventImage){
          this.HasImage = true
          console.log("this.HasImage",this.HasImage)
        }
        this.AllDayEvent = event.AllDayEvent
        this.NewEventDialog = true
      },
      
      GetSiteModerators(){
        let vm = this
        return new Promise(function(resolve, reject) {
          if(vm.userLoggedIn){
            vm.AppointmentOwner = vm.userLoggedIn
            let Moderator = {
              Full_Name: vm.userLoggedIn.Full_Name,
              Name: vm.userLoggedIn.Name,
              Surname: vm.userLoggedIn.Surname,
              ModerationPermissions: vm.userLoggedIn.ModerationPermissions ? vm.userLoggedIn.ModerationPermissions : [],
              id: vm.userLoggedIn.id
            }
            vm.Moderators.push(Moderator)
            
            vm.ContentRootPath.collection('sitemoderators').onSnapshot(res => {
              const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    vm.SiteModerators.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                })
                resolve('Got Moderators')
            })
          }
          else{
            resolve('Got Moderators')
          }        
        })
      },
        ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
    let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
    let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
    window.open(FinalLink, '_blank')
  },
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        //console.log(new Date(yearnumber, monthnumber-1, daynumber))
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
        MockupConfirmAttendance(event,eventattendance){
          // console.log(event)
          // console.log(eventattendance)
          alert('You registration for the event '+event.name+' has been processed as '+eventattendance.Name)
        },
        ConfirmAttendance(event,eventattendance){
          console.log(event)
          console.log(eventattendance)
            if(this.UserAttendance && !eventattendance){
                confirm('This will confirm your attendance as '+this.UserAttendance.Name+'.Do you want to confirm?') && this.UpdateUserAttendance(event)
            }
            else{
              this.UserAttendance = eventattendance
              confirm('This will update your attendance as '+this.UserAttendance.Name+'.Do you want to confirm?') && this.UpdateUserAttendance(event)
            }
        },
        UpdateUserAttendance(event){
          console.log(event.Attending_Users)
          console.log(this.UserAttendance)
          let AttendanceRegister = {ConfirmationDate: new Date(), Full_Name: this.UserRecord.Full_Name,Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,recordid: this.UserRecord.id, Attendance_Confirmation: this.UserAttendance}
          if(this.GroupData){
            AttendanceRegister.groupid = this.GroupData.id
          }
          let oncheck = event.Attending_Users.find(obj => obj.recordid === this.UserRecord)
          if(oncheck){
            this.EventsDB.doc(doc.id).collection('Attending_Users').doc(oncheck.id).update({
              Attendance_Confirmation: this.UserAttendance
            })
          }
          else{
            this.EventsDB.doc(event.id).collection('Attending_Users').add(AttendanceRegister).then(doc => {
              console.log('created under '+doc.id)
            })
          
        }
            
            
        },
        RemoveUserFromEventFollowers(event,attendid){
          console.log('removing attempt')
            let EventFollowers = event.FollowingUsers
            let NewEventFollowingUsers = event.FollowingUsers.filter(follower => {
                return follower.id !== this.UserRecord.id
            })
                let FollowingUserIDStrings = event.FollowingUserIDStrings
                let FollowingUserIDKeys = event.FollowingUserIDKeys
            event.FollowingUsers.map(follower => {
                if(FollowingUserIDKeys[this.UserRecord.id] && FollowingUserIDKeys[this.UserRecord.id] === true){
                    delete FollowingUserIDKeys[this.UserRecord.id]
                    let followingstringobj = FollowingUserIDStrings.find(obj => obj == this.UserRecord.id)
                let followingstringindex = FollowingUserIDStrings.indexOf(followingstringobj)
                    FollowingUserIDStrings.splice(followingstringindex,1)
                }
                })
                console.log("this.UserAttendance",this.UserAttendance)
                this.EventsDB.doc(event.id).collection('Attending_Users').doc(attendid).update({
                Attendance_Confirmation: this.UserAttendance
            })
        },
        onEventImageSelect(event) {
        this.selectedEventImage = event.target.files[0]
        this.selectedEventImagelocalURL = URL.createObjectURL(this.selectedEventImage)
        this.HasImage = false
      },
        onEventImageUpload(eventid){
          let filename = this.selectedEventImage.name.split('.')[0]+'_'+this.selectedEventImage.size+'.'+this.selectedEventImage.name.split('.')[1]
          let EventImageStorageRef = ''
          if(this.$route.name === 'GroupSingle'){
            EventImageStorageRef = 'GroupEvents/'+this.$route.params.id+'/'+eventid+ '/EventImage/'+new Date()+'/'+filename
              }
              else{
                EventImageStorageRef = 'Events/'+eventid+ '/EventImage/'+new Date()+'/'+filename
              }
              this.$emit('ActivateProcessing',true)
                var storageRef = firebase.storage().ref(EventImageStorageRef);
            var uploadTask = storageRef.put(this.selectedEventImage);
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                this.EventsDB.doc(eventid).update({
                    EventImage: url,
                    EventImageStorageRef: EventImageStorageRef,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
            })
            this.$emit('ActivateProcessing',false)
            this.selectedEventImage = ''
                }),
                  this.Machine_Photofilesnackbar = true
        },
        closeEventImageUpload(){

        },
        
      GetUsers(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
      AddSelection(){
     
      let ToPush = this.RelatedFilterRecords.filter(record => {return record.selected}).map(record => {return record[this.MailFieldFilter]})
      this.Attendees = ToPush
      
      this.CancelSelection()
    },
      CancelSelection(){
      this.AttendeeSelectDialog = false
      this.ArrayFilter = ''
      this.RecordFilter = ''
      this.MailFieldFilter = ''
    },
      CheckifUser(){
      this.MailFieldFilter = ''
      if(this.EventRecordFilter === 'Users'){
        this.MailFieldFilter = 'Email'
      }
    },
      ToggleSelectDialog(){
    
      this.AttendeeSelectDialog = true
    },
    GetEventAttendingUsers(eventobj){
      let vm = this
      return new Promise(function(resolve, reject) {
        console.log('calling event')
        eventobj.Attending_Users = []
        vm.EventsDB.doc(eventobj.id).collection('Attending_Users').onSnapshot(res => {
            let arraylength = res.docs.length
            const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
              eventobj.Attending_Users.push({
                ...change.doc.data(),
                id: change.doc.id
              })
            }
            if(eventobj.Attending_Users.length === arraylength){
              resolve('Got Attending_Users')
            }
          })
        })
      })
    },
    GetEvents(EventsQuery){
       let vm = this
    EventsQuery.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let eventobj = change.doc.data()
          eventobj.id = change.doc.id
         if(!eventobj.IsBirthdayEvent){
           this.GetEventAttendingUsers(eventobj).then(function(result) {
            //console.log(result)
            vm.AllEvents.push(eventobj)
          })
         }
         else{
           eventobj.Attending_Users = []
           vm.AllEvents.push(eventobj)
         }
        }


      })
      
      changes.forEach(change => {
        if (change.type === 'added') {
          this.TempAllEvents.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    //console.log(this.AllEvents)
        },
        TimestampFormatter(d){
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        var day = days[d.getDay()];
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "am";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "pm";
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        return `<p style="font-size:12px;color:grey;">`+day + " " + date + " " + month + " " + year + " "+ hr + ":" + min + ampm + " </p>";
      },

      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        updateRange ({ start, end }) {
      this.start = start
        this.end = end
    },
    viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      ActivateNewEvent(){
        //this.EditedEvent = event replaced with below makes sense right?
        this.EditedEvent = {Attendees: []}
        this.NewEventDialog = true
      },
      AssignEventEndDate(){
        console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
      CheckAllowedMinutes(){
       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
     
      
     
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      CheckRouting(){
      
       
             //console.log(this.$route.params.id)
            // this.RelatedObj = {id: this.$route.params.id, name: this.name,identifier: 'name',collection: 'groups',Link:'Group' }
          // console.log('this.RelatedObj')
          // console.log(this.RelatedObj)
          // console.log('this.UserRecord')
          // console.log(this.UserRecord)
          // console.log('this.RelatedParentObj')
          // console.log(this.RelatedParentObj)
            //   this.defaultItem[this.RelatedObj.identifier] = this.RelatedObj
            //   this.editedItem = Object.assign({}, this.defaultItem)
            //   let queryfield = this.RelatedObj.identifier+'id'
              
            //   if(typeof this.RelatedParentObj !== 'undefined'){
            //     this.defaultItem[this.RelatedParentObj.identifier] = this.RelatedParentObj
            //     let queryfield = this.RelatedParentObj.identifier+'id'
            //     this.CollectionRef = this.CollectionRef.where(queryfield,'==',this.RelatedParentObj.id)
            //   }
            //   else{
            //     let queryfield = this.RelatedObj.identifier+'id'
            //     this.CollectionRef = this.CollectionRef.where(queryfield,'==',this.RelatedObj.id)
            //   }
              let vm = this
        this.GetSiteModerators().then(function(result) {
          if(result){
            //console.log('checking if moderator')
            let UserModeratorobj = ''
            if(vm.UserRecord){
              vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
            }            
            //console.log(vm.UserRecord)
            //console.log(UserModeratorobj)
            if(UserModeratorobj){
              if(UserModeratorobj.ModerationPermissions.includes('Events')){
                console.log('yes events included')
                vm.EventModerator = true
              }
              
            }
            if(vm.$route.name !== 'GroupSingle' && vm.$route.name !== 'GroupPage' && vm.$route.name !== 'GroupPageEditor'){
              if(vm.$store.state.UserisGuest && !vm.ChannelRestricted){
                vm.GetEvents(vm.EventsQuery.where('GuestsIncluded','==',true))
              }
              else if(!vm.$store.state.UserisGuest && !vm.ChannelRestricted || vm.userIsAdmin){
                vm.GetEvents(vm.EventsQuery) 
              }
              else{
                vm.GetEvents(vm.EventsQuery.where('PublishStatus','==','Published (Public)'))
              }
            }
            else{
              let colref = vm.EventsQuery
              if(this.$store.state.TemplateView){
                vm.GetEvents(colref)
              }
              else if(vm.userLoggedIn){                
                if(vm.UserisTeamMember){
                  vm.GetEvents(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
                }
                else if(vm.userLoggedIn && !vm.$store.state.UserisGuest){
                  vm.GetEvents(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
                }
                else{                
                  vm.GetEvents(colref.where('GuestsIncluded','==',true).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
                }   
              }
              else{
                let colref = vm.EventsQuery
                //alert('mofo')
                vm.GetEvents(colref.where('GuestsIncluded','==',true).where('PublishStatus','==','Published (Public)'))
              }
              //vm.GetEvents(vm.EventsQuery) 
            }
            if(vm.userLoggedIn){
            vm.GetUsers()
            }
          }
        })
        },
        AddNewEvent(){
          this.$emit('ActivateProcessing',true)
        // // let NEWeditorContent = document.getElementById('NewBlogValue')
        // // this.EventDetails = NEWeditorContent.innerHTML;
        // console.log(this.EventDetails)
        // console.log(this.RelatedObj)
        let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
        let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
         let NewJavaStartDate = new Date(NewStartDateString)
        let NewJavaEndDate = new Date(NewEndDateString)
        // let regardingrecord = {
        //       Link:'/'+this.RelatedObj.Link+'/'+this.$route.params.id, Name:[this.RelatedObj.identifier], [this.RelatedObj.identifier]: this.RelatedObj[this.RelatedObj.identifier],id: this.$route.params.id
        //     }
            // console.log(regardingrecord)
            let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
            this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewEvent = {
            Owner: {Full_Name: this.AppointmentOwner.Full_Name,Name: this.AppointmentOwner.Name,Surname: this.AppointmentOwner.Surname,id: this.AppointmentOwner.id},
            Ownerid: this.AppointmentOwner.id,
            Attendees: this.Attendees,
          name: this.EventName,
          whocanview: this.EventViewAccess,
          whocanjoin: this.EventJoinAccess,
          subject: this.EventSubject,
          details: this.EditedEvent.details,
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          color: 'pop',
          GuestsIncluded: this.GuestsIncluded,
        //   activitytype: 'Appointment',
        //   regardingrecord: regardingrecord,
        //   regardingtype: this.RelatedObj.Link,
        Moderators: this.Moderators,
        moderatorrolesarrayDBRules: ModeratorRolesObject,
        moderatorrolesarrayQuery: ModeratorRolesArray,
        creatorfullname: this.UserRecord.Full_Name,
        creatorid: this.UserRecord.id,
        status: 'Open',
        AllDayEvent: this.AllDayEvent,
        Location: this.EventLocation,
        EventisMapLocation: this.EventisMapLocation,
        
        }
        if(this.$route.name === 'GroupSingle'){
          NewEvent.groupid = this.$route.params.id
        }
        if(!NewEvent.groupid && this.userLoggedIn && this.userLoggedIn.Company){
          NewEvent.Company = this.userLoggedIn.Company
          NewEvent.Companyid = this.userLoggedIn.Companyid
        }
        let nameQuery = []
        let nameStringArray = NewEvent.name.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(nameStringArray.length,nameStringArray)
          for (p = 0; p < nameStringArray.length; p++) {
              buildup = buildup+ nameStringArray[p]
                if(p === nameStringArray.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
                }                   
          nameQuery = lowertext.split(',')  
          nameQuery = nameQuery.concat(NewEvent.name.split(' ')) 
          console.log(nameQuery)
          NewEvent.nameQuery = nameQuery.map(entry => {
                          return entry.toLowerCase()
                        })
        // if(this.EventisMapLocation && AddressStreet_Number && AddressStreet_Name && AddressSuburb && AddressCity && AddressPostal_Code && AddressState_Province && AddressCountry_Region){
        //     NewEvent.AddressStreet_Number =  this.AddressStreet_Number
        //     NewEvent.AddressStreet_Name = this.AddressStreet_Name
        //     NewEvent.AddressSuburb = this.AddressSuburb
        //     NewEvent.AddressCity = this.AddressCity
        //     NewEvent.AddressPostal_Code = this.AddressPostal_Code
        //     NewEvent.AddressState_Province = this.AddressState_Province
        //     NewEvent.AddressCountry_Region = this.AddressCountry_Region
        // }

        if(this.EventisMapLocation){
            NewEvent.EventLocationLink =  this.EventLocationLink
        }
            
        console.log(NewEvent)
        if(this.UserRecord.id === this.AppointmentOwner.id){
            NewEvent.FollowingUsers = [{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        else{
            NewEvent.FollowingUsers = [{id: this.AppointmentOwner.id, Name: this.AppointmentOwner.Name, Surname: this.AppointmentOwner.Surname, Full_Name: this.AppointmentOwner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        if(this.Attendees.length > 0){
          NewEvent.AttendeeUserIDStrings = []
          this.Attendees.map(attendee => {
            let userobj = this.UsersArray.find(obj => obj.Email === attendee)
            if(userobj){
              let attendeeobj = {id: userobj.id, Name: userobj.Name, Surname: userobj.Surname, Full_Name: userobj.Full_Name}
              NewEvent.AttendeeUserIDStrings.push(attendeeobj.id)
              let followingobj = NewEvent.FollowingUsers.find(obj => obj.id === attendeeobj.id)
              if(!followingobj){
                NewEvent.FollowingUsers.push(attendeeobj)
              }
            }
          })
        }
    //     if(typeof this.Business_Unit.id !== 'undefined'){
    //     NewEvent.Business_Unitid = this.Business_Unit.id
    //   }
      let FollowingUserIDStrings = []
       let FollowingUserIDKeys = {}
       NewEvent.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       NewEvent.FollowingUserIDKeys = FollowingUserIDKeys
       NewEvent.FollowingUserIDStrings = FollowingUserIDStrings
       

        let primaryuser = {Full_Name: this.AppointmentOwner.Full_Name,Name: this.AppointmentOwner.Name,Surname: this.AppointmentOwner.Surname,recordid: this.AppointmentOwner.id, Attendance_Confirmation: {Name: 'Going',ID: 1000001}}
        if(this.GroupData){
          primaryuser.groupid = this.GroupData.id
        }
        this.Attending_Users = [primaryuser],
       
        console.log(NewJavaStartDate)
        console.log(NewJavaEndDate)
        if(!this.EditedEvent.id){
          console.log(NewEvent)
          
          this.$emit('ActivateProcessing',false)
        this.EventsDB.add(NewEvent).then(doc => {
          this.Attending_Users.map((user,i) => {
            this.EventsDB.doc(doc.id).collection('Attending_Users').add(user).then(attenddoc => {
              console.log('added user for attendance under '+attenddoc.id)
            })            
          })
          if(this.HasImage === false){
            this.onEventImageUpload(doc.id)
          }
            const systemactivity = {
                            user: this.UserRecord,
                            contentvar: 'created a new Event',
                            location: '/Events/',
                            docname: NewEvent.name,
                            docid: doc.id,
                            type: 'New Event',
                          }                          
                          this.$store.dispatch('createSystemActivity',systemactivity)
        })
        }
        else {
          if(this.HasImage === true){
            NewEvent.EventImage = this.selectedEventImagelocalURL
            this.$emit('ActivateProcessing',false)
             this.$store.dispatch('CreateSearchTitle',NewEvent.name).then(searchtitle => {
              NewEvent.nameQuery = searchtitle
              this.EventsDB.doc(this.EditedEvent.id).set(NewEvent).then(doc => {
            })
             })
          }
          else{
            this.$emit('ActivateProcessing',false)
             this.$store.dispatch('CreateSearchTitle',NewEvent.name).then(searchtitle => {
              NewEvent.nameQuery = searchtitle
              this.EventsDB.doc(this.EditedEvent.id).set(NewEvent).then(doc => {
              this.onEventImageUpload(this.EditedEvent.id)
            })
        })
          }
        
        }
        this.AllDayEvent = false
        this.EventSubject = ''
        this.EventName = ''
        this.EventDetails = ''
        this.EventStartDate = ''
        this.EventStartTime = ''
        this.EventEndDate = ''
        this.EventEndTime = ''
        this.EventViewAccess = ''
        this.EventJoinAccess = ''
        this.EditedEvent = {Attendees: []}
        
        this.NewEventDialog = false        
      },
    },
    

}
</script>

<style>

</style>