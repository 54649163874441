<template>
  <v-card tile flat class="transparent" height="100%" :dark="AppisDarkMode">
     <v-layout row class="mx-3 justify-center" style="margin-top:50px;" v-if="CompProperties.Style === 'Pipeline'">  
        
      <v-select dense @change="CheckforCharts(elmnt,i,index)" clearable label="Sub Label" :items="PipelineSublabelFields" item-text="id" return-object v-model="elmnt.SublabelField"/>
      <v-select dense v-if="DashboardEntity" label="Value Type" :readonly="!DashboardEntity.NumberFields || DashboardEntity.NumberFields && DashboardEntity.NumberFields.length === 0" return-object :items="['Count','Amount']" item-text="id" v-model="elmnt.ValueType"/>
      <v-select dense @change="CheckforCharts(elmnt,i,index)" v-if="elmnt.ValueType === 'Amount'" label="Value Field" return-object :items="DashboardEntity.NumberFields" item-text="id" v-model="elmnt.ValueField"/>
    <v-select dense @change="CheckforCharts(elmnt,i,index)" v-if="elmnt.ValueType === 'Amount'" label="Value Type" return-object :items="['ZAR']" item-text="id" v-model="elmnt.ValueCurrency"/> 
    <vue-funnel-graph style="padding:50px;" v-if="HasData && elmnt.ChartLabelsfromProcess" :width="width" :height="height" :labels="elmnt.ChartLabels.map(label => {return label.LabelName})"
        :values="elmnt.ChartValues" :colors="elmnt.ChartColors" :sub-labels="elmnt.NEWSubLabelsdata.map(label => {return label.Name})" :direction="elmnt.direction"
        :gradient-direction="gradientDirection"
        :animated="true" :display-percentage="true"
    ></vue-funnel-graph>
    
    </v-layout>
    <v-card :class="elmnt.ChartType === 'line' && !elmnt.HasDataContext ? elmnt.ChartBG : ''" height="100%" tile :id="'row'+i+'element'+index+'chart'" style="padding:25px;" v-if="CompProperties.Style === 'Pie'">
         
    </v-card>
    <div class="col mx-0 justify-center my-3" v-if="CompProperties.Style === 'Top'">
    <v-card dark :color="elmnt.ChartBG" class="mx-10" round elevation="10" width="80%" height="350" style="margin-bottom:-70px;z-index:1;">               
        <v-card-title class="justify-center overline white--text my-1" style="align:center;" v-if="elmnt.TimeFilterOption">
                {{elmnt.TimeFilterOption.Name}}<v-icon x-small @click="CheckforCharts(elmnt,i,index)">mdi-refresh</v-icon><v-menu 
                v-if="!ViewOnly"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-palette</v-icon>
                    </template>
                <v-color-picker v-model="elmnt.ChartBGObj"></v-color-picker>
                </v-menu>                                   
        </v-card-title>
        <v-card style="padding:20px;" flat class="transparent" height="220" tile :id="'row'+i+'element'+index+'chart'">                
        </v-card>
    </v-card>
    <v-card rounded width="100%">
                <v-card-actions class="mediumoverline" style="text-transform:uppercase;padding-top:140px;">
           
                   
                    <v-spacer></v-spacer>
                    <!-- {{FilterStaticTimeline({id:3},elmnt)}} -->
                    {{elmnt.TotalValue}}
                </v-card-actions>
          <!-- <v-btn class="mx-2 my-6" small @click="ActivateRenderContext()">Get Context</v-btn>      -->
           <v-row class="justify-center" v-if="elmnt.ComponentTwo.Style === 'Cards' && elmnt.ShowSummary">
            <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" >
            <v-card class="mx-4" :color="elmnt.ContextTitleColor" round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                    <v-card-title class="justify-center" style="align:center;">
                <v-avatar tile size="70" class="transparent mx-3" >
                    <v-img contain src="@/assets/RAInvert.png"/>
                </v-avatar>
                    </v-card-title>
                </v-card>
                <v-card rounded >
                    <v-card-title class="justify-end overline">
                        {{elmnt.ContextTitle}}
                    </v-card-title>
                    <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                    {{elmnt.ContextValue}}
                    </v-card-title>
                    <v-divider>
                    </v-divider>
                    <v-card-actions v-if="!elmnt.HideActions">
                        {{elmnt.Description}}
                        <v-spacer>
                        </v-spacer>
                        <v-btn class="green--text" x-small>
                            <v-icon x-small color="green">mdi-filter</v-icon>Filter
                            </v-btn>
                            <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                            <v-icon x-small color="blue">mdi-database</v-icon>View Data
                            </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-row>
        <v-row class="justify-center" v-if="elmnt.ComponentTwo.Style === 'Cards' && !elmnt.ShowSummaryOnly">
            <v-flex xl3 lg3 md4 sm4 xs6 class="mx-1 my-2" v-for="arritem in elmnt.Context" :key="arritem.itemObjKey">
            <v-card class="mx-4" :color="arritem.ContextTitleColor" round elevation="10" width="100" height="100" style="margin-bottom:-60px;z-index:1;">
                    <v-card-title class="justify-center" style="align:center;">
                <v-avatar tile size="70" class="transparent mx-3" >
                    <v-img contain src="@/assets/RAInvert.png"/>
                </v-avatar>
                    </v-card-title>
                </v-card>
                <v-card rounded >
                    <v-card-title class="justify-end overline">
                        {{arritem.ContextTitle}}
                    </v-card-title>
                    <v-card-title class="mediumoverline justify-end" style="text-transform:uppercase;">
                    {{arritem.ContextValue}}
                    </v-card-title>
                    <v-divider>
                    </v-divider>
                    <v-card-actions v-if="!elmnt.HideActions">
                        {{arritem.Description}}
                        <v-spacer>
                        </v-spacer>
                        <v-btn class="green--text" x-small>
                            <v-icon x-small color="green">mdi-filter</v-icon>Filter
                            </v-btn>
                            <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                            <v-icon x-small color="blue">mdi-database</v-icon>View Data
                            </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-row>
            <v-row class="justify-center" v-if="elmnt.ComponentTwo.Style === 'List' && elmnt.ShowSummary">
        
          <v-flex xl12 lg12 md12 sm12 xs12 >
            <v-card height="100%" class="mx-3" tile  :dark="AppisDarkMode">
                <v-card-title class="mediumoverline">
                         {{elmnt.Title}}<v-spacer></v-spacer>{{elmnt.ContextValue}}
                        </v-card-title>
                         <v-card-text v-if="elmnt.GoalMeasurements">
                            <v-list dense class="transparent" width="100%">
                                <!-- <v-list-item-title class="mediumoverline">
                                     <span :class="elmnt.AchievementClass">
                                    {{elmnt.Achievement}}</span>
                                </v-list-item-title>
                                <v-list-item class="overline">
                                    Target <v-spacer></v-spacer>{{elmnt.ContextTarget}}
                                </v-list-item>
                                <v-list-item class="overline">
                                    Achieved <v-spacer></v-spacer>{{elmnt.ContextValue}}
                                </v-list-item> -->
                                <v-list-item v-for="breakdown in elmnt.Timelines" :key="breakdown.itemObjKey" >
                                    <v-list-item-avatar>
                                            <v-icon small>mdi-vote</v-icon>
                                        </v-list-item-avatar>
                                    <v-list-item-content>
                                    <v-list-item-title>
                                        {{breakdown.Name}}
                                        </v-list-item-title>                    
                                        <v-list-item-subtitle>
                                        <v-progress-linear :color="breakdown.Color" :value="breakdown.Value"></v-progress-linear>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                        {{breakdown.AchievedText}}  ({{breakdown.Achieved}} out of {{breakdown.Target}})
                                        </v-list-item-subtitle>  
                                        <!-- <v-list-item-subtitle>
                                        {{breakdown.Achieved}} out of {{breakdown.Target}}
                                        </v-list-item-subtitle>                         -->
                                    </v-list-item-content>
                                    <v-list-item-content>
                                    <v-list-item-title class="justify-end" v-if="breakdown.PercentageText">
                                        <h2 :class="breakdown.IncreaseColor+'--text justify-end caption'"><v-icon :color="breakdown.IncreaseColor">mdi-menu-up</v-icon>
                                                {{breakdown.PercentageText}}</h2>
                                        </v-list-item-title>                    
                                        <v-list-item-subtitle class="caption justify-end">
                                            {{breakdown.Period}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="breakdown.NotificationCount && breakdown.NotificationCount>0">
                                            <v-menu
                                                open-on-hover
                                                top
                                                offset-y
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                                                mdi-bell-check
                                                </v-icon>
                                                <v-badge color="warning" class="mx-1" v-if="breakdown.NotificationCount && breakdown.NotificationCount>0" overlap>
                                                    <span slot="badge"> {{breakdown.NotificationCount}}</span>
                                                    </v-badge>
                                                </template>
                                                <v-card max-width="400">
                                                <v-card-title>
                                                    Notifications
                                                </v-card-title>
                                                <v-card-text class="caption"  v-html="breakdown.Notice">

                                                </v-card-text>
                                                </v-card>
                                                
                                            </v-menu>
                                            </v-list-item-action>
                                    <v-list-item-action v-if="!elmnt.HideActions">
                                        <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(breakdown)">
                                            <v-icon x-small color="blue">mdi-database</v-icon>View Data
                                            </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            
                        </v-card-text>
                    <v-list dense>
                        
                        <v-list-item  v-if="!elmnt.GoalMeasurements && !elmnt.HideActions">
                           <v-btn class="green--text" x-small>
                                <v-icon x-small color="green">mdi-filter</v-icon>Filter
                                </v-btn>
                                <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                                <v-icon x-small color="blue">mdi-database</v-icon>View Data
                                </v-btn>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                       <span class="caption grey--text"><v-icon small>mdi-timer-outline</v-icon> Just Now</span>
                        </v-list-item>
                    </v-list>
                    
                    </v-card>
          </v-flex>
      </v-row>
      <v-row class="justify-center" v-if="elmnt.ComponentTwo.Style === 'List' && !elmnt.ShowSummaryOnly">
         
          <v-flex xl12 lg12 md12 sm12 xs12 >
            <v-card height="100%" class="mx-3" tile  :dark="AppisDarkMode">
                <v-card-title class="mediumoverline">
                        <v-spacer></v-spacer>{{elmnt.ContextValue}}
                        </v-card-title>
                    <v-list dense v-if="!elmnt.IsActivities">
                        <div v-for="arritem in elmnt.Context" :key="arritem.itemObjKey">
                        <v-list-item>
                            <v-list-item-content class="overline">
                                {{arritem.ContextTitle}}
                            </v-list-item-content>
                            <v-list-item-content class="mediumoverline">
                                {{arritem.ContextValue}}
                            </v-list-item-content>
                        <v-list-item-content class="justify-end overline" v-if="!elmnt.HideActions">
                                <v-btn class="green--text" x-small>
                                <v-icon x-small color="green">mdi-filter</v-icon>Filter
                                </v-btn>
                                <v-btn class="blue--text" x-small @click="ActivateActiveDashboardTable(arritem)">
                                <v-icon x-small color="blue">mdi-database</v-icon>View Data
                                </v-btn>
                            </v-list-item-content>
                    
                        </v-list-item>
                        <v-divider></v-divider>
                        </div>
                    </v-list>
                    <v-list :dark="AppisDarkMode" v-if="elmnt.IsActivities">
                         <v-list-item-title >
                                <v-select @change="CheckforCharts(elmnt,i,index)" class="mx-2" dense  label="Owner" return-object :items="UsersArray" item-text="Full_Name" clearable v-model="elmnt.ActivityOwnerFilter"/>
                                </v-list-item-title>
                        <v-menu  v-for="item in elmnt.RawData" :key="item.itemObjKey"
                                transition="scale-transition"
                                right
                                open-on-hover
                                top
                                max-width="400"
                            >
                        <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" >
                            {{item.name}}
                        </v-list-item>
                        </template>
                        <v-card dark>
                        <v-card-title class="mediumoverline" style="text-transform:uppercase;">{{item.name}}
                            </v-card-title>
                            <v-card-subtitle>
                            <v-list :dark="AppisDarkMode" class="transparent">
                            <v-list-item>
                                <v-list-item-avatar class="blue" tile size="50">
                                <img v-if="item.Owner.Profile_Photo "
                                :src="item.Owner.Profile_Photo "    
                                style="object-fit: cover;"          
                                >
                                <img v-if="!item.Owner.Profile_Photo "
                                    src="@/assets/BlankProfilePic.png"   
                                    style="object-fit: cover;"           
                                >                      
                                </v-list-item-avatar>
                                <v-list-item-content >
                                <v-list-item-title>Owner - {{item.Owner.Full_Name}}</v-list-item-title>
                                <v-list-item-subtitle v-if="item.Due_Date.toDate">
                                    Due By {{item.Due_Date.toDate()}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    Due By {{item.Due_Date}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.Created_On.toDate">
                                    Created On {{item.Created_On.toDate()}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    Created On {{item.Created_On}}
                                </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>                   
                            </v-card-subtitle>
                            <v-divider>
                            </v-divider>
                            <v-card-text>
                            {{item.Description}}
                            </v-card-text>
                            <v-divider>
                            </v-divider>
                            <v-card-actions v-if="item.Regarding_Record">
                            <span class="blue--text overline">
                            {{item.Regarding_Record.Name}}
                            </span>
                            <v-spacer>
                            </v-spacer>
                            <v-btn target="_blank" :to="item.Regarding_Record.Link">Regarding</v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-menu>
                        </v-list>
                    </v-card>
          </v-flex>
      </v-row>
         <!-- <DashboardElmtDataContext @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 
        :HasData="HasData" :i="i" :index="index" :CompProperties="elmnt.ComponentTwo" :elmnt="elmnt" /> -->
        <!-- <DashboardElmtChart v-if="!LoadingDashboardElmtChart" @ActivateActiveDashboardTable="ActivateActiveDashboardTable"
        :HasData="HasData" :i="i" :index="index" :CompProperties="elmnt.ComponentTwo" :elmnt="elmnt" /> -->
           
        <!-- <v-divider>
        </v-divider>
        <v-card-title class="caption grey--text">
            <v-icon small>mdi-clock-outline</v-icon> updated 2 mins ago
            <v-spacer>
            </v-spacer>
            <v-btn  v-if="!elmnt.IsActivities" small  @click="ActivateActiveDashboardTable(elmnt,true,elmnt.TimelineFilter)">View Table</v-btn>
        </v-card-title> -->
    </v-card>
    </div>
  </v-card>
</template>

<script>
// import { VueFunnelGraph } from 'vue-funnel-graph-js';
import DashboardElmtDataContext from '@/components/SuitePlugins/CustomDashboard/DashboardElmtDataContext'
import DashboardElmtChart from '@/components/SuitePlugins/CustomDashboard/DashboardElmtChart'

export default {
    props: ['elmnt','CompProperties','HasData','i','index','UsersArray','AppisDarkMode','DashboardEntity'],
    components: {
        // VueFunnelGraph,
        DashboardElmtDataContext,
        DashboardElmtChart,
        },
    data(){
        return{
            gradientDirection: 'horizontal',
          height: 300,
          width: 800,
          LoadingDashboardElmtDataContext: true,
          LoadingDashboardElmtChart: true,
          ChildCompName: '',
        }
    },
    computed: {
        PipelineSublabelFields(){
            return this.DashboardEntity.AllFields
            .filter(field => {
                return field.Type === 'Option Set' && field.Options.length < 8
            })
        }
    },
    created(){
        this.ActivateRenderContext()
    },
    methods:{
        CheckforCharts(elmnt,i,index){
            this.$emit('CheckforCharts',elmnt,i,index)
        },
        ActivateRenderContext(){
            if(this.CompProperties.Style === 'Top'){
             this.ChildCompName = ''
            
            setTimeout(() => {
                 
                this.ChildCompName =  this.elmnt.ComponentTwo.CompName 
                if(this.elmnt.ComponentTwo.CompName === 'DashboardElmtDataContext'){
                    this.LoadingDashboardElmtDataContext = false
                }
                else if(this.elmnt.ComponentTwo.CompName === 'DashboardElmtChart'){
                   this.LoadingDashboardElmtChart = false
                }
                //console.log('LoadingDashboardElmtDataContext',this.LoadingDashboardElmtDataContext)
            }, 200);   
            }
            
            
        },
        ActivateActiveDashboardTable(arritem,IsParent,item){
            this.$emit('ActivateActiveDashboardTable',arritem,IsParent,item)
        },
    }
}
</script>

<style>

</style>


    