        <template>
    <div style="margin-top: -14px;">
        <v-dialog v-model="AutomationDialog" width="400" persistent>
        <WorkFlowBuilder v-if="AutomationDialog" :BuildAutomations="BuildAutomations" @ToggleAutomationDialog="ToggleAutomationDialog"
        :System="System" :SystemEntities="DashboardSystemEntities" :CurrentEntity="CurrentEntity"

        />
         </v-dialog>
      
        <v-navigation-drawer v-if="ActiveAppMode === 'Bird View' && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs && $route.name === 'MyDashboard'" class="elevation-4 hidden-sm-and-down" app height="100%" v-model="Navigation" :dark="RenderDark" >
            <v-list-item :class="ThemeColor">
                <v-list-item-content>
                    <v-list-item-title class="justify-space-between'" style="display: flex;align-items: center;">
                        <h1 :class="ThemeHeaderColor+'--text mediumoverline'">{{ComputedDashboardTitle}}</h1>
                    </v-list-item-title>
                    <v-list-item-subtitle :class="ThemeHeaderColor+'--text'">
                        {{GroupTitle}}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                    <v-icon @click="ReloadPage()" small :class="ThemeHeaderColor+'--text'">mdi-refresh</v-icon>
                </v-list-item-avatar>  
            </v-list-item>
            <v-list
            class="transparent"
            dense                
            nav
                v-for="item in ComputedNavigation"
            :key="item.title"
            >
            <v-list-item dense class="overline">
            <v-list-item-icon>
                <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title :class="ThemeColor+'--text'">{{RouteName}} {{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider>
                </v-divider>
                <v-list
                dense>
                <div v-for="child in item.Children"
                    :key="child.title">
                <v-list-item dense v-if="!child.Children || child.Children && child.Children.length<0"
                class="justify-start"
                @click="ActivateSection(child)"
                    
                >
                <v-list-item-icon>                        
                        <v-icon size="20">{{ child.icon }}</v-icon><v-badge color="warning" class="mx-1" v-if="child.notifications" overlap>
                        <span slot="badge"> {{child.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>                            
                        <v-list-item-title style="font-weight:400;">{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-group active-class="dbnavlist--text"
              v-if="child.Children && child.Children.length" dense
              >
                <template v-slot:activator>
                  <v-list-item-action>
                  <v-icon small class="actionicon" color="accent">{{ child.icon }}</v-icon>
                </v-list-item-action>
                  <v-list-item-title class="navoverline">{{child.title}}</v-list-item-title>
                </template>
                <div v-for="subsub in child.Children"
                        :key="subsub.itemObjKey">
                        <v-list-item  
                class="justify-start"
                @click="ActivateSection(subsub)"
                    
                >
                <v-list-item-icon>                        
                        <v-icon size="20">{{ subsub.icon }}</v-icon><v-badge color="warning" class="mx-1" v-if="subsub.notifications" overlap>
                        <span slot="badge"> {{subsub.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>                            
                        <v-list-item-title style="font-weight:400;">{{ subsub.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </div>
                </v-list-group>
                </div>
                </v-list>
            </v-list>
                <v-divider>
            </v-divider>
            
        </v-navigation-drawer>
        <v-navigation-drawer v-if="SecondaryNavigation && !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && ActiveAppMode === 'Super Admin'" right app height="100%" v-model="SecondaryNavigation" :dark="RenderDark" >
           
            <v-list-item :class="ThemeColor">
                <v-list-item-content>
                    <v-list-item-title class="justify-space-between'" style="display: flex;align-items: center;">
                        <h1 :class="ThemeHeaderColor+'--text mediumoverline'">{{ComputedSecondaryDashboardTitle}}</h1>
                    </v-list-item-title>
                    <v-list-item-subtitle :class="ThemeHeaderColor+'--text'">
                        {{GroupTitle}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list
            class="transparent"
            dense                
            nav
                v-for="item in ComputedSecondaryNavigation"
            :key="item.title"
            >
            <v-list-item class="overline">
            <v-list-item-icon>
                <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title :class="ThemeColor+'--text'">{{RouteName}} {{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider>
                </v-divider>
                <v-list
                dense>
                <v-list-item 
                class="justify-start"
                @click="ActivateSection(child)"
                    v-for="child in item.Children"
                    :key="child.title"
                >
                <v-list-item-icon>                        
                        <v-icon size="20">{{ child.icon }}</v-icon><v-badge color="warning" class="mx-1" v-if="child.notifications" overlap>
                        <span slot="badge"> {{child.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>                            
                        <v-list-item-title style="font-weight:400;">{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list>
            </v-list>
                <v-divider>
            </v-divider>
            
        </v-navigation-drawer>

        <v-main :dark="RenderDark" :class="ThemeColor+' lighten-5 fill-height'" style="padding-top:60px;">
             
            <v-card width="100%" height="100%" :dark="RenderDark" tile>
                <v-card-title :class="ThemeColor+' justify-start '+ThemeHeaderColor+'--text largeoverline'">
                    
                    My {{ActiveSession.title}}
                    <v-spacer></v-spacer>
                        <v-dialog
                            max-width="400"
                            >
                            <template v-slot:activator="{ on }">
                                <v-icon :dark="RenderDark" :class="ThemeHeaderColor+'--text'" v-on="on">mdi-palette-advanced</v-icon>
                            </template>
                            <v-card max-width="400">
                            <v-autocomplete :items="LibraryColors" label="Theme Color" @change="UpdateThemeColors()" v-model="ThemeColor">
                                <template v-slot:item="{ item }">
                                    <span :class="item">{{item}}
                                        <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                        </p>
                                        </span>
                                </template>
                            </v-autocomplete>
                            <v-autocomplete :items="LibraryColors" label="Header Color" @change="UpdateThemeColors()" v-model="ThemeHeaderColor">
                                <template v-slot:item="{ item }">
                                    <span :class="item">{{item}}
                                        <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog
                                        </p>
                                        </span>
                                </template>
                            </v-autocomplete>
                            <v-switch v-model="MyDashDark" @change="UpdateThemeColors()" label="Always Dark"/>
                            </v-card>
                            </v-dialog>
                    <v-spacer></v-spacer>
                    <v-icon v-if="userIsAdmin && !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && ActiveAppMode === 'Super Admin'" :class="ThemeHeaderColor+'--text hidden-sm-and-down'" @click="SecondaryNavigation = !SecondaryNavigation">mdi-menu</v-icon>
                    <v-icon v-if="ActiveAppMode === 'Bird View'" :class="ThemeHeaderColor+'--text hidden-sm-and-down'" @click="Navigation = !Navigation">mdi-menu</v-icon>
                    
                </v-card-title>
                <v-card-subtitle style="padding-top:10px;" :class="ThemeColor+' justify-space-between '+ThemeHeaderColor+'--text overline'" v-if="ActiveSession && ActiveSession.AllFields">                    
                    {{ActiveSession.Entity_Type.Name}}<br><v-btn v-if="!$route.params.id" @click="ActivatePipelineworkflowdialog()" small color="pop" dark><v-icon small>mdi-auto-fix</v-icon>Workflows</v-btn>
                </v-card-subtitle>
                <!-- style="padding: 0px;" -->
                <v-card-text >
                
                    <component v-if="ActiveSession && GotEntities && ActiveSession.ComponentName || $route.params.id && GroupData && ActiveSession && ActiveSession.ComponentName"  :is="ActiveSessionComponent"  :MenuItem="ActiveSession" :UsersArray="UsersArray"
                    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="ActiveSession.ComponentName === 'DashboardBuilder' ? SystemEntities : DashboardSystemEntities" :GroupData="GroupData"
                    :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
                    :TeamView="TeamView" :ThemeColor="ThemeColor" :ActiveSession="ActiveSession" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications"
                    :GroupLandingPageViews="GroupLandingPageViews" :System="System" :EntityRecord="ActiveSession.EntityRecord" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
                    :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj"/>
                </v-card-text>
                <!-- <v-card-text style="display:none">
                    <span v-for="item in FinalNavigation" :key="item.itemObjKey">
                    <component :is="item.ComponentName" :UsersArray="UsersArray" :DashboardSystemEntities="DashboardSystemEntities" v-if="GotEntities || $route.params.id && GroupData && ActiveSession"
                    @PushArray="PushArray" :MenuItem="item" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyActivitiesArray="MyActivitiesArray"
                    :TeamView="TeamView" :ThemeColor="ThemeColor" :ActiveSession="ActiveSession" :ThemeHeaderColor="ThemeHeaderColor"
                    @GetClientLiaison="GetClientLiaison" :SiteModerators="SiteModerators" :Notifications="Notifications"
                    :AppisDarkMode="RenderDark" />
                    
                </span>
                </v-card-text> -->
            </v-card>
        </v-main>
          <BottomNavigation v-if="BottomNavigationActive" style="position: fixed;bottom: 50px;z-index: 5;" :BottomNavigationItems="BottomNavigationItems" :ThemeColor="ThemeColor"
    :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements" :BottomMenu="false" :TopMenu="true"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
    </div>
  
</template>


<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import MyGoals from '@/components/SuitePlugins/Goals/MyGoals'
import MyActivePipeline from '@/components/SuitePlugins/Pipelines/MyActivePipeline'
import MyArticles from '@/components/SuitePlugins/Articles/MyArticles'
import MyMeetups from '@/components/SuitePlugins/Meetups/MyMeetups'
import MyBlogs from '@/components/SuitePlugins/Blogs/MyBlogs'
import MyClassifieds from '@/components/SuitePlugins/Classifieds/MyClassifieds'
import MyEvents from '@/components/SuitePlugins/Events/MyEvents'
import MyPolls from '@/components/SuitePlugins/Polls/MyPolls'
import MyActivities from '@/components/SuitePlugins/Activities/MyActivities'
import MyTeamActivities from '@/components/SuitePlugins/Activities/MyTeamActivities'
import Timesheets from '@/components/SuitePlugins/Timesheets/Timesheets'
import MyForumThreads from '@/components/SuitePlugins/Forums/MyForumThreads'
import MyGroups from '@/components/SuitePlugins/Groups/MyGroups'
import UserInvites from '@/components/Dashboards/UserInvites'
import MyNoticeBoardPosts from '@/components/SuitePlugins/DigitalNoticeBoard/MyNoticeBoardPosts'
import MySocialStats from '@/components/SocialNetwork/MySocialStats'
import GroupDirectoriesDashboard from '@/components/SuitePlugins/Groups/GroupDirectoriesDashboard'
import MyGroupPageStats from '@/components/SuitePlugins/Groups/MyGroupPageStats'
import SocialMediaPlatforms from '@/components/SuitePlugins/SocialCentre/SocialMediaPlatforms'
import WorkFlowBuilder from '@/components/SuitePlugins/Automations/WorkFlowBuilder'
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder'
import BottomNavigation from '@/components/Navigation/BottomNavigation'
import TeamInbox from '@/components/SuitePlugins/Activities/TeamInbox'
export default {
    props: ['AppisDarkMode','UsersArray','Notifications','MyActivitiesArray','Directories'
    ,'MyGoals','MyGoalsisEmpty','SiteModerators','NotificationObj','UnreadNotifications','System','GroupEntities','SystemEntities','PushedNavItem','UserDBNavList'],
    components: {
        TeamInbox,
        WorkFlowBuilder,
        SocialMediaPlatforms,
        MyGoals,
        MyActivePipeline,
        MyArticles,
        MyMeetups,
        MyBlogs,
        MyClassifieds,
        MyEvents,
        MyPolls,
        MyTeamActivities,
        MyActivities,
        Timesheets,
        MyForumThreads,
        MyGroups,
        UserInvites,
        MyNoticeBoardPosts,
        MySocialStats,
        GroupDirectoriesDashboard,
        MyGroupPageStats,
        DataCollectionViewComponent,
        DashboardBuilder,
        BottomNavigation
        },
    data(){
        return{
            SocialAnalytics: [{RouteEventID: 1004, Name: 'Articles', Array: 'SiteArticlesAnalytics'}],
            SiteArticlesAnalytics: [],
            AppModes: [
        {ID: 1000001, Name: 'Bird View',icon: 'mdi-bird'},
        {ID: 1000002, Name: 'Team',icon: 'mdi-account-group'},
        {ID: 1000003, Name: 'Admin',icon: 'mdi-file-cabinet'},
        {ID: 1000004, Name: 'Community',icon: 'mdi-home-group'},
        {ID: 1000005, Name: 'Super Admin',icon: 'mdi-shield-key'}
      ],
            HasBottomNavigation: true,
            UserDashboards: [],
            GroupLandingPageViews: [],
            BuildAutomations: [],
            AutomationDialog: false,
            SecondaryNavigation: false,
            GotEntities: false,
            DashboardSystemEntities: [],
            GroupData: '',
            GroupTitle: '',
            Navigation: true,
            MyDashDark: false,
            ThemeHeaderColor: 'white',
            LibraryColors: [
        'red',
        'white',
        'black',
        'pink',
        'purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey'
      ],
      ThemeColor: 'blue',
            ActiveSession: '',
            secondaryitems: [
                { title: 'Marketing', icon: 'mdi-monitor-cellphone-star',GroupsEnabled: true,
                Children: [
                     { title: 'Social Pages', icon: 'mdi-facebook',IsRoute: true,ComponentName: 'SocialMediaPlatforms' },
                    // leads should fall under Social brands comp { title: 'Social Brand Leads', icon: 'mdi-emoticon-excited-outline',ComponentName: 'SocialMediaPlatforms' },                     
                     { title: 'Campaigns', icon: 'mdi-satellite-uplink',IsRoute: true,ComponentName: 'Campaigns' },
                     { title: 'Campaign Builder', icon: 'mdi-monitor-cellphone-star',IsRoute: true,ComponentName: 'CampaignBuilder' },
                ]  
               },

               { title: 'Support', icon: 'mdi-clipboard-list',GroupsEnabled: true,
                Children: [
                    { title: 'Goals', icon: 'mdi-bullseye-arrow',IsRoute: true,ComponentName: 'Goals' },
                    { title: 'System Config', icon: 'mdi-cogs',IsRoute: true,ComponentName: 'SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID },
                    { title: 'System Tickets', icon: 'mdi-ticket-confirmation',IsRoute: true,ComponentName: 'SystemTickets' },
                    { title: 'Chatbot', icon: 'mdi-robot',IsRoute: true,ComponentName: 'ChatbotBuilder' },
                    { title: 'Roadmap', icon: 'mdi-road-variant',IsRoute: true,ComponentName: 'Roadmap' },
                    { title: 'RM Admin', icon: 'mdi-application-import',IsRoute: true,ComponentName: 'RMAdministration' },
                    { title: 'Data Imports', icon: 'mdi-database-import',IsRoute: true,ComponentName: 'DataImports' },
                    
                ]  
               },
               
               //Need to add Security Overview, Group Featured Members, Business Members
               { title: 'Security', icon: 'mdi-security',GroupsEnabled: true,
                Children: [                                        
                    { title: 'Security Overview', icon: 'mdi-server-security',IsRoute: true,ComponentName: 'SecurityOverview' },
                    { title: 'Business Units', icon: 'mdi-security-network',IsRoute: true,ComponentName: 'BusinessUnits' },
                    { title: 'Users', icon: 'mdi-tag-faces',IsRoute: true,ComponentName: 'Users' },
                    { title: 'User Invites', icon: 'mdi-tag-faces',ComponentName: 'UserInvites' },                    
                ]  
               },
            //    { title: 'Custom Dashboards', icon: 'mdi-clipboard-list',GroupsEnabled: true,
            //     Children: [
            //     ]  
            //    },
            ],
            items: [     
                { title: 'Goals', icon: 'mdi-bullseye-arrow', 
                 Children: [
                     {title: 'Goals', icon: 'mdi-bullseye-arrow',ComponentName: 'MyGoals'}
                 ],},
                 { title: 'Dashboards', icon: 'mdi-monitor-dashboard', 
                 Children: [
                     
                 ],},
             { title: 'Activities', icon: 'mdi-clipboard-list',GroupsEnabled: false,
            Children: [
            // { title: 'Tasks', icon: 'mdi-clipboard-list',ComponentName: 'MyTasks' },
            // { title: 'Appointments', icon: 'mdi-calendar-month',ComponentName: 'MyAppointments' },
            { title: 'Activities', icon: 'mdi-clipboard-list',ComponentName: 'MyActivities' },
            { title: 'Team Activities', icon: 'mdi-calendar-month',ComponentName: 'MyActivities' },
            { title: 'Timesheets', icon: 'mdi-account-clock',ComponentName: 'Timesheets' },
            ]
            },       
            { title: 'Pipelines', icon: 'mdi-pipe',RenderonProp: true,GroupsEnabled: true, RenderProp: 'EntitieswithPipelines',
            Children: [
            // { title: 'Leads', icon: 'mdi-pipe',ComponentName: 'MyActivePipeline' },
            ]
            },
             { title: 'Records', icon: 'mdi-database',
            Children: [
               
            ]
            },
            { title: 'Groups', icon: 'mdi-clipboard-pulse-outline',
            Children: [
            { title: 'Groups', icon: 'mdi-vote',ComponentName: 'MyGroups' },
            ]
            },
           
            ],
        }
    },
    computed:{
        SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        WarehousesArray(){
            return this.$store.state.WarehousesArray
        },
        UserisTeamMember(){
        return this.GroupMemberObj ? this.GroupMemberObj.IsTeamMember : ''
        },
        CanToggleSocialModule(){
      if(this.UserDBNavList){
        return this.System && this.SocialNetworkActive && this.System.Social_Landing_Page && this.UserDBNavList.Options.SocialModule
      }
      else{
        return this.System && this.SocialNetworkActive && this.System.Social_Landing_Page
      }
    },
        ComputedAppModes(){
            return this.AppModes.map(mode => {
                let modeobj = {
                title: mode.Name,
                icon: mode.icon,
                Method: 'ToggleAppMode',
                Prop: mode,
                ItemPass: true,
                }
                return modeobj
            }).filter(mode => {
                if(mode.title === 'Super Admin'){
                    return this.userIsAdmin
                }
                else{
                    return mode
                }
            })
            },
        ActiveAppMode(){
        return this.$store.state.AppMode.Name
        },
        ActiveAppModeIcon(){
        return this.$store.state.AppMode.icon
        },
        CustomDashboards(){
            return  this.UserDashboards.map(dashboard => {
                let dashboarditem = {
                    icon: 'mdi-desktop-mac-dashboard',
                    title: dashboard.DashboardName,
                    ComponentName: 'DashboardBuilder',
                    target: 'self',
                    id: dashboard.id
                }
                return dashboarditem         
                })
        },
        CommunityDashboardNavigation(){
                return this.ComputedNavigation.filter(item => {
                    return item.title === 'Posts' || item.title === 'Groups'
            })
        },
        CommunityPostsDashboard(){
            return this.CommunityDashboardNavigation.filter(item => {return item.title === 'Posts'})
        },
        CommunityGroupsDashboard(){
            return this.CommunityDashboardNavigation.filter(item => {return item.title === 'Groups'})
        },
        BirdViewDashboardNavigation(){
            let items = [
                {
                icon: 'mdi-plus',
                title: 'New Dashboard',
                Method: 'OpenDashboardBuilder',
                Prop: '',
                ItemPass: true
                }
            ]
            return items.concat(this.ComputedNavigation.filter(item => {
                    return item.title === 'Dashboards'
            }).map(item => {
                return item.Children
            }).flat())
        },
        BottomNavigationItems(){
            if(this.$route.name === 'MyDashboard'){
                return this.MyDashboardBottomNavigationItems
            }
            else{
                let socialobj = Object.assign({},this.items.find(obj => obj.title === 'Posts'))
                if(socialobj && socialobj.Children){
                    console.log('socialobj',socialobj)
                let socialstats = Object.assign({},socialobj.Children.find(obj => obj.title === 'Social Stats'))
                socialstats.ItemPass = this.GroupAdmin
                let groupagestats ={ title: 'Group Stats', icon: 'mdi-home-analytics',ComponentName: 'MyGroupPageStats',ItemPass: true }
                let pipelines = Object.assign({},this.FinalNavigation.find(obj => obj.title === 'Pipelines'))
                console.log('pipelines',pipelines)
                let socialcomps = socialobj.Children.filter(item => {
                    return item.title === 'Blogs' || item.title === 'Articles' || item.title === 'Classifieds' || item.title === 'Events' || item.title === 'Forums' || item.title === 'Groups'
                || item.title === 'Meetups'  || item.title === 'Polls' 
                }).map(child => {
                    child.ItemPass = this.GroupMemberObj[child.title+'Moderator']
                    return child
                })
                let items = [
                    {
                        title: 'View Group',
                        icon: 'mdi-eye',
                        ItemPass: true,
                        IsRoute: true,
                        Path: '/Group/'+this.$route.params.id
                    },
                    {
                        title: 'Switch to '+this.System.Social_Module_Name,
                        icon: 'mdi-repeat',
                        ItemPass: this.CanToggleSocialModule,
                        Method: 'ImmediateIntranetViewToggle',
                        Prop: true
                    },
                    {
                        title: 'Dashboard',
                        icon: 'mdi-monitor-dashboard',
                        ItemPass: true,
                        Children: [
                            socialstats,
                            groupagestats
                        ]
                    },
                    {
                        title: 'Posts',
                        icon: 'mdi-clipboard-pulse-outline',
                        ItemPass: true,
                        Children: socialcomps
                    },
                ]
                if(pipelines && pipelines.title){
                    items.push(pipelines)
                }
                return items.filter(item => {
                    return item.ItemPass
                }).map(item => {
                    if(item.Children){
                        item.Children = item.Children.filter(child => {
                            return child.ItemPass
                        })
                    }
                    return item
                })
                .filter(item => {
                    if(item.Children){
                        return item.Children.length > 0
                    }
                    else{
                        return item
                    }
                })
                }
                else{
                    return []
                }
                
            }
        },
        RatingbasedNavlists(){
            //console.log(this.Directories)
            return this.Directories.filter(navlist => {
                return navlist.Has_Ratings && navlist.RatingModeratorsIDs && navlist.RatingModeratorsIDs.includes(this.userLoggedIn.id)
            }).map(ratingnavlist => {
                let ent = this.SystemEntities.find(obj => obj.id === ratingnavlist.id)
                let navlistobj ={
                    title: ratingnavlist.id.split('_').join(''),
                    ComponentName: 'TeamInbox',
                    notifications: 0,
                    ItemPass: true,
                    id: ratingnavlist.id,
                    SingleName: ent.SingleName,
                    icon: ent.Table_Icon
                }
                return navlistobj
            })
        },
        UserReviewModerator(){
            return this.RatingbasedNavlists && this.RatingbasedNavlists.length > 0
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        AllAppPlugins(){
        return this.AppsDataBase.map(app => {
            let appobj = Object.assign({},app)
            return appobj.Plugins
        }).flat()
        },
        SuitehasWarehouse(){
        return this.AllAppPlugins.find(obj => obj.id === 'Warehouse_&_Products')
        },
        MyDashboardBottomNavigationItems(){
            let pipelinesobj = {
            title: 'Pipelines',
            icon: 'mdi-pipe',
            ItemPass: this.ActiveAppMode === 'Bird View' || this.ActiveAppMode === 'Admin',
            Children: this.ComputedNavigation.filter(item => {
                return item.title === 'Pipelines'
            }).map(item => {
                return item.Children
            }).flat()
            }
            pipelinesobj.notifications = pipelinesobj.Children.map(child => {
                return child.notifications
            }).reduce((a, b) => a + b, 0)
            
            //console.log('reviewsobj',reviewsobj,this.UserReviewModerator)
            let reviewsobj = {
            title: 'Product Reviews',
            icon: 'mdi-star',
            ItemPass: this.UserReviewModerator && this.ActiveAppMode === 'Team',
            Children: this.RatingbasedNavlists
            }
            reviewsobj.notifications = pipelinesobj.Children.map(child => {
                return child.notifications
            }).reduce((a, b) => a + b, 0)
            let activitiesobj = Object.assign({},this.ComputedNavigation.find(obj => obj.title === 'Activities'))
            activitiesobj.ItemPass = this.ActiveAppMode === 'Team'
            if(reviewsobj && reviewsobj.Children && reviewsobj.Children.length > 0){
                let reviewoncheck = activitiesobj.Children.find(obj => obj.title === 'Product Reviews')
                if(!reviewoncheck){
                 activitiesobj.Children.push(reviewsobj)   
                }                
            }
            let goalsobj = Object.assign({},this.ComputedNavigation.find(obj => obj.title === 'Goals'))
            let goalsviewobj = goalsobj.Children[0]
            goalsviewobj.ItemPass = this.ActiveAppMode === 'Team'
            let socialpostsdash = Object.assign({},this.CommunityDashboardNavigation.find(obj => obj.title === 'Posts'))
            socialpostsdash.title = 'Social Dashboard'
            socialpostsdash.ItemPass = this.ActiveAppMode === 'Bird View' || this.ActiveAppMode === 'Community'
            let socialgroupsdash = Object.assign({},this.CommunityDashboardNavigation.find(obj => obj.title === 'Groups'))
            socialgroupsdash = socialgroupsdash.Children[0]
            socialgroupsdash.icon = 'mdi-account-group'
            socialgroupsdash.title = 'Groups'
            socialgroupsdash.ItemPass = this.ActiveAppMode === 'Bird View' || this.ActiveAppMode === 'Community'
            let recordtables = this.ComputedNavigation.filter(item => {
                return item.title === 'Records'
            }).map(item => {
                return item.Children
            }).flat()
            let warehousewip = {
                title: 'Warehouses',
                icon: 'mdi-warehouse',
                ItemPass: this.SuitehasWarehouse && this.WarehousesArray.length > 0,
                Children: this.WarehousesArray.map(wh => {
                    let whobj ={
                        title: wh.Name,
                        icon: 'mdi-warehouse',
                        //or wh check
                        ItemPass: true,
                        IsRoute: true,
                        Path: '/Warehouse/'+wh.Business_Unitid+'/'+wh.id
                        
                    }
                    return whobj
                })
            }
            let recordsobj =  {
            title: 'Records',
            icon: 'mdi-database',
            ItemPass: true,
            Children: recordtables
            }
            let securityobj = Object.assign({},this.secondaryitems.find(obj => obj.title === 'Security'))
            securityobj.ItemPass = this.ActiveAppMode === 'Super Admin'
            let supportobj = Object.assign({},this.secondaryitems.find(obj => obj.title === 'Support'))
            supportobj.ItemPass = this.ActiveAppMode === 'Super Admin'
            
            let switchtocommunity = {
                title: 'Switch to '+this.System.Social_Module_Name,
                icon: 'mdi-repeat',
                ItemPass: this.CanToggleSocialModule,
                Method: 'ImmediateIntranetViewToggle',
                Prop: true
            }
            return [
         
           socialpostsdash,
             {
            title: 'Banner Ads',
            icon: 'mdi-monitor-star',
            ItemPass: this.ActiveAppMode === 'Admin' && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.includes('Banner Ads') ||
            this.ActiveAppMode === 'Admin' && this.userIsAdmin,
            IsRoute: true,
            Path: '/BannerAds'
            },
             {
            title: 'Dashboards',
            icon: 'mdi-monitor-dashboard',
            ItemPass: this.ActiveAppMode === 'Bird View' || this.ActiveAppMode === 'Admin',
            Children: this.BirdViewDashboardNavigation
            },
            activitiesobj,
            // {
            // title: 'Team',
            // icon: 'mdi-account-group',
            // },
            securityobj,
            {
            title: this.ActiveAppMode,
            icon: this.ActiveAppModeIcon,
            ItemPass: true,
            Children: [switchtocommunity,recordsobj,warehousewip].concat(this.ComputedAppModes)
            },
            supportobj,
            goalsviewobj,
            // {
            // title: 'Team',
            // icon: 'mdi-account-group',
            // Children: this.ComputedNavigation
            // .filter(item => {
            //     return item.title === 'Pipelines' || item.title === 'Activities' || item.title === 'Goals'
            // }).map(item => {
            //     if(item.title === 'Goals'){
            //         item = item.Children[0]
            //     }
            //     return item
            // })
            
            // },
             pipelinesobj,
            {
            title: 'Landing Pages',
            icon: 'mdi-language-html5',
            ItemPass: this.ActiveAppMode === 'Admin' && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.includes('Landing Pages') ||
            this.ActiveAppMode === 'Admin' && this.userIsAdmin,            
            IsRoute: true,
            Path: '/LandingPages'
            },
            socialgroupsdash,
            // {
            // title: 'Admin',
            // icon: 'mdi-cogs',
            // ItemPass: this.ActiveAppMode === 'Admin',
            // Children: this.ComputedSecondaryNavigation
            // }
            ].filter(item => {
                return item.ItemPass
            }).map(item => {
                    if(item.Children){
                        // item.Children = item.Children.filter(child => {
                        //     return child.ItemPass
                        // })
                    }
                    return item
                })
        },
        BottomNavigationActive(){
        return this.userLoggedIn ? this.$route.name === 'MyDashboard' || this.$route.meta && this.$route.meta.DataSingleBuilder || this.$route.name === 'GroupDashboard' : ''
        },
        SessionClosesSecondaryNav(){
            return this.ActiveSession && this.ActiveSession.ComponentName === 'DashboardBuilder' || this.ActiveSession && this.ActiveSession.ComponentName === 'DataCollectionViewComponent' || this.ActiveSession && this.ActiveSession.ComponentName === 'Timesheets'
        },
        GroupMemberObj(){
            return this.$route.params.id? this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id) : ''
        },
        GroupAdmin(){
            return this.$route.params.id? this.GroupMemberObj.Administrator : ''
        },
        RenderDark(){
            return this.AppisDarkMode || this.MyDashDark
        },
        TableNameSplit(){
            return this.ActiveSession? this.ActiveSession.DisplayName.split(' ').join('') : ''
        },
        UserSiteModerator(){
            return this.SiteModerators.find(obj => obj.id === this.userLoggedIn.id) || this.userIsAdmin
        },
        EntitieswithPipelines(){
            return this.DashboardSystemEntities
            .filter(entity => {
                return entity.EntityProcesses.length > 0
            })
        },
        RouteName(){
            if(this.$route.params.id){
                return 'Group'
            }
            else{
                return 'My'
            }
        },
        ComputedSecondaryDashboardTitle(){
            if(this.$route.params.id){
                return 'Group Admin'
            }
            else{
               return 'Admin' 
            }
            
        },
        ComputedDashboardTitle(){
            if(this.$route.params.id){
                return 'Group Dashboard'
            }
            else{
               return 'My Dashboard' 
            }
            
        },
        
        ActiveSessionComponent() {
            return this.ActiveSession.ComponentName
        },
        ComputedQuery(){
            if(this.$route.params.id && this.ActiveSession && this.ActiveSession.groupcollectionname){
                return "db.collection('"+this.ActiveSession.groupcollectionname+"').where('Administrators','array-contains',"+this.userLoggedIn.id+").where('"+this.ActiveSession.groupcolfield+"',==,"+this.$route.params.id+")"
            }
            else if(this.ActiveSession && this.ActiveSession.sitecollectionname){
               return "db.collection('"+this.ActiveSession.sitecollectionname+"').where('"+this.ActiveSession.sitecolfield+"',==,"+this.userLoggedIn.id+")" 
            }
        },
        FinalNavigation(){
            return this.PreparedNavigation
            .map(obj => {
                if(obj.ComponentName === 'MyActivities'){ 
                    //actually now, here we need to seriously figure out, what obj.title is. 
                    //if obj.title  === Team Activities then team count, otherwise...the below...you get?                  
                    obj.Array = this.MyActivitiesArray.map(record => {
                        record.Notice = `<ul>`
                        record.NotificationCount = 0
                        let unreadnotifications = this.Notifications
                                .filter(not => {
                                    return not.taskid === record.id
                                })
                                //console.log(unreadnotifications)
                                record.NotificationCount = Number(record.NotificationCount)+Number(unreadnotifications.length)
                                unreadnotifications.map(not => {
                                    record.Notice = record.Notice+`<li>New Task - `+record.name+` by `+not.CreatorFullName+`</li>`
                                    return not
                                })
                            let today = new Date()
                            let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                            if(record.activitytype === 'Task' && record.status === 'Open'){
                                record.icon = 'mdi-clipboard-list-outline'
                                record.duedatestring = this.TimestampFormatterSTRING(record.duedate.toDate())
                                record.duedateobj = new Date(record.duedatestring)
                                if(record.duedateobj < today){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Task is overdue - action required</li>`
                                }
                                else if(record.duedateobj < weekfromnow){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Task is due in 7 days</li>`
                                }
                                
                                //but still need "task created" that is "unread"
                            }
                            else if(record.activitytype === 'Appointment' && record.status === 'Open'){
                                record.icon = 'mdi-calendar-check-outline'
                                let unreadnotifications = this.Notifications
                                .filter(not => {
                                    return not.appointmentid === record.id
                                })
                                //console.log('unreadnotifications',unreadnotifications)
                                record.NotificationCount = Number(record.NotificationCount)+Number(unreadnotifications.length)
                                unreadnotifications.map(not => {
                                    record.Notice = record.Notice+`<li>New Appointment - `+record.name+` by `+not.CreatorFullName+`</li>`
                                    return not
                                })
                                record.startdatestring = this.TimestampFormatterSTRING(record.start.toDate())
                                record.startdateobj = new Date(record.startdatestring)
                                record.enddatestring = this.TimestampFormatterSTRING(record.end.toDate())
                                record.enddateobj = new Date(record.enddatestring)
                                if(record.enddateobj < today){
                                record.NotificationCount = record.NotificationCount-1+2
                                record.Notice = record.Notice+`<li>Appointment end date already passed - consider closing</li>`
                                }
                                else if(record.startdateobj < today){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Appointment has already started</li>`
                                }
                                else if(record.startdateobj <= weekfromnow){
                                    record.NotificationCount = record.NotificationCount-1+2
                                    record.Notice = record.Notice+`<li>Appointment Start less than week from now - Get ready!</li>`
                                }
                                
                            }  
                            record.Notice = record.Notice+`</ul>`
                            return record
                    })
                    //console.log(obj)
                    obj.notificationsarray = obj.Array
                    .filter(record => {
                        return record.NotificationCount>0
                    })
                    obj.notifications = obj.notificationsarray.length
                }               
                return obj 
            })
        },
        PreparedNavigation(){
            return this.ComputedNavigation
            .filter(item => {
                return item.GroupsEnabled
            })
            .map(item => {
                return item.Children
            }).flat()
        },
        ComputedSecondaryNavigation(){
            return this.secondaryitems
            .filter(item => {
                if(this.$route.params.id){
                    return item.GroupsEnabled
                }
                else{
                    return item
                }
            })
        },
        ComputedNavigation(){
            return this.items
            .filter(item => {
                if(this.$route.params.id){
                    return item.GroupsEnabled
                }
                else{
                    return item
                }
            })
            .filter(item => {
                if(item.title === 'Pipelines'){
                    if(this.EntitieswithPipelines && this.EntitieswithPipelines.length > 0){
                      
                        return item
                    }
                }
                else{
                    return item
                }
            })
            .map(item => {
                if(item.title === 'Pipelines'){
                    //console.log('DashboardSystemEntities this.EntitieswithPipelines',this.EntitieswithPipelines)
                item.Children = this.EntitieswithPipelines.map(obj => {
                         if(obj.Entity_Type && obj.Entity_Type.Name === 'Classified Listing'){
                             obj.IsClassifiedListing = true
                             obj.ClassifiedListingPath = '/'+obj.SingleName.split(' ').join('')+'-Classified'
                             obj.ClassifiedListingEditPath = '/ClassifiedPreview/'+obj.SingleName.split(' ').join('')
                         }
                    obj.notifications =this.UnreadNotifications.filter(not => {return not.topicid === obj.topicid}).length
                    return obj  
                })
                }
                return item
            })
            .filter(item => {
                if(item.title === 'Pipelines'){
                    item.Children = item.Children.map(obj => {                        
                         if(this.$store.state[obj.topicid.split('_').join('')+'Query']) {
                             obj.CanAccess = true
                            }
                            else if(this.$route.params.id){
                                obj.CanAccess = true
                            }
                        return obj
                    })
                    .filter(obj => {
                        return obj.CanAccess
                    })
                }
                return item
            })
            .filter(item => {
                return item.Children.length > 0
            })
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        StateTableData(){
          return this.$store.state.TableData  
        }
        
    },
    watch: {
        SessionClosesSecondaryNav (value){
            if(value){
                this.SecondaryNavigation = false
            }
        },
        ComputedNavigation (value){
            if(value){
                this.$emit('UpdateBottomNavigation',value,this.ThemeColor)
            }
        },
        GotEntities (value){
            if(value){
                 let RecordsItem = this.items.find(obj => obj.title === 'Records')
          let RecordItemIndex = this.items.indexOf(RecordsItem)
          if(this.System.DBNavbarItems){
            let array = JSON.parse(JSON.stringify(this.System.DBNavbarItems))
            array.map(item => {
              item.Children = item.Children.filter(child => {
                //console.log(child,vm.$store.state[child.QueryName])
                return this.$store.state[child.QueryName]
              }).map(child => {
                let entity = this.SystemEntities.find(obj => obj.id === child.title.split(' ').join('_'))
                //console.log('entity',entity,child.title.split(' ').join('_'),this.SystemEntities)
                if(entity){
                  child.icon = entity.Table_Icon     
                  child.EntityRecord = entity             
                }
                child.ComponentName = 'DataCollectionViewComponent'
                return child
              })
              this.items[RecordItemIndex].Children.push(item)
            })
          }
            }
        },     
        GroupEntities: {
            handler: function(oldvalue, newvalue) {
                let vm = this
              //console.log('GroupEntities watcher','oldvalue',oldvalue,'newvalue', newvalue,this.RouteID,oldvalue !== newvalue)
              if(this.$route.name === 'GroupDashboard'){
                  let entity = this.GroupEntities.find(item => item.groupid === this.$route.params.id)
                  //console.log('DashboardSystemEntities GroupEntities',this.GroupEntities)
                  if(entity){
                      this.DashboardSystemEntities = this.GroupEntities.filter(item => { return item.groupid === this.$route.params.id})
                      .filter(build => {
                          return build.EntityProcesses.length > 0
                      })
                      .map(build => {
                                    let obj = { title: build.DisplayName, icon: build.Table_Icon,ComponentName: 'MyActivePipeline',storequery: build.DisplayName.split(' ').join('')+'Query',storedata: build.DisplayName.split(' ').join('')+'Array',id: build.id }
                                        obj.DisplayName = build.DisplayName
                                        obj.collection = build.DisplayName.split(' ').join('').toLowerCase()
                                        obj.datasetsecuritystructure = 'Group Member'
                                        obj.RecordPrimaryField = build.AllFields.find(item => item.Primary)
                                        if(build.HasActivities){
                                            obj.HasActivities = build.HasActivities
                                        }
                                        obj.groupid = build.groupid
                                        obj.EntityProcesses = build.EntityProcesses
                                        obj.DigitizedForms = build.DigitizedForms
                                        obj.automations = build.automations
                                        obj.SingleName = build.SingleName
                                        obj.Entity_Type = build.Entity_Type
                                        obj.SecurityType = 'Group Member'                                       
                                        obj.DocumentRegisters = vm.CabinetbyPermissions('DocumentRegisters',build,obj)
                                        obj.Galleries = vm.CabinetbyPermissions('Galleries',build,obj)          
                                        obj.buildid = build.id
                                        if(build.ClientLookupField){
                                            obj.ClientLookupField = build.ClientLookupField
                                            obj.LiaisonProp = 'ClientLiaison'
                                            
                                        }
                                        else{
                                            obj.LiaisonProp = 'InternalLiaison'
                                        }
                                        obj.topicid = build.id                                        
                                        obj.AllFields = build.AllFields
                                        if(build.HasWebForm){
                                            obj.HasWebForm = build.HasWebForm
                                        }
                                        let statusfield = build.AllFields.find(item => item.Name === 'Status')
                                        if(statusfield){
                                            build.StatusField = statusfield
                                        }
                                return obj
                      })
                      //console.log('DashboardSystemEntities for groups',this.DashboardSystemEntities)
                  }       
              }
            },
            deep: true
        },
        PushedNavItem (value) {
            if (typeof value !== 'undefined') {
                aler('have item')
               this.ActiveSession = value
            }
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        NotificationObj (value) {
            if (value) {
                if(value.IsLiaison){
                    let pipelinesobj = this.ComputedNavigation.find(obj => obj.title === 'Pipelines')
                    let mypipelineobj = pipelinesobj.Children.find(obj => obj.topicid === value.ActiveSessionid)
                    //console.log('NotificationObj',pipelinesobj,mypipelineobj)
                    this.ActiveSession = mypipelineobj
                }
            }
        },
        
    },
    created(){
        //console.log('NotificationObj',this.NotificationObj,'this.PushedNavItem',this.PushedNavItem)
        if (typeof this.PushedNavItem !== 'undefined') {
            this.ActiveSession = this.PushedNavItem
        }
        if (this.NotificationObj) {
           // console.log('NotificationObj',this.NotificationObj)
            setTimeout(() => {
             if(this.NotificationObj.IsLiaison){
                let pipelinesobj = this.ComputedNavigation.find(obj => obj.title === 'Pipelines')
                let mypipelineobj = pipelinesobj.Children.find(obj => obj.topicid === this.NotificationObj.ActiveSessionid)
                //console.log('NotificationObj',pipelinesobj,mypipelineobj)
                this.ActiveSession = mypipelineobj
            }   
            }, 2000);
            
        }
        this.IntranetViewToggle(false)
         if(this.$route.params.id){
            this.GetGroup()
        }
        else{
           this.GetAllUserDashboards()
        }
        
        if(this.System.LastAutomationInteger){
            this.LastAutomationInteger = this.System.LastAutomationInteger
        }
        
        if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md){
            this.Navigation = true
        }
        if(this.UserSiteModerator && this.$route.name !== 'GroupDashboard'){
            let socialobj = { title: 'Posts', icon: 'mdi-clipboard-pulse-outline',GroupsEnabled: true,
            Children: [
            
            { title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
            { title: 'Group Monetization',Pluginid: 'Group_Monetization', icon: 'mdi-cash-register',ComponentName: 'GroupDirectoriesDashboard', MonetizationOfficer: true },
            { title: 'Events',Pluginid: 'Site_Events', icon: 'mdi-calendar-star',ComponentName: 'MyEvents',sitecollectionname: 'siteevents',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupevents',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Blogs',Pluginid: 'Site_Blogs', icon: 'mdi-newspaper',ComponentName: 'MyBlogs',sitecollectionname: 'siteblogs',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupblogs',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Articles',Pluginid: 'Site_Articles', icon: 'mdi-newspaper',ComponentName: 'MyArticles',sitecollectionname: 'sitearticles',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouparticles',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { title: 'Classifieds',Pluginid: 'Classifieds', icon: 'mdi-monitor-star',ComponentName: 'MyClassifieds',sitecollectionname: 'classifieds',sitecolfield: 'Created_Byid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupclassifieds',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Forums',Pluginid: 'Site_Forums', icon: 'mdi-forum',ComponentName: 'MyForumThreads',sitecollectionname: 'siteforumdiscussions',sitecolfield: 'Creatorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupforumdiscussions',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { title: 'Meetups',Pluginid: 'Site_Meetups', icon: 'mdi-human-greeting-proximity',ComponentName: 'MyMeetups',sitecollectionname: 'sitemeetups',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupmeetups',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { title: 'Notices',Pluginid: 'Digital_NoticeBoard', icon: 'mdi-billboard',ComponentName: 'MyNoticeBoardPosts',sitecollectionname: 'notices',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupnotices',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Polls',Pluginid: 'Site_Polls', icon: 'mdi-vote',ComponentName: 'MyPolls',sitecollectionname: 'sitepolls',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouppolls',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            ]
            }
            socialobj.Children = socialobj.Children.filter(socialcomp => {           
                if(socialcomp.Pluginid){
                  return this.PluginDataBase[socialcomp.Pluginid] && this.UserSiteModerator && this.UserSiteModerator.ModerationPermissions && this.UserSiteModerator.ModerationPermissions.includes(socialcomp.title)  
                }
                else if(socialcomp.title === 'Notices'){
                    return this.UserSiteModerator && this.UserSiteModerator.ModerationPermissions && this.UserSiteModerator.ModerationPermissions.includes(socialcomp.title)
                }
                else if(socialcomp.MonetizationOfficer){
                    return this.userLoggedIn.MonetizationOfficer || this.userIsAdmin
                }
                else{
                    return socialcomp
                }
            })
            this.items.push(socialobj)
        }
        if(this.$route.name !== 'GroupDashboard'){
            this.GetEntities()
            if(this.userLoggedIn.ModerationPermissions){
                this.SocialAnalytics.map(analtype => {
                    if(this.userLoggedIn.ModerationPermissions.includes(analtype.Name)){
                      //this.GetAnalytics(db.collection('siteanalytics').where('RouteEventID','==',analtype.RouteEventID))  
                    }                    
                })
            }                       
        }  
        else{
            if(this.GroupAdmin){
                this.GetGroupAnalytics(db.collection('siteanalytics').where('RouteEventID','==',1015).where('RecordId','==',this.$route.params.id).where('Type','==','public').where('PublicUser','==',true))
            }
        }  
        if(this.userLoggedIn.MyDashboardThemeColor){
            this.ThemeColor = this.userLoggedIn.MyDashboardThemeColor
        }
        if(this.userLoggedIn.MyDashDark){
            this.MyDashDark = this.userLoggedIn.MyDashDark
        }
        if(this.userLoggedIn.ThemeHeaderColor){
            this.ThemeHeaderColor = this.userLoggedIn.ThemeHeaderColor
        }
       if(this.userIsAdmin && !this.$route.params.id){
            this.SecondaryNavigation = true
           
        }
        setTimeout(() => {
            if(!this.$route.params.id && !this.PushedNavItem && !this.NotificationObj){
              this.ActiveSession = { title: 'Goals', icon: 'mdi-bullseye-arrow',ComponentName: 'MyGoals' }  
            }
            
        }, 1000);
        
    },
    methods:{
        OpenDashboardBuilder(){
        let ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').doc()
        let newdocid = ref.id
        this.$router.push('/DashboardBuilder/'+newdocid)
        },
        ToggleAppMode(mode){
        if(mode.Name === 'Community'){
            this.$store.commit('SetAppMode',mode)
            //this.IntranetViewToggle(true) 
        }
        else{
            this.$store.commit('SetAppMode',mode)
        }
        },
        ImmediateIntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean,true)
      },
        IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
        SelectBottomNavMenuItem(item){
            console.log(item)
            if(item.Method){
                this[item.Method](item.Prop)
            }
            else if(item.IsRoute && !item.ComponentName){
                this.$router.push(item.Path)
            }
            else{
              this.ActivateSection(item)  
            }        
        },
        GetAllUserDashboards(){
        this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
        this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
        },
            GetUserDashboards(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let userdashboarddata = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    this.UserDashboards.push(userdashboarddata)
                    let dashboardsitem = this.items.find(obj => obj.title === 'Dashboards')
                this.UserDashboards.map(dashboard => {
                let dashboarditem = {
                    icon: 'mdi-desktop-mac-dashboard',
                    title: dashboard.DashboardName,
                    ComponentName: 'DashboardBuilder',
                    target: 'self',
                    id: dashboard.id
                }
                let oncheck = dashboardsitem.Children.find(obj => obj.id === dashboarditem.id)
                if(!oncheck){
                    
                    dashboarditem.DashboardEntity = this.SystemEntities.find(obj => obj.id === userdashboarddata.DashboardEntity)
                    console.log(userdashboarddata,dashboarditem)
                    dashboardsitem.Children.push(dashboarditem)
                }          
                })
                }
                })
            })
        },
        GetAnalytics(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        console.log('GetAnalytics',recordobj)
                    this[array].push(recordobj)
                    }
                    })
            })
        },
        GetGroupAnalytics(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        console.log('GroupLandingPageViews',recordobj)
                    this.GroupLandingPageViews.push(recordobj)
                    }
                    })
            })
            
        },
        ToggleAutomationDialog(){
            this.DeactivatePipelineworkflowdialog()
        },
        ActivatePipelineworkflowdialog(){
            this.CurrentEntity = this.ActiveSession
            this.AutomationDialog = true
            this.BuildAutomations = this.ActiveSession.automations
        },
        DeactivatePipelineworkflowdialog(){
            this.AutomationDialog = false
            this.BuildAutomations = []
        },
        ReloadPage(){
        location.reload()
        },
        
        GetEntities(){
            let vm = this
            vm.$emit('ActivateProcessing',true)
            return new Promise(function(resolve, reject) {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                   
                    vm.$emit('ActivateProcessing',false)
                }
                const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let build = change.doc.data()
                        build.id = change.doc.id
                        vm.GetAllEntityProps(build).then(function(allpropsresult) {
                            if(allpropsresult){
                            let oncheck = vm.DashboardSystemEntities.find(obj => obj.id == build.id)
                            if(!oncheck){
                                if(build.EntityProcesses.length > 0){
                                    let obj = { title: build.DisplayName, icon: build.Table_Icon,ComponentName: 'MyActivePipeline',storequery: build.DisplayName.split(' ').join('')+'Query',storedata: build.DisplayName.split(' ').join('')+'Array',id: build.id }
                                        obj.DisplayName = build.DisplayName
                                        obj.collection = build.DisplayName.split(' ').join('').toLowerCase()
                                        let routes = vm.$router.getRoutes()
                                        let routeobj = routes.find(item => item.name === obj.collection)
                                        obj.datasetsecuritystructure = build.datasetsecuritystructure
                                        obj.RecordPrimaryField = build.AllFields.find(item => item.Primary)
                                        if(build.HasActivities){
                                            obj.HasActivities = build.HasActivities
                                        }
                                        obj.EntityProcesses = build.EntityProcesses
                                        obj.DigitizedForms = build.DigitizedForms
                                        obj.automations = build.automations
                                        obj.SingleName = build.SingleName
                                        obj.Entity_Type = build.Entity_Type
                                        if(routeobj.meta && routeobj.meta.Security && routeobj.meta.Security.SecurityType){
                                         obj.SecurityType = routeobj.meta.Security.SecurityType
                                        }                                        
                                        obj.DocumentRegisters = vm.CabinetbyPermissions('DocumentRegisters',build,obj)
                                        obj.Galleries = vm.CabinetbyPermissions('Galleries',build,obj)          
                                        obj.buildid = build.id
                                        if(build.ClientLookupField){
                                            obj.ClientLookupField = build.ClientLookupField
                                            obj.LiaisonProp = 'ClientLiaison'
                                            
                                        }
                                        else{
                                            obj.LiaisonProp = 'InternalLiaison'
                                        }
                                        obj.topicid = build.id                                        
                                        obj.AllFields = build.AllFields
                                        if(build.HasWebForm){
                                            obj.HasWebForm = build.HasWebForm
                                        }
                                        let statusfield = build.AllFields.find(obj => obj.Name === 'Status')
                                        if(statusfield){
                                            build.StatusField = statusfield
                                        }
                                            vm.DashboardSystemEntities.push(obj)
                                            if(vm.DashboardSystemEntities.length === arraylength){
                                                vm.GotEntities = true    
                                                vm.$emit('ActivateProcessing',false)
                                                resolve('Method DashboardSystemEntities  Finished for ' + vm.DashboardSystemEntities.length)
                                                }
                                }
                                else{
                                   vm.DashboardSystemEntities.push(build)  
                                   if(vm.DashboardSystemEntities.length === arraylength){
                                    vm.GotEntities = true    
                                    vm.$emit('ActivateProcessing',false)
                                    resolve('Method DashboardSystemEntities  Finished for ' + vm.DashboardSystemEntities.length)
                                    }
                                }
                               
                            }
                            
                            }
                            })
                    }
                    
            })
            })
            })
        },
        CabinetbyPermissions(CabinetType,build,obj){
            let vm = this
            //console.log('CabinetbyPermissions '+CabinetType+' create check on '+build.id+' '+obj.SecurityType)
            return build[CabinetType].map(reg => {
                    if(reg.CreatePermission === 'Inherited'){
                        if(obj.SecurityType === 'Custom Roles'){
                            reg.CanCreate = vm.$store.state[obj.DisplayName.split(' ').join('')+'CanEdit'] || vm.userIsAdmin || obj.SecurityType === 'Group Member'
                        }
                        else if(obj.SecurityType === 'Business Unit'){
                            reg.CanCreate = true
                            reg.CreateUndetermined = true
                        }
                        //this is incomplete considering, user may be able to save table, but if BU structure then only know once open record
                    }
                    else if(reg.CreatePermission === 'Admin'){
                        reg.CanCreate = vm.userIsAdmin || obj.SecurityType === 'Group Member'
                    }
                    else if(reg.CreatePermission === 'Custom Role'){
                        reg.CustomCreateRoles.map(role => {
                            let usercheck = vm.userLoggedIn.rolesarrayQuery.find(userrole => userrole === role)
                            if(usercheck || vm.userIsAdmin || obj.SecurityType === 'Group Member'){
                                reg.CanCreate = true
                            }
                        })
                    }
                    return reg
                }).filter(reg => {return reg.CanCreate})
        },
        GetEntityAllFields(build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method DashboardSystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method DashboardSystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGalleries(build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length                
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                else{
                    const changes = res.docChanges();
                     changes.forEach(change => {
                        if (change.type === 'added') {
                            build.Galleries.push({
                                ...change.doc.data(),
                                id: change.doc.id
                            })
                        }
                        //console.log('attempting to get all Galleries for '+build.id+' '+arraylength,build.Galleries)
                        if(build.Galleries.length === arraylength){                        
                            resolve('Method DashboardSystemEntities Galleries Finished for ' + build.Galleries.length)
                        }
                    })
                }                
                })
            })
        },
        GetDocumentRegisters(build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method DashboardSystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method DashboardSystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetEntityProcesses(build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('EntityProcesses').where('Status','!=','InActive').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method DashboardSystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method DashboardSystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method DashboardSystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetAllEntityProps(build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(build).then(function(allfieldsresult) {
                if(allfieldsresult){
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(build).then(function(subcolresult) {
                        // console.log(subcolresult)
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(build).then(function(formsresult) {
                            vm.GetDocumentRegisters(build).then(function(docregresult) {  
                                vm.GetGalleries(build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(build).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                vm.GetGoalMeasurements(build).then(function(goalsresult) {
                                    if(allfieldsresult && subcolresult && processesresult && formsresult && automationsresult && goalsresult && docregresult && galleryres){
                                        resolve('got the props for '+build.id)
                                    }
                                })
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method DashboardSystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method DashboardSystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            
                            //console.log(targetslength,i-1+2)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
        GetGroup(){
            // this.GetMyGroups(this.userLoggedIn.groups)
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                let groupdata = snapshot.data()
                //alert(groupdata.name)
                this.GroupData = groupdata
                console.log('GroupData',this.GroupData)
                this.GroupTitle = groupdata.name
                let socialobj = { title: 'Posts', icon: 'mdi-clipboard-pulse-outline',GroupsEnabled: true,
            Children: [
            { title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
            { title: 'Events',Pluginid: 'Site_Events', icon: 'mdi-calendar-star',ComponentName: 'MyEvents',sitecollectionname: 'siteevents',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupevents',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Blogs',Pluginid: 'Site_Blogs', icon: 'mdi-newspaper',ComponentName: 'MyBlogs',sitecollectionname: 'siteblogs',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupblogs',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Articles',Pluginid: 'Site_Articles', icon: 'mdi-newspaper',ComponentName: 'MyArticles',sitecollectionname: 'sitearticles',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouparticles',groupcolfield: 'groupid',groupcolprop: this.$route.params.id,ModeratorsProp: 'ArticlesModerators'  },
            { title: 'Classifieds',Pluginid: 'Classifieds', icon: 'mdi-monitor-star',ComponentName: 'MyClassifieds',sitecollectionname: 'classifieds',sitecolfield: 'Created_Byid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupclassifieds',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Forums',Pluginid: 'Site_Forums', icon: 'mdi-forum',ComponentName: 'MyForumThreads',sitecollectionname: 'siteforumdiscussions',sitecolfield: 'Creatorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupforumdiscussions',groupcolfield: 'groupid',groupcolprop: this.$route.params.id,ModeratorsProp: 'ForumsModerators'  },
            { title: 'Meetups',Pluginid: 'Site_Meetups', icon: 'mdi-human-greeting-proximity',ComponentName: 'MyMeetups',sitecollectionname: 'sitemeetups',sitecolfield: 'authorid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupmeetups',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { title: 'Polls',Pluginid: 'Site_Polls', icon: 'mdi-vote',ComponentName: 'MyPolls',sitecollectionname: 'sitepolls',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'grouppolls',groupcolfield: 'groupid',groupcolprop: this.$route.params.id  },
            { title: 'Notices',Pluginid: 'Digital_NoticeBoard', icon: 'mdi-billboard',ComponentName: 'MyNoticeBoardPosts',sitecollectionname: 'notices',sitecolfield: 'Ownerid',sitecolprop: 'userLoggedIn',groupcollectionname: 'groupnotices',groupcolfield: 'groupid',groupcolprop: this.$route.params.id },
            { title: 'Page Stats', icon: 'mdi-home-analytics',ComponentName: 'MyGroupPageStats' }
            ]
            }
            socialobj.Children = socialobj.Children.filter(socialcomp => {
                //console.log('this.GroupMemberObj',socialcomp.title+'Moderator',this.GroupMemberObj[socialcomp.title+'Moderator'])
                return this.GroupData.AssignedGroupScope && this.GroupMemberObj ? this.GroupData.AssignedGroupScope.includes(socialcomp.title) && this.GroupMemberObj[socialcomp.title+'Moderator'] || socialcomp.ComponentName === 'MyGroupPageStats' || socialcomp.ComponentName === 'MySocialStats' : socialcomp.ComponentName === 'MyGroupPageStats' || socialcomp.ComponentName === 'MySocialStats'             
            })
            console.log('socialobj.Children',socialobj.Children,socialobj)
            this.items.push(socialobj)
            let defaultitem = socialobj.Children.find(obj => obj.title === 'Page Stats')
            this.ActiveSession = defaultitem
            })
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        PushArray(item,array){
            //console.log('getting for ',item.ComponentName,array)

            let obj = this.PreparedNavigation.find(obj => obj.ComponentName === item.ComponentName)
            //console.log(obj)
            //console.log('yay for '+obj.ComponentName,array.length,obj)
            obj.Array = array.map(record => {
                //console.log('record',record)
                record.Notice = `<ul>`
                if(obj.ComponentName === 'MyBlogs' || obj.ComponentName === 'MyArticles'){
                    //share, likes, commnets
                    record.NotificationCount = 0
                }
                else if(obj.ComponentName === 'MyEvents'){
                    
                    // people joined, event coming up this week
                    record.NotificationCount = 0
                    
                    record.Attending_Users.map(subcol => {
                        if(!subcol.AdminNotificationCleared){
                            record.NotificationCount = record.NotificationCount-1+2,
                            record.Notice = record.Notice+`<li>New Attendance - `+subcol.Full_Name+`</li>`
                        }
                    })
                    record.startdatestring = this.TimestampFormatterSTRING(record.start.toDate())
                    record.startdateobj = new Date(record.startdatestring)
                    record.enddatestring = this.TimestampFormatterSTRING(record.end.toDate())
                    record.enddateobj = new Date(record.enddatestring)
                    //console.log('record.enddateobj',record.enddateobj)
                    let today = new Date()
                    let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                    if(record.enddateobj < today){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Event end date already passed - consider closing</li>`
                    }
                    else if(record.startdateobj < today){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Event has already started</li>`
                    }
                    else if(record.startdateobj <= weekfromnow){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Event Start less than week from now - Get ready!</li>`
                    }
                }
                else if(obj.ComponentName === 'MyForumThreads'){
                    // comments on forums are from notification in app bar
                    let unreadnotifications = this.Notifications
                    .filter(not => {
                        return not.forumid === record.id
                    })
                    //console.log(unreadnotifications)
                    record.NotificationCount = unreadnotifications.length
                    unreadnotifications.map(not => {
                        record.Notice = record.Notice+`<li>New Comment - `+not.CreatorFullName+`</li>`
                        //return not
                    })
                }
                else if(obj.ComponentName === 'MyPolls'){
                    // New Vote, Poll End Date reached, All votes in
                    record.NotificationCount = 0
                    record.Votes.map(subcol => {
                        if(!subcol.AdminNotificationCleared){
                            record.NotificationCount = record.NotificationCount-1+2,
                            record.Notice = record.Notice+`<li>New Vote - `+subcol.Voter.Full_Name+`</li>`
                        }
                    })
                    record.enddatestring = this.TimestampFormatterSTRING(record.PollEndDate.toDate())
                    record.enddateobj = new Date(record.enddatestring)
                    let today = new Date()
                    let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                    if(record.enddateobj < today){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Poll Due date is reached - consider closing</li>`
                    }
                    else if(record.enddateobj <= weekfromnow){
                        record.NotificationCount = record.NotificationCount-1+2
                        record.Notice = record.Notice+`<li>Poll Due Date less than week from now - Get ready!</li>`
                    }
                }
                else if(obj.ComponentName === 'MyNoticeBoardPosts'){
                    // reached due date, and anything created more than a month ago
                    record.NotificationCount = 0
                    let today = new Date()
                    record.createdatestring = this.TimestampFormatterSTRING(record.createdon.toDate())
                    record.createdateobj = new Date(record.createdatestring)
                    let monthago = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30)
                    let weekfromnow = new Date(today.getFullYear(),today.getMonth(),today.getDate()+7)
                    if(record.createdateobj < monthago){
                            record.NotificationCount = record.NotificationCount-1+2
                            record.Notice = record.Notice+`<li>Notice Created more than 30 days ago - consider action</li>`
                        }
                    if(record.HasDueDate){
                        console.log('record.HasDueDate',record)                        
                        record.enddatestring = this.TimestampFormatterSTRING(record.DueDate.toDate())
                        record.enddateobj = new Date(record.enddatestring)
                       
                        if(record.enddateobj < today){
                            record.NotificationCount = record.NotificationCount-1+2
                            record.Notice = record.Notice+`<li>Notice Due date is reached - consider closing</li>`
                        }
                        else if(record.enddateobj <= weekfromnow){
                            record.NotificationCount = record.NotificationCount-1+2
                            record.Notice = record.Notice+`<li>Notice Due Date less than week from now - Get ready!</li>`
                        }   
                        
                    }
                    
                }
                
                record.Notice = record.Notice+`</ul>`
                return record
            })
            obj.notificationsarray = obj.Array
            .filter(record => {
                return record.NotificationCount>0
            })
            // .map(record => {
            //     return record.NotificationCount
            // }).reduce((a, b) => a + b, 0)
            // if(obj.notificationscheck > 0){
                obj.notifications = obj.notificationsarray.length
            // }
            // console.log(this.items)
            this.Navigation = false
            this.Navigation = true
            // item.Array = array
        },
        // GetQuery(item){
        //     if(this.$route.params.id && item && item.groupcollectionname){
        //         return db.collection(item.groupcollectionname).where('Administrators','array-contains',this.userLoggedIn.id).where(item.groupcolfield,'==',this.$route.params.id)
        //     }
        //     else if(item && item.sitecollectionname){
        //        return db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
        //     }
        // },
        ActivateMultipleFilesUploadNotification(boolean){
            this.$emit('ActivateMultipleFilesUploadNotification',boolean)
        },
        ActivateUploadingSingleFileNotification(boolean){
            this.$emit('ActivateUploadingSingleFileNotification',boolean)
        },
        ActivateSection(item){
            this.ActiveSession = ''
            console.log(item)
            if(item.IsRoute){
               this.$router.push('/'+item.ComponentName) 
            }
            else{
              if(item.title === 'Team Activities'){
                this.TeamView = true
                }
                else if(item.title === 'Activities'){
                this.TeamView = false
                }
                setTimeout(() => {
                  this.ActiveSession = item
                if(this.ActiveSession.AllFields){
                 this.ActiveSession.StatusField = this.ActiveSession.AllFields.find(obj => obj.id === 'Status')
                this.ActiveSession.StatusOptions = this.ActiveSession.StatusField.Options   
                }
                
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;    
                }, 100);
                
            }
            
        },
        UpdateThemeColors(){
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.userLoggedIn.id).update({
                MyDashboardThemeColor: this.ThemeColor,
                MyDashDark: this.MyDashDark,
                ThemeHeaderColor: this.ThemeHeaderColor
            })
        }
    }

}
</script>

<style>

</style>

    