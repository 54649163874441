<template>
        <span>
            <v-icon v-if="SingleFormEditing" class="links--text" @click="UpdateEditing()">mdi-cogs</v-icon>
         
      <v-dialog v-model="EditingDialog" max-width="500px">
  <v-card>
      <v-img
      width="500"
      src="@/assets/RABaseBG-Soft.png"
      ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
        <v-card-title class="mediumoverline white--text">
          Editing 
        </v-card-title></div>
        <v-card-text>
        <v-select v-model="EditOption" :items="['Move out of Record','Destroy']">
        </v-select>
      </v-card-text>
      <v-card-actions class="white documenttypeshadowinvert">
        <v-btn  color="orange" dark class="overline" @click="CancelEditingDialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
            <v-btn  color="green" dark class="overline" @click="ProcessEditingDialog()">Process</v-btn>
      </v-card-actions>
      </v-img>
      </v-card>
      </v-dialog>
  <!-- {{section}} -->
            <v-card flat min-height="200px"  :dark="AppisDarkMode" v-if="ComputedImagesUploads">
            <v-btn v-if="CanEdit && !Editing" @click="Editing = !Editing"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-pencil</v-icon></v-btn><v-btn v-if="Editing" @click="Editing = !Editing,CancelEditingDialog()"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-cancel</v-icon></v-btn>
                     <!-- {{section.DataArray}} -->
              <v-pagination
                v-model="Page"
                :length="ComputedImagesUploads.length/12"
                :total-visible="7"
              ></v-pagination>
              <v-dialog v-model="SlideShowDialog" max-width="800">
              <v-carousel v-model="SlideShow">
                  <v-carousel-item
                    v-for="(card,i) in ImagesUploadsPagination"
                    :key="card.itemObjKey"
                  >
                    <v-sheet
                      :color="color"
                      height="100%"
                      tile
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="display-3">
                          Image {{ i + 1 }}
                          <v-img :src="card.url" contain max-height="500"/>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
            </v-dialog>
            <v-layout class="justify-center">
            <input
                style="display: none"
                type="file"
                @change="AddImages($event)"
                ref="fileInputer"
                :multiple="true"
                accept="image/*"
                >
                <v-btn v-if="CanCreate" @click="$refs.fileInputer.click()" outlined  small class="elevation-6">
                  <v-icon>mdi-plus-thick</v-icon>Add Images</v-btn>
                  
                  <v-btn v-if="CanCreate" @click="ViewLibraries()" outlined  small class="elevation-6">
                  <v-icon>mdi-plus-thick</v-icon>Add From Libraries</v-btn>

              <v-btn @click="SlideShowDialog = true" outlined  small class="elevation-6"><v-icon>mdi-file-presentation-box</v-icon>SlideShow</v-btn>
            </v-layout>
              <v-layout row class="align-center mx-3">
                 <v-col
                v-for="(card,i) in ImagesUploadsPagination"
                :key="card.itemObjKey"
                class="d-flex child-flex"
                :cols="GetCols(2)"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-auto"  @click="OpenorEditing(card,i)"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.ThumbURL"
                    :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover && !Editing"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                     <div
                      v-if="hover && Editing"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      Edit
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
                </v-hover>
              </v-col>
                </v-layout>

             </v-card>
        </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['tab','section','sectionindex','Fields','AppisDarkMode','CanEdit','CanCreate','CanDelete','System',
    'Record','Cols','Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','SystemPhotoLibraries',
    'ActivitiesArray','SingleFormEditing','EntityName','EntityCollectionRef','BaseStorageRef',
    'EntityDataRef','SampleOnly'],
    components: {

    },
    data(){
        return{
          GalleryLibraries: [],
            ImagesUploads: [],
            SlideShowDialog: false,
            SlideShow: 1,
            Editing: false,
            EditingDialog: false,
            editedItem: '',
            editedItemIndex: -1,
            EditOption: '',            
            Uploading: false,
            totallist: 0,
            listuploadcount: 0,
            uploadTotal: 0,
            runningpercentage: 0,
            runningProgress: 0,
            uploadPercentage: 0,
        }
    },
    computed:{
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
        ComputedImagesUploads(){
            return this.section.DataArray
        },
        ImagesUploadsPagination(){
            return this.ComputedImagesUploads
        },
        FinalStorageRef(){
            return this.BaseStorageRef+this.SectionStorageRef
        },
        SectionStorageRef(){
            return this.section.Name.split(' ').join('')+'/'+this.Record.id
        },
        roottableentities(){
        return this.$store.state.roottableentities
        },
        SectionDBRef(){
            return this.$route.meta && this.$route.meta.EntityID && this.$store.state.NewTableStructure && !this.roottableentities.includes(this.$route.meta.EntityID) ? this.EntityDataRef.collection('Galleries').doc(this.section.Name).collection('Items') : 
            this.EntityDataRef.collection(this.section.Name.split(' ').join('').split('_').join('').toLowerCase())
        },
        
        UsersArray(){
      return this.$store.state.UsersArray
    },
        userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
        HeaderDarkLightStyle(){
            if(this.AppisDarkMode){
                return 'white--text font-weight-light'
            }
            else{
                return 'primary--text font-weight-light'
            }
            },
    },
    watch: {
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateCabinetDialog','SelectSingleGalleryItem']   
        if(v && acceptedmethods.includes(v) && this.section.DisplayName === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
        else if(v && acceptedmethods.includes(v) && this.SocialItemInteractMethod === 'SelectSingleGalleryItem'){
          if(this.SocialItemInteractMethodProp){
            
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
       //console.log(this.SectionDBRef,this.FinalStorageRef)
       //this.GetGalleryObjects()
    },
    methods:{
      SelectSingleGalleryItem(asset){
        console.log(asset,this.SectionDBRef)
        this.SectionDBRef.add(asset)
      },
      GetGalleryObjects(){									
            let routes = this.$router.getRoutes()									
            let GalleryPaths = routes.filter(option => {return option.meta && option.meta.photolibrary})									
            //console.log('GalleryPaths',GalleryPaths)									
            GalleryPaths.map(path => {		
              console.log(path)							
                this.GetGalleryItems(path)									
            })									
        },									
        GetGalleryItems(path){				
          //console.log('whoooooppeeee')					
            db.collection(path.name).onSnapshot(res => {									
                        const changes = res.docChanges();									
                        changes.forEach(change => {									
                          if (change.type === 'added') {									
                              let returnobj = {									
                              ...change.doc.data(),									
                              id: change.doc.id									
                            }									
                            //console.log(typeof this.SystemAssets)									
                            // returnobj.Library = path.meta.subcat									
                            let check = this.GalleryLibraries.find(obj => obj.name === path.meta.subcat)									
                            if(!check){									
                                let libraryobj = {									
                                    name: path.meta.subcat,									
                                    compname: path.meta.subcat.split(' ').join(''),									
                                    Assets: []									
                                }	
                                let libentity = this.SystemPhotoLibraries.find(obj => obj.id === path.meta.EntityID)	
                                if(libentity){
                                    if(libentity.CustomTags){
                                      libraryobj.CustomTags = libentity.CustomTags  
                                    }
                                    libraryobj.TableCollectionRef = db.collection(libentity.id.split('_').join('').toLowerCase())	                                    
                                }	
                                	//console.log('libraryobj',libraryobj)				
                                libraryobj.Assets.push(returnobj)									
                                this.GalleryLibraries.push(libraryobj)									
                            }									
                            else{									
                                check.Assets.push(returnobj)									
                            }									
                            //console.log(this.GalleryLibraries)									
                            									
                          }									
                        })									
                      })									
        },
        ViewLibraries(){
         //console.log(this.$store.state.GalleryLibraries)
        this.$store.commit('SetSocialItemInteractMethodProp',true)
         this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        },
        ActivateCabinetDialog(){
          this.$refs.fileInputer.click()
        },
        GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
    CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
        OpenorEditing(item,index){
      if(this.Editing){
        this.EditingDialog = true
        this.editedItem = item
        this.editedItemIndex = index
      }
      else{
        this.GetShortLivedURL(item)
      }
      },
        GetCols(int){
      if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg){
        return int
      }
      else if(this.$vuetify.breakpoint.md){
        return int*1.5
      }
      else if(this.$vuetify.breakpoint.sm){
        return int*2.5
      }
      else{
        return int*3
      }
    },
        CancelEditingDialog(){
            this.EditingDialog = false
            this.editedItem = ''
            this.editedItemIndex = -1
            this.EditOption = ''
        },
      UpdateEditing(){
          if(!this.SingleFormEditing){
            this.Editing = !this.Editing
          }
          else{
            this.$emit('OpenDialog',this.section,this.sectionindex,true)
          }
            
            //console.log(this.Record,this.MutatedRecord)
            //this.$emit('UpdateEditableField',this.FieldName,FieldValue,this.AdditionalSaveMethod,this.MutatedRecord)
        },
        AddImages(){
         console.log(this.FinalStorageRef)
      let fileList = event.target.files || e.dataTransfer.files
      this.$store.commit('setUploadFilesList',fileList)
      let filestorageref = this.FinalStorageRef
      let dbrecordref = this.SectionDBRef
      this.$store.commit('setUploadFileStorageRef',filestorageref)
      this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
      },
      ProcessEditingDialog(){
      console.log(this.EditOption)
      if(this.EditOption === 'Move out of Record'){
        confirm('This will move the Image out of this table, and move it to another. Are you sure you want to do this?') &&
        this.TransfertoRMAdminTable()
      }
      else if(this.EditOption === 'Destroy'){
        //alert('We Will Delete')
        confirm('This will destroy all traces of the Image,are you Sure you want to do this?') && this.DeleteItem()
        
      }
    },
    DeleteItem(){
      let vm = this
      let thumstorageRef = firebase.storage().ref(vm.editedItem.StorageRef+'_thumb');
     
        let storageRef = firebase.storage().ref(vm.editedItem.StorageRef);	
              storageRef.delete().then(function() {									
                // File deleted successfully
                		console.log('actual file successfully deleted')
                    thumstorageRef.delete().then(function() {									
                      // File deleted successfully		
                      console.log('thumb file successfully deleted')
                      vm.SectionDBRef.doc(vm.editedItem.id).delete().then(function() {
                        vm.ImagesUploads.splice(vm.editedItemIndex,1)
                      }).catch(function(error) {									
                      // Uh-oh, an error occurred!									
                    });
                    }).catch(function(error) {									
                      // Uh-oh, an error occurred!									
                    });							
              }).catch(function(error) {									
                // Uh-oh, an error occurred!									
              });
    },
    TransfertoRMAdminTable(){
      if(this.userIsAdmin){
            // let doc = Object.assign({},this.editedItem)
            // doc.Name = doc.title
            // doc.FileType = doc.type
            // doc.url = doc.path
            // doc.StorageRef = this.FinalStorageRef+this.editedItem.title
            // this.RemoveSingleItemfromTable(doc,'ImagesUploads','salesquotes')	
            }
            else{
                alert('Apologies but only Administrators can do that')
            }
        },
    }
}
</script>

<style>

</style>