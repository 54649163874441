<template>
  <div>
      <v-overlay z-index="10" color="white" :opacity="0.8"  >
          <v-progress-circular style="animation: simplepulsing 1.5s infinite;"
            :value="100" :size="220" :width="12" class="mediumoverline" :color="'red'">
            <v-card-title :class="'red--text mediumoverline'" style="text-shadow: 4px 4px rgba(0,0,0,0.16);position: absolute;margin-top:-80px;animation: simplepulsing 1.5s infinite;animation-delay: 0.15s;font-weight:500;">Offline</v-card-title>
        
     <v-icon style="animation: simplepulsing 1.5s infinite;margin-top:70px;" color="red" size="100">mdi-wifi-off</v-icon>
        </v-progress-circular>
       
          </v-overlay>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },
    computed:{

    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
