<template>
  <div style="margin-top: 50px;">
      <v-dialog v-model="TableFilterdialog" width="400">
    <v-card width="100%" height="100%">
        <v-card-title>Table Filter</v-card-title>
        <v-card-text>
            <v-text-field label="Name" v-model="TableFilter.Name"/>
          <v-select :items="SystemEntities" label="Table" item-text="id" v-model="TableFilter.Table"/>
          <v-select :items="TableFilterEntityUserFields" label="Field" item-text="id" v-model="TableFilter.Field"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeTableFilterDialog()" dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="ProcessTableFilterOptions()" dark color="success">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
       <v-list>
        <v-btn @click="ActivateTableFilterdialog()">Add Filter</v-btn>
        <v-list-item class="newbasicoutlined">
        <v-list-item-content>
        <h4 class="outlinefont">User Table Filters</h4>
        </v-list-item-content>
        <v-spacer></v-spacer>
        <v-list-item-content>
        </v-list-item-content>
        </v-list-item>
        
        <v-list-item v-for="filter in SystemTableFilters" :key="filter.itemObjKey">
        <v-list-item-content>
            {{  filter.Table }}
            </v-list-item-content>
            <v-list-item-content>
            {{  filter.Field }}
            </v-list-item-content>
            <v-list-item-content>
            <v-icon @click="ActivateTableFilterdialog(filter)">mdi-cogs</v-icon>
            </v-list-item-content>
        </v-list-item>
        </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','SystemTableFilters'],
    components: {},
    data() {
        return {
            TableFilterIndex: -1,
            UserTableFilters: [],
            TableFilterdialog: false,
            TableFilter: {Name: '', Table: '', Field: ''},
            defaultTableFilter: {Name: '', Table: '', Field: ''},
        }
    },
    computed:{
        TableFilterEntity(){
            if(this.TableFilter && this.TableFilter.Table){
                let tableobj = this.SystemEntities.find(obj => obj.id === this.TableFilter.Table)
                return tableobj
            }
            },

            TableFilterEntityUserFields(){
            if(this.TableFilterEntity){
                return this.TableFilterEntity.AllFields.filter(field => {
                return field.Type === 'Lookup' && field.RelatedBuildID === 'Users'
            })
            }
            else{
                return []
            }
            },
    },
    created(){

    },
    methods:{
        ProcessTableFilterOptions(){
            if(this.TableFilterIndex === -1){
                db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('SystemTableFilters').add(this.TableFilter).then(doc => {
                    this.closeTableFilterDialog()
                })
            }
            else{
                db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('SystemTableFilters').doc(this.TableFilter.id).set(this.TableFilter).then(doc => {
                    this.closeTableFilterDialog()
                })
            }
        },
        closeTableFilterDialog(){
            this.TableFilterdialog = false
            setTimeout(() => {
            this.TableFilter = Object.assign({}, this.defaultTableFilter)
            this.TableFilterIndex = -1
            }, 300)

          },
        ActivateTableFilterdialog(role){
            if(role){
            this.TableFilterIndex = this.SystemTableFilters.indexOf(role)
            }          
            this.TableFilter = Object.assign({}, role)
            //this.BuildsforRoles()
            this.TableFilterdialog = true
        },
    }
}
</script>

<style>

</style>
