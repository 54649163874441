<template>
 <div>
  <v-system-bar fixed dark class="SoftBuilderGradient" v-if="ActiveSuiteTemplate">
    <v-list-item dense class="justify-space-between" style="padding: 0px;">
     <span class="lime--text">
     <span v-if="!XSView">{{PurchasedPlugins.length}} {{PurchasedPlugins.length === 1 ? 'Plugin' : 'Plugins'}} @ </span>
    {{CurrencyFormatter(SuiteTotal,$store.state.DefaultCurrency.Currency)}} (p/m)</span>
   <!-- Technically this "Activate" should be on user acceptance of invoice, it should be client that accepts it, but okay -->
    <v-btn v-if="RAAdmin" @click="ActivateSuiteTemplateInstallerDialog()" color="pink" x-small><v-icon>mdi-cart</v-icon><span v-if="!MiniView">Activate</span></v-btn>
    <!-- <span style="-webkit-text-align: center;">
    <span v-if="!MiniView">Total Plugins installed on this Suite - {{PurchasedPlugins.length}} at a cost </span> 
    {{CurrencyFormatter(SuiteTotal,$store.state.DefaultCurrency.Currency)}} (p/m)
    </span> -->
    <v-spacer>
    </v-spacer>
    <marquee :style="$vuetify.breakpoint.md? 'max-width: 42%;' : $vuetify.breakpoint.lg? 'max-width: 55%;' : 'max-width: 60%;'" 
     behavior="scroll" direction="left">
           Your Website has the full power of a Web App! Speak to your Web Developer about activating more plugins for your Web App.</marquee>
    </v-list-item>
  </v-system-bar>
  <v-btn dark class="RAWebBtnGraddarksoft" @click="HiddenBar = !HiddenBar" v-if="mini" absolute right :style="HiddenBar ? 'right:0px;z-index:10;position: fixed;bottom:180px;' : 'right:60px;z-index:10;position: fixed;bottom:180px;'">
    <v-icon small>{{HiddenBar ? 'mdi-comment' : 'mdi-chevron-right'}}
    </v-icon>
  </v-btn>
     <v-navigation-drawer dark
      v-model="drawer" 
      :mini-variant.sync="mini"
      permanent right fixed class="RAWebBtnGraddarksoft"
      width="400" mini-variant-width="80"
      :style="HiddenBar ? 'right:-80px;transition: all 0.25s;' : 'right:0px;transition: all 0.25s;'"
    >
    
      <!-- :mini-variant.sync="mini" -->
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="userLoggedIn.Profile_Photo ? userLoggedIn.Profile_Photo : require('@/assets/BlankProfilePic.png')"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
        <v-list-item-title>{{userLoggedIn.Full_Name}}</v-list-item-title>
         <v-list-item-subtitle style="color: mediumspringgreen;text-decoration: underline;text-underline-position: under;cursor: pointer;" @click="RoutetoMyTemplatesandcloseMini()" v-if="SuiteTemplatesAllowed">
         My Suite Templates
        </v-list-item-subtitle>
         <!-- <router-link  :to="'/MySuiteTemplates'">My Suite Templates</router-link> -->
        </v-list-item-content>
        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon color="mediumspringgreen">mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense v-if="!ChatDialog">
       <v-list-item v-for="(suite,index) in RelatedSuiteTemplates" :key="suite.itemObjKey" @click="CheckActiveSuite(suite)" :class="ActiveSuiteTemplate && ActiveSuiteTemplate.id === suite.id ? 'actionbounce RAWebBtnGradShiny' : 'BuilderGradient'"    
          link :style="'place-content: center;position: fixed;background-color: black;width:100%;z-index:3;top: '+(50+(50*index))+'px;'"
        >
        <v-badge
        avatar
        bordered
        overlap
      >
        <template v-slot:badge>
          <v-avatar :color="CheckUnreadSuiteNotificationsCount(suite) > 0 ? 'red white--text' : 'grey white--text'">
           {{CheckUnreadSuiteNotificationsCount(suite)}}
          </v-avatar>
        </template>
        <v-avatar color="blue" size="40">
           {{abbreviate(suite.Name)}}
        </v-avatar>
      </v-badge>
       </v-list-item>
      </v-list>
      
       <v-list dense v-if="ChatDialog">
       <v-list-item class="BuilderGradient"    
          link :style="'place-content: center;position: fixed;background-color: black;width:100%;z-index:3;top: '+(80)+'px;'"
        >
        <v-badge
        avatar
        bordered
        overlap
      >
        <template v-slot:badge>
          <v-avatar :color="CheckUnreadSuiteNotificationsCount(ActiveSuiteTemplate) > 0 ? 'red white--text' : 'grey white--text'">
           {{CheckUnreadSuiteNotificationsCount(ActiveSuiteTemplate)}}
          </v-avatar>
        </template>
        <v-avatar color="blue" size="40">
           {{abbreviate(ActiveSuiteTemplate.Name)}}
        </v-avatar>
      </v-badge>
       </v-list-item>
      </v-list>
       <RecordChatRoom v-if="ChatDialog && DataRecord" :RecordData="DataRecord" :ActiveSession="CurrentEntity" :LiaisonDialogActive="true"
      :OperationalDB="OperationalDB" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :BackView="true"
      @ActivateProcessing="ActivateProcessing" @ToggleLiaisonDialog="ToggleLiaisonDialog" :FullonCreate="true" @GoBack="ToggleChat()"
      :HideSeeRecord="true"
      :System="System" :SystemEntities="SystemEntities" :CanEdit="CanLiaiseSuiteTemplate" :LabelledFooterActive="LabelledFooterActive"
      />
    </v-navigation-drawer>
     
 </div>
</template>
<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RecordChatRoom from '@/components/General/RecordChatRoom';
export default {
    props: ['System','SystemEntities','PublicNavbarEditing','Notifications','LabelledFooterActive','SystemPhotoLibraries'],
    components: {RecordChatRoom},
    data() {
        return {
            ChatDialog: false,
            drawer: true,
            HiddenBar: false,
        // items: [
        //   { title: 'Home', icon: 'mdi-home-city' },
        //   { title: 'My Account', icon: 'mdi-account' },
        //   { title: 'Users', icon: 'mdi-account-group-outline' },
        // ],
        mini: true,
        CurrentEntity: {
                //it's okay undderscore caps below fixes it
                id: 'Suite_Templates',
                collection: 'suitetemplates',
                DisplayName: 'Suite Templates',
                //SingleName: 'Suite Template', actually now the purpose of it is purely routing so
                SingleName: 'Your-Domain',
                //getting WAY too many errrs here
                ClientLookupField: {Name: 'Client',DisplayName: 'Client',id: 'Client',LookupFieldName: 'Full_Name'},
                DigitizedForms: [],
                DocumentRegisters: [],
                //hell yeah!!! let's get real, how do we put Assets in here?
                Galleries: [{id: 'Assets',DisplayName: 'Assets'}],
                RecordPrimaryField: {Name: 'Name',DisplayName: 'Name',id: 'Name'}
            },
            SuiteTemplate: ''
        }
    },	
    computed:{
      RAAdmin(){
          return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
      },
      RAUserObj(){
          return this.$store.state.RAUserObj
      },
      SuiteTemplatesAllowed(){
        //console.log('this.userLoggedIn',this.userLoggedIn)
        return this.userLoggedIn.IsSiteCreator || this.userIsAdmin
      },
      PluginDataBase(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.PluginDataBase : ''
        },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
        PurchasedPlugins(){
          return this.ActiveSuiteTemplate ? this.PluginList.filter(plug => {
            return this.ActiveSuiteTemplate.PluginDataBase[plug.id] && this.ActiveSuiteTemplate.PluginDataBase[plug.id].Active
          }) : []
        },
        PluginList(){
              return this.ActiveSuiteTemplate ? this.DataSingleBuilder ? this.AppPluginsMatched(['Database Builder','DBB']) : this.RAPluginsMatched(['Website Builder','WB','Module']) : []
          },
        SuiteTotal(){
              return this.ActiveSuiteTemplate ? this.PurchasedPlugins.map(plug => {
                  return plug.Price
              }).reduce((a, b) => a + b, 0) : 0
        },
        DataSingleBuilder(){
            return this.$route.meta && this.$route.meta.DataSingleBuilder
        },
        PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
            return plug
            //btw perhaps ActiveSuiteTemplate here must rather be the relevant "SITE"
            //we are not letting you "purchase", you simply turn on and off so yeah
            //   return !this.ActiveSuiteTemplate.PluginDataBase || this.ActiveSuiteTemplate && !this.ActiveSuiteTemplate.PluginDataBase[plug.id]
            //    || this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.PluginDataBase[plug.id] && !this.ActiveSuiteTemplate.PluginDataBase[plug.id].Active && plug.Strictly_One_Purchase
            //    || !plug.Strictly_One_Purchase
          })
        },
        SuiteNotifications(){
            return this.Notifications.filter(not => {
                return not.clientrecords === this.ActiveSuiteTemplate.id
            })
        },
        UnreadSuiteNotifications(){
          return this.SuiteNotifications.filter(not => {
            return !not.Read
          })
        },
        UnreadSuiteNotificationsCount(){
          return this.UnreadSuiteNotifications.length
        },
        SuiteNotificationCount(){
            return this.SuiteNotifications.length
        },  
        OperationalDB(){
            return this.ActiveSuiteTemplate ? db.collection('SystemConfig').doc(this.ActiveSuiteTemplate.id) : ''
        },
        DataRecord(){
          if(this.ActiveSuiteTemplate){
            let record = Object.assign({},this.SuiteTemplate)
            record.Notifications = this.Notifications.filter(not => {
                //need to improve right
                return not
            })
            record.NotificationCount = record.Notifications.length
            return record
          }
        },
        CanLiaiseSuiteTemplate(){
        return this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Clientid === this.userLoggedIn.id || this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.Ownerid === this.userLoggedIn.id
        },
        SuiteTemplateMain(){
        return this.$route.name === 'Suite Template Main'
        },
        SuiteTemplateWebsite(){
        return this.$route.name === 'Suite Template Website'
        },
        SuiteTemplateSocialNetwork(){
        return this.$route.name === 'Suite Template Social Network'
        },
        SuiteTemplateDatabase(){
        return this.$route.name === 'Suite Template Database App'
        },
        SuiteTemplatePreview(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        SuiteTemplateView(){
            return this.SuiteTemplateSocialNetwork || this.SuiteTemplateWebsite || this.SuiteTemplateDatabase || this.SuiteTemplateMain
        },
        ActiveSuiteTemplate(){
                return this.$store.state.ActiveSuiteTemplate
            },
        RelatedSuiteTemplates(){
         return this.MySuiteTemplates.concat(this.ClientSuiteTemplates) 
        },
        SuiteTemplates(){
        return this.MySuiteTemplates.concat(this.OtherSuiteTemplates,this.ClientSuiteTemplates)
        },
        OtherSuiteTemplates(){
        return this.$store.state.OtherSuiteTemplates
        },
        MySuiteTemplates(){
        return this.$store.state.MySuiteTemplates
        },
        ClientSuiteTemplates(){
        return this.$store.state.ClientSuiteTemplates
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        FeaturedMemberView(){
        return this.$route.name === 'FeaturedMemberViewer' || this.PublicNavbarEditing && this.$route.name === 'FeaturedMemberEditor' || this.$route.name === 'FeaturedMemberCreator'
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        XSView(){
            return this.$vuetify.breakpoint.xs
        },
        SMView(){
            return this.$vuetify.breakpoint.sm
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    },
    watch: {
      MiniView: {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue && newvalue){
                    this.HiddenBar = true
                }
                else if(!this.MiniView){
                  this.HiddenBar = false
                }
            },
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.MiniView){
        this.HiddenBar = true
      }
        this.GetSuiteTemplate()
    },
    methods:{
      ActivateSuiteTemplateInstallerDialog(){
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','ActivateSuiteTemplateInstallerDialog')
      },
      RoutetoMyTemplatesandcloseMini(){
        this.mini = !this.mini
        this.HiddenBar = true
        this.$router.push('/MySuiteTemplates')
      },
      CheckSuiteNotifications(suite){
          return this.Notifications.filter(not => {
              return not.clientrecords === suite.id
          })
      },
      CheckUnreadSuiteNotifications(suite){
        return this.CheckSuiteNotifications(suite).filter(not => {
          return !not.Read
        })
      },
      CheckUnreadSuiteNotificationsCount(suite){
        return this.CheckUnreadSuiteNotifications(suite).length
      },
      CheckSuiteNotificationCount(suite){
          return this.CheckSuiteNotifications(suite).length
      },
      CheckActiveSuite(item){
        //console.log('item',item)
        if(this.ActiveSuiteTemplate && this.ActiveSuiteTemplate.id !== item.id || !this.ActiveSuiteTemplate){
          //i think NOT right? so NOT
          //this.$store.commit('setActiveSuiteTemplate',item)
          this.GetSuiteTemplate()
         this.$router.push('/Your-Domain/'+item.id+'/Main')
          this.ToggleChat(true)
        }
        else{
          this.ToggleChat()
        }
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      AppPluginsMatched(categorymatches){
            let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
        RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
            if(this.DataSingleBuilder){
                return item.Exclusively_as_Extension
            }
            else{
                return item
            }
        })
      },
        GetSuiteTemplate(){
            this.SuiteTemplate = Object.assign({},this.ActiveSuiteTemplate)
            //console.log('this.SuiteTemplate',this.SuiteTemplate)
            if(this.SuiteTemplate){
                //console.log('this.SuiteTemplate',this.SuiteTemplate)
                //alert('thought')
                //this.GetMarketplacetemplate()
            }
        },
        ToggleChat(forced){
          if(!forced){
            this.ChatDialog = !this.ChatDialog
            this.mini = !this.ChatDialog
            this.$store.commit('setChatDialog',this.ChatDialog)
          }
          else{
            this.ChatDialog = true
            this.$store.commit('setChatDialog',this.ChatDialog)
          }
          if(this.ChatDialog){
            this.HiddenBar = false
          }
            
        },
        CapatilizeSentence(str) {
            let smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
            let words = str.split(' ');      // Get me an array of separate words

            words = words.map(function(word, index) {
                if (index > 0 && ~word.search(smallWords))
                return word;                 // If it's a small word, don't change it.
                
                if (~word.lastIndexOf('.', 1)) // If it contains periods, it's an abbreviation: Uppercase.
                return word.toUpperCase();   // lastIndexOf to ignore the last character.

                // Capitalize the fist letter if it's not a small word or abbreviation.
                return word.charAt(0).toUpperCase() + word.substr(1); 
            });
            
            return words.join(' ');
            },
        abbreviate(str) {
            let final = ''
            let words = str.split(" ")
            words.map(wrd => {
                final = final+wrd.substr(0,1).toUpperCase()
            })
            return final
    }
    }
}
</script>

<style>

</style>



