<template>
  <div>
    <v-card-subtitle class="mediumoverline" style="padding-top:30px;">
                        Previously Ordered
                    </v-card-subtitle>
                     <v-card  class="siteconfigcard"  width="100%">
                   <v-list>
                       <v-list-item v-for="item in OrderedItemsbyArticle" :key="item.itemObjKey">
                           <v-list-item-content>
                               <v-list-item-title>
                           {{item[item.identifier]}}
                               </v-list-item-title>
                               <v-list-item-subtitle>
                                   {{item.SalesOrderid}}
                               </v-list-item-subtitle>
                           </v-list-item-content>
                           <v-list-item-content>
                               {{item.Qty}}
                           </v-list-item-content>
                           <v-list-item-actions>
                               <v-btn :small="MiniView" :to="'/StoreItem/'+item.StoreCollection+'/'+item.WarehouseDoc">View in Store</v-btn>
                           </v-list-item-actions>
                       </v-list-item>
                   </v-list>
                     </v-card>
                   <!-- Items from Previous Orders
                   {{OrderedItemsbyArticle}} -->
                   <!-- <DirectoryViewer v-if="CurrentEntity" :ProvidedAdditionalFilters="AdditionalFilters" @ActivateFeaturesListConfigDialog="ActivateFeaturesListConfigDialog" :Record="Record"
                    :ProvidedNavList="ComputedNavList" :ProvidedCurrentEntity="CurrentEntity" @ActivateNewNavItemDialog="ActivateNewNavItemDialog" @SaveNavList="SaveNavList" @EditNavItem="EditNavItem"
                    @EditNavItemSubItem="EditNavItemSubItem" @ActivateTreeNode="ActivateTreeNode" @ActivateViewportBuilder="ActivateViewportBuilder" :ProvidedFeatureList="CurrentEntity.FeatureList"
                    />   -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','ActiveAccount','ClientStoreOrders'],
    components: {},
    data() {
        return {
            OrderLineItems: [],
        }
    },	
    computed:{
        OrderedItemsbyArticle(){
            //ProvidedCurrentEntity
            return this.OrderLineItems.map(item => {
                let itemobj = item.LookupObj
                itemobj.SalesOrderid = item.SalesOrderid
                itemobj.Qty = item.Qty
                itemobj.Created_On = item.Created_On      
                return itemobj          
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetOrderLines()
    },
    methods:{
        GetOrderLines(){
            //console.log(this.ClientStoreOrders)
            this.ClientStoreOrders.map(record => {
                let path = db.collection(record.topicid.split('_').join('').toLowerCase()).doc(record.id).collection('Cart_Items').where('BillingAccountid','==',this.ActiveAccount.id)
                path.onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                    if (change.type === 'added') {
                        let invoiceobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        invoiceobj.SalesOrderid = record.id
                        this.OrderLineItems.push(invoiceobj)
                    }
                    if (change.type === 'modified') {
                        let invoiceobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        invoiceobj.SalesOrderid = record.id
                        let oncheck = this.OrderLineItems.find(obj => obj.id === invoiceobj.id)
                        if(oncheck){
                            let index = this.OrderLineItems.indexOf(oncheck)
                            this.OrderLineItems.splice(index,1,invoiceobj)
                        }
                    }
                    })
                })
            })
            // this.StoreOrderEntities.map(ent => {
            //     let path = db.collection(ent.id.split('_').join('').toLowerCase())
            //     path.onSnapshot(res => {
            //         const changes = res.docChanges();
            //         changes.forEach(change => {
            //         if (change.type === 'added') {
            //             let invoiceobj = {
            //             ...change.doc.data(),
            //             id: change.doc.id
            //             }
            //             this.OrderLineItems.push(invoiceobj)
            //         }
            //         if (change.type === 'modified') {
            //             let invoiceobj = {
            //             ...change.doc.data(),
            //             id: change.doc.id
            //             }
            //             let oncheck = this.OrderLineItems.find(obj => obj.id === invoiceobj.id)
            //             if(oncheck){
            //                 let index = this.OrderLineItems.indexOf(oncheck)
            //                 this.OrderLineItems.splice(index,1,invoiceobj)
            //             }
            //             this.OrderLineItems.push(invoiceobj)
            //         }
            //         })
            //     })
            // })
        },
    }
}
</script>

<style>

</style>



