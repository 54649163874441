    <template>
    <div>
    
<AllMyTasksandAppointments :SystemEntities="SystemEntities" @RemoveActivity="RemoveActivity" v-if="ActivitiesbyType" :TeamView="TeamView"
:ActivityType="ComputedActivityType" :UserRecord="ActiveUser" :AllEvents="AppointmentsArray" :ActivitiesArray="ActivitiesArray"
 :UnreadNotifications="UnreadNotifications"
  />
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'
import MyActivitiesbyType from '@/views/Activities/MyProfile/MyActivitiesbyType'
import AllMyTasksandAppointments from '@/views/Activities/MyProfile/AllMyTasksandAppointments'

export default {
  props: ['AppisDarkMode','TeamView','MenuItem','SystemEntities','MyActivitiesArray','UnreadNotifications'],
  components: { BreadCrumbs,AllMyTasksandAppointments },
  data() {
    return {
    
    ActivityType: '',
    ActivitiesbyType: true,
      Icons: [],
      drawer: false,
      items: [],
    
      UserFullName: '',
      UserAssignedRoles: [],
      IsAdmin: false,
      // MyActivitiesArray: [],
      UserRecord: {},
      ActiveUser: '',
    }
  },
  computed: {
    ActivitiesArray(){
      return this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id})
    },
    AppointmentsArray(){
      return this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id})
    },
      ComputedActivityType(){
        if(this.ActivityType){
          return this.ActivityType
        }
        else{
          return {
            countname: 'None',
            tasks: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id}),
            apointments: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id}),
          }
        }
      },
      userLoggedIn () {
      return this.$store.getters.user
      },
      UserTeamMembers(){
          return this.UsersStore.filter(user => {
              let match = this.userLoggedIn.Subordinatearrayquery.find(obj => obj === user.id)
              if(match){
                  return user
              }
          })
      },
      UsersStore(){
          return this.$store.state.UsersArray
      },
    SelectedEvents(){
        if(this.ActivityType){
            return this.ActivityType.appointments
        }
    },
    SelectedTasks(){
        if(this.ActivityType){
            return this.ActivityType.tasks
        }
    },


    itemsCount(){
      return this.items.map(item => {
          if(item.countname === 'Other'){
              
              item.count = this.MyActivitiesArray.filter(activity => {
                return typeof activity.regardingtype === 'undefined'
            }).length
            item.tasks = []
            item.tasks = this.MyActivitiesArray.filter(activity => {
                return typeof activity.regardingtype === 'undefined' && activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id
            })
            item.appointments = this.MyActivitiesArray.filter(activity => {
                return typeof activity.regardingtype === 'undefined' && activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id
            }) 
            return item
          }
          else{
             item.count = this.MyActivitiesArray.filter(activity => {
                return activity.regardingtype === item.countname
            }).length
            item.tasks = []
            item.tasks = this.MyActivitiesArray.filter(activity => {
                return activity.regardingtype === item.countname && activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id
            })
            item.appointments = this.MyActivitiesArray.filter(activity => {
                return activity.regardingtype === item.countname && activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id
            }) 
            return item
          }
        
        
        // this.itemsCount.push(item)
        // this.itemsCount.push(item)
        // this.itemsCount.push(item)
        // this.itemsCount.push(item)
      })
    },
    },

    watch: {
      
    },

  created() {
      this.ActiveUser = this.userLoggedIn

    //this.GetRequestingUser()
  },

  methods: {
    ChangeActivityStatus(event){
      if(event.status === 'Open'){
        event.status = 'Completed'
        this.SaveActivityStatus(event)
      }
      else if(event.status === 'Completed'){
        event.status = 'Open'
        this.SaveActivityStatus(event)
      }
      else if(event.status === 'Cancelled'){
        event.status = 'Open'
        this.SaveActivityStatus(event)
      }
    },

    ChangeActivityStatustoCancelled(event){
      if(event.status === 'Open'){
        event.status = 'Cancelled'
        this.SaveActivityStatus(event)
      }
    },
    SaveActivityStatus(event){
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(event.id).update({
        status: event.status,
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid: this.userLoggedIn.id,
        Modified_On: new Date(), 
      })

    },
    StatusBGColor(status){
      if(status === 'Open'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status === 'In Progress'){
        return 'blue-grey lighten-4 mx-3'
      }
      else if(status === 'On Hold'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status === 'Completed'){
        return 'blue-grey lighten-4 mx-3'
      }
      
    },
      UpdateView(){
          
          this.ActivitiesbyType = false
        //   this.MyActivitiesArray = []
          this.ActivityType = ''
          setTimeout(() => {
          this.ActivitiesbyType = true  
          }, 300);
      },
      RemoveActivity(item,type){
          console.log(item)
          console.log(this.MyActivitiesArray)
          let indexitem = this.MyActivitiesArray.find(obj => obj.id === item.id)
          let index = this.MyActivitiesArray.indexOf(indexitem)
          console.log(index)
          this.MyActivitiesArray.splice(index,1)
          console.log(this.MyActivitiesArray)
          this.ActivitiesbyType = false
          setTimeout(() => {
          this.ActivitiesbyType = true  
          }, 300);

      },
      ActivateType(type){
        console.log('type',type)
          this.ActivitiesbyType = false
          setTimeout(() => {
            this.ActivityType = type
          this.ActivitiesbyType = true  
          }, 300);
          
      },
    DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserRecord = userdetails
          vm.UserRecord.id = user.uid
          
          if(vm.TeamView){
              vm.userLoggedIn.Subordinatearrayquery.map(teammemberid => {
                  console.log('getting for team member ',teammemberid)
                  vm.GetMemberActivities(teammemberid)
              })
          }
          else{
              vm.GetActivities()
          }
          vm.getUserActivityRelated()
          })
        }
      })
    },
    GetMemberActivities(teammemberid){
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Ownerid', '==', teammemberid).where('Type.Name', 'in', ['Task','Appointment']).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
          if(this.ActivitiesbyType){
              this.ActivitiesbyType = false
              setTimeout(() => {
                this.ActivitiesbyType = true
              }, 300);
          }
        }


      })
    //   this.itemsCount = []
      
    })
    },
    GetActivities(){
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('Ownerid', '==', this.UserRecord.id).where('Type.Name', 'in', ['Task','Appointment']).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
          if(this.ActivitiesbyType){
              this.ActivitiesbyType = false
              setTimeout(() => {
                this.ActivitiesbyType = true
              }, 300);
          }
        }


      })
    //   this.itemsCount = []
      
    })
    },


    async getUserActivityRelated() {
        var vm = this;

      await firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
        //console.log('AuthchangeLoggedIn but not dispatching userstate')
        // console.log(user.uid)
        // vm.$store.dispatch('UserState',user)
      vm.items = []
      let Otheritemobject = {
            icon: 'mdi-help',
            title: 'Other',
            to: '/MyActivities/undefined',
            countname: 'Other'
          }
          vm.items.push(Otheritemobject)
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
           var userdetails = snapshot.data()

          vm.UserFullName = userdetails.Name+' '+userdetails.Surname
          if(typeof userdetails.rolesarrayQuery !== 'undefined'){
          vm.UserAssignedRoles = userdetails.rolesarrayQuery
          let AdminTest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
          
          let DefaultgetAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get All')
      if(typeof DefaultgetAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let Name21roletest = vm.UserAssignedRoles.find(obj => obj === 'Name 21')
      if(typeof Name21roletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list All')
      if(typeof DefaultlistAllroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultdeleteALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete ALL')
      if(typeof DefaultdeleteALLroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit')
      if(typeof DefaultUnitroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultupdateUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update Unit Down')
      if(typeof DefaultupdateUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultgetUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UserUnit')
      if(typeof DefaultgetUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UserUnit')
      if(typeof DefaultlistUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultCreateAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Create All')
      if(typeof DefaultCreateAllroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultlistUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default list UnitDown')
      if(typeof DefaultlistUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let Defaultcreateunitdownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create unitdown')
      if(typeof Defaultcreateunitdownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateALLroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update ALL')
      if(typeof DefaultupdateALLroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultOwnerroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Owner')
      if(typeof DefaultOwnerroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultAllroletest = vm.UserAssignedRoles.find(obj => obj === 'Default All')
      if(typeof DefaultAllroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultcreateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default create UserUnit')
      if(typeof DefaultcreateUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UserUnit')
      if(typeof DefaultdeleteUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultdeleteUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default delete UnitDown')
      if(typeof DefaultdeleteUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultupdateUserUnitroletest = vm.UserAssignedRoles.find(obj => obj === 'Default update UserUnit')
      if(typeof DefaultupdateUserUnitroletest !== 'undefined' || vm.IsAdmin){
      
      }
          let DefaultUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default Unit Down')
      if(typeof DefaultUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
      }
          let DefaultgetUnitDownroletest = vm.UserAssignedRoles.find(obj => obj === 'Default get UnitDown')
      if(typeof DefaultgetUnitDownroletest !== 'undefined' || vm.IsAdmin){
      
      }
          }
        })



    }

   else {
        //console.log('AuthchangeLoggedOut')
        vm.pushLoggedOutItems

      vm.items = [

        {
          icon: 'mdi-login-variant',
          title: 'Login',
          to: '/Login'
        },
      ]

  }
    })
    }

  }

}
</script>

<style>
.shaped{
  border: 1px solid red;
  border-radius: 5px;
}
</style>
    