<template>
  <div style="margin-top: 50px;">

      <PODocViewer :PDFExportFunctionData="POPDFFunctionData" :SelectedSupplier="SelectedSupplier" v-if="POData && POLineItems && POPDFFunctionData"
      :POData="POData" :RightHeaderFields="ComputedPORightHeaderFields" :LineItems="ComputedPOLineItems" :HeadersforTable="POLineHeadersforTable" :DocRef="PODocRef" :LineItemsRef="POLineItemsRef"
      />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PODocViewer from '@/components/SuitePlugins/Warehousing/PODocViewer'

export default {
    props: ['System','SystemEntities'],
    components: {PODocViewer},
    data() {
        return {
            POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Warehouse',Type: 'Lookup',LookupFieldName: 'Name',propvalue: 'Warehouse',value: 'Warehouse.Name',class: 'overline',TableHidden: true},
              {text: 'Item',propvalue: 'StockObj',value: 'StockObj.Item_Name',Type: 'Lookup',LookupFieldName: 'Item_Name',class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Delivery Date',propvalue: 'Delivery_Date',value: 'Delivery_Date',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',propvalue: 'Sales_Order',value: 'Sales_Order.Name',class: 'overline',TableHidden: true},
            ],
            POLineItems: [],
            SelectedSupplier: '',
            POData: '',
            PDFExportFunctionData: '',
            PORightHeaderFields: [
            {Name: 'Created_On',DisplayName: 'PO Date',Type: 'Date'},
            {Name: 'Payment_Terms',DisplayName: 'Payment Terms',text: 'Payment Terms',value: 'Payment_Terms',Type: 'Single Line Text'}, 
            {Name: 'Shipping_Method',DisplayName: 'Shipping Method',text: 'Shipping Method',value: 'Shipping_Method',Type: 'Option Set',Options:[{ID: 1000001,Name: 'FOB'}]},
            {Name: 'Promised_Date',DisplayName: 'Promised Date',text: 'Promised Date',value: 'Promised_Date',Type: 'Date'},
          ],
        }  
    },
    computed:{
        ComputedPOLineItems(){
          return this.POLineItems
        },
        POLineItemsRef(){
          return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('PurchaseOrdersLine_Items').where('Warehouseid','==',this.$route.params.slug).where('Transaction_Docid','==',this.$route.params.id)
        },
        PODocRef(){
          return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('PurchaseOrders').doc(this.$route.params.id)
        },
        POLineHeadersforTable(){
        return this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
      },
        OrderforDelivery(){
            return this.POData.Delivery
        },
        ComputedPORightHeaderFields(){
        return this.OrderforDelivery ? this.PORightHeaderFields : this.PORightHeaderFields.filter(field => {
          return field.Name !== 'Shipping_Method'
        })
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        POPDFFunctionData(){
            if(this.PDFExportFunctionData){
                let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //POLineItemsTable
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'POLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.POLineItemsTable
        newelemnt.Justify = 'center'
        if(this.POLineItems.length <= 3){
          newelemnt.PaddingTop = 450
        }
        else if(this.POLineItems.length <= 6){
          newelemnt.PaddingTop = 425
        }
        else if(this.POLineItems.length <= 9){
          newelemnt.PaddingTop = 400
        }
        else if(this.POLineItems.length <= 12){
          newelemnt.PaddingTop = 375
        }
        else {
          newelemnt.PaddingTop = 350
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata
            }
        
      },
    },
    created(){
        if(this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.$route.params.slug] && this.userLoggedIn.Warehouses[this.$route.params.slug].Ordering || this.userIsAdmin){
            this.GetPurchaseOrder()
            this.GetPOPDFFunction()
        }
        else{
            this.$router.push('/PermissionError')
        }
    },
    methods:{
        GetPOPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Purchase_Orders').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.PDFExportFunctionData = functiondata
        })
      },
        ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

        GetPurchaseOrder(){
            this.PODocRef.onSnapshot(snapshot => {
                let podata = snapshot.data()
                this.POData = podata
                this.POData.id = this.$route.params.id
                db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('storesuppliers').doc(this.POData.Supplierid).onSnapshot(suppsnap => {
                let supplierdata = suppsnap.data()
                    this.SelectedSupplier = supplierdata
                })
                //POLineItems
                this.POLineItemsRef.onSnapshot(res => {
                     const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                            let prlineobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            let oncheck = this.POLineItems.find(obj => obj.id === prlineobj.id)
                            if(oncheck){
                              let index = this.POLineItems.indexOf(oncheck)
                              this.POLineItems.splice(index,1,prlineobj)
                            }
                            else{
                               this.POLineItems.push(prlineobj)
                            }
                           
                        }
                    })
                })
            })
        }
    }
}
</script>

<style>

</style>


