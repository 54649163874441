import { render, staticRenderFns } from "./GroupFeaturedMemberView.vue?vue&type=template&id=24df1736&"
import script from "./GroupFeaturedMemberView.vue?vue&type=script&lang=js&"
export * from "./GroupFeaturedMemberView.vue?vue&type=script&lang=js&"
import style0 from "./GroupFeaturedMemberView.vue?vue&type=style&index=0&id=24df1736&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports