<template>
  <span>
      <v-row class="justify-start recordtoolbar" v-if="MiniView && ActiveTreeNode[0]">
          <v-btn class="my-2 mx-6" outlined dark v-if="ActiveTreeNode[0]" @click="SelectTreeNode(ActiveTreeNode[0])">
            <v-icon>mdi-chevron-left</v-icon>  {{ActiveTreeNode[0].name}}
          </v-btn>
      </v-row>
       <v-row class="justify-start" style="padding-left:25px;padding-right:25px;" v-if="MiniView && ActiveTreeNode[0] && ActiveTreeNode[0].children">
           <v-col  :cols="ActiveTreeListFiltersColumns" v-for="subfilter in ActiveTreeListFilters" :key="subfilter.itemObjKey" style="text-align: center;padding:5px;">
          <!-- <v-overflow-btn :placeholder="subfilter.FilterName" return-object v-model="subfilter.ActiveFilter" clearable @change="CheckTempTreeNode(subfilter.ActiveFilter)" :items="subfilter.FilterData" :item-text="subfilter.PropName"
          class="shopoverflowfilterbtn"></v-overflow-btn> -->
          <v-select dense :placeholder="subfilter.FilterName" return-object v-model="subfilter.ActiveFilter" style="padding-top:10px;" outlined :class="$vuetify.theme.dark ? 'blacklabel' : 'whitelabel'"
          clearable @change="CheckTempTreeNode(subfilter.ActiveFilter)" :items="subfilter.FilterData" :item-text="subfilter.PropName"
          />
           </v-col>
      </v-row>
      <v-row class="justify-center" style="padding:15px;" v-if="MiniView && !ActiveTreeNode[0]">
          
      <v-col  cols="3" v-for="clss in ActiveTreeList" :key="clss.itemObjKey" style="text-align: center;padding:2px;">
            <v-btn dark elevation="0" class="links" fab style="padding:0px;"
            @click="SelectTreeNode(clss)">
              
            <v-icon
              >{{clss.Icon ? clss.Icon : 'mdi-filter-outline'}}</v-icon>
            </v-btn><br>
            <span class="caption">
            {{clss.name}}
            </span>
          </v-col>
      </v-row>
      <span  v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">
        <v-list-item  class="overline">
        {{navitem.Title}} <v-spacer></v-spacer>
         <v-icon v-if="!ListPrimaryField && $route.name.includes('DirectoryBuilder')" @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon>
        <!-- <v-icon v-if="ActiveBrandFilter" @click="ActiveBrandFilter = ''">mdi-close</v-icon> -->
        </v-list-item>
        <!-- :activatable="$route.name !== 'DirectoryBuilder'" -->
        <v-treeview return-object :active.sync="ActiveTreeNode" :activatable="!KeyWord" :style="NavFont" class="" :items="navitem.TreeList"></v-treeview>
        
      </span>
      <span v-if="ListPrimaryField && $route.name.includes('DirectoryBuilder') && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">
       <v-list>
           <v-dialog             			
                max-width="400px">							
                <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on">Add Option</v-btn>
                </template>
                <v-card>
                <v-card-title>
                    New Filter
                    </v-card-title>
                    <v-card-text>
                    <v-text-field v-model="NewOptionName" label="Name"/>
                    <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                        v-model="NewOption.Type" label="Type"/> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-btn>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddOption(1)">Save</v-btn>
                    </v-card-actions>  
                </v-card>
            </v-dialog>
           
        <v-list-group
            v-for="item in ListPrimaryField.Options"
            :key="item.ID"
            v-model="item.active"
            no-action
        >
            <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title v-text="item.Name"></v-list-item-title>
            </v-list-item-content>
            </template>
             <v-dialog             			
                max-width="400px">							
                <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on">Add Option</v-btn>
                </template>
                <v-card>
                <v-card-title>
                    New Filter
                    </v-card-title>
                    <v-card-text>
                    <v-text-field v-model="NewOptionName" label="Name"/>
                    <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                        v-model="NewOption.Type" label="Type"/> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-btn>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddOption(2,item)">Save</v-btn>
                    </v-card-actions>  
                </v-card>
            </v-dialog>
            <v-btn x-small @click="AppendFilters(1,item)">Append Filters</v-btn>

            <v-list-group
            v-for="child in item.Options"
            :key="child.ID"
           sub-group
            no-action
        >
            <template v-slot:activator>
            <v-list-item-content>
                <v-list-item-title v-text="child.Name"></v-list-item-title>
            </v-list-item-content>
            </template>
             <v-dialog             			
                max-width="400px">							
                <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on">Add Option</v-btn>
                </template>
                <v-card>
                <v-card-title>
                    New Filter
                    </v-card-title>
                    <v-card-text>
                    <v-text-field v-model="NewOptionName" label="Name"/>
                    <!-- <v-select :items="['Single Line Text','Multiple Lines Text','Option Set','Single File Upload','Number Field','Date']"
                        v-model="NewOption.Type" label="Type"/> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-btn>Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddOption(3,child)">Save</v-btn>
                    </v-card-actions>  
                </v-card>
            </v-dialog>
            <v-btn x-small @click="AppendFilters(2,child,item)">Append Filters</v-btn>

            <v-list-item
            v-for="subchild in child.Options"
            :key="subchild.ID"
            >
            <v-list-item-content>
                <v-list-item-title v-text="subchild.Name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
            <v-btn x-small @click="AppendFilters(3,subchild,child,item)">Append Filters</v-btn>
             </v-list-item-content>
            </v-list-item>
        </v-list-group>
        </v-list-group>
        </v-list>
      </span>
    </span>
</template>

<script>
export default {
    props: ['navitem','navitemindex','ListPrimaryField','KeyWord'],
    components: {

    },
    data(){
        return {
            ParentFilter: '',
            ActiveTreeNode: [],
            NewOptionName: '',
            ActiveTreeList: [],
        }
    },
    watch: {
      ActiveTreeNode (value){
          if(value && typeof value !== 'undefined'){
              this.ActivateTreeNode()
          }
      },
    },
    created(){
        this.ActiveTreeList = this.navitem.TreeList
    },
    computed:{
        ActiveTreeListFiltersColumns(){
            return 12/this.ActiveTreeListFilters.length
        },
        ActiveTreeListFilters(){
            let filters = []
            //appendedfilteritems pending
            //but this means, we cannot push the children., the children item is a single filter
            if(this.ActiveTreeNode[0] && this.ActiveTreeNode[0].children){
             filters.push({FilterName: 'Type',FilterData: this.ActiveTreeNode[0].children,PropName: 'name',ActiveFilter: ''} ) 
            //  filters.push({FilterName: 'Type',FilterData: this.ActiveTreeNode[0].children,PropName: 'name',ActiveFilter: ''} ) 
            //  filters.push({FilterName: 'Type',FilterData: this.ActiveTreeNode[0].children,PropName: 'name',ActiveFilter: ''} ) 
            }
            return filters
            
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        NavFont(){
        if(this.NavStyleFont){
          return 'font-family: '+this.NavStyleFont+';font-size: 14px;'
        }
        else{
          return 'font-size: 14px;'
        //   border: 0.2px solid;border-color:rgba(107, 107, 107, 0.042);
        }
        
      },
    },
    methods: {
        AppendFilters(lvl,item,parent,grandparent){
            let TreeList = this.navitem.TreeList
            //actually this is completely wrong china. if we do it from ListPrimaryField we are adding to the ListPrimaryFeild Options, not to TreeList
            //we want to be adding to Treelist
            //But then again when it comes to it, perhaps TreeList needs to align with ListPrimaryField right?
            //any single view would refer tot eh LsitPrimaryField I would say?
            console.log(lvl,item,parent,TreeList)
            let listitem = ''
            if(grandparent){
                let grandparentlistitem = TreeList.find(obj => obj.id === grandparent.ID)
                let parentlistitem = grandparentlistitem.children.find(obj => obj.id === parent.ID)
                listitem = parentlistitem.children.find(obj => obj.id === item.ID)
            }
            else if(parent){
                let parentlistitem = TreeList.find(obj => obj.id === parent.ID)
                listitem = parentlistitem.children.find(obj => obj.id === item.ID)
            }
            else{
                listitem = TreeList.find(obj => obj.id === item.ID)
            }
            this.$emit('AppendFilters',lvl,item,listitem,parent)
        },
        CheckTempTreeNode(subfilter){            
            if(subfilter){
                this.ParentFilter = this.ActiveTreeNode[0]
            }
            //console.log(this.ActiveTreeNode,this.ActiveTreeNode[0],this.ParentFilter)
            if(subfilter){
                this.SelectTreeNode(subfilter)
            }
            else{
                this.SelectTreeNode(this.ParentFilter)
                this.ParentFilter = ''
            }
        },
        SelectTreeNode(clss){
            //console.log(this.ActiveTreeNode,this.ActiveTreeNode[0],clss,this.ActiveTreeList,this.navitem)
            if(clss && this.ActiveTreeNode[0] && this.ActiveTreeNode[0].id === clss.id){
                this.ActiveTreeNode = []
            }
            else{
             this.ActiveTreeNode[0] = clss   
            }    
            if(this.ActiveTreeNode[0] && this.ActiveTreeNode[0].children){  
                this.ActiveTreeList = this.ActiveTreeNode[0].children
            }
            else if(this.ActiveTreeNode[0] && !this.ActiveTreeNode[0].children){
                //little unnecceasry
                this.ActiveTreeList = []
            }
            else{
               this.ActiveTreeList = this.navitem.TreeList
            }        
            this.ActivateTreeNode()
        },
        ActivateTreeNode(){         
          //console.log(this.ActiveTreeNode)
        //   console.log(this.ListPrimaryField,this.ActiveTreeNode[0])
          this.$emit('ActivateTreeNode',this.ActiveTreeNode[0])
      },
        EditNavItem(navitem,navitemindex){
            this.$emit('EditNavItem',navitem,navitemindex)
        },
        AddOption(lvl,parent){
            this.ListPrimaryField.lastassignedinteger = this.ListPrimaryField.lastassignedinteger-1+2
            let newoption = {
                Name: this.NewOptionName,
                ID: this.ListPrimaryField.lastassignedinteger
            }
            let level = this.ListPrimaryField.Levels[lvl-1]
            newoption.Level = level
            newoption.LevelFieldName = level.FieldName
            if(lvl < this.ListPrimaryField.LastLevel){
                newoption.Options = []
                newoption.HasDeepHierarchy
            }
            if(lvl === 1){
               this.ListPrimaryField.Options.push(newoption) 
            }
            else if(lvl !== 1){
                parent.Options.push(newoption)
            }
            this.NewOptionName = ''
            //thus the below shows only issue is knowing which level we wokr from, easy
            //HasDeepHierarchy is lvl not exceeding
            //Level (fullobj)
            //LevelFieldName (txt)
            //Options (IFHasDeepHierarchy)
            
        }
    }
}
</script>

<style>

</style>