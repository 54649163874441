<template>
<div>
<v-list-item>
    <v-list-item-content v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.md || !Editing" style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action style="font-weight: 300;" v-if="!Editing">
      {{CurrencyFormatter(FieldValue,$store.state.DefaultCurrency.Currency)}}
    </v-list-item-action>
    <v-list-item-content style="font-weight: 300;" v-if="Editing" class="bootstrap-form-input">
     <v-text-field class="" :filled="field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation' || field.ReadOnly"
        :readonly="field.ReadOnly" outlined dense
        :rules="field.IsMandatory? [$store.state.formrules.numberfield,$store.state.formrules.required] : [$store.state.formrules.numberfield]" 
        v-model.number="Record[field.Name]" type="number" :label="field.DisplayName"></v-text-field>
    </v-list-item-content>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      FieldValue(){
        return this.Record && typeof this.Record[this.field.id] === 'number' ? 
        this.Record[this.field.id] : 
        0
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        PerformCalulation(Record,field,IsRollup){
            console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map((formula,i) => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            //console.log(formula.integer)
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = Record[formulas[i-1].fieldName.Name]*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                            //console.log(formula.integer)
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
        UpdateRecordCalculation(RecordObj,field,result){
            this.$emit('UpdateRecordCalculation',RecordObj,field,result)
        },
    }
}
</script>

<style>

</style>



