<template>
  <div>
    <v-dialog v-model="StartupDialog" max-width="500">
    <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span> 
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="step"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="1">
         
        <v-card-text>
          <span class="text-caption grey--text text--darken-1">
            Choose below from the option that best describes what you want to build.
          </span>
          <v-select style="font-size:12px;"
            label="Description"
            v-model="LibraryType"
            item-text="ConciseDescription"
            return-object
            :items="LibraryTypes"
            dense
          >
           <template slot='selection' slot-scope='{ item }'>
    <v-icon small>{{item.Icon}}</v-icon> {{ item.Type }}
  </template>
  <template slot='item' slot-scope='{ item }'>
    {{item.ConciseDescription}}
  </template>
          </v-select>
         
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
       
       <ProductsComponent v-if="LibraryType.Type === 'Products'" />
       <IndexDocumentationComponent v-if="LibraryType.Type === 'Indexed Documentation'" />
       <TechnicalCaseStudiesComponent v-if="LibraryType.Type === 'Technical Case Studies'" />
       <HowToComponent v-if="LibraryType.Type === 'How to Library'" />
       <CourseMaterialComponent v-if="LibraryType.Type === 'Course Material'" />
       <InductionComponent v-if="LibraryType.Type === 'Induction'" />
        <LocationComponent v-if="LibraryType.Type === 'Places of Interest'" />
      </v-window-item>

      <!-- <v-window-item :value="3">
       <ProductsComponent/>
      </v-window-item>
      <v-window-item :value="4">
       <IndexDocumentationComponent />
      </v-window-item>
      <v-window-item :value="5">
       <TechnicalCaseStudiesComponent/>
      </v-window-item>
      <v-window-item :value="6">
       <HowToComponent />
      </v-window-item>
      <v-window-item :value="7">
       <CourseMaterialComponent />
      </v-window-item>
      <v-window-item :value="8">
       <InductionComponent />
      </v-window-item>
      <v-window-item :value="9">
       <LocationComponent />
      </v-window-item> -->
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="step === 1"
        text
        @click="step--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="step === 9"
        color="primary"
        depressed
        @click="step++"
      >
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

import ProductsComponent from '@/components/ContentLibraryBuilder/Components/ProductsComponent';
import IndexDocumentationComponent from '@/components/ContentLibraryBuilder/Components/IndexDocumentationComponent';
import TechnicalCaseStudiesComponent from '@/components/ContentLibraryBuilder/Components/TechnicalCaseStudiesComponent';
import HowToComponent from '@/components/ContentLibraryBuilder/Components/HowTo';
import CourseMaterialComponent from '@/components/ContentLibraryBuilder/Components/CourseMaterialComponent';
import InductionComponent from '@/components/ContentLibraryBuilder/Components/InductionComponent';
import LocationComponent from '@/components/ContentLibraryBuilder/Components/LocationComponent';
import CustomComponent from '@/components/ContentLibraryBuilder/Components/CustomComponent';

export default {
    props: ['System','SystemEntities'],
    components: {
      ProductsComponent,
      IndexDocumentationComponent,
      TechnicalCaseStudiesComponent,
      HowToComponent,
      CourseMaterialComponent,
      InductionComponent,
      LocationComponent,
      CustomComponent
    },
    data() {
        return {
          LibraryType: '',
          StartupDialog: true,
          step: 1,
          LibraryTypes: [
            {
              Type: 'Products',
              ConciseDescription: 'I want to showcase products to the public, or internal uses without a product store.',
              Icon: 'mdi-cart-off'
            },
            {
              Type: 'Indexed Documentation',
              ConciseDescription: 'I want a Documentation library, possibly indexed with a navigational bar to the index.',
              Icon: 'mdi-library-shelves'
            },
            {
              Type: 'Technical Case Studies',
              ConciseDescription: 'I want to display Case Studies either to public, or for intenral uses.',
              Icon: 'mdi-library-shelves'
            },
            {
              Type: 'How to Library',
              ConciseDescription: 'I want a "How to Library", like Cooking recipes, or other "train by reading" purposes.',
              Icon: 'mdi-help'
            },
            {
              Type: 'Course Material',
              ConciseDescription: 'I want display course material, intended for specific Users.',
              Icon: 'mdi-book-education'
            },
            {
              Type: 'Induction',
              ConciseDescription: 'I want an Induction programme, intended for specific Users.',
              Icon: 'mdi-toolbox'
            },
            {
              Type: 'Places of Interest',
              ConciseDescription: 'I want to showcase places of interest to public, or internal Users.',
              Icon: 'mdi-map'
            },
            {
              Type: 'Advanced',
              ConciseDescription: 'I want a completely custom library for purposes not mentioned above (advanced).',
              Icon: 'mdi-cogs'
            },
          ]
        }
    },
    computed:{
      currentTitle () {
        switch (this.step) {
          case 1: return 'What do I want?'
          case 2: return 'Preview Template'
          case 3: return 'Products'
          case 4: return 'Index Documentation'
          case 5: return 'Technical Case Studies'
          case 6: return 'How to'
          case 7: return 'Course Material'
          case 8: return 'Induction'
          case 9: return 'Places of Interest'
          default: return 'Advanced'
        }
      },

    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
