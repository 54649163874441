<template>
  <div>
    <v-dialog v-model="PaymentDialog" max-width="400" persistent>
        <v-card tile
            class="background mx-auto"
            max-width="500"
          >
            <v-img height="100" contain class="black" :src="require('@/assets/logo.png')">
            <v-card style="background-color: #110022bb;" tile height="100%" width="100%">
                   <v-card-title style=""
              class="mediumoverline white--text">
              <v-avatar 
                  color="purple"
                  class="subheading white--text"
                  size="40"
                  v-text="PaymentDialogStep"
                ></v-avatar><span style="padding-left:10px;">{{ PaymentDialogTitle }}</span>
                <v-spacer>
                </v-spacer>
                
                <v-btn fab small icon @click="ClosePaymentDialog()" dark outlined color="red">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>     
                <v-card-text class="white--text">
                {{PaymentDialogDescription}}
              </v-card-text>         
              </v-card>
            </v-img>
                <v-window v-model="PaymentDialogStep">
                   <!-- <v-card-text>
                {{PaymentDialogDescription}}
              </v-card-text> -->
              <v-window-item :value="1">
                <!-- MarketDialogType applies HOWEVER I relaly think we overkill this now.
                You see we do "options" as if there are multiple but realisticaly only had 1 option at a time for plugins, package seleciton, all of the above always only one! -->
                
                  <SignupPackages :System="System" :SystemEntities="SystemEntities" :ViewOnly="false" :FullWidth="true"
                   @SignupwithPackage="SignupwithPackage" v-if="MarketDialogType === 'Site Directory Listing'"
                    :SignUpLabel="'Select'"
                    :SubscriptionPackages="MarketDialogOptions"/>
                  <v-row class="justify-center fill-height" v-if="field">
                  <!-- field.Array.concat(field.Array,field.Array,field.Array,field.Array) -->
                          <div class="flex xs12 sm12 md12 lg12 xl2" style="padding:20px;" v-for="item in field.Array" :key="item.itebObjKey">
                                <v-card height="100%" width="100%" class="SoftBuilderGradient">
                                  <v-card-title class="BuilderGradient" style="align-items: normal;padding-bottom:25px;">
                                    {{item[field.LookupFieldName]}}
                                      <v-spacer>
                                    </v-spacer>
                                  <v-icon v-if="item.Icon">{{item.Icon}}</v-icon>
                                    <!-- <span class="caption" v-if="field.LookupSubtitleField && field.LookupSubtitleField.IsCurrency">
                                    {{CurrencyFormatter(item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                                  </span>
                                  <span v-else-if="field.LookupSubtitleField">
                                    {{item[field.LookupSubtitleField.Name]}}
                                  </span> -->
                                  </v-card-title>
                                  <v-card-subtitle style="align-items: normal;" class="grey darken-4" v-if="field.LookupSubtitleField">
                                    <span v-if="field.LookupSubtitleField && field.LookupSubtitleField.IsCurrency" style="text-align: -webkit-right;">
                                    Price - {{CurrencyFormatter(item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                                  </span>
                                  <span v-else style="text-align: -webkit-right;">
                                    {{item[field.LookupSubtitleField.Name]}}
                                  </span>
                                  </v-card-subtitle>
                                  <v-card-text style="text-align: -webkit-center;" class="black" v-if="field.LookupAvatarField">
                                    <v-img height="150" v-if="item[field.LookupAvatarField.Name] && item[field.LookupAvatarField.Name].ThumbURL" :src="item[field.LookupAvatarField.Name].ThumbURL"
                                    />
                                    <v-img height="150" v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && $vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && $vuetify.theme.dark"  src="@/assets/ImageHolder_dark.png" />
                                    <v-img height="150"  v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && !$vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && !$vuetify.theme.dark"  src="@/assets/ImageHolder.png" />
                                  </v-card-text>
                                  <v-card-text v-if="field.LookupCaptionField" v-html="smart_substr(item[field.LookupCaptionField.Name],MiniView ? 130 : 170)">
                                  </v-card-text>
                                  <!--  style="position: absolute; bottom:10px;left:10px;" -->
                                  <v-card-actions>
                                    <v-btn @click="ConvertPlugintoPackageandSignup(item)">Select</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </div>
                         </v-row>
              </v-window-item>
                <v-window-item :value="2">
                  <v-card-text style="padding:30px;">                    
                      
                        <v-autocomplete prepend-icon="mdi-bank" return-object :readonly="!CanToggleBA" :filled="!CanToggleBA" item-text="Client_Reference"
                     v-model="BillingAccount" :items="ComputedUserBillingAccounts"/>
                       <v-card-subtitle style="font-weight: 500;padding-bottom: 0px;">
                       <!-- shop name -->
                        {{System.Company_Name}} 
                       </v-card-subtitle>
                     <v-list dense class="detailslistoutline" v-for="lineitem in ComputedDocLineItems" :key="lineitem.itemObjKey">
                      <v-list-item dense>
                        <v-list-item-content>
                          {{lineitem.Name}}
                        </v-list-item-content>
                        <v-list-item-action-text v-html="CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency)">
                          
                        </v-list-item-action-text>
                      </v-list-item>
                     </v-list>
                     <v-divider>
                     </v-divider>
                     <v-card-title>
                      <span style="font-weight: 300;">
                      Total: 
                      </span>
                      <v-spacer>
                      </v-spacer>
                      <span class="mediumoverline" v-html="CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency,true)">
                      </span>
                     </v-card-title>
                     <v-card-title class="justify-center" style="text-align: center;" v-if="$store.state.PDFFooterObj">
                       <span class="text-caption grey--text text--darken-1"  v-html="$store.state.PDFFooterObj.Footer_Note">
                      
                      </span>
                     </v-card-title>
                     <v-divider>
                     </v-divider>
                     <!-- other payment options, pay with cellphone or whatever -->
                     
                  </v-card-text>
                </v-window-item>
                 <v-window-item :value="3">
                   <v-card-title style="font-weight: 300;" class="justify-center largeoverline" v-html="CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency,true)">
                      <!-- {{CurrencyFormatter(GrandTotal,$store.state.DefaultCurrency.Currency)}} -->
                     </v-card-title>
                     <v-card-text>
                     <v-card elevation="3" rounded style="padding:15px;padding-top:30px;">
                      <v-tabs centered>
                        <v-tab>Card<v-icon right>mdi-credit-card</v-icon>
                        </v-tab>
                        <v-tab-item>
                          <v-card-text>
                          <v-form ref="cardpaymentform">
                            <v-text-field dense outlined rounded v-model="Card.Number" label="Card Number (no spaces or hyphens)"/>
                            <v-row class="justify-center">
                              <v-col cols="6">
                            <v-text-field dense outlined rounded v-model="Card.Expiry" label="Expiry (MM/YY)"/>
                              </v-col>
                              <v-col cols="6">
                            <v-text-field type="password" dense outlined rounded v-model="Card.Expiry" label="Code (CVC)"/>
                              </v-col>
                            </v-row>
                          <v-divider>
                          </v-divider>
                          <v-card-actions class="justify-end">
                            <v-spacer>
                            </v-spacer>
                            <img src="@/assets/PaymentOptions.png" height="40"/>
                          </v-card-actions>
                          <v-card-title class="caption justify-center">
                            All transactions billed monthly
                          </v-card-title>
                          <!-- other payment options, pay with cellphone or whatever -->
                          
                            </v-form>
                          </v-card-text>
                        </v-tab-item>
                        <!-- <v-tab>
                          Paypal<v-icon right>mdi-credit-card</v-icon>
                        </v-tab>
                        <v-tab-item>
                          <v-card-text>
                          <v-form ref="paypalpaymentform" style="padding-top:10px;">
                            <v-text-field v-model.number="GrandTotal" :label="'Payment Amount ('+$store.state.DefaultCurrency.Currency+')'" 
                             dense outlined rounded readonly filled/>
                             <v-card-actions class="justify-center">
                            <v-btn class="blue" dark x-large width="200" style="'font-size:0.7em;">
                              Pay Now
                            </v-btn>
                             </v-card-actions>
                          </v-form>
                          </v-card-text>
                        </v-tab-item> -->
                      </v-tabs>
                     </v-card>
                     </v-card-text>
                </v-window-item>
                </v-window>
                 <v-divider></v-divider>

              <v-card-actions>
                <v-btn dark color="lime darken-1"
                  :disabled="RegressDisabled"
                  depressed
                  @click="RegressPaymentDialogStep()"
                >
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn dark
                  :disabled="ProgressDisabled"
                  color="purple"
                  depressed
                  @click="ProgressPaymentDialogStep()"
                >
                  Next
                </v-btn>
              
              </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'

export default {
    props: ['System','SystemEntities','PaymentDialog','PaymentShowsPackages','PreSelectedPackage'],
    components: {SignupPackages},
    data() {
        return {
          PaymentDialogStep: 1,
          BillingAccount: '',
          Card: {},          
          NewSubOrderid: '',
          selectedPackage: '',
          Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          Billing_Address: {Name: 'Billing_Address',FieldBreakdown: [],DisplayName: 'Billing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
        }
    },	
    computed:{
      field(){
        if(this.MarketDialogType === 'Group Membership' || this.MarketDialogType === 'Group Directory Listing'){
          return this.PackageField
        }
        else if(this.MarketDialogType === 'Plugin Purchase'){
          return this.PluginField
        }
      },
      PluginField(){
        let fieldobj = {id: 'Plugin',Name: 'Plugin',DisplayName: 'Plugin',value: 'Plugin',text: 'Plugin Package',
            Type: 'Lookup',LookupFieldName: 'Name',Array: this.MarketDialogOptions, Response: '',IsMandatory: true,
            LookupSubtitleField: {Name: 'Price',Type: 'Number Field',IsCurrency: true},
            LookupCaptionField: {Name: 'Description'},
            LookupAvatarField: {Name: 'IMG'},Has_Selection_Dial: true,DialType: 'Lookup Cards',DialHeader: 'Select a Subscription Package',DialCaption: 'The packages available for your selections are:'}
            return fieldobj
      },
       MarketDialogType(){
        return this.$store.state.MarketDialogType
        },
      PDFDataRecord(){
        return this.$store.state.PDFDataRecord
        },
      RegressDisabled(){
        return this.PaymentDialogStep === 1 || this.PaymentDialogStep === 2 && !this.PaymentShowsPackages
      },
      ProgressDisabled(){
        return this.PaymentDialogStep === 1 && !this.selectedPackage
      },
      MarketDialogOptions(){
        return this.$store.state.MarketDialogOptions
      },
      SelectedBA(){
        return this.$store.state.SelectedBA
      },
      CanToggleBA(){
        return this.$store.state.CanToggleBA
      },
      POSubTotal(){
        let total = this.ComputedDocLineItems.map(polineitem => {
          return polineitem.Unit_Price ? polineitem.Unit_Price*polineitem.Qty : polineitem.Price
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
        GrandTotal(){
        let total = this.POSubTotal
        //console.log(total)
        if(this.DiscountValue && this.DiscountValue !== 0){
          total = total-this.DiscountValue
        }
        if(this.ShippingTotal && this.ShippingTotal !== 0){
          total = total+this.ShippingTotal
        }
        if(this.TaxTotal && this.TaxTotal !== 0){
          total = total+this.TaxTotal
        }
        return Number(total.toFixed(2))
      },
      ComputedUserBillingAccounts(){
        return this.UserBillingAccounts.filter(acct => {
          return acct
        }).map(acct => {
          let acctobj = {}
          let props = ['id','Client_Reference','Account_Name','Account_Type','Type_Default','Customer_ID','Billing_Type','User','Userid']
          props.map(prp => {
            if(typeof acct[prp] !== 'undefined'){
              acctobj[prp] = acct[prp]
            }
          })
          return acctobj
        })
      },
      ActiveUser(){
        return this.$store.state.UsePrimaryUserObj ? this.PrimaryUserObj : this.userLoggedIn
      },
      PrimaryUserObj(){
        return this.$store.state.PrimaryUserObj
      },
      UserBillingAccounts(){
        return this.ActiveUser && this.ActiveUser.BillingAccounts ? this.ActiveUser.BillingAccounts : []
      },
      HeadersforTable(){
        return [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Site',propvalue: 'Site',value: 'Site.Name',Type: 'Lookup',LookupFieldName: 'Name',class: 'overline'},
              {text: 'Name',propvalue: 'Name',value: 'Name',Type: 'Single Line Text',class: 'overline'},
              {text: 'Description',propvalue: 'Description',value: 'Description',Type: 'Multiple Lines Text',IsContentEditable: true,class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},              
            ]
      },
      ComputedDocLineItems(){
        return this.$store.state.PDFDocLineItems
      },
      ComputedDocLineItemsTEST(){
        //return this.$store.state.PDFDocLineItems
        //let PDFDocLineItems = []
        let subpack = {
          Name: 'SME Business Listing',
          PaymentSchedule: 'Monthly',
          Description: 'Custom domain excluded, avilable on request',
          Price: 100
        }
            let descr = `<p>`+subpack.PaymentSchedule+` subscription to our `+subpack.Name+` Package</p>`
            descr = descr+`<br>`+subpack.Description
            let item = {
                Nr: 1,
                Name: subpack.Name,
                Description: descr,
                Price: subpack.Price,
                Qty: 1,
                Site: {
                    Name: this.System.Name,
                    id: this.$store.state.ActiveSuiteid
                }
            }
            return [item]
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      PaymentDialogTitle() {
        switch (this.PaymentDialogStep) {
          case 1: return 'Choose Package'
          case 2: return 'Purchase Review'
          case 3: return 'Payment Information'
          default: return 'Payment Dialog'
        }
        },
        PaymentDialogDescription() {
        switch (this.PaymentDialogStep) {
          case 1: return 'Choose from the Selection'
          case 2: return 'Review your purchase'
          case 3: return 'Confirm your payment details'
          default: return 'Payment Complete'
        }
        },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ProcessPaymentInvoice']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      PaymentDialog(v){   
        if(v){
          this.PrepareDialog()
        }
      }
    },
    created(){
      this.PrepareDialog()
      

      
    },
    methods:{
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      PrepareAddressField(fieldname,obj){
        let field = this[fieldname]
        
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : obj[field.Name+'_Street_Number'],
            [field.Name+'_Street_Name'] : obj[field.Name+'_Street_Name'],
            [field.Name+'_Suburb'] : obj[field.Name+'_Suburb'],
            [field.Name+'_City'] : obj[field.Name+'_City'],
            [field.Name+'_Postal_Code'] : obj[field.Name+'_Postal_Code'],
            [field.Name+'_State_Province'] : obj[field.Name+'_State_Province'],
            [field.Name+'_Country_Region'] : obj[field.Name+'_Country_Region']
        }
        //console.log(obj,obj[field.Name+'_Street_Number'],field.Name+'_Street_Number',StreetNumber,field.Response)
      },      
      PrepareDialog(){
        //console.log('PrepareDialog',this.SelectedBA,this.CanToggleBA,this.UserBillingAccounts)
        let acct = this.ComputedUserBillingAccounts.find(obj => obj.id === this.SelectedBA)
        if(acct){
          this.BillingAccount = acct
        }
        if(this.PaymentShowsPackages){
            this.PaymentDialogStep = 1
        }
        else{
          this.PaymentDialogStep = 2
        }
        this.PrepareAddressField('Company_Address',this.System)
        this.PrepareAddressField('Billing_Address',this.System)
        if(this.PreSelectedPackage){
          this.selectedPackage = this.PreSelectedPackage
        }
        //technicall if payment were checkout for store etc, items would be in cart so step 2,
        //but all other package or signup or plugins based stuff, we select the pack/plug first, being step 2
      },
      ProgressPaymentDialogStep(){
        if(this.PaymentDialogStep === 3){
          //generate invoice and/or/else process payment
          //1. if not direct payment we need invoice to keep accounting
          //2. if direct payment we need invoice to keep accounting
          //really unanimously step 1 generate invoice. Or it's final step if process payment. dunno. but right now no payment method so yes
          this.PrepareInvoice()
          //so prepping the invoice is great but we realy really really don't even want to view the invoice, just process it, 
          //return pdf, and store in accordance with whatever method the child componnet
        }
        else{
          this.PaymentDialogStep++
        }
        
      },
      RegressPaymentDialogStep(){
        this.PaymentDialogStep--
      },
      
      ConvertPlugintoPackageandSignup(plug){
        // let subpack = {...plug}
        // console.log(subpack)
        //this.SignupwithPackage(subpack)
        //see below what does it need?
        //PaymentSchedule
      },
      SignupwithPackage(subpack){
        let PDFDocLineItems = []
            let descr = `<p>`+subpack.PaymentSchedule+` subscription to our "`+subpack.Name+`" Package</p>`
            descr = descr+`<br>`+subpack.Description
            let item = {
                Nr: 1,
                Name: subpack.Name,
                Description: descr,
                Price: subpack.Price,
                Qty: 1,
                Site: {
                    Name: this.System.Name,
                    id: this.$store.state.ActiveSuiteid
                }
            }
            
            PDFDocLineItems.push(item)
            let taxtotal = 0
            let taxperc = 0
            let total = 0
            if(this.$store.state.PrimaryMonetizeBU && typeof this.$store.state.PrimaryMonetizeBU.Tax_Percentage !== 'undefined'){
                taxperc = this.$store.state.PrimaryMonetizeBU.Tax_Percentage
            }
            if(taxperc > 0){
                total = subpack.Price*(taxperc/100)
            }
            taxtotal = Number(total.toFixed(2))
            let PDFFooterObj = {
                Footer_Note: '',
                Sub_Total: subpack.Price,
                Discount_Total: 0,
                Freight_Total: 0,
                Tax_Total: taxtotal,
                Tax_Percentage: taxperc,
                Grand_Total: Number(taxtotal)+Number(subpack.Price),
                TotalFieldProp: 'Invoice_Total',
                TotalFieldValue: subpack.Price
            }
            let note = `<p>This is a Subscripton Order, and the grand total value reflecting on the invoice will be payable on a recurring basis. <br>The subscrition plan you opted for will make this grand total payable on a `
            if(subpack){
                note = note+` `+subpack.PaymentSchedule+` `
            }
            note = note+` basis.</p>`
            if(this.MarketDialogType === 'Group Membership'){
                note = note+`
                <p>This plan is a contract between yourself and `+billingaccount.Account_Name+`, that is only <b>facilitated</b> by `+this.$store.state.PrimaryMonetizeBU.Company_Name+`</p>`
                //we cannot assume group owner or group is vat registered. the only VAT applicable is VAT payable for our "royalty invoice" to the group owner
                PDFFooterObj.Tax_Total = 0
                PDFFooterObj.Grand_Total = Number(subpack.Price)
                //but Invoice_Total is always vat exclusive to record revenue with
            }
            PDFFooterObj.Footer_Note = note
            
            this.$store.commit('setPDFFooterObj',PDFFooterObj)
            this.$store.commit('setPDFDocLineItems',PDFDocLineItems)
            this.$store.commit('EmitMarketDialogObject',this.System)
            this.selectedPackage = subpack
            this.PaymentDialogStep++
      },
      PrepareInvoice(){
        //hear me out this is currenlty rigged for site directory listing, but you know as well as I do everything that geenrates an invoice looks pretty much the same, value changes here and there. 
        //so do NOT remove the invoice generation or even invoice storing functions here. do NOT. 
        //the ONLY funciton I want on child compnents is the "finalize" for instnace "Store package against BA" etc.
        let billingaccount = this.BillingAccount
           billingaccount.Customer_Contact = this.userLoggedIn.Full_Name
           billingaccount.Account_Name = this.System.Name
           
            let subordertypes = this.$store.state.SubscriptionPackageTypes
            let invtype = {ID: 1000002,Name: 'Subscription Order'}
            let type = subordertypes.find(obj => obj.Name === this.MarketDialogType)
           // console.log(type)
            let vm = this
            vm.$store.commit('setCustomProcessingDialog',true)
            let invpayload = {
            Docid: 'DispatchInstructions_Number',
            Data: {Invoice_Type: invtype},
            siteid: process.env.VUE_APP_RA_PROVIDER_ID
            }
            vm.$store.commit('setCustomProcessingDialogText','Creating Invoice Number')
            const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            AssignAutoNumber(invpayload).then(result => {
                let suborderpayload = {
                Docid: 'Subscription_Orders_Order_Number',
                Data: {Type: type},
                siteid: process.env.VUE_APP_RA_PROVIDER_ID
                }
                vm.$store.commit('setCustomProcessingDialogText','Creating Order Number')
            AssignAutoNumber(suborderpayload).then(subordresult => {
                vm.NewSubOrderid = subordresult.data
                //console.log(vm.NewSubOrderid)
                vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
               // console.log(vm.selectedPackage,vm.$store.state.PrimaryMonetizeBU)
                //okay simply put, if this.MarketDialogType, then address details somehwat change
                let PDFHeaderObj = {
                    Company_Name: vm.$store.state.PrimarySystem.Company_Name,
                    Company_Address_Prop: 'PO_Bill_To_Address_',
                    Billing_Address_Prop: 'SO_Delivery_Address_',
                    Primary_Field_Name: 'Invoice_Number',
                    Date: new Date(),        
                    Customer_ID: billingaccount.Customer_ID,
                    Business_Unit: vm.$store.state.PrimaryMonetizeBU,
                    Business_Unitid: vm.$store.state.PrimaryMonetizeBU.id,
                    Reference_Number: billingaccount.id,
                    BillingAccount: {Client_Reference: billingaccount.Client_Reference,Customer_ID: billingaccount.Customer_ID,id: billingaccount.id},
                    BillingAccountid: billingaccount.id,
                    Client: {
                        id: vm.userLoggedIn.id,
                        Full_Name: vm.userLoggedIn.Full_Name,
                        Name: vm.userLoggedIn.Name,
                        Surname: vm.userLoggedIn.Surname
                    },
                    Customer_Company: billingaccount.Account_Name,
                    Customer_Contact: billingaccount.Customer_Contact,
                    Clientid: vm.userLoggedIn.id,
                    Recordid: result.data,
                    Delivery: false,
                    Shipping_Method: {ID: 1000001,Name: 'FOB'},
                    Due_Date: new Date(),
                }
                
                    vm.Company_Address.FieldBreakdown.map(brd => {
                    let newname = brd.Name.split('Company_Address_').join(PDFHeaderObj.Company_Address_Prop)
                    let brdname = brd.Name
                    if(vm.$store.state.PrimarySystem[brdname]){
                        PDFHeaderObj[brdname] = vm.$store.state.PrimarySystem[brdname]
                    }
                    })
                    vm.Billing_Address.FieldBreakdown.map(brd => {
                    let newname = brd.Name
                    let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
                    if(billingaccount[brdname]){
                        PDFHeaderObj[newname] = billingaccount[brdname]
                    }
                    })
                    let PDFFooterObj = vm.$store.state.PDFFooterObj
                    PDFHeaderObj.Payment_Terms = ''
                    if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'COD'){
                        PDFHeaderObj.Payment_Terms = 'COD'
                        PDFHeaderObj.Payment_Period = 0
                    }
                    else if(billingaccount.Billing_Type && billingaccount.Billing_Type.Name === 'Credit'){          
                        PDFHeaderObj.Payment_Period = billingaccount.Days_for_Payment
                        PDFHeaderObj.Payment_Terms = 'Net '+billingaccount.Days_for_Payment
                    }
                    vm.$store.commit('setPDFHeaderObj',PDFHeaderObj)
                    vm.$store.commit('setPDFFooterObj',PDFFooterObj)
                    vm.$store.commit('setPDFFunctionName','Sales_Invoices')
                    vm.$store.commit('setPDFDownloadOnly',false)
                    vm.$store.commit('EmitMarketDialogInteractMethod','ProcessPaymentInvoice')
                })
            })
      },
      
      ProcessPaymentInvoice(){
        let vm = this
        let doc = this.$store.state.PDFExportDoc
        vm.$store.commit('setCustomProcessingDialogText','Parsing PDF')
        //console.log(this.PDFDataRecord)
        let NewPOObject = this.PDFDataRecord
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        let storagelocation = 'Sales_Invoices/'+NewPOObject.BillingAccountid+'/'+NewPOObject.Invoice_Number
        var storageRef = firebase.storage().ref(storagelocation);
        var uploadTask = storageRef.put(blobpdf);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {     
              let PDF_File = {
                StorageRef: storagelocation,
                Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Modified_On: new Date(),
                Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Created_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                fileurl: url,
                FileType: 'application/pdf',
                ModifiedDate: new Date(),
                Name: NewPOObject.Invoice_Number,
                filename: NewPOObject.Invoice_Number
              }
              //console.log(PDF_File)
              if(vm.MarketDialogType === 'Site Directory Listing' || vm.MarketDialogType === 'Plugin Purchase'){
                // let paydialpayload = {
                //     PDF_File: PDF_File,
                //     subpack: vm.selectedPackage
                //     } 
                // vm.$store.commit('SetSocialItemInteractMethodProp',paydialpayload)
                // vm.$store.commit('SetSocialItemInteractMethod','TogglePaymentDialog')
                vm.ProcessSiteSubscriptionInvoice(PDF_File,NewPOObject)
              }
              
            })
      },
      ProcessSiteSubscriptionInvoice(PDF_File,POObject){
        let vm = this
        let paydialpayload = {
            PDF_File: PDF_File,
            subpack: vm.selectedPackage
            } 
        vm.$store.commit('SetSocialItemInteractMethodProp',paydialpayload)
        vm.$store.commit('SetSocialItemInteractMethod','TogglePaymentDialog')
        let invtype = {ID: 1000002,Name: 'Subscription Order'}
        let subordertypes = vm.$store.state.SubscriptionPackageTypes
        let subordertype = subordertypes.find(obj => obj.Name === vm.MarketDialogType)
        vm.$store.commit('setCustomProcessingDialogText','Processing Order')
        vm.$store.commit('setCustomProcessingDialog',true)
        let NewPOObject = Object.assign({},POObject)
        NewPOObject.Recurring = true
        NewPOObject.PaymentSchedule = vm.selectedPackage.PaymentSchedule
        NewPOObject.Invoice_Type = invtype
        NewPOObject.Subscription_Type = subordertype
        NewPOObject.IsFinal = false
        NewPOObject.Progress = {ID: 1000002, Name: 'Invoiced'}
        NewPOObject.Latest_PDF_File = PDF_File
        NewPOObject.Invoice_Date = new Date(),NewPOObject.Site = {
          Name: vm.System.Name,
          id: vm.$store.state.ActiveSuiteid
      }
      NewPOObject.Line_Items = vm.ComputedDocLineItems
      NewPOObject.Siteid = vm.$store.state.ActiveSuiteid
      NewPOObject.Subscription_Package = {
        id: vm.selectedPackage.id,Name: vm.selectedPackage.Name,Price: vm.selectedPackage.Price,Date: new Date(),Client: NewPOObject.Client,Clientid: NewPOObject.Clientid,
        Order_Number: vm.NewSubOrderid,User: NewPOObject.Client,Userid: NewPOObject.Clientid,BillingAccount: NewPOObject.BillingAccount,BillingAccountid: NewPOObject.BillingAccountid,
        Progress: NewPOObject.Progress, Type: vm.selectedPackage.Type, Document_Type: NewPOObject.Invoice_Type,Site: NewPOObject.Site,Siteid: NewPOObject.Siteid,
        Total_Invoiced: NewPOObject.Grand_Total,Total_Paid: 0,Active: true}
      if(this.MarketDialogType === 'Site Directory Listing'){
        NewPOObject.Subscription_Package.DirectoryPages = vm.selectedPackage.DirectoryPages
      }
      NewPOObject.Subscription_Packageid = vm.selectedPackage.id
      NewPOObject.LookupObj = {id: vm.NewSubOrderid,list: 'Subscription_Orders',Order_Number: vm.NewSubOrderid,single: 'Subscription Order',identifier: 'Order_Number'}
      if(NewPOObject.Progress && NewPOObject.Progress.Name === 'Paid'){
        NewPOObject.Subscription_Package.Total_Paid = NewPOObject.Grand_Total
      }
      let PrimaryString = NewPOObject.Invoice_Number
          var lowertext = "";                
          var p;
          let buildup = ''
          console.log(PrimaryString.length,PrimaryString)
          for (p = 0; p < PrimaryString.length; p++) {
              buildup = buildup+ PrimaryString[p]
              if(p === PrimaryString.length-1){
                  lowertext += buildup.toLowerCase();
              }
              else{
                  lowertext += buildup.toLowerCase() + ",";
              }
          } 
          NewPOObject.SearchQuery = []
          NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(NewPOObject.Invoice_Number.trim().split(' '))         
          NewPOObject.SearchQuery = NewPOObject.SearchQuery.concat(lowertext.split(','))  
          NewPOObject.SearchQuery = NewPOObject.SearchQuery.map(entry => {
              return entry.toLowerCase()
          })
        NewPOObject.Due_Date = {Type: 'Date',Date: NewPOObject.Due_Date}
        NewPOObject.Invoice_Date = {Type: 'Date',Date: NewPOObject.Invoice_Date}
      let findocpayload = {
        Collection: 'Sales_Invoices',
        ParentObj: NewPOObject,
        IDProp: 'Invoice_Number',
        siteid: process.env.VUE_APP_RA_PROVIDER_ID
        }
        //console.log(findocpayload)
        this.CheckObjectforUndefined(1,findocpayload,'SubscriptionOrder')
        const functions = firebase.functions();
        const CreateFinancialDocument = functions.httpsCallable('CreateFinancialDocument');
        CreateFinancialDocument(findocpayload).then(result => {
           if(vm.MarketDialogType === 'Site Directory Listing'){           
            vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
            vm.$store.commit('SetSocialItemInteractMethodProp',NewPOObject)
            vm.$store.commit('SetSocialItemInteractMethod','ProcessSiteSubscriptionInvoice')
           }
           else if(vm.MarketDialogType === 'Plugin Purchase'){
            vm.$store.commit('SetSocialItemInteractMethodProp',{Plugins: [vm.selectedPackage]})                
            vm.$store.commit('SetSocialItemInteractMethod','PurchasePlugin')
           }
            setTimeout(() => {
               vm.ClosePaymentDialog()
            }, 500);
        })
    },
    CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
      PRQuantityRules(item){
        return this.$route.meta && this.$route.meta.id !== 'GoodsReceiving' ? [ 
              v => !!v || "This field is required",
              v => ( v && v >= item.Min_Qty ) || "Qty should be no less "+item.Min_Qty,
          ] : [ 
              v => !!v || "This field is required",
              v => ( v && v <= item.Outstanding_Qty ) || "Qty should be no less "+item.Outstanding_Qty,
          ]
      },
      CurrencyFormatter(value,currency,smalldec){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return smalldec ? this.SmallDecimals(formalformat) : formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        SmallDecimals(value){
          let price = value;
          let split = price.split(',')
          let final  = ''
          split.map((spl, i) => {
            if(i-1+2 < split.length){
              final = final+spl
            }
            else{
              final = final+`<span style="font-size:0.5em;">.`+spl+`</span>`
            }
          })
         return final
         //replace(/(\D*)(\d*\.)(\d*)/,'<span style="font-size:16px;">$1</span><span style="font-size:22px;">$2</span><span style="font-size:14px;">$3</span>')
        },
      ClosePaymentDialog(){
        let paydialpayload = {
          bool: false,
          PaymentShowsPackages: false,
          } 
          // setPDFFunctionName
          // setExportingtoPDFDialog
        //console.log('paydialpayload',paydialpayload)
        this.$store.commit('setPDFFunctionName','')
        this.$store.commit('setExportingtoPDFDialog',false)
        this.$store.commit('SetSocialItemInteractMethodProp',paydialpayload)
        this.$store.commit('SetSocialItemInteractMethod','TogglePaymentDialog')
        this.$store.commit('setPDFExportDoc','')
        this.$store.commit('setOrderConfirmationPDF','')
        this.$store.commit('setPDFDataRecord','')        
        this.$store.commit('EmitMarketDialogType','')
        this.$store.commit('setCustomProcessingDialogText','Done')
        this.$store.commit('setCustomProcessingDialog',false)
        this.$store.commit('setOrderConfirmationDialog',false)
        this.$store.commit('setPDFFooterObj','')
        this.$store.commit('setPDFDocLineItems',[])
        this.$store.commit('EmitMarketDialogObject','')
        this.$store.commit('EmitMarketDialogOptions',[])
        this.$store.commit('EmitMarketDialogDescription','')
        this.$store.commit('EmitMarketDialogTitle','')        
        this.$store.commit('EmitMarketDialogAvatar','')
        this.$store.commit('EmitMarketDialog',false)
        this.$store.commit('setSelectedBA','')
        this.$store.commit('setCanToggleBA',true)
        this.selectedPackage = ''
        this.NewSubOrderid = ''
        this.$emit('ClosePaymentDialog')
      },
    }
}
</script>

<style>

</style>



