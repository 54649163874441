
<template>
  <div>
    <v-dialog persistent v-model="dialog"	max-width="400"		
            :close-on-content-click="false"                            									
            transition="scale-transition"                            									
            >		
            <v-card v-if="ActiveMarketTemplate" tile>
              <v-card-title class="black white--text">
                  New Sample Group
              </v-card-title>
              <v-card-text>
                <v-form  ref="form">
                  
                   <v-textarea :rules="[$store.state.formrules.required]" v-model="editedItem.caption" label="caption"></v-textarea>
                     <v-img class="black" height="200" contain v-if="!SampleCoverIMG && editedItem.coverimageThumbURL"									
                        :src="editedItem.coverimageThumbURL"									
                        >
                        <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                     
                            <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleNoticeCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </v-row>
                  </v-img>	
                   <v-img height="200" contain v-if="!editedItem.coverimageThumbURL"									
                    src="@/assets/ImageHolder.png"									
                   >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <v-chip style="cursor: pointer;"  @click="ActivateGalleryDialog('SampleNoticeCoverIMG')" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                    </v-row>
               </v-img>	
                </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-btn dark @click="Closedialog()" outlined color="warning">Cancel</v-btn>
              <v-spacer>
              </v-spacer>
              <v-btn @click="save()" dark outlined color="success">Save</v-btn>
              </v-card-actions>
            </v-card>  	
     </v-dialog>
    <v-btn v-if="ActiveMarketTemplate && Editor && userLoggedIn && userLoggedIn.id === ActiveMarketTemplate.Ownerid" @click="Activatedialog()" outlined>Add (Sample Only)</v-btn>
  
      <v-row 
        class="justify-center"
        align="center"
      >
      
      <v-spacer></v-spacer><v-icon v-if="userisNoticeModerator || userIsAdmin" class="actionicon" @click="NewNotice()">mdi-plus-thick</v-icon>
      <v-carousel v-model="model"  v-if="filterednotices.length>0 && !SampleOnly" dark cycle>
         <v-carousel-item 
            v-for="notice in filterednotices.slice(0,Excerpt)"
          :key="notice.id"
                      >
                      <div>
                    <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain :class="$vuetify.theme.dark ? 'background darken-1' : 'background darken-1'"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
          <v-list-item @click="ActiveMarketTemplate ? ToggleEdit(notice,'AboutDigitalNoticeboard') : ''" :to="ActiveMarketTemplate ? '' : '/NoticeSingle/'+notice.id" class="mx-1 noticeoutlined" >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
                      </div>
         </v-carousel-item>
      </v-carousel> 
       <v-carousel class="background" v-model="model"  v-if="SampleOnly" dark cycle>
         <v-carousel-item 
                      >
                      <div>
                    <v-list-item class="justify-center"
          >
          <v-img v-if="!samplenotice.ImageShy"
          height="300"
          contain :class="$vuetify.theme.dark ? 'background darken-1' : 'background darken-1'"
           v-bind:src="samplenotice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
          <v-list-item class="mx-1 noticeoutlined" >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{samplenotice.monthcreated}}
                    <br>
                  {{samplenotice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="samplenotice.IconColor">{{samplenotice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{samplenotice.createddate}}</span><span class="purple--text"> {{samplenotice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{samplenotice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="samplenotice.MustConfirm && !samplenotice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!samplenotice.UserRead && samplenotice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="samplenotice.UserRead">
                Read {{samplenotice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="samplenotice.MustConfirm && samplenotice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{samplenotice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
                      </div>
         </v-carousel-item>
      </v-carousel> 
      </v-row>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import NoticeSingle from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeSingle'


export default {
    props: ['clientDB','Description','Excerpt','Filter','HasFilter','AppisDarkModeCard','AppisDarkMode','UserView','UserID','SampleOnly','Editor'],
    components: {
        NoticeSingle,
  },
    middleware: 'auth',
    data () {
        return {
          model: 0,
            Notice: '',
            NoticeDialog: false,
          
        CollectionRef: db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').where('PublishStatus','==','Published (Internal)'),
          tempid: 'new',
          //userid: this.$store.state.user.id,
        GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            notices: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                coverimageThumbURL: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                coverimageThumbURL: null,
                                
            },
            UserRecord: {},           

        }
    },
    computed: {      
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ConfigPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      samplenotice(){
        let dateobj = new Date()
        let not = {}
        not = {
          coverimageThumbURL: require('@/assets/GallerySample2.jpg'),
          Category: {Name: 'General',Icon: 'mdi-eye'},
          Icon: 'mdi-eye',
          caption: 'Tea break has been cancelled until futher notice',
          monthcreated: this.TimestampFormatterNoticeBoard(dateobj,'month'),
          daycreated: this.TimestampFormatterNoticeBoard(dateobj,'date'),
          createddate: this.TimestampFormatterNoticeBoard(dateobj,'full'),
          createdon: this.TimestampFormatterNoticeBoard(dateobj,'full'), 
        }
        return not
      },
        userLoggedIn () {
      return this.$store.getters.user
    },
    userisNoticeModerator(){
      if(this.UserRecord && this.UserRecord.ModerationPermissions){
            let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Notices')
            if(match){
              return true
            }
          }
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    filterednotices() {
      
      return this.notices.filter(notice => {
          if(this.HasFilter){
          return this.Filter.includes(notice.type)
        }
          else{
            return notice
          }
      }).map(not => {
        if(typeof not.createdon.toDate !== 'undefined'){
        not.monthcreated = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'month')
        not.daycreated = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'date')        
        not.createddate = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'full')
        if(not.UserReadDate){
            not.UserReadDate = this.TimestampFormatterNoticeBoard(not.UserReadDate.toDate(),'full')
        }
        not.createdon = this.TimestampFormatterSTRING(not.createdon.toDate())
        }
        if(not.MustRead && not.MustConfirm && !not.UserRead || not.MustConfirm && !not.UserConfirmed){
          not.IconColor = 'red'
          not.Icon = 'mdi-clipboard-alert'
        }
        else if(not.MustRead &&!not.MustConfirm && !not.UserRead){
          not.IconColor = 'orange'
          not.Icon = 'mdi-clipboard-alert-outline'
        }
        else if(!not.MustRead &&!not.MustConfirm && !not.UserRead){
          not.IconColor = 'blue'
          not.Icon = 'mdi-clipboard-outline'
        }
        else{
          not.IconColor = 'green'
          not.Icon = 'mdi-clipboard-check-outline'
        }
        return not
      }).sort((a, b) => {
            var key1 = b.createdon;
            var key2 = a.createdon;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    
    },
    // FilterComputed(){
    //     return this.Filter.map(filter => {
    //         return filter.Name
    //     })
    // }
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['AssignSampleNoticeCoverIMG']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created() {
      if(this.$store.state.TemplateView){
        this.GetSamples(this.ConfigPath.collection('samplenotices'),'notices')
      }
      else{
        // this.$store.dispatch('GetUserStateChange')
      if(this.userLoggedIn){
          this.UserRecord = this.userLoggedIn
      }
      //console.log(this.Filter)
      if(this.UserView){
        this.CollectionRef = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').where('creatorid','==',this.UserID)
        this.GetNotices()
      }
      else{
        this.GetNotices()
      } 
      }
      
    
    // this.FocusedViewToggle()
    //   this.IntranetViewToggle()
    // //   this.GetRequestingUser()
},
    methods: {
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
      GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      Activatedialog(){
        this.dialog = true
      },
      OpenEditdialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
      ToggleEdit(item,dialprop){
      if(this.ActiveMarketTemplate && this.Editor && this.userLoggedIn && this.userLoggedIn.id === this.ActiveMarketTemplate.Ownerid){
        this.editedItem = Object.assign({},item)
        this.OpenEditdialog()
      }
      else if(this.ActiveMarketTemplate && !this.Editor){
        //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
        this.OpenAboutDialog(dialprop)
      }
      
    } ,  
      OpenAboutDialog(dialprop){      
      this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
      this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
    },
      NewNotice(){
        let ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc()
        let newdocid = ref.id
        let routes = this.$router.getRoutes()
        console.log('routes',routes.find(obj => obj.name === 'noticenew'))
        console.log(this.$store.state.PluginDataBase)
        // this.$router.push({ name: 'noticenew', params: {id: newdocid }})
        this.$router.push('/NoticeNew/'+newdocid)
      },
        SubmitResponse(){
            alert('wow you responded')
        },
        OpenNotice(notice){
            this.$router.push({ name: 'noticesingle', params: {Notice: notice }})
        },
        ViewNotice(notice){
            this.Notice = notice
            this.NoticeDialog = true
        },
        TimestampFormatterSTRING(dateobj){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = dateobj.getHours();
       
        var min = dateobj.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = dateobj.getDate();
        var month = months[dateobj.getMonth()];
        var year = dateobj.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(dateobj,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
      TimestampFormatterNoticeBoard(dateobj,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = dateobj.getHours();
       
        var min = dateobj.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = dateobj.getDate();
        var month = months[dateobj.getMonth()];
        var year = dateobj.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(dateobj,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
      GetRequestingUser(){
      this.UserRecord = this.userLoggedIn
              this.UserRecord.id = this.userLoggedIn.id
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
         GetNotices() {
           if(this.$store.state.UserisGuest){
             this.CollectionRef = this.CollectionRef.where('GuestsIncluded','==',true)
           }
           this.CollectionRef.onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
            let noticeobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                let responsedocdata = responddoc.data()
                if(responsedocdata && responsedocdata.Confirmed){
                    noticeobj.UserConfirmed = true
                }
                if(responsedocdata && responsedocdata.Read){
                    noticeobj.UserRead = true
                    noticeobj.UserReadDate = responsedocdata.ReadDate
                }
                if(responsedocdata && responsedocdata.ConfirmDate){
                    noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                }
                this.notices.push(noticeobj)
            })
            
          
        }  
      })
    })

    },
    AssignSampleNoticeCoverIMG(){
        this.editedItem.coverimageThumbURL = this.SelectedImage.fileurl
        this.$store.commit('setIMGProp','')
      },
    Opendialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
            let notice = {
              Category: {
                Name: 'General',
                Icon: 'mdi-clipboard-alert'
              },
              MustRead: true,
              MustConfirm: true,
              caption: this.editedItem.caption,
              coverimageThumbURL: this.editedItem.coverimageThumbURL
            }
            if(!this.editedItem.createdon){
              notice.createdon = new Date()
            }
            if(this.editedItem.id){
              this.ConfigPath.collection('samplenotices').doc(this.editedItem.id).set(group).then(function(doc) {
                  })
            }
            else{
              notice.createdon = new Date()
              this.ConfigPath.collection('samplenotices').add(notice).then(function(doc) {
                  })
            }
            this.Closedialog()
          }
      }
      },


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.noticeoutlined{
   border-style: solid;
    border-color: rgb(207, 205, 205);
   border-width: thin;
}
.outlinedavatar{
  border:1px double red;

  background-color: #2196F3;
}
</style>
    


    