
<template>
<v-main style="margin-top:60px;margin-bottom:-150px">
    <!-- <v-card class="grey lighten-4 stickytopconscious" width="100%" height="100%" flat> -->
    <!-- <v-card class="transparent"> -->
      <!-- <div  class="my-10"> -->
     <v-card min-width="100%" tile flat class="background">
                <v-card-title class="recordtoolbar display-2 white--text" style="text-transform: uppercase">
                    Banner Ad Builder
                </v-card-title>
                <v-card-title class="recordtoolbar white--text" style="text-transform: uppercase">
                    Step {{step}} - {{step === 2 ? 'Design' : 'Setup'}}
                    <v-icon large color="white">
                      {{step === 2 ? 'mdi-palette' : 'mdi-cogs'}}
                    </v-icon>
                    <v-spacer></v-spacer>
               <v-btn @click="SaveBannerAdFileCheck()">Save</v-btn>
              <v-btn
             
                color="primary"
                depressed
                @click="step++"
              >
                Next
              </v-btn>
                </v-card-title>
        <v-window v-model="step">
              <v-window-item :value="1">
                    <v-card-text>
                        <v-text-field v-model="BannerName" label="Name"/>
                        <v-select dense label="Type" readonly :items="NewBannerTypes" item-text="Name" return-object v-model="BannerType"/>
                         <v-select dense label="Link Type" :items="LinkTypes" item-text="Name" return-object v-model="LinkType">
                           <template v-slot:item="{ item }">
                            <v-list dense class="tranparent">
                               <v-list-item dense>
                                 {{item.Name}}
                               </v-list-item>
                               <v-list-item dense class="caption">
                                 {{item.Description}}
                               </v-list-item>
                            </v-list>
                            </template>
                        </v-select>
                         <v-select dense v-if="LinkType && LinkType.Name === 'Group'" label="Group" :items="ComputedSiteGroups" item-text="Name" return-object v-model="LinkSource"/>
                         <v-select dense v-if="LinkType && LinkType.Name === 'Page'" label="Page" :items="PageRouteComponents" item-text="Name" return-object v-model="LinkSource"/>
                         <v-text-field dense v-if="LinkType && LinkType.Name === 'Route'" label="Item Path" v-model="LinkSource"/>
                        
                        <v-select v-if="LinkType && LinkType.Name === 'Navlist'" label="Page" :items="PreparedDirectories" item-text="Page" return-object v-model="Navlist"/>
                        <v-btn @click="AddRouteQuery()" v-if="SelectedNavlistQueryFields && SelectedNavlistQueryFields.length > 0">Add Query</v-btn>
                        <v-list class="transparent" v-if="SelectedNavlistQueryFields && SelectedNavlistQueryFields.length > 0">
                          <v-list-item dense v-for="(routequery,routequeryindex) in RouteQueries" :key="routequery.itemObjKey">
                            <v-list-item-content>
                               <v-select :items="SelectedNavlistQueryFields" item-text="Prop" v-model="routequery.Prop"/>
                            </v-list-item-content>
                            <v-list-item-content>
                               <v-text-field label="Value" v-model="routequery.Value"/>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon @click="RemoveRouteQueryroutequery(routequeryindex)">mdi-delete-forever</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <!-- <v-select v-if="LinkType && LinkType.Name === 'Navlist' && Navlist && Navlist.id" label="Page" :items="PreparedDirectories" item-text="Page" return-object v-model="LinkSource"/> -->
                         <v-select v-if="LinkType && LinkType.Name === 'Landing Page'" label="Page" :items="LandingPages" item-text="Name" return-object v-model="LinkSource"/>
                         
                         <!-- <v-select item-text="Name" return-object v-model="Link"/> -->
                         <v-switch label="Featered on Specific Records" v-model="RecordSpecific" />
                         <v-autocomplete dense v-if="!RecordSpecific" label="Where to Feature Banner?  (select Multiple)" :items="SiteRouteComponents" item-text="name" return-object multiple v-model="BannerComponents"/>
                         <v-autocomplete dense v-if="RecordSpecific" label="Record Types" :items="RecordSpecificTypes" item-text="Name" return-object v-model="FeatureType"/>
                         <v-autocomplete dense v-if="RecordSpecific && FeatureType && FeatureType.Name === 'Wiki'" label="Feature" :items="WikiRouteComponents" item-text="name" return-object multiple v-model="BannerComponents"/>
                         <v-text-field dense v-if="RecordSpecific && FeatureType && FeatureType.Name === 'Wiki' && BannerComponents"  v-model="RecordName" label="Record Name"/>
                         <input type="file"  @change="BannerImageselected($event)"/>
                        
                        <v-select dense label="Banner Image" item-text="Name" :items="SystemAssets" v-model="BannerImage" return-object>
                          <template v-slot:item="{ item }">
                            <span>
                               <v-list-item dense>
                                 <v-list-item-avatar tile size="50">
                                   <img :src="item.ThumbURL" style="object-fit: cover;" />
                                 </v-list-item-avatar>
                                 <v-list-item-content>
                                   {{item.Name}}
                                 </v-list-item-content>
                               </v-list-item>
                            </span>
                            </template>
                            </v-select>
                        <v-menu      
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="400px"
                                >
                                <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-image-edit-outline</v-icon>
                                </template>
                                <v-card>
                                 <v-row>
                                            <v-col
                                             v-for="asset in SystemAssets" :key="asset.itemObjKey"
                                            class="d-flex child-flex"
                                            cols="4"
                                            >
                                            <v-img @click="AssignBannerImage(asset)" height="100" contain :src="asset.Path"/>
                                            </v-col>
                                        </v-row>
                                </v-card>
                            </v-menu>
                            <v-menu
                        v-model="ExpiryDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        id="due_date">
                        <template v-slot:activator="{ on }">
                          <v-text-field  dense                          
                            v-model="ExpiryDate"
                            label="Expiry Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="due_date"
                          ></v-text-field>
                        </template>
                        <v-date-picker dense v-model="ExpiryDate" @input="ExpiryDatemenu = false"></v-date-picker>
                      </v-menu>
                        <v-select dense label="Publish Type" :items="ComputedPublishTypes" v-model="PublishType"/>
                        
                    </v-card-text>
              </v-window-item>
              <v-window-item :value="2">
                <v-carousel v-model="model" height="400" v-if="BannerType && BannerType.Name === 'TopBar (HTML)'">
                      <v-carousel-item
                      >
                      <v-img
                            :src="RelevantImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-btn @click="BannerPreview = !BannerPreview">Preview</v-btn>
                            <v-card dark class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline white--text mx-3" v-if="BannerPreview">
                                {{BannerHeader}}                                
                                </v-card-title>
                                <v-card-title v-if="!BannerPreview">
                                    <v-text-field label="Header" v-model="BannerHeader"/>
                                </v-card-title>
                              
                               <v-card-text v-if="BannerPreview" style="font-size: 10px" class="white--text overline mx-3">
                                {{BannerDescription}}
                               </v-card-text>
                               <v-card-text  v-if="!BannerPreview">
                                <v-textarea v-model="BannerDescription" label="Description" />
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="BannerLink">{{ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
              </v-window-item>
        </v-window>
        <v-card-actions>
            </v-card-actions>
     </v-card>
          </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['System','SystemEntities','Directories'],
    components: {
        },
    data() {
    return {  
      step: 1,
      LinkSource: '',
        BannerType: {
                ID: 1000003,
                Name: 'TopBar (HTML)'
            },
        ExpiryDatemenu: false, 
        ExpiryDate: '',
        AssetName: '',
        SystemAssets: [],
        SampleBannerImagefile: '',
        BannerImageFile: '',
        RecordSpecificTypes: [
            {
            ID: 1000001,
            Name: 'Wiki'
            }
        ],
        PublishType: '',
        PublishTypes: [
            'Public',
            'Members Only'
        ],
        RecordName: '',
        FeatureType: '',
        RecordSpecific: false,
        // SiteRouteComponents: [],
        BannerComponents: [],   
        BannerImage: '',
        ActionButtonName: 'Find out More',
        BannerLink: '',
        BannerPreview: true,
        BannerHeader: '',
        LinkType: '',
        Navlist: '',
        RouteQueries: [],
        BannerName: '',
        BannerDescription: '',
        NewBannerTypes: [
            {
                ID: 1000001,
                Name: 'Sidebar'
            },
            {
                ID: 1000002,
                Name: 'Topbar (GIF)'
            },
            {
                ID: 1000003,
                Name: 'TopBar (HTML)'
            },
        ],
        LandingPages: [],
        SiteGroups: [],
        LinkTypes: [
            {
                ID: 1000001,
                Name: 'Page',
                Description: `The banner action button will take vewing users to a Page.`
            },
            {
                ID: 1000007,
                Name: 'Route',
                Description: `The banner action button will take vewing users to a Route/ Path. Typically if you want users to to a path such as "My Account", or "My Profile" etc.`
            },
            {
                ID: 1000008,
                Name: 'Navlist',
                Description: `The banner action button will take vewing users to a bavigational list, like a Page that contains "listings". Typically "Store" page, or "Classified Listins" page.
                This option has additional options, whereby you can se "Route Queries", meaninng the action button would then add filters to the related page. `
            },
            {
                ID: 1000002,
                Name: 'Group',
                Description: `The banner action button will take vewing users to a Group in the Social Module. `
            },
            {
                ID: 1000003,
                Name: 'Article',
                Description: `The banner action button will take vewing users to an Article.`
            },
            {
                ID: 1000004,
                Name: 'Wiki',
                Description: `The banner action button will take vewing users to a Wiki page.`
            },
            {
                ID: 1000005,
                Name: 'Landing Page',
                Description: `The banner action button will take viewing users to a created Landing Page.`
            },
            {
                ID: 1000006,
                Name: 'External',
                Description: `The banner action button will take viewing users to an external link.`
            },
        ],
        
        UserRecord: '',
        rules: {
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            AssetName: ''
            

            }
    }, 

    computed:{
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      userModerationPermissions(){
        return this.userLoggedIn.ModerationPermissions ? this.userLoggedIn.ModerationPermissions :[]
      },
      ComputedPublishTypes(){
        let publishpash = this.userModerationPermissions.find(obj => obj === 'Public Banner Ads') || this.userIsAdmin
        let internalpass = this.userModerationPermissions.find(obj => obj === 'Banner Ads') || this.userIsAdmin
        return this.PublishTypes.filter(type => {
          if(type === 'Public'){
            return publishpash
          }
          else{
            return internalpass
          }
        })
      },
      SelectedNavlist(){
        if(this.Navlist && this.Navlist.id && this.LinkType && this.LinkType.Name === 'Navlist'){
          return this.Directories.find(obj => obj.id === this.Navlist.id)
        }
      },
      SelectedNavlistFilters(){
        console.log(this.SelectedNavlist)
        return this.SelectedNavlist ? this.SelectedNavlist.NavList : []
      },
      SelectedNavlistFilterFields(){
        let fields = []
        this.SelectedNavlistFilters.map(filter => {
          
          if(filter.Type === 'Single Option Filter' && filter.Field){
            fields.push(filter.Field.id)
          }
          else if(filter.Type === 'TreeView'){
            fields.push(filter.NavTreeField)
          }
          else if(filter.Type === 'Filter List'){
            filter.Filters.map(subfilt => {
              fields.push(subfilt.id)
            })
          }
        })
        return fields
      },
      SelectedNavlistEntity(){
        return this.SelectedNavlist ? this.SystemEntities.find(obj => obj.id === this.SelectedNavlist.id) : ''
      },
      SelectedNavlistEntityFields(){
        return this.SelectedNavlistEntity ? this.SelectedNavlistEntity.AllFields : []
      },
      SelectedNavlistQueryFields(){
        let querytypefields = this.SelectedNavlistEntityFields.filter(field => {return field.Type === 'Lookup' || field.Type === 'Option Set'})
        console.log(querytypefields,this.SelectedNavlistFilterFields,this.SelectedNavlist)
        return querytypefields.filter(field => {          
          return this.SelectedNavlistFilterFields.find(obj => obj === field.id)
        }).map(field => {
          //okay one more logic here though - Product Group highest level, but we could also return Product Class and Product Type...so yeah
          //Option set is going to actually allow the return of objects...so...should we not then also go back and allow lookups by calling them?
          //I want to KISS for now, just let them type the crap out. 
          let fieldobj = {Prop: field.id,Value: '',Type: field.Type}
          return fieldobj
        })
      },
      PreparedDirectories(){
        console.log(this.Directories)
        return this.Directories.map(navlist => {
          let navlistobj = {
            id: navlist.id,
            Page: navlist.ActivePageid 
          }
          return navlistobj
        })
        .filter(navlistobj => {
          return typeof navlistobj.Page !== 'undefined'
        })
      },
      ComputedSiteGroups(){
        return this.SiteGroups.map(group => {
          group.Name = group.name
          return group
        })
      },
        ExpiryDateObject(){
                return this.DateFormatter(this.ExpiryDate)
            }, 
       BannerImageSimple(){
            if(this.BannerImage && this.BannerImage.Path){
                return this.BannerImage.Path
            }
            else if(this.BannerImage){
                return this.BannerImage
            }
        },
        RelevantImage(){
            if(!this.SampleBannerImagefile && this.BannerImageSimple){
                return this.BannerImageSimple
            }
            else{
                return this.SampleBannerImagefile
            }
        },
        SiteRouteComponents(){
          return this.$router.getRoutes().filter(route => {
            return this.PublishType === 'Public' ? route.meta && route.meta.RouteEventID === 1028 :  route.meta && route.meta.RouteEventID === 1027
          })
        },
        WikiRouteComponents(){
            return this.SiteRouteComponents.filter(comp => {
                return typeof comp.meta !== 'undefined' && comp.meta.type === 'Wiki Pages'
            })
        },
        PageRouteComponents(){
            return this.SiteRouteComponents.filter(comp => {
                return typeof comp.meta !== 'undefined' && comp.meta.type === 'Pages'
            }).map(comp => {
                comp.Name = comp.meta.subcat
                return comp
            })
            // .filter(page => {
            //   return page.PublishType === this.PublishType
            // })
        },
      SimpleBannerComponents(){
          return this.BannerComponents.map(comp => {
              let obj = {
                  Name: comp.name,
                  path: comp.path,
                  meta: comp.meta
              }
              return obj
          })
      },
      SimpleBannerComponentsNames(){
          return this.BannerComponents.map(comp => {
              let obj = {
                  Name: comp.name,
                  path: comp.path,
                  meta: comp.meta
              }
              return obj.Name
          })
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()
      

    },
    
    methods: {
      RemoveRouteQueryroutequery(index){
        this.RouteQueries.splice(index,1)
      },
      AddRouteQuery(){
        let newquery ={
          Prop: '',
          Value: ''
        }
        this.RouteQueries.push(newquery)
      },
        AssignBannerImage(asset){
            this.BannerImage = asset
        },
        BannerImageselected(){
            
            var value = prompt("Please choose a name for future refence to this asset", "");
                if (value != null) {
                this.AssetName = value
                this.BannerImageFile = event.target.files[0]
            this.SampleBannerImagefile = URL.createObjectURL(this.BannerImageFile)
                }
            
        },
        SaveBannerAdFileCheck(){
          let vm = this
            if(this.BannerImageFile){
                this.PrepareThumbnail(this.BannerImageFile).then(resultobj => {
                  let thumburl = resultobj.thumb
                  let ref = resultobj.ref
                  let file = this.BannerImageFile
                  let Assetshorttype = this.BannerImageFile.type.split('image/').join('')
                  var storageRef = firebase.storage().ref(ref)
                  var uploadTask = storageRef.put(file);
                let vm = this
                        uploadTask
                        .then(snapshot => snapshot.ref.getDownloadURL())
                            .then((url) => {
                          let NewAsset = {
                              FileType: Assetshorttype,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Created_Byid: this.userLoggedIn.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.lastModifiedDate,
                              Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Modified_Byid: this.userLoggedIn.id,
                              Modified_On: new Date(),
                              Name: this.AssetName,
                              path: url,
                              Path: url,
                              DataTags: [],
                              DataTagsid: [],
                              StorageRef: ref
                              
                              }
                    
                    //{Name: this.AssetName, Path: thumburl, ShortType: Assetshorttype, FullName: this.AssetName.split(' ').join('_')+'.'+Assetshorttype}
                        // this.SystemAssets.push(NewAsset)
                        db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('PhotoLibraries').doc('System_Assets').collection('Assets').add(NewAsset).then(doc => {
                                console.log('updated asset')
                        })
                    this.BannerImage = NewAsset
                    this.SaveBannerAd()
                          })
                })
            }
            else{
                this.SaveBannerAd()
            }
        },
        PrepareThumbnail(file){
            let vm = this
            return new Promise(function(resolve, reject) {
            let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
             file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            let ref = 'LibraryAssets/Banner_Ads/'+new Date()+'/'+ file.name
            var storageRef = firebase.storage().ref(ref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => { 
                        resolve({thumb: thumburl,ref: ref})
                    })
                })
                
            }
            })
        },
        SaveBannerAd(){
            let BannerAdObj = {
              Expired: false,
                ExpiryDate: this.ExpiryDateObject,
                BannerImage: this.BannerImage,
                ActionButtonName: this.ActionButtonName,
                BannerLink: this.BannerLink,
                BannerHeader: this.BannerHeader,
                LinkType: this.LinkType,
                BannerType: this.BannerType,
                BannerTypeID: this.BannerType.ID,
                BannerName: this.BannerName,
                BannerDescription: this.BannerDescription,
                
                RecordSpecific: this.RecordSpecific,
                PublishType: this.PublishType
            }
            if(this.Navlist){
              BannerAdObj.Navlist = this.Navlist
            }
            if(this.RouteQueries && this.RouteQueries.length > 0){
              BannerAdObj.RouteQueries = this.RouteQueries
            }
            if(this.LinkSource){
              BannerAdObj.LinkSource = this.LinkSource
            }
            console.log(this.LinkSource)
            if(this.FeatureType && this.RecordSpecific){
                BannerAdObj.FeatureType = this.FeatureType
                BannerAdObj.BannerComponents = this.SimpleBannerComponents
                BannerAdObj.BannerComponentsNames = this.SimpleBannerComponentsNames      
                BannerAdObj.RecordName = this.RecordName          
            }
            else{
                BannerAdObj.BannerComponents = this.SimpleBannerComponents
                BannerAdObj.BannerComponentsNames = this.SimpleBannerComponentsNames
            }
            if(this.LinkType.Name === 'Landing Page'){
                BannerAdObj.LinkSource = {
                    id: this.LinkSource.id,
                    Name: this.LinkSource.Name,
                    PageRoute: this.LinkSource.PageRoute
                }
                BannerAdObj.BannerLink = '/LandingPage/'+this.LinkSource.PageRoute
            }
            else if(this.LinkType.Name === 'Group'){
              BannerAdObj.LinkSource = {
                    id: this.LinkSource.id,
                    Name: this.LinkSource.Name
                }
              let groupobj = this.ComputedSiteGroups.find(obj => obj.id === BannerAdObj.LinkSource.id)
              if(groupobj.DefaultPage){
                BannerAdObj.BannerLink = '/Group-Page/'+groupobj.id+'/'+groupobj.DefaultPage.Name.split(' ').join('-')
              }
              else{
                BannerAdObj.BannerLink = '/Group/'+groupobj.id
              }
            }
            else{
              if(this.LinkSource.id){
                BannerAdObj.LinkSource = {
                    id: this.LinkSource.id,
                    Name: this.LinkSource.Name
                }
                BannerAdObj.BannerLink = '/'+this.LinkSource.Name
              }
              else{
                BannerAdObj.BannerLink = this.BannerLink
              }
                
            }
            console.log(BannerAdObj)
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('bannerads').doc(this.$route.params.id).set(BannerAdObj).then(doc => {
                this.$router.push('/BannerAds')
            })
        },
        PrepareNewBannerAd(){
            let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('bannerads').doc()
        let newdocid = ref.id
        this.BannerAdView = true
        this.NewBannerAdID = newdocid
        
        },
        PrepareNewLandingPage(){
            let ref = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('landingpages').doc()
        let newdocid = ref.id
        this.LandingPageView = true
        this.NewLandingePageID = newdocid
        this.$router.push('/BannerAd/New/'+newdocid)
        let LinkedfromObj = {
                        Type: "Password Link",
                        id: this.$route.params.id,
                        Created_On: passwordresetdata.Created_On.toDate(),
                        ExpiryDate: ExpiryDate,
                        CanAction: true,
                        ActionText: 'You can always Request another Password Reset?',
                        ActionLink: '/Login'
                    }
                    console.log(LinkedfromObj)
                    this.$router.push({ name: 'newbannerad', params: {LinkedfromObj: LinkedfromObj }})
        
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
            if(this.userLoggedIn){
               this.UserRecord = this.userLoggedIn
              console.log(this.UserRecord)
              this.GetSiteGroups() 
              //this.GetSiteComponents()
              this.GetLandingPages()
              this.GetBannerAd()
              this.GetSystemAssets()
            }
        },
        GetSystemAssets(){
            db.collection('Libraries').doc(this.$store.state.ActiveSuiteid).collection('PhotoLibraries').doc('System_Assets').collection('Assets').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.SystemAssets.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
        GetBannerAd(){
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('bannerads').doc(this.$route.params.id).onSnapshot(snapshot => {
                let BannerAdObj = snapshot.data()
                if(BannerAdObj){
                    
                this.BannerComponents = BannerAdObj.BannerComponents.map(comp => {
                    if(comp.Name){
                        comp.name = comp.Name
                    }
                    return comp
                })
                console.log(this.BannerComponents)
                this.ExpiryDate = format(BannerAdObj.ExpiryDate.toDate(),'yyyy-MM-dd')
                this.BannerImage = BannerAdObj.BannerImage
                this.ActionButtonName = BannerAdObj.ActionButtonName
                this.BannerLink = BannerAdObj.BannerLink
                this.BannerHeader = BannerAdObj.BannerHeader
                this.LinkType = BannerAdObj.LinkType
                this.BannerType = BannerAdObj.BannerType 
                this.BannerName = BannerAdObj.BannerName
                this.BannerDescription = BannerAdObj.BannerDescription
                this.RecordSpecific = BannerAdObj.RecordSpecific
                this.LinkSource = BannerAdObj.LinkSource
                this.PublishType = BannerAdObj.PublishType
                this.FeatureType = BannerAdObj.FeatureType
                if(BannerAdObj.RecordName){                  
                this.RecordName = BannerAdObj.RecordName          
                }
                if(BannerAdObj.Navlist){
                  this.Navlist = BannerAdObj.Navlist
                }
                if(BannerAdObj.RouteQueries){
                  this.RouteQueries = BannerAdObj.RouteQueries
                }
            }
            

            })
        },
        GetSiteComponents(){
            this.SiteRouteComponents = this.$router.options.routes
        },
        GetLandingPages(){
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('landingpages').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.LandingPages.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
        GetSiteGroups(){
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.SiteGroups.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.imgcontentgrad {
  background-image: linear-gradient(to right, rgba(255,0,0,0.5), rgb(238,255,0, 1));
}
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}

#my-lax .v-img__content {
  background: linear-gradient(135deg,  rgba(231,60,126,0.8), rgb(35,166,213, 0.5));
}
.clip-svg{
  mask-size: contain;
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert?alt=media&token=54d7ec67-2109-4eda-93c0-7bf98f55dbf8);
  mask-repeat: no-repeat;
  mask-position: center;
}
</style>