<template>
<div>
<v-list-item v-if="field.Type === 'Option Set' && !field.IsMultiple" :style="Editing ? '' : ''">
    <v-list-item-content v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.md || !Editing" style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action v-if="!Editing">
      <v-list-item-title style="font-weight: 300;">
        
        <v-avatar size="30" v-if="ComputedValue"
        :color="ComputedValue.Color? ComputedValue.Color : ''" 
        >
        <v-icon small dark>{{ComputedValue.Icon}}
      </v-icon>
        </v-avatar>
        {{FieldValue.Name}}
      </v-list-item-title>
    </v-list-item-action>
    <v-list-item-content v-if="Editing" class="bootstrap-form-input">
      <span v-if="field.Type === 'Option Set' && Editing"><span v-if="!field.IsDynamic">
                    <v-select outlined dense return-object :readonly="field.ReadOnly" :rules="field.IsMandatory? [$store.state.formrules.required]: []" 
                    v-model="Record[field.Name]" :items="field.Options"  :label="field.DisplayName" item-text="Name"></v-select><span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1]">
                    <v-select outlined dense @change="UpdateEditableField(field)" v-if="Record[field.Name]" :readonly="field.ReadOnly" return-object :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Levels[1].FieldName]" :items="Record[field.Name].Options" :label="field.Levels[1].name" item-text="Name"></v-select></span><span v-if="field.Levels && field.Levels[1] && field.Levels[2] && Record[field.Levels[1].FieldName] && Record[field.Levels[1].FieldName].Options">
                    <v-select outlined dense @change="UpdateEditableField(field)" v-if="Record[field.Levels[1].FieldName]" :readonly="field.ReadOnly" return-object :rules="field.IsMandatory? [$store.state.formrules.required] : []" v-model="Record[field.Levels[2].FieldName]" :items="Record[field.Levels[1].FieldName].Options" :label="field.Levels[2].name" item-text="Name"></v-select></span></span><span v-if="field.IsDynamic">
            <FieldConfig @UpdateField="UpdateEditableField(field)" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
            :CurrentEntity="CurrentEntity" :OptionsOnly="true"
            :SystemEntities="SystemEntities" :field="Record[field.Name]" :NewField="false" :System="System"/>
            <!-- <v-btn @click="ActivateOptionSetDialog(Record[field.Name],field)">Options</v-btn> -->
            </span></span>
    </v-list-item-content>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldConfig from '@/components/Database/FieldConfig';
export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {FieldConfig},
    data() {
        return {

        }
    },	
    computed:{
      ComputedValue(){
        let obj = {
          Value: this.FieldValue,
        }
        if(this.field){
          let opt = this.field.Options.find(obj => obj.ID === this.FieldValue.ID)
          if(opt){
            obj.Icon = opt.Icon
            obj.Color = opt.Color && opt.Color.hexa ? opt.Color.hexa : opt.Color
            if(!obj.Icon && this.field.id === 'Status'){
              if(this.FieldValue.Name === 'Active'){
                obj.Icon = this.FieldValue.Name === 'Active' ? 'mdi-power' : 'mdi-power-off'
                obj.Color = this.FieldValue.Name === 'Active' ? 'green' : 'red'
              }
            }
          }
        }
        return this.FieldValue ? obj : {}
      },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



