<template>
  <div>
    <v-snackbar
    
       color="links"
       top
      v-model="invitesnackbar"
      :timeout="4000"
    >
      Invite has been sent to {{InvitingUserEmail}}
    </v-snackbar>
      <SystemBuilderQuickstart :ClientDBBusinessUnits="ClientDBBusinessUnits" :ClientDBAdministrators="ClientDBAdministrators"
  v-if="$route.meta && $route.meta.SystemBuilder" :QuickstartStep="QuickstartStep" :clientDB="clientDB" :clientApp="clientApp"
  :QuickStartDialog="QuickStartDialog" :ClientFireStore="ClientFireStore" :SystemObj="DynamicRecordData"
  :ClientAppAuthDialog="ClientAppAuthDialog" :ViewingSystemID="$route.params.id"
  />

  <!-- ClientAppSignedIn {{ClientAppSignedIn}} -->
  <v-list  >
          <v-list-item two-line subheader v-if="ClientAppSignedIn">
            <v-list-item-content>          
            <v-list-item-title>Signed In</v-list-item-title>
            <v-list-item-subtitle>
              {{ClientappSignedInEmail}}
            </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-icon color="green">mdi-check-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!ClientAppSignedIn"  @click="AuthenticateClientApp()">
            <v-list-item-title class="warning--text fieldmappings">Sign In<p class="tooltiptext">Sign into your App</p></v-list-item-title>
            <v-list-item-action>
            <v-icon color="green">mdi-login</v-icon>
            </v-list-item-action>
          </v-list-item>
           <v-dialog v-model="invitedialog" v-if="ClientappSignedInEmail && SystemObj && SystemObj.ClientAppURL"
            max-width="800"                         
            >
            <template v-slot:activator="{ on }">
                <v-list-item v-if="ClientappSignedInEmail && SystemObj && SystemObj.ClientAppURL"  v-on="on">
                  <v-list-item-title class="warning--text fieldmappings">Invite<p class="tooltiptext">New Admin</p></v-list-item-title>
                  <v-list-item-action>
                  <v-icon color="green">mdi-login</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-card tile flat>
                <v-card-title>
                  New Admin Email
                </v-card-title>
                <v-card-text>
                  Invite new admin to {{SystemObj.Name}} on {{SystemObj.ClientAppURL}}
                  <v-text-field :rules="[$store.state.formrules.email]" type="text" v-model="InvitingUserEmail" placeholder="Email" size="12"/><br>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="ProcessInvitetoUser()">Process</v-btn>
                </v-card-actions>
              </v-card>
           </v-dialog>
          <!-- <v-list-item v-if="ClientAppSignedIn && BaseClientSystemReady" @click="ReplicateSystemConfigMastSubCols()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            SubCol Replicate
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item> -->
        <v-list-item v-if="ClientAppSignedIn && BaseClientSystemReady" @click="ReplicateSystemMasterBU()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            BU Replicate
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
      <v-list-item v-if="ClientAppSignedIn && BaseClientSystemReady" @click="ReplicateSystemConfig()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Replicate
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
        
        </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import SystemBuilderQuickstart from '@/components/RAExclusive/SystemBuilderQuickstart';


export default {
    props: ['ClientFireStore','clientDB','ClientDBAdministrators','ClientDBBusinessUnits','QuickstartStep','EntityDataRef','EntityCollectionRef','BaseClientSystemReady',
    'SystemEntities','System','ClientAppUser',
    'QuickStartDialog','ClientAppAuthDialog','ViewingSystemID','SystemObj','ClientappSignedInEmail','ClientAppSignedIn','CurrentEntity','DynamicRecordData'],
    components:{
      SystemBuilderQuickstart
    },
    data(){
        return{
          rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        invitedialog: false,
          invitesnackbar: false,
          ClientAppURL: '',
          InvitingUserEmail: '',
          BUEntity: ''
        }
    },
    computed:{
      userLoggedIn () {
      return this.$store.getters.user
    },
    },
    created(){
      // alert('builder called')
      db.collection('systems').doc()
        this.BUEntity = this.SystemEntities.find(obj => obj.id === 'Business_Units')
        console.log(this.QuickstartStep)
        console.log(this.CurrentEntity)
    },
    methods:{
      ProcessInvitetoUser(){
        console.log('will invite user on '+this.InvitingUserEmail)
        let ref = this.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc()
        let newdocid = ref.id
        let NewUserObj = {
          Email: this.InvitingUserEmail,
          Status: {
            ID: 1000001,
            Name: 'Sent'
          },
          Business_Unit: {
            id: 'Head_Office',
            Name: 'Head Office'
          },
          Business_Unitid: 'Head Office',
          Created_By: {id: this.ClientAppUser.id, Name: this.ClientAppUser.Name, Surname: this.ClientAppUser.Surname, Full_Name: this.ClientAppUser.Full_Name},
          Created_Byid: this.ClientAppUser.id,
          Created_On: new Date(),
        }
        NewUserObj.Expired = false
        if(this.InviteUserisAdmin){
          NewUserObj.rolesarrayQuery = ['System Admin']
          NewUserObj.rolesarrayDBRules = {SystemAdmin: true}
        }
        this.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userinvites').doc(newdocid).set(NewUserObj).then(doc => {
          console.log('created user invite record')
        })

        let path = this.SystemObj.ClientAppURL+'/UserInvite/'+newdocid
        let EmailBody = `Good Day<div><br></div><div>You have been invited to join `+this.SystemObj.Name+`. </div><div>Please click on the below link to accept and process your invite.</div><div><br></div>
        <a href="`+path+`">Open your Invite</a><br>
        <div>Please note that this link is PRIVATE and you can NOT share the link with anybody else, lest you leave your pending profile at risk for attacks</div>
        <div>Thank you in advance</div>`
        console.log(EmailBody)
          let EmailObject = {          
            EmailFrom: 'notification@rapidapps.co.za',
            EmailTo: this.InvitingUserEmail,
            EmailSubject: 'Invite to join - '+this.SystemObj.Name,
            EmailBody: EmailBody,

          }
              //alert('email sending')
              console.log(EmailObject)
              const functions = firebase.functions();
              const SendEmailNow = functions.httpsCallable('sendEmailNow');
              SendEmailNow(EmailObject).then(result => {
                console.log(result)
             
                  this.invitesnackbar = true
                this.CancelInvitingUserEmail()
              })
      },
      CancelInvitingUserEmail(){
        this.invitedialog = false
        setTimeout(() => {
          this.InvitingUserEmail = ''
        }, 8000);
      },
        AuthenticateClientApp(){
            this.$emit('AuthenticateClientApp')
        },
        ReplicateSystemMasterBU(){
          console.log(this.BUEntity)
          let path = db.collection('systems').doc(this.$route.params.id).collection('entities').doc(this.BUEntity.id)
          let buobj = Object.assign({},this.BUEntity)
          delete buobj.AllFields
          delete buobj.SubCollections
          delete buobj.Galleries
          delete buobj.DocumentRegisters
          delete buobj.EntityProcesses
          delete buobj.GoalMeasurements
          delete buobj.DigitizedForms
          delete buobj.buildid
          delete buobj.automations
          //buobj.Table_Icon = this.BUEntity.dataseticon
          buobj.Security_Structure = {
            ID: 1000001,
            Name: 'Custom Roles'
          }
          buobj.Entity_Type = {
            ID: 1000005,
            Name: 'Business Unit Entity'
          }
          buobj.SystemRequired = true
          delete buobj.dataseticon
          console.log(path,buobj)
          //okay actually we would do best to put this under current system ops doc "entities" SubColleciton. to be honest.

          path.set(buobj).then(doc => {
            this.BUEntity.AllFields.map(field => {
              path.collection('AllFields').doc(field.id).set(field)
            })
            
          })
        },
        ReplicateSystemConfigMastSubCols(){
      console.log(this.CurrentEntity)
      this.CurrentEntity.SubCollections.map(subcol => {
        db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('SubCollections').doc(subcol.id).set(subcol)
      })
    },
    ReplicateFonts(){
      db.collection('SystemConfig').doc('ConfigMaster').collection('AppFonts').onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let fontdoc = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                console.log(fontdoc)
                this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AppFonts').doc(fontdoc.id).set(fontdoc)
              }
            })
      })
    },
    ReplicatetoConfigMasters(){
      this.ReplicateFonts()
      this.CurrentEntity.SubCollections.map(subcol => {
        this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('SubCollections').doc(subcol.id).set(subcol)
      })
      //what about system "AllFields"
      this.ProcessReplicateSystemConfig(this.CurrentEntity)
      let entityobj = this.SystemEntities.find(obj => obj.id === 'Entities')
      this.ProcessReplicateSystemConfig(entityobj)
      let fieldobj = this.SystemEntities.find(obj => obj.id === 'Fields')
      this.ProcessReplicateSystemConfig(fieldobj)

    },
    ProcessReplicateSystemConfig(entity){
      let cliententityref = this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc(entity.id)
      //no more, we do from datarecordtoolbar for us now - let raentityref = db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc(entity.id)
      //one for client
      cliententityref.update({
        SingleBuildTabs: entity.SingleBuildTabs
      })
      entity.AllFields.map(field => {
        cliententityref.collection('AllFields').doc(field.id).set(field)
      })
      //another for us no more
      // raentityref.update({
      //   SingleBuildTabs: entity.SingleBuildTabs
      // })
      // entity.AllFields.map(field => {
      //   raentityref.collection('AllFields').doc(field.id).set(field)
      // })
      
    },
    ReplicateClientMaster(){
      
      
      //okay we create systemconfig and shite already as is all perfect, no worries so go ahead and replicate entities and shit
      if(this.DynamicRecordData.SubCollections && this.DynamicRecordData.SubCollections.length > 0){
        this.DynamicRecordData.SubCollections.map(subcol => {
            if(subcol.Data){
                //right we need to add the data per subcol like uhm... hmmm
                    subcol.Data.map(subcolrecord => {
                    console.log(subcolrecord.LookupObj,subcol)
                    let obj = Object.assign({},subcolrecord)
                    delete obj.CanGet
                    delete obj.CanDelete
                    delete obj.CanEdit
                    delete obj.SubCollections
                    delete obj.id
                    console.log(obj)
                    if(subcol.id === 'entities' && obj.Security_Structure){
                      //alert('yay one')
                      obj.datasetsecuritystructure = obj.Security_Structure.Name
                      console.log(obj)
                    }
                    this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection(subcol.id).doc(subcolrecord.id).set(obj).then(subcoldoc => {
                        if(subcolrecord.id === 'Business_Units' && !subcolrecord.LookupObj){
                          subcolrecord.LookupObj = {
                            list: 'Entities'
                          }
                        }
                        if(subcolrecord.LookupObj && subcolrecord.LookupObj.list){
                        let build = subcol.LookupBuilds.find(obj => obj.id === subcolrecord.LookupObj.list)
                        console.log(build)
                            if(build.SubCollections){
                              if(subcol.EntityBuilder && build.id === 'Entities'){
                                //simply push the required
                                let gallreyon = build.SubCollections.find(obj => obj === 'Galleries')
                                if(!gallreyon){
                                  build.SubCollections.push('Galleries')
                                }
                                let docregon = build.SubCollections.find(obj => obj === 'Document_Registers')
                                if(!docregon){
                                  build.SubCollections.push('Document_Registers')
                                }
                                } 
                            let childsubcollength = build.SubCollections.length
                                build.SubCollections.map((subcolchild,subcolchildindex) => {
                                    subcolrecord.SubCollections = []
                                    this.GetSubColSubCol(build.id,subcolrecord,subcolchild,this.EntityDataRef.collection(subcol.id).doc(subcolrecord.id).collection(subcolchild)).then(result => {
                                    if(subcolchildindex-1+2 === childsubcollength){
                                        console.log('result',result)
                                        result.SubCollections.map(finalsubcol => {
                                            let docref = this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection(subcol.id).doc(result.id).collection(finalsubcol.SubColName)
                                            finalsubcol.SubColData.map(entry => {
                                                docref.doc(entry.id).set(entry)
                                            })
                                        })
                                        // subcolrecord = result
                                        // this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection(subcol.id).doc(result.id).collection(subcolchild.id).set(result).then(subcoldoc => {
                                        //     subcol.SubCollections.map(subcolchild)
                                        // })
                                        //okay now we got all subcols ready to add subcol under systemmaster
                                    }
                                    })
                                })   
                            }
                            else{
                                //there are no subcols, just add record right?
                            }
                        }
                        else{
                            //this is not a Lookup type subcolrecord,, like samplerecords...do we just add?
                        }   
                    })
                    
                })  
            }
            else{
                //okay there is a subcol but no data, so nothing to be added all is good
            }
          
          
        })
      }
      else{
          //there are not subcollecitons for teh system, actually we should not even have this method as an option anymore
      }
    },
    ReplicateSystemConfig(){
      //two paths here. One replicates the system record and all of its subcols and crap into the systemmaster of the client db
      //a second copies the current systemmaster into the configmaster of client end, as well as Ra end
      //but first, something preceding this, we push the basic system single values to the clientmaster
      console.log(this.SingleTabsLayout,this.SystemEntities,this.CurrentEntity,this.clientDB,this.DynamicRecordData)
      // let Social_Landing_PageField = {
      //   id: 'Social_Landing_Page',
      //   Name: 'Social_Landing_Page',
      //   DisplayName: 'Social Landing Page',
      //   Type: 'Route Path',
      //   PathFilter: 'Internal Pages'
      // }
      let AllFields = this.CurrentEntity.AllFields
      
      // AllFields.push(Social_Landing_PageField)
      AllFields.map(field => {
        if(typeof this.DynamicRecordData[field.Name] !== 'undefined'){
          this.clientDB.collection('SystemConfig').doc(this.$route.params.id).update({
            [field.Name]: this.DynamicRecordData[field.Name]
          })
        }        
      })
      //  this.clientDB.collection('SystemConfig').doc(this.$route.params.id).update({
      //       ClientAppURL: this.DynamicRecordData.ClientAppURL
      //     })
      
      //right, now we can actually dynamically push db, and path, along with "this"[item]
      this.ReplicateClientMaster()
      this.ReplicatetoConfigMasters()
      this.CopyAutoNumbers()
    },
    CopyAutoNumbers(){
             let autonumberscollection = ['StoreOrderEntityDefault','StoreSupplierEntityDefault','User_Companies_Company_ID',
                 'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number','GoodsReceivingVouchers_GRV_Number','PackingLists_Number','Payment_Invoices_Invoice_Number',
                 'PickingSlips_Number','PurchaseRequisitions_Number','Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number','Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
                autonumberscollection.map(col => {
                    let docpath = db.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        docdata.LastNumbers = []
                        docdata.Last_Number = 0
                        docdata.Last_Value = 0
                        let docid = col
                        this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(docid).set(docdata)
                        if(!docdata.IsEntityDefault){
                          this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection('AutoNumbers').doc(docid).get().then(autonrsnap => {
                            let autodoc = autonrsnap.data()
                            if(!autodoc){
                              this.clientDB.collection('SystemConfig').doc(this.$route.params.id).collection('AutoNumbers').doc(docid).set(docdata)
                            }
                          })
                        }
                    })
                })
        },
    GetSubColSubCol(entityid,record,subcolname,subcolquery,headers){
      console.log('GetSubColSubCol',entityid,record,subcolname,subcolquery,headers)
      let subcol = {
        SubColName: subcolname,
        SubColData: [],
        
      }
      if(entityid){
        subcol.PrimaryEntity = entityid
      }
      if(headers){
        subcol.Headers = headers
      }
      let vm = this									
       return new Promise(function(resolve, reject) {		
         subcolquery.onSnapshot(res=> {
           let length = res.docs.length
           if(length === 0){
             resolve(record)
           }
           else{
             if(!record.SubCollections){
               record.SubCollections = []
             }
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                subcol.SubColData.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                if(subcol.SubColData.length === length){
                  record.SubCollections.push(subcol)
                  resolve(record)
                }
              }
            })
           }
           
         })
       })
    },
    }
}
</script>

<style>

</style>