
    <template>
<v-card flat class="transparent" width="100%" height="100%">
    <h4>Tasks for {{RelatedObj[RecordPrimaryField]}}</h4>

                      <v-list dark class="recordtoolbar" dense max-height="75">
                        <v-list-item>
                          <v-list-item-content>
                        <!-- <v-btn outlined class="mr-4" color="secondary">
                          <v-text-field v-model="NewTask.Name" label="Name"></v-text-field>
                        </v-btn> -->
                        
                            <v-text-field  color="secondary" dense outlined v-model="search" label="Subject"></v-text-field>
                          </v-list-item-content>
                          <v-list-item-content>
                      <v-menu
                      
                          v-model="TaskFilterDatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                            dense
                            outlined
                            color="secondary"
                              v-model="TaskFilterDate"
                              label="Due Date"
                              prepend-icon="mdi-calendar-month"
                              readonly
                              v-on="on"
                              clearable
                                                         
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="TaskFilterDate" @input="TaskFilterDatemenu = false"></v-date-picker>
                      </v-menu>
                      </v-list-item-content>
                        
                        </v-list-item>
                          </v-list>
                          <v-dialog v-model="TaskDialog" max-width="500px">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on"
                                  fab
                                  color="primary"
                                  right
                                  absolute
                                  small
                                ><v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                            </template>
                          <v-card width ="600px">
                          <v-card-title class="recordtoolbar white--text headline">
                            Add New Task
                          </v-card-title>
                                <v-card-text>
                                  <v-text-field v-model="NewTask.Subject" label="Subject"></v-text-field>
                                </v-card-text>
                                <v-card-text>
                                  <v-card-title class="justify-start">
                                Description: 
                              </v-card-title>
                              <v-card-subtitle class="caption">
                                Write the value using the editor below, and remember to click on the "save" to capture the value. 
                              </v-card-subtitle>
                                  <ContentEditableField style="padding:15px;"
                            :FieldObject="NewTask" :FieldName="'Description'"
                            :FieldValue="NewTask.Description" @UpdateContentEditableField="UpdateTaskDescription" :AdditionalSaveMethod="''" />
                                </v-card-text>
                                <v-card-text>
                                  <UserLookup :UsersLookupArray="UsersArray"  :ModelProp="'Owner'" :RecordObj="NewTask" @UpdateUserLookupProp="UpdateUserLookupProp"
                                      :FieldLabel="'Owner'" :Rules="[$store.state.formrules.required]"
                                      />
                                </v-card-text>
                                <v-card-text>
                                  <v-menu
                                        v-model="DueDatemenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field dense
                                            v-model="NewTask.Due_Date"
                                            label="Due Date"
                                            prepend-icon="mdi-calendar-month"
                                            readonly
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="NewTask.Due_Date" @input="DueDatemenu = false"></v-date-picker>
                                      </v-menu>
                                </v-card-text>                                
                                <v-card-text>
                                   <v-switch label="Has Reminder" dense v-model="NewTask.Has_Reminder"/>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn dark color="blue darken-1" @click="CloseTaskDialog()">Cancel</v-btn>
                                  <v-btn dark color="blue darken-1" @click="SaveNewTask()">Save</v-btn>
                                </v-card-actions>
                          </v-card>
                          </v-dialog>
                           <v-layout row class="justify-center my-6">
                      {{TaskStatusFilter}} - {{TasksFilteredandSearched.length}}
                      Total - {{ActivitiesArray.length}}
                      </v-layout>   
                          <v-layout class="justify-center mx-4" style="font-size:12px">
                            <v-col>
                              <strong>Owner</strong>
                            </v-col>
                            <v-col>
                              <strong>Subject</strong>
                            </v-col>
                            <v-col>
                              <strong>Date</strong>
                            </v-col>
                            
                            <v-col>
                              <v-menu
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-filter-menu-outline</v-icon>
                              </template>
                                <v-card>
                                <v-layout row class="mx-3">
                                    <v-select :items="['Assigned','In Progress','On Hold','Completed']" v-model="TaskStatusFilter" label="Status Filter"></v-select>
                                  </v-layout>
                                                  
                                  <v-layout row class="mx-3">
                                    <v-menu
                                    v-model="TaskFilterDatemenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="TaskFilterDate"
                                        label="Filter Date"
                                        prepend-icon="mdi-calendar-month"
                                        readonly
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="TaskFilterDate" @input="TaskFilterDatemenu = false"></v-date-picker>
                                </v-menu>
                                </v-layout>
                                </v-card>
                              </v-menu>                              
                            </v-col>
                          </v-layout>
                          
                          <v-list-group class="mx-3 my-2 red lighten-5 basicoutlined"
                                  v-for="task in TasksFilteredandSearched" :key="task.id"
                              >
                                <template v-slot:activator>
                                  <v-layout class="justify-center" style="font-size:12px">
                                  <v-col cols="1">
                                    <v-avatar color="accent" size="25">
                                    <v-btn class="transparent" :to="'/User/'+task.Owner.id" target="_blank"><span class="white--text">{{task.Owner.Full_Name.split(' ')[0].substr(0,1)}}{{task.Owner.Full_Name.split(' ')[1].substr(0,1)}}</span></v-btn>
                                  </v-avatar>
                                  </v-col>
                                  <v-col cols="6" class=" mx-2">
                                   {{ task.Subject }}
                                  </v-col>
                                   <v-col cols="6">
                                  {{ task.Due_Date.toDate().getDate()}}-{{ task.Due_Date.toDate().getMonth()-1+2}}-{{ task.Due_Date.toDate().getFullYear()}}
                                  </v-col>
                                
                                  </v-layout>
                                </template>
                                <v-list dense class="white taskdetails">
                              <v-list-item>
                                View <v-spacer></v-spacer><v-icon @click="ViewActivityItem(task)" class="blue--text">mdi-eye</v-icon>
                              </v-list-item>
                              <v-list-item>
                                <b>Owner:</b><v-spacer></v-spacer>{{ task.Owner.Full_Name }}
                              </v-list-item>
                              <v-list-item>
                                <b>Subject:</b><v-spacer></v-spacer>{{ task.Subject }}
                              </v-list-item>
                              
                              <v-list-item>
                                <b>Description:</b>
                              </v-list-item>
                              <v-list-item>
                                {{ task.Description }}
                              </v-list-item>
                              <v-list-item>
                                <b>Status:</b><v-spacer></v-spacer>{{ task.Status_Reason.Name }}
                              </v-list-item>
                              <!-- <v-list-item>
                                <b>Mark Complete:</b><v-spacer></v-spacer><v-btn fab x-small color="success"><v-icon small color="white">mdi-check</v-icon></v-btn>
                              </v-list-item> -->
                     
                            </v-list>
                                </v-list-group>
                                                  
</v-card>    
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import UserLookup from '@/components/General/UserLookup'
import ContentEditableField from '@/components/Database/ContentEditableField'
export default {
    props: ['Business_Unit','UserRecord','UsersArray','RelatedObj','TasksQuery',
    'RecordPrimaryField','RegardingTypefilter','ActivityTypefilter','SystemEntities'],  
    components: {UserLookup,ContentEditableField}, 
    data() {
        return {
          ActivitiesArray: [],
            TaskFilterDate: '',
      TaskDialog: false,
      rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      NewTask: {
            Subject: '',
            Description: '',
            Type: 'Task',
            Regarding_Type: '',
            createdon: format(new Date(),'yyyy-MM-dd'),
            Due_Date: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
      },
      DefaultNewTask: {
            Subject: '',
            Description: '',
            Type: 'Task',
            Regarding_Type: '',
            createdon: format(new Date(),'yyyy-MM-dd'),
            Due_Date: '',
            Owner: {id: '', Name: '', Surname: '', Full_Name: ''},
            Ownerid: '',
      },
            pickerDate: null,
            TaskStatusFilter: 'Assigned',
            CreatedOnmenu: false,
            DueDatemenu: false,
            TaskFilterDatemenu: false,
            taskheaders: [
            { text: 'Owner', value: 'Owner'},
            { text: 'Subject', value: 'Subject'},
            { text: 'Date', value: 'Due_Date'},
            { text: 'Delete', value: 'delete', sortable: false },
            { text: 'Check', value: 'check', sortable: false },
        ],
        search: '',
        }
    },
    computed: {
      userLoggedIn () {
      return this.$store.getters.user
    },
    userBUID () {
      return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    userBU () {
    return this.$store.state.buobj
    },
      ActivityStatusField(){
      //console.log('SystemEntities',this.SystemEntities)
      let activityentity = this.SystemEntities.find(obj => obj.id === 'Activities')
      //console.log('SystemEntities activityentity',activityentity)
      if(activityentity){
        return activityentity.AllFields.find(obj => obj.id === 'Status')
      }
    },
        ActivitiesSearched() {
      return this.ActivitiesArray.filter(Activity => {
        if(Activity.Subject){return Activity.Subject.toLowerCase().includes(this.search.toLowerCase())}})
      
      },
      ActivitiesFilteredandSearched() {
      return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.Type){ return Activity.Type.Name.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.Regarding_Type){ return Activity.Regarding_Type.Name.includes(this.RegardingTypefilter)}} else {return Activity}})
      },
      TasksFilteredandSearched(){
          return this.ActivitiesFilteredandSearched.filter(activity => {
              return activity.Type.Name === 'Task'
          }).filter(activity => {
              if(this.TaskFilterDate){
                  return this.DateFilterString(activity.Due_Date) === this.FormattedTextDate
              }
              else{
                  return activity
              }
          })
          .filter(activity => {
              if(this.TaskStatusFilter){
                  return activity.Status_Reason.Name.includes(this.TaskStatusFilter)
              }
              else{
                  return activity
              }
          })
      },
      TasksFilteredandSearchedTable(){
          return this.TasksFilteredandSearched.filter(activity => {
              return activity.Due_Date === this.TaskFilterDate
          })
      },
      FormattedPickDate(){
        return this.DateFormatter(this.TaskFilterDate)
      },
      FormattedTextDate(){
          if(this.TaskFilterDate){
        return this.TaskFilterDate.split('-').join('')
          }
      },    
    },
    created(){
      console.log('this.TasksQuery',this.TasksQuery)
      this.GetActivitiesArray(this.TasksQuery)
    },
    methods:{
      UpdateTaskDescription(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(prop,value,AdditionalSaveMethod,FieldObject)     
    },
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
      GetActivitiesArray(query){
        query.onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              this.ActivitiesArray.push({
                ...change.doc.data(),
                id: change.doc.id
              })
            }
          })
        })
      },
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
    },
        SaveNewTask(){
      const TasktoAdd = {
            Subject: this.NewTask.Subject,
            Description: this.NewTask.Description,
            Type: {ID: 1000001, Name: 'Task'},
            Regarding_Type: this.RelatedObj.Link,
            Due_Date: new Date(this.NewTask.Due_Date),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Created_On: new Date(),
            Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Modified_Byid: this.userLoggedIn.id,
            Modified_On: new Date(),
            Status: this.ActivityStatusField.DefaultOption,
            Status_Reason: this.ActivityStatusField.DefaultLevel2Option,
            Regarding_Record: {
              Link:'/'+this.RelatedObj.Link+'/'+this.RelatedObj.id, Name:this.RecordPrimaryField, id: this.RelatedObj.id
            },
             

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      let FollowingUserIDStrings = []
      let FollowingUserIDKeys = {}
       TasktoAdd.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       TasktoAdd.FollowingUserIDKeys = FollowingUserIDKeys
       TasktoAdd.FollowingUserIDStrings = FollowingUserIDStrings
      if(this.NewTask.Has_Reminder){
        TasktoAdd.Has_Reminder = this.NewTask.Has_Reminder
      }
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.Type === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Subject,
              Content: TasktoAdd.Description,
              CreatorFullName: TasktoAdd.Created_By.Full_Name,
              CreatorID: TasktoAdd.Created_Byid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.Created_By.Full_Name,
                    Date: new Date()
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },
        DateFilterString(date){      
      let onedate = date.toDate()
      let dateyear = onedate.getFullYear().toString()
      let datemonth = onedate.getMonth()-1+2
      let datemonthfinal = datemonth.toString()
      let dateday = onedate.getDate()
      if(datemonth.toString().length===1){
        let stringdate = dateyear+'0'+datemonth+dateday
      return stringdate
      }
      else{
        let stringdate = dateyear+datemonth+dateday
      return stringdate
      }
    },
    }
}
</script>