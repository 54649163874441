
<template>			
<v-main>			
  <v-row class="white justify-center">			
      <v-flex xl8 lg9 md10 sm11 class="white">			
          			
         <v-card class="white" tile height="100%" width="100%">             			
            <v-card-text>			
                <v-form			
                ref="form"			
                v-model="valid"			
                lazy-validation			
                >			
             <v-stepper  v-model="SurveyStep" class="steppernoshadow">			
            <v-stepper-header class="overline">			
                <div v-for="(elmnt,i) in tab.Elements" :key="elmnt.itemObjkey">			
                <v-stepper-step 			
                :complete="SurveyStep > i-1+2"			
                :step="i-1+2">			
                {{elmnt.ObjectDisplayName}}			
                </v-stepper-step>			
                <v-divider></v-divider>			
                </div>			
            </v-stepper-header>			
            <v-stepper-items >			
                <v-stepper-content v-for="(elmnt,i) in tab.Elements" :key="elmnt.itemObjkey" :step="i-1+2">			
                   			
                    <v-card flat v-if="elmnt.Type === 'Table'">			
                        <v-card-text>			
                            <v-data-table 			
                            hide-default-footer show-expand			
                            :items="elmnt.TableData" :headers="elmnt.TableHeaders">			
                                <!-- We need to loop through headers and respond to props to let these Templates and jazz work dynamically -->			
                                <template v-slot:expanded-item="{ headers, item }">			
                                    <td :colspan="headers.length">			
                                    <v-card class="my-3" width="100%" flat tile>			
                                        <span v-for="expandprop in elmnt.TableExpandProps" :key="expandprop.itemObjkey">			
                                        <img class="mx-2 my-2" :src="item[expandprop.PropName]" :height="expandprop.ImgHeight" v-if="expandprop.Type === 'img'"/>			
                                        <v-card-text v-if="expandprop.Type === 'v-html'">			
                                            <span v-html="item[expandprop.PropName]"/>			
                                        </v-card-text>			
                                        </span>			
                                        <v-card-actions>			
                                            <div v-for="action in elmnt.TableExpandItemsActions" :key="action.itemObjkey">			
                                            <v-spacer></v-spacer>			
                                            <v-btn v-if="action.Type === 'RecordRoute'" :to="action.RecordLocation+item.EntityID+'/'+item.id">View in Store</v-btn>			
                                            </div>			
                                        </v-card-actions>			
                                    </v-card>			
                                    </td>			
                                </template>			
                                <template v-slot:[`item.actions`]="{ item }">			
                                    <span v-for="action in elmnt.TableActions" :key="action.itemObjkey">			
                                    <v-btn outlined fab x-small @click="DoSomething(action,item)" :class="action.Class">			
                                        {{action.Name}}			
                                        <v-icon small v-if="action.Icon">			
                                            {{action.Icon}}			
                                            </v-icon>			
                                        </v-btn>			
                                    </span>			
                                </template>			
                                <template v-slot:no-data>			
                                    <div v-if="!elmnt.MustContainData">			
                                        No Data			
                                    </div>			
                                    <div v-if="elmnt.MustContainData" class="red--text" v-html="elmnt.NoDataMessage">			
                                    </div>			
                                    <v-btn			
                                    dark			
                                    color="success"			
                                    @click="DoSomething(elmnt.NoDataAction,SurveyItem)" 			
                                    v-if="elmnt.MustContainData && elmnt.NoDataAction"			
                                    >			
                                    {{elmnt.NoDataAction.Name}}			
                                    </v-btn>			
                                </template>			
                            </v-data-table>			
                        </v-card-text>			
                        <v-card-text>			
                        </v-card-text>			
                    </v-card>			
                    <WebFormTab v-if="elmnt.Type === 'Web Form'"			
                    :tab="elmnt" :IsSurveyForm="true" @UpdateSurveyInfo="UpdateSurveyInfo"			
                    />			
                    <v-card flat v-if="elmnt.Type === 'Summary'">			
                        <v-card-title>			
                            Summary			
                        </v-card-title>			
                        <v-list v-for="prop in RemoveProps(SurveyItem,['Confirmation','ConfirmationAction','ConfirmationButton','Processing'])" :key="prop.itemObjKey">			
                            <v-divider></v-divider>			
                            <v-card-title class="subtleoverline">			
                                {{prop.DisplayTitle}}			
                            </v-card-title>			
                            <v-card-text v-if="prop.Type === 'Table'">			
                                <v-list>			
                                    <v-list-item dense>			
                                        <v-list-item-content v-for="header in prop.Headers" :key="header.itemObjKey">			
                                            {{header.text}}			
                                        </v-list-item-content>			
                                    </v-list-item>			
                                    <v-list-item dense v-for="response in prop.Response" :key="response.itemObjKey">			
                                        <v-list-item-content v-for="header in prop.Headers" :key="header.itemObjKey">			
                                            {{response[header.value]}}			
                                        </v-list-item-content>			
                                    </v-list-item>			
                                </v-list>			
                            </v-card-text>			
                            <v-card-text v-if="prop.Type === 'Web Form'">			
                                <v-list>			
                                    			
                                    <v-list-item dense v-for="itemprop in Object.entries(prop.Response)" :key="itemprop.itemObjKey">			
                                       <v-list-item-content :class="typeof itemprop[1] === 'object' ? 'objectcontentheader' : ''">			
                                           {{itemprop[0]}}			
                                       </v-list-item-content>			
                                       <v-list-item-content v-if="typeof itemprop[1] === 'string'">			
                                           {{itemprop[1]}}			
                                       </v-list-item-content>			
                                       <v-list-item-content v-if="typeof itemprop[1] === 'object'">			
                                           <v-list>			
                                               <v-list-item dense v-for="objprop in itemprop[1]" :key="objprop.itemObjKey">			
                                                   {{objprop}}			
                                               </v-list-item>			
                                           </v-list>			
                                       </v-list-item-content>			
                                    </v-list-item>			
                                </v-list>			
                            </v-card-text>			
                            <v-card-text v-if="prop.Type === 'Date Range Max'">			
                                {{prop.Response}}			
                            </v-card-text>			
                            <v-divider></v-divider>			
                        </v-list>			
                        			
                    </v-card>			
                  <v-card flat v-if="elmnt.Type === 'Confirmation'">			
                      <v-card-text v-html="SurveyItem.Confirmation">			
                      </v-card-text>			
                  </v-card>			
                </v-stepper-content>			
            </v-stepper-items>			
            			
             </v-stepper>			
             <v-divider></v-divider>			
             </v-form>			
        </v-card-text>			
        			
        <v-card-actions v-if="!userLoggedIn" id="surveyrecaptcha">    			
                     			
        </v-card-actions>			
        <v-card-actions style="padding:20px;color: rgb(89,89,89)" :class="footer.SubTitle? 'mediumoverline' : 'articleoverline'" v-for="footer in tab.TabFooters" :key="footer.itemObjKey">			
           {{footer.Name}}			
           <v-spacer></v-spacer><span v-if="footer.IsCurrency && $store.state.DefaultCurrency">			
           {{CurrencyFormatter(tab[footer.Prop],$store.state.DefaultCurrency.Currency)}}</span>			
        </v-card-actions>			
        			
        <v-card-actions>			
            <v-spacer></v-spacer>			
            <v-btn text @click="RegressSurvey()">			
                Back			
                </v-btn>			
            <v-btn			
                color="primary"			
                :disabled="AdvancingDisabled"			
                @click="AdvanceSurvey()"			
                v-if="!tab.Elements[SurveyStep-1].IsFinal && !SurveyItem.Confirmation"			
                >			
                Continue			
                </v-btn>			
                <v-btn			
                color="primary"			
                :disabled="AdvancingDisabled"			
                 @click="ValidateSurvey(tab,SurveyItem)" 			
                v-if="tab.Elements[SurveyStep-1].IsFinal && !SurveyItem.Confirmation"			
                >			
                {{tab.FinalStepName}}			
                </v-btn>			
                <v-btn			
                dark			
                color="success"			
                 @click="DoSomething(SurveyItem.ConfirmationAction,SurveyItem)" 			
                v-if="SurveyItem.ConfirmationAction"			
                >			
                {{SurveyItem.ConfirmationAction.Name}}			
                </v-btn>			
        </v-card-actions>			
        			
         </v-card>			
          			
      </v-flex>			
      </v-row>			
</v-main>			
</template>			
			
<script>			
import firebase from 'firebase';			
import db from '@/main'			
import axios from 'axios'			
import format from 'date-fns/format'			
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';			
import { max } from 'date-fns';			

export default {			
    props: ['tab','SurveyItem','ActiveStore','StoreCheckout','SystemEntities','GrandTotal','TaxTotal','TaxPercentage','OrderTotal'],			
    components: {			
        WebFormTab			
    },			
    data(){			
        return {			
            Bypass_Picking_and_Packing: false,			
            SurveyStep: 1,			
            rules: {			
            numberfield: value => {			
                const pattern = /^[0-9]+$/im;			
                return pattern.test(value) || "Not a valid number.";			
            },			
            telnr: value => {			
                const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;			
                return pattern.test(value) || "Not a telephone number.";			
            },			
            youtubeurl: value => {			
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;			
                return pattern.test(value) || "Not a Youtube Video Link.";			
            },			
            min8Chars: value => value.length >= 8 || "Min. 8 characters",			
            required: value => !!value || "Required.",			
            email: value => {			
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;			
                return pattern.test(value) || "Invalid e-mail.";			
            },			
            url: value => {			
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol			
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name			
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address			
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path			
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string			
            '(\\#[-a-z\\d_]*)?$','i'); 			
                return urlpattern.test(value) || "Invalid Link.";			
            }			
            },			
        }			
    },			
    watch: {			
        			
    },			
    computed:{
        UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },			
       		userLoggedIn () {
        return this.$store.getters.user
        },	
        // ActiveStore(){			
        //     return this.$store.state.ActiveStore			
        // },			
        AdvancingDisabled(){			
            let currentelement = this.tab.Elements[this.SurveyStep-1]			
            //console.log(currentelement)			
            if(this.SurveyItem.Processing){			
                return true			
            }			
            else{			
             if(currentelement && currentelement.Type === 'Web Form'){			
                if( currentelement.Validates){			
                    return false			
                }			
                else{			
                    return true			
                }			
            }			
            else{			
                if(currentelement && currentelement.Type === 'Table'){			
                    if(currentelement.MustContainData && currentelement.TableData && currentelement.TableData.length > 0){			
                        return false			
                    }			
                    else{			
                        return true			
                    }			
                }			
                			
            }   			
            }			
            			
        },			
    },			
    created(){			
			
    },			
    methods:{			
        RemoveProps(item,PropsArray){			
            let object = Object.assign({},item)			
            PropsArray.map(prop => {			
                delete object[prop]			
            })			
            return object			
        },			
        UpdateSurveyInfo(tab){			
            //console.log(tab,tab.FormFields)			
            let currentelement = this.tab.Elements[this.SurveyStep-1]			
            currentelement.Validates = true			
            this.SurveyStep--			
            this.SurveyStep++			
        },			
        AdvanceSurvey(){			
            let currentelement = this.tab.Elements[this.SurveyStep-1]			
            			
            this.$emit('UpdateSurvey',currentelement.Type,currentelement.ObjectDisplayName,currentelement.ObjectProp,currentelement[currentelement.ObjectValue],this.SurveyStep-1)			
            if(this.tab.Elements[this.SurveyStep] && this.tab.Elements[this.SurveyStep].IsFinal && this.tab.FinalAdditions && this.tab.FinalAdditions.length > 0){			
                this.tab.FinalAdditions.map(addition => {			
                    let Type = addition.Type			
                    let ObjectDisplayName = addition.ObjectDisplayName			
                    let ObjectProp = addition.ObjectProp			
                    let ObjectValue = ''			
                    if(addition.Type === 'Date Range Max'){			
                        //console.log(this.SurveyItem,addition.ObjectLoop)			
                        let DateRange = this.SurveyItem[addition.ObjectLoop].Response.map(item => {			
                            return new Date(item[addition.ObjectValue])			
                        })			
                        ObjectValue = max(DateRange)			
                    }			
                    			
                    this.$emit('UpdateSurvey',Type,ObjectDisplayName,ObjectProp,ObjectValue,this.SurveyStep-1,true)			
                    this.SurveyStep++ 			
                })			
            }			
            else{			
             this.SurveyStep++   			
            }			
            			
            //console.log(this.SurveyStep)			
        },			
        RegressSurvey(){			
            this.SurveyStep--			
            //console.log(this.SurveyStep)			
        },			
        DoSomething(action,object){			
            this.$emit('DoSomething',action,object)			
        },			
        DateFormatter(date){			
    if(date){			
      let removeT = date.split('T').join(' ')			
      let removeFWDslash = date.split('/').join('-')			
    let yearstring = removeFWDslash.split('-')[0]			
      let monthstring = removeFWDslash.split('-')[1]			
      let daystring = removeFWDslash.split('-')[2]			
      let yearnumber = Number(yearstring)			
      let monthnumber = Number(monthstring)			
      let daynumber = Number(daystring)			
      return new Date(yearnumber, monthnumber-1, daynumber)			
      }			
    else{			
      return null			
    }			
  },			
        PrepResponses(NewRecord,FormFields,tab){			
            let length = FormFields.length			
            //console.log(length,NewRecord,FormFields,tab)			
           FormFields.map((field,i) => {			
               //console.log(field.Name,field.Response,field)			
                //Long story short need to facilitate for SubColleciton entries now			
                //Even longer story short, this method of looping entries must be a method outside this current one			
                //as we need to loop through the very same methods per subcollection entry			
                if(field.Type === 'Number Field'){			
                NewRecord[field.Name.split(' ').join('_')] = Number(field.Response)			
                }	
                else if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){			
                field.FieldBreakdown.map(brd => {
                    //console.log(brd,field.Response)
                    if(typeof field.Response[brd.Name] !== 'undefined'){
                        NewRecord[brd.Name] = field.Response[brd.Name]
                    }
                })
                }		
                else{			
                    NewRecord[field.Name.split(' ').join('_')] = field.Response			
                }			
                if(field.Type === 'Lookup'){			
                    NewRecord[field.Name.split(' ').join('')+'id'] = field.Response.id			
                }			
                NewRecord.Status = tab.StatusDefault			
                NewRecord.Status_Reason = tab.Status_ReasonDefault			
                if(field.Type === 'Date'){			
                    if(!field.HasTimeInput){			
                     let date = this.DateFormatter(field.Response)   			
                     NewRecord[field.Name.split(' ').join('_')] = {Type: 'Date', DateNumber: date.getTime()} 			
                    }			
                    else{			
                     //console.log(field.Response,field.TimeResponse)			
                     let finaldate = new Date(field.Response+' '+field.TimeResponse)			
                     NewRecord[field.Name.split(' ').join('_')] = {Type: 'Date', DateNumber: finaldate.getTime()}   			
                    }    			
                }			
                if(i-1+2 === length){ 			
                  if(!NewRecord.Created_By && this.userLoggedIn){			
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}			
                      NewRecord.Created_Byid = this.userLoggedIn.id                      			
                  }			
                  //console.log('reached length',NewRecord)			
                //   if(tab.DefaultOwner){			
                //     NewRecord.Owner = tab.DefaultOwner			
                //     NewRecord.Ownerid = tab.DefaultOwner.id			
                //     NewRecord.NewNotification = {			
                //     itemidprop: 'recordid',			
                //     Type: 'Web Form Submission',			
                //     Header: 'Form Submission for '+tab.FormName,			
                //     Content: 'New Submission for a '+tab.SingleName,			
                //     Owner: NewRecord.Owner,			
                //     Ownerid: NewRecord.Ownerid,			
                //     Read: false,			
                //     Path: '/'+tab.SingleName.split(' ').join(''),			
                //     Message: 'New Submission for a '+tab.SingleName,			
                //     }			
                //     if(this.userLoggedIn){			
                //         NewRecord.NewNotification.CreatorFullName = this.userLoggedIn.Full_Name			
                //         NewRecord.NewNotification.CreatorID = this.userLoggedIn.id			
                //         NewRecord.NewNotification.Content = NewRecord.NewNotification.Content+' by '+NewRecord.NewNotification.CreatorFullName			
                //         NewRecord.NewNotification.Message = NewRecord.NewNotification.Message+' by '+NewRecord.NewNotification.CreatorFullName			
                //     }			
                //   }			
                  if(this.userLoggedIn){			
                      NewRecord.WebSubmission = false			
                  }			
                  else{			
                      NewRecord.WebSubmission = true			
                  }			
                  			
                //   let obj = {			
                //     collection: 'Website_Orders',			
                //     data: NewRecord			
                //   }    			
                //   if(SubCollectionRecords && SubCollectionRecords.length > 0){			
                //       obj.subcoldata = SubCollectionRecords			
                //   }			
                //   console.log(obj)        			
                //   if(!tab.ExistingRecord){			
                //       SubmitFormData(obj).then(result => {			
                //         console.log(result)			
                //         if(tab.FromDatabase){			
                //             this.$emit('ConfirmSubmission',tab,obj,result.data)			
                //             this.$emit('ProcessingData',false)			
                //         }			
                //         else{			
                //         tab.FormSubmitted = true  			
                //         }			
                        			
                //     }) 			
                //   }  			
                //   else{			
                //     if(tab.FromDatabase){			
                //         this.$emit('ConfirmSubmission',tab,obj,tab.ExistingRecord.id)			
                //         this.$emit('ProcessingData',false)			
                //     }			
                //     else{			
                //     tab.FormSubmitted = true  			
                //     }			
                //   }			
                //console.log('reached length',NewRecord)			
                			
            }			
                }) 			
                return NewRecord			
        },			
        ValidateSurvey(tab,SurveyItem){			
            			
            this.$emit('UpdateSurvey','Processing')			
            this.SurveyStep--			
            this.SurveyStep++			
        //    if(this.$refs.form.validate()){			
               if(this.userLoggedIn){			
                   			
                this.CompleteSurvey(tab,SurveyItem)      			
               }			
               else{			
                   this.VerifyCaptcha(tab,SurveyItem)			
               }			
               			
        //    }			
        //    else{			
        //        alert('Please complete all Form Fields')			
        //    }			
       },			
       VerifyCaptcha(tab,SurveyItem){           			
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('surveyrecaptcha', {			
        'callback': (response) => {			
                // reCAPTCHA solved, allow signInWithPhoneNumber.			
                this.CompleteSurvey(tab,SurveyItem)			
                // ...			
            },			
            'expired-callback': () => {			
                // Response expired. Ask user to solve reCAPTCHA again.			
                // ...			
            }			
            })			
            window.recaptchaVerifier.render()			
            //console.log(window.recaptchaVerifier)			
        			
       },			
        CompleteSurvey(tab,SurveyItem){			
            let vm = this
            this.$store.commit('setCustomProcessingDialogText','Processing')
            this.$store.commit('setCustomProcessingDialog',true)			
            //console.log(tab,SurveyItem)			
            const functions = firebase.functions();			
            const SubmitFormData = functions.httpsCallable('SubmitFormData'); 			
            let FormFields = []			
            let NewRecord = {}            			
            let SubCollectionRecords = []			
            for(var prop in SurveyItem) {			
                if (SurveyItem.hasOwnProperty(prop) && SurveyItem[prop].Type === 'Web Form') {			
                    //console.log(prop)			
                   let element = tab.Elements[SurveyItem[prop].ElementIndex]			
                   element.FormFields.map(field => {			
                       FormFields.push(field)			
                   })			
                    			
                }			
                else if (SurveyItem.hasOwnProperty(prop) && SurveyItem[prop].IsSummaryAddition) {			
                    if(SurveyItem[prop].Type === 'Date Range Max'){			
                       //console.log(prop)			
                         let field = {			
                             Name: SurveyItem[prop].Title,			
                             DisplayName: SurveyItem[prop].DisplayTitle,			
                             Type: 'Date',			
                             Response: format(SurveyItem[prop].Response,'yyyy-MM-dd')			
                         }			
                         //console.log(field)			
                         FormFields.push(field)			
                    }			
                    			
                    			
                }			
                else if (SurveyItem.hasOwnProperty(prop) && SurveyItem[prop].Type === 'Table') {			
                    			
                    let SobColObj = {			
                        Name: SurveyItem[prop].Title,			
                        Data: []			
                    }			
                    let newarray = JSON.parse(JSON.stringify(SurveyItem[prop].Response))                    			
                    SobColObj.Data = newarray.map(record => {			
                        let SubColFormFields = []			
                        let element = tab.Elements[SurveyItem[prop].ElementIndex]			
                            element.FormFields.map(field => {			
                                field.Response = record[field.Name]			
                            SubColFormFields.push(field)			
                        })			
                        //console.log(record.Description)			
                        //console.log(SubColFormFields)			
                        let recordobj = this.PrepResponses(record,SubColFormFields,this.tab)			
                        return recordobj			
                    })			
                    SubCollectionRecords.push(SobColObj)			
                    //console.log(SubCollectionRecords)			
                    // let field = {Name: prop,Type: 'SubCollection',DisplayName: SurveyItem[prop].DisplayTitle}			
                    // field.Response = SurveyItem[prop].Response			
                    //SubCollectionRecords.push(field)			
                    			
                }			
            }			
                //console.log(FormFields)			
                NewRecord = this.PrepResponses(NewRecord,FormFields,this.tab)			
                if(this.userLoggedIn && this.StoreCheckout){
                    let account = this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default)
                    NewRecord.BillingAccount = {
                        Client_Reference: account.Client_Reference,
                        id: account.id
                    }
                    NewRecord.BillingAccountid = account.id
                    NewRecord.AutoConfigDocid = this.$store.state.Cart_Entity+'_SO_Number'
                    NewRecord.AutoConfigProp = 'SO_Number'
                    NewRecord.Amount_Invoiced = 0
                    NewRecord.Amount_Paid = 0
                    NewRecord.Order_Total = this.OrderTotal
                    NewRecord.Tax_Total = this.TaxTotal
                    NewRecord.Tax_Percentage = this.TaxPercentage
                    NewRecord.Grand_Total = this.GrandTotal
                    
                }
                if(this.userLoggedIn && this.StoreCheckout){
                    NewRecord.User = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                    NewRecord.Userid = this.userLoggedIn.id,
                    NewRecord.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                    NewRecord.Modified_Byid = this.userLoggedIn.id
                    // if(this.CurrentEntity.Precede_with_Quote){!E-COMMERCE PLUGIN
                    // NewRecord.Order_Progress = {ID: 1000001,Name: 'Preparing'}
                    // }
                    // else{
                    // NewRecord.Order_Progress = {ID: 1000003,Name: 'Approved'}
                    // }
                    NewRecord.Order_Progress = {
                        ID: 1000001,
                        Name: 'Preparing'
                    }
                }		
                //THIS ENTIRE PROCESS IS NOW FLAWED, GIVEN HOW "SURVEY" HAS NOW BEEN LIMITED TO CART_ENTITY ONLY, BEING FOR STORE ORDER ONLY, THIS IS NOT DYNAMIC ENOUGH	
                let obj = {			
                    collection: this.$store.state.Cart_Entity,			
                    data: NewRecord			
                  }    			
                  if(SubCollectionRecords && SubCollectionRecords.length > 0){	                      		
                      obj.subcoldata = SubCollectionRecords.map(subcol => {
                          subcol.Data = subcol.Data.map(NewObj => {
                              //LookupObj
                              //LinkedFromIdentifier?
                              let finaldate = new Date()	
                              
                              let ent = this.SystemEntities.find(obj => obj.id === this.$store.state.Cart_Entity)
                              console.log(this.SystemEntities,this.$store.state.Cart_Entity)
                              if(NewRecord.BillingAccount){
                                NewObj.BillingAccount = NewRecord.BillingAccount
                                NewObj.BillingAccountid = NewRecord.BillingAccountid
                              }
                              if(NewRecord.User){
                                NewObj.User = NewRecord.User
                                NewObj.Userid = NewRecord.Userid
                              }
                                NewObj.LinkedFromIdentifier = ent.Primary_Field_Name
                                NewObj.LookupObj = NewObj.Warehouse.LookupObj
                                NewObj.Total_Dispatching = 0
                                NewObj.Purchase_Requisitions_Progressed = []
                                NewObj.Purchase_Requisitions_Regressed = []
                                NewObj.Purchase_Orders_Progressed = []
                                NewObj.Purchase_Orders_Regressed = []      
                                NewObj.Goods_Receiving_Vouchers_Progressed = []
                                NewObj.Goods_Receiving_Vouchers_Regressed = []
                                NewObj.Picking_Slips_Progressed = []
                                NewObj.Picking_Slips_Regressed = []
                                NewObj.Packing_Lists_Progressed = []
                                NewObj.Packing_Lists_Regressed = []
                                NewObj.Dispatch_Instructions_Progressed = []
                                NewObj.Dispatch_Instructions_Regressed = []
                                NewObj.Created_On = {Type: 'Date', DateNumber: finaldate.getTime()}
                                NewObj.Modified_On = {Type: 'Date', DateNumber: finaldate.getTime()}
                                //console.log('this.userLoggedIn',this.userLoggedIn,NewObj)
                                if(this.userLoggedIn){
                                    NewObj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                                    NewObj.Modified_Byid = this.userLoggedIn.id
                                      
                                }
                                if(this.StoreCheckout){
                                    NewObj.LookupObj.StoreCollection = NewObj.EntityID
                                    NewObj.LookupObj.WarehouseDoc = NewObj.id,
                                    NewObj.LookupObj.WarehouseID = NewObj.Warehouse.id
                                }
                                
                              return NewObj
                          })
                          return subcol
                      })
                  }			
                // if(this.ActiveStore){			
                //     obj.SiteId = this.ActiveStore.id			
                //   }	
                obj.siteid = this.$store.state.ActiveSuiteid		
                  console.log(obj) 	
                  
                  //autonumber required if StoreCheckout
                  SubmitFormData(obj).then(result => {			
                        console.log(result.data)
                        console.log('NewRecord',NewRecord,SubCollectionRecords)			
                        this.$store.commit('setCustomProcessingDialogText','Adding Lines')
                        this.$store.commit('setCustomProcessingDialog',false)	
                       		
                        this.$emit('UpdateSurvey','Confirmation',result.data)			
                        this.SurveyStep++			
                        if(this.tab.FromDatabase){			
                            // this.$emit('ConfirmSubmission',tab,obj,result.data)			
                            // this.$emit('ProcessingData',false)			
                        }			
                        else{			
                        //this.tab.FormSubmitted = true  			
                        }
                         //Actually quite easy. This store does not allow allocating of stock PRIOR to PRocessing Order, those that want it will await code update
                         //Stock does not get allocated here.
                         //it would only be wise if e-commerce was setup complete different attack!
                        // if(SubCollectionRecords){
                        //     SubCollectionRecords.map(subcolrecord => {
                                
                        //         let datarecord = subcolrecord.Data[0]
                        //         console.log('NewRecord datarecord',datarecord)
                        //         if(datarecord.Warehouse){
                        //             //the issue being payload, payload has a heck of a lot of work half the props we don't know if they are there.
                        //             let payload = {
                        //                 Data: {
                        //                 LookupObj: datarecord.Warehouse.LookupObj,
                        //                 StockObj: datarecord.Warehouse.LookupObj,
                        //                 // Reference_Number: newdocid,
                        //                 Qty: datarecord.Qty,
                        //                 // Created_By: datarecord.Created_By,
                        //                 // Created_Byid: datarecord.Created_Byid,
                        //                 // Modified_By: datarecord.Modified_By,
                        //                 // Modified_Byid: datarecord.Modified_Byid,
                        //                 // Progress: {ID: 1000005,Name: 'Completed'},
                                        
                        //                 },
                        //                 //Collection: NewObj.LookupObj.list.split('_').join('').toLowerCase(),
                        //                 // id: NewObj.LookupObj.id
                        //                 }
                        //                 if(vm.userLoggedIn){
                        //                     payload.Data.Created_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id}
                        //                     payload.Data.Created_Byid = payload.Data.Created_By.id
                        //                     payload.Data.Modified_By = payload.Data.Created_By
                        //                     payload.Data.Modified_Byid = payload.Data.Created_By.id
                        //                 }
                        //                 if(this.Bypass_Picking_and_Packing){
                        //                     payload.Data.Bypass_Picking_and_Packing = true
                        //                     payload.Collection = 'DispatchInstructions'
                        //                     payload.Data.Progress = {ID: 1000005,Name: 'Completed'}
                        //                 }
                        //                 else{
                        //                     payload.Collection = 'PickingSlips'
                        //                     payload.Data.Progress = {ID: 1000001,Name: 'Issued'}
                        //                 }
                        //                 payload.Data.StockObj.StoreCollection = datarecord.EntityID
                        //                 payload.Data.StockObj.WarehouseDoc = datarecord.id,
                        //                 payload.Data.StockObj.WarehouseID = datarecord.Warehouse.id
                        //             console.log('payload',payload)
                        //             const ConsumeStockQty = functions.httpsCallable('ConsumeStockQty');
                        //             ConsumeStockQty(payload).then(result => {
                                        
                        //             })  
                        //         }
                        //     })
                        // }
                        
                        			
                    })			
                			
        },			
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },			
    }			
			
}			
</script>			
			
<style>			
			
</style>			

