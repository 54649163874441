
<template>
<v-card
      flat
      class="transparent"
      style="padding-bottom:50px;"
      :dark="AppisDarkMode"
    >
<v-layout class="justify-center">

    <v-flex xs12 s12 md12 lg12>
         <v-toolbar flat dark color="accent">
             <v-toolbar-title>Friends</v-toolbar-title>
        <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You changed a User Role.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
            <v-snackbar v-model="contributorsnackbar" :timeout="8000" top color="success">
      <span>Awesome! You added a Contributor, view them all and finalize their Contributor Profiles in "Member Contributors".</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
 <!-- <v-img align="left"
          :src="require('@/assets/elb_twirl.png')"
          class="my-3"
          
          height="40px"
        ></v-img> -->
      <v-spacer></v-spacer>
      <v-text-field :dark="AppisDarkMode"
        v-model="search"
        append-icon="mdi-file-find-outline"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
        <v-menu>
            <template v-slot:activator="{ on }">
              <v-icon  v-on="on" color="white">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item>
                Edit
              </v-list-item>
            </v-list>
        </v-menu>
          </v-toolbar>
          <v-layout  row class="justify-center">
          <v-card :dark="AppisDarkMode"
                     v-for="member in Computedgroupmembers" :key="member.id"
                    class="my-3 mx-3"
                    max-width="350px"
                        >
                        <v-list max-width="300">
                            <v-list-item>
                                <!-- <v-list-item-content > -->
                                <v-list-item-avatar class="blue" tile size="50">
                                <v-img v-if="member.Profile_Photo" :src="member.Profile_Photo"></v-img>
                                <v-img v-if="!member.Profile_Photo"  src="@/assets/BlankProfilePic.png"></v-img>                   
                                </v-list-item-avatar>
                                <!-- </v-list-item-content> -->
                                <v-list-item-content >
                                <v-list-item-title>
                                    <router-link :to="'/SiteMember/'+member.id" target="_blank">{{member.Full_Name}}
                                    </router-link></v-list-item-title>                    
                                <v-list-item-subtitle v-if="member.Position">
                                    <v-chip color="primary" x-small dark> {{member.Position}}</v-chip>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    Online: {{member.onlinestatus}}
                                </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <!-- <v-toolbar flat dark color="accent" height="30px">
                        </v-toolbar> -->
                       
           </v-card>
          </v-layout>
    </v-flex>
 </v-layout>
</v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
props: ['UserRecord','UserID','AppisDarkMode'],
    data() {
        return {
          hover: false,
          showerror: false,
          tags: [
                    'Auckland',
                    'Wellington',
                    'Very long string that would overflow'
                ],
          snackbar: false,
          contributorsnackbar: false,
              UserRolesType: [
      {index: 0, text: 'Follower'},
      {index: 1, text: 'Editor'},
      {index: 2, text: 'Administrator'},
        ],
        ListingContributors: false,
        EditingContributors: false,
        blogdialog: false,
        contributordialog: false,
        dialog: false,
        search: '',
        servicesearch: '',
        orgservicesearch: '',
        groupblogs: [],
        groupmembers: [],
        contributorgroups: [],
        contributingmembers: [],
        groupName: null,
        groups: [],
        users: [],
        // membersofgroup: [],
        groupid: this.$route.params.id,
        content: null,
        logo: '',
        image: '',
        description: null,
        Network: false,
        Editing: false,
        name: null,
        ServicestoOrganizations: [],
        DefaultServicestoOrganizations: [],
        ServicestoIndividuals: [],
        DefaultServicestoIndividuals: [],
        newservicetoindividual : {
          index: '', Name: '' ,checked: true
        },
        defaultservicetoindividual: {
          index: '', Name: '', check: true
        },
        editedContributor: {
              id: null,
              memberid: null,
              groupmemberid: null,
              ContributionCategory: null,
              ContributetoIndividuals: false,
              IndividualContributionDescription: null,
              OrganizationContributionDescription: null,
              ContributetoOrganizations: false,
              FreeConsultation: false,
              ServiceCategorytoIndividuals: null,
              //consulting,legal,financial,technical specialist,other
              ServiceCategorytoOrganizations: null,
              //consulting,legal,financial,technical specialist,other
              ServicestoIndividuals: [],
              ServicestoOrganizations: [],
              DefaultServicestoOrganizations: [],
              DefaultServicestoIndividuals: [],         


        },
            editedItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            defaultItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            blogtitle: null,
            blogcontent: null,
            image: null,
            //userid: this.$store.state.user.id,
            UserisMember: false,
            UserisAdmin: false,
            tetempusid: "'GNagVng6n1VgjtSXXrHDdEejjM93'"+","+"'Sk7cvSO6uXMJdN2qwJW5C9LSwzj1'", 
            Changes: false,
            GroupCategory: null,
            Contributorprofilefield: '',
            helpnewsheet: false,
            // MinistryContributionstoIndividialNames: '',
            MinistryContributionstoIndividualArr: [],
            BusinessContributionstoIndividualArr: [],
            FamilyContributionstoIndividualArr: [],
            MinistryContributionstoOrganizationsArr: [],
            BusinessContributionstoOrganizationsArr: [],
            FamilyContributionstoOrganizationsArr: [],
            HasOrgServices: false,
            HasIndServices: false,      
        
        }
    },
    computed: {
      Computedgroupmembers(){
        return this.users.map(member => {
          if(member.MembershipDate){
              member.MembershipDate = this.TimestampFormatterSTRING(member.Created_On.toDate())
          }
          
          return member
          
        }).filter(member => {
          return member.Full_Name.toLowerCase().includes(this.search.toLowerCase())
        })
                    
       },
    },
        created() {    
          setTimeout(() => {
            console.log(this.UserRecord)
          }, 1000); 
             this.IntranetViewToggle()
        this.getUsers()
    },

     methods: {
       IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
       TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
        ToggleHelp() {         
            this.helpnewsheet = true
          },

        editItem (item) {
        this.editedIndex = this.GroupMembersWithSearch.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        // this.Editing = true
      },

      

   
        
    getUsers() {
        let queryfield = this.UserID
        console.log(queryfield)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('friendships').where(queryfield,'==',true).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
            let friendshipobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          console.log(friendshipobj)
          let friendid = ''
          if(friendshipobj.Recipientid === this.UserID){
              friendid = friendshipobj.Requestorid
          }
          else{
              friendid = friendshipobj.Recipientid
          }
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(friendid).onSnapshot(snapshot => {
              let userdata = snapshot.data()
              userdata.id = friendid
              userdata.Friendship = friendshipobj
              let frienddate = friendshipobj.Date.toDate()
              userdata.FriendshipDate = format(frienddate,'yyyy-MM-dd')
              this.users.push(userdata)
          })
        //   this.users.push({
        //     ...change.doc.data(),
        //     id: change.doc.id
        //   })

        }  
      })
    })    
        },
          
            
     }
}
</script>

<style>
.tags-input {
    border: 1px solid #333;
    display: inline-block;
}
.UserName {
    font-size: 10px;
    font-weight: bold;
    color: #474747
}
.Memo {
      font-size: 10px;
}
.userprofileBG {
  align-self: center
}
.v-avatar.outlined {
                  border: 8px solid #048abf;
                  border-radius:100%;

}
.searchguide {
  color: #e4003b;
  font-size: 14px
}

</style>

    

    

    