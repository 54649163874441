<template>
  <div>
      <v-card @click="ActivateActiveCabinet(col)" tile flat class="transparent" >
      <v-card-title class="justify-center mediumoverline">
        {{col.CabinetID}}
      </v-card-title>
    <v-layout class="justify-center" >
      <v-avatar :color="col.CabinetType === 'Gallery' ? 'orange' : col.CabinetType === 'Document Register' ? 'blue' : 'green'" size="150">
      <v-icon dark size="130">
        {{col.CabinetType === 'Gallery' ? $store.state.DefaultIcons.find(obj => obj.Name === 'Galleries').Icon :
        col.CabinetType === 'Document Register' ? $store.state.DefaultIcons.find(obj => obj.Name === 'Document Registers').Icon : 
        col.CabinetType === 'Gallery' ? $store.state.DefaultIcons.find(obj => obj.Name === 'SubCollections').Icon : ''}}
      </v-icon>
      </v-avatar>
    </v-layout>
      </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','CurrentEntity'],
    components: {},
    data() {
        return {
        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      ActivateActiveCabinet(col){
        this.$emit('ActivateActiveCabinet',col)
      }
    }
}
</script>

<style>

</style>



