<template>
  <div>
     <v-card
    class="mx-auto"
    max-width="500"
  >
  <v-card-title class="text-h4 font-weight-regular justify-space-between">
      <span>{{tab.FormName}}</span>
      <v-chip
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="SurveyStep+' of '+tab.Elements.length"
      ></v-chip>
    </v-card-title>
  <v-card-title v-if="$route.name === 'SurveyBuilder' && CurrentTab" class="text-h6 font-weight-regular justify-space-between">
        <v-text-field class="mediumoverline" v-model="CurrentTab.FormName"></v-text-field>
        <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="SurveyStep"
      ></v-avatar>
      </v-card-title>
    <v-card-title v-if="$route.name !== 'SurveyBuilder' || !CurrentTab" class="text-h6 font-weight-regular justify-space-between">      
      <span>{{ currentTitle }}</span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="SurveyStep"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="SurveyStep">
      <v-card-actions v-if="$route.name === 'SurveyBuilder'">
           <v-btn @click="ActivateAddNewWebFormTabDialog()"><v-icon>mdi-format-list-text</v-icon>Add Section</v-btn>
        </v-card-actions>
      <v-window-item v-for="(elmnt,index) in Survey.SurveyTab.Elements" :key="elmnt.itemObjKey" :value="index-1+2">
        
        <v-card-text>
          <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" :PageID="ActivePageID" :PageName="PageName"
            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
              :DynamicWikiData="DynamicWikiData" :IsSurveyForm="true" @UpdateSurveyInfo="UpdateSurveyInfo"
            :tab="elmnt" :i="i"
            />
        </v-card-text>
        <v-card-actions v-if="$route.name === 'SurveyBuilder'">
         
          <v-chip-group
                active-class="primary--text"
                column
            >
            <v-chip
                @click="AddSurveySectionFields(field,true,elmnt)"
                small  v-for="field in FieldTypes"
                :key="field.itemObjKey"
                >
                {{field.Type}}
                </v-chip>                           
            </v-chip-group>
        </v-card-actions>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="SurveyStep === 1"
        text
        @click="SurveyStep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn

        color="primary"
        depressed
        @click="AdvanceSurvey()"
      >
        {{FinalItem ? 'Submit' : 'Next'}}
      </v-btn>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';

export default {
    props: ['System','SystemEntities','Survey'],
    components: {WebFormTab},
    data() {
        return {
          SurveyStep: 1,
          FieldTypes: [],
        }
    },
    computed:{
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      tab(){
        return this.Survey.SurveyTab
      },
      FinalItem(){
        return !this.tab.Elements[this.SurveyStep]
      },
      FormFields(){
        return this.tab.Elements.map(tab => {
          return tab.FormFields
        }).flat()
      },
      CurrentTab(){
        return this.tab && this.tab.Elements[this.SurveyStep-1]? this.tab.Elements[this.SurveyStep-1] : ''
      },
      currentTitle () {
       return this.tab && this.tab.Elements[this.SurveyStep-1]? this.tab.Elements[this.SurveyStep-1].FormName : 'No Tab'
      },

    },
    created(){
      console.log('this.tab Survey Form',this.tab,this.Survey)
      this.PrepareFieldTypes()
    },
    methods:{
      PrepareFieldTypes(){
        let fieldtypes = ['Single Line Text','Multiple Lines Text','Boolean','Radio Group','Option Set','Date','AutoNumber Field','Number Field','Single File Upload','Calculated Field','Map Location','Shared Doc Link','Lookup','Route Path']
        this.FieldTypes =fieldtypes.map(type => {
            let typeobj = {
                Type: type,
                Name: '',
                DisplayName: '',              
            }
            if(type === 'Lookup'){

            }
            else if(type === 'Option Set'){
                typeobj.Options = []
                typeobj.lastassignedinteger = 1000000
            }
            return typeobj
        })
      },
      AdvanceSurvey(){
        if(!this.FinalItem){
              this.SurveyStep++
            }
            else{
              console.log(this.tab,this.tab.FormFields,this.Survey,this.FormFields)
            }
      },
      UpdateSurveyInfo(tab){            
            let currentelement = this.tab.Elements[this.SurveyStep-1]
            currentelement.Validates = true
            this.AdvanceSurvey()
        },
      ActivateAddNewWebFormTabDialog(){
        this.$emit('ActivateAddNewWebFormTabDialog')
      },
      AddSurveySectionFields(field,AddingNewField,elmnt){
        let newfield = Object.assign({},field)
        if(newfield.Type === 'Option Set'){
            newfield.Options = []
            newfield.lastassignedinteger = 1000000
        }
        this.$emit('AddSurveySectionFields',newfield,AddingNewField,elmnt)
      },
    }
}
</script>

<style>

</style>
