<template>
  <v-bottom-navigation :id="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm || TopMenu ? '' : 'belowlogobar'"
    :value="value" style="z-index: 10;"
    :class="ThemeBGColor ? ThemeBGColor : ''"
    horizontal
  >
  
   <v-list class="transparent" v-if="DBNavbar">
      <v-list-item>
        <!-- <v-list-item-title class="mediumoverline white--text hidden-sm-and-down">
        <span>{{System.Name.substr(0,System.Name.indexOf(' '))}}</span><span v-if="System.Name.substr(System.Name.indexOf(' ')+1)" class="font-weight-light"> {{System.Name.substr(System.Name.indexOf(' ')+1)}}</span>
      </v-list-item-title>
      <v-list-item-title v-if="!$vuetify.breakpoint.xs" class="mediumoverline white--text hidden-md-and-up">
        <span></span><span class="font-weight-light"> App</span>
      </v-list-item-title> -->
      <v-list-item-action>
                        <v-menu offset-y :close-on-content-click="false" bottom left>
                          <template v-slot:activator="{ on }">
                             <v-badge color="pop" overlap
                              >

                              <v-icon
                              
                              v-on="on">
                              mdi-bell-check
                            </v-icon>

                            <span slot="badge"> {{UnreadNotifications.length}} </span>
                            </v-badge>
                          </template>

                          <v-tabs dark>
                                <v-tab>Unread</v-tab>
                                <v-tab-item>
                                <v-list light v-if="UnreadNotifications.length>0">
                                  <h3 class="viewingnotificationheader">New Notifications</h3><br>
                                  <v-list-item v-for="notification in UnreadNotifications" :key="notification.itemObjKey" :class="{newbasicoutlined: !notification.Read}" @click="SelectBottomNavMenuItem({Method: 'NotificationRead',Prop:notification})">
                                      <v-list-item-content class="notificationheader">
                                      {{notification.Header}}
                                      <p class="notificationcontent">{{notification.Message}}<span class="tooltiptext"><h4>{{notification.CreatorFullName}}</h4>{{notification.Content? notification.Content.substr(0, 50) : ''}}</span></p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-list class="viewingnotification" light v-if="UnreadNotifications.length===0">
                                  <h3 class="viewingnotificationheader">New Notifications</h3><br>
                                  <v-list-item>
                                    <v-list-item-content class="notificationheader">
                                      There are no new notifications<br>
                                      <p class="notificationcontent">Click on the "Read" tab to see Read notifications</p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                </v-tab-item>
                                <v-tab>Read</v-tab>
                                <v-tab-item>
                                <v-list class="viewingnotification" light v-if="ReadNotifications.length>0">
                                  <h3 class="viewingnotificationheader">Read Notifications</h3><br>
                                  <v-list-item v-for="notification in ReadNotifications" :key="notification.itemObjKey" :class="{newbasicoutlined: notification.Read}">
                                    <v-list-item-content class="notificationheader">
                                      {{notification.Header}}
                                      <p class="notificationcontent">{{notification.Message}}<span class="tooltiptext"><h4>{{notification.CreatorFullName}}</h4>{{notification.Content? notification.Content.substr(0, 50) : ''}}</span></p>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                      <v-btn small @click="SelectBottomNavMenuItem({Method: 'MarkasUnread',Prop:notification})">Mark Unread</v-btn><v-btn small @click="SelectBottomNavMenuItem({Method: 'ViewItem',Prop:notification})">View</v-btn>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-list class="viewingnotification" light v-if="ReadNotifications.length===0">
                                  <h3 class="viewingnotificationheader">Read Notifications</h3><br>
                                  <v-list-item>
                                    <v-list-item-content class="notificationheader">
                                      There are no Read notifications<br>
                                      <p class="notificationcontent">Click on the "Unread" tab to see New notifications</p>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                </v-tab-item>
                              </v-tabs>

                            </v-menu>
                            
      </v-list-item-action>
      <v-list-item-action>
      <v-icon @click="SelectBottomNavMenuItem({Method: 'ToggleAppDarkMode',Prop:''})">mdi-invert-colors</v-icon>
      </v-list-item-action>
</v-list-item>
   </v-list>
      <v-spacer></v-spacer>
    <v-btn v-for="main in BottomNavigationItems" :key="main.itemObjKey">
     <v-menu v-model="main.Menu" 
       :bottom="BottomMenu" :top="TopMenu" v-if="main.Children"
      :offset-y="offset"
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn
        
          dark
          v-bind="attrs"
          v-on="on"
        >
          <span v-if="!$vuetify.breakpoint.xs">
          {{main.title}}
        </span>
        <v-icon :color="ThemeColor">{{main.icon}}</v-icon>
      <v-badge color="warning" v-if="main.notifications" left>
          <span slot="badge"> {{main.notifications}}</span>
          </v-badge>
        </v-btn>
      </template>

      <v-list min-width="100" class="justify-start" tile>
          <v-list-item  v-for="subitem in main.Children" :key="subitem.itemObjKey">
          <v-menu  v-model="subitem.Menu" :bottom="BottomMenu" :top="TopMenu" nudge-right="150">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item  v-bind="attrs" :href="subitem.RouteBlank? subitem.Path : ''"
                v-on="on" @click="SelectBottomNavMenuItem(subitem)">
                <v-list-item-title class="caption justify-space-between">
                  
                <v-icon :color="ThemeColor">{{ subitem.icon }}</v-icon><v-badge color="warning" v-if="subitem.notifications" left>
                        <span slot="badge"> {{subitem.notifications}}</span>
                        </v-badge>
                {{subitem.title}} <v-icon style="right:0px;" v-if="subitem.Children">mdi-chevron-right</v-icon>
                 </v-list-item-title>         
              </v-list-item>  
            </template>
            <v-list min-width="200" class="justify-start" tile v-if="subitem.Children">
              <v-virtual-scroll
                    :items="subitem.Children"
                    :height="subitem.Children.length > 4 ? 200 : subitem.Children.length*45"
                    item-height="40"                  
                  >
                  <template v-slot:default="{ item }">
                  <v-menu v-if="item.Children" v-model="item.Menu" :bottom="BottomMenu" :top="TopMenu" nudge-left="150">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="caption" v-bind="attrs"
                                            v-on="on">
                                        <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>{{item.title}}
                                        <v-icon style="right:0px;">mdi-chevron-right</v-icon>
                                    </v-list-item>
                      </template>
                      <v-list min-width="200" class="justify-start" tile>
                        
                        <v-list-item @click="SelectBottomNavMenuItem(baby)" :href="item.RouteBlank? item.Path : ''" class="caption" v-for="baby in item.Children" :key="baby.itemObjKey">
                          <v-icon :color="ThemeColor">{{ baby.icon }}</v-icon>{{baby.title}}
                      </v-list-item>
                      </v-list>
                      </v-menu>
                      <v-list-item @click="SelectBottomNavMenuItem(item)"  :href="item.RouteBlank? item.Path : ''" class="caption" v-if="!item.Children">
                                        <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>{{item.title}}
                                    </v-list-item>
                  </template>
                  </v-virtual-scroll>
                    </v-list>
            </v-menu>           
          </v-list-item>
      </v-list>
    </v-menu>
     <v-btn  @click="SelectBottomNavMenuItem(main)"
           v-if="!main.Children"
          dark :href="main.RouteBlank? main.Path : ''"
          v-bind="attrs"
          v-on="on"
        >
          <span v-if="!$vuetify.breakpoint.xs">
          {{main.title}}
        </span>
        <v-icon :color="ThemeColor">{{main.icon}}</v-icon>
      
        </v-btn>
   
    </v-btn>
     <v-spacer></v-spacer>
     <ProfileActionsDropdown style="padding-right:5px;padding-top:5px;cursor: pointer;" v-if="DBNavbar && !$vuetify.breakpoint.xs" :ClientDB="ClientDB" :System="System"
        @ToggleAppStylingView="ToggleAppStylingView"/>
     
  </v-bottom-navigation>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ProfileActionsDropdown from '@/components/Navigation/ProfileActionsDropdown'
export default {
    props: ['System','SystemEntities','BottomNavigationItems','ThemeColor','ThemeBGColor','DBNavbar','UserPicture','UserRecord','UnreadNotifications','ReadNotifications','BottomMenu','TopMenu'],
    components: {ProfileActionsDropdown},
    data() {
        return {
            value: 1,
      offset: true,
      
      
        }
    },
    computed:{
      ClientDB(){
      return db
    },
      ActiveAppMode(){
      return this.$store.state.AppMode.Name
    },
      userLoggedIn () {
      return this.$store.getters.user
    },
    userBUID () {
      return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    userBU () {
    return this.$store.state.buobj
    },
      userIsAdmin () {
    return this.$store.state.IsAdmin
  },
      CurrentEntity(){
        if(this.$route.meta && this.$route.meta.DataSingleBuilder){
          return this.SystemEntities.find(obj => obj.id === this.$route.meta.EntityID)
        }
      },
      CurrentEntityMenuItems(){
        return this.EntityIndex0Items
      },
      
    },
    created(){

    },
    methods:{
      ToggleAppStylingView(){
        this.$emit('ToggleAppStylingView')
      },
        SelectBottomNavMenuItem(item){
          //console.log(item)
          if(!item.Children && !item.RouteBlank){
            item.Menu = false
            this.$emit('SelectBottomNavMenuItem',item)
          }
            
        }
    }
}
</script>

<style>

</style>
