<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between"><v-btn v-if="SampleOnly" @click="ToggleNoticeSample()" outlined> {{!NoticeSample ? 'Preview' : 'Close'}}</v-btn>
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
        <v-list-item class="justify-center" v-if="notice.IsGroupInvite"
          >
          <router-link :to="'/SiteMember/'+notice.InviteData.Invited_Byid">
            {{notice.InviteData.Invited_By.Full_Name}}
          </router-link>
          </v-list-item>
          <v-list-item class="justify-center" v-if="notice.IsGroupInvite"
          >
           invited you to join the Group
           </v-list-item>
          <v-list-item class="justify-center" v-if="notice.IsGroupInvite"
          >
           <router-link :to="'/Group/'+notice.InviteData.GroupID">
            {{notice.InviteData.GroupName}}
          </router-link>           
          </v-list-item>
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
         <v-list-item :to="ActiveMarketTemplate ? '' : notice.IsGroupInvite ? '' : '/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','mustreadnotices','SampleOnly','NoticeSample'],
    components: {},
    data() {
        return {
          noticestep: 0,
        }
    },	
    computed:{
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      ToggleNoticeSample(){
        this.$emit('ToggleNoticeSample')
      }
    }
}
</script>

<style>

</style>



