<template>
<div>
     <v-bottom-sheet persistent hide-overlay v-if="EditedTab" v-model="SizeandPositionDialog">
                <v-card width="100%" height="100%" flat tile style="background-color: rgba(255,255,255,0.9)">
                    <v-btn right absolute top outlined dark @click="CancelSizeandPosition()" color="red" >Cancel
                    </v-btn>
                    <v-card-subtitle class="background darken-1 mediumoverline" style="padding:15px;">
                        {{EditedTab.Name}}
                    </v-card-subtitle>
                    <v-tabs v-model="SizeandPositionMenuIndex" class="transtabs transparent" centered>
                    <v-tab>Sizing
                    </v-tab>
                    <v-tab-item class="transparent">
                        <v-row class="justify-center mx-3 my-3">
                        <v-btn-toggle class="justify-center" light >
                            <v-btn @click="ActivateWindowPreset(item,flexind)" fab icon v-for="(item,flexind) in ComputedWindowPresets" :key="item.iemObjKey">
                                <v-icon color="#466ca4" :style="item.Rotate? 'transform: rotate(90deg);' : ''">
                                    {{item.Icon}}
                                </v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        </v-row>
                        <v-row class="justify-center mx-3 my-3">
                        <v-slider 
                            v-model="EditedTab[ComputedWindowPresets[FlexIndex].FlexProp]"
                            :label="WindowPreset.Name+'('+WindowPreset.Dimensions+')'"
                            min="1"
                            max="12" thumb-label="always"
                            ></v-slider>
                        </v-row>
                    </v-tab-item>
                    <v-tab>Margins
                    </v-tab>
                    <v-tab-item class="transparent">
                        <v-list-item>									
                            <v-select :items="['justify-start','justify-center','justify-end','justify-space-between']" v-model="EditedTab.Justify" 									
                                label="Horizontal Align"/>									
                            </v-list-item>									
                        <v-list-item>									
                                <v-select clearable :items="['flex-start','center','flex-end']" v-model="EditedTab.Alignment" 									
                                label="Vertical Align"/>									
                            </v-list-item>
                            <v-list-item v-if="EditedTab.Name === 'Title'">									
                                <v-select clearable :items="['flex-start','center','flex-end']" v-model="EditedTab.TextAlign" 									
                                label="Text Align"/>									
                            </v-list-item>
                        <v-row class="justify-center">
                        <v-list class="transparent" v-for="opt in MarginOptions" :key="opt.itemObjKey">								
                                <v-list-item>
                                    <v-list-item-content>
                                        Margin {{opt.Pos}} ({{EditedTab[opt.Prop]}})
                                    </v-list-item-content>
                                    <v-list-item-action> <v-btn x-small @click="FigureMarginMethod(opt,EditedTab,'down')">									
                                    <v-icon>mdi-chevron-down</v-icon>									
                                </v-btn>
                                    </v-list-item-action>
                                    <v-list-item-action><v-btn x-small @click="FigureMarginMethod(opt,EditedTab,'up')">									
                                    <v-icon>mdi-chevron-up</v-icon>									
                                </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-row>
                    </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-bottom-sheet>
            
            
    <v-dialog max-width="400" v-model="NewSectionDialog" persistent>
        <v-card>
            <v-card-title>
                New Section
            </v-card-title>
            <v-card-text>
               <v-text-field label="Name" v-model="EditedSection.Name"/>
            </v-card-text>

            <v-card-actions>
                <v-btn dark outlined color="warning" @click="CancelNewSectionDialog()">
                    Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn dark outlined color="warning" @click="SaveNewSection()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
    <v-dialog max-width="400" v-model="NewRowDialog" persistent>
        <v-card>
            <v-card-title>
                New Row
            </v-card-title>
            <v-card-text>
               <v-text-field label="Name" v-model="EditedRow.Name"/>
            </v-card-text>
            <v-list-item dense> 
                <v-switch dense v-model="EditedRow.Transparent" label="Transparent" />
            </v-list-item>
            <v-list-item dense> 
                <v-switch dense v-model="EditedRow.Tile" label="Tile" />
            </v-list-item>
            <v-list-item dense> 
                <v-switch dense v-model="EditedRow.Flat" label="Flat" />
            </v-list-item>
            <v-list-item dense> 
                <v-switch dense v-model="EditedRow.Rounded" label="Rounded" />
            </v-list-item>
            <v-list-item dense >
           <v-slider
                v-model="EditedRow.Elevation"
                min="0"
                max="24"
                label="Elevation"
                ></v-slider>
            </v-list-item>
            <v-card-actions>
                <v-btn dark outlined color="warning" @click="CancelNewRowDialog()">
                    Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn dark outlined color="warning" @click="SaveNewRow()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>    
            <v-dialog max-width="400" v-model="NewColDialog" persistent>
        <v-card>
            <v-card-title>
                New Col
            </v-card-title>
            <v-card-text>
               <v-text-field label="Name" v-model="EditedColumn.Name"/>
               <v-select :items="ComputedColTypes" label="Type"
               v-model="EditedColumn.Type"
               />
               <v-select item-text="DisplayName" :items="FieldCardFields" return-object label="Field" v-if="EditedColumn.Type === 'Field Card'"
               v-model="EditedColumn.Field"
               >
               <template v-slot:append-item>
                <DataTableFieldBuilder :SampleRecord="{}" v-if="NewColDialog"
                 :CurrentEntity="CurrentEntity" :NewOnly="true" :RADB="RADB"
                 :AllowedFieldTypes="FieldCardFieldTypes" style="max-width: 300px;"
                 :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                />
               </template>
               </v-select>
                <v-select :items="ComputedFieldGroupTypes" label="Group Type" v-if="EditedColumn.Type === 'Field Group'"
               v-model="EditedColumn.FieldGroupType"
               />
               <!-- {{FieldGroupFields.length}} {{EditedColumn.Fields ? EditedColumn.Fields.length : 0}} -->
                <v-select item-text="id" multiple :items="FieldGroupFields" label="Field" v-if="EditedColumn.Type === 'Field Group' && EditedColumn.FieldGroupType"
               v-model="EditedColumn.Fields" 
               >
               <template v-slot:prepend-item>
                    <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="SelectAllItems(EditedColumn,'Fields',FieldGroupFields,'id','NewColDialog')"
                    >
                    <v-list-item-action>
                        <v-icon :color="EditedColumn.Fields && EditedColumn.Fields.length > 0 ? 'indigo darken-4' : ''">
                        {{ MultiSelectionIcon(EditedColumn,'Fields',FieldGroupFields,'id') }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                        Select All
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                </template>
               <template v-slot:append-item>
                <DataTableFieldBuilder :SampleRecord="{}" v-if="NewColDialog"
                 :CurrentEntity="CurrentEntity" :NewOnly="true" :RADB="RADB"
                 :AllowedFieldTypes="FieldGroupFieldTypes" style="max-width: 300px;"
                 :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                />
               </template>
               </v-select>
               <!-- EditedColumn.Type -->
                <v-select :items="AvailableTables" :return-object="CurrentEntity" :item-text="AvailableTablesIdentifier"
                label="Table" v-if="EditedColumn.Type === 'Table'" @change="AssignEntityID()"
               v-model="EditedColumn.Table" clearable
               />
                <v-select item-text="DisplayName" :items="ProgressCardFields" return-object label="Field" v-if="EditedColumn.Type === 'Progress Card'"
               v-model="EditedColumn.Field"
               >
              <template v-slot:append-item>
                <DataTableFieldBuilder :SampleRecord="{}" v-if="NewColDialog"
                 :CurrentEntity="CurrentEntity" :NewOnly="true" :RADB="RADB"
                 :AllowedFieldTypes="ProgressFieldTypes" style="max-width: 300px;"
                 :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef"
                :SystemEntities="SystemEntities" :System="System"
                />
               </template>
               </v-select>
               <LibraryColorsSelector v-if="ColorLibActive"
               :Record="EditedColumn" :field="{id: 'Color',DisplayName: 'Color'}" :FullRange="false"
               />
               <LibraryColorsSelector v-if="FullColorLibActive"
               :Record="EditedColumn" :field="{id: 'Color',DisplayName: 'Color'}" :FullRange="true"
               />               
               <v-text-field label="Icon" v-model="EditedColumn.Icon"
                 v-if="IconsActive"
               />
               <v-text-field label="Button Text" v-model="EditedColumn.ButtonText"
                v-if="EditedColumn.Type === 'Action Button'"
               />
               
                 <v-select :items="CabinetTypes" label="Cabinet Type" v-if="EditedColumn.Type === 'Cabinet'"
               v-model="EditedColumn.CabinetType"
               />
                <v-select :items="CabinetsbyType" label="Cabinet" v-if="EditedColumn.Type === 'Cabinet' && EditedColumn.CabinetType"
               v-model="EditedColumn.CabinetID" item-text="id"
               />
               <v-select :items="AvailableCalendars" item-text="Name" return-object label="Calendar" v-if="EditedColumn.Type === 'Calendar'"
               v-model="EditedColumn.Calendar"
               />
                 <v-select :items="AvailableBarGraphs" item-text="Name" return-object label="Graph" v-if="EditedColumn.Type === 'Bar Graph'"
               v-model="EditedColumn.Graph"
               />
                 <v-select :items="AvailableLineGraphs" item-text="Name" return-object label="Graph" v-if="EditedColumn.Type === 'Line Graph'"
               v-model="EditedColumn.Graph"
               />
               
            </v-card-text>

            <v-card-actions>
                <v-btn dark outlined color="warning" @click="CancelAddRowCol()">
                    Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn dark outlined color="warning" @click="SaveNewRowCol()">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>  
            <!-- NOT INTENDED FOR VERISON 1 <v-btn outlined v-if="CanEditDashboard" @click="ActivateAddSection()"> -->
    <div v-if="ComputedActiveCabinet" >
        
        <v-btn @click="ActiveCabinet = ''" outlined>Back</v-btn>
        <DataSingleImageGallery  :EntityName="EntityName" :EntityCollectionRef="EntityCollectionRef"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="EntityDataRef"
                @UpdateEditableField="UpdateEditableField" :SystemPhotoLibraries="SystemPhotoLibraries"
            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :System="System"
            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex"
            :PrimaryFieldName="CurrentEntity.Primary_Field_Name" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
            :Record="Record" v-if="ComputedActiveCabinet.SectionType === 'Gallery'" :AdditionalSaveMethod="AdditionalSaveMethod"
            :section="ComputedActiveCabinet" :tab="tab" :Fields="ComputedActiveCabinet.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="ComputedActiveCabinet.CanEdit" :CanCreate="ComputedActiveCabinet.CanCreate" :CanDelete="ComputedActiveCabinet.CanDelete"
            />
            <DataSingleDocumentRegister :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
            :Record="Record" v-if="ComputedActiveCabinet.SectionType === 'Document Register'"
            :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"
            @OpenDialog="OpenDialog" :sectionindex="sectionindex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
            :Business_Unit="Business_Unit" :PrimaryFieldName="CurrentEntity.Primary_Field_Name" :RelatedObj="RelatedObj"
            :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true" :System="System"
            :section="ComputedActiveCabinet" :tab="tab" :Fields="ComputedActiveCabinet.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="ComputedActiveCabinet.CanEdit" :CanCreate="ComputedActiveCabinet.CanCreate" :CanDelete="ComputedActiveCabinet.CanDelete"
            />
            <TableEmbedTab 									
                v-if="ComputedActiveCabinet.SectionType === 'Related Table' && ComputedActiveCabinet.Entityid && ComputedActiveCabinet.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"		
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"					
                :tab="ComputedActiveCabinet" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="Record" :System="System"									
                />
                <TableEmbedTab 									
                v-if="ComputedActiveCabinet.SectionType === 'SubCollection' && ComputedActiveCabinet.Entityid && ComputedActiveCabinet.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity"	
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"					
                :tab="ComputedActiveCabinet" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="Record" :RADB="RADB" :System="System"									
                />
    </div>
    <v-btn @click="GoBack()" outlined v-if="CanGoBack">
        Back
    </v-btn>
     <v-btn :color="EditingDashboard ? 'error' : 'warning'" outlined v-if="CanEditDashboard && !ComputedActiveCabinet" @click="EditingDashboard = !EditingDashboard">
        <v-icon v-if="EditingDashboard">mdi-cancel</v-icon>
    {{EditingDashboard ? 'Cancel' : 'Edit Dashboard'}}</v-btn>
    
    <v-btn color="success" outlined v-if="EditingDashboard && !ComputedActiveCabinet" @click="StoreSingleDashView()">
    Save View</v-btn>
    <v-layout v-if="!ComputedActiveCabinet && EditingDashboard"> 
        <v-btn outlined @click="SelectEditingType('Sections')">
            Sections
        </v-btn>
        <v-btn outlined @click="SelectEditingType('Rows')">
            Rows
        </v-btn>
        <v-btn outlined @click="SelectEditingType('Columns')">
            Columns
        </v-btn>
    </v-layout>
    <v-layout v-if="!ComputedActiveCabinet"> 
        
        <v-btn outlined v-if="EditingDashboard && EditingType === 'Sections'" @click="ActivateAddSection()">
                Add Section</v-btn>      
    <div  v-for="(sect,secti) in ComputedSections" :key="sect.itemObjKey"
        :class="'flex xl'+sect.FlexXLRG+' lg'+sect.FlexLarge+' md'+sect.FlexMedium+' sm'+sect.FlexSmall+' xs'+sect.FlexXSmall">
        <v-menu  v-if="EditingDashboard && EditingType === 'Sections'"									
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                            									
                    >									
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" absolute v-if="EditingDashboard && EditingType === 'Sections'">
                            <v-icon>mdi-menu
                            </v-icon>
                        </v-btn>	
                    </template>								
                    <v-card width="200">									
                        <v-card-title>Options
                            </v-card-title>
                            <v-list-item @click="EditSection(sect,secti)">
                                <v-icon>mdi-cogs</v-icon> Update
                            </v-list-item>
                            <v-list-item @click="ConfirmDeleteSection(sect,secti)" >
                                <v-icon color="error">mdi-delete-forever</v-icon> Delete
                            </v-list-item>
                            <v-list-item @click="ActivateElementSizingDialog(sect)"> 
                                <v-icon color="blue">mdi-arrow-expand-horizontal</v-icon> Sizing
                            </v-list-item>                  
                    </v-card>
                   </v-menu>
        <v-card :class="sect.Transparent ? 'transparent' : ''" :tile="true" :flat="sect.Flat" :elevation="sect.Flat? 0 : sect.Elevation"
        height="100%" min-height="200px" width="100%"
        >
         
        <v-card-text>
             <v-btn outlined v-if="EditingDashboard && EditingType === 'Rows'" @click="ActivateAddSectionRow(sect,secti)">
                Add Row</v-btn>
        <div v-for="(row,rowi) in sect.Rows" :key="row.itemObjKey">
            <!-- <v-card :class="row.Transparent ? 'transparent' : ''" :tile="row.Tile" :flat="row.Flat" :elevation="row.Flat? 0 : row.Elevation"
                    :rounded="row.Rounded" height="100%" min-height="200px" width="100%"
                    > -->
            <!-- <v-card-title class="mediumoverline">{{row.Name}}
                    </v-card-title> -->
            <v-menu  v-if="EditingDashboard && EditingType === 'Rows'"									
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                            									
                    >									
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" absolute v-if="EditingDashboard && EditingType === 'Rows'">
                            <v-icon>mdi-menu
                            </v-icon>
                        </v-btn>	
                    </template>								
                    <v-card width="200">									
                        <v-card-title>Options
                            </v-card-title>
                            <v-list-item @click="EditRow(sect,secti,row,rowi)">
                                <v-icon>mdi-cogs</v-icon> Update
                            </v-list-item>
                            <v-list-item @click="ConfirmDeleteRow(sect,secti,row,rowi)" >
                                <v-icon color="error">mdi-delete-forever</v-icon> Delete
                            </v-list-item>
                            <v-list-item @click="ActivateElementSizingDialog(row)"> 
                                <v-icon color="blue">mdi-arrow-expand-horizontal</v-icon> Sizing
                            </v-list-item>                  
                    </v-card>
                   </v-menu>
            <v-row class="justify-start">
                
                <v-col :style="col.Type === 'Counter' ? 'flex-grow: 0!important;' : ''" v-for="(col,coli) in row.Columns" :key="col.itemObjKey"
                :xl="col.FlexXLRG" :lg="col.FlexLarge" :md="col.FlexMedium" :sm="col.FlexSmall" :xs="col.FlexXSmall">
                  
                   <v-menu  v-if="EditingDashboard && EditingType === 'Columns'"									
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                            									
                    >									
                    <template v-slot:activator="{ on }">
                        <v-btn fab v-on="on" absolute v-if="EditingDashboard && EditingType === 'Columns'">
                            <v-icon>mdi-menu
                            </v-icon>
                        </v-btn>	
                    </template>								
                    <v-card width="200">									
                        <v-card-title>Options
                            </v-card-title>
                            <v-list-item @click="EditColumn(sect,secti,row,rowi,col,coli)">
                                <v-icon>mdi-cogs</v-icon> Update
                            </v-list-item>
                            <v-list-item @click="ConfirmDeleteCol(sect,secti,row,rowi,col,coli)" >
                                <v-icon color="error">mdi-delete-forever</v-icon> Delete
                            </v-list-item>
                            <v-list-item @click="ActivateElementSizingDialog(col)"> 
                                <v-icon color="blue">mdi-arrow-expand-horizontal</v-icon> Sizing
                            </v-list-item>                  
                    </v-card>
                   </v-menu>
                    <v-card :class="row.Transparent ? 'transparent' : ''" :tile="row.Tile" :flat="row.Flat" :elevation="row.Flat? 0 : row.Elevation"
                     height="100%" min-height="20px" width="100%" :style="row.Rounded ? 'border-radius: 25px;' : ''"
                     :color="col.Type === 'Counter' ? col.Color? col.Color : 'red' : ''"
                    >
                     <v-card-subtitle class="subtleoverline" v-if="!HeaderlessColTypes.includes(col.Type)" >{{col.Name}}
                    </v-card-subtitle>
                   
                    <v-card-text>
                       
                     <DashActionButton v-if="col.Type === 'Action Button'"
                         :System="System" :SystemEntities="SystemEntities"
                     :col="col"
                        />
                     <DashCabinet v-if="col.Type === 'Cabinet'"
                         :System="System" :SystemEntities="SystemEntities"
                     :col="col" :CurrentEntity="CurrentEntity" @ActivateActiveCabinet="ActivateActiveCabinet"
                        />
                     <DashCalendar v-if="col.Type === 'Calendar'" :CurrentEntity="CurrentEntity" :Record="Record"
                         :System="System" :SystemEntities="SystemEntities"
                     :col="col"
                        />
                     <DashCounter  v-if="col.Type === 'Counter'"
                         :System="System" :SystemEntities="SystemEntities"
                     :col="col"
                        />
                        <DashFeed  v-if="col.Type === 'Feed'"
                         :System="System" :SystemEntities="SystemEntities"
                     :col="col"
                        />
                        <DashFieldGroup v-if="col.Type === 'Field Group'"
                         :System="System" :SystemEntities="SystemEntities"
                        :col="col" :Record="Record" :CanEdit="CanEdit"
                        :EntityDataRef="EntityDataRef"
                        :RelatedObj="RelatedObj"
                        @SaveRecord="SaveRecord"
                        />
                        <DashFieldCard v-if="col.Type === 'Field Card'"
                         :System="System" :SystemEntities="SystemEntities"
                        :col="col" :Record="Record" :CanEdit="CanEdit"
                        :EntityDataRef="EntityDataRef" :CurrentEntity="CurrentEntity"
                         :RelatedObj="RelatedObj" @SaveDocumentField="SaveDocumentField"
                        @SaveRecord="SaveRecord" @onPhotoUpload="onPhotoUpload"
                        />
                         <DashLineGraph v-if="col.Type === 'Line Graph'"
                         :System="System" :SystemEntities="SystemEntities"
                         :col="col" :TeamView="true"
                         :MyActivitiesArray="MyActivitiesArray" :UnreadNotifications="UnreadNotifications"
                        />
                        <DashBarGraph v-if="col.Type === 'Bar Graph'"
                         :System="System" :SystemEntities="SystemEntities"
                         :col="col" :TeamView="true"
                         :MyActivitiesArray="MyActivitiesArray" :UnreadNotifications="UnreadNotifications"
                        />
                        <DashProgressCard v-if="col.Type === 'Progress Card'"
                         :System="System" :SystemEntities="SystemEntities"
                          :col="col" :Record="Record" :CanEdit="CanEdit"
                          @SaveRecord="SaveRecord"
                        />
                      <DashTable v-if="col.Type === 'Table'"
                        :System="System" :SystemEntities="SystemEntities"
                        :col="col" :UnreadNotifications="UnreadNotifications"
                        :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity"
                        :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef" 
                        :Record="Record"
                        />
                        
                    </v-card-text>
                    </v-card>                   
                </v-col>
                <v-col>
                    <v-btn style="height: 100%;width: 15px;" outlined v-if="EditingDashboard" @click="ActivateAddRowCol(sect,secti,row,rowi)">
                <v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
            </v-row>
            <!-- </v-card> -->
        </div>
        </v-card-text>
        </v-card>
        </div>
    </v-layout>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DataTableFieldBuilder from '@/components/SuiteBuilder/SuiteApps/Settings/DBBuilder/DataTableFieldBuilder';
import DashActionButton from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashActionButton'
import DashCabinet from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashCabinet'
import DashCalendar from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashCalendar'
import DashCounter from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashCounter'
import DashFeed from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashFeed'
import DashFieldGroup from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashFieldGroup'
import DashFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashFieldCard'
import DashLineGraph from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashLineGraph'
import DashBarGraph from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashBarGraph'
import LibraryColorsSelector from '@/components/Database/LibraryColorsSelector'

import DashProgressCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashProgressCard'
import DashTable from '@/components/SuiteBuilder/SuiteApps/Dashboards/DashTable'
import DataSingleDocumentRegister from '@/components/Database/DataSingleDocumentRegister';
import DataSingleImageGallery from '@/components/Database/DataSingleImageGallery';
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';

export default {
    props: ['System','SystemEntities','AllTabs','ActiveEntityTabIndex','ActiveSections','MyActivitiesArray','UnreadNotifications','CanEditDashboard','Record','CurrentEntity','AllFields','DefaultIcon',
    'RelatedObj','CanGoBack',
    'CanEdit','CanDelete','RADB'],
    components: {DataSingleDocumentRegister,DataSingleImageGallery,TableEmbedTab,
    DashActionButton,DashCabinet,DashCalendar,DashCounter,DashFeed,DashFieldGroup,DashFieldCard,DashLineGraph,DashBarGraph,DashProgressCard,DashTable,DataTableFieldBuilder,
    LibraryColorsSelector},
    data() {
        return {
            LibColorColumnTypes: ['Progress Card','Action Button','Progress Bars'],
            FullLibColorColumnTypes: [],
            IconColTypes: ['Action Button'],
            EditingType: '',
            SizeandPositionDialog: false,
            SubCollections: [],
            DocumentRegisters: [],
            Galleries: [],
            CustomRolesDefault:   
            [
            {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
            {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
            {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
            {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'UpdatePermission',IntBoolean: 'CanEdit', Action: 'Update'},
            {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
            ],
            ActiveCabinet: '',
            CabinetTypes: ['Gallery','Document Register','SubCollection'],
            EditingDashboard: false,
            HeaderlessColTypes: [
                'Counter',
                'Field Card',
                // 'Field Group'
            ],
            FieldCardTypes: [
                'User Avatar',
                'Option Toggle',

            ],
            CalendarPresetOptions: [
                 {
                    Name: 'Team Appointments',
                    ComponentPath: 'components/SuitePlugins/Activities/MyTeamSchedule',
                }
            ],
            GraphPresetOptions: [
                {
                    Name: 'Team Activities',
                    ComponentPath: 'components/SuitePlugins/Activities/ActivitiesSummary',
                    Color: 'orange'
                }
            ],
            ColTypes: [
                // 'Action Button',
                'Cabinet',
                'Calendar',
                'Counter',
                'Feed',
                'Field Card',
                'Field Group',
                'Line Graph',
                'Bar Graph',
                'Progress Card',
                'Table',
            ],
            MarginOptions: [
            {Pos: 'Top',Prop: 'MarginTop',Method: 'RowTopMargin'},
            {Pos: 'Bottom',Prop: 'MarginBottom',Method: 'RowBottomMargin'},
            {Pos: 'Left',Prop: 'MarginLeft',Method: 'RowLeftMargin'},
            {Pos: 'Right',Prop: 'MarginRight',Method: 'RowRightMargin'},
        ],
            EditedTab: '',
            WindowPreset: {
                Name: 'Default',
                Icon: 'mdi-monitor'
            },
            FlexIndex: 0,
            WindowPresets: [            
            {
                Name: 'Default',
                Icon: 'mdi-monitor'
            },
            {
                Name: 'Laptop',
                Icon: 'mdi-laptop',
                Width: '1263px',
                Height: '710px',
                Dimensions: '1263px by 710px',
                Breakpoint: 'md',
                FlexProp: 'FlexMedium'
            },
             {
                Name: 'Tablet Portrait',
                Icon: 'mdi-tablet',
                Width: '768px',
                Height: '1024px',
                Dimensions: '768px by 1024px',
                Breakpoint: 'sm',
                Rotate: true,
                FlexProp: 'FlexSmall'
            },
            {
                Name: 'Tablet Landscape',
                Icon: 'mdi-tablet',
                Width: '1024px',
                Height: '768px',
                Dimensions: '1024px by 768px',
                Breakpoint: 'md',
                FlexProp: 'FlexMedium'
            },           
            {
                Name: 'Phone Portrait',
                Icon: 'mdi-cellphone',
                Width: '360px',
                Height: '740px',
                Dimensions: '360px by 740px',
                Breakpoint: 'xs',
                FlexProp: 'FlexXSmall'
            },
            {
                Name: 'Phone Landscape',
                Icon: 'mdi-cellphone',
                Width: '740px',
                Height: '360px',
                Dimensions: '740px by 360px',
                Breakpoint: 'sm',
                Rotate: true,
                FlexProp: 'FlexSmall'
            },
        ],
            SizeandPositionMenuIndex: 0,
            Sections: [],
            EditedSection: '',
            NewSectionDialog: false,
            DefaultEditedSection: {
                Name: 'Some Section',                
                Flat: true,
                Transparent: true,
                Elevation: 0,
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 12,									
                FlexXSmall: 12,
            },
            EditedSectionIndex: -1,
            NewRowDialog: false,
            NewColDialog: false,
            EditedRow: '',
            EditedRowIndex: -1,
            DefaultEditedRow: {
                Name: 'Some Row',
                Columns: []
            },
            DefaultEditedCol: {
                Flat: true,
                Transparent: true,
                Elevation: 0,
                FlexXLRG:6,									
                FlexLarge: 6,									
                FlexMedium: 6,									
                FlexSmall: 12,									
                FlexXSmall: 12,
                Name: 'Some Col',
            },
            EditedColumn: '',
            EditedColumnIndex: -1,
            AllFieldTypes: ['Single Line Text','Multiple Lines Text','Common Field','Boolean','Option Set','Radio Group',
        'Date','AutoNumber Field','Number Field','Single File Upload','Rollup Calculation','Calculated Field',
        'Map Location','Shared Doc Link','Lookup','Parent Field','Route Path','Social Lookup','Custom Object'],
            SliderFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            ProgressFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            CurrencyFieldTypes: ['Number Field','Rollup Calculation','Calculated Field','Parent Field'],
            LinkFieldTypes: ['Common Field','Shared Doc Link','Route Path','Lookup','Social Lookup','Parent Field'],
            ToggleFieldTypes: ['Boolean','Parent Field'],
            OptionsFieldTypes: ['Option Set','Parent Field','Radio Group'],
            TextFieldTypes: ['Single Line Text','Common Field','Boolean','Option Set','AutoNumber Field',
                    'Number Field','Rollup Calculation','Calculated Field','Lookup','Social Lookup',
                    'Parent Field'],
            WriteupTypes: ['Multiple Lines Text']

        }
    },	
    computed:{
        AvailableCalendars(){
            return this.CalendarPresetOptions
        },
        AvailableBarGraphs(){
            return this.GraphPresetOptions
        },
        AvailableLineGraphs(){
            return this.GraphPresetOptions
        },
        EntityMethods(){
            return this.CurrentEntity && this.CurrentEntity.id === 'System Tickets' ?
            //On Roadmap Visit Forum Escalate to RapidApps 
            [{Name: 'Create Forum',Color: 'purple',Icon: 'mdi-publish'}] :
            []
            //
        },
        ActiveCols(){
            let rows = this.ComputedSections.map(sect => {
                return sect.Rows
            }).flat()
            return rows.ap(row => {
                return row.Columns
            }).flat()
        },
        ColorLibActive(){
            return this.EditedColumn && this.LibColorColumnTypes.includes(this.EditedColumn.Type) || 
            this.EditedColumn && this.LibColorColumnTypes.includes(this.EditedColumn.FieldGroupType)
        },
        FullColorLibActive(){
            return this.EditedColumn && this.FullLibColorColumnTypes.includes(this.EditedColumn.Type) || 
            this.EditedColumn && this.FullLibColorColumnTypes.includes(this.EditedColumn.FieldGroupType)
        },
        IconsActive(){
            return this.EditedColumn && this.IconColTypes.includes(this.EditedColumn.Type)
        },
        DataTableswithLookuptoCurrentEntity(){
            if(this.CurrentEntity){
                return this.SystemEntities.map(entity => {
                    let entityobject = {
                        id: entity.id
                    }
                    let oncheck = entity.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
                    if(oncheck){
                        entityobject.RelationshipField = {
                            id: oncheck.id,
                            Name: oncheck.Name,
                            DisplayName: oncheck.DisplayName,
                            Type: oncheck.Type,
                            LookupFieldName: oncheck.LookupFieldName,
                            RelationshipName: entity.DisplayName+' ('+oncheck.DisplayName+')',
                            Entityid: entity.id
                        }
                        entityobject.RelationshipName = entity.DisplayName+' ('+oncheck.DisplayName+')'
                    }
                    return entityobject
                }).filter(entity => {
                    return entity.RelationshipField
                })
            }
            else{
                return []
            }
        },
        AvailableTablesIdentifier(){
            return this.CurrentEntity ? 'RelationshipName' : 'id'
        },
        AvailableTables(){
            return this.CurrentEntity ? this.DataTableswithLookuptoCurrentEntity.map(ent => {
                return ent.RelationshipField
            }) : 
            this.SystemEntities
        },
        UserRoles(){
            return this.userLoggedIn.rolesarrayQuery
        },
        ComputedActiveCabinet(){
            let cab = ''
            if(this.ActiveCabinet){
                cab = Object.assign({},this.ActiveCabinet)
                // cab.DataArray = []
            }
            return cab
        },
        EntityDataRef(){
            return this.CurrentEntity ? 
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.Record.id) :
            ''
        },
        BaseStorageRef(){
            return this.EntityName.split(' ').join('')+'/'
        },
        EntityName(){
            let entity = this.SystemEntities.find(obj => obj.id === this.CurrentEntity.id)
            return entity.DisplayName
        },
        CabinetsbyType(){
            return this.EditedColumn && this.EditedColumn.CabinetType === 'Gallery' ? 
            this.CurrentEntity.Galleries : this.EditedColumn && this.EditedColumn.CabinetType === 'Document Register' ? 
            this.CurrentEntity.DocumentRegisters : this.EditedColumn && this.EditedColumn.CabinetType === 'SubCollection' ? 
            this.CurrentEntity.SubCollections : []
        },
        FieldGroupFieldTypes(){
                if(this.EditedColumn.FieldGroupType === 'Sliders'){
                    return this.SliderFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Progress Bars'){
                    return this.ProgressFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Currencies'){
                    return this.CurrencyFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Links'){
                    return this.LinkFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Write'){
                    return this.WriteupTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Toggles'){
                    return this.ToggleFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Options'){
                    return this.OptionsFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Standard'){
                    return this.TextFieldTypes
                }
                else if(this.EditedColumn.FieldGroupType === 'Mixed'){
                    //console.log('this.CurrentEntity',this.CurrentEntity.AllFields,this.AllFieldTypes)
                    return this.AllFieldTypes
                }
                else{
                    return []
                }
        },
        ComputedFieldGroupTypes(){
            let types = [
                'Sliders',
                'Progress Bars',
                'Currencies',
                'Links',
                'Toggles',
                'Options',
                'Standard',
                'Mixed'
            ]
            return types
        },
        ProgressCardFields(){
            return this.AllFields.filter(field => {
                return field.Type === 'Number Field' && field.IsPercentage || 
                field.Type === 'Rollup Field' && field.IsPercentage || 
                field.Type === 'Calculated Field' && field.IsPercentage || 
                field.Type === 'Rollup Calculation' && field.IsPercentage
            }).map(fieldobj => {
                return {id: fieldobj.id,FieldType: 'Progress Card',DisplayName: fieldobj.DisplayName}
            })
        },
        FieldGroupFields(){
            //EditedColumn.FieldGroupType
            //console.log('this.AllFields',this.AllFields)
            return this.AllFields.filter(field => {
                let types = this.FieldGroupFieldTypes.filter(type => {return type !== 'Parent Field' && type !== 'Common Field'})
                if(this.EditedColumn.FieldGroupType === 'Sliders'){
                    return field.Type === 'Number Field' && field.IsSlider ||
                    field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Number Field' && field.ParentFieldObj.IsSlider
                }
                else if(this.EditedColumn.FieldGroupType === 'Progress Bars'){
                    return field.Type === 'Number Field' && field.IsPercentage ||
                    field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Number Field' && field.ParentFieldObj.IsPercentage
                }
                else if(this.EditedColumn.FieldGroupType === 'Currencies'){
                    return types.includes(field.Type) && field.IsCurrency ||
                    field.Type === 'Parent Field' && field.ParentFieldObj && types.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsCurrency
                }
                else if(this.EditedColumn.FieldGroupType === 'Links'){
                    let commontypes = ['URL Link']
                    return types.includes(field.Type) || 
                    field.Type === 'Parent Field' && field.ParentFieldObj && types.includes(field.ParentFieldObj.Type) ||
                    commontypes.includes(field.CommonFieldType) || 
                    field.Type === 'Parent Field' && field.ParentFieldObj && commontypes.includes(field.ParentFieldObj.Type)
                }
                else if(this.EditedColumn.FieldGroupType === 'Toggles'){
                    return field.Type === 'Boolean'
                }
                else if(this.EditedColumn.FieldGroupType === 'Standard' || this.EditedColumn.FieldGroupType === 'Options'){
                    return types.includes(field.Type) || 
                    field.Type === 'Parent Field' && field.ParentFieldObj && types.includes(field.ParentFieldObj.Type) && field.CommonFieldType !== 'Physical Address' && 
                    field.CommonFieldType !== 'Postal Address' && field.Type !== 'Single File Upload' && field.Type !== 'Multiple Lines Text' && field.CommonFieldType !== 'QR Link'
                }
                else if(this.EditedColumn.FieldGroupType === 'Mixed'){
                    return field.CommonFieldType !== 'Physical Address' && 
                    field.CommonFieldType !== 'Postal Address'  && field.Type !== 'Single File Upload' && field.Type !== 'Multiple Lines Text' && field.CommonFieldType !== 'QR Link'
                }
                let fieldobj = Object.assign({},field)
                if(fieldobj.id.split('.')[1]){
                    fieldobj.id = fieldobj.id.split('.')[0]
                }
                return fieldobj
            })
        },
        ComputedColTypes(){
            return this.ColTypes.filter(type => {
                //these types to be added to entity later but it's social channel tagging config, on top of rollup calculations
                return this.CurrentEntity && type !== 'Counter' && type !== 'Feed'
                || type !== 'Field Card' && type !== 'Field Group'
            })
        },
        DBRef(){
            return db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        },
        EntityCollectionRef(){
            return this.CurrentEntity ? this.DBRef.collection('entities').doc(this.CurrentEntity.id) : ''
        },
        ComputedSections(){
            //console.log('this.Sections',this.Sections)
            return this.Sections.map(sectobj => {
            let sect = Object.assign({},sectobj)
              sect.Rows = sect.Rows.map(rowobj => {
              let row = Object.assign({},rowobj)
                row.Columns = row.Columns.map(colobj => {
                  let col = Object.assign({},colobj)
                  if(col.Type === 'Field Card' || col.Type === 'Progress Card'){
                    col.FieldObj = Object.assign({},this.AllFields.find(obj => obj.id === col.Field.id))
                    if(col.FieldObj && col.FieldObj.Options){
                        col.FieldObj.Options = col.FieldObj.Options.map(opt => {
                            if(opt.Color && !opt.Icon){
                                opt.Icon = this.DefaultIcon
                            }
                            return opt
                        })
                    }
                  }
                  else if(col.Type === 'Field Group'){
                    col.FieldObjects = col.Fields.map(field => {
                        let fieldobj = Object.assign({},this.AllFields.find(obj => obj.id === field))
                        if(col.FieldGroupType === 'Sliders'){
                            fieldobj.FieldType = 'SliderField'
                        }
                        else if(col.FieldGroupType === 'Progress Bars'){
                            fieldobj.FieldType = 'ProgressField'
                        }
                        else if(col.FieldGroupType === 'Currencies'){
                            fieldobj.FieldType = 'CurrencyField'
                        }
                        else if(col.FieldGroupType === 'Links'){
                            fieldobj.FieldType = this.GetFieldType(fieldobj)
                            //still missing Shared Doc Link and comps noet riggered for parent related as yet
                        }
                        else if(col.FieldGroupType === 'Options'){
                            fieldobj.FieldType = 'OptionsField'
                        }
                        else if(col.FieldGroupType === 'Toggles'){
                            fieldobj.FieldType = 'ToggleField'
                        }
                        else if(col.FieldGroupType === 'Standard' || col.FieldGroupType === 'Mixed'){
                            fieldobj.FieldType = this.GetFieldType(fieldobj)
                        }
                        return fieldobj
                    })
                  }
                  else if(col.CabinetType === 'Document Register' || col.CabinetType === 'Gallery'){
                    //console.log('col',col)
                //  this.CustomRolesDefault.map(defrole => {
                //    console.log(defrole.Prop,section.Name,section[defrole.Prop])
                //  })
                    let cab = col.CabinetType === 'Document Register' ? 
                    Object.assign({},this.CurrentEntity.DocumentRegisters.find(obj => obj.id === col.CabinetID)) :
                    Object.assign({},this.CurrentEntity.Galleries.find(obj => obj.id === col.CabinetID))
                    if(cab.CreatePermission === 'Inherited'){
                    col.CanCreate = this.CanEdit
                    }
                    else if(cab.CreatePermission === 'Custom Role'){
                    cab.CustomCreateRoles.map(role => {
                        if(this.UserRoles.includes(role)){
                        col.CanCreate = true
                        }
                    })
                    }
                    else if(cab.CreatePermission === 'Admin'){
                    col.CanCreate = this.userIsAdmin
                    }
                    if(cab.GetPermission === 'Inherited'){
                    col.CanGet = true
                    }
                    else if(cab.GetPermission === 'Custom Role'){
                    cab.CustomGetRoles.map(role => {
                        if(this.UserRoles.includes(role)){
                        col.CanGet = true
                        }
                    })
                    }
                    else if(cab.GetPermission === 'Admin'){
                    col.CanGet = this.userIsAdmin
                    }
                    if(cab.ListPermission === 'Inherited'){
                    col.CanList = true
                    }
                    else if(cab.ListPermission === 'Custom Role'){
                    cab.CustomListRoles.map(role => {
                        if(this.UserRoles.includes(role)){
                        col.CanList = true
                        }
                    })
                    }
                    else if(cab.ListPermission === 'Admin'){
                    col.CanList = this.userIsAdmin
                    }
                    if(cab.UpdatePermission === 'Inherited'){
                    col.CanEdit = this.CanEdit
                    }
                    else if(cab.UpdatePermission === 'Custom Role'){
                    cab.CustomUpdateRoles.map(role => {
                        if(this.UserRoles.includes(role)){
                        col.CanEdit = true
                        }
                    })
                    }
                    else if(cab.UpdatePermission === 'Admin'){
                    col.CanEdit = this.userIsAdmin
                    }
                    if(cab.DeletePermission === 'Inherited'){
                    if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Custom Roles'){
                        col.CanDelete = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'CanDelete']
                    }
                    else if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
                    if(this.deleteAll){
                        col.CanCreate =  true
                        }
                        else if(this.deleteUnitdown){
                        if(this.userBUChildren.includes(this.Record.Business_Unitid)){
                            col.CanCreate = true  
                            }
                        }
                        else if(this.deleteUserUnit){
                        if(this.Record.Business_Unitid === this.userBUID){
                            col.CanCreate = true  
                            }
                        }
                        else if(this.deleteOwner){
                        if(this.Record.Ownerid === this.UserRecord.id){
                            col.CanCreate = true  
                            }
                        }
                    }
                    }
                    else if(col.DeletePermission === 'Custom Role'){
                    col.CustomDeleteRoles.map(role => {
                        if(this.UserRoles.includes(role)){
                        col.CanDelete = true
                        }
                    })
                    }
                    else if(col.DeletePermission === 'Admin'){
                    col.CanDelete = this.userIsAdmin
                    }
                }
                else if(col.CabinetType === 'SubCollection'){
                    //console.log(col)
                    let subcol = this.CurrentEntity.SubCollections.find(obj => obj.id === col.Entityid)
                    col.CanCreate = this.CanEdit
                    col.CanDelete = this.CanDelete
                    col.CanEdit = this.CanEdit

                }
                  //console.log('csol',col)
                //   if(!col.FieldObj){
                //     col.FieldObj = {}
                //   }
                  return col
                }).map(col => {
                    if(col.FieldObj){
                        col.FieldObj = this.CheckandPrepLookupField(col.FieldObj)
                    }
                    if(col.FieldObjects){
                        col.FieldObjects = col.FieldObjects.map(fieldobj => {
                            fieldobj = this.CheckandPrepLookupField(fieldobj)
                            return fieldobj
                        })
                    }
                    return col
                })
                return row
              })
              return sect
            })
        },
        createAll(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createAll']
        },
        createUnitdown(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createUnitdown']
        },
        createUserUnit(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createUserUnit']
        },
        createOwner(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createOwner']
        },
        deleteAll(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
        },
        deleteAll(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
        },
        deleteUnitdown(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteUnitdown']
        },
        deleteUserUnit(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteUserUnit']
        },
        deleteOwner(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteOwner']
        },
        deleteAll(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
        },
        editAll(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editAll']
        },
        editUnitdown(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editUnitdown']
        },
        editUserUnit(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editUserUnit']
        },
        editOwner(){
        return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editOwner']
        },
        FieldCardFieldTypes(){
            return [
                'Option Set','Lookup','Social Lookup','Single File Upload','Common Field','Date','Multiple Lines Text'
            ]
        },
        FieldCardFields(){
            return this.AllFields.map(field => {
                let fieldobj = Object.assign({},field)
                if(field.Type === 'Option Set'){
                    fieldobj.FieldType = 'Option Toggle'
                }
                else if(field.Type === 'Lookup' && field.RelatedBuildID === 'Users' || field.Type === 'Social Lookup' && field.RelatedBuildID === 'Users'){
                    fieldobj.FieldType = 'User Avatar'
                }
                else if(field.Type === 'Single File Upload'){
                    console.log('fieldobj',fieldobj)
                    fieldobj.FieldType = field.FeaturedPhoto ? 'Data Image Field' : 'Data Upload File Field'
                }
                else if(field.Type === 'Common Field'){
                    fieldobj.FieldType = field.CommonFieldType === 'QR Link' ? 'QRImageField' : 
                    field.CommonFieldType === 'Physical Address' ? 'PhysicalAddressField' : 
                    field.CommonFieldType === 'Postal Address' ? 'PostalAddressField' : ''
                }
                else if(field.Type === 'Date'){
                    fieldobj.FieldType = 'Date Graphic'
                }
                else if(field.Type === 'Multiple Lines Text'){
                    fieldobj.FieldType = 'Writeup'
                }
                return {id: fieldobj.id,FieldType: fieldobj.FieldType,DisplayName: fieldobj.DisplayName}
            }).filter(field => {
                return field.FieldType
            })
        },
         FlexSizes(){
        return this.$store.state.FlexSizes
        }, 
        ComputedWindowPresets(){
            return this.WindowPresets
            .map(pres => {
                if(pres.Name === 'Default'){
                    pres.Width = window.innerWidth,
                    pres.Height = '100%'
                    pres.Dimensions = window.innerWidth+'px by '+window.innerHeight+'px'
                    let flex = this.FlexSizes.find(obj => obj.Breakpoint === this.$vuetify.breakpoint.name)
                    pres.Breakpoint = flex.Breakpoint
                    pres.FlexProp = flex.Prop
                }
                return pres
            })
            .map(sz => {
                sz.IsCurrent = false
                if(this.$vuetify.breakpoint[sz.Breakpoint]){
                    sz.IsCurrent = true
                }
                return sz
            })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        IsPrimarySite(){
      return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
    },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
    },
    beforeDestroy() {
        this.AssignCabinets(true)
    },
    watch: {
        Record: {
            handler: function(newvalue, oldvalue) {
                // console.log('newvalue',newvalue)
                // console.log('oldvalue',oldvalue)
                if(newvalue !== oldvalue && newvalue.id !== oldvalue.id){
                    if(!newvalue){
                    this.AssignCabinets(true)
                    }
                    else{
                    this.AssignCabinets()
                    }
                    
                    
                }
            },deep: true

        },
        CurrentEntity: {
            handler: function(newvalue, oldvalue) {
                // console.log('newvalue',newvalue)
                // console.log('oldvalue',oldvalue)
                if(newvalue !== oldvalue){
                    if(!newvalue){
                    this.AssignCabinets(true)
                    }
                    else if(newvalue && oldvalue && newvalue.id !== oldvalue.id){
                    this.AssignCabinets()
                    }
                    else{
                        //no need assign cab, just change to entity
                        
                    }
                    
                    
                }
            },deep: true

        },
        ActiveSections: {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    this.Sections = this.ActiveSections
                }
            },deep: true

        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
         //alert('created')
        this.Sections = this.ActiveSections
        //console.log('this.ActiveSections',this.ActiveSections,'this.Sections',this.Sections)
        if(this.CurrentEntity){
            this.AssignCabinets()
              
        }
        
    },
    methods:{
        CheckandPrepLookupField(fieldobj){
            if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID !== 'Users'){
            fieldobj.Array = this.$store.state[fieldobj.RelatedRecord.split(' ').join('')+'Array']
            if(fieldobj.IsMultiple){
                fieldobj.Array = fieldobj.Array.map(item => {                      
                        let itemobj = {
                        [fieldobj.LookupFieldName]: item[fieldobj.LookupFieldName],
                        id: item.id
                        }
                        return itemobj
                    })
                }
            }
            else if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID === 'Users'){
            fieldobj.Array = this.$store.state.UsersArray
            }
            //console.log('fieldobj',fieldobj,this.$store.state)
            return fieldobj
        },
        GoBack(){
            this.$emit('GoBack')
        },
        AssignEntityID(){
            this.EditedColumn.EntityID = this.EditedColumn.Table.Entityid
        },
        SelectAllItems(record,array,fields,prp,dial){
            if (this.IncludesAllItems(record,array,fields,prp)) {
                record[array] = []
            } else {
                record[array] = fields.map(item => {
                    if(prp){
                        return item[prp]
                    }
                    else{
                        return item
                    }
                })
            }
            if(dial){
                this[dial] = false
                setTimeout(() => {
                    this[dial] = true
                }, 50);
            }
        },
        MultiSelectionIcon(record,array,fields,prp) {
        if (this.IncludesAllItems(record,array,fields,prp)) return 'mdi-close-box'
        if (this.IncludesSomeItems(record,array,fields,prp)) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
        IncludesAllItems(record,array,fields,prp){
            return record && record[array] && record[array].length === fields.length
        },
        IncludesSomeItems(record,array,fields,prp){
            return record && record[array] && record[array].length > 0 && !this.IncludesAllItems(record,array,fields,prp)
        },
        SelectEditingType(type){
            this.EditingType = type
        },
        CancelEditingType(){
            this.EditingType = ''
        },
        ActivateElementSizingDialog(tab){
            this.EditedTab = tab
            this.SizeandPositionDialog = true
        },
        CancelSizeandPosition(){
            this.SizeandPositionDialog = false
            this.EditedTab = ''
        },
        SaveDocumentField(payload){
            this.$emit('SaveDocumentField',payload)
        },
        onPhotoUpload(payload){
            this.$emit('onPhotoUpload',payload)
        },
        SaveRecord(payload){
            this.$emit('SaveRecord',payload)
        },
        StoreSingleDashView(){
            
            
            let alltabs = this.AllTabs
            alltabs[this.ActiveEntityTabIndex].Sections = this.ComputedSections
            //console.log('alltabs',alltabs)
            this.$emit('StoreSingleDashView',alltabs)
            this.EditingDashboard = !this.EditingDashboard
            
        },
        AssignCabinets(destroy){
            //console.log('AssignCabinets',destroy)
        if(!destroy){
            this.SubCollections = this.CurrentEntity.SubCollections
            this.DocumentRegisters = this.CurrentEntity.DocumentRegisters
            this.Galleries = this.CurrentEntity.Galleries
        }
        else{
            this.SubCollections.map(cab => {
                if(cab.Snapshot){
                    cab.Snapshot();
                    delete cab.DataArray
                }
            })
            this.DocumentRegisters.map(cab => {
                if(cab.Snapshot){
                    cab.Snapshot();
                    delete cab.DataArray
                }
            })
            this.Galleries.map(cab => {
               if(cab.Snapshot){
                    cab.Snapshot();
                    delete cab.DataArray
                }
            })
            this.SubCollections = []
            this.DocumentRegisters = []
            this.Galleries = []
        }            
        },
        ActivateActiveCabinet(col){
        let array = col.CabinetType === 'Gallery' ? 
            this.Galleries : col.CabinetType === 'Document Register' ? 
            this.DocumentRegisters : col.CabinetType === 'SubCollection' ? 
            this.SubCollections : []
            let cab = array.find(obj => obj.id === col.CabinetID)
            let props = ['CanCreate','CanGet','CanList','CanEdit','CanDelete']
            props.map(prp => {
                if(typeof col[prp] !== 'undefined'){
                    cab[prp] = col[prp]
                }
            })
           if(!cab.DataArray){
            cab.DataArray = []
            if(col.CabinetType === 'Document Register'){
              
            let dbref = this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) ? this.EntityDataRef.collection('DocumentRegisters').doc(cab.id).collection('Items') : 
            this.EntityDataRef.collection(cab.id.split(' ').join('').split('_').join('').toLowerCase())
            console.log('dbref',dbref)
            this.GetCabinetDocuments(dbref,cab)
            }
            else if(col.CabinetType === 'Gallery'){
            let dbref = this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) ? this.EntityDataRef.collection('Galleries').doc(cab.id).collection('Items') : 
            this.EntityDataRef.collection(cab.id.split(' ').join('').split('_').join('').toLowerCase())
            console.log('dbref',dbref,cab)
            this.GetCabinetDocuments(dbref,cab)
            }
            this.ActiveCabinet = cab
           }
           else{
            this.ActiveCabinet = cab
           }
            
            
        },
        GetCabinetDocuments(query,elmnt){  
        elmnt.Snapshot = query.onSnapshot(res => {
            const changes = res.docChanges();
            
            
                changes.forEach(change => {
                if (change.type === 'added') {
                    elmnt.DataArray.push({
                    ...change.doc.data(),
                    id: change.doc.id
                    })
                }
                if (change.type === 'modified') {
                    let entry = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = elmnt.DataArray.find(obj => obj.id === entry.id)
                    if(oncheck){
                    let index = elmnt.DataArray.indexOf(oncheck)
                    elmnt.DataArray.splice(index,1,entry)
                    }
                }
                if (change.type === 'removed') {
                    let entry = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = elmnt.DataArray.find(obj => obj.id === entry.id)
                    if(oncheck){
                    let index = elmnt.DataArray.indexOf(oncheck)
                    elmnt.DataArray.splice(index,1)
                    }
                }
                })
        })
        },
        GetFieldType(field){
            //the below validates ParentFieldObjType but does NOT prepare it, so a "Lookup" could become "text" for parent field
            let linkcommontypes = ['URL Link']
            let linkfieldtypes = this.LinkFieldTypes.filter(type => {return type !== 'Common Field'})
            if(this.SliderFieldTypes.includes(field.Type) && field.IsSlider || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.SliderFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsSlider ){
                return 'SliderField'
            }
            else if(this.ProgressFieldTypes.includes(field.Type) && field.IsPercentage || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ProgressFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsPercentage ){
                return 'ProgressField'
            }
            else if(this.CurrencyFieldTypes.includes(field.Type) && field.IsCurrency || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.CurrencyFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj.IsCurrency ){
                return 'CurrencyField'
            }
            else if(this.ToggleFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ToggleFieldTypes.includes(field.ParentFieldObj.Type)){
                return 'ToggleField'
            }
            else if(this.WriteupTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.WriteupTypes.includes(field.ParentFieldObj.Type)){
                return 'Writeup'
            }            
            else if(this.OptionsFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.ToggleFiOptionsFieldTypeseldTypes.includes(field.ParentFieldObj.Type)){
                return 'OptionsField'
            }
            else if(linkfieldtypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && linkfieldtypes.includes(field.ParentFieldObj.Type) || 
            linkcommontypes.includes(field.CommonFieldType) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && linkcommontypes.includes(field.ParentFieldObj.CommonFieldType)){
                return field.Type === 'Route Path' ? 'RoutePathField' : 
                field.Type === 'Social Lookup' ? 'SocialLookupField' : 
                field.CommonFieldType === 'URL Link' ? 'URLLinkField' : 'LookupField'
            }
            else if(field.Type === 'Date' || 
            field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Date'){
                return 'DateField'
            }
            else if(field.Type === 'Number Field' || 
            field.Type === 'Parent Field' && field.ParentFieldObj && field.ParentFieldObj.Type === 'Number Field'){
                //by now only basic number field remaining
                return 'NumberField'
            }
            else if(this.TextFieldTypes.includes(field.Type) || 
            field.Type === 'Parent Field' && field.ParentFieldObj && this.TextFieldTypes.includes(field.ParentFieldObj.Type) && field.ParentFieldObj){
                return field.CommonFieldType === 'Email' ? 'EmailField' : field.CommonFieldType === 'Telephone Number' ? 'TelephoneNumberField' : 
                field.CommonFieldType === 'Physical Address' ? 'PhysicalAddressField' : field.CommonFieldType === 'Postal Address' ? 'PostalAddressField' : 'TextField'
            }
            else{
                return 'TextField'
            }
            //DateField NumberField Option of fucking snap....this was supposed to happen...
        },
        RowBottomMargin(tab,value){									
            if(!tab.MarginBottom){									
                tab.MarginBottom = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginBottom = tab.MarginBottom-10									
            }									
             if(value === 'up'){									
                tab.MarginBottom = tab.MarginBottom+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowTopMargin(tab,value){									
            if(!tab.MarginTop){									
                tab.MarginTop = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginTop = tab.MarginTop-10									
            }									
             if(value === 'up'){									
                tab.MarginTop = tab.MarginTop+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowLeftMargin(tab,value){									
            if(!tab.MarginLeft){									
                tab.MarginLeft = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginLeft = tab.MarginLeft-10									
            }									
             if(value === 'up'){									
                tab.MarginLeft = tab.MarginLeft+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },									
        RowRightMargin(tab,value){									
            if(!tab.MarginRight){									
                tab.MarginRight = 0									
            }									
            // let widthnumber = Number(tab.Width.split('%')[0])									
            if(value === 'down'){									
                tab.MarginRight = tab.MarginRight-10									
            }									
             if(value === 'up'){									
                tab.MarginRight = tab.MarginRight+10									
            }									
            this.TabDialog = false									
            this.TabDialog = true									
            tab.ShowComponents = false									
             setTimeout(() => {									
                tab.ShowComponents = true									
            }, 3)									
        },
        FigureMarginMethod(opt,tab,direction){
            this[opt.Method](tab,direction)
        },
        ActivateWindowPreset(item,flexind){
            this.FlexIndex = flexind
            this.WindowPreset = item
        },
        EditSection(sect,secti){
            this.EditedSection = sect
            this.EditedSectionIndex = secti
            this.NewSectionDialog = true
            this.SizeandPositionDialog = true
            this.EditedTab = sect
        },
        ConfirmDeleteSection(sect,secti){
            confirm('This will delete the element, and there would be no going back. Are you sure?') && this.ProcessDeleteSection(sect,secti)
        },
        ProcessDeleteSection(sect,secti){
            this.Sections.splice(secti,1)
            this.CancelNewSectionDialog()
        },
        CancelNewSectionDialog(){
            this.NewSectionDialog = false
            this.EditedSection = Object.assign({},this.DefaultEditedSection)
            this.EditedSectionIndex = -1
        },
        SaveNewSection(){
            if(this.EditedColumnIndex === -1){
             this.Sections.push(this.EditedSection)   
            }
            else{
                this.Sections[this.EditedSectionIndex] = Object.assign({},this.EditedSection)
            }
        },
        ActivateAddSection(){
            this.EditedSection = Object.assign({},this.DefaultEditedSection)
            this.NewSectionDialog = true
        },
        EditRow(sect,secti,row,rowi){
            this.EditedSection = Object.assign({},sect)
            this.EditedSectionIndex = secti
            this.EditedRow = row
            this.EditedRowIndex = rowi
            this.NewRowDialog = true
        },
        ConfirmDeleteRow(sect,secti,row,rowi){
            confirm('This will delete the element, and there would be no going back. Are you sure?') && this.ProcessDeleteRow(sect,secti,row,rowi)
        },
        ProcessDeleteRow(sect,secti,row,rowi){
            this.Sections[secti].Rows.splice(rowi,1)
            this.CancelNewRowDialog()
        },
        ActivateAddSectionRow(sect,secti){
            this.EditedSection = Object.assign({},sect)
            this.EditedSectionIndex = secti
            this.EditedRow = Object.assign({},this.DefaultEditedRow)
            this.NewRowDialog = true
        },
        CancelNewRowDialog(){
            this.NewRowDialog = false
            this.EditedSection = Object.assign({},this.DefaultEditedSection)
            this.EditedSectionIndex = -1
            this.EditedRow = Object.assign({},this.DefaultEditedRow)
            this.EditedRowIndex = -1
        },  
        SaveNewRow(){
            //console.log('this.Sections',this.Sections,this.EditedSectionIndex)
            if(this.EditedRowIndex === -1){
                this.Sections[this.EditedSectionIndex].Rows.push(this.EditedRow)
            }
            else{
                this.Sections[this.EditedSectionIndex].Rows[this.EditedRowIndex] = Object.assign({},this.EditedRow)
            }
           
           this.CancelNewRowDialog()
        },
        EditColumn(sect,secti,row,rowi,col,coli){
            this.EditedSection = Object.assign({},sect)
            this.EditedSectionIndex = secti
            this.EditedRow = Object.assign({},row)
            this.EditedRowIndex = rowi
            this.EditedColumn = col
            this.EditedColumnIndex = coli
            this.NewColDialog = true
        },
        ConfirmDeleteCol(sect,secti,row,rowi,col,coli){
            confirm('This will delete the element, and there would be no going back. Are you sure?') && this.ProcessDeleteCol(sect,secti,row,rowi,col,coli)
        },
        ProcessDeleteCol(sect,secti,row,rowi,col,coli){
            this.Sections[secti].Rows[rowi].Columns.splice(coli,1)
            this.CancelAddRowCol()
        },
        ActivateAddRowCol(sect,secti,row,rowi){
            this.EditedSection = Object.assign({},sect)
            this.EditedSectionIndex = secti
            this.EditedRow = Object.assign({},row)
            this.EditedRowIndex = rowi
            this.EditedColumn = Object.assign({},this.DefaultEditedCol)
            this.NewColDialog = true
        },
        CancelAddRowCol(){
            this.NewColDialog = false
            this.EditedSection = Object.assign({},this.DefaultEditedSection)
            this.EditedSectionIndex = -1
            this.EditedRow = Object.assign({},this.DefaultEditedRow)
            this.EditedRowIndex = -1
            this.EditedColumn = Object.assign({},this.DefaultEditedCol)
            this.EditedColumnIndex = -1
        },   
        SaveNewRowCol(){
            if(this.EditedColumnIndex === -1){
             this.Sections[this.EditedSectionIndex].Rows[this.EditedRowIndex].Columns.push(this.EditedColumn)   
            }
            else{
                this.Sections[this.EditedSectionIndex].Rows[this.EditedRowIndex].Columns[this.EditedColumnIndex] = Object.assign({},this.EditedColumn)
            }
           //this.EditedRow.Columns.push(this.EditedColumn)
           //console.log('this.Sections',this.Sections)
           this.CancelAddRowCol()
        },

    }
}
</script>

<style>

</style>



