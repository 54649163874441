<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
          <v-card class="my-3" width="100%"  :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
            
                          <v-card :color="tab.BGColor.hex" width="100%" :elevation="tab.HeaderElevation" :tile="tab.HeaderTile" :outlined="tab.HeaderOutlined" :shaped="tab.HeaderShaped">
                        <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                                
                                <v-card class="slightlypadded" v-if="InfoCardEdit">
                                
                                  <v-card-title>
                                    <v-text-field label="Card Name" v-model="tab.Name"></v-text-field>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-expansion-panels>
                                      <v-expansion-panel v-for="card in tab.InfoCards" :key="card.itemObjKey">
                                        <v-expansion-panel-header>
                                          {{card.Name}}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <v-list>
                                            <v-list-item>
                                              <v-text-field v-model="card.Name" label="Card Name" />
                                            </v-list-item>
                     
                                      <v-list-item v-for="chip in card.InfoChips" :key="chip.itemObjKey">
                                        <v-list-item-content>
                                          <v-text-field label="Chip Value" v-model="chip.Value"></v-text-field>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                          <v-text-field label="Chip Icon" v-model="chip.Icon"></v-text-field>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                          <v-checkbox label="Chip is Link" v-model="chip.IsLink"></v-checkbox>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                          <v-select label="Chip Link Type" v-model="chip.LinkType" :items="['Phone','Email','URL']"></v-select>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                          <v-text-field label="Chip Link Value" v-model="chip.LinkValue"></v-text-field>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>                                    
                                  </v-card-text>
                                </v-card>
                          </v-card>
                            <v-layout row wrap class="justify-center mx-3" v-for="card in tab.InfoCards" :key="card.itemObjKey">
                              <v-card  :elevation="tab.CardsElevation" :tile="tab.CardsTile" :outlined="tab.CardsOutlined" :shaped="tab.CardsShaped" :color="tab.CardsColor.hex" class="my-3" width="100%"  ripple>
                                <v-card-title  :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex"> 
                                  {{card.Name}}
                                </v-card-title>
                                <v-card-subtitle> 
                                  <v-chip outlined v-for="chip in card.InfoChips" :key="chip.itemObjKey"
                                  class="ma-2"
                                  :color="tab.ChipsOutline.hex"
                                  :text-color="tab.ChipsOutline.hex"
                                  :href="GetHrefProps(chip)"
                                  :target="chip.LinkType === 'URL' ? '_blank' : ''"
                                  
                                  >
                                    <v-avatar left>
                                      <v-icon :color="tab.FontColor.hex">{{chip.Icon}}</v-icon>
                                    </v-avatar>
                                  {{chip.Value}}
                                  </v-chip>
                                </v-card-subtitle>                  
                              </v-card>
                          </v-layout>                          
                        </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';


export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','DefaultPageFont'],
    components: {
      TitleTab
        },
    data() {
    return {     
      InfoCardEdit: false,
      InfoCardDialog: false,
      InfoCard: '', 
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
        GetHrefProps(chip){
            if(chip.IsLink){
                if(chip.LinkType === 'Email'){
                    return 'mailto:'+chip.LinkValue
                }
                else if(chip.LinkType === 'Phone'){
                    return 'tel:'+chip.LinkValue
                }
                else if(chip.LinkType === 'URL'){
                    return '//'+chip.LinkValue+' '
                }
            }
        },
      AddInfoChip(card){
         let NewInfoChip = {
           Value: '0800 570 0789',
           Icon: 'mdi-deskphone',
           IsLink: true,
           LinkType: 'Phone',
           LinkValue: '+278005700789'

         }
         card.InfoChips.push(NewInfoChip)
        //  this.InfoCardDialog = false
        //  this.InfoCardDialog = true
       },
       ActivateInfoCardDialog(card){
         
         this.InfoCardDialog = true
         this.InfoCard = card
         console.log(this.InfoCard)
       },
       PushInfoCard(content){
         if(!content.InfoCards){
           content.InfoCards = []
         }
         let NewInfoCardObject = {
           Name: 'Name',
           InfoChips: []
         }
         content.InfoCards.push(NewInfoCardObject)
        //  this.NewContentDialog = false
        //  this.NewContentDialog = true
       },
        CheckifTabhasIMG(tab){
            if(tab.HasImage){
                if(tab.IMG){
                    return  { backgroundImage: 'url(' + tab.IMG + ')' ,backgroundSize: 'cover',position: 'relative'}
                }
                else{
                  return  { backgroundImage: 'url(' + require('@/assets/RASolidA.jpeg') + ')' ,backgroundSize: 'cover',position: 'relative'}
                }
            }
            else {
                return ''
            }
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
						// 			L 0,0
						// 			L 1,0
						// 			L 1,1
						// 			C .65 .8, .35 .8, 0 1
						// 			Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: "'+this.PageHeadersFont+'", sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: "'+tabHeaderFontFamily+'", sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: "'+this.PageHeadersFont+'", sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        