
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
            <v-card :class="tab.BoxTransparency+' mx-3'" v-if="tab.ListItems" :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  
            :style="'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
             <!-- <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                /> -->
              <v-spacer></v-spacer>
              <v-switch @change="CheckInfo" v-if="EditingView" v-model="Editing" label="Edit"/>
              <span v-if="!EditingView">
              <div
                  v-for="(section, i) in tab.ListItems"
                  :key="section.itemObjKey"
                >
                  <v-card-title v-if="!Editing"
                    :style="
                      isOdd(i)
                        ? 'background: linear-gradient(300deg,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 90%)'
                        : 'background: linear-gradient(60deg,rgba(255,255,255,0) 5%,rgba(255,255,255,1) 90%)'
                    "
                    :class="
                      isOdd(i)
                        ? 'justify-end headline background links--text'
                        : 'justify-start headline background links--text'
                    "
                    >
                    {{ section.Title }}</v-card-title>
                    <v-text-field dense v-model="section.Title" v-if="Editing" label="Title"/>
                  

                  <v-divider
                    :width="isOdd(i) ? '100%' : '70%'"
                    class="grey"
                    :inset="isOdd(i)"
                  ></v-divider>
                  <v-card-text
                    style="padding: 20px"
                    v-for="child in section.Children"
                    :key="child.itemObjKey"
                  >
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <span>{{ child.SubheaderOne }}</span>
                        </v-list-item-content>
                        <v-list-item-content>
                          <span>{{ child.SubheaderTwo }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <span v-html="child.SubText"></span>
                  </v-card-text>
                </div>
              </span>
              <span v-if="EditingView">
              <v-btn v-if="Editing" @click="AddWriteupSection()" small>Add</v-btn>
                <div v-for="section in tab.ListItems" :key="section.itemObjKey">
                <v-card-title class="justify-center headline links--text" v-if="!Editing">{{section.Title}}</v-card-title>
                <v-text-field dense v-model="section.Title" v-if="Editing" label="Title"/>
                <v-spacer></v-spacer> <v-btn @click="AddWriteupSectionChild(section)" small v-if="Editing">Add</v-btn>
                    <v-divider width="70%" class="grey"></v-divider>
                <v-card-text style="padding:20px;" v-for="child in section.Children" :key="child.itemObjKey">
                    
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                               <span  v-if="!Editing">{{child.SubheaderOne}}</span>
                               <v-text-field dense v-model="child.SubheaderOne" v-if="Editing" label="Subheader One"/>
                            </v-list-item-content>
                            <v-list-item-content>
                                <span  v-if="!Editing">{{child.SubheaderTwo}}</span>
                                <v-text-field dense v-model="child.SubheaderTwo" v-if="Editing" label="Subheader Two"/>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                   <span  v-if="!Editing"  v-html="child.SubText"></span> 
                   <ContentEditable v-if="Editing" @ActivateTagLinkDialog="ActivateTagLinkDialog" :PushedElementid="'highlightwriteup-tab-'+tabindex+'-child-'+child.ID"
                   @BlurWriteup="BlurWriteup"/>
                   <!-- <v-textarea dense v-model="child.SubText" v-if="Editing" label="Sub Text"/>                  -->
                </v-card-text>
                </div>
              </span>
        </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldViewerComponent from '@/components/Database/FieldViewerComponent';
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTab';
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','EditingView'],
    components: {
        FieldViewerComponent,
        FieldValueEditerComponent,
        TitleTab,
        ContentEditable
        },
    data() {
    return {     
        Editing: false,
        ImageRecord: '',
        ImageField: '',
        PreviewMode: true, 
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      ComputedItems(){
        return this.tab.ListItems.map(itemobj => {
            let item = Object.assign({},itemobj)
            let target = 100
               //item.Value = Math.round((item.Value/target)*100) 
                let redcolor = Math.round(100-item.Value)*2.55
                let greencolor = Math.round(item.Value*2.55)                 
                item.Color = 'rgb('+redcolor+','+greencolor+',0)'
                item.Style = 'width: '+item.Value+'%; background-color: '+item.Color+';text-align: right;padding-top: 0px;padding-bottom: 0px;color: white;" class="skills html'
                return item
            })
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
      this.CheckInfo()
    },
    
    methods: {
      BlurWriteup(){
        //console.log('sure it blurs',this.tab.ListItems)
        if(this.Editing){
        this.tab.ListItems.map(section => {
          section.Children.map(child => {
          let elementid = 'highlightwriteup-tab-'+this.tabindex+'-child-'+child.ID
          //console.log('elementid',elementid)
          setTimeout(() => {
            let blogcontentelmnt = document.getElementById(elementid)
            //console.log('blogcontentelmnt',blogcontentelmnt)
            if(blogcontentelmnt){
              child.SubText = blogcontentelmnt.innerHTML
            } 
             }, 300); 
        })
        return section
        })
        }
      },
      CheckInfo(){ 
                  this.tab.ListItems.map(section => {
                    section.Children.map(child => {
                    let elementid = 'highlightwriteup-tab-'+this.tabindex+'-child-'+child.ID   
                    if(this.Editing){      
                    setTimeout(() => {
                        let blogcontentelmnt = document.getElementById(elementid)
                        if(blogcontentelmnt){
                          blogcontentelmnt.innerHTML = child.SubText
                        }
                    }, 300); 
                    }
                  })
                  })
      },
        isOdd(num) {
      return num % 2;
    },
        AddWriteupSectionChild(section){
          this.tab.LastItemID++
          let newchild = {
                    SubheaderOne: '2003-2007',
                    SubheaderTwo: 'Some Job - Some Place',
                  SubText: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.`,
                  ID: this.tab.LastItemID
                 }
        if(!section.Children){
            section.Children = []
        }
        section.Children.push(newchild)
        this.CheckInfo()
      },
      AddWriteupSection(){
        this.tab.LastItemID++
          let newsection = {
              Title: 'Work Experience',
              Children: [
                  {
                    SubheaderOne: '2003-2007',
                    SubheaderTwo: 'Some Job - Some Place',
                  SubText: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.`,
                  ID: this.tab.LastItemID
                  },
              ]
          } 
          this.tab.ListItems.push(newsection)
          this.CheckInfo()
      },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    