
<template>

    <v-timeline style="width: 100%;"
    align-top
    :dense="$vuetify.breakpoint.smAndDown"
  >
 
    <v-timeline-item
      v-for="(item, itemindex) in tab.Items"
      :key="itemindex"
      :color="item.color"
      :icon="item.icon"
      fill-dot
    
    >
      <v-card
        :class="item.BoxTransparency+' mx-3'"  :color="item.color" :elevation="item.Elevation" :tile="item.Tile" :outlined="item.Outlined" :shaped="item.Shaped" dark
        @click="OpenDialog(item,itemindex)"
      >
        <v-card-title class="title">
          {{item.Title}}
        </v-card-title>
        <v-card-text class="white text--primary">
          <p>{{item.Description}}</p>
           <!-- <ActionButtonEmbed  v-if="item.HasActionButton" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="item" :i="i" :PageID="PageID" :PageName="PageName" :ConfigOnly="true"						
                /> -->
          <v-row :class="item.ButtonAlignment">
             <v-menu nudge-right="30"								
                                            :close-on-content-click="false"                            									
                                            transition="scale-transition"                            									
                                            >	
            <template v-slot:activator="{ on }">
               <v-btn v-on="on" v-if="item.Fancy && item.HasActionButton" :dark="item.ActionBtnDark" x-large width="200" :outlined="item.Outlined" :style="item.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+item.ButonColor.hexa">
                {{item.ActionButtonName}}
            </v-btn>
             <v-btn v-on="on" v-if="!item.Fancy && item.HasActionButton"
              :dark="item.ActionBtnDark" :outlined="!item.Outlined" :style="item.Outlined || !item.ButonColor? '' : 'background-color:'+item.ButonColor.hexa">
                <!-- <a :href="tab.ActionButtonRoute" target="_blank">  {{tab.ActionButtonName}}</a> -->
                {{item.ActionButtonName}}
              </v-btn>
          <!-- <v-btn v-on="on"
          v-if="item.HasActionButton"
            :color="item.color"
             
            outlined
          >
            {{item.ActionButtonName}}
          </v-btn> -->
            </template>
             <ActionButtonConfigurationComponent :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :Name="item.Title"								
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="itemindex" :rowindex="rowindex"									
                                :EditedTab="item" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
            </v-menu>
          </v-row>
        </v-card-text>
      
      </v-card>
    </v-timeline-item>
  </v-timeline>
                            

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
// C:\RANEWApps\DNetGmailAccount\disciplenet\src\components\WebPages\ConfigComponents\ActionButtonConfiguringComponent.vue
import ActionButtonConfigurationComponent from '@/components/WebPages/ConfigComponents/ActionButtonConfiguringComponent';
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbed';

export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex'],
    components: {
      ActionButtonConfigurationComponent,
      ActionButtonEmbed
        },
    data() {
    return { 
      WindowHeight: 0,
      WindowWidth: 0,     
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
      this.WindowWidth = window.innerWidth;
      this.WindowHeight = window.innerHeight;
      window.addEventListener("resize", this.ResizeWindow);

    },
    
    methods: {
      ResizeWindow(event){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
      },
     OpenDialog(tab,tabindex){
        
        //  if(this.rowindex > -1){
             
        //      this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
        //  }
        //  else{
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex)
        //  }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        


    