<template>
<div>
  <v-card-title class="mediumoverline" style="padding-top:30px;">
                        Statement<v-spacer>
                            </v-spacer>
                    </v-card-title>
                     <v-card  class="siteconfigcard"  width="100%" v-if="ComputedRecordObj">
                    <v-card-title :class="MiniView ? 'stdfontsubtleoverline' :'mediumoverline'" style="padding:30px;">
                        {{ComputedRecordObj.Account_Name}}
                        <v-spacer>
                        </v-spacer> 
                        <v-btn dark color="warning white--text" v-if="ComputedRecordObj.Group" :to="'/Group/'+ComputedRecordObj.Group.id"
                            >View Group</v-btn>
                            <!-- two ways, if marketpalce templates exist for groups, wel show them -->
                            <v-menu              
                                top
                                offset-y>
                                <template v-slot:activator="{ on }">
                            <v-btn v-on="on" dark color="warning white--text"  v-if="MarketTemplatesActive && !ComputedRecordObj.Group && ComputedRecordObj.Account_Type.Name === 'Group Account' &&
                            AvailableGroupSubscriptionPackages.length > 0 && GroupSiteTemplates.length > 0">
                                Create Group
                            </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        Create Group
                                    </v-card-title>
                                    <v-card-text>
                                     <v-btn @click="CreateGroupFromAccount(true)" dark color="warning white--text"  v-if="ComputedRecordObj.Account_Type && ComputedRecordObj.Account_Type.Name === 'Group Account' &&
                                    AvailableGroupSubscriptionPackages.length > 0">
                                        With Template
                                    </v-btn>
                                    <v-btn outlined @click="CreateGroupFromAccount()" dark color="warning white--text"  v-if="ComputedRecordObj.Account_Type && ComputedRecordObj.Account_Type.Name === 'Group Account' &&
                                    AvailableGroupSubscriptionPackages.length > 0">
                                        No Template
                                    </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                            <v-btn @click="CreateGroupFromAccount()" dark color="warning white--text"  v-if="!MarketTemplatesActive && ComputedRecordObj.Group && ComputedRecordObj.Account_Type.Name === 'Group Account' &&
                                    AvailableGroupSubscriptionPackages.length > 0">
                                        No Template
                                    </v-btn>
                          <v-btn @click="DownloadStatementInvoice(ComputedRecordObj.Active_Statement_Invoiceid)" dark color="success" v-if="ComputedRecordObj.Group" 
                            >Download<v-icon>mdi-download</v-icon></v-btn>
                    </v-card-title>
                                        <v-card-text>
                        <v-row class="justify-space-between "
                            no-gutters style="border: 2px solid grey;border-radius: 12px;padding:25px;"
                        >
                        <!-- style="border: 2px solid grey;border-radius: 12px;padding:25px;" -->
                            <v-col :cols="MiniView ? 12 : 6" style="align-self: center;"
                            >                          
                            <v-list-item dense>
                                <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Account ID
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content v-html="ComputedRecordObj.Customer_ID" style="align-self: baseline;">
                                </v-list-item-content>
                                </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Account Name
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content v-html="ComputedRecordObj.Account_Name" style="align-self: baseline;">
                                </v-list-item-content>
                                </v-list-item>
                                
                             <v-list-item dense>
                                  <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                  Address
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content v-html="AccountInvoicing_Address" style="align-self: baseline;">
                                </v-list-item-content>
                             </v-list-item>
                            </v-col>
                            <v-col :cols="MiniView ? 12 : 6" style="align-self: flex-start;"
                            > 
                               <v-list-item dense>
                                 <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Signup Date
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content  style="align-self: baseline;">
                                    {{ComputedRecordObj.Created_On.toDate ? TimestampFormatterSTRING(ComputedRecordObj.Created_On.toDate())
                                    : TimestampFormatterSTRING(new Date(ComputedRecordObj.Created_On))}}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense v-if="ComputedRecordObj.Account_Type">
                                 <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Account Type
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content  style="align-self: baseline;">
                                    {{ComputedRecordObj.Account_Type.Name}}
                                </v-list-item-content>
                            </v-list-item>
                             <v-list-item dense v-if="ComputedRecordObj.Billing_Type">
                                 <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Billing Type
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content  style="align-self: baseline;">
                                    {{ComputedRecordObj.Billing_Type.Name}}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                 <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Total Invoiced
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content  style="align-self: baseline;">
                                    {{CurrencyFormatter(ComputedRecordObj.Total_Invoiced,$store.state.DefaultCurrency.Currency)}}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                 <v-list-item-content style="align-self: baseline;">
                                <v-list-item-title>
                                   Total Paid
                                </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content  style="align-self: baseline;">
                                    {{CurrencyFormatter(ComputedRecordObj.Total_Paid,$store.state.DefaultCurrency.Currency)}}
                                </v-list-item-content>
                            </v-list-item>
                            </v-col>
                        </v-row>
                                        </v-card-text>
                         <v-list>
                       <v-list-item v-if="!MiniView">
                           Statement Date <v-spacer>
                               </v-spacer>
                           <FieldValueEditerComponent v-for="field in StatementFilters" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="DateObject" :field="field" @onPhotoFileselected="onPhotoFileselected"
                            :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                            @UploadFileSelect="UploadFileSelect"
                                />
                       </v-list-item>
                        <v-list-item v-if="MiniView">
                           Statement 
                       </v-list-item>
                        <v-list-item v-if="MiniView">
                           <FieldValueEditerComponent v-for="field in StatementFilters" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="DateObject" :field="field" @onPhotoFileselected="onPhotoFileselected"
                            :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                            @UploadFileSelect="UploadFileSelect"
                                />
                       </v-list-item>
                       <v-list-item :class="MiniView ? 'background overline' : 'background justaboveoverline'">
                           Opening Balance <v-spacer></v-spacer>
                           {{CurrencyFormatter(StartingBalance,$store.state.DefaultCurrency.Currency)}}
                       </v-list-item>
                       <v-list-item :style="MiniView ? 'text-transform: none !important;' : ''" :class="MiniView ? 'overline' : ''">
                           <v-list-item-content>
                               Date
                           </v-list-item-content>
                        <v-list-item-content  >
                               Description
                            </v-list-item-content>
                            <v-list-item-content  style="text-align: end;">
                               <span>Amount</span>
                            </v-list-item-content>
                        <v-list-item-content style="text-align: end;">
                           <span>Ending Balance</span>
                        </v-list-item-content>
                        </v-list-item>
                       <v-list-item :class="MiniView ? 'caption' : ''" v-for="entry in DebitsandCredits" :key="entry.itemObjKey">
                           <v-list-item-content>
                            <v-list-item-subtitle  :class="MiniView ? 'caption' : ''">
                                   {{entry.DateString}}
                               </v-list-item-subtitle>
                               <v-list-item-title  :class="MiniView ? 'caption grey--text' : 'grey--text'">
                                  <span>{{CurrencyFormatter(entry.Starting_Balance,$store.state.DefaultCurrency.Currency)}}</span> (Starting)
                               </v-list-item-title>
                           </v-list-item-content>
                        <v-list-item-content  >
                               <span v-html="entry.Reference"></span>
                            </v-list-item-content>
                            <v-list-item-content  style="text-align: end;">
                               <span class="blue--text" v-if="entry.Type === 'Debit'">{{CurrencyFormatter(entry.Amount,$store.state.DefaultCurrency.Currency)}}</span>
                                <span class="green--text" v-if="entry.Type === 'Credit'">-{{CurrencyFormatter(entry.Amount,$store.state.DefaultCurrency.Currency)}}</span>
                            </v-list-item-content>
                        <v-list-item-content style="text-align: end;">
                            <span>{{CurrencyFormatter(entry.Ending_Balance,$store.state.DefaultCurrency.Currency)}}</span>
                        </v-list-item-content>
                        </v-list-item>
                       <v-list-item  :class="MiniView ? 'background overline' : 'background justaboveoverline'">
                           Ending Balance <v-spacer></v-spacer>
                            {{CurrencyFormatter(EndingBalance,$store.state.DefaultCurrency.Currency)}}
                       </v-list-item>
                   </v-list>
                     </v-card>
                     </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
export default {
    props: ['System','SystemEntities','ActiveAccount','CompanyView','CompanyRecord','CompanyBillingAccounts','SubscriptionPackages','AvailableGroupSubscriptionPackages'],
    components: {FieldValueEditerComponent},
    data() {
        return {
            CalledAccounts: [],
            AccountDebits: [],
            AccountCredits: [],
            StatementFilters: [
                {Name: 'Start_Date',DisplayName: 'Start Date',Type: 'Date'},
                {Name: 'End_Date',DisplayName: 'End Date',Type: 'Date'},
            ],
            DateObject: {},
            Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Billing_Address: {Name: 'Billing_Address',FieldBreakdown: [],DisplayName: 'Billing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
            Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
            Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
        }
    },	
    computed:{
        ActiveGroup(){
        return this.$store.state.ActiveGroup
      },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
        MarketTemplatesActive(){
          return this.PluginDataBase.Marketplace_Templates && this.PluginDataBase.Marketplace_Templates.Active
        },
        GroupSiteTemplates(){
            return this.MarketplaceTemplates.filter(temp => {
                return temp.Secondary_Category.Name === 'Group Site'
            })
        },
        MarketplaceTemplates(){
        return this.SuiteTemplateView ? this.SuiteTemplate.templates : this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        GroupSubscriptionPackage(){
        //console.log(this.GroupBillingAccount,this.SubscriptionPackages)
        if(this.ComputedRecordObj){
          return this.SubscriptionPackages.find(obj => obj.id === this.ComputedRecordObj.Subscription_Packageid)
        }
      },
        AccountInvoicing_Address(){
          return this.ComputedRecordObj ? ``+this.ComputedRecordObj.Invoicing_Address_Street_Number+` `+this.ComputedRecordObj.Invoicing_Address_Street_Name+`, `+this.ComputedRecordObj.Invoicing_Address_Suburb+`, `+this.ComputedRecordObj.Invoicing_Address_Postal_Code+`<br>
              `+this.ComputedRecordObj.Invoicing_Address_City+`, `+this.ComputedRecordObj.Invoicing_Address_State_Province+`,<br>
              `+this.ComputedRecordObj.Invoicing_Address_Country_Region : ''
      },
        ComputedRecordObj(){
            if(this.ActiveAccount){
                return this.ActiveAccount
            }
            else{
                let obj  = Object.assign({},this.CompanyRecord)
            obj.Account_Name = obj.Company_Name
            obj.Total_Paid = this.CompanyBillingAccounts.map(ba => {
                let total = Number(ba.Total_Paid)
                return total
            }).reduce((a, b) => a + b, 0)
            obj.Total_Invoiced = this.CompanyBillingAccounts.map(ba => {
                let total = Number(ba.Total_Invoiced)
                return total
            }).reduce((a, b) => a + b, 0)
            return obj
            }            
        },
        LoanAccount(){
            return this.ActiveAccount.Account_Type && this.ActiveAccount.Account_Type.Name === 'Cash Loan Account'
        },
        LoanOptionsActive(){
            //entities to follow soon
            return this.LoanAccount && this.userLoggedIn.LoanEntities && this.userLoggedIn.LoanEntities.length > 0 && this.userLoggedIn.id === this.ActiveAccount.Userid
        },
        DebitsandCredits(){
            return this.AccountDebits.concat(this.AccountCredits)
            .filter(item => {
                item.createdon = item.Date.toDate()
                //console.log(this.DateObject)
                let startfilter = ''
                let endfilter = ''
                // if(this.DateObject.Start_Date){
                // startfilter = new Date(this.DateObject.Start_Date)
                // }
                // if(this.DateObject.End_Date){
                //     endfilter = new Date(this.DateObject.End_Date)
                // }
                // console.log(startfilter,endfilter,this.DateObject.Start_Date,this.DateObject.End_Date)
                let itemdate = new Date(item.Date.toDate())
                    itemdate.setHours(0,0,0,0)
                    return this.DateObject.Start_Date && this.DateObject.End_Date ? new Date(this.DateObject.Start_Date) <= itemdate && new Date(this.DateObject.End_Date) >= itemdate : item        
            }).map(entry => {
                let date = entry.Date.toDate()
                entry.DateString = date.toDateString()
                // if(entry.Type = 'Debit'){
                //     entry.Debit = entry.Amount
                // }
                // else{
                //     entry.Credit = entry.Amount
                // }
                return entry
            })
            .sort((a, b) => {
            var key1 = a.createdon;
            var key2 = b.createdon;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
        },
        EndingBalance(){
          return this.DebitsandCredits && this.DebitsandCredits[this.DebitsandCredits.length-1] ? this.DebitsandCredits[this.DebitsandCredits.length-1].Ending_Balance : 0
      },
      StartingBalance(){
          return this.DebitsandCredits[0] ? this.DebitsandCredits[0].Starting_Balance : 0
      },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ActiveAccountRef(){
            return this.ActiveAccount && this.ActiveAccount.id ? db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.ActiveAccount.id) :  ''
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        CompanyBillingAccounts:{
         handler: function(newvalue, oldvalue) {
          //console.log('this.CompanyBillingAccounts',this.CompanyBillingAccounts)
            if(newvalue !== oldvalue){
              this.CallBillingAccounts()
            }
         },deep: true
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['InstallGroupTemplatePostFinancials']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        let today = new Date()
        let endofmonth = this.EndofMonth(today)
        let startofmonth = this.StartofMonth(today)
        if(!this.CompanyView){          
        this.GetAccountDebits(this.ActiveAccountRef.collection('debits').where('Date','<=',endofmonth).where('Date','>=',startofmonth))
        this.GetAccountCredits(this.ActiveAccountRef.collection('credits').where('Date','<=',endofmonth).where('Date','>=',startofmonth))  
        }
        else{
            // this.DateObject.Start_Date = format(startofmonth,'yyyy-MM-dd')
            // this.DateObject.End_Date = format(endofmonth,'yyyy-MM-dd')
            //console.log('this.CompanyBillingAccounts',this.CompanyBillingAccounts)
            this.CallBillingAccounts()         
        }
        this.PrepareAddressField('Company_Address')
        this.PrepareAddressField('Billing_Address')
    },
    methods:{
        CreateGroupFromAccount(seetempaltes){
            //console.log('this.MyMarketplaceTemplates',this.MarketplaceTemplates)
            
            //so yeah I believe AvailableGroupSubscriptionPackages applies, only if length > 0 then offer this button and once done, emit ActivateGroupAccountDialog, but PUSH GROUP BELOW
            //for now though let's test it without there being packages avail
            let  group = { 
                name: this.ComputedRecordObj.Account_Name,
                Description: '',
                Created_By: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                Created_Byid: this.userLoggedIn.id,
                Owner: {Full_Name: this.userLoggedIn.Full_Name, id: this.userLoggedIn.id},
                Ownerid: this.userLoggedIn.id,
                Created_On: new Date(),
                //GroupCategory: {ID: this.GroupCategory.ID,Name: this.GroupCategory.Name},
                GroupPrivacy: 'Invite Only',
                GroupPrivacyIcon: 'mdi-lock',
                GroupPublishStatus: 'Draft',
                EventsPrivacy: 'Members Only',
                ArticlesPrivacy: 'Members Only',
                BlogPrivacy: 'Members Only',
                PollsPrivacy: 'Members Only',
                MembersPrivacy: 'Members Only',
                NewsPrivacy: 'Members Only',
                ForumPrivacy: 'Members Only',
                MembersPrivacy: 'Members Only',
                MeetupsPrivacy: 'Members Only',
                TeamMembers: [this.userLoggedIn.id],
                AssignedGroupScope: [],
                Administrators: [this.userLoggedIn.id],
                // logo: this.FileURL     
                id: this.ComputedRecordObj.id     ,
                BillingAccount: {id: this.ComputedRecordObj.id, Client_Reference: this.ComputedRecordObj.Client_Reference},
                BillingAccountid: this.ComputedRecordObj.id
            }
            //need help on GroupCategory
            //console.log(group)
            //group id always equals acct id
            // let ref = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(this.ComputedRecordObj.id)
            // console.log(group,ref)
            
            if(seetempaltes){
                this.$store.commit('SetSocialItemInteractMethodProp','Site') 
                this.$store.commit('SetSocialItemInteractMethod','ActivateViewMarketplaceTemplates')
                this.$store.commit('setTemplateSecondaryCatFilter','Group Site')
                //ALSO COMMIT A VALUE THAT PREVENTS TEMPALTE ISNTALLATION setTemplateInstallationMethod
                this.$store.commit('setTemplateInstallationMethod','InstallGroupTemplatePostFinancials')
                //yeah this is great in theory but..this requires PluginandTemplateInteraciton knows the Group...
                this.$store.commit('setActiveGroup',group)
                //The issue is, the PLuginandTemplateInteraction comp looks for subscription packages on active group...so....yeah
            }
            else{
              this.$emit('ActivateGroupAccountDialog',group)  
            }
            
        },
        InstallGroupTemplatePostFinancials(payload){
            let path = payload.path
            let pagespath = payload.pagespath
            let navlistprop = payload.navlistprop
            let navlistitems = payload.navlistitems
            let navlistprops = payload.navlistprops
            let newpath = payload.newpath
            let siteobj = payload.siteobj
            let children = payload.children
            console.log(payload)
            let group = this.ActiveGroup
            group.Templateid = siteobj.Templateid
            //okay but really it's like...we need to know "group" so 
            this.$emit('ActivateGroupAccountDialog',this.ActiveGroup,payload) 
            //I guess we should clear groiup already???
            //well that's the darn thing, actually here we need to dissect the group scope and stuff..And please assign a darn Templateid to the group

            this.$store.commit('setActiveGroup','')
        },
        CallBillingAccounts(){
            this.CompanyBillingAccounts.map(ba => {
                let oncheck = this.CalledAccounts.find(obj => obj === ba.id)
                if(!oncheck){
                    this.CalledAccounts.push(ba.id)
                    let ref = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(ba.id)
                    this.GetAccountDebits(ref.collection('debits'))
                    this.GetAccountCredits(ref.collection('credits'))  
                }
                })
        },
        PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : '',
            [field.Name+'_Street_Name'] : '',
            [field.Name+'_Suburb'] : '',
            [field.Name+'_City'] : '',
            [field.Name+'_Postal_Code'] : '',
            [field.Name+'_State_Province'] : '',
            [field.Name+'_Country_Region'] : ''
        }
        //console.log(field)
        },  
        PreparePDFViewing(InvoiceData){
            let vm = this
            console.log(InvoiceData)
            let PDFHeaderObj = {
                ...InvoiceData,
                Invoice_Number: InvoiceData.id,
                Primary_Field_Name: 'Invoice_Number',
                Company_Address_Prop: 'PO_Bill_To_Address_',
                Billing_Address_Prop: 'SO_Delivery_Address_',
            }
            vm.Company_Address.FieldBreakdown.map(brd => {
            let newname = brd.Name.split('Company_Address_').join(PDFHeaderObj.Company_Address_Prop)
            let brdname = brd.Name
            if(vm.System[brdname]){
                PDFHeaderObj[brdname] = vm.System[brdname]
            }
            })
            vm.Billing_Address.FieldBreakdown.map(brd => {
            let newname = brd.Name
            let brdname = brd.Name.split('Billing_Address_').join('Invoicing_Address_')
            if(InvoiceData[brdname]){
                PDFHeaderObj[newname] = InvoiceData[brdname]
            }
            })
            
            let PDFDocLineItems = InvoiceData.Line_Items.map((lineitem,lineitemindex) => {
                lineitem.Nr = lineitemindex-1+2
                lineitem.Price = lineitem.Unit_Price
                return lineitem
            })
            

            let PDFFooterObj = {
                ...InvoiceData,                
                TotalFieldProp: 'Invoice_Total',
                TotalFieldValue: InvoiceData.Invoice_Total
            }
            //actually we DO need to calculate the subtotals and jazz from lineitems.
            //Per perhaps the point of this test is to calcualate on call. thus it shold likely move to the funciton
            //front end easiest and simpletst though so leave there for now
            let costtotals = PDFDocLineItems
            .filter(line => {
                return line.Line_Type === 'Debit' || line.Line_Type === 'Payment'
            })
            .map(line => {
                let total = Number(line.Sub_Total)
                return total
            }).reduce((a, b) => a + b, 0)
            let earningtotals = PDFDocLineItems
            .filter(line => {
                return line.Line_Type === 'Earnings'
            })
            .map(line => {
                let total = Number(line.Sub_Total)
                return total
            }).reduce((a, b) => a + b, 0)
            PDFFooterObj.Sub_Total = costtotals-earningtotals
            PDFFooterObj.Tax_Total = 0
            if(PDFFooterObj.Sub_Total > 0){
                PDFFooterObj.Tax_Total = costtotals*(PDFFooterObj.Tax_Percentage/100)
            }
            PDFFooterObj.Grand_Total = PDFFooterObj.Sub_Total+PDFFooterObj.Tax_Total
            PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
            <br>Negative Amounts means payment is due to you.`
            if(PDFFooterObj.Grand_Total > 0){
                PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
                You currently owe on this account.`
            }
            else{
                PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
                You are currently owed a payout on this account.`
            }
            PDFFooterObj.Footer_Note = PDFFooterObj.Footer_Note+`
            <br>Always bear in mind the final run at the end of your billing cycle will determine the final result.`
            vm.$store.commit('EmitMarketDialogType','View Statement Invoice')
            vm.$store.commit('setPDFFunctionName','Sales_Invoices')
            vm.$store.commit('setPDFHeaderObj',PDFHeaderObj)
            vm.$store.commit('setPDFFooterObj',PDFFooterObj)
            vm.$store.commit('setPDFDocLineItems',PDFDocLineItems)
            vm.$store.commit('setPDFDownloadOnly',true)
        },
        ViewStatementPDF(){
            let vm = this
            let doc = this.$store.state.PDFExportDoc            
            var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
            vm.$store.commit('EmitMarketDialogType','View Statement Invoice')
            vm.$store.commit('setPDFDownloadOnly',false)
            vm.$store.commit('setOrderConfirmationPDF',blobpdf)
            vm.$store.commit('setCustomProcessingDialog',false)
            vm.$store.commit('setOrderConfirmationDialog',true)
        },
        DownloadStatementInvoice(invoiceid){
            //console.log(invoiceid)
            let vm = this
            vm.$store.commit('setCustomProcessingDialogText','Calling Invoice')
            vm.$store.commit('setCustomProcessingDialog',true)
            const functions = firebase.functions();
            let payload = {
                Docid: invoiceid
            }
            const DownloadStatementInvoice = functions.httpsCallable('DownloadStatementInvoice');
            DownloadStatementInvoice(payload).then(result => {
                console.log(result)
                let InvoiceData = result.data
                InvoiceData.id = invoiceid
                InvoiceData.Invoice_Number = invoiceid
                InvoiceData.Recordid = invoiceid
                if(InvoiceData.Latest_PDF_File){
                    vm.$store.commit('setPDFDownloadOnly',true)
                    vm.$store.commit('setOrderConfirmationPDF',InvoiceData.Latest_PDF_File)
                    vm.$store.commit('setCustomProcessingDialog',false)
                    vm.$store.commit('setOrderConfirmationDialog',true)
                }
                else{
                    vm.$store.commit('setCustomProcessingDialog',false)
                    //vm.$store.commit('setCustomProcessingDialogText','Parsing PDF') 
                    vm.PreparePDFViewing(InvoiceData)   
                }
            })
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        if(sec < 10){
          sec = "0"+sec
        }
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      StartofMonth(d){
            d.setDate(1);	
            d.setHours(0,0,0,0);
            return d
        }, 
        EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },	
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        GetAccountDebits(ActiveAccountRef){
            ActiveAccountRef
            .orderBy('Date', 'asc')
            .onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    item.Type = 'Debit'
                    this.AccountDebits.push(item)
                }
                 if (change.type === 'modified') {
                    let item = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    item.Type = 'Debit'
                    let oncheck = this.AccountDebits.find(obj => obj.id === item.id)
                    if(oncheck){
                        let index = this.AccountDebits.indexOf(oncheck)
                        this.AccountDebits.splice(index,1,item)
                    }
                }
                if (change.type === "removed") {                      

                      let item = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.AccountDebits.find(obj => obj.id === item.id)
                      if(oncheck){
                        let index = this.AccountDebits.indexOf(oncheck)
                        this.AccountDebits.splice(index, 1);
                      }
                  }
                })
            })
        },
        GetAccountCredits(ActiveAccountRef){
            //console.log(startperiod,endperiod)
            ActiveAccountRef
            .orderBy('Date', 'asc')
            .onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    item.Type = 'Credit'
                    this.AccountCredits.push(item)
                }
                 if (change.type === 'modified') {
                    let item = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    item.Type = 'Credit'
                    let oncheck = this.AccountCredits.find(obj => obj.id === item.id)
                    if(oncheck){
                        let index = this.AccountCredits.indexOf(oncheck)
                        this.AccountCredits.splice(index,1,item)
                    }
                }
                if (change.type === "removed") {                      

                      let item = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.AccountCredits.find(obj => obj.id === item.id)
                      if(oncheck){
                        let index = this.AccountCredits.indexOf(oncheck)
                        this.AccountCredits.splice(index, 1);
                      }
                  }
                })
            })
        },
    }
}
</script>

<style>

</style>



