
          <template>
<v-card
      flat
      class="transparent"
      
    >

  <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          dark
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>This is the My Profile help sheet. What would you want to know?</div>
      </v-sheet>
    </v-bottom-sheet>

 <!-- <v-parallax
            height="170"
            src="@/assets/RASolidA.jpeg"
            
            >
             
            </v-parallax>    -->

  <v-layout class="justify-center" style="margin-top:40px;">
      <!-- <v-row class="justify-center"> -->
      
     <v-flex lg8 md10 >
        <v-parallax v-if="!UserData.Parallax"
            height="200"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            class="mx-3"
            >
             
            </v-parallax>   
            <v-parallax  v-if="UserData.Parallax"
            height="200"
            :src="UserData.Parallax"
            class="mx-3"            
            >
            </v-parallax>
       
          <v-avatar
          style="margin-top:-220px;"
           tile
              color="grey"
              size="164"
              class="mx-10"                     
            >
            <v-img v-if="UserData.Profile_Photo" :src="UserData.Profile_Photo"></v-img>
            <v-img v-if="!UserData.Profile_Photo"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>
          <v-row class="mb-6 mx-3 justify-center" style="margin-top:-25px;" no-gutters> 
                             
            <v-col cols="12" :xl="3" :lg="3" :md="4" :sm="4" :xs="12" class="primary">
             <v-card-title class="mediumoverline white--text">{{UserData.Full_Name}} <v-icon color="links" v-if="UserisFeaturingGroupAdmin"
            @click="EditingFeaturedMember = !EditingFeaturedMember">mdi-pencil</v-icon><v-icon color="green" v-if="EditingFeaturedMember"
            @click="SaveFeaturedMember()">mdi-content-save</v-icon></v-card-title>
             <p class="centertext grey--text mx-2 my-2">
               {{UserData.FavoriteQuote}}
              </p>
             <v-card-title class="headline white--text">Skills</v-card-title>
             <v-divider class="pop"></v-divider>
             <v-chip-group
             class="mx-3"
                    active-class="blue--text"
                    column
                >
                    <v-chip
                    v-for="tag in UserData.tags"
                    :key="tag"
                    >
                    {{ tag }}
                    </v-chip>
                </v-chip-group>
                <v-select v-if="EditingFeaturedMember" class="pop--text" item-text="Name" :rules="[$store.state.formrules.required]" v-model="UserData.tags" :items="GroupTypeTags" multiple label="Tags"></v-select>
                 <v-form ref="Enquiriesform" id="Enquiriesform">
                     <v-card class="transparent" flat dark height="100%">
                      <v-card-text style="align-self:center;">
                            <v-text-field  class="pop--text" :rules="[$store.state.formrules.required]" v-model="Name" label="Name"></v-text-field>
                            <v-text-field  class="pop--text" :rules="[$store.state.formrules.required]" v-model="Surname" label="Surname"></v-text-field>
                            <v-select  class="pop--text" :rules="[$store.state.formrules.required]" v-model="Enquiry_Type" :items="UserData.tags" multiple label="Enquiry Type" item-text="Name"></v-select>
                            <v-menu 
                            v-model="Date_Requiredmenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field  class="pop--text"
                                  label="Date Required"
                                   v-model="Date_Required"
                                  prepend-icon="mdi-calendar-month"
                                  readonly  :rules="[$store.state.formrules.required]"
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker v-model="Date_Required" @input="Date_Requiredmenu = false"></v-date-picker>
                            </v-menu>
                            <v-text-field  class="pop--text" :rules="[$store.state.formrules.required]" v-model="Quantity_Required" type="number" label='Quantity Required'></v-text-field>
                              <input
                     
                          type="file"
                          @change="onSampleFileselected($event)"
                          ref="SamplefileInputer">
                     <!--  <v-btn @click="$refs.SamplefileInputer.click()"  v-if="!selectedSampleFile" color="pop" dark>Upload Sample</v-btn> -->
                      
                    
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <v-btn color="warning" dark>Cancel</v-btn> -->
                        <v-btn color="pop" dark @click="SubmitEnquiries()">Submit</v-btn>
                      </v-card-actions>
                     </v-card>
                    </v-form>
         </v-col>
            <v-col cols="12" :xl="9" :lg="9" :md="8" :sm="8" :xs="12">
            <v-card tile  :dark="AppisDarkMode" width="100%" height="100%" class="white text-xs-center">
                  
               <v-spacer></v-spacer> <v-btn @click="AddWriteupSection()" v-if="EditingFeaturedMember" small>Add</v-btn>
                <div v-for="section in UserData.WriteupSections" :key="section.itemObjKey">
                <v-card-title class="justify-center headline links--text" v-if="!EditingFeaturedMember">{{section.Title}}</v-card-title>
                <v-text-field dense v-model="section.Title" v-if="EditingFeaturedMember" label="Title"/>
                <v-spacer></v-spacer> <v-btn @click="AddWriteupSectionChild(section)" small v-if="EditingFeaturedMember">Add</v-btn>
                    <v-divider width="70%" class="grey"></v-divider>
                <v-card-text style="padding:20px;" v-for="child in section.Children" :key="child.itemObjKey">
                    
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                               <span  v-if="!EditingFeaturedMember">{{child.SubheaderOne}}</span>
                               <v-text-field dense v-model="child.SubheaderOne" v-if="EditingFeaturedMember" label="Subheader One"/>
                            </v-list-item-content>
                            <v-list-item-content>
                                <span  v-if="!EditingFeaturedMember">{{child.SubheaderTwo}}</span>
                                <v-text-field dense v-model="child.SubheaderTwo" v-if="EditingFeaturedMember" label="Subheader Two"/>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                   <span  v-if="!EditingFeaturedMember">{{child.SubText}}</span> 
                   <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog" :PushedElementid="child.SubheaderOne"/>
                   <!-- <v-textarea dense v-model="child.SubText" v-if="EditingFeaturedMember" label="Sub Text"/>                  -->
                </v-card-text>
                </div>
               
              </v-card>
            </v-col>
            
          
          </v-row>
          
<!--       
        <v-layout class="justify-center mx-3">
        <v-card flat tile class="transparent" width="100%" style="padding-bottom:40px;"
          min-height="500">
        
      </v-card>
        </v-layout> -->
     </v-flex>
  </v-layout>
  
    

  </v-card>
</template>




<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
export default {
  props: ['AppisDarkMode','groupid','groupname','grouptype','System'],
  middleware: 'auth',
components: {ContentEditable
},
  data() {
    return {
        WriteupSections: [],
        EditingFeaturedMember: true,
        UserisFeaturingGroupAdmin: false,
        GroupType: '',
      rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
      UserData: {
          tags: [],
          WriteupSections: []
      },
      
    }
  },

  created(){
    this.IntranetViewToggle()
    if(this.userLoggedIn){
        this.GetUser()
    }
    else {
        this.RoutetoLoginPage()
          }
    console.log(this.grouptype)
    // window.addEventListener('scroll', this.handleScroll)

  },
  computed: {
      usercanEdit() {
      return this.userIsAdmin;
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    GroupCategoryTypes(){
      return this.System.Group_Categories ? this.System.Group_Categories.Options : []
    },
    GroupTypeTags(){
      let typeobj = this.GroupCategoryTypes.find(obj => obj.ID === this.GroupType.ID)
      return typeobj ? typeobj.tags : []
    },
   
    },
  methods: {
      SaveFeaturedMember(){
          let typeID = ''
          if(this.grouptype && this.grouptype.ID){
            typeID = this.grouptype.ID.toString()
          }
          else{
            let grouptype = this.$route.params.slug.split('-').join(' ')
            let type = this.GroupCategoryTypes.find(obj => obj.Name === grouptype)
            if(type){
              typeID = type.ID.toString()
            }
          }
          let groupid = this.groupid
          if(!groupid && this.UserData.groupid){
            groupid = this.UserData.groupid
          }
          console.log('this.UserData',this.UserData)
          this.UserData.userid = this.$route.params.id
          delete this.UserData.id
          db.collection('featuredgroupmembers').doc(this.$route.params.id).set({
            Disabled: false
          }).then(userdoc => {
            let featuredmember = this.UserData
            if(featuredmember.WriteupSections){
                featuredmember.WriteupSections = featuredmember.WriteupSections.map(section => {
                  section.Children.map(child => {
                  let elementid = child.SubheaderOne           
                   let blogcontentelmnt = document.getElementById(elementid)
                    if(blogcontentelmnt){
                      child.SubText = blogcontentelmnt.innerHTML
                    } 
                    return child
                })
                return section
                })
            }
             db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('featuredgroupmembers').doc(this.$route.params.id).collection('grouptypes').doc(typeID).set(featuredmember).then(doc => {
              console.log('did it')
                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(this.$route.params.id).collection('groups').doc(groupid).update({
                  Featured: true
                })
            })
          })
         
      },
      AddWriteupSectionChild(section){
          let newchild = {
                    SubheaderOne: '2003-2007',
                    SubheaderTwo: 'Some Job - Some Place',
                  SubText: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.`
                  }
        if(!section.Children){
            section.Children = []
        }
        section.Children.push(newchild)
      },
      AddWriteupSection(){
          let newsection = {
              Title: 'Work Experience',
              Children: [
                  {
                    SubheaderOne: '2003-2007',
                    SubheaderTwo: 'Some Job - Some Place',
                  SubText: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.`
                  },
              ]
          } 
          this.UserData.WriteupSections.push(newsection)
      },
    IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      
    DateFormatter(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
    },
    
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    
    
    ResetFilters() {},
    
    ResetSearch() {
      this.search = ''
    },
    
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      
      
        GetGroupInfo(groupid){
          db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(groupid).onSnapshot(groupsnap => {
                    let groupdata = groupsnap.data()
                    console.log('groupdata',groupdata,groupid)
                     this.GroupType = groupdata.GroupCategory
                    db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(this.$route.params.id).collection('groups').doc(groupid).onSnapshot(groupmembersnap => {
                      let groupmemberdata = groupmembersnap.data()
                      if(groupmemberdata && groupmemberdata.Administrator){
                          this.UserisFeaturingGroupAdmin = true                          
                      }
                    })
                    
                })
        },
        GetUser() {
          let vm = this
          //let grouptype = this.$route.query.GroupType.split('-').join(' ')
          let grouptype = this.$route.params.slug.split('-').join(' ')
          //this.$route.query.GroupType.split('-').join(' ')
          console.log(this.GroupCategoryTypes,grouptype)
          //this.$route.query.GroupType.split('-').join(' ')
          let grouptypeid = this.GroupCategoryTypes.find(obj => obj.Name === grouptype)
          let grouptypeidstring = ''+grouptypeid.ID
          if(this.groupid){
            this.GetGroupInfo(this.groupid)
          }
            
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.$route.params.id).onSnapshot(snapshot => {
            var user = snapshot.data()
            console.log(user)
            user.WriteupSections = []
            this.UserData.Full_Name = user.Full_Name
            this.UserData.Name = user.Name
            this.UserData.Surname = user.Surname
            this.UserData.id = this.$route.params.id
            this.UserData.tags = []
            if(this.groupid){
            this.UserData.groupid = this.groupid
            this.UserData.group = {id: this.groupid,name: this.groupname},
            this.UserData.grouptype = this.GroupType
            }
            
            if(user.Profile_Photo){
              this.UserData.Profile_Photo = user.Profile_Photo  
            }
            
            db.collection('featuredgroupmembers').doc(this.$route.params.id).collection('grouptypes').doc(grouptypeidstring).onSnapshot(featured => {
                
                let featuredmember = featured.data()
                if(featuredmember){
                console.log(featuredmember.tags)
                this.UserData.tags = featuredmember.tags
                if(featuredmember.WriteupSections){
                  this.UserData.WriteupSections = featuredmember.WriteupSections 
                  this.UserData.WriteupSections.map(section => {
                    section.Children.map(child => {
                    let elementid = child.SubheaderOne           
                    setTimeout(() => {
                        let blogcontentelmnt = document.getElementById(elementid)
                        if(blogcontentelmnt){
                          blogcontentelmnt.innerHTML = child.SubText
                        }
                    }, 300); 
                  })
                  })
                   
                }
                if(!this.groupid){
              this.UserData.groupid = featuredmember.groupid
              this.UserData.group = featuredmember.group
              this.UserData.grouptype = featuredmember.grouptype
              this.GetGroupInfo(this.UserData.groupid)
            }
                
                console.log(this.UserData,this.UserData.tags)    
                }
                
            })
                  
             
              
            })
          
        },
        
      
      

  }
}


</script>

<style>
/* .borderthicknobottom{
  border-top: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
}
.borderthicknotop{
  border-bottom: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
} */

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}

.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.hasBG{
background-image: 'url(' + require('@/assets/logo.png') + ')';
 background-color: #cccccc;
}
</style>
    

    