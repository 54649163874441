<template>
  <div :class="'flex xl'+12+' lg'+12+' md'+12+' sm'+12+' xs'+12"> 
     <div v-if="SingleRecordNavMenuItem.Name === 'Alerts' && GotEntities">
         
      
         <InfoSheet v-for="elmnt in HighlightedAlertElements" :key="elmnt.itemObjKey" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                :tab="elmnt"									
                />
        <!-- <InfoSheet  :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                :tab="elmnt"									
                /> -->
    </div> 
    <div v-if="SingleRecordNavMenuItem.Name !== 'Statistics' && GotEntities">
        <!-- StoredValidatorTabs {{StoredValidatorTabs}} -->
        <v-btn @click="SaveStoredValidatorTabs()">Save</v-btn>
        <v-card style="padding:20px;" :color="MatchColor(tab)" :class="tab.RowTransparency" tile  width="100%" flat  class="CodeRenders" id="PageCode"
        v-for="(tab,i) in ComputedPageTabs" :key="tab.itemObjKey" >
         <v-card-title class="text-h4 mx3">
              {{tab.DisplayName}}
            </v-card-title>
            <v-tabs centered>
                <v-tab>
                    Overview
                </v-tab>
                <v-tab-item>
                    <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                    class="mx-auto"
                    height="100%"
                    width="80%">
                
                <v-card-title class="pink--text mediumoverline">
                    Validator - {{tab.Elements.length}} Checks Done
                    </v-card-title>
                        
                        <v-layout fill-height row justify-center align-center>
                        <div class="BoldBuilderGradient" style="position: absolute;width: 150px;height: 150px;border-radius:100px;animation: spin 4   s linear infinite;">
                        </div>
                            <v-img height="100" style="position: absolute;opacity: 0.3;" contain  src="@/assets/BnPFullLogo.png">
                            </v-img>
                    <v-progress-circular style="animation: simplepulsing 1.5s infinite;"
                            :value="tab.Progress" :size="180" :width="20" class="largeoverline" :color="TabReadinessProgressColor(tab)">
                            <v-card-title :class="TabReadinessTitleColor(tab)+'--text mediumoverline'" style="text-shadow: 4px 4px rgba(0,0,0,0.16);position: absolute;margin-top:-80px;animation: simplepulsing 1.5s infinite;animation-delay: 0.15s;font-weight:500;">{{TabReadiness(tab)}}</v-card-title>
                        
                        <v-icon :color="TabReadinessTitleColor(tab)" size="100" style="text-shadow: 4px 4px rgba(0,0,0,0.16);margin-top:40px;animation: simplepulsing 1.5s infinite;">
                            {{TabReadinessIcon(tab)}}
                        </v-icon>
                        </v-progress-circular>
                        </v-layout>
                        <v-card-text>
                            <!-- <InfoSheet :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-for="elmnt in tab.Elements" :key="elmnt.itemObjKey"									
                                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                                            :tab="elmnt"									
                                            /> -->
                            <v-expansion-panels>
                                <v-expansion-panel class="my-1" :style="GetElmntReadiness(elmnt)" v-for="elmnt in tab.Elements" :key="elmnt.itemObjKey">
                                    <v-expansion-panel-header>
                                        {{elmnt.Title}}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card-text>
                                            {{elmnt.Brief}}
                                        </v-card-text>
                                       <InfoSheet :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                                            :tab="elmnt"									
                                            />
                                            
                                            <v-card-text>
                                                 <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="elmnt.SystemGuides" :tab="systemguideelmnt" />
                                            </v-card-text>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color=green>Go</v-btn>
                        </v-card-actions>
                    
                </v-card>
                </v-tab-item>
                 <v-tab>
                    <v-badge
                            color="pop"
                            
                            :content="tab.IncompleteItems.length"
                            >
                            Incomplete
                            </v-badge>
                </v-tab>
                <v-tab-item>
                     <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                    class="mx-auto my-10"
                    height="100%"
                    width="80%">
                <v-card-title class="pink--text mediumoverline">
                {{tab.DisplayName}} - Incomplete Items ({{tab.IncompleteItems.length}})
                </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel class="my-1"  v-for="item in tab.IncompleteItems" :key="item.itemObjKey">
                        <v-expansion-panel-header>
                            <v-badge
                            color="pop"
                            overlap
                            :content="item.OutstandingActions"
                            >
                            <v-icon color="blue">
                                {{item.ItemIcon}}
                                </v-icon>
                            {{item.Title}}
                            </v-badge>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                <v-list dense three-line>
                                    <v-list-item v-for="elmnt in RelevantElements(item,tab.Elements)" :key="elmnt.itemObjKey">
                                        <v-list-item-action>
                                            <v-icon>{{elmnt.Icon}}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{elmnt.Title}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="elmnt.Brief"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-icon color="green" v-if="item[elmnt.Title]">
                                               mdi-check
                                            </v-icon>
                                            <v-icon color="red" v-if="!item[elmnt.Title]">
                                                mdi-close
                                            </v-icon>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="!item[elmnt.Title]" >                                          
                                            <v-dialog max-width="1000px">
                                                <template v-slot:activator="{ on }">
                                            <v-btn dark small class="BoldBuilderGradient" v-on="on">
                                                Guides
                                            </v-btn>
                                                </template>
                                                <v-card tile flat>
                                                    <v-card-title>
                                                        {{elmnt.Title}}
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                       See below System Guides to help you set up {{item.Title}}
                                                    </v-card-subtitle>
                                                    <v-card-text>
                                                    <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="elmnt.SystemGuides" :tab="systemguideelmnt" />
                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>
                                        </v-list-item-action>
                                        <v-list-item-action v-if="item[elmnt.Title]" >
                                             <v-btn depressed disabled small>
                                                Guides
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            </v-card>
                </v-tab-item>
                <v-tab>
                    Complete
                </v-tab>
                <v-tab-item>
                    <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                    class="mx-auto my-10"
                    height="100%"
                    width="80%">
                <v-card-title class="pink--text mediumoverline">
                {{tab.DisplayName}} - Complete ({{tab.ReadyItems.length}})
                </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel class="my-1"  v-for="item in tab.ReadyItems" :key="item.itemObjKey">
                        <v-expansion-panel-header>
                           {{item.Title}}
                            <template v-slot:actions>
                                <v-icon color="blue">
                                {{item.ItemIcon}}
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>
                                <v-list dense three-line>
                                    <v-list-item  v-for="elmnt in RelevantElements(item,tab.Elements)" :key="elmnt.itemObjKey">
                                         <v-list-item-action>
                                            <v-icon>{{elmnt.Icon}}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{elmnt.Title}}</v-list-item-title>
                                            <v-list-item-subtitle v-html="elmnt.Brief"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                           <v-icon color="green" v-if="item[elmnt.Title]">
                                                mdi-check
                                            </v-icon>
                                            <v-icon color="red" v-if="!item[elmnt.Title]">
                                                mdi-close
                                            </v-icon>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            </v-card>
                </v-tab-item>
            </v-tabs>
            
            <!-- <v-layout class="my-5" row justify-center v-if="tab.ShowComponents">
                    <v-col
                        v-for="(elmnt,index) in tab.Elements"
                        :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                        cols="12"    
                        :class="elmnt.Justify+' '+ColMY(elmnt)"  
                        :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
                    > 
                
                    <v-list-item-title class="text-h6 mx-3">{{elmnt.Title}}</v-list-item-title>
                <v-list-item-subtitle class="mx-3 my-2">
                    <v-progress-linear :value="elmnt.Progress" :color="elmnt.Color"></v-progress-linear>
                    
                </v-list-item-subtitle>
                    <InfoSheet :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                    :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                    :tab="elmnt"									
                    />              
                </v-col>
            </v-layout> -->
            
           
        </v-card>
    </div>
    
    <div v-if="SingleRecordNavMenuItem.Name === 'Statistics' && GotEntities && !DrillDown" style="padding:20px;">
        <v-dialog   	
        v-model="SiteAnalyticsClearDialog"
        width="400"			
        >	
            <template v-slot:activator="{ on }">	
                <v-btn small v-on="on" >Clear Statistics</v-btn>	
            </template>
            <v-card
                class="mx-auto"
                max-width="500"
            >
                <v-card-title class="text-h6 font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="primary lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-alert
                        :border="'top'"
                        :colored-border="true"
                        :icon="'mdi-alert'"
                        :elevation="6"
                        :color="'red'"
                        
                        >
                        <v-card-text>
                            Clearing Statistics is PERMANENT and should only be used to clear illigetimate stats
                        </v-card-text>
                        </v-alert>
                        <v-select :items="StatTypes" return-object item-text="Name" v-model="ClearStatType" />
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-text>
                        There are {{ClearStatTypeCount}} Stats that will be cleared.
                        Ensure you select a Date and Time to narrow down what will be cleared
                    </v-card-text>
                    <v-card-text>
                        <v-menu			
                            class="FormFields"			
                            v-model="StatClearStartDateDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                            <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearStartDate" label="Start Date" prepend-icon="mdi-calendar-month"	clearable		
                                    readonly v-on="on"/>
                            </template>
                            <v-date-picker class="FormFields" v-model="StatClearStartDate" @input="StatClearStartDateDialog = false"></v-date-picker>	
                        </v-menu>
                         <v-menu			
                            class="FormFields"			
                            v-model="StatClearStartTimeDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                            <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearStartTime" label="Start Time" prepend-icon="mdi-clock-time-twelve-outline" clearable			
                                    readonly v-on="on"/>
                            </template>
                            <v-time-picker class="FormFields" v-model="StatClearStartTime" @input="StatClearStartTimeDialog = false"></v-time-picker>	
                        </v-menu>
                        <v-menu			
                            class="FormFields"			
                            v-model="StatClearEndDateDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                            <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearEndDate" label="End Date" prepend-icon="mdi-calendar-month"	clearable		
                                    readonly v-on="on"/>
                            </template>
                            <v-date-picker class="FormFields" v-model="StatClearEndDate" @input="StatClearEndDateDialog = false"></v-date-picker>	
                        </v-menu>
                        <v-menu			
                            class="FormFields"			
                            v-model="StatClearEndTimeDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                           <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearEndTime" label="End Time" prepend-icon="mdi-clock-time-twelve-outline"	clearable		
                                    readonly v-on="on"/>
                            </template>
                            <v-time-picker class="FormFields" v-model="StatClearEndTime" @input="StatClearEndTimeDialog = false"></v-time-picker>	
                        </v-menu>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                     <v-card-text>
                         There are {{FinalClearStatTypeCount}} Stats that will be cleared.
                            You can specify by Route
                     </v-card-text>
                     <v-card-text>
                         <v-select v-model="ClearSpecificPath" :items="ClearStatTypeItemsPaths" item-text="Page_Name" label="Route"/>
                     </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                     <v-card-text>
                     <v-alert
                        :border="'top'"
                        :colored-border="true"
                        :icon="'mdi-alert'"
                        :elevation="6"
                        :color="'red'"
                        
                        >
                        <v-card-text>
                            The clearing of {{FinalClearStatTypeCount}} will be PERMANENT. Are you sure?
                        </v-card-text>
                        </v-alert>
                     </v-card-text>
                </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn
                    :disabled="step === 1"
                    text
                    @click="step--"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :color="step === 4 ? 'error' : 'primary'"
                    depressed
                    @click="step !== 4 ? step++ : ClearStatistics()"
                >
                    {{currentContinue}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row       
            :class="'mb-6 '+StatsCounterTab.Justify"
            no-gutters
            :style="'margin-top:'+StatsCounterTab.MarginTop+'px;margin-bottom:'+StatsCounterTab.MarginBottom+'px;margin-left:'+StatsCounterTab.MarginLeft+'px;margin-right:'+StatsCounterTab.MarginRight+'px;'"            
            >  
             <v-col
                v-for="(elmnt,index) in StatsCounterTab.Elements"
                :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                cols="12"    
                :class="elmnt.Justify+' '+ColMY(elmnt)"  
                :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
            >   
                <CounterTab 
                v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog" @EmitDrillDown="EmitDrillDown"
                />
             </v-col>
        </v-row>
       
        
        <DashboardBuilder v-for="item in ComputedStatisticCharts" :key="item.itemObjKey" :SystemEntities="ValidatorSystemEntities" :PushedElmnt="item"
        />
    </div>
    <!-- <v-btn style="position: fixed;left: 60px;top: 200px;z-index:5;" small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn> -->
    <div v-if="SingleRecordNavMenuItem.Name === 'Statistics' && GotEntities && DrillDown" style="margin-top:-90px;">
      <!-- PublicWebFormSubMissions {{PublicWebFormSubMissions}} -->
      
        <v-parallax
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{DrillDownItem.Title}}</h1>
                <h4 class="headline">DrillDown</h4>
                <h5><v-btn small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
                
                </h5>                
              </v-col>
            </v-row>
            </v-parallax>
            
        <!-- Context on DrillDown? -->
       <!-- <v-btn style="position: absolute;z-index:5;" small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn> -->
       
        <DashboardBuilder :PushedRows="ComputedStatisticCharts" :SystemEntities="ValidatorSystemEntities" :PushedElmnt="DrillDownItem"
        />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import SystemGuideComponent from '@/components/WebPages/RenderComponents/SystemGuideComponent';
import DashboardElmtChart from '@/components/SuitePlugins/CustomDashboard/DashboardElmtChart';
import DashboardElmtDataContext from '@/components/SuitePlugins/CustomDashboard/DashboardElmtDataContext';
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder';
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
export default {
    props: ['System','SingleRecordNavMenuItem','ActiveDB','SystemGuides','RADB','RAApp',],
    components: {InfoSheet,SystemGuideComponent,DashboardElmtChart,DashboardElmtDataContext,DashboardBuilder,CounterTab},
    data() {
        return {
            step: 1,
            StatTypes: [
                {ID: 1000001,Name: 'Public Page Stats',ItemsProp: 'PublicPageViews'},
                {ID: 1000002,Name: 'Landing Page Stats',ItemsProp: 'LandingPageViews'},
                {ID: 1000003,Name: 'Social Page Stats',ItemsProp: 'SocialPageViews'},
            ],
            ClearStatType: '',
            StatClearStartDateDialog: false,
            StatClearStartTimeDialog: false,
            StatClearEndDateDialog: false,
            StatClearEndTimeDialog: false,
            StatClearStartDate: '',
            StatClearStartTime: '',
            StatClearEndDate: '',
            StatClearEndTime: '',
            ClearSpecificPath: '',
            SiteAnalyticsClearDialog: false,
            DrillDown: false,
            DrillDownItem: '',
            SystemUsers: [],
            SystemBusinessUnits: [],
            PublicPageViews: [],
            GroupLandingPageViews: [],
            LandingPageViews: [],
            PublicLandingPages: [],
            InternalLandingPages: [],
            SocialPageViews: [],
            colors: [									
                    'red',									
                    'pink',									
                    'purple',									
                    'deep-purple',									
                    'indigo',									
                    'blue',									
                    'light-blue',									
                    'cyan',									
                    'teal',									
                ],
            DefaultCounterElmnt: {									
                CounterIcon: 'mdi-excavator',									
                CounterInteger: 0,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Counter Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                Tile: true,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Title',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:2,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,
                // HideActions: true,								
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                IsDark: true,						
            },
           DefaultCounterTab: {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Counter',									
            Elements: [
                {}
            ],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-counter',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },
            systemguideelmnt: {			
              HideGuidesCarousel: true,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            defaulttab: {									
                Open: true,									
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Name: 'blank',	
                DisplayName: 'blank',									
                Elements: [],									
                ShowComponents: true,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                },
            GotEntities: false,
            SiteModerators: [],
            SitePages: [],
            SystemEntities: [],
            TimeLineStatsSample: {
                    HideActions: true,
                    AccumulativeGraph: true,			
                    ShowSummary: true,			
                    ShowSummaryOnly: true,			
                    HasDataContext: true,
                    DataContextStyle: 'List',
                    Sort: 'Descending (Value)',			
                    PrimaryLabelType: 'From Timeline',
                    PrimaryEntity: 'Users',
                    LookupArrays: [],
                    Data: [],
                    ChartLabelsfromTimeline: true,
                    ChartLabels: [],
                    PrimaryDateFilter: 
                        {
                            Name: 'Created_On',
                            Type: 'Date'
                        },
                    TimeFilterOption:   {			
          ID: 1000000,			
          FilterName: 'This Day (Per 2 Hrs)',			
          FilterDescription: 'When viewing, this filter returns the result of Day, by every 2 Hours',			
          GiveName: 'Per 2 Hrs',			
          Name: 'This Day',			
          StartDateVariable: 'ThisToday',			
          EndDateVariable: 'ThisTodayEnd',			
          ChartLabels: ['0AM - 2AM','2AM - 4AM','4AM - 6AM','6AM - 8AM','8AM - 10AM','10AM - 12PM','12PM - 14PM','14PM - 16PM','16PM - 18PM','18PM - 20PM','20PM - 22PM','22PM - 0AM'],			
          ChartLabelsVariables: ['ThisToday','Todayby2AM','Todayby4AM','Todayby6AM','Todayby8AM','Todayby10AM','Todayby12PM','Todayby14PM','Todayby16PM','Todayby18PM','Todayby20PM','Todayby22PM'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'Endof2Hours',			
        },				
                    ChartData: [],	
                    ChartType: 'bar',	
                    ColumnWidth: 'Full Column',	
                    HasChart: true,		
                    Title: 'New Users',			
                    Type: '',			
                    Sort: 'Descending (Value)',			
                    Description: 'Lorem Ipsum this is good!',			
                    ValueField: 'Full_Name',			
                    ValueType: 'Count',			
                    ValueCurrency: '',			
                    EntityRelatedfield: '',			
                    Entity: '',			
                    ChartBGObj: {hex: '#2196F3'},	
                    ChartPosition: 'Top',	
                    ShowSummaryOnly: true,	
                    Content: [			
                                    
                    ],	
                    Context: [
                        {
                            ContextData: []
                        }
                    ],
                    
                    // Tile: 'ad',
                    // ShowTitle: true,		
                    HasData: true,	
                    DashboardEntity: {
                        id: 'Users',
                        AllFields: [
                            {
                                Name: 'Full_Name',
                                Type: 'Single Line Text'
                            },
                            {
                                Name: 'Created_On',
                                Type: 'Date'
                            }
                        ]
                    }	
                },
            StatisticCharts: [
               {
                    HideActions: true,
                    AccumulativeGraph: true,			
                    ShowSummary: true,			
                    ShowSummaryOnly: true,			
                    HasDataContext: true,
                    DataContextStyle: 'List',
                    Sort: 'Descending (Value)',			
                    PrimaryLabelType: 'From Timeline',
                    PrimaryEntity: 'Users',
                    LookupArrays: [],
                    Data: [],
                    ChartLabelsfromTimeline: true,
                    ChartLabels: [],
                    PrimaryDateFilter: 
                        {
                            Name: 'Created_On',
                            Type: 'Date'
                        },
                    TimeFilterOption:  {			
          ID: 1000003,			
          AccumulatorID: 1000002,			
          FilterName: 'This Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month',			
          Name: 'This Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
        },			
                    ChartData: [],	
                    ChartType: 'bar',	
                    ColumnWidth: 'Full Column',	
                    HasChart: true,		
                    Title: 'New Users',			
                    Type: '',			
                    Sort: 'Descending (Value)',			
                    Description: 'Lorem Ipsum this is good!',			
                    ValueField: 'Full_Name',			
                    ValueType: 'Count',			
                    ValueCurrency: '',			
                    EntityRelatedfield: '',			
                    Entity: '',			
                    ChartBGObj: {hex: '#2196F3'},	
                    ChartPosition: 'Top',	
                    ShowSummaryOnly: true,	
                    Content: [			
                                    
                    ],	
                    Context: [
                        {
                            ContextData: []
                        }
                    ],
                    
                    // Tile: 'ad',
                    // ShowTitle: true,		
                    HasData: true,	
                    DashboardEntity: {
                        id: 'Users',
                        AllFields: [
                            {
                                Name: 'Full_Name',
                                Type: 'Single Line Text'
                            },
                            {
                                Name: 'Created_On',
                                Type: 'Date'
                            }
                        ]
                    }	
                }
            ],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,								
            },	
            SystemTickets: [],
            RoadmapItems: [],
            BasicPieChart: {
                HideActions: true,
                ChartBGObj: {hex: '#2196F3'},
                HasChart: true,			
                HasDataContext: true,			
                ShowTitle: true,	
                ChartPosition: 'Right',			
                DataContextStyle: 'List',			
                ChartType: 'pie',			
                ChartLabelsfromTimeline: false,
            },
            AppValidatorEntities: [
                {id: 'Site_Analytics',AllFields: [
                        {
                            Name: 'Path',
                            Type: 'Single Line Text',
                            Primary: true
                        },
                        {
                            Name: 'Date',
                            Type: 'Date'
                        },
                        {
                            Name: 'Page',
                            DisplayName: 'Page',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'PageName'
                        },
                        {
                            Name: 'Most_Viewed_From_Page',
                            DisplayName: 'Most Viewed From Page',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'PageName'
                        }
                    ],
                GoalMeasurements: [
                    {
                        id: 'Session_Conversions',
                        Targets: []
                    }
                ]
                },
                
                {id: 'Public_Web_Forms',AllFields: [
                        {
                            Name: 'Form_Page_Obj',
                            DisplayName: 'Form_Page_Obj',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'Page_Name'
                        },
                        {
                            Name: 'Related_Session',
                            DisplayName: 'Related Session',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Analytics',
                            LookupFieldName: 'Path'
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
                {id: 'Site_Pages',AllFields: [
                        {
                            Name: 'Page_Name',
                            DisplayName: 'Page Name',
                            Type: 'Single Line Text',
                            Primary: true,
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
                {id: 'Landing_Pages',AllFields: [
                        {
                            Name: 'Page_Name',
                            DisplayName: 'Page Name',
                            Type: 'Single Line Text',
                            Primary: true,
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
            ]
        }
    },
    computed:{
        ClearStatTypeItems(){
            let startdate = ''
            let enddate = ''
            new Date(this.StatClearStartDate)
            if(this.ClearStatType){
                let arr = this[this.ClearStatType.ItemsProp]
                if(this.StatClearStartDate && !this.StatClearStartTime){
                    startdate = new Date(this.StatClearStartDate)
                }
                else if(this.StatClearStartDate && this.StatClearStartTime){
                    let time = this.StatClearStartTime
                    let hours = time.split(':')[0]
                    let minutes = time.split(':')[1]                    
                    startdate = new Date(this.StatClearStartDate)
                    startdate.setHours(hours,minutes)
                    //console.log(hours,minutes,startdate)
                }
                if(this.StatClearEndDate && !this.StatClearEndTime){
                    enddate = new Date(this.StatClearEndDate)
                    enddate.setHours(23,59,59,999)
                }
                else if(this.StatClearEndDate && this.StatClearEndTime){
                    let time = this.StatClearEndTime
                    let hours = time.split(':')[0]
                    let minutes = time.split(':')[1]
                    enddate = new Date(this.StatClearEndDate)
                    enddate.setHours(hours,minutes)
                    //console.log(hours,minutes,enddate)
                }
                if(this.StatClearStartDate){
                    return this[this.ClearStatType.ItemsProp].filter(item => {
                        let javadate = item.Date.toDate()
                        if(this.StatClearEndDate){
                          return javadate >= startdate && javadate <= enddate  
                        }
                        else{
                            return javadate >= startdate
                        }                        
                    })
                }
                else{
                    return this[this.ClearStatType.ItemsProp].filter(item => {
                        return !item
                    })
                }
                
            }
            else{
                return []
            }
        },
        ClearStatTypeItemsPaths(){
             return this.ClearStatTypeItems.map(item => {
                    return item.Path
                })
        },
        FinalClearStatTypeItems(){
            return this.ClearStatTypeItems.filter(item => {
                if(this.ClearSpecificPath){
                    return item.Path === this.ClearSpecificPath
                }
                else{
                    return item
                }
            })
        },
        ClearStatTypeCount(){
            return this.ClearStatTypeItems.length
        },
        FinalClearStatTypeCount(){
            return this.FinalClearStatTypeItems.length
        },
        currentTitle () {
            switch (this.step) {
            case 1: return 'Which Stats'
            case 2: return 'Which Date'
            default: return 'Clear?'
            }
        },
        currentContinue () {
            switch (this.step) {
            case 4: return 'Clear'
            default: return 'Next?'
            }
        },
        PublicPagesConversions(){
            return this.PublicWebFormSubMissions
        },
        PublicConversionRate(){
            if(this.PublicWebFormSubMissions && this.PublicWebFormSubMissions.length > 0 && this.PublicPageSessions && this.PublicPageSessions.length > 0){
                let num = (this.PublicWebFormSubMissions.length/this.PublicPageSessions.length)*100
               return num.toFixed(2)+'%'
            }
            else {
                return '0%'
            }
            
        },
        WebFormEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'
            })
        },
        WebFormSubmissionsAllEntities(){
            return this.WebFormEntities.map(entity => {
                return entity.WebFormSubmissions
            }).flat()
        },
        PublicWebFormSubMissions(){
            return this.WebFormSubmissionsAllEntities.filter(submission => {
                return !submission.Created_By
            })
            .map(submission => {
                submission.Related_Session = this.PublicPageSessions.find(session => session.Submissions.includes(submission.id))
                return submission
            })
        },
        CollaborativePublicPageViews(){
            return this.PublicPageViews.concat(this.LandingPageViews)
        },
        SessionBasedPublicPageView(){
            return this.CollaborativePublicPageViews.map(item => {
                item.SessionChildren = this.CollaborativePublicPageViews.filter(obj => {return obj.PrecedingViewInstanceID === item.id})
                item.Views = item.SessionChildren.length
                return item
            })
        },
        PublicPageSessions(){
            return this.SessionBasedPublicPageView.filter(view => {
                return !view.PrecedingViewInstanceID
            })
            .map(session => {
                session.Views = 0
                session.Views = this.ForeverLoopChildren(session,'SessionChildren',session.Views)
                session.Total_Minutes = 0
                session.Total_Minutes = this.ForeverLoopChildrenTimeDiff(session,'SessionChildren','Date',session.Total_Minutes,'Minutes')
                session.Submissions = this.WebFormSubmissionsAllEntities.filter(submission => {
                    return submission.ViewInstanceID === session.id || this.GetMatchinObjectorChildren(session,'SessionChildren',session.Submissions,submission.ViewInstanceID)
                }).map(submission => {return submission.id})
                //console.log('session',session)
                return session
            })
        },
       
        ComputedPublicPageViews(){
            return this.PublicPageViews.map(view => {
                // view.Most_Viewed_From_Page = Object.assign({},view.Page_Obj)
                // console.log('view.Most_Viewed_From_Page',view.Most_Viewed_From_Page)
                //view.RouteFromMeta must match this page, MAX
                //so first filter by that which amtches most, return ITS PageBJ
                //in fact here we msut decide if we take RouteFromMeta, OR, PreceidingSession...
                return view
            })
        },
        PublicPageViewPageObjects(){
            return this.PublicPageViews.map(view => {
                return view.Page_Obj
            })
        },
        PublicLandingPageViews(){
            return this.LandingPageViews.map(item => {
                let pageobj = this.PublicLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
                if(pageobj){
                    item.Page_Obj.Page_Name = pageobj.Name
                    item.PageTabs = pageobj.PageTabs
                }
                return item
            })
            .filter(item => {
                return this.PublicLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
            })
        },
        InternalLandingPageViews(){
            return this.LandingPageViews.map(item => {
                let pageobj = this.InternalLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
                if(pageobj){
                    item.Page_Obj.Page_Name = pageobj.Name
                    item.PageTabs = pageobj.PageTabs
                }
                return item
            })
            .filter(item => {
                return this.InternalLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
            })
        },
        ValidatorSystemEntities(){
            //now we will be able to add entities such as Pages
            return this.SystemEntities.concat(this.PreparedAppValidatorEntities)
        },
        PreparedAppValidatorEntities(){
            return this.AppValidatorEntities
            // .map(entity => {
            //     if(entity.id === 'Site_Analytics'){
            //         entity.GoalMeasurements[0].Targets = this.PublicPageSessions.map(session => {
            //             let targetobj = {
            //                 StartDate = session.Date,
            //                 Target: 1,
            //             }
            //             return targetobj
            //         })
            //     }
            //     return entity
            // })
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        IncompleteSystemGuides(){
            return this.ClientSystemGuides.filter(ticket => {
                return ticket.Status && ticket.Status.Name === 'Active' && ticket.Status_Reason && ticket.Status_Reason.Name !== 'Utilized'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
                {Title: 'Incomplete System Guides',Array: 'IncompleteSystemGuides', TableName: 'System Guides', TablePath: 'SystemGuides',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        PublicLocations(){
            return this.SystemBusinessUnits.filter(bu => {
                return bu.IsPublicLocation
            })
        },
        StatsCounterTab(){
            let tab = this.DefaultCounterTab
            let elements = [
                {CounterIcon: 'mdi-face-agent',Data: this.SystemUsers,CounterInteger: this.SystemUsers.length, Title: 'Total Users',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-office-building',Data: this.SystemBusinessUnits,CounterInteger: this.SystemBusinessUnits.length, Title: 'Business Units',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-map-marker',Data: this.PublicLocations,CounterInteger: this.PublicLocations.length, Title: 'Public Locations',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-newspaper',Data: this.SocialComponents,CounterInteger: this.SocialComponents.length, Title: 'Social Components',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-tag-faces',Data: this.SiteModerators,CounterInteger: this.SiteModerators.length, Title: 'Social Moderators',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-book-open-page-variant',Data: this.SocialPages,CounterInteger: this.SocialPages.length, Title: 'Social Pages',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},                
                {CounterIcon: 'mdi-search-web',Data: this.SocialPageViews,CounterInteger: this.SocialPageViews.length, Title: 'Social Page Views',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: 'primary',
                Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Social Page Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Views by Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'User_Obj',Name: 'User_Obj',DisplayName: 'Full Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Full_Name',RelatedBuildID: 'Users'},                   
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicPages,CounterInteger: this.PublicPages.length, Title: 'Public Pages',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},},
                {CounterIcon: 'mdi-search-web',Data: this.ComputedPublicPageViews,CounterInteger: this.ComputedPublicPageViews.length, Title: 'Public Page Views',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: 'primary',
                Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Public Page Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Views by Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                // LookupArrays: [{
                //                     Field: 'Most_Viewed_From_Page',
                //                     Array: this.PublicPageViewPageObjects
                //                 }]
                },
                                
                {CounterIcon: 'mdi-search-web',Data: this.PublicLandingPageViews,CounterInteger: this.PublicLandingPageViews.length, Title: 'Public LP Views',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: 'primary',
                Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Public LP Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'By Public LP',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Landing_Pages'},           
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicPageSessions,CounterInteger: this.PublicPageSessions.length, Title: 'Public Page Sessions',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: 'primary',
                Charts: [
              
                {Preset: 'Static Timeline Filter',Title: 'Public Sessions Started',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true,
                //SublabelField:{id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages',GoalID: 'Session_Conversions'}
                },
                {Preset: 'Pie Chart',Title: 'Sessions by Start Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Page Views by Ses. Start Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: {id: 'Views',Name: 'Views',DisplayName: 'Views'},ValueType: 'Amount',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Mins per Sess. by Start Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: {id: 'Total_Minutes',Name: 'Total_Minutes',DisplayName: 'Total Mins'},ValueType: 'Amount',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                ],
                NumberFields: [{id: 'Views',Name: 'Views',DisplayName: 'Views'},{id: 'Total_Minutes',Name: 'Total_Minutes',DisplayName: 'Total Mins'}]
                // LookupArrays: [{
                //                     Field: 'Most_Viewed_From_Page',
                //                     Array: this.PublicPageViewPageObjects
                //                 }]Total_Minutes
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicWebFormSubMissions,CounterInteger: this.PublicWebFormSubMissions.length, Title: 'Public Form Submits',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Public_Web_Forms',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: 'primary',
                Charts: [
                // {Preset: 'Static Timeline Filter',Title: 'Public LP Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                // ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'By Public Session',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {Name: 'Related_Session',DisplayName: 'Related Session',Type: 'Lookup',Primary: false,LookupFieldName: 'Path',RelatedBuildID: 'Site_Analytics'},           
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicPagesConversions,CounterInteger: this.PublicConversionRate, Title: 'Submits/Session (Rate)',BGColor: {hex: 'primary'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},},
                
            ]
            tab.Elements = elements.map(elmnt => {
                // let ranint = Math.floor(Math.random() * Math.floor(this.colors.length))
                // alert(ranint+' '+this.colors[ranint])	
                let baseelmnt = Object.assign({},this.DefaultCounterElmnt)
                let requiredprops = ['BGColor','HeaderColor','FontColor','CounterIcon','CounterInteger','Title','HasCounterData','Data','Charts','HasAnalysisDrillDown',
                'ThemeColor','PrimaryEntity','AnalysisDrillDownMethod','LookupArrays','NumberFields']
                requiredprops.map(prop => {
                    if(elmnt[prop]){
                        baseelmnt[prop] = elmnt[prop]
                    }
                })
                //console.log('baseelmnt',baseelmnt)
                return baseelmnt
            })
            return tab
        },
        WebModuleItems(){
            return this.PublicPages
        },
        SocialModuleItems(){
            return this.SocialPages.concat(this.SocialComponents)
        },
        
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        }, 
        SocialComponents(){
            let array = [
                {id: 'Site_Articles',Name: 'Site Articles',DisplayName: 'Site Articles',PluralName: 'Articles',SingleName: 'Article',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Articles',HasCategories: true,CatProp: 'Article_Categories'},
                {id: 'Site_Blogs',Name: 'Site Blogs',DisplayName: 'Site Blogs',PluralName: 'Blogs',SingleName: 'Blog',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Blogs',HasCategories: true,CatProp: 'Blog_Categories'},
                {id: 'Site_Classifieds',Name: 'Site Classifieds',DisplayName: 'Site Classifieds',PluralName: 'Classifieds',SingleName: 'Classified',PageIcon: 'mdi-newspaper',Pluginid: 'Classifieds'},
                {id: 'Site_Events',Name: 'Site Events',DisplayName: 'Site Events',PluralName: 'Events',SingleName: 'Event',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Events'},
                {id: 'Site_Forums',Name: 'Site Forums',DisplayName: 'Site Forums',PluralName: 'Forums',SingleName: 'Forum',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Forums'},
                {id: 'Site_Groups',Name: 'Site Groups',DisplayName: 'Site Groups',PluralName: 'Groups',SingleName: 'Group',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Groups',HasCategories: true,CatProp: 'Group_Categories'},
                {id: 'Site_Meetups',Name: 'Site Meetups',DisplayName: 'Site Meetups',PluralName: 'Meetups',SingleName: 'Meetup',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Meetups'},
                {id: 'Site_Polls',Name: 'Site Polls',DisplayName: 'Site Polls',PluralName: 'Polls',SingleName: 'Poll',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Polls'},
            ]
            return array.filter(item => {
                return this.PluginDataBase[item.Pluginid] && this.PluginDataBase[item.Pluginid].Active
            })
        },
        SocialPages(){
            return this.SitePages.filter(page => {
                return page.PublishType !== 'Public'
            })
        },
        PublicPages(){
            return this.SitePages.filter(page => {
                return page.PublishType === 'Public'
            })
        },
        ConfigSystemid(){
            if(this.$route.meta && this.$route.meta.SystemBuilder){
                return this.$route.params.id
            }
            else{
                return this.System.id
            }  
        },
        
        ClientSystemGuides(){
            return this.SystemGuides.filter(guide => {
                if(this.$route.meta && this.$route.meta.SystemBuilder){
                    return guide
                }
                else{
                 return !guide.Provider   
                }                
            })
        },
        RASystemGuides(){
            return this.SystemGuides.filter(guide => {
                if(this.$route.meta && this.$route.meta.SystemBuilder){
                    return guide
                }
                else{
                 return guide.Provider && guide.Provider.Name === 'RapidApps'   
                }                
            })
        },
        StoredValidatorTabs(){
            let tabs = []
            tabs.push(this.StoredDataBaseModuleTab)
            tabs.push(this.StoredSocialModuleTab)
            tabs.push(this.StoredWebsiteModuleTab)
            return tabs
        },
        StoredDataBaseModuleTab(){
            let itemtab = Object.assign({},this.defaulttab)
            itemtab.DisplayName = 'Database Module'
            itemtab.Alerts = true
            let baseelements = [
                    {
                    Name: 'Data Tables have Single Forms?',
                    Check: 'SingleBuildTabs',
                    array: 'SystemEntities',
                    Brief: 'We need to check if the Data Table has Single Forms. Without Single Forms being configured it means opening a record from a Table would show a blank page.',
                    Icon: 'mdi-database',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'BU Securty roles assigned?',
                    Check: 'BU Security Roles',
                    array: 'SystemEntities',
                    Brief: 'Configuring a Data Table to a Security Structure is good preparation, but without creating security roles and assigning to a table, nobody but the Administrator would ever be able to access these tables.',
                    Icon: 'mdi-security-network',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'Custom Security Roles assigned?',
                    Check: 'Custom Security Roles',
                    array: 'SystemEntities',
                    Brief: 'Configuring a Data Table to a Security Structure is good preparation, but without creating security roles and assigning to a table, nobody but the Administrator would ever be able to access these tables.',
                    Icon: 'mdi-security-network',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'Listing Type has Tables?',
                    Check: 'Classified Table Entries',
                    array: 'ClassifiedListingEntities',
                    Brief: 'A Classified Listing type Data Table requires a Classified Table.',
                    Icon: 'mdi-table-cog',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'Listing Type has NavLists?',
                    Check: 'Classified NavList',
                    array: 'ClassifiedListingEntities',
                    Brief: 'A Classified Listing type Data Table requires a Classified Navigational List in order to be viewable from Pages.',
                    Icon: 'mdi-menu',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'Listing Type has Combined Title?',
                    Check: 'Classified Combined Title',
                    array: 'ClassifiedListingEntities',
                    Brief: 'A Classified Listing type Data Table requires a Combined Title in order for the page link to have a conventional name, e.g. Vehicle/Mercedes-Benx-C180_2 as opposed to Vehicle/sLN8ibP71ojJa77lFNnE.',
                    Icon: 'mdi-format-title',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'Wiki Type has Tables?',
                    Check: 'Wiki Table Entries',
                    array: 'WikiEntities',
                    Brief: 'A Wiki Entity requires a Wiki Table.',
                    Icon: 'mdi-table-cog',
                    SystemGuides: this.RASystemGuides
                },               
                    
                ]
            itemtab.Elements = baseelements.map(item => {
                let object = Object.assign({},this.elmnt)
                object.Title = item.Name
                object.Brief = item.Brief
                object.Icon = item.Icon
                object.SystemGuides = [item.SystemGuides[0]]
                object.Check = item.Check
                object.array = item.array
                return object
            })
            return itemtab
        },
        StoredWebsiteModuleTab(){
            let itemtab = Object.assign({},this.defaulttab)
            itemtab.DisplayName = 'Website Module'
            itemtab.WebsiteModule = true
            itemtab.Alerts = true
            let baseelements = [
                {
                    Name: 'Website has Default Landing Page?',
                    Check: 'Website Landing Page',
                    array: '',
                    Brief: 'We need to add a Default Landing Page when toggling to the Social Module within the App.',
                    Icon: 'mdi-page-next-outline',
                    SystemGuides: this.RASystemGuides
                }, 
                {
                    Name: 'Web Pages have Tabs?',
                    Check: 'WebPageTabs',
                    array: 'PublicPages',
                    Brief: 'We to add Tabs for a Page or else it will display as empty.',
                    Icon: 'mdi-page-next-outline',
                    SystemGuides: this.RASystemGuides
                },     
                {
                    Name: 'Web Pages are on Menu?',
                    Check: 'Web Pages on Menu',
                    array: 'PublicPages',
                    Brief: 'We need to add Tabs Social Pages on the menu, OR mark them as "Non-menu" items.',
                    Icon: 'mdi-menu',
                    SystemGuides: this.RASystemGuides
                }, 
                    
                ]
            
            itemtab.Elements = baseelements.map(item => {
                let object = Object.assign({},this.elmnt)
                object.Title = item.Name
                object.Brief = item.Brief
                object.Icon = item.Icon
                object.SystemGuides = [item.SystemGuides[0]]
                object.Check = item.Check
                if(item.array){
                object.array = item.array
                }
                return object
            })
            return itemtab
        },
        StoredSocialModuleTab(){
            let itemtab = Object.assign({},this.defaulttab)
            itemtab.DisplayName = 'Social Module'
            itemtab.SocialModule = true
            itemtab.Alerts = true
            let baseelements = [
                {
                    Name: 'Social Module has Default Landing Page?',
                    Check: 'Social Module Landing Page',
                    array: '',
                    Brief: 'We need to add a Default Landing Page when toggling to the Social Module within the App.',
                    Icon: 'mdi-page-next-outline',
                    SystemGuides: this.RASystemGuides
                }, 
                {
                    Name: 'Social Pages have Tabs?',
                    Check: 'SocialPageTabs',
                    array: 'SocialPages',
                    Brief: 'We to add Tabs for a Page or else it will display as empty.',
                    Icon: 'mdi-page-next-outline',
                    SystemGuides: this.RASystemGuides
                },     
                {
                    Name: 'Social Pages are on Menu?',
                    Check: 'Social Pages on Menu',
                    array: 'SocialPages',
                    Brief: 'We need to add Tabs Social Pages on the menu, OR mark them as "Non-menu" items.',
                    Icon: 'mdi-menu',
                    SystemGuides: this.RASystemGuides
                }, 
                {
                    Name: 'Component Internal Moderators Assigned?',
                    Check: 'Internal Moderators Assigned',
                    array: 'SocialComponents',
                    Brief: 'We need to assign Moderators who can create Social Component Entries.',
                    Icon: 'mdi-page-next-outline',
                    SystemGuides: this.RASystemGuides
                },
                {
                    Name: 'Component Categories Created?',
                    Check: 'Social Component Categories',
                    array: 'SocialComponents',
                    Brief: 'We need to create Categories that can be assigned when creating Social Component Entries.',
                    Icon: 'mdi-page-next-outline',
                    SystemGuides: this.RASystemGuides
                }, 
              
                       
                    
                ]
                
            itemtab.Elements = baseelements.map(item => {
                let object = Object.assign({},this.elmnt)
                object.Title = item.Name
                object.Brief = item.Brief
                object.Icon = item.Icon
                object.SystemGuides = [item.SystemGuides[0]]
                object.Check = item.Check
                if(item.array){
                object.array = item.array
                }
                return object
            })
            return itemtab
        },
        ComputedPageTabs(){
            let tabs = this.StoredValidatorTabs
                let websitemoduletab = this.StoredWebsiteModuleTab
                let webmodstd = websitemoduletab.Elements.map(object => {
                    object = this.GetTableProgress(object)
                if(object.Progress === 100){
                        object.Color = 'green'
                        object.Description = `All in Good Standing<p>Ready to Use</p>`
                        object.AlertIcon = 'mdi-cloud-check'
                    }
                    else if(object.Progress >= 50){
                        object.Color = 'warning'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else if(object.Complete === 0){
                        object.Color = 'red'
                        object.Description = `NONE in Good Standing <p class="red--text">All `+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else{
                        object.Color = 'red'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }    
                    return object
                })
                
                let websitemoduletablistlength = webmodstd.length
                websitemoduletab.Total = websitemoduletablistlength*100
                //ReadyItmes are items that are not Incomplete on ANY of the elements
                //BUT we first need to know the items, get them unique array
                //but we got systementities do we not?
                //now loop through systementities, and do all check again?
                
                websitemoduletab.AllItems = JSON.parse(JSON.stringify(this.WebModuleItems))
                //console.log('this.WebModuleItems',this.WebModuleItems)
                websitemoduletab.AllItems.map(item => {
                    item.OutstandingActions = 0
                    webmodstd.map(elmnt => {
                        let ValidationRule = elmnt.Title
                        let oncheck = elmnt.AllItems.find(obj => obj.id === item.id)
                        //console.log('ValidationRule',ValidationRule,item.id,oncheck,elmnt.IncompleteItems)
                        if(oncheck){
                            
                            let incompletecheck = elmnt.IncompleteItems.find(obj => obj.id === item.id)
                            if(incompletecheck){
                                item.Incomplete = true
                                item[ValidationRule] = false
                                item.OutstandingActions++
                            }
                            else{
                                item[ValidationRule] = true
                            }    
                        }
                        else{
                            item[ValidationRule] = 'N/A'
                        }
                    })
                    item.Title = item.Name
                    item.ItemIcon = item.PageIcon
                    return item
                })
                websitemoduletab.ReadyItems = websitemoduletab.AllItems.filter(item => {return !item.Incomplete})
                websitemoduletab.Progress = webmodstd.map(item => {return item.Progress}).reduce((a, b) => a + b, 0)/websitemoduletablistlength
                websitemoduletab.IncompleteItems = websitemoduletab.AllItems.filter(item => {return item.Incomplete})
                websitemoduletab.Incomplete = webmodstd.map(item => {return item.IncompletePercentage}).reduce((a, b) => a + b, 0)/websitemoduletablistlength


                let socialmoduletab = this.StoredSocialModuleTab
                let socialmodstd = socialmoduletab.Elements.map(object => {
                    object = this.GetTableProgress(object)
                if(object.Progress === 100){
                        object.Color = 'green'
                        object.Description = `All in Good Standing<p>Ready to Use</p>`
                        object.AlertIcon = 'mdi-cloud-check'
                    }
                    else if(object.Progress >= 50){
                        object.Color = 'warning'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else if(object.Complete === 0){
                        object.Color = 'red'
                        object.Description = `NONE in Good Standing <p class="red--text">All `+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else{
                        object.Color = 'red'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }    
                    return object
                })
                
                let socialmoduletablistlength = socialmodstd.length
                socialmoduletab.Total = socialmoduletablistlength*100
                //ReadyItmes are items that are not Incomplete on ANY of the elements
                //BUT we first need to know the items, get them unique array
                //but we got systementities do we not?
                //now loop through systementities, and do all check again?
                
                socialmoduletab.AllItems = JSON.parse(JSON.stringify(this.SocialModuleItems))
                //console.log('this.SocialModuleItems',this.SocialModuleItems)
                socialmoduletab.AllItems.map(item => {
                    item.OutstandingActions = 0
                    socialmodstd.map(elmnt => {
                        let ValidationRule = elmnt.Title
                        let oncheck = elmnt.AllItems.find(obj => obj.id === item.id)
                        //console.log('ValidationRule',ValidationRule,item.id,oncheck,elmnt.IncompleteItems)
                        if(oncheck){
                            
                            let incompletecheck = elmnt.IncompleteItems.find(obj => obj.id === item.id)
                            if(incompletecheck){
                                item.Incomplete = true
                                item[ValidationRule] = false
                                item.OutstandingActions++
                            }
                            else{
                                item[ValidationRule] = true
                            }    
                        }
                        else{
                            item[ValidationRule] = 'N/A'
                        }
                    })
                    item.Title = item.Name
                    item.ItemIcon = item.PageIcon
                    return item
                })
                socialmoduletab.ReadyItems = socialmoduletab.AllItems.filter(item => {return !item.Incomplete})
                socialmoduletab.Progress = socialmodstd.map(item => {return item.Progress}).reduce((a, b) => a + b, 0)/socialmoduletablistlength
                socialmoduletab.IncompleteItems = socialmoduletab.AllItems.filter(item => {return item.Incomplete})
                socialmoduletab.Incomplete = socialmodstd.map(item => {return item.IncompletePercentage}).reduce((a, b) => a + b, 0)/socialmoduletablistlength
                
            // if(this.SingleRecordNavMenuItem){
                let datatabletab = this.StoredDataBaseModuleTab
                
                let datatablestd = datatabletab.Elements.map(object => {
                    object = this.GetTableProgress(object)
                if(object.Progress === 100){
                        object.Color = 'green'
                        object.Description = `All in Good Standing<p>Ready to Use</p>`
                        object.AlertIcon = 'mdi-cloud-check'
                    }
                    else if(object.Progress >= 50){
                        object.Color = 'warning'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else if(object.Complete === 0){
                        object.Color = 'red'
                        object.Description = `NONE in Good Standing <p class="red--text">All `+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }
                    else{
                        object.Color = 'red'
                        object.Description = ``+object.Complete+` Ready to use.<p class="red--text">`+object.Incomplete+` need action.</p>`
                        object.AlertIcon = 'mdi-alert'
                    }    
                    return object
                })
                
                let datatabletablistlength = datatablestd.length
                datatabletab.Total = datatabletablistlength*100
                //ReadyItmes are items that are not Incomplete on ANY of the elements
                //BUT we first need to know the items, get them unique array
                //but we got systementities do we not?
                //now loop through systementities, and do all check again?
                //console.log('this.SystemEntities',this.SystemEntities)
                datatabletab.AllItems = JSON.parse(JSON.stringify(this.SystemEntities))
                datatabletab.AllItems.map(item => {
                    item.OutstandingActions = 0
                    datatablestd.map(elmnt => {
                        let ValidationRule = elmnt.Title
                        let oncheck = elmnt.AllItems.find(obj => obj.id === item.id)
                        if(oncheck){
                            let incompletecheck = elmnt.IncompleteItems.find(obj => obj.id === item.id)
                            if(incompletecheck){
                                item.Incomplete = true
                                item[ValidationRule] = false
                                item.OutstandingActions++
                            }
                            else{
                                item[ValidationRule] = true
                            }    
                        }
                        else{
                            item[ValidationRule] = 'N/A'
                        }
                        // object.AllItems
                        
                        
                    })
                    item.Title = item.DisplayName
                    item.ItemIcon = item.Table_Icon
                    return item
                })
                datatabletab.ReadyItems = datatabletab.AllItems.filter(item => {return !item.Incomplete})
                datatabletab.Progress = datatablestd.map(item => {return item.Progress}).reduce((a, b) => a + b, 0)/datatabletablistlength
                datatabletab.IncompleteItems = datatabletab.AllItems.filter(item => {return item.Incomplete})
                datatabletab.Incomplete = datatablestd.map(item => {return item.IncompletePercentage}).reduce((a, b) => a + b, 0)/datatabletablistlength
                // tabs.push(datatabletab)
            return tabs.filter(tab => {
             
                return tab[this.SingleRecordNavMenuItem.Name.split(' ').join('')]
            })
        },
        ClassifiedListingEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            })
        },
        WikiEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Has_Wiki
            })
        },
        ComputedStatisticCharts(){
            return this.DrillDownItem? this.DrillDownItem.Charts
            .map(chart => {
                //console.log('this.DrillDownItem',this.DrillDownItem)
                let chartobj = ''
                if(chart.Preset === 'Static Timeline Filter'){
                    chartobj = Object.assign({},this.TimeLineStatsSample)
                   // chartobj.PrimaryEntity = chart.PrimaryEntity
                    chartobj.PrimaryDateFilter = chart.PrimaryDateFilter
                    chartobj.DashboardEntity = chart.DashboardEntity
                    chartobj.ValueField = chart.ValueField
                    chartobj.ValueType = chart.ValueType
                    chartobj.ValueCurrency = chart.ValueCurrency
                    chartobj.ChartType = chart.ChartType
                    chartobj.ColumnWidth = chart.ColumnWidth
                    chartobj.HasChart = chart.HasChart
                    chartobj.Title = chart.Title 
                }
                 else if(chart.Preset === 'Pie Chart'){
                    chartobj = Object.assign({},this.BasicPieChart)
                    chartobj.PrimaryLabelType = chart.PrimaryLabelType
                    //chartobj.PrimaryEntity = chart.PrimaryEntity
                    chartobj.PrimaryDateFilter = chart.PrimaryDateFilter
                    // chartobj.DashboardEntity = chart.DashboardEntity
                    chartobj.ValueField = chart.ValueField
                    chartobj.ValueType = chart.ValueType
                    chartobj.ValueCurrency = chart.ValueCurrency
                    chartobj.ChartType = chart.ChartType
                    chartobj.ColumnWidth = chart.ColumnWidth
                    chartobj.HasChart = chart.HasChart
                    chartobj.Title = chart.Title
                    if(chart.RelatedBuildID){
                        chartobj.RelatedBuildID = chart.RelatedBuildID
                    }
                    
                 }
                 if(chart.SublabelField){
                     chartobj.SublabelField = chart.SublabelField
                 }
                 if(chart.PrimaryLabelField){
                        chartobj.PrimaryLabelField = chart.PrimaryLabelField
                    }
                    if(chart.PrimaryFilterType){
                        chartobj.PrimaryFilterType = chart.PrimaryFilterType
                    }
                     if(chart.PrimaryFilterField){
                        chartobj.PrimaryFilterField = chart.PrimaryFilterField
                    }
                    if(chart.ConfigName){
                        chartobj.ConfigName = chart.ConfigName
                    }
                if(this.DrillDownItem.Name === 'Counter Single'){
                chartobj.Data = this.DrillDownItem.Data
                }
                console.log('chartobj',chartobj)
                //later advanced
                // EntityRelatedfield: '',			
                // Entity: '',
                
                return chartobj
            }) 
            : this.StatisticCharts.map(elmnt => {
                elmnt = this.PrepareElementComponents(elmnt)
                return elmnt
            })
            .map(elmnt => {
                if(elmnt.DashboardEntity.id === 'Users'){
                    elmnt.Data = this.$store.state.UsersArray
                   // console.log('elmnt.FullArray',elmnt.Data)
                }
                return elmnt
            })
           
        },
        ComputedDashboardElements(){
            let row = {
                Elements: this.ComputedStatisticCharts,
                Type: 'Row'
            }
            return row
        },
    },
    
    created(){
        //console.log(this.ActiveDB)
        let systemquery = this.ActiveDB.collection('SystemConfig').doc(this.ConfigSystemid)
        //console.log(systemquery)        
        this.GetArrayData(this.ActiveDB.collection('roadmapitems'),'RoadmapItems')
        this.GetArrayData(this.ActiveDB.collection('systemtickets'),'SystemTickets')
        this.GetArrayData(this.ActiveDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users'),'SystemUsers')
        this.GetArrayData(this.ActiveDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits'),'SystemBusinessUnits')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','in',[1028,5001]).where('Type','==','public').where('PublicUser','==',true),'PublicPageViews')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','==',1027).where('Type','==','internal').where('SignedInUser','==',true),'SocialPageViews')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','==',1032).where('Type','==','public').where('PublicUser','==',true),'LandingPageViews')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','==',1015).where('Type','==','public').where('PublicUser','==',true),'GroupLandingPageViews')
        this.GetSiteModerators(this.ActiveDB.collection('sitemoderators'))
        this.GetSitePages(systemquery.collection('Pages'))
        this.GetArrayData(db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('landingpages').where('PublishType','==','Public'),'PublicLandingPages')
        this.GetArrayData(db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('landingpages').where('PublishType','!=','Public'),'InternalLandingPages')
        this.GetSystemEntities(systemquery.collection('entities')).then(result => {
            this.GotEntities = true
          })
    },
    methods:{
        CancelClearStats(){
            this.StatClearStartDateDialog = false            
            this.ClearStatType = ''
            this.ClearSpecificPath = ''
            this.StatClearStartDate = ''
            this.StatClearStartTime = ''
            this.StatClearEndDate = ''
            this.StatClearEndTime = ''
        },
        ClearStatistics(){
            console.log('this.FinalClearStatTypeItems',this.FinalClearStatTypeItems)
            this.FinalClearStatTypeItems.map((item,index) => {
                this.ActiveDB.collection('siteanalytics').doc(item.id).delete().then(doc => {
                    //console.log('deleted item '+item.id)
                    if(index-1+2 === this.FinalClearStatTypeItems.length){
                        this.CancelClearStats()                        
                    }
                })
            })            
        },
        ForeverLoopChildrenTimeDiff(item,childarray,datefield,int,Type){
            //console.log('ForeverLoopChildrenTimeDiff ',item,childarray,datefield,int,Type)
            let parentdate = item[datefield].toDate()
            //let parenttime = parentdate.getTime()
            item[childarray].map(child => {
                let childdate = child[datefield].toDate()
                var diffMs = childdate-parentdate
                var diffDays = Math.floor(diffMs / 86400000) // days
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                //let childtime = childdate.getTime()
                if(Type === 'Minutes'){
                    int = int+diffMins
                }
                else if(Type === 'Days'){
                    int = int+diffDays
                }
                else if(Type === 'Hours'){
                    int = int+diffHrs
                }
                if(child[childarray]){
                    int = this.ForeverLoopChildrenTimeDiff(child,childarray,datefield,int,Type)
                }
            })
            return int
        },
        GetMatchinObjectorChildren(item,childarray,matchid){
            let match = item[childarray].find(obj => obj.id === matchid)
            if(match){
                return match
            }
            else{
                item[childarray].map(child => {
                    return this.GetMatchinObjectorChildren(child,childarray,matchid)
                })
                
            }
        },
        ForeverLoopChildren(item,childarray,int){
            item[childarray].map(child => {
                int++
                if(child[childarray]){
                    int = this.ForeverLoopChildren(child,childarray,int)
                }
            })
            return int
        },
        EmitDrillDown(MethodName, tab){
            //console.log(MethodName,tab)
            this.DrillDown = true
            this.DrillDownItem = tab
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
        GetSiteModerators(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.SiteModerators.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                }
                })
            })
        },
        GetSitePages(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.SitePages.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                }
                })
            })
        },
        SaveStoredValidatorTabs(){
            alert('Still To Do. Save to Ra, Maintain on RA, call from Ra from then')
        },
        RelevantElements(item,Elements){
            return Elements.filter(elmnt => {
                return item[elmnt.Title] !== 'N/A'
            })
        },
        GetElmntReadiness(tab){
            if(tab.Progress === 100){
                return 'border-left: 6px solid green;'
            }
             else if(tab.Progress >= 50){
                return 'border-left: 6px solid orange;'
            }
            else{
                return 'border-left: 6px solid red;'
            }
        },
        TabReadinessIcon(tab){
            if(tab.Progress === 100){
                return 'mdi-cloud-check'
            }
            else if(tab.Progress >= 50){
                return 'mdi-alert'
            }
            else{
                return 'mdi-alert'
            }
        },
        TabReadinessTitleColor(tab){
            if(tab.Progress === 100){
                return 'green'
            }
            else if(tab.Progress >= 50){
                return 'pink'
            }
            else{
                return 'red'
            }
        },
        TabReadinessProgressColor(tab){
            if(tab.Progress === 100){
                return 'blue lighten-4'
            }
            else if(tab.Progress >= 50){
                return 'warning'
            }
            else{
                return 'red'
            }
        },
        TabReadiness(tab){
            if(tab.Progress === 100){
                return 'Ready'
            }
            else{
                return 'Incomplete'
            }
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        MatchColor(tab){
            if(tab.RowColor){
                return tab.RowColor.hexa
            }
            
        },
        
        GetTableProgress(object){            
            object.AllItems = []
            object.ReadyItems = []
            object.IncompleteItems = []

            if(object.Check === 'SingleBuildTabs'){
                object.AllItems = this[object.array]
                object.ReadyItems = object.AllItems.filter(entity => {
                    return entity.SingleBuildTabs && entity.SingleBuildTabs.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.SingleBuildTabs || entity.SingleBuildTabs && entity.SingleBuildTabs.length === 0
                })                
            }
            else if(object.Check === 'BU Security Roles'){
                object.AllItems = this[object.array].filter(entity => {
                    return entity.Security_Structure && entity.Security_Structure.Name === 'Business Unit'
                })
                object.ReadyItems = object.AllItems.filter(entity => {
                    return entity.AllistRoles
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.AllistRoles
                })
            }
            else if(object.Check === 'Custom Security Roles'){
                object.AllItems = this[object.array].filter(entity => {
                    return entity.Security_Structure && entity.Security_Structure.Name === 'Custom Roles'
                })
                object.ReadyItems = object.AllItems.filter(entity => {
                    return entity.AllistRoles
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.AllistRoles
                })
            }
            else if(object.Check === 'Classified Table Entries'){
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.ClassifiedEntity
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.ClassifiedEntity
                })
            }
            else if(object.Check === 'Wiki Table Entries'){
                object.AllItems = this[object.array]
                //console.log(object.AllItems)
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.WikiEntity
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.WikiEntity
                })
            }            
            else if(object.Check === 'Classified NavList'){
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.EntityNavList
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.EntityNavList
                })
            }
            else if(object.Check === 'Classified Combined Title'){
                
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(entity => {
                    return entity.EntityNavList && entity.EntityNavList.CombinedTitleMap
                })
                object.IncompleteItems = object.AllItems.filter(entity => {
                    return !entity.EntityNavList || entity.EntityNavList && !entity.EntityNavList.CombinedTitleMap
                })
            }
            
            else if(object.Check === 'Website Landing Page'){
                
                object.AllItems = [
                    {
                        id: 'Public_Landing_Page',
                        Name: 'Public Landing Page'
                    }
                ]
                let check = this.System[object.AllItems[0].id]
                if(check){
                    object.ReadyItems = object.AllItems
                    object.IncompleteItems = []    
                }
                else{
                    object.ReadyItems = []
                    object.IncompleteItems = object.AllItems
                }
            }
            else if(object.Check === 'WebPageTabs'){
                
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(page => {
                    return page.PageTabs && page.PageTabs.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(page => {
                    return !page.PageTabs || page.PageTabs && page.PageTabs.length === 0
                })
            }
            else if(object.Check === 'Web Pages on Menu'){
                
                object.AllItems = this[object.array].filter(page => {return !page.NonMenuItem})
                .map(page => {
                    if(this.System.PublicNavbarItems){
                     let paths = this.System.PublicNavbarItems.map(child => {return child.title})
                     if(paths.includes(page.Name)){
                            page.OnMenu = true
                        } 
                    }                    
                    return page
                })  
                object.ReadyItems =  object.AllItems.filter(page => {                    
                    return page.OnMenu
                })
                object.IncompleteItems = object.AllItems.filter(page => {                    
                    return !page.OnMenu
                })
            }
            else if(object.Check === 'SocialPageTabs'){
                
                object.AllItems = this[object.array]
                object.ReadyItems =  object.AllItems.filter(page => {
                    return page.PageTabs && page.PageTabs.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(page => {
                    return !page.PageTabs || page.PageTabs && page.PageTabs.length === 0
                })
            }
            else if(object.Check === 'Social Pages on Menu'){
                
                object.AllItems = this[object.array].filter(page => {return !page.NonMenuItem})
                .map(page => {
                    if(this.System.SocialNavbarItems){
                     this.System.SocialNavbarItems.map(item => {
                        let paths = []
                        if(item.Children){
                            paths = item.Children.map(child => {return child.title})
                        if(paths.includes(page.Name)){
                            page.OnMenu = true
                        }
                        }
                    })   
                    }                    
                    return page
                })  
                object.ReadyItems =  object.AllItems.filter(page => {                    
                    return page.OnMenu
                })
                object.IncompleteItems = object.AllItems.filter(page => {                    
                    return !page.OnMenu
                })
            }
            else if(object.Check === 'Social Module Landing Page'){
                
                object.AllItems = [
                    {
                        id: 'Social_Landing_Page',
                        Name: 'Social Landing Page'
                    }
                ]
                let check = this.System[object.AllItems[0].id]
                if(check){
                    object.ReadyItems = object.AllItems
                    object.IncompleteItems = []    
                }
                else{
                    object.ReadyItems = []
                    object.IncompleteItems = object.AllItems
                }
            }
            else if(object.Check === 'Social Component Categories'){
                object.AllItems = this[object.array].filter(comp => {return comp.HasCategories})
                object.ReadyItems =  object.AllItems.filter(comp => {                    
                    return this.System[comp.CatProp] && this.System[comp.CatProp].Options.length > 0
                })
                object.IncompleteItems = object.AllItems.filter(comp => {                    
                    return !this.System[comp.CatProp] || this.System[comp.CatProp] && this.System[comp.CatProp].Options.length === 0
                })
            }
            else if(object.Check === 'Internal Moderators Assigned'){
                let moderatoritems = this.SiteModerators.map(mod => {return mod.ModerationPermissions}).flat()
                
                object.AllItems = this[object.array]                
                object.ReadyItems = object.AllItems.filter(item => {                    
                    let check = moderatoritems.find(obj => obj === item.PluralName)
                    return check
                })
                
                object.IncompleteItems = object.AllItems.filter(item => {
                    let check = moderatoritems.find(obj => obj === item.PluralName)
                    return !check
                })
                
                
                
            }
            //console.log('object.Incomplete '+object.Check,object.Incomplete)
            let total = object.AllItems.length
            let progress =  object.ReadyItems.length
            object.Progress = (progress/total)*100
            object.Total = total
            object.Incomplete = total-progress
            object.IncompletePercentage = (object.Incomplete/object.Total)*100
            object.Complete = progress
            return object
            
        },
        GetWebFormSubmissions(query,build){            
            query.onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach((change,i) => {
                   if (change.type === 'added') {
                        let submission = change.doc.data()
                        submission.id = change.doc.id
                        build.WebFormSubmissions.push(submission)
                   }
                })
            })
        },
        GetSystemEntities(entityquery){
            let vm = this
            return new Promise(function(resolve, reject) {
            entityquery.onSnapshot(res => {
            let arraylength = res.docs.length
                if(arraylength === 0){
                  resolve('GotEntities')
                    //vm.$emit('ActivateProcessing',false)
                }
                else{
                  const changes = res.docChanges();
                    changes.forEach((change,i) => {
                   if (change.type === 'added') {
                        let build = change.doc.data()
                        build.id = change.doc.id
                        if(build.Entity_Type && build.Entity_Type.Name === 'Web Form'){
                            build.WebFormSubmissions = []
                            vm.GetWebFormSubmissions(db.collection(build.id.split('_').join('').toLowerCase()),build)
                        }
                        vm.GetAllEntityProps(build).then(function(allpropsresult) {
                          //console.log('allpropsresult',allpropsresult,build)
                            if(allpropsresult){
                               vm.SystemEntities.push(build)
                               if(vm.SystemEntities.length === arraylength){
                                 //vm.GotEntities = true
                                //  vm.CheckAuth()
                                resolve('GotEntities')
                                //vm.SystemLoadingvalue = 75
                               }
                            }
                        })                        
                   }
                  })
                }
            })
            })
        },
        GetEntityNavList(build,subcol){
            let vm = this
            // console.log('calling props')
            return new Promise(function(resolve, reject) {
            vm.ActiveDB.collection('SystemConfig').doc(vm.ConfigSystemid).collection('Directories').doc(build.id).onSnapshot(clsfdsnapshot => {
                let clsfddata = clsfdsnapshot.data()
                build.EntityNavList = clsfddata
                resolve(build)
            })
            })
        },
        GetWikiEntity(build){
            let vm = this
            // console.log('calling props')
            return new Promise(function(resolve, reject) {
            vm.ActiveDB.collection('SystemConfig').doc(vm.ConfigSystemid).collection('wikientities').doc(build.id).onSnapshot(snapshot => {
                let wikidata = snapshot.data()
                build.WikiEntity = wikidata
                resolve(build)
            })
            })
        },
        GetClassifiedListingEntity(build){
            let vm = this
            // console.log('calling props')
            return new Promise(function(resolve, reject) {
            vm.ActiveDB.collection('SystemConfig').doc(vm.ConfigSystemid).collection('classifiedentities').doc(build.id).onSnapshot(snapshot => {
                let clsfddata = snapshot.data()
                build.ClassifiedEntity = clsfddata
                resolve(build)
            })
            })
        },
        GetAllEntityProps(build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(build).then(function(allfieldsresult) {
                if(allfieldsresult){
                  
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(build).then(function(subcolresult) {
                        // console.log(subcolresult)
                        
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(build).then(function(formsresult) {
                            vm.GetDocumentRegisters(build).then(function(docregresult) {  
                                vm.GetGalleries(build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(build).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                    if(build.Entity_Type && build.Entity_Type.Name === 'Classified Listing'){
                                        vm.GetClassifiedListingEntity(build).then(listingresult => {
                                           vm.GetEntityNavList(build,'classifiedentities').then(listingresult => {
                                               if(build.Has_Wiki){
                                                    vm.GetWikiEntity(build).then(listingresult => {
                                                            resolve('got the props for '+build.id)
                                                        })
                                                }
                                                else{
                                                    resolve('got the props for '+build.id)
                                                }
                                                
                                            })
                                        })
                                    }
                                    else if(build.Has_Wiki){
                                        vm.GetWikiEntity(build).then(listingresult => {
                                                resolve('got the props for '+build.id)
                                            })
                                    }
                                    else{
                                        resolve('got the props for '+build.id)
                                    }
                                    
                                
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
    GetEntityAllFields(build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('EntityProcesses').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            vm.ActiveDB.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                vm.ActiveDB.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            
                            //console.log(targetslength,i-1+2)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
        GetLineorBarChart(type,elmnt,ChartData,ChartLabels,ChartColors,ChartAllData){			
            console.log(elmnt,ChartData,ChartLabels,ChartColors,ChartAllData)			
            elmnt.textContent = ''			
          var canvas = document.createElement("CANVAS")			
          console.log(elmnt.offsetHeight)			
          // canvas.style.width = ''+elmnt.offsetWidth			
          // canvas.style.height = ''+elmnt.offsetHeight 			
         			
          canvas.style.width = '100%'			
          canvas.style.height = '100%' 			
          elmnt.appendChild(canvas)			
          let namelist = ['Jan','Piet','Gert','Thuli','Jackie','Hennie','Chade','Johan','Lood','Rudi','Mike','Jacques','Sipho','John','Oliver','Louis','Sannie']			
          let surnamelist = ['Fourie','Louw','Snyman','Nel','van Wyk','le Roux','van Staden','Rambo','Krynauw','Beyers','Oosthuizen','Ndlovu','Skoonraadt','van der Merwe','Steyn','Pretorius','Seemonster']			
          let datalength = 40          			
          let generateddatasets = []			
          let labellength = ChartLabels.length			
          for(var x = 0; x < datalength; x++){			
            let randomname = Math.floor(Math.random() * Math.floor(namelist.length))			
            let randomsurname = Math.floor(Math.random() * Math.floor(surnamelist.length))			
            let obj = {			
              label: namelist[randomname]+' '+surnamelist[randomsurname],			
              data: [],			
              borderColor: "#ff0000",			
              backgroundColor: ChartColors,			
              fill: false,			
            }			
            for(var y = 0; y < labellength; y++){			
              let randomvalue = Math.floor(Math.random() * Math.floor(5))			
              obj.data[y] = randomvalue			
            }			
            //console.log(obj)			
            generateddatasets[x] = obj			
          }			
          console.log(generateddatasets)			
          new Chart(canvas, {			
            type: type,			
            data: {			
                labels: ChartLabels,			
                datasets: ChartAllData.datasets,			
                //datasets: generateddatasets,			
            },			
            			
            options: {			
                title: {			
                // display: true,			
                text: 'World population per region (in millions)',			
                			
                },			
                legend: {			
                    display: false,			
                    labels: {			
                        fontColor: 'rgb(255, 255, 255)'			
                    },			
                },			
			
                scales: {			
                        xAxes: [{			
                            			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            },			
                            scaleLabel: {			
                            display: false,			
                            labelString: 'Month'			
                            }			
                        }],			
                        yAxes: [{			
                            display: true,			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            maxTicksLimit: 5			
			
                            },			
                        }]			
                    }			
            }			
            })			
        },			
        		
        PrepareElementComponents(elmnt){			
        if(elmnt.HasDataContext && elmnt.HasChart){			
                    elmnt.HasTwoColumns = this.GetHasTwoColumns(elmnt)			
                    elmnt.HasTwoRows = !elmnt.HasTwoColumns			
                    elmnt.ComponentOne = this.GetComponentOne(elmnt)			
                    elmnt.ComponentTwo = this.GetComponentTwo(elmnt)			
                }			
                // else if(elmnt.HasDataContext && elmnt.HasChart){			
                // }			
                else{			
                    elmnt.HasTwoColumns = false			
                    elmnt.HasTwoRows = false			
                    elmnt.ComponentTwo = ''			
                    // console.log('from here')			
                    elmnt.ComponentOne = this.GetComponentOne(elmnt)                			
                }			
                            
                return elmnt			
        },
        GetComponentOne(elmnt){			
        if(!elmnt.ChartPosition){			
            if(elmnt.HasChart){			
            // console.log('from here')			
            return {CompName: 'DashboardElmtChart',Style: 'Pipeline'}			
            }			
            else if(elmnt.HasDataContext){          			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
            }			
        }			
        else if(elmnt.ChartPosition === 'Top' && elmnt.HasChart){			
            return {CompName: 'DashboardElmtChart',Style: elmnt.ChartPosition}			
        }			
        else if(elmnt.ChartPosition === 'Right' || elmnt.HasDataContext && !elmnt.HasChart){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
        else if(elmnt.ChartPosition === 'Left'){			
            return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
        }			
        },			
        GetComponentTwo(elmnt){			
        if(elmnt.ChartPosition === 'Top'){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
        else if(elmnt.ChartPosition === 'Right'){			
            return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
        }			
        else if(elmnt.ChartPosition === 'Left'){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }  			
        },
        GetHasTwoColumns(elmnt){			
        if(elmnt.ChartPosition === 'Top'){			
            return false			
        }			
        else{			
            return true			
        }			
        },					
    }
}
</script>

<style>

</style>
