<template>
<v-main style="margin-top: 60px;">
   <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[POPDFFunctionData]"		:PassedFunction="POPDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFPOData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
  <v-card class="transparent" flat tile >
    <!-- <v-btn @click="FixGRVSupplierInvoices()">Fix GRv Suplier Invoices</v-btn> -->

    <!-- <v-btn @click="RandomAutoNumberTest()">RandomAutoNumberTest</v-btn> -->
    <v-card-title :class="!MiniView ? 'mediumoverline recordtoolbar white--text' : 'subtleoverline recordtoolbar white--text'" v-if="ActiveWarehouse">
      <v-icon :size="MiniView ? 30 : 45" dark>mdi-gift</v-icon>Receiving<v-spacer></v-spacer> {{ActiveWarehouse.Name}}
    </v-card-title>
        <v-dialog v-model="SelectedPODialog" persistent :max-width="step === 1 ? 400 : 1000">
            <v-card
                class="mx-auto"
            >
                <v-card-title class="mediumoverline recordtoolbar white--text justify-space-between">
                  <v-icon class="soloactionicon" color="white" @click="DeactivateSelectedPODialog()">mdi-close</v-icon>
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="primary lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="step">
                <v-window-item :value="1">
                        <v-card-text>           
                            <FieldValueEditerComponent v-for="field in ComputedReceivingFields" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="editedReceivingItem" :field="field" @onPhotoFileselected="onPhotoFileselected"
                                :SystemEntities="SystemEntities" :PrimaryFieldName="'GRV_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                                @UploadFileSelect="UploadFileSelect"
                                    />
                        </v-card-text>
                 
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-text>
                     <PODocViewer :PDFExportFunctionData="POPDFFunctionData" :SelectedSupplier="SelectedSupplier" v-if="POData && POLineItems && POPDFFunctionData"
                        :POData="ComputedPOData" :RightHeaderFields="ComputedPORightHeaderFields" :LineItems="ComputedPOLineItems" :HeadersforTable="POLineHeadersforTable" :DocRef="PODocRef" :LineItemsRef="POLineItemsRef"
                        />
                    </v-card-text>
                </v-window-item>
                </v-window>
                <v-card-actions>
                <v-btn
                    :disabled="step === 1"
                    text
                    @click="step--"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn dark
                    v-if="step === 2"
                    color="red"
                    depressed
                    @click="DownloadPOInvoice(true)"
                >
               
                    Preview PDF <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
               
                <v-btn
                    :disabled="StopNext"
                    color="primary"
                    depressed
                    @click="step === 2 ? ReceiveItems() : step++"
                >
                    Next
                </v-btn>
                
                </v-card-actions>
            </v-card>
           
        </v-dialog>
           <v-card-text>
          <v-layout class="justify-center" row style="margin-top:60px;">
          <v-flex lg10 md10>
     
          <v-toolbar rounded dark color="recordtoolbar darken-1">
       <v-toolbar-title>Purchase Order - Receiving</v-toolbar-title>
      
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        Select PO
       > Select Goods
       > Create GRV
        <v-spacer></v-spacer>
        <v-text-field 
          v-model="posearch"
          append-icon="mdi-file-find-outline"
          label="Search PR"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="posearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
     </v-toolbar>
     
          </v-flex>
          </v-layout>
          <v-layout class="justify-center" row>
            <v-flex lg11 md11>
                <v-data-table
                :page.sync="page"
                @page-count="pageCount = $event"
                :headers="POHeaders"
                :items="ComputedPurchaseOrders"
                :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
                >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn dark @click="SelectedPurchaseOrder(item)">
                      Receive
                  </v-btn>
                </template>
            </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
  </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PODocViewer from '@/components/SuitePlugins/Warehousing/PODocViewer'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
export default {
    props: ['System','SystemEntities'],
    components: {PODocViewer,FieldValueEditerComponent,PDFExporter},
    data() {
        return {
          GRVLinesbyPRLineItems: [],
          RepriotizePicking: false,
          PDFDownloadOnly: false,
          ExportingtoPDFDialog: false,
            posearch: '',
            editedReceivingItemIndex: -1,
            editedReceivingItem: { Receiving_Document: {}},
            ReceivingFields: [
        {id: 'Reference_Number',DisplayName: 'Reference Number', Name: 'Reference_Number',Type: 'Single Line Text'},
        // {id: 'Qty',DisplayName: 'Qty', Name: 'Qty',Type: 'Number Field'},
        {id: 'Date_Received',DisplayName: 'Date Recieved', Name: 'Date_Received',Type: 'Date'},
        // {id: 'Price',DisplayName: 'Price', Name: 'Price',Type: 'Number Field'},
        {id: 'Receiving_Document',DisplayName: 'Receiving Document', Name: 'Receiving_Document',Type: 'Single File Upload'},
        {id: 'Progress',DisplayName: 'Progress', Name: 'Progress',Type: 'Option Set',Options: [{ID: 1000000, Name: 'Received'},{ID: 1000001,Name: 'Inspection'},{ID: 1000002,Name: 'Completed'}]}
      ],
            step: 1,
            POHeaders: [
                {text: 'PO Number',value: 'PO_Number',class: 'overline'},
                {text: 'Supplier',value: 'Supplier.Supplier_Name',class: 'overline'},
                {text: 'actions',value: 'actions',class: 'overline'}
            ],
            SelectedPODialog: false,
            PurchaseOrders: [],
            POLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Warehouse',Type: 'Lookup',LookupFieldName: 'Name',propvalue: 'Warehouse',value: 'Warehouse.Name',class: 'overline',TableHidden: true},
              {text: 'Item',propvalue: 'StockObj',value: 'StockObj.Item_Name',Type: 'Lookup',LookupFieldName: 'Item_Name',class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Sub Total',propvalue: 'Sub_Total',value: 'Sub_Total',Type: 'Number Field',IsCurrency: true,class: 'overline'},
              {text: 'Supplier',propvalue: 'Supplier',value: 'Supplier.Supplier_Name',Type: 'Lookup',LookupFieldName: 'Supplier_Name',class: 'overline',TableHidden: true},
              {text: 'Delivery Date',propvalue: 'Delivery_Date',value: 'Delivery_Date',Type: 'Date',class: 'overline'},
              {text: 'Sales Order',propvalue: 'Sales_Order',value: 'Sales_Order.Name',class: 'overline',TableHidden: true},
            ],
            POLineItems: [],
            SelectedSupplier: '',
            POData: '',
            PDFExportFunctionData: '',
            PORightHeaderFields: [
            {Name: 'Created_On',DisplayName: 'PO Date',Type: 'Date'},
            {Name: 'Payment_Terms',DisplayName: 'Payment Terms',text: 'Payment Terms',value: 'Payment_Terms',Type: 'Single Line Text'}, 
            {Name: 'Shipping_Method',DisplayName: 'Shipping Method',text: 'Shipping Method',value: 'Shipping_Method',Type: 'Option Set',Options:[{ID: 1000001,Name: 'FOB'}]},
            {Name: 'Promised_Date',DisplayName: 'Promised Date',text: 'Promised Date',value: 'Promised_Date',Type: 'Date'},
          ],
        }  
    },
    watch: {
        WarehouseApp(v){
            if(v){
                if(!this.ActiveSuiteApp){
                this.$store.commit('setActiveSuiteApp',this.WarehouseApp)   
                }
            }
        },
        POData(v) {
            if (v) {
              console.log(v)
                this.GetPurchaseOrderLines();
                setTimeout(() => {
                    this.SelectedPODialog = true;
                }, 1000);
            }
            else{
                this.POData = ''
            }
        }
    },
    computed:{
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ActiveSuiteApp(){
          return this.$store.state.ActiveSuiteApp
      },
      AppsDataBase(){
          return this.$store.state.AppsDataBase
      },
      WarehouseApp(){           
          return this.AppsDataBase.find(obj => obj.id === this.ViewWarehouse.Primary_Appid)
      },
      ViewWarehouse(){
        return this.Warehouses.find(obj => obj.id === this.$route.params.slug)
      },        
      ActiveWarehouse(){
          return this.$store.state.ActiveWarehouse
      },        
      Warehouses(){
          return this.$store.state.WarehousesArray
      },
      StopNext(){
        let bool = false
        this.ReceivingFields.map(field => {
          if(typeof this.editedReceivingItem[field.Name] === 'undefined'){
            bool = true
          }
        })
        return this.NoReceivingDocument ? true : bool
      },
      ComputedReceivingFields(){
        //BypassReceivingWIP
        //console.log('this.BypassReceivingWIP',this.BypassReceivingWIP)
        return this.ReceivingFields.map(field => {
          if(field.id === 'Progress' && this.BypassReceivingWIP){
            field.ReadOnly = true
            this.editedReceivingItem.Progress = field.Options[field.Options.length-1]
          }
          return field
        })
      },
      NoReceivingDocument(){
        return typeof this.editedReceivingItem.Receiving_Document.UploadFile === 'undefined'
      },
      ComputedPOObject(){
        this.POData.RecordPrimaryFieldName = 'PO_Number'
        return this.POData
      },
      PDFPOData(){
        let sampleobj = this.POData
        console.log('sampleobj',sampleobj)
        sampleobj.SubCollections = []
        return sampleobj
      },
      currentTitle(){
        switch (this.step) {
          case 1: return 'Capture GRV Headers'
          case 2: return 'Selecte Lines from PO'
          default: return 'Capture GRV Headers'
        }

      },
      ComputedPurchaseOrders(){
        return this.PurchaseOrders.filter(po => {
          if(this.posearch){
            return po.PO_Number.toLowerCase().includes(this.posearch.toLowerCase()) || po.Supplier && po.Supplier.Supplier_Name.toLowerCase().includes(this.posearch.toLowerCase())
          } 
          else{
            return po
          }
        })
      },
        CurrentEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Sales_Order_Entity)
        },
        StoreProductEntity(){
            return this.SystemEntities.find(obj => obj.id === this.ActiveWarehouse.Store_Entity)
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        StoreProductEntityApp(){
                return this.ActiveWarehouse ? this.AppsDataBase.find(obj => obj.id === this.ActiveWarehouse.Primary_Appid) : ''
        },
        BypassReceivingWIP(){
          let appplugins = []
          if(this.StoreProductEntityApp){
            appplugins = this.StoreProductEntityApp.Plugins.map(plug => {return plug.id})
          }
          return this.StoreProductEntityApp && !appplugins.includes('Receiving_WIP')
        },
        POSubTotal(){
        let total = this.ComputedPOLineItems.map(polineitem => {
          return polineitem.Price*polineitem.Qty
        }).reduce((a, b) => a + b, 0)
        return Number(total.toFixed(2))
      },
      TaxPercentage(){
        return this.SelectedSupplier && typeof this.SelectedSupplier.Tax_Percentage !== 'undefined' ? this.SelectedSupplier.Tax_Percentage : 0
      },
      TaxTotal(){
        if(this.SelectedSupplier && this.SelectedSupplier.id){
          if(this.SelectedSupplier.Tax_Percentage !== 0){            
            let total = this.POSubTotal*(this.SelectedSupplier.Tax_Percentage/100)
            return Number(total.toFixed(2))
          }
          else{
            return 0
          }
        }
        else{
            return 0
          }
        
      },
        GrandTotal(){
        let total = this.POSubTotal
        if(this.POData.Discount_Total !== 0){
          total = total-this.POData.Discount_Total
        }
        if(this.POData.Freight_Total !== 0){
          total = total+this.POData.Freight_Total
        }
        if(this.POData.Tax_Total !== 0){
          total = total+this.TaxTotal
        }
        return Number(total.toFixed(2))
      },
      VatExclusiveInvoiceTotal(){
       let total = this.POSubTotal
        if(this.POData.Discount_Total !== 0){
          total = total-this.POData.Discount_Total
        }
        if(this.POData.Freight_Total !== 0){
          total = total+this.POData.Freight_Total
        }
        return Number(total.toFixed(2))
      },
        ComputedPOData(){
            if(this.POData){
             let object = this.POData
                object.Grand_Total = this.GrandTotal
                object.Sub_Total = this.POSubTotal
                object.Tax_Total = this.TaxTotal
                object.Tax_Percentage = this.TaxPercentage
                return object   
            }
            
        },
        ComputedPOLineItems(){
          return this.POLineItems
            .map(item => {
                if(!item.Prepared){
                    item.Outstanding_Qty = item.Qty
                    item.Qty = item.Qty-item.Received
                    item.Prepared = true
                }
                item.Sub_Total = item.Price*item.Qty              
              return item
            })
          // .filter(item => {
          //   return item.Qty > 0
          // })
          
        },
        POLineItemsRef(){
          return this.POData ? db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('PurchaseOrdersLine_Items').where('Warehouseid','==',this.$route.params.slug).where('Transaction_Docid','==',this.POData.id).where('Progress.Name','in',['Ordered','Partially Received']) : ''
        },
        PODocRef(){
          return this.POData ? db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('PurchaseOrders').doc(this.POData.id) : ''
        },
        POLineHeadersforTable(){
        let headers = this.POLineHeaders.filter(head => {
          return !head.TableHidden
        })
        if(this.RepriotizePicking){
          return headers.concat([
            {text: 'PickingSlips',propvalue: 'PickingSlips',value: 'PickingSlips',Type: 'Button',class: 'overline'},
          ])
        }
        else{
          return headers
        }
      },
        OrderforDelivery(){
            return this.POData.Delivery
        },
        ComputedPORightHeaderFields(){
        return this.OrderforDelivery ? this.PORightHeaderFields : this.PORightHeaderFields.filter(field => {
          return field.Name !== 'Shipping_Method'
        })
      },
        UserCanPrioirtizePickingSlips(){
          return this.UserWHObj && this.UserWHObj.Receiving_Priorities
        },
        UserWHObj(){
          return this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.$route.params.slug] ? this.userLoggedIn.Warehouses[this.$route.params.slug] : ''
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        GRVLineItems(){
          return this.ComputedPOLineItems.filter(item => {
            return item.Qty  > 0
          })
        },
        GRVLineItemsTable(){
        let headerslength = this.POLineHeadersforTable.length
        let html = `<table style="width:550px;padding:5px;border: 1px solid grey;">
                    <tr>`
        this.POLineHeadersforTable.map(head => {
          html = html+`
          <th class="overline" style="text-align: left;">`+head.text+`</th>`
        })
        html = html+`
        </tr>`
        this.GRVLineItems.map(lineitem => {
          html = html+`
          <tr class="detailslistoutline">`
          this.POLineHeadersforTable.map((head,headnindex) => {
            //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
            let value = lineitem[head.propvalue]
            if(head.Type === 'Number Field' && head.IsCurrency){
              value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
            }
            else if(head.Type === 'Date' && !head.HasTimeInput){
              if(value.toDate){
                value = value.toDate()
              }
              value = value.toDateString()
            }
            else if(head.Type === 'Lookup'){
              value = value[head.LookupFieldName]
            }
            html = html+`
            <td style="text-align: left;">`+value+`</td>`
            if(headnindex-1+2 === headerslength){
              html = html+`
              </tr>`
            }
          })
        })
        html = html+`
        </table>`
        return html
      },
        POPDFFunctionData(){
            if(this.PDFExportFunctionData){
                let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //POLineItemsTable
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'POLineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.GRVLineItemsTable
        newelemnt.Justify = 'center'
        if(this.ComputedPOLineItems.length <= 3){
          newelemnt.PaddingTop = 450
        }
        else if(this.ComputedPOLineItems.length <= 6){
          newelemnt.PaddingTop = 425
        }
        else if(this.ComputedPOLineItems.length <= 9){
          newelemnt.PaddingTop = 400
        }
        else if(this.ComputedPOLineItems.length <= 12){
          newelemnt.PaddingTop = 375
        }
        else {
          newelemnt.PaddingTop = 350
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        functiondata.Pages[0].Elements.push(newelemnt)
        return functiondata
            }
        
      },
    },
    created(){
      if(!this.ActiveSuiteApp && this.WarehouseApp){
         this.$store.commit('setActiveSuiteApp',this.WarehouseApp) 
         this.$store.commit('setActiveWarehouse',this.ViewWarehouse)  
        }
        if(this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.$route.params.slug] && this.userLoggedIn.Warehouses[this.$route.params.slug].Ordering || this.userIsAdmin){
            this.GetOpenPurchaseOrders()
            this.GetPOPDFFunction()
        }
        else{
            this.$router.push('/PermissionError')
        }
    },
    methods:{
      FinalizeGRV(payload){
        let vm = this
        let grlinepickingslips = payload.Data.map(dataobj => {
          let docdata = dataobj.Data
          return docdata.PickingSlips
        }).flat()
        let salesorders = grlinepickingslips.map(grvline => {
          return grvline.LookupObj.id
        })
        let uniqsalesorders = [...new Set(salesorders)]
        //console.log(grlinepickingslips,uniqsalesorders)
        payload.ParentObj.PickingSlips = uniqsalesorders.map(so => {
          let pickingdoc = Object.assign({},grlinepickingslips.find(obj => obj.LookupObj && obj.LookupObj.id === so))
          delete pickingdoc.StockObj
          delete pickingdoc.Qty          
          pickingdoc.LineItems = grlinepickingslips.filter(pickinglist => {
            return pickinglist.LookupObj.id === so
          })
          return pickingdoc
        })
        vm.$store.commit('setCustomProcessingDialog',false)
        console.log(payload)
        const functions = firebase.functions();
        const ConsumeBulkStockQty = functions.httpsCallable('ConsumeBulkStockQty');
                          ConsumeBulkStockQty(payload).then(result => {
                            console.log(result)
                              vm.$store.commit('setCustomProcessingDialogText','Server Responded - '+result.data)
                              setTimeout(() => {
                                vm.$store.commit('setCustomProcessingDialog',false)
                                vm.DeactivateSelectedPODialog()
                              }, 4000);
                              
                              //console.log(result)
                            })
      },
      // FixGRVSupplierInvoices(){
      //   db.collection('supplierinvoices').onSnapshot(res => {
      //     const changes = res.docChanges();
      //       changes.forEach(change => {
      //           if (change.type === 'added') {
      //               let grvobj = {
      //               ...change.doc.data(),
      //               id: change.doc.id
      //               }
      //               db.colleciton('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc('Payment_Invoices').collection('Records').add(grvobj)
      //           }
      //       })
      //   })
      // },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      ToggleDialog(dialogname){					
      this[dialogname] = !this[dialogname]					
    },		
      async RandomAutoNumberTest(){
        let array = [{Name: 'a'},{Name: 'f'},{Name: 'h'},{Name: 'l'}]
          for (var item of array) {
          item.string = await this.AssignAutoNumberMethod(AutoNumberConfig);
          i++
          console.log(item,i);
        }
        console.log('we running array for RandomAutoNumberTest')
        let AutoNumberConfig = {
          Docid: 'GoodsReceivingVouchers_GRV_Number',
          Data: {}
        } 
        
        // const promises = [];
        // array.map(item => {
        //   promises.push(
        //     await this.AssignAutoNumberMethod(AutoNumberConfig)
        //   )
        // })
        // Promise.all(promises).then(result => {
        //   console.log(result)
        // })
      },
      DeactivateSelectedPODialog(){
        this.SelectedPODialog = false
        this.SelectedSupplier = ''
        this.POData = ''
        this.editedReceivingItem = { Receiving_Document: {}}
        this.step = 1
        this.RepriotizePicking = false
      },
      ResetSearch(){
        this.posearch = ''
      },      
      
        ReceiveItems(){
        let vm = this
        let grvlineslist = vm.ComputedPOLineItems
            .filter(polineitem => {
              return polineitem.Qty > 0
            })
        let grvlineslength = grvlineslist.length
        if(grvlineslength === 0){
          alert('No Qualifying lines, check Qty')
        }
        else{
         this.DownloadPOInvoice()
        }
        
      },
      DeactivateExportingtoPDFDialog(doc){    
        this.$store.commit('setCustomProcessingDialog',false)    
        if(!this.PDFDownloadOnly){
         this.ProcessGRVPDF(doc) 
        }        
        this.ExportingtoPDFDialog = false
        this.PDFDownloadOnly = false
      },
      DownloadPOInvoice(downloadonly){
        this.ExportingtoPDFDialog = true
        this.PDFDownloadOnly = downloadonly
        this.$store.commit('setCustomProcessingDialogText','PDF Worker')
        this.$store.commit('setCustomProcessingDialog',true)
      },
      ProcessGRVPDF(doc){
        let vm = this
        vm.$store.commit('setCustomProcessingDialog',true)
        vm.$store.commit('setCustomProcessingDialogText','Preparing Supplier Invoice PDF')
        //console.log(this.NewPOLineItems,this.SelectedPRLineItems,this.ComputedPOObject)
        //so actually we need to get autonumber. But first let's create autonumber field, see it's logic and take it from there see if it can be used
        var blobpdf = new Blob([doc.output('blob')],{type: 'application/pdf'})
        var bloburl = URL.createObjectURL(blobpdf)  
        //console.log(blobpdf,bloburl)
        let storagelocation = 'Purchase_Invoices/'+vm.POData.PO_Number+'/'+new Date()
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(blobpdf);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Preparing Purchase Order')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                          }
                          vm.ProcessGRV(PDF_File)
                        })
    },
      ProcessGRV(PDF_File){
        let vm = this
        
          vm.$store.commit('setCustomProcessingDialogText','Registering GRV')
          const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            let payload = {
              Docid: 'GoodsReceivingVouchers_GRV_Number',
              Data: {},
            siteid: this.$store.state.ActiveSuiteid
            }
            AssignAutoNumber(payload).then(result => {
              vm.editedReceivingItem.GRV_Number = result.data
              PDF_File.Name = vm.POData.PO_Number+' - '+vm.editedReceivingItem.GRV_Number
              vm.SaveReceivingItem(PDF_File)
            }) 
      },
        QuickAssignAutoNumberMethod(newstringprefix,nrstring,docdata,index){    
          return new Promise(function(resolve, reject) {
          let finalstring = ''
          let nrstring = '0'
            if(docdata.NumberingString){
              nrstring = docdata.NumberingString
            }      
          let newnr = index-1+2
          let newnrlength = newnr.toString().length
          let newstringcount = docdata.Digits-newnrlength
          for(let x = 0; x < newstringcount; x++){ 
            newstringprefix = newstringprefix+nrstring
            if(x-1+2 === newstringcount){
              finalstring = newstringprefix+newnr.toString()
              resolve({
                Last_Number: newnr,
                Last_Value: finalstring
              })
            }
          }
          })
        },
        SaveReceivingItem(PDF_File){
        console.log(this.OperationalDB,this.editedReceivingItem)// if(this.$refs.polineitemsform.validate()){
        let vm = this
        vm.$store.commit('setCustomProcessingDialogText','Uploading GRV Attachment File')
        let file = vm.editedReceivingItem.Receiving_Document.UploadFile
        let filename = vm.editedReceivingItem.Receiving_Document.FileName
            let storepath = '/GoodsReceiving/'+vm.editedReceivingItem.GRV_Number+'/'+filename+'/'+new Date()
            var storageRef = firebase.storage().ref(storepath)
            var uploadTask = storageRef.put(file)
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              vm.$store.commit('setCustomProcessingDialogText','Creating GRV')
                let UploadObject = {
                fileurl: url,
                url: url,
                FileType: file.type,
                Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                Modified_Byid: vm.userLoggedIn.id,
                Created_On: new Date(),
                Modified_On: new Date(),
                ModifiedDate: new Date(file.lastModified),
                Size: file.size,
                StorageRef: storepath,
                filename: filename,
                Name: filename
                }
                PDF_File.filename = PDF_File.Name
                let GRVDoc = Object.assign({},vm.editedReceivingItem)
                GRVDoc.PO_Invoice_File = PDF_File
                GRVDoc.PayableInvoice = {
                  Business_Unit: vm.ActiveWarehouse.Business_Unit,
                  Business_Unitid: vm.ActiveWarehouse.Business_Unitid,
                  Owner: vm.POData.Owner,
                  Ownerid: vm.POData.Ownerid,
                  Reference_Number: vm.editedReceivingItem.Reference_Number,
                  Date_Received: new Date(vm.editedReceivingItem.Date_Received),
                  PO_Invoice_File: PDF_File,
                  Invoice_Number: PDF_File.Name.split(' ').join('_'),
                  PO_Number: vm.POData.PO_Number,
                  Receiving_Document: UploadObject,
                  GRV_Number: vm.editedReceivingItem.GRV_Number,
                  Sub_Total: vm.POSubTotal,
                  Tax_Total: vm.TaxTotal,
                  Tax_Percentage: vm.TaxPercentage,
                  Freight_Total: vm.POData.Freight_Total,
                  Vat_Exclusive_Total: vm.VatExclusiveInvoiceTotal,
                  Invoice_Total: vm.VatExclusiveInvoiceTotal,
                  Grand_Total: vm.GrandTotal,
                  Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
                  Created_Byid: vm.userLoggedIn.id,
                  Modified_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
                  Modified_Byid: vm.userLoggedIn.id,
                  Created_On: new Date(),
                  Modified_On: new Date(),
                  Supplier: {id: vm.SelectedSupplier.id,Supplier_Name: vm.SelectedSupplier.Supplier_Name},
                  Supplierid: vm.SelectedSupplier.id,
                  ReferenceProp: 'Invoice_Number',
                  Invoice_Reference: PDF_File.Name
                }
                delete vm.editedReceivingItem.Receiving_Document
                GRVDoc.Business_Unit = vm.ActiveWarehouse.Business_Unit
                GRVDoc.Business_Unitid = vm.ActiveWarehouse.Business_Unitid
                GRVDoc.Receiving_Document = UploadObject
                GRVDoc.Date_Received = new Date(vm.editedReceivingItem.Date_Received)
                GRVDoc.Created_On = new Date()
                GRVDoc.Modified_On = new Date()
                GRVDoc.Created_By = {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name}
                GRVDoc.Created_Byid = vm.userLoggedIn.id
                GRVDoc.Modified_By = {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name}
                GRVDoc.Modified_Byid = vm.userLoggedIn.id
                GRVDoc.PO_Number = vm.POData.PO_Number
                GRVDoc.Warehouse = vm.POData.Warehouse
                GRVDoc.Warehouseid = vm.POData.Warehouseid
                GRVDoc.Supplier = {id: vm.SelectedSupplier.id,Supplier_Name: vm.SelectedSupplier.Supplier_Name}
                GRVDoc.Supplierid = vm.SelectedSupplier.id
                GRVDoc.Invoice_Total = vm.VatExclusiveInvoiceTotal
                //not loving this actually. shipping cost???
                //shipping cost would be per GRV, even if PO has a shipping cost total. rememebr shipping cost would differ if delivery split so yeah..
                //practially this oes leave challenges but yeah. for now we just hope shipping is billed separately
                GRVDoc.Sub_Total = vm.POSubTotal
                GRVDoc.Warehouseid = vm.POData.Warehouseid
                // let newstringprefix = vm.editedReceivingItem.GRV_Number+'_'
                // let nrstring = '0'
                let grvlineslist = vm.ComputedPOLineItems
                    .filter(polineitem => {
                      return polineitem.Qty > 0
                    }).map(polineitem => {
                      polineitem.PurchaseOrderLineid = polineitem.id
                      return polineitem
                    })
                console.log(grvlineslist,vm.ComputedPOLineItems)
                vm.$store.commit('setCustomProcessingDialogText','Sending to Server')
                    let finalgrvlines = grvlineslist.map((lineitem,lineindex) => {
                        let grvline = Object.assign({},GRVDoc)
                        delete grvline.Last_Number
                        delete grvline.Last_Value
                        delete grvline.Digits
                        delete grvline.NumberingString
                        grvline.Transaction_Docid = GRVDoc.GRV_Number
                        grvline.PurchaseOrderLineid = lineitem.PurchaseOrderLineid
                        grvline.PurchaseOrderid = vm.POData.PO_Number
                        grvline.Price = lineitem.Price
                        grvline.Qty = lineitem.Qty
                        grvline.StockObj = lineitem.StockObj
                        return {Data: grvline}                        
                    })
                    let bulkoutboundpayload = {
                        Collection: 'GoodsReceivingVouchers',
                        Data: [],
                        ParentObj: GRVDoc,
                        Warehouse: GRVDoc.Warehouseid
                      }
                      bulkoutboundpayload.Data = finalgrvlines
                      //console.log('bulkoutboundpayload',bulkoutboundpayload)
                      const functions = firebase.functions();
                      const ObtainPRLineItems = functions.httpsCallable('ObtainPRLineItems');
                      ObtainPRLineItems(bulkoutboundpayload).then(result => {
                        
                        
                        result.data.map(dataobj => {
                          let docdata = dataobj.Data
                          let balance = Number(docdata.Qty)
                          docdata.PickingSlips = docdata.PRLineItems.map(originalprlineobj => {   
                            //okay they already sequenced by dates so...FIFO in check
                            let pickdoc = {
                                  Created_By: docdata.Created_By,
                                  Created_Byid: docdata.Created_Byid,
                                  Modified_By: docdata.Modified_By,
                                  Modified_Byid: docdata.Modified_Byid,
                                  Created_On: new Date(),
                                  Modified_On: new Date(),
                                  Progress: {
                                    ID: 1000001,
                                    Name: 'Issued'
                                  },
                                  Warehouse: docdata.Warehouse,
                                  Warehouseid: docdata.Warehouseid,
                                  PurchaseOrderLineid: docdata.PurchaseOrderLineid
                              }
                              if(balance > 0){
                                balance = Number(balance)-Number(originalprlineobj.Outstanding_Qty)
                                if(balance >= 0){
                                  pickdoc.Qty = Number(originalprlineobj.Outstanding_Qty)
                                }
                                else{
                                  pickdoc.Qty = Number(originalprlineobj.Outstanding_Qty)+Number(balance)
                                }
                              pickdoc.LookupObj = originalprlineobj.LookupObj
                              pickdoc.Created_on_GRV = true                              
                              pickdoc.StockObj = originalprlineobj.StockObj
                              }                              
                              return pickdoc
                          }) .filter(pickdoc => {
                            return pickdoc.Qty
                          })   
                          
                          let polineobj = vm.POLineItems.find(obj => obj.id === docdata.PurchaseOrderLineid)
                          polineobj.PRLineItems = docdata.PRLineItems 
                          polineobj.PickingSlips = docdata.PickingSlips
                        })
                        //console.log(result)
                        bulkoutboundpayload.Data = result.data
                        //console.log(bulkoutboundpayload)
                        if(vm.UserCanPrioirtizePickingSlips){
                          vm.RepriotizePicking = true
                          //vm.$store.commit('setCustomProcessingDialog',false)
                          vm.FinalizeGRV(bulkoutboundpayload)
                        }
                        else{
                         vm.FinalizeGRV(bulkoutboundpayload)
                        }
                        
                      })
                      
                // })
            })  
        },
        UploadFileSelect(file,field,Record){
        //console.log(file,field,Record)
        Record[field.Name] = {UploadFile: file, FileName: file.name}
        },
        SelectedPurchaseOrder(item){
          console.log(item)
            this.POData = item
        },
        GetOpenPurchaseOrders(){
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.$route.params.slug).collection('PurchaseOrders').where('Progress.Name','==','Ordered').onSnapshot(res => {
               
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                            let poobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                            this.PurchaseOrders.push(poobj)
                        }
                    })
            })
        },
        GetPOPDFFunction(){
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').doc('Purchase_Orders').onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          this.PDFExportFunctionData = functiondata
        })
      },
        ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
        GetPOLinesPromisealltest(){
          let array = []
          changes.forEach(change => {
                        if (change.type === 'added') {
                            let prlineobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                            }
                        }
          })
          array.reduce( async (previouspagePromise, page,pageindex) => {
                  await previouspagePromise;
                  return something
                  }, Promise.resolve());
        },
        GetPurchaseOrderLines(){
          this.POLineItems = []
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('storesuppliers').doc(this.POData.Supplierid).onSnapshot(suppsnap => {
          let supplierdata = suppsnap.data()
              this.SelectedSupplier = supplierdata
              this.SelectedSupplier.id = this.POData.Supplierid
              //console.log('supplierdata',supplierdata )
          })
          //POLineItems
          this.POLineItemsRef.onSnapshot(res => {
                const changes = res.docChanges();
                //console.log(changes)
              changes.forEach(change => {
                  if (change.type === 'added') {
                      let prlineobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      this.POLineItems.push(prlineobj)
                  }
                  if (change.type === 'modified') {
                      let prlineobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      let oncheck = this.POLineItems.find(obj => obj.id === prlineobj.id)
                      if(oncheck){
                        let index = this.POLineItems.indexOf(oncheck)
                        this.POLineItems.splice(index, 1, prlineobj);
                      }
                  }
                  if (change.type === "removed") {                      

                      let probj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this.POLineItems.find(obj => obj.id === probj.id)
                      if(oncheck){
                        let index = this.POLineItems.indexOf(oncheck)
                        this.POLineItems.splice(index, 1);
                      }
                  }
              })
          })
        }
    }
}
</script>

<style>

</style>


