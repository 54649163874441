<template>
<v-main :class="!MiniView ? 'v-main__wrap' : ''" style="padding-bottom: 0px;">
    <v-dialog v-model="MessageGroupDialog" width="400" persistent>
        
                       
                      <v-card width="400">
                        <v-card-title class="mediumoverline">
                          <v-avatar
                            class="Grouptitle Avatar mx-4"
                            :size="50"
                            color="white"
                            tile
                          >
                            <v-img contain :src="logo"></v-img
                          ></v-avatar>
                          {{ name }} <v-spacer></v-spacer>                          
                           <v-icon  x-large @click="DeactivateMessageGroupDialog()" color="green" class="soloactionicon my-1">mdi-close</v-icon>
                        </v-card-title>
                         <v-row class="justify-center" style="padding:10px;">
                          <v-rating v-if="GroupRatingActive"
                            v-model="GroupRating" class="justify-center"
                            background-color="amber lighten-1"
                            color="amber lighten-1"
                            
                          ></v-rating>
                        </v-row>
                        <v-card-text>
                          
                          <ContentEditableField
                            :FieldObject="NewMessage" :FieldName="'message'"
                            :FieldValue="NewMessage.message" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                        </v-card-text>
                       
                        <v-card-actions>
                          <v-spacer>
                          </v-spacer>
                          <v-btn @click="SendMessagetoGroup()">Send Message</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
    <v-layout style="position: fixed;top: 130px;left: 10px;z-index: 5" column >
    <v-btn fab @click="AddtoWishlist()" dark color="red">
      <v-icon >{{IteminWishlist ? 'mdi-heart' : 'mdi-heart-outline'}}</v-icon>
    </v-btn>
  </v-layout>
     <v-dialog v-model="GalleryDialog" fullscreen >
    <v-card tile width="100%" height="100%" class="black" style="overflow:hidden;">
      <!-- <div class="d-block"> -->
      <!-- @click="CancelFullScreenCarouselIMGDialog()" -->
      
      <v-btn absolute fab style="top:25px;" top left dark class="accent growheight" @click="OpenGalleryDialog()"><v-icon>mdi-close</v-icon></v-btn>
     
    <v-btn v-if="ActiveGalleryImages[GalleryDialogIndex-1]" absolute fab :style="ArrowLeftStyle"  top left dark class="accent growleft" @click="GalleryDialogIndex--">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn v-if="ActiveGalleryImages[GalleryDialogIndex+1]" absolute fab :style="ArrowRightStyle" top right dark class="accent growright" @click="GalleryDialogIndex++">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-right</v-icon>
    </v-btn>
       <img v-if="ActiveGalleryImages[GalleryDialogIndex] && ActiveGalleryImages[GalleryDialogIndex].URL" :src="ActiveGalleryImages[GalleryDialogIndex].URL"
        style="object-fit: scale-down;width:100%;height:100%;z-index:1;">      
     
    
      <!-- </div> -->
    </v-card>
    <v-navigation-drawer width="250" v-if="ActiveGalleryImages[GalleryDialogIndex] && ActiveGalleryImages[GalleryDialogIndex].Caption" id="commentsnavbar"  fixed right >
      <v-card flat tile class="transparent"> 
        <v-card-text>
        {{ActiveGalleryImages[GalleryDialogIndex].Caption}}
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-dialog>   
    <!-- <v-row style="margin-top:100px;">
        <v-flex lg2 md3 sm4 xs12  style="padding-left:10px;border-right: 2px solid #ddd;">
              
                  
        </v-flex> -->
        <!-- <v-container            
            class="grey lighten-5"
            > -->
            
            <!-- <v-breadcrumbs class="caption" :items="ThisProductClassCrumbs" divider=">">
                
            </v-breadcrumbs> -->
            <ParallaxTab v-if="ThisProduct && ProductBrandParallax" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"
                    :tab="ProductBrandParallax" @OpenDialog="OpenDialog" :i="i"
                    />
                     <v-chip v-if="!ProductBrandParallax && ThisProduct && ThisProduct.Store_Product_Brand">
            {{ThisProduct.Store_Product_Brand.Name}}
        </v-chip>
                    <v-layout justify-center v-if="ThisProduct">
                    <v-flex xl8 lg9 md10 sm11 xs12 class="justify-center">
             <v-row :style="userLoggedIn ? 'padding-top:20px;' : 'padding-top:20px;'"
            class="justify-center" align="left"
       
      >
       
      
       
        <!-- <v-col cols="12" class="storecard" :xl="3" :lg="3" :md="12" :sm="12" :xs="12">
   
            <v-img :src="ThisProduct.IMG" class="imgplay" :width="$vuetify.breakpoint.md ? '50%' : '100%'" v-if="ThisProduct && ThisProduct.IMG"/>
      
        </v-col> -->
       <v-col cols="12" style="padding: 3px;" :xl="9" :lg="9" :md="8" :sm="12" :xs="12">
            <v-card height="100%" elevation="0" tile class="transparent" v-if="ThisProduct">
                <v-img :src="ThisProduct.IMG" class="imgplay" :width="PrimImgWidth" v-if="ThisProduct && ThisProduct.IMG"/>
                <v-card  height="100%" flat tile class="transparent" v-if="ThisProduct">
                <v-card-text class="primary--text" v-html="ThisProduct.Title" :style="'font-family: Metrophobic;font-size:24px;line-height: 1.4;'">
                </v-card-text>
                <v-list dense class="transparent">
                    <v-list-item v-if="$store.state.DefaultCurrency.Currency">
                         <span class="red--text display-1">{{CurrencyFormatter(ThisProduct.Price,$store.state.DefaultCurrency.Currency)}} <span v-if="ActiveStore && ActiveStore.StoreDeliveryMethod !== 'Collection Only'" class="orange--text"> + Shipping Fees</span></span>
                    </v-list-item>
                    <v-list-item class="overline" v-if="Ridiculousvariancesubcollectionoptions">
                        Color: 
                    </v-list-item>
                    <v-list-item v-if="Ridiculousvariancesubcollectionoptions">
                        <v-chip-group>
                            <v-chip v-for="n in 5"
                            :key="n">
                            red
                            </v-chip>

                        </v-chip-group>
                    </v-list-item>
                    <v-list-item class="overline">
                        About this item: 
                    </v-list-item>
                    <ul class="caption" v-html="ThisProduct.DescriptiveText">
                    </ul>
                    
                </v-list>
            </v-card>   
            </v-card>        
       </v-col>
       <v-col class="justify-center" cols="12" style="padding: 3px;" :xl="3" :lg="3" :md="4" :sm="12" :xs="12"   >
           <v-btn width="100%" v-if="ActiveGalleryImages.length > 3" dark :disabled="startindex === 0" class="accent justify-center" outlined  @click="startindex--,endindex--">
                    <v-icon class="mx-10"  color="white">mdi-chevron-up</v-icon>
                    </v-btn>
           <v-layout class="justify-center">
               
            <v-card elevation="0" tile class="transparent" v-if="ThisProduct" :style="MiniView ? 'width: 65%;' : 'width: 100%;float: right;align-self: center;'">
                
            <!-- <div style="width: 100%;float: right;"> .slice(startindex,endindex)-->
                
                    <v-img v-for="(img,cardindex) in ActiveGalleryImages.slice(startindex,endindex)" @click="OpenGalleryDialog(cardindex,ActiveGalleryImages)" :key="img.itemObjKey" :style="MiniView ? 'width: 100%;cursor: zoom-in;' : 'width: 100%;float: right;cursor: zoom-in;'" :src="img.ThumbURL"/>
                <!-- </div> -->
               
               
            </v-card>
            
             </v-layout>
              <v-btn width="100%" v-if="ActiveGalleryImages.length > 3" dark :disabled="endindex === ActiveGalleryImages.length" class="accent justify-center" outlined @click="startindex++,endindex++">
                    <v-icon class="mx-10" color="white">mdi-chevron-down</v-icon>
                    </v-btn>
       </v-col>
             </v-row>
            <v-row
      class="justify-center transparent"
      
    >
    
    <v-col cols="12" v-if="PurchaseWithProducts && PurchaseWithProducts.length > 0">
        <GalleryTab  :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
            :PageHeadersFontSize="PageHeadersFontSize" :tab="ComputedGalleryTab"
            :HeaderFontFamily="'font-family: Roboto, sans-serif;font-size:50px;'"
                                  />
    </v-col>
            </v-row> 
                    </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
        <v-card tile style="padding: 0px;" v-if="StoreHasRatings">
            <v-card-title style="padding-bottom: 0px;" class="links white--text largeoverline">Reviews</v-card-title>
                    <!-- send message for groups only -->
            <StoreRatingComponent v-if="userLoggedIn"
        :Ratings="ItemRatings" :ItemName="ThisProduct.Title" :CanWriteReview="UserCanWriteReview" @EmitReviewMethod="EmitReviewMethod" :CanSendMessage="false"
            @EmitSendMessageMethod="EmitSendMessageMethod" :CanViewReplies="CanViewReplies"
        />
        </v-card>
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import GalleryTab from '@/components/WebPages/RenderComponents/GalleryTabReadOnly';
import ParallaxTab from '@/components/WebPages/RenderComponents/ParallaxTabReadOnly';
import StoreRatingComponent from "@/components/WebPages/RenderComponents/RatingComponent";
import ContentEditableField from '@/components/Database/ContentEditableField'
import { ar } from 'date-fns/locale';
export default {
    props: ['System','SystemEntities','Directories'],
    components: {
        GalleryTab,
        ParallaxTab,
        StoreRatingComponent,
        ContentEditableField
    },
    data(){
        return {
            CalledRatings: false,
            ItemRatings: [],
            MessagefromUser: '',
      MessageGroupDialog: false,
      GroupRatingActive: false,
      GroupRating: 3,
      NewMessage: {
        recipientid: '',
        senderid: '',
        message: '',
        Created_On: '',
        Read: false
      },
      DefaultNewMessage: {
        recipientid: '',
        senderid: '',
        message: '',
        Created_On: '',
        Read: false
      },
            startindex: 0,
            endindex: 3,
            GalleryDialog: false,
            GalleryDialogIndex: 0,
            HTMLBox: {
                BoxTransparency: 'transparent',
                RowColor: '#00ffff',									
                Name: 'HTML Box',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: ``,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#ffffff'									
                },									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Elevation: 0,
                HeaderFontSize: 50,		
                //DisplayasChip: true						
            },
            ProductObj: '',
            alignments: [
        'start',
        'center',
        'end',
      ],

            // AllStoreProducts: [],
            pricerange: [0,5000],
            ActiveStoreFilters: {},
            ActiveBrandFilter: '',  
            ActiveProductFilter: '',
            PageContentFont: 'Montserrat',
        PageHeadersFontSize: 42,
        PageHeadersFont: 'Raleway',
        }
    },
    computed: {
        StoreHasRatings(){
            return this.VuexStoreCatNavlist && this.VuexStoreCatNavlist.Has_Ratings
        },
        CanViewReplies(){
            return this.userLoggedIn
        },
        UserHasOrderedProduct(){
            return this.userLoggedIn
        },
        UserHasPriorRating(){
            return !this.userLoggedIn
        },
        UserCanWriteReview(){
            return this.UserHasOrderedProduct && !this.UserHasPriorRating
        },
        UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
        DefaultBillingAccount(){
          return this.UserBillingAccounts && this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) : ''
      },
        WishListProduct(){
            let obj = {
            }
            if(this.ThisProduct && this.ThisProduct.id){
                obj.id = this.ThisProduct.id
                obj.Photo = this.ThisProduct.Photo.ThumbURL
                obj.IMG = this.ThisProduct.IMG
                obj.DescriptiveText = this.ThisProduct.DescriptiveText
                obj.Title = this.ThisProduct.Title,
                obj.Added_On = new Date(),
                obj.StoreCollection = this.$route.params.slug
            }
            return obj
        },
        WishListItems(){
            return this.DefaultBillingAccount && this.DefaultBillingAccount.WishList ? this.DefaultBillingAccount.WishList : []
        },
        IteminWishlist(){
            return this.ThisProduct && this.ThisProduct.id ? this.WishListItems.find(obj => obj.id === this.ThisProduct.id) : ''
        },
        RouteObj(){
            return this.$route
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        VuexStoreCatNavlist(){
        return this.$store.state.CatNavlists.find(obj => obj.EntityID === this.$route.params.slug)
    },
        VuexStoreStateProducts(){
            return this.VuexStoreCatNavlist ? this.VuexStoreCatNavlist.Data : []
        },
        PurchaseWithProducts(){
            let arr = []
            if(this.ThisProduct && this.ThisProduct.Purchased_With){
                return this.VuexStoreStateProducts.filter(prod => {
                    return this.ThisProduct.Purchased_With[prod.id] && prod.id !== this.$route.params.id
                })
            }
            return arr
        },
        PrimImgWidth(){
            if(this.$vuetify.breakpoint.xl){
                return '45%'
            }
            else if(this.$vuetify.breakpoint.lg){
                return '50%'
            }
            else if(this.$vuetify.breakpoint.md){
                return '75%'
            }
            else{
                return '80%'
            }
            
        },
        WindowHeight(){
        return window.innerHeight
      },
      ArrowRightStyle(){
        return this.ActiveGalleryImages[this.GalleryDialogIndex] && this.ActiveGalleryImages[this.GalleryDialogIndex].Caption? 'top:'+(this.WindowHeight/2)+'px;right:'+(15+250)+'px;' : 'top:'+(this.WindowHeight/2)+'px;right:15px;'
      },
      ArrowLeftStyle(){
        return 'top:'+(this.WindowHeight/2)+'px;'
      },
        ActiveGalleryImages(){
            return this.ThisProduct && this.ThisProduct.Assets ? this.ThisProduct.Assets
            //.concat(this.ThisProduct.Assets,this.ThisProduct.Assets,this.ThisProduct.Assets)
            : []
        },
        StoreSystemObj(){
            return this.$store.state.SystemObj
        },
        ProductBrandParallax(){
            if(this.ThisProduct && this.ThisProduct.Store_Product_Brand && this.ThisProduct.Store_Product_Brand.Logo){
                let brand = this.ThisProduct.Store_Product_Brand
               let defaulttab = {									
                RowColor: '#ffffff',									
                Name: 'Parallax',									
                SectionType: true,									
                Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-image-area',									
                Height: 200,									
                Elements: [],									
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            }
            defaulttab.IMG = brand.Logo
            let titletab = Object.assign({},this.HTMLBox)
            titletab.Title = brand.Name
            if(brand.Store_Read_MoreLink){
                //titletab.ChipRoute = brand.WikiPath
                titletab.HasActionButton = true
                titletab.ButtonAlignment = 'justify-end'
                titletab.ButonColor = {
                    hex: '#ffffff',
                    hexa: '#ffffff'
                }
                titletab.Outlined = true
                titletab.Fancy = true
                if(!brand.Store_Writeup){
                    titletab.ActionButtonName = 'View'
                    titletab.ActionButtonType = 'URL'
                    titletab.ActionButtonRoute = brand.Store_Read_MoreLink   
                }
            }
            if(brand.Store_Writeup){
                    let actioncontenttab = Object.assign({},this.HTMLBox)
                   
                    
                    if(brand.Store_Read_MoreLink){
                        actioncontenttab.ActionButtonName = 'View'
                        actioncontenttab.ActionButtonType = 'URL' 
                        actioncontenttab.ActionButtonRoute = brand.Store_Read_MoreLink
                         actioncontenttab.HasActionButton = true
                        actioncontenttab.ButtonAlignment = 'justify-end'
                        actioncontenttab.ButonColor = {
                            hex: '',
                            hexa: ''
                        }
                    }
                    
                    actioncontenttab.Description = brand.Store_Writeup
                    actioncontenttab.Title = brand.Name
                    if(brand.Logo){
                        actioncontenttab.HasHeaderImage = true
                        actioncontenttab.Justify = 'justify-center'
                        actioncontenttab.HeaderImage = brand.Logo
                    }
                    titletab.ContentDialogType = {
                        ComponentName: 'HTML Box'
                    }
                    //console.log(actioncontenttab)
                    titletab.HasActionButton = true
                    titletab.ButtonAlignment = 'justify-end'
                    titletab.ButonColor = {
                        hex: '#ffffff',
                        hexa: '#ffffff'
                    }
                    titletab.Outlined = true
                    titletab.Fancy = true
                    titletab.ActionButtonName = 'Read More'
                    titletab.ActionButtonType = 'Content Dialog'                    
                    titletab.ContentDialogBox = actioncontenttab
                }
            //console.log(titletab)
            defaulttab.Elements.push(titletab)
            return defaulttab 
            }
            
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        // ThisProductEntity(){
        //     return this.SiteEntities.find(obj => obj.id === this.ThisProduct.EntityID)
        // },
        // ProductFinalClass(){
        //     return this.ThisProductEntity.Levels(lvl => {
        //         return lvl
        //     })
        // },
        // ProductFinalClassUp(){
        //     return this.ThisProductEntity.Levels(lvl => {
        //         return lvl
        //     })
        // },
        ThisProductClassCrumbs(){
            return [{
                   text: 'BusinessUnits',
                    disabled: false,
                    to: '/BusinessUnits',
               },
               {
                   text: 'BusinessUnits2',
                    disabled: false,
                    to: '/BusinessUnits2',
               }]
        },  
        pricemax(){
            if(this.AllStoreProductsRendered.length > 0){
             let Prices = this.AllStoreProductsRendered
            .map(prod => {
                return prod.Price
            })
            //console.log(Prices,Math.max(...Prices))
            return Math.max(...Prices)   
            }
            
        },
        pricemin(){
            if(this.AllStoreProductsRendered.length > 0){
            let Prices = this.AllStoreProductsRendered
            .map(prod => {
                return prod.Price
            })
            //console.log(Prices)
            return Math.min(...Prices)
            }
        },
      NavFont(){
        if(this.NavStyleFont){
          return 'font-family: '+this.NavStyleFont+';font-size: 14px;'
        }
        else{
          return 'font-size: 14px;'
        }
        
      },
      StoreProductsEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.IsStoreProduct
            })
        },
        StoreBrands(){
          return this.AllStoreProductsRendered
          .filter(prod => {
                return typeof prod.Store_Product_Brand !== 'undefined'
            })
            .map(entity => {
              return entity.Store_Product_Brand
            }).flat()
        },
        StoreFilters(){
          return this.StoreProductsEntities
          .filter(entity => {
                return entity.StorehasFilter && entity.StoreFilters.length > 0
            })
            .map(entity => {
              return entity.StoreFilters
              .map(filter => {
                filter.EntityID = entity.id
                return filter
              })
            }).flat()
        },
        ProductsClassesNEW(){
            return this.StoreProductsEntities
            .filter(entity => {
                return entity.HierarchyField
            })
            .map(entity => {
                let HierarchyField = entity.AllFields.find(obj => obj.id === entity.HierarchyField.id)
                 if(HierarchyField && HierarchyField.Levels && HierarchyField.Options){
                let array = JSON.parse(JSON.stringify(HierarchyField.Options))      
                return array
                .map(option => {
                    //console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    id: option.ID,
                    EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                })
                
            }
            }).flat()  
        },
        AllStoreProductsRendered(){
            return this.VuexStoreStateProducts
            .filter(prod => {
                return prod.Price >= this.pricerange[0] && prod.Price <= this.pricerange[1]
            })
            .filter(prod => {              
              return typeof prod.FilterObstruction === 'undefined' || prod.FilterObstruction  === false
            })
            .filter(product => {
                if(this.ActiveProductFilter && this.ActiveProductFilter.EntityID){
                    let entityobj = this.StoreProductsEntities.find(obj => obj.id === this.ActiveProductFilter.EntityID)
                    let HierarchyField = entityobj.AllFields.find(obj => obj.id === entityobj.HierarchyField.id)
                    let level = HierarchyField.Levels.find(obj => obj.id === this.ActiveProductFilter.Levelid)
                    //console.log(this.ActiveProductFilter,HierarchyField)
                    return product.EntityID === this.ActiveProductFilter.EntityID && product[level.FieldName] && product[level.FieldName].ID === this.ActiveProductFilter.id
                }
                else{
                    return product
                }
            })
            .filter(prod => {
              if(this.ActiveBrandFilter){
                return prod.Store_Product_Brand.ID === this.ActiveBrandFilter.ID
              }
              else{
                return prod
            }
            })
            
            
        },
        ComputedGalleryTab(){
            let obj = Object.assign({},this.ThisProduct)
            if(obj){
             obj.Elevation = 3
             delete obj.Description
             obj.BGColor = {
                 hexa: '#eeeeee'
             }
             obj.Elevation = 0
             obj.Tile = true
             obj.BoxTransparency = 'transparent'
             obj.Title = 'Clients who bought this item also bought:'
             obj.HeaderFontSize= 20

             obj.Center = 'justify-start'
             obj.HeaderColor = {hex: '#898989'}
             obj.FontColor = {hex: '#898989'}
             obj.Assets = this.PurchaseWithProducts.map(prod => {
                 let prodassetobj = {
                     URL: prod.Photo.url,
                     
                     ThumbURL: prod.Photo.ThumbURL,
                     RoutePath: '/StoreItem/'+this.$route.params.slug+'/'+prod.id
                 }
                 if(!prod.Store_Product_Brand){
                     prodassetobj.Caption = prod.Title
                 }
                 else{
                     prodassetobj.Caption = ``+prod.Store_Product_Brand.Name+`<br>`+prod.Title
                 }
                 //console.log(prodassetobj)
                 return prodassetobj
             })
             delete obj.FullScreenDialog
            //  obj.
            //  obj.Assets = obj
            //  obj.RowTransparency = 'transparent'
            //  obj.BoxTransparency = 'transparent'
            //     obj.Justify = 'justify-center'
            //obj.ExtendWithGalleryParallax = true
            return obj  
            }
        },
        ThisProduct(){
            return Object.assign({},this.VuexStoreStateProducts.find(obj => obj.id === this.$route.params.id))
           
            
        },
    },
    watch: {
        RouteObj : {
            handler: function(newvalue, oldvalue) {
            // if(this.$route.name !== 'landingpagesingle' && this.$route.meta && this.$route.meta.RouteEventID === 1028 && this.$route.meta && this.$route.meta.RouteEventID === 1027){
            // }
            if(newvalue, oldvalue){
                this.ScrolltoTop()
                this.ItemRatings = []
            }
            },
            deep: true
    },
      ThisProduct: {
            handler: function(oldvalue, newvalue) {
                //console.log(oldvalue, newvalue)
              if(oldvalue !== newvalue){                 
                let entity = this.Directories.find(obj => obj.id === this.$route.params.slug)
                this.$store.commit('SetCartEntity',entity.Cart_Entity)
                this.$emit('PushActiveProduct',this.ProductObj)
                //   this.ProductObj = this.ThisProduct
                //   if(!this.ProductObj.Warehouses || this.ProductObj.Warehouses && this.ProductObj.Warehouses.length === 0){
                //     this.ProductObj.Warehouses = []
                //     this.GetProductWarehouseEntries()  
                //   }  
                if(this.userLoggedIn && !this.CalledRatings && this.ThisProduct){
                    
                   this.GetProductRatings()   
                }        
                        
              }
            },deep: true
        },
      StoreSystemObj : {
            handler: function(newvalue, oldvalue) {
            // if(this.$route.name !== 'landingpagesingle' && this.$route.meta && this.$route.meta.RouteEventID === 1028 && this.$route.meta && this.$route.meta.RouteEventID === 1027){
            // }
            if(newvalue.AppDefaultHeader){
            this.PageHeadersFont = newvalue.AppDefaultHeader.Name
            this.PageHeadersFontSize = newvalue.AppDefaultHeader.SizeMultiplier
            }
            if(newvalue.AppDefaultBody){
            this.PageContentFont = newvalue.AppDefaultBody.Name
            }             
              
            },
            deep: true
    },
    VuexStoreStateProducts: {
           handler: function(newvalue, oldvalue) {
               //console.log(newvalue, oldvalue)
                if(oldvalue !== newvalue){
                 //this.AllStoreProducts = this.VuexStoreStateProducts
                }
            },
            deep: true
    },
    
    },
    created(){
        if(this.ThisProduct && this.userLoggedIn){
            this.GetProductRatings()
        }
        this.ScrolltoTop()
        this.$emit('ActivateStoreView',true)
        //this.GetAllStoreProducts()
        let newvalue = this.System
        //console.log(this.System)
        if(newvalue.AppDefaultHeader){
            this.PageHeadersFont = newvalue.AppDefaultHeader.Name
            this.PageHeadersFontSize = newvalue.AppDefaultHeader.SizeMultiplier
            }
            if(newvalue.AppDefaultBody){
            this.PageContentFont = newvalue.AppDefaultBody.Name
            }   
            //console.log(this.$store.state.CatNavlists,this.VuexStoreStateProducts,this.ThisProduct)
            if(this.ThisProduct){
                let entity = this.Directories.find(obj => obj.id === this.$route.params.slug)
                this.$store.commit('SetCartEntity',entity.Cart_Entity)
                this.$emit('PushActiveProduct',this.ProductObj)
            }
    },
    methods: {
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
        GetProductRatings(){
            this.CalledRatings = true
            db.collection('usermessages').where('OfficialRepresentative','==',false).where('relatedid','==',this.$route.params.id).where('IsProductRating','==',true).onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    item.Replies = []
                    this.ItemRatings.push(item)
                    if(this.CanViewReplies){
                      db.collection('usermessages').where('IsProductRating','==',true).where('Replytoid','==',item.id).onSnapshot(replyres => {
                        const rechanges = replyres.docChanges();
                        rechanges.forEach(rechange => {
                        if (rechange.type === 'added') {
                            let reply = {
                            ...rechange.doc.data(),
                            id: rechange.doc.id
                            }
                            item.Replies.push(reply)
                        }
                        })
                     })  
                    }
                     
                    
                }  
                })
                //console.log('this.SentMessages',this.SentMessages,this.AllUserConversations,this.AllUserConversationsAscending)
            })
        },
        SendMessagetoGroup(){
      let NewMessage = Object.assign({},this.NewMessage)
      NewMessage.Created_On = new Date()
      if(this.GroupRatingActive){
        NewMessage.IsProductRating = true
        NewMessage.Rating = this.GroupRating
      }
       db.collection('usermessages').add(NewMessage).then(doc => {
          this.DeactivateMessageGroupDialog()         
       })
    },
        EmitReviewMethod(){
        this.ActivateGroupRating()
        },
        EmitSendMessageMethod(){
        this.ActivateMessagetoGroup()
        },
        ActivateGroupRating(){
        this.GroupRatingActive = true
        this.ActivateMessagetoGroup()
        },
        ActivateMessagetoGroup(){      
        //this.DefaultNewMessage.recipientid = 'icMHmsfU9XMr8ZMjFoSIJXrvLdY2',
        //who?????no createdby and now BU either? who? has to eb setup
        this.DefaultNewMessage.senderid = this.userLoggedIn.id,
        //this.NewMessage.recipientid = 'icMHmsfU9XMr8ZMjFoSIJXrvLdY2',
        this.NewMessage.senderid = this.userLoggedIn.id,
        this.NewMessage.relatedid = this.ThisProduct.id,
        this.NewMessage.topicid = this.$route.params.slug
        this.NewMessage.relatedtype = this.$route.params.slug.split('_').join('')
        this.NewMessage.Title = this.ThisProduct.Title,
        // this.NewMessage.grouplogo = this.GroupData.logo,
        this.NewMessage.OfficialRepresentative = false
        this.NewMessage.IsProductRating = true
        this.MessageGroupDialog = true
        },
        DeactivateMessageGroupDialog(){
        this.MessageGroupDialog = false
        this.GroupRatingActive = false
        this.GroupRating = 3
        this.NewMessage = Object.assign({},this.DefaultNewMessage)
        },
        AddtoWishlist(){
            if(this.DefaultBillingAccount){
             let WishList = []
            if(this.DefaultBillingAccount && this.DefaultBillingAccount.WishList){
                WishList = this.DefaultBillingAccount.WishList
            }
            let oncheck = WishList.find(obj => obj.id === this.WishListProduct.id)
            if(!oncheck){
                WishList.push(this.WishListProduct)
            }
            else{
                let index = WishList.indexOf(oncheck)
                WishList.splice(index,1)
            }
            db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('billingaccounts').doc(this.DefaultBillingAccount.id).update({
                WishList: WishList
            })   
            }
            
        },
        ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        FullScreenNavigate(event){
        let vm = this
        if (event.code === 'ArrowLeft' && vm.GalleryDialog && vm.ActiveGalleryImages[vm.GalleryDialogIndex-1]) {
            vm.GalleryDialogIndex = vm.GalleryDialogIndex-1
            
          }
          else if(event.code === 'ArrowRight' && vm.GalleryDialog && vm.ActiveGalleryImages[vm.GalleryDialogIndex+1]){
            vm.GalleryDialogIndex = vm.GalleryDialogIndex+1
            
          }
      },
        OpenGalleryDialog(cardindex){
        
        if(typeof cardindex !== 'undefined'){
          this.GalleryDialogIndex = cardindex
        }
        else{
          this.GalleryDialogIndex = 0
        }
        this.GalleryDialog = !this.GalleryDialog
        setTimeout(() => {
          if(this.GalleryDialog){
            document.addEventListener('keydown', this.FullScreenNavigate);
          }
          else{
            document.removeEventListener('keydown', this.FullScreenNavigate);
          }
        }, 50);
      },
        GetProductWarehouseEntries(){
            //alert('getting warehouses')
            db.collection(this.$route.params.slug.split('_').join('').toLowerCase()+'store').doc(this.$route.params.id).collection('Warehouses').onSnapshot(res => {
                let length = res.docs.length
                if(length === 0){
                    this.$emit('PushActiveProduct',this.ProductObj)
                    //console.log(this.ProductObj)
                }
                const changes = res.docChanges();									
                changes.forEach(change => {									
                    if (change.type === 'added') {
                        this.ProductObj.Warehouses.push({									
                        ...change.doc.data(),									
                        id: change.doc.id									
                    })
                    if(this.ProductObj.Warehouses.length === length){
                        this.ProductObj.Stock_Qty = this.ProductObj.Warehouses.map(wh => {
                            if(!wh.Available){
                                wh.Available = 0
                            }
                            return wh.Available
                        }).reduce((a, b) => a + b, 0)
                        let entity = this.Directories.find(obj => obj.id === this.$route.params.slug)
                        //console.log('entity',entity,this.Directories)
                        this.$store.commit('SetCartEntity',entity.Cart_Entity)
                        this.$emit('PushActiveProduct',this.ProductObj)
                        //console.log(this.ProductObj,this.$store.state.Cart_Entity)
                    }
                    }
                })
            })
        },
       CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        
    }
}
</script>
<style>
.imgplay:hover{
    transform: translate3d(0, 0%, 0);
    animation: fill 2500ms forwards     
}
.imgplay{
    transition: transform 500ms;
     transform: translate3d(4%, 20%, 0);
}
</style>