          
<template>
  <v-card v-if="!PushedID" color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >

  <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="ExporttoPDFFunctions"
    :FullItem="PDFUserData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" 
    />
  

  
    
  <v-dialog width="400" v-model="ChangeStatusDialog">
          <v-card width="400">
            <v-card-title>Change Status</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Status"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
             v-if="Status"
            :items="Status.Options"
            v-model="Status_Reason"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <v-toolbar tile dark :color="Status && Status.ID === 1000001 ? 'accent':'warning'"  width="100%" id="recordtoolbar" v-if="!WikiMode">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
        <v-list-item v-if="!UserWelcomed" @click="ConfirmSendWelcomeMessage(item)" class="listoutline">
          <v-list-item-title class="subtleoverline">
            Welcome User
            </v-list-item-title>
          <v-list-item-action>
            <v-icon class="actionicon"        
            color="red"
          >
            mdi-help
          </v-icon>
          </v-list-item-action>
          </v-list-item>             
          <v-list-item v-if="UserWelcomed" class="listoutline">
          <v-list-item-title class="subtleoverline">
            User Welcome Sent
            </v-list-item-title>
          <v-list-item-action>
            <v-icon class="actionicon"        
            color="green"
          >
            mdi-check-bold
          </v-icon>
          </v-list-item-action>
          </v-list-item>
          <v-list-item @click="DisableUserDialog()" v-if="!UserisDisabled"  class="listoutline">
          <v-list-item-title class="subtleoverline">
            Disable User
            </v-list-item-title>
          <v-list-item-action>
            <v-icon class="actionicon" color="red">mdi-account-off-outline</v-icon>
          </v-list-item-action>
          </v-list-item>
          <v-list-item @click="EnableUserDialog()" v-if="UserisDisabled" class="listoutline">
          <v-list-item-title class="subtleoverline">
            Enable User
            </v-list-item-title>
          <v-list-item-action>
            <v-icon class="actionicon" color="blue">mdi-account-tie</v-icon>
          </v-list-item-action>
          </v-list-item>
          <v-list-item @click="ResetUserPasswordDialog()" class="listoutline">
          <v-list-item-title class="subtleoverline">
            Reset Password
            </v-list-item-title>
          <v-list-item-action>
            <v-icon class="actionicon" color="blue">mdi-lock-reset</v-icon>
          </v-list-item-action>
          </v-list-item>
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
         <v-list-item @click="ChangeStatusDialog = !ChangeStatusDialog" class="listoutline">
            <v-list-item-title  class="subtleoverline">
              {{StatusToggleName}}
            </v-list-item-title>
          <v-list-item-action>
              <v-icon class="actionicon" color="white">mdi-power</v-icon>
            </v-list-item-action>
          </v-list-item>
        <v-list-item @click="UpdateUser()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditUser()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
    <v-list-item v-if="ExporttoPDFFunctions && ExporttoPDFFunctions.length>0" @click="ExportingtoPDFDialog = !ExportingtoPDFDialog">
      <v-list-item-title>Export to PDF</v-list-item-title>
      <v-list-item-action>
      <v-icon color="warning">mdi-file-pdf</v-icon>
      </v-list-item-action>
    </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title>
   <v-list width="100%" class="transparent">
   <v-list-item>
  <v-list-item-content >
    <span class="headline"><span class=" hidden-sm-and-down">User - </span><span class="headline font-weight-light">{{ Full_Name  }}</span></span>                   
    <v-list-item-subtitle v-if="Status">
       {{Status.Name}}
    </v-list-item-subtitle>
  </v-list-item-content>
  </v-list-item>
    </v-list>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
  <LookupComponent :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersStore" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
  <v-icon style="background-color:green;" @click="UpdateUser()" color="white" class="soloactionicon">mdi-content-save</v-icon>
  </v-layout>
  

  
  
    

  
    <!--<EmailComponent v-if="previewsendemaildialog" :PresetEmailBody="EmailBody" :PresetEmailFrom="EmailFrom" :PresetEmailTo="EmailTo" :PresetEmailCC="EmailCC" :PresetEmailBCC="EmailBCC" :PresetEmailSubject="EmailSubject" :previewsendemaildialog="previewsendemaildialog"/> -->
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the User help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingUser" tile  :dark="AppisDarkMode" width="100%" class="stickytopbanner3" id="recordownershipbanner1">    
</v-card>
<v-card v-if="EditingUser" :dark="AppisDarkMode" tile width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">User - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Full_Name" label="Full Name" />
  </v-card-text>
  </v-img>
</v-card> 
  
  
  

        <v-dialog v-model="ResetPasswordDialog" max-width="500">
    <v-card>
    <v-img
    width="500"
    src="@/assets/RABaseBG-Soft.png"
    ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
      <v-card-title class="mediumoverline white--text">
       Password Reset
      </v-card-title></div>
      <v-card-text>
        <v-select v-model="ModulePath" :items="Modules" label="Module" :rules="[$store.state.formrules.required]" item-text="Name" return-object/>
      </v-card-text>
      <v-card-actions class="white documenttypeshadowinvert">
        <v-btn color="green" dark  class="overline" @click="ProcessUserPasswordReset()">Process</v-btn>
      </v-card-actions>
    </v-img>
    </v-card>
  </v-dialog>

  <v-layout class="justify-center">
       <v-flex lg8 md10>
            <v-layout row>
              <v-col class="my-5 slightmargin" cols="12" sm="6">
                <v-card class="transparent" flat height="100%"  shaped>
                  <v-card shaped dark flat width="90%" class="mx-4 primary text-xs-center elevatedsectionheader">    
                    <v-card-title class="align-center">
                      User General<v-spacer></v-spacer><v-icon v-if="userIsAdmin" @click="EditingUserGeneral = !EditingUserGeneral">mdi-pencil</v-icon>
                    </v-card-title>
                    <v-card-subtitle >
                    Edit
                    </v-card-subtitle>             
                  </v-card>
                    <v-card height="100%" shaped :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }">
                      <v-card flat height="60" class="overlayed transparent">
                        </v-card>
                      <v-card
                          class="mx-auto"
                          max-width="434"
                          width="95%"
                          
                          tile
                          dark
                          color="rgba(47, 47, 47, 0.7)"
                        >
                      <v-row class="mx-3 justify-space-between">
                        <v-col row>
                          <v-avatar
                            color="grey"
                            size="164"                      
                          >
                          <v-img src="@/assets/BlankProfilePic.png" v-if="!profilephotolocalURL && !Profile_Photo"><v-btn v-if="!selectedprofilephotoFile" @click="$refs.profilephotofileInputer.click()" color="white" dark icon absolute top right><v-icon v-if="userIsAdmin">mdi-pencil</v-icon></v-btn></v-img>
                          <v-img :src="profilephotolocalURL" v-if="profilephotolocalURL"></v-img>
                          <v-img v-if="!profilephotolocalURL && Profile_Photo" :src="Profile_Photo"><v-btn v-if="!selectedprofilephotoFile" @click="$refs.profilephotofileInputer.click()" color="white" dark icon absolute top right><v-icon v-if="userIsAdmin">mdi-pencil</v-icon></v-btn></v-img>
                          <!-- <v-btn :href="Profile_Photo" v-if="!selectedprofilephotoFile" target="_new" color="#eb371b"  fab dark icon><v-icon>mdi-eye</v-icon></v-btn> -->
                        </v-avatar>
                        </v-col>
                        <v-col row>
                          <h4 v-if="!EditingUserGeneral" class="white--text">Full Name</h4>
                          <v-spacer></v-spacer>
                          <h4 v-if="!EditingUserGeneral" class="light-blue--text">{{ Full_Name }}</h4>
                          <!-- <h4 v-if="EditingUserGeneral" class="white--text">Name</h4>
                          <v-spacer></v-spacer> -->
                          <v-text-field label="Name" v-if="EditingUserGeneral" class="light-blue--text" v-model="Name"></v-text-field>
                          <!-- <h4 v-if="EditingUserGeneral" class="white--text">Surname</h4>
                          <v-spacer></v-spacer> -->
                          <v-text-field label="Surname" v-if="EditingUserGeneral" class="light-blue--text" v-model="Surname"></v-text-field>
                          <h4 v-if="!EditingUserGeneral" class="white--text">Business Unit</h4>
                          <v-spacer></v-spacer>
                          <router-link :to="'/BusinessUnit/'+Business_Unit.id" v-if="Business_Unit"><h4 v-if="!EditingUserGeneral" class="light-blue--text">{{Business_Unit.Name}}</h4></router-link><a v-if="!Business_Unit && HasBusiness_Unit" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedBusiness_Unit.Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedBusiness_Unit.Name }}"</p></a>
                          <span v-if="EditingUserGeneral"><v-combobox
                              v-if="Business_Unit || !Business_Unit && !HasBusiness_Unit"
                              v-model="Business_Unit"
                              :items="Business_UnitArray"
                              item-text="Name"
                              hide-selected
                              label="Business Unit"
                              small-chips
                              solo
                              flat
                              >
                              <template v-slot:prepend-item>
                                  <v-list>                            
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-btn small @click="PrepareLinkExistingBusiness_Unit()" color="warning" dark text>Lookup More</v-btn>
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        <v-btn small @click="ActivateAddNewBusiness_UnitDialog()" color="green" dark text>Add New</v-btn>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </template></v-combobox>
                              <div  class="unpermitted" v-if="!Business_Unit && HasBusiness_Unit">
                              <v-combobox
                              v-model="UnpermittedBusiness_Unit"
                              readonly
                              :items="Business_UnitArray"
                              item-text="Name"
                              hide-selected
                              label="Business Unit"
                              small-chips
                              solo
                              flat
                              ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedBusiness_Unit.Name }}", and therefore cannot change the linked record</p></div>
                            </span>
                          <h4 v-if="!EditingUserGeneral" class="white--text">Manager</h4>
                          <v-spacer></v-spacer>
                          <h4 class="light-blue--text" v-if="Manager && Manager.Full_Name && !EditingUserGeneral">{{ Manager.Full_Name }}</h4>
                           <!-- <UserLookup :UsersLookupArray="PotentialManagers"  :ModelProp="'Manager'" :RecordObj="this" @UpdateUserLookupProp="UpdateUserLookupProp"
                                      :FieldLabel="'Manager'" :Rules="[$store.state.formrules.required]"
                                      /> -->
                          <v-combobox
                           v-if="EditingUserGeneral"
                            v-model="Manager"


                            :items="PotentialManagers"
                            @change="AssignUsersHierarchy()"
                            item-text="Full_Name"
                            hide-selected
                            label="Manager"
                            small-chips
                            solo
                            flat
                            ></v-combobox>
                        </v-col>
                        <!-- <v-list class="transparent">
                          <v-list-item>
                            <v-list-item-avatar class="blue" tile size="80">
                              <img v-if="UserPicture "
                              :src="UserPicture "              
                              >
                              <img v-if="!UserPicture "
                                src="@/assets/BlankProfilePic.png"              
                              >                      
                              </v-list-item-avatar>
                            <v-list-item-content >
                              <v-list-item-title>{{UserRecord.Full_Name}}</v-list-item-title>                    
                              <v-list-item-subtitle>
                                <router-link  :to="'/MyProfile'">View My Profile</router-link>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{UserRecord.Email}}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                Online: Online Now
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                    </v-list>                           -->
                        </v-row>
                      </v-card>
                    </v-card>
                </v-card>
              </v-col>
              <v-col class="my-5 slightmargin" cols="12" sm="6">
                <v-card class="transparent" flat height="100%"  shaped>
                  <v-card shaped dark flat width="90%" class="mx-4 primary text-xs-center elevatedsectionheader">    
                    <v-card-title class="align-center">
                      Permissions General<v-spacer></v-spacer>
                      <v-icon v-if="userIsAdmin" @click="EditingPermissionsGeneral = !EditingPermissionsGeneral">mdi-pencil</v-icon>
                    </v-card-title>
                    <v-card-subtitle >
                    Edit
                    </v-card-subtitle>             
                  </v-card>

                    <v-card height="100%" shaped>
                      <v-card flat height="60" class="overlayed transparent">
                        </v-card>
                     <v-list dense class="transparent">
                       <v-list-item>
                            <v-list-item-content>
                              DB Navlist
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-select :items="DBNavLists" return-object dense item-text="Name" clearable
                              :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateDBNavList()" v-model="DBNavList"></v-select>
                            </v-list-item-action>
                          </v-list-item>
                          <!-- MonetizationOfficer -->
                          <v-list-item>
                            <v-list-item-content>
                              Monetization Officer
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateMonetizationOfficer()" v-model="MonetizationOfficer"></v-switch>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              Eagle View
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateEagleView()" v-model="IsEagleViewer"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                              Business Profile Admin
                            </v-list-item-content>
                            <v-list-item-action>
                             <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateBusinessProfileAdmin()" v-model="BusinessProfileAdmin"></v-switch>
                            </v-list-item-action>
                          </v-list-item> 
                          <v-list-item>
                            <v-list-item-content>
                              Site Content Admin
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateSiteContentAdmin()" v-model="SiteContentAdmin"></v-switch>
                            </v-list-item-action>
                          </v-list-item> 
                          <v-list-item>
                            <v-list-item-content>
                             Guest User
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin" @change="UpdateIsGuestUser()" v-model="IsGuestUser"></v-switch>
                            </v-list-item-action>
                          </v-list-item> 
                          <v-list-item>
                            <v-list-item-content>
                               System Admin
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin" @change="UpdateSystemAdmin()" v-model="IsSystemAdmin"></v-switch>
                            </v-list-item-action>
                          </v-list-item>     
                          <v-list-item>
                            <v-list-item-content>
                               Manages Others
                            </v-list-item-content>
                            <v-list-item-action>
                               <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin" @change="UpdateUserTaskManager()" v-model="IsTeamActivityManager"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                               Invite Users
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateCanInviteUsers()" v-model="CanInviteUsers"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                               Export Data
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateCanExport()" v-model="CanExport"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                               Import Data
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense :disabled="!EditingPermissionsGeneral || !userIsAdmin || IsSystemAdmin" @change="UpdateCanImport()" v-model="CanImport"></v-switch>
                            </v-list-item-action>
                          </v-list-item>                      
                        </v-list>
                    </v-card>
                </v-card>
              </v-col>
            </v-layout>
           <v-card
        light
              :style="{ backgroundImage: 'url(' + require('@/assets/BusCardBG.jpeg') + ')'  ,backgroundSize: 'cover'}"
              
              >
              <v-icon v-if="userIsAdmin" @click="EditingVcard = !EditingVcard">
                mdi-pencil
              </v-icon>
                <v-list dense 
                class="transparent">
              <v-list-item>
                <v-list-item-avatar tile size="120">
                  <v-img contain src="@/assets/logo.png"></v-img>
                </v-list-item-avatar>
              </v-list-item>
              <hr>
              <v-list-item class="caption">              
                <p>For top Quality Vehicles:<br>
             Sales and Financing</p>         
              </v-list-item>
              <hr>
              <v-list-item>
                  <v-list-item-content >
                      <v-list-item-title class="overline">{{Full_Name}}</v-list-item-title>      
                      <v-list-item-subtitle v-if="!EditingVcard">
                      {{Position}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="EditingVcard">
                      <v-text-field v-model="Position" label="Position"/>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  <v-list-item-content >
                      <v-list-item-title v-if="!EditingVcard && Business_Unit">{{Business_Unit.Name}}</v-list-item-title> 
                      <v-list-item-title v-if="EditingVcard"><v-combobox
                              v-if="Business_Unit || !Business_Unit && !HasBusiness_Unit"
                              v-model="Business_Unit"
                              :items="Business_UnitArray"
                              item-text="Name"
                              hide-selected
                              label="Business Unit"
                              small-chips
                              solo
                              flat
                              >
                              <template v-slot:prepend-item>
                                  <v-list>                            
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-btn small @click="PrepareLinkExistingBusiness_Unit()" color="warning" dark text>Lookup More</v-btn>
                                      </v-list-item-content>
                                      <v-list-item-content>
                                        <v-btn small @click="ActivateAddNewBusiness_UnitDialog()" color="green" dark text>Add New</v-btn>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </template></v-combobox>   
                      </v-list-item-title> 
                      <v-list-item-subtitle v-if="EditingVcard">
                      <v-text-field v-model="Work_Number" label="Work Number" />
                      </v-list-item-subtitle>  
                      <v-list-item-subtitle v-if="!EditingVcard">
                        {{Work_Number}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="EditingVcard">
                      <v-text-field v-model="Fax_Number" label="Fax Number" />
                      </v-list-item-subtitle>  
                      <v-list-item-subtitle v-if="!EditingVcard">
                        {{Fax_Number}}
                      </v-list-item-subtitle>  
                      <v-list-item-subtitle v-if="EditingVcard">
                      <v-text-field v-model="Mobile_Number" label="Mobile Number" />
                      </v-list-item-subtitle>  
                      <v-list-item-subtitle v-if="!EditingVcard">
                        {{Mobile_Number}}
                      </v-list-item-subtitle>               
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-icon small  @click="CreateVCard()">mdi-smart-card</v-icon>
                      <v-btn small icon :href="'/SiteMember/'+this.$route.params.id" target="_blank"  >
                        <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                    </v-list-item-content>
              </v-list-item>
            </v-list>
           

            </v-card>

        
                <v-card height="30px" flat class="transparent">
                </v-card>
                <v-layout row class="justify-start mx-3"><span class="primary--text headline" id="SecurityPermissions">Security Permissions</span></v-layout>
                <v-layout row v-if="!EditingUser">
                  <v-col class="my-5 slightmargin" cols="12" sm="12">
                    <v-card class="transparent" flat height="100%"  shaped>
                      <v-card shaped dark flat width="90%" class="mx-4 primary text-xs-center elevatedsectionheader">    
                        <v-card-title class="align-center">
                          User Roles
                          <!-- <v-spacer></v-spacer><v-icon v-if="CanEdit" @click="EditingSection1 = !EditingSection1">mdi-pencil</v-icon> -->
                        </v-card-title>
                        <!-- <v-card-subtitle >
                        Edit
                        </v-card-subtitle>              -->
                        </v-card>
                        <v-card height="100%" shaped>
                          <v-card flat height="60" class="overlayed transparent">
                            </v-card>
                            <v-expansion-panels ::dark="AppisDarkMode">
                              <v-expansion-panel :disabled="!userIsAdmin">
                                <v-expansion-panel-header> Data Filters
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-select multiple :items="SystemTableFilters" return-object dense item-text="Name" clearable
                              :disabled="!userIsAdmin || IsSystemAdmin" @change="UpdateUserTableFilters()" v-model="UserTableFilters"></v-select>
                                     <v-btn to="/SystemTableFilters" @click="ActivateTableFilterdialog()">Add System Filter</v-btn> 
                                  
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel :disabled="!userIsAdmin">
                                <v-expansion-panel-header> Activity Queries
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-switch label="Related Owner" dense @change="UpdateActivityQueries()" v-model="ActivitiesRegardingOwner"></v-switch>
                                    <v-switch label="Related Owner Manager" dense @change="UpdateActivityQueries()" v-model="ActivitiesRegardingSubordinateOwner"></v-switch>
                                    <v-switch label="Related Business Unit" dense @change="UpdateActivityQueries()" v-model="ActivitiesRegardingUnit"></v-switch>
                                    <v-switch label="Related Business Unit in Structure" dense @change="UpdateActivityQueries()" v-model="ActivitiesRegardingUnitStructure"></v-switch>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                        </v-card>
                    </v-card>
                  </v-col>
                </v-layout>
            
          
           





   
      </v-flex>
  </v-layout>


    

  


   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
        
          
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-icon x-large class="soloactionicon" style="background-color:red;" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
  </v-layout>
  

  
  
    </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
import { jsPDF } from "jspdf";
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
import LookupComponent from '@/components/Database/LookupComponent'
import Appointments from '@/components/SuitePlugins/Activities/Appointments'
import RecordTasks from '@/components/SuitePlugins/Activities/RecordTasks'
import RecordEmails from '@/components/SuitePlugins/Activities/RecordEmails'
import UserLookup from '@/components/General/UserLookup'
export default {
  props: ['AppisDarkMode','PushedID','DBNavLists','System','SystemEntities','SystemTableFilters'],
components: {
  ContentEditableField,
  jsPDF,
  PDFExporter,
  SystemTicketsLogging,
  LookupComponent,
    Appointments,
    RecordTasks,
    RecordEmails,
    UserLookup
},
    

  data() {
    return {
      ActiveWarehouse: '',
      WarehouseActions: [
        {Display: 'Requisitions',Prop: 'Requisitions'},
        {Display: 'Ordering',Prop: 'Ordering'},
        {Display: 'Receiving',Prop: 'Receiving'},
        {Display: 'Picking',Prop: 'Picking'},
        {Display: 'Packing',Prop: 'Packing'},
        {Display: 'Dispatching',Prop: 'Dispatching'},
        {Display: 'Auditing',Prop: 'Auditing'},
      ],
      ActivitiesRegardingQueries: ['ActivitiesRegardingOwner','ActivitiesRegardingSubordinateOwner','ActivitiesRegardingUnit','ActivitiesRegardingUnitStructure'],
      ActivitiesRegardingOwner: false,
      ActivitiesRegardingSubordinateOwner: false,
      ActivitiesRegardingUnit: false,
      ActivitiesRegardingUnitStructure: false,
      RouteID: 0,
      ExporttoPDFFunctions: [],
      ExportingtoPDFDialog: false,
      ChangeStatusDialog: false,
  
      EditingPermissionsGeneral: false,
      CanExport: false,
      CanImport: false,
      EditingUserGeneral: false,
      ResetPasswordDialog: false,
      Modules : [
      {
        Name: 'RapidApps',
        URL: ''
      },
      {
        Name: 'RapidApps',
        URL: ''
      },
      ],
      ModulePath: '',
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      
      SiteContentAdmin: false,
      IsGuestUser: true,
      BusinessProfileAdmin: false,
      DBNavList: '',
      IsEagleViewer: false,
      MonetizationOfficer: false,
      IsSystemAdmin: false,
      ModerationPermissions: [],
      ModerationPermissionsOptions: [
        'Articles',
        'Blogs',
        'Events',
        'Forums',
        'Polls',
        'Groups',
        'Meetups',
        'Members',
        'Landing Pages',
        'Banner Ads',
        'Notices',
        'Wiki',
        'Glossary',
        'Public Articles',
        'Public Blogs',
        'Public Events',
        'Public Forums',
        'Public Polls',
        'Public Groups',
        'Public Meetups',
        'Public Members',
        'Public Landing Pages',
        'Public Banner Ads',
        'Public Notices',
        'Public Wiki',
        'Public Glossary',
      ],
      SiteModerators: [],
      IsSiteModerator: false,
      BannerTabs: [
        ],
      TicketLog: false,
      step: 1,
      rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      previewsendemaildialog: false,
      EmailBody: '',
      EmailFrom: '',
      EmailTo: '',
      EmailCC: '',
      EmailBCC: '',
      EmailSubject: '',
      STDAppointmentsQuery: '',
      AppointmentsQuery: '',
      ActivitiesArray: [],
      NotesArray: [],
      Full_Name: '',
      Surname: '',
      Name: '',
      Business_Unit:  {id: '', name: ''},
      UnpermittedBusiness_Unit:  {id: '', name: ''},
      HasBusiness_Unit: false,
      Business_UnitRelatedParentObj: {},
      Manager:  {id: '', Full_Name: ''},
      UnpermittedManager:  {id: '', Full_Name: ''},
      HasManager: false,
      ManagerRelatedParentObj: {},
      Profile_Photo: '',
      Notification_Emails: '',
      Is_Team_Activity_Manager: '',
      Business_Unitname: null,
      ManagerFull_Name: null,
      
        Profile_Photofilesnackbar: false,
        selectedProfile_PhotoFile: '',
        Profile_PhotolocalURL: '',
      
      
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      Business_UnitArray: [],
      Business_Unitfilter: {name: ''},
      
      
      Managerfilter: {Full_Name: ''},
      
      EditingUser: false,
      dialog: false,
      initialize: '',
      search: '',
      UserData: {},
      RecordObj: '',
      headers: [
      
        
        { text: 'Full Name', value: 'Full_Name'},
        
        
        { text: 'Surname', value: 'Surname'},
        
        
        { text: 'Name', value: 'Name'},
        
        
        
        { text: 'Business Unit', value: 'Business_Unit.name'},
        
        
        { text: 'Manager', value: 'Manager.Full_Name'},
        
        { text: 'Profile Photo', value: 'Profile_Photo'},
        
        
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager'},
        
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      EditingVcard: false,
      Mobile_Number: '',
      Work_Number: '',
      Company: '',
      Position: '',
      CompanyPositions: [],
      Birthday: '',
      BirthDayMenu: false,
      Fax_Number: '',
      Full_Name:  '',
      Name:  '',
      Surname:  '',
      Business_Unit:  {id: '', Name: ''},
      Manager:  {id: '', Full_Name: ''},
      Profile_Photo:  '',
      ManagerFull_Name: null,
      profilephotofilesnackbar: false,
      selectedprofilephotoFile: '',
      profilephotolocalURL: '',
      Business_UnitArray: [],
      BusinessUnitfilter: {Name: ''},
      Managerfilter: {Full_Name: ''},
      EditingUser: false,
      dialog: false,
      initialize: '',
      search: '',
      helpsheet: false,
      headers: [
        
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      Buildsbysystem: [
        {
          id: "Business_Units",
          tempname: "Business Unit"
        },
        
        {
          id: "Users",
          tempname: "User"
        },
        ],
      AssignedRoles: [],
      SecurityRoles: [
      {
          Description: 'Default Guest, assigning only the most basic "Signed In" rights',
          DisplayName: "Guest",
          Name: "Guest"
        },],
      UserTableFilters: [],
      UserTableFiltersKeys: [],
      RolesArray: [],
      snackbar: false,
      EditedUsersnackbar: false,
      UserEditdialog: false,
      newUserdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      IsTeamActivityManager: false,
      CanInviteUsers: false,
      
      
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ManagersToUpdate: [],
      SubordindatesArray: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: [],
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      IsAdmin: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    if(this.PushedID){
      this.RouteID = this.PushedID
    }
    else {
      this.RouteID = this.$route.params.id
    }
    this.STDAppointmentsQuery = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').where('activitytype','==','Appointment').where('regardingtype', '==', 'User').where('regardingrecord.id', '==', this.RouteID)
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)
this.GetBusinessUnitsBusinessUnit()


this.GetRequestingUser()
window.addEventListener('scroll', this.handleScroll)

  },
  
  computed: {
    ActiveWarehouses(){
      return this.$store.state.WarehousesArray
    },
    ManagerArray(){
      return this.$store.state.UsersArray.filter(user => {
        return !user.IsGuestUser && user.id !== this.$route.params.id
      })
    },
    ComputedModerationPermissionsOptions(){
      let arr1 = this.ModerationPermissionsOptions
      let arr2 = this.SystemEntities.filter(obj => obj.Entity_Type && obj.Entity_Type.Name === 'Classified Listing').map(entity => {
        return entity.id.split('_').join(' ')+' Classified Listings'
      })
      return arr1.concat(arr2)
    },
    
        ComputedSingleTabsLayout(){
    return this.SingleTabsLayout.map(tab => {
      if(tab.Type === 'SubCollection'){
        tab.Children = []
        tab.Sections.map(section => {            
          let array = this[section.SubCollectionName].map(item => {
            item.Name = item[section.PrimaryValue]
            return item
          })
          console.log(array)
          tab.Children = tab.Children.concat(array)
        })
      }
      else{
        tab.Children = tab.Sections
      }
      console.log(tab)
      return tab
    })
  },
    DynamicWikiData(){
      let fullobject = Object.assign({},this.UserData)
      fullobject.Collection = 'userswiki'
      fullobject.RecordPrimaryFieldName = 'Full_Name'
      fullobject.Path = this.$route.meta.subcat.split(' ').join('')
      fullobject.SubCollections = [
      ]
      fullobject.Fields = [
        {
          Name: 'Full_Name',
          Type: 'Full_Name'
        },
        {
          Name: 'Surname',
          Type: 'Surname'
        },
        {
          Name: 'Name',
          Type: 'Name'
        },
        {
          Name: 'Business_Unit',
          Type: 'Business_Unit'
        },
        {
          Name: 'Manager',
          Type: 'Manager'
        },
        {
          Name: 'Profile_Photo',
          Type: 'Profile_Photo'
        },
        {
          Name: 'Notification_Emails',
          Type: 'Notification_Emails'
        },
        {
          Name: 'Is_Team_Activity_Manager',
          Type: 'Is_Team_Activity_Manager'
        },
      ]
      fullobject.Business_Unit = this.Business_Unit
      fullobject.Manager = this.Manager
      return fullobject
    },
    PDFUserData(){
      let fullobject = this.UserData
      fullobject.RecordPrimaryFieldName = 'Full_Name'
      fullobject.Business_Unit = this.Business_Unit
      fullobject.Manager = this.Manager
      return fullobject
    },
      userisSiteContentAdmin(){
        return this.$store.state.SiteContentAdmin || this.userIsAdmin
      },
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
    ComputedStageID(){
    return this.ProcessStages[this.StageID-1].Number
  },
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
          return true
        }
      else{
      return this.userIsAdmin
      }
    }
    else{
      return this.userIsAdmin
    }

  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  ActivitiesSearched() {
    return this.ActivitiesArray.filter(Activity => {
      if(Activity.Name){return Activity.Name.toLowerCase().includes(this.search.toLowerCase())}}).filter(Activity => {if(Activity.description){return Activity.description.toLowerCase().includes(this.search.toLowerCase())}
    })
    },
    ActivitiesFilteredandSearched() {
    return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
    },
    TasksFilteredandSearched(){
        return this.ActivitiesFilteredandSearched.filter(activity => {
            return activity.activitytype === 'Task' && this.DateFilterString(activity.duedate) === this.FormattedTextDate && activity.status.includes(this.TaskStatusFilter)
        })
    },
    TasksFilteredandSearchedTable(){
        return this.TasksFilteredandSearched.filter(activity => {
            return activity.duedate === this.TaskFilterDate
        })
    },
    FormattedPickDate(){
      return this.DateFormatter(this.TaskFilterDate)
    },
    FormattedTextDate(){
      return this.TaskFilterDate.split('-').join('')
    },
      PotentialManagers() {
    return this.UsersStore.filter(unit => {
      return !this.SubordindatesArray.includes(unit.id) && unit.id !== this.$route.params.id
    })
  },
  BirthdayObject(){
    console.log(this.Birthday)
    if(this.Birthday){
      return this.DateFormatter(this.Birthday)
    }
  
},  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    },
    PDFUserData(value){
      if(value && this.PushedID){
        setTimeout(() => {
          this.$emit('updateFullItem',this.PDFUserData)
        }, 2000);        
      }
    }
  },
  methods: {
    // UpdateWarehousePermissions(action){
    //   console.log(action,this.ActiveWarehouse)
    //   let boolean = this.ActiveWarehouse[action.Prop]
    //   let arrayprop = action.Prop+'Clerks'
    //   if(!boolean){
    //     let oncheck = this.ActiveWarehouse[arrayprop].find(obj => obj === this.$route.params.id)
    //     if(oncheck){
    //       let index = this.ActiveWarehouse[arrayprop].indexOf(oncheck)
    //       this.ActiveWarehouse[arrayprop].splice(index,1)
    //     }
    //   }
    //   else{
    //     this.ActiveWarehouse[arrayprop].push(this.$route.params.id)
    //   }
    //   db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('Warehouses').doc(this.ActiveWarehouse.id).update({
    //     [arrayprop]: this.ActiveWarehouse[arrayprop]
    //   })
    // },
    // CheckWarehousePermissions(){
    //   let wh = this.ActiveWarehouse
    //   this.WarehouseActions.map(act => {
    //     if(!wh[act.Prop+'Clerks']){
    //       wh[act.Prop+'Clerks'] = []
    //     }
    //     if(wh[act.Prop+'Clerks'].includes(this.$route.params.id)){
    //       wh[act.Prop] = true
    //     }
    //     else{
    //       wh[act.Prop] = false
    //     }
    //   })
    // },
    UpdateActivityQueries(){
      this.ActivitiesRegardingQueries.map(query => {
        if(typeof this[query] !== 'undefined'){
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.$route.params.id).update({
            [query]: this[query]
          })
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
            [query]: this[query]
          })
        }
      })
    },
    
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
    ToggleDialog(dialogname){
      this[dialogname] = !this[dialogname]
    },
    AddItemtoSubCol(storeobj,SelectedProductsArrayName){
      this[SelectedProductsArrayName].push(storeobj)
    },
    
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
              if(vm.UserRecord !== 'undefined'){
            vm.AppointmentsQuery = vm.STDAppointmentsQuery.where('Ownerid', '==', vm.UserRecord.id)
                vm.UserRoles = vm.UserRecord.rolesarrayQuery
                let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.GetUser(AdminTest)
                        
                 let BusinessUnitsBusiness_UnitQuery = this.$store.state.BusinessUnitsQuery
                 //console.log(typeof BusinessUnitsBusiness_UnitQuery,BusinessUnitsBusiness_UnitQuery)
                    if(BusinessUnitsBusiness_UnitQuery){
                    vm.GetBusinessUnitsBusiness_UnitArrayandLookupValue(BusinessUnitsBusiness_UnitQuery)
                    }
                

                        
                
                

                }
                else{
                  vm.RoutetoErrorPage()
                }
              }
              
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      GetExporttoPDFFunctions(){
        console.log(this.$route.meta.subcat.split(' ').join('_'))
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions').where('FunctionEntity','==',this.$route.meta.EntityID).onSnapshot(res => {
          const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    this.ExporttoPDFFunctions.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                })
        })
      },
      GetUser() {
        let vm = this
        if(!this.PushedID && this.$route.meta && this.$route.meta.EntityID){
          this.GetExporttoPDFFunctions()  
        }
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).onSnapshot(snapshot => {
            var user = snapshot.data()
            user.id = this.RouteID
            
            this.selectedRecord = {id: this.RouteID, Full_Name: user.Full_Name }
            
            this.RelatedObj = {id: this.RouteID, Full_Name: user.Full_Name,identifier: 'Full_Name',collection: 'users',Link:'User' }
                  let ActivitiesCollectionReference = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities')
                  let ActivitiesstandardQuery = ActivitiesCollectionReference.where('regardingtype', '==', 'User').where('regardingrecord.id', '==', vm.RouteID)
                  let ActivityRegardingQuery =  ActivitiesstandardQuery;
                  vm.GetActivities(ActivityRegardingQuery)
            this.UserData = user
            this.Status_Reason = user.Status_Reason
            this.UserData.id = this.RouteID
            this.RecordObj = this.UserData
            this.$emit('PushRecordName',this.UserData.Full_Name)
              this.GetUserSecurityPermissions().then(function(result) {
              console.log('Got User Security Structure')
              })
              if(typeof user.Mobile_Number !== 'undefined'){
                this.Mobile_Number = user.Mobile_Number
              }
              if(typeof user.Work_Number !== 'undefined'){
                 this.Work_Number = user.Work_Number
              }
              if(typeof user.Fax_Number !== 'undefined'){
                 this.Fax_Number = user.Fax_Number
              }
              if(typeof user.Birthday !== 'undefined'){
                 this.Birthday = format(user.Birthday.toDate(),'yyyy-MM-dd')
                 console.log(this.Birthday)
              }
              if(typeof user.Position !== 'undefined'){
                 this.Position = user.Position
              }
              if(typeof user.Full_Name !== 'undefined'){
                this.Full_Name = user.Full_Name
              }
              if(typeof user.Name !== 'undefined'){
                this.Name = user.Name
              }
              if(typeof user.Surname !== 'undefined'){
                this.Surname = user.Surname
              }
              if(typeof user.Profile_Photo !== 'undefined'){
                this.Profile_Photo = user.Profile_Photo
              }
            if(typeof user.notesarray !== 'undefined'){
            this.NotesArray = user.notesarray
              }
            if(typeof user.Owner !== 'undefined'){
              this.Business_Unit = user.Business_Unit
            }
            
                
        this.Full_Name = user.Full_Name
        this.editedItem.Full_Name = user.Full_Name
        //I think API Trigger should be here, outside the first if loop on fields
        this.Surname = user.Surname
        this.editedItem.Surname = user.Surname
        //I think API Trigger should be here, outside the first if loop on fields
        this.Name = user.Name
        this.editedItem.Name = user.Name
        //I think API Trigger should be here, outside the first if loop on fields
        if(user.Business_Unit){
        this.Business_UnitRelatedParentObj = {id: user.Business_Unit.id, Full_Name: user.Full_Name,identifier: 'name',collection: 'businessunits',Link: 'BusinessUnit' }
          }
        //I think API Trigger should be here, outside the first if loop on fields
              this.Business_Unit = this.Business_UnitArray.find(business_unit => business_unit.id === user.Business_Unitid)
            
        if(typeof user.Business_Unitid !== 'undefined' && user.Business_Unitid !== ''){
          this.HasBusiness_Unit = true
          if(typeof this.Business_Unit === 'undefined'){
            this.UnpermittedBusiness_Unit = {id: user.Business_Unitid,name: user.Business_Unit.name}
          }
        }
        this.editedItem.Business_Unit = user.Business_Unit
        if(user.Manager){
        this.ManagerRelatedParentObj = {id: user.Manager.id, Full_Name: user.Full_Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
          }
        //I think API Trigger should be here, outside the first if loop on fields
            this.Manager = this.UsersStore.find(manager => manager.id === user.Managerid)
            
        if(typeof user.Managerid !== 'undefined' && user.Managerid !== ''){
          this.HasManager = true
          if(typeof this.Manager === 'undefined'){
            this.UnpermittedManager = {id: user.Managerid,Full_Name: user.Manager.Full_Name}
          }
        }
        this.editedItem.Manager = user.Manager
        this.Profile_Photo = user.Profile_Photo
        this.editedItem.Profile_Photo = user.Profile_Photo
        //I think API Trigger should be here, outside the first if loop on fields
        this.Notification_Emails = user.Notification_Emails
        this.editedItem.Notification_Emails = user.Notification_Emails
        //I think API Trigger should be here, outside the first if loop on fields
        this.Is_Team_Activity_Manager = user.Is_Team_Activity_Manager
        this.editedItem.Is_Team_Activity_Manager = user.Is_Team_Activity_Manager
        //I think API Trigger should be here, outside the first if loop on fields

            })
          
        },
      
      


    
      
      
            SwitchtoDynamicWiki(){
      let wikirouteobj = { name: 'entitywiki', params: {DynamicWikiData: this.DynamicWikiData }}
      this.$router.push(wikirouteobj)
    },
      UpdateOptions(fieldname){
      let intname = fieldname+'LastIntegerID'
      this[intname] = this[intname]-1+2
      this[fieldname] = this[fieldname].map(option => {
        let optionobj = Object.assign({},option)
        if(!option.ID){
          optionobj = {
          ID: this[intname],
          Name: option
         }
        }        
        return optionobj
      })      
    },
      UpdateRecordOptionSet(field,level,obj){
        console.log(obj)
        if(field.HasDeepHierarchy){
          let levelobj = field.Levels.find(obj => obj.id === level)
          delete levelobj.children
         
          let storeobj = {
            ID: obj.ID,
            EntityID: this.ActiveEntity.id,
            Name: obj.Name,
            Level: levelobj,
            LevelFieldName: obj.LevelFieldName,
          }
          if(obj.Options){
            storeobj.Options = obj.Options
          }
          db.collection('products').doc(this.RouteID).update({
            [levelobj.FieldName]: storeobj 
          })
        }
        else{
          db.collection('products').doc(this.RouteID).update({
            [field]: obj 
          })
        }
      },
      toggleMultipleRadio(fieldname,_id) {
            console.log(_id,this[fieldname],this[fieldname].includes(_id))
            this[fieldname].map(opt => {
              if(opt.ID === _id.ID){
                console.log('well we see it this way')
              }
            })
            if (this[fieldname].includes(_id)) {
            this[fieldname] = this[fieldname].filter((i) => {
            return i !== _id;
            });
        } else {
            this[fieldname] = this[fieldname].concat(_id);
        }
            
        },
      GetCols(int){
      if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg){
        return int
      }
      else if(this.$vuetify.breakpoint.md){
        return int*1.5
      }
      else if(this.$vuetify.breakpoint.sm){
        return int*2.5
      }
      else{
        return int*3
      }
    },
      ResetStatus(){
      this.Status = Object.assign({},this.UserData.Status)
      this.Status_Reason = Object.assign({},this.UserData.Status_Reason)
      this.ChangeStatusDialog = false
    },
    UpdateStatus(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
        Status: this.Status,
        Status_Reason: this.Status_Reason
      })
      this.ChangeStatusDialog = false
    },
      GetShortLivedURL(item){
      console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
      ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode')
      },
      CreateVCard(){
         let birthday = format(new Date(),'yyyy-MM-dd')
         let item = 
`BEGIN:VCARD
VERSION:3.0
N:`+this.Surname+`;`+this.Name+`
FN:`+this.Name+` `+this.Surname+`
ORG:`+this.Company+`
TITLE:`+this.Position+`
ADR;TYPE=WORK,POSTAL,PARCEL:;;`+this.Business_Unit.AddressLine1+`;`+this.Business_Unit.AddressLineCity+`;`+this.Business_Unit.AddressLineProvince+`;`+this.Business_Unit.AddressLinePostalCode+`;`+this.Business_Unit.AddressLineCountry+`
TEL;TYPE=WORK,VOICE:`+this.Work_Number+`
TEL;TYPE=CELL,VOICE:`+this.Mobile_Number+`
TEL;TYPE=WORK,FAX:`+this.Fax_Number+`
EMAIL:`+this.UserData.Email+`
BDAY;VALUE=DATE:`+birthday+``
         console.log(item)
        var link = document.createElement("a");
        let fullcardcontent = "data:text/vcf;charset=UTF-8,"+item
        link.setAttribute("href", fullcardcontent);
        let cardname = this.Name+' '+this.Surname+'.vcf'
        link.setAttribute("download", cardname);
        document.body.appendChild(link); // Required for FF

      link.click();
       },
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateUser(){
        this.UndefinedMandatoryFields = []
      const EditedUser = {
    Full_Name: this.Full_Name,
    Surname: this.Surname,
    Name: this.Name,
    
        }
        console.log(EditedUser)
        
      for(var prop in EditedUser) {
          if (EditedUser.hasOwnProperty(prop)) {
            if(typeof EditedUser[prop] === 'undefined' || EditedUser[prop] === '' || EditedUser[prop] === null){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
        Full_Name: EditedUser.Full_Name,
        Surname: EditedUser.Surname,
        Name: EditedUser.Name,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedUsersnackbar = true
              this.CancelUserEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Full_Name !== 'undefined'){                  
                  let Full_NameQuery = []
                  let Full_NameStringArray = this.Full_Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(Full_NameStringArray.length,Full_NameStringArray)
                    for (p = 0; p < Full_NameStringArray.length; p++) {
                        buildup = buildup+ Full_NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    Full_NameQuery = lowertext.split(',')  
                    Full_NameQuery.length = Full_NameQuery.length-1
                   db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                     Full_NameQuery: Full_NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                 if(typeof this.Profile_Photo !== 'undefined'){
                   db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                     Profile_Photo: this.Profile_Photo,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                     })
                 }
                
                 if(typeof this.Notification_Emails !== 'undefined'){
                   db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                     Notification_Emails: this.Notification_Emails,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                     })
                 }
                
                 if(typeof this.Is_Team_Activity_Manager !== 'undefined'){
                   db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                     Is_Team_Activity_Manager: this.Is_Team_Activity_Manager,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                     })
                 }
                
                     if(typeof this.Business_Unit !== 'undefined' && typeof this.Business_Unit.id !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.RouteID).update({
                          Business_Unit: {Name: this.Business_Unit.Name, id: this.Business_Unit.id},
                            Business_Unitid: this.Business_Unit.id
                        })
                      }
                     if(typeof this.Mobile_Number !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                          Mobile_Number: this.Mobile_Number
                        })
                      }
                      if(typeof this.Work_Number !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                          Mobile_Number: this.Mobile_Number
                        })
                      }
                      if(typeof this.Fax_Number !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                          Fax_Number: this.Fax_Number
                        })
                      }
                     if(typeof this.Birthday !== 'undefined' && typeof this.BirthdayObject !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                          Birthday: this.BirthdayObject
                        })
                      }
                      if(typeof this.Position !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                          Position: this.Position
                        })
                      }
                     if(typeof this.IsTeamActivityManager !== 'undefined'){
                       db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                         IsTeamActivityManager: this.IsTeamActivityManager,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                       })
                     }
                     if(this.IsSiteModerator === true && this.ModerationPermissions){
                      db.collection('sitemoderators').doc(this.RouteID).set({
                        Full_Name: this.Full_Name,
                        Name: this.Name,
                        Surname: this.Surname,
                        ModerationPermissions: this.ModerationPermissions,                        
                      })
                      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.RouteID).update({
                        IsSiteModerator: this.IsSiteModerator,
                        
                      })
                    }
                      if(typeof this.AssignedRoles !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                         assignedroles: this.AssignedRoles,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                       })
                      }
                      if(typeof this.Full_Name !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                         Full_Name: this.Full_Name,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                       })
                      }
                      if(typeof this.Name !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                         Name: this.Name,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                       })
                      }
                      if(typeof this.Surname !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                         Surname: this.Surname,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                       })
                      }
                      
                      if(typeof this.Manager !== 'undefined'){
                        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
                         Manager: {Full_Name: this.Manager.Name+' '+this.Manager.Surname,Name: this.Manager.Name, Surname: this.Manager.Surname, id: this.Manager.id},
                         Managerid: this.Manager.id,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                       })
                      }
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.UsersStore.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(item.id).delete() && this.UsersStore.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.UsersStore.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelUserEditing(){
      this.EditingUser = false
    },
    EditUser() {
      this.EditingUser = !this.EditingUser
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetBusinessUnitsBusiness_Unit() {
  db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetBusinessUnitsBusiness_UnitArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.BusinessUnitsStore.length > 0){
      //alert('got from store')
      vm.Business_UnitArray = vm.BusinessUnitsStore
      vm.GetBusiness_Unit();
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setBusinessUnitsArray',
                    getter: 'getBusinessUnitsArray',
                    arrayname: 'BusinessUnitsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.BusinessUnitsStore)
                        vm.Business_UnitArray = vm.BusinessUnitsStore
                        vm.GetBusiness_Unit();
                        })
    }
  },

  GetBusiness_Unit(){
    this.Business_Unit = this.Business_UnitArray.find(business_unit => business_unit.id === this.UserData.Business_Unitid)
  },

  //GetBusinessUnitsBusiness_Unit(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Business_UnitArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetBusinessUnitsBusiness_Unit finished')
 //   })
 // })
//    })
//  },

  

  

  GetManager(){
    this.Manager = this.ManagerArray.find(manager => manager.id === this.UserData.Managerid)
  },




CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingBusiness_Unit(){
this.CancelAddParentDialog()
this.PrepareDataParentBusiness_Unit()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Business Unit'
      this.LookupArray = this.Business_UnitArray
      this.LookupArrayHeaders = [
        { text: 'Description', value: 'Description',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Parent', value: 'Parent.name',class: "overline"},
      ]
},

PrepareDataParentBusiness_Unit(){
  this.ParentTitle = 'Business_Unit'
  this.ParentCollection = 'businessunits'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Business_Unit', Prop: 'Business_Unit', Propid: 'Business_Unitid', Value: '',Array: 'Business_UnitArray',LookupFieldName: 'name'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Business Unit', Prop: 'Business_Unit', Value: '',LookupFieldName: 'name'}
        let DescriptionObj = {Type: 'Multiple Lines Text', Name: 'Description',Prop: 'Description'}
  this.NewParentAdditional.push(DescriptionObj)
        let ParentObj = {Type: 'Lookup', Name: 'Parent',Prop: 'name',Array: this.ParentArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(ParentObj)     
  
},

ActivateAddNewBusiness_UnitDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentBusiness_Unit()
},


PrepareLinkExistingManager(){
this.CancelAddParentDialog()
this.PrepareDataParentManager()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Manager'
      this.LookupArray = this.ManagerArray
      this.LookupArrayHeaders = [
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Business Unit', value: 'Business_Unit.name',class: "overline"},
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
      ]
},

PrepareDataParentManager(){
  this.ParentTitle = 'Manager'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Manager', Prop: 'Manager', Propid: 'Managerid', Value: '',Array: 'ManagerArray',LookupFieldName: 'Full_Name'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Manager', Prop: 'Manager', Value: '',LookupFieldName: 'Full_Name'}
        let Business_UnitObj = {Type: 'Lookup', Name: 'Business Unit',Prop: 'name',Array: this.Business_UnitArray, Propid: 'nameid', LookupFieldName: 'name', Value: ''}
  this.NewParentAdditional.push(Business_UnitObj)
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj)
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj)
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj)
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj)
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj)     
  
},

ActivateAddNewManagerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentManager()
},



      
    
      
      
    
      
    
      
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          DateFilterString(date){      
      let onedate = date.toDate()
      let dateyear = onedate.getFullYear().toString()
      let datemonth = onedate.getMonth()-1+2
      let datemonthfinal = datemonth.toString()
      let dateday = onedate.getDate()
      if(datemonth.toString().length===1){
        let stringdate = dateyear+'0'+datemonth+dateday
      return stringdate
      }
      else{
        let stringdate = dateyear+datemonth+dateday
      return stringdate
      }
    },
    AddNote(){
      let NewNote = {
        Title: 'New Note',
        Owner: {Full_Name: this.UserRecord.Full_Name, id:this.UserRecord.id},
        Description: 'Add Description to the Note',
        Color: '#ECEC93FF',
        TitleColor: '#EBEB6C',
        Dark: false
      }
      this.NotesArray.push(NewNote)
      this.UpdateNotesArray()
    },
    LightenColor(note) {
    let color = note.Color
  	let num = parseInt(color.replace("#",""), 16)
		let amt = Math.round(2.55 * 25)
		let R = (num >> 16) - amt
		let B = (num >> 8 & 0x00FF) - amt
		let G = (num & 0x0000FF) - amt;

		note.TitleColor = (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    },
    ToggleNoteDark(note){
      if(note.Dark === true){
        note.Dark = false
        this.UpdateNotesArray()
      }
      else{
        note.Dark = true
        this.UpdateNotesArray()
      }
    },
    UpdateNotesArray(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.RouteID).update({
        notesarray: this.NotesArray,
        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        Modified_Byid: this.UserRecord.id,
        Modified_On: new Date()
      })
    },
    
    SaveNewTask(){
      const TasktoAdd = {
            Name: this.NewTask.Name,
            description: this.NewTask.description,
            activitytype: 'Task',
            regardingtype: 'User',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: this.DateFormatter(this.NewTask.duedate),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            regardingrecord: {
              Link:'/User/'+this.RouteID, Name:this.Full_Name, Full_Name:this.Full_Name,id: this.RouteID
            },

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      if(typeof this.Business_Unit.id !== 'undefined'){
        TasktoAdd.Business_Unitid = this.Business_Unit.id
      }
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.activitytype === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Name,
              Content: TasktoAdd.description,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },

    GetActivities( regardingquery) {

      regardingquery.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },
      
      
     
          ConfirmSendWelcomeMessage(){
    // if(item.roles)
    // this.SendWelcomeMessage(item)
    let item = this.UserData
    console.log(item)
    if(this.UserRoles && this.UserRoles.length>0){
      confirm('This User has roles. Send the Welcome Message now?') && this.SendWelcomeMessage(item)
    }
    else{
      confirm('This User has NO ASSIGNED ROLES. Strongly advise you do not send welcome message. Send anyway?') && this.SendWelcomeMessage(item)
    }
  },
  SendWelcomeMessage(item){
    console.log(item)
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(item.id).update({
      Welcomed: true
    })
    
    let path = this.ModulePath.URL
    this.EmailBody = `Good Day<div><br></div><div>Your `+this.System.Name+` account has been succesfully created.</div><div>Please click on the below link below to log in.</div><div><br></div>
    <a href="`+path+`">Open the App</a><br>
    <div>Happy Networking!</div>`
    console.log(this.EmailBody)
      let EmailObject = {          
        EmailFrom: 'notification@rapidapps.co.za',
        EmailTo: item.Email,
        EmailSubject: this.System.Name+' - Account Approved!',
        EmailBody: this.EmailBody,

      }
          alert('email sending')
          console.log(EmailObject)
          const functions = firebase.functions();
          const SendEmailNow = functions.httpsCallable('sendEmailNow');
          SendEmailNow(EmailObject).then(result => {
            console.log(result)
            this.EmailBody = ''
          })

          let userobj = this.UsersStore.find(obj => obj.id === item.id)
    let userindex = this.UsersStore.indexOf(userobj)
    this.UsersStore[userindex].Welcomed = true
  },
  ResetUserPasswordDialog(){
    this.ResetPasswordDialog = true
  },
  ProcessUserPasswordReset(){
    let PasswordresetRequest = {
      Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Created_Byid: this.UserRecord.id,
      Created_On: new Date(),
      Email: this.UserData.Email,
      Userid: this.$route.params.id,
      step: 1,
      From: 'Admin Page'
    }
    db.collection('passwordresets').add(PasswordresetRequest).then(doc => {
      alert('Created a New Ticket under '+doc.id+'. Will send link to user shortly.')
      let path = this.ModulePath.URL+'/Password-Reset/'+doc.id
      this.EmailBody = `Good Day<div><br></div><div>An Administrative request to reset your `+this.System.Name+` account password has been processed.</div><div>Please click on the below link below to reset your password.</div><div><br></div>
    <a href="`+path+`">Reset Link the App</a><br>
    <div>Happy Networking!</div>` 
    let EmailObject = {          
        EmailFrom: 'notification@rapidapps.co.za',
        EmailTo: this.UserData.Email,
        EmailSubject: this.System.Name+' - Password Reset Notification!',
        EmailBody: this.EmailBody,

      }
    const functions = firebase.functions();
      const SendEmailNow = functions.httpsCallable('sendEmailNow');
          SendEmailNow(EmailObject).then(result => {
            console.log(result)
            this.EmailBody = ''
          })
    })
  },
  EnableUserDialog(){
    confirm('This will enable, and allow all previous access to the App. Are you sure?') && this.ProcessEnableUser()
  },
  
  ProcessEnableUser(){
    const functions = firebase.functions();
          const EnableUser = functions.httpsCallable('EnableUser');                
          EnableUser(this.$route.params.id).then(result => {
              console.log('disabled user supposedly')
              console.log(result);
              }).catch(error => {
              console.log("error",error);
              });
  },

  DisableUserDialog(){
    confirm('This will revoke access to the App. Are you sure?') && this.ProcessDisableUser()
  },

  ProcessDisableUser(){      
    const functions = firebase.functions();
          const DisableUser = functions.httpsCallable('DisableUser');                
          DisableUser(this.$route.params.id).then(result => {
              console.log('disabled user supposedly')
              console.log(result);
              }).catch(error => {
              console.log("error",error);
              });
  },

  AssignUsersHierarchy(){
    console.log('the first call')
    console.log(this.Manager,this.ManagerArray)
    let CurrentUnitObj = this.UsersStore.find(obj => obj.id == this.$route.params.id)
    let CurrentUnitIndex = this.UsersStore.indexOf(CurrentUnitObj)
    this.UsersStore[CurrentUnitIndex].Manager = {Name: this.Manager.Name, id: this.Manager.id}
    this.UsersStore[CurrentUnitIndex].Managerid = this.Manager.id
    console.log(this.ManagerArray,CurrentUnitObj,CurrentUnitIndex)
    // this.UpdateBusinessUnit()
    this.fixunithierarchy()
  },

  async fixunithierarchy(){
    console.log('I await')

    await this.DestroyOldHierarchy()
    console.log('Done Waiting')

    this.getParentofParent(this.Manager)
  },


  async DestroyOldHierarchy(){

    console.log('watch this')
    console.log(this.PotentialManagers)
    let FilteredUsersArray = this.PotentialManagers.filter(unit => {
      return unit.id !== this.$route.params.id
    })
    let CurrentUser = this.UsersStore.find(obj => obj.id === this.$route.params.id)
        console.log('current bu')
        console.log(CurrentUser)

    Array.from(Array(CurrentUser.Subordinatearrayquery.length).keys()).map(x => {
      this.RemoveBUfromParentQueryandDBArray(CurrentUser.Subordinatearrayquery[x])
    })
  },

  RemoveBUfromParentQueryandDBArray(BUid){
    console.log("removing "+BUid)
    // let CurrentUser = this.UsersStore.find(obj => obj.id === this.$route.params.id)
    // let BUidCheck = CurrentUser.Subordinatearrayquery.indexOf(BUid)
    // if(BUidCheck === -1){
    let BUtoRemove = this.UsersStore.find(obj => obj.id == BUid)
    let FilteredUsersArray = this.PotentialManagers.filter(unit => {return unit.id !== BUid && unit.id !== this.$route.params.id && unit.id !== BUtoRemove.Managerid && unit.Managerid !== this.$route.params.id})
    console.log('filterarray')
    console.log(FilteredUsersArray)
    FilteredUsersArray.map(unit => {
      if(BUtoRemove.Managerid !== unit.id){
        let dbchildindex = unit.Subordinatearrayquery.indexOf(BUid)
        let querychildobjectprop = unit.SubordinateArrayDBrules[BUid]

        if(dbchildindex !== -1){
          console.log('BUtoRemove '+BUtoRemove.id+' is index '+dbchildindex+' on '+unit.id)
          unit.Subordinatearrayquery.splice(dbchildindex,1)

          }
          console.log('BUtoRemove '+BUtoRemove.id+' has no index on '+unit.id)
          if(typeof querychildobjectprop !== 'undefined'){
            console.log('from '+unit.id)
          delete unit.SubordinateArrayDBrules[BUid]
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(unit.id).update({
          SubordinateArrayDBrules: unit.SubordinateArrayDBrules,
          Subordinatearrayquery: unit.Subordinatearrayquery
            })
          }
      }
      else {
        console.log('BU '+BUid+' has '+ unit.id+' as a parent')
      }
      })


      if(this.Manager.Name !== ''){
          this.ManagersToUpdate = []
          console.log('initial call for')
          console.log(this.Manager)
          console.log('Finished')
          console.log('parentarrayvs filtered')
          console.log(this.UsersStore)
          console.log(FilteredUsersArray)
        }
        // }
  },

  UpdateParent(parent){
    console.log('now calling UpdateParent')
      let FilteredUsersArray = this.PotentialManagers.filter(unit => {return unit.id !== this.$route.params.id})
      let unitdata = FilteredUsersArray.find(obj => obj.id == parent.id)

        if(typeof unitdata.Manager !== 'undefined'){
        console.log('this parent has a parent')
        console.log(unitdata)
        this.getParentofParent(unitdata.Manager)
        }
        else {
        console.log('second okay save it')
        console.log(this.ManagersToUpdate)
        this.ManagerArrayUpdate()
        }


  },

  ManagerArrayUpdate() {
    console.log('now calling ManagerArrayUpdate')
    Array.from(Array(this.ManagersToUpdate.length).keys()).map(x => {
      this.PrepareChildrenList(this.ManagersToUpdate[x])
    })
  },

  PrepareChildrenList(parent){
    console.log('now calling PrepareChildrenList')
    let EditedUnit = this.UsersStore.find(obj => obj.id == this.$route.params.id)
    // this.AddQueryandDBChildtoParent(EditedUnit.id)
    console.log("parent")
    console.log(parent)
    let unitdata = this.UsersStore.find(obj => obj.id == parent)
    console.log("unitdata")
    console.log(unitdata)
      Array.from(Array(unitdata.Subordinatearrayquery.length).keys()).map(x => {
      this.AddQueryandDBChildtoParent(EditedUnit.Subordinatearrayquery[x],parent)
    })
  },

  AddQueryandDBChildtoParent(length, parent){
    console.log('now calling AddQueryandDBChildtoParent')
    console.log(length)
    let CurrentUser = this.UsersStore.find(obj => obj.id === this.$route.params.id)
    console.log('each individually')
    console.log('parents first')
    console.log(parent)
      let FilteredUsersArray = this.PotentialManagers.filter(unit => {return unit.id !== this.$route.params.id})
      let unitdata = this.UsersStore.find(obj => obj.id == parent)
    console.log(unitdata)
    Array.from(Array(CurrentUser.Subordinatearrayquery.length).keys()).map(x => {
      this.NarrowChildofChildQueryandDBtoParent(CurrentUser.Subordinatearrayquery[x], unitdata)
    })
    // this.NarrowChildofChildQueryandDBtoParent(unitdata, CurrentUser)
  },

  NarrowChildofChildQueryandDBtoParent(unitid,unitdata){
    console.log('now calling NarrowChildofChildQueryandDBtoParent')
    let checkon = unitdata.Subordinatearrayquery.indexOf(unitid)
    if(checkon === -1){
      console.log('not on array')
      console.log(unitid)
    unitdata.Subordinatearrayquery.push(unitid)
    console.log('new array')
    console.log(unitdata.Subordinatearrayquery)
    }
    else {
      console.log()
      console.log('is on array')
    }
    let tempchildarrayQuery = unitdata.Subordinatearrayquery
    console.log(unitdata)
    unitdata.SubordinateArrayDBrules[unitid] = true
    let tempchildarrayDBRules = unitdata.SubordinateArrayDBrules
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(unitdata.id).update({
      Subordinatearrayquery: tempchildarrayQuery,
      SubordinateArrayDBrules: tempchildarrayDBRules,
    })
    // this.UpdateBusinessUnit()
  },


  getParentofParent(parent){
    console.log('now calling getParentofParent')
    console.log(parent)
    this.ManagersToUpdate.push(parent.id)
    let unitdata = this.UsersStore.find(obj => obj.id == parent.id)

      if(typeof unitdata.Manager === 'undefined'){
        console.log('has no parent')
      this.UpdateParent(unitdata)
      }
      else{
        console.log('yip has parent')
        this.ManagersToUpdate.push(unitdata.Manager.id)
        this.UpdateParent(unitdata.Manager)
      }

  },
     onprofilephotoUpload() {
      var storageRef = firebase.storage().ref('User/' + 'Profile_Photo/'+this.selectedprofilephotoFile.name);
      var uploadTask = storageRef.put(this.selectedprofilephotoFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.$route.params.id).update({
                  Profile_Photo: url,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
          })
          this.selectedprofilephotoFile = ''
              }),
                this.profilephotofilesnackbar = true



    },
    onprofilephotoFileselected(event) {
      this.selectedprofilephotoFile = event.target.files[0]
      this.profilephotolocalURL = URL.createObjectURL(this.selectedprofilephotoFile)
    },




    GetBusinessUnitsBusinessUnit() {
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.Business_UnitArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
    },

    
          
        AssignModeratorRoles(){
      let ModeratorRolesObject = {}
      let ModeratorRolesArray= []
      this.ModerationPermissions.map(role => {
        ModeratorRolesObject[role.split(' ').join('')] = true
        ModeratorRolesArray.push(role)
      })
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,                        
        })
      // db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.$route.params.id).update({
      // moderatorrolesarrayDBRules: ModeratorRolesObject,
      // moderatorrolesarrayQuery: ModeratorRolesArray,
      // })
    },
    UpdateCanInviteUsers(){
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
        CanInviteUsers: this.CanInviteUsers,
        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        Modified_Byid: this.UserRecord.id,
        Modified_On: new Date()
      })
  },
    UpdateUserTaskManager(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
        IsTeamActivityManager: this.IsTeamActivityManager
      })
    },
    UpdateUserTableFilters(){
      let newlength = this.UserTableFilters.length
      console.log(this.UserTableFiltersKeys,this.UserTableFilters)
      let length = this.UserTableFiltersKeys.length
      if(length > 0){
       this.UserTableFiltersKeys.map((filterkey,keyindex) => {
        let oncheck = this.UserTableFilters.find(obj => obj.Table === filterkey)
        if(oncheck){

        }
        else{
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
              ['UserFilter'+filterkey]: firebase.firestore.FieldValue.delete(),
          })
        }
        if(keyindex-1+2 === length && newlength > 0){
          this.UserTableFilters.map((newfilter,newfilterindex) => {
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
                ['UserFilter'+newfilter.Table]: newfilter.Field+'id',
            })
              if(newfilterindex-1+2 === newlength){
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
                    UserTableFilters: this.UserTableFilters,
                })
              }
          })
          
        }        
        else if(keyindex-1+2 === length && newlength === 0){
           db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
                    UserTableFilters: this.UserTableFilters,
                })
        }
      }) 
      }
      else{
        this.UserTableFilters.map((newfilter,newfilterindex) => {
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
                ['UserFilter'+newfilter.Table]: newfilter.Field+'id',
            })
              if(newfilterindex-1+2 === newlength){
                db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
                    UserTableFilters: this.UserTableFilters,
                })
              }
          })
      }
      
    },
    UpdateDBNavList(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
          DBNavList: this.DBNavList,
      })
    },
    UpdateMonetizationOfficer(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
          MonetizationOfficer: this.MonetizationOfficer
        })
    },
    UpdateEagleView(){
    if(this.IsEagleViewer){
      this.AssignedRoles.push({Name: 'Eagle View',DisplayName: 'Eagle View'})
      console.log(this.AssignedRoles)
      let RolesObject = {}
      let RolesArray= []
      this.AssignedRoles.map(role => {
        RolesObject[role.DisplayName.split(' ').join('')] = true
        RolesArray.push(role.DisplayName)
      })
      let rolesarrayDBRules = RolesObject
      let rolesarrayQuery = RolesArray
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
          assignedroles: this.AssignedRoles,
          rolesarrayQuery: rolesarrayQuery,
          rolesarrayDBRules: rolesarrayDBRules,
          IsEagleViewer: this.IsEagleViewer
        })
    }
    else{
      
    }
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
      IsEagleViewer: this.IsEagleViewer
    })
  },
    UpdateBusinessProfileAdmin(){
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
        BusinessProfileAdmin: this.BusinessProfileAdmin,
        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        Modified_Byid: this.UserRecord.id,
        Modified_On: new Date()
        })
    },
    UpdateSiteContentAdmin(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
          SiteContentAdmin: this.SiteContentAdmin,
          Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Modified_Byid: this.UserRecord.id,
          Modified_On: new Date()
        })
    },
    UpdateIsGuestUser(){
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
          IsGuestUser: this.IsGuestUser,
          Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
          Modified_Byid: this.UserRecord.id,
          Modified_On: new Date()
        })
    },
    
    UpdateSystemAdmin(){
  if(this.IsSystemAdmin){
    this.IsGuestUser = false
    this.AssignedRoles.push({Name: 'System Admin',DisplayName: 'System Admin'})
    console.log(this.AssignedRoles)
    let RolesObject = {}
    let RolesArray= []
    this.AssignedRoles.map(role => {
      RolesObject[role.DisplayName.split(' ').join('')] = true
      RolesArray.push(role.DisplayName)
    })
    let rolesarrayDBRules = RolesObject
    let rolesarrayQuery = RolesArray
     db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
        assignedroles: this.AssignedRoles,
        rolesarrayQuery: rolesarrayQuery,
        rolesarrayDBRules: rolesarrayDBRules,
        IsSystemAdmin: this.IsSystemAdmin,          
      })
      this.UpdateIsGuestUser()
  }
  else{
    
  }
  db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
    IsSystemAdmin: this.IsSystemAdmin
  })
},
  UpdateCanExport(){
    db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
      CanExport: this.CanExport
    })
  },
  UpdateCanImport(){
  db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
    CanImport: this.CanImport
  })
},
    GetUserSecurityPermissions(){
    let vm = this
    console.log('check for all moderators')
    return new Promise(function(resolve, reject) {
      db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('userroles').doc(vm.$route.params.id).onSnapshot(snapshot => {
        let user = snapshot.data()
         vm.GetSiteModerators().then(function(result) {
            if(typeof result !== 'undefined'){
              // console.log('checking if moderator',vm.SiteModerators.length)
              // let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.$route.params.id)
              // if(UserModeratorobj){
                vm.ModerationPermissions = result.ModerationPermissions
                vm.IsSiteModerator = true
              // }
            }
          })
        if(user.IsTeamActivityManager){
              vm.IsTeamActivityManager = user.IsTeamActivityManager
            }
            if(typeof user.CanInviteUsers !== 'undefined'){
              vm.CanInviteUsers = user.CanInviteUsers
            }
            if(typeof user.Subordinatearrayquery === 'undefined' || user.Subordinatearrayquery.length === 0){
                      let tempSubordindatesArray = []
                      tempSubordindatesArray.push(vm.$route.params.id)
                      let tempSubordinateArrayDBrules = {}
                      tempSubordinateArrayDBrules[vm.$route.params.id] = true
                      db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('userroles').doc(vm.$route.params.id).update({
                      SubordinateArrayDBrules: tempSubordinateArrayDBrules,
                      Subordinatearrayquery: tempSubordindatesArray,
                      Modified_By: {Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
                      Modified_Byid: vm.UserRecord.id,
                      Modified_On: new Date()
                        })
                    }
            else if(typeof user.SubordindatesArray !== 'undefined'){
              vm.SubordindatesArray = user.SubordindatesArray
            }
        if(user.IsSiteModerator){
              vm.IsSiteModerator = user.IsSiteModerator
            }
        if(typeof user.assignedroles !== 'undefined'){
        vm.AssignedRoles = user.assignedroles
        let admintest = vm.AssignedRoles.find(obj => obj === 'System Admin')
        if(admintest){
          vm.IsSystemAdmin = true
        }
      }
      if(user.UserTableFilters){
              vm.UserTableFilters = user.UserTableFilters
              vm.UserTableFiltersKeys = user.UserTableFilters.map(item => {return item.Table})
            }
      if(typeof user.rolesarrayQuery !== 'undefined'){
        
        let admintest = user.rolesarrayQuery.find(obj => obj === 'System Admin')
        if(admintest){
          vm.IsSystemAdmin = true
          vm.CanExport = true
          vm.CanImport = true
        }
      }      
      if(typeof user.MonetizationOfficer !== 'undefined'){
        vm.MonetizationOfficer = user.MonetizationOfficer
      }
      if(typeof user.IsEagleViewer !== 'undefined'){
        vm.IsEagleViewer = user.IsEagleViewer
      }
      vm.ActivitiesRegardingQueries.map(query => {
        if(typeof user[query] !== 'undefined'){
          vm[query] = user[query]
        }
      })
      if(user.DBNavList){
        vm.DBNavList = user.DBNavList
      }
      if(typeof user.BusinessProfileAdmin !== 'undefined'){
        vm.BusinessProfileAdmin = user.BusinessProfileAdmin
      }
      if(typeof user.SiteContentAdmin !== 'undefined'){
        vm.SiteContentAdmin = user.SiteContentAdmin
      }
      if(typeof user.IsGuestUser !== 'undefined'){
        vm.IsGuestUser = user.IsGuestUser
      }        
      if(typeof user.CanExport !== 'undefined'){
        vm.CanExport = user.CanExport
      }
      if(typeof user.CanImport !== 'undefined'){
        vm.CanImport = user.CanImport
      }
        if(typeof user.Manager !== 'undefined'){
              vm.Manager = vm.ManagerArray.find(Manager => Manager.id === user.Managerid)
            }
        if(typeof user.Business_Unit !== 'undefined'){
        vm.Business_Unit = user.Business_Unit
      }
      })
    })
    },
        GetSiteModerators(){
    let vm = this
    console.log('check for all moderators')
    return new Promise(function(resolve, reject) {
    db.collection('sitemoderators').doc(vm.$route.params.id).onSnapshot(snapshot => {
      let moderatordata = snapshot.data()
        if(moderatordata){
          resolve(moderatordata)
        }
        else{
          resolve(moderatordata)
        }
    })
    })
  },
        AssignIsModerator(){
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
            IsSiteModerator: this.IsSiteModerator,              
          }).then(result => {
            let text = `Updated User Moderator to `+this.IsSiteModerator
            if(this.IsSiteModerator){
              text = text+`. The User has `+this.ModerationPermissions.length+` Moderator Roles`
              if(this.ModerationPermissions.length === 0){
                text = text+`
                <p>Now Assign Roles to the User</p>`
                let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: text,
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
              }
              else{
                text = text+`
                <p>The User has the following Moderator Roles already assigned: </p>
                <ul>`          
                  this.ModerationPermissions.map((role,i) => {
                  text = text+`<li>`+role+`</li>`
                  if(i-1+2 === length){
                    text = text+`
                    </ul>`
                  }
                })
                let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: text,
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
              }
            }
            else{
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: text,
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent) 
            }
            
          })
      },

      AssignModerationPermissions(){
        db.collection('sitemoderators').doc(this.$route.params.id).set({
            Full_Name: this.Full_Name,
            Name: this.Name,
            Surname: this.Surname,
            ModerationPermissions: this.ModerationPermissions,                        
          }).then(result => {
        let length = this.ModerationPermissions.length
        let text = `Updated User Moderator Roles to a total of `+length+`:
        <ul>`          
        this.ModerationPermissions.map((role,i) => {
        text = text+`<li>`+role+`</li>`
        if(i-1+2 === length){
          text = text+`
          </ul>`
        }
      })
        let snackbarcontent = {
          snackbartimeout: 4000,
          snackbartext: text,
          snackbartop: true,
          snackbarmultiLine: true
        }
        this.$emit('ActivateSnackbar',true,snackbarcontent)
      })
      },

        AssignRoles(){
      const EditedUser = {
        assignedroles: this.AssignedRoles
      }
      let RolesObject = {}
      this.RolesArray= []
      this.AssignedRoles.map(role => {
        RolesObject[role.DisplayName.split(' ').join('')] = true
        this.RolesArray.push(role.DisplayName)
      })
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(this.$route.params.id).update({
      rolesarrayDBRules: RolesObject,
      rolesarrayQuery: this.RolesArray,
      assignedroles: EditedUser.assignedroles,
            Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            Modified_Byid: this.UserRecord.id,
            Modified_On: new Date()
      }).then(result => {
        let length = this.RolesArray.length
        let text = `Updated User Perimssions to a total of `+length+`:
        <ul>`          
        this.RolesArray.map((role,i) => {
        text = text+`<li>`+role+`</li>`
        if(i-1+2 === length){
          text = text+`
          </ul>`
        }
      })
        let snackbarcontent = {
          snackbartimeout: 4000,
          snackbartext: text,
          snackbartop: true,
          snackbarmultiLine: true
        }
        this.$emit('ActivateSnackbar',true,snackbarcontent)
      })
    },
     
    ProcessDeleteItem(item,itemarrayname,collection){
      let vm = this
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(vm.RouteID).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.RouteID+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.RouteID).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.RouteID).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
  onProfile_PhotoUpload() {
    //singleuploadfile.FeaturedPhoto true
    let vm = this
    let filename = vm.selectedProfile_PhotoFile.name
        let storepath = 'Users/'+vm.RouteID+'/Profile_Photo/'+filename
        var storageRef = firebase.storage().ref(storepath);
        var uploadTask = storageRef.put(vm.selectedProfile_PhotoFile);
        vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,vm.selectedProfile_PhotoFile,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let Profile_PhotoObject = {
              fileurl: url,
              url: url,
              Profile_PhotoThumbURL: thumbresult,
              FileType: vm.selectedProfile_PhotoFile.type,
              Modified_By: {Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},
              Modified_Byid: vm.UserRecord.id,
              Modified_On: new Date(),
              ModifiedDate: vm.selectedProfile_PhotoFile.FileModifiedDate,
              Size: vm.selectedProfile_PhotoFile.size,
              StorageRef: storepath,
              filename: filename
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(vm.RouteID).update({
                    Profile_Photo: Profile_PhotoObject,
                    Profile_PhotoThumbURL: Profile_PhotoObject.Profile_PhotoThumbURL
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added Profile Photo to - '+vm.Full_Name,
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            vm.selectedProfile_PhotoFile = ''
                })
                  vm.Profile_Photofilesnackbar = true
         })
      },
      closeProfile_Photo(){
      this.Profile_PhotolocalURL = ''
      this.selectedProfile_PhotoFile = ''
    },
      onProfile_PhotoFileselected(event) {
        this.selectedProfile_PhotoFile = event.target.files[0]
        this.selectedProfile_PhotoFile.FileModifiedDate = new Date(this.selectedProfile_PhotoFile.lastModified)
         if(this.ReviseUserDialog){
          this.RevisedUserFull_Name = this.selectedProfile_PhotoFile.name
        }
        this.Profile_PhotolocalURL = URL.createObjectURL(this.selectedProfile_PhotoFile)
      },

      
      
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}

.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        