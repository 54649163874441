<template>
  <div>
    
    <v-dialog v-model="ServiceDialog" max-width="400">
        <v-card v-if="ActiveService">
            <v-card-title>
                Service
            </v-card-title>
            <v-card-text>
            <v-switch v-model="ActiveService.IsPromoted" label="Is Promoted"/>
            <ContentEditableField style="padding:15px;" v-if="ActiveService.IsPromoted"
                :FieldObject="ActiveService" :FieldName="'Description'"
                :FieldValue="ActiveService.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            <DataImageFieldCard 
                :System="System" :SystemEntities="SystemEntities"
                :col="ActiveServiceImageFieldCol" :Record="ActiveService" :CanEdit="true"
                @onPhotoUpload="onPhotoUpload"
            /> 
            </v-card-text>
            <v-card-actions>
                <v-btn outlined color="warning" dark @click="DeactivateserviceDialog()">
                    Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn outlined color="success" dark @click="SaveActiveService()">
                    Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="NewRestrictionDialog" max-width="400">
        <v-card>
            <v-card-title>
                Restriction
            </v-card-title>
            <v-card-text>
            <v-text-field dense v-model="NewRestriction.Title" label="Title"/>
            <v-textarea dense v-model="NewRestriction.Description" label="Description"/>
            <v-select v-model="NewRestriction.Plugin" return-object :items="AvailableRestrictionPlugins"
            item-text="id"/>
            </v-card-text>
            <v-card-actions>
                <v-btn outlined color="warning" dark @click="CancelNewRestrictionDialog()">
                    Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn outlined color="success" dark @click="AddNewRestriction()">
                    Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Welcome to your Suite
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Tasks
      </v-card-subtitle>
      <v-card-text >
        <InfoSheet v-for="elmnt in HighlightedAlertElements" :key="elmnt.itemObjKey" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                :tab="elmnt"									
                />
      </v-card-text>
      </v-card>
      
        <v-card elevation="0" class="siteconfigcard">
        <v-card-title class="mediumoverline">
          Suite Services
          <v-spacer>
          </v-spacer>
          <!-- <v-btn outlined @click="ActivateNewRestrictionDialog()">
                            <v-icon>mdi-plus</v-icon>
                            Save</v-btn> -->
        </v-card-title>
      <v-card-text >
        <v-list>
            <v-list-item @click="ActivateServiceDialog(ser)" class="detailslistoutline" v-for="ser in ComputedServiceOptions" :key="ser.itemObjKey">
               <v-list-item-avatar size="50"
                :color="ser.Color"
               >
               <v-icon dark size="30">{{ser.icon}}
               </v-icon>
               </v-list-item-avatar>
               <v-list-item-content>
                <v-list-item-title>
                    {{ser.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ser.Pluginid}}
                </v-list-item-subtitle>
               </v-list-item-content>
               <v-list-item-action>
                <v-switch readonly v-model="ser.IsPromoted"/>
               </v-list-item-action>
            </v-list-item>
        </v-list>
      </v-card-text>
        </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Restrictions
      </v-card-subtitle>
      <v-card-text >
        <v-list>
             <v-btn outlined @click="ActivateNewRestrictionDialog()">
                            <v-icon>mdi-plus</v-icon>
                            Add New</v-btn>	
            <v-list-item v-for="restriction in ComputedRestrictions" :key="restriction.itemObjKey">
                 <v-list-item-content>
                    <v-list-item-title>
                        <v-icon small>
                            {{restriction.Plugin.Icon}}
                        </v-icon>
                        {{restriction.Plugin.id}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{restriction.Title}}
                    </v-list-item-subtitle>
                 </v-list-item-content>
                 <v-list-item-action>
                    <v-list-item-action-text>
                 {{restriction.Description}}
                    </v-list-item-action-text>
                    <v-list-item-subtitle>
                        <v-switch dense @change="UpdateRestrictions()" v-model="restriction.Restricted" label="Restricted"/>
                    </v-list-item-subtitle>
                 </v-list-item-action>
                 <!-- <v-list-item-action-text>
                 {{restriction.Description}}
                 </v-list-item-action-text>
                 <v-list-item-action>
                    <v-switch @change="UpdateRestrictions()" v-model="restriction.Restricted" label="Restricted"/>
                 </v-list-item-action> -->
            </v-list-item>
        </v-list>
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Analytics
      </v-card-subtitle>
      <v-card-text >
         Analytics Quick Card
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
      
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import ContentEditableField from '@/components/Database/ContentEditableField'
import DataImageFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DataImageFieldCard'
export default {
    props: ['System','SystemEntities'],
    components: {InfoSheet,ContentEditableField,DataImageFieldCard},
    data() {
        return {
            ActiveServiceImageFieldCol: {FieldObj: {id: 'Image', Name: 'Image'}},
            ServiceDialog: false,
            ActiveService: '',
            NewRestrictionDialog: false,
            NewRestriction: {
                Restricted: true,
                AllowedUsers: []
            },
            DefaultNewRestriction: {
                Restricted: true,
                AllowedUsers: []
            },
            SystemTickets: [],
            RoadmapItems: [],
            SocialDashItems: [
            //{ title: 'Social Stats', icon: 'mdi-home-analytics',ComponentName: 'MySocialStats' },
            //{ title: 'Group Monetization',Pluginid: 'Group_Monetization', icon: 'mdi-cash-register',ComponentName: 'GroupDirectoriesDashboard', MonetizationOfficer: true },
            { name: 'My Events',Color:'blue', title: 'Events',Pluginid: 'Site_Events', icon: 'mdi-calendar-star' },
            { name: 'My Blogs',Color:'purple', title: 'Blogs',Pluginid: 'Site_Blogs', icon: 'mdi-newspaper'},
            { name: 'My Articles',Color:'pink', title: 'Articles',Pluginid: 'Site_Articles', icon: 'mdi-newspaper' },
            { name: 'My Classifieds',Color:'green accent-4', title: 'Classifieds',Pluginid: 'Classifieds', icon: 'mdi-monitor-star'},
            { name: 'My Forums',Color:'orange', title: 'Forums',Pluginid: 'Site_Forums', icon: 'mdi-forum' },
            { name: 'My Meetups',Color:'lime', title: 'Meetups',Pluginid: 'Site_Meetups', icon: 'mdi-human-greeting-proximity'},
            { name: 'My Notices',Color:'red', title: 'Notices',Pluginid: 'Digital_NoticeBoard', icon: 'mdi-billboard' },
            { name: 'My Polls',Color:'amber', title: 'Polls',Pluginid: 'Site_Polls', icon: 'mdi-vote'},
            ],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	
        }
    },	
    computed:{
        ComputedServiceOptions(){
            return this.System.ActiveServices ? this.System.ActiveServices.concat(this.AvailableServiceOptions) : this.AvailableServiceOptions
        },
        AvailableServiceOptions(){
            return this.System.ActiveServices ? this.ServiceOptions.filter(opt => {
                return !this.System.ActiveServices.find(obj => obj.name === opt.name)
            }) :
            this.ServiceOptions
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
         Has_Member_Groups(){
         return this.PluginDataBase && this.PluginDataBase.Member_Groups ? this.PluginDataBase.Member_Groups.Active : false
       },
        Has_Public_Signup(){
         return this.PluginDataBase && this.PluginDataBase.Public_Signup ? this.PluginDataBase.Public_Signup.Active : false
       },
        WebsiteisActive(){
            return this.$store.state.WebsiteisActive
        },
        SocialNetworkisActive(){
            return this.$store.state.SocialNetworkisActive
        },
        DatabaseisActive(){
            return this.$store.state.DatabaseisActive
        },
        DocumentationisActive(){
            return this.$store.state.DocumentationisActive
        },
        ActiveDB(){
            return db
        },
        IsPrimarySite(){
      return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
    },
        FeaturedMemberActive(){
          //console.log('this.PluginDataBase',this.PluginDataBase)
            return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
        },
        ClassifiedsActive(){
          return this.PluginDataBase.Classifieds && this.PluginDataBase.Classifieds.Active
        },
        GroupDirectoriesActive(){
          return this.PluginDataBase.Group_Directories && this.PluginDataBase.Group_Directories.Active
        },
        DirectoryListings(){
        let arr = []
        if(this.FeaturedMemberActive){
          arr.push({
                            name: 'Featured Member',
                            icon: 'mdi-account-star',
                            Color: 'goldgradbg'
                        })
        }
        if(this.ClassifiedsActive){
          arr.push({
                            name: 'Org Directory',
                            icon: 'mdi-office-building',
                            Color: 'blue'
                        })
        }
        if(this.GroupDirectoriesActive){
          arr.push({
                            name: 'Classifieds',
                            icon: 'mdi-monitor-star',
                            Color: 'red'
                        })
        }
        return arr
        },
        SocialModeratorChannels(){
            return this.SocialDashItems.filter(socialcomp => {           
               return this.PluginDataBase[socialcomp.Pluginid] && this.PluginDataBase[socialcomp.Pluginid].Active
            })
        },
        ServiceOptions(){
        return this.SocialModeratorChannels.concat(this.SpecialModeratorChannels,this.DirectoryListings)
        },
        
      
        WebAppBuilderActive(){
        return this.PluginDataBase && this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active
      },
        SpecialModeratorChannels(){
             // 6. Special moderator (suite templates/marketplacetemplates)
            //cannot be return [this.ComputedSuiteNavItem] ??
            return this.WebAppBuilderActive ?  [{
                            name: 'Web Apps',
                            icon: 'mdi-apps',
                            Color: 'BuilderGradient'
                        },{
                            name: 'My Sites',
                            icon: 'mdi-folder-home-outline',
                            Color: 'BuilderGradient'
                        },{
                            name: 'Market Templates',
                            icon: 'mdi-cash-register',
                            Color: 'brown'
                }] : []
        },
        
        ComputedRestrictions(){
            return this.System.Restrictions ? this.System.Restrictions : []
        },
        AvailableRestrictionPlugins(){
            return this.RAPlugins.filter(plug => {
                return !this.ComputedRestrictions.find(obj => obj.Plugin.id === plug.id)
            }).map(plug => {
                return {
                    id: plug.id,
                    Icon: plug.Icon
                }
            })
        },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(this.IsPrimarySite && this.userIsAdmin){
        this.GetArrayData(this.ActiveDB.collection('roadmapitems'),'RoadmapItems')
        this.GetArrayData(this.ActiveDB.collection('systemtickets'),'SystemTickets')
        }
        else if(this.IsPrimarySite && !this.userIsAdmin){
            this.$router.push('/MyAccount')
        }
    },
    methods:{
        onPhotoUpload(payload) {
            //console.log(payload)
          let filename = payload.filename
          let file = payload.file
          let field = payload.field
          let storepath = this.$store.state.ActiveSuiteid+'/Services/'+this.ActiveService.name+'/'+file.name+'/'+new Date()
        let vm = this
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$emit('ActivateProcessing',true)
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              ThumbURL: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            vm.ActiveService.ImageUploadObject = UploadObject
            vm.ActiveService.Image = url
            console.log('vm.ActiveService',vm.ActiveService,UploadObject)
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added '+field.DisplayName+' to Record',
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            // delete field.localURL
            // delete field.file
                })
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        //console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        //console.log(this.field,FieldObject,this.Record)
        },
        ActivateServiceDialog(ser){
            this.ActiveService = Object.assign({},ser)
            this.ServiceDialog = true
        },
        DeactivateserviceDialog(){
            this.ServiceDialog = false
            this.ActiveService = ''
        },
        SaveActiveService(){
            console.log('this.ActiveService',this.ActiveService)
            if(!this.System.ActiveServices){
                this.System.ActiveServices = []
            }
            //console.log('this.System.ActiveServices',this.System.ActiveServices)
            let oncheck = this.System.ActiveServices.find(obj => obj.name === this.ActiveService.name)
            if(!oncheck){
                this.System.ActiveServices.push(this.ActiveService)
            }
            else{
                let index = this.System.ActiveServices.indexOf(oncheck)
                this.System.ActiveServices.splice(index,1,this.ActiveService)
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).update({
                ActiveServices: this.System.ActiveServices
            })
            this.DeactivateserviceDialog()
        },
        UpdateRestrictions(){
            //console.log(this.ComputedRestrictions,this.System.Restrictions)
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).update({
                Restrictions: this.System.Restrictions
            })
            this.CancelNewRestrictionDialog()
        },
        ActivateNewRestrictionDialog(){
            this.NewRestrictionDialog = true
        },
        CancelNewRestrictionDialog(){
            this.NewRestrictionDialog = false
            this.NewRestriction = Object.assign({},this.DefaultNewRestriction)
        },
        AddNewRestriction(){
            if(!this.System.Restrictions){
                this.System.Restrictions = []
            }
            let oncheck = this.System.Restrictions.find(obj => obj.Plugin.id === this.NewRestriction.Plugin.id)
            if(!oncheck){
              this.System.Restrictions.push(this.NewRestriction)  
            }
            else{
                let index = this.System.Restrictions.indexOf(oncheck)
                this.System.Restrictions.splice(index,this.NewRestriction)
            }            
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).update({
                Restrictions: this.System.Restrictions
            })
            this.CancelNewRestrictionDialog()
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



