
<template>
<div>
    <div v-if="!tab.FancyTitle && tab.Title">
                                <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="GetRandomPicture()"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                <v-card-title :class="tab.Center" :style="tab.HeaderBlendMode ? 'mix-blend-mode: '+tab.HeaderBlendMode+';'+TitleStyle(tab) : TitleStyle(tab)">
                               
                               {{tab.Title}}
                                 <!-- <v-divider inset class="white" >
                                </v-divider> -->
                                <!-- <hr style="width:35%; color:blue;"> -->
                                </v-card-title>
                                <!-- <v-divider inset class="white" >
                                </v-divider> -->
            </div>
            <TitleTab v-if="tab.FancyTitle && tab.Title" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                                <v-row class="justify-center" v-if="tab.TestimonialsType === 'Flip Cards' || 
                                tab.TestimonialsType === 'STD Cards' || tab.TestimonialsType === 'Hover Cards'">
                                    <v-col v-for="(elmnt,index) in ComputedElements" :key="elmnt.itemObjKey" cols="10" xl="3" lg="3" md="3" sm="4"
                                    >
                                    <CardRowTab v-if="tab.TestimonialsType === 'Flip Cards'"
                                        :style="elmnt.StyleObject" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"
                                            :tab="elmnt" @OpenDialog="OpenDialog" :PageID="ActivePageID" :PageName="PageName" :selectedIndex="selectedIndex"
                                            />
                                    <v-card  v-if="tab.TestimonialsType === 'STD Cards'"
                                    :class="tab.BoxTransparency+' '+tab.CustomClass+'' "  :color="tab.BGColor.hexa"
                                    :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" 
                                       >
                                       <v-img v-if="!elmnt.Avatar && !tab.HideIMG"
                                                 :aspect-ratio="4/4"
                                                :src="GetRandomPicture()"
                                                /><v-img v-if="elmnt.Avatar && !tab.HideIMG"
                                                :aspect-ratio="4/4"
                                                :src="elmnt.Avatar"
                                                />
                                        <v-card-text  :class="tab.FillHeight"  :style="tab.FontBlendMode ? 'mix-blend-mode: '+tab.FontBlendMode+';'+TabFontStyle(tab) : TabFontStyle(tab)">
                                            <span :id="tabindex+'_'+i+'_Description'"
                                            :class="tab.HiglightInitialLetter === 'Each Paragraph' ? 'magartallp' : tab.HiglightInitialLetter === 'First Paragraph' ? 'magartfirstp' : ''"
                                            v-html="elmnt.Description" :style="DefaultPageFont+'color:'+tab.FontColor.hex+';font-size:'+tab.FontSize+'px;'"/>
                                        </v-card-text>
                                       <v-card-title :class="'justify-center'" :style="tab.HeaderBlendMode ? 'mix-blend-mode: '+tab.HeaderBlendMode+';'+ElmntTitleStyle(elmnt) : ElmntTitleStyle(elmnt)">
                                            <!-- +TitleStyle(elmnt) -->
                                            {{elmnt.Title}}
                                                <!-- <v-divider inset class="white" >
                                                </v-divider> -->
                                                <!-- <hr style="width:35%; color:blue;"> -->
                                                </v-card-title>
                                    </v-card>
                                    <v-hover v-slot="{ hover }" v-if="tab.TestimonialsType === 'Hover Cards'">
                                        <v-card
                                        :class="tab.BoxTransparency+' '+tab.CustomClass+'' "  :color="tab.BGColor.hexa"
                                        :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" 
                                        >
                                        <v-card-title class="justify-center">
                                        <v-avatar v-if="!tab.HideIMG" size="100" style="margin-top:20px;">

                                        <v-img v-if="!elmnt.Avatar"
                                                :src="GetRandomPicture()"
                                                /><v-img v-if="elmnt.Avatar"
                                                :src="elmnt.Avatar"
                                                />
                                        </v-avatar>
                                        </v-card-title>
                                        <v-card-text
                                            class="pt-6"
                                            style="position: relative;"
                                        >
                                           
                                            <div class="font-weight-light text-h6 mb-2" v-html="elmnt.Description">
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="caption justify-center my-2">
                                            <span>
                                                 {{elmnt.Name}} - 
                                            </span>
                                            <span class="font-weight-light">
                                                 {{elmnt.Role}}
                                            </span>
                                            
                                        </v-card-actions>
                                        <v-expand-transition>
                                            <div
                                                v-if="hover"
                                                class="d-flex transition-fast-in-fast-out white--text"
                                                :style="HoverCardStyle(tab)+'align-items: center;'"
                                            >
                                            <span :style="TabFontStyle(tab)" v-html="elmnt.Description">
                                               
                                            </span>
                                            <div style="position: absolute; bottom: 15px;">
                                                 <span>
                                                 {{elmnt.Name}} - 
                                            </span>
                                            <span class="font-weight-light">
                                                 {{elmnt.Role}}
                                            </span>
                                            </div>
                                            </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                    </v-col>
                                    
                                </v-row>
   <v-carousel v-model="model" v-if="!tab.TestimonialsType || tab.TestimonialsType === 'Default'">
                        <v-carousel-item
                        v-for="tes in tab.Elements"
                        :key="tes" class="testimonycarousel"
                         :style="!tab.HideIMG ? 
                        { backgroundImage: `linear-gradient(351deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 100%),url('`+tes.Avatar+`')`,backgroundSize: `cover` } : 
                       ''"
                        
                        >
                         <v-sheet class="transparent"
                            height="100%"
                            tile
                            
                        >
                        
                        <v-card class="transparent" height="100%" width="100%" flat 
                        >
                            <v-layout row class="justify-center">
                           
                                <v-avatar v-if="!tab.HideIMG"
                            size="150"
                            >
                           <v-img v-if="!tes.Avatar"
                                :src="GetRandomPicture()"
                                style="object-fit: cover;"
                                />
                                <v-img  v-if="tes.Avatar"
                                :src="tes.Avatar"
                                style="object-fit: cover;"
                                />
                            </v-avatar>
                            </v-layout>
                            <v-card-title class="justify-center">
                                {{tes.Name}}
                            </v-card-title>
                            <v-card-title v-if="!tab.HideRoles" class="justify-center">
                                {{tes.Role}}
                            </v-card-title>
                            <v-layout row class="justify-center">
                                <v-card-text class="mx-10" v-html="tes.Description">  
                               
                                </v-card-text>
                            </v-layout>
                        </v-card>
                           
                        </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
            
                            
    <!-- </v-card> -->
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';
import CardRowTab from '@/components/WebPages/RenderComponents/CardRowTabReadOnly';

export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex'],
    components: {
        TitleTab,
        CardRowTab
        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      ComputedElements(){
        return this.tab.Elements.map(elmnt => {
            let elmntobj = JSON.parse(JSON.stringify(elmnt))
            let props = []
            if(this.tab.TestimonialsType === 'Flip Cards'){
                props = ['IMGonTop','TiledIMG','FullIMG','IMGHeight','FlipCard','LabelGradient','LabelBGColor','ContainIMG','BGColor','HeaderColor','FontColor']
            }
            else if(this.tab.TestimonialsType === 'STD Cards'){
                props = ['IMGonTop','TiledIMG','FullIMG','IMGHeight','FlipCard','LabelGradient','LabelBGColor','ContainIMG','BGColor','HeaderColor','FontColor','SubHeaderFontSize','SubHeaderColor','SubHeaderFontFamily']
            }
            else if(this.tab.TestimonialsType === 'Hover Cards'){
                props = ['IMGonTop','TiledIMG','FullIMG','IMGHeight','FlipCard','LabelGradient','LabelBGColor','ContainIMG','BGColor','HeaderColor','FontColor']
            }            
            props.map(prp => {
                if(typeof this.tab[prp] !== 'undefined'){
                    elmntobj[prp] = this.tab[prp]
                }
            })
            elmntobj.IMG = elmnt.Avatar
            elmntobj.Title = elmnt.Name
            //console.log(elmntobj)
            return elmntobj
        })
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
       HoverCardStyle(tab){
            //console.log(tab)
            let style = 'height: 100%;align-items: center;bottom: 0;justify-content: center;opacity: .75;position: absolute;width: 100%;'
            return style+'background-color: '+tab.HoverColor.hexa+';'
        },
        GetRandomPicture(){
            let name = 'Business Person '+this.RandomNumber(1,5)+'.jpg'
            return require('@/assets/'+name)
        },
        RandomNumber(min,max){
            return Math.floor(Math.random() * (max - min) + min)
        },
        TabFontStyle(tab){
            return tab.FontBGColor && !tab.FontUnderline ? 'background-color: '+tab.FontBGColor+';width: 50%;' :
            tab.FontUnderline && tab.FontUnderlineColor ? 'background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;' :
            ''
        },
        BoxStyle(tab){
        if(tab.TestimonialsType === 'STD Cards'){
            return 'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            return 'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'
        }
       },
       ElmntTitleStyle(tab){
            // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
            if(tab.HeaderBGColor){
                return tab.Center? 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;text-align: center;color:'+tab.SubHeaderColor.hex+';'+this.SubHeaderFontFamily(tab,tab.SubHeaderFontFamily) : 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.SubHeaderColor.hex+';'+this.SubHeaderFontFamily(tab,tab.SubHeaderFontFamily)
            }
            else{
                return tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.SubHeaderColor.hex+';'+this.SubHeaderFontFamily(tab,tab.SubHeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.SubHeaderColor.hex+';'+this.SubHeaderFontFamily(tab,tab.SubHeaderFontFamily)
            }
        },
        TitleStyle(tab){
            // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
            if(tab.HeaderBGColor){
                return tab.Center? 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
            else{
                return tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        SubHeaderFontFamily(tab,tabSubHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.SubHeaderFontSize){
                 return 'font-family: "'+this.PageHeadersFont+'", sans-serif;font-size:50px;'  
               }
               else{
                   if(tabSubHeaderFontFamily){
                       return 'font-family: "'+tabSubHeaderFontFamily+'", sans-serif;font-size:'+tab.SubHeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: "'+this.PageHeadersFont+'", sans-serif;font-size:'+tab.SubHeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabSubHeaderFontFamily)
       },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>
.testimonycarousel .v-carousel__item{
    align-items: center;
}

</style>

        


    