<template>
  <v-card tile flat class="background" width="100%" height="100%">
   
    <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" :to="'/'+Location+'/'+LinkID"  v-if="collectionname !== 'socialsiteposts'">
                  <v-card-actions style="font-size:10px">
                  <v-chip small color="secondary" class="white--text"  v-if="UserRecord.id">
                    {{author}}
                  </v-chip>
                  
                  <v-spacer></v-spacer>
                  <v-chip v-if="DueDate" small :color="DueDateColor" class="white--text">
                    {{DueDate}}
                  </v-chip>
                </v-card-actions>
               
                        <v-card-title class="accent headline justify-center my-3"><v-chip color="accent" class="white--text">
                    <v-icon >{{SharedTypeIcon}}</v-icon>
                  </v-chip>{{ title }}</v-card-title>
                   <v-card-subtitle v-if="eventDate" class="overline">{{ eventDate }}</v-card-subtitle>         
                  <v-img
                  height="150px"
                  v-bind:src="SocialItemData.logo"
                  v-if="SocialItemData.logo"
                ></v-img>

                 <v-img
                  height="150px"
                  v-bind:src="image"
                  v-if="image"
                ></v-img>
                <v-card-text v-if="SocialItemData.PollOptions">
                    <ul>
                        <li v-for="option in SocialItemData.PollOptions" :key="option.itemObjKey">
                            {{option.Name}}
                        </li>
                    </ul>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text v-html="caption">
                </v-card-text>
                
                
              </v-card>
               <v-card class="my-3 mx-3" hover width="90%" :to="'/'+Location+'/'+LinkID" v-if="collectionname === 'socialsiteposts'">
             
                <SocialPostView :SharedPost="true" :AppisDarkMode="$vuetify.theme.dark" :post="SocialItemData"/>
              </v-card>
              </v-layout>
</v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SocialPostView from '@/components/SocialNetwork/SocialPostView';
export default {
    props: ['collectionname','LinkID','UserRecord','Location'],
    components: {SocialPostView},
    data(){
        return {
            SocialItemData: '',
            itempubDate: '',
            eventDate: '',
            caption: '',
            title: '',
            author: '',
            image: '',
        }
    },
    computed: {
        DueDate(){
            if(this.SocialItemData.PollEndDate){
                return this.TimestampFormatterSTRINGDayText(this.SocialItemData.PollEndDate.toDate())
            }
            else if(this.SocialItemData.start){
                return this.TimestampFormatterSTRINGDayText(this.SocialItemData.start.toDate())
            }
            else if(this.SocialItemData.createdon){
                return this.TimestampFormatterSTRINGDayText(this.SocialItemData.createdon.toDate())
            }
            else if(this.SocialItemData.PublishDate){
                return this.TimestampFormatterSTRINGDayText(this.SocialItemData.PublishDate.toDate())
            }
        },
        DueDateColor(){
            if(this.SocialItemData.PollEndDate){
                return 'pop'
            }
            else if(this.SocialItemData.start){
                return 'warning'
            }
            else if(this.SocialItemData.createdon){
                return 'accent'
            }
            else if(this.SocialItemData.PublishDate){
                return 'secondary'
            }
        },
        SharedType(){
            if(this.Location === 'SitePoll'){
                return 'Poll'
            }
            else if(this.Location === 'Blog'){
                return 'Blog'
            }
            else if(this.Location === 'Article'){
                return 'Article'
            }
            else if(this.Location === 'Forum'){
                return 'Forum'
            }
            else if(this.Location === 'SiteEvent'){
                return 'Event'
            }
            else if(this.Location === 'Classified'){
                return 'Classified'
            } 
        },
        SharedTypeIcon(){
            if(this.Location === 'SitePoll'){
                return 'mdi-vote'
            } 
            else if(this.Location === 'Blog'){
                return 'mdi-blogger'
            }
            else if(this.Location === 'Article'){
                return 'mdi-blogger'
            }
            else if(this.Location === 'Forum'){
                return 'mdi-forum-outline'
            }  
            else if(this.Location === 'SiteEvent'){
                return 'mdi-forum-outline'
            } 
            else if(this.Location === 'Classified'){
                return 'mdi-format-list-bulleted-type'
            } 
        }
    },
    created(){
        this.GetRequestingUser()
    },
    methods: {
        async GetRequestingUser(){
        let vm = this;
        //console.log("I try assign this.UserRecord")
            await firebase.auth().onAuthStateChanged(function(user) {
                if (user) {         
                    //console.log('let us see now, yes the user is signed in')
                    db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
                    var userdetails = snapshot.data()

                     // vm.UserRecord = userdetails
                    // vm.UserRecord.id = user.uid
                    if(vm.collectionname === 'socialsiteposts'){
                        vm.getInternalSocialInformation() 
                    }
                    else{
                      vm.getInternalSocialInformation()  
                    }
                    
                    //console.log(vm.UserRecord)
                    })
                }
                else{
                vm.getPublicSocialInformation()
                    console.log('nope the user is apprantely NOT signed in')
                }
            })
        },
        GetPostImages(postobj,usergallery){
          let vm = this
          return new Promise(function(resolve, reject) {
            let query = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsiteimages').where('relatedid','==',postobj.id)
            if(usergallery){
              query = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('socialsiteimages').where('relatedid','==',postobj.id).where('TaggedUsers','array-contains',vm.$route.params.id)
            }
            query.onSnapshot(res =>{
           
              let listlength = res.docs.length
                if(listlength === 0){
                      resolve(postobj)
                }
                else{
                  if(!postobj.Images){
                   postobj.Images = [] 
                  }                  
                  const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      let imgobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                      }
                      // imgObj.MapTags = []
                      //vm.GetMapTags(postobj,imgobj)
                      postobj.Images.push(imgobj)
                      if(postobj.Images.length === listlength){
                        resolve(postobj)
                      }
                    }
                  })
                }
            })
          })
        },
        async getInternalSocialInformation() {
        // console.log(this.LinkID)
        // console.log(this.collectionname)
        let collection = db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection(this.collectionname)
        let docref = collection.doc(this.LinkID)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
            var socialitemdata = snapshot.data()           
            if(this.collectionname === 'socialsiteposts'){
                let postobj = socialitemdata
                postobj.id = this.LinkID
                this.GetPostImages(postobj).then(imgresultobj => {
                    this.SocialItemData = imgresultobj
                })
            }
            else{
                this.SocialItemData = socialitemdata
                this.SocialItemData.id = this.LinkID
            }
             //console.log('SocialItemData',this.SocialItemData)
            // console.log(socialitemdata)
            if(socialitemdata.caption){
            this.caption = socialitemdata.caption
            }
            else if(socialitemdata.details){
                this.caption = socialitemdata.details
            }
            else if(socialitemdata.Description){
                this.caption = socialitemdata.Description
            }
            else if(socialitemdata.post){
                this.caption = socialitemdata.post
            }
            else if(socialitemdata.PollDescription){
                this.caption = socialitemdata.PollDescription
            }
            this.Moderators = socialitemdata.moderatorrolesarrayQuery
            if(socialitemdata.title){
             this.title = socialitemdata.title   
            }
            if(socialitemdata.Title){
             this.title = socialitemdata.Title   
            }
            
            else if(socialitemdata.name){
             this.title = socialitemdata.name   
            }
            else if(socialitemdata.PollName){
             this.title = socialitemdata.PollName   
            }
            
            this.content = socialitemdata.content
            this.blogcontent = socialitemdata.description
            if(socialitemdata.author){
             this.author = socialitemdata.author
            this.authorid = socialitemdata.authorid   
            }
            else if(socialitemdata.Owner){
                 this.author = socialitemdata.Owner.Full_Name
            this.authorid = socialitemdata.Ownerid 
            }
            else if(socialitemdata.Created_By){
                 this.author = socialitemdata.Created_By.Full_Name
            this.authorid = socialitemdata.Created_Byid 
            }
            else if(socialitemdata.Creator){
                 this.author = socialitemdata.Creator.Full_Name
            this.authorid = socialitemdata.Creatorid 
            }
            
            this.PubStatus = socialitemdata.PublishStatus
            if(typeof socialitemdata.ImageShy !== 'undefined'){
            this.ImageShy = socialitemdata.ImageShy
            }
            if(typeof socialitemdata.coverimage !== 'undefined'){
            this.image = socialitemdata.coverimage
            }
            else if(socialitemdata.EventImage){
                console.log('socialitemdata.EventImage',socialitemdata.EventImage)
                this.image = socialitemdata.EventImage
            }
            else if(socialitemdata.FeaturedPhoto){
                this.image = socialitemdata.FeaturedPhoto.Path
            }
            
            this.createdon = socialitemdata.createdon
            if(socialitemdata.PublishDate){
               this.pubdatastring = format(socialitemdata.PublishDate.toDate(),'yyyy-MM-dd') 
            }
            
            
            if(socialitemdata.PublishDate){
                this.itempubDate = this.TimestampFormatterSTRINGDayText(socialitemdata.PublishDate.toDate())
            }
            if(socialitemdata.createdon){
               this.itempubDate = format(socialitemdata.createdon.toDate(),'yyyy-MM-dd') 
            }
            if(socialitemdata.start && socialitemdata.end){
                this.eventDate = this.TimestampFormatterSTRINGDayText(socialitemdata.start.toDate())+' to '+this.TimestampFormatterSTRINGDayText(socialitemdata.end.toDate())
                //eventDate
            }
            
           
           
            // console.log('blog id is '+this.blogid)
            // console.log('user id is '+this.UserRecord.id)
            // console.log('author id is '+this.authorid)
         
        })
    
        },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Shorttext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
    }
}
</script>

<style>

</style>

  