<template>
<span >

    <!-- FILTER LIST -->
    <v-list-item  class="overline">
        {{navitem.Title}} <v-icon  v-if="$route.name === 'DirectoryBuilder'" @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon>
        <v-icon v-if="ActiveBrandFilter" @click="ActiveBrandFilter = ''">mdi-close</v-icon>
        </v-list-item>
    <v-list  dense style="padding:10px;" class="transparent">
    <!-- <v-list-item>
        <v-select outlined :class="$vuetify.theme.dark ? 'blacklabel' : 'whitelabel'" label="Brand" dense clearable v-model="ClassFilter" :items="ClassifiedClassesCounted" item-text="CountName" return-object/>
        </v-list-item> -->
            <v-list-item v-for="(filter,i) in navitem.Filters" :key="filter.itemObjKey">
            <!-- Should be "CountName" -->
            <v-autocomplete v-if="filter.Type === 'Radio Group' ||filter.Type === 'Option Set' || filter.Type === 'Lookup'" @change="ActivateFilter(filter)" v-model="filter.ActiveFilter" outlined :class="$vuetify.theme.dark ? 'blacklabel' : 'whitelabel'" :label="filter.DisplayName" dense clearable :items="filter.Options" item-text="Name" return-object/>
            <v-select v-if="filter.Type === 'Number Field'" @change="ActivateFilter(filter)" outlined :class="$vuetify.theme.dark ? 'blacklabel' : 'whitelabel'" v-model="filter.ActiveFilter"  :label="filter.DisplayName" dense clearable :items="filter.Options" item-text="Name" return-object/>
            <v-icon  v-if="$route.name === 'DirectoryBuilder' && filter.CanEdit" @click="EditNavItemSubItem(navitem,navitemindex,filter,i,'Filters')">mdi-cogs</v-icon>
        </v-list-item>                 
    
    </v-list>
    </span>
</template>

<script>
export default {
    props: ['navitem','navitemindex'],
    components: {

    },
    data(){
        return {

        }
    },
    created(){

    },
    computed:{

    },
    methods: {
        ActivateFilter(filter){
            this.$emit('ActivateFilter',filter,'Filter List')
        },
        EditNavItem(navitem,navitemindex){
            this.$emit('EditNavItem',navitem,navitemindex)
        },
        EditNavItemSubItem(navitem,navitemindex,child,childindex,prop){
            this.$emit('EditNavItemSubItem',navitem,navitemindex,child,childindex,prop)
        },
    }
}
</script>

<style>

</style>