<template>
   <v-card flat tile class="transparent">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
          <v-list dense v-if="EditedTab.DataViewName === 'Data Single Document Register' || EditedTab.DataViewName === 'Data Single Gallery' || EditedTab.DataViewName === 'Data Single SubCollection'">
                            <v-list-item-subtitle>Add New</v-list-item-subtitle>
                            <v-list-item>
                                <v-text-field v-model="NewTableOptionSearch" label="Search" />
                            </v-list-item>
                            
                            <!-- <v-list-item  v-for="field in FieldTypesSearched"
                                :key="field.itemObjKey">
                                {{field.Type}}
                            </v-list-item> -->
                            <v-chip-group
                                active-class="primary--text"
                                column
                            >
                            
                                <v-chip v-for="opt in TableOptionsSearched"
                                :key="opt.itemObjKey"
                                @click="ActivateTableOptionDialog(opt)" small
                                >
                                {{opt.OptionType}}
                                </v-chip>
                            </v-chip-group>
                        </v-list>
          <v-list dense  v-if="EditedTab.DataViewName === 'Data Single Fields'">
                            
                            <v-list-item-subtitle>Fields on Form</v-list-item-subtitle>
                           
                            <!-- ExistingFormFields -->
                            <v-list-item class="detailslistoutline"  @drop.prevent="Twopropemit('dataformfielddragfinish',EditedTab,fieldindex)"	@dragover.prevent								
                            :draggable="true" @dragstart="Twopropemit('dataformfielddragstart',field,fieldindex)" dense v-for="(field,fieldindex) in EditedTab.Fields"
                            :key="field.itemObjKey">
                            <v-list-item-action>
                                <v-icon small>mdi-drag</v-icon>
                            </v-list-item-action>
                           <v-list-item-content>
                            <v-list-item-title v-text="field.Name"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="blue" @click="ActivateNewFieldDialog(field)">mdi-cogs</v-icon>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-icon small @click="Threepropemit('RemoveFieldfromTab',field,fieldindex,EditedTab)" color="red">mdi-delete-forever</v-icon>
                            </v-list-item-action>
                            </v-list-item>
                            
                            <v-list-item-subtitle>Unused Fields</v-list-item-subtitle>
                            <v-list-item>
                                <v-text-field v-model="ExistingFieldSearch" label="Search" />
                            </v-list-item>
                            <v-list-item class="detailslistoutline" draggable @dragstart="Onepropemit('AssignNewFieldDragging',field)"  dense v-for="field in UnusedFields"
                            :key="field.itemObjKey">
                           <v-list-item-content>
                            <v-list-item-title v-text="field.Name"></v-list-item-title>
                            
                            </v-list-item-content>
                            </v-list-item>
                            <v-list-item-subtitle>Add New</v-list-item-subtitle>
                            <v-list-item>
                                <v-text-field v-model="NewFieldSearch" label="Search" />
                            </v-list-item>
                            <v-list-item>
                                <v-dialog v-model="importdialog" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                        <span class="headline">Import Dialog</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                <v-text-field v-model="ImportFileSessionName" label="Name"></v-text-field>
                                                <input v-if="!ImportFile"
                                                        type="file"
                                                        @change="onImportfileSelected($event)"
                                                        ref="ImportFileinputter"
                                                        id="fileUpload"
                                                        >
                                                <v-btn @click="DownloadFieldsImportTemplate()">Download Template</v-btn>
                                            </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                            </v-list-item>
                            <!-- <v-list-item  v-for="field in FieldTypesSearched"
                                :key="field.itemObjKey">
                                {{field.Type}}
                            </v-list-item> -->
                            <v-chip-group
                                active-class="primary--text"
                                column
                            >
                            <v-chip
                                @click="ActivateNewFieldDialog(field,true)"
                                small  v-for="field in FieldTypesSearched"
                                :key="field.itemObjKey"
                                >
                                {{field.Type}}
                                </v-chip>                           
                            </v-chip-group>
                        </v-list>
            <v-card-text v-if="EditedTab.Name === 'Wiki Collection'">
                  
                          <v-select :items="ComputedWikiEntities" item-text="id" label="Entity" v-model="EditedTab.Entityid"/>
                          <v-switch  label="Hide Navigation" v-model="EditedTab.HideNavigation"/>
                          <v-list>
                              <v-list-item v-for="item in WikiBooleanProperties" :key="item.itemObjKey">
                                   <v-switch @change="RefreshComponent(EditedTab)" dense :label="item" v-model="EditedTab[item]" />
                              </v-list-item>
                          </v-list>
                          <v-list>
                              <v-list-item>
                              <v-switch  label="Is Filtered" v-model="EditedTab.IsFiltered"/>
                              </v-list-item>
                              <v-list-item v-if="EditedTab.IsFiltered">
                                <v-select return-object :items="WikiEntityFields" item-text="Name" label="FilterField" v-model="EditedTab.FilterField"/>
                              </v-list-item>
                              <v-list-item v-if="EditedTab.FilterField && EditedTab.FilterField.Type === 'Lookup'">
                                <v-switch  label="Filter by Current" v-model="EditedTab.FilterbyCurrent"/>
                              </v-list-item>
                          </v-list>
                      </v-card-text>	
                      
                      <v-card-text v-if="EditedTab.DataViewName === 'Data Single SubCollection' || EditedTab.DataViewName === 'Data Single Document Register' || EditedTab.DataViewName === 'Data Single Gallery'">									
                        <v-btn v-if="EditedTab.Entityid" @click="ConfigureTable(EditedTab)" small>Configure</v-btn>
                        <v-select clearable :items="UnusedTableOptions" @change="Onepropemit('UpdateEntitySource',EditedTab)" item-text="id" label="Option" v-model="EditedTab.Entityid"/>						
                      </v-card-text>
                      <v-card-text v-if="EditedTab.DataViewName === 'Data Single Related Data'">
                        <v-select :items="UnusedTableOptions" @change="Onepropemit('UpdateEntitySource',EditedTab)" return-object item-text="RelationshipName" label="Option" v-model="EditedTab.EntityObj"/>						
                      </v-card-text>
                   
                        <v-card-text v-if="WikiType">
                          <v-dialog v-model="WikiSourceDialog" max-width="500px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="green" dark class="mb-2" v-if="!$route.meta.ClassifiedListingBuilder" v-on="on">Configure Source</v-btn>
                                </template>
                                <WikiSourceEditor v-if="WikiSourceDialog" :EditedTab="EditedTab" :CurrentEntity="CurrentEntity" :SystemEntities="SystemEntities"
                                @UpdateWikiSource="UpdateWikiSource" @CancelWikiSourceDialog="CancelWikiSourceDialog" @ActivateNewWikiFieldDialog="ActivateNewWikiFieldDialog"
                                        />
                          </v-dialog>
                          <v-list  v-if="WikiType && $route.meta.ClassifiedListingBuilder">                             
                            <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Title' || EditedTab.Name === 'Image' || EditedTab.Name === 'Parallax'">									
                                <v-switch v-model="EditedTab.ContentFromField" label="From Field"/>									
                            </v-list-item>
                            
                            <v-list-item v-if="EditedTab.ContentFromField && EditedTab.Name === 'HTML Box'">
                                <v-select return-object @change="UpdateWikiValue(EditedTab,'Description',EditedTab.ContentField)" v-model="EditedTab.ContentField" :items="CurrentEntity.AllFields" label="Field" item-text="DisplayName">
                                    <template v-slot:no-data>
                          <i class="mx-4">no data</i>
                        </template>
                        <template v-slot:append-item>
                            <v-list max-width="300"> 
                                <v-list-item>
                                    Add New (Wiki Only)
                                </v-list-item>                           
                              <v-list-item>
                                 <v-chip-group
                                        active-class="primary--text"
                                        column
                                    >
                                    <v-chip
                                        @click="ActivateNewFieldDialog(field,true)"
                                        x-small  v-for="field in FieldTypesSearched"
                                        :key="field.itemObjKey"
                                        >
                                        {{field.Type}}
                                        </v-chip>                           
                                    </v-chip-group>
                              </v-list-item>
                            </v-list>
                          </template>
                                </v-select>
                            </v-list-item>		
                            <v-list-item v-if="EditedTab.ContentFromField && EditedTab.Name === 'Title'">
                                <v-select return-object @change="UpdateWikiValue(EditedTab,'Title',EditedTab.ContentField)" v-model="EditedTab.ContentField" :items="CurrentEntity.AllFields" label="Field" item-text="DisplayName" />
                            </v-list-item>
                            <v-list-item v-if="EditedTab.ContentFromField && EditedTab.Name === 'Image'">
                                <v-select return-object @change="UpdateWikiValue(EditedTab,'IMG',EditedTab.ContentField)" v-model="EditedTab.ContentField" :items="CurrentEntityPhotoFields" label="Field" item-text="DisplayName" />
                            </v-list-item>
                            <v-list-item v-if="EditedTab.ContentFromField && EditedTab.Name === 'Parallax'">
                                <v-select return-object @change="UpdateWikiValue(EditedTab,'IMG',EditedTab.ContentField)" v-model="EditedTab.ContentField" :items="CurrentEntityPhotoFields" label="Field" item-text="DisplayName" />
                            </v-list-item>
                            <v-list-item v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Carousel'">
                                <v-select @change="AssignWikiBasedGallery(EditedTab)" v-model="EditedTab.Galleryid" :items="DynamicWikiData.Galleries" label="Gallery" item-text="id"/>
                            </v-list-item> 
                            <v-list-item v-if="EditedTab.Name === 'Description List'">
                                <v-select v-if="DynamicWikiData" multiple @change="AssignDescriptionList(EditedTab)" v-model="EditedTab.ListItems" :items="EntityFieldsandFeatures" label="List Items" return-object item-text="DisplayName"/>
                            </v-list-item>                                                    
                          </v-list>

                          
                      </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import WikiSourceEditor from '@/components/SuitePlugins/Wiki/WikiSourceEditor';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,WikiSourceEditor},
    data() {
        return {
            WikiSourceDialog: false,
            NewTableOptionSearch: '',
            ExistingFormFieldSearch: '',
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            NewFieldSearch: '',
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        WikiEntityFields(){
            if(this.EditedTab && this.EditedTab.Name === 'Wiki Collection' && this.EditedTab.Entityid){
                let wikientity = this.WikiEntities.find(obj => obj.id === this.EditedTab.Entityid)
                return wikientity.WikiFields
            }
        },
        ComputedWikiEntities(){
            return this.WikiEntities
            .filter(entity => {
                if(this.$route.meta && this.$route.meta.ClassifiedListingBuilder){
                    //we need to get the classifiedentity somehow and pass it's publishtype to the this.Publishttypoe prop
                    //note this is clasifiedeneity, NOT on catnavlist dude
                    return entity
                }
                else{
                    return entity.PublishType === this.PublishType
                }                
            })
        },
        CurrentEntityPhotoFields(){
            return this.CurrentEntity.AllFields
            .filter(field => {
                return field.Type === 'Single File Upload' && field.FeaturedPhoto
            })
        },
        DataTableswithLookuptoCurrentEntity(){
            if(this.CurrentEntity){
                return this.SystemEntities.map(entity => {
                    let entityobject = {
                        id: entity.id
                    }
                    let oncheck = entity.AllFields.find(obj => obj.RelatedBuildID === this.CurrentEntity.id)
                    if(oncheck){
                        entityobject.RelationshipField = {
                            id: oncheck.id,
                            Name: oncheck.Name,
                            DisplayName: oncheck.DisplayName,
                            Type: oncheck.Type,
                            LookupFieldName: oncheck.LookupFieldName
                        }
                        entityobject.RelationshipName = entity.DisplayName+' ('+oncheck.DisplayName+')'
                    }
                    return entityobject
                }).filter(entity => {
                    return entity.RelationshipField
                })
            }
            else{
                return []
            }
        },
         UnusedTableOptions(){
            let docregisters = this.CurrentEntity.DocumentRegisters
            let galleries = this.CurrentEntity.Galleries
            if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single SubCollection'){
                return this.CurrentEntity.SubCollections.filter(opt => {
                    if(this.TableoptionsSearch){
                        return opt.DisplayName.toLowerCase().includes(this.TableoptionsSearch.toLowerCase())
                    }
                    else{
                        return opt
                    }
                })
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Related Data'){
                return this.DataTableswithLookuptoCurrentEntity
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Document Register'){
                return docregisters
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Gallery'){
                return galleries
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Cabinet'){
                
                return docregisters.concat(galleries).filter(opt => {
                    if(this.TableoptionsSearch){
                        return opt.DisplayName.toLowerCase().includes(this.TableoptionsSearch.toLowerCase())
                    }
                    else{
                        return opt
                    }
                })
            }
        },
        TableOptionsSearched(){
            let array = []
            if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single SubCollection'){
                let stringarray = ['SubCollection']
                stringarray.map(stringopt => {
                    let arrayobj = {
                        Name: '',
                        DisplayName: '',
                        OptionType: stringopt
                    }
                    if(stringopt === 'SubCollection'){
                        arrayobj.ConfigCollection = 'SubCollections'
                        arrayobj.Headers = []
                        arrayobj.HeadersInteger = 1000000
                        
                    }
                    
                    array.push(arrayobj)
                })
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Document Register'){
                let stringarray = ['Document Register']
                stringarray.map(stringopt => {
                    let arrayobj = {
                        Name: '',
                        DisplayName: '',
                        OptionType: stringopt,
                    }
                    if(stringopt === 'Document Register'){
                        arrayobj.ConfigCollection = 'DocumentRegisters'
                    }
                    else if(stringopt === 'Gallery'){
                        arrayobj.ConfigCollection = 'Galleries'
                    }
                    array.push(arrayobj)
                })
            }
            else if(this.EditedTab && this.EditedTab.DataViewName === 'Data Single Gallery'){
                let stringarray = ['Gallery']
                stringarray.map(stringopt => {
                    let arrayobj = {
                        Name: '',
                        DisplayName: '',
                        OptionType: stringopt,
                    }
                    if(stringopt === 'Document Register'){
                        arrayobj.ConfigCollection = 'DocumentRegisters'
                    }
                    else if(stringopt === 'Gallery'){
                        arrayobj.ConfigCollection = 'Galleries'
                    }
                    array.push(arrayobj)
                })
            }
            return array.filter(item => {
                if(this.NewTableOptionSearch){
                    return item.Type.toLowerCase().includes(this.NewTableOptionSearch.toLowerCase())
                }   
                else{
                    return item
                }
            })
        },
        FieldTypesSearched(){
            return this.FieldTypes.filter(field => {
                if(this.NewFieldSearch){
                    return field.Type.toLowerCase().includes(this.NewFieldSearch.toLowerCase())
                }   
                else{
                    return field
                }
            })
        },
        UnusedFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return !field.UsedonForm && !field.SystemRequired
            }).filter(field => {
                if(this.ExistingFieldSearch){
                    return field.Name.toLowerCase().includes(this.ExistingFieldSearch.toLowerCase()) || field.DisplayName.toLowerCase().includes(this.ExistingFieldSearch.toLowerCase())
                }
                else{
                    return field
                }
            })
        },
        ExistingFormFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.UsedonForm && !field.SystemRequired
            }).filter(field => {
                if(this.EditedTab){
                    let fields = this.EditedTab.Fields.map(tabfield => {return tabfield.id})
                    return fields.includes(field.id)
                }
                else{
                    return field
                }
            })
            .filter(field => {
                if(this.ExistingFormFieldSearch){
                    return field.Name.toLowerCase().includes(this.ExistingFormFieldSearch.toLowerCase()) || field.DisplayName.toLowerCase().includes(this.ExistingFormFieldSearch.toLowerCase())
                }
                else{
                    return field
                }
            })
        },
    },
    created(){
        
    },
    methods:{
        AssignDescriptionList(EditedTab){
            this.$emit('AssignDescriptionList',EditedTab)
        },
        CancelAddNewWikiField(){
            this.NewWikiFieldDialog = false
            this.NewFieldName = ''
            this.NewfieldType = ''
        },
        AddNewWikiField(){
            let newwikifield = {
                Name: this.NewFieldName.split(' ').join('_'),
                Type: this.NewfieldType,
                Source: 'Page Record Prop'
            }
            this.ProcessAddNewWikiField(newwikifield)
            this.CancelAddNewWikiField()
        },
        ProcessAddNewWikiField(newwikifield){
            
            let oncheck = this.CurrentEntity.WikiFields.find(obj => obj.Name === newwikifield.Name)
            if(!oncheck){
                this.CurrentEntity.WikiFields.push(newwikifield)
            }
            // this.WikiSourceDialog = false
            // setTimeout(() => {
            //         this.WikiSourceDialog = true
            // }, 1000);
            console.log(this.CurrentEntity)
        },
        UpdateWikiSource(EditedTab,SourceProps){
            EditedTab.SourceProps = SourceProps
            EditedTab.SourceProps.map(prop => {
                if(!prop.IsArray){
                    //actually just assign the values now
                    if(prop.SourceType === 'Data Field' || prop.SourceType === 'Page Record Prop'){
                        let value = this.DynamicWikiData[prop.DataProp.Name]
                        
                        if(value !== 'undefined'){
                            if(prop.DataProp.Type === 'Lookup'){
                                value = value[prop.DataProp.LookupFieldName]
                            }
                            else if(prop.DataProp.Type === 'Option Set'){
                                value = value.Name
                            }
                            else if(prop.DataProp.Type === 'Single File Upload'){
                                value = value.url
                            }
                            EditedTab[prop.ElementProp] = value
                        }                  
                            let newwikifield = Object.assign({},prop.DataProp)
                            newwikifield.Source = 'Data Field'
                            console.log('SourceType value',prop.DataProp.Name,value,prop.ElementProp,EditedTab)
                            this.ProcessAddNewWikiField(newwikifield)                    
                    }
                }
                else{
                    if(prop.SourceType === 'Data Field'){
                        let arrayobj = this.CurrentEntity[prop.EntityProp].find(obj => obj.id === prop.DataProp)
                        if(arrayobj){
                            EditedTab[prop.ElementProp] = arrayobj.DataArray.map(item => {
                                if(item.url){
                                    item.URL = item.url
                                }
                                return item
                            })
                        }
                    }
                }
               
               
            })
           
            console.log(EditedTab,this.CurrentEntity)
        },
        CancelWikiSourceDialog(){
            this.WikiSourceDialog = false
        },
        RefreshComponent(EditedTab){
            this.$emit('RefreshComponent',EditedTab)
        },
        UpdateWikiValue(EditedTab,tabprop,recordfield){
            this.$emit('UpdateWikiValue',EditedTab,tabprop,recordfield)
        },
        AssignWikiBasedGallery(EditedTab){
            this.$emit('AssignWikiBasedGallery',EditedTab)
        },
        ActivateTableOptionDialog(tableoption){
            this.$emit('ActivateTableOptionDialog',tableoption)
        },
        ConfigureTable(EditedTab){
            this.$emit('ConfigureTable',EditedTab)
        },
        onImportfileSelected(event){
        this.$emit('onImportfileSelected',event)
        },
        DownloadFieldsImportTemplate(){
            this.$emit('DownloadFieldsImportTemplate')
        },
        closeImport(){
            this.$emit('closeImport')
        },
        saveImport() {
            this.$emit('saveImport')
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            this.$emit('ActivateNewFieldDialog',field,AddingNewField,ListPanel)
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
