<template>
  <div>
     <!-- <v-btn fab icon style="position: absolute;z-index:100;" x-large @click="CheckforCharts(ComputedChartObj)">
         <v-icon >mdi-refresh</v-icon>
     </v-btn> -->
   <v-row class="justify-center" v-if="ComputedChartObj.HasTwoColumns && ComputedChartObj.ComponentOne"> 			
            		<!-- xl12 lg12 md12 sm12 xs12 -->
        <v-flex xl10 lg11 md11 sm11 xs10 class="mx-1">
            <component :is="ComputedChartObj.ComponentOne.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
            :HasData="ComputedChartObj.HasData" :i="i" :index="0" :CompProperties="ComputedChartObj.ComponentOne" :elmnt="ComputedChartObj" :UsersArray="UsersArray" :DashboardEntity="ComputedChartObj.DashboardEntity"			
            :AppisDarkMode="AppisDarkMode"/>   			
        </v-flex>	
        <!-- xl12 lg12 md12 sm12 xs12 -->		
        <v-flex xl10 lg11 md11 sm11 xs10 style="margin-top:-1px;" class="elmntcomp2flex mx-1">	
            <component :is="ComputedChartObj.ComponentTwo.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
            :HasData="ComputedChartObj.HasData" :i="i" :index="0" :CompProperties="ComputedChartObj.ComponentTwo" :elmnt="ComputedChartObj" :UsersArray="UsersArray" :DashboardEntity="ComputedChartObj.DashboardEntity" 			
            :AppisDarkMode="AppisDarkMode"/>      			
        </v-flex>			
        </v-row>			
        <v-row class="justify-center" :style="PushedElmnt ? 'margin-top:-60px;':''"  v-if="ComputedChartObj.HasTwoRows && ComputedChartObj.ComponentOne && ComputedChartObj.HasData">			
                    <!-- xl10 lg11 md11 sm11 xs10 -->
            <v-flex xl10 lg11 md11 sm11 xs10 class="mx-1">			
            <component :is="ComputedChartObj.ComponentOne.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
            :HasData="ComputedChartObj.HasData" :i="i" :index="0" :CompProperties="ComputedChartObj.ComponentOne" :elmnt="ComputedChartObj" :UsersArray="UsersArray" :DashboardEntity="ComputedChartObj.DashboardEntity"  			
            :AppisDarkMode="AppisDarkMode"/>    			
            </v-flex>			
        </v-row>  			
                    
            <v-row class="justify-center" :style="PushedElmnt ? 'margin-top:-60px;':''" v-if="ComputedChartObj.ComponentOne && !ComputedChartObj.HasTwoColumns && !ComputedChartObj.HasTwoRows">			
                    <!-- xl10 lg11 md11 sm11 xs10 -->
            <v-flex xl10 lg11 md11 sm11 xs10 class="">			
            <component :is="ComputedChartObj.ComponentOne.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
            :HasData="ComputedChartObj.HasData" :i="i" :index="0" :CompProperties="ComputedChartObj.ComponentOne" :elmnt="ComputedChartObj" :UsersArray="UsersArray" :DashboardEntity="ComputedChartObj.DashboardEntity"  			
            :AppisDarkMode="AppisDarkMode"/>                  			
            </v-flex>			
            </v-row>	
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder';
// import { VueFunnelGraph } from 'vue-funnel-graph-js';			
import DashboardElmtDataContext from '@/components/SuitePlugins/CustomDashboard/DashboardElmtDataContext'			
import DashboardElmtChart from '@/components/SuitePlugins/CustomDashboard/DashboardElmtChart'			
export default {
    props: ['System','SystemEntities','ChartObj','i','QuickElementid'],
    components: {
        DashboardBuilder,
        DashboardElmtDataContext,			
        DashboardElmtChart,	
        // VueFunnelGraph		
      },
    data() {
        return {
            Today: '',
            TimelineFilterOptions: [			
        {			
          ID: 1000000,			
          FilterName: 'This Day (Per 2 Hrs)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Day',			
          StartDateVariable: 'ThisToday',			
          EndDateVariable: 'ThisTodayEnd',			
          ChartLabels: ['0AM - 2AM','2AM - 4AM','4AM - 6AM','6AM - 8AM','8AM - 10AM','10AM - 12PM','12PM - 14PM','14PM - 16PM','16PM - 18PM','18PM - 20PM','20PM - 22PM','22PM - 0AM'],			
          ChartLabelsVariables: ['ThisToday','Todayby2AM','Todayby4AM','Todayby6AM','Todayby8AM','Todayby10AM','Todayby12PM','Todayby14PM','Todayby16PM','Todayby18PM','Todayby20PM','Todayby22PM'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'Endof2Hours',			
        },
        {			
          ID: 1000001,			
          FilterName: 'This Week (Per Day)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Week',			
          StartDateVariable: 'ThisMonday',			
          EndDateVariable: 'ThisSunday',			
          ChartLabels: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],			
          ChartLabelsVariables: ['ThisMonday','ThisTuesday','ThisWednesday','ThisThursday','ThisFriday','ThisSaturday','ThisSunday'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'EndofDay',			
        },			
        {			
          ID: 1000002,			
          AccumulatorID: 1000001,			
          FilterName: 'This Month (Per Week)',			
          FilterDescription: 'When viewing, this filter returns each Week for the total number of Weeks of the Current Month, and relevant results on each Week. For Goals, the results shown is by default filtered by the current Week',			
          GiveName: 'Per Week',			
          Name: 'This Month',			
          StartDateVariable: 'CurrentMonthStart',			
          EndDateVariable: 'CurrentMonthEnd',			
          ChartLabelTrigger: 'CurrentMonthName',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentMonthLabels',         			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofWeek',			
        },			
        {			
          ID: 1000003,			
          AccumulatorID: 1000002,			
          FilterName: 'This Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month',			
          Name: 'This Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
        },			
        {			
          ID: 1000004,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Q1','Q2','Q3','Q4'],			
          ChartLabelsVariables: ['FiscalQ1Start','FiscalQ2Start','FiscalQ3Start','FiscalQ4Start'],			
          ChartlabelPeriodEndMethodX: 'EndofQuarter',			
          ChartlabelPeriodEndMethodY: 'EndofQuarter'			
        },			
        {			
          ID: 1000005,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Quarter (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Quarter, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Quarter',			
          StartDateVariable: 'CurrentFiscalQStart',			
          EndDateVariable: 'CurrentFiscalQEnd',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentFiscalLabels',			
          ChartLabelTrigger: 'CurrentFiscalPeriod',			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
          			
        },			
        			
      ],
            DefaultColors: [],
            BasicPieChart: {
                HideActions: true,
                ChartBGObj: {hex: '#c67eb7'},
                HasChart: true,			
                HasDataContext: true,			
                ShowTitle: true,	
                ChartPosition: 'Right',			
                DataContextStyle: 'List',			
                ChartType: 'pie',			
                ChartLabelsfromTimeline: false,
            },
        }
    },
    computed:{
        QuickElementid(){
            return 'row'+this.i+'element'+0+'chart'
        },
        PreparedEntities(){
            return this.SystemEntities.concat(this.ChartObj.ChartEntities)
        },
        ThisToday(){
        return new Date(this.Today)
        },
        Todayby2AM(){
        let d = new Date(this.ThisToday)
        d.setHours(2)
        return d
        },
        Todayby4AM(){
        let d = new Date(this.ThisToday)
        d.setHours(4)
        return d
        },
        Todayby6AM(){
        let d = new Date(this.ThisToday)
        d.setHours(6)
        return d
        },
        Todayby8AM(){
        let d = new Date(this.ThisToday)
        d.setHours(8)
        return d
        },
        Todayby10AM(){
        let d = new Date(this.ThisToday)
        d.setHours(10)
        return d
        },
        Todayby12PM(){
        let d = new Date(this.ThisToday)
        d.setHours(12)
        return d
        },
        Todayby14PM(){
        let d = new Date(this.ThisToday)
        d.setHours(14)
        return d
        },
        Todayby16PM(){
        let d = new Date(this.ThisToday)
        d.setHours(16)
        return d
        },
        Todayby18PM(){
        let d = new Date(this.ThisToday)
        d.setHours(18)
        return d
        },
        Todayby20PM(){
        let d = new Date(this.ThisToday)
        d.setHours(20)
        return d
        },
        Todayby22PM(){
        let d = new Date(this.ThisToday)
        d.setHours(22)
        return d
        },	
        ThisTodayEnd(){
        return this.EndofDay(this.Today)
        },
        ThisMonday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+1))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisTuesday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+2))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisWednesday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+3))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisThursday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+4))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisFriday(){			
      let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+5))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisSaturday(){			
       let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+6))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisSunday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+7))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      LastweekLastDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      NextweekFirstDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek + 7))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      CurrentYear(){			
          return this.ComputedToday.getFullYear()			
      },			
      ThreeYearsStartDate(){			
          let threeyrsback = this.CurrentYear-2			
          let date = new Date(threeyrsback.toString()+'-01-01')			
          date.setHours(0,0,0,0)			
          return date			
      },			
      CurrentyearStartDate(){			
          let date = new Date(this.CurrentYear.toString()+'-01-01')			
          date.setHours(0,0,0,0)			
          return date			
      },			
          			
      ComputedToday(){			
          let date = new Date(this.Today)			
          date.setHours(0,0,0,0)			
          return date			
      },	
      ThisJanuaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 0;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisFebruaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 1;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisMarchStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 2;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisAprilStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 3;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisMayStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 4;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJuneStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 5;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJulyStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 6;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisAugustStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 7;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisSeptemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 8;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisOctoberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 9;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisNovemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 10;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisDecemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 11;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },
      CurrentMonthLabels(){			
      //to return the weeks of the month. But possible return as objects for looping purposes, TBD			
       let variabletableobj = {			
        ChartLabels: ['Week 1','Week 2','Week 3','Week 4','Week 5'],          			
        ChartLabelsVariables: ['MonthWeek1Start','MonthWeek2Start','MonthWeek3Start','MonthWeek4Start','MonthWeek5Start'],			
      }			
      let Wvarobj = {			
        Labels: [],			
        ChartLabelsVariables : []			
      }			
      let length = 4			
      let newtoday = new Date(this.Today)			
      var firstdayofmonth = 1;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofmonth)			
      d.setDate(firstdayofmonth+28)			
      d.setHours(0,0,0,0);			
      let week5month = d.getMonth()			
      if(week5month === month){			
        length = 5			
      }			
      for(var i = 0; i < length; i++){			
        Wvarobj.Labels[i] = variabletableobj.ChartLabels[i]			
        Wvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[i]			
      }			
      return Wvarobj			
    },			
    MonthWeek1Start(){			
      let newtoday = new Date(this.Today)			
      var firstdayofmonth = 1;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek2Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek1Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek3Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek2Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek4Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek3Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek5Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek4Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },	
        ComputedChartObj(){
            let elmnt = Object.assign({},this.ChartObj)
            if(elmnt.TimeFilterOption && !elmnt.TimeFilterOption.Name){
                elmnt.TimeFilterOption = this.TimelineFilterOptions.find(obj => obj.Name === elmnt.TimeFilterOption)
            }
            //console.log('elmnt',elmnt)	
              if(elmnt.PrimaryFilterType === 'Lookup' && elmnt.PrimaryFilterField){	
                	
                if(elmnt.PrimaryFilterField.RelatedBuildID === 'Users' || elmnt.PrimaryFilterField.RelatedRecord === 'Users'){			
                  elmnt.FilterArray = this.UsersArray			
                }			
                else{			
                  let lookupobj = this.LookupArrays.find(obj => obj.Field === elmnt.PrimaryFilterField.id)			
                  elmnt.FilterArray = lookupobj.Array.map(item => {			
                    item.FieldName = item[elmnt.PrimaryFilterField.LookupFieldName]			
                    return item			
                  })                  			
                }			
                			
              }			
              elmnt = this.PrepareElementComponents(elmnt)			
              let ChartlabelPeriodEndMethodX = '' 			
              let ChartlabelPeriodEndMethodY = ''			
              let ChartLabelsVariables = ''			
              let ChartLabels = ''			
              if(elmnt.TimeFilterOption && !elmnt.TimeFilterOption.ChartLabelsisDynamic){			
                ChartlabelPeriodEndMethodX = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodX			
                ChartlabelPeriodEndMethodY = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodY			
                ChartLabelsVariables = elmnt.TimeFilterOption.ChartLabelsVariables			
                ChartLabels = elmnt.TimeFilterOption.ChartLabels			
                .map((label,i) => {			
                  let obj = {			
                    LabelName: label,			
                    ChartLabelID: i			
                  }			
                  if(elmnt.GoalMeasurements){   			
                      obj.min = this[ChartLabelsVariables[i]]			
                      obj.max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[i]])			
                      //console.log(obj)   			
                      if(!elmnt.GoalTargets){			
                        elmnt = this.GetElementTargets(elmnt)			
                      }             			
                      obj.Targets = elmnt.GoalTargets.filter(target => {return target.StartDate.getTime() === obj.min.getTime() && target.EndDate.getTime() === obj.max.getTime()})			
                      if(elmnt.ActivePrimaryFilter){			
                        obj.Targets = obj.Targets			
                        .filter(target => {			
                          if(elmnt.PrimaryFilterType === 'Lookup'){			
                          return target.Ownerid === elmnt.ActivePrimaryFilter.id			
                          }			
                        })			
                      }			
                       let objtargetvalues = obj.Targets.map(target => {return target.Target})			
                      obj.TargetValues = objtargetvalues.reduce((a, b) => a + b, 0)	
                      }		
                  return obj			
                })			
              }			
              else if(elmnt.TimeFilterOption && elmnt.TimeFilterOption.ChartLabelsisDynamic){			
                ChartlabelPeriodEndMethodX = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodX			
                ChartlabelPeriodEndMethodY = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodY			
                ChartLabelsVariables = this[elmnt.TimeFilterOption.DynamicChartLabelsProp].ChartLabelsVariables			
                ChartLabels = this[elmnt.TimeFilterOption.DynamicChartLabelsProp].Labels			
                .map((label,i) => {			
                  let obj = {			
                    LabelName: label,			
                    ChartLabelID: i			
                  }			
                  			
                  if(elmnt.GoalMeasurements){   			
                      obj.min = this[ChartLabelsVariables[i]]			
                      obj.max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[i]])   			
                                 			
                      obj.Targets = elmnt.GoalTargets.filter(target => {return target.StartDate.getTime() === obj.min.getTime() && target.EndDate.getTime() === obj.max.getTime()})			
                       let objtargetvalues = obj.Targets.map(target => {return target.Target})			
                      obj.TargetValues = objtargetvalues.reduce((a, b) => a + b, 0)	
                      }			
                      			
                       			
                  return obj			
                })			
              }			
              if(elmnt.ChartLabelsfromProcess && elmnt.PipelineProcess){			
                if(!elmnt.PipelineProcess.id){			
                  let process = this.DashboardEntity.EntityProcesses.find(obj => obj.id === elmnt.PipelineProcess)			
                  if(process){			
                    elmnt.PipelineProcess = process			
                  }			
                }			
                if(elmnt.PipelineProcess && elmnt.PipelineProcess.id){			
                  let array = JSON.parse(JSON.stringify(elmnt.PipelineProcess.ProcessStages))			
                  ChartLabels = array.map(item => {			
                  let obj = {			
                      ChartLabelID: item.Number,			
                      LabelName: item.Name			
                  }                 			
                  return obj			
                })			
                }		
              }			
              if(elmnt.ChartBGObj){			
                elmnt.ChartBG = elmnt.ChartBGObj.hex			
              }			
              if(!elmnt.ChartType){			
                elmnt.ChartType = 'line'			
              }			
              let PrimaryDate = ''			
              let PrimaryDateSTRING = ''			
              if(elmnt.PrimaryDateFilter){			
               PrimaryDate = elmnt.PrimaryDateFilter.Name			
               PrimaryDateSTRING = elmnt.PrimaryDateFilter.Name+'filterdatestring' 			
              }					
              let FullArray = []			
              let primaryfield = ''			
              if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.RelatedChild.id){			
                			//alert('this type')
                primaryfield = elmnt.RelatedChild.AllFields.find(obj => obj.Primary === true)			
                primaryfield = primaryfield.Name			
                if(elmnt.RelatedChild.id === 'Activities'){			
                    FullArray = elmnt.Data			
                    elmnt.IsActivities = true			
                }			
                else{			
                  FullArray = elmnt.Data			
                }			
              }			
              else if(elmnt.GoalMeasurements){			
                FullArray = JSON.parse(JSON.stringify(elmnt.Data))		
              }			
              else{			
                FullArray = JSON.parse(JSON.stringify(elmnt.Data))			
              }	
              elmnt.RawData = JSON.parse(JSON.stringify(FullArray))
              .filter(data => {			
                if(this.BusinessUnitFilter){			
                  return data.Business_Unitid === this.BusinessUnitFilter.id			
                }				
                else{			
                  return data			
                }                			
              })			
              .filter(data => {			
                if(this.OwningUserfilter){			
                  return data.Ownerid === this.OwningUserfilter.id			
                }					
                else{			
                  return data			
                }                			
              })   
              .filter(data => {			
                if(this.PrimaryStatusFilter){			
                  return data.Status && data.Status.ID === this.PrimaryStatusFilter.ID			
                }			
                else{			
                  return data			
                }                			
              }) 
              .filter(data => {			
                if(this.SecondaryStatusFilter){			
                  return data.Status_Reason && data.Status_Reason.ID === this.SecondaryStatusFilter.ID			
                }			
                else{			
                  return data			
                }                			
              })               			
              .filter(data => {			
                return data			
              })			
              .filter(data => {			
                if(elmnt.RelatedChild && elmnt.RelatedChild.id === 'Activities' && elmnt.ActivityOwnerFilter){			
                return data.Ownerid === elmnt.ActivityOwnerFilter.id			
                }			
                else{			
                  return data			
                }			
              })//PreFilterField              			
              .filter(data => {	
                if(elmnt.RelatedChild && elmnt.RelatedChild.id === 'Activities' && elmnt.ActivityOwnerFilter){			
                return data
                }	
                else if(elmnt.PreFilter && elmnt.PreFilterField){			
                  if(elmnt.PreFilterField.Type === 'Static Option Set'){			
                    return data[elmnt.PreFilterField.Name] === elmnt.PreFilter.Name			
                  }			
                  else if(elmnt.PreFilterField.Type === 'Option Set'){		
                    if(elmnt.SecondaryFilterField){
                      return data[elmnt.PreFilterField.Name] && data[elmnt.PreFilterField.Name].ID === elmnt.PreFilter.ID && data[elmnt.PreFilterField.Levels[1].FieldName] && data[elmnt.PreFilterField.Levels[1].FieldName].ID === elmnt.SecondaryFilterField.ID
                    }	
                    else{
                      return data[elmnt.PreFilterField.Name] && data[elmnt.PreFilterField.Name].ID === elmnt.PreFilter.ID
                    }                    			
                  }	
                }			
                else{			
                  return data			
                }			
              })//From Timeline fix to get context. because yes we get chart data and labels, but now context = labels...uhm			
              .map(data => {	
                  //console.log('data',data)
                return data			
              })				
              .filter(data => {			
                if(elmnt.ActivePrimaryFilter){			
                  if(elmnt.PrimaryFilterType === 'Option Set'){			
                    return data[elmnt.PrimaryFilterField.Name]			
                     && data[elmnt.PrimaryFilterField.Name].ID === elmnt.ActivePrimaryFilter.ID			
                  }		
                  else if(elmnt.PrimaryFilterType === 'Radio Group'){	
                    let selectedgroups = []
                    if(data[elmnt.PrimaryFilterField.Name]){
                      selectedgroups = data[elmnt.PrimaryFilterField.Name].map(group => {
                        return group.ID
                      })
                    }
                    return data[elmnt.PrimaryFilterField.Name]			
                     && selectedgroups.includes(elmnt.ActivePrimaryFilter.ID)			
                  }	
                  else if(elmnt.PrimaryFilterType === 'Lookup'){			
                    			
                    return data[elmnt.PrimaryFilterField.Name]			
                     && data[elmnt.PrimaryFilterField.Name].id === elmnt.ActivePrimaryFilter.id			
                  }			
                  else if(elmnt.PrimaryFilterType === 'Option Set from Lookup'){			
                    return data[elmnt.PrimaryFilterLookup.Name] && data[elmnt.PrimaryFilterLookup.Name][elmnt.PrimaryFilterField.Name]			
                     && data[elmnt.PrimaryFilterLookup.Name][elmnt.PrimaryFilterField.Name].ID === elmnt.ActivePrimaryFilter.ID			
                  } 			
                  else if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.ChildRelationshipsField){			
                    return data[primaryfield]			
                  } 			
                  else if(elmnt.PrimaryLabelType === 'From Timeline' && elmnt.TimeFilterOption){			
                    return data			
                  } 			
                  			
                }			
                else{			
                  return data			
                }			
              })//Now we assign dateobject			
              .map(arrayrecord => {			      
                if(arrayrecord[PrimaryDate] && typeof arrayrecord[PrimaryDate].toDate !== 'undefined'){			
                    arrayrecord[PrimaryDateSTRING] = this.TimestampFormatterSTRING(arrayrecord[PrimaryDate].toDate())			
                    arrayrecord.PrimaryDateObj = new Date(arrayrecord[PrimaryDateSTRING])			
                }	
                else if(arrayrecord[PrimaryDate] && typeof arrayrecord[PrimaryDate].seconds !== 'undefined'){
                  arrayrecord[PrimaryDateSTRING] = this.TimestampFormatterSTRING(new Date(arrayrecord[PrimaryDate].seconds*1000))	
                  arrayrecord.PrimaryDateObj = new Date(arrayrecord[PrimaryDate].seconds*1000) 
                }
                return arrayrecord			
              })              			
              .filter(arrayrecord => {
                if(elmnt.ActiveDateFilter){			
                  if(!elmnt.ActiveDateFilter.ChartLabelsisDynamic){			
                      let filterChartlabelPeriodEndMethodX = elmnt.ActiveDateFilter.ChartlabelPeriodEndMethodX			
                      let filterChartLabelsVariables = elmnt.ActiveDateFilter.ChartLabelsVariables			
                      let lastvariable = filterChartLabelsVariables.length-1                    			
                      let min = this[filterChartLabelsVariables[0]]			
                      let max = this[filterChartlabelPeriodEndMethodX](this[filterChartLabelsVariables[lastvariable]])
                      return arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max                    			
                    }			
                    else if(elmnt.ActiveDateFilter.ChartLabelsisDynamic){			
                      let filterChartlabelPeriodEndMethodX = elmnt.ActiveDateFilter.ChartlabelPeriodEndMethodX			
                      let filterChartLabelsVariables = this[elmnt.ActiveDateFilter.DynamicChartLabelsProp].ChartLabelsVariables			
                      let lastvariable = filterChartLabelsVariables.length-1                    			
                      let min = this[filterChartLabelsVariables[0]]			
                      let max = this[filterChartlabelPeriodEndMethodX](this[filterChartLabelsVariables[lastvariable]])			
                      return arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max			
                    }			
                }			
                else{			
                  return arrayrecord	
                }
              })//now we filter if ChartLabelsfromTimeline			
              .filter(arrayrecord => {
                if(ChartlabelPeriodEndMethodX && ChartlabelPeriodEndMethodY){     
                   
                    let lastvariable = ChartLabelsVariables.length-1                    	
                    //console.log(ChartLabelsVariables[0],ChartlabelPeriodEndMethodX,ChartLabelsVariables,ChartLabelsVariables[lastvariable],lastvariable)  		
                    let min = this[ChartLabelsVariables[0]]
                    let max = this[ChartlabelPeriodEndMethodX](this[ChartLabelsVariables[lastvariable]])		                    
                    return arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max			
                  }			
                  else{			
                    return arrayrecord			
                  }			
              })//Now just a little step, for sublabelfields that are lookup, assign the record with ID prop			
              .map(arrayrecord => {		
                if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Lookup' && elmnt.PrimaryLabelType !== 'Name of Child'){			
                  if(arrayrecord[elmnt.SublabelField.Name]){			
                    arrayrecord[elmnt.SublabelField.Name].ID = arrayrecord[elmnt.SublabelField.Name].id			
                  }			
                }			
                else if(elmnt.PrimaryLabelType === 'Name of Child'){			
                  let related = elmnt.ChildRelationshipsField			
                  if(arrayrecord[related]){			
                  			
                  let parentobj = this.FullArray.find(obj => obj.id === arrayrecord[related].id)
                    if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Lookup' && !elmnt.SublabelFromRelatedChild){			
                      if(parentobj[elmnt.EntityRelatedfield.Name]){			
                        arrayrecord[elmnt.EntityRelatedfield.Name] = parentobj[elmnt.EntityRelatedfield.Name]			
                        arrayrecord[elmnt.EntityRelatedfield.Name].ID = arrayrecord[elmnt.EntityRelatedfield.Name].id			
                      }			
                    }			
                    else if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Option Set' && !elmnt.SublabelFromRelatedLookup && !elmnt.SublabelFromRelatedChild){	
                      if(parentobj[elmnt.SublabelField.Name]){			
                        arrayrecord[elmnt.SublabelField.Name] = parentobj[elmnt.SublabelField.Name]			
                      }			
                    }			
                    else if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Option Set' && elmnt.SublabelFromRelatedLookup && !elmnt.SublabelFromRelatedChild){			
                      if(parentobj[elmnt.EntityRelatedfield.Name] && parentobj[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name]){			
                        arrayrecord[elmnt.EntityRelatedfield.Name] = parentobj[elmnt.EntityRelatedfield.Name]			
                        arrayrecord[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name] = parentobj[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name]			
                      }			
                    }			
                    else if(elmnt.SublabelField && elmnt.SublabelFromRelatedChild){			
                      if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Lookup'){			
                        if(arrayrecord[elmnt.SublabelField.Name]){			
                            arrayrecord[elmnt.SublabelField.Name].ID = arrayrecord[elmnt.SublabelField.Name].id			
                          }			
                      }			
                    }		
                  }			
			
                }			
                return arrayrecord			
              })              				
              .map(arrayrecord => {			
                if(elmnt.ValueType === 'Count'){			
                  arrayrecord.RawValue = 1			
                  arrayrecord.ContextValue = 1			
                }			
                else if(elmnt.ValueType === 'Amount'){	
                  console.log('elmnt.ValueField',elmnt.ValueField,arrayrecord,arrayrecord[elmnt.ValueField.Name],elmnt.ChartLabels)		
                  if(elmnt.ValueField && elmnt.ValueCurrency && arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = arrayrecord[elmnt.ValueField.Name]
                    arrayrecord.ContextValue = this.CurrencyFormatter(arrayrecord[elmnt.ValueField.Name],this.$store.state.DefaultCurrency.Currency)		
                  }		
                  else if(elmnt.ValueField && elmnt.ValueCurrency && !arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = 0			
                    arrayrecord.ContextValue = this.CurrencyFormatter(0,this.$store.state.DefaultCurrency.Currency)			
                  }	
                  else if(elmnt.ValueField && !elmnt.ValueCurrency && arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = arrayrecord[elmnt.ValueField.Name]			
                    arrayrecord.ContextValue = arrayrecord[elmnt.ValueField.Name]			
                  }	
                  else if(elmnt.ValueField && !elmnt.ValueCurrency && !arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = 0		
                    arrayrecord.ContextValue = 0		
                  }
                }			
                return arrayrecord			
              })//Assign ContextID to each record based on label config - if lookup then it's .id, it option set then ID, if timeline then index			
              .map(arrayrecord => {			
                if(!elmnt.ChartLabelsfromProcess){			
                if(elmnt.PrimaryLabelType === 'Option Set' && elmnt.PrimaryLabelField && arrayrecord[elmnt.PrimaryLabelField.Name] && !elmnt.ChartLabelsfromProcess){			
                  console.log(elmnt.PrimaryLabelField.Options,arrayrecord[elmnt.PrimaryLabelField.Name])			
                  let titleobj = elmnt.PrimaryLabelField.Options.find(obj => obj.ID === arrayrecord[elmnt.PrimaryLabelField.Name].ID)			
                  arrayrecord.ContextTitle = titleobj.Name			
                  arrayrecord.ContextID = arrayrecord[elmnt.PrimaryLabelField.Name].ID			
                  if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                  arrayrecord.ChartLabelID = arrayrecord.ContextID			
                  }			
                  else if(elmnt.ChartLabelsfromTimeline){			
                  ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                      let min = this[ChartLabelsVariables[chartlabelindex]]			
                      let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])			
                        if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                          arrayrecord.ChartLabelID = chartlabelindex			
                        }        			
                  })                 			
                  }			
			
                }			
                else if(elmnt.PrimaryLabelType === 'Lookup' && elmnt.PrimaryLabelField && elmnt.PrimaryLabelField.Type === 'Lookup' && arrayrecord[elmnt.PrimaryLabelField.Name]){			
                  			
                  arrayrecord.ContextTitle = arrayrecord[elmnt.PrimaryLabelField.Name][elmnt.PrimaryLabelField.LookupFieldName]			
                  arrayrecord.ContextID = arrayrecord[elmnt.PrimaryLabelField.Name].id			
                  if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                  arrayrecord.ChartLabelID = arrayrecord.ContextID			
                  }			
                  else if(elmnt.ChartLabelsfromTimeline){			
                 			
                  ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                      let min = this[ChartLabelsVariables[chartlabelindex]]			
                      let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])                      			
                      			
                        if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                          arrayrecord.ChartLabelID = chartlabelindex			
                        }        			
                  })                 			
                  }			
			
                }			
                //arrayrecord[elmnt.PrimaryLabelField.Name]
                else if(elmnt.PrimaryLabelType === 'Option Set from Lookup' && elmnt.PrimaryLabelLookup && arrayrecord[elmnt.PrimaryLabelLookup.Name]){
                  let lookupobj = this.LookupArrays.find(obj => obj.Field === elmnt.PrimaryLabelLookup.id)
                  if(lookupobj){
                    let match = lookupobj.Array.find(obj => obj.id === arrayrecord[elmnt.PrimaryLabelLookup.Name].id)
                    if(match){
                      arrayrecord[elmnt.PrimaryLabelLookup.Name] = match
                    }                    
                    if(arrayrecord[elmnt.PrimaryLabelLookup.Name][elmnt.PrimaryLabelField.Name]){
                    
                    let titleobj = elmnt.PrimaryLabelField.Options.find(obj => obj.ID === arrayrecord[elmnt.PrimaryLabelLookup.Name][elmnt.PrimaryLabelField.Name].ID)			
                    arrayrecord.ContextTitle = titleobj.Name			
                    arrayrecord.ContextID = arrayrecord[elmnt.PrimaryLabelLookup.Name][elmnt.PrimaryLabelField.Name].ID			
                    if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                    arrayrecord.ChartLabelID = arrayrecord.ContextID			
                    }			
                    else if(elmnt.ChartLabelsfromTimeline){			
                      elmnt.TargetsbyLabel = []			
                    ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                        let min = this[ChartLabelsVariables[chartlabelindex]]			
                        let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])                      			
                          if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                            arrayrecord.ChartLabelID = chartlabelindex			
                          }        			
                    })                 			
                    }	
                    }
                  }                  
                }			
                 else if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.ChildRelationshipsField){			
                    arrayrecord.ContextTitle = arrayrecord[primaryfield]			
                    arrayrecord.ContextID = arrayrecord.id			
                   if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                    arrayrecord.ChartLabelID = arrayrecord.ContextID			
                    }			
                    else if(elmnt.ChartLabelsfromTimeline){			
                    ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                        let min = this[ChartLabelsVariables[chartlabelindex]]			
                        let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])			
                          if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                            arrayrecord.ChartLabelID = chartlabelindex			
                          }        			
                    })                 			
                    }                   			
                  } 			
                  else if(elmnt.PrimaryLabelType === 'From Timeline' && elmnt.TimeFilterOption){			
                    arrayrecord.ContextTitle = elmnt.TimeFilterOption.Name			
                    arrayrecord.ContextID = elmnt.TimeFilterOption.ID			
                   if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                    arrayrecord.ChartLabelID = arrayrecord.ContextID			
                    }			
                    else if(elmnt.ChartLabelsfromTimeline){			
                    ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                        let min = this[ChartLabelsVariables[chartlabelindex]]			
                        let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])			
                          if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                            arrayrecord.ChartLabelID = chartlabelindex			
                          }        			
                    })                 			
                    }                   			
                  } 			
                  			
                }			
                else if(elmnt.ChartLabelsfromProcess && elmnt.PipelineProcess){			
                    //We already assigned Chartlabels now so we must do something with it here			
                    ChartLabels.map((chartlabel,chartlabelindex) => {			
                      if(arrayrecord.ActiveProcess && arrayrecord.stagenameid){			
                       			
                          arrayrecord.ChartLabelID = arrayrecord.stagenameid			
                          arrayrecord.ContextTitle = arrayrecord.ChartLabelID			
                          arrayrecord.ContextID = arrayrecord.ChartLabelID			
                      }			
                    })			
                  }			
                			
                return arrayrecord			
              })//final filter on pipeline process to return only data that has ativeprocess			
              .filter(arrayrecord => {			
                //console.log('data',arrayrecord) 
                  if(elmnt.ChartLabelsfromProcess && elmnt.PipelineProcess){			
                    return arrayrecord.ActiveProcess			
                  }			
                  else{			
                    return arrayrecord			
                  }			
              })			
              //console.log('elmnt.RawData',elmnt.RawData)			
			
              //Now we have the raw data, based on the filters, BEFORe values, and BEFORE structure to labels. 			
              //however if from child then structure changes			
              // elmnt.ContextfromChild			
			
              let RawData = []			
                  RawData = elmnt.RawData			
              //Now for elmnt RawValue			
               if(elmnt.GoalMeasurements){   			
               elmnt.RawTarget = ChartLabels.map(label => {			
                 return label.TargetValues			
               }).reduce((a, b) => a + b, 0)			
                if(elmnt.ValueType === 'Count'){			
                  elmnt.ContextTarget = elmnt.RawTarget			
                }			
                else if(elmnt.ValueType === 'Amount'){			
                    if(elmnt.ValueField && elmnt.ValueCurrency){			
                      elmnt.ContextTarget = this.CurrencyFormatter(elmnt.RawTarget,this.$store.state.DefaultCurrency.Currency)		
                    }	
                    else if(elmnt.ValueField && !elmnt.ValueCurrency){			
                      elmnt.ContextTarget = elmnt.RawTarget			
                    }		
                }			
             			
               }			
              elmnt.RawValue = RawData			
              .filter(arrayrecord => {			
                return typeof arrayrecord.RawValue !== 'undefined'			
              })			
              .map(arrayrecord => {			
                //now go get the values, formatted			
                return arrayrecord.RawValue			
              }).reduce((a, b) => a + b, 0)			
              if(elmnt.ValueType === 'Count'){			
                elmnt.ContextValue = elmnt.RawValue			
              }			
              else if(elmnt.ValueType === 'Amount'){			
                  if(elmnt.ValueField && elmnt.ValueCurrency){			
                    elmnt.ContextValue = this.CurrencyFormatter(elmnt.RawValue,this.$store.state.DefaultCurrency.Currency)			
                  }
                  else if(elmnt.ValueField && !elmnt.ValueCurrency){			
                      elmnt.ContextValue = elmnt.RawValue			
                    }
              }			
              if(elmnt.GoalMeasurements){			
                let achievement = elmnt.RawValue/elmnt.RawTarget			
                console.log(achievement)			
                if(achievement>=1){			
                  elmnt.AchievementClass = 'success--text'			
                }			
                else{			
                  elmnt.AchievementClass = 'red--text'			
                }			
                let achievedpercentage = achievement*100			
                console.log(achievedpercentage)			
                elmnt.AchievedPercentage = achievedpercentage.toFixed(2) 			
                elmnt.Achievement = elmnt.AchievedPercentage+'% Achieved'			
              }			
              //console.log(elmnt.RawValue)			
              //Rawdata - CHECK, RawVALUe - CHECK, ContextValue - CHECK, Now elmnt.Context Structure (chartlabels)			
			
			
                //HOWEVER THINKING ACTIVITIES OR ANYTHING ELSE THAT COULD BE CHILD RECORDS, WE MUST NOW FIRST GET			
                //CHILDRAWDATA - GET, CHILDRAWVALUE - GET, CHILDCONTEXTVALUE - GET			
                //PRECEDING THIS, TEMP NOTE - filteredateobj should become "duedatefilteradteobj", or "stockdatefilteradteobj" etc 			
                //BECAUSE we will now get filterdate for parent, and then filter date for child			
                //is this maybe mre applicable elsehwere as in, Filter date stock, filter date whatever all on parent?			
			
                //THE POINT IS WE HAVE RAW DATA AND RAWVALUE AND I THINK IT IS GREAT BUT ACTUALLY BEFORE RAW VALUE WE MUST RESTRUCTURE TO CHILD, IF CHILD APPLICABLE. 			
			
                			
			
			
			
                elmnt.Context = []			
                if(elmnt.ChartLabelsfromProcess){		
                  elmnt.Context = ChartLabels.map(chartlabel => {			
                    let obj = {			
                      ContextTitle: chartlabel.LabelName,			
                      ContextID: chartlabel.ChartLabelID			
                    }			
                    return obj			
                  })			
                }			
                else if(elmnt.PrimaryLabelType === 'Option Set' && elmnt.PrimaryLabelField){
                  elmnt.Context = elmnt.PrimaryLabelField.Options			
                  .map(opt => {			
                    let obj = {			
                      ContextTitle: opt.Name,			
                      ContextID: opt.ID			
                    }			
                    return obj			
                  })			
                }			
                else if(elmnt.PrimaryLabelType === 'Lookup' && elmnt.PrimaryLabelField && elmnt.PrimaryLabelField.Type === 'Lookup' ){			
                  //now return from raw data push what is not there - arrayrecord.ContextID = arrayrecord[PrimaryLabelField.Name].id	
                  RawData			
                  .map(data => {			
                    if(data.ContextTitle && data.ContextID){
                      let oncheck = elmnt.Context.find(obj => obj.ContextID === data.ContextID)			
                      if(!oncheck){			
                        elmnt.Context.push({ContextTitle: data.ContextTitle, ContextID: data.ContextID})			
                      }			
                    }			
                  })			
                }			
                else if(elmnt.PrimaryLabelType === 'Option Set from Lookup' && elmnt.PrimaryLabelLookup && elmnt.PrimaryLabelField){		
                  elmnt.Context = elmnt.PrimaryLabelField.Options			
                  .map(opt => {			
                    let obj = {			
                      ContextTitle: opt.Name,			
                      ContextID: opt.ID			
                    }			
                    return obj			
                  })			
                }			
                 else if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.ChildRelationshipsField){		
                   //console.log('elmnt',elmnt)			
                    elmnt.Context = RawData.map(arrayrecord => {			
                       let obj = {			
                        ContextTitle: arrayrecord[primaryfield],			
                        ContextID: arrayrecord.id,			
                        IsChild: true			
                      }			
                      if(elmnt.RelatedChild.id === 'Activities'){			
                        obj.IsActivities = true			
                        			
                      }			
                      return obj			
                    })			
                 			
                  }			
                  else if(elmnt.PrimaryLabelType === 'From Timeline' && elmnt.TimeFilterOption){	
                   //console.log(elmnt)			
                    elmnt.Context = [{			
                        ContextTitle: elmnt.TimeFilterOption.Name,			
                        ContextID: elmnt.TimeFilterOption.ID,			
                      }]                 			
                  }			
                  			
                  //console.log('elmnt.Context',elmnt.Context,elmnt)			
                 			
                //so what about processes and pipeline? we need to ensure then that PrimaryLabel type is "process" and Primaryfield is "ActiveProcess"			
                //wiht a Contexttitle of "ProcessStages(match).Name, matching ProcessStageInteger as ContextID,"			
                if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){		
                  elmnt.ChartLabels = elmnt.Context.map(item => {			
                    let obj = {			
                      LabelName: item.ContextTitle,			
                      ChartLabelID: item.ContextID			
                    }			
                    return obj			
                  })			
                }			
              // }			
               else{			
                 elmnt.ChartLabels = ChartLabels			
               }			
              // console.log(elmnt.ChartLabels)			
              			
			
              //Rawdata - CHECK, RawVALUe - CHECK, ContextValue - CHECK, elmntContext - STRUCTURE CHECK BUT Now we extend that with data,rawvalue and contextvalue 			
              elmnt.Context.map(contextitem => {			
                contextitem.ContextData = RawData			
                .filter(data => {			
                  return data.ContextID === contextitem.ContextID			
                })			
                 contextitem.RawValue = contextitem.ContextData			
                .map(data => {			
                  return data.RawValue			
                })			
                .reduce((a, b) => a + b, 0) 			
               			
                //console.log(contextitem.RawValue)			
                if(elmnt.ValueType === 'Count'){			
                  contextitem.ContextValue = contextitem.RawValue			
                }			
                else if(elmnt.ValueType === 'Amount'){			
                  if(elmnt.ValueField && elmnt.ValueCurrency){			
                    contextitem.ContextValue = this.CurrencyFormatter(contextitem.RawValue,this.$store.state.DefaultCurrency.Currency)			
                  }		
                  else if(elmnt.ValueField && !elmnt.ValueCurrency){			
                    contextitem.ContextValue = contextitem.RawValue			
                  }	
                }			
              })			
              //console.log('elmnt.Context',elmnt.Context)			
              			
			
              elmnt.ChartLabels = elmnt.ChartLabels.map(label => {			
                label.DataArray = elmnt.Context.map(contextitem => {			
                  let dataarray = contextitem.ContextData.filter(data => {			
                    return data.ChartLabelID === label.ChartLabelID			
                  })  			
                  return dataarray			
                }).flat()			
                return label			
              })		
              elmnt.ChartColors = []			
              if(elmnt.ChartLabelsfromTimeline){			
                			
                ChartLabelsVariables.map((variable,index) => {			
                  if(this[variable] > this.ComputedToday){			
                    elmnt.ChartColors[index] = '#ffffff'			
                  }			
                  else if(this[variable] < this.ComputedToday){			
                    elmnt.ChartColors[index] = '#ff0000'			
                  }			
                  else{			
                    elmnt.ChartColors[index] = '#FF9800'			
                  }			
                })			
              }			
              else{			
                if(this.PushedElmnt && this.PushedElmnt.PipelineProcess && !this.SublabelField){			
                  elmnt.Context.map((contextitem,index) => {			
                    elmnt.ChartColors[index] = this.PushedElmnt.ThemeColor			
                  })			
                }			
                else{			
                  elmnt.Context.map((contextitem,index) => {			
                    let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                    elmnt.ChartColors[index] = this.DefaultColors[ranint]			
                  }) 			
                }			
                			
              }			
              elmnt.ChartData = {			
                labels: elmnt.ChartLabels.map(contextitem => {return contextitem.LabelName}),			
                datasets: []			
              }			
              elmnt.ChartValues = []			
              elmnt.NEWSubLabelsdata = []			
              if(!elmnt.SublabelField && !elmnt.GoalMeasurements){			
                elmnt.ChartData.datasets[0] = {			
                  fill: false,			
                  borderColor: '#ffffff',			
                  backgroundColor: elmnt.ChartColors,			
                  data: []			
                }			
                elmnt.ChartLabels			
                .map((label,labelindex) => {			
                  if(elmnt.AccumulativeGraph && elmnt.ChartType === 'line' && elmnt.ChartLabelsfromTimeline){			
                    elmnt.ChartData.datasets[0].data[labelindex] = RawData			
                    .filter(data => {                      			
                      return data.ChartLabelID <= label.ChartLabelID			
                    })			
                    .map(contextitem => {			
                        return contextitem.RawValue			
                      }).reduce((a, b) => a + b, 0)                  			
                }			
                else{			
                elmnt.ChartData.datasets[0].data[labelindex] = label.DataArray.map(contextitem => {			
                    return contextitem.RawValue			
                  }).reduce((a, b) => a + b, 0)   			
                }			
                  			
                  if(elmnt.ChartLabelsfromProcess){			
                  elmnt.ChartValues = elmnt.ChartData.datasets[0].data			
                }                			
                })			
                			
              }			
              else if(elmnt.GoalMeasurements){		
                elmnt.Timelines = []				
                let rangemax = ChartLabels[ChartLabels.length-1].max			
                this.TimelineFilterOptions.map(opt => {			
                  let TimelineItem = {			
                            Name: opt.Name,			
                            Period: opt.Name,			
                            TimelineID: opt.ID			
                        }			
                    if(!opt.ChartLabelsisDynamic){			
                      let length = opt.ChartLabels.length			
                    TimelineItem.min = this[opt.ChartLabelsVariables[0]]			
                    TimelineItem.max = this[opt.ChartlabelPeriodEndMethodX](this[opt.ChartLabelsVariables[length-1]])    			
                    let targets = elmnt.GoalTargets.filter(obj => {return obj.StartDate.getTime() === TimelineItem.min.getTime() && obj.EndDate.getTime() === TimelineItem.max.getTime()})			
                      TimelineItem.Target = targets.map(target => {return target.Target}).reduce((a, b) => a + b, 0)			
                    }    			
                    else  if(opt.ChartLabelsisDynamic){	
                    let length = this[opt.DynamicChartLabelsProp].Labels.length			
                    let minprop = this[opt.DynamicChartLabelsProp].ChartLabelsVariables[0]			
                    TimelineItem.min = this[minprop]			
                    let maxprop = this[opt.DynamicChartLabelsProp].ChartLabelsVariables[length-1]			
                    TimelineItem.max = this[opt.ChartlabelPeriodEndMethodX](this[maxprop])  			
                    let targets = elmnt.GoalTargets.filter(obj => {return obj.StartDate.getTime() === TimelineItem.min.getTime() && obj.EndDate.getTime() === TimelineItem.max.getTime()})			
                    TimelineItem.Target = targets.map(target => {return target.Target}).reduce((a, b) => a + b, 0)			
                    }    			
                    TimelineItem.ContextData = elmnt.RawData.filter(record => {
                      console.log('record.PrimaryDateObj',record.PrimaryDateObj)
                      return record.PrimaryDateObj >= TimelineItem.min && record.PrimaryDateObj <= TimelineItem.max})			
                    TimelineItem.Achieved = TimelineItem.ContextData.length  			
                    //console.log(data)			
                    TimelineItem.Value = Math.round((TimelineItem.Achieved/TimelineItem.Target)*100)			
                           			
                    let redcolor = Math.round(100-TimelineItem.Value)*2.55			
                    let greencolor = Math.round(TimelineItem.Value*2.55)                 			
                    TimelineItem.Color = 'rgb('+redcolor+','+greencolor+',0)'					
                    if(TimelineItem.max <= rangemax){			
                      			
                      elmnt.Timelines.push(TimelineItem)			
                    }			
                    			
                })			
                elmnt.Timelines.map(TimelineItem => {			
                  let int = (TimelineItem.Achieved/elmnt.RawValue)*100			
                  TimelineItem.PercentageUp = int.toFixed(2)			
                  TimelineItem.IncreaseColor = 'green' 			
                  if(isNaN(TimelineItem.Value)){			
                    TimelineItem.AchievedText = 'No data for '+TimelineItem.Name			
                    TimelineItem.PercentageText = ''			
                  }			
                  else{			
                    TimelineItem.AchievedText = TimelineItem.Value+'% Achieved '+TimelineItem.Name			
                    TimelineItem.PercentageText = TimelineItem.PercentageUp+'% Contribution to Total'			
                  }			
                  if(!isFinite(TimelineItem.Value)){			
                    TimelineItem.Value = 0			
                  }			
                  return TimelineItem			
                })   			
                elmnt.NEWSubLabelsdata = [			
                  {			
                    Name: 'Target',			
                    ID: 1000001			
                    },			
                    {			
                    Name: 'Achieved',			
                    ID: 1000002			
                    }			
                ]			
                elmnt.NEWSubLabelsdata.map((sublabel,sublabelindex) => {			
                      elmnt.ChartData.datasets[sublabelindex] = {			
                      fill: false,			
                      			
                      backgroundColor: elmnt.ChartColors,			
                      data: [],			
                      label: sublabel.Name			
                    }			
                    if(sublabel.Name === 'Target'){			
                      elmnt.ChartData.datasets[sublabelindex].borderColor = '#455A64'			
                    }			
                    else{			
                      elmnt.ChartData.datasets[sublabelindex].borderColor = '#FFCCBC'			
                    }			
                    elmnt.ChartLabels			
                    .map((label,labelindex) => {			
                      if(!elmnt.ChartData.datasets[sublabelindex].data[labelindex]){			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = []			
                      }			
                      if(sublabel.Name === 'Target'){			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = elmnt.ChartLabels.filter(newlabel => {                      			
                            return newlabel.ChartLabelID <= label.ChartLabelID			
                          }).map(data => {			
                           return data.TargetValues			
                         }).reduce((a, b) => a + b, 0)			
                      }			
                      else{			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = RawData			
                          .filter(data => {                      			
                            return data.ChartLabelID <= label.ChartLabelID			
                          }).map(data => {			
                           return data.RawValue			
                         }).reduce((a, b) => a + b, 0)			
                      }			
                    })			
                })			
              }			
              else if(elmnt.SublabelField){				
                if(elmnt.SublabelField.Type === 'Option Set'){			
                elmnt.NEWSubLabelsdata = JSON.parse(JSON.stringify(elmnt.SublabelField.Options))			
                }		
                else{			
                  			
                  let array = RawData			
                  .filter(data => {			
                    return data[elmnt.SublabelField.Name] && data[elmnt.SublabelField.Name].ID			
                  })			
                  .map(data => {			
                    let obj = {			
                      Name: data[elmnt.SublabelField.Name][elmnt.SublabelField.LookupFieldName],			
                      ID: data[elmnt.SublabelField.Name].ID			
                    }			
                    return obj			
                  })			
                  array.map(item => {			
                    let oncheck = elmnt.NEWSubLabelsdata.find(obj => obj.ID === item.ID)			
                    if(!oncheck){			
                      elmnt.NEWSubLabelsdata.push(item)			
                    }			
                  })			
                 			
                }				
                elmnt.NEWSubLabelsdata.map((sublabel,sublabelindex) => {			
                      elmnt.ChartData.datasets[sublabelindex] = {			
                      fill: false,			
                      borderColor: '#ffffff',			
                      backgroundColor: elmnt.ChartColors,			
                      data: [],			
                      label: sublabel.Name			
                    }			
                    elmnt.ChartLabels			
                    .map((label,labelindex) => {			
                      if(!elmnt.ChartData.datasets[sublabelindex].data[labelindex]){			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = []			
                      }			
                      elmnt.ChartData.datasets[sublabelindex].data[labelindex] = label.DataArray			
                      .filter(data => {			
                        if(!elmnt.SublabelFromRelatedLookup){			
                         return data[elmnt.SublabelField.Name] && data[elmnt.SublabelField.Name].ID === sublabel.ID 			
                        }			
                        else{			
                          return data[elmnt.EntityRelatedfield.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name].ID === sublabel.ID			
                        }			
                        			
                      })			
                      .map(data => {			
                        return data.RawValue			
                      }).reduce((a, b) => a + b, 0)  	
                    })			
                    			
                })			
                if(elmnt.ChartLabelsfromProcess){			
                   elmnt.ChartLabels			
                    .map((label,labelindex) => {			
                      elmnt.NEWSubLabelsdata.map((sublabel,sublabelindex) => {			
                        if(!elmnt.ChartValues[labelindex]){			
                          elmnt.ChartValues[labelindex] = []			
                        }			
                        elmnt.ChartValues[labelindex][sublabelindex] = label.DataArray			
                      .filter(data => {			
                        if(!elmnt.SublabelFromRelatedLookup){			
                         return data[elmnt.SublabelField.Name] && data[elmnt.SublabelField.Name].ID === sublabel.ID 			
                        }			
                        else{			
                          return data[elmnt.EntityRelatedfield.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name].ID === sublabel.ID			
                        }			
                        			
                      })			
                      .map(data => {			
                        return data.RawValue			
                      }).reduce((a, b) => a + b, 0) 			
                      })			
                    })			
                }			
                			
              }			
              //console.log(elmnt)	
            elmnt.Context = elmnt.Context			
              .map(context => {			
                  let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                  context.ContextTitleColor = this.DefaultColors[ranint]			
                  return context			
              })			
               let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                  elmnt.ContextTitleColor = this.DefaultColors[ranint]	
              if(elmnt.ColumnWidth === 'Full Column'){			
                elmnt.FlexXLRG = 12			
                elmnt.FlexLarge = 12			
                elmnt.FlexMedium = 12			
              }			
              else if(elmnt.ColumnWidth === 'Half Column'){			
                elmnt.FlexXLRG = 5			
                elmnt.FlexLarge = 5			
                elmnt.FlexMedium = 5			
              }			
              else if(elmnt.ColumnWidth === 'Third Column'){			
                elmnt.FlexXLRG = 3			
                elmnt.FlexLarge = 3			
                elmnt.FlexMedium = 4			
              }			
              elmnt.FlexSmall = 12			
              elmnt.FlexXSmall = 12	
            if(elmnt.Sort === 'Descending (Value)'){			
                    elmnt.Context = elmnt.Context.sort((a, b) => {
                                var key1 = a.RawValue			
                                var key2 = b.RawValue			

                                if (key1 > key2) {			
                                    return -1;			
                                } else if (key1 == key2) {			
                                    return 0;			
                                } else {			
                                    return 1;			
                                }			
                        })			
                }			
                else if(elmnt.Sort === 'AlphaBetically (Ascending)'){			
                    //console.log('is descending')			
                    elmnt.Context = elmnt.Context.sort(function(a, b) {			
                        var textA = a.ContextTitle.toUpperCase();			
                        var textB = b.ContextTitle.toUpperCase();			
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;			
                    });			
                }			
                else if(elmnt.Sort === 'AlphaBetically (Descending)'){	
                    elmnt.Context = elmnt.Context.sort(function(b, a) {			
                        var textA = a.ContextTitle.toUpperCase();			
                        var textB = b.ContextTitle.toUpperCase();			
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;			
                    });			
                }			
                else{				
                    elmnt.Context = elmnt.Context.sort((a, b) => {		
                                var key1 = a.RawValue			
                                var key2 = b.RawValue			

                                if (key1 < key2) {			
                                    return -1;			
                                } else if (key1 == key2) {			
                                    return 0;			
                                } else {			
                                    return 1;			
                                }			
                        })			
                }			
                return elmnt
        },
        
    },
    watch: {
        ComputedChartObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.CheckforCharts(newvalue)
                }
            }
        }
        
    },
    created(){
        for(var prop in this.$vuetify.theme.themes.light){   			
            if (!this.DefaultColors[prop]) {                       			
                this.DefaultColors.push(this.$vuetify.theme.themes.light[prop])			
            }			
        }
        this.Today = new Date()
      this.Today.setHours(0)
      this.Today.setMinutes(0)
      this.Today.setSeconds(0) 
    },
    methods:{
      
        EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    EndofQuarter(date){			
      //console.log(date)			
      var year = date.getFullYear();			
      var month = date.getMonth();			
      var day = date.getDate();			
      return this.EndofMonth(new Date(year, month+2, day));			
    },		
    Endof2Hours(date){			
      var hours = date.getHours()
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)	
      d.setHours(hours,59,59,999)			
      return d			
    },
    EndofDay(date){			
      var day = date.getDate()			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month, day)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    EndofWeek(date){			
     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();			
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },	
    TimestampFormatterSTRING(d){			
       			
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];			
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]			
      			
        var hr = d.getHours();			
       			
        var min = d.getMinutes();			
        if (min < 10) {			
            min = "0" + min;			
        }			
        if(hr < 10){			
          hr = "0"+hr			
        }			
        			
        var date = d.getDate();			
        var month = months[d.getMonth()];			
        var year = d.getFullYear();			
        // 2020-03-15 09:00'			
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;			
        //console.log(finaldate)			
        return finaldate			
      },		
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        PrepareElementComponents(elmnt){			
        if(elmnt.HasDataContext && elmnt.HasChart){			
                    elmnt.HasTwoColumns = this.GetHasTwoColumns(elmnt)			
                    elmnt.HasTwoRows = !elmnt.HasTwoColumns			
                    elmnt.ComponentOne = this.GetComponentOne(elmnt)			
                    elmnt.ComponentTwo = this.GetComponentTwo(elmnt)			
                }			
                else{			
                    elmnt.HasTwoColumns = false			
                    elmnt.HasTwoRows = false			
                    elmnt.ComponentTwo = ''			
                    elmnt.ComponentOne = this.GetComponentOne(elmnt)                			
                }			
                            
                return elmnt			
        },
        GetComponentOne(elmnt){			
        if(!elmnt.ChartPosition){			
            if(elmnt.HasChart){					
            return {CompName: 'DashboardElmtChart',Style: 'Pipeline'}			
            }			
            else if(elmnt.HasDataContext){          			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
            }			
        }			
        else if(elmnt.ChartPosition === 'Top' && elmnt.HasChart){			
            return {CompName: 'DashboardElmtChart',Style: elmnt.ChartPosition}			
        }			
        else if(elmnt.ChartPosition === 'Right' || elmnt.HasDataContext && !elmnt.HasChart){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
        else if(elmnt.ChartPosition === 'Left'){			
            return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
        }			
        },			
        GetComponentTwo(elmnt){			
        if(elmnt.ChartPosition === 'Top'){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
        else if(elmnt.ChartPosition === 'Right'){			
            return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
        }			
        else if(elmnt.ChartPosition === 'Left'){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }  			
        },
        GetHasTwoColumns(elmnt){			
        if(elmnt.ChartPosition === 'Top'){			
            return false			
        }			
        else{			
            return true			
        }			
        },
        CheckforCharts(elmnt,i,index){
            //console.log(this.QuickElementid)	
          setTimeout(() => {		
           let elmntobj = document.getElementById(this.QuickElementid)			
          if(elmnt.Preset === 'Pie Chart' && elmnt.ChartData && elmnt.ChartLabels){	
            this.GetPieChart(elmntobj,elmnt.ChartType,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors)			
          }  			
          else if(elmnt.ChartLabelsfromTimeline && elmnt.ChartData && elmnt.ChartLabels){	
            this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors,elmnt.ChartData)			
          }  			
           else if(elmnt.Preset === 'Activities'){			
            this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors,elmnt.ChartData)  			
            }   			
           			
          }, 1000);			
          			
      },
      GetLineorBarChart(type,elmnt,ChartData,ChartLabels,ChartColors,ChartAllData){	
         
            elmnt.textContent = ''			
          var canvas = document.createElement("CANVAS")			
          canvas.style.width = '100%'			
          canvas.style.height = '100%' 			
          elmnt.appendChild(canvas)			
          let namelist = ['Jan','Piet','Gert','Thuli','Jackie','Hennie','Chade','Johan','Lood','Rudi','Mike','Jacques','Sipho','John','Oliver','Louis','Sannie']			
          let surnamelist = ['Fourie','Louw','Snyman','Nel','van Wyk','le Roux','van Staden','Rambo','Krynauw','Beyers','Oosthuizen','Ndlovu','Skoonraadt','van der Merwe','Steyn','Pretorius','Seemonster']			
          let datalength = 40          			
          let generateddatasets = []			
          let labellength = ChartLabels.length			
          for(var x = 0; x < datalength; x++){			
            let randomname = Math.floor(Math.random() * Math.floor(namelist.length))			
            let randomsurname = Math.floor(Math.random() * Math.floor(surnamelist.length))			
            let obj = {			
              label: namelist[randomname]+' '+surnamelist[randomsurname],			
              data: [],			
              borderColor: "#ff0000",			
              backgroundColor: ChartColors,			
              fill: false,			
            }			
            for(var y = 0; y < labellength; y++){			
              let randomvalue = Math.floor(Math.random() * Math.floor(5))			
              obj.data[y] = randomvalue			
            }			
            //console.log(obj)			
            generateddatasets[x] = obj			
          }			
          //console.log(generateddatasets)			
          new Chart(canvas, {			
            type: type,			
            data: {			
                labels: ChartLabels,			
                datasets: ChartAllData.datasets,		
                //datasets: generateddatasets,			
            },			
            backgroundColor: '#ff0000',			
            options: {			
                title: {			
                // display: true,			
                text: 'World population per region (in millions)',			
                			
                },			
                legend: {			
                    display: false,			
                    labels: {			
                        fontColor: 'rgb(255, 255, 255)'			
                    },			
                },			
			
                scales: {			
                        xAxes: [{			
                            			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            },			
                            scaleLabel: {			
                            display: false,			
                            labelString: 'Month'			
                            }			
                        }],			
                        yAxes: [{			
                            display: true,			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            maxTicksLimit: 5			
			
                            },			
                        }]			
                    }			
            }			
            })			
        },			
        GetFunnelChart(){			
            setTimeout(() => {			
             let elmnt = document.getElementById('funneltest')			
            var canvas = document.createElement("CANVAS")			
          canvas.style.width = '100%'			
          canvas.style.height = '100%' 			
          elmnt.appendChild(canvas)			
          new Chart(canvas, {			
                type: 'funnel',			
                data: {			
                    datasets: [{			
                        data: [30, 60, 90],			
                        backgroundColor: [			
                            "#FF6384",			
                            "#36A2EB",			
                            "#FFCE56"			
                        ],			
                        hoverBackgroundColor: [			
                            "#FF6384",			
                            "#36A2EB",			
                            "#FFCE56"			
                        ]			
                    }],			
                    labels: [			
                        "Red",			
                        "Blue",			
                        "Yellow"			
                    ]			
                }			
            })   			
            }, 1000);			
            			
        },			
        GetPieChart(elmnt,type,ChartData,ChartLabels,ChartColors){			
         //console.log(elmnt,type,ChartData,ChartLabels,ChartColors)			
         elmnt.textContent = ''			
          var canvas = document.createElement("CANVAS")			
          canvas.style.width = '100%'			
          canvas.style.height = (this.WindowHeight/2.2)+'px' 			
          elmnt.appendChild(canvas)			
           let namelist = ['Jan','Piet','Gert','Thuli','Jackie','Hennie','Chade','Johan','Lood','Rudi','Mike','Jacques','Sipho','John','Oliver','Louis','Sannie']			
          let surnamelist = ['Fourie','Louw','Snyman','Nel','van Wyk','le Roux','van Staden','Rambo','Krynauw','Beyers','Oosthuizen','Ndlovu','Skoonraadt','van der Merwe','Steyn','Pretorius','Seemonster']			
          let datalength = 7          			
          let generateddatasets = []			
          let labellength = ChartLabels.length			
          for(var x = 0; x < datalength; x++){			
            let randomname = Math.floor(Math.random() * Math.floor(namelist.length))			
            let randomsurname = Math.floor(Math.random() * Math.floor(surnamelist.length))			
            // let obj = {			
            //   label: namelist[randomname]+' '+surnamelist[randomsurname],			
            //   data: [],			
            //   borderColor: "#ff0000",			
            //   backgroundColor: ChartColors,			
            //   fill: false,			
            // }			
            let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
            let obj = {			
              label: namelist[randomname]+' '+surnamelist[randomsurname],			
              data: [],			
              borderColor: this.DefaultColors[ranint],			
              backgroundColor: ChartColors,			
              fill: false,			
            }			
            if(type === 'bar'){			
             			
              obj.backgroundColor = this.DefaultColors[ranint]			
            }			
            else if(type === 'line'){             			
              obj.borderColor = this.DefaultColors[ranint]			
              obj.backgroundColor = ChartColors			
            }			
            else{			
              //obj.borderColor = this.DefaultColors[ranint]			
              obj.backgroundColor = ChartColors			
            }			
            for(var y = 0; y < labellength; y++){			
              let randomvalue = Math.floor(Math.random() * Math.floor(5))			
              obj.data[y] = randomvalue			
            }			
            //console.log(obj)			
            generateddatasets[x] = obj			
          }			
          // let Colors = []			
         let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
         if(type === 'bar'){			
             ChartData.datasets.map(obj => {            			
              obj.backgroundColor = this.DefaultColors[ranint]			
               })			
            }			
            else if(type === 'line'){    			
              ChartData.datasets.map(obj => {          			
              obj.borderColor = this.DefaultColors[ranint]			
              obj.backgroundColor = ChartColors			
              })			
            }			
            else{			
             ChartData.datasets.map(obj => { 			
              obj.backgroundColor = ChartColors			
             })			
            }			
          //console.log(generateddatasets)			
          setTimeout(() => {			
            new Chart(canvas, {			
              type: type,			
              data: ChartData,			
              // data: {			
              //   labels: ChartLabels,			
              //   // datasets: generateddatasets			
              //   datasets: [{			
              //     // label: "Population (millions)",			
              //     backgroundColor: ChartColors,			
              //     data: ChartData			
              //   }]			
              // },			
              options: {			
                title: {			
                  display: false,			
                  text: 'Predicted world population (millions) in 2050'			
                },			
                			
              }			
          });			
          }, 500);			
            			
        },	
    }
}
</script>

<style>

</style>
