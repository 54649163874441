<template>
  <div>
      <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
    ></v-img>

    <v-card-title>Cook "Chicken a la King" like a BOSS </v-card-title>

    <v-card-text>
     

      <div class="my-4 text-subtitle-1">
        Cooking || Recipes
      </div>

      <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>


    <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        Read More
      </v-btn>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },
    computed:{

    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
