<template>
  <div>
      <v-dialog v-model="ClientAppAuthDialog" max-width="500">
    <v-card
    class="mx-auto RAWebBtnGraddark"
    max-width="500"
    dark
  >
   
      
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>Sign in to your Client App</span>
      <v-avatar
        size="45"
      >
      <v-img  v-if="userLoggedIn.Profile_Photo" :src="userLoggedIn.Profile_Photo">
      </v-img>
      <v-img v-if="!userLoggedIn.Profile_Photo" src="@/assets/BlankProfilePic.png">
      </v-img>
      </v-avatar>
    </v-card-title>
    <v-card-subtitle>
      {{userLoggedIn.Full_Name}}
    </v-card-subtitle>
    <!-- <v-window v-model="AdministratorDialogstep">
          <v-window-item :value="1"> -->
              <v-card-text>
                <v-text-field type="text" v-model="clientloginemail" placeholder="Email" size="12"/><br>
                  <v-text-field type="password" v-model="clientloginpassword" placeholder="Password" @keydown.enter="loginClientAppWithFirebase"/>
                <span class="caption">You may be signed in to RapidApps, but in order to speak to your data on your app, please sign into your app as an administrator</span>
              </v-card-text>
              <v-card-actions><v-btn @click="loginClientAppWithFirebase">Sign In</v-btn></v-card-actions>
          <!-- </v-window-item>
    <v-window-item :value="2">
             <v-card-text>                        
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-text-field dense v-model="email" label="Email"></v-text-field>
                      <v-text-field dense v-model="password" type="password" label="Password"></v-text-field>
                      <v-select dense label="Select Title" v-model="Title" :items="TitleArray" item-text="Name"></v-select>                    
                      <v-text-field dense v-model="AdminName" label="Name"></v-text-field>
                      <v-text-field dense v-model="Surname" label="Surname"></v-text-field>
                      <v-text-field dense readonly filled v-model="UserFullName" label="FullName"></v-text-field>
                      <v-select dense label="Select Gender" v-model="Gender" :items="GenderArray" item-text="Name"></v-select>                        
                    </v-col>
                  </v-row>              
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>              
                <v-btn color="pop" v-if="ClientFireStore" @click="CreateSystemAdministrator()">Sign Up</v-btn>
              </v-card-actions>  
      </v-window-item>      
    </v-window>
    <v-card-actions>
              <v-btn
            :disabled="AdministratorDialogstep === 1"
            text
            @click="AdministratorDialogstep--"
          >
            Back
          </v-btn>
      <v-spacer></v-spacer>
            <v-btn  v-if="AdministratorDialogstep === 1"      
              depressed
              color="green"
              @click="AdministratorDialogstep++"
            >
              Create Admin
            </v-btn>          
          </v-card-actions> -->
 
    </v-card>
    </v-dialog>
      <v-dialog v-model="QuickStartDialog" max-width="500" persistent
    >
       <v-card
        class="mx-auto"
        max-width="500"
        dark
        :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' ,backgroundSize: 'cover'}"
      >
          
        <v-card-title class="title font-weight-regular justify-space-between">
          <v-icon @click="ToggleQuickStartDialog()" dark class="error soloactionicon">
            mdi-close
          </v-icon>
          <span>Quick Setup - Step {{QuickstartStep}}</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="userLoggedIn.Profile_Photo" :src="userLoggedIn.Profile_Photo">
          </v-img>
          <v-img v-if="!userLoggedIn.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{QuickStepTitle}}
        </v-card-subtitle>
        <v-window v-model="QuickstartStep">
          <v-window-item :value="1">
            <v-card-text>
              We get it, you just want to get started!!! Well that's why we do this here. If you follow these steps, including all optional steps, you will already be able to export and test your app! Don't let the anxiety overwhelm you, it takes 2 minutes to get your App running! Here is a rundown of what we will be doing in the next few steps:
              <v-list class="transparent">
                <v-list-item>
                 
                    <h3>Configure the Backend</h3>
                </v-list-item>
                 <v-list-item>
                    You need to configure the backend database, or else your app will have not database                
                  </v-list-item>
                 <v-list-item>                
                    <h3>Create your "Head Office"</h3>
                     </v-list-item>
                 <v-list-item>
                    Whether you have multiple branches in your organization or not, you have to configure at least one master, head busines unit
                      </v-list-item>
                 <v-list-item>
                    <h3>Create your "Administrator"</h3>
                      </v-list-item>
                    <v-list-item>
                    <h3>Configure Emailing</h3>
                      </v-list-item>
                 <v-list-item>
                    If you want your system to send emails you need to configure the emailing account you send with.
                    </v-list-item>          
              </v-list>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2">
            <v-card-text>
              Insert the {{DatabaseProvider}} config here
              <v-list class="transparent">
                <v-list-item>
                  <v-textarea  v-model="firebaseConfig"></v-textarea>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-window-item>
           <v-window-item :value="3">
            <v-card-text>
              Great, now that we can access let backend, let's add the Master Business Unit
              <v-list class="transparent">
                <v-list-item>
                  ClientFireStore <span v-if="ClientFireStoreConfigured"> Configured</span><span v-else>Not yet Configured please wait</span>
                
                </v-list-item>
                <v-list-item>
                  
                  <v-btn @click="ProcessClientBU()" v-if="ClientFireStoreConfigured && ClientDBBusinessUnits.length === 0">Add</v-btn>
                  <span v-if="ClientDBBusinessUnits.length > 0">
                    You now have {{ClientDBBusinessUnits[0].Name}} loaded. Let's continue
                    </span>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="4">
            <v-card-text>
              Let's create your Administrator
              <v-list-item v-if="ClientDBAdministrators.length > 0">
              <span>
                    You now have {{ClientDBAdministrators[0].Full_Name}} loaded. Let's continue
                    </span>
              </v-list-item>
              <v-card
                  class="mx-auto transparent"
                  max-width="500"
                  dark
                  v-if="ClientDBAdministrators.length === 0"
                >
                
                
                            <v-card-text>
                        
                            <v-row>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-text-field dense v-model="email" label="Email"></v-text-field>
                                <v-text-field dense v-model="password" type="password" label="Password"></v-text-field>
                                <v-select dense label="Select Title" v-model="Title" :items="TitleArray" item-text="Name"></v-select>                    
                                <v-text-field dense v-model="AdminName" label="Name"></v-text-field>
                                <v-text-field dense v-model="Surname" label="Surname"></v-text-field>
                                <v-text-field dense readonly filled v-model="UserFullName" label="FullName"></v-text-field>
                                <v-select dense label="Select Gender" v-model="Gender" :items="GenderArray" item-text="Name"></v-select>
                                  
                              </v-col>
                            </v-row>
                        
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>              
                          <v-btn color="pop" v-if="ClientFireStore" @click="CreateSystemAdministrator()">Sign Up</v-btn>
                        </v-card-actions>  
                         
                    </v-card>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="5">
             <v-list class="transparent">                      
                <v-list-item>                     
                <v-text-field v-model="SystemFromEmailHost" label="Email smtp host"></v-text-field>
                </v-list-item>
                <v-list-item>                         
                <v-text-field v-model="SystemFromEmailAddress" label="Email Address"></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field v-model="SystemFromEmailPassword" type="password" label="Password"></v-text-field>
                  </v-list-item>
                <v-list-item>
                  <v-text-field v-model="SystemFromEmailPort" label="Port" type="number"></v-text-field>
                 </v-list-item>
                <v-list-item>
                  <v-switch 
                    v-model="SystemFromEmailSecure"
                    class="mx-2"
                    label="Secure Mail"
                  ></v-switch>
                </v-list-item>    
            </v-list>
          </v-window-item>
          <v-window-item :value="6">
         <v-card-text>
              That's it you are all set!
              You can get building. If you duly followed all the steps you should actually be able to export your app and test it.
              <p>You can get started with even more, by visting the MarketPlace and getting some extensions from there.</p>
              <v-btn :to="'/MarketPlace/'+ViewingSystemID">Visit Market Place</v-btn> 
              <p>Hint - If you add any entities (Data Records) to your system, whether through the Market Place or manually, you need to navigate to System General and Add Record Groups, then assign those Record Groups to those entities in the SuiteBuilder. If not, your entities will appear nowhere on your navigation.
              </p>
            </v-card-text>
          </v-window-item>
        
        </v-window>
        
            <v-card-actions>
              <v-btn
            :disabled="QuickstartStep === 1"
            text
            @click="QuickstartStep--"
          >
            Back
          </v-btn>
      <v-spacer></v-spacer>
            <v-btn        
              depressed
              color="green"
              @click="AdvanceQuickStart()"
               :disabled="!QuickStartValidate"
            >
              Next
            </v-btn>          
          </v-card-actions>
       
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
    props: ['ClientFireStore','ClientDBAdministrators','ClientDBBusinessUnits','QuickstartStep','QuickStartDialog','ClientAppAuthDialog','ViewingSystemID','SystemObj'],
    components: {

    },
    data(){
        return {
          clientApp: {},
      clientDB: '',
          AdministratorDialogstep: 1,
            clientloginemail: '',
            clientloginpassword: '',
            UserPicture: '',
            ClientappSignedInEmail: '',
            firebaseConfig: '',
            TitleArray: [
          {index: 0, Name: 'Mr'},
          {index: 1, Name: 'Mrs'},
          {index: 2, Name: 'Ms'},

        ],
      GenderArray: [
          {index: 0, Name: 'Male'},
          {index: 1, Name: 'Female'},

        ],
        Title: '',
      AdminName: '',
      Surname: '',
      Gender: '',
       email: '',
      password: '',
        }
    },
    computed:{
      UserFullName(){
      return this.AdminName+' '+this.Surname
    },
        userLoggedIn () {
          return this.$store.getters.user
        },
        ClientFireStoreConfigured(){
      if(this.ClientFireStore && this.ClientFireStore.projectId){
        return true
      }
    },
        QuickStartValidate(){
          if(this.QuickstartStep !== 1){
            if(this.QuickstartStep === 2){
              if(this.firebaseConfig){
                return true
              }
              else{
                
                return false
              }
            }
            if(this.QuickstartStep === 3){
              if(this.ClientDBBusinessUnits.length > 0){
                return true
              }
            }
            if(this.QuickstartStep === 4){
              if(this.ClientDBAdministrators.length > 0){
                return true
              }
            }
            else if(this.QuickstartStep === 5){
              return true
            }
            else if(this.QuickstartStep === 6){
              return true
            }
            
          }
          else{
            return true
          }
        },
        SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
        QuickStepTitle(){
          if(this.QuickstartStep === 1){
            return 'Overview'
          }
          else if(this.QuickstartStep === 2){
            return 'Backend Config'
          }
          else if(this.QuickstartStep === 3){
            return 'Master Business Unit'
          }
          else if(this.QuickstartStep === 4){
            return 'Administrator'
          }
          else if(this.QuickstartStep === 5){
            return 'Email Config'
          }
          else if(this.QuickstartStep === 6){
            return 'Email Config'
          }
        },
    },
    created(){
      console.log(this.ClientFireStore)
      if(this.SystemObj.firebaseConfig){
        this.firebaseConfig = this.SystemObj.firebaseConfig
      }
    },
    methods:{
      ToggleQuickStartDialog(){
        this.$emit('ToggleQuickStartDialog',!this.QuickStartDialog)
      },
      CreateSystemAdministrator(){
        console.log(this.ClientDBBusinessUnits)
      let vm = this
      let buobj =  {id: 'Head_Office',Name: 'Head Office'}
      const user = {
        email: this.email,
        password: this.password,
        Title: this.Title,
        Name: this.AdminName,
        Surname: this.Surname,
        Gender: this.Gender,
        fullname: this.UserFullName,
        rolesarrayDBRules: {SystemAdmin: true},
        rolesarrayQuery: ['System Admin'],
        Business_Unit: {Name: buobj.Name, id: buobj.id},
        Business_Unitid: buobj.id,
        IsGuestUser: false,
        IsSystemAdmin: true,
      }
      // if(this.TutorialStep === 2 && element === 'AddAministratorsbtn'){
      //     this.$emit('UpdateTutorialStep',3)
      // }
      let payload = {
        user: user,
        SystemName: this.SystemObj.Name,
        ClientFireStore: this.ClientFireStore
      }
      this.$store.dispatch('ClientAdminsignUpAction', payload).then(response => {
        let userdocid = response.user.uid
        vm.clientApp = firebase.apps.find(obj => obj.name === payload.SystemName)
        vm.clientDB = vm.clientApp.firestore()
        
        vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userdocid).set({
              IsSystemAdmin: true,
              rolesarrayDBRules: user.rolesarrayDBRules,
              rolesarrayQuery: user.rolesarrayQuery,
              IsGuestUser: user.IsGuestUser,
              Business_Unit: user.Business_Unit,
              Business_Unitid: user.Business_Unitid,
              SubordinateArrayDBrules: {
                [userdocid]: true
              },
              Subordinatearrayquery: [userdocid],
              Disabled: false,
            })
        vm.clientDB.collection('SocialNetworks').doc(this.ViewingSystemID).collection('groupmembers').doc(userdocid).set({
              Disabled: false,
            })
        vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(userdocid).set({
          Disabled: false,
        })
        vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(userdocid).set({
              Title: user.Title,
              Name: user.Name,
              Surname: user.Surname,
              Full_Name: user.fullname,
              Gender: user.Gender,
              Email: user.email,
              Created_On: new Date(),
              Disabled: false,
              Business_Unit: user.Business_Unit,
              Business_Unitid: user.Business_Unitid,
            })
        vm.ClientAppUser = user
      
        vm.ClientappSignedInEmail = user.email
        vm.ClientDBAdministrators.push(user)
      })
    },
      loginClientAppWithFirebase(){
      let vm = this
      const user = {
        email: this.clientloginemail,
        password: this.clientloginpassword
      }
      let payload = {
        user: user,
        SystemName: this.SystemObj.Name,
        ClientFireStore: this.ClientFireStore
      }
      
      this.$store.dispatch('signInClientAppAction', payload).then(response => {
        console.log(response)
        console.log(response.user)
        console.log(response.user.uid)
        if(response){
          vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = user.uid
            console.log(userdata)
            // vm.$store.commit('setUserObj', userdata)
            alert('successfully signed in')
            vm.ClientAppAuthDialog = false
          })
          
        }
         
      })

    
    },
        ProcessClientBU(){
      let vm = this
      let HeadofficeObj = {
        Name: 'Head Office',
        Description: 'The default, main Business Unit',
        NameQuery: [
          'h',
          'he',
          'hea',
          'head',
          'heado',
          'headof',
          'headoff',
          'headoffi',
          'headoffic',
          'headoffice'
        ],
        Top: true
      }
      let GuestObj = {
        Name: 'Guest',
        Description: 'A Business Unit completely unrelated to others meant to give basic Signed In Permissions to any Guest Users',
        NameQuery: [
          'g',
          'gu',
          'gue',
          'gues',
          'guest',
        ],
        IsGuestBU: true,
        FinalStructure: true
      }
      let payload = {
        NewclientBU: HeadofficeObj,
        SystemName: this.SystemObj.Name,
        ClientFireStore: this.ClientFireStore
      }
      let guestpayload = {
        NewclientBU: GuestObj,
        SystemName: this.SystemObj.Name,
        ClientFireStore: this.ClientFireStore
      }
      this.$store.dispatch('ProcessClientBU', payload).then(response => {
        HeadofficeObj.id = response
        vm.ClientDBBusinessUnits.push(HeadofficeObj)
        vm.$store.dispatch('ProcessClientBU', guestpayload).then(guestresponse => {
          GuestObj.id = guestresponse
          vm.ClientDBBusinessUnits.push(GuestObj)
        })
      })
    },
    AdvanceQuickStart(){
      
      
      if(this.QuickstartStep === 2 && this.firebaseConfig){
        console.log(this.firebaseConfig)
        let first = this.firebaseConfig.split('const firebaseConfig = ')
        console.log(first)
        console.log(first[1])
        let lines = first[1].split("\n")
        let splitted = lines.map(line => {
          return line.split(':')
        })
        let fixed = splitted.map(split => {
          let obj = {}
          if(typeof split[1] !== 'undefined'){
          obj[split[0].split(' ').join('')] = split[1].split(" ").join('').split('"').join('').split(',').join('').trim()
          return obj
          }
        })
        console.log('const firebaseConfig = ',fixed)
        console.log('const firebaseConfig = ',fixed[1])
        let dburltry = lines[3].split('databaseURL:').join('').split('"').join('').trim()
        let appIdtry = lines[7].split('appId:').join('').split('"').join('').trim()
        let twoclientfirebaseConfig = { apiKey: fixed[1].apiKey, authDomain: fixed[2].authDomain, projectId: fixed[3].projectId, storageBucket: fixed[4].storageBucket, messagingSenderId: fixed[5].messagingSenderId, appId: fixed[6].appId, measurementId: fixed[7].measurementId }
       
        twoclientfirebaseConfig.appId = appIdtry
      const EditedSystem = {
        // name: this.Name,
        firebaseConfig: this.firebaseConfig,
        ClientFireStore: twoclientfirebaseConfig,
        ClientAppURL: 'https://'+twoclientfirebaseConfig.projectId+'.web.app'
        }
        db.collection('systems').doc(this.ViewingSystemID).update({
        firebaseConfig: EditedSystem.firebaseConfig,
        ClientFireStore: EditedSystem.ClientFireStore,
        ClientAppURL : EditedSystem.ClientAppURL
        })
        this.ClientFireStore = EditedSystem.ClientFireStore
        this.ClientAppURL = EditedSystem.ClientAppURL
        this.QuickstartStep = this.QuickstartStep-1+2
        db.collection('systems').doc(this.ViewingSystemID).update({
          QuickstartStep: this.QuickstartStep
        })
      }
      else if(this.QuickstartStep === 6){
          this.QuickStartDialog = false
        }
     
      else {
        if(this.QuickstartStep === 5){
          let SiteEmailConfigObj = {}
            SiteEmailConfigObj.SystemFromEmailAddress = this.SystemFromEmailAddress
            SiteEmailConfigObj.SystemFromEmailPassword = this.SystemFromEmailPassword
            SiteEmailConfigObj.SystemFromEmailPort = this.SystemFromEmailPort
            SiteEmailConfigObj.SystemFromEmailHost = this.SystemFromEmailHost
            SiteEmailConfigObj.SystemFromEmailSecure = this.SystemFromEmailSecure
            SiteEmailConfigObj.SystemNotificationsHasEmails = true
            SiteEmailConfigObj.SystemSendsEmails = true
          
          
          db.collection('systems').doc(this.ViewingSystemID).collection('Options').doc('EmailSettings').set(SiteEmailConfigObj).then(doc => {
            console.log('done SiteEmailConfigObj')
          })
        }
        this.QuickstartStep = this.QuickstartStep-1+2
        db.collection('systems').doc(this.ViewingSystemID).update({
          QuickstartStep: this.QuickstartStep
        })
        
      }
    },
    }
}
</script>

<style>

</style>