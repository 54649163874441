<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
      
      <v-dialog v-model="previewsendemaildialog" max-width="600">
        <v-card width="100%">
     <EmailComponent :PresetEmailBody="EmailBody" :PresetEmailFrom="EmailFrom" :PresetEmailTo="EmailTo" :PresetEmailCC="EmailCC" :PresetEmailBCC="EmailBCC" :PresetEmailSubject="EmailSubject" :previewsendemaildialog="previewsendemaildialog"/>
        </v-card>
     </v-dialog>
     <v-tabs centered v-if="!ActiveRecord">
          <v-tab>List</v-tab>
          <v-tab-item>
              <!-- <v-select dense :items="ByDaysOptions" v-model="ByDays" label="By Days"/> -->
               
                <v-divider/>
                <v-list  >
                    <v-list-item  @click="PushItem(poll)" v-for="poll in SitePollsSearched" :key="poll.itemObjKey">
                        <v-list-item-avatar size="80" class="mx-2" tile>
                        <v-img contain v-if="poll.coverimage" :src="poll.coverimage"/>
                        <v-img contain v-if="!poll.coverimage" src="@/assets/ImageHolder.png"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="poll.class">{{ poll.title }}</v-list-item-title>
                        <v-list-item-subtitle :class="poll.class">{{ poll.caption }}</v-list-item-subtitle>
                    </v-list-item-content>
                     <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                            {{poll.monthcreated}}
                                <br>
                            {{poll.daycreated}}
                    </v-list-item-avatar>
                    </v-list-item>
                </v-list>
          </v-tab-item>
           <v-tab>
                Stats
            </v-tab>
            <v-tab-item>
                You know, this is possibly "Stats" as in relating to User, and Notices in geenral. It may become useful but nothing for now
            </v-tab-item>
     </v-tabs>
        <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        <v-btn :to="'/NoticeSingle/'+ActiveRecord.id">View</v-btn>
        <v-spacer></v-spacer>
         <v-icon color="red">mdi-delete-forever</v-icon>
        </v-card-title>
        <!-- {{ActiveRecord}} -->
        <div v-if="ActiveRecord">
             <v-card width="100%">
       
        <v-card-text>

        
            <v-data-table
            :dark="AppisDarkMode"
            :headers="ResponseHeadersComputed"
            :items="ResponsesComputed"
            show-select                                          
            v-model="UsersSelected" 
            >
            <template v-slot:top>
        <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12"  color="primary white--text">
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white">mdi-message-reply-text</v-icon>Responses</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-sequential</v-icon>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-grid</v-icon>
         
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
             
                  
                <v-list-item class="listoutline" @click="ActivateEmail()">
                  <v-list-item-title class="subtleoverline">
                    Email
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="blue">mdi-chart-bar</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
            </template>
            </v-data-table>
        </v-card-text>
        </v-card>
        </div>
      
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import ItemNotifications from '@/components/Dashboards/ItemNotifications'
import EmailComponent from '@/components/General/EmailComponent';

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','UsersArray','MenuItem'],
    components: {
        ItemNotifications,
        EmailComponent

    },
    data(){
        return{
            ResponsesComputed: [],
            ByDays: 7,
            ByDaysOptions: [7,14,30,60,90,120,150,300],
            items: [
                {
                    icon: 'mdi-vote',
                    title: 'Active'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'InActive'
                },
            ],
            ActiveRecord: '',
        SitePolls: [],
        search: '',
        cardsPerPage: 6,
        CardPage: 1,
        UsersSelected: [],
        ResponseHeaders: [
            { text: 'Full Name', value: 'Full_Name',class: "overline"},
            { text: 'Read', value: 'Read',class: "overline"},
            { text: 'ReadDate', value: 'ReadDate',class: "overline"},
            { text: 'Confirmed', value: 'Confirmed',class: "overline", ConfirmedOnly: true},
            { text: 'Confirmed Date', value: 'ConfirmDate', sortable: false ,class: "overline", ConfirmedOnly: true},
        ],
        EmailBody: '',
            EmailSubject: '',
            EmailFrom: '',
            EmailTo: [],
            EmailCC: [],
            previewsendemaildialog: false,
        }
    },
    computed:{
        ResponseHeadersComputed(){
          return this.ResponseHeaders.filter(header => {
            if(!this.ActiveRecord.MustConfirm){
              if(header){
                return !header.ConfirmedOnly
              }
            }
            else{ 
              return header
              }
          })
        },
        
        ComputedNotificationsQuery(){
            return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').where('noticeid','==',this.ActiveRecord.id)
        },
            SitePollsSearched(){
                return this.SitePolls.map(poll => {
                    poll.Status = 'Active'
                    poll.monthcreated = this.TimestampFormatterNoticeBoard(poll.PublishDate.toDate(),'month')
                    poll.daycreated = this.TimestampFormatterNoticeBoard(poll.PublishDate.toDate(),'date') 
                    return poll
                })
            },
            userIsAdmin () {
                return this.$store.state.IsAdmin
            },
            userLoggedIn () {
                return this.$store.getters.user
            },
            userBUID () {
                return this.$store.getters.userBUID
            },
            userBUChildren () {
                return this.$store.getters.userBUChildren
            },
            userBUParents () {
                return this.$store.getters.userBUParents
            },
    },
    created(){
        this.GetQuery(this.MenuItem)
        //this.GetPolls()
    },
    watch: {
      ActiveRecord(value){
          if (value !== null && value !== undefined && value.id) {
              this.Attending_Users = []              
              this.ComputeResponses()
          
          
          }
      },
    },
    methods:{
       GetQuery(item){
            if(this.$route.params.id && item && item.groupcollectionname){
              
                let query = db.collection(item.groupcollectionname).where(item.groupcolfield,'==',this.$route.params.id)
                let subquery = db.collection(item.groupcollectionname)
                console.log(item.groupcollectionname,'where ',item.groupcolfield,'==',this.$route.params.id)
                this.GetPolls(query,subquery)
            }
            else if(item && item.sitecollectionname){
               let query = db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
               let subquery = db.collection(item.sitecollectionname)
               console.log(item.sitecollectionname,'where ',item.sitecolfield,'==',this.userLoggedIn.id)
               this.GetPolls(query,subquery)
            }
        },
        ActivateEmail(){
            //console.log(this.UsersSelected)
            this.previewsendemaildialog = true
            this.EmailTo = this.UsersSelected.map(user => {
                return user.Email
            })
            this.EmailFrom = this.userLoggedIn.Email
            this.EmailSubject = 'Please repsond to Notice - '+this.ActiveRecord.title
            this.EmailBody = `Good Day
            <p>You are yet to respond to the Notice <strong>`+this.ActiveRecord.title+`</strong></p>`
            if(this.ActiveRecord.HasDueDate && this.ActiveRecord.DueDate){
            this.EmailBody = this.EmailBody+`Please respond as there is a Due date of `+this.ActiveRecord.DueDate.toDate()
            }
            this.EmailBody = this.EmailBody+`<br><br>
            Please click <a href="`+window.location+`">here</a> to view the Notice`
            let UserManagerids = this.UsersSelected.filter(user => {
                return user.Manager
            }).map(user => {
                return user.Managerid
            })
            this.EmailCC = UserManagerids.map(manid => {
                let managerobj = this.UsersArray.find(obj => obj.id === manid)
                return managerobj.Email
            })
            
        },
        ComputeResponses(){
            this.ResponsesComputed = []
            
            let responses = this.ActiveRecord.Responses.map(not => {
                if(not.ConfirmDate && typeof not.ConfirmDate.toDate !== 'undefined'){
                    not.StringConfirmDate = this.TimestampFormatterNoticeBoard(not.ConfirmDate.toDate(),'full')
                }
                if(not.ReadDate && typeof not.ReadDate.toDate !== 'undefined'){
                    not.StringReadDate = this.TimestampFormatterNoticeBoard(not.ReadDate.toDate(),'full')
                }
                return not
            })
            //console.log('computing',this.ActiveRecord.Responses.length)
            let users = JSON.parse(JSON.stringify(this.UsersArray))
            users.map(user => {
                
                let matchobj = responses.find(obj => obj.id === user.id)
                let pushitem = { 
                    id: user.id,                      
                        Full_Name: user.Full_Name,
                        Email: user.Email
                    }
                if(user.Manager){
                    pushitem.Manager= user.Manager
                }
                if(user.Managerid){
                    pushitem.Managerid= user.Managerid
                }
                //console.log(matchobj)
                if(matchobj && matchobj.Read){
                    pushitem.Read= matchobj.Read
                }
                if(matchobj && matchobj.StringReadDate){
                    pushitem.ReadDate= matchobj.StringReadDate
                }
                if(matchobj && matchobj.Confirmed){
                    pushitem.Confirmed= matchobj.Confirmed
                }
                if(matchobj && matchobj.StringConfirmDate){
                    pushitem.ConfirmDate= matchobj.StringConfirmDate
                }
                    //console.log(pushitem)
                this.ResponsesComputed.push(pushitem)
                //console.log(this.ResponsesComputed)
                
            })
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
       GetMatchingStatus(item){
           if(this.MenuItem && this.MenuItem.Array){
             return this.MenuItem.Array
            .map(item => {
                item.Status = 'Active'
                return item
            })
            .filter(blog => {
                return blog.Status === item.title
            })
            .map(blog => {
                if(item.title === 'Draft'){
                    blog.class = 'warning--text'
                }
                blog.Confirmations = []
                if(blog.MustConfirm){
                    blog.Confirmations = blog.Responses
                    .filter(resp => {
                        return resp.Confirmed
                    })
                    
                }
                blog.ConfirmValue = Math.round((blog.Confirmations.length/this.UsersArray.length)*100)
                blog.ReadValue = Math.round((blog.Responses.length/this.UsersArray.length)*100)
                let readredcolor = Math.round(100-blog.ReadValue)*2.55
                let readgreencolor = Math.round(blog.ReadValue*2.55)                 
                blog.Color = 'rgb('+readredcolor+','+readgreencolor+',0)'
                let confirmredcolor = Math.round(100-blog.ReadValue)*2.55
                let confirmgreencolor = Math.round(blog.ReadValue*2.55)                 
                blog.ConfirmColor = 'rgb('+confirmredcolor+','+confirmgreencolor+',0)'
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                blog.Responses.map(resp => {
                    resp.PrimaryDateSTRING = this.TimestampFormatterSTRING(resp.ReadDate.toDate())
                    resp.PrimaryDateObj = new Date(resp.PrimaryDateSTRING)
                    
                })
                 blog.Confirmations.map(resp => {
                        resp.SecondaryDateSTRING = this.TimestampFormatterSTRING(resp.ConfirmDate.toDate())
                        resp.SecondaryDateObj = new Date(resp.SecondaryDateSTRING)
                        return resp
                    })
                let readsthisweek = blog.Responses.filter(resp => {
                    let date = new Date()
                    date.setHours(0,0,0,0)                    
                    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate()-this.ByDays)
                  
                    return resp.PrimaryDateObj >= d
                }).length
                let confirmationsthisweek = blog.Confirmations.filter(resp => {
                    let date = new Date()
                    date.setHours(0,0,0,0)                    
                    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate()-this.ByDays)
                  
                    return resp.PrimaryDateObj >= d
                }).length
                //console.log(readsthisweek)
                blog.PercentageReadThisWeek = Math.round((readsthisweek/blog.Responses.length)*100)
                if(blog.PercentageReadThisWeek > 0){
                    blog.WeekReadIncreaseColor = 'green'
                }
                //console.log(confirmationsthisweek)
                blog.PercentageConfirmedThisWeek = Math.round((confirmationsthisweek/blog.Confirmations.length)*100)
                if(blog.PercentageConfirmedThisWeek > 0){
                    blog.WeekConfirmedIncreaseColor = 'green'
                }
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                return blog
            })
           }
           else{
               return []
           }
        },
        PushItem(item){
            this.ActiveRecord = item
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        GetPolls(query,subquery){
            // let subcol = ''
            // if(this.$route.params.id){
            //     subcol = 'grouppollvotes'
            // }
            // else{
            //     subcol = 'response'
            // }
            query.onSnapshot(res => {
                let noticeslength = res.docs.length
                const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      
                          let pollobj ={
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      pollobj.Responses = []
                    //   console.log(pollobj)
                subquery.doc(pollobj.id).collection('responses').onSnapshot(votesres => {
                    const voteschanges = votesres.docChanges();
                    let listlength = votesres.docs.length
                    if(listlength === 0){
                      this.SitePolls.push(pollobj)
                    if(this.SitePolls.length === noticeslength){
                      this.$emit('PushArray',this.MenuItem,this.SitePolls)  
                      }                    
                    }
                    voteschanges.forEach(votechange => {
                    if (votechange.type === 'added') {
                        pollobj.Responses.push({
                        ...votechange.doc.data(),
                        id: votechange.doc.id
                        })
                    }
                    if(pollobj.Responses.length === listlength){
                        this.SitePolls.push(pollobj)
                        if(this.SitePolls.length === noticeslength){
                         this.$emit('PushArray',this.MenuItem,this.SitePolls)   
                        }
                        
                    }
                })
                      })
                  }
              })
            })
        }
    }

}
</script>

<style>

</style>


    