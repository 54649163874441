
<template>
<v-card flat :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent' : 'transparent'" width="100%" height="100%">
  <!-- <v-card  :flat="NoticeSelect" -->
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">Blogs</h4>
              </v-col>
            </v-row>
            </v-parallax> 
    <v-layout class="justify-end" v-if="!NoticeSelect">
      <v-btn outlined color="accent" @click="OpenAboutDialog('AboutSiteBlogs')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Blogs</v-btn>
    <v-btn outlined color="accent" :to="SingleRouteNewPrefix" v-if="UserModerator || CanCreate || UserisTemplateOwner"><v-icon>mdi-plus</v-icon>New Blog</v-btn>
    </v-layout>
    
<v-list v-if="!NoticeSelect">
     <v-list-item  v-for="article in articlesDated" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                
                <v-card-actions style="font-size:10px">
                   {{article.pubDate}}<v-spacer>
                    </v-spacer>
                    <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                </v-card-actions>        
                
                <v-card-text v-html="smart_substr(article.description,1800)">
                </v-card-text>
                <v-card-actions>
                  
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark  :to="SingleRoutePrefix+article.id">
                  <v-icon v-if="MiniView">mdi-glasses</v-icon>
                  {{MiniView? '' : 'Read More'}}</v-btn>
                </v-card-actions>
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
        <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="articlesDated" item-text="title" v-model="selectedSocialItem" return-object label="Blog"/>
    </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','CanCreate','NoticeSelect','System','GroupData'],
    components: {
        //FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
            InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
            groupblogs: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
      articlesDated(){
        return this.groupblogs.map(article => {
          if(!article.pubDate){
            //still need to convert this.$store.dispatch('FullSTDDate',article.PublishDate.toDate()).then(res => {
            article.pubDate = this.TimestampFormatterSTRINGDayText(article.PublishDate.toDate())
          }
          
      
          return article
        })
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      SingleRouteNewPrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/NewBlog'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/NewBlog'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/NewBlog' : '/Group/NewBlog/'+this.$route.params.id
        }
      },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupBlogs' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Blogs' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Blog/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Blog/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Article/' : '/Group-Article/'+this.$route.params.id+'/Article/'
        }
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      CollectionRef(){
        if(this.TemplateRootPath){
          return this.ContentRootPath.collection('samplesiteblogs')
        }
        else if(this.NonGroupRoute){
         return this.userLoggedIn ? this.ContentRootPath.collection('siteblogs') : db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('siteblogswiki') 
        }
        else{
          return this.ContentRootPath.collection('groupblogs').where('groupid','==',this.$route.params.id)
          //.where('GuestsIncluded','==',true)
        }
        
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      UserisTeamMember(){
        return this.GroupData && this.GroupData.TeamMembers ? this.GroupData.TeamMembers.find(obj => obj === this.userLoggedIn.id) : false
      },
      UserisGroupMember(){
        return this.GroupData && this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.find(obj => obj.id === this.GroupData.id) : false
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserModerator(){
        if(this.userLoggedIn.ModerationPermissions){
          let match = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Blogs')
          if(match){
            return true
          }
          else{
            return this.userIsAdmin
          }
        }
        else{
          return this.userIsAdmin
        }
      },
        RelatedBlogs () {
            return this.groupblogs.filter(blog => {
              if(this.search){
                return blog.title.toLowerCase().includes(this.search.toLowerCase()) && blog.Groupid === this.groupid && blog.PublishStatus === 'Published' || blog.content.toLowerCase().includes(this.search.toLowerCase()) && blog.Groupid === this.groupid && blog.PublishStatus === 'Published' || blog.content.toLowerCase().includes(this.search.toLowerCase()) && blog.authorid === this.userLoggedIn.id  && blog.Groupid === this.Groupid && blog.PublishStatus === 'Draft'
              }
              else{
                return blog
                }
            })
        },

        BlogExcerpt () {
          return this.RelatedBlogs.filter(blog => {
            return blog.content === this.blog.content
          })
        }
    },

    created() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.GetSamples(this.CollectionRef,'groupblogs')
      }
      else{
      if(this.NonGroupRoute){
        this.InternalLinkType = 'Blog'
        this.SharedCollection = 'siteblogs'
        this.LinkComponent = 'SiteBlogShared'
        let colref = this.CollectionRef
        if(this.$store.state.UserisGuest || this.userIsAdmin){
          this.getGroupBlogs(colref.where('PublishStatus','==','Published (Public)'))
          if(!this.ChannelRestricted || this.userIsAdmin){
            if(!this.CompanyOnlyFilter || this.userIsAdmin){
            this.getGroupBlogs(colref.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
            }
            else{
              this.getGroupBlogs(colref.where('PublishStatus','==','Published (Internal)').where('Companyid','==',this.UserCompanyid))
            }
          }
          
        }
        else{
          if(!this.ChannelRestricted || this.userIsAdmin){
            if(!this.CompanyOnlyFilter || this.userIsAdmin){
            this.getGroupBlogs(colref.where('PublishStatus','!=','Draft'))
            }
            else{
              this.getGroupBlogs(colref.where('PublishStatus','!=','Draft').where('Companyid','==',this.UserCompanyid))
            }
          }
          else{
            this.getGroupBlogs(colref.where('PublishStatus','==','Published (Public)'))
          }
        }
      }
      else{
        this.InternalLinkType = 'Group-Blog/'+this.$route.params.id+'/Blog'
        this.SharedCollection = 'groupblogs'
        this.LinkComponent = 'GroupBlogShared'
        let colref = db.collection('groupblogs').where('groupid','==',this.$route.params.id)
        if(this.UserisTeamMember){
          this.getGroupBlogs(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
        }
        else if(this.userLoggedIn && !this.$store.state.UserisGuest){
          this.getGroupBlogs(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
        }
        else{                
          this.getGroupBlogs(colref.where('GuestsIncluded','==',true).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
        }   
      }
      }
   
        //console.log(this.userid)
},
    methods: {
      OpenAboutDialog(dialprop){      
          this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
          this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
        },
      TimestampFormatterSTRINGDayText(d){       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]   
        var hr = d.getHours();       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year
        return finaldate
      },   
      GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      smart_substr(str, len) {
            var total = str
            // var temp = str.substr(0, len);
            // if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
            //     temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
            // }
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
         getGroupBlogs(col) {
           col.where('PublishStatus','==','Published (Internal)').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupblogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }  
            })
          })
          col.where('PublishStatus','==','Published (Public)').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupblogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }  
            })
          })
          col.where('PublishStatus','==','Draft').where('moderatorrolesarrayQuery','array-contains',this.userLoggedIn.id).onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.groupblogs.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('groupblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

    


    