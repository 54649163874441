<template>
<v-row class="justify-center">
<v-flex xl11 lg11 md11 sm12 xs12 style="margin-top:20px;">
  <SocialSitePosts  :UserObj="userLoggedIn" :AppisDarkMode="AppisDarkMode" class="mx-3" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" 
   :UserRecord="userLoggedIn" :UserorGroupID="userLoggedIn.id" :UserView="false" :Slice="10" />
</v-flex>
</v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';

export default {
    props: ['AppIsDarkMode'],
    components: {
        SocialSitePosts
    },
    data(){
        return {

        }
    },
    computed:{
        userLoggedIn () {
      return this.$store.getters.user
    },
    },
    created(){
        this.IntranetViewToggle()
    },
    methods:{
        IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
        ActivateMultipleFilesUploadNotification(boolean){
        this.$emit('ActivateMultipleFilesUploadNotification',boolean)
      },
    }

}
</script>

<style>

</style>