<template>
  <div>    
    <v-btn @click="ToggleStopWatchDialog()" outlined>Start Timesheet</v-btn>
      <v-tabs>
          <v-tab v-if="!RelatedObj">Current
          </v-tab>
          <v-tab-item v-if="!RelatedObj">
              <v-list>
                  <v-list-item>
                      <v-text-field v-model="UserSearch" label="Search"/>
                  </v-list-item>
                  <v-list-item :class="$vuetify.theme.dark ? 'darkdetailslistoutline' : 'detailslistoutline'" v-for="user in ComputedUsersArray" :key="user.itemObjKey">
                      <v-list-item-actions>
                      <v-avatar size="40" v-if="user.Profile_Photo">
                              <img :src="user.Profile_Photo" style="object-fit: cover;" >
                            </v-avatar>
                            <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!user.Profile_Photo">                               
                                {{user.Full_Name.split(' ')[0].substr(0,1)}}{{user.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-avatar>
                      </v-list-item-actions>
                       <v-list-item-content>
                            <v-list-item-title>
                            {{user.Full_Name}}
                        </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content v-if="user.CurrentJob">
                            <v-list-item-title>
                                {{user.CurrentJob.RelatedObj[user.CurrentJob.RelatedObj.identifier]}}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size:10px;color: grey;" >
                                Started:
                            </v-list-item-subtitle>
                            <v-list-item-subtitle style="font-size:10px;color: grey;" v-if="user.CurrentJob.Created_On && user.CurrentJob.Created_On.toDate">
                                {{user.CurrentJob.Created_On.toDate()}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle style="font-size:10px;color: grey;" v-else-if="user.CurrentJob.Created_On && user.CurrentJob.Created_On.seconds">
                                {{new Date(user.CurrentJob.Created_On.seconds*1000)}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="!user.CurrentJob">
                            <v-list-item-title class="success--text" style="font-size:14px;">
                                <v-icon small color="success">mdi-timer-sand</v-icon>Available
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-actions>
                          <v-btn :disabled="!user.CurrentJob" color="success" fab icon><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-list-item-actions>
                  </v-list-item>
              </v-list>
          </v-tab-item>
          <v-tab>Timesheets
          </v-tab>
          <v-tab-item>
               <v-select label="View by" style="width:20%;" v-model="ViewBy" :items="['User','Project']" color="secondary" >
                        
                    </v-select>
               <v-calendar
            style="display:none;"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="AllEventsViewFormatted"
            :now="today"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
           <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12" scolor="primary white--text">            
                    <v-btn outlined class="mr-4" color="secondary" @click="setToday()">
                        Today
                    </v-btn>
                   
                    <v-btn fab text small color="secondary" @click="$refs.calendar.prev()">
                        <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab text small color="secondary" @click="$refs.calendar.next()">
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-toolbar-title class="white--text">{{ datetitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on }">
                        <v-btn
                            outlined
                            color="secondary"
                            v-on="on"
                        >
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                        <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                    </v-toolbar>
              <v-card min-height="200" v-if="ViewBy === 'User'">
                 
                    <v-data-table
                    :headers="ComputedHeaders"
                    :items="AllEventsViewFormatted"
                    >
                     <template v-slot:item="{ item }">
                        <tr class="justify-start">
                            <td v-for="header in ComputedHeaders" :key="header.itemObjKey" class="text-start">
                              <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                              <v-btn fab elevation="2" tile small v-bind="attrs" style="font-size: 8px;" :class="msToTimeClass(item[header.value+'Milliseconds'])" 
                              v-on="on" v-if="header.value !== 'Full_Name'">
                                {{ item[header.value].split(' ')[0] }}<br>{{ item[header.value].split(' ')[1] }}
                              </v-btn>
                              </template>
                               <span >
                              {{ item[header.value] }}</span>
                              </v-tooltip>
                              <span  v-if="header.value === 'Full_Name'">
                                <v-avatar size="40" v-if="item.Profile_Photo">
                                  <img :src="item.Profile_Photo" style="object-fit: cover;" >
                                </v-avatar>
                                <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!item.Profile_Photo">                               
                                    {{item.Full_Name.split(' ')[0].substr(0,1)}}{{item.Full_Name.split(' ')[1].substr(0,1)}}
                                </v-avatar>
                              </span>                             
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
              </v-card>
              <div v-if="ViewBy === 'Project'">  
      
      <v-list class="my-3" :dark="AppisDarkMode"  outlined three-line v-for="event in ProjectEventsViewFormatted"  :key="event.itemObjKey">
              <v-card-title>
              {{event.RecordName}}
              </v-card-title>
              <v-card-text>
               Start: {{event.StartObject.toGMTString()}}
                   End: {{event.EndObject.toGMTString()}}
              </v-card-text>
             
            <v-list-item append class="mx-1" > 
              <v-list-item-avatar :class="msToTimeClass(event.DurationMS)+' noticeboarddate justify-center'"  size="60" rounded>
                <ul style="list-style-type:none;padding: 0;">
                  <li>{{event.Total.split(' ')[0]}}</li>
                  <li class="caption">{{event.Total.split(' ')[1]}}</li>
                </ul>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle >                              
                  <v-chip :color="event.TypeColor">
                    {{event.Name}}
                  </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle >                                   
                  <router-link class="blue--text" :to="'/'+event.RelatedObj.Link+'/'+event.RelatedObjid"> {{event.RelatedObj[event.RelatedObj.identifier]}}</router-link>
                </v-list-item-subtitle>

                <v-list-item-content class="caption grey--text">
                {{event.Presenter}}
                </v-list-item-content>
                </v-list-item-content>
                <v-list-item-action>
                    
                </v-list-item-action>
                <v-list-item-action>
                </v-list-item-action>            
            </v-list-item>
          </v-list>
              </div>
          </v-tab-item>
      </v-tabs>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ScheduledBookingViewComp from '@/components/SuitePlugins/CalendarTables/ScheduledBookingViewComp'
import Stopwatch from '@/components/SuitePlugins/Timesheets/Stopwatch'
export default {
    props: ['System','SystemEntities','RelatedObj'],
    components: {ScheduledBookingViewComp,Stopwatch},
    data() {
        return {
            ViewBy: 'User',
            UserSearch: '',
            Timesheets: [],
            focus: '',
            WeekDay: 0,
            type: 'week',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
            },
            start: null,
            end: null,
            today: ''
        }
    },
    computed:{
      StopwatchDialog(){
        return this.$store.state.StopwatchDialog
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ComputedSystemEntities(){
          return this.SystemEntities.filter(entity => {
            return this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
          })
        },
        datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
        WeekDays(){
          //console.log(this.start,this.end)
    let array= []
    if(this.start && this.end){
    let start = new Date(this.start.date)
    let sunday = start
    let monday = new Date(sunday)
    monday.setDate(sunday.getDate()-1+2)
    let tuesday = new Date(monday)
    tuesday.setDate(monday.getDate()-1+2) 
    let wednesday = new Date(tuesday)
    wednesday.setDate(tuesday.getDate()-1+2)
    let thursday = new Date(wednesday)
    thursday.setDate(wednesday.getDate()-1+2)
    let friday = new Date(thursday)
    friday.setDate(thursday.getDate()-1+2)  
    let end = new Date(this.end.date)
    end.setHours(23,59,59,999)
       array= [
        {
          Short: 'Su',
          Day: sunday.getDate(),
          DateObject: sunday,
          DateString: format(sunday,'yyyy-MM-dd')
        },
        {
          Short: 'Mo',
          Day: monday.getDate(),
          DateObject: monday,
          DateString: format(monday,'yyyy-MM-dd')
        },
        {
          Short: 'Tu',
          Day: tuesday.getDate(),
          DateObject: tuesday,
          DateString: format(tuesday,'yyyy-MM-dd')
        },
        {
          Short: 'We',
          Day: wednesday.getDate(),
          DateObject: wednesday,
          DateString: format(wednesday,'yyyy-MM-dd')
        },
        {
          Short: 'Th',
          Day: thursday.getDate(),
          DateObject: thursday,
          DateString: format(thursday,'yyyy-MM-dd')
        },
        {
          Short: 'Fr',
          Day: friday.getDate(),
          DateObject: friday,
          DateString: format(friday,'yyyy-MM-dd')
        },
        {
          Short: 'Sa',
          Day: end.getDate(),
          DateObject: end,
          DateString: format(end,'yyyy-MM-dd')
        }
      ]
    }
    
    
    return array
  },
        userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        AllEventsViewFormatted(){
            return this.DataPrepped.map(event => {
                let eventobj = Object.assign({},event) 
                eventobj.name = eventobj.RecordName                 
                //console.log(eventobj)  
                return eventobj
                
            })
            .filter(event => {
            if(this.focus){
            let start = new Date(this.focus)
            let end = new Date(this.focus)
            end.setHours(23,59,59,999)
            //console.log(event.StartObject,this.WeekDays[0].DateObject,event.EndObject,this.WeekDays[6].DateObject)
            //return event.StartObject >= this.WeekDays[0].DateObject && event.EndObject <= this.WeekDays[6].DateObject
            return event.StartObject >= start && event.EndObject <= end || event.IsUser
            //return event.StartObject >= start && event.EndObject <= end || event.IsUser
            }
            else{
            return event
            }
            
        }).sort((a, b) => {
                //console.log('sorting again')
                //console.log(a.start)
                var key1 = a.StartObject;
                var key2 = b.StartObject;

                if (key1 < key2) {
                    return -1;
                } else if (key1 == key2) {
                    return 0;
                } else {
                    return 1;
                }
            })
        },
        DayCount(){
            return this.start && this.start.month ? this.DaysofMonth(this.start.month,this.start.year) : 0
        },
        TypeHeaders(){
            let array = [
                {text: 'Full Name',value: 'Full_Name'},
            ]
            if(this.type === 'day'){
                array.push({text: 'Total',value: 'Total'})
            }
            else if(this.type === 'week'){
                array.push({text: 'Su',value: 'Su'})
                array.push({text: 'Mo',value: 'Mo'})
                array.push({text: 'Tu',value: 'Tu'})
                array.push({text: 'We',value: 'We'})
                array.push({text: 'Th',value: 'Th'})
                array.push({text: 'Fr',value: 'Fr'})
                array.push({text: 'Sa',value: 'Sa'})
                array.push({text: 'Total',value: 'Total'})
            }
            else if(this.type === 'month'){      
                console.log(this.DayCount)
                for(var x = 1; x <= this.DayCount; x++){	
                    array.push({text: x,value: x+'Count'})
                    if(x === this.DayCount){
                        array.push({text: 'Total',value: 'Total'})
                    }
                }
            }
            return array
        },
        ComputedHeaders(){
            return this.ViewBy === 'Project' ? [] 
            : this.TypeHeaders
        },
        ProjectEventsViewFormatted(){
            let array = []
            this.TimeSheetsViewFormatted
            .map(timesheet => {
                let oncheck = array.find(obj => obj.RelatedObjid === timesheet.RelatedObjid)
                if(!oncheck){
                    array.push(timesheet)
                }
                else{
                    oncheck.DurationMS = oncheck.DurationMS+timesheet.DurationMS
                    oncheck.Total = this.msToTime(oncheck.DurationMS)
                }
            })
            return array
        },
        TimeSheetsViewFormatted(){
            return this.ProjectDataPrepped.map(event => {
                    let eventobj = Object.assign({},event) 
                    eventobj.name = eventobj.RecordName                 
                    //console.log(eventobj)  
                    return eventobj                    
                })
                .filter(event => {
                if(this.start && this.end){
                let start = new Date(this.start.date)
                let end = new Date(this.end.date)
                end.setHours(23,59,59,999)
                //console.log(start,event.StartObject,end,event.EndObject,event.StartObject >= start,event.EndObject <= end,event.StartObject >= start || event.EndObject <= end)
                //return event.StartObject >= this.WeekDays[0].DateObject && event.EndObject <= this.WeekDays[6].DateObject
                
                //MANY WAYS ACTUALLY FIRST return event.StartObject >= start && event.EndObject <= end
                //THIS MEANS IT HAS TO BE A TIMESHEET THAT OPENED AND CLOSED IN VIEWING PERIOD. 
                //THEN return event.StartObject >= start
                //THIS MEANS IT HAD TO HAVE STARTED IN THE VIEWING PERIOD, MEANING SAY IT STARTED MONTH END END ENDED THE NEXT DAY, IT WILL VIEW IN STARTING MONTH VIEW
                //FINALLY return event.StartObject >= start || event.EndObject <= end
                //THIS MEANS, IF IT STARTED IN THIS PERIOD, OR ENDED IN THIS PERIOD. probably the most accurate barring "intervals" could be across a period
                //Technically though we have to dissect by intervals AND Start/End
                //whatever week or month the intervals falls in, is the relevant time. but too many dynamics in that
                return event.StartObject >= start
                // the above removed && event.EndObject <= end, safest way for now, at least if period overextends you can view it, until we decide whether to limit extend or not
                //event.StartObject >= start || event.EndObject <= end But this does seem to be very clear cut thouhg, it only counts the "total time" in the starting and ending period. I suspectit accurately dissects the interval as well 
                }
                else{
                return event
                }                
            }).map(item => {
                item.Total = this.msToTime(item.DurationMS)
                return item
            })
        },
        ProjectDataPrepped(){
            return this.ComputedTimesheetsArray.map(item => {
                item.RecordName = item.RecordName
                item.StartObject = item.Created_On.toDate()
                item.EndObject = item.End_Date.toDate()
                item.start = this.TimestampFormatterSTRING(item.StartObject)
                item.end = this.TimestampFormatterSTRING(item.EndObject)
                //console.log('item',item)
                return item
            })
        },
        UserDataPrepped(){
            return this.UsersArray.map(item => {
                item.RecordName = item.Full_Name
                item.IsUser = true
                item.RelatedObj = {}
                item.start = '2000-01-01'
                item.end = '2000-01-01'
                item.RangeEntries = this.TimeSheetsViewFormatted.filter(timesheet => {
                    return timesheet.Userid === item.id
                })
                item.Milliseconds = item.RangeEntries
                .map(timesheet => {
                    return timesheet.DurationMS
                })
                .reduce((a, b) => a + b, 0)
                item.Total = this.msToTime(item.Milliseconds)
                //console.log(this.WeekDays,item)
                this.WeekDays.map((weekday,headindex) => {
                    let Milliseconds = item.RangeEntries
                    .filter(timesheet => {
                        if(headindex === 6){                            
                            let daystart = new Date(weekday.DateObject)
                            daystart.setHours(0,0,0,0)
                            return timesheet.StartObject >= daystart && timesheet.EndObject < weekday.DateObject
                        }
                        else{
                            let dayend = new Date(weekday.DateObject)
                            dayend.setHours(23,59,59,999)
                          return timesheet.StartObject >= weekday.DateObject && timesheet.EndObject < dayend  
                        }                        
                    })
                    .map(timesheet => {
                        return timesheet.DurationMS
                    }).reduce((a, b) => a + b, 0)
                    item[weekday.Short] = this.msToTime(Milliseconds)
                    item[weekday.Short+'Milliseconds'] = Milliseconds
                })
                for(var x = 1; x <= this.DayCount; x++){	
                    let startdate = new Date(this.start.date)                    
                    startdate.setDate(x)
                    let nextdate = new Date(startdate)
                    nextdate.setDate(x-1+2)
                    let Milliseconds = item.RangeEntries
                    .filter(timesheet => {
                        return timesheet.StartObject >= startdate && timesheet.EndObject < nextdate                          
                    })
                    .map(timesheet => {
                        return timesheet.DurationMS
                    }).reduce((a, b) => a + b, 0)
                    item[x+'Count'] = this.msToTime(Milliseconds)
                    item[x+'CountMilliseconds'] = Milliseconds
                }
                return item
            }) 
        },
        DataPrepped(){
            return this.ViewBy === 'Project' ? this.ProjectDataPrepped : this.UserDataPrepped
        },
        ComputedUsersArray(){
            return this.UsersArray.filter(user => {
                if(this.UserSearch){
                    return user.Full_Name.toLowerCase().includes(this.UserSearch.toLowerCase())
                }
                else{
                    return user
                }
            })
        },
        ComputedTimesheetsArray(){
            return this.Timesheets.map(item => {
                let userobj = this.UsersArray.find(obj => obj.id === item.Userid)
                if(userobj){
                    item.User.Profile_Photo = userobj.Profile_Photo
                }
                // let intergerrollup = item.Intervals.map(int => {
                //     return int.interval.INT
                // }).reduce((a, b) => a + b, 0)
                let start = item.Created_On.toDate()
                let starttime = start.getTime()
                let end = item.End_Date.toDate()
                let endtime = end.getTime()
                let intergerrollup = endtime-starttime
                item.DurationMS = intergerrollup
                item.DurationMinutes = this.millisToMinutesAndSeconds(item.DurationMS)
                return item
            })
        },
        IsPrimarySite(){
      return this.$store.state.ActiveSuiteid === process.env.VUE_APP_RA_SYSTEM_ID
    },
        DBRoot(){
          return this.IsPrimarySite ? db : db.collection('Databases').doc(this.$store.state.ActiveSuiteid)
        },
        TimeSheetsPath(){
          return this.DBRoot.collection('timesheets')
        },
        UsersPath(){
          return this.IsPrimarySite ? db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles') : db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users')
        },
        
    },
    created(){
      
      if(this.RelatedObj && !this.userLoggedIn.CurrentJob){
        this.$store.commit('SetTimesheetRelated',this.RelatedObj)
      }
        //console.log(this.userLoggedIn,this.SystemEntities)
        var d = new Date();
      var n = d.toISOString();
      this.today = n.split('T')[0]
      this.setToday()
        let query = this.TimeSheetsPath
        if(this.RelatedObj){
          //console.log(this.RelatedObj)
          query = query.where('RelatedObjid','==',this.RelatedObj.id)
          this.ViewBy = 'Project'
          this.type = 'month'
        }
        this.GetTimesheets(query)
    },
    methods:{
      ToggleStopWatchDialog(){
        this.$store.commit('SetStopwatchDialog',!this.StopwatchDialog)
      },
        DaysofMonth(month,year) {
        return new Date(year, month, 0).getDate();
        },
        nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        updatefocus(index){
      this.focus = this.WeekDays[index].DateString
      //console.log(this.WeekDays,this.focus)
    },
        updateRange ({ start, end }) {
      //console.log('change')
      this.start = start
        this.end = end
        
    },
        setToday () {
        
        this.focus = this.today
        setTimeout(() => {
         let todayofweek = this.WeekDays.find(obj => obj.DateString === this.today)
        //console.log(todayofweek,this.WeekDays,this.today)
        let todayfweekindex = this.WeekDays.indexOf(todayofweek)
        this.WeekDay = todayfweekindex
        }, 100);
        
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      msToTimeClass(duration) {

        let seconds = Math.floor((duration / 1000) % 60)
        let minutes = Math.floor((duration / (1000 * 60)) % 60)
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
        //console.log('seconds',seconds)
          if(hours > 9){
            return 'warning' 
          }
          else if(hours > 5){
            return 'blue' 
          }
          else if(hours > 3){
            return 'blue lighten-1'
          }
          else if(seconds !== 0 || hours !== 0){
            return 'blue lighten-3'
          }
          else{
            return 'background'
          }
        },
      msToTime(duration) {

        let seconds = Math.floor((duration / 1000) % 60)
        let minutes = Math.floor((duration / (1000 * 60)) % 60)
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + "h " + minutes + "m "
        },
        millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        },
        GetTimesheets(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let timesheetobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    this.Timesheets.push(timesheetobj)
                }
            })
            })
        }
    }
}
</script>

<style>

</style>
