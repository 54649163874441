<template>
<div>
<v-list-item>
    <v-list-item-content style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action style="font-weight: 300;" v-if="!Editing">
      <span v-if="field.Type === 'Social Lookup' && Record[field.Name]">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <router-link :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id">{{Record[field.Name][field.LookupFieldName]}}</router-link>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
        <!-- {{FieldValue}} -->
    </v-list-item-action>
    <v-list-item-action style="font-weight: 300;" v-if="Editing">
        <span v-if="field.RelatedBuildID !== 'Users'">
                    <v-autocomplete :multiple="field.IsMultiple" class="smallinput" :items="field.Array" :item-text="'id'" :rules="field.IsMandatory? [$store.state.formrules.required] : []" 
                    :readonly="field.ReadOnly || field.RelatedReadOnly || field.MappedField && userLoggedIn || field.MappedField && !userLoggedIn && !field.MappedField.LoggedInUserValue"
                    v-model="Record[field.Name]" return-object :label='field.Name' :filter="fieldFilter">
                      <template v-slot:no-data>
                          <i class="mx-4">no data</i>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingLookup()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewLookupDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template>
                          <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="data.item[field.LookupFieldName]"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                           <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title  v-html="data.item[field.LookupFieldName]"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.id"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                    </v-autocomplete>
                    </span><span v-if="field.RelatedBuildID === 'Users'">
                      <UserLookup :UsersLookupArray="field.Array"  :ModelProp="field.Name" :RecordObj="Record" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="field.DisplayName" :readonly="field.ReadOnly || Record[field.Name] && !UserCanGetLookup" :Rules="field.IsMandatory? [$store.state.formrules.required] : []"
                      />
                    </span>
    </v-list-item-action>
    <!-- types could be
    'Shared Doc Link','Route Path','Lookup','Social Lookup' and of couse Parent Field if PArentFieldObj.Type any of these..
    and then url link, commonfieldtype
     -->
</v-list-item>
 <LookupComponent v-if="ViewExistingLookupDialog || AddNewParentLookupDialog" :ParentCollection="ParentCollection" 
 :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" 
 :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" 
 :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" 
 :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LookupComponent from '@/components/Database/LookupComponent'
export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing','RelatedObj'],
    components: {LookupComponent},
    data() {
        return {            
            AddNewParentLookupDialog: false,
            ViewExistingLookupDialog: false,
            FieldFilter: '',
            ParentTitle: '',
            ParentCollection:  '',
            NewParentPrimary: '',
            ParentLookup: '',
            NewParentAdditional: [],
            LookupArray: [],
            LookupArrayHeaders: []
        }
    },	
    computed:{
      
        LookupRecord(){
          //console.log(this.field)
          let luarray = []
          if(this.field.Array){
            luarray = this.field.Array
          }
            if(this.Record[this.field.Name]){
                //console.log(this.Record[this.field.Name].id,this.field,this.Record[this.field.Name])
                let matchingrecord = luarray.find(obj => obj.id === this.Record[this.field.Name].id)
                if(matchingrecord){
                    return matchingrecord
                }
                else{
                    return this.Record[this.field.Name]
                }
            }
        },
        LookupRoute(){
            let routes = this.$router.getRoutes()
            let routeitem = routes.find(obj => obj.meta && obj.meta.RouteEventID === 1029 && obj.meta.EntityID === this.field.RelatedBuildID)
            return routeitem
        },
        LookupRouteName(){
            return this.LookupRoute ? this.LookupRoute.path.split('/').join('') : ''
        },
        LookupRouteSecurity(){
            return this.LookupRoute ? this.LookupRoute.meta.Security : ''
        },
        LookupRouteSecurityType(){
            return this.LookupRouteSecurity ? this.LookupRouteSecurity.SecurityType : ''
        },
        UserCanGetLookup(){
            let systemrecord = this.LookupRecord
            //console.log('LookupRecord',systemrecord,this.field,this.$store.state,this.LookupRouteName)
            if(systemrecord && this.LookupRouteSecurityType === 'Business Unit'){
                //console.log('LookupRouteName',this.LookupRouteName+'viewAll',this.LookupRouteName+'viewUnitdown',this.LookupRouteName+'viewUserUnit',this.LookupRouteName+'viewOwner')
                if(this.$store.state[this.LookupRouteName+'viewAll']){
                    return true
                }
                else if(this.$store.state[this.LookupRouteName+'viewUnitdown']){
                    //LookupRecord
                    return this.userBUChildren.includes(systemrecord.Business_Unitid)
                }
                else if(this.$store.state[this.LookupRouteName+'viewUserUnit']){
                    //LookupRecord
                    return systemrecord.Business_Unitid === this.userBUID
                }
                else if(this.$store.state[this.LookupRouteName+'viewOwner']){
                    //LookupRecord
                    return systemrecord.Ownerid === this.userLoggedIn.id
                }
            }
            else if(systemrecord && this.LookupRouteSecurityType === 'Custom Roles'){
                return this.$store.state[this.LookupRouteName+'CanView']
            }
        },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        CancelSelection(){
        this.ViewExistingLookupDialog = false
        },
        CancelAddParentDialog(){
          this.AddNewParentLookupDialog = false
        },
        ActivateAddNewLookupDialog(){
        this.CancelSelection()
        this.LookupArray = this.field.Array
        //console.log(this.field,this.LookupArray)
        //lookupentity headers
        let entity = this.SystemEntities.find(obj => obj.id === this.field.RelatedBuildID)
        this.LookupArrayHeaders = entity.AllFields.filter(field => {
            return field.IsHeader}).map(field => {
                field.text = field.DisplayName
                field.value = field.Name
                if(field.Type === 'Lookup'){
                    field.value = field.value+'.'+field.LookupFieldName
                }
                else if(field.Type === 'Option Set'){
                    field.value = field.value+'.Name'
                }
                field.class = 'overline'
                return field
            })
        this.ParentTitle = this.field.Name
        this.ParentCollection = entity.DisplayName.split(' ').join('').toLowerCase()
        this.NewParentAdditional = []
        this.ParentLookup = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Propid: this.field.Name+'id', Value: '',Array: this.field.Name+'Array',LookupFieldName: this.field.LookupFieldName}
        this.NewParentPrimary = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Value: '',LookupFieldName: this.field.LookupFieldName}
        this.AddNewParentLookupDialog = true
      },
        PrepareLinkExistingLookup(){
        //this.CancelAddParentDialog()
        //this.PrepareDataParentPrimary_Contact()
        this.ViewExistingLookupDialog = true
        this.FieldFilter = this.field.DisplayName
        this.LookupArray = this.field.Array
        //console.log(this.field,this.LookupArray)
        //lookupentity headers
        let entity = this.SystemEntities.find(obj => obj.id === this.field.RelatedBuildID)
        this.LookupArrayHeaders = entity.AllFields.filter(field => {
            return field.IsHeader}).map(field => {
                field.text = field.DisplayName
                field.value = field.Name
                if(field.Type === 'Lookup'){
                    field.value = field.value+'.'+field.LookupFieldName
                }
                else if(field.Type === 'Option Set'){
                    field.value = field.value+'.Name'
                }
                field.class = 'overline'
                return field
            })
            
        //     [
        //     { text: 'Business Unit', value: 'Business_Unit.Name',class: "overline"},
        //     { text: 'Company', value: 'Company.Company_Name',class: "overline"},
        //     { text: 'Full Name', value: 'Full_Name',class: "overline"},
        //     { text: 'Owner', value: 'Owner.Full_Name',class: "overline"},
        //     { text: 'Status', value: 'Status.Name',class: "overline"},
        // ]
        this.ParentTitle = this.field.Name
        this.ParentCollection = entity.DisplayName.split(' ').join('').toLowerCase()
        this.NewParentAdditional = []
        this.ParentLookup = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Propid: this.field.Name+'id', Value: '',Array: this.field.Name+'Array',LookupFieldName: this.field.LookupFieldName}
        this.NewParentPrimary = {Type: 'Lookup', Name: this.field.Name, Prop: this.field.Name, Value: '',LookupFieldName: this.field.LookupFieldName}
        //         let CompanyObj = {Type: 'Lookup', Name: 'Company',Prop: this.PrimaryFieldName,Array: this.CompanyArray, Propid: this.PrimaryFieldName+'id', LookupFieldName: this.PrimaryFieldName, Value: ''}
        // this.NewParentAdditional.push(CompanyObj)
},
    }
}
</script>

<style>

</style>



