
<template>
    <v-content>
      
        <v-dialog transition="fab-transition" persistent v-model="StaticAddNewParentLookupDialog" width="100%">
      <v-layout class="justify-center">
    <v-card width="100%" class="stickytopbanner">
      <v-card-title>
        Add New {{ParentTitle}}
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              {{NewParentPrimary.Name}}
            </v-list-item-content>
            <v-list-item-content>
                <v-text-field style="font-size:14px;color:grey;" dense v-model="NewParentPrimary.Value" :label="NewParentPrimary.Name">
                </v-text-field>
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
            <v-list-item-content v-for="field in NewParentAdditional.slice(0,2)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-text-field v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'" :rules="[$store.state.formrules.required,$store.state.formrules.email]" v-model="field.Value" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup'"
                            :label="field.DisplayName" :item-text="field.LookupFieldName" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="NewParentAdditional.length>=3">
            <v-list-item-content v-for="field in NewParentAdditional.slice(2,4)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
             <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                             <v-text-field v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'" :rules="[$store.state.formrules.required,$store.state.formrules.email]" v-model="field.Value" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' && field.RelatedBuildID !== 'Users'" :label="field.Name" :item-text="field.LookupFieldName" :items="field.Array"></v-autocomplete>
                            <span v-if="field.RelatedBuildID === 'Users'">
                      <UserLookup :UsersLookupArray="field.Array" :ModelProp="'Value'" :RecordObj="field" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="field.DisplayName" :readonly="FieldIsReadOnly(field)"
                      :Rules="field.IsMandatory && !FieldIsReadOnly(field)? [$store.state.formrules.required] : []"
                      />
                      </span>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="NewParentAdditional.length>=5">
            <v-list-item-content v-for="field in NewParentAdditional.slice(4,6)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                             <v-text-field v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'" :rules="[$store.state.formrules.required,$store.state.formrules.email]" v-model="field.Value" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete style="font-size:14px;color:grey;" return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
            </v-list-item>
           <v-list-item v-if="NewParentAdditional.length>=7">
            <v-list-item-content v-for="field in NewParentAdditional.slice(6,8)" :key="field.itemObjKey">
              <v-list-item-title>{{field.Name}}</v-list-item-title>
              <v-list-item-action>
                <v-select style="font-size:14px;color:grey;" return-object v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.Name"></v-select>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Single Line Text'" :label="field.Name"></v-text-field>
                            <v-text-field v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'" :rules="[$store.state.formrules.required,$store.state.formrules.email]" v-model="field.Value" :label="field.Name"></v-text-field>
                            <v-text-field style="font-size:14px;color:grey;" v-model="field.Value" type="number" v-if="field.Type === 'Number Field'" :label="field.Name"></v-text-field>
                            <v-textarea style="font-size:14px;color:grey;" v-model="field.Value" v-if="field.Type === 'Multiple Lines Text' " :label="field.Name"></v-textarea>
                            <v-autocomplete return-object v-model="field.Value" v-if="field.Type === 'Lookup' " :label="field.Name" :item-text="field.Prop" :items="field.Array"></v-autocomplete>
                            <v-menu v-model="field.Value" v-if="field.Type === 'Date'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="200px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field style="font-size:14px;color:grey;"
                                  v-model="field.Value" :label="field.Name"
                                  prepend-icon="mdi-calendar-month"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                                </template>
                              <v-date-picker></v-date-picker>
                            </v-menu>
                              <input :label="field.Name" type="file"  v-if="field.Type === 'Single File Upload'">
                </v-list-item-action>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-layout class="justify-center" style="font-size:12px;color:grey;">
        This will create the {{ParentTitle}} and link it to {{Job_Number}} as a Parent 
      </v-layout>
      <v-card-actions>
        <v-layout class="justify-end">
          <v-btn @click="CancelAddParentDialog()" color="red" dark>Cancel</v-btn><v-btn  color="green" dark @click="AddParentandLink()">Save</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
      </v-layout>
    </v-dialog>
    <v-dialog max-width="800" v-model="ViewExistingLookupDialog">
      <v-card flat width="100%" height="100%">
            <div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
              <v-card-title class="mediumoverline white--text">
                Lookup Records - {{RelatedObj.Link}}
              </v-card-title>
              <v-card-subtitle class="overline white--text">
                Choose below a new Parent {{FieldFilter}} for {{RelatedObj[RelatedObj.identifier]}}
            </v-card-subtitle></div>
        
        <v-layout class="justify-center">
        <v-list width="100%" dense>  
                    <v-list-item class='listoutline'>
                      <v-list-item-content class="overline">               
                      Record:
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-combobox class="FormFields" v-model="RelatedObj" readonly filled label="Record" autocomplete="off" :items="RelatedObjArray" :item-text="RelatedObj.identifier"></v-combobox>  
                      </v-list-item-content>               
                   </v-list-item>
                   <v-list-item class='listoutline' v-if="MailRecordFilter !== 'Users'">
                      <v-list-item-content class="overline">               
                      Field:
                      </v-list-item-content>
                      <v-list-item-content>               
                      <v-text-field class="FormFields" filled readonly v-model="FieldFilter" label="Field" autocomplete="off"></v-text-field>  
                      </v-list-item-content>              
                   </v-list-item>              
                   
                  </v-list>
                   </v-layout>
                  <v-layout class="justify-center grey lighten-3">
                  <v-card width ="80%" outlined flat>
                    
                    <v-data-table
                      :items-per-page="itemsPerPage"
                      :headers="LookupArrayHeaders"
                      :items="LookupArraySearched"
                      class="elevation-1"
                      show-select
                        single-select
                        v-model="SelectedRecords"
                    >
                      <template v-slot:top>
                    <v-toolbar  flat rounded src="@/assets/RABaseBG.jpeg" elevation="12" color="primary white--text">
                        <v-toolbar-title  class="mediumoverline">{{FieldFilter}} Records</v-toolbar-title>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>
                      <v-text-field dark
                        v-model="search"
                        append-icon="mdi-file-find-outline"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      </v-toolbar>
                      </template> 
                      <template v-slot:[`item.selected`]="{ item }">
                      <v-checkbox
                        v-model="item.selected"
                        label="Select"
                      >
                      </v-checkbox>
                      
                    </template>
                    </v-data-table>         
                    </v-card>
                </v-layout>
                <br>
                <v-layout class="justify-end mx-3 my-3">
                  <v-btn color="pop" dark @click="CancelSelection()">Cancel <v-icon>mdi-cancel</v-icon></v-btn><v-btn color="green" dark @click="LinkParent()">OK <v-icon>mdi-check</v-icon></v-btn>
                </v-layout>
        </v-card>
      </v-dialog>
    </v-content>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import UserLookup from '@/components/General/UserLookup'
export default {
    props: ['ParentCollection','ParentTitle','ParentLookup','LookupArrayHeaders','LookupArray','FieldFilter','ViewExistingLookupDialog',
    'RelatedObj','AddNewParentLookupDialog','NewParentPrimary','NewParentAdditional','System','SystemEntities'],
    components: {UserLookup},
    data() {
        return {
          StaticAddNewParentLookupDialog: false,
          rules: {
          numberfield: value => {
            const pattern = /^[0-9]+$/im;
            return pattern.test(value) || "Not a valid number.";
          },
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
          itemsPerPage: 5,
            RelatedRecordFilter: '',
            
            SelectedRecords: [],
            search: '',
        }
    },
    watch: {
      AddNewParentLookupDialog:{
         handler: function(oldvalue, newvalue) {
           alert(this.AddNewParentLookupDialog)
          this.StaticAddNewParentLookupDialog = this.AddNewParentLookupDialog
          },
          deep: true
      },
    },
    created(){
      this.StaticAddNewParentLookupDialog = this.AddNewParentLookupDialog
    },

    computed: {
      userIsAdmin () {
        return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
        userBUID () {
        return this.$store.getters.userBUID
        },
        userBUChildren () {
        return this.$store.getters.userBUChildren
        },
        userBUParents () {
        return this.$store.getters.userBUParents
        },
      ParentTablecollectionRef(){
        return this.ParentEntity ? db.collection(this.ParentEntity.id.split('_').join('').toLowerCase()) : ''
      },
      ParentEntity(){
        return this.NewParentPrimary && this.SystemEntities.find(obj => obj.id === this.NewParentPrimary.RelatedBuildID) ? 
         this.SystemEntities.find(obj => obj.id === this.NewParentPrimary.RelatedBuildID) : ''
      },
      DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
        LookupArraySearched(){
            return this.LookupArray.filter(item => {
                if(this.search){
                    console.log(this.search,this.ParentLookup.Prop,item[this.ParentLookup.Prop])
                    return item[this.ParentLookup.Prop].toLowerCase().includes(this.search.toLowerCase())
                }
                else{
                    return item
                }
            })
        }
    },

    methods: {
      FieldIsReadOnly(field){
        return field.ReadOnly || field.RelatedReadOnly || field.MappedField && this.userLoggedIn || field.MappedField && !this.userLoggedIn && !field.MappedField.LoggedInUserValue
      },
        LinkParent(){
            let field = this.ParentLookup
            field.Value = this.SelectedRecords[0]
            console.log(this.SelectedRecords[0])
            let ParentObj = {                
            }
            ParentObj[field.LookupFieldName] = {[field.Prop]: field.Value[field.Prop],id: field.Value.id}
            ParentObj[field.Propid] = field.Value.id
            this.$emit('LinkParent',ParentObj)
            this.CancelSelection()
        },
        CancelSelection(){
            this.$emit('CancelSelection')
        },
        CancelAddParentDialog(){
            this.$emit('CancelAddParentDialog')
        },
        GetParentRecordid(NewDataObj){
          let vm = this
          //console.log(vm.ParentEntity,this.NewParentPrimary,this.SystemEntities,vm.ParentTablecollectionRef)
          let ref = vm.ParentTablecollectionRef.doc()
          let newdocid = ''
          if(this.ParentEntity && this.ParentEntity.Entity_Type && this.ParentEntity.Entity_Type.Name === 'Plugins'){
              newdocid = NewDataObj.Name.split(' ').join('_').split('/').join('')
            }
            else if(this.ParentEntity && this.ParentEntity.Entity_Type && this.ParentEntity.Entity_Type.Name === 'Inventory Article'
            ){
              newdocid = NewDataObj.Product_Code.split(' ').join('_').split('/').join('')
            }
            else if(this.ParentEntity && this.ParentEntity.Entity_Type && this.ParentEntity.Entity_Type.Name === 'Store Order'){
              const functions = firebase.functions();
              
              const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
              let payload = {
                Docid: this.ParentEntity.id+'_SO_Number',
                Data: {},
                siteid: this.$store.state.ActiveSuiteid
              }
              AssignAutoNumber(payload).then(result => {
                newdocid = result.data
                NewDataObj.SO_Number = newdocid
                vm.FinalizeRecordLoading(newdocid,NewDataObj)
              })
            }
            else if(this.ParentEntity && this.ParentEntity.Entity_Type && this.ParentEntity.Entity_Type.Name === 'Store Supplier'){
              const functions = firebase.functions();
              
              const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
              let payload = {
                Docid: 'Store_Suppliers_Supplier_Code',
                Data: NewDataObj,
                siteid: this.$store.state.ActiveSuiteid
              }
              AssignAutoNumber(payload).then(result => {
                newdocid = result.data
                NewDataObj.Supplier_Code = newdocid
                vm.FinalizeRecordLoading(newdocid,NewDataObj)
              })
            }
            else if(this.ParentEntity && this.ParentEntity.Entity_Type && this.ParentEntity.Entity_Type.Name === 'Billing Account'){
              const functions = firebase.functions();
              
              const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
              let payload = {
                Docid: 'BillingAccounts_Customer_ID',
                Data: NewDataObj,
                siteid: this.$store.state.ActiveSuiteid
              }
              AssignAutoNumber(payload).then(result => {
                newdocid = result.data
                NewDataObj.Customer_ID = newdocid
                vm.FinalizeRecordLoading(newdocid,NewDataObj)
              })
            }
            else{
              newdocid = ref.id
              //console.log(newdocid)
              vm.FinalizeRecordLoading(newdocid,NewDataObj)
            }
        },
        FinalizeRecordLoading(newdocid,ParentObj){
          let vm = this
          ParentObj.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
          ParentObj.Created_Byid = this.userLoggedIn.id
          ParentObj.Created_On = new Date()
          ParentObj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
          ParentObj.Modified_Byid = this.userLoggedIn.id
          ParentObj.Modified_On = new Date()  
          vm.ParentTablecollectionRef.doc(newdocid).set(ParentObj).then(doc => {
              console.log(newdocid)
              if(vm.RelatedObj.id){
                console.log('dude says he has id')
                db.collection(vm.RelatedObj.collection).doc(vm.RelatedObj.id).update({
                  [vm.ParentLookup.Prop]: {[vm.NewParentPrimary.LookupFieldName]: ParentObj[vm.NewParentPrimary.LookupFieldName],id: newdocid},
                  [vm.ParentLookup.Propid]: newdocid,
                }).then(updateddoc => {
                  vm.CancelAddParentDialog()
                }) 
              }
              else{
                //console.log(newdocid)
                vm.$emit('UpdateNewChild',{
                  [vm.ParentLookup.Prop]: {[vm.NewParentPrimary.LookupFieldName]: ParentObj[vm.NewParentPrimary.LookupFieldName],id: newdocid},
                  [vm.ParentLookup.Propid]: newdocid,
                })
                vm.CancelAddParentDialog()
              }              
            })
        },
        AddParentandLink(){
        let vm = this
        let ParentObj = {[vm.NewParentPrimary.LookupFieldName] : vm.NewParentPrimary.Value,SearchQuery: [] }
        let promises = []
        let primarysearchpayload = {NewDataObj: ParentObj,header: vm.NewParentPrimary}
        //console.log(primarysearchpayload)
        promises.push(vm.$store.dispatch('AssignQueryString',primarysearchpayload).then((res) => {
          console.log(res)
          return res
      }))
      // vm.$store.dispatch('AssignQueryString',primarysearchpayload).then((res) => {
      //     console.log(res)
      //     return res
      // })
        //ParentObj[vm.NewParentPrimary.LookupFieldName+'Query'] = vm.$store.dispatch('AssignQueryString',vm.NewParentPrimary)
        vm.NewParentAdditional.map(field => {
         
          if(field.Type !== 'Lookup'){
          ParentObj[field.Prop] = field.Value
          }
          if(field.Type === 'Lookup'){
          ParentObj[field.Name] = {[field.LookupFieldName]: field.Value[field.LookupFieldName],id: field.Value.id}
          ParentObj[field.Name+'id'] = field.Value.id
          }
           if(field.IsSearch){
            let fieldsearchpayload = {NewDataObj: ParentObj,header: field}
            console.log(fieldsearchpayload)
            promises.push(vm.$store.dispatch('AssignQueryString',fieldsearchpayload).then((res) => {
              console.log(res)
          return res
      }))
          }
        })
        //console.log(vm.DefaultStatusField)
        if(!ParentObj.Status){
          ParentObj.Status = vm.DefaultStatusField.DefaultOption
          ParentObj.Status_Reason = vm.DefaultStatusField.DefaultLevel2Option
        }
        else if(!ParentObj.Status_Reason){
          ParentObj.Status = vm.DefaultStatusField.DefaultOption
          ParentObj.Status_Reason = vm.DefaultStatusField.DefaultLevel2Option
        }
        //console.log(ParentObj)
       // console.log('will save on this',vm.RelatedObj,promises)
         return Promise.all(promises).then(result => {
          //console.log('result '+result)
          let length = result.length
          let finalindex = length-1
          result.map((res,i) => {
            //console.log(res)
            //ParentObj.SearchQuery = ParentObj.SearchQuery.concat(res.SearchQuery)
            if(i === finalindex){
              console.log(ParentObj)
              vm.GetParentRecordid(ParentObj)
              
            }
          })
          
        })
      },
    }
}
</script>
<style>
.stickytopbanner{
  position: fixed;
  top: 70px;
  z-index: 100
}
</style>

<style scoped>
.v-data-table{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#757575;
}
</style>

    