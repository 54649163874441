<template>
<div v-if="userLoggedIn">
  <v-menu
                      bottom
                      left
                      offset-y
                      :close-on-content-click="false"
                      >
                      <template v-slot:activator="{ on }">
                      <v-avatar v-on="on" size="40">
                        <img v-if="userLoggedIn.Profile_Photo "
                          :src="userLoggedIn.Profile_Photo " 
                          style="object-fit: cover;"             
                        >
                        <img v-if="!userLoggedIn.Profile_Photo "
                          src="@/assets/BlankProfilePic.png"     
                          style="object-fit: cover;"         
                        >
                      </v-avatar>
                      </template>
                      <v-list max-width="300" >
                      <v-list-item>
                        <!-- <v-list-item-content > -->
                        <v-list-item-avatar class="blue" tile size="80">
                          <img v-if="userLoggedIn.Profile_Photo "
                          :src="userLoggedIn.Profile_Photo "    
                          style="object-fit: cover;"          
                          >
                          <img v-if="!userLoggedIn.Profile_Photo "
                            src="@/assets/BlankProfilePic.png"   
                            style="object-fit: cover;"           
                          >                      
                          </v-list-item-avatar>
                        <!-- </v-list-item-content> -->
                        <v-list-item-content >
                          <v-list-item-title>{{userLoggedIn.Full_Name}}</v-list-item-title>                    
                          <v-list-item-subtitle>
                            <!-- My Account is now perfect for all in fact -->
                            <router-link  :to="UserisGuest ? '/MyAccount' : '/MyAccount'">View My {{UserisGuest ? 'Account' : 'Account'}}</router-link>
                          </v-list-item-subtitle>
                          <!-- <v-list-item-subtitle v-if="FeaturedMembersAllowed">
                            <router-link  :to="FeaturedMemberDefaultPage ? '/FeaturedMember/'+userLoggedIn.id+'/'+FeaturedMemberDefaultPage : '/FeaturedMemberCreator/'+userLoggedIn.id">My Featured Profile</router-link>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="MarketTemplatesAllowed">
                            <router-link  :to="'/MyMarketplaceTemplates'">My Market Templates</router-link>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="SuiteTemplatesAllowed">
                            <router-link  :to="'/MySuiteTemplates'">My Suite Templates</router-link>
                          </v-list-item-subtitle> -->
                          <v-list-item-subtitle v-if="userIsAdmin">
                            <router-link  :to="'/Getting-Started'">Suite Admin</router-link>
                          </v-list-item-subtitle>                          
                          <v-list-item-subtitle>
                            {{userLoggedIn.Email}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Online: Online Now
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="ToggleAppStylingView()" v-if="userIsAdmin && !HideAppStyling">
                         <v-list-item-avatar>
                        <v-icon>mdi-palette-advanced</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            Styling 
                          </v-list-item-content>
                      </v-list-item>

                        <v-list-item @click="ActivateTicketLog()">
                        <v-list-item-avatar>
                        <v-icon>mdi-ticket-confirmation</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            Log Ticket 
                          </v-list-item-content>
                        </v-list-item>
                         <v-dialog max-width="400" v-model="NewTaskDialog">
                          <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                          <v-list-item-avatar>
                          <v-icon>mdi-clipboard-list</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                              Create Activity
                            </v-list-item-content>
                          </v-list-item>
                          </template>
                        <v-card>
                          <v-card-title>
                            Create Activity
                          </v-card-title>
                          <v-card-text>
                            <v-text-field label="Subject" v-model="NewTask.Subject" />
                             <v-card-title class="justify-start">
                                Description: 
                              </v-card-title>
                              <v-card-subtitle class="caption">
                                Write the value using the editor below, and remember to click on the "save" to capture the value. 
                              </v-card-subtitle>
                              <v-card-text>
                                <v-layout row class="justify-center" >
                              <v-col cols="12" >
                            <ContentEditableField style="padding:15px;"
                            :FieldObject="NewTask" :FieldName="'Description'"
                            :FieldValue="NewTask.Description" @UpdateContentEditableField="UpdateTaskDescription" :AdditionalSaveMethod="''" />
                              </v-col>
                                </v-layout>
                              </v-card-text>
                            <!-- <v-textarea label="Description" v-model="NewTask.Description" /> -->
                           <v-menu
                                        v-model="NewTaskDueDatemenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                       <template v-slot:activator="{ on }">
                                         <v-text-field
                                            v-model="NewTask.Due_Date"
                                            label="Due Date"
                                            prepend-icon="mdi-calendar-month"
                                            readonly
                                            v-on="on"
                                          ></v-text-field>
                                       </template>
                                       <v-date-picker :allowed-dates="disablePastDates" v-model="NewTask.Due_Date" @input="NewTaskDueDatemenu = false"></v-date-picker>
                                     </v-menu>
                            <v-switch label="Set Reminder" v-model="NewTask.Has_Reminder" />
                            <v-menu
                                        v-model="NewTaskSnoozed_UntilDatemenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                       <template v-slot:activator="{ on }">
                                         <v-text-field v-if="NewTask.Has_Reminder"
                                            v-model="NewTask.Snoozed_Until"
                                            label="Reminder Date"
                                            prepend-icon="mdi-calendar-month"
                                            readonly
                                            v-on="on"
                                          ></v-text-field>
                                       </template>
                                       <v-date-picker :allowed-dates="disablePastDates" :max="NewTask.Due_Date" v-model="NewTask.Snoozed_Until" @input="NewTaskSnoozed_UntilDatemenu = false"></v-date-picker>
                                     </v-menu>
                            <v-text-field label="QuickLink (Optional)" :rules="[$store.state.formrules.url]" v-model="NewTask.QuickLink" />
                            <v-select item-text="Name" label="Type" v-model="NewTask.Type" :items="ActivityTypes" return-object />
                             <v-select label="Owner" item-text="Full_Name" v-model="NewTask.Owner" :items="UsersStore" return-object />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="CloseTaskDialog()">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="CreateNewTask()">Save</v-btn>
                          </v-card-actions>
                        </v-card>
                  </v-dialog>
                        <v-list-item @click="ConfirmlogoutFromFirebase">
                        <v-list-item-avatar>
                        <v-icon>mdi-exit-to-app</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            Sign Out 
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-dialog v-model="TicketLog" max-width="600px">
                    <v-card width="100%">
                        <SystemTicketsLogging :SystemEntities="SystemEntities" :db="ClientDB"
                        @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="userLoggedIn" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
                    </v-card>
                    </v-dialog>
</div>
</template>

<script>
import db from '@/main'
import ContentEditableField from '@/components/Database/ContentEditableField'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'

export default {
 props: ['AppisDarkMode','CurrentRecordName','FriendsList','Notifications','System','SitePages','SystemEntities','ActiveSearch',
  'AppStylingView','GraphicalElements','ActivityStatusField','SocialQuickNavPush','SystemPhotoLibraries','ClientDB','HideAppStyling'],
  components: {ContentEditableField,SystemTicketsLogging},
  data() {
        return {
            UserRecord: '',
            TicketLog: false,
            UsersArray: [],
            rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
            ActivityTypes: [
        {
          ID: 1000001,
          Name: 'Task'
        },
        {
          ID: 1000002,
          Name: 'Appointment'
        },
      ],
      NewTaskDialog: false,
      NewTaskDueDatemenu: false,
      NewTask: {
        Subject: '',
        Description: '',
        Type: '',
        Has_Reminder: true,
        Owner: '',
        Due_Date: '',
      },
      DefaultNewTask: {
        Subject: '',
        Description: '',
        Type: '',
        Has_Reminder: true,
        Owner: '',
        Due_Date: '',
      },
        }
    },	
    computed:{
      UserisGuest(){
        return this.$store.state.UserisGuest && this.userLoggedIn         
      },
        FeaturedMemberDefaultPage(){
          return this.$store.state.FeaturedMemberDefaultPage
        },
        FeaturedMemberActive(){
            return this.PluginDataBase.Featured_Members && this.PluginDataBase.Featured_Members.Active
        },
        FeaturedMembersAllowed(){
            return this.FeaturedMemberActive && this.userLoggedIn.IsFeaturedMember || this.FeaturedMemberActive && this.userIsAdmin
            // && this.userIsAdmin || this.FeaturedMemberActive && this.System && this.System.Featured_Members_Self_Manage
        },
        MarketTemplatesActive(){
          return this.PluginDataBase.Marketplace_Templates && this.PluginDataBase.Marketplace_Templates.Active
        },
        MarketTemplatesAllowed(){
          //console.log('this.userLoggedIn',this.userLoggedIn)
          return this.MarketTemplatesActive && this.userLoggedIn.IsWebDeveloper || this.MarketTemplatesActive && this.userIsAdmin
        },
        WebAppBuilderActive(){
          return this.PluginDataBase.Web_App_Builder && this.PluginDataBase.Web_App_Builder.Active
        },
        SuiteTemplatesAllowed(){
          //console.log('this.userLoggedIn',this.userLoggedIn)
          return this.WebAppBuilderActive && this.userLoggedIn.IsSiteCreator 
          || this.WebAppBuilderActive && this.userIsAdmin
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(this.UserRecord.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.Notifications = []
      this.UserRecord = {}
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
        disablePastDates(val) {
       return val >= new Date().toISOString().substr(0, 10)
    },
    CloseTaskDialog(){
      this.NewTask = Object.assign({}, this.DefaultNewTask)
      this.NewTaskDialog = false
    },
    UpdateTaskDescription(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(prop,value,AdditionalSaveMethod,FieldObject)     
    },
    CreateNewTask(){
      const TasktoAdd = {
            Comments: [],
            Subject: this.NewTask.Subject,
            Description: this.NewTask.Description,
            Type: this.NewTask.Type,
            Due_Date: new Date(this.NewTask.Due_Date),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Created_On: new Date(),
            Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Modified_Byid: this.userLoggedIn.id,
            Modified_On: new Date(),
            Status: this.ActivityStatusField.DefaultOption,
            Status_Reason: this.ActivityStatusField.DefaultLevel2Option,
            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}]
      }
      let FollowingUserIDStrings = []
      let FollowingUserIDKeys = {}
       TasktoAdd.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       TasktoAdd.FollowingUserIDKeys = FollowingUserIDKeys
       TasktoAdd.FollowingUserIDStrings = FollowingUserIDStrings
      if(this.NewTask.QuickLink){
        TasktoAdd.QuickLink = this.NewTask.QuickLink
      }
      if(this.NewTask.Has_Reminder){
        TasktoAdd.Has_Reminder = this.NewTask.Has_Reminder
      }
      if(this.NewTask.Snoozed_Until){
        TasktoAdd.Snoozed_At = new Date()
        TasktoAdd.Snoozed_Until = new Date(this.NewTask.Snoozed_Until)
      }
      if(typeof this.NewTask.Owner.Business_Unit !== 'undefined'){
        TasktoAdd.Business_Unitid = this.NewTask.Owner.Business_Unit.id
        TasktoAdd.Business_Unit = {Name: this.NewTask.Owner.Business_Unit.Name, id: this.NewTask.Owner.Business_Unit.id}
      }
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.Type === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Subject,
              Content: TasktoAdd.Description,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/'+doc.id,
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname
            }
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').add(NewNotification).then(notedoc => {
            })
             }


          this.CloseTaskDialog()
          })
    },
        ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
        CancelSubmitSystemTicket(){
      this.TicketLog = false
    },
      ActivateTicketLog(){
        this.UsersArray = [this.userLoggedIn]
        this.TicketLog = true
      },

    }
}
</script>

<style>

</style>
