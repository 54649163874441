<template>
  <div>
      <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/BnPFullLogo.png"/>
        </v-avatar>
      Libraries for your Suite
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle v-if="!ActiveLibTypeComp" class="mediumoverline">
          Types
        </v-card-subtitle>         
      <v-card-text v-if="!ActiveLibTypeComp">
         <v-list>
              <v-list-item @click="ActivateLibTypComponent(libtype)" v-for="libtype in LibraryTypes" :key="libtype.itemObjKey">
                  <v-list-item-avatar v-if="!MiniView" :size="MiniView ? 25 : 50" tile :style="MiniView ? '' : 'align-self: center;padding-right: 10px;'">
                    <v-icon :size="40">{{libtype.Icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-action>
                    <v-list-item-title style="align-self: start;" class="smallbanneroverline">
                      <v-icon v-if="MiniView" :size="20">{{libtype.Icon}}</v-icon>
                      {{libtype.Name}}
                    </v-list-item-title>
                    </v-list-item-action>
              </v-list-item>
          </v-list>
      </v-card-text>
      <v-card-text v-if="ActiveLibTypeComp">
        <component :is="ActiveLibTypeComponent" :System="System" :SystemEntities="SystemEntities" @GoBack="GoBack"
        :Directories="Directories" :DocumentationLibraries="DocumentationLibraries"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
        />
      </v-card-text>
       </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','SystemPhotoLibraries','SystemDocumentLibraries','ActiveTemplate','DocumentationLibraries','Directories'],
    components: {},
    data() {
        return {
            LibraryTypes: [
                {
                Icon: 'mdi-folder-image', Name: 'Photo Libraries', ConfigComponent: 'components/SuitePlugins/PhotoLibrary/PhotoLibrariesConfig'
                },
                {
                Icon: 'mdi-library-shelves', Name: 'Document Libraries', ConfigComponent: 'components/SuitePlugins/DocumentLibrary/DocumentLibrariesConfig'
                },
                 {
                Icon: 'mdi-view-list', Name: 'Directories', ConfigComponent: 'components/Library/DirectoryLibrariesConfig'
                },
                 {
                Icon: 'mdi-book-education', Name: 'Documentation', ConfigComponent: 'components/Library/DocumentationLibrariesConfig'
                }
                
            ],
            ActiveLibTypeComp: ''
        }
    },	
    computed:{
        ActiveLibTypeComponent(){
          if(this.ActiveLibTypeComp){
                  return () => import(`@/`+this.ActiveLibTypeComp+`.vue`);	 
              }
        },  
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      
        System: {
            handler: function(newvalue, oldvalue) {
             //console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue && newvalue && newvalue.id && !this.ActiveTemplate){
                     this.SystemInstance = this.System
                }
            },deep: true
        },
    },
    created(){
      
    },
    methods:{
      GoBack(){
        this.ActiveLibTypeComp = ''
      },
      ActivateLibTypComponent(libtype){
        this.ActiveLibTypeComp = libtype.ConfigComponent
      },
    }
}
</script>

<style>

</style>



