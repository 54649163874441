  <template>


  <div class="activities">
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new Activity.</span>
      <v-btn color="white" flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>

    <div class="hidden-sm-and-down">
        <h1 class="Header">Activities</h1>
    </div>



<v-tabs>
    <v-tab>Emails</v-tab>
    <v-tab-item>
        <v-data-table
    :headers="headers"
    :items="ActivitiesFilteredandSearched"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My Activities</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field light
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Activity</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>




                    <v-textarea v-model="editedItem.description" label="Description"></v-textarea>







                      <v-combobox
                        v-model="editedItem.activitytype"

                        :items="ActivityTypeOptions"
                        hide-selected
                        label="Activity Type Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>






                      <v-combobox
                        v-model="editedItem.regardingtype"

                        :items="RegardingTypeOptions"
                        hide-selected
                        label="Regarding Type Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>





                      <v-menu
                        v-model="CreatedOnmenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.createdon"
                            label="Created On"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.createdon" @input="CreatedOnmenu = false"></v-date-picker>
                      </v-menu>






                      <v-menu
                        v-model="DueDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.duedate"
                            label="Due Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.duedate" @input="DueDatemenu = false"></v-date-picker>
                      </v-menu>




                  </v-col>
                </v-row>
              </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="importdialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Import Dialog</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="Import.Name" label="Name"></v-text-field>
                    <input v-if="!ImportFile"
                              type="file"
                              @change="onImportfileSelected($event)"
                              ref="ImportFileinputter"
                              id="fileUpload"
                              >
                    <v-btn @click="DownloadContactsImportTemplate()">Download Template</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

<v-toolbar v-if="ActivitiesArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="ActivityTypeOptions"
          v-model="ActivityTypefilter"
          item-text="activitytype"
          label=""
          autowidth
          return-object
          chips
          flat
          />
          </div>
          <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="RegardingTypeOptions"
          v-model="RegardingTypefilter"
          item-text="regardingtype"
          label="Regarding"
          autowidth
          return-object
          chips
          flat
          />
          </div>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-icon
        small
        @click="ViewItem(item)"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
    </v-tab-item>
    <v-tab>Tasks</v-tab>
    <v-tab-item>
        <v-date-picker
        v-model="TaskFilterDate"
        full-width
        landscape
        class="mt-4"
        ref="picker"
        :picker-date.sync="pickerDate"
        ></v-date-picker>
            <v-col cols="12" sm="6" class="my-2 px-1">
            <div class="title">Month news ({{ pickerDate || 'change month...' }})</div>
            <div class="subheading">The tasks for this month (change month to see other tasks)</div>
            <ul class="ma-4">
                <li v-for="task in TasksFilteredandSearched" :key="task.id">{{ task.name }}</li>
                <strike><li v-for="task in TasksFilteredandSearched" :key="task.id">{{ task.name }}</li></strike>
            </ul>
            </v-col>
        <v-list v-if="TasksFilteredandSearchedTable.length > 0">
            <v-list-item v-for="task in TasksFilteredandSearchedTable" :key="task.id">
                <v-list-item-content>
                    {{task.name}}
                </v-list-item-content>
                <v-list-item-content>
                    <v-icon @click="ViewItem(task)">mdi-eye</v-icon>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-tab-item>
    <v-tab>Appointments</v-tab>
    <v-tab-item>
        <v-data-table
    :headers="headers"
    :items="ActivitiesFilteredandSearched"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My Activities</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field light
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Activity</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>




                    <v-textarea v-model="editedItem.description" label="Description"></v-textarea>







                      <v-combobox
                        v-model="editedItem.activitytype"

                        :items="ActivityTypeOptions"
                        hide-selected
                        label="Activity Type Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>






                      <v-combobox
                        v-model="editedItem.regardingtype"

                        :items="RegardingTypeOptions"
                        hide-selected
                        label="Regarding Type Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>





                      <v-menu
                        v-model="CreatedOnmenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.createdon"
                            label="Created On"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.createdon" @input="CreatedOnmenu = false"></v-date-picker>
                      </v-menu>






                      <v-menu
                        v-model="DueDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.duedate"
                            label="Due Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.duedate" @input="DueDatemenu = false"></v-date-picker>
                      </v-menu>




                  </v-col>
                </v-row>
              </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="importdialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Import Dialog</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="Import.Name" label="Name"></v-text-field>
                    <input v-if="!ImportFile"
                              type="file"
                              @change="onImportfileSelected($event)"
                              ref="ImportFileinputter"
                              id="fileUpload"
                              >
                    <v-btn @click="DownloadContactsImportTemplate()">Download Template</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

<v-toolbar v-if="ActivitiesArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="ActivityTypeOptions"
          v-model="ActivityTypefilter"
          item-text="activitytype"
          label=""
          autowidth
          return-object
          chips
          flat
          />
          </div>
          <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="RegardingTypeOptions"
          v-model="RegardingTypefilter"
          item-text="regardingtype"
          label="Regarding"
          autowidth
          return-object
          chips
          flat
          />
          </div>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-icon
        small
        @click="ViewItem(item)"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
    </v-tab-item>
    <v-tab>Phone Calls</v-tab>
    <v-tab-item>
        <v-data-table
    :headers="headers"
    :items="ActivitiesFilteredandSearched"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My Activities</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field light
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Activity</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.name" label="Name"></v-text-field>




                    <v-textarea v-model="editedItem.description" label="Description"></v-textarea>







                      <v-combobox
                        v-model="editedItem.activitytype"

                        :items="ActivityTypeOptions"
                        hide-selected
                        label="Activity Type Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>






                      <v-combobox
                        v-model="editedItem.regardingtype"

                        :items="RegardingTypeOptions"
                        hide-selected
                        label="Regarding Type Options"
                        small-chips
                        solo
                        flat
                      ></v-combobox>





                      <v-menu
                        v-model="CreatedOnmenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.createdon"
                            label="Created On"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.createdon" @input="CreatedOnmenu = false"></v-date-picker>
                      </v-menu>






                      <v-menu
                        v-model="DueDatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="editedItem.duedate"
                            label="Due Date"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.duedate" @input="DueDatemenu = false"></v-date-picker>
                      </v-menu>




                  </v-col>
                </v-row>
              </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="importdialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Import Dialog</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="Import.Name" label="Name"></v-text-field>
                    <input v-if="!ImportFile"
                              type="file"
                              @change="onImportfileSelected($event)"
                              ref="ImportFileinputter"
                              id="fileUpload"
                              >
                    <v-btn @click="DownloadContactsImportTemplate()">Download Template</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeImport()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="saveImport()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

<v-toolbar v-if="ActivitiesArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
        <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="ActivityTypeOptions"
          v-model="ActivityTypefilter"
          item-text="activitytype"
          label=""
          autowidth
          return-object
          chips
          flat
          />
          </div>
          <div width="100px"><br>
          <v-autocomplete class="mx-5"
          :items="RegardingTypeOptions"
          v-model="RegardingTypefilter"
          item-text="regardingtype"
          label="Regarding"
          autowidth
          return-object
          chips
          flat
          />
          </div>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-icon
        small
        @click="ViewItem(item)"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
    </v-tab-item>
</v-tabs>





<div class="hidden-md-and-up">

<v-data-table
      :headers="headers"
      :items="ActivitiesFilteredandSearched"
      :search="search"
      class="mobile" dark>

      <template v-slot:items="props" pagination.sync {rowsPerPage: -1}>
                  <tr class="hidden-md-and-up">
                  <td>
                    <ul class="flex-content">
                                          <v-flex xs6 sm6>
                      <v-btn flat :to="'/activity/' + props.item.id"><v-icon >mdi-eye</v-icon></v-btn>
                      </v-flex>
<v-icon flat @click="editActivityItem(props.item)">mdi-pencil</v-icon>
                    </ul>
                  </td>
</tr>

      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template> -->
    </v-data-table>
    </div>


  </div>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'


export default {
  data() {
    return {
      TaskFilterDate: '',
      pickerDate: null,
      CreatedOnmenu: false,
      DueDatemenu: false,

      ActivityTypeOptions: ['Email','Phone Call','Task','Appointment'],
      ActivityTypefilter: '',
      RegardingTypeOptions: [
  "BusinessUnit",
  "User"
],
      RegardingTypefilter: '',

      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      headers: [
      {
          text: 'id',
          align: 'left',
          sortable: false,
          value: 'id'
        },
        { text: 'Name', value: 'name'},


        { text: 'Description', value: 'description'},


        { text: 'Activity Type', value: 'activitytype'},


        { text: 'Regarding Type', value: 'regardingtype'},


        { text: 'Created On', value: 'createdon'},


        { text: 'Due Date', value: 'duedate'},


        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      importdialog: false,
      importsnackbar: false,
      ImportHeaders: [],
      NewImportsArray: [],
      PartialFailuresArray: [],
      SuccessfulImportsArray: [],
      CSVResult: [],
      Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Activities', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
      ImportFile: '',
      EditedActivitysnackbar: false,
      ActivityEditdialog: false,
      newActivitydialog: false,
      ActivitiesArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
          name: '',
          subject: '',
          to: '',
          from: '',
          description: '',
          activitytype: '',
          regardingtype: '',
          createdon: '',
          duedate: '',
      },
      editedItem: {
        id: '',
          name: '',
          subject: '',
          to: '',
          from: '',
          description: '',
          activitytype: '',
          regardingtype: '',
          createdon: '',
          duedate: '',
      },
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: [],
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      pickerDate: null,
    }
  },

  created(){

    this.GetActivities()
    this.GetRequestingUser()
    // this.GetBusinessUnits()

  },
  computed: {

    formTitle () {
        return this.editedIndex === -1 ? 'New Activity' : 'Edit Activity'
      },
    ActivitiesSearched() {
      return this.ActivitiesArray.filter(Activity => {
        if(Activity.name){return Activity.name.toLowerCase().includes(this.search.toLowerCase())}}).filter(Activity => {if(Activity.description){return Activity.description.toLowerCase().includes(this.search.toLowerCase())}
      })
      },
      ActivitiesFilteredandSearched() {
      return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
      },
      TasksFilteredandSearched(){
          return this.ActivitiesFilteredandSearched.filter(activity => {
              return activity.activitytype === 'Task' && activity.duedate.includes(this.pickerDate)
          })
      },
      TasksFilteredandSearchedTable(){
          return this.TasksFilteredandSearched.filter(activity => {
              return activity.duedate === this.TaskFilterDate
          })
      },

      HeaderNames() {
        return this.headers.map(header => {
          return header.text
        }).filter(header => {
          return header !== 'id'
        })
      },
      HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
         let correctheader = header.split(' ').join('').toLowerCase()
          return correctheader
        })
      }
    },
  methods: {

    closeImport(){
      this.importdialog = false
      this.ImportFile = null
      this.Import.Name = ''
    },

    saveImport() {
      let importLocalURL = URL.createObjectURL(this.ImportFile)
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this
      reader.addEventListener('load', function(e) {
            var text = e.target.result;
            vm.CSVConvertToJSON(text)
      });
    },


    CSVConvertToJSON(text){
      alert('another thing')
      this.NewImportsArray = []
      this.PartialFailuresArray = []
      let lines = text.split("\n")
      // let dataonly = lines
      // dataonly.splice(0,1)
      // console.log(dataonly)
      this.CSVResult = []
      this.ImportHeaders=lines[0].split(",");
      for(var i=1;i-1+2<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(",");

      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.fullname == obj.fullname)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)


    }
      this.CSVResult.map((res,i) => {
        // console.log(res)
        this.UpdateFiresStore(res,i)
      })
    },

    UpdateFiresStore(SingleResult, Index){
      console.log("Index")
      console.log(Index)
      let ResultLength = this.CSVResult.length
      console.log("ResultLength")
      console.log(ResultLength)
      console.log("Index-1+2")
      console.log(Index-1+2)
      let OriginalImportObject = Object.assign({},SingleResult)
      let ImportActivity = {
        id: '',
        name: SingleResult.name,
        description: SingleResult.description,
        createdon: SingleResult.createdon,
        duedate: SingleResult.duedate,
        }

        let FailureObject = Object.assign({},ImportActivity)
        FailureObject.Failures = []
        if(SingleResult.activitytype !== ''){
          let Options = [
  "Email",
  "Phone Call",
  "Task",
  "Appointment"
]
          let GetObj = Options.find(option => option == SingleResult.activitytype)
          if(typeof GetObj === 'undefined'){
            FailureObject.activitytype = 'Failed - No Matching Option Found'
            ImportActivity.hasFailures = true
            let FailureInstance = {Type: 'No Matching Option', Name: 'activitytype'}
            FailureObject.Failures.push(FailureInstance)
          }
          else{
          let ActivityTypeObject = {id: GetObj.id,companyname: GetObj.companyname}
          ImportActivity.activitytype = SingleResult.activitytype
          }

        }
        if(SingleResult.regardingtype !== ''){
          let Options = [
  "BusinessUnit",
  "User"
]
          let GetObj = Options.find(option => option == SingleResult.regardingtype)
          if(typeof GetObj === 'undefined'){
            FailureObject.regardingtype = 'Failed - No Matching Option Found'
            ImportActivity.hasFailures = true
            let FailureInstance = {Type: 'No Matching Option', Name: 'regardingtype'}
            FailureObject.Failures.push(FailureInstance)
          }
          else{
          let RegardingTypeObject = {id: GetObj.id,companyname: GetObj.companyname}
          ImportActivity.regardingtype = SingleResult.regardingtype
          }

        }

        if(ImportActivity.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }

        this.NewImportsArray.push(OriginalImportObject)
        // db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(ImportActivity).then({
        // })
        db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(ImportActivity).then(doc => {
          let AllImportObj = this.NewImportsArray.find(imp => imp.name == ImportActivity.name)
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = doc.id
          console.log(this.NewImportsArray[AllImportObjIndex])
          console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp.name == ImportActivity.name)

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
          console.log(this.PartialFailuresArray[PartialImportObjIndex])
          console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.name == ImportActivity.name)
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
          console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          console.log(this.SuccessfulImportsArray)
          }
          if (Index-1+2 === ResultLength){
        console.log("NewImportsArray")
        console.log(this.NewImportsArray)
        console.log("PartialFailuresArray")
        console.log(this.PartialFailuresArray)

        let CreatedOn = format(new Date(),'yyyy-MM-dd')
        let User = this.UserRecord
        let RefinedHeaders = this.ImportHeaders.map(header => {
          let obj = {text: header, value: header}
          return obj
        })
        console.log("this.NewImportsArray at final length")
        console.log(this.NewImportsArray)
        let DataImport = {name: this.Import.Name, dataset: 'Activities', datasetrecordpath: 'Activity', createdon: CreatedOn, createdby: {id: User.id, fullname: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
        db.collection('dataimports').add(DataImport).then(doc => {
        var storageRef = firebase.storage().ref('DataImport/CreatedOn/'+this.ImportFile.name);
        var uploadTask = storageRef.put(this.ImportFile);
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            db.collection('dataimports').doc(doc.id).update({
                    csvfile: url
            })
            this.ImportFile = ''
                }),
                  this.importsnackbar = true
        })

        }
        })


    },

    onImportfileSelected(event){
      this.ImportFile = event.target.files[0]
    },

    DownloadContactsImportTemplate(){
      let copyText = this.HeaderNamesSplit

      var csvfilename = 'Activities.csv'
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
      element.setAttribute('download', csvfilename)
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      // }
    },
    ResetFilters() {
      this.ActivityTypefilter = ''
      this.RegardingTypefilter = ''
    },
    ResetSearch() {
      this.search = ''
    },

    GetActivities() {
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.UserRoles = userdetails.rolesarrayQuery
              vm.UserBusinessUnitID = userdetails.BusinessUnitid
              console.log(vm.UserBusinessUnitID)
              db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             //console.log(vm.UserRoles)
             vm.UserRoles.map(role => {
              let alltest = vm.AlllistRoles.find(obj => obj == role)
              let peertest = vm.UnitDownlistRoles.find(obj => obj == role)
              let parenttest = vm.UserUnitlistRoles.find(obj => obj == role)
              let childtest = vm.OwnerlistRoles.find(obj => obj == role)
              if(typeof alltest !== 'undefined'){
                vm.listAll = true
                console.log('Permissions for Activities is All')
              }
              else if(typeof peertest !== 'undefined'){
                vm.listUnitDown = true
                console.log('Permissions for Activities is UnitDown')
              }
              else if(typeof parenttest !== 'undefined'){
                vm.listUserUnit = true
                console.log('Permissions for Activities is UserUnit')
              }
              else if(typeof childtest !== 'undefined'){
                vm.listOwner = true
                console.log('Permissions for Activities is Owner')
              }
             })
             vm.GetActivities(vm.UserBusUnitChildren, vm.UserBusinessUnitID)
           })

            })


          }
      else {
          }
          })
      },

      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },

    GetActivities(busunitchildren, busunitid) {
      if(this.listAll === false && this.listUnitDown === false && this.listUserUnit === false && this.listOwner === false){
        //   this.RoutetoErrorPage()
        }
      var collectionReference = db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities')
      console.log(busunitchildren)
      if(this.listAll){
      var query = collectionReference;
      this.ActivitiesCall(query)
      }
      else if(this.listUnitDown){
      var query = collectionReference.where('BusinessUnitid', 'in', busunitchildren);
      this.ActivitiesCall(query)
      }
      else if(this.listUserUnit){
      var query = collectionReference.where('BusinessUnitid', '==', busunitid);
      this.ActivitiesCall(query)
      }
      else if(this.listOwner){
      var query = collectionReference.where('Ownerid', '==', this.UserRecord.id);
      this.ActivitiesCall(query)
      }

  },

  ActivitiesCall(query){
    query.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
  },





      ViewItem (item) {
       this.$router.push('/Activity/'+item.id)
      },

      deleteItem (item) {
        const index = this.ActivitiesArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(item.id).delete() && this.ActivitiesArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.ActivitiesArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },


      save () {
      const EditedActivity = {
    name: this.editedItem.name,
    description: this.editedItem.description,
    activitytype: this.editedItem.activitytype,
    regardingtype: this.editedItem.regardingtype,
    createdon: this.editedItem.createdon,
    duedate: this.editedItem.duedate,
        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').doc(this.mainrecordid).update({
        name: EditedActivity.name,

        description: EditedActivity.description,

        activitytype: EditedActivity.activitytype,

        regardingtype: EditedActivity.regardingtype,

        createdon: EditedActivity.createdon,

        duedate: EditedActivity.duedate,

              });
              Object.assign(this.ActivitiesArray[this.editedIndex], this.editedItem)
              this.closeActivityeditDialog
              this.EditedActivitysnackbar = true
        }
         else {
          const NewActivity = {
      name: EditedActivity.name,

      description: EditedActivity.description,

      activitytype: EditedActivity.activitytype,

      regardingtype: EditedActivity.regardingtype,

      createdon: EditedActivity.createdon,

      duedate: EditedActivity.duedate,

          }
          db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('activities').add(NewActivity).then({

          })
        }

        this.close()
      },

  }
}

</script>

  