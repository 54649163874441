
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
      <v-card :class="tab.BoxTransparency+' '+tab.CustomClass+' mx-3'"  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'" @click="OpenDialog(tab,i)">
           <DirectoryViewer v-if="CurrentEntity" :ProvidedAdditionalFilters="AdditionalFilters" @ActivateFeaturesListConfigDialog="ActivateFeaturesListConfigDialog" :Record="Record"
            :ProvidedNavList="ComputedNavList" :ProvidedCurrentEntity="CurrentEntity" @ActivateNewNavItemDialog="ActivateNewNavItemDialog" @SaveNavList="SaveNavList" @EditNavItem="EditNavItem"
            @EditNavItemSubItem="EditNavItemSubItem" @ActivateTreeNode="ActivateTreeNode" @ActivateViewportBuilder="ActivateViewportBuilder" :ProvidedFeatureList="CurrentEntity.FeatureList"
            />  
      </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DirectoryViewer from '@/components/Directories/DirectoryViewer';


export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','WikiEntities','Record'],
    components: {
      DirectoryViewer
        },
    data() {
    return {   
      WikiProperties: ['WikiFields','HideIMG','IMGField','HideDescription','DescriptionField','TaggedFields','ShowPrice','PriceField','TitleField','HideTitle','TagField','IsFiltered','FilterField','FilterbyCurrent'],
      NavList: [],
      // CurrentEntity: '',   
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      CurrentEntity(){
        let entity = this.WikiEntities.find(obj => obj.id === this.tab.Entityid)
        entity.CategoricalListType = 'Wiki Listing'
        entity.DisplayName = this.tab.Entityid.split('_').join(' ')
      
        if(!entity.FeatureList){
          entity.FeatureList = []
        }
        console.log(entity)
        entity.HideNavigation = this.tab.HideNavigation
        entity.BoxTransparency = this.tab.BoxTransparency
        entity.Elevation = this.tab.Elevation
        if(this.tab){
        this.WikiProperties.map(item => {
          if(typeof this.tab[item] !== 'undefined'){
            entity[item] = this.tab[item]
          }
        })
        // IsFiltered
        //FilterField
        //FilterbyCurrent">
      }
        return entity
      },
      ComputedNavList(){
            // let arr1 = this.NavList
            // let arr2 = this.AdditionalFilters
            // return this.SavingNavList ? arr1 : arr1.concat(arr2)
            return this.NavList
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
      // this.CurrentEntity = this.WikiEntities.find(obj => obj.id === this.tab.Entityid)
      // this.CurrentEntity.CategoricalListType = 'Wiki Listing'
      // this.CurrentEntity.DisplayName = this.tab.Entityid.split('_').join(' ')
    
      // if(!this.CurrentEntity.FeatureList){
      //   this.CurrentEntity.FeatureList = []
      // }
      // console.log(this.CurrentEntity)
      // this.CurrentEntity.HideNavigation = this.tab.HideNavigation
    },
    
    methods: {
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>

        

    