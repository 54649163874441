<template>
  <div>
        <!-- <v-layout  v-if="!tab.HideHeader" row class="justify-start mx-3">
            <span v-if="tab.Icon"><v-icon color="primary" large>{{tab.Icon}}</v-icon></span>
            <span class="secondary--text headline" id="Basic">{{tab.DisplayName}}</span></v-layout> -->
        <!-- <v-icon class="links--text" @click="OpenDialog(elmnt,index,true,i)">mdi-cogs</v-icon> -->
        <div class="justify-center flex row xl12 lg12 md12 sm12" v-if="!SingleElementView">
        
            <div style="padding-bottom:80px;" :class="'my-5 slightmargin col-sm-'+section.FlexSmall+' col-md-'+section.FlexMedium+' col-12'" v-for="(section,sectionindex) in TabElements" :key="section.itemObjKey" :cols="section.Cols" :sm="section.Cols">
            <!-- <span v-for="section in tab.Sections" :key="section.itemObjKey"> -->
                <!-- NewFieldDragging {{NewFieldDragging}} -->
                <v-card flat width="100%" @dragover.prevent @drop.prevent="NewFieldDragfinish(NewFieldDragging,NewFieldDraggingIndex,section,sectionindex, $event)" v-if="NewFieldDragging && section.DataViewName === 'Data Single Fields'">									
                <v-layout class="justify-center">									
                    <v-card-title class="grey--text">									
                    <v-icon>mdi-plus-thick</v-icon> Add Field here									
                    </v-card-title>									
                </v-layout>									
                </v-card>
            <SingleTabFieldsSection @UpdateEditableField="UpdateEditableField" @GetShortLivedURL="GetShortLivedURL" @UpdateRecordCalculation="UpdateRecordCalculation"
            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" :SingleElementView="SingleElementView"
            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex" @onPhotoUpload="onPhotoUpload" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateProcessing="ActivateProcessing" :System="System"
            :Record="Record" v-if="section.DataViewName === 'Data Single Fields' && section.ShowComponents" :AdditionalSaveMethod="AdditionalSaveMethod"
            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
            />
            <SingleTabFieldsSection @UpdateEditableField="UpdateEditableField" @GetShortLivedURL="GetShortLivedURL"
            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" :System="System"
            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex" @onPhotoUpload="onPhotoUpload"
            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing"
            :Record="Record" v-if="section.DataViewName === 'Data Single Feature List' && section.ShowComponents" :AdditionalSaveMethod="AdditionalSaveMethod"
            :section="section" :tab="tab" :Fields="FeatureNavList.FeatureList" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
            />
            <SingleTabActivitiesSection 
            :Record="Record" v-if="section.DataViewName === 'Data Single Activities' && section.ShowComponents"
            :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing"
            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :System="System"
            :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
            :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
            :ActivitiesArray="ActivitiesArray"
            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
            />
            </div>
        </div>
        <div class="justify-center flex row xl12 lg12 md12 sm12" v-if="SingleElementView">
            <div :class="'col-sm-12 col-md-12 col-12'" :cols="12" :sm="12" v-for="(section,sectionindex) in TabElements" :key="section.itemObjKey">
            <SingleTabFieldsSection @UpdateEditableField="UpdateEditableField" @GetShortLivedURL="GetShortLivedURL" @UpdateRecordCalculation="UpdateRecordCalculation"
            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" :SingleElementView="SingleElementView"
            @OpenDialog="OpenDialog" :sectionindex="sectionindex" :tabindex="tabindex" @onPhotoUpload="onPhotoUpload" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateProcessing="ActivateProcessing" :System="System"
            :Record="Record" v-if="section.DataViewName === 'Data Single Fields' && section.ShowComponents" :AdditionalSaveMethod="AdditionalSaveMethod"
            :section="section" :tab="tab" :Fields="section.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
            />
            </div>
        </div>
    </div>
</template>

<script>
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import SingleTabActivitiesSection from '@/components/Database/SingleTabActivitiesSection';

export default {
    props: ['SingleRecordNavMenuItem','tab','AppisDarkMode','CanEdit','Record','SingleElementView',
    'Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','TasksQuery','EmailsQuery','NotesQuery','PhoneCallsQuery','ActivitiesArray','SystemEntities','CurrentEntity','FeatureNavList',
    'AdditionalSaveMethod','SingleFormEditing','tabindex','NewFieldDragging','NewFieldDraggingIndex','EntityDataRef'],
    components: {
        SingleTabFieldsSection,
        SingleTabActivitiesSection
    },
    data(){
        return{

        }
    },
    computed:{
        TabElements(){
            return this.tab.Elements.filter(elmnt => {
                if (this.SingleRecordNavMenuItem && this.SingleRecordNavMenuItem.RenderSingleSection){
                    let singleelmnt = this.SingleRecordNavMenuItem.Elements[0]
                    if(singleelmnt){
                        return elmnt.Name === singleelmnt.Name
                    }
                }
                else{
                    return elmnt
                }
            })
        }
    },
    created(){
        //console.log('this.SingleElementView',this.SingleElementView)
        //console.log(this.Record)
    },
    methods:{
        
        ActivateUploadingSingleFileNotification(boolean){
            this.$emit('ActivateUploadingSingleFileNotification',boolean)
        },
        ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
        },
        GetShortLivedURL(fileobject){
        this.$emit('GetShortLivedURL',fileobject)
      },
        onPhotoUpload(filename,file,storepath,field){
            this.$emit('onPhotoUpload',filename,file,storepath,field)
        },
        NewFieldDragfinish(NewFieldDragging,NewFieldDraggingIndex,section,sectionindex, event){
            //console.log(NewFieldDragging,NewFieldDraggingIndex,section,sectionindex, event)
            this.$emit('NewFieldDragfinish',NewFieldDragging,section)
        },
        OpenDialog(elmnt,index,boolean){
            this.$emit('OpenDialog',elmnt,index,boolean,this.tabindex)
        },
        UpdateRecordCalculation(Record,field,value){
        this.$emit('UpdateRecordCalculation',Record,field,value)
      },
        UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList){
            //console.log(prop,value,AdditionalSaveMethod,FieldObject)
            
            this.$emit('UpdateEditableField',prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList)
    //   FieldObject[prop] = value
    //   console.log(AdditionalSaveMethod)
    //   if(AdditionalSaveMethod){
    //     this[AdditionalSaveMethod]()
    //   }
    },
    }
}
</script>

<style>

</style>