<template>
    <v-dialog persistent fullscreen v-model="AppQuickStartDialog">
                    <!-- <v-overlay class="BuilderGradient"> -->
         
                        <v-card :dark="AppisDarkMode" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                                class="mx-auto" height="100%"
                                :min-width="400">
                                <v-card-title class="primary white--text justify-space-between">
                                  <span>{{ currentTitle }}</span>
                                  <v-avatar
                                    color="primary lighten-2"
                                    class="subheading"
                                    size="24"
                                    v-text="step"
                                  ></v-avatar>
                                </v-card-title>
                            <v-window v-model="step">
                              <v-window-item :value="1">
                             <v-card-text>
                               <!--  style="margin-top: 180px;" -->
                                <!-- <v-row style="margin-top: 180px;"
                                  align="center"
                                  justify="center"
                                > -->
                                <!-- <v-btn @click="TestRACloudFunction()">Test Ra Function</v-btn> -->
                                 <!-- <v-list-item class="justify-center">
                                   <v-btn @click="step = 2">
                                     Database Builder
                                   </v-btn>
                                 </v-list-item>
                                 <v-list-item class="justify-center">
                                   <v-btn @click="step = 3">
                                     Social Network Builder
                                   </v-btn>
                                 </v-list-item>
                                 <v-list-item class="justify-center">
                                   <v-btn @click="step = 4">
                                     Website Builder
                                   </v-btn>
                                 </v-list-item> -->
                                 <v-card-text>
                               <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Module'])" :tab="systemguideelmnt" />
                            </v-card-text>
                               <!-- </v-row> -->
                            </v-card-text>
                          </v-window-item>
                          <v-window-item :value="2">
                             <v-card-text>
                               <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Database Builder','DBB'])" :tab="systemguideelmnt" />
                            </v-card-text>
                          </v-window-item>

                          <v-window-item :value="3">
                            <v-card-text>
                              <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Social Network Builder','SNB'])" :tab="systemguideelmnt" />
                            </v-card-text>
                          </v-window-item>

                           <v-window-item :value="4">
                            <v-card-text>
                               <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Website Builder','WB'])" :tab="systemguideelmnt" />
                            </v-card-text>
                          </v-window-item>
                        </v-window>
                        <v-divider></v-divider>
                        <v-card-actions class="primary" style="position: absolute;bottom: 50px;width: 100%;">
                          <v-btn
                            :disabled="step === 1"
                            text
                            @click="step=1"
                          >
                            Back
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="step !== 1"
                            color="primary"
                            depressed
                            @click="ActivateBuilderView()"
                          >
                            Go there now
                          </v-btn>
                        </v-card-actions>
                            </v-card>
                              <v-layout column style="position: fixed;top: 60px;left: 50px;z-index: 5">
                                <v-btn color="white" icon x-large @click="ToggleAppQuickStartDialog()"><v-icon  color="blue" x-large>mdi-close</v-icon></v-btn>
                        </v-layout>
                    <!-- </v-overlay> -->
    </v-dialog>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';

export default {
    props: ['System','SystemEntities','RAAppAdminDialog','RADB','RAApp','AppisDarkMode','SitePages'],
    components: {InfoSheet,RAPluginCarousel},
    data() {
        return {
            SelectedBuilder: '',
            Builders: [
            {Name: 'Website Builder'},
            {Name: 'Social Network Builder'},
            {Name: 'Database Builder'}
          ],
            step: 1,
            raloginemail: '',
            raloginpassword: '',
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
          
        }
    },
    computed:{
      RelevantBuilderView () {
        switch (this.step) {
          case 2: return 'Database Builder'
          case 3: return 'Social Network Builder'
          case 4: return 'Website Builder'
        }
      },
      currentTitle () {
        switch (this.step) {
          case 1: return 'Suite Builder'
          case 2: return 'Database Module'
          case 3: return 'Social Module'
          case 4: return 'Website Module'
        }
      },

        WindowWidth(){
        return window.innerWidth
        },
        WindowHeight(){
        return window.innerHeight
        },
          UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        ComputedBuilders(){
            return this.Builders.filter(builder => {
            return this.PluginDataBase[builder.Name.split(' ').join('_')] && this.PluginDataBase[builder.Name.split(' ').join('_')].Active
            })
        },
        WebsiteisActive(){
            return this.$store.state.WebsiteisActive
        },
        SocialNetworkisActive(){
            return this.$store.state.SocialNetworkisActive
        },
        DatabaseisActive(){
            return this.$store.state.DatabaseisActive
        },
        DocumentationisActive(){
            return this.$store.state.DocumentationisActive
        },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        ComputedRAPlugins(){
            return this.RAPlugins.map(guide => {
                guide.elmnt = Object.assign({},this.systemguideelmnt)
                guide.elmnt.Description = guide.Description
                return guide
            })
        },
        userLoggedIn () {
            return this.$store.getters.user
            },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        AppQuickStartDialog () {
            return this.$store.state.AppQuickStartDialog
        },
    },
    
    watch: {
     AppQuickStartDialog(v) {
            if (v) {
               
            }
            else{
              this.step = 1
            }
        }, 
    },
    

    created(){
    },
    methods:{
      TestRACloudFunction(){
         const functions = firebase.functions(this.RAApp)
         //okay this first line is key, can we make it "funcitons" of "another app???"
         const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
         let autopayload = {
          Docid: 'BillingAccounts_Customer_ID',
          Data: {Account_Name: 'Random'},
            siteid: this.$store.state.ActiveSuiteid
        }
        AssignAutoNumber(autopayload).then(result => {
          console.log(result)
        })
      },
      ActivateBuilderView(){
        this.ToggleAppQuickStartDialog()
        this.SelectedBuilder = this.Builders.find(obj => obj.Name === this.RelevantBuilderView)
        this.step = 1
        this.CheckBuilderRoute()
      },
      CheckBuilderRoute(){        
        // if(this.IsGroupView && this.SelectedBuilder && this.SelectedBuilder.Name !== 'Website Builder'){
        //   this.$router.push('/Group/Edit/'+this.GroupID)
        // }
        //okay actually it is in here. I have to have default routes my thoughts being..if GroupView it's either gorup edit or..well group edit
        //now I could go and make it quite "simple" for myself here and push to systemconfig always but ahve you seen teh delay here? No I need to understand do I have public pages or social pages and if I do push to them, if not etc.
        let relevantroute = ''
        if(this.SelectedBuilder.Name === 'Website Builder' || this.SelectedBuilder.Name === 'Social Network Builder'){
          //alert('eyeyey')
          if(this.IsGroupView && this.SelectedBuilder.Name === 'Website Builder'){
            relevantroute = this.ComputedSitePages[0]
            if(relevantroute){
              this.$router.push('/Group-PageEditor/'+this.GroupID+'/'+relevantroute.id)
            }
            else{
              this.$router.push('/Group/Edit/'+this.GroupID)
            }
          }
          else if(this.IsGroupView){
            this.$router.push('/Group/Edit/'+this.GroupID)
          }
          else{
            if(this.SelectedBuilder.Name === 'Website Builder'){
              relevantroute = this.SitePages.find(obj => obj.PublishType === 'Public')
            }
            else{
              //at this stage the issue is, if we push to a community page we lose the social component toggle
              //because it's within system config that the tabs exist to set general social comp settings
              relevantroute = this.SitePages.find(obj => obj.PublishType === 'Members Only')
            }
            if(relevantroute){
              this.$router.push('/PageEditor/'+relevantroute.id)
            }
            else{
              this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID)
            }
          }
        }
        else{
          //okay yeah think about it, if we toggle to dbview?
          if(this.IsGroupView){
            this.$router.push('/Group/Edit/'+this.GroupID)
          }
          else{
            this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID)
          }
        }
        this.$store.commit('setBuilderView',this.SelectedBuilder)
      },
      RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || !categorymatches.includes('Module') && plug.Module_Category.Name === 'General'
        })
      },
        ToggleAppQuickStartDialog(){
        this.$store.commit('setAppQuickStartDialog',!this.AppQuickStartDialog)
      
    },
        loginClientAppRAAdmin(){
      let vm = this
      const user = {
        email: this.raloginemail,
        password: this.raloginpassword
      }
      let payload = {
        user: user,
        SystemName: 'RapidApps',
        ClientFireStore: this.RAApp
      }
      
      this.$store.dispatch('signInClientAppAction', payload).then(response => {
        console.log(response)
        console.log(response.user)
        console.log(response.user.uid)
        if(response){
          vm.RADB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = user.uid
            console.log(userdata)
            // vm.$store.commit('setUserObj', userdata)
            alert('successfully signed in')
            vm.RAAppAdminDialog = false
          })
          
        }
         
      })

    
    },
    }
}
</script>

<style>
.iconpulse{
  animation: simplepulsing 1.0s infinite;
}
@keyframes simplepulsing {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}

}
</style>
