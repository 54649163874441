<template>
  <div>
      <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <!-- <v-layout class="justify-center"> -->
    <v-img
               style="padding-top:50px;"
                height="250"
                src="@/assets/logo.png"
                ></v-img>
    <!-- </v-layout> -->
    <v-card-subtitle class="mx-3 text-subtitle-1 caption">
       305 View - 14 days ago
    </v-card-subtitle>
    <v-card-title class="green white--text"><b>Company Overview</b>
        
    </v-card-title>
    
    <v-card-text class="my-1">
    <!-- <v-card-subtitle class="caption"> -->
       Reading Time - <v-chip small>15min</v-chip>
    <!-- </v-card-subtitle> -->
    </v-card-text>
    <v-card-text>
      <div class="text-subtitle-1 overline">
        <b>Category:</b> General || <b>Audience:</b> All
      </div>

      <div class="my-2">Learn more about our Company and what we offer our clients.</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions class="mx-3 caption">By John Doe
        <v-spacer></v-spacer><v-chip small color="error" dark>Mandatory</v-chip><v-chip small color="warning" dark>Incomplete</v-chip>
    </v-card-actions>

    <v-card-text>
     <v-progress-linear value="15"></v-progress-linear>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {

        }
    },
    computed:{

    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>
