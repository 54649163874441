<template>
   <v-card flat tile class="transparent">
        <!-- :style="MobileView ? 'top:50px;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'" -->
       <v-list :style="MobileView ? 'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;' : 
          'top:50px;position: fixed;right: -100px;width: 150px;text-decoration: none;z-index: 3;'"
              dark>               
              <v-list-item v-for="sect in ActiveButtonMenuItems" :key="sect.itemObjKey" @click="ActivateButtonMenuSection(sect)" :class="sect.Class">          
                  <v-list-item-action>
                  <v-icon>{{sect.Icon}}</v-icon>
                  </v-list-item-action>          
                  <v-list-item-content>
                  {{sect.Name}}
                  </v-list-item-content>
              </v-list-item>
          </v-list>	
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
          <v-list v-if="ActiveButtonMenuSection.Name === 'Standard'">
            <AssetsDirectory :Assets="EditedTab.Assets" :Cols="6" :HoverPreview="false" :HideSelect="true" :EditedTab="EditedTab"
         :ViewFullGalleryTop="true" @ActivateFullGallery="ActivateGalleryDialog" :ViewFullGalleryText="'Add More'"
         :GalAssetsCall="true"
                                />
          </v-list>
          <v-list v-if="ActiveButtonMenuSection.Name === 'Styling'">
             <v-list-item class="justify-space-between">   
                  <v-select v-model="EditedTab.GalleryType" label="Type" @change="CheckChangedValue(EditedTab)"
                :items="['Default','Cards','Clipped Images','Carousel']"
                />
                </v-list-item>  
                 <v-list-item  v-if="EditedTab.GalleryType === 'Cards'">   
                 <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="200px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-palette-advanced</v-icon>
                                    Hover Color
                                    </template>
                                    <v-color-picker  label="HoverColor" v-model="EditedTab.HoverColor"></v-color-picker>
                                    </v-menu>		
                 </v-list-item>
               
                <v-list-item v-if="EditedTab.GalleryType === 'Carousel' || 
                EditedTab.GalleryType === 'Cards'">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Caption Color					
                    </template>									
                <v-color-picker label="AssetCaptionColor" v-model="EditedTab.AssetCaptionColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
               
                 <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Caption BG Color					
                    </template>									
                <v-color-picker v-model="EditedTab.AssetCaptionBGColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
                <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Title Color					
                    </template>									
                <v-color-picker v-model="EditedTab.AssetTitleColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
                 <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Title BG Color					
                    </template>									
                <v-color-picker v-model="EditedTab.AssetTitleBGColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
                <v-dialog max-width="800">								
                                    <template v-slot:activator="{ on }">									
                                    <v-icon v-if="EditedTab.GalleryType === 'Carousel'"
                                    v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>	
                                      						
                                            <v-tabs v-if="!UseGroupAssets">									
                                            <v-tab v-for="lib in GalleryLibraries" :key="lib.itemObjKey">									
                                                {{lib.name}}									
                                            </v-tab>									
                                            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">							
                                                <v-card width="800">									
                                                <PhotoLibraryViewer :FullGallery="true"	:TableCollectionRef="lib.TableCollectionRef" :TableName="lib.name"							
                                                @AssignImage="AssignImage" :IMGProp="'ClippingIMG'" :CurrentEntity="lib" :AppisDarkMode="true"									
                                                :tab="EditedTab" />									
                                                </v-card>									
                                            </v-tab-item>									
                                        </v-tabs>				
                                        <v-card-text v-if="UseGroupAssets">
                                            <v-row  v-if="SystemAssets.length > 0">
                                                <v-col
                                                v-for="n in SystemAssets"
                                                :key="n.itemOvjKey"
                                                class="d-flex child-flex"
                                                cols="3"
                                                >
                                                <v-img
                                                    :src="n.ThumbURL"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                                    @click="AssignImage(EditedTab,'ClippingIMG',n)"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>                                      
                                    </v-dialog>	
                                    <v-text-field v-if="EditedTab.ClippingIMG"  v-model="EditedTab.ClippingIMG" clearable/>
              
                   
                <v-list-item v-if="EditedTab.GalleryType === 'Clipped Images' || 
                EditedTab.GalleryType === 'Carousel'">
                  <v-text-field
          label="Gradient"
          v-model="EditedTab.BoxGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>  
                
               
                <!-- <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                  <v-textarea v-model="EditedTab.ClipAnimPathREV" label="Clip Animate Start"
                  />
                </v-list-item>   
                 <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                  <v-textarea v-model="EditedTab.ClipAnimPathFWD" label="Clip Animate End"
                  />
                </v-list-item>     -->
                 <v-list-item v-if="EditedTab.GalleryType === 'Cards'">                  
                  <v-select v-model="EditedTab.CaptionFontSize" label="Caption Font Size" :items="[10,12,14,16,20,24,30,36,42,46,50,54,60]"									
                    />
                 </v-list-item>
            <v-list-item v-if="EditedTab.GalleryType === 'Cards'">
                  <v-text-field v-model.number="EditedTab.GalPadding" label="Padding"
                  />
                </v-list-item>
                  <v-list-item class="justify-space-between">   
                  <v-slider v-model="EditedTab.GalleryCols" label="Column Size"									
              min="1" v-if="EditedTab.Name === 'Gallery'"								
              max="3"									
                />
                </v-list-item> 
                 <v-list-item class="justify-space-between">   
                <v-select v-model="EditedTab.GalleryColsRatio" label="Ratio" 
                :items="[0.5,1,2,3]"
                />
                </v-list-item> 
          </v-list>
          
           <!-- <v-list v-if="ActiveButtonMenuSection.Name === 'Sizing'">
             
           </v-list> -->
          <v-list v-if="ActiveButtonMenuSection.Name === 'Settings'">
             <v-list-item class="justify-space-between" v-if="EditedTab.GalleryType === 'Carousel'">   
                 <v-switch v-model="EditedTab.GalIMGContain" label="IMG Contain"/>		
                </v-list-item>
             <v-list-item class="justify-space-between">
                <v-switch v-model="EditedTab.NotHovered" label="Not Hovered"></v-switch>   
                <v-switch v-if="EditedTab.Name === 'Gallery'" v-model="EditedTab.HideCaptions" label="Hide Captions"/>	
            </v-list-item>
            <v-list-item class="justify-space-between">              
              <v-switch v-if="EditedTab.Name === 'Gallery'" v-model="EditedTab.HideViewButton" label="Hide View Button"/>	                               
                <v-switch v-model="EditedTab.FullScreenDialog" label="Full Screen Dialog"></v-switch>
            </v-list-item>            
            <v-list-item v-if="EditedTab.FullScreenDialog">
                  Dialog Buttons Col
                  <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="200px"
                      >
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-palette-advanced</v-icon>
                      </template>
                      <v-color-picker v-model="EditedTab.GalleryDialogBtnColor"></v-color-picker>
                      </v-menu>
                  </v-list-item>
            <v-list-item class="justify-space-between">  
                <v-switch v-model="EditedTab.ExtendWithGalleryParallax" label="Extend with Parallax"></v-switch>  
                <v-switch v-if="EditedTab.ExtendWithGalleryParallax" v-model="EditedTab.ParallaxClipped" label="Clipped"></v-switch> 
            </v-list-item>
            <v-list-item class="justify-space-between">      
              <v-select clearable v-if="EditedTab.ExtendWithGalleryParallax && EditedTab.ParallaxClipped" item-text="Name" v-model="EditedTab.ParallaxClippingImage" :items="SystemAssets" return-object label="Clipping Image"></v-select>        
                     			
             									
            </v-list-item>	
             <v-list-item>
                  <v-switch @change="CheckforFilters(EditedTab)" v-model="EditedTab.HasFilters" label="Has Filters"
                  />
                </v-list-item>
                <v-list v-if="EditedTab.HasFilters">
                  <v-btn @click="AddFilter(EditedTab)">Add Filter</v-btn>
                  <v-list-item v-for="filter in EditedTab.Filters" :key="filter.itemObjKey">
                    <v-text-field label="Name" v-model="filter.Name"/>
                    <v-icon @click="ConfirmDeleteFilter(EditedTab,filter)">mdi-delete-forever</v-icon>
                  </v-list-item>
                </v-list>
          </v-list>
          
        <v-card-text>
           
               
        </v-card-text>
         
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets','GroupData',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,AssetsDirectory},
    data() {
        return {
          ActiveButtonMenuSection: {Name: 'Standard'},
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      ActiveButtonMenuItems(){
                return [
                    {Icon: 'mdi-folder-multiple-image',Name: 'Standard',Class: 'grey'},
                    {Icon: 'mdi-image-size-select-large',Name: 'Styling',Class: 'grey'},
                    // {Icon: 'mdi-arrow-expand-horizontal',Name: 'Sizing',Class: 'grey'},
                    {Icon: 'mdi-cogs',Name: 'Settings',Class: 'grey'},
                    // {Icon: 'mdi-text-box',Name: 'Paragraph',Class: 'grey'},
                ]
            },
    },
    watch: {
        SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['AssignImageandCloseTab']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      ActivateButtonMenuSection(sect){
            this.ActiveButtonMenuSection = sect
        },
      ConfirmDeleteFilter(tab,filter){
        let index = tab.Filters.indexOf(filter)
        confirm('Are you sure you want to remove this filter forever?') && tab.Filters.splice(index,1)
      },
      AddFilter(tab){
        let lastint = tab.Filters.length
        let newnr = Number(tab.LastFilterID)+Number(lastint)
        let newfilter = {Name: 'New Filter',ID: newnr}
        tab.LastFilterID = newfilter.ID
        tab.Filters.push(newfilter)
      },
      CheckforFilters(tab){
        if(tab.HasFilters && !tab.Filters){
          tab.Filters = []
          tab.LastFilterID = 1000
        }
      },
      CheckChangedValue(tab){
        if(tab.GalleryType === 'Carousel' && typeof tab.AssetCaptionBGColor === 'undefined'){
          tab.AssetCaptionBGColor = {hex: '#FF0000',hexa: '#FF0000FF'}
          tab.AssetFontColor = tab.FontColor
          tab.AssetTitleBGColor = {hex: '#FF0000',hexa: '#FF0000FF'}
          tab.AssetCaptionColor = {hex: '#000000',hexa: '#FF0000FF'}
          tab.AssetTitleColor = {hex: '#000000',hexa: '#FF0000FF'}
        }
        if(tab.GalleryType === 'Cards' && !tab.HoverColor){
          tab.HoverColor =  {hex: '#FF0000',hexa: '#FF0000FF'}
        }
      },
      ActivateGalleryDialog(){
        if(!this.EditedTab.Assets){
          this.EditedTab.Assets = []
        }
        let arr = this.SystemAssets.filter(asset => {
          let oncheck = this.EditedTab.Assets.find(obj => obj.URL === asset.fileurl)
          return !oncheck
        })
        this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
        this.$store.commit('setGalleryAssets',arr)
        this.$store.commit('setEditedTab',this.EditedTab)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp','LibraryAsset')
        //GalleryLibraries
        //methods ? SelectGalleryAssets
      },
      ActivateLib(libindex){
        this.libindex = libindex
      },
      AssignImageandCloseTab(){
          this.AssignGalleryAssets(this.EditedTab,this.SelectedImage)
          //this.NoPropsemit('DeactivateTabDialog')
        },
        
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
