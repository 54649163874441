<template>
  <div>
    <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveRecord({Record: Record,Fields: [field]})" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn>
     <v-list-item class="justify-center subtleoverline">
        {{field.DisplayName}}
    </v-list-item>
   <v-layout class="justify-center" style="padding: 5px;" v-if="!Editing">
    <v-avatar size="120" v-if="ComputedValue"
        :color="ComputedValue.Color? ComputedValue.Color : ''" 
        >
        <v-icon size="100" dark>{{ComputedValue.Icon}}
      </v-icon>
        </v-avatar>
      <!-- <v-icon :color="ComputedValue.Color" size="120">
        {{ComputedValue.Icon}}
      </v-icon> -->
   </v-layout>
   <v-layout class="justify-center mediumoverline" style="font-weight: 400;" v-if="FieldValue && !Editing">
      {{FieldValue.Name}}
   </v-layout>
   <v-layout class="justify-center mediumoverline" v-if="!FieldValue && !Editing">
      NO DATA
   </v-layout>
    <!-- {{col.Field}} -->
    <v-list-item v-if="FieldLevel2 && !Editing" class="subtleoverline justify-center">
      {{FieldLevel2.Name}}
    </v-list-item>
    <v-layout class="justify-center" v-if="Editing">
    <v-card width="400" flat tile>
            <v-card-title class="justify-center mediumoverline">Change  {{field.DisplayName}}</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="field.Options"
            v-model="Record[field.id]"
            item-text="Name"
            :label="field.DisplayName"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
            v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1] && Record[field.id]"
            :items="Record[field.id].Options"
            v-model="Record[field.Levels[1].FieldName]"
            item-text="Name"
            :label="field.Levels[1].FieldName"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
    </v-layout>
    <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit'],
    components: {},
    data() {
        return {
            Editing: false,

        }
    },	
    computed:{
      FieldLevel2(){
        let field = this.field
        return field && field.HasDeepHierarchy && field.Levels && field.Levels[1] ? 
         this.Record[field.Levels[1].FieldName] : ''
      },
      field(){
        return this.col.FieldObj
    },
      ComputedValue(){
        let obj = {
          Value: this.FieldValue,
        }
        if(this.field){
          let opt = this.field.Options.find(obj => obj.ID === this.FieldValue.ID)
          if(opt){
            obj.Icon = opt.Icon
            obj.Color = opt.Color && opt.Color.hexa ? opt.Color.hexa : opt.Color
            if(!obj.Icon && this.field.id === 'Status'){
              if(this.FieldValue){
                obj.Icon = this.FieldValue.Name === 'Active' ? 'mdi-power' : 'mdi-power-off'
                obj.Color = this.FieldValue.Name === 'Active' ? 'green' : 'red'
              }
            }
          }
        }
        return this.FieldValue ? obj : {}
      },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },

    }
}
</script>

<style>

</style>



