
<template>
<v-card flat :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent' : 'transparent'" width="100%" height="100%">
    <!-- <v-card  :flat="NoticeSelect || $route.name === 'GroupPage'" -->
    <v-parallax v-if="$route.name !== 'GroupSingle' && $route.name !== 'GroupPage' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">Articles</h4>
              </v-col>
            </v-row>
            </v-parallax>
 <v-layout class="justify-center" v-if="!NoticeSelect"> 
    <v-card flat class="transparent" width="90%">
    <v-layout class="justify-end">
      <v-btn outlined color="accent" @click="OpenAboutDialog('AboutSiteArticles')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Articles</v-btn>
    <v-btn outlined color="accent"  :to="SingleRouteNewPrefix" v-if="UserModerator || CanCreate || UserisTemplateOwner"><v-icon>mdi-plus</v-icon>New Article</v-btn>
    </v-layout>
   
<v-list v-if="!NoticeSelect" class="transparent">
     <v-list-item style="padding:0px;" v-for="article in articlesDated" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover :width="!$vuetify.breakpoint.xs ? '100%' : '100%'" >
                  
                <v-img
               
                  :height="article.coverimage ? '350px' : '220px'"
                  v-bind:src="article.coverimage ? article.coverimage : '@/assets/logo.png'"
                >
                <v-sheet
                          style="opacity: 0.8;"
                          :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'my-4 background' : 'mx-10 my-4 background'"
                          :height="article.coverimage ? '80%' : '100%'"
                          :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '85%' : '60%'"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'configtext--text largeoverline' : 'configtext--text articleoverline'" :style="$vuetify.breakpoint.xs ? '' : 'padding-left:25px;'">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'configtext--text overline' : 'configtext--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <!-- <v-card-actions class="mx-3">
                              <v-btn  :to="userLoggedIn? '/Article/' + article.id :  '/Article/' + article.PublicURL" color="links black--text" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions> -->
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                </v-img>
                <v-divider></v-divider>
                <v-card-actions style="font-size:13px" class="mx-3 grey--text">
                  {{article.pubDate}}<v-spacer></v-spacer>
                   <v-chip small :color="tab && tab.ButonColor ? tab.ButonColor.hexa : 'secondary'" class="white--text">
                    {{article.author}} 
                  </v-chip>
                </v-card-actions>
                <v-divider></v-divider>
                  <v-card-text v-html="smart_substr(article.description,500)">
                </v-card-text>
                <v-card-actions>
                  
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :small="MiniView" class="accent--text" dark :to="SingleRoutePrefix+article.id">
                  <v-icon v-if="MiniView">mdi-glasses</v-icon>
                  {{MiniView? '' : 'Read More'}}</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>

    </v-card>
    </v-layout>
    <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="articlesDated" item-text="title" v-model="selectedSocialItem" return-object label="Article"/>
</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['CanCreate','NoticeSelect','System','SystemEntities','GroupData'],
    components: {
    
        },
    data() {
        return {
          InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
           UserRecord: {},
            ArticleTitle: '',
            NewArticleDialog: false,
            Forums: [],
          NewCommentInput: '',
          NewReplyInput: '',
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
            articles: [],
            selectedcoverimage: '',
            coverimagelocalurl: '',
            SiteModerators: [],
            ArticleModerator: false
        }
        
    },
    
    computed: {
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      }, 
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.$route.name === 'GroupArticle' || this.$route.name === 'MarketplaceTemplateGroupSingle' || this.$route.name === 'SuiteTemplateGroupSingle' ? this.GroupData.name+' Articles' : 
          this.SuiteSocialNetwork ? this.SuiteSocialNetwork.Name :
          this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.System.Name
        },
      SingleRouteNewPrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/NewArticle'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/NewArticle'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/NewArticle' : '/Group/NewArticle/'+this.$route.params.id
        }
      },
      SingleRoutePrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/Article/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/Article/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/Article/' : '/Group-Article/'+this.$route.params.id+'/Article/'
        }
      },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      CollectionRef(){
        //ContentRootPath
        //ContentColPath
        if(this.TemplateRootPath){
          return this.ContentRootPath.collection('samplesitearticles')
        }
        else if(this.NonGroupRoute){
         return this.userLoggedIn ? this.ContentRootPath.collection('sitearticles') : db.collection('Websites').doc(this.$store.state.ActiveSuiteid).collection('sitearticleswiki') 
        }
        else{
          return this.ContentRootPath.collection('grouparticles').where('groupid','==',this.$route.params.id)
          //.where('GuestsIncluded','==',true)
        }
        
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
      UserisTeamMember(){
        return this.GroupData && this.GroupData.TeamMembers ? this.GroupData.TeamMembers.find(obj => obj === this.userLoggedIn.id) : false
      },
      UserisGroupMember(){
        return this.GroupData && this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.find(obj => obj.id === this.GroupData.id) : false
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserModerator(){
        if(!this.GroupData){
          if(this.userLoggedIn.ModerationPermissions){
          let match = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Articles')
          if(match){
            return true
          }
          else{
            return this.userIsAdmin
          }
        }
        else{
          return this.userIsAdmin
        }
        }
        else{
          return this.UserisGroupMember && this.UserisGroupMember.ArticlesModerator || this.GroupData.Administrators && this.GroupData.Administrators.includes(this.userLoggedIn.id)
        }
        
      },
      articlesDated(){
        return this.articles.map(article => {
          if(!article.pubDate){
            //still need to convert this.$store.dispatch('FullSTDDate',article.PublishDate.toDate()).then(res => {
            article.pubDate = this.TimestampFormatterSTRINGDayText(article.PublishDate.toDate())
          }
          
      
          return article
        })
      },
      
      PostsTimeline () {
        return this.Posts.map(post => {          
          if(typeof post.createdon.toDate !== 'undefined'){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        }).slice().reverse()
      },
      

    },
    watch: {
      UserRecord (value) {
        if (value !== null && value !== undefined) {
          console.log('has UserRecord')
          this.UsersModeration()
        }
      },
      
    // WritingPost (value) {
    //   if (value !== null && value !== undefined && value === true) {
    //     // this.inputwidth = '90%'
    //     // let postelmnt = document.getElementById('postinput')
    //     setTimeout(() => {
    //         this.postelmnt.focus()
    //       }, 100); 

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
  },
    created(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      if(this.$store.state.TemplateView){
        //much as I want to make all the logic flow this is the safest bet to prevent failures
        this.GetSamples(this.TemplateRootPath.collection('samplesitearticles'),'articles')
      }
      else{
        //console.log('will check UserRecord.',this.UserRecord)
        if(!this.ActiveSuiteApp){
        this.IntranetViewToggle()
        }
       
      this.GetRequestingUser()
      }
      
      


    },



    methods: {
      OpenAboutDialog(dialprop){      
          this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
          this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
        },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
      async GetRequestingUser(){
        let userdetails = this.userLoggedIn
         this.UserRecord = userdetails
        this.UserRecord.id = userdetails.id
        this.authorid = userdetails.id

        this.author = userdetails.Name+' '+userdetails.Surname
      },
      UsersModeration(){
        let vm = this
        this.GetSiteModerators().then(function(result) {
          if(result){
            console.log('checking if moderator')
            let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
            if(UserModeratorobj){
              if(UserModeratorobj.ModerationPermissions.includes('Articles')){
                vm.ArticleModerator = true
              }
              
            }
            if(vm.userIsAdmin){
              vm.ArticleModerator = true
            }
            let colref = vm.CollectionRef
           if(vm.NonGroupRoute){
              vm.InternalLinkType = 'Article'
              vm.SharedCollection = 'sitearticles'
              vm.LinkComponent = 'SiteArticleShared'
              if(vm.$store.state.UserisGuest){
                vm.GetArticles(colref.where('PublishStatus','==','Published (Public)'))
                if(!vm.ChannelRestricted || vm.userIsAdmin){
                  if(!vm.CompanyOnlyFilter){
                  vm.GetArticles(colref.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
                  }
                  else{
                    vm.GetArticles(colref.where('PublishStatus','==','Published (Internal)').where('Companyid','==',vm.UserCompanyid))
                  }
                }
                
              }
              else{
                if(!vm.ChannelRestrictedChannelRestricted || vm.userIsAdmin){
                  if(!vm.CompanyOnlyFilter|| vm.userIsAdmin){
                  vm.GetArticles(colref.where('PublishStatus','!=','Draft'))
                  }
                  else{
                    vm.GetArticles(colref.where('PublishStatus','!=','Draft').where('Companyid','==',vm.UserCompanyid))
                  }
                }
                else{
                  vm.GetArticles(colref.where('PublishStatus','==','Published (Public)'))
                }
              }
              
            }
            else{
              vm.InternalLinkType = 'Group-Article/'+vm.$route.params.id+'/Article'
              vm.SharedCollection = 'grouparticles'
              vm.LinkComponent = 'GroupArticleShared'
              console.log('vm.GroupData',vm.GroupData,vm.UserisTeamMember)
              if(vm.UserisTeamMember){
                vm.GetArticles(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
              }
              else if(vm.userLoggedIn && !vm.$store.state.UserisGuest){
                vm.GetArticles(colref.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
              }
              else{                
                vm.GetArticles(colref.where('GuestsIncluded','==',true).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
              }              
            }
          }
        })
      },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        db.collection('SocialNetworks').doc(vm.$store.state.ActiveSuiteid).collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
        IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
        onarticlecoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        GetArticles(col){
        col.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.articles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        
        },

      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },      
        
    },
}

</script>

<style>

</style>