<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
     <div v-if="!ActiveRecord">
        <!-- <v-tabs centered v-if="!ActiveRecord">
          <v-tab>List</v-tab>
          <v-tab-item> -->
              <v-list
            
                :dark="AppisDarkMode"
                dense                
                nav
                
                v-for="item in items"
                :key="item.title"
                >
                
                <v-list-item class="overline">
                     <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                <!-- <v-list-item-icon>
                    <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
                    </v-list-item-content> -->
                </v-list-item>
                <v-divider/>
                <v-list  :dark="AppisDarkMode"  v-if="GetMatchingStatus(item).length>0">
                <v-list-item v-for="poll in GetMatchingStatus(item)" :key="poll.itemObjKey"  @click="PushItem(poll)">
                    <!-- <v-list-item-icon>
                    <v-icon small>mdi-blogger</v-icon>
                    </v-list-item-icon> -->
                    <v-list-item-content>
                        <v-list-item-title :class="poll.class">{{ poll.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="poll.NotificationCount && poll.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="poll.NotificationCount && poll.NotificationCount>0" overlap>
                            <span slot="badge"> {{poll.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="poll.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <v-list :dark="AppisDarkMode" v-else>
                <v-list-item >
                    <v-list-item-icon>
                    <v-icon color="red" small>mdi-cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title  style="font-weight:300;"><i>There are no items for this Status at the moment</i></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-list>
          <!-- </v-tab-item>
           <v-tab>
                Stats
            </v-tab>
            <v-tab-item>
               
            </v-tab-item>
        </v-tabs> -->
     </div>
        <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        <v-btn :to="'/Forum/'+ActiveRecord.id">View</v-btn>
        <v-spacer></v-spacer>
         <v-icon color="red">mdi-delete-forever</v-icon>
        </v-card-title>
        <!-- {{ActiveRecord}} -->
        <!-- <v-tabs v-if="ActiveRecord" centered>            
            <v-tab>
                Notifications
            </v-tab>
            <v-tab-item> -->
                <ItemNotifications v-if="ActiveRecord" :query="ComputedNotificationsQuery"
               />
            <!-- </v-tab-item>
            <v-tab>
                Stats
            </v-tab>
            <v-tab-item>
                TBD
            </v-tab-item>
        </v-tabs> -->
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
// import SiteEvents from '@/views/SiteEvents'
import LookupComponentSubCollections from '@/components/Database/LookupComponentSubCollection'
import ItemNotifications from '@/components/Dashboards/ItemNotifications'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem'],
    components: {
        // SiteEvents,
        LookupComponentSubCollections,
        ItemNotifications

    },
    data(){
        return{
            items: [
                {
                    icon: 'mdi-vote',
                    title: 'Active'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'InActive'
                },
            ],
            ActiveRecord: '',
        SitePolls: [],
        search: '',
        cardsPerPage: 6,
        CardPage: 1,
        }
    },
    computed:{
        ComputedNotificationsQuery(){
            return db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('notifications').where('forumid','==',this.ActiveRecord.id)
        },
            SitePollsSearched(){
                return this.SitePolls.map(poll => {
                    poll.Status = 'Active'
                    return poll
                })
            },
            userIsAdmin () {
                return this.$store.state.IsAdmin
            },
            userLoggedIn () {
                return this.$store.getters.user
            },
            userBUID () {
                return this.$store.getters.userBUID
            },
            userBUChildren () {
                return this.$store.getters.userBUChildren
            },
            userBUParents () {
                return this.$store.getters.userBUParents
            },
    },
    created(){
        this.GetQuery(this.MenuItem)
    },
    watch: {
      ActiveRecord(value){
          if (value !== null && value !== undefined ) {
              this.Attending_Users = []
          
          
          }
      },
    },
    methods:{
        GetQuery(item){
            if(this.$route.params.id && item && item.groupcollectionname){
                let query = db.collection(item.groupcollectionname).where(item.groupcolfield,'==',this.$route.params.id)
                console.log('Get '+item.groupcollectionname+' where '+item.groupcolfield+' == '+this.$route.params.id,this.userLoggedIn.id)
                this.GetPolls(query)
            }
            else if(item && item.sitecollectionname){
               let query = db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
               console.log(item.sitecollectionname,'where ',item.sitecolfield,'==',this.userLoggedIn.id)
               this.GetPolls(query)
            }
        },
       GetMatchingStatus(item){
           if(this.MenuItem && this.MenuItem.Array){
             return this.MenuItem.Array
            .map(item => {
                item.Status = 'Active'
                return item
            })
            .filter(blog => {
                return blog.Status === item.title
            })
            .map(blog => {
                if(item.title === 'Draft'){
                    blog.class = 'warning--text'
                }
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                return blog
            })  
           }
           else{
               return []
           }
            
            
        },
        PushItem(item){
            this.ActiveRecord = this.SitePollsSearched.find(obj => obj.id === item.id)
        },
        GetPolls(query){
             let commentcol = ''
             let repliescol = ''
            if(this.$route.params.id){
                commentcol = 'groupforumcomments'
                repliescol = 'groupforumreplies'
            }
            else{
                commentcol = 'siteforumcomments'
                repliescol = 'siteforumreplies'
            }
            //only missing the query fror ownerid, but while testing we test all
            query.onSnapshot(res => {
                const changes = res.docChanges();
                let forumlength = res.docs.length
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      
                          let pollobj ={
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      pollobj.Comments = []
                      //pollobj.Replies = []
                      //console.log(pollobj)
                    //     db.collection(commentcol).where('relatedid','==',pollobj.id).onSnapshot(votesres => {
                    //         const voteschanges = votesres.docChanges();
                    //         let listlength = votesres.docs.length
                    //         if(listlength === 0){
                                 
                    //             this.SitePolls.push(pollobj)
                    //             if(this.SitePolls.length == forumlength){
                    //                 this.$emit('PushArray',this.MenuItem,this.SitePolls)
                    //             }
                    //         }
                    //         else{
                    //             voteschanges.forEach(votechange => {
                    //         if (votechange.type === 'added') {
                    //             let pollcommentobj = {
                    //             ...votechange.doc.data(),
                    //             id: votechange.doc.id
                    //             }
                    //             pollcommentobj.Replies = []
                                
                    //             //console.log(pollcommentobj.id,pollcommentobj.Replies)
                    //             db.collection(repliescol).where('forumid','==',pollobj.id).where('relatedid','==',pollcommentobj.id).onSnapshot(repliesres => {
                    //                 const replieschanges = repliesres.docChanges();
                    //                 let replylistlength = repliesres.docs.length
                    //                 //console.log(pollobj.id,listlength,pollobj.Comments.length,replylistlength,pollcommentobj.Replies.length)
                    //                 if(replylistlength === 0){
                    //                     pollobj.Comments.push(pollcommentobj)
                    //                     if(pollobj.Comments.length === listlength){
                    //                      this.SitePolls.push(pollobj)   
                    //                      if(this.SitePolls.length == forumlength){
                    //                             this.$emit('PushArray',this.MenuItem,this.SitePolls)
                    //                         }                                         
                    //                     }
                                        
                    //                 }
                    //                 else{
                    //                  replieschanges.forEach(replychange => {
                    //                     if (replychange.type === 'added') {
                    //                         pollcommentobj.Replies.push({
                    //                         ...replychange.doc.data(),
                    //                         id: replychange.doc.id
                    //                         })
                    //                     }
                    //                     //console.log(pollobj.id,listlength,pollobj.Comments.length === listlength,replylistlength)
                    //                     if(pollcommentobj.Replies.length === replylistlength){
                    //                         pollobj.Comments.push(pollcommentobj)
                    //                             if(pollobj.Comments.length === listlength){
                    //                             this.SitePolls.push(pollobj)
                    //                                 if(this.SitePolls.length == forumlength){
                    //                                     this.$emit('PushArray',this.MenuItem,this.SitePolls)
                    //                                 }   
                    //                             }
                    //                         }
                    //                     })   
                    //                 }
                                    
                    //                 })
                    //                 }
                                    
                    //             })
                    //         }
                            
                    //   })
                     
                  }
              })
            })
        }
    }

}
</script>

<style>

</style>


    