<template>
  <div>
      <v-card-title class="mediumoverline">
              {{TableName}}: 
          </v-card-title>    
              <v-list-item >
        <v-text-field v-model="itemsearch" label="Search" append-icon="mdi-file-search">
        </v-text-field>
        <v-spacer>
        </v-spacer>
        <!-- <v-rowd
            align="center"
        > -->
            <v-btn v-if="CanAdd" @click="ActivateAddMethod()" outlined >                          
                Add New<v-icon>mdi-plus</v-icon></v-btn>
        <!-- </v-row> -->
        </v-list-item>
      <v-data-table
                    :items-per-page="5"
                    :headers="UserHeaders"
                    :items="ComputedUsersLookupArray"
                    item-key="id"
                    class="elevation-1"
                    :show-select="CanSelect"
                    v-model="SelectedRecords"
                    :show-expand="ShowExpand"
                >
        <template v-slot:[`item.Full_Name`]="{ item }">
            <v-btn plain text @click="CheckifMethod(item)" style="text-transform: inherit;">
                    <v-avatar size="40" v-if="item.Profile_Photo">
                            <img :src="item.Profile_Photo" style="object-fit: cover;" >
                        </v-avatar>
                        <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!item.Profile_Photo">                               
                            {{item.Full_Name.split(' ')[0].substr(0,1)}}{{item.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-avatar>
                        {{item.Full_Name}}
            </v-btn>
                </template>
                <template v-slot:[`item.Alerts`]="{ item }">
                    {{item.Alerts }}
                     <v-icon color="error" v-if="item.Alerts > 0">
                          mdi-alert-circle
                        </v-icon>
                        <v-icon color="success" v-if="item.Alerts === 0">
                          mdi-check
                        </v-icon>
                </template>
                <template v-slot:expanded-item="{  item }">
                        <td class="background" v-if="AdditionalHeaders.length > 0" :colspan="AdditionalHeaders.length">
                        <v-list-item >
                            <v-list-item-content :class="head.class" style="justify-content: end;" v-for="head in AdditionalHeaders" :key="head.itemObjKey">
                                {{head.text}}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-content :class="head.class" style="justify-content: end;" v-for="head in AdditionalHeaders" :key="head.itemObjKey">
                                {{item[head.value]}}
                            </v-list-item-content>
                        </v-list-item>
                        </td>
                        <td class="background" v-if="AdditionalHeaders.length === 0" :colspan="12">
                            </td>
                    </template>
      </v-data-table>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','ModelProp','UsersLookupArray','UserHeaders','FieldLabel','ReadOnly','Rules','RecordObj','TableName','CanSelect','SelectMethod','AdditionalHeaders','ShowExpand','CanAdd'],
    components: {},
    data() {
        return {
            SelectedRecords: [],
            
            itemsearch: '',
            rules: {
          numberfield: value => {
            const pattern = /^[0-9]+$/im;
            return pattern.test(value) || "Not a valid number.";
          },
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        }
    },
    computed:{
        UsersEntity(){
            return this.SystemEntities.find(obj => obj.id === 'Users')
        },
        // UserHeaders(){
        //     return this.UsersEntity.AllFields.map(field => {
        //         let fieldobj = Object.assign({},field)
        //         fieldobj.text = field.DisplayName
        //         fieldobj.value = field.id
        //         fieldobj.class = 'overline'
        //         return fieldobj
        //     })
        // },
        ComputedUsersLookupArray(){
            return this.UsersLookupArray.filter(item => {
                let searchText = this.itemsearch.toLowerCase()
                if(searchText){
                    return item.Email && item.Email.toLowerCase().includes(searchText) || item.Full_Name && item.Full_Name.toLowerCase().includes(searchText) || item.Mobile && item.Mobile.toLowerCase().includes(searchText)
                            }
                else{
                    return item
                }
            })
        },
    },
    created(){

    },
    methods:{
        ActivateAddMethod(){
            this.$emit('AddMethod')
        },
        CheckifMethod(user){
            if(this.SelectMethod){
                this.$emit(this.SelectMethod,user)
            }
        },
        UpdateUserLookupProp(){
            console.log(this.ModelProp,this.RecordObj[this.ModelProp],this.RecordObj)
            this.$emit('UpdateUserLookupProp',this.ModelProp,this.RecordObj[this.ModelProp],this.RecordObj)
        },
        userFilter (item, queryText, itemText) {
          const Email = item.Email.toLowerCase()
          const Full_Name = item.Full_Name.toLowerCase()
          const Mobile = item.Mobile
          const searchText = queryText.toLowerCase()

          return Email.indexOf(searchText) > -1 || Full_Name.indexOf(searchText) > -1
      },
    }
}
</script>

<style>

</style>
