<template>
<div>
  <v-card tile flat>
      <v-card-title class="recordtoolbar justify-space-between white--text mediumoverline">                    
            {{ActiveSession.DisplayName}}    
            <v-icon class="soloactionicon" @click="ToggleAutomationDialog()" dark>mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle style="padding-top:10px;" class="recordtoolbar justify-space-between white--text overline">                    
            <v-icon dark small>mdi-auto-fix</v-icon>Automations
        </v-card-subtitle>
      <v-card-text>
        <v-list-item  class="justify-center" v-for="button in record.AutomationButtons" :key="button.itemObjKey">
            <v-btn :color="ThemeColor" @click="ActivateAutoButton(button,record)" small dark v-on="on">{{button.ButtonName}}</v-btn>
                <!-- <v-list-item-subtitle >{{record}}</v-list-item-subtitle> -->
            
        </v-list-item>
      </v-card-text>
  </v-card>
      <v-dialog max-width="800" persistent v-model="AutomationDialog">	
                           	
                                <v-snackbar v-model="AutomationCreatedSnackbar" :timeout="4000" top color="success">	
                                    <span v-html="AutomationSnackbarDetails"></span>	
                                    <v-btn color="white" @click="AutomationCreatedSnackbar= false">Close</v-btn>	
                                    </v-snackbar>	
                                    <!-- <template v-slot:activator="{ on }">	
                                        <v-btn :color="ThemeColor" @click="whatbtn(Autobutton)" small dark v-on="on">{{Autobutton.ButtonName}}</v-btn>	
                                    </template> -->	
                                    <v-card max-width="800" >	
                                        <v-stepper v-model="automationstep"  non-linear>	
                                            <v-stepper-header><span  v-for="n in AutoSteps" :key="n">	
                                                <v-stepper-step :step="n">	
                                                    {{AutoStepName(n)}}   <v-divider v-if="i !== 3"/>                                         	
                                                </v-stepper-step></span>	
                                            </v-stepper-header>	
                                             <v-stepper-content step="1">	
                                                <v-card flat class="transparent">	
                                                <v-card-title>	
                                                    The Windows you are about to see are:	
                                                </v-card-title>	
                                                <v-card-text>	
                                                    <ul>	
                                                        <li v-for="n in AutoSteps" :key="n">	
                                                            {{AutoStepName(n)}}	
                                                        </li>	
                                                    </ul>	
                                                </v-card-text>	
                                                <v-card-text v-if="Autobutton.Steps && Autobutton.Steps.length>0">	
                                                  <v-list v-if="Autobutton && Autobutton.Steps && Autobutton.Steps.length>0">
                                                        <v-list-item-title class="mx-1 green--text" v-if="Autobutton.Pass">
                                                            This Record Complies with the Required Rules
                                                        </v-list-item-title>    
                                                        <v-list-item-title class="mx-1 red--text" v-if="!Autobutton.Pass">
                                                            This Record does NOT Comply with the Required Rules
                                                        </v-list-item-title>
                                                       <v-list-item v-for="condstep in Autobutton.Steps" :key="condstep.itemObjKey">   
                                                            <v-list-item-content>   
                                                                <v-list-item-title> 
                                                                    Check: <span class="green--text" v-if="condstep.Pass">{{condstep.CheckParams}}</span><span class="red--text" v-if="!condstep.Pass">{{condstep.CheckParams}}</span>  
                                                                </v-list-item-title>    
                                                                <v-list-item-subtitle class="blue--text">   
                                                                   Value: {{condstep.CheckValue}}   
                                                                </v-list-item-subtitle> 
                                                            </v-list-item-content>  
                                                            <v-list-item-avatar>    
                                                                <v-icon :color="condstep.Color">    
                                                                    {{condstep.icon}}   
                                                                </v-icon>   
                                                            </v-list-item-avatar>   
                                                        </v-list-item>
  
                                                    </v-list>   
                                                </v-card-text>	
                                                </v-card>                                               	
                                                </v-stepper-content>	
                                            </v-stepper>	
                                       	
                                        <v-expand-transition>	
                                            <div	
                                                v-if="FormProcessing"	
                                                :class="'d-flex transition-fast-in-fast-out '+ThemeColor+' v-card--reveal mediumoverline white--text'"	
                                                style="height: 100%;"	
                                            >	
                                                <v-progress-circular size="130"	
                                                :width="5"	
                                                :color="ThemeHeaderColor"	
                                                indeterminate	
                                                >Processing</v-progress-circular>	
                                            </div>	
                                            </v-expand-transition>	
                                        <!-- <v-card-title class="title font-weight-regular justify-space-between">	
                                            	
                                                <span>{{ Autobutton.Windowtitle }}</span>	
                                                <v-avatar	
                                                    color="primary lighten-2"	
                                                    class="subheading white--text"	
                                                    size="24"	
                                                    v-text="automationstep"	
                                                ></v-avatar>	
                                                </v-card-title> -->	
                                        <!-- <v-card-title> -->                                            	
                                        <!-- </v-card-title> -->	
                                        <v-window v-model="automationstep">	
                                                <v-window-item :value="1">	
                                                  <v-card-text>  	
                                                   
                                                  </v-card-text>
	
                                                </v-window-item>	
                                               	
                                                <v-window-item  :value="i+2" v-for="(action,i) in AutobuttonResultActions" :key="action.ItemObjKey">
                                                    	
                                                    <v-card-text v-if="action.ActionType === 'Create'">	
                                                        <v-list-item>	
                                                            <v-text-field v-model="action.FormEmailRecipient" :rules="[$store.state.formrules.email]" label="Send Email?"/>	
                                                            <v-select clearable v-model="action.EmailtoUser" return-object :items="UsersArray" @change="UpdateFormEmailRecipient(action)" item-text="Full_Name" label="Email to"/>	
                                                        </v-list-item>	
                                                       <WebFormTab v-if="action.ActionType === 'Create'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	
                                                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"	
                                                            @ConfirmSubmission="ConfirmSubmission" :PageName="action.PageName" @ProcessingData="ProcessingData"	
                                                            :CanHideMappedFormFields="true"
                                                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"	
                                                            :tab="action" :i="i"	
                                                            />	
                                                            	
                                                        </v-card-text>	
                                                        <v-card-title v-if="action.ActionType === 'Update' && action.Record.id === ActiveSession.id">	
                                                           	
                                                            <span v-if="action.ActionType === 'Update' && action.Record.id === ActiveSession.id">	
                                                                Update "{{ProcessRecord[action.Record.RecordPrimaryField.Name]}}"</span>	
                                                        </v-card-title>	
                                                        <v-card-text v-if="action.ActionType === 'Update'">	
                                                            <WebFormTab v-if="action.ActionType === 'Update'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	
                                                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"	
                                                            @ConfirmSubmission="ConfirmSubmission" :PageName="action.PageName" @ProcessingData="ProcessingData"	
                                                            :CanHideMappedFormFields="true"
                                                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"	
                                                            :tab="action" :i="i"	
                                                            />	
                                                             <!-- <v-list-item v-if="action.StatusReasonUpdate">	
                                                              	
                                                        </v-list-item>	
                                                        <v-list-item v-if="action.StatusReasonUpdate">	
                                                            {{action.StatusReasonUpdate}}	
                                                        </v-list-item>	
                                                        <v-list-item v-if="action.FieldMapping">	
                                                            {{action.FieldMapping}}	
                                                        </v-list-item> -->	
	
                                                        </v-card-text>	
                                                </v-window-item>	
	
                                                <!-- <v-window-item :value="3">	
                                                    <v-card-text>	
                                                    <v-select return-object v-model="ActiveProcess" item-text="DisplayName" :items="ActiveSession.EntityProcesses" label="Process"></v-select>	
                                                        <v-select v-if="ActiveProcess" return-object v-model="ActiveStage" item-text="Name" :items="ActiveProcess.ProcessStages" label="Stage"></v-select>	
                                                    </v-card-text>	
                                                </v-window-item>	
                                                <v-window-item :value="4">	
                                                    <v-card-text>	
                                                        This will now update the Record	 Click "{{UnAssignedButton}}" below to confirm
                                                    </v-card-text>	
                                                </v-window-item> -->	
                                                </v-window>	
	
                                                <v-divider></v-divider>	
	
                                                <v-card-actions>	
                                                    <v-btn dark :color="ThemeColor" @click="AutomationDialog = !AutomationDialog,Autobutton = '',ProcessRecord = ''"> Cancel
                                                         	
                                                    </v-btn>	
                                                <v-btn	
                                                    :disabled="automationstep === 1"	
                                                    text	
                                                    @click="automationstep--"	
                                                >	
                                                    Back	
                                                </v-btn>	
                                                <v-spacer></v-spacer>	
	
                                                  <!-- :disabled="automationstep > 1" -->	
                                                
                                                <v-btn v-if="Autobutton && Autobutton.ResultActions"	
                                                    :disabled="AutoAdvanceDisabled"	
                                                    dark	
                                                    :color="UnAssignedButtonColor"	
                                                    depressed	
                                                    @click="AdvanceAutomationButton()"	
                                                >	
                                                    {{UnAssignedButton}}	
                                                </v-btn>	
                                                </v-card-actions>	
                                    </v-card>	
                            </v-dialog>	
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';

export default {
    props: ['System','SystemEntities','record','ActiveSession'],
    components: {
        WebFormTab
    },
    data() {
        return {
            ActiveAction: '',
            Autobutton: '',
            ProcessRecord: '',
            AutomationDialog: false,
            AutomationDialog: false,
            FormProcessing: false,
            AutomationCreatedSnackbar: false,
            AutomationSnackbarDetails: '',           
            AutomationButtons: [],
            automationstep: 1,
             rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
        }
    },
    computed:{
        UsersArray(){
            //console.log(this.$store.state)
            return this.$store.state.UsersArray
        },
        AutobuttonResultActions(){
            if(this.ProcessRecord && this.Autobutton){
            let record = this.ProcessRecord
            let button = this.ProcessRecord.AutomationButtons.find(obj => obj.ButtonName === this.Autobutton.ButtonName)
            return button.ResultActions.map(act => {
                        console.log(act,act.Record)
                        if(act.ActionType === 'Create' || act.ActionType === 'Update'){
                            if(act.ActionType === 'Update'){
                                if(act.Record){
                                    if(act.Record.Relationship === 'Build Self'){
                                        if(this.ProcessRecord){
                                            act.ExistingRecord = this.ProcessRecord
                                        }
                                    }
                                    else if(isNaN(act.Record.RelationshipField)){
                                        act.ExistingRecord = this.ProcessRecord[act.Record.RelationshipField]
                                        //good, this is a method output value
                                    }
                                    else{          
                                        let autoobj = button.ResultActions.find(btnact => btnact.MethodID === act.Record.SourceField) 
                                        act.ExistingRecord = autoobj.Output                            
                                        //nope, the reocrd is from the ProcessRecord
                                    }
                                }
                            }
                            if(!act.EmailtoUser && record.Created_Byid){
                             act.EmailtoUser = this.UsersArray.find(obj => obj.id === record.Created_Byid)
                             act.FormEmailRecipient = act.EmailtoUser.Email  
                            }
                            
                            act.Record.AllFields.map(field => {
                            let matchobj = act.FieldMapping.find(obj => obj.Name === field.Name)
                            if(matchobj){
                                field.MappedForm = true
                                field.MapObj = matchobj
                            }
                            return field
                        })
                        act.Windowtitle = 'Select Record Fields'
                        act.FormFields = act.Record.AllFields.filter(field => {
                            return field.MappedForm || field.IsHeader
                        }).map(field => {
                            let fieldobj = Object.assign({},field)
                            return fieldobj
                        })
                        act.EntityForm = true
                        act.RelatedEntity = {
                            id: act.Record.DisplayName.split(' ').join('').toLowerCase()
                        }
                        if(act.RelatedEntity.id === 'activities' && act.ActionType === 'Create'){
                            act.Regarding_Record = {
                            Link:'/'+this.ActiveSession.SingleName.split(' ').join('')+'/'+record.id, Name: record[this.ActiveSession.RecordPrimaryField.Name], Subject: record[this.ActiveSession.RecordPrimaryField.Name], id: record.id
                            }
                            //[this.ActiveSession.RecordPrimaryField.Name]
                            act.Regarding_Type = this.ActiveSession.SingleName.split(' ').join('')
                        }
                        act.PageName = this.$route.name
                        act.FromDatabase = true
                        // act.DefaultOwner = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                        act.Name = 'Web Form'
                        act.BGColor = {hexa: '#ffffff'}
                        act.HeaderColor = {hex: '#000000'}
                        act.FormName = act.MethodName
                        act.FormFields = act.FormFields.map(field => {
                            if(act.RelatedEntity.id === 'activities' && act.ActionType === 'Create' && field.id === 'Type'){
                                //field.Options = field.Options.slice(0,-2)
                                field.Response = field.Options[0]
                                field.MappedField = true
                            }
                            if(field.MappedForm){
                                
                                let finalinput = ''
                                if(field.MapObj.InputType === 'Combined Value'){                                    
                                   field.MapObj.Input.map(input => {
                                       if(input.MapType === 'Custom Text'){
                                           finalinput = finalinput+input.DisplayName
                                       }
                                       else{
                                           finalinput = record[input.ParentInput.Name]
                                           
                                       }
                                       
                                    })
                                    field.Response = finalinput
                                }
                                else{
                                   //console.log('but when') 
                                if(field.MapObj.source && !isNaN(field.MapObj.source.RelationshipField)){
                                    //console.log('automation workflow based right here',field.MapObj.source.RelationshipField,button.ResultActions)
                                    let autoobj = button.ResultActions.find(btnact => btnact.MethodID === field.MapObj.source.RelationshipField)
                                    //console.log(autoobj)
                                    //outstanding is
                                    //1. Syncronize "next" and "submit" to respect automation sequence. Perhaps "finish" is needed if at resultacitons length end
                                    //2. update the record with "automation count integer", then afterwards we can consider "once off only" check ConfirmSubmission
                                    if(autoobj && autoobj.Output){
                                        //console.log('computed for next step', autoobj.Output)
                                        let parinput = field.MapObj.Input[0]
                                        //console.log('parinput', parinput,field)
                                        // console.log(parinput.Name,record) 
                                        let valueobj = autoobj.Output[parinput.Name]
                                        //console.log('from parinput',valueobj)
                                        if(field.Type === 'Lookup'){
                                            valueobj = {
                                                [field.MapObj.LookupFieldName]: autoobj.Output[parinput.Name],
                                                id: autoobj.Output.id
                                            }
                                            field.Array = []
                                            field.Array.push(valueobj)
                                        }
                                        if(field.Type === 'Date'){
                                            if(autoobj.Output[parinput.Name].toDate){
                                            valueobj = format(autoobj.Output[parinput.Name].toDate(),'yyyy-MM-dd')
                                            }
                                        }
                                        field.Response = valueobj
                                    }
                                    
                                }
                                else if(field.MapObj.source && isNaN(field.MapObj.source.RelationshipField)){
                                    let parinput = field.MapObj.Input[0]
                                    let source = field.MapObj.source
                                    let sourcefield = field.MapObj.source.RelationshipField
                                    let valueobj = ''
                                    //console.log('parinput source sourcefield', parinput,source,sourcefield,record[sourcefield])
                                    if(record[sourcefield]){
                                     if(source.Relationship === 'Lookup Field Build'){                                         
                                         let collection = source.DisplayName.split(' ').join('').toLowerCase()
                                         let luvalue = record[sourcefield]
                                         let parentobj = ''
                                        let storearray = source.DisplayName.split(' ').join('')+'Array'
                                            let storequery = source.DisplayName.split(' ').join('')+'Query'
                                            //console.log(this.$store.state[storearray],this.$store.state[storequery])
                                            if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                                                parentobj = this.$store.state[storearray].find(obj => obj.id === luvalue.id)
                                               // console.log('parinput source sourcefield parentobj',parentobj)
                                            }
                                            else if(this.$store.state[storequery]){
                                                // this.$store.state[storequery].where(firebase.firestore.FieldPath.documentId(), '==', luvalue.id).onSnapshot(res => {
                                                    db.collection(collection).doc(parentobj.id).onSnapshot(snapshot => {
                                                    let snapdata = snapshot.data()
                                                    if(snapdata){
                                                        parentobj = snapdata
                                                    }
                                                })
                                            }
                                            if(parentobj && parinput.Type === 'Lookup'){
                                                valueobj = parentobj[parinput.Name]
                                                field.Array = []
                                                field.Array.push(valueobj)
                                                field.Response = valueobj
                                            }
                                            else{
                                                valueobj = parentobj[parinput.Name]
                                                field.Response = valueobj
                                            }
                                        }    
                                    }
                                }
                                else{
                                    let parinput = field.MapObj.Input[0]
                                    //console.log(parinput.Name,record) 
                                    let valueobj = record[parinput.Name]
                                    //console.log(valueobj) 
                                    if(field.Type === 'Lookup'){    
                                       // console.log(field)   
                                        if(field.RelatedBuildID === this.ActiveSession.id){
                                        valueobj = {
                                            [parinput.Name]: record[parinput.Name],
                                            id: record.id
                                        }
                                        }                          
                                        field.Array = []
                                        field.Array.push(valueobj)
                                    }
                                    if(field.Type === 'Date'){
                                        if(record[parinput.Name].toDate){
                                        valueobj = format(record[parinput.Name].toDate(),'yyyy-MM-dd')
                                        }
                                    }
                                    field.Response = valueobj   
                                }
                                
                            }
                            }
                            
                            return field
                        })
                        .filter(field => {
                            if(act.ActionType === 'Update' && act.StatusUpdate){
                            return field.MappedForm || field.Name === 'Status' || field.Name === 'Status_Reason'
                            }
                            else if(act.ActionType === 'Update' && !act.StatusUpdate){
                            return field.MappedForm
                            }
                            else{
                             return field.Response || field.IsHeader   
                            }
                        })
                        .map(field => {
                            if(field.Type === 'Lookup' && !field.MappedForm){
                              //console.log('wait a minut mate, we need to assign arrays fror lookup types',field)  
                            //   field.Array = this.$store.state[field.RelatedRecord+'Array'] 
                            }                            
                            return field
                        })
                        let actionrecord = act.Record
                        if(actionrecord){
                            let entity = this.SystemEntities.find(obj => obj.id === actionrecord.id)
                            if(entity){
                               let statusfield = entity.AllFields.find(obj => obj.Name === 'Status')
                                if(statusfield){
                                    let formstatus = act.FormFields.find(obj => obj.Name === 'Status')
                                      
                                    if(act.StatusUpdate && formstatus){                                    
                                        formstatus.Response = act.StatusUpdate  
                                        formstatus.Level1Response = act.StatusReasonUpdate 
                                        act.StatusDefault = act.StatusUpdate
                                        act.Status_ReasonDefault = act.StatusReasonUpdate    
                                        //console.log('statusfield '+act.MethodName+' formstatus StatusUpdate',statusfield,formstatus)                            
                                    }
                                    else{
                                        //console.log('statusfield '+act.MethodName+' formstatus NOStatusUpdate',statusfield,formstatus)
                                        act.StatusDefault = statusfield.DefaultOption
                                        act.Status_ReasonDefault = statusfield.DefaultLevel2Option
                                    }
                                    
                                }
                            }
                        }
                        
                        
                        // .filter(field => {
                        //     return field.Name !== 'Status' && field.Name !== 'Status_Reason'
                        // })
                        //RecordHeaderFields
                        //FieldMapping
                        //pass to a prop called FormFields
                        //may need PageHeadersFontSize
                        
                        }
                        console.log('act',act)
                        return act
                    })
            }
            
        },
        AutoSteps(){
            if(this.Autobutton && this.Autobutton.ResultActions){
                return this.Autobutton.ResultActions.length-1+2
            }
        },
        AutoAdvanceDisabled(){
            if(this.automationstep === 1 && this.Autobutton.Pass){
                return false
            }
            else if(this.automationstep > this.Autobutton.ResultActions.length-1+2){
                return true
            }
            else{
                return typeof this.Autobutton.ResultActions[this.automationstep-2] === 'undefined' || this.Autobutton.ResultActions[this.automationstep-2] && typeof this.Autobutton.ResultActions[this.automationstep-2].Output === 'undefined'
            }
        },
        AutomationWindowtitle(){
           switch (this.automationstep) {
                case 1: return 'Validation'
                case 2: return 'Confirmation'
                case 3: return 'Process Actions'
                default: return 'Automation'
            }
        },
        UnAssignedButtonColor(){
           switch (this.unassignedstep) {
                case 4: return 'red'
                default: return 'green'
            }
        },
        UnAssignedButton(){
           switch (this.unassignedstep) {
                case 4: return 'Process'
                default: return 'Next'
            }
        },
        
    },
    created(){

    },
    methods:{
        ToggleAutomationDialog(){
            this.$emit('ToggleAutomationDialog')
        },
        ActivateAutoButton(button,record){
            console.log(button)
            let vm = this
            this.unassignedstep = 1
            
            this.ProcessRecord = Object.assign({},record)
            this.ProcessRecord.AutomationButtons = this.PrepareRecordButtonResponses(this.ProcessRecord,button.ButtonName)
            this.Autobutton = this.ProcessRecord.AutomationButtons.find(obj => obj.ButtonName === button.ButtonName)
            //console.log(this.Autobutton,this.ProcessRecord)
            this.AutomationDialog = true
            this.Autobutton.ResultActions.map(action => {
                if(action.Record){
                    let entityobj = this.SystemEntities.find(obj => obj.id === action.Record.id)
                    action.Record.AllFields = entityobj.AllFields
                }
                if(action.FormFields){
                    action.FormFields.map(field => {
                        if(!field.MappedForm && field.Type === 'Lookup'){
                            let arrayname = ''
                            //console.log('this.$store.state',this.$store.state)
                            if(field.RelatedBuildID === 'Users'){
                                arrayname = 'Users'
                            }
                            else{
                                arrayname = field.RelatedRecord.split(' ').join('')+'Array'
                            }
                            if(this.$store.state[arrayname].length>0){
                               field.Array = this.$store.state[arrayname] 
                            }
                            else{
                                let payload = {                
                                query: this.$store.state[field.RelatedRecord.split(' ').join('')+'Query'],
                                arraymutation: 'set'+arrayname,
                                getter: 'get'+arrayname,
                                arrayname: arrayname
                                }
                                this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                                    //console.log(vm.$store.state[arrayname])
                                    field.Array = vm.$store.state[arrayname]
                                    })
                            }
                        }
                    })
                }
            })
        },
        PrepareRecordButtonResponses(record,ButtonName){
            return record.AutomationButtons
            .filter(btn => {
                return btn.ButtonName === ButtonName
            })
            .map(btn => {
                let button = Object.assign({},btn)
                    //console.log(button)
                    button.Steps = button.Steps.map(condstep => {
                        if(condstep.LookupParameter === 'Preset Options'){
                            //bob this only works forlookup buiols being the check,m and then refer to lookupbuild, not this...
                            let fieldcheck = condstep.FieldtoCheck
                            let valuecheck = ''
                            if(fieldcheck.Type === 'Lookup' && record[fieldcheck.Name]){
                                valuecheck = record[fieldcheck.Name][fieldcheck.LookupFieldName]
                            }
                            else if(fieldcheck.Type === 'Option Set' && record[fieldcheck.Name]){
                                valuecheck = record[fieldcheck.Name].Name
                            }
                            else if(condstep.FieldtoCheck.Type === 'Option Set' && !record[fieldcheck.Name]){
                                    valuecheck = 'No Value in '+fieldcheck.Name
                                }
                            
                            else{
                                valuecheck = record[fieldcheck.Name]
                                
                            }
                            if(record[condstep.FieldtoCheck.Name] && condstep.Value === 'Contains Data'){
                                condstep.Pass = true
                                condstep.icon = 'mdi-check'
                                condstep.Color = 'green'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                            else if(!record[condstep.FieldtoCheck.Name] && condstep.Value === 'Does Not Contain Data'){
                                condstep.Pass = true
                                condstep.icon = 'mdi-check'
                                condstep.Color = 'green'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                            else if(record[condstep.FieldtoCheck.Name] && condstep.Value === '==='){
                                if(condstep.FieldtoCheck.Type === 'Option Set' && record[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                                    condstep.CheckParams = record[condstep.FieldtoCheck.Name].Name+' '+condstep.Value.Name
                                    condstep.Pass = true
                                    condstep.icon = 'mdi-check'
                                    condstep.Color = 'green'
                                    condstep.CheckValue = valuecheck
                                }                                
                            }
                            else {
                                condstep.Pass = false
                                condstep.icon = 'mdi-cancel'
                                condstep.Color = 'red'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value                                
                                condstep.CheckValue = valuecheck
                            }
                        }
                        
                        else{
                            let fieldcheck = condstep.FieldtoCheck
                            let valuecheck = ''
                            let checkrecord = ''
                            let value = ''
                            if(condstep.Record && condstep.Record.Relationship === 'Lookup Field Build'){
                                checkrecord = record[condstep.Record.RelationshipField]
                            }
                            else{
                                checkrecord = record
                            }
                            value = checkrecord[fieldcheck.Name]
                            console.log('condstep value checkrecord',condstep,value,checkrecord)
                            if(fieldcheck.Type === 'Lookup' && value){
                                valuecheck = value[fieldcheck.LookupFieldName]
                            }
                            else if(fieldcheck.Type === 'Option Set' && value){
                                valuecheck = value.Name
                            }
                            else if(condstep.FieldtoCheck.Type === 'Option Set' && !value){
                                    valuecheck = 'No Value in '+fieldcheck.Name
                                }
                            else if(condstep.FieldtoCheck.Type === 'Boolean'){
                                
                                if(typeof value === 'undefined' && condstep.Parameter !== 'false or undefined'){
                                    valuecheck = 'No Value in '+fieldcheck.Name 
                                }
                                else if(typeof value === 'undefined' && condstep.Parameter === 'false or undefined'){
                                    valuecheck = 'No Value in '+fieldcheck.Name
                                    condstep.Pass = true
                                }
                                else if(!value && condstep.Parameter === 'false or undefined'){
                                    valuecheck = value
                                    condstep.Pass = true
                                }
                                else if(!value && condstep.Parameter === 'false'){
                                    condstep.Pass = true
                                    valuecheck = value
                                }
                                else if(value && condstep.Parameter === 'true'){
                                    condstep.Pass = true
                                    valuecheck = value
                                }                                
                                if(condstep.Pass){
                                    condstep.icon = 'mdi-check'
                                    condstep.Color = 'green'
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter
                                    condstep.CheckValue = valuecheck
                                }
                                else{
                                    condstep.Pass = false
                                    condstep.icon = 'mdi-cancel'
                                    condstep.Color = 'red'                                    
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter                                
                                    condstep.CheckValue = valuecheck
                                }                           
                            }
                            else{
                                valuecheck = value
                                
                            }
                            if(checkrecord[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Contains Data'){
                                condstep.Pass = true
                                condstep.icon = 'mdi-check'
                                condstep.Color = 'green'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                            else if(!checkrecord[condstep.FieldtoCheck.Name] && condstep.Parameter === 'Does Not Contain Data'){
                                condstep.Pass = true
                                condstep.icon = 'mdi-check'
                                condstep.Color = 'green'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                                condstep.CheckValue = valuecheck
                            }
                            else if(condstep.Parameter === '==='){
                                if(condstep.FieldtoCheck.Type === 'Option Set' && checkrecord[condstep.FieldtoCheck.Name] && checkrecord[condstep.FieldtoCheck.Name].ID === condstep.Value.ID){
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                                    condstep.Pass = true
                                    condstep.icon = 'mdi-check'
                                    condstep.Color = 'green'
                                    condstep.CheckValue = valuecheck
                                } 
                                else if(condstep.FieldtoCheck.Type === 'Option Set' && checkrecord[condstep.FieldtoCheck.Name]){
                                    condstep.Pass = false
                                    condstep.icon = 'mdi-cancel'
                                    condstep.Color = 'red'
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                                    condstep.CheckValue = valuecheck
                                }  
                                else if(condstep.FieldtoCheck.Type === 'Option Set' && !checkrecord[condstep.FieldtoCheck.Name]){
                                    condstep.Pass = false
                                    condstep.icon = 'mdi-cancel'
                                    condstep.Color = 'red'
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name                                
                                    condstep.CheckValue = valuecheck
                                }                             
                            }
                            else if(condstep.FieldtoCheck.Type !== 'Boolean'){
                               condstep.Pass = false
                                condstep.icon = 'mdi-cancel'
                                condstep.Color = 'red'
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value                                
                                condstep.CheckValue = valuecheck 
                            }
                        }
                        return condstep
                    })
                    let unpassedlength = button.Steps.filter(step => {
                        return step.Pass === false
                    }).length
                    if(unpassedlength>0){
                        button.Pass = false
                    }
                    else{
                        button.Pass = true
                    }
                    
                    button.ResultActions = button.ResultActions.map(act => {
                        //console.log(act,act.Record)
                        if(act.ActionType === 'Create' || act.ActionType === 'Update'){
                            if(act.ActionType === 'Update'){
                                if(act.Record){
                                    if(act.Record.Relationship === 'Build Self'){
                                        if(this.ProcessRecord){
                                            act.ExistingRecord = this.ProcessRecord
                                        }
                                    }
                                    else if(isNaN(act.Record.RelationshipField)){
                                        //good, this is a method output value
                                    }
                                    else{
                                        //nope, the reocrd is from the ProcessRecord
                                    }
                                }
                            }
                            if(!act.EmailtoUser && record.Created_Byid){
                             act.EmailtoUser = this.UsersArray.find(obj => obj.id === record.Created_Byid)
                             act.FormEmailRecipient = act.EmailtoUser.Email  
                            }
                            
                            act.Record.AllFields.map(field => {
                            let matchobj = act.FieldMapping.find(obj => obj.Name === field.Name)
                            if(matchobj){
                                field.MappedForm = true
                                field.MapObj = matchobj
                            }
                            return field
                        })
                        act.Windowtitle = 'Select Record Fields'
                        act.FormFields = act.Record.AllFields.filter(field => {
                            return field.MappedForm || field.IsHeader
                        })
                        act.EntityForm = true
                        act.RelatedEntity = {
                            id: act.Record.DisplayName.split(' ').join('').toLowerCase()
                        }
                        act.PageName = this.$route.name
                        act.FromDatabase = true
                        // act.DefaultOwner = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                        act.Name = 'Web Form'
                        act.BGColor = {hexa: '#ffffff'}
                        act.HeaderColor = {hex: '#000000'}
                        act.FormName = act.MethodName
                        act.FormFields = act.FormFields.map(field => {
                            if(field.MappedForm){
                                
                                let finalinput = ''
                                if(field.MapObj.InputType === 'Combined Value'){                                    
                                   field.MapObj.Input.map(input => {
                                       if(input.MapType === 'Custom Text'){
                                           finalinput = finalinput+input.DisplayName
                                       }
                                       else{
                                           finalinput = record[input.ParentInput.Name]
                                           
                                       }
                                       
                                    })
                                    field.Response = finalinput
                                }
                                else{
                                   //console.log('but when') 
                                if(field.MapObj.source && !isNaN(field.MapObj.source.RelationshipField)){
                                    console.log('automation workflow based right here',field.MapObj.source.RelationshipField,button.ResultActions)
                                    let autoobj = button.ResultActions.find(btnact => btnact.MethodID === field.MapObj.source.RelationshipField)
                                    //console.log(autoobj)
                                    //outstanding is
                                    //1. Syncronize "next" and "submit" to respect automation sequence. Perhaps "finish" is needed if at resultacitons length end
                                    //2. update the record with "automation count integer", then afterwards we can consider "once off only" check ConfirmSubmission
                                    if(autoobj && autoobj.Output){
                                        //console.log('computed for next step', autoobj.Output)
                                        let parinput = field.MapObj.Input[0]
                                        //console.log('parinput', parinput,field)
                                        // console.log(parinput.Name,record) 
                                        let valueobj = autoobj.Output[parinput.Name]
                                        //console.log('from parinput',valueobj)
                                        if(field.Type === 'Lookup'){
                                            valueobj = {
                                                [field.MapObj.LookupFieldName]: autoobj.Output[parinput.Name],
                                                id: autoobj.Output.id
                                            }
                                            field.Array = []
                                            field.Array.push(valueobj)
                                        }
                                        if(field.Type === 'Date'){
                                            if(autoobj.Output[parinput.Name].toDate){
                                            valueobj = format(autoobj.Output[parinput.Name].toDate(),'yyyy-MM-dd')
                                            }
                                        }
                                        field.Response = valueobj
                                    }
                                    
                                }
                                else{
                                    let parinput = field.MapObj.Input[0]
                                    //console.log(act.MethodName,field,parinput,record) 
                                    let valueobj = record[parinput.Name]
                                    //console.log(valueobj) 
                                    if(field.Type === 'Lookup'){    
                                        //console.log(field)   
                                        if(field.RelatedBuildID === this.ActiveSession.id){
                                        valueobj = {
                                            [parinput.Name]: record[parinput.Name],
                                            id: record.id
                                        }
                                        }                          
                                        field.Array = []
                                        field.Array.push(valueobj)
                                    }
                                    else if(field.Type === 'Date'){
                                        if(record[parinput.Name].toDate){
                                        valueobj = format(record[parinput.Name].toDate(),'yyyy-MM-dd')
                                        }
                                    }
                                    field.Response = valueobj  
                                    if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
                                        field.Response = {}
                                        field.FieldBreakdown.map(breakdownfield => {
                                            field.Response[breakdownfield.Name] = record[breakdownfield.Name]
                                        })
                                    }
                                }
                                
                            }
                            }
                            
                            return field
                        })
                        .filter(field => {
                            if(act.ActionType === 'Update'){
                            return field.MappedForm || field.Name === 'Status' || field.Name === 'Status_Reason'
                            }
                            else if(act.ActionType === 'Update' && !act.StatusUpdate){
                            return field.MappedForm
                            }
                            else{
                             return field.Response || field.IsHeader   
                            }
                        })
                        .map(field => {
                            if(field.Type === 'Lookup' && !field.MappedForm){
                              //console.log('wait a minut mate, we need to assign arrays fror lookup types',field)  
                            //   field.Array = this.$store.state[field.RelatedRecord+'Array'] 
                            }                            
                            return field
                        })
                        let actionrecord = act.Record
                        if(actionrecord){
                         let entity = this.SystemEntities.find(obj => obj.id === actionrecord.id)
                            if(entity){
                               let statusfield = entity.AllFields.find(obj => obj.Name === 'Status')
                                if(statusfield){
                                    let formstatus = act.FormFields.find(obj => obj.Name === 'Status')
                                      
                                    if(act.StatusUpdate && formstatus){                                    
                                        formstatus.Response = act.StatusUpdate  
                                        act.StatusDefault = act.StatusUpdate
                                        act.Status_ReasonDefault = act.StatusReasonUpdate    
                                        //console.log('statusfield '+act.MethodName+' formstatus StatusUpdate',statusfield,formstatus)                            
                                    }
                                    else{
                                        //console.log('statusfield '+act.MethodName+' formstatus NOStatusUpdate',statusfield,formstatus)
                                        act.StatusDefault = statusfield.DefaultOption
                                        act.Status_ReasonDefault = statusfield.DefaultLevel2Option
                                    }
                                    
                                }
                            }
                        }
                        
                        
                        // .filter(field => {
                        //     return field.Name !== 'Status' && field.Name !== 'Status_Reason'
                        // })
                        //RecordHeaderFields
                        //FieldMapping
                        //pass to a prop called FormFields
                        //may need PageHeadersFontSize
                        
                        }
                        
                        return act
                    })
                    if(this.automationstep === 1){
                        button.Windowtitle = 'Validation'
                    }
                    
                    else{
                        // let stepcountindex = this.automationstep-2
                        // button.Windowtitle = button.ResultActions[stepcountindex].Windowtitle
                    }
                    
                    return button
                })
        },
        AutoStepName(n){
            if(n === 1){
                return 'Overview'
            }
            else{
            let autoobj = this.Autobutton.ResultActions[n-2]
                if(autoobj){
                    return autoobj.MethodName
                }
            }
        },
        UpdateFormEmailRecipient(action){
            if(action.EmailtoUser){
              action.FormEmailRecipient = action.EmailtoUser.Email
            this.AutomationDialog = false
            this.AutomationDialog = true
            //console.log(action.FormEmailRecipient)  
            }
            
        },
        ProcessingData(boolean){
            this.FormProcessing = boolean
        },
        AdvanceAutomationButton(){
            if(this.Autobutton && this.Autobutton.ResultActions && this.Autobutton.Pass){                
                this.automationstep = this.automationstep-1+2 
                if(this.automationstep > this.Autobutton.ResultActions.length-1+2){ 
                   this.AutomationDialog = false
                   this.Autobutton = ''
                   this.ActiveRecord = ''
                }
                else{
                    this.RefreshDialog()
                }
            } 
            else if(this.Autobutton && this.Autobutton.ResultActions && !this.Autobutton.Pass){
                alert('This Record does not meet the required Criteria')
            }           
        },
        RefreshDialog(){
            this.AutomationDialog = false
            let newrecord = Object.assign(this.ProcessRecord)
            this.automationstep = this.automationstep-1
            this.automationstep = this.automationstep-1+2
            this.ProcessRecord = ''
            this.ProcessRecord = newrecord
            this.AutomationDialog = true 
            
        },
        
        ConfirmSubmission(tab,obj,docid){   
            let button = this.ProcessRecord.AutomationButtons.find(obj => obj.ButtonName === this.Autobutton.ButtonName)
            let action = button.ResultActions[this.automationstep-2]
            action.Output = {...obj.data,id: docid}
            console.log('confirm assigned',action)
            if(action.ActionType === 'Create'){
                console.log(action,this.ProcessRecord,this.ActiveSession)
                let count = 0
                let actionprocessedprop = action.MethodID+'Processed'
                let actionprocesscountprop = action.MethodID+'Count'
                if(this.ProcessRecord[actionprocesscountprop]){
                    count = this.ProcessRecord[actionprocesscountprop]
                }
                count = count-1+2
                console.log('this.ActiveSession.collection this.ProcessRecord.id',this.ActiveSession.collection,this.ProcessRecord.id)
                console.log('db.collection(this.ActiveSession.collection).doc(this.ProcessRecord.id)',db.collection(this.ActiveSession.collection).doc(this.ProcessRecord.id))
                db.collection(this.ActiveSession.collection).doc(this.ProcessRecord.id).update({
                    [actionprocessedprop]: true,
                    [actionprocesscountprop]: count
                })
                this.AdvanceAutomationButton()
            }
            else if(action.ActionType === 'Update'){
                let obj = action.Output
                for(var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if(obj[prop] !== 'undefined' && obj[prop].Type === 'Date'){
                    obj[prop] = new Date(obj[prop].DateNumber)
                    }
                    this.$emit('CheckifClassifiedEntityandStatusUpdate',action.Record,prop,obj,action.ExistingRecord)
                    db.collection(action.Record.collection).doc(action.ExistingRecord.id).update({
                    [prop]: obj[prop]
                    })
                    }
                }
                this.AdvanceAutomationButton()
            }
            else{
                this.AdvanceAutomationButton()
            }
            
            //okay so here we returned the right object as well which is great but in automation chainging, we need to return this object to the automation that requires it
            //to  automation we need to attach the "Output"
            //so firt we get the automation we need and then...well
            //Below a sample of how single code updates to reocrd the automation is done on
            // {action.MethodName.split(' ').join('')}}Processed: true,
            // Process{action.MethodName.split(' ').join('')}}Count: this.Process{action.MethodName.split(' ').join('')}}Count,
            // Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
            // Modified_Byid: this.UserRecord.id,
            // Modified_On: new Date()
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record here`
        },
    }
}
</script>

<style>

</style>
