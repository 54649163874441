<template>
  <div >
    <v-card class="transparent" flat tile
    :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'+tab.BoxGradient ? tab.BoxGradient+'animation: gradientBG 15s ease infinite;background-size: 400% 400%;' : ''">
          <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
        :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
        :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
        />
        <!-- <v-divider inset class="white" >
        </v-divider> -->
            <HTMLDescription
            :tab="tab" :PageContentFont="PageContentFont"
            />
        
        <v-card-text v-if="tab.HasImage">
            <v-layout :class="tab.Justify" row>
            <v-img  v-if="tab.HasImage && tab.IMG"
            :contain="!tab.Clipped"
            :height="tab.Height"
                :src="tab.IMG"
                
                />
                <v-img :contain="!tab.Clipped" v-if="tab.HasImage && !tab.IMG"
            :height="tab.Height"
                src="@/assets/ImageHolder.png"
                />
            </v-layout>
        </v-card-text>

<v-card-actions v-if="tab.HasActionButton && elmnt.ButonColor" :class="tab.ButtonAlignment">
    <v-btn :dark="tab.ActionBtnDark" :to="'/'+tab.ActionButtonRoute" :style="'background-color:'+tab.ButonColor.hex">
        {{tab.ActionButtonName}}
    </v-btn>
</v-card-actions>
<v-card-text class="cardcarouselscene" :style="SceneStyleObject">
   
        <!-- transform: rotateY( 40deg) translateZ(288px); -->
        <!-- width: 100%; position: absolute; transform: translateZ(-687px); transform-style: preserve-3d; transition: transform 1s ease 0s; -->
        <!-- style="width: 100%; position: absolute; transform: translateZ(-288px) rotateY(-40deg); transform-style: preserve-3d; transition: transform 1s ease 0s;" -->
  <v-row class="cardcarousel justify-center" v-if="!tab.FlipCard">
    
    <v-card v-for="elmnt in ComputedElements" :key="elmnt.itemObjKey" @click="ActivateCardDialog(elmnt.OriginalIndex),OpenDialog(elmnt,elmnt.OriginalIndex)"
    :style="elmnt.StyleObject+tab.CustomStyle"
    :class="elmnt.BoxTransparency+' '+elmnt.CustomClass+' mx-3'" :color="elmnt.BGColor.hex" :dark="elmnt.IsDark" :elevation="elmnt.Elevation" :tile="elmnt.Tile"  :outlined="elmnt.Outlined" :shaped="elmnt.Shaped">
    <v-list-item two-line v-if="!elmnt.FullIMG">
             <v-list-item-avatar :style="$vuetify.breakpoint.xs ? 'align-self: center;' : ''"
         v-if="!MiniView && elmnt.HasImage && elmnt.IMG && !elmnt.IMGonTop"
        size="100"
      >
       <img  v-if="elmnt.HasImage && elmnt.IMG"
        :contain="!elmnt.Clipped"
        :height="elmnt.Height"
                :src="elmnt.IMG"
                :style="elmnt.ContainIMG ? 'object-fit: contain;' : 'object-fit: cover;'"
            
                />
                <img :contain="!elmnt.Clipped" :style="elmnt.ContainIMG ? 'object-fit: contain;' : 'object-fit: cover;'" v-if="elmnt.HasImage && !elmnt.IMG"
        :height="elmnt.Height"
                src="@/assets/ImageHolder.png"
                />
      </v-list-item-avatar>
      <v-list-item-content>    
          <v-list-item-avatar
         v-if="$vuetify.breakpoint.sm && elmnt.HasImage && elmnt.IMG && !elmnt.IMGonTop || $vuetify.breakpoint.xs && elmnt.HasImage && elmnt.IMG && !elmnt.IMGonTop"
        size="100"
        class="justify-center"
      >
       <img  v-if="elmnt.HasImage && elmnt.IMG"
        :contain="!elmnt.Clipped"
        :height="elmnt.Height"
                :src="elmnt.IMG"
                :style="elmnt.ContainIMG ? 'object-fit: contain;' : 'object-fit: cover;'"
            
                />
                <img :contain="!elmnt.Clipped" :style="elmnt.ContainIMG ? 'object-fit: contain;' : 'object-fit: cover;'" v-if="elmnt.HasImage && !elmnt.IMG"
        :height="elmnt.Height"
                src="@/assets/ImageHolder.png"
                />
      </v-list-item-avatar>    
        <v-list-item-title v-if="elmnt.Title && !$vuetify.breakpoint.xs" :style="elmnt.CenteredHeader ?
        'text-align: center; padding-bottom: 20px;color:'+elmnt.HeaderColor.hex+';'+HeaderFontFamily(elmnt,elmnt.HeaderFontFamily) : 
        'padding-bottom: 20px;color:'+elmnt.HeaderColor.hex+';'+HeaderFontFamily(elmnt,elmnt.HeaderFontFamily)">
          {{elmnt.Title}}
        </v-list-item-title>
         <HTMLDescription
            :tab="elmnt" :PageContentFont="PageContentFont"
            />
      </v-list-item-content>
    </v-list-item>
    <v-list-item  v-if="!elmnt.FullIMG && elmnt.Title && $vuetify.breakpoint.xs">
        <v-list-item-title :class="elmnt.CenteredHeader ? 'justify-center' : ''" :style="'padding: 0px;color:'+elmnt.HeaderColor.hex+';'+HeaderFontFamily(elmnt,elmnt.HeaderFontFamily)">
          {{elmnt.Title}}
        </v-list-item-title>
    </v-list-item>
    <v-avatar v-if="elmnt.FullIMG" :tile="elmnt.FullIMG" :style="AvatarStyle(elmnt)" >
      <img  v-if="elmnt.HasImage && elmnt.IMG"
                :src="elmnt.IMG"
                :style="elmnt.ContainIMG ? 'object-fit: contain;' : 'object-fit: cover;'"
            
                />
                <img :contain="!elmnt.Clipped" :style="elmnt.ContainIMG ? 'object-fit: contain;' : 'object-fit: cover;'" v-if="elmnt.HasImage && !elmnt.IMG"
                src="@/assets/ImageHolder.png"
                />
      </v-avatar>
    <v-card-title :class="elmnt.CenteredHeader ? 'justify-center' : ''" v-if="elmnt.Title && elmnt.FullIMG" :style="'text-align: center;padding-bottom: 20px;color:'+elmnt.HeaderColor.hex+';'+HeaderFontFamily(elmnt,elmnt.HeaderFontFamily)">
        {{elmnt.Title}}
    </v-card-title>
    <v-card-text v-if="elmnt.FullIMG">
        <HTMLDescription
            :tab="elmnt" :PageContentFont="PageContentFont"
            />
    </v-card-text>
    <v-card-actions v-if="elmnt.HasActionButton && elmnt.ButonColor" :class="elmnt.ButtonAlignment">
    <v-btn :dark="elmnt.ActionBtnDark" :to="'/'+elmnt.ActionButtonRoute" :style="'background-color:'+elmnt.ButonColor.hex">
        {{elmnt.ActionButtonName}}
    </v-btn>
</v-card-actions>
    </v-card>
  </v-row>
  <v-row class="cardcarousel justify-center" v-if="tab.FlipCard">
    <!-- <span  v-for="(elmnt,index) in ComputedElements" :key="elmnt.itemObjKey">
         v-if="elmnt.ShowComponents"
    </span> -->
    <CardRowTab @click="ActivateCardDialog(elmnt.OriginalIndex)" v-for="(elmnt,index) in ComputedElements" :key="elmnt.itemObjKey"
    :style="elmnt.StyleObject" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
        :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"
        :tab="elmnt" @OpenDialog="OpenDialog" :PageID="ActivePageID" :PageName="PageName" :selectedIndex="selectedIndex"
        />
  </v-row>
</v-card-text>
    </v-card>
    

    <v-btn absolute :disabled="!tab.Elements[this.selectedIndex-1] || tab.Elements.length < 4 && !tab.FlipCard" fab top left dark class="accent growleft" style="top: 50%;" @click="selectedIndex--">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn absolute :disabled="!tab.Elements[this.selectedIndex-1+2] || tab.Elements.length < 4 && !tab.FlipCard" fab top right dark class="accent growright" style="top: 50%;" @click="selectedIndex++">
      <v-icon class="mx-10" x-large color="white">mdi-chevron-right</v-icon>
    </v-btn>
   
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import CardRowTab from '@/components/WebPages/RenderComponents/CardRowTab';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTab';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';
export default {
    props: ['System','SystemEntities','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','tabindex'],
    components: {
        TitleTab,
        HTMLDescription,
        CardRowTab
        },
    data() {
        return {
            WindowHeight: 0,
            WindowWidth: 0,   
            cardcarousel: '',
            selectedIndex: 0,
            DialogCardIndex: -1,
        }
    },	
    computed:{
        MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
        ElmntWidth(){
            let lswidth = this.WindowWidth/3
            let pwidth = this.MiniView ? this.WindowWidth/1.2 : this.WindowWidth/2
            //console.log(lswidth)
            return this.Orientation === 'Portrait' ? pwidth : lswidth > 450 ? lswidth : 450 
        },
        Orientation(){
            return this.WindowHeight > this.WindowWidth ? 'Portrait' : 'Landscape'
        },
        SceneStyleObject(){
            if(this.tab.FlipCard || this.tab.IMGonTop){
            if(this.Orientation === 'Portrait'){
                return 'padding-bottom: 100%;padding-top: 40px;'
            }
            else{
                return 'padding-bottom: 40%;padding-top: 40px;'
            }            
            }
            else{
                if(this.Orientation === 'Portrait'){
                return 'padding-bottom: 300px;padding-top: 0px;'
            }
            else{
                return 'padding-bottom: 200px;padding-top: 0px;'
            }
            }
        },
        CarouselStyleObject(){
            var angle = this.selectedIndex / this.cellCount * -360;
            let transform = 'translateZ(-'+this.TranslateZInt+'px) rotateY(' + angle + 'deg)';
            return 'position: absolute;transform: '+transform+';transform-style: preserve-3d;transition: transform 1s;'
        },
        PreviousIndex(){
            let previndex = this.selectedIndex-1
            if(previndex < 0){
                previndex = this.cellCount-1
            }
            return previndex
        },
        NextIndex(){
            let nextindex = this.selectedIndex-1+2
            if(nextindex>this.cellCount-1){
                nextindex = 0
            }
            return nextindex
        },
        LeftIndexes(){
            return this.ComputedElements.filter((elmnt,elmntindex) => {
                return !this.RightIndexes.includes(elmntindex)
            }).map((elmnt,elmntindex) => {
                return elmntindex
            })
        },
        RightIndexes(){
            let array = []
            for (var x = this.selectedIndex-1+2; x < this.cellCount-1;x++){
                array.push(x)
            }
            return array
        },
        // ComputedElements(){
        //     return this.tab.Elements.map((elmntobj,elmntindex) => {
        //         // let elmntobj = Object.assign({},elmnt)
        //         elmntobj.OriginalIndex = elmntindex
        //         //elmntobj.Degrees = elmntindex*this.DegInt
        //         //'transform: rotateY(  '+elmnt.Degrees+'deg) translateZ('+TranslateZInt+'px);width: '+ElmntWidth+'px;position: absolute;height: 500px;transition: 0.5s;'
        //         if(elmntindex === this.selectedIndex){
        //             //elmntobj.StyleObject = 'transform: rotateY(  '+elmntindex*this.DegInt+'deg) translateZ('+(this.TranslateZInt*1.5)+'px);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;z-index:5;'
        //         }
        //         else{
        //             elmntobj.StyleObject = 'transform: rotateY(  '+elmntindex*this.DegInt+'deg) translateZ('+this.TranslateZInt+'px);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;'
        //         }
        //         return elmntobj
        //     }).filter((elmnt,elmntindex) => {
        //         return elmntindex === this.selectedIndex || elmntindex === this.PreviousIndex || elmntindex === this.NextIndex
        //     }).map((elmnt,elmntindex) => {
        //         let elmntobj = Object.assign({},elmnt)
        //          if(elmntobj.OriginalIndex === this.selectedIndex){
        //             elmntobj.StyleObject = 'transform: rotateY(  0deg) translateZ('+(this.TranslateZInt*1.5)+'px);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;z-index:5;'
        //         }
        //         else{
        //             if(elmntobj.OriginalIndex === this.NextIndex){
        //             elmntobj.StyleObject = 'transform: rotateY(  '+(this.DegInt)+'deg) translateZ('+this.TranslateZInt+'px);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;'
        //             }
        //             else if(elmntobj.OriginalIndex === this.PreviousIndex){
        //             elmntobj.StyleObject = 'transform: rotateY(  '+(360-this.DegInt)+'deg) translateZ('+this.TranslateZInt+'px);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;'
        //             }
        //         }
        //         return elmntobj
        //     })
        // },
        CenterMargin(){
            //return (this.WindowWidth/2)-this.ElmntWidth
            return this.ElmntWidth/2
        },
        ComputedElements(){
            return this.tab.Elements.map((elmntobj,elmntindex) => {
                elmntobj.OriginalIndex = elmntindex
                // if(elmntindex === this.selectedIndex){
                //     elmntobj.StyleObject = 'transform: scale(1);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;z-index:5;'
                // }
                // else{
                //     elmntobj.StyleObject = 'transform: scale(0.5);width: '+this.ElmntWidth+'px;position: absolute;height: 300px;transition: 0.5s;z-index:4;'
                // }
                return elmntobj
            }).filter((elmnt,elmntindex) => {
                return elmnt
            }).map((elmntobj,elmntindex) => {
                //let elmntobj = Object.assign({},elmnt)
                //let sidecount = this.cellCount-1
                //let persidecount = sidecount/2
                let sidemin = 100
                let sidemax = 220            
                 if(elmntobj.OriginalIndex === this.selectedIndex){
                    elmntobj.StyleObject = 'transform: scale(1) translateX(-0%)translateY(-10%);width: '+this.ElmntWidth+'px;max-width: 500px;position: absolute;transition: 1.5s;z-index:3;max-height: 100%;'
                }
                else{
                    let translate = 0
                    let zIndex = 1
                    let scale = 0.5
                    let transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(0%);'
                    let maxheight = 'max-height: 250px;overflow: hidden;'
                    if(elmntobj.OriginalIndex === this.NextIndex){
                        maxheight = 'max-height: 500px;overflow: hidden;'
                        translate = sidemin
                        zIndex = 2   
                        scale = 0.7        
                        transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(-25%);' 
                        if(elmntobj.FlipCard){
                            transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(-15%);' 
                        }          
                    }
                    else if(elmntobj.OriginalIndex === this.PreviousIndex){
                        maxheight = 'max-height: 500px;overflow: hidden;'
                        translate = -sidemin
                        zIndex = 2  
                        scale = 0.7  
                        transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(-25%);'  
                        if(elmntobj.FlipCard){
                            transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(-15%);' 
                        }             
                    }
                    else if(this.RightIndexes.includes(elmntobj.OriginalIndex)){
                        //let index = this.RightIndexes.indexOf(elmntobj.OriginalIndex)
                        console.log(sidemax,this.RightIndexes)
                        translate = sidemax
                        transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(0%);'   
                    }
                    else{
                        translate = -sidemax
                        transform = 'transform: scale('+scale+') translateX('+translate+'%)translateY(0%);'   
                    }
                    elmntobj.StyleObject = transform+'width: '+this.ElmntWidth+'px;position: absolute;transition: 1.5s;z-index:'+zIndex+';'+maxheight
                }
                return elmntobj
            })
        },
        TranslateZInt(){
            return this.GetTZ(this.ElmntWidth,this.cellCount)
        },
        cellCount(){
            return this.tab.Elements.length
        },
        DegInt(){
            return 360/this.cellCount
        },
        DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.selectedIndex = Math.ceil(this.tab.Elements.length/2)
         window.onload = setTimeout(() => {
        this.cardcarousel = document.querySelector('.cardcarousel');
        window.addEventListener("resize", this.ResizeWindow);
        //console.log(this.GetTZ(190,9))
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight;
            }, 300);
    },
    methods:{
        ActionButtonOpenDialog(tab,tabindex){
        this.$emit('OpenDialog',tab,tabindex)
      },
      DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
     OpenDialog(tab,tabindex){
        console.log(tab,tabindex,this.rowindex)
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex)
         }
         
     },
        ActivateCardDialog(elmntindex){
           this.selectedIndex = elmntindex
            // else{
            // this.DialogCardIndex = elmntindex
            // }
        },
        ResizeWindow(event){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
      },
        AvatarStyle(tab){
        if(typeof tab.IMGHeight !== 'undefined'){
            //this will cause issues cannot do this must disable imgheight config if style used
          return this.$vuetify.breakpoint.xs ? 'padding: 0px;width: 100%;height:150px;' : 'padding: 0px;width: 100%;height:'+tab.IMGHeight+'px;'
        }
        else{
          return 'padding: 0px;width: 100%;height:150px;'
        }
      },
      NEWrotateCarousel(elmntindex){
        this.selectedIndex = elmntindex
      },
        rotateCarousel(increase,toenlarge) {
            if(increase){
                this.selectedIndex++
            }
            else{
                this.selectedIndex--
            }
        var angle = this.selectedIndex / this.cellCount * -360;
        this.cardcarousel.style.transform = 'translateZ(-'+this.TranslateZInt+'px) rotateY(' + angle + 'deg)';
        if(toenlarge){
            this.ActivateCardDialog(this.selectedIndex)
        }
        },
        GetTZ(cellSize,numberOfCells){
            var tz = Math.round( ( cellSize / 2 ) /  Math.tan( Math.PI / numberOfCells ) );
            return tz
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },
    }
}
</script>

<style>


</style>



