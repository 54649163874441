
<template>
<v-card-text :id="tab.ElementID+'_Description'"  
:class="tab.FillHeight"  :style="tab.FontBlendMode ? 'mix-blend-mode: '+tab.FontBlendMode+';'+TabFontStyle(tab) : TabFontStyle(tab)">
    <span 
    :class="tab.HiglightInitialLetter === 'Each Paragraph' ? 'magartallp' : tab.HiglightInitialLetter === 'First Paragraph' ? 'magartfirstp' : ''"
    v-html="tab.Description"                                
    :style="DefaultPageFont+'color:'+tab.FontColor.hex+';font-size:'+tab.FontSize+'px;'"/>
</v-card-text>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex'],
    components: {

        },
    data() {
    return {      
        RSSitems: [],
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
        //this.fetchRSS()

    },
    
    methods: {
        TabFontStyle(tab){
          let style = ''
          if(tab.FontBGColor && !tab.FontUnderline){
            if(this.MiniView){
              // sorry but width: 80%; has gotta go...eventually but for now yes I get it, too damn hard to figure out
              style = 'background-color: '+tab.FontBGColor+';width: 80%;'
            }
            else{
              // sorry but width: 50%; has gotta go
              style = 'background-color: '+tab.FontBGColor+';'
            }
          }
          else if(tab.FontUnderline && tab.FontUnderlineColor){
            style = 'background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;'
          }
          if(tab.WriteupStyle){
            style = style+tab.WriteupStyle
          }
          if(tab.AnimationCSS && tab.AnimationCSS.Description && tab.Animations.Description && tab.AnimationsActive){
            style = style+tab.AnimationCSS.Description
          }
          //console.log(style)
          return style
        },

    }    
}
</script>

<style>


</style>

        

    