
<template>
<v-card flat :class="$route.name !== 'GroupSingle' && !NoticeSelect ? 'transparent' : 'transparent'" width="100%" height="100%" 
>
<!-- :flat="NoticeSelect" -->
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">Polls</h4>
              </v-col>
            </v-row>
            </v-parallax> 
    <v-dialog v-model="OptionSetDialog" width="800px">
  <v-card class="slightlypadded">
    <v-img src='@/assets/RABaseBG.jpeg'>
      <v-card-title class="headline blue-grey darken-2 white--text">
                {{PollName}} - Configuration <v-layout class="justify-end">
      <v-btn fab @click="SaveOptionSets()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelOptionSetDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn>
      </v-layout>
                </v-card-title>
     
  <v-layout row  color="rgb(236, 60, 126, 0.7)">
      
    <v-col cols="4">
      <v-list>
        <v-list-item>
          <v-list-item-content class="listtitle">
          Options
          </v-list-item-content>
          <v-list-item-action>
            <v-menu 
          :close-on-content-click="false"                            
          transition="scale-transition"                            
          >
          <template v-slot:activator="{ on }">
              <v-btn fab icon v-on="on"><v-icon>mdi-plus-thick</v-icon></v-btn>
            </template>
            <v-card width="400px">
              <v-card-title>
                New Option for {{EditOptionSet.Name}}
                </v-card-title>
                <v-text-field v-model="OptionName" label="Name"></v-text-field>                                                        
                  <v-card-actions>
                      <v-btn v-if="OptionName !== ''" @click="AddOptionSetOption()">Save</v-btn><v-btn @click="CancelGlobalOptionSetOption()">Cancel</v-btn>
                  </v-card-actions>
              </v-card>
          </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        
        <v-list>
        <v-list-item v-for="optionsetoption in PollOptions.Options" :key="optionsetoption.itemObjKey" class="listoutline" @click="OptionNameSelect(optionsetoption)">
          <v-list-item-content>
            {{optionsetoption.Name}}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <!-- <v-scroll-y-transition mode="in-out" v-if="EditOptionSet.Name"> -->

            <v-card outlined  height="100%" width="100%" color="rgb(255, 255, 255, 0.9)">                                
                <v-card-title class="headline blue-grey darken-2 white--text" v-if="Option.Name">
                Option - {{Option.Name}}
                  <!-- <v-spacer></v-spacer>
                  <v-switch dark
                    v-model="Option.IsDefault"
                    class="mx-2"
                    label="Is Default"
                  ></v-switch> -->
                </v-card-title>
                <v-card-text >
                  <p v-if="Option.ID">Option ID: {{Option.ID}}</p>
                  <v-text-field label="Option Name" v-model="Option.Name" v-if="Option.Name"></v-text-field>
                  <v-select label="Option Type" v-model="Option.Type" :items="['Image','URL']" v-if="Option.Name"></v-select>
                  <v-text-field  :rules="[$store.state.formrules.url,$store.state.formrules.required]" v-model="Option.Name" v-if="Option.Name && Option.Type === 'URL'"></v-text-field>
                  <v-layout class="justify-end" v-if="Option.Name"><v-icon @click="DeleteOption(Option)" color="red">mdi-delete-forever</v-icon></v-layout>                                                       
                </v-card-text>
                <v-card-text v-if="typeof Option.Name === 'undefined'">
                  Add an option to the left<span v-if="PollOptions.Options.length>0">, or select an existing option to change its name</span>...                                                    
                </v-card-text>
                                        
            </v-card>
                                
            <!-- </v-scroll-y-transition> -->
        </v-col>
    </v-layout>
    
    <!-- <v-layout class="justify-end">
      <v-btn fab @click="SaveGlobalOptionSets()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn>
      </v-layout> -->
      <v-layout class="justify-end">
      <v-btn fab @click="DeleteFieldOffTabSectionANDRecordType(EditTab,EditSection,EditOptionSet)" icon><v-icon color="red" x-large>mdi-delete-forever</v-icon></v-btn>
      </v-layout>
      </v-img>
      
    </v-card>
  </v-dialog>
  <v-layout class="justify-center">
<v-card flat class="transparent" width="90%" v-if="!NoticeSelect">
    <v-layout class="justify-end">
      <v-btn outlined color="accent" @click="OpenAboutDialog('AboutSitePolls')" v-if="$store.state.TemplateView"><v-icon>mdi-help</v-icon>About Polls</v-btn>
    <v-btn outlined color="accent"  :to="SingleRouteNewPrefix" v-if="UserIsModerator || CanCreate || UserisTemplateOwner"><v-icon>mdi-plus</v-icon>New Poll</v-btn>
    </v-layout>
    <v-card  v-for="forum in GroupPollsTimeline" :key="forum.itemObjKey" elevation="3" :to="SingleRoutePrefix+forum.id">
       
        <v-list three-line class="my-3 white">
            <v-list-item>
                <v-list-item-avatar>
              <img v-if="forum.creatorimg"
                :src="forum.creatorimg"
                :alt="forum.creatorname"
                 :href="'/SiteMember/'+forum.Creatorid"
            >
            <img v-if="!forum.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="forum.creatorname"
                 :href="'/SiteMember/'+forum.Creatorid"
            >
            
          </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title v-if="forum.PollName" v-html="forum.PollName"></v-list-item-title>
                <v-list-item-title v-if="!forum.PollName" v-html="forum.PollDescription.substr(0,50)"></v-list-item-title>
                <v-list-item-subtitle v-html="forum.PollDescription.substr(0,450)"></v-list-item-subtitle>
                </v-list-item-content>
               
                <v-list-item-action style="font-size:12px">
                  Voting Closes - 
                    <span v-if="forum.PollEndDate.toDate">{{forum.PollEndDate}}</span>
                    <span v-else>{{forum.PollEndDate}}</span>
                </v-list-item-action>
            
                <!-- <v-list-item-action style="font-size:12px">
                    <v-badge dark overlap>
                    <v-icon color="blue" @click="LikePost(post)">mdi-thumb-up-outline</v-icon>
                    <span slot="badge"> {{forum.Likes.length}} </span>
                </v-badge>
                </v-list-item-action>
                <v-list-item-action style="font-size:12px">
                    <v-badge dark overlap>
                    <v-icon color="orange">mdi-comment-outline</v-icon>
                    <span slot="badge"> {{forum.Comments.length}} </span>
                </v-badge>
                </v-list-item-action> -->
               
            </v-list-item>

        </v-list>        
    </v-card>
  

</v-card>
  </v-layout>
   <v-select v-if="NoticeSelect" @change="AddSocialItemtoNotice()" :items="GroupPollsTimeline" item-text="PollName" v-model="selectedSocialItem" return-object label="Poll"/>
</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
    props: ['CanCreate','NoticeSelect','System','GroupData','Editor'],
    components: {
    
        },
    data() {
        return {
          InternalLinkType: '',
            LinkComponent: '',
            SharedCollection: '',
            selectedSocialItem: '',
            NoticeCoverIMGProp: 'coverimage',
            rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
            PollEndDate: '',
            PollEndDatemenu: false,
            PollOptions:{Options: []},
            PollDescription: '',
            EditingTestingWebForm: false,
            PollName: '',
            PollsForm: {Name: 'Poll Name'},
            WebFormObject: '', 
            OptionSetDialog: false,     
            EditOptionSet: '',
            Option: {},
            OptionName: '',
            DiscussionTitle: '',
            NewDiscussionDialog: false,
            Forums: [],
          NewCommentInput: '',
          NewReplyInput: '',
            GroupPolls: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
            UserisModerator: false,
            SiteModerators: [],
        }
        
    },

    created(){
       document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      
      
      // this.CheckAuth() hells no
      if(this.$store.state.TemplateView){
        this.GetSamples(this.ContentColPath,'GroupPolls')
      }
      else{
        this.CheckRouting()
        this.IntranetViewToggle()
      }     
      
    //   this.GetSocialComments()
    //   this.GetSocialLikes()
    //   this.GetSocialReplies()


    },

    watch: {
    // WritingPost (value) {
    //   if (value !== null && value !== undefined && value === true) {
    //     // this.inputwidth = '90%'
    //     // let postelmnt = document.getElementById('postinput')
    //     setTimeout(() => {
    //         this.postelmnt.focus()
    //       }, 100); 

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
  },

    computed: {
      SingleRoutePrefix(){
        //$route.name !== 'GroupSingle' ? '/SitePoll/'+forum.id : '/Group-Poll/'+$route.params.id+'/Poll/'+forum.id
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/SitePoll/'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/SitePoll/'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/SitePoll/' : '/Group-Poll/'+this.$route.params.id+'/Poll/'
        }
      },
      SingleRouteNewPrefix(){
        if(this.ActiveSuiteTemplate){
          return '/Your-Domain/'+this.ActiveSuiteTemplate.id+'/Social-Network/NewPoll'
        }
        else if(this.ActiveMarketTemplate){
          return '/MarketplaceTemplate/'+this.$route.params.id+'/NewPoll'
        }
        else{
          return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' ? '/NewPoll' : '/Group/NewPoll/'+this.$route.params.id
        }
      },
      UserisTemplateOwner(){
        return this.$store.state.TemplateOwner
      },    
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
          return this.$store.state.SocialItemInteractMethodProp
      },
      SuiteSocialNetwork(){
            return this.ActiveSuiteTemplate ? this.ActiveSuiteTemplate.SocialNetwork : ''
        },
      RouteName(){
          return this.ActiveMarketTemplate && this.ActiveMarketTemplate.TemplateProps && this.ActiveMarketTemplate.TemplateProps.Name ? this.ActiveMarketTemplate.TemplateProps.Name :
          this.ActiveMarketTemplate.Name ? this.ActiveMarketTemplate.Name : this.System.Name
        },
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      ActiveSuiteTemplate(){
          return this.$store.state.ActiveSuiteTemplate
      },
      ContentCategoriesPath(){
        return this.$route.name === 'GroupNewPoll' ? this.ContentRootPath.collection('groups').doc(this.$route.params.id).collection('PollsCategories') : 
        this.ContentRootPath.collection('PollsCategories')
      },
      ContentRootPath(){
        return this.$store.state.TemplateView ? this.TemplateRootPath : db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid)
      },
      ContentColPath(){
        //quick tip here. Edit and New components reuqire collection to update, or add docs. thus, not interchangeable with CollectionRef on "feed" or "collection view"
        //the CollectionRef would add permission queries like for group aticles "where gourpid...etc"
        return this.$store.state.TemplateView ? this.ContentRootPath.collection('samplesitepolls') : 
        this.$route.name === 'GroupNewPoll' ? this.ContentRootPath.collection('grouppolls') : this.ContentRootPath.collection('sitepolls')
      },
      TemplateRootPath(){
        return this.ActiveSuiteTemplate ? db.collection('SocialNetworks').doc(this.ActiveSuiteTemplate.id) :
        this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
      },
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.$store.state.UserisGuest && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      UserisTeamMember(){
        return this.GroupData && this.GroupData.TeamMembers ? this.GroupData.TeamMembers.find(obj => obj === this.userLoggedIn.id) : false
      },
      UserisGroupMember(){
        return this.GroupData && this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups.find(obj => obj.id === this.GroupData.id) : false
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserIsModerator(){
        if(!this.NonGroupRoute){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.PollsModerator
          }
          if(this.userLoggedIn.ModerationPermissions){
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Polls')
              if(moderator){
                return true
              }
              else{
                return false
              }
          }
          else{
            return this.userIsAdmin
          }
      },
      UserRecord(){
            return this.$store.state.userobj
          },
      ComputedSocialReplies(){
        return this.SocialReplies.map(mainreply => {
          this.SocialReplies.map(reply => {
                    if(reply.relatedid === mainreply.id){
                      let replyonmainreplycheck = mainreply.Replies.find(obj => obj.id == reply.id)
                      if(!replyonmainreplycheck){
                        mainreply.Replies.push(reply)
                      }
                    }
                  })
          return mainreply
        }).map(mainreply => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === mainreply.id){
                      let likeonmainreplycheck = mainreply.Likes.find(obj => obj.id == like.id)
                      if(!likeonmainreplycheck){
                        mainreply.Likes.push(like)
                      }
                    }
                  })
          return mainreply
        })
      },
      ComputedSocialComments(){
        return this.SocialComments.map(comment => {
          this.ComputedSocialReplies.map(reply => {
                    if(reply.relatedid === comment.id){
                      let replyoncommentcheck = comment.Replies.find(obj => obj.id == reply.id)
                      if(!replyoncommentcheck){
                        if(reply.Replies){
                          comment.Replies = comment.Replies.concat(reply.Replies)
                        }
                        comment.Replies.push(reply)
                      }
                    }
                  })
          return comment
        }).map(comment => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === comment.id){
                      let likeoncommentcheck = comment.Likes.find(obj => obj.id == like.id)
                      if(!likeoncommentcheck){
                        comment.Likes.push(like)
                      }
                    }
                  })
          return comment
        })
      },
      GroupPollsTimeline () {
        return this.GroupPolls.map(post => {
          this.ComputedSocialComments.map(comment => {
            if(comment.relatedid === post.id){
              let oncheck = post.Comments.find(obj => obj.id == comment.id)
                if(!oncheck){
                  post.Comments.push(comment)
                }
              }   
          })
          return post
        }).map(post => {
          this.SocialLikes.map(like => {
            if(like.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == like.id)
                if(!oncheck){
                  post.Likes.push(like)
                }
              }   
          })
          return post
        }).map(post => {
          this.ComputedSocialReplies.map(reply => {
            if(reply.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == reply.id)
                if(!oncheck){
                  post.Likes.push(reply)
                }
              }   
          })
          return post
        }).map(post => {          
          if(typeof post.PollEndDate.toDate !== 'undefined'){
            let dataobj = post.PollEndDate.toDate()
            post.PollEndDate = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        }).sort((a, b) => {
            console.log('sorting again')
            console.log(a.PollEndDate)
            var key1 = this.DateFormatterwithTime(a.PollEndDate);
            var key2 = this.DateFormatterwithTime(b.PollEndDate);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
      },

    },

    methods: {
      GetSamples(query,array){        
        query.onSnapshot(res => {
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      this[array].push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                if (change.type === 'modified') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1,itemobj)
                    }
                }
                if (change.type === 'removed') {
                  let itemobj =  {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    let oncheck = this[array].find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = this[array].indexOf(oncheck)
                      this[array].splice(index,1)
                    }
                }
              })
            })
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.selectedSocialItem.id,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this.selectedSocialItem[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this.selectedSocialItem[this.NoticeCoverIMGProp]
        }      
        this.$emit('AddSocialItemtoNotice',socialitem)
      },
      async CheckAuth(){
      let vm = this
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // vm.$store.dispatch('SetUserObj', user.uid)
          vm.CheckRouting()
        }
        else{
          
        }
    })
    },
    CheckRouting(){
      
              let vm = this
        this.GetSiteModerators().then(function(result) {
          if(result){
            console.log('checking if moderator')
            let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
            if(UserModeratorobj){
              if(UserModeratorobj.ModerationPermissions && UserModeratorobj.ModerationPermissions.includes('Polls') || vm.userIsAdmin){
                console.log('yes Polls included')
                vm.UserisModerator = true
              }
            }
            let col = vm.ContentColPath
            if(vm.NonGroupRoute){
              if(!vm.ChannelRestricted || vm.userIsAdmin){
                if(vm.CompanySocial){
                  col = col.where('Companyid','==',vm.UserCompanyid)
                }
                vm.InternalLinkType = 'SitePoll'
                vm.SharedCollection = 'sitepolls'
                vm.LinkComponent = 'SitePollShared'
                if(vm.$store.state.UserisGuest){
                  vm.GetSitePolls(col.where('PublishStatus','==','Published (Public)'))
                  vm.GetSitePolls(col.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
                }
                else{
                  vm.GetSitePolls(col.where('PublishStatus','!=','Draft'))
                  if(vm.userLoggedIn.ModerationPermissions && vm.userLoggedIn.ModerationPermissions.includes('Polls') || vm.userIsAdmin){                  
                    vm.GetSitePolls(col.where('PublishStatus','==','Draft').where('Ownerid','==',vm.userLoggedIn.id))
                  }
                }
              }              
            }
            else{
              vm.InternalLinkType = 'Group-Poll/'+vm.$route.params.id+'/Poll'
              vm.SharedCollection = 'grouppolls'
              vm.LinkComponent = 'GroupPollShared'
              if(vm.UserisTeamMember){
                vm.GetSitePolls(col.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
              }
              else if(vm.userLoggedIn && !vm.$store.state.UserisGuest){
                vm.GetSitePolls(col.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
              }
              else{
                vm.GetSitePolls(col.where('GuestsIncluded','==',true).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
              }   
            }
            
          }
        })
        if(this.$route.params.id){
            console.log('has single')
        }
        },
        GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        vm.ContentRootPath.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
        IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
       
       OptionNameSelect(option){
        this.Option = option
      },
      CancelOptionSetDialog(){

      },
      SaveOptionSets(){
      
        this.OptionSetDialog = false
      },
       AddOptionSetOption(){
      if(typeof this.PollOptions.lastassignedinteger === 'undefined'){
        this.PollOptions.lastassignedinteger = 1000000
        
      }
      
       let integer = this.PollOptions.lastassignedinteger-1+2
      
      let NewOption = {Name: this.OptionName, ID: integer}
      this.PollOptions.Options.push(NewOption)
      this.OptionName = ''
      this.PollOptions.lastassignedinteger = integer
      },
        AddFieldtoPollsForm(){
              if(!this.PollsForm.Fields){
                  this.PollsForm.Fields = []
                }
                let NewField = {}
                this.PollsForm.Fields.push(NewField)
                this.WikiMode = false
                this.WikiMode = true
                this.EditingPollsForm = false
                this.EditingPollsForm = true
            },
        DateFormatter(date){
              if(date){
              let yearstring = date.split('-')[0]
                let monthstring = date.split('-')[1]
                let daystring = date.split('-')[2]
                let yearnumber = Number(yearstring)
                let monthnumber = Number(monthstring)
                let daynumber = Number(daystring)
                return new Date(yearnumber, monthnumber-1, daynumber)
                }
              else{
                return null
              }
      },
          Activatedialog(){
            this.dialog = true
          },
          OpenEditdialog() {
            this.dialog = true
            // $nuxt.$router.push('/new_group/'+this.tempid)
        },
          ToggleEdit(item,dialprop){
          if(this.$store.state.TemplateView && this.Editor && this.$store.state.TemplateOwner){
            this.editedItem = Object.assign({},item)
            this.OpenEditdialog()
          }
          else if(this.$store.state.TemplateView && !this.Editor){
            //Technically now we may need to showcase a "group single" type of "feature boast" just so people get context on power of groups
            this.OpenAboutDialog(dialprop)
          }
          
        } ,  
          OpenAboutDialog(dialprop){      
          this.$store.commit('SetSocialItemInteractMethodProp',dialprop) 
          this.$store.commit('SetSocialItemInteractMethod','ToggleSystemAboutDialog')
        },
      ActivateGalleryDialog(prp){
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp',prp)
      },
       
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        console.log(finaldate)
        return finaldate
      },
      DateFormatterwithTime(date){
      if(date){
        console.log(date)
        console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        var ampm = "am";
     
        console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
        return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
        }
      else{
        return null
      }
},
      
        GetSitePolls(col){
          col.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.GroupPolls.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
        
    },
}

</script>

<style>

</style>