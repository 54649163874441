        <template>
  <v-main class="transparent" style="margin-top: 60px;">
       <v-card class="siteconfigcard">
         <v-card-title class="my-2 BoldBuilderGradient justify-start mediumoverline">
            Goals<v-spacer></v-spacer>
             <v-btn @click="Goaldialog = !Goaldialog">Add</v-btn>
        </v-card-title>
          <v-dialog max-width="600" v-model="Goaldialog">
        <v-card>
            <v-card-title>
                {{formTitle}}
            </v-card-title>
            <v-card-text>
                <v-tabs>
                    <v-tab>Basic
                    </v-tab>
                    <v-tab-item>
                          <v-list class="transparent" dense>
                    <v-list-item>
                        <v-text-field dense v-model="editedItem.Name" label="Name"/>
                    </v-list-item>
                    <v-list-item>
                        <v-textarea dense v-model="editedItem.Description" label="Description"/>
                    </v-list-item>
                     <v-list-item>
                         <v-select v-if="GotEntities" return-object :items="ComputedSystemEntities" item-text="id" label="Entity" v-model="editedItem.PrimaryEntity"/>
                    </v-list-item>
                    <v-list-item>
                         <v-select label="Value Type" return-object :items="['Count','Amount']" item-text="id" v-model="editedItem.ValueType"/>
                    </v-list-item>
                    <v-list-item>
                         <v-select v-if="editedItem.ValueType === 'Amount' && editedItem.PrimaryEntity" label="Value Field" return-object :items="editedItem.PrimaryEntity.NumberFields" item-text="id" v-model="editedItem.ValueField"/>
                    </v-list-item>
                    <v-list-item>
                         <v-select v-if="editedItem.ValueType === 'Amount' && editedItem.PrimaryEntity && editedItem.ValueField" label="Value Type" return-object :items="['ZAR']" item-text="id" v-model="editedItem.ValueCurrency"/>
                    </v-list-item>
                    <v-list-item>
                        <v-select v-if="editedItem.PrimaryEntity" label="Primary Date Filter" return-object :items="editedItem.PrimaryEntity.DateFields" item-text="id" v-model="editedItem.PrimaryDateFilter"/>
                    </v-list-item>
                    <v-list-item>
                        <v-select :items="['Option Set','Lookup','Option Set from Lookup']" v-model="editedItem.PrimaryFilterType" label="Primary Filter Type"/>
                    </v-list-item>
                    <v-select label="Prefilter Field" return-object :items="editedItem.PrimaryEntity.OptionSets" item-text="id" v-model="editedItem.PreFilterField"/>
                     <v-select v-if="editedItem.PreFilterField" label="Prefilter" return-object :items="editedItem.PreFilterField.Options" item-text="Name" v-model="editedItem.PreFilter"/>
                    <v-list-item>
                        <v-select v-if="editedItem.PrimaryFilterType === 'Option Set'" label="Primary Filter Field" return-object :items="editedItem.PrimaryEntity.OptionSets" item-text="id" v-model="editedItem.PrimaryFilterField"/>
                        <v-select v-if="editedItem.PrimaryFilterType === 'Lookup'" label="Primary Filter Field" :items="editedItem.PrimaryEntity.LookupFields" return-object  item-text="id" v-model="editedItem.PrimaryFilterField"/>
                        <v-select v-if="editedItem.PrimaryFilterType === 'Option Set from Lookup'" label="Primary Filter Lookup" :items="editedItem.PrimaryEntity.LookupFields" return-object  item-text="id" v-model="editedItem.PrimaryFilterLookup"/>
                        <v-select v-if="editedItem.PrimaryFilterType === 'Option Set from Lookup' && editedItem.PrimaryFilterLookup" label="Primary Filter Field" return-object :items="GetRelatedOptionSets(editedItem,'PrimaryFilterLookup')" item-text="id" v-model="editedItem.PrimaryFilterField"/>
                    </v-list-item>
                  
                    <!-- <v-list-item>
                        <v-text-field
                        label="Fisal Year" clearable v-model="editedItem.FiscalYear"
                        dense item-text="Name" :items="Fiscalyears"/>      
                    </v-list-item> -->
                    <!-- <v-list-item>
                          
                    </v-list-item> -->
                                      
                       
                </v-list>
                    </v-tab-item>
                     <v-tab>Qualification
                    </v-tab>
                    <v-tab-item>
                        <p>Basically, what qualifies this Goal for Actual</p>
                        <p>Then, what qualifies this Goal In Progress</p>
                    </v-tab-item>
                    <v-tab>Targets
                    </v-tab>
                    <v-tab-item>
                         <v-layout row class="justify-center">
                            <v-card class="transparent" flat width="95%">
                                <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12" style="margin-bottom:-40px;" color="primary white--text">
                                <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white"></v-icon>Target Settings</v-toolbar-title>
                                <v-menu min-width="400" max-width="800" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                <v-btn outlined fab small class="elevation-6" dark>
                                <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>
                                </v-btn>
                                </template>
                                <v-spacer></v-spacer>
                                <v-list>
                                    <v-spacer></v-spacer>
                                   
                                    <v-menu min-width="400" max-width="800" :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">                                   
                                    <v-list-item v-on="on">
                                        <v-list-item-title  class="subtleoverline">
                                            Export Template
                                        </v-list-item-title>
                                        <v-list-item-action>
                                            <v-icon class="actionicon" color="grey">mdi-database-export</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    </template>
                                     <v-card width="400">
                                        <v-card-title>
                                            Export Template
                                        </v-card-title>
                                        <v-card-text class="caption">
                                        <p>Here you get to choose what periods you want to define manually</p>
                                        <p>Goals can be shown per week, month, year, fiscal year, fiscal quarter etc. The main thing here is to define what period you input manually, the rest will calculate automatically</p>
                                        <p> For instance if the Goals you want to set are "per month" then choose "month". The system will calculate the per week and per year and so forth from the values given "per month" </p>
                                        </v-card-text>
                                        <v-card-text>
                                            <v-list>
                                                 <v-list-item>
                                                     <!-- ComputedToday {{ComputedToday}} -->
                                                     <!-- DaysofYear {{DaysofYear}}
                                                     WeeksofYear {{WeeksofYear}} -->
                                                  <v-select :items="FiscalYearOptions" v-model="editedItem.Calendar_Year" label="Choose Calendar Year"/>
                                                </v-list-item>
                                                <v-list-item>
                                                  <v-autocomplete item-text="Full_Name" multiple return-object :items="BasicUsersArray" v-model="editedItem.UserList" label="Users" />
                                                </v-list-item>
                                                 <v-list-item>
                                                  <v-select 
                                                    label="Timeline to Set" clearable return-object v-model="editedItem.ExportTimelineType"
                                                    dense item-text="GiveName" :items="TimelineFilterOptions"/>  
                                                </v-list-item>                                                
                                                <!-- <v-list-item>
                                                  <v-autocomplete v-if="editedItem.ExportTimelineType"
                                                    label="Filter Options" multiple clearable return-object v-model="editedItem.TimelineFilterOptions"
                                                    dense item-text="FilterName" :items="ItemBasedTimelineFilterOptions">
                                                    <template v-slot:item="{ item }">
                                                        <span><span style="font-weight: bold;">{{item.FilterName}}</span>
                                                            <p style="font-size:12px;color:red;font-weight:thin;">{{item.FilterDescription}}
                                                            </p>
                                                            </span>
                                                    </template>
                                                  </v-autocomplete>
                                                </v-list-item> -->
                                               
                                               
                                            </v-list>
                                        </v-card-text>
                                        <v-card-text>
                                            <input v-if="!ImportFile"
                                                type="file"
                                                @change="onImportfileSelected($event)"
                                                ref="ImportFileinputter"
                                                id="fileUpload"
                                                >
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer>
                                            </v-spacer>
                                            <v-btn  v-if="!ImportFile" @click="PrepareExport()">Export</v-btn>
                                             <v-btn  v-if="ImportFile" @click="saveImport()">Import</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-menu>
                                </v-list>
                               
                            </v-menu>
                                </v-toolbar>
                            </v-card>
                         </v-layout>
                        <v-data-table
                        
                        :dark="AppisDarkMode"
                        :headers="TargetHeadersDisplay"
                        :items="editedItem.Targets"
                        class="elevation-1"
                        >
                        <template v-slot:top>
                        <div style="padding-top:40px;"/>
                    </template>
                        <template v-slot:[`item.Target`]="{ item }">
                            <v-text-field v-if="item.OriginalImport" v-model="item.Target" label="Target" dense type="number"/>
                            <span v-if="!item.OriginalImport" >{{item.Target}}</span>
                            </template>
                       </v-data-table>
                    </v-tab-item>
                </v-tabs>
              
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelGoal()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="SaveGoal()">Save</v-btn>
            </v-card-actions>
        </v-card>
          </v-dialog>
          <v-list-item v-for="goal in Goals" :key="goal.itemObjKey">
              <v-list-item-content>
              {{goal.Name}}
              </v-list-item-content>
              <v-list-item-content>
                  <v-icon @click="EditGoal(goal)">mdi-cogs</v-icon>
              </v-list-item-content>
          </v-list-item>
          <!-- $reverseText('message') {{$reverseText('message')}}
          {{EndofDay(new Date())}} -->
       </v-card>      
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['AppisDarkMode'],
    data(){
        return {
            snackbar: false,
            Reimport: false,
            importdialog: false,
            importsnackbar: false,
            ImportHeaders: [],
            ImportingProgressDialog: false,
            Importcount: '',
            importresultsdialog: false,
            NewImportsArray: [],
            PartialFailuresArray: [],
            SuccessfulImportsArray: [],
            CSVResult: [],
            Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Vehicle Forms', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
            ImportFile: '',
            TargetHeaders: [
                { text: 'Name', value: 'Name',class: "overline"},
                { text: 'Fiscal', value: 'Calendar_Year',class: "overline"},
                { text: 'TimelineType', value: 'TimelineType',class: "overline",DoNotDisplay: true}, 
                { text: 'TimeItemIndex', value: 'TimeItemIndex',class: "overline",DoNotDisplay: true},   
                { text: 'Target', value: 'Target',class: "overline"}, 
                { text: 'Ownerid', value: 'Ownerid',class: "overline",DoNotDisplay: true},  
                { text: 'Owner', value: 'Owner.Full_Name',class: "overline"},  
                { text: 'Actions', value: 'actions', sortable: false ,class: "overline"},
            ],
            Goaldialog: false,
            Goals: [],
            GotEntities:false,
            SystemEntities: [],
            editedIndex: -1,
            editedItem: {
                Name: '',
                Description: '',
                PrimaryEntity: '',
                Targets: []
            },
            defaultitem: {
                Name: '',
                Description: '',
                PrimaryEntity: '',
                Targets: []
            },
            NewGoalDescription: '',
            ExportDataArray: [],
            ExportHeaders: [],
            FiscalEndMonth: 1,
            ExportTimelineType: '',
            ExportFiscalYear: 2021,
            TimelineFilterOptions: [

        {
          ID: 1000001,
          FilterName: 'This Week (Per Day)',
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',
          GiveName: 'Per Day',
          Name: 'This Week',
          StartDateVariable: 'ThisMonday',
          EndDateVariable: 'ThisSunday',
          ChartLabels: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],
          ChartLabelsVariables: ['ThisMonday','ThisTuesday','ThisWednesday','ThisThursday','ThisFriday','ThisSaturday','ThisSunday'],
          ChartlabelPeriodEndMethodX: 'EndofDay',
          ChartlabelPeriodEndMethodY: 'EndofDay',
        },
        {
          ID: 1000002,
          FilterName: 'This Month (Per Week)',
          FilterDescription: 'When viewing, this filter returns each Week for the total number of Weeks of the Current Month, and relevant results on each Week. For Goals, the results shown is by default filtered by the current Week',
          GiveName: 'Per Week',
          Name: 'This Month',
          StartDateVariable: 'CurrentMonthStart',
          EndDateVariable: 'CurrentMonthEnd',
          ChartLabelTrigger: 'CurrentMonthName',
          ChartLabelsisDynamic: true,
          DynamicChartLabelsProp: 'CurrentMonthLabels',         
          ChartlabelPeriodEndMethodX: 'EndofMonth',
          ChartlabelPeriodEndMethodY: 'EndofWeek',
        },
        {
          ID: 1000003,
          FilterName: 'This Year (Per Month)',
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',
          GiveName: 'Per Month',
          Name: 'This Year',
          StartDateVariable: 'ThisJanuaryStart',
          EndDateVariable: 'ThisDecemberEnd',
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],
          ChartlabelPeriodEndMethodX: 'EndofMonth',
          ChartlabelPeriodEndMethodY: 'EndofMonth',
        },
        {
          ID: 1000004,
          FilterName: 'This Fiscal Year (Per Month)',
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',
          GiveName: 'Per Month (Fiscal)',
          Name: 'This Fiscal Year',
          StartDateVariable: 'ThisJanuaryStart',
          EndDateVariable: 'ThisDecemberEnd',
          ChartLabels: ['Q1','Q2','Q3','Q4'],
          ChartLabelsVariables: ['FiscalQ1Start','FiscalQ2Start','FiscalQ3Start','FiscalQ4Start'],
          ChartlabelPeriodEndMethodX: 'EndofQuarter',
          ChartlabelPeriodEndMethodY: 'EndofQuarter'
        },
        {
          ID: 1000005,
          FilterName: 'This Fiscal Quarter (Per Month)',
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Quarter, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',
          GiveName: 'Per Month (Fiscal)',
          Name: 'This Fiscal Quarter',
          StartDateVariable: 'CurrentFiscalQStart',
          EndDateVariable: 'CurrentFiscalQEnd',
          ChartLabelsisDynamic: true,
          DynamicChartLabelsProp: 'CurrentFiscalLabels',
          ChartLabelTrigger: 'CurrentFiscalPeriod',
          ChartlabelPeriodEndMethodX: 'EndofMonth',
          ChartlabelPeriodEndMethodY: 'EndofMonth',
          
        },
        
      ],
            
        }
    },
    computed:{
      ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
      AppEntities(){
            return this.SystemEntities.filter(ent => {
                //console.log(ent.id) or if activities plugin installed
                return ent.Primary_Appid === this.ActiveSuiteApp.id || ent.System_Required || this.SharedPluginEntities.includes(ent.id)
            })
        },
      SharedPluginEntities(){
            return this.ActiveSuiteApp.SharedPlugins.map(plug => {
                let tables = []
                if(plug.Installation_Tables){
                    tables = plug.Installation_Tables.map(table => {
                        return table.id
                    })
                }
                return tables
            }).flat()
        },
      UsersArray(){
        return this.$store.state.UsersArray
      },
        DaysofYear(){
           if(this.editedItem.Calendar_Year){   
               let date1 = this.ThisJanuaryStart
               let date2 = this.EndofMonth(this.ThisDecemberStart)
               // To calculate the time difference of two dates 
            var Difference_In_Time = date2.getTime() - date1.getTime(); 
            
            // To calculate the no. of days between two dates 
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
            return Math.ceil(Difference_In_Days)
           }
        },
        WeeksofYear(){
            if(this.editedItem.Calendar_Year){  
                return this.DaysofYear/7
            }
        },
    LastJanuaryStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 0;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastFebruaryStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 1;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastMarchStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 2;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastAprilStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 3;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastMayStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 4;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastJuneStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 5;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastJulyStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 6;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastAugustStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 7;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastSeptemberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 8;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastOctoberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 9;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastNovemberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 10;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    LastDecemberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 11;
      var day = this.ComputedToday.getDate();
      var d = new Date(year-1, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
        ThisJanuaryStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 0;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisFebruaryStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 1;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisMarchStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 2;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisAprilStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 3;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisMayStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 4;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisJuneStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 5;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisJulyStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 6;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisAugustStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 7;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisSeptemberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 8;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisOctoberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 9;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisNovemberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 10;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ThisDecemberStart(){
      var year = this.ComputedToday.getFullYear();
      var month = 11;
      var day = this.ComputedToday.getDate();
      var d = new Date(year, month, day)
      d.setDate(1);
      d.setHours(0,0,0,0);
      return d
    },
    ComputedToday(){
        if(this.editedItem.Calendar_Year){       
         
            let date = new Date(this.editedItem.Calendar_Year+'-01-01')
            date.setHours(0,0,0,0)
            return date
        }
          
      },
        ItemBasedTimelineFilterOptions(){
            return this.TimelineFilterOptions.filter(option => {
                return option.ID >=this.editedItem.ExportTimelineType.ID
            })
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        TargetHeadersDisplay(){
            return this.TargetHeaders.filter(target => {
                return !target.DoNotDisplay
            })
        },
        FiscalYearOptions(){
            let start = 2010
            let end  = 2030
            let length = end-start
            let array = []
            for(var x = 0;x<length;x++){
                array[x] = start+x
            }
            return array
        },
        BasicUsersArray(){
            return this.UsersArray.map(user => {
                let userobj = {
                    id: user.id,
                    Full_Name: user.Full_Name,
                    
                }
                if(user.Profile_Photo){
                    userobj.Profile_Photo = user.Profile_Photo
                }
                return userobj
            })
        },
        formTitle () {
        return this.editedIndex === -1 ? 'New Goal' : 'Edit Goal'
        },
        ComputedSystemEntities(){
          return this.AppEntities
          .map(entity => {
              entity.RelatedFields = entity.AllFields
              .filter(field => {
                  return field.Type === 'Lookup' || field.Type === 'Option Set'
              })
              entity.NumberFields = entity.AllFields
              .filter(field => {
                  return field.Type === 'Number Field' || field.Type === 'Calculated Field'
              })
              entity.DateFields = entity.AllFields
              .filter(field => {
                  return field.Type === 'Date'
              })
              entity.OptionSets = entity.AllFields
              .filter(field => {
                  return field.Type === 'Option Set'
              })
              entity.LookupFields = entity.AllFields
              .filter(field => {
                  return field.Type === 'Lookup'
              })
              return entity
          })
      },
      ExportedExportFiscalYear(){

      },
    },
    created(){
      this.ScrolltoTop()
      this.$store.commit('setBuilderView',{Name: 'Database Builder'}) 
        let vm = this
         this.GetEntities().then(result => {
              console.log(result)
              vm.GetGoals()
          })
    },
    methods:{
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        EndofMonth(date){
            console.log(date)
      var month = date.getMonth()
      var year = date.getFullYear()
      var d = new Date(year, month + 1, 0)
      d.setHours(23,59,59,999)
      return d
    },
    EndofQuarter(date){
      console.log(date)
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();
      return this.EndofMonth(new Date(year, month+2, day));
    },
    EndofDay(date){
      var day = date.getDate()
      var month = date.getMonth()
      var year = date.getFullYear()
      var d = new Date(year, month, day)
      d.setHours(23,59,59,999)
      return d
    },
    EndofWeek(date){
     
      var month = date.getMonth()
      var year = date.getFullYear()
      var lastdaylastweek = date.getDate() - date.getDay();
      let d = new Date(year, month, lastdaylastweek+7)
      d.setHours(23,59,59,999)
      return d
    },
    CurrentMonthLabels(date){
      //to return the weeks of the month. But possible return as objects for looping purposes, TBD
       let variabletableobj = {
        ChartLabels: ['Week 1','Week 2','Week 3','Week 4','Week 5'],          
        ChartLabelsVariables: ['MonthWeek1Start','MonthWeek2Start','MonthWeek3Start','MonthWeek4Start','MonthWeek5Start'],
      }
      let Wvarobj = {
        Labels: [],
        ChartLabelsVariables : []
      }
      let length = 4
      let newtoday = new Date(date)
      var firstdayofmonth = 1;
      var year = newtoday.getFullYear();
      var month = newtoday.getMonth();
      var d = new Date(year, month, firstdayofmonth)
      d.setDate(firstdayofmonth+28)
      d.setHours(0,0,0,0);
      let week5month = d.getMonth()
      if(week5month === month){
        length = 5
      }
      for(var i = 0; i < length; i++){
        Wvarobj.Labels[i] = variabletableobj.ChartLabels[i]
        Wvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[i]
      }
      return Wvarobj
    },
    MonthWeek1Start(date){
      let newtoday = new Date(date)
      var firstdayofmonth = 1;
      var year = newtoday.getFullYear();
      var month = newtoday.getMonth();
      var d = new Date(year, month, firstdayofmonth)
      d.setHours(0,0,0,0);
      return d
    },
    MonthWeek2Start(date){
      let newtoday = this.EndofWeek(this.MonthWeek1Start(date))
      var firstdayofweek = newtoday.getDate()-1+2;
      var year = newtoday.getFullYear();
      var month = newtoday.getMonth();
      var d = new Date(year, month, firstdayofweek)
      // d.setDate(firstdayofmonth)
      d.setHours(0,0,0,0);
      return d
    },
    MonthWeek3Start(date){
      let newtoday = this.EndofWeek(this.MonthWeek2Start(date))
      var firstdayofweek = newtoday.getDate()-1+2;
      var year = newtoday.getFullYear();
      var month = newtoday.getMonth();
      var d = new Date(year, month, firstdayofweek)
      // d.setDate(firstdayofmonth)
      d.setHours(0,0,0,0);
      return d
    },
    MonthWeek4Start(date){
      let newtoday = this.EndofWeek(this.MonthWeek3Start(date))
      var firstdayofweek = newtoday.getDate()-1+2;
      var year = newtoday.getFullYear();
      var month = newtoday.getMonth();
      var d = new Date(year, month, firstdayofweek)
      // d.setDate(firstdayofmonth)
      d.setHours(0,0,0,0);
      return d
    },
    MonthWeek5Start(date){
      let newtoday = this.EndofWeek(this.MonthWeek4Start(date))
      var firstdayofweek = newtoday.getDate()-1+2;
      var year = newtoday.getFullYear();
      var month = newtoday.getMonth();
      var d = new Date(year, month, firstdayofweek)
      // d.setDate(firstdayofmonth)
      d.setHours(0,0,0,0);
      return d
    },
        saveImport() {
    this.ImportingProgressDialog = true
//     setTimeout(() => {
//     const totalprogresselement = document.getElementById('importprogressbar')
//     totalprogresselement.setAttribute('max','100')
//     totalprogresselement.setAttribute('value',1)
//   }, 200);
    this.Importcount = 'Preparing Import'
    let importLocalURL = URL.createObjectURL(this.ImportFile)
    var reader = new FileReader();
    let test = reader.readAsText(this.ImportFile);
    let vm = this
    reader.addEventListener('load', function(e) {
          var text = e.target.result;
          vm.CSVConvertToJSON(text)
    });
  },


  CheckDelimiter(lines){
    let headertest = lines[0].split(";");
    if(headertest.length ===1){
      this.ConversionCSVtoJJSON(lines,',')
    }
    else{
      this.ConversionCSVtoJJSON(lines, ';')
    }
    console.log('headertest')
    console.log(headertest)
  },
  GetQuarterlyTargetsfromPerMonthGoals(res){
      let Ownerobj = this.CSVResult.find(obj => obj.Ownerid === res.Ownerid)

      let CalendarQ1Start = Ownerobj.Targets[0].StartDate
      let CalendarQ1End = Ownerobj.Targets[2].EndDate
      let Q1CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ1Start && target.EndDate <= CalendarQ1End
      })
      let CalendarQ2Start = Ownerobj.Targets[3].StartDate
      let CalendarQ2End = Ownerobj.Targets[5].EndDate
      let Q2CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ2Start && target.EndDate <= CalendarQ2End
      })
      let CalendarQ3Start = Ownerobj.Targets[6].StartDate
      let CalendarQ3End = Ownerobj.Targets[8].EndDate
      let Q3CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ3Start && target.EndDate <= CalendarQ3End
      })
      let CalendarQ4Start = Ownerobj.Targets[9].StartDate
      let CalendarQ4End = Ownerobj.Targets[11].EndDate
      let Q4CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ4Start && target.EndDate <= CalendarQ4End
      })

      let Q1calendaryeartarget = Q1CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      let Q2calendaryeartarget = Q2CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      let Q3calendaryeartarget = Q3CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      let Q4calendaryeartarget = Q4CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      
        let Q1calendartargetobj = {
            StartDate: CalendarQ1Start,
            EndDate: CalendarQ1End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q1calendaryeartarget,
            Name: res.Owner.Full_Name+'- Calendar Q1 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q1calendartargetobj)

        let Q2calendartargetobj = {
            StartDate: CalendarQ2Start,
            EndDate: CalendarQ2End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q2calendaryeartarget,
            Name: res.Owner.Full_Name+'- Calendar Q2 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q2calendartargetobj)

        let Q3calendartargetobj = {
            StartDate: CalendarQ3Start,
            EndDate: CalendarQ3End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q3calendaryeartarget,
            Name: res.Owner.Full_Name+'- Calendar Q3 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q3calendartargetobj)

        let Q4calendartargetobj = {
            StartDate: CalendarQ4Start,
            EndDate: CalendarQ4End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q4calendaryeartarget,
            Name: res.Owner.Full_Name+'- Calendar Q4 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q4calendartargetobj)
 
  },
  GetFiscalQuarterlyTargetsfromPerMonthGoals(res){
      let ChartLabelsVariables = ['LastJanuaryStart','LastFebruaryStart','LastMarchStart','LastAprilStart','LastMayStart','LastJuneStart','LastJulyStart','LastAugustStart','LastSeptemberStart','LastOctoberStart','LastNovemberStart','LastDecemberStart',
        'ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart']
      let Ownerobj = this.CSVResult.find(obj => obj.Ownerid === res.Ownerid)
      let fiscalyearstartindex = this.FiscalEndMonth+1
    let q1startindex = fiscalyearstartindex+0
    let q1endindex = fiscalyearstartindex+2
    let q2startindex = fiscalyearstartindex+3
    let q2endindex = fiscalyearstartindex+5
    let q3startindex = fiscalyearstartindex+6
    let q3endindex = fiscalyearstartindex+8
    let q4startindex = fiscalyearstartindex+9
    let q4endindex = fiscalyearstartindex+11
      let CalendarQ1Start = this[ChartLabelsVariables[q1startindex]]
      let CalendarQ1End = this[ChartLabelsVariables[q1endindex]]
      CalendarQ1End = this.EndofMonth(CalendarQ1End)
      let Q1CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ1Start && target.EndDate <= CalendarQ1End
      })
      let CalendarQ2Start = this[ChartLabelsVariables[q2startindex]]
      let CalendarQ2End = this[ChartLabelsVariables[q2endindex]]
      CalendarQ2End = this.EndofMonth(CalendarQ2End)
      let Q2CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ2Start && target.EndDate <= CalendarQ2End
      })
      let CalendarQ3Start = this[ChartLabelsVariables[q3startindex]]
      let CalendarQ3End = this[ChartLabelsVariables[q3endindex]]
      CalendarQ3End = this.EndofMonth(CalendarQ3End)
      let Q3CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ3Start && target.EndDate <= CalendarQ3End
      })
      let CalendarQ4Start = this[ChartLabelsVariables[q4startindex]]
      let CalendarQ4End = this[ChartLabelsVariables[q4endindex]]
      CalendarQ4End = this.EndofMonth(CalendarQ4End)
      let Q4CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarQ4Start && target.EndDate <= CalendarQ4End
      })

      let Q1calendaryeartarget = Q1CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      let Q2calendaryeartarget = Q2CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      let Q3calendaryeartarget = Q3CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      let Q4calendaryeartarget = Q4CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
      
        let Q1calendartargetobj = {
            StartDate: CalendarQ1Start,
            EndDate: CalendarQ1End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q1calendaryeartarget,
            Name: res.Owner.Full_Name+'- Fiscal Q1 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q1calendartargetobj)

        let Q2calendartargetobj = {
            StartDate: CalendarQ2Start,
            EndDate: CalendarQ2End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q2calendaryeartarget,
            Name: res.Owner.Full_Name+'- Fiscal Q2 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q2calendartargetobj)

        let Q3calendartargetobj = {
            StartDate: CalendarQ3Start,
            EndDate: CalendarQ3End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q3calendaryeartarget,
            Name: res.Owner.Full_Name+'- Fiscal Q3 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q3calendartargetobj)

        let Q4calendartargetobj = {
            StartDate: CalendarQ4Start,
            EndDate: CalendarQ4End,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: Q4calendaryeartarget,
            Name: res.Owner.Full_Name+'- Fiscal Q4 - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(Q4calendartargetobj)
    let FiscalYearStart = Q1calendartargetobj.StartDate
     let FiscalYearEnd = Q4calendartargetobj.EndDate
     let FiscalCalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= FiscalYearStart && target.EndDate <= FiscalYearEnd
      })
     let fiscalcalendaryeartarget = FiscalCalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
        
        let fiscalcalendartargetobj = {
            StartDate: FiscalYearStart,
            EndDate: FiscalYearEnd,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: fiscalcalendaryeartarget,
            Name: res.Owner.Full_Name+' - Fiscal Year '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(fiscalcalendartargetobj)
 
  },
  GetYearlyTargetsfromPerMonthGoals(res,yearstartindex,yearendindex){
      //console.log('calling')
    //   let array = []
        
      let Ownerobj = this.CSVResult.find(obj => obj.Ownerid === res.Ownerid)
      let CalendarStart = Ownerobj.Targets[yearstartindex].StartDate
      let CalendarEnd = Ownerobj.Targets[yearendindex].EndDate
      let CalendarTargets = Ownerobj.Targets.filter(target => {
          return target.OriginalImport && target.StartDate >= CalendarStart && target.EndDate <= CalendarEnd
      })
      let calendaryeartarget = CalendarTargets
      .map(target => {
          return target.Target
      })
      .reduce((a, b) => a + b, 0)
     
        let calendartargetobj = {
            StartDate: CalendarStart,
            EndDate: CalendarEnd,
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: calendaryeartarget,
            Name: res.Owner.Full_Name+' - Calendar Year '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(calendartargetobj)
 
    
  },
  GetWeeklyTargetsfromPerMonthGoals(length,res,target,ChartLabelsVariablesObj){
      //console.log('calling')
    //   let array = []
    let months = ['January','February','March','April','May','June','July','August','September','October','November','December']
    let datemonth = res.StartDate.getMonth()
    let datename = months[datemonth]
      let Ownerobj = this.CSVResult.find(obj => obj.Ownerid === res.Ownerid)
      for(var x = 0;x<length;x++){                       
        let start = this[ChartLabelsVariablesObj.ChartLabelsVariables[x]](res.StartDate)
        
        let targetobj = {
            StartDate: start,
            EndDate: this.EndofWeek(start),
            Calendar_Year: res.Calendar_Year,
            Owner: res.Owner,
            Ownerid: res.Ownerid,
            TimelineType: res.TimelineType,
            Target: target,
            Name: res.Owner+ ' - '+datename+' '+ChartLabelsVariablesObj.Labels[x]+' - '+res.Calendar_Year,
            TimeItemIndex: res.TimeItemIndex
        }
        Ownerobj.Targets.push(targetobj)
        //console.log(x,length)
        // if(x===length-1){
        //     return array
        // }
    }
    
  },
  ConversionCSVtoJJSON(lines,delimiter){
    let dataonly = Object.assign([],lines)
    dataonly.splice(0,1)
    //console.log(dataonly)
    let results = []
    this.CSVResult = []
    this.ImportHeaders=lines[0].split(delimiter);
    console.log('this.ImportHeaders')
    console.log(this.ImportHeaders)
    let finalindex = this.ImportHeaders.length-1
  let finalheader = this.ImportHeaders[finalindex]
  if(finalheader.includes('id')){
    this.Reimport = true
    confirm('We picked up this is a Reimport. Shall you continue?') && this.ProceedReimport(lines,delimiter)
  }
  else{
    for(var i=1;i-1+2<lines.length;i++){

    var obj = {};
    var currentline=lines[i].split(delimiter);
    
    for(var j=0;j<this.ImportHeaders.length;j++){
      obj[this.ImportHeaders[j]] = currentline[j];
    }
    results.push(obj);
  }
  console.log("results length is "+results.length)

  results.map(res => {
      let oncheck = this.CSVResult.find(obj => obj.id === res.Ownerid)
      let Owner = this.BasicUsersArray.find(obj => obj.id === res.Ownerid)
      if(!oncheck){
          this.CSVResult.push({id: res.Ownerid, Targets: results.filter(obj => {return obj.Ownerid === res.Ownerid}),Owner: Owner, Ownerid: res.Ownerid,Calendar_Year: res.Calendar_Year,TimelineType: res.TimelineType,TimeItemIndex: res.TimeItemIndex})
      }
  })
  
  this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
  let importitems = []

  this.CSVResult = this.CSVResult
        .map(result => {            
            result.Targets = result.Targets.map((target,i) => {
              if(this.editedItem.ExportTimelineType.ID === 1000003){
                target.StartDate = this[this.editedItem.ExportTimelineType.ChartLabelsVariables[i]]
                target.EndDate = this[this.editedItem.ExportTimelineType.ChartlabelPeriodEndMethodY](this[this.editedItem.ExportTimelineType.ChartLabelsVariables[i]])
                target.OriginalImport = true
                target.Target = Number(target.Target)
                }  
                return target
            })
            return result
        })
        results.map((res,i) => {            
            if(this.editedItem.ExportTimelineType.ID === 1000003){
            let ChartLabelsVariablesObj = this.CurrentMonthLabels(res.StartDate)
            let length = ChartLabelsVariablesObj.ChartLabelsVariables.length
            let weekstarget = res.Target/length
            this.GetWeeklyTargetsfromPerMonthGoals(length,res,weekstarget,ChartLabelsVariablesObj)                    
            }
        })
        this.CSVResult.map(res => {
          let yearstartindex = 0
            let yearendindex = 11
            this.GetYearlyTargetsfromPerMonthGoals(res,yearstartindex,yearendindex) 
            this.GetFiscalQuarterlyTargetsfromPerMonthGoals(res) 
            this.GetQuarterlyTargetsfromPerMonthGoals(res)
        })
        
  console.log(this.CSVResult)
    this.CSVResult.map((res,i) => {      
      this.UpdateFiresStore(res,i)
      
    })
  }
  },
  ProceedReimport(lines,delimiter){
  for(var i=1;i-1+2<lines.length;i++){
      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Title == obj.Title)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)
    }
    this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
      this.CSVResult.map((res,i) => {
          this.UpdateFiresStoreReimport(res,i)
      })
},
  CSVConvertToJSON(text){
   
    this.NewImportsArray = []
    this.PartialFailuresArray = []
    
    let lines = text.split("\n")
    this.CheckDelimiter(lines)
    
  },
  UpdateFiresStoreReimport(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let TitleQuery = []
                        let TitleStringArray = SingleResult.Title.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(TitleStringArray.length,TitleStringArray)
                          for (p = 0; p < TitleStringArray.length; p++) {
                              buildup = buildup+ TitleStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          TitleQuery = lowertext.split(',')  
                          TitleQuery.length = TitleQuery.length-1
                  
    let ImportGoalTarget = {
      Title: SingleResult.Title.trim(),
      TitleQuery: TitleQuery,
      Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
      Modified_Byid: this.userLoggedIn.id,
      Modified_On: new Date(), 
      ImportedRecord: true,
      }
      
        ImportGoalTarget.id = SingleResult.id
        ImportGoalTarget.Owner = SingleResult.Owner
        ImportGoalTarget.Ownerid = SingleResult.Ownerid
      
      
      let FailureObject = Object.assign({},ImportGoalTarget)
      FailureObject.Failures = []
      
      if(ImportGoalTarget.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)

      db.collection('goals').doc(this.editedItem.id).collection('Targets').doc(ImportGoalTarget.id).update({
      Title: ImportGoalTarget.Title,
      TitleQuery: ImportGoalTarget.TitleQuery,
      Modified_By: ImportGoalTarget.Modified_By,
      Modified_Byid: ImportGoalTarget.Modified_Byid,
      Modified_On: ImportGoalTarget.Modified_On, 
      ImportedRecord: true
        
      })
      
     
        let AllImportObj = this.NewImportsArray.find(imp => imp.Title == ImportGoalTarget.Title)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = ImportGoalTarget.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Title == ImportGoalTarget.Title)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = ImportGoalTarget.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Title == ImportGoalTarget.Title)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = ImportGoalTarget.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.userLoggedIn
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'VehicleForms', datasetrecordpath: 'VehicleForm', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      


  },
  UpdateFiresStore(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      
    let ImportGoalTarget = {
      Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
      Created_Byid: this.userLoggedIn.id,
      Created_On: new Date(),        
      Status: {ID: 1000001, Name: 'Active'},
      Status_Reason: {ID: 1000001, Name: 'Active'},
      ImportedRecord: true,
      id: OriginalImportObject.id,      
      Owner: OriginalImportObject.Owner,
      Ownerid: OriginalImportObject.Ownerid,
      Targets: OriginalImportObject.Targets.map(target => {
          target.Target = Number(target.Target)
          target.TimeItemIndex = Number(target.TimeItemIndex) 
          target.TimelineType = Number(target.TimelineType)
          return target
      })
      }
      
   
      
      let FailureObject = Object.assign({},ImportGoalTarget)
      FailureObject.Failures = []
      

      if(ImportGoalTarget.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
        
      db.collection('goals').doc(this.editedItem.id).collection('Targets').doc(ImportGoalTarget.id).set(ImportGoalTarget).then(doc => {
        let AllImportObj = this.NewImportsArray.find(imp => imp.id == ImportGoalTarget.id)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = ImportGoalTarget.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.id == ImportGoalTarget.id)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = ImportGoalTarget.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.id == ImportGoalTarget.id)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = ImportGoalTarget.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.userLoggedIn
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      this.SaveGoal(true)
    //   let DataImport = {name: this.Import.Name, dataset: 'Goals', datasetrecordpath: 'Goal', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
    //   db.collection('dataimports').add(DataImport).then(doc => {
    //   var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
    //   var uploadTask = storageRef.put(this.ImportFile);
    //   uploadTask
    //   .then(snapshot => snapshot.ref.getDownloadURL())
    //     .then((url) => {
    //       db.collection('dataimports').doc(doc.id).update({
    //               csvfile: url
    //       })
    //       this.ImportFile = ''
    //           }),
    //           this.importresultsdialog = true
    //           this.importdialog = false
    //           this.Import.Name = ''
    //           this.ImportingProgressDialog = false
    //   })

      }
      })


  },
        onImportfileSelected(event){
    this.ImportFile = event.target.files[0]
  },
        PrepareExport(){
            console.log(this.editedItem)
            //what are the headers? It's dynamic depending on selection
            this.ExportHeaders = Array.from(this.TargetHeaders)
            //Now loop to get data, because you export data that must 
            this.ExportDataArray = []
                               
                this.editedItem.UserList.map(user => {
                    this.editedItem.ExportTimelineType.ChartLabels.map((label,labelindex) => {
                    let exportobj = {} 
                    exportobj.Calendar_Year = this.ExportFiscalYear,
                    exportobj.Ownerid = user.id,
                    exportobj.Owner = {Full_Name: user.Full_Name,id: user.id} ,
                    exportobj.TimelineType = this.editedItem.ExportTimelineType.ID
                    exportobj.Target = '0'
                        console.log(label)
                    exportobj.Name = user.Full_Name+' '+label+' '+this.ExportFiscalYear                    
                    exportobj.TimeItemIndex = labelindex.toString()  
                    console.log(exportobj) 
                    //this.editedItem.Targets.push(exportobj)    
                    this.ExportDataArray.push(exportobj)               
                })
                
            })
            console.log(this.ExportDataArray)
            this.ExportData(this.editedItem.Name+' Import.csv',this.ExportDataArray,this.ExportHeaders)
        },
        ExportData(filename,exportdata,exportheaders){
        this.exportToCsv(filename,exportdata,exportheaders)
        },
        exportToCsv(filename,exportdata,exportheaders,forreimport) {
        const headers = exportheaders.map(header => {
            return header.value.split('.')[0]
        })
        const headerrows = [headers]
        if(forreimport === true){
            headers.push('id')
            let idobj = { text: 'id', value: 'id'}
            exportheaders.push(idobj)
            headers.push('id')
            exportheaders.push(idobj)
        }
        console.log(exportdata)
        console.log(headers)
        const datarows = exportdata.map(row => {
            row.csvdata =  exportheaders.map(header => {
            
            let headervalue = ''
            let firstprop = header.value.split('.')[0]
            let secondprop = header.value.split('.')[1]
            
            if(secondprop){
                
                if(row[firstprop]){
                if(row[firstprop][secondprop]){   
                    let thirdprop = row[firstprop][secondprop]
                                
                headervalue = row[firstprop][secondprop]
                }
                }
            }
            else{
                if(row[firstprop]){
                
                headervalue = row[firstprop]
                }
            }   
            if(header.text === 'Owner'){
                if(row.Owner){
                headervalue = row.Owner.Full_Name
                }
                
            }         
            return headervalue
            })
            return row
            }).map(row => {
            return row.csvdata
            })
        const rows = headerrows.concat(datarows)
        

        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename);
            document.body.appendChild(link); // Required for FF

            link.click();
        },
        EditGoal(goal){
            this.editedItem = Object.assign({},goal)
            console.log(goal)
            let index = this.Goals.indexOf(goal)
            this.editedIndex = index
            this.Goaldialog = true
        },
        CancelGoal(){
            this.editedItem = Object.assign({},this.defaultitem)
            this.editedIndex = -1
            this.Goaldialog = false
        },
        
        SaveGoal(fromimport){
          if(this.editedItem.UserList){
            this.editedItem.UserListIDs = this.editedItem.UserList.map(user => {return user.id})
          }
            if(this.editedIndex === -1){
                let NewGoal = Object.assign({},this.editedItem)
                NewGoal.PrimaryEntity = NewGoal.PrimaryEntity.id
                db.collection('goals').add(NewGoal).then(doc => {
                    console.log('added new doc '+doc.id)
                    if(!fromimport){
                      this.CancelGoal()  
                    }
                    
                })
            }
            else{

                delete this.editedItem.Targets
                this.editedItem.PrimaryEntity = this.editedItem.PrimaryEntity.id
                db.collection('goals').doc(this.editedItem.id).set(this.editedItem)
                .then(doc => {
                    if(!fromimport){
                      this.CancelGoal()  
                    }
                })
            }
        },
        GetGoals(){
            let vm = this
            db.collection('goals').onSnapshot(res => {
                const changes = res.docChanges()
                changes.forEach((change,i) => {
                    if(change.type === 'added'){
                        let obj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let entityid = obj.PrimaryEntity
                        console.log(this.ComputedSystemEntities,entityid)
                        obj.PrimaryEntity = this.ComputedSystemEntities.find(obj => obj.id === entityid)
                        if(obj.UserList){
                          obj.UserList = obj.UserList.map(user => {
                            let userobj = this.UsersArray.find(obj => obj.id === user.id)
                            return userobj 
                        })  
                        }
                        obj.Targets = []
                        this.GetGoalTargets(obj).then(result => {
                            console.log(obj,result)
                            vm.Goals.push(obj)
                        })
                        
                    }
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            
                            console.log(targetslength,i-1+2)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
        GetEntities(){
            let vm = this
            return new Promise(function(resolve, reject) {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').onSnapshot(res => {
                let arraylength = res.docs.length
                //console.log(arraylength)
                const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let build = change.doc.data()
                        build.id = change.doc.id
                        vm.GetAllEntityProps(build).then(function(allpropsresult) {
                            if(allpropsresult){
                            //   console.log("%c "+build.id, "background: blue; color: white")
                            //  console.log(build)
                            let oncheck = vm.SystemEntities.find(obj => obj.id == build.id)
                            if(!oncheck){
                                vm.SystemEntities.push(build)
                            }
                            //console.log(vm.SystemEntities.length)
                                if(vm.SystemEntities.length === arraylength){
                                vm.GotEntities = true    
                                resolve('Method SystemEntities  Finished for ' + vm.SystemEntities.length)
                                }
                            }
                            })
                    }
                    
            })
            })
            })
        },
        GetEntityAllFields(build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntityProcesses(build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('EntityProcesses').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetAllEntityProps(build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(build).then(function(allfieldsresult) {
                if(allfieldsresult){
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(build).then(function(subcolresult) {
                        // console.log(subcolresult)
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(build).then(function(processesresult) {
                        // console.log(processesresult)
                        
                            if(allfieldsresult && subcolresult && processesresult){
                                resolve('got the props for '+build.id)
                            }
                        })
                    }
                    })
                }           
            })
        })
        },
    }
}
</script>

<style>

</style>

    