
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
            <v-card :class="tab.BoxTransparency+' '+tab.CustomClass+' mx-3' "  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" 
            :id="tab.ElementID+'_Tab'" @click="ActivateElementConfigComp('DescriptionListConfigComponent',tab,i)"
            :height="tab.Name === 'Card Row Single' && tab.InfographicBox ? '100%' : ''"
             :style="tab.AnimationCSS && tab.AnimationCSS.Tab ? 
             BoxStyle(tab)+tab.AnimationCSS.Tab : 
             BoxStyle(tab)">
            <v-card-text style="display: flex;gap:30px;place-content: center;" class="tiled-titles">
              <v-row :class="tab.Justify">
                <!-- <div class="year" style="gap: 30px;position: relative;display: flex;"> -->
                <span v-for="item in tab.ListItems" :key="item.itemObjKey" 
                :style="'--bgcol: '+item.BGColor.hexa+';--clr: '+item.Color.hexa+';--font-em: '+tab.FontEM+'em;--font-weight: '+tab.FontWeight+';--line-height: '+tab.LineHeight+'px;'"
                
                :data-text="item.RevealTitle">
                    <b>{{item.Title}}</b>
                </span>
              </v-row>
                <!-- :style="'--clr: '+item.Color.hexa+';--font-em: '+tab.FontEM+'em;--font-weight: '+tab.FontWeight+';--line-height: '+tab.LineHeight+'px;'" -->
                <!-- </div> -->
                </v-card-text>
            </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbedReadOnly';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';
import ImageRenderComp from '@/components/WebPages/RenderComponents/ImageRenderComp';
export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID','Editor'],
    components: {
        ActionButtonEmbed,
        TitleTab,
        HTMLDescription,
        ImageRenderComp
        },
    data() {
    return {    
        
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
    },
    
    methods: {
      ActivateElementConfigComp(compname,tab,i){
            if(this.Editor){
            this.$store.commit('setActiveElmntConfigComp',compname)
            this.OpenDialog(tab,i)
            }
        },
       BoxStyle(tab){
        let style = ''
        if(tab.FullHeight){
            style = 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            style = 'width: 100%;padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'
        }
        style = style+tab.CustomStyle
        return style
       },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>

.magartallp p::first-letter {
    margin: 1% 3% -2px 0;
    padding:0 5% 0;
    font-size: 3em;;
    line-height: 1;
    float: left;
    color:#f0f0f0;
    font-weight:700;
    background: #ff6633;
    /* background: inherit; */
    vertical-align:bottom;
  }
.magartfirstp  p:first-of-type::first-letter {
    margin: 1% 3% -2px 0;
    padding:0 5% 0;
    font-size: 3em;;
    line-height: 1.5;
    float: left;
    color:#f0f0f0;
    font-weight:700;
    background: #ff6633;
    /* background: inherit; */
    vertical-align:bottom;
  }
  .tiled-titles span{
    position: relative;
    width: var(--line-height);
    height: var(--line-height);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 35px rgba(0,0,0,0.25);
    background-color: var(--bgcol);
  } 
.tiled-titles span b{
    font-size: var(--font-em);
    font-weight: var(--font-weight);
    -webkit-text-stroke: 2px var(--clr);
    color: transparent;
   }
   .tiled-titles span:hover b{
    transition: 0.5s ease-in-out;
    opacity: 0;
   }
  .tiled-titles span::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    font-size: var(--font-em);
    font-weight: var(--font-weight);
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: var(--line-height);
    color: var(--clr);
    /* border: 6px solid var(--clr); */
    border-bottom: 6px solid var(--clr);
    overflow: hidden;
    transition: 0.5s ease-in-out;
  }
.tiled-titles span:hover::before{
 height: 100%;
 filter: drop-shadow(0 0 20px var(--clr))   
}
</style>