<template>
  <div style="margin-top:100px;">
      <v-dialog max-width="600" v-model="NewFieldDialog">                               
        <v-card>
            <v-card-title class="overline">
                {{NewField.Type}}
               
            </v-card-title>
            <v-card-text v-html="NewField.Description">
            </v-card-text>
            <!-- <v-card-text> -->
                <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                 :CurrentEntity="CurrentEntity" :WebFormElmnt="true"
                :SystemEntities="SystemEntities" :field="NewField" :NewField="true" :System="System"
                />
            <v-card-text>
                <SingleTabFieldsSection
                    @UpdateEditableField="UpdateEditableField"
                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                :sectionindex="''" :tabindex="''" :SampleOnly="true"
                :PrimaryFieldName="''" :SingleFormEditing="false"
                :Record="SampleRecord" :AdditionalSaveMethod="AdditionalSaveMethod"
                :section="''" :tab="''" :Fields="[NewField]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn @click="CancelNewFieldDialog()">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="AddWebFormEntityField(NewField)">
                   
                    {{AddingNewField? 'Add Field' : 'Update Field'}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
      <v-dialog v-model="WebFormTabdialog" width="400">
          <v-card>
              <v-card-title>
                  Section Dialog
              </v-card-title>
              <v-card-text>
                  <v-text-field label="Name" v-model="WebFormElmnt.FormName"/>
              </v-card-text>
              <v-card-text>

              </v-card-text>
              <v-card-actions>
                  <v-btn @click="CancelAddorUpdatedTab()">Cancel</v-btn>
                  <v-spacer>
                  </v-spacer>
                  <v-btn @click="AddorUpdatedTab()">Save</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
       
      
      <v-btn @click="SaveSurvey()">Save Survey</v-btn>
      <v-list dense>
        <v-list-item>
        <v-select :items="AvailableWebFormTypes" v-model="Survey.WebFormType" label="Form Intent" />
        </v-list-item>
            <v-list-item v-if="WebFormTypesrequiringLookup.includes(Survey.WebFormType)">
        <v-select @change="EvaluateWebFormType(Survey.WebFormType,Survey.ParentEntity)" item-text="DisplayName" :items="PotentialWebFormRelatedEntities" return-object v-model="Survey.ParentEntity" label="Related Entity" />
        </v-list-item>
        <v-list-item>
        <v-text-field v-model="Survey.DisplayName" label="Display Name" />
        </v-list-item>
        <v-list-item>
        <v-text-field v-model="Survey.SingleName" label="Single Item Name" />
        </v-list-item>
        <v-list-item>
        <v-text-field v-model="Survey.Primary_Field_Name" label="Primary Field Name" />
        </v-list-item>
        <v-list-item>
        <v-text-field v-model="Survey.Table_Icon" label="Table Icon" />
        </v-list-item>
        <v-list-item class="justify-center">
            <ContentEditableField
        :FieldObject="Survey" :FieldName="'Description'"
        :FieldValue="Survey.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-list-item>
</v-list>
      <SurveyForm :Survey="ComputedSurvey" @AddSurveySectionFields="ActivateNewFieldDialog"
      @ActivateAddNewWebFormTabDialog="ActivateAddNewWebFormTabDialog"
      />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SurveyForm from '@/components/WebPages/RenderComponents/SurveyForm';
import FieldConfig from '@/components/Database/FieldConfig';	
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';

export default {
    props: ['System','SystemEntities','RADB'],
    components: {SurveyForm,FieldConfig,SingleTabFieldsSection},
    data() {
        return {
            SampleRecord: {},
            WebFormTypesrequiringLookup: [
                'Classified Listing Enquiry'
            ],
            NewFieldDialog: false,
            AddingNewField: false,
            NewField: '',
            EditedTabIndex: -1,
            DefaultWebForm: {
                HideFormName: true,
                Description: `<p>Some Web Form</p>`,
                ShowComponents: true,
                Name: 'Web Form',
                FormName: '',
                FromDatabase:   true,
                DarkForm: false,
                BGColor: {
                    hexa: 'dbcard'},
                HeaderColor: {hex: '#000000'},
                EntityForm: true,
                RelatedEntity: {id: ''},
                FormSubmitted:false,
                SingleName:'',
                FormFields: [],
                StatusDefault: {
                "Options": [
                    {
                    "Name": "Open",
                    "Level": {
                        "children": [],
                        "id": 2,
                        "FieldName": "Status_Reason",
                        "name": "Status Reason"
                    },
                    "LevelFieldName": "Status_Reason",
                    "ID": 1000003
                    }
                ],
                "ID": 1000001,
                "HasDeepHierarchy": true,
                "Level": {
                    "children": [
                    {
                        "name": "Status Reason",
                        "children": [],
                        "FieldName": "Status_Reason",
                        "id": 2
                    }
                    ],
                    "FieldName": "Status",
                    "id": 1,
                    "name": "Status"
                },
                "LevelFieldName": "Status",
                "Name": "Active"
                },
                Status_ReasonDefault: {
                "LevelFieldName": "Status_Reason",
                "ID": 1000003,
                "Name": "Open",
                "Level": {
                    "name": "Status Reason",
                    "FieldName": "Status_Reason",
                    "children": [],
                    "id": 2
                }
                },
            },
            WebFormElmnt: {},
            WebFormTabdialog: false,
            Survey: {
                
                Table_Icon: 'mdi-form-select',
                DisplayName: '',
                SingleName: '',
                Name: '',
                Primary_Field_Name: 'Subject',
               
                Entity_Type: {
                    Name: 'Survey Form',
                    ID: 1000013
                },
                Security_Structure: {
                    ID: 1000002,
                    Name: 'Business Unit'
                },
                AllgetRoles: [],
                UnitDowngetRoles: [],
                UserUnitgetRoles: [],
                OwnergetRoles: [],
                AlllistRoles: [],
                UnitDownlistRoles: [],
                UserUnitlistRoles: [],
                OwnerlistRoles: [],
                AllcreateRoles: [],
                UnitDowncreateRoles: [],
                UserUnitcreateRoles: [],
                OwnercreateRoles: [],
                AllupdateRoles: [],
                UnitDownupdateRoles: [],
                UserUnitupdateRoles: [],
                OwnerupdateRoles: [],
                AlldeleteRoles: [],
                UnitDowndeleteRoles: [],
                UserUnitdeleteRoles: [],
                OwnerdeleteRoles: [],
                datasetsecuritystructure: 'Business Unit',
                Primary_Field_Name: 'Subject',
                Name: 'Survey',
                StatusDefault: {
                "Options": [
                    {
                    "Name": "Open",
                    "Level": {
                        "children": [],
                        "id": 2,
                        "FieldName": "Status_Reason",
                        "name": "Status Reason"
                    },
                    "LevelFieldName": "Status_Reason",
                    "ID": 1000003
                    }
                ],
                "ID": 1000001,
                "HasDeepHierarchy": true,
                "Level": {
                    "children": [
                    {
                        "name": "Status Reason",
                        "children": [],
                        "FieldName": "Status_Reason",
                        "id": 2
                    }
                    ],
                    "FieldName": "Status",
                    "id": 1,
                    "name": "Status"
                },
                "LevelFieldName": "Status",
                "Name": "Active"
                },
                Status_ReasonDefault: {
                "LevelFieldName": "Status_Reason",
                "ID": 1000003,
                "Name": "Open",
                "Level": {
                    "name": "Status Reason",
                    "FieldName": "Status_Reason",
                    "children": [],
                    "id": 2
                }
                },
                SurveyTab: {
                RelatedEntity: {id: ''},
                Elements: [],
                FormName: '',
                },
                
            }
        }
    },
    computed:{
         DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
    ActiveSurveyTab(){
        return this.Survey.SurveyTab
    },
        AvailableWebFormTypes(){
            let arr = ['Basic Enquiry','Internal Workflow']
            if(this.ClassifiedListingEntities.length > 0){
                arr.push('Classified Listing Enquiry')
            }
            return arr
        },
        ClassifiedListingEntities(){
            return this.NonWebFormEntities.filter(entity => {
                return entity.Entity_Type.Name === 'Classified Listing'
            })
        },
        NonWebFormEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name !== 'Web Form'
            })
        },
        PotentialWebFormRelatedEntities(){
            switch (this.Survey.WebFormType) {
                case 'Classified Listing Enquiry': return this.ClassifiedListingEntities.map(entity => {
                    let entityobj = {
                        id: entity.id,
                        SingleName: entity.SingleName,
                        DisplayName: entity.DisplayName,
                        Table_Icon: entity.Table_Icon,
                        Entity_Type: entity.Entity_Type,
                        Primary_Field_Name: 'Combined_Title',
                        RecordPrimaryField: entity.RecordPrimaryField
                    }
                    return entityobj
                    })        
                case 'Internal Workflow': return this.NonWebFormEntities.map(entity => {
                    let entityobj = {
                        id: entity.id,
                        SingleName: entity.SingleName,
                        DisplayName: entity.DisplayName,
                        Table_Icon: entity.Table_Icon,
                        Entity_Type: entity.Entity_Type,
                        Primary_Field_Name: entity.Primary_Field_Name,
                        RecordPrimaryField: entity.RecordPrimaryField
                    }
                    return entityobj
                    })
                default: return []
                }  
        },
        RenderedSurveyTab(){
            let tabobj = this.ActiveSurveyTab
            console.log('tabobj',tabobj)
            tabobj.FormName = this.Survey.DisplayName
            tabobj.RelatedEntity.id = this.Survey.DisplayName.split(' ').join('_')
            tabobj.SingleName = this.Survey.SingleName
            if(this.Survey.WebFormType){
                tabobj.WebFormType = this.Survey.WebFormType
            }
            if(this.Survey.ParentEntity){
                tabobj.ParentEntity = this.Survey.ParentEntity
            }
            if(this.ActiveSurveyTab.Elements && this.ActiveSurveyTab.Elements.length > 0){
                this.ActiveSurveyTab.Elements = this.ActiveSurveyTab.Elements.map(elmnt => {
                    let elmntobj = elmnt
                    elmntobj.RelatedEntity.id = this.RouteID 
                   
                    elmntobj.FormFields = elmntobj.FormFields.map((field,fieldindex) => {
                    field.WebFormIndex = fieldindex
                    if(field.MappedField){
                    let mappedfield = field.MappedField.Name
                    if(!field.MappedField.Primary && field.MappedField.LoggedInUser){
                        //alert('one')
                        field.Response = {
                            Full_Name: this.userLoggedIn.Full_Name,
                            Name: this.userLoggedIn.Name,
                            Surname: this.userLoggedIn.Surname,
                            id: this.userLoggedIn.id
                        } 
                        
                        field.Array = [field.Response]
                    }
                    else if(field.MappedField.LoggedInUserValue){
                        field.Response = this.userLoggedIn[mappedfield]
                        if(field.Type === 'Lookup'){
                            field.Array = [field.Response]
                        }
                    }
                    }
                    //console.log(field)
                    return field
                }) 
                return elmntobj
                })
                 
              
            }
            
           
           
            //FormName
            //RelatedEntity.id
            //SingleName
            return tabobj
        },
       RAUser(){
            return this.$store.state.RAUserObj
        },
        RASystemOpsDB(){
            if(this.NeedsRASync){
                return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities')
            }
        },
        RAOperationalDB(){
            if(this.NeedsRASync){
                return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.RouteID)
            }
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
            return this.$store.state.NeedsRASync          
        },
        ComputedSurvey(){
            return this.Survey
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        OpsDB(){
           return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.RouteID)
        },
        RouteID(){
            return this.$route.params.id
        }
    },
    created(){
        this.DeactivateAddNewWebFormTabDialog()
        this.DefaultWebForm.StatusDefault = this.Survey.StatusDefault
        this.DefaultWebForm.Status_ReasonDefault = this.Survey.Status_ReasonDefault
        this.Survey.SingleBuildTabs = []
        if(this.RouteID){
         this.GetSurvey()   
        }
        
    },
    methods:{
        
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
        EvaluateWebFormType(WebFormType,ParentEntity){
            console.log(this.SystemEntities,ParentEntity)
            let newfield = {}
            if(WebFormType === 'Classified Listing Enquiry'){                
                newfield.Type = 'Lookup'
                newfield.Name = ParentEntity.SingleName
                newfield.DisplayName = ParentEntity.SingleName
                newfield.RelatedLocation = ParentEntity.SingleName
                newfield.RelatedRecord = ParentEntity.DisplayName
                newfield.RelatedBuildID = ParentEntity.id
                newfield.LookupFieldName = ParentEntity.RecordPrimaryField.Name
                newfield.MappedField = {
                        Name: ParentEntity.Primary_Field_Name,
                        Primary: true
                    }                 
            }
            this.WebFormElmnt.Description = `<b>Overview</b>
            <p>This is Web Form entity, parented to `+ParentEntity.DisplayName+` Listings.</p>
            <b>The benefits and features include:</b>
            <ul>
                <li>As a Web Form it allows capturing Enquiries on `+ParentEntity.DisplayName+` Listings.
                </li>
                <li>As a Data Table Entity it allows the full scope of features a data table offers, which include:
                    <ul>
                    <li>Adding and Managing of Processes to maintain incoming enquriies in a pipeline
                    </li>
                    <li>Fully tailorable Data Security Structures
                    </li>
                    <li>Automations, to convert from enquiry to data records (e.g. utilize Enquiry fields to convert one Enquiry to an "Account", a "Contact" linked to the "Account", and even a "Sales Order")
                    </li>
                    <li>Slotting in Tabs and sections int eh Entity Builder, e.g Document Tables, Galleries, and more 
                    </li>
                    </ul> 
                </li>
                <li>As a "Classified Listing Enquiry" Form, this Web Form has by default been slotted with a Entity Process/Pipeline called "Default Classifieds Process".
                </li>
            </ul><br>`
            this.AddWebFormEntityField(newfield)
        },
        SaveSurvey(){
            let docid = ''
            console.log(this.Survey)
            if(this.RouteID){
                docid = this.RouteID
                if(this.NeedsRASync){
                    this.AddDefaultProcesses(this.RASystemOpsDB.doc(docid),docid,true)
                    //this.UpdateFormFields(this.RASystemOpsDB.doc(docid),docid,false,true)
                    this.UpdateWebFormAllowed(this.RASystemOpsDB.doc(docid))
                    this.AddDefaultProcesses(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid),docid)
                    //this.UpdateFormFields(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid),docid)
                    this.UpdateWebFormAllowed(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid))
                }
                else {
                    //this.UpdateFormFields(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid,docid))
                    this.AddDefaultProcesses(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid),docid)
                    this.UpdateWebFormAllowed(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid))
                }           
                
            }
            else{
                docid = this.Survey.DisplayName.split(' ').join('_')  
                let opsdb = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid) 
                
                let DefaultFields = [
                    {Name: this.Survey.Primary_Field_Name.split(' ').join('_').split('(').join('').split(')').join('').split('/').join(''), DisplayName: this.Survey.Primary_Field_Name, Type: 'Single Line Text',Primary: true, Created: true,IsSearch: true,IsMandatory: true,IsHeader: true,HeaderIndex: 0},
                    {Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    {Name: 'State_Modified_Date', DisplayName: 'State Modified Date', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
                    this.DefaultStatusField
                ]    
               
                delete this.Survey.ShowComponents
                this.Survey.SurveyTab = this.RenderedSurveyTab 
                this.Survey.Name = this.Survey.DisplayName
                this.Survey.datasethasrecordowner = true                
                this.Survey.datasethasprocessstages = true
                this.Survey.datasethasprocessmap = true
                this.Survey.Records_Have_Owner = true
                this.Survey.RecordPrimaryField = DefaultFields[0]
                this.Survey.SingleBuildTabs = []
                // this.Survey.Has_Entity_Processes = true
                // this.Survey.Entity_Processes_Map = true
                this.Survey.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                this.Survey.Created_Byid = this.userLoggedIn.id
                this.Survey.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                this.Survey.Modified_Byid = this.userLoggedIn.id                          
                if(this.NeedsRASync){
                    let RaWebForm = Object.assign({},this.Survey) 
                    RaWebForm.Created_By = {id: this.RAUser.id, Name: this.RAUser.Name, Surname: this.RAUser.Surname, Full_Name: this.RAUser.Full_Name}
                    RaWebForm.Created_Byid = this.RAUser.id
                    RaWebForm.Modified_By = {id: this.RAUser.id, Name: this.RAUser.Name, Surname: this.RAUser.Surname, Full_Name: this.RAUser.Full_Name}
                    RaWebForm.Modified_Byid = this.RAUser.id 
                    
                 this.RASystemOpsDB.doc(docid).set(RaWebForm).then(radoc => {
                     this.UpdateFormFields(this.RASystemOpsDB.doc(docid),docid,true,true)
                     DefaultFields.map((field,fieldindex) => {
                        let fieldobj = Object.assign({},field)
                        delete fieldobj.Response
                        delete fieldobj.Array
                        delete fieldobj.CanEdit
                        delete fieldobj.CanGet
                        delete fieldobj.CanDelete
                        delete fieldobj.CanView
                        delete fieldobj.CanCreate
                        this.RASystemOpsDB.doc(docid).collection('AllFields').doc(fieldobj.Name.split(' ').join('_')).set(fieldobj)
                        if(fieldindex-1+2 === DefaultFields.length){
                            this.AddDefaultProcesses(this.RASystemOpsDB.doc(docid),docid,true)
                        }
                        })                    
                                        
                    opsdb.set(this.Survey).then(setdoc => {
                        console.log(DefaultFields)
                        this.AddDefaultFields(docid,DefaultFields)                    
                    })
                    })
                }
                else{
                    opsdb.set(this.Survey).then(setdoc => {
                        console.log(DefaultFields)
                        this.AddDefaultFields(opsdb,docid,DefaultFields)                    
                    })
                }                
            }
            
        },
        AddDefaultFields(docid,DefaultFields){
            let opsdb = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(docid)
            let fieldslength = DefaultFields.length
            DefaultFields.map((field,fieldindex) => {
                opsdb.collection('AllFields').doc(field.Name).set(field)
                .then(newfielddoc => {
                    if(fieldindex-1+2 === fieldslength){
                        //this.UpdateFormFields(opsdb,docid,true,false)
                        this.AddDefaultProcesses(opsdb,docid)
                    }
                })
            })
        },
        AddDefaultProcesses(configdb,docid,forRA){
            //alert('process')
            let NewProcess = {
                Name: 'Default Enquiries',DisplayName: 'Default Enquiries', Number: 1000001, Automations: [], Type:'Process', IsHeader: true,
                ProcessStages: [
                    {Name: 'Reviewing', Number: 1000001, Automations: [], Type:'Stage', IsHeader: true, Steps: []},
                    {Name: 'Actioned', Number: 1000002, Automations: [], Type:'Stage', IsHeader: true, Steps: []},
                    {Name: 'Completed', Number: 1000003, Automations: [], Type:'Stage', IsHeader: true, Steps: []}
                ],
                ProcessStageInteger: 1000003
            }
            //I do believe automations would be good, but a lot of thought needes to go in, on web form types and so forth before default automations could be assigned.
            let ProcessesInteger = 1000001
            configdb.update({
                ProcessesInteger: ProcessesInteger
            })
            console.log(NewProcess)
            configdb.collection('EntityProcesses').doc(NewProcess.Number.toString()).set(NewProcess).then(setdoc => {
                if(!forRA){
                  this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID)  
                }
                
            })            
        },
        UpdateFormFields(configdb,docid,newform,forRA){
            // let fieldslength = this.WebForm.WebFormTab.FormFields.length
            // if(fieldslength === 0){
            //    this.$router.push('/SystemConfig/'+process.env.VUE_APP_RA_SYSTEM_ID) 
            // }
            //         this.WebForm.WebFormTab.FormFields.map((field,fieldindex) => {
            //             let fieldobj = Object.assign({},field)                        
            //             delete fieldobj.Response
            //             delete fieldobj.Array
            //             delete fieldobj.CanEdit
            //             delete fieldobj.CanGet
            //             delete fieldobj.CanDelete
            //             delete fieldobj.CanView
            //             delete fieldobj.CanCreate
            //             console.log('configdb',configdb)
            //             configdb.collection('AllFields').doc(fieldobj.Name).set(fieldobj)
            //             // .then(newfielddoc => {
            //                 if(fieldindex-1+2 === fieldslength && newform){
            //                     this.AddDefaultProcesses(configdb,docid,forRA)
            //                 }
            //             // })
            //         })
        },
        UpdateWebFormAllowed(configdb){
            if(this.WebForm.Description){
              configdb.update({
                Description: this.WebForm.Description
             })  
            }
            if(this.WebForm.WebFormType){
              configdb.update({
                WebFormType: this.WebForm.WebFormType
             })  
            }
            if(this.WebForm.ParentEntity){
              configdb.update({
                ParentEntity: this.WebForm.ParentEntity
             })  
            }
            
        },
        AddWebFormEntityField(field){
            console.log(this.NewTableOption)
            if(!field.Name){
              field.Name = field.DisplayName.split(' ').join('_')  
            }   
            else{
               field.Name = field.Name.split(' ').join('_') 
            }         
            field.text = field.DisplayName
            field.value = field.Name
            field.IsHeader = true
            field.UsedonWebForm = true
            field.WebFormIndex = this.WebFormElmnt.FormFields.length
            
            this.WebFormElmnt.FormFields.push(field)
           this.ProcessAddField(field)
           this.UpdatewebForm()
            this.CancelNewFieldDialog()
            
        },
        UpdatewebForm(){
            this.OpsDB.collection('SurveyElements').doc(this.WebFormElmnt.id).set(this.WebFormElmnt)  
        },
        ProcessAddField(field){
            let opsdb = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.RouteID)
            if(this.NeedsRASync){
                this.RAOperationalDB.collection('AllFields').doc(field.Name).set(field).then(rafield => {
                   opsdb.collection('AllFields').doc(field.Name).set(field) 
                })                
            }
            else{
                opsdb.collection('AllFields').doc(field.Name).set(field)                
            }
        },
        ActivateNewFieldDialog(field,AddingNewField,elmnt){
            this.WebFormElmnt = elmnt
            this.NewField = Object.assign({},field)
            this.NewFieldDialog = true
            this.AddingNewField = AddingNewField
            console.log(this.EntityCollectionRef,field)
            // if(tableoption){
            //     this.NewTableOption = tableoption 
            // }
        },
        CancelNewFieldDialog(){
            this.NewFieldDialog = false,
            this.NewField = ''
             this.AddingNewField = false
            //this.NewTableOption = ''
        },
        AddorUpdatedTab(){
            let elementid = this.Survey.SurveyTab.Elements.length+1000000
            this.WebFormElmnt.FormIndex = this.Survey.SurveyTab.Elements.length
            let docid = elementid.toString()
            //this.ActiveSurveyTab.Elements.push(this.WebFormElmnt)
            //console.log(this.Survey)
            this.OpsDB.collection('SurveyElements').doc(docid).set(this.WebFormElmnt).then(doc => {
                this.CancelAddorUpdatedTab()
            })
        },
        CancelAddorUpdatedTab(){
            this.WebFormTabdialog = false
            this.WebFormElmnt = Object.assign({},this.DefaultWebForm)
        },
        ActivateAddNewWebFormTabDialog(){
            this.EditedTabIndex = this.Survey.SurveyTab.Elements.length
            this.DefaultWebForm.Entityid = this.RouteID
            this.WebFormElmnt = Object.assign({},this.DefaultWebForm)
            this.WebFormTabdialog = true
        },
        DeactivateAddNewWebFormTabDialog(){
            this.WebFormTabdialog = false
            this.WebFormElmnt = Object.assign({},this.DefaultWebForm)
        },
        GetSurvey(){
            this.Survey = this.SystemEntities.find(obj => obj.id === this.RouteID)
        }
    }
}
</script>

<style>

</style>
