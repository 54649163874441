
        <template>
  <v-layout class="justify-center" :style="SampleOnly ? '' : 'padding-bottom:60px;'">
      <div :class="SampleOnly ? 'flex xl12 lg12 md12 sm12 xs12' : 'flex xl10 lg10 md11 sm11 xs11'">
          <v-row :class="SampleOnly ? '' : 'mx-3'" :style="SampleOnly ? '' : 'padding-top:60px;'">
        <v-col
          v-for="card in ComputedBusinessUnitMembers" :key="card.id"
         class="d-flex child-flex"
          >
          <v-card width="350" height="250"
                light :to="'/BusinessMember/'+card.id"
                :style="{ backgroundImage: 'linear-gradient(60deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0.5) 100%),url(' + require('@/assets/logo.png') + ')'  ,backgroundSize: 'cover'}">
            
                <v-list dense 
                class="transparent">
              <v-list-item>
                <v-list-item-avatar tile size="80">
                  <v-img contain src="@/assets/logo.png"></v-img>
                </v-list-item-avatar><v-spacer></v-spacer>
                <v-list-item-avatar
                v-if="card.Profile_Photo"
                  size="80"
                  color="grey"
                >
                <img 
                style="object-fit: cover;"
                :src="card.Profile_Photo">                
                </v-list-item-avatar>
                <v-list-item-avatar
                v-if="!card.Profile_Photo && AppisDarkMode"
                  size="80"
                  color="grey"
                >               
                <img 
                src="@/assets/ImageHolder_dark.png">
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="120"
                  color="grey"
                  v-if="!card.Profile_Photo && !AppisDarkMode"
                >
                <img 
                src="@/assets/ImageHolder.png">
                </v-list-item-avatar>
              </v-list-item>
              <hr class="accent" style="width:90%;">
              <div class="caption" style="padding-left:20px;" v-html="card.LocationSlogan">
              </div>    
              <hr class="accent" style="width:80%;">
              <v-list-item>
                  <v-list-item-content >
                      <v-list-item-title class="overline" style="font-weight:bold;">{{card.Full_Name}}</v-list-item-title>      
                      <v-list-item-subtitle style="font-weight:bold;">
                      {{card.Position}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  <v-list-item-content >
                      <v-list-item-title>{{card.Business_UnitName}}</v-list-item-title> 
                       <v-list-item-subtitle style="font-weight:bold;">
                        {{card.Mobile_Number}}
                      </v-list-item-subtitle> 
                       <v-list-item-subtitle v-if="card.Work_Number" style="font-weight:bold;">
                        {{card.Work_Number}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="!card.Work_Number && card.LocationWork_Number" style="font-weight:bold;">
                        {{card.LocationWork_Number}}
                      </v-list-item-subtitle> 
                       <!-- <v-list-item-subtitle style="font-weight:bold;">
                        {{card.Mobile_Number}}
                      </v-list-item-subtitle>                -->              
                    </v-list-item-content>
              </v-list-item>
            </v-list>
           

            </v-card>
        </v-col>
      </v-row>
      </div>
  </v-layout>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
  props: ['SampleOnly'],
    data(){
        return {
            BusinessUnitMembers: []
        }
    },
    computed:{
      ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
      userLoggedIn () {
        return this.$store.getters.user
        },
        userBUID () {
        return this.$store.getters.userBUID
      },
      userBU(){
        return this.BusinessUnitsStore.find(obj => obj.id === this.userBUID)
      },
        BusinessUnitsStore(){
          return this.$store.state.BusinessUnitsArray
        },
      ComputedBusinessUnitMembers(){
        return this.SampleOnly && this.userLoggedIn ? [
          {
            Full_Name: this.userLoggedIn.Full_Name,
            Business_UnitName: this.userLoggedIn.Business_Unit.Name,
            Position: this.userLoggedIn.Position,
            Mobile_Number: this.userLoggedIn.Mobile_Number,
            Work_Number: this.userLoggedIn.Work_Number,
            LocationWork_Number: this.userBU.Telephone,
            LocationSlogan: this.userBU.Slogan,
            Profile_Photo: this.userLoggedIn.Profile_Photo,
            id: this.userLoggedIn.id
          }
        ] : this.BusinessUnitMembers
      },
    },
    created(){
        this.GetBusinesUnitMembers()
    },
    methods:{
        GetBusinesUnitMembers(){
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessmembers').onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.BusinessUnitMembers.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                       
                        }  
                    }) 
            })
        }
    }
}
</script>

<style>

</style>

    