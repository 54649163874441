<template>

      <v-layout class="justify-center"> 
      <v-card flat tile style="padding-bottom:100px;padding-top:40px;" width="100%">
        <v-sheet height="64" class="transparent" >
        
        <v-toolbar elevation="4" style="margin-top: 0px;margin-bottom:-40px;border-radius: 0px 0px 5px 5px;margin-right:60px;" >
            
          <v-btn outlined class="mr-4"  @click="setToday()">
            Today
          </v-btn>
          <v-btn fab text small  @click="$refs.calendar.prev()">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small  @click="$refs.calendar.next()">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ datetitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet :height="MiniView? 300 : 400">
          
        <v-btn color="green" dark fab icon @click="ActivateNewEvent()"><v-icon>mdi-plus-thick</v-icon></v-btn>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="AllEventsViewFormatted"
            :event-color="getEventColor"
            :now="today"
            :type="type"
            :event-ripple="false"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseup:event="CheckDrag"
            @mouseleave.native="cancelDrag"
            @change="updateRange"
             @click:event="OpenEventEditor"
             
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-dialog
          max-width="800"
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <!-- <v-toolbar
                color="pop"
                dark
              >
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent[this.CurrentEntity.Primary_Field_Name]"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>              
              </v-toolbar> -->
              <v-toolbar v-if="CurrentEntity.HasFromField" height="100px">
                <v-list >
                  <v-list-item>
                <v-text-field v-model="selectedEvent.From"  label="From" readonly />
                  </v-list-item>
                </v-list>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.Description"></span>
              </v-card-text>
              <v-list-item v-if="selectedEvent.Location_Street_Number && selectedEvent.Location_Street_Name && selectedEvent.Location_Suburb && selectedEvent.Location_City && selectedEvent.Location_Postal_Code && selectedEvent.Location_State_Province && selectedEvent.Location_Country_Region">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                        
                          <v-btn icon @click="ViewMapItem(selectedEvent.Location_Street_Number,selectedEvent.Location_Street_Name,selectedEvent.Location_Suburb,selectedEvent.Location_City,selectedEvent.Location_Postal_Code,selectedEvent.Location_State_Province,selectedEvent.Location_Country_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-dialog max-width="800" v-model="ConfirmationMessageDynamic">
                  <template v-slot:activator="{ on }">
                     <v-btn v-on="on" v-if="CurrentEntity && CurrentEntity.HasCalendarEntryResponse && !CurrentEntity.CalendarEntryResponseisStatic && selectedEvent.Status_Reason && selectedEvent.Status_Reason.ID === 1000001">
                      Confirm
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Confirmation Message
                    </v-card-title>
                    <v-layout class="justify-center">
                    <v-card width="90%" contenteditable class="postinputbox" id="ConfirmationMessage">
                    </v-card>
                    </v-layout>
                    <v-card-actions>
                      <v-btn>Cancel</v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="ConfirmCalendarEntry()" v-if="CurrentEntity && CurrentEntity.HasCalendarEntryResponse && !CurrentEntity.CalendarEntryResponseisStatic && selectedEvent.Status_Reason && selectedEvent.Status_Reason.ID === 1000001">
                      Confirm
                    </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
               
                <v-btn @click="CloseCalendarEntry()" v-if="CurrentEntity && CurrentEntity.HasCalendarEntryResponse && selectedEvent.Status_Reason && selectedEvent.Status_Reason.ID === 1000002">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-card>
    </v-layout>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['CurrentEntity','Data','Editing','CanCreate','CalendarEntryCompleted'],
    components: {

    },
    data(){
        return{
          
          dragEvent: null,
          createEvent: null,
          createStart: null,
          extendOriginal: null,
          colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
          TableData: [],
          EditedNewEventIndex: -1,
          newevents: [],
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            ConfirmationMessageDynamic: false,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
        }
    },
    computed:{
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
        AllEventsViewFormatted(){
      return this.TableData.concat(this.NewEventsPrepped).map(event => {
          let eventobj = Object.assign({},event) 
          eventobj.name = eventobj[this.CurrentEntity.Primary_Field_Name] 
          //console.log(eventobj)  
        return eventobj
      }).map(session => {
      if(typeof session.end === 'number'){
        
        let enddate = new Date(session.end)
        session.EndTime = enddate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
        session.EndObject = enddate
        session.End = format(enddate,'yyyy-MM-dd')
        //console.log(session.end)
      }
      return session
    }).map(session => {
      if(typeof session.start === 'number'){
        let startdate = new Date(session.start)
        session.StartTime = startdate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
        session.StartObject = startdate
        session.Start = format(startdate,'yyyy-MM-dd')
      
      }
      return session
    })
    },
    datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
      NewEventsPrepped(){
          return this.newevents.map(session => {
            //console.log(session)
            let end = session.end
            let start = session.start
            let enddate = new Date(end)
            let startdate = new Date(start)
            let newenddate = new Date(end)
            let newstartdate = new Date(start)
            session.name = session[this.CurrentEntity.Primary_Field_Name]
            session.EndObject = new Date(enddate)
            session.End = format(session.EndObject,'yyyy-MM-dd')
            session.EndTime = newenddate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
            session.StartObject = new Date(startdate)
            session.StartTime = newstartdate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
            session.Start = format(session.StartObject,'yyyy-MM-dd')
            return session
          }).filter(session => {
            //return !this.createEvent || this.createEvent && session.name !== this.createEvent.name
            return session
          })
        },
    },
    watch: {
      CalendarEntryCompleted(v){
        if(v && this.EditedNewEventIndex !== -1){
          this.newevents.splice(this.EditedNewEventIndex,1)
          this.EditedNewEventIndex = -1
        }
      },
      Data: {
        handler: function(newvalue, oldvalue) { 
            if(newvalue !== oldvalue){
              this.PrepareData()
            }    
            },
            deep: true
      },
    },
    created(){
      this.PrepareData()
      var d = new Date();
      var n = d.toISOString();
      this.today = n.split('T')[0]
      this.setToday()
    },
    methods:{
      PrepareData(){
        this.TableData = this.Data.map(item => {
          let itemobj = Object.assign({},item)
          //console.log(itemobj,item)
          return itemobj
        })
      },
      startDrag ({ event, timed }) {
        if (event && !event.id) {    
          this.dragEvent = this.newevents.find(obj => obj.name === event.name)
          this.dragTime = null
          this.extendOriginal = null          
        }
        else{
          this.dragEvent = this.TableData.find(obj => obj.id === event.id)
          this.dragTime = null
          this.extendOriginal = null     
        }
      },
      startTime (tms) {
        const mouse = this.toTime(tms)
        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start

          this.dragTime = mouse - start
        } else {
          this.createStart = this.roundTime(mouse)
          this.createEvent = {
            [this.CurrentEntity.Primary_Field_Name]: `Event #${this.AllEventsViewFormatted.length}`,
            name: `Event #${this.AllEventsViewFormatted.length}`,
            color: this.rndElement(this.colors),
            start: this.createStart,
            end: this.createStart,
            timed: true,
          }
      //console.log(this.newevents)
          this.newevents.push(this.createEvent)
        } 
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)]
      },
      extendBottom (event) {
        //console.log(event)
        if(!event.id){
          this.createEvent = this.newevents.find(obj => obj.name === event.name)
          this.createStart = event.start
          this.extendOriginal = event.end
        }
        else{
          this.createEvent = this.TableData.find(obj => obj.id === event.id)
          this.createStart = event.start
          this.extendOriginal = event.end
        }
        
        
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
        }
      },
      CheckDrag({ event, timed }){
        //console.log(event)
        if(event.id){
          this.OpenEventEditor({event})
        }
      },
      endDrag () {
        this.cancelDrag(true)
        this.dragTime = null
        this.dragEvent = null
        //this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag (skip) {
        //console.log('mouse left',this.createEvent,this.extendOriginal)
        if (this.createEvent) {
          if (this.extendOriginal) {
            //this.createEvent.end = this.extendOriginal
          } else if(!skip) {
            const i = this.newevents.indexOf(this.createEvent)
            if (i !== -1) {
              this.newevents.splice(i, 1)
            }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },
      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },
      UploadFileSelect(file,field,Record){
      console.log(file,field,Record,this.BookingHeaders)
      field.UploadFile = file
      field.FileName = file.name
      let uploadfileobj = {
        id: field.value,
        UploadFile: file,
        FieldObj: field
      }
      let oncheck = Record.UploadFiles.find( obj => obj.id === uploadfileobj.id)
      if(!oncheck){
        Record.UploadFiles.push(uploadfileobj)
      }
      else{
        let index = Record.UploadFiles.indexOf(oncheck)
        Record.UploadFiles[index] = Object.assign({},uploadfileobj)
      }
      
    },
        ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
    CloseCalendarEntry(){
      let newstatus = {ID: 1000003,
                                Name: 'Closed'}
             //What was the point behind this?!!!!!!! Why "sites"???
              // db.collection('sites').doc(this.ActiveTemplateSite.id).collection('entities').doc(this.ActiveEntity.id).collection('datarecords').doc(this.selectedEvent.id).update({
              //   Status_Reason: newstatus
              // })
              this.selectedEvent.Status_Reason = newstatus
    },
    ConfirmCalendarEntry(){
      //send email to selectedEvent.From
      //Render static, or let user input message, depending  ActiveEntity.RAStylesheet.CalendarEntryResponseisStatic
            let elmnt = document.getElementById('ConfirmationMessage')
            let message = elmnt.innerHTML
            let EmailBody = `<html>
<body>
<head></head>
<h2>Booking Confirmed</h2>`+message+`
</body>
</html>`
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: this.selectedEvent.From,
                EmailSubject: 'Booking Confirmed',
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
              let confirmedstatus = {ID: 1000002,
                                Name: 'Confirmed'}
              //What was the point behind this? why "sites"???
              // db.collection('sites').doc(this.ActiveTemplateSite.id).collection('entities').doc(this.ActiveEntity.id).collection('datarecords').doc(this.selectedEvent.id).update({
              //   Status_Reason: confirmedstatus,
              //   ConfirmationMessage: message
              // })
              this.selectedEvent.Status_Reason = confirmedstatus
              this.ConfirmationMessageDynamic = false
    },
    updateRange ({ start, end }) {
      //console.log('change')
      this.start = start
        this.end = end
    },
    getEventColor (event) {
      if(event.id && event.Status_Reason.ID === 1000001){
        return 'orange'
      }
      else if(event.id && event.Status_Reason.ID === 1000002){
        return 'green'
      }
      else{
        return 'grey'
      }
        
      },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
    nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      OpenEventEditor({ nativeEvent, event }){
       this.$emit('OpenEventEditor',event)
          if(!event.id){
            let eventobj = this.newevents.find(obj => obj.Title === event.Title)
            this.EditedNewEventIndex = this.newevents.indexOf(eventobj)
            console.log(this.EditedNewEventIndex,event,this.newevents)
          } 
        
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      setToday () {
        
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
    }
}
</script>

<style>

</style>