
<template>
<!-- modular tabs replace card color with style :style="PageBGGradient" as opposed to color="rgba(255,255,255,0.01)" -->
  <v-card  v-if="!PushedID" :style="PageBGGradient" :dark="AppisDarkMode" flat height="100%" :class="TabsRenderMode === 'Selected Element' ? 'background' : ''" >
    <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="ExporttoPDFFunctions" :FunctionPages="Pages"
    :FullItem="DynamicRecordData" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" 
    />
    <v-dialog fullscreen v-model="TimesheetsDialog">
      <v-card tile>
        <v-card-title>
          <v-icon color="red" wi @click="TimesheetsDialog = !TimesheetsDialog" dark class="soloactionicon">
            mdi-close</v-icon>
          TimeSheets</v-card-title>
    <Timesheets :RelatedObj="RelatedObj"
    :System="System" :SystemEntities="SystemEntities"
    />
      </v-card>
    </v-dialog>
    <v-dialog v-model="SelectProcessDialog" max-width="400">
  <v-card>
    <v-card-title>
      Assign Process
    </v-card-title>
    <v-select return-object @change="AssignProcessConfirm()" v-model="ActiveProcess" item-text="DisplayName" :items="CurrentEntity.EntityProcesses" label="Process"></v-select>
  </v-card>
</v-dialog>
<v-dialog v-model="InvoicePaymentConfirmationDialog" max-width="800">
        <v-card tile  width="100%" flat v-if="RecordData && RecordData.id">
          <v-card-title class="justify-space-between recordtoolbar white--text mediumoverline">
            Payment Receipt<v-spacer></v-spacer>{{RecordData.id}}
          </v-card-title>
          <v-card-text>
            <FieldValueEditerComponent v-for="field in PaymentReceiptHeaders" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="paymentreceipt" :field="field" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Reference'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect"
                />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="ClosePaymentConfirmationDialog()" dark color="warning">
              Cancel
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn @click="UploadPOP()">Save</v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
    <!-- modular tabs singlrecordnav -->
     <v-dialog v-model="RATicketLog" max-width="600px">
      <v-card width="100%">
        <SystemTicketsLogging :db="RADB" :SystemEntities="SystemEntities" @ProvideTicket="ProvideTicket" :RelatedObj="RelatedObj"
        @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="RAUser" :UsersArray="[RAUser]" :View="'Single'" />
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="RoadmapItemDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          Roadmap Dialog
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="NewRoadmapItem.Name" label="Name"/>
          <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewRoadmapItem" :field="{Name: 'Overview',DisplayName: 'Overview',Type: 'Multiple Lines Text',IsContentEditable: true}" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect" 
              />
              <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewRoadmapItem" :field="{Name: 'DueDate',DisplayName: 'DueDate',Type: 'Date'}" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect" 
              />
           <v-select v-model="NewRoadmapItem.TaskPriority" label="Priority" :items="[1,2,3]"></v-select>
           <v-select  :items="STDActionTypes" item-text="Name" v-model="NewRoadmapItem.Type" label="Type" return-object></v-select>
         <!--  <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewForum" :field="ForumCategoriesField" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect" 
              />
            <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewForum" :field="ForumDescriptionField" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect" 
              /> -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelRoadmapItemDialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="CreateRoadmapItem()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ForumDiscussionDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          Forum Discussion
        </v-card-title>
        <v-card-text>
          Here you can Create a Forum Discussion from the Record
        </v-card-text>
        <v-card-text>
          <v-text-field v-model="NewForum.Title" label="Title"/>
          <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewForum" :field="ForumCategoriesField" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect" 
              />
            <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="NewForum" :field="ForumDescriptionField" @onPhotoFileselected="onPhotoFileselected"
            :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            @UploadFileSelect="UploadFileSelect" 
              />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelForumDiscussionDialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="CreateForumDiscussion()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="TableStateDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          Table State Management
        </v-card-title>
        <v-card-text>
          Here you can configure the State Management Principle of the Table.
        </v-card-text>
        <v-card-text>
         <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
            :CurrentEntity="CurrentEntity"
          :SystemEntities="SystemEntities" :field="EditedStatusField" :NewField="false" :System="System"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelTableStateDialog()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="UpdateStatemanagement()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="ReceivingDialog" max-width="800">
      <v-card>
        <v-card-title class="recordtoolbar white--text">
          Goods Receiving
        </v-card-title>
        <v-card-text style="padding-top: 20px;">           
              <FieldValueEditerComponent v-for="field in ReceivingFields" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField" :Record="editedReceivingItem" :field="field" @onPhotoFileselected="onPhotoFileselected"
                  :SystemEntities="SystemEntities" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                  @UploadFileSelect="UploadFileSelect"
                    />
        </v-card-text>
        <v-card-actions class="recordtoolbar white--text">
          <v-btn dark class="warning" @click="DeactivateReceivingDialog()">
            <v-icon>mdi-cancel</v-icon>
            Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn dark class="success" @click="SaveReceivingItem()">
            <v-icon>mdi-content-save</v-icon>
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      
    <SingleRecordNavMenuComp v-if="SingleRecordNavMenu && EntityReady" :SingleBuildTabs="NavMenuSingleBuildTabs" :EntityReady="EntityReady" :ConfigSystemEntities="ConfigSystemEntities" :System="System"
    :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ParentNavItem="ParentNavItem" :SystemEntities="SystemEntities" :FavoriteFeatures="FavoriteFeatures" @ToggleFeatureFavorites="ToggleFeatureFavorites"
      @ActivateSingleRecordNavMenuItem="ActivateSingleRecordNavMenuItem" :SitePages="SitePages" :SubCollections="SubCollections" :SystemPhotoLibraries="SystemPhotoLibraries"
      :Directories="Directories"
      :AppisDarkMode="AppisDarkMode" :SingleRecordNavName="RecordData[CurrentEntity.Primary_Field_Name]"/>
  
<v-main style="margin-top:-140px;"  v-if="!PushedID" color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
    
  <v-dialog width="400" v-model="ChangeStatusDialog">
          <v-card width="400">
            <v-card-title>Change Status</v-card-title>
            <v-card-text>
              <v-autocomplete class="mx-5"
            :items="StatusField.Options"
            v-model="Status"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            <v-autocomplete class="mx-5"
             v-if="Status"
            :items="Status.Options"
            v-model="Status_Reason"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="ResetStatus()">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="UpdateStatus()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      
      <DataRecordToolbar v-if="!WikiMode" :SystemEntities="SystemEntities" :System="System" :CurrentEntity="CurrentEntity" :AppStylingView="AppStylingView"
      :Status="Status"  @AuthenticateClientApp="AuthenticateClientApp" :DynamicRecordData="DynamicRecordData" :EntityReady="EntityReady" :RecordData="RecordData"
        :ClientDBBusinessUnits="ClientDBBusinessUnits" :ClientDBAdministrators="ClientDBAdministrators" :QuickstartStep="QuickstartStep" :clientDB="clientDB" :clientApp="clientApp"
        :QuickStartDialog="QuickStartDialog" :ClientFireStore="ClientFireStore" :SystemObj="RecordData" @ActivateLiaisonDialog="ActivateLiaisonDialog" @ToggleTimesheetlistdialog="ToggleTimesheetlistdialog"
        :ClientappSignedInEmail="ClientappSignedInEmail" :ClientAppSignedIn="ClientAppSignedIn" :BaseClientSystemReady="BaseClientSystemReady" @SetActivityRegarding="SetActivityRegarding"
        :ClientAppAuthDialog="ClientAppAuthDialog" :ViewingSystemID="RouteID" :EntityDataRef="OperationalDB" @ToggleTimesheetDialog="ToggleTimesheetDialog"
       :EntityCollectionRef="ConfigDB" :BannerTabs="BannerTabs" :RAUser="RAUser" :StatusToggleName="StatusToggleName" :ConfigSystemEntities="ConfigSystemEntities"
       :CanModerateClassified="CanModerateClassified" :CanModerateWiki="CanModerateWiki" :ClientAppUser="ClientAppUser" @GetShortLivedURL="GetShortLivedURL"
       :CanEdit="CanEdit" :CanEditOwnership="CanEditOwnership" :EditingOwnership="EditingOwnership" :CanEditLayout="CanEditLayout" @ViewActivities="ViewActivities"
       :StatusField="StatusField" :SingleFormEditing="SingleFormEditing" :DynamicWikiData="DynamicWikiData" :ExportingtoPDFDialog="ExportingtoPDFDialog"
       @NoPropEmit="NoPropEmit" @OnePropEmit="OnePropEmit" @EditOwnership="EditOwnership" @ActivateReceivingDialog="ActivateReceivingDialog"
       @UpdateOwningUser="UpdateOwningUser"
      />
      
<!-- <v-layout v-if="userIsAdmin" row class="stickytopright mx-3" style="padding-top:10px;right:100px;">
  <v-icon style="background-color:green;" @click="ConfirmRenameFieldRAOnly(0,0,'Account_Type','Billing_Type',true,true)" color="white" class="soloactionicon">mdi-auto-fix</v-icon>
  </v-layout> -->
      
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2" style="padding-top:10px;">
    <v-btn class="mx-3" color="success" @click="ActivateInvoicePaymentConfirmationDialog()" dark v-if="CanRecordPayment">Record Payment</v-btn>
    <v-btn class="mx-3" color="success" @click="GetShortLivedURL(RecordData.Proof_of_Payment)"  dark v-if="InvoicePaidinFull">View POP</v-btn>
    
  <v-icon v-if="$route.meta && $route.meta.SystemBuilder" style="background-color:green;" @click="UpdateSystem()" color="white" class="soloactionicon">mdi-content-save</v-icon>
  </v-layout>
  

  
  
    

  
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the System help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
  
  
     <v-layout column :style="FabContainerStyle" id="myBtn">
    <v-icon class="soloactionicon" :style="$vuetify.breakpoint.xs ? 'font-size: 20px;background-color:red;' : 'font-size: 40px;background-color:red;'" color="white" @click="topFunction()">mdi-arrow-up</v-icon>
  </v-layout>
  <!-- ComputedSingleBuildTabs {{ComputedSingleBuildTabs}}
  !SingleFormEditing && EntityReady {{!SingleFormEditing && EntityReady}} -->
  <!-- SingleRecordNavMenuItem {{SingleRecordNavMenuItem}} -->
  <!-- :DataSingleTabs="SingleRecordNavMenuItem && SingleRecordNavMenuItem.SingleBuildTabs ? SingleRecordNavMenuItem.SingleBuildTabs : ComputedSingleBuildTabs" -->
  <!-- <v-layout  column v-if="HasLiaison"
    style="position: fixed;
    top: 190px;
    left: 50px;
    z-index: 5"
    > -->
      <RecordLiaison v-if="HasLiaison" :RecordData="RecordData" :ActiveSession="CurrentEntity" :LiaisonDialogActive="LiaisonDialogActive"
      :OperationalDB="OperationalDB" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
      @ActivateProcessing="ActivateProcessing" @ToggleLiaisonDialog="ToggleLiaisonDialog"
      :System="System" :SystemEntities="SystemEntities" :CanEdit="CanEdit" :ActiveRecord="ActiveProcess"
      />
  <!-- </v-layout> -->

 <v-stepper v-if="HasProcess && !ActivitiesView" v-model="StageID"  :dark="AppisDarkMode"  style="margin-top:200px;">
   <v-switch v-if="HasProcedure" class="mx-3" label="View Full Record" v-model="FullRecordView"/>
      <v-stepper-header>
        <span v-for="(stage,index) in ProcessStages" :key="stage.Number">
          <v-stepper-step complete-icon="mdi-check" :edit-icon="IsComplete(stage) ? 'mdi-check' : 'mdi-pencil'" :editable="!HasProcedure" :step="index-1+2" :complete="IsComplete(stage)" @click="updateProcessStage(stage,ActiveProcessDocid)">
            {{stage.Name}}
          </v-stepper-step>
          <v-divider></v-divider></span>          
      </v-stepper-header>     
    <v-list dense style="margin-top:-15px;" class="justify-space">
      <v-list-item class="recordtoolbar">
      <v-list-item-content >
        <v-list-item-title style="font-size: 1.25em;padding-bottom:10px;" class="mediumoverline white--text">{{ProcessStages[StageID-1].Name}}</v-list-item-title> 
      </v-list-item-content>
      </v-list-item>
      <v-list-item >
      <v-list-item-content >             
        <v-list-item-subtitle style="font-size: 0.825em !important;" class="subtleoverline" v-if="StageID && ProcessStages && ProcessStages[StageID-1].Steps">
          Checklist below
        </v-list-item-subtitle>
        <v-list-item-subtitle style="font-size: 0.825em !important;" class="subtleoverline"  v-else>
          No Checklist
        </v-list-item-subtitle>
      </v-list-item-content>
      </v-list-item>
         <ProcedureBuilder v-if="HasProcedure" :ProcessStep="ProcessStages[StageID-1]" @updateProcessStage="updateProcessStage"
          @onPhotoFileselected="onPhotoFileselected"  @onPhotoUpload="onPhotoUpload"
         :ActiveProcess="ActiveProcess" :ProcessStages="ProcessStages" :StageID="StageID" :ActiveProcessDocid="ActiveProcessDocid"
         :ProcedureSteps="ProcessStages[StageID-1].Steps" :CanEditBuild="false" :CurrentEntity="CurrentEntity"
         :Record="DynamicRecordData" @UpdateEditableField="UpdateEditableField" :SingleBuildTabs="ComputedSingleBuildTabs"
         :EntityCollectionRef="ConfigDB" :EntityDataRef="OperationalDB"  :RelatedObj="RelatedObj"
         @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
         @UpdateProcedureStep="UpdateProcedureStep"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
            :EditedElement="EditedElement" :EditedElementIndex="EditedElementIndex" style="padding-bottom:50px;"
            />
      <v-list-item>
        <v-btn dark color="warning" v-if="ProcessStages[StageID-2]"  @click="updateProcessStage(ProcessStages[StageID-2],ActiveProcessDocid)"><v-icon>mdi-chevron-double-left</v-icon>{{ProcessStages[StageID-2].Name}}</v-btn>
        <v-btn v-else disabled>Start of Process</v-btn>  
        <v-spacer></v-spacer><v-btn dark color="success" :disabled="ProcessStages[StageID-1].Steps" v-if="ProcessStages[StageID]"  @click="updateProcessStage(ProcessStages[StageID],ActiveProcessDocid)"><v-icon>mdi-chevron-double-right</v-icon>{{ProcessStages[StageID].Name}}</v-btn>
        <v-btn v-else disabled>End of Process</v-btn>
      </v-list-item>
    </v-list>
  </v-stepper>
<div v-if="!ActivitiesView && RelatedObj" :style="ProcessStages && ProcessStages.length > 0 ? 'margin-top:50px;' : 'margin-top:180px;'">
  
    <v-btn v-if="$route.query.TableType" @click="NavigatetoRouteParent()">Back to Parent</v-btn>
    <v-btn v-if="$route.query.TableType" @click="RefreshComponent()">Refresh</v-btn>
  
      <SingleElement v-if="TabsRenderMode === 'Selected Element'" :DataSingleTabs="SelectedTabs"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing"
       :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :SingleElementView="true"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
       :EntityCollectionRef="ConfigDB" :CurrentEntity="CurrentEntity"
       :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="''" @UpdateRecordCalculation="UpdateRecordCalculation"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
        :ActivitiesArray="ActivitiesArray" :FeatureNavList="FeatureNavList"
        :PrimaryFieldName="PrimaryFieldName" :clientDB="clientDB"
       :ActiveEntityID="ConfigEntityID"/>
       <LandingPageSingle v-if="TabsRenderMode === 'Standard Tabs'" :DataSingleTabs="ComputedSingleBuildTabs"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing" :SystemPhotoLibraries="SystemPhotoLibraries"
       :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
       :EntityCollectionRef="ConfigDB" :CurrentEntity="CurrentEntity"
       :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="''" @UpdateRecordCalculation="UpdateRecordCalculation"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
        :ActivitiesArray="ActivitiesArray" :FeatureNavList="FeatureNavList"
        :PrimaryFieldName="PrimaryFieldName" :clientDB="clientDB"
       :ActiveEntityID="ConfigEntityID"/>
       <NewLandingPage v-if="TabsRenderMode === 'Editing Tabs'" :DataSingleTabs="ComputedSingleBuildTabs"
       :SinglePageBGGradient="SinglePageBGGradient" :SystemPhotoLibraries="SystemPhotoLibraries"
       :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" :FeatureNavList="FeatureNavList"
       :EntityCollectionRef="ConfigDB" :CurrentEntity="CurrentEntity"
       :DataSingleBuilder="true"
       :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="''"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
        :ActivitiesArray="ActivitiesArray" @CancelEditing="CancelEditing"
        :PrimaryFieldName="PrimaryFieldName"
       :ActiveEntityID="ConfigEntityID"/>  
    </div>
    <div v-if="ActivitiesView" style="margin-top: 150px;">
      <AllMyTasksandAppointments :SystemEntities="SystemEntities" @RemoveActivity="RemoveActivity" :RelatedObj="RelatedObj"
      :ActivityType="ComputedActivityType" :UserRecord="userLoggedIn" :AllEvents="AppointmentsArray" :ActivitiesArray="ActivitiesArray"
  />
    </div>
</v-main>
    </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
import { jsPDF } from "jspdf";
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
import { getDate, getMonth, getYear } from 'date-fns';
import axios from 'axios'
import DataRecordToolbar from '@/components/Database/DataRecordToolbar'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'
import LookupComponentSubCollections from '@/components/Database/LookupComponentSubCollection'
import LookupComponent from '@/components/Database/LookupComponent'
import FieldConfig from '@/components/Database/FieldConfig';
// modular tabs comps
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import SingleElement from '@/components/WebPages/LandingPageSingle'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import AuditHistoryView from '@/components/SuitePlugins/AuditHistory/AuditHistoryView';
import SystemBuilderComponent from '@/components/RAExclusive/SystemBuilderComponent';
import SecurityRulesUpdateComponent from '@/components/Database/SecurityOverview';
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
//singlerecordnav
import SingleRecordNavMenuComp from '@/components/Navigation/SingleRecordNavMenuComp'
import ProcedureBuilder from '@/components/SuitePlugins/Pipelines/ProcedureBuilder';
import RecordLiaison from '@/components/General/RecordLiaison';
import Timesheets from '@/components/SuitePlugins/Timesheets/Timesheets';
import AllMyTasksandAppointments from '@/views/Activities/MyProfile/AllMyTasksandAppointments';

export default {
  // modular tabs props ,'SystemEntities','System':RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"
  props: ['AppisDarkMode','FunctionEntityid','PushedID','SystemEntities','System','AppisDarkModeCard','SitePages','AppStylingView','SystemPhotoLibraries','Directories','SystemGuides','RADB','RAApp','FavoriteFeatures'],
components: {
  ContentEditableField,
  jsPDF,
  PDFExporter,
  DataRecordToolbar,
  SystemTicketsLogging,
  LookupComponentSubCollections,
  LookupComponent,
  FieldConfig,
// modular tabs comps
  NewLandingPage,
  SingleElement,
  LandingPageSingle,
  AuditHistoryView,
  SystemBuilderComponent,
  SecurityRulesUpdateComponent,
  //singlerecordnav
  SingleRecordNavMenuComp,
  FieldValueEditerComponent,
  ProcedureBuilder,
  RecordLiaison,
  Timesheets,
  AllMyTasksandAppointments
},
    

  data() {
    return {
      PaymentReceiptHeaders: [
            {id: 'Amount_Paid',DisplayName: 'Amount Paid', Name: 'Amount_Paid',Type: 'Number Field'},
            {id: 'SelectedPOPFile',DisplayName: 'POP File', Name: 'SelectedPOPFile',Type: 'Single File Upload'},
          ],
          InvoicePaymentConfirmationDialog: false,
          paymentreceipt: {
            Amount_Paid: 0,
            SelectedPOPFile: {}
          },
      RelatedTabsRefresh: false,
      RefreshingSingleElement: false,
      SelectedElement: '',
      ActivityType: '',
      ActivitiesView: false,
      UpdatingProcess: false,
      TimesheetsDialog: false,
      LiaisonDialogActive: false,
      FullRecordView: false,
      ActivitiesArray: [],
      SelectProcessDialog: false,
      ActiveProcess: '',
      ActiveProcessDocid: '',
      ProcessStages: [],
      StageID: 1,
      StageName: '',
      CustomRolesDefault:   
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'UpdatePermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
      CanEdit: false,
      EditingOwnership: false,
      RATicketLog: false,
      ReceivingDialog: false,
      editedReceivingItem: {},
      editedReceivingItemIndex: -1,
      ReceivingFields: [
        {id: 'Reference_Number',DisplayName: 'Reference Number', Name: 'Reference_Number',Type: 'Single Line Text'},
        {id: 'Qty',DisplayName: 'Qty', Name: 'Qty',Type: 'Number Field'},
        {id: 'Date_Received',DisplayName: 'Date Recieved', Name: 'Date_Received',Type: 'Date'},
        {id: 'Price',DisplayName: 'Price', Name: 'Price',Type: 'Number Field'},
        {id: 'Receiving_Document',DisplayName: 'Receiving Document', Name: 'Receiving_Document',Type: 'Single File Upload'},
        {id: 'Progress',DisplayName: 'Progress', Name: 'Progress',Type: 'Option Set',Options: [{ID: 1000000, Name: 'Received'},{ID: 1000001,Name: 'Inspection'},{ID: 1000002,Name: 'Completed'}]}
      ],
      //systemconfigbuilder props
      BaseClientSystemReady: false,
      firebaseConfig: '',
      RouteConfig: '',
      ConfigSystemEntities: [],
      ConfigSystem: '',
      clientloginemail: '',
      clientloginpassword: '',
      ClientAppAuthDialog: false,
      UserPicture: '',
      ClientappSignedInEmail: '',
      ClientFireStore: {},
      QuickstartStep: 1,
      QuickStartDialog: false,
      ClientSystemAdminDialog: false,
      ClientDBBusinessUnits: [],
      ClientDBAdministrators: [],
      ClientAppUsers: [],
      clientApp: {},
      clientDB: '',
      //systemconfigbuilderprops

      // modular tabs props
      //singlerecordnav
      RecordData: '',
      SingleRecordNavMenu: false,
      SingleTabsLayout: [],

      ParentNavItem: {
        Children: []
      },
      SingleRecordNavMenuItem: '',
      PrimaryFieldName: '',
      ChangesMade: [],
      ViewAuditHistory: false,
      PageBGGradient: 'rgba(255,255,255,0.01)',
      SinglePageBGGradient: '',
      SingleFormEditing: false,
      EntityBuilderForm: false,
      OldStyle: false,
      CurrentEntity: '',
      // OperationalDB: '',
      EntityReady: false,
      SingleBuildTabs: [],
      SubCollections: [],
      // modular tabs props

      // RouteID: 0,
      ExporttoPDFFunctions: [],
      ExportingtoPDFDialog: false,
      ChangeStatusDialog: false,
  
      EditingPermissionsGeneral: false,
      
      BannerTabs: [
        ],
      TicketLog: false,
      step: 1,
      rules: {
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      Name: '',
      NewRoadmapItem: {Name: ''},
      NewForum: {Title: ''},
      RoadmapItemDialog: false,
      STDActionTypes: [
            {
                Name: 'Bug Fixes',
                Icon: 'mdi-bug',
                Color: 'red'
            },
            {
                Name: 'Additional Features',
                Icon: 'mdi-plus',
                Color: 'green'
            },
            {
                Name: 'Field Management',
                Icon: 'mdi-clipboard-list',
                Color: 'warning'
            },
            {
                Name: 'Style and Layout',
                Icon: 'mdi-palette-advanced',
                Color: 'purple'
            },
            {
                Name: 'Dashboards and Reporting',
                Icon: 'mdi-desktop-mac-dashboard',
                Color: 'indigo'
            },   
            {
                Name: 'Content Management',
                Icon: 'mdi-content-copy',
                Color: 'red'
            },   
            {
                Name: 'Data Management',
                Icon: 'mdi-database',
                Color: 'blue'
            }, 
            {
                Name: 'System Setup',
                Icon: 'mdi-view-dashboard',
                Color: 'red'
            },       
            {
                Name: 'Navigation',
                Icon: 'mdi-sign-direction',
                Color: 'pink'
            },
            {
                Name: 'Security',
                Icon: 'mdi-security-network',
                Color: 'cyan'
            },
        ],
      ForumDiscussionDialog: false,
      TableStateDialog: false,
      EditedStatusField: '',
      StatusField: '',
      Status: {ID: 1000001,Name: 'Active' },
      Status_Reason: {
  "Level": {
    "id": 2,
    "FieldName": "Status_Reason",
    "children": [],
    "name": "Status Reason"
  },
  "Name": "Open",
  "ID": 1000003,
  "LevelFieldName": "Status_Reason"
},
      Created_On: '',
      Created_Onmenu : false,
      // Created_By:  {id: '', Full_Name: ''},
      // UnpermittedCreated_By:  {id: '', Full_Name: ''},
      // HasCreated_By: false,
      // Created_ByRelatedParentObj: {},
      // Created_ByFull_Name: null,
      
      // Created_Onmenu: false,
      
      StatusOptions: [{
  "HasDeepHierarchy": true,
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Level": {
        "FieldName": "Status_Reason",
        "id": 2,
        "name": "Status Reason",
        "children": []
      },
      "ID": 1000003,
      "Name": "Open"
    }
  ],
  "Name": "Active",
  "Level": {
    "id": 1,
    "FieldName": "Status",
    "name": "Status",
    "children": [
      {
        "id": 2,
        "name": "Status Reason",
        "children": [],
        "FieldName": "Status_Reason"
      }
    ]
  },
  "LevelFieldName": "Status",
  "ID": 1000001
},{
  "Level": {
    "FieldName": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "id": 2,
        "name": "Status Reason",
        "children": []
      }
    ],
    "id": 1,
    "name": "Status"
  },
  "ID": 1000002,
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Level": {
        "name": "Status Reason",
        "id": 2,
        "children": [],
        "FieldName": "Status_Reason"
      },
      "Name": "Closed",
      "ID": 1000004
    }
  ],
  "LevelFieldName": "Status",
  "Name": "InActive",
  "HasDeepHierarchy": true
}],
      Statusfilter: '',
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      EditingSimpleEntity: false,
      dialog: false,
      initialize: '',
      search: '',
      SimpleEntityData: {},
      RecordObj: '',
      headers: [
      
        
        { text: 'Name', value: 'Name'},
        
        
        { text: 'Status', value: 'Status.Name'},
        
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedSimpleEntitysnackbar: false,
      SimpleEntityEditdialog: false,
      newSimpleEntitydialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      // UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: [],
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      TasksQuery: '',
      EmailsQuery: '',
      NotesQuery: '',
      PhoneCallsQuery: '',
      AppointmentsQuery: '',
      //maybe for BU struct?
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      IsAdmin: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      
      AllgetRoles: [],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      FeatureNavList: '',
      FeatureNavListLookupEntry: '',
      //maybe for BU struct?
    }
    
    
  },

  created(){
    //alert('this.PushedID d '+this.PushedID)
    if(this.$route.name === 'SystemConfig'){
      //console.log(this.BuilderView)
         
      if(this.BuilderView && this.BuilderView.Name === 'Website Builder'){
        this.$store.commit('setBuilderView',{Name: 'Social Network Builder'})
      } 
      else {
       this.$store.commit('setBuilderView',{Name: 'Database Builder'}) 
      }  
      //console.log(this.BuilderView)
      this.ToggleSiteDialog()
    }
    else{
      this.PrepareSuiteApp()
    }
    //this.$store.commit('SetCurrentItemModerator',this.CanEdit)
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.$route.name === 'SystemConfig' && !this.userIsAdmin){
      this.$router.push('/MyProfile')
    }
    else{
      this.$emit('ActivateRecordLoadingDialog',true,25) 
    if(this.PushedID){
      //this.RouteID = this.PushedID
      if(this.FunctionEntityid){
        this.CurrentEntity = this.SystemEntities.find(obj => obj.id === this.FunctionEntityid)
        this.ConfigSystemEntities = this.SystemEntities
      }
    }
    else {
      //this.RouteID = this.RouteID
    }
    //modular tabs create calls
    //this.CheckRouting()    
    //modular tabs create calls

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
window.addEventListener('scroll', this.handleScroll) 
    }
   

  },
  
  computed: {
    FunctionsRef(){
      return this.$store.state.ActiveSuiteid !== process.env.VUE_APP_RA_SYSTEM_ID ? 
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions') : 
      db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('pdfexportfunctions')
    },
     DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
    ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
    BypassInboundWIP(){
        let appplugins = []
        if(this.CurrentEntityApp){
          appplugins = this.CurrentEntityApp.Plugins.map(plug => {return plug.id})
        }
        return this.CurrentEntityApp && !appplugins.includes('Warehouse_Inbound')
      },
    StoreProductEntity(){
            return this.CurrentEntity.Entity_Type.Name === 'Store Product'
        },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    BuilderView(){
      return this.$store.state.BuilderView
    },
    InvoicePaidinFull(){
      return this.EntityisPurchaseInvoice && this.RecordData && typeof this.RecordData.Amount_Paid === 'number' && this.RecordData.Invoice_Total <= this.RecordData.Amount_Paid
    },
    CanRecordPayment(){
      return this.userisCreditorsClerk && this.EntityisPurchaseInvoice && !this.InvoicePaidinFull
    },
    userisCreditorsClerk(){
        return this.RecordData && this.RecordData.id ? this.IsAccountingClerk('Creditor',this.RecordData.Business_Unitid) :''
      },
    EntityisPurchaseInvoice(){
      return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Purchase Invoice'
    },
    StoreItemPublisher(){
      return this.EntityStoreActive && this.UserWarehouseObj && this.UserWarehouseObj.Publish
    },
    WarehousesArray(){
      return this.$store.state.WarehousesArray
    },
    ActiveWarehouse(){
      return this.RecordData && this.RecordData.Warehouseid ? this.WarehousesArray.find(obj => obj.id === this.RecordData.Warehouseid) : ''
    },
    UserWarehouseObj(){
        return this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses && this.ActiveWarehouse && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] ? this.userLoggedIn.Warehouses[this.ActiveWarehouse.id] : ''
    },
    EntityisAppStore(){
      return this.CurrentEntityApp && this.CurrentEntityApp.Warehouse_Product_Master === this.CurrentEntity.id && this.CurrentEntityApp.Plugins.find(obj => obj.id === 'Online_Store')
    },
    AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
    CurrentEntityApp(){
            return this.CurrentEntity ? this.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid) : ''
    },
    EntityStoreActive(){
        return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product' && this.EntityisAppStore
      },
    StoreHeaders(){
      return [
    { text: 'Image', recordvalue: 'Image',value: 'IMG',Type: 'Single File Upload',FeaturedPhoto: true, sortable: false,class: "overline" },
    { text: 'DescriptiveText', recordvalue: 'Descriptive_Text',value : 'DescriptiveText', sortable: false,Type: 'HTML',class: "overline" },        
    // { text: 'Discounted Price', recordvalue: 'Discounted_Price',value: 'Discounted_Price',Type: 'Number', sortable: false,class: "overline" },
    // { text: 'Store Price', recordvalue: 'Store_Price',value: 'Price',Type: 'Number', sortable: false,class: "overline" },
    { text: 'Description', recordvalue: 'Product_Code',value: 'Description',Type: 'Multiple Lines Text', sortable: false,class: "overline" },
    // { text: 'Brands', recordvalue: 'Brands',value: 'Brand',Type: 'Option Set', sortable: false,class: "overline" },
  ]
    },
    UpdatedSearchQuery(){
      let SearchQuery = []
      if(this.CurrentEntity && this.CurrentEntity.AllFields){
        this.CurrentEntity.AllFields.map(fieldobj => {
          let value = this.RecordData[fieldobj.id]
          if(value){
            if(fieldobj.IsSearch || fieldobj.Primary){
              let PrimaryString = ''
                if(fieldobj.Type === 'Single Line Text'){
                  PrimaryString = value.trim()
                }
                else if(fieldobj.Type === 'Option Set'){
                  PrimaryString = value.Name.trim()
                }
              var lowertext = "";       
              var p;
              let buildup = ''
              //console.log(PrimaryString.length,PrimaryString)
              for (p = 0; p < PrimaryString.length; p++) {
                  buildup = buildup+ PrimaryString[p]
                  if(p === PrimaryString.length-1){
                    lowertext += buildup.toLowerCase();
                  }
                  else{
                    lowertext += buildup.toLowerCase() + ",";
                  }
              } 
              SearchQuery = SearchQuery.concat(value.trim().split(' '))         
              SearchQuery = SearchQuery.concat(lowertext.split(','))  
              SearchQuery = SearchQuery.map(entry => {
                return entry.toLowerCase()
              }) 
            }
          }          
        })
      }
      return SearchQuery      
    },
    FabContainerStyle(){
      if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
        return `position: fixed;
  top: 160px;
  right: 7px;
  z-index: 100;`
      }
      else{
        return `position: fixed;
  bottom: 112px;
  right: 7px;
  z-index: 100;`
      }
    },
    ActiveAppMode(){
        return this.$store.state.AppMode.Name
        },
    SelectedTabs(){
      return this.SelectedElement ? [this.SelectedElement.ParentTab] : []
    },
    SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
    MyActivitiesArray(){
      return []
    },
    ComputedActivityType(){
        if(this.ActivityType){
          return this.ActivityType
        }
        else{
          return {
            countname: 'None',
            tasks: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id}),
            apointments: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id}),
          }
        }
      },
      SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    NeedsRASync(){
      return this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.DataSingleBuilder || 
      this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.SubColEntryViewer     
    },
    HasLiaison(){
      return this.RecordData && this.RecordData.id && this.CurrentEntity && this.CurrentEntity.id
    },
    HasProcess(){
      return this.ProcessStages && this.ProcessStages.length > 0
    },
    HasProcedure(){
      return this.ActiveProcess && this.StageID && this.ProcessStages && this.ProcessStages[this.StageID-1].Steps && this.ProcessStages[this.StageID-1].Steps.length > 0 && !this.UpdatingProcess
    },
    RouteID(){
      if(this.PushedID){
        return this.PushedID
      }
      else{
        return this.$route.params.id
      }
    },
    ComputedStageID(){
      return this.ProcessStages[this.StageID-1].Number
    },
    EntityOwnerField(){
      if(this.CurrentEntity && this.CurrentEntity.AllFields){
        return this.CurrentEntity.AllFields.find(obj => obj.id === 'Owner')
      }
    },
    CanEditOwnership(){
      if(this.CurrentEntity && this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
        return this.CanEdit
      }
      else{
        return this.CanEdit
      }
    },
    RAUser(){
            return this.$store.state.RAUserObj
        },
    ForumDescriptionField(){
      let field = {
        Name: 'post',
        DisplayName: 'post',
        Type: 'Multiple Lines Text',
        IsContentEditable: true
      }
      return field
    },
    ForumCategoriesField(){
      let field = Object.assign({},this.System.Forum_Categories)
      field.Name = 'Class'
      field.DisplayName = 'Class'
      field.IsDynamic = false
      return field
    },
    NavMenuSingleBuildTabs(){
      let tabs = this.SingleBuildTabs
      tabs.map(tab => {
        if(tab.SystemGuides){
          tab.SystemGuides = tab.SystemGuides.map(guide => {
            if(guide.ConditionalDisabled && guide.ConditionalBooleans){
              
              guide.ConditionalBooleans.map(boolean => {
                if(boolean.Value){
                  if(this.DynamicWikiData[boolean.Field]){
                    guide.Disabled = true
                  }
                }
              })
            }
            return guide
          })
        }
        return tab
      })
      let systemtabs = [
        {
            DisplayName: 'Dashboard',
            Icon: 'mdi-view-dashboard',
            SystemDashboard: true,
            Elements: [
             
          {
            Icon: 'mdi-alert',
            DisplayName: 'Alerts',
            Name: 'Alerts',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Alerts',
            Elements: [],
            Children: [
              {
            Icon: 'mdi-database',
            DisplayName: 'Database Module',
            Name: 'Database Module',
            SystemDashboard: true,
            DashboardType: 'Database Module'
          },
          {
            Icon: 'mdi-share-variant',
            DisplayName: 'Social Module',
            Name: 'Social Module',
            SystemDashboard: true,
            DashboardType: 'Social Module'
          },
          {
            Icon: 'mdi-search-web',
            DisplayName: 'Website Module',
            Name: 'Website Module',
            SystemDashboard: true,
            DashboardType: 'Website Module'
          },
           
            ]
          },
          {
            Icon: 'mdi-chart-box',
            DisplayName: 'Statistics',
            Name: 'Statistics',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Statistics',
             Elements: []
          },
            ]
        }
        
      ]
      let finaltabs = systemtabs.concat(tabs)
      //console.log('finaltabs',finaltabs)
      return finaltabs
    },
    
    CanModerateWiki(){
      return this.CurrentEntity && this.CurrentEntity.Has_Wiki && this.UserisWikiModerator || this.CurrentEntity && this.CurrentEntity.Has_Wiki && this.userIsAdmin
    },
    CanModerateClassified(){
      return this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.UserisClassifiedModerator
    },
    UserisClassifiedModerator(){
      //for now admin only
      return this.userIsAdmin || this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === this.CurrentEntity.id.split('_').join(' ')+' Classified Listings')
    },
    UserisWikiModerator(){
      return this.userIsAdmin || this.CurrentEntity && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Wiki')
    },
    CanEditLayout(){
      if(!this.SystemisRA){
        return this.userIsAdmin && this.$route.query.TableEntity !== 'Entities' && this.$route.name !== 'SystemConfig'
      }
      else{
        return this.userIsAdmin && this.$route.query.TableEntity !== 'Entities'
      }
    },
    RAOperationalDB(){
      if(this.$route.meta && this.$route.meta.RecordisSystem && !this.SystemisRA){
        if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
          return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection(this.$route.query.RecordCollection).doc(this.$route.query.Record)
        }
        else{
          return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID)
        }
      }
    },
    ClassifiedListingDB(){
      if(this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.RecordData.Combined_Title){
        return db.collection(this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings').doc(this.RouteID)
      }      
    },
    ActiveSuiteid(){
        return this.$store.state.ActiveSuiteid
      },
    roottableentities(){
        return this.$store.state.roottableentities
        },
    OperationalDB(){
      if(this.$route.meta && this.$route.meta.DataSingleBuilder && !this.$route.meta.RecordisSystem){
        if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
          return db.collection(this.$route.meta.Collection).doc(this.RouteID).collection(this.$route.query.RecordCollection).doc(this.$route.query.Record)
        }
        else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && !this.$route.query.TableEntity){
          return db.collection(this.$route.meta.Collection).doc(this.RouteID).collection(this.$route.query.RecordCollection).doc(this.$route.query.Record)
        }
        else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && this.$route.query.TableEntity){
          return db.collection(this.$route.meta.Collection).doc(this.RouteID).collection('entities').doc(this.$route.query.TableEntity).collection(this.$route.query.RecordCollection).doc(this.$route.query.Record)
        }
        
        //operational Entity for Sample Records to "entities"
        //Entity SubCollection for products to SO
        //then what returns for sample reocrds on "users" for system
        else if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
          return db.collection(this.$route.meta.Collection).doc(this.RouteID).collection(this.$route.query.RecordCollection).doc(this.$route.query.Record)
        }
        else{
          return this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && 
          !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.RouteID) : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.RouteID) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.RouteID)
        }
      }
      else if(this.$route.name === 'PDFExportFunctionBuilder' || this.FunctionEntityid){
        return db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('entities').doc(this.FunctionEntityid).collection('Records').doc(this.RouteID)
      } 
      else if(this.$route.meta && this.$route.meta.RecordisSystem){
        if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
          return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection(this.$route.query.RecordCollection).doc(this.$route.query.Record)
        }
        else{
          return db.collection('SystemConfig').doc(this.RouteID)
        }       
      }
      else if(this.$route.meta && this.$route.meta.SubColEntryViewer){
        return db.collection(this.$route.params.slug.split('_').join('').toLowerCase()).doc(this.$route.query.Parent).collection(this.RouteID).doc(this.$route.query.Record)
      }           
    },
    ConfigDB(){
      // if(this.$route.query && this.$route.query.TableEntity){
      if(this.$route.meta && this.$route.meta.DataSingleBuilder && !this.$route.meta.RecordisSystem){
        if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && !this.$route.query.TableEntity){
          return db.collection(this.$route.meta.Collection).doc(this.ConfigEntityID)
        }
        else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && this.$route.query.TableEntity){
          return db.collection(this.$route.meta.Collection).doc(this.RouteID).collection('entities').doc(this.$route.query.TableEntity)
        }
        else{
          //tabletype system entity is same
          return db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.ConfigEntityID)
        }        
      }
      else if(this.$route.meta && this.$route.meta.RecordisSystem){
        //this is the old SystemConfig view never gonna use again but leaving for reference until we kick it
        if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
          return db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Entities')
        }
        else{
          return db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems')
        }
      
      }
      //it will never hit systemconfigbuilder, no single route is configured for it, not system single, nor systemconfig
      //but what was intended with this then?
      else if(this.$route.meta && this.$route.meta.SystemConfigBuilder){
        return db.collection('SystemConfig').doc(this.ConfigEntityID)
      }
      else if(this.$route.meta && this.$route.meta.SubColEntryViewer){
        let parententity = this.SystemEntities.find(obj => obj.id === this.$route.params.slug.split(' ').join('_'))
        let subcol = parententity.SubCollections.find(obj => obj.id === this.RouteID)
        //console.log(parententity,subcol)
        if(subcol.EntityBuilder){
          return db.collection(this.$route.params.slug.split('_').join('').toLowerCase()).doc(this.$route.query.Parent).collection(this.RouteID).doc(this.$route.query.Record)
        }
        else{
          return db.collection(this.$route.params.slug.split('_').join('').toLowerCase()).doc(this.$route.query.Parent)
        }
      }
    },
    TabsRenderMode(){
      if(!this.SingleFormEditing && this.EntityReady && this.SingleRecordNavMenuItem && !this.SingleRecordNavMenuItem.SingleBuildTabs && !this.SelectedElement && !this.RelatedTabsRefresh){
        return 'Standard Tabs'
      }
      else if(!this.SingleFormEditing && this.EntityReady && !this.SingleRecordNavMenuItem && !this.SelectedElement && !this.RelatedTabsRefresh){
        return this.HasProcedure && !this.FullRecordView ? '' : 'Standard Tabs'
      }
      else if(!this.SingleFormEditing && this.EntityReady && this.SelectedElement && !this.RefreshingSingleElement){
        return 'Selected Element'
      }
      else if(!this.SingleFormEditing && this.EntityReady && this.SingleRecordNavMenuItem && this.SingleRecordNavMenuItem.SingleBuildTabs && !this.SelectedElement && !this.RelatedTabsRefresh){
        return 'Standard Tabs'
      }
      else if(this.SingleFormEditing){
        return 'Editing Tabs'
      }
    },
    //modular tabs computed start
    ComputedSingleBuildTabs(){
      //if(!this.SingleRecordNavMenuItem || !this.SingleFormEditing && this.EntityReady && this.SingleRecordNavMenuItem && !this.SingleRecordNavMenuItem.SingleBuildTabs){
        //if(!this.SingleRecordNavMenuItem || !this.SingleFormEditing && this.EntityReady && this.SingleRecordNavMenuItem){
      if(!this.SingleRecordNavMenuItem && this.EntityReady){
        //console.log('!this.SingleRecordNavMenuItem || !this.SingleFormEditing && this.EntityReady && this.SingleRecordNavMenuItem')
       let AllFields = this.CurrentEntity.AllFields
      return this.SingleBuildTabs.map(tab => {
        let elementsprop = ''
        if(tab.Elements){
          elementsprop = 'Elements'
        }
        else{
          elementsprop = 'Sections'
        }
        //alert(elementsprop)
        if(tab[elementsprop]){
          tab[elementsprop] = tab[elementsprop].map(section => {
            //console.log(section)
          if(section.Fields){
            section.Fields = section.Fields.map(field => {
             
              let fieldobj = AllFields.find(obj => obj.id === field.Name)
              //console.log('AllFields,field.Name',AllFields,field.Name,fieldobj)
              if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID !== 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup',fieldobj.RelatedRecord.split(' ').join('')+'Array',this.$store.state[fieldobj.RelatedRecord+'Array'],this.$store.state)
                  fieldobj.Array = this.$store.state[fieldobj.RelatedRecord.split(' ').join('')+'Array']
                  if(fieldobj.IsMultiple){
                    fieldobj.Array = fieldobj.Array.map(item => {                      
                            let itemobj = {
                            [fieldobj.LookupFieldName]: item[fieldobj.LookupFieldName],
                            id: item.id
                            }
                            return itemobj
                        })
                    }
                }
                else if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID === 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup','Users',this.$store.state['Users'],this.$store.state)
                  fieldobj.Array = this.$store.state['Users']
                }
                if(!fieldobj){
                  console.log('missing field ',field)
                  fieldobj = field
                }
              return fieldobj
            })
          }
          //prepare updated permissionname Props just prior to GetCabinetDocuments
          //CustomRolesDefault
          //console.log(section.Name,section.DataViewName,section,this.$store.state)
          if(section.DataViewName === 'Data Single Document Register' || section.DataViewName === 'Data Single Gallery'){
          //  this.CustomRolesDefault.map(defrole => {
          //    console.log(defrole.Prop,section.Name,section[defrole.Prop])
          //  })
            if(section.CreatePermission === 'Inherited'){
              section.CanCreate = this.CanEdit
            }
            else if(section.CreatePermission === 'Custom Role'){
              section.CustomCreateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanCreate = true
                }
              })
            }
            else if(section.CreatePermission === 'Admin'){
              section.CanCreate = this.userIsAdmin
            }
            if(section.GetPermission === 'Inherited'){
              section.CanGet = true
            }
            else if(section.GetPermission === 'Custom Role'){
              section.CustomGetRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanGet = true
                }
              })
            }
            else if(section.GetPermission === 'Admin'){
              section.CanGet = this.userIsAdmin
            }
            if(section.ListPermission === 'Inherited'){
              section.CanList = true
            }
            else if(section.ListPermission === 'Custom Role'){
              section.CustomListRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanList = true
                }
              })
            }
            else if(section.ListPermission === 'Admin'){
              section.CanList = this.userIsAdmin
            }
            if(section.UpdatePermission === 'Inherited'){
              section.CanEdit = this.CanEdit
            }
            else if(section.UpdatePermission === 'Custom Role'){
              section.CustomUpdateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanEdit = true
                }
              })
            }
            else if(section.UpdatePermission === 'Admin'){
              section.CanEdit = this.userIsAdmin
            }
            if(section.DeletePermission === 'Inherited'){
              if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Custom Roles'){
                section.CanDelete = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'CanDelete']
              }
              else if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
               if(this.deleteAll){
                  section.CanCreate =  true
                }
                else if(this.deleteUnitdown){
                   if(this.userBUChildren.includes(this.DynamicRecordData.Business_Unitid)){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteUserUnit){
                  if(this.DynamicRecordData.Business_Unitid === this.userBUID){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteOwner){
                  if(this.DynamicRecordData.Ownerid === this.UserRecord.id){
                    section.CanCreate = true  
                    }
                }
              }
            }
            else if(section.DeletePermission === 'Custom Role'){
              section.CustomDeleteRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanDelete = true
                }
              })
            }
            else if(section.DeletePermission === 'Admin'){
              section.CanDelete = this.userIsAdmin
            }
          }
          else if(section.DataViewName === 'Data Single SubCollection'){
            //console.log(section)
            let subcol = this.SubCollections.find(obj => obj.id === section.Entityid)
            section.CanCreate = this.CanEdit
            section.CanDelete = this.CanDelete
            section.CanEdit = this.CanEdit

          }
          // if(this.SelectedElement && this.SelectedElement.DisplayName !== section.DisplayName){
          //   section.ShowComponents = false
          // }
          return section
        })
        // .filter(elmnt => {
        //   return elmnt.ShowComponents
        // })
        }
         //tab.ShowComponents = false
        return tab
      }).filter(tab => {
        return tab.Elements.length > 0
      })
      }
      else{
        //return this.SingleRecordNavMenuItem.SingleBuildTabs
        return []
      }
      
    },
    RelatedDataTabs(){
      if(this.ComputedSingleBuildTabs){
       return this.ComputedSingleBuildTabs.map(tab => {
        return tab.Elements
      }).flat()
      .filter(elmnt => {
        return elmnt.DataViewName === 'Data Single Related Data'
      }) 
      }
      else {
        return []
      }
      
    },
    DynamicRecordData(){
      let vm = this
      let dataobj = {}
      //this should become OpsRecordID not routeparams, not work along with subcol entry view
      dataobj.id = this.RouteID
      console.log('this.ConfigSystemEntities',this.ConfigSystemEntities)
      let entity = this.ConfigSystemEntities.find(obj => obj.id === this.ConfigEntityID)
      console.log('entity',entity)
      
      if(entity){
      if(entity.SubCollections){
        dataobj.SubCollections = this.SubCollections
        this.SubCollections.map(subcol => {
          if(subcol.id === 'BookedUsers'){
            ///console.log('subcol',subcol)
            dataobj.BookedUsers = subcol.Data.map(bookeduser => {
              let usermatch = this.UsersArray.find(obj => obj.id === bookeduser.id)              
              //console.log('usermatch',usermatch)
              let userobj = Object.assign({},usermatch)
              userobj.BookingObj = Object.assign({},bookeduser)
              if(bookeduser.BookingDate){
               userobj.BookingDate = bookeduser.BookingDate 
              }
              else if(bookeduser.Created_On){
                userobj.BookingDate = bookeduser.Created_On 
              }              
              return userobj
            })
          }
        })
      }
      if(this.RelatedDataTabs){
        //this.EntityReady = false
        dataobj.RelatedTables = this.RecordObj.RelatedTables
        //this.EntityReady = true
        //console.log('dataobj.RelatedTables',dataobj.RelatedTables)
      }
      //console.log('dataobj.RelatedTables',dataobj.RelatedTables,this.RelatedDataTabs,this.RecordObj.RelatedTables)
      entity.AllFields.map(field => {
        //console.log('field.Name ',field.Name,this[field.Name])
        if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
          field.FieldBreakdown.map(breakdown => {
            dataobj[breakdown.Name] = this.RecordData[breakdown.Name]
          })
        }
        else if(field.Type === 'Date' && this.RecordData[field.Name] && this.RecordData[field.Name].toDate){
          let dateobject = this.RecordData[field.Name].toDate()
          dataobj[field.Name] = format(dateobject,'yyyy-MM-dd')
          if(field.HasTimeInput){
            let hours = dateobject.getHours()
            let mins = dateobject.getMinutes()
            if(hours === 0){
              hours = '00'
            }
            if(mins === 0){
              mins = '00'
            }
            field.TimeResponse = hours+':'+mins
            dataobj[field.Name+'TimeResponse'] = field.TimeResponse
          }
        }
        else{
          if(this[field.Name]){
            dataobj[field.Name] = this[field.Name]
          }
          else if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]           
            if(field.Type === 'Option Set' && field.HasDeepHierarchy){
               let primopt = field.Options.find(obj => obj.ID === this.RecordData[field.Name].ID)
              if(primopt && field.Levels && field.Levels[1] && this.RecordData[field.Levels[1].FieldName]){   
                dataobj[field.Levels[1].FieldName] = this.RecordData[field.Levels[1].FieldName] 
                let secopt = primopt.Options.find(obj => obj.ID === this.RecordData[field.Levels[1].FieldName].ID)          
                if(secopt && field.Levels && field.Levels[2] && this.RecordData[field.Levels[2].FieldName]){
                  dataobj[field.Levels[2].FieldName] = this.RecordData[field.Levels[2].FieldName]
                  dataobj[field.Levels[2].FieldName].Options = secopt.Options
                  //console.log(field)
                }
              }
            }
          }
          
        }
        // if(this[field.Name]){
        //  dataobj[field.Name] = this[field.Name] 
        // }        
      })
      if(this.FeatureNavList){
        this.FeatureNavList.FeatureList.map(field => {
          if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]
          }
        })
      }
      
      //console.log('dataobj',entity,this.SubCollections,dataobj)
      if(this.RecordObj.Order_Progress){
        dataobj.Order_Progress = this.RecordObj.Order_Progress
      }
      return dataobj    
      }
      
    },
    ConfigEntityID(){
      if(this.$route.meta && this.$route.meta.SubColEntryViewer){
        let parententity = this.SystemEntities.find(obj => obj.id === this.$route.params.slug.split(' ').join('_'))
        let subcol = parententity.SubCollections.find(obj => obj.id === this.RouteID)
        //console.log(parententity,subcol)
        if(subcol.EntityBuilder){
          return this.$route.query.Record
        }
        else{
          return this.$route.query.Parent
        }
      }
      else if(this.$route.name === 'PDFExportFunctionBuilder' || this.FunctionEntityid){
        return this.FunctionEntityid
      }
      // else{
      //   return this.$route.meta && this.$route.meta.SubColEntryViewer ? this.$route.query.Parent : this.$route.meta.EntityID
      // } 
      else if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
        return this.$route.query.TableEntity
      }
      else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && !this.$route.query.TableEntity){
        return this.RouteID
      }
      else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && this.$route.query.TableEntity){
        return this.$route.query.TableEntity
      }
      else{
        return this.$route.meta.EntityID
      }
    },
    OpsRecordID(){
      
    },
    //modular tabs computed end

//singlerecordnav
        
    DynamicWikiData(){
      let fullobject = Object.assign({},this.RecordData)
      delete fullobject.Entities
      
      fullobject.Collection = 'systemswiki'
      fullobject.RecordPrimaryFieldName = 'Name'
      if(this.$route.meta.subcat){
        fullobject.Path = this.$route.meta.subcat.split(' ').join('')
      }
      fullobject.Path = this.$route.meta.subcat.split(' ').join('')
      fullobject.SubCollections = [
        {
          id: 'Entities',
          Name: 'Entities',
          Data: this.Entities,
          Headers: this.Entitiesheaders
        },
      ]
      fullobject.Fields = [
        {
          Name: 'Name',
          Type: 'Name'
        },
        {
          Name: 'SystemFromEmailHost',
          Type: 'SystemFromEmailHost'
        },
        {
          Name: 'SystemFromEmailPassword',
          Type: 'SystemFromEmailPassword'
        },
        {
          Name: 'SystemFromEmailSecure',
          Type: 'SystemFromEmailSecure'
        },
        {
          Name: 'StatementofIntent',
          Type: 'StatementofIntent'
        },
        {
          Name: 'Status',
          Type: 'Status'
        },
        {
          Name: 'Created_On',
          Type: 'Created_On'
        },
        {
          Name: 'Business_Unit',
          Type: 'Business_Unit'
        },
        {
          Name: 'Company',
          Type: 'Company'
        },
        {
          Name: 'Created_By',
          Type: 'Created_By'
        },
        {
          Name: 'Owner',
          Type: 'Owner'
        },
        {
          Name: 'ClientAppURL',
          Type: 'ClientAppURL'
        },
        {
          Name: 'SystemFromEmailAddress',
          Type: 'SystemFromEmailAddress'
        },
        {
          Name: 'SystemFromEmailPort',
          Type: 'SystemFromEmailPort'
        },
        {
          Name: 'CustomDashboardsDisabled',
          Type: 'CustomDashboardsDisabled'
        },
        {
          Name: 'GuestsareRegional',
          Type: 'GuestsareRegional'
        },
        {
          Name: 'HasPublicSignup',
          Type: 'HasPublicSignup'
        },
        {
          Name: 'SiteArticlesPublic',
          Type: 'SiteArticlesPublic'
        },
        {
          Name: 'SiteBlogsPublic',
          Type: 'SiteBlogsPublic'
        },
        {
          Name: 'SiteEventsPublic',
          Type: 'SiteEventsPublic'
        },
        {
          Name: 'SiteEventshaveBackend',
          Type: 'SiteEventshaveBackend'
        },
        {
          Name: 'SiteHasChatbot',
          Type: 'SiteHasChatbot'
        },
        {
          Name: 'SiteHasGoals',
          Type: 'SiteHasGoals'
        },
        {
          Name: 'SiteHasSocalPlatforms',
          Type: 'SiteHasSocalPlatforms'
        },
        {
          Name: 'SiteHasStore',
          Type: 'SiteHasStore'
        },
        {
          Name: 'SiteMeetupsPublic',
          Type: 'SiteMeetupsPublic'
        },
        {
          Name: 'SiteSendsEmails',
          Type: 'SiteSendsEmails'
        },
        {
          Name: 'SiteStockQtyHidden',
          Type: 'SiteStockQtyHidden'
        },
        {
          Name: 'SiteStorePricesHidden',
          Type: 'SiteStorePricesHidden'
        },
        {
          Name: 'SiteStoreisGuestsOnly',
          Type: 'SiteStoreisGuestsOnly'
        },
        {
          Name: 'SitehasArticles',
          Type: 'SitehasArticles'
        },
        {
          Name: 'SitehasBlogs',
          Type: 'SitehasBlogs'
        },
        {
          Name: 'SitehasClassifieds',
          Type: 'SitehasClassifieds'
        },
        {
          Name: 'SitehasEvents',
          Type: 'SitehasEvents'
        },
        {
          Name: 'SitehasForums',
          Type: 'SitehasForums'
        },
        {
          Name: 'SitehasGroups',
          Type: 'SitehasGroups'
        },
        {
          Name: 'SitehasMeetups',
          Type: 'SitehasMeetups'
        },
        {
          Name: 'SitehasPolls',
          Type: 'SitehasPolls'
        },
        {
          Name: 'PurposedApplications',
          Type: 'PurposedApplications'
        },
      ]
      fullobject.Business_Unit = this.Business_Unit
      fullobject.Company = this.Company
      fullobject.Created_By = this.Created_By
      fullobject.Owner = this.Owner
      return fullobject
    },
    PDFSystemData(){
      let fullobject = this.DynamicRecordData
    //   fullobject.RecordPrimaryFieldName = 'Name'
    //   fullobject.Entities = this.Entities
    //   fullobject.Business_Unit = this.Business_Unit
    //   fullobject.Company = this.Company
    //   fullobject.Created_By = this.Created_By
    //   fullobject.Owner = this.Owner
      return fullobject
    },
    createAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createAll']
    },
    createUnitdown(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createUnitdown']
    },
    createUserUnit(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createUserUnit']
    },
    createOwner(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createOwner']
    },
    deleteAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
    },
    deleteAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
    },
    deleteUnitdown(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteUnitdown']
    },
    deleteUserUnit(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteUserUnit']
    },
    deleteOwner(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteOwner']
    },
    deleteAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
    },
    editAll(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editAll']
    },
    editUnitdown(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editUnitdown']
    },
    editUserUnit(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editUserUnit']
    },
    editOwner(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editOwner']
    },
    userBUID () {
    return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
    WikiClassifiedDescription(){
      let postelmnt = document.getElementById('DescriptionValue')
          if(postelmnt){
              return postelmnt.innerHTML
          }
      },
  userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
          return true
        }
      else{
      return this.userIsAdmin
      }
    }
    else{
      return this.userIsAdmin
    }

  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
    AccountsStore(){
      return this.$store.state.AccountsArray
    },
    UsersArray(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    
    EntitiesSubColArrayComputed(){
      return this.EntitiesSubColArray
    },
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },  
  RouteObj(){
    return this.$route
  },
  HasEntityandRecord(){
    return this.CurrentEntity && this.DynamicRecordData
  },
  
          
    },
    watch: {
      CurrentEntityApp:{
         handler: function(oldvalue, newvalue) {
          if(oldvalue !== newvalue){
          }
         },deep: true
      },
      BuilderView:{
         handler: function(oldvalue, newvalue) {
          if(oldvalue !== newvalue){
          }
         },deep: true
      },
      RelatedDataTabs:{
         handler: function(oldvalue, newvalue) {
          if(oldvalue !== newvalue){
            this.RecordData.RelatedTables = this.RelatedDataTabs.map(elmnt => {
                
                let entity = this.SystemEntities.find(obj => obj.id === elmnt.Entityid)
                elmnt.RelationshipField = Object.assign({},entity.AllFields.find(obj => obj.id === elmnt.RelationshipField.id))
                if(elmnt.RelationshipField.MappedValues){
                  elmnt.RelationshipField.RecordData = this.RecordData
                }
                let fieldid = elmnt.RelationshipField.id+'id'
                let tablename = elmnt.Entityid.split('_').join('')
                let storequery = tablename+'Query'
                let storearray = tablename+'Array'
                let relatedtable = {
                  id: elmnt.Entityid,
                  TableName: elmnt.Entityid.split('_').join(''),
                  StoreQuery: tablename+'Query',
                  StoreArrayName: tablename+'Array',
                  StoreData: []
                }
                
                
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  //console.log('this.$store.state[storearray]',this.$store.state[storearray],this.RouteID)
                  relatedtable.StoreData = this.$store.state[storearray].filter(record => {
                    return record[elmnt.RelationshipField.id+'id'] === this.RouteID
                  })
                }
                else if(this.$store.state[storequery]){
                  this.GetRelatedData(relatedtable,this.$store.state[storequery],elmnt.RelationshipField.id)
                  // let storepayload = {
                  //         query: this.$store.state[storequery],
                  //         arrayname: storearray
                  //       }
                  //       console.log(storepayload,vm.$store.state,storearray)
                  //       this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                          // relatedtable.StoreData = vm.$store.state[storearray]
                        // })
                }
                //console.log('RelatedTables this.RecordData.RelatedTables relatedtable',relatedtable)
                
                this.RefreshRelatedTabs()
                return relatedtable
              })
             
          }
          },
          deep: true
      },
      
      SelectedTabs:{
         handler: function(oldvalue, newvalue) {
          if(this.SelectedTabs){
            this.$store.commit('SetSelectedDataTabs',this.SelectedTabs)
          }
          },
          deep: true
      },
      ComputedSingleBuildTabs:{
         handler: function(oldvalue, newvalue) {
          if(this.ComputedSingleBuildTabs){
            this.$store.commit('SetCurrentDataTabs',this.ComputedSingleBuildTabs)
          }
          },
          deep: true
      },
      ActiveAppMode(v){
        if(v === 'Super Admin'){
          this.EditSingleForm()
        }
        else if(this.SingleFormEditing){
         this.EditSingleForm()
        }
      },
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateLiaisonDialog','ViewActivities','ToggleTimesheetlistdialog','ToggleExportingtoPDFDialog','ActivateSingleRecordNavMenuItem',
        'EditOwnership','ActivateReceivingDialog','AddtoStore','AddStoreArticle','ActivateWarehouseMovementDialog','AssignProcess','EditOwnership','ToggleChangeStatusDialog','ActivateSelectedElement']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      CurrentEntity(value) {
        if(value && this.$route.meta && this.$route.meta.DataSingleBuilder){
          this.$store.commit('SetCurrentEntity',value) 
        }
      },
      StoreItemPublisher(value) {
        //alert('change canedit '+value)
            if (value) {
              this.$store.commit('SetStoreItemPublisher',value)              
            }
        },
      
      CanEdit(value) {
        //alert('change canedit '+value)
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)              
            }
        },
      HasEntityandRecord (value) {
        if(value){
          //console.log('this.CurrentEntity',this.CurrentEntity)
            this.RelatedObj = {id: this.RouteID, [this.CurrentEntity.Primary_Field_Name]: this.DynamicRecordData[this.CurrentEntity.Primary_Field_Name],identifier: this.CurrentEntity.Primary_Field_Name,collection: this.$route.meta.Collection,Link: this.CurrentEntity.SingleName.split(' ').join('') }
            if(this.CurrentEntity.Table_Icon){
              this.RelatedObj.Icon = this.CurrentEntity.Table_Icon  
            }
            if(this.$route.meta && this.$route.meta.RecordisSystem && !this.SingleRecordNavMenuItem){
              this.FinalizerecordLoadingdialog()
              if(this.SingleRecordNavMenu){
                this.EntityReady = true
              }             
            }
        }
      },
      StageID:{
         handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                  this.UpdatingProcess = true
                  setTimeout(() => {
                    this.UpdatingProcess = false
                  }, 100);
                }
         },
            deep: true
      },
      CurrentEntityApp: {
            handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                  this.PrepareSuiteApp()
                }
            }
      },
      RouteObj: {
            handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                  
                  //console.log(oldvalue, newvalue)
                  this.$emit('ActivateRecordLoadingDialog',true,0)
                  this.SingleRecordNavMenu = false
                  this.EntityReady = false
                  this.ProcessStages = []
                  this.ActiveProcess = ''
                  this.ActiveProcessDocid = ''
                  this.StageID = 1
                  this.StageName = ''
                  this.CurrentEntity = ''
                  this.ConfigSystemEntities = []  
                  this.CanEdit = false  
                  //alert('routechange CanEdit '+this.CanEdit)              
                  this.CheckRouting()
                  this.GetSystemRecord()
                  this.PrepareSuiteApp()
                }
            },
            deep: true
        },
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    },
    DynamicRecordData: {
            handler: function(oldvalue, newvalue) {
             //console.log('something on DynamicRecordData and updateFullItem ',this.PushedID,oldvalue,newvalue,this.DynamicRecordData)
                if(this.PushedID){
                  
                  setTimeout(() => {
                    this.$emit('updateFullItem',this.DynamicRecordData)
                  }, 2000);        
                }
            },
            deep: true
        },
  },
  methods: {
    UpdateOwningUser(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
            this.AssignBU()
        },
      AssignBU(){
      let userid = this.Record.Owner.id
      let ownerobj = {
        id: this.Record.Owner.id,
        Full_Name: this.Record.Owner.Full_Name,
        Name: this.Record.Owner.Name,
        Surname: this.Record.Owner.Surname
      }
      this.CreateLiaisonNotificationtoOwner(this.Record)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userid).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        let BUId = userdata.Business_Unitid
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(BUId).onSnapshot(snapshot => {
        let BUdata = snapshot.data()
        let buobj = {
          id: BUId,
          Name: BUdata.Name
        }
        this.OperationalDB.update({
          Owner: ownerobj,
          Ownerid: ownerobj.id,
          Business_Unit: buobj,
          Business_Unitid: buobj.id
        })        
      })
      })
    },
    
    PrepareSuiteApp(){
        if(!this.ActiveSuiteApp && this.CurrentEntityApp){
          this.$store.commit('setActiveSuiteApp',this.CurrentEntityApp)
        }
        else{
        }
      },
    ToggleSiteDialog(){
            this.$store.commit('setSiteDialog',!this.SiteDialog)
        },
        TurnoffSiteDialog(){
            this.$store.commit('setSiteDialog',false)
        },
        TurnonSiteDialog(){
            this.$store.commit('setSiteDialog',true)
        },
    ConfirmRenameFieldRAOnly(tabindex,elmntindex,oldfieldprop,newfieldprop,fixtemplate,removeoldfield){
      let confirmmsg = 'Proceed with CAUTION!!! This will replace the field '+oldfieldprop+' with '+newfieldprop+'. It will also replace the field in the data Tabs on tab index '+tabindex+' and element index '+elmntindex
      if(fixtemplate){
        confirmmsg = confirmmsg+'. This will also update the RA template.'
      }
      if(removeoldfield){
        confirmmsg = confirmmsg+'. This will also remove the old field from App config db as well as RA db.'
      }
      confirmmsg = confirmmsg+'. Do you wish to proceed?'
      confirm(confirmmsg) && this.RenameFieldRAOnly(tabindex,elmntindex,oldfieldprop,newfieldprop,fixtemplate,removeoldfield)
    },
    RenameFieldRAOnly(tabindex,elmntindex,oldfieldprop,newfieldprop,fixtemplate,removeoldfield){
      let systemdoc = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
      let rasystemdoc = this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
      rasystemdoc.get().then(snapshot => {
        let raentitydata = snapshot.data()
        let ratemplatedoc = this.RADB.collection('entities').doc(raentitydata.LookupObj.id)
        let field = this.CurrentEntity.AllFields.find(obj => obj.id === oldfieldprop)
        let fieldobj = this.SingleBuildTabs[tabindex].Elements[elmntindex].Fields.find(obj => obj.id === oldfieldprop)
        let fieldindex = this.SingleBuildTabs[tabindex].Elements[elmntindex].Fields.indexOf(fieldobj)
        console.log(fieldobj,fieldindex)
        let NewField = Object.assign({},field)
        NewField.id = newfieldprop
        NewField.Name = newfieldprop
        NewField.DisplayName = newfieldprop.split('_').join('')
        NewField.text = newfieldprop.split('_').join('')
        NewField.value = newfieldprop
        this.SingleBuildTabs[tabindex].Elements[elmntindex].Fields.splice(fieldindex,1,NewField)
        let newtabs = JSON.parse(JSON.stringify(this.SingleBuildTabs))
        
        console.log(NewField,newtabs,systemdoc,rasystemdoc,ratemplatedoc)
        systemdoc.collection('AllFields').doc(NewField.id).set(NewField)
        rasystemdoc.collection('AllFields').doc(NewField.id).set(NewField)
        systemdoc.update({
          SingleBuildTabs: newtabs
        })
        rasystemdoc.update({
          SingleBuildTabs: newtabs
        })
        if(fixtemplate){
          ratemplatedoc.collection('AllFields').doc(NewField.id).set(NewField)
          ratemplatedoc.update({
          SingleBuildTabs: newtabs
        })
        }
        if(removeoldfield){
          systemdoc.collection('AllFields').doc(oldfieldprop).delete()
          rasystemdoc.collection('AllFields').doc(oldfieldprop).delete()
          if(fixtemplate){
            ratemplatedoc.collection('AllFields').doc(oldfieldprop).delete()
          }
        }
      })
      db.collection(this.$route.meta.Collection).onSnapshot(res => {
        const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              let dataobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              console.log(dataobj[oldfieldprop])
              db.collection(this.$route.meta.Collection).doc(dataobj.id).update({
                [newfieldprop]: dataobj[oldfieldprop],
                Type_Default: true
              })
            }
          })   
      })

    },
    UploadPOP(){
        let vm = this
        //SelectedPOPFile
        //paymentreceipt.Receiving_Document.UploadFile
        vm.$store.commit('setCustomProcessingDialog',true)
        vm.$store.commit('setCustomProcessingDialogText','Uploading POP')
        let file = vm.paymentreceipt.SelectedPOPFile.UploadFile
        let storagelocation = 'Purchase_Invoices/'+vm.RecordData.id+'/Payments/'+file.name+new Date()
         var storageRef = firebase.storage().ref(storagelocation);
                    var uploadTask = storageRef.put(file);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                          vm.$store.commit('setCustomProcessingDialogText','Capturing Details')
                          let PDF_File = {
                            StorageRef: storagelocation,
                            Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Modified_Byid: vm.userLoggedIn.id,
                            Modified_On: new Date(),
                            Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                            Created_Byid: vm.userLoggedIn.id,
                            Created_On: new Date(),
                            fileurl: url,
                            FileType: 'application/pdf',
                            ModifiedDate: new Date(),
                            Name: file.name,
                            filename: file.name
                          }
                          this.ProcessQuotePayment(PDF_File)
                        })
      },
    ProcessQuotePayment(PDF_File){
        this.$store.commit('setCustomProcessingDialogText','Updating Quote')
        //PaidinFull
        this.OperationalDB.update({
          Amount_Paid: Number(this.paymentreceipt.Amount_Paid),
          Proof_of_Payment: PDF_File,
          Progress: {ID: 1000003, Name: 'Paid'}
        }).then(quoteupdatedoc => {
          this.ClosePaymentConfirmationDialog()
          this.$store.commit('setCustomProcessingDialog',false)
        })
      },
    ClosePaymentConfirmationDialog(){
        this.InvoicePaymentConfirmationDialog = false
        this.paymentreceipt = {
            Amount_Paid: 0,
            SelectedPOPFile: {}
          }
      },
    ActivateInvoicePaymentConfirmationDialog(){
      this.paymentreceipt.Amount_Paid = Number(this.RecordData.Invoice_Total.toFixed(2))
        //console.log(this.paymentreceipt)
        this.InvoicePaymentConfirmationDialog = true
    },
    IsAccountingClerk(accountingclerkprop,BUid){
        return this.userLoggedIn.BusinessAccounting && BUid && this.userLoggedIn.BusinessAccounting[BUid] && this.userLoggedIn.BusinessAccounting[BUid][accountingclerkprop]
      },
    RefreshRelatedTabs(){
        this.RelatedTabsRefresh = true
        //console.log('TabsRenderMode',this.TabsRenderMode) 
        setTimeout(() => {
          this.RelatedTabsRefresh = false
        }, 50);
      },
    AddStoreArticle(){
      let record = this.DynamicRecordData
      let StoreGalleryItems = []
      
      let tab = this.SingleBuildTabs.find(obj => obj.DataViewName === 'Data Single Cabinet')
      //console.log(tab)
      if(tab){
        let elmnt = tab.Elements.find(obj => obj.Entityid === 'Gallery')
        //console.log(elmnt)
        if(elmnt){
          StoreGalleryItems = elmnt.DataArray
        }
      }
      //console.log(record)
      let storeobj = {  
        // Backorder: this.RecordData.Backorder,
        //Inbound: this.RecordData.Inbound,
        FullScreenDialog: true,
          EntityID: this.CurrentEntity.id,               
          recordid: record.id,                  
          IsDataRecord: true,                 
          Center: 'justify-center',                 
          CenteredHeader: true,                 
          Name: 'Store Product Single',                 
          Photo: record.Image,                                    
          show: false,                  
          HasBGIMG: true,                 
          BGIMGisIMG: true,                 
          ShowsPrice: true,                 
          SubHeaderFontFamily: 'Roboto',                  
          SubHeaderFontSize: 18,                  
          SubHeaderColor: {                 
              hex: '#FF0000FF'                  
          },                  
          HeaderFontFamily: 'Roboto',                 
          HeaderFontSize: 14,                 
          HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },                 
          FontSize: 10,                 
          FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },                 
          HasActionButton: true,                  
          ActionButtonName: 'Add to Cart',                  
          ActionBtnDark: true,                  
          ActionButtonType: 'Add Cart Item',                  
          ButonColor: {                 
              hex: '#FF0000FF'                  
          },                  
          ButtonAlignment: 'justify-end',                 
          Title: record['Product_Code'],                 
          Description: `<div style="text-align: center;">`+record['Descriptive_Text']+`</div>`,                 
                                              
          FlexXLRG:3,                 
          FlexLarge: 3,                 
          FlexMedium: 3,                  
          FlexSmall: 6,                 
          FlexXSmall: 12,                 
          Cols: 3,                  
          Justify: 'justify-center',                  
          RowColor: '#00ffff',                  
          ShowComponents: true,                 
          Icon: 'mdi-cards',                  
          MarginTop: 0,                 
          MarginBottom: 0,                  
          MarginLeft: 0,                  
          MarginRight: 0,                 
          BGColor: {                  
                        hex: '#ffffff',     
                        hexa: '#ffffffff',            
                        rgba: {r: 255,g: 255,b: 255}                  
                },
          IsDark: true,               
          } 
          if(this.RecordData.Purchased_Counter){
            storeobj.Purchased_Counter = this.RecordData.Purchased_Counter
          }
          else{
            storeobj.Purchased_Counter = 0
          }
          if(this.RecordData.Purchased_With){
            storeobj.Purchased_With = this.RecordData.Purchased_With
          }
          else{
            storeobj.Purchased_With = {}
          }
          storeobj.Description = record['Product_Code'] 
          //console.log(this.CurrentEntity.StorePriceField,record[this.CurrentEntity.StorePriceField.Name])
          // if(this.CurrentEntity.StorehasFilter && this.CurrentEntity.StoreFilters){
          //   this.CurrentEntity.StoreFilters.map(filter => {
          //     storeobj[filter.Name] = filter.Response
          //     storeobj[filter.Name].IsStoreFilter = true
          //   })
          // } 
          if(this.CurrentEntity.StoreImageField && record[this.CurrentEntity.StoreImageField.Name]){                  
              storeobj.HasImage = true                  
              storeobj.IMG = record['Image']['ThumbURL']                  
          } 
          
          //console.log(this.CurrentEntity.StorePriceField)                
          if(record.Store_Price){    
                          
              storeobj.Price = record.Store_Price                 
          }
          if(this.CurrentEntity.StoreShips){
            storeobj.ItemShips = true
          }
          if(this.CurrentEntity.StoreAllowsRating){
            storeobj.AllowsRating = true
          }
          if(this.CurrentEntity.StorehasUsed && this.CurrentEntity.StoreUsedField && record[this.CurrentEntity.StoreUsedField.Name]){
            storeobj.Condition = record[this.CurrentEntity.StoreUsedField.Name]
          }   
          let brandfield = this.CurrentEntity.AllFields.find(obj => obj.id === 'Store_Product_Brand')
          if(brandfield){
            //console.log(record[brandfield.Name])
            if(brandfield.Type === 'Lookup' && record[brandfield.Name]){
              storeobj.Store_Product_Brand = {
                ID: record[brandfield.Name].id,
                Name: record[brandfield.Name][brandfield.LookupFieldName]
              }
              
            let brandentity = this.SystemEntities.find(obj => obj.id === brandfield.RelatedBuildID)
            //console.log(brandentity)
            if(brandentity){
              //has it got brand logo field? Any single file upload with featured photo can be marked as such but will only take one
              let brandlogofield = brandentity.AllFields.find(obj => obj.Store_Brand_Logo)
              if(brandlogofield && record[brandfield.Name][brandlogofield.Name]){
                storeobj.Store_Product_Brand.Logo = record[brandfield.Name][brandlogofield.Name].fileurl
              }
              // if(brandentity.Has_Wiki){
              //   let singlenamesplit = brandentity.SingleName.split(' ').join('')
              //   storeobj.Store_Product_Brand.WikiPath = '/'+singlenamesplit+'-Wiki/'+record[brandfield.Name].id
              // }
              let Store_Read_Morefield = brandentity.AllFields.find(obj => obj.Store_Read_More)
              if(Store_Read_Morefield && record[brandfield.Name][Store_Read_Morefield.Name]){
                storeobj.Store_Product_Brand.Store_Read_MoreLink = record[brandfield.Name][Store_Read_Morefield.Name]
              }
              let Store_Writeupfield = brandentity.AllFields.find(obj => obj.Store_Writeup)
              if(Store_Writeupfield && record[brandfield.Name][Store_Writeupfield.Name]){
                storeobj.Store_Product_Brand.Store_Writeup = record[brandfield.Name][Store_Writeupfield.Name]
              }
              
            }
            let payl = {
                Navlistid: this.CurrentEntity.id,
                NavListSubColId: brandfield.RelatedBuildID,
                Data: storeobj.Store_Product_Brand,
                siteid: this.$store.state.ActiveSuiteid
              }
              const functions = firebase.functions();
                const AppendCatNavlistLookupFilter = functions.httpsCallable('AppendCatNavlistLookupFilter');
                AppendCatNavlistLookupFilter(payl).then(result => {
                  //  vm.DeactivateReceivingDialog()
                    console.log(result)
                  }) 
            }
            else{
              storeobj.Store_Product_Brand = record[brandfield.Name]
              let payl = {
                Navlistid: this.CurrentEntity.id,
                NavListSubColId: brandfield.RelatedBuildID,
                Data: storeobj.Store_Product_Brand,
                siteid: this.$store.state.ActiveSuiteid
              }
              const functions = firebase.functions();
                const AppendCatNavlistLookupFilter = functions.httpsCallable('AppendCatNavlistLookupFilter');
                AppendCatNavlistLookupFilter(payl).then(result => {
                  //  vm.DeactivateReceivingDialog()
                    console.log(result)
                  }) 
            }
          }
          
          //maybe simple enough. get brandfield, if brandfield it has "brand". 
          //check for brandfield entity while you at it. find and check if has wiki
          //if has wiki and publish type same as this (we skipped that step anyway but ja), then...Hmm autoamtically?? add wiki path
          //store single at this stage is static. We can just add it there. Like a button. And IF the item is in fact slotted with "wikiPath" then it's active. takes you to it
          //now just make sure don't activate social navbar in the process, IF not gustscansocial
          if(this.CurrentEntity.StorehasBrands && this.CurrentEntity.StoreBrandField && record[this.CurrentEntity.StoreBrandField.Name]){
            if(this.CurrentEntity.StoreBrandField.Type === 'Lookup'){
              storeobj.Store_Product_Brand = {
                ID: record[this.CurrentEntity.StoreBrandField.Name].id,
                Name: record[this.CurrentEntity.StoreBrandField.Name][this.CurrentEntity.StoreBrandField.LookupFieldName]
              }
            }
            else{
              storeobj.Store_Product_Brand = record[this.CurrentEntity.StoreBrandField.Name]
            }
            
          }    
          
          //storeobj.Site = {Site: this.ActiveStore.Name,id: this.ActiveStore.id}
         // storeobj.Siteid = this.ActiveStore.id
         let HeirarchyField = this.CurrentEntity.AllFields.find(obj => obj.id === 'Product_Group')
          if(HeirarchyField){
            let fieldobj = Object.assign({},HeirarchyField)
            
            fieldobj.Levels.map(lvl => {
              delete lvl.children
              if(record[lvl.FieldName]){
                storeobj[lvl.FieldName] = {
                  ID: record[lvl.FieldName].ID,
                  EntityID: this.CurrentEntity.id,
                  Name: record[lvl.FieldName].Name,
                  Level: lvl,
                  LevelFieldName: record[lvl.FieldName].LevelFieldName,
                }
              }
            })            
          }
          let descrtextelmnt = document.getElementById('DescriptiveText')
          if(descrtextelmnt){
            storeobj.DescriptiveText = descrtextelmnt.innerHTML
          }
          storeobj.DescriptiveText = record.Descriptive_Text
          //console.log(record)
          if(record.Stock_Qty){
            storeobj.Stock_Qty = record.Stock_Qty
          }
          if(record.Discounted_Price){
            storeobj.Discounted_Price = record.Discounted_Price
          }
          if(record.Discount){
            storeobj.Discount = record.Discount
          }
          if(record.Purchase_Leadtime){
            storeobj.Purchase_Leadtime = record.Purchase_Leadtime
          }
          if(record.Supply_Leadtime){
            storeobj.Supply_Leadtime = record.Supply_Leadtime
          }
          //console.log(record)
          if(StoreGalleryItems && StoreGalleryItems.length > 0){
            storeobj.Assets = StoreGalleryItems.map(image => {
              let obj = {
                ThumbURL: image.ThumbURL,
                URL: image.fileurl
              }
              return obj
            })
          }
          storeobj.IMG = storeobj.Photo.ThumbURL
            //console.log(storeobj,record)
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()+'store').doc(record.id).set(storeobj)
    },
    
    ActivateSelectedElement(item){      
      //console.log('ActivateSelectedElement',item)
      this.RefreshingSingleElement = true
      if(item === this.SelectedElement && this.$route.name !== 'SystemConfig'){
        this.SelectedElement = ''
      }
      else{
        this.SelectedElement = item
      }
      setTimeout(() => {
          this.RefreshingSingleElement = false
      }, 100);
      //console.log(item,this.SelectedElement,this.RefreshingSingleElement)
    },
    ViewActivities(){
      this.ActivitiesView = !this.ActivitiesView
    },
    SetActivityRegarding(type){     
      
      let ActivityTypes = [
        {
          ID: 1000001,
          Name: 'Task'
        },
        {
          ID: 1000002,
          Name: 'Appointment'
        },
      ]
         let item = {
          Regarding: {Regarding_Type: this.CurrentEntity.SingleName.split(' ').join(''),
          Regarding_Record: this.RelatedObj,Regarding_Topic: this.CurrentEntity.id
          },
          Type: ActivityTypes.find(obj => obj.Name === type)
          }
          //console.log('SetActivityRegarding',item)
          if(this.Business_Unit){
            item.Regarding.Regarding_Record.Business_Unitid = this.Business_Unit.id
          }
          if(this.Owner){
            item.Regarding.Regarding_Record.Ownerid = this.Owner.id
          }
      this.$emit('SetActivityRegarding',item)
    },
    ToggleTimesheetlistdialog(){
      this.TimesheetsDialog = !this.TimesheetsDialog
    },
    ToggleLiaisonDialog(){
      this.LiaisonDialogActive = !this.LiaisonDialogActive
    },
    ActivateLiaisonDialog(){
      this.LiaisonDialogActive = true
    },
    ToggleTimesheetDialog(){
      this.$emit('ToggleTimesheetDialog',this.RelatedObj)
    },
    onPhotoFileselected(field,file){
        field.localURL = URL.createObjectURL(file)
        field.UploadFile = file
        // this.Record[field.Name+'localURL'] = 
        //console.log(this.Record,field,file)
      },
    
    UpdateProcedureStep(n){
      //StageID-1
      //stagenameid
      this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).update({
        [this.RecordData.stagenameid+'Step']: n
      })
    },
    ToggleExportingtoPDFDialog(){
      this.ExportingtoPDFDialog = !this.ExportingtoPDFDialog
    },
    ToggleChangeStatusDialog(){
      this.ChangeStatusDialog = !this.ChangeStatusDialog
    },
    ToggleRATicketLog(){
      this.RATicketLog = !this.RATicketLog
    },
    OnePropEmit(Method,Prop){
      this[Method](Prop)
    },
    NoPropEmit(Method){
      this[Method]()
    },
    AssignProcess(){
      this.SelectProcessDialog = true
    },
    AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
      this.OperationalDB.update({
        ActiveProcess: this.ActiveProcess
      })
      let processdoc = 'RecordProcess'+this.ActiveProcess.Number
      //console.log(processdoc)
      //console.log(typeof this.RecordData[processdoc],this.RecordData[processdoc])
      //console.log(!this.RecordData[processdoc])
      if(!this.RecordData[processdoc]){
        // let ProcessDoc = 
        this.OperationalDB.collection('processes').add(this.ActiveProcess).then(doc => {
          this.OperationalDB.update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
            let Stage = this.ActiveProcess.ProcessStages[0]
            this.updateProcessStage(Stage,doc.id)
        })
       
        this.SelectProcessDialog = false
      }
      else{
        this.OperationalDB.collection('processes').doc(this.RecordData[processdoc]).update({
         DisplayName: this.ActiveProcess.DisplayName,
         Name: this.ActiveProcess.Name,
         ID: this.ActiveProcess.Number,
          ProcessStages: this.ActiveProcess.ProcessStages
        })
        this.OperationalDB.update({
            ActiveProcessDocid: this.RecordData[processdoc]
          })
          this.ActiveProcessDocid = this.RecordData[processdoc]
          //console.log(this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid))
          this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).get().then(stagesnap => {
            var stagedata = stagesnap.data()
            if(stagedata){
            this.StageName = stagedata.StageName
            if(stagedata.StageID){
            let stageobj = this.ActiveProcess.ProcessStages.find(obj => obj.Number === stagedata.StageID)
            //console.log('stageobj',stageobj,this.ActiveProcess,this.ActiveProcess.ProcessStages.indexOf(stageobj))
            this.StageID = this.ActiveProcess.ProcessStages.indexOf(stageobj)-1+2
            this.updateProcessStage(stageobj,this.ActiveProcessDocid)            
            }
            }
            this.SelectProcessDialog = false
            })
          }
    },
    IsComplete(Stage){
      if(this.StageID && this.ProcessStages.length > 0){
        //console.log('this.ProcessStages this.StageID',this.ProcessStages,this.StageID)
      return this.ProcessStages[this.StageID-1].Number >= Stage.Number  
      }
      
    },
    UpdateStages(Prop,value){
      this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).update({
        [Prop]: value
      })
    },
    updateProcessStage(Stage,ActiveProcessDocid){
          setTimeout(() => {
          this.OperationalDB.collection('processes').doc(ActiveProcessDocid).update({
            StageName: Stage,
            StageID: Stage.Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
          })
          this.OperationalDB.update({
            stagename: Stage,
            stagenameid: Stage.Number,
          })
          
        }, 300)
        },
    RefreshComponent(){
      this.EntityReady = false
      this.SingleRecordNavMenu = false
      setTimeout(() => {
        this.EntityReady = true
      }, 100);
    },
    ToggleFeatureFavorites(item){
      let array = JSON.parse(JSON.stringify(this.FavoriteFeatures))
      let oncheck = array.find(obj => obj === item.id)
      if(!oncheck){
        array.push(item.id)
      this.OperationalDB.update({
        FavoriteFeatures: array
      })
      }
      else{
        let index = array.indexOf(oncheck)
        array.splice(index,1)
        this.OperationalDB.update({
        FavoriteFeatures: array
      })

      }
    },
    ProvideTicket(NewRATicket){
      this.OperationalDB.update({
        RATicketid: NewRATicket.id
      })
    },
    ActivateRoadmapItemDialog(){
      this.RoadmapItemDialog = true      
    },
    CancelRoadmapItemDialog(){
      this.RoadmapItemDialog = false   
      this.NewRoadmapItem = {Name: ''}   
    },
    CreateRoadmapItem(){
      let newroadmapitem = Object.assign({},this.NewRoadmapItem)
      newroadmapitem.DueDate = new Date(newroadmapitem.DueDate)
      newroadmapitem.Status = {ID: 1000001, Name: 'Active'}
      newroadmapitem.Status_Reason = {ID: 1000003, Name: 'Incoming'} 
      newroadmapitem.Systemid = process.env.VUE_APP_RA_SYSTEM_ID
      newroadmapitem.Comments = []
      newroadmapitem.Commentnonce = 0
      db.collection('roadmapitems').add(newroadmapitem).then(doc => {
        newroadmapitem.id = doc.id
        this.OperationalDB.update({
          RoadmapItem: newroadmapitem
        })
        this.CancelRoadmapItemDialog()
      })
    },
    ActivateForumDiscussionDialog(){
      this.EditedStatusField = Object.assign({},this.StatusField)
      this.ForumDiscussionDialog = true
      
    },
    CancelForumDiscussionDialog(){
      this.ForumDiscussionDialog = false
      this.NewForum = {Title: ''}
    },
    CreateForumDiscussion(){
      //console.log(this.NewForum,this.ForumCategoriesField)
        let NewPost = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.NewForum.Title,
          Creator: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},          
          Creatorid: this.userLoggedIn.id,
          creatorname: this.userLoggedIn.Full_Name,
          post: this.NewForum.post,
          createdon: new Date(),
          Comments: [],
          Class: this.NewForum.Class,
          Category: this.ForumCategoriesField.Level1Response,
          RelatedObj: this.RelatedObj
        }
        if(this.userLoggedIn.Profile_Photo){
          NewPost.creatorimg = this.userLoggedIn.Profile_Photo
        }
        let col = db.collection('siteforumdiscussions')
        //console.log(NewPost)
        col.add(NewPost).then(doc => {
          NewPost.id = doc.id
          this.OperationalDB.update({
            Forum: NewPost
          })
        })
        //console.log(NewPost.post)
        this.CancelForumDiscussionDialog()
      
        },
    EditTableState(){
      this.EditedStatusField = Object.assign({},this.StatusField)
      this.TableStateDialog = true
    },
    CancelTableStateDialog(){
      this.TableStateDialog = false
      this.EditedStatusField = ''
      this.$store.commit('SetSocialItemInteractMethodProp',false) 
      this.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
    },
    UpdateStatemanagement(){
      this.$store.commit('SetSocialItemInteractMethodProp',true) 
      this.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
      //console.log(this.EditedStatusField,this.StatusField,this.ConfigDB)
      if(this.NeedsRASync){
         this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ConfigEntityID).collection('AllFields').doc('Status').set(this.EditedStatusField).then(rafield => {
            this.ConfigDB.collection('AllFields').doc('Status').set(this.EditedStatusField).then(configfield => {
              this.CancelTableStateDialog()
            })
        })          
      }
      else{
        this.ConfigDB.collection('AllFields').doc('Status').set(this.EditedStatusField).then(configfield => {
              this.CancelTableStateDialog()
            })
      }
      //TableStateDialog
      
    },
    ActivateWarehouseMovementDialog(){
      this.$router.push('/StockMovement/'+this.CurrentEntity.id.split('_').join('')+'/'+this.RecordData.Warehouseid+'/'+this.RouteID)
    },
    UploadFileSelect(file,field,Record){
      //console.log(file,field,Record)
      Record[field.Name] = {UploadFile: file, FileName: file.name}
    },
    ActivateProcessing(boolean){
      this.$emit('ActivateProcessing',boolean)
    },
    SaveReceivingItem(){
      let vm = this
      let file = vm.editedReceivingItem.Receiving_Document.UploadFile
      let filename = vm.editedReceivingItem.Receiving_Document.FileName
      if(this.editedReceivingItemIndex === -1){
        //console.log(this.OperationalDB,this.editedReceivingItem)
        let storepath = this.CurrentEntity.DisplayName.split(' ').join('')+'/GoodsReceiving/'+filename+'/'+new Date()
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$store.commit('setCustomProcessingDialogText','Uploading GRV')
        vm.$store.commit('setCustomProcessingDialog',true)
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
              
            //  let snackbarcontent = {
            //   snackbartimeout: 4000,
            //   snackbartext: 'Added '+field.DisplayName+' to - '+vm.RecordData[vm.CurrentEntity.PrimaryFieldName],
            //   snackbartop: true,
            // }
            // vm.$emit('ActivateSnackbar',true,snackbarcontent)
            delete vm.editedReceivingItem.Receiving_Document
            let GRVDoc = Object.assign({},vm.editedReceivingItem)
            GRVDoc.Receiving_Document = UploadObject
            GRVDoc.Date_Received = new Date(vm.editedReceivingItem.Date_Received)
            GRVDoc.Created_On = new Date()
            GRVDoc.Modified_On = new Date()
            GRVDoc.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
            GRVDoc.Created_Byid = this.userLoggedIn.id
            GRVDoc.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
            GRVDoc.Modified_Byid = this.userLoggedIn.id
            GRVDoc.StockObj = {
              [this.CurrentEntity.Primary_Field_Name]: this.DynamicRecordData[this.CurrentEntity.Primary_Field_Name],
              id: this.DynamicRecordData.id,
              list: this.CurrentEntity.id,
              single: this.CurrentEntity.SingleName,
              identifier: this.CurrentEntity.Primary_Field_Name
            }
            GRVDoc.Warehouseid = vm.RecordData.Warehouseid
            if(vm.RecordData.AddedtoStore){
              GRVDoc.StockObj.UpdateStore = vm.RecordData.AddedtoStore
              let Inventory_ArticleField = vm.CurrentEntity.AllFields.find(obj => obj.id === 'Inventory_Article')
              if(Inventory_ArticleField && vm.RecordData.Warehouseid && vm.RecordData.Inventory_Articleid){
                GRVDoc.StockObj.StoreCollection = Inventory_ArticleField.RelatedBuildID
                GRVDoc.StockObj.WarehouseDoc = vm.RecordData.Inventory_Articleid
                GRVDoc.StockObj.WarehouseID = vm.RecordData.Warehouseid
              }
            }
            vm.$store.commit('setCustomProcessingDialogText','Assigning GRV Number')
            const functions = firebase.functions();
             const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
              let payload = {
                Docid: 'GoodsReceivingVouchers_GRV_Number',
                Data: {},
                siteid: vm.$store.state.ActiveSuiteid
              }
              AssignAutoNumber(payload).then(result => {
                vm.$store.commit('setCustomProcessingDialogText','Submitting to Server')
                GRVDoc.GRV_Number = result.data
                let bulkoutboundpayload = {
                  Collection: 'GoodsReceivingVouchers',
                  Data: [],
                  ParentObj: GRVDoc,
                  Warehouse: vm.RecordData.Warehouseid,
                  siteid: vm.$store.state.ActiveSuiteid
                }
                let movementdoc = JSON.parse(JSON.stringify(bulkoutboundpayload))
                movementdoc.Data = GRVDoc
                bulkoutboundpayload.Data.push(movementdoc)
                //console.log('bulkoutboundpayload',bulkoutboundpayload)
                
                const ReceiveOrderlessGRV = functions.httpsCallable('ReceiveOrderlessGRV');
                ReceiveOrderlessGRV(bulkoutboundpayload).then(result => {
                  vm.$store.commit('setCustomProcessingDialogText','Server Response - OK')
                    vm.DeactivateReceivingDialog()
                    //console.log(result)
                  }) 
              })
            //insert autonumber somehwere here

            //needs to actually become ConsumeStockQty
            // db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('Warehouses').doc(vm.RecordData.Warehouseid).collection('GoodsReceivingVouchers').add(vm.editedReceivingItem).then(grdoc => {
            //   vm.DeactivateReceivingDialog()
            // })
            
          })          
      }
    },
    DeactivateReceivingDialog(){
      this.ReceivingDialog = false
      this.editedReceivingItem = {}
      this.$store.commit('setCustomProcessingDialog',false)
    },
    AddtoStore(){
      //console.log('RecordData',this.RecordData,this.DynamicRecordData,this.SubCollections,this.Galleries,this.CurrentEntity,this.SingleBuildTabs)
      let galleryentries = []
      let tab = this.SingleBuildTabs.find(obj => obj.DataViewName === 'Data Single Cabinet')
      if(tab){
        let galelmnt = tab.Elements.find(obj => obj.Entityid === 'Gallery')
        if(galelmnt){
          galleryentries = galelmnt.Data
        }
      }
      // let headerfailures = this.StoreHeaders.filter(header => {
      //     return !this.Record[header.recordvalue]
      //   })
      //   console.log('headerfailures',headerfailures,this.RecordData)
      //   if(headerfailures.length === 0){
      //     alert('yayay')
      //   }
      let articlefield = this.CurrentEntity.AllFields.find(obj => obj.id === 'Inventory_Article')
      if(articlefield){
        
       if(this.RecordData.Inventory_Article && this.RecordData.Business_Unit){
         let storevalues = [{RecordValue: this.RecordData.Available,StoreValue: 'Available',Type: 'Number'},{RecordValue: this.RecordData.Store_Price,StoreValue: 'Price',Type: 'Number'}
      ,{RecordValue: this.RecordData.Inventory_Article.Purchase_Leadtime,StoreValue: 'Purchase_Leadtime',Type: 'Number'},{RecordValue: this.RecordData.Inventory_Article.Supply_Leadtime,StoreValue: 'Supply_Leadtime',Type: 'Number'}
      //,{RecordValue: this.RecordData.Store_Price,StoreValue: 'Price',Type: 'Number'} here we actually need to separate discounted price from std price, that's it we need to do it if we want both displaying
      //but right now we do not have discounted price anywhere near the store anyway so it's okay until then
      ]
        let WarehouseDoc = {}
        storevalues.map(val => {
          if(val.Type === 'Number'){
            if(!val.RecordValue){
              WarehouseDoc[val.StoreValue] = 0
            }
            else{
              WarehouseDoc[val.StoreValue] = val.RecordValue
            }            
          }
        })
        WarehouseDoc.LookupObj = {
          [this.CurrentEntity.Primary_Field_Name]: this.RecordData[this.CurrentEntity.Primary_Field_Name],
          id: this.RecordData.id,
          list: this.CurrentEntity.DisplayName,
          single: this.CurrentEntity.SingleName,
          identifier: this.CurrentEntity.Primary_Field_Name
        }
        let col = db.collection(articlefield.RelatedBuildID.split('_').join('').toLowerCase()+'store').doc(this.RecordData.Inventory_Articleid).collection('Warehouses').doc(this.RecordData.Warehouseid)
        //console.log('col',col,WarehouseDoc)
        WarehouseDoc.WarehouseName = this.RecordData.Warehouse.Name
        WarehouseDoc.Inbound = this.RecordData.Inbound
        col.set(WarehouseDoc)
        this.OperationalDB.update({
          AddedtoStore: true,
          ArticleStoreCollection: articlefield.RelatedBuildID
        })
      } 
      }
      
    },
    ActivateReceivingDialog(){
      if(this.BypassInboundWIP){
        let progressfield = this.ReceivingFields.find(obj => obj.id === 'Progress')
        progressfield.ReadOnly = true
        this.editedReceivingItem.Progress = progressfield.Options[progressfield.Options.length-1]
      }
      this.ReceivingDialog = true
    },
    GetShortLivedURL(item){
      //console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
       let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
    CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
    onPhotoUpload(filename,file,storepath,field) {
    let vm = this
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,file,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              ThumbURL: thumbresult,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            this.OperationalDB.update({
                    [field.Name]: UploadObject,
                    [field.Name+'ThumbURL']: UploadObject.ThumbURL
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added '+field.DisplayName+' to - '+vm.RecordData[vm.CurrentEntity.PrimaryFieldName],
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            delete field.localURL
            delete field.file
                })
         })
      },
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
                  type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
    NavigatetoRouteParent(){
      let routes = this.$router.getRoutes()
      let newroute = routes.find(obj => obj.name === this.$route.name)
      //console.log(routes)
      newroute.query = {}
      //console.log(newroute)
      this.$router.push(newroute)
    },
    CheckRouting(){
      this.EntityReady = false
      this.SingleBuildTabs = []
    //console.log(this.$route)
    //modular tabs create calls
    if(this.$route.meta && this.$route.meta.DataSingleBuilder && !this.$route.meta.RecordisSystem && this.$route.query.TableType !== 'Operational_Entity'){
      this.ConfigSystemEntities = this.SystemEntities
      //console.log(this.OperationalDB,this.ConfigDB,this.ConfigEntityID,this.ConfigSystemEntities)
      this.CurrentEntity = this.ConfigSystemEntities.find(obj => obj.id === this.ConfigEntityID)
      this.StatusField = this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
      // this.Status = this.StatusField.DefaultOption
      // this.Status_Reason = this.StatusField.DefaultLevel2Option
      //console.log('StatusField',this.StatusField,this.Status,this.Status_Reason)
      this.GetEntitySingleBuildTabs(this.ConfigDB)
    }
    else if(this.$route.query.TableType === 'Operational_Entity'){
      if(this.$route.query.TableEntity){
        this.GetConfigSystemEntities(db.collection(this.$route.meta.Collection).doc(this.RouteID).collection('entities'))
      }
      else{
       this.GetConfigSystemEntities(db.collection(this.$route.meta.Collection)) 
      }      
    }
    else if(this.$route.meta && this.$route.meta.RecordisSystem){
      //console.log('this.ConfigDB',this.ConfigDB)
      this.CheckConfigMaster(db.collection('SystemConfig').doc('ConfigMaster'),db,process.env.VUE_APP_RA_SYSTEM_ID,this.System,this.userLoggedIn)
      
    }
    else if(this.$route.meta && this.$route.meta.SystemConfigBuilder){
      
      this.ConfigSystemEntities = this.SystemEntities
      this.CurrentEntity = this.ConfigSystemEntities.find(obj => obj.id === this.ConfigEntityID)
      this.GetEntitySingleBuildTabs(this.ConfigDB)
    }
     else if(this.$route.meta && this.$route.meta.SubColEntryViewer){
      //  alert('yay')
      let parententity = this.SystemEntities.find(obj => obj.id === this.$route.params.slug.split(' ').join('_'))
      let subcol = parententity.SubCollections.find(obj => obj.id === this.RouteID)
      //console.log(parententity,subcol)
      if(subcol.EntityBuilder){
        this.GetConfigSystemEntities(db.collection(this.$route.params.slug.split(' ').join('_').toLowerCase()).doc(this.$route.query.Parent).collection(this.RouteID))
      }
      else{
        this.GetConfigSystemEntities(db.collection(this.$route.params.slug.split(' ').join('_').toLowerCase()))
      }
       //this.GetConfigSystemEntities(db.collection(this.$route.params.slug.split(' ').join('_').toLowerCase()))
     }
     
    },
    UpdateSubColEntry(prop,value,AdditionalSaveMethod,FieldObject){
      //console.log(prop,value,AdditionalSaveMethod,FieldObject)
      this.OperationalDB.collection(FieldObject.RecordCollection).doc(FieldObject.id).update({
        [prop]: value,
        Modified_On: new Date(),
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
      })
    },
    CreateClientSystemMaster(){
      let basedoc = {
        Disabled: false,
        DefaultStatusField: this.DefaultStatusField,
        Name: this.RecordData.Name
      }
      this.clientDB.collection('SystemConfig').doc(this.RouteID).set(basedoc)
      
      this.FinalizerecordLoadingdialog() 
    },
    GetClientDBSystem(){
      //console.log('GetClientDBSystem',this.clientDB,this.ClientAppSignedIn)
      this.clientDB.collection('SystemConfig').doc(this.RouteID).onSnapshot(snapshot => {
        let system = snapshot.data()
        //console.log('ClientDBSystem',system)
        if(!system){
          this.CheckConfigMaster(this.clientDB.collection('SystemConfig').doc('ConfigMaster'),this.clientDB,this.RouteID,this.RecordData,this.ClientAppUser)
          //this.CheckConfigMaster(query,activedb,systemid,System,Administrator){
        }
        else{
          this.BaseClientSystemReady = true
          
          this.FinalizerecordLoadingdialog() 
        }
      })
    },
    AuthenticateClientApp(){
      this.ClientAppAuthDialog = true
    },
    GetSystemConfig(system){
      if(system.QuickstartStep){
        this.FinalizerecordLoadingdialog()
           this.QuickstartStep = system.QuickstartStep 
           if(this.QuickstartStep !== 8){
                this.QuickStartDialog = true
              }
        if(system.ClientFireStore){
                
          //console.log(system)
          let firebaseConfig = system.ClientFireStore
              this.ClientFireStore = firebaseConfig
              //console.log('const firebaseConfig = firebaseConfig',firebaseConfig)
              //console.log(this.ClientFireStore,firebase.apps)
              let check = firebase.apps.find(obj => obj.name === system.Name)
                if(check){
                  this.clientApp = check
                }
                else{
                  this.clientApp = firebase.initializeApp(this.ClientFireStore, system.Name)
                }
                this.ClientAppAuth()
            }
      }   
      else{
        this.FinalizerecordLoadingdialog()
        this.QuickStartDialog = true
      } 
        
      },
      async ClientAppAuth(){
      const clientdb = this.clientApp.firestore();
      let vm= this
      vm.clientDB = vm.clientApp.firestore()
                await this.clientApp.auth().onAuthStateChanged(function(user) {
                  if(user){
                    vm.ClientAppSignedIn = true
                    
                    vm.clientDB.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').onSnapshot(res => {
                                    const changes = res.docChanges();
                                    changes.forEach(change => {
                                      if (change.type === 'added') {
                                        vm.ClientDBBusinessUnits.push({
                                          ...change.doc.data(),
                                          id: change.doc.id
                                        })
                                      }
                                    })                 
                                  })
                    vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
                          vm.ClientAppUser = snapshot.data()
                          vm.ClientAppUser.id = user.uid
                          vm.GetClientDBSystem()
                          //console.log(vm.ClientAppUser)
                          if(vm.ClientAppUser && vm.ClientAppUser.id){
                            // vm.ClientAppUser.id = user.uid
                            vm.UserPicture = vm.ClientAppUser.Profile_Pic
                            vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(user.uid).onSnapshot(rolesnapshot => {
                              if(rolesnapshot){
                               
                             vm.ClientappSignedInEmail = user.email
                              //vm.getTickets(clientdb)                    
                              
                              vm.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').where('rolesarrayQuery','array-contains','System Admin').onSnapshot(res => {
                                    const changes = res.docChanges();
                                    changes.forEach(change => {
                                      if (change.type === 'added') {
                                        let obj = {
                                          ...change.doc.data(),
                                          id: change.doc.id
                                        }
                                        vm.GetClientAppUsers()
                                        vm.GetUserdata(obj)
                                      }
                                    })                 
                                  })
                              }
                            
                              })
                          }                   
                          
                        })                        
                  }
                  else{
                    //okay I think if no users in users in client db, then..create everythign needed in client db. the base stuff
                    //remember it is within quickstartdialog that you first give the firebaseconfig, and therfore nothing could hae been done prior to this actually. 
                    if(vm.QuickstartStep === 8){
                      vm.ClientAppAuthDialog = true
                    }
                     
                    // if(vm.QuickstartStep === 7){
                    //   vm.ClientAppAuthDialog = true
                    // }
                    // if(vm.QuickstartStep > 2){
                      
                    //  vm.clientDB.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').onSnapshot(res => {
                    //       const changes = res.docChanges();
                    //       changes.forEach(change => {
                    //         if (change.type === 'added') {
                    //           vm.ClientDBBusinessUnits.push({
                    //             ...change.doc.data(),
                    //             id: change.doc.id
                    //           })
                    //         }
                    //       })                 
                    //     })
                    // }
                   
                  }
                })
    },
    GetClientAppUsers(){ 
      //alert('getting client users')     
      this.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let userobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
              this.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userobj.id).onSnapshot(snapshot => {
              let userdata = snapshot.data()
              //console.log('userdata',userdata)
              if(userdata.IsGuestUser){
                userobj.IsGuestUser = userdata.IsGuestUser
              }
              if(userdata.CanInviteUsers){
                userobj.CanInviteUsers = userdata.CanInviteUsers
              }
              userobj.Business_Unit = userdata.Business_Unit
              userobj.Business_Unitid = userdata.Business_Unitid              
      
              userobj.Subordinatearrayquery = userdata.Subordinatearrayquery
              userobj.SubordinateArrayDBrules = userdata.SubordinateArrayDBrules
              userobj.rolesarrayDBRules = userdata.rolesarrayDBRules
              userobj.rolesarrayQuery = userdata.rolesarrayQuery   
              this.clientDB.collection('sitemoderators').doc(userobj.id).onSnapshot(modsnapshot => {
                let moderatordata = modsnapshot.data()
                //console.log('moderatordata',moderatordata)
                if(moderatordata && moderatordata.ModerationPermissions){
                  userobj.ModerationPermissions = moderatordata.ModerationPermissions
                  this.ClientAppUsers.push(userobj)
                }
                else{
                  this.ClientAppUsers.push(userobj)  
                }                                       
              })           
               
            })
          }         
                              
        })
      })
    },
    
     //modular tabs methods start
     //singlerecordnav
     ActivateSingleRecordNavMenuItem(item,ParentNavItem){
       if(this.SelectedElement){
         this.ActivateSelectedElement('')
       }
       if(item.RouteOnly){
         this.$router.push(item.RoutePath)
       }
       if(item.WikiCollectionBuilder){
         this.$router.push('/WikiCollectionBuilder/'+item.id)
       }
       if(item.CatNavlistBuilder){
         this.$router.push('/DirectoryBuilder/'+item.id)
       }
       if(item.ContentLibraryBuilder){
         this.$router.push('/ContentLibraryBuilder')
       }
       if(item.PageBuilder){
         let routes = this.$router.getRoutes()
         //console.log(routes)
         let pagebuilderroute = routes.find(obj => obj.name === 'PageBuilder')
         //pagebuilderroute.params = {id: 'New'}
         pagebuilderroute.query = {PublishType: item.PublishType}
         this.$router.push(pagebuilderroute)
       }
       else if(item.IsWebFormBuilder){
         //here we need to activate a dialog to add a new library but more of that later
         //
         this.ParentNavItem = item
       }
       else if(item.IsWebForm){
         //here 
         if(item.id === 'ExistingWebForms'){
           this.$router.push('/WebForms')
         }
         else if(item.id === 'NewWebForm'){
            this.$router.push('/WebFormBuilder-New')
         }
       }
       else if(item.PhotoLibraryBuilder){
         this.ParentNavItem = item
         //here we need to activate a dialog to add a new library but more of that later
       }
       else if(item.IsPhotoLibrary){
         this.$router.push('/'+item.Name.split(' ').join(''))
       }
       else if(item.IsPage){
         let routes = this.$router.getRoutes()
         //console.log(routes)
         let route = routes.find(obj => obj.name === 'PageEditor')
         route.params = {id: item.id}
         //console.log(route)
         this.$router.push(route)
       }
       else{
         //console.log(item,'SystemGuides',item.SystemGuides,this.ComputedSingleBuildTabs)
         if(!item.SingleBuildTabs){
           //alert('no tabs')
           this.SingleRecordNavMenuItem = item
         }
         else{
           this.ParentNavItem = {Children: []}
           this.SingleRecordNavMenu = false
         }
          
          if(typeof item.Children !== 'undefined' || typeof item.SystemGuides !== 'undefined'){
            this.ParentNavItem = item
          }
          else if(ParentNavItem){
            this.ParentNavItem = ParentNavItem
          }
          this.EntityReady = false
          let newroute = { path: this.$route.path, query: item.RouteQuery}
          //console.log(newroute)
          if(item.RouteQuery){
           this.$router.push(newroute) 
          }          
          //okay how would I knoed this is either STD subcol or lookup subcol uhm..
          

          // this.$route.query.Record = 'Test'
          // this.$route.path = this.$route.path+'?Record=Test'
          //console.log(this.$route)
          setTimeout(() => {
              this.EntityReady = true
          }, 300);
       }
      
      
    },
    GetUserdata(userrole){
      //console.log(userrole)
      this.clientDB.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(userrole.id).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        this.ClientDBAdministrators.push(userdata)
      })
    },
     //singlerecordnav

    ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean) 
        },
    ActivateMultipleFilesUploadNotification(boolean){
        this.$emit('ActivateMultipleFilesUploadNotification',boolean) 
        },
    CancelEditing(boolean){
      this.SingleFormEditing = boolean
    },
    GetSubCollectionData(subcolarrayname,subcoldata,LookupBuilds){
      this.OperationalDB.collection(subcolarrayname).onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let subcolentry = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                subcolentry.CanCreate = this.CanEdit
                subcolentry.CanEdit = this.CanEdit
                subcolentry.CanDelete = this.CanDelete
               subcoldata.push(subcolentry)
                // if(LookupBuilds){
                //   buildswithsubcol = build.SubCollections.filter(build => {return build.SubCollections && build.SubCollections.length > 0})                    
                //     if(buildswithsubcol.length === 0){
                //       subcoldata.push(subcolentry)
                //     }
                //     else{
                //       buildswithsubcol.map(build => {
                //       if(build.SubCollections){
                //         build.SubCollections.map(subcolsubcol => {
                //           build.SubCollections = []
                //           this.GetSubColSubCol(build,subcolsubcol,this.OperationalDB.collection(newsubcol.id).doc(subcolentry.id).collection(subcolsubcol))
                //         })                        
                //       }                      
                //     })
                //     }
                //   }
                //   else{
                //     subcoldata.push(subcolentry)
                //   }
              }
            })
      })
    },
    CheckConfigMaster(query,activedb,systemid,System,Administrator){
      query.onSnapshot(snapshot => {
        let systemdata = snapshot.data()
        
        //console.log('CheckConfigMaster systemdata',systemdata,query,activedb,systemid,System,Administrator,this.userLoggedIn)
        if(systemdata && Administrator.id === this.userLoggedIn.id){
          this.GetConfigSystemEntities(activedb.collection('SystemConfig').doc('ConfigMaster').collection('entities'))
        }
        else{
          this.CreateBasicConfigMaster(activedb.collection('SystemConfig').doc('ConfigMaster'),this.System,systemid,Administrator)
          if(this.SystemisRA){
          this.CreateBasicConfigMaster(activedb.collection('SystemConfig').doc(systemid),this.System,systemid,Administrator,true)
          }
          else if(systemid !== process.env.VUE_APP_RA_SYSTEM_ID){
            this.CreateClientSystemMaster()
          }
        }
        
      })
      
    },
    CreateBasicConfigMaster(configpath,System,systemid,Administrator,systemmaster){
      //console.log('this.System',System)
      this.CreateConfigEntity('Systems',configpath,System,systemid,Administrator,systemmaster)     
      this.CreateConfigEntity('Entities',configpath,System,systemid,Administrator,systemmaster) 
      this.CreateConfigEntity('Fields',configpath,System,systemid,Administrator,systemmaster)
      this.FinalizerecordLoadingdialog() 
    },
    
    CreateConfigEntity(Type,configpath,System,systemid,Administrator,systemmaster){
    let configentity = ''
    let entityrecords = []
    if(Type === 'Systems'){
     configentity = {
        DisplayName: 'Systems',
        SingleName: 'System',
        Entity_Type: {ID: 1000001, Name: 'System Builder'},
        dataseticon: 'mdi-apps',
        datasetsecuritystructure: 'Business Unit',
        systemid: systemid,
        system: {
          name: System.Name,
          id: systemid
        },
        RecordPrimaryField: {
          Name: 'Name',
          DisplayName: 'Name',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        Primary_Field_Name: 'Name',
        SingleRecordNavMenu: true,
        SingleBuildTabs: []
      }
    }
    else if(Type === 'Entities'){
     configentity = {
        DisplayName: 'Entities',
        SingleName: 'Entity',
        Entity_Type: {ID: 1000002, Name: 'Entity Builder'},
        dataseticon: 'mdi-apps',
        datasetsecuritystructure: 'Custom Roles',
        systemid: systemid,
        system: {
          name: System.Name,
          id: systemid
        },
        RecordPrimaryField: {
          Name: 'DisplayName',
          DisplayName: 'DisplayName',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: true,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        Primary_Field_Name: 'DisplayName',
        // SingleRecordNavMenu: true,
        SingleBuildTabs: []
      }
    }
    else if(Type === 'Fields'){
     configentity = {
        DisplayName: 'Fields',
        SingleName: 'Field',
        Entity_Type: {ID: 1000003, Name: 'Field Builder'},
        dataseticon: 'mdi-apps',
        datasetsecuritystructure: 'Custom Roles',
        systemid: systemid,
        system: {
          name: System.Name,
          id: systemid
        },
        RecordPrimaryField: {
          Name: 'Type',
          DisplayName: 'Type',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        Primary_Field_Name: 'Type',
        // SingleRecordNavMenu: true,
        SingleBuildTabs: []
      }
      let fieldtypes = ['Single Line Text','Multiple Lines Text','Boolean','Option Set','Date','AutoNumber Field','Number Field','Single File Upload','Calculated Field','Map Location','Shared Doc Link','Lookup']
      entityrecords = fieldtypes.map(type => {
        let fieldobj = {
          Type: type,
          Created_By: {id: Administrator.id, Name: Administrator.Name, Surname: Administrator.Surname, Full_Name: Administrator.Full_Name},
          Created_Byid: Administrator.id,
          Created_On: new Date(),
          Modified_By: {id: Administrator.id, Name: Administrator.Name, Surname: Administrator.Surname, Full_Name: Administrator.Full_Name},
          Modifiedid: Administrator.id,
          Modified_On: new Date(),
        }
        let Status = this.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
        let StatusReason = Status.Options.find(obj => obj.Name === 'Open')
        fieldobj.Status = Status
        fieldobj.Status_Reason = StatusReason
        return fieldobj
      })
      
    }
    
      let entityfields = [
        {Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        
      ]
      entityfields.push(this.DefaultStatusField)
      if(Type === 'Systems'){
        let additionalfields = [{Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'BusinessUnit', RelatedRecord: 'BusinessUnits',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
         {Name: 'Company', DisplayName: 'Company', Type: 'Lookup',LookupFieldName: 'Company_Name',RelatedBuildID: 'Accounts',RelatedLocation: 'Account', RelatedRecord: 'Accounts',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true},
         {
          Name: 'Name',
          DisplayName: 'Name',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        }
        ]
        entityfields = entityfields.concat(additionalfields)
      }
      else if(Type === 'Entities'){
        let additionalfields = [
          {
          Name: 'DisplayName',
          DisplayName: 'DisplayName',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: true,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        {
          Name: 'Entity Type',
          DisplayName: 'Entity Type',
          Type: 'Option Set',
          UsedonForm: true,
          IsHeader: true,
          HeaderIndex: 2,
          Options: [
            
            {
              ID: 1000000,
              Name: 'Activities'
            },
            {
              ID: 1000001,
              Name: 'System Builder'
            },
            {
              ID: 1000002,
              Name: 'Entity Builder'
            },
            {
              ID: 1000003,
              Name: 'Field Builder'
            },
            {
              ID: 1000004,
              Name: 'User Entity'
            },
            {
              ID: 1000005,
              Name: 'Business Unit Entity'
            },
            {
              ID: 1000006,
              Name: 'Standard'
            },
            {
              ID: 1000007,
              Name: 'Store Product'
            },
            {
              ID: 1000008,
              Name: 'Classified Listing'
            },
            {
              ID: 1000009,
              Name: 'Calendar'
            },
            {
              ID: 1000010,
              Name: 'Booking Schedule'
            },
            {
              ID: 1000011,
              Name: 'Document Library'
            },
            {
              ID: 1000012,
              Name: 'Web Form'
            },
            {
              ID: 1000013,
              Name: 'Inventory Article'
            },
            {
              ID: 1000014,
              Name: 'Ticketing Table'
            },
             {
              ID: 1000015,
              Name: 'User Guide'
            }
          ],
          lastassignedinteger: 1000013

        },
        {
          Name: 'Description',
          DisplayName: 'Description',
          Type: 'Multiple Lines Text',
          IsHeader: true,
          UsedonForm: false,
          HeaderIndex: 3
        },
        {
          Name: 'Primary Field Name',
          DisplayName: 'Primary Field Name',
          Type: 'Single Line Text',
          UsedonForm: false,
          IsHeader: true,
          HeaderIndex: 4
        },
        {
          Name: 'Table Icon',
          DisplayName: 'Table Icon',
          Type: 'Single Line Text',
          UsedonForm: false,
          IsHeader: true,
          HeaderIndex: 5
        },
        {
          Name: 'Security Structure',
          DisplayName: 'Security Structure',
          Type: 'Option Set',
          UsedonForm: false,
          IsHeader: true,
          HeaderIndex: 6,
          Options: [
            {
              ID: 1000001,
              Name: 'Custom Roles'
            },
            {
              ID: 1000002,
              Name: 'Business Unit'
            },
            {
              ID: 1000003,
              Name: 'Warehouse'
            }
          ],
          lastassignedinteger: 1000003
        },
        ]
        entityfields = entityfields.concat(additionalfields)
      }
      else if(Type === 'Fields'){
        let additionalfields = [
          {
          Name: 'Type',
          DisplayName: 'Type',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        ]
        entityfields = entityfields.concat(additionalfields)
      }
      //Okay system master and configmaster alike for a SystemBuilder actaully needs to here do something else. 
      //we need the subcollections applicable to a system to be added automatically. you get? So we don't ahve that now
      //Additionally remember "entities" subcolleciton needs to be fitted with a boolean "EntityBuilder" which will be used to respond to RecordPrimaryField and Primary Field Name and whatever. 
      //Also we need to fit "entities" subcol with something that would sync in two ways
      //1. The first sync is to sync with the template it generated from, a button to "update"
      //2. the second seync actually is a matter of checking updates on client end somehwere somehow check client db see what changes move over to RA, and vice versa
      if(!systemmaster && Type === 'Systems'){
        
        configpath.set({Disabled: false}).then(configdoc => {
       let systemdocpath =  configpath.collection('entities').doc(configentity.DisplayName.split(' ').join('_'))
        systemdocpath.set(configentity).then(doc => {
          entityfields.map((field,fieldindex) => {
            field.Name = field.Name.split(' ').join('_')
            systemdocpath.collection('AllFields').doc(field.Name).set(field).then(fielddoc => {
            })
          })          
        })
      })
      }
      else{
        let systemdocpath =  configpath.collection('entities').doc(configentity.DisplayName.split(' ').join('_'))
        systemdocpath.set(configentity).then(doc => {
          entityfields.map((field,fieldindex) => {
            field.Name = field.Name.split(' ').join('_')
            systemdocpath.collection('AllFields').doc(field.Name).set(field).then(fielddoc => {
            })
          })    
        }) 
        //console.log('entityrecords',entityrecords)
        entityrecords.map(record => {
            //console.log('record',record,configentity.DisplayName.split(' ').join('').toLowerCase(),[configentity.Primary_Field_Name],record[configentity.Primary_Field_Name].split(' ').join('_'))
            db.collection(configentity.DisplayName.split(' ').join('').toLowerCase()).doc(record[configentity.Primary_Field_Name].split(' ').join('_')).set(record)
          }) 
      }
    },
    GetConfigSystemEntities(query){
        let vm = this
        //console.log('query',query,vm.ConfigEntityID)
        query.onSnapshot(res => {
            const changes = res.docChanges();
            let arraylength = res.docs.length
            changes.forEach(change => {
              if (change.type === 'added') {
                let build = change.doc.data()
                build.id = change.doc.id
                //console.log('build',build)
                vm.GetAllEntityProps(build,query).then(function(allpropsresult) {
                    //console.log('allpropsresult',allpropsresult,build)
                    if(allpropsresult){
                        vm.ConfigSystemEntities.push(build)
                        //console.log(vm.ConfigSystemEntities.length,arraylength,vm.ConfigSystemEntities.length === arraylength)
                        if(vm.ConfigSystemEntities.length === arraylength){
                          if(vm.$route.query && vm.$route.query.Parent){
                            vm.CurrentEntity = vm.ConfigSystemEntities.find(obj => obj.id === vm.ConfigEntityID)
                            vm.StatusField = vm.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
                          }
                          else{
                            vm.CurrentEntity = vm.ConfigSystemEntities.find(obj => obj.id === vm.ConfigEntityID)
                            vm.StatusField = vm.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
                          }  
                          //console.log(vm.ConfigEntityID,vm.ConfigDB,vm.CurrentEntity,vm.ConfigSystemEntities,vm.EntityReady)                          
                            vm.GetEntitySingleBuildTabs(vm.ConfigDB)
                        }
                    }
                })  
              }
            })
            
        })
    },
    GetAllEntityProps(build,query){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(build,query).then(function(allfieldsresult) {
                if(allfieldsresult){
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(build,query).then(function(subcolresult) {
                        // console.log(subcolresult)
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(build,query).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(build,query).then(function(formsresult) {
                            vm.GetDocumentRegisters(build,query).then(function(docregresult) {  
                                vm.GetGalleries(build,query).then(function(galleryres) {                          
                                vm.GetEntityAutomations(build,query).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                vm.GetGoalMeasurements(build).then(function(goalsresult) {
                                    if(allfieldsresult && subcolresult && processesresult && formsresult && automationsresult && goalsresult && docregresult && galleryres){
                                        resolve('got the props for '+build.id)
                                    }
                                })
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
    GetEntityAllFields(build,query){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(build,query){     
            //console.log('calling SubCollections',build,query)
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(build,query){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('EntityProcesses').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(build,query){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(build,query){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(build,query){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(build,query){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        //console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
    GetEntitySingleBuildTabs(query){
      query.onSnapshot(snapshot => {        
        let entity = snapshot.data()     
        entity.id = this.ConfigEntityID   
        //console.log('1SystemConfigentity',entity,this.ConfigSystemEntities)
          if(entity){
            // if(this.$route.meta && this.$route.meta.RecordisSystem){
             
            //   this.FinalizerecordLoadingdialog() 
            // }
            //okay we need to call ConfigSystemEntities if RecordisSystem
            // if(this.CurrentEntity && this.CurrentEntity.SingleRecordNavMenu){
            //   this.SingleRecordNavMenu = true
            // }
            //console.log('this.CurrentEntity',this.CurrentEntity)
            this.PrimaryFieldName = entity.RecordPrimaryField.Name
            if(this.CurrentEntity && this.CurrentEntity.SubCollections){
              //alert('yep it has subcols')
              this.SubCollections = []
              this.CurrentEntity.SubCollections.map(subcol => {
                let subcolheaders = []
                subcol.Headers.map(header => {
                  let headerobj = { text: header.text, value: header.value, Type: header.Type }
                  if(headerobj.Type === 'Option Set'){
                    headerobj.Options = header.Options
                    headerobj.propvalue = header.value
                    headerobj.value = headerobj.value+'.Name'
                  }
                  if(header.Primary){
                    headerobj.Primary = header.Primary
                  } 
                  if(header.CommonFieldType){
                    headerobj.CommonFieldType = header.CommonFieldType
                  }
                  if(header.IsContentEditable){
                    headerobj.IsContentEditable = header.IsContentEditable
                  }
                  if(header.FieldFormulas){
                    headerobj.FieldFormulas = header.FieldFormulas
                  }
                  subcolheaders.push(headerobj)
                })
                subcolheaders.push({ text: 'Linked From', value: 'LookupObj'})
                subcolheaders.push({ text: 'Action', value: 'action'})         
                let subcolobj = {
                  id: subcol.id,
                  Name: subcol.id,
                  Data: [],
                  Headers: subcolheaders,
                  Type: subcol.Type
                }
                if(subcol.Type === 'Lookup' && subcol.LookupBuilds){
                  //console.log('subcolobj',subcol,subcolobj)
                  subcolobj.LookupBuilds = subcol.LookupBuilds.map(build => {
                  let newbuildobj = {id: build.id, identifier: build.recordprimaryfield.Name,recordprimaryfield: build.recordprimaryfield}
                  let lookupbuild = this.ConfigSystemEntities.find(obj => obj.id === build.id)
                  //console.log('subcol obj',lookupbuild,this.ConfigSystemEntities,build.id)
                  if(lookupbuild){
                    newbuildobj.AllFields = lookupbuild.AllFields
                  }
                  
                   if(build.LinkedFields){
                    newbuildobj.LinkedFields = build.LinkedFields
                  }
                  if(build.single){
                    newbuildobj.single = build.single
                  }
                  if(build.SubCollections){
                    newbuildobj.SubCollections = build.SubCollections
                  }
                  //newbuildobj.recordprimaryfield = build.recordprimaryfield
                  if(build.Entity_Type){
                    newbuildobj.Entity_Type = build.Entity_Type
                    if(build.Entity_Type.Name === 'Store Product'){ 
                      let buildapp = this.AppsDataBase.find(obj => obj.id === lookupbuild.Primary_Appid)                     
                      let articletablefield = lookupbuild.AllFields.find(obj => obj.id === 'Inventory_Article')
                      if(articletablefield){
                        newbuildobj.ArticleTableField = articletablefield
                      }
                      if(buildapp && !buildapp.Plugins.find(obj => obj.id === 'Warehouse_Outbound')){
                        newbuildobj.Bypass_Picking_and_Packing = true
                      }
                      if(typeof subcol.Available_Stock_Only !== 'undefined'){
                        newbuildobj.Available_Stock_Only = subcol.Available_Stock_Only
                      }
                    }
                  }
                  if(lookupbuild.Security_Structure){
                    newbuildobj.Security_Structure = lookupbuild.Security_Structure
                  }
                  //console.log('newbuildobj',newbuildobj)
                  // Bypass_Receiving
                  return newbuildobj
                  })
                }
                if(subcol.ConfigValueasDocID){
                  subcolobj.ConfigValueasDocID = subcol.ConfigValueasDocID
                  subcolobj.DocIDProp = subcol.DocIDProp
                }
                if(subcol.Lookup_Once_Only){
                  subcolobj.Lookup_Once_Only = subcol.Lookup_Once_Only
                }
                if(subcol.PrimaryHeader){
                  subcolobj.PrimaryHeader = subcol.PrimaryHeader
                }
                if(subcol.HasSingleView){
                  subcolobj.HasSingleView = subcol.HasSingleView
                }
                 if(subcol.EntityBuilder){
                  subcolobj.EntityBuilder = subcol.EntityBuilder
                }
                 if(subcol.HeadersFromSubCol){
                  subcolobj.HeadersFromSubCol = subcol.HeadersFromSubCol
                  subcolobj.HeadersSubCollection = subcol.HeadersSubCollection
                }
                this.SubCollections.push(subcolobj)
                this.SubCollections.map(newsubcol => {
                  newsubcol.CanCreate = this.CanEdit
                  newsubcol.CanEdit = this.CanEdit
                  newsubcol.CanDelete = this.CanDelete
                  this.GetSubCollectionData(newsubcol.id,newsubcol.Data,newsubcol.LookupBuilds)
                  
                })
              })
              //now we still need to call the subcollectiondata and push to subcolobj.Data
              //this[subcol.id]
            }
            let array = JSON.parse(JSON.stringify(entity.SingleBuildTabs))
            if(entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'){
              let tab = {									
                RowColor: '#ffffff',									
                Name: 'Form Data',
                DisplayName: 'Form Data',
                DataViewName: 'Data Single Standard',	
                Icon: '',								
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                    Open: true,									
                    ShowComponents: true,									
                    Icon: 'mdi-database',									
                    Height: 300,									
                    Elements: [
                      {									
                RowColor: '#ffffff',									
                Name: this.CurrentEntity.WebFormTab.SingleName+' Form',
                DisplayName: this.CurrentEntity.WebFormTab.SingleName+' Form',
                DataViewName: 'Data Single Fields',	
                DataSingleStandard: true,	
                Fields: [],			
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-clipboard-list',									
                Height: 300,									
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 11,									
                FlexXSmall: 11,										
                Cols: 12,									
            },
                    ],									
                    Rows: [],									
                    BGColor: {
                        hex: '#ffffff',
                        hexa: '#ffffffff'
                },									
                    FontColor :{									
                            hex: '#000000'									
                    },									
                    HeaderColor: {									
                        hex: '#000000'									
                    },									
                    MarginTop: 0,									
                    MarginBottom: 0,									
                    MarginLeft: 0,									
                    MarginRight: 0,									
                    FlexXLRG:10,									
                    FlexLarge: 10,									
                    FlexMedium: 10,									
                    FlexSmall: 11,									
                    FlexXSmall: 11,									
                    Cols: 12,									
                }
                //console.log(this.CurrentEntity)
                let defaultwebformtabfields = [this.CurrentEntity.RecordPrimaryField] 
                defaultwebformtabfields = defaultwebformtabfields.concat(this.CurrentEntity.AllFields.filter(field => {
                  return field.UsedonWebForm
                }))
                tab.Elements[0].Fields = defaultwebformtabfields
                if(array.length === 0){
                  array.push(tab)
                }
                else{
                  //array.push(tab)
                }
                
            }
            if(entity.SinglePageBGGradient){
              this.PageBGGradient = entity.SinglePageBGGradient
              this.SinglePageBGGradient = entity.SinglePageBGGradient
            }
            this.SingleBuildTabs = array.map(tab => {
    
              // if(tab.InQuickNav){
                // let bannertab = {
                //   Name: tab.Name.split(' ').join(''),
                //   Icon: tab.Icon
                //   }                 
                // let banneron = this.BannerTabs.find(obj => obj.Name === bannertab.Name)
                // if(!banneron){
                //   this.BannerTabs.push(bannertab)
                // } 
               
              // }
              if(tab.Elements){
                tab.Elements.map(section => {
                  if(section.DataViewName === 'Data Single Activities' || section.DataViewName === 'Data Single Fields'){
                    if(!section.Name){
                      section.Name = section.DisplayName
                    }
                    if(section.Name){
                     let bannertab = {
                        Name: section.Name.split(' ').join(''),
                        }    
                        if(section.DataViewName === 'Data Single Fields' && !bannertab.Icon){
                          bannertab.Icon = 'mdi-database'
                        }  
                        else if(section.DataViewName === 'Data Single Activities' && !bannertab.Icon){
                          bannertab.Icon = 'mdi-clipboard-list'
                        }            
                      let banneron = this.BannerTabs.find(obj => obj.Name === bannertab.Name)
                      if(!banneron){
                        this.BannerTabs.push(bannertab)
                      } 
                    }
                    
                  }                    
                })
              }
              
              if(tab.Type === 'Standard'){
                //alert(typeof 12/tab.Sections.length)
                let sectionlength = tab.Sections.length
                //alert(typeof sectionlength)
                tab.Sections = tab.Sections.map(section => {
                  section.Cols = 12/sectionlength
                  section.ColsMD = section.Cols
                 
                  if(sectionlength > 1){
                    section.ColsSM = 6
                  }
                  else{
                    section.ColsSM = 12
                  }
                   if(!section.FlexXLRG){
                    section.FlexXLRG = section.ColsMD
                    section.FlexLarge = section.ColsMD
                    section.FlexMedium = section.ColsMD
                    section.FlexSmall = section.ColsSM
                    section.FlexXSmall = 12
                  }
                  if(section.SectionType === 'Fields'){
                    section.Fields = section.Fields.map(field => {
                      if(field.Name && field.Name.Name){
                        return field.Name
                      }
                      else{
                        return field
                      }
                      
                    })
                  }
                  return section
                })
              }
              else if(tab.DataViewName === 'Data Single Cabinet'){
                if(tab.Elements){
                  tab.Elements.map(elmnt => {
                    if(elmnt.MultipleFileUploadType === 'Images' || elmnt.MultipleFileUploadType === 'Document Register' || elmnt.MultipleFileUploadType === 'Gallery'){
                      elmnt.DataArray = []
                      let cabinet = ''
                      if(elmnt.MultipleFileUploadType === 'Document Register'){
                        cabinet = this.CurrentEntity.DocumentRegisters.find(obj => obj.id === elmnt.Entityid)
                        if(cabinet && cabinet.PrimaryOptions){
                          elmnt.PrimaryOptions = cabinet.PrimaryOptions
                          elmnt.PrimaryOptionsInteger = cabinet.PrimaryOptionsInteger
                        }
                        if(!elmnt.PrimaryOptions){
                          elmnt.PrimaryOptions = []
                        }
                        if(!elmnt.PrimaryOptionsInteger){
                          elmnt.PrimaryOptionsInteger = 1000000
                        }
                      }
                      else if(elmnt.MultipleFileUploadType === 'Gallery'){
                        cabinet = this.CurrentEntity.Galleries.find(obj => obj.id === elmnt.Entityid)
                        
                      }
                      this.CustomRolesDefault.map(defrole => {
                        //console.log(defrole.Prop,cabinet[defrole.Prop],cabinet)
                          if(cabinet[defrole.Prop]){
                            elmnt[defrole.Prop] = cabinet[defrole.Prop]
                          }
                          if(cabinet[defrole.Name]){
                            elmnt[defrole.Name] = cabinet[defrole.Name]
                          }
                          if(!elmnt[defrole.Name]){
                            elmnt[defrole.Name] = []
                          }
                        })
                        if(elmnt.MultipleFileUploadType === 'Document Register'){
                        let dbref = this.$store.state.NewTableStructure && !this.roottableentities.includes(this.CurrentEntity.id) ? this.OperationalDB.collection('DocumentRegisters').doc(elmnt.Name).collection('Items') : 
                        this.OperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase())
                        this.GetCabinetDocuments(dbref,elmnt)
                      }
                      else if(elmnt.MultipleFileUploadType === 'Gallery'){
                        let dbref = this.$store.state.NewTableStructure && !this.roottableentities.includes(this.CurrentEntity.id) ? this.OperationalDB.collection('Galleries').doc(elmnt.Name).collection('Items') : 
                        this.OperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase())
                        this.GetCabinetDocuments(dbref,elmnt)
                      }
                    }
                  })
                }
              }
              return tab
            })
            //console.log(this.SingleBuildTabs)
            if(this.$route.query && this.$route.query.TableType === 'Operational_Entity'){
              this.FinalizerecordLoadingdialog()
            }
            // this.EntityReady = true
          }
          else{
            // this.EntityReady = true
          }
      })
    },
    GetCabinetDocuments(query,elmnt){      
      query.onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                elmnt.DataArray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
               if (change.type === 'modified') {
                let entry = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = elmnt.DataArray.find(obj => obj.id === entry.id)
                if(oncheck){
                  let index = elmnt.DataArray.indexOf(oncheck)
                  elmnt.DataArray.splice(index,1,entry)
                }
              }
               if (change.type === 'removed') {
                let entry = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = elmnt.DataArray.find(obj => obj.id === entry.id)
                if(oncheck){
                  let index = elmnt.DataArray.indexOf(oncheck)
                  elmnt.DataArray.splice(index,1)
                }
              }
            })
      })
    },
    RoutetoEntityBuilder(){
      //wellthe below sure don't work for entties on systemsingle
      //meaning, e.g. I need entitybuilder, when vewing from System/qMWrs8CTLGyFvmtctuTu?TableEntity=Entities&RecordCollection=entities&Record=Service_Bookings
      //on the other hand, we do get the record no problem, record comes from EntityRecord which we pass below
      //
     
      let obj  = Object.assign({},this.DynamicRecordData)
      if(!obj.SingleBuildTabs){
        obj.SingleBuildTabs = []
      }
      let AllFieldssubcol = obj.SubCollections.find(obj => obj.id === 'AllFields')
      obj.AllFields = AllFieldssubcol.Data
      if(!obj.SingleName){
        obj.SingleName = obj.DisplayName
      }
      //console.log(obj)
      let ConfigDB = db.collection(this.$route.meta.Collection).doc(this.RouteID)
      //console.log(this.ConfigDB,ConfigDB,this.OperationalDB)
      let ConfigSystemEntities = [obj]
      //console.log(this.CurrentEntity)
     
      if(this.$route.query.Record){
        let newroute = { name: 'TableViewer',query:{TableEntity: this.$route.query.Record, Record: this.RouteID}, params: {slug: this.$route.meta.subcat,id: 'Sample_Records', EntityRecord: obj,EntityConfigDB: ConfigDB,EntityConfigSystemEntities: ConfigSystemEntities }}
        this.$router.push(newroute)
      }
      else{
        let newroute = { name: 'TableViewer',query:{Record: this.RouteID}, params: {slug: this.$route.meta.subcat,id: 'Sample_Records', EntityRecord: obj,EntityConfigDB: ConfigDB,EntityConfigSystemEntities: ConfigSystemEntities }}
        this.$router.push(newroute)
      }      
      
    },
    
    EditSingleForm(){
      this.SingleRecordNavMenuItem = ''
      this.SingleFormEditing = !this.SingleFormEditing
      
      if(this.CurrentEntity.SingleRecordNavMenu){
        //this.SingleRecordNavMenu = !this.SingleRecordNavMenu
      }
    },
    UpdateFeatureList(){
      if(this.FeatureNavList){
         this.FeatureNavList.FeatureList.map(feature => {
           let prop = feature.Name
           if(typeof this.DynamicRecordData[prop] !== 'undefined'){
             let value = this.DynamicRecordData[prop]
             db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).set({
              [prop] : value
            })
           }            
         })
      }
    },
    UpdateRecordCalculation(Record,field,value){
      if(typeof value !== 'undefined'){
        Record[field.Name] = value
        //  this.OperationalDB.update({
        //   [field.Name]: value
        // })
        // if(field.Name === 'Order_Total' && this.StoreProductEntity){
        //   let taxtotal = value*(this.$store.state.DefaultTax_Percentage/100)
        //   let grandtotal = value+taxtotal
        //    this.OperationalDB.update({
        //     Grand_Total: grandtotal,
        //     Tax_Total: taxtotal
        //   })
        // }
      }
        //console.log('UpdateRecordCalculation',Record,this.DynamicRecordData,field.Name,this.OperationalDB)
       
      },
    PrepareFieldupdateEmail(triggerfield,Record){
      let newrecord = Object.assign({},Record)
      //console.log('PrepareFieldupdateEmail this.UsersArray',this.UsersArray,triggerfield.RecipientValue.id)
      //alert('yay email prep')
      //console.log(FieldObject)
      let emailto = ''
      if(triggerfield.RecipientType === 'User'){
        let userobj = this.UsersArray.find(obj => obj.id === triggerfield.RecipientValue.id)
        emailto = userobj.Email
      }
      else if(triggerfield.RecipientType === 'Related User'){        
        let recordvalue = Record[triggerfield.RecipientValue.id]
        let userobj = this.UsersArray.find(obj => obj.id === recordvalue.id)
        //console.log('Related User',triggerfield.RecipientValue,triggerfield.RecipientValue.id,recordvalue,userobj,Record)
        emailto = userobj.Email
      }
      else if(triggerfield.RecipientType === 'Email Field'){
        let userfield = this.CurrentEntity.AllFields.find(obj => obj.id === triggerfield.RecipientValue.id)
        let recordvalue = Record[userfield.Name]
        emailto = recordvalue
      }
      else if(triggerfield.RecipientType === 'Static'){
        emailto = triggerfield.RecipientValue
      }
      if(emailto){
      //console.log('EmailBody Record',newrecord)
      let FormFields = triggerfield.EmailFormFields.map(item => {
        let fieldobj = this.CurrentEntity.AllFields.find(obj => obj.id === item)
        let newfieldobj = Object.assign({},fieldobj)
        if(fieldobj.Type === 'Lookup' || fieldobj.Type === 'Social Lookup'){
            newrecord[fieldobj.Name] = newrecord[fieldobj.Name][fieldobj.LookupFieldName]
        }
        else if(fieldobj.Type === 'Option Set'){[
            newrecord[fieldobj.Name] = newrecord[fieldobj.Name].Name
        ]}
        
        return newfieldobj
      })
      
        //console.log('EmailBody FormFields',FormFields)
      let EmailBody = `<html>
<body>
<head></head>
<h2>`+triggerfield.EmailSubject+`</h2>`+
`<p>`+triggerfield.EmailGreetingLine+`</p>
<table>`
             let length = FormFields.length
            FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.DisplayName+`:</th>
<td align="left">`+newrecord[field.Name]+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>`
                 } 
            })
          if(triggerfield.EmailincludesLinktoRecord){
          EmailBody = EmailBody+`<p>To view the details more details, click <a href="`+window.location.href+`">here</a></p>`  
          }
          if(triggerfield.EmailincludesUpdatingUserName){
          EmailBody = EmailBody+`<i>This update was made by `+this.userLoggedIn.Full_Name+` on `+new Date()+`</i>`  
          }
          
          EmailBody = EmailBody+`
</body>
</html>`  
            //console.log('EmailBody',EmailBody)
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: emailto,
                EmailSubject: triggerfield.EmailSubject,
                EmailBody: EmailBody
            }
            //console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              //console.log(result)
              this.EmailBody = ''
            })
      }
      else{
        //report to admin
      }
      
    },
    UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList){
      //console.log(FieldObject)
      let fieldobj = this.CurrentEntity.AllFields.find(obj => obj.id === prop)
      //console.log('fieldobj ',prop,fieldobj,this.CurrentEntity.AllFields)
      if(fieldobj && fieldobj.EmailonUpdate){
        this.PrepareFieldupdateEmail(fieldobj,FieldObject)
      }
      //this[prop] = value
      this.UpdateFeatureList()
      if(FromFeatureList && this.FeatureNavList.FeatureListType === 'Lookup' && typeof value !== 'undefined'){
        if(this.FeatureNavListLookupEntry){
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).update({
            [prop] : value
          })
        }
        else{
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).set({
            [prop] : value
          })
        }
        
      }
      //console.log(prop,value,AdditionalSaveMethod,FieldObject,this.$route.meta.Collection,this.OperationalDB)
      if(AdditionalSaveMethod && typeof value !== 'undefined'){
        this[AdditionalSaveMethod](prop,value,AdditionalSaveMethod,FieldObject)
      }
      else if(!AdditionalSaveMethod && typeof value !== 'undefined'){
        if(this.$route.meta && this.$route.meta.RecordisSystem && !this.SystemisRA){
          this.RAOperationalDB.update({
            [prop]: value
          }).then(radoc => {
            this.OperationalDB.update({
              [prop]: value
            })
          })
        }
        else if(fieldobj && fieldobj.Type === 'Option Set' && fieldobj.HasDeepHierarchy){
          //console.log(fieldobj.Levels[1],fieldobj.Levels[1].FieldName)
          //console.log(FieldObject[fieldobj.Levels[1].FieldName])
              if(fieldobj.Levels && fieldobj.Levels[1] && FieldObject[fieldobj.Levels[1].FieldName]){                
                if(fieldobj.Levels && fieldobj.Levels[2] && FieldObject[fieldobj.Levels[2].FieldName]){
                  this.OperationalDB.update({
                    [prop]: value,
                    [fieldobj.Levels[1].FieldName]: FieldObject[fieldobj.Levels[1].FieldName],
                    [fieldobj.Levels[2].FieldName]: FieldObject[fieldobj.Levels[2].FieldName]
                  })
                }
                else{
                  this.OperationalDB.update({
                    [prop]: value,
                    [fieldobj.Levels[1].FieldName]: FieldObject[fieldobj.Levels[1].FieldName],
                  })
                }
              }
              else{
               this.OperationalDB.update({
                    [prop]: value,
                  })
              }
            }
        else{
          this.OperationalDB.update({
            [prop]: value
          })
           if(prop === 'Order_Total' && this.StoreProductEntity){
          let taxtotal = value*(this.$store.state.DefaultTax_Percentage/100)
          let grandtotal = value+taxtotal
           this.OperationalDB.update({
            Grand_Total: grandtotal,
            Tax_Total: taxtotal,
            Tax_Percentage: this.$store.state.DefaultTax_Percentage
          })
        }
          if(FieldObject.IsSearch || FieldObject.Primary){
            let NameQuery = []
            let PrimaryString = ''
              if(FieldObject.Type === 'Single Line Text'){
                PrimaryString = value.trim()
              }
              else if(FieldObject.Type === 'Option Set'){
                PrimaryString = value.Name.trim()
              }
            var lowertext = "";       
            var p;
            let buildup = ''
            //console.log(PrimaryString.length,PrimaryString)
            for (p = 0; p < PrimaryString.length; p++) {
                buildup = buildup+ PrimaryString[p]
                if(p === PrimaryString.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
            } 
            let SearchQuery = []
            if(this.RecordData.SearchQuery){

            }      
            NameQuery = lowertext.split(',') 
            NameQuery.length = NameQuery.length-1
            this.OperationalDB.update({
              [prop+'Query']: NameQuery
            })
          }
        }
        this.UpdateRecordSearchQuery()
      }
      //the issue is "Updateaccount" is called, and actually "updateaccount" does not ahve the new "Description" field...
    },
    UpdateRecordSearchQuery(){
      setTimeout(() => {
        this.OperationalDB.update({
          SearchQuery: this.UpdatedSearchQuery
        })  
      }, 1000);      
    },
    ToggleDialog(dialogname){
      this[dialogname] = !this[dialogname]
    },
    
    
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
    FinalizerecordLoadingdialog(){
            this.$emit('ActivateRecordLoadingDialog',true,100)
            let featurenavlist = this.Directories.find(obj => typeof obj.FeaturesListRelatedBuildID !== 'undefined' && obj.FeaturesListRelatedBuildID === this.CurrentEntity.id)
                //console.log('featurenavlist',featurenavlist,this.Directories,this.CurrentEntity,this.OperationalDB)
                if(featurenavlist){
                  this.FeatureNavList = featurenavlist
                  if(this.FeatureNavList.FeatureListType === 'Lookup'){
                      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).onSnapshot(snapshot => {
                        let data = snapshot.data()
                        if(!data){
                          // db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID)
                        }
                        else{
                          this.FeatureNavListLookupEntry = data
                          this.FeatureNavListLookupEntry.id = this.RouteID
                        }
                        //console.log('this.FeatureNavListLookupEntry',this.FeatureNavListLookupEntry)
                      })
                    }
                }
                else{
                  featurenavlist = this.Directories.find(obj => obj.id === this.CurrentEntity.id)
                  if(featurenavlist && featurenavlist.FeatureListType === 'Standard'){
                    this.FeatureNavList = featurenavlist
                  }
                }
            setTimeout(() => {
              //Moved  this.EntityReady = true to HasEntityandRecord when systemconfig
                  if(this.$route.meta && !this.$route.meta.RecordisSystem || !this.SingleRecordNavMenu){
                    this.EntityReady = true
                    this.$emit('PushRecordName',this.RecordData[this.CurrentEntity.Primary_Field_Name])
                  }
                  //alert('recordfinalcalled')
                  this.$emit('ActivateRecordLoadingDialog',false,100)
            }, 1000);
        },  
     CheckRouting(){
      //nevermind right?
     },
     GetRequestingUser(){      
        var vm = this;
        if (this.userLoggedIn) {
              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
                vm.$emit('ActivateRecordLoadingDialog',true,75)
                
                vm.CheckRouting()
                vm.GetSystemRecord()
            //console.log(vm.userBU)
            vm.UserBusinessUnitID = vm.userBU.id
            //console.log(vm.UserBusinessUnitID)
            vm.UserBusUnitChildren = vm.userBU.childarrayQuery
            vm.UserBusUnitParents = vm.userBU.parentarray
            //console.log(vm.UserBusUnitChildren)
              vm.UserRoles = vm.userLoggedIn.rolesarrayQuery
              vm.UserBusinessUnitID = vm.userLoggedIn.Business_Unitid
              //console.log(vm.UserBusinessUnitID)
              db.collection('SystemConfig').doc(vm.$store.state.ActiveSuiteid).collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             //console.log(vm.UserRoles)
             let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }   
                if(!vm.$route.meta.RecordisSystem && vm.CurrentEntity && vm.CurrentEntity.datasetsecuritystructure === 'Business Unit'){
                   vm.BURolesCheck(vm.CurrentEntity.AllgetRoles,vm.CurrentEntity.UnitDowngetRoles,vm.CurrentEntity.UserUnitgetRoles,vm.CurrentEntity.OwnergetRoles).then(PermissionLevel => {
                    //console.log(PermissionLevel)
                    if(PermissionLevel === 4 || vm.IsAdmin === true){
                      vm.listAll = true
                      //console.log('Permissions for Systems is All')
                      //vm.GetSystemBUCheck()
                    }
                    else if(PermissionLevel === 3){
                      vm.listUnitDown = true
                      //console.log('Permissions for Systems is UnitDown')
                    // vm.GetSystemBUCheck()
                    }
                    else if(PermissionLevel === 2){
                      vm.listUserUnit = true
                      //console.log('Permissions for Systems is UserUnit')
                      //vm.GetSystemBUCheck()
                    }
                    else if(PermissionLevel === 1 || vm.IsAdmin){
                      vm.listOwner = true                   
                      //console.log('Permissions for Systems is Owner')
                      //vm.GetSystemBUCheck()
                    }
                    else {
                      vm.RoutetoErrorPage()
                        }
                  })  
                }           
             
              })
          }
      else {
        vm.RoutetoErrorPage()
          }
      },
      GetActivityQueries(){
        let vm = this
        let STDAppointmentsQuery = db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Appointment').where('Regarding_Type', '==', vm.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID)
        vm.AppointmentsQuery = STDAppointmentsQuery
        let STDTasksQuery = db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Task').where('Regarding_Type', '==', vm.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID)
        vm.TasksQuery = STDTasksQuery
        let STDEmailsQuery = db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Email').where('Regarding_Type', '==', vm.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID)
        vm.EmailsQuery = STDEmailsQuery
        let STDNotesQuery = db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Note').where('Regarding_Type', '==', vm.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID)
        vm.NotesQuery = STDNotesQuery
        let STDPhoneCallsQuery = db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('activities').where('Type.Name','==','Phone Call').where('Regarding_Type', '==', vm.CurrentEntity.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.RouteID)
        vm.PhoneCallsQuery = STDPhoneCallsQuery
      },
    CustomRolesCheck(Roles){
    let vm = this
    let PermissionLevel = 0
    let Roleslength = vm.UserRoles.length
    //console.log(Roleslength)
    return new Promise(function(resolve, reject) {
    vm.UserRoles.map((role,i) => {
        //console.log(i)
            let Rolestest = Roles.find(obj => obj == role)
            if(Rolestest){
                PermissionLevel = 1
            }      
            if(i-1+2 === Roleslength){
                resolve(PermissionLevel)
            }
            })

    })
    },
    BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
    let vm = this
    let PermissionLevel = 0
    let Roleslength = vm.UserRoles.length
    //console.log(Roleslength)
    return new Promise(function(resolve, reject) {
    vm.UserRoles.map((role,i) => {
        //console.log(i)
            let Alltest = AllRoles.find(obj => obj == role)
            let Peertest = PeerRoles.find(obj => obj == role)
            let Parenttest = ParentRoles.find(obj => obj == role)
            let Childtest = ChildRoles.find(obj => obj == role)
            if(Alltest){
                PermissionLevel = 4
            }                
            else if(Peertest){
                if(PermissionLevel<3){
                PermissionLevel = 3
                }
            }                  
            else if(Parenttest){
                if(PermissionLevel<2){
                PermissionLevel = 2
                }
            } 
            else if(Childtest){
                if(PermissionLevel<1){
                PermissionLevel = 1
                }
                
            } 
            if(i-1+2 === Roleslength){
                resolve(PermissionLevel)
            }
            })

    })
    },
    RoutetoLoginPage(){
    this.$router.push('/login')
    },
    RoutetoErrorPage(){
    this.$router.push('/PermissionError')
    },
    GetExporttoPDFFunctions(){
    //console.log(this.FunctionsRef)
    this.FunctionsRef.where('FunctionEntity','==',this.ConfigEntityID).onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                this.ExporttoPDFFunctions.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
            })
    })
    },
    GetProcessData(systemrecord){
              
              let ProcessObj = this.CurrentEntity.EntityProcesses.find(obj => obj.id == systemrecord.ActiveProcess.id)
              if(ProcessObj){
                this.ActiveProcessDocid = systemrecord.ActiveProcessDocid            
                
                this.ProcessStages = JSON.parse(JSON.stringify(ProcessObj.ProcessStages))
              if (typeof systemrecord.stagenameid !== 'undefined'){
                let stageobj = this.ProcessStages.find(obj => obj.Number === systemrecord.stagenameid)
                this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
              }
              this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
                    var stagedata = stagesnap.data()                    
                    this.ActiveProcess = stagedata
                    this.StageName = stagedata.StageName
                    if(this.StageName){
                      let stageobj = this.ProcessStages.find(obj => obj.Number === systemrecord.stagenameid)
                    this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
                    this.ProcessStages = this.ProcessStages
                    .map(stage => {
                      if(stage.Steps && stage.Steps.length>0){
                        stage.Steps = stage.Steps.map(step => {
                          if(typeof stagedata[step.Name] !== 'undefined'){
                            step.Prop = stagedata[step.Name]
                          }
                          return step
                        })
                      }
                      return stage
                    })
                  }
                })
              }
              
    },
    GetRelatedData(table,query,fieldid){        
        let fieldvalue = fieldid+'id'
        //alert(this.RouteID)
        //console.log('query',query)
        if(!Array.isArray(query)){
        query.where(fieldvalue,'==',this.RouteID).onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                table.StoreData.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
        })
        }
        else{
          // let length = query.length
          // query.map((singlequery,singlequeryindex) => {
            query[0].where(fieldvalue,'==',this.RouteID).onSnapshot(res => {
              const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    table.StoreData.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                })
            // })
          })
        }
    },
    GetSystemRecord() {
        let vm = this
        if(!this.PushedID && this.$route.meta && this.ConfigEntityID){
          this.GetExporttoPDFFunctions()  
        }
        
        if(vm.IsAdmin){
          vm.CanEdit = true
          vm.$store.commit('SetCurrentItemModerator',true) 
        }
        console.log(this.OperationalDB)
            this.OperationalDB.onSnapshot((snapshot) => {
            var systemrecord = snapshot.data()
            if(!systemrecord){
              this.$emit('ActivateRecordLoadingDialog',false,100)
              this.$router.push('/404')
            }
            if(systemrecord.ActiveProcessDocid){
              this.GetProcessData(systemrecord)
            }            
            //console.log(systemrecord)
            systemrecord.id = this.RouteID
            if(systemrecord.Status){
              this.Status = systemrecord.Status
            }
            if(systemrecord.Status_Reason){
              this.Status_Reason = systemrecord.Status_Reason
            }
            this.RecordData = systemrecord  
            if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'){
            let wh = this.$store.state.WarehousesArray.find(obj => obj.id === systemrecord.Warehouseid)
            //let called = false
            if(wh){
             let WarehouseActions = [
              {Display: 'Inbound',Prop: 'InboundTransaction', ArrayName: 'Inbound_Transactions'},
              {Display: 'Outbound',Prop: 'OutboundTransaction', ArrayName: 'Outbound_Transactions'},
            ]
            if(this.userLoggedIn.Warehouses[wh.id]['Receiving'] || this.userIsAdmin){
              this.$store.commit('SetCanReceive',true)
            }
              WarehouseActions.map(act => {
                //console.log('WarehouseActions',wh)
                if(this.userIsAdmin){
                  this.$store.commit('SetCanViewMovementHistory',true)
                }
                else if(this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id]){
                  for(var prop in this.userLoggedIn.Warehouses[wh.id]) {
                    if (this.userLoggedIn.Warehouses[wh.id].hasOwnProperty(prop)) {
                      if(this.userLoggedIn.Warehouses[wh.id][prop] || this.userIsAdmin){
                        if(prop === 'Receiving'){
                         this.$store.commit('SetCanReceive',true)
                        }
                        this.$store.commit('SetCanViewMovementHistory',true)
                        //called = true
                      }
                    }
                  }
                }
              }) 
            }
            
          
        }          
            if(this.CurrentEntity && this.CurrentEntity.HasActivities){
             this.GetActivityQueries() 
            }     
            
            //console.log('this.RecordData',this.RecordData,systemrecord,this.RelatedDataTabs,this.ComputedSingleBuildTabs)
            this.RecordData.RelatedTables = []
            if(this.RelatedDataTabs){
              this.RecordData.RelatedTables = this.RelatedDataTabs.map(elmnt => {
                
                let entity = this.SystemEntities.find(obj => obj.id === elmnt.Entityid)
                elmnt.RelationshipField = Object.assign({},entity.AllFields.find(obj => obj.id === elmnt.RelationshipField.id))
                if(elmnt.RelationshipField.MappedValues){
                  elmnt.RelationshipField.RecordData = this.RecordData
                }
                let fieldid = elmnt.RelationshipField.id+'id'
                let tablename = elmnt.Entityid.split('_').join('')
                let storequery = tablename+'Query'
                let storearray = tablename+'Array'
                let relatedtable = {
                  id: elmnt.Entityid,
                  TableName: elmnt.Entityid.split('_').join(''),
                  StoreQuery: tablename+'Query',
                  StoreArrayName: tablename+'Array',
                  StoreData: []
                }
                
                
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  //console.log('this.$store.state[storearray]',this.$store.state[storearray],this.RouteID)
                  relatedtable.StoreData = this.$store.state[storearray].filter(record => {
                    return record[elmnt.RelationshipField.id+'id'] === this.RouteID
                  })
                }
                else if(this.$store.state[storequery]){
                  this.GetRelatedData(relatedtable,this.$store.state[storequery],elmnt.RelationshipField.id)
                  // let storepayload = {
                  //         query: this.$store.state[storequery],
                  //         arrayname: storearray
                  //       }
                  //       console.log(storepayload,vm.$store.state,storearray)
                  //       this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                          // relatedtable.StoreData = vm.$store.state[storearray]
                        // })
                }
                //console.log('RelatedTables this.RecordData.RelatedTables relatedtable',relatedtable)
                return relatedtable
              })
              //console.log('this.RecordData.RelatedTables',this.RecordData.RelatedTables)
            }
            //console.log('this.$store.state',this.$store.state)
               if(!this.userIsAdmin){
                 //maybe unnceessary but seems we forgot custom roles check...
              if(this.$route.meta && this.$route.meta.subcat){
                if(this.$store.state[this.$route.meta.subcat.split(' ').join('')+'CanEdit']){
                  this.CanEdit = true
                  this.$store.commit('SetCurrentItemModerator',true)   
                }
              }
              if(this.CurrentEntity && this.CurrentEntity.datasetsecuritystructure === 'Warehouse' && systemrecord.Warehouseid && this.userLoggedIn.Warehouses){
                   this.CanEdit = this.userLoggedIn.Warehouses[systemrecord.Warehouseid].Update
                   if(this.CanEdit){
                     this.$store.commit('SetCurrentItemModerator',true)
                   }
              }
              if(this.editAll){
                  this.CanEdit = true       
                  this.$store.commit('SetCurrentItemModerator',true)   
                }
                else if(this.editUnitdown){
                  if(this.userBUChildren.includes(systemrecord.Business_Unitid)){
                    this.CanEdit = true  
                    this.$store.commit('SetCurrentItemModerator',true)
                    }
                }
                else if(this.editUserUnit){
                  if(systemrecord.Business_Unitid === this.userBUID){
                    this.CanEdit = true  
                    this.$store.commit('SetCurrentItemModerator',true)
                    }
                }
                else if(this.editOwner){
                  if(systemrecord.Ownerid === this.UserRecord.id){
                    this.CanEdit = true 
                    this.$store.commit('SetCurrentItemModerator',true) 
                    }
                }
            }
            this.selectedRecord = {id: this.RouteID, Name: systemrecord.Name }
                   
            //this.GetEntitiesSubColArray()
            //Moved RouteObj apply to HasEntityandRecord watcher
            if(this.$route.meta && this.$route.meta.SystemBuilder && !this.$route.meta.RecordisSystem){
              this.GetSystemConfig(systemrecord)
            }
            else if(this.$route.meta && this.$route.meta.RecordisSystem){
             //this.FinalizerecordLoadingdialog() 
             //console.log('this.SystemEntities,this.ConfigSystemEntities',this.SystemEntities,this.ConfigSystemEntities)
            }
            else{
              //alert('is else')
             
              this.FinalizerecordLoadingdialog()
            }
            
            this.RecordData = systemrecord
            this.Status_Reason = systemrecord.Status_Reason
            this.RecordData.id = this.RouteID
            this.RecordObj = this.RecordData
            this.$emit('PushRecordName',this.RecordData[this.CurrentEntity.Primary_Field_Name])
            if(typeof systemrecord.Owner !== 'undefined'){
              let tempOwner = systemrecord.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof systemrecord.Owner !== 'undefined'){
              this.Business_Unit = systemrecord.Business_Unit
            }
                
            this.Name = systemrecord.Name
            this.editedItem.Name = systemrecord.Name
            if(this.CurrentEntity && this.CurrentEntity.AllFields){
              let lookupfields = this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Lookup' || field.Type === 'Social Lookup'  
              })
              lookupfields.map(field => {
                let storequery = field.RelatedBuildID.split('_').join('')+'Query'
                let storearray = field.RelatedBuildID.split('_').join('')+'Array'
                if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                  field.Array = this.$store.state[storearray]
                  if(field.IsMultiple){
                   field.Array = field.Array.map(item => {                      
                        let itemobj = {
                          [field.LookupFieldName]: item[field.LookupFieldName],
                          id: item.id
                        }
                          return itemobj
                    })
                   }
                  if(this.RecordData[field.Name+'id']){
                    let lookupobj = field.Array.find(obj => obj.id === this.RecordData[field.Name+'id'])
                    if(lookupobj){
                      this.RecordData[field.Name] = lookupobj
                    } 
                  } 
                }
                else if(this.$store.state[storequery]){
                  let storepayload = {
                    query: this.$store.state[storequery],
                    arrayname: field.RelatedBuildID.split('_').join('')+'Array'
                  }
                  //console.log(storepayload,vm.$store.state,storearray)
                  vm.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                    //console.log('store GetCollectionArray',result,storearray,vm.$store.state[storearray])
                    field.Array = vm.$store.state[storearray]
                    if(field.IsMultiple){
                   field.Array = field.Array.map(item => {                      
                        let itemobj = {
                          [field.LookupFieldName]: item[field.LookupFieldName],
                          id: item.id
                        }
                          return itemobj
                    })
                   }
                    if(vm.RecordData[field.Name+'id']){
                      let lookupobj = field.Array.find(obj => obj.id === vm.RecordData[field.Name+'id'])
                      if(lookupobj){
                        vm.RecordData[field.Name] = lookupobj
                      } 
                    }
                  })
                }
              })
            }
        }, (error) => {
              //console.log("error",error)
              });
    },
      
          GetSubColSubCol(entityid,record,subcolname,subcolquery,headers){
      let subcol = {
        SubColName: subcolname,
        SubColData: [],
        
      }
      if(entityid){
        subcol.PrimaryEntity = entityid
      }
      if(headers){
        subcol.Headers = headers
      }
      let vm = this									
       return new Promise(function(resolve, reject) {		
         subcolquery.onSnapshot(res=> {
           let length = res.docs.length
           if(length === 0){
             resolve(record)
           }
           else{
             if(!record.SubCollections){
               record.SubCollections = []
             }
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                subcol.SubColData.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                if(subcol.SubColData.length === length){
                  record.SubCollections.push(subcol)
                  resolve(record)
                }
              }
            })
           }
           
         })
       })
    },
    SwitchtoDynamicWiki(){
      let wikirouteobj = { name: 'entitywiki', params: {DynamicWikiData: this.DynamicWikiData }}
      this.$router.push(wikirouteobj)
    },
      
      
      ResetStatus(){
      this.Status = Object.assign({},this.RecordData.Status)
      this.Status_Reason = Object.assign({},this.RecordData.Status_Reason)
      this.ChangeStatusDialog = false
    },
    UpdateStatus(){
      this.OperationalDB.update({
        Status: this.Status,
        Status_Reason: this.Status_Reason,
        State_Modified_Date: new Date()
      })
      if(this.ClassifiedListingDB){
        const functions = firebase.functions();
        const UpdateRecordData = functions.httpsCallable('UpdateRecordData'); 
        let payload = {
          collection: this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings',
          RecordID: this.RouteID,
          UpdatedValues: [
            {Prop: 'Status', Value: this.Status.Status,Prop: 'Status_Reason', Value: this.Status_Reason}
          ],
          siteid: this.$store.state.ActiveSuiteid
        }
        UpdateRecordData(payload).then(result => {
            //console.log(result)
        })
      }
      this.ChangeStatusDialog = false
    },
      DateFormatterwithTimeXML(date){
        if(date){
              //console.log(date)
              //console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateSystem(){
        this.UndefinedMandatoryFields = []
      const EditedSystem = {
    
        }
        //console.log(EditedSystem)
        
      for(var prop in EditedSystem) {
          if (EditedSystem.hasOwnProperty(prop)) {
            if(typeof EditedSystem[prop] === 'undefined' || EditedSystem[prop] === '' || EditedSystem[prop] === null){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            //console.log('something is amook')
          }
        }
        //console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
      this.OperationalDB.update({
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedSystemsnackbar = true
              this.CancelSystemEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Name !== 'undefined'){                  
                  let NameQuery = []
                  let NameStringArray = this.Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   //console.log(NameStringArray.length,NameStringArray)
                    for (p = 0; p < NameStringArray.length; p++) {
                        buildup = buildup+ NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    NameQuery = lowertext.split(',')  
                    NameQuery.length = NameQuery.length-1
                   this.OperationalDB.update({
                     NameQuery: NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                if(typeof this.SystemFromEmailHost !== 'undefined'){
                   this.OperationalDB.update({
                      SystemFromEmailHost: this.SystemFromEmailHost,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SystemFromEmailPassword !== 'undefined'){
                   this.OperationalDB.update({
                      SystemFromEmailPassword: this.SystemFromEmailPassword,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SystemFromEmailSecure !== 'undefined'){
                   this.OperationalDB.update({
                      SystemFromEmailSecure: this.SystemFromEmailSecure,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.StatementofIntent !== 'undefined'){
                   this.OperationalDB.update({
                      StatementofIntent: this.StatementofIntent,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.ClientAppURL !== 'undefined'){
                   this.OperationalDB.update({
                      ClientAppURL: this.ClientAppURL,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SystemFromEmailAddress !== 'undefined'){
                   this.OperationalDB.update({
                      SystemFromEmailAddress: this.SystemFromEmailAddress,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.CustomDashboardsDisabled !== 'undefined'){
                   this.OperationalDB.update({
                      CustomDashboardsDisabled: this.CustomDashboardsDisabled,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.GuestsareRegional !== 'undefined'){
                   this.OperationalDB.update({
                      GuestsareRegional: this.GuestsareRegional,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.HasPublicSignup !== 'undefined'){
                   this.OperationalDB.update({
                      HasPublicSignup: this.HasPublicSignup,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SiteHasStore !== 'undefined'){
                   this.OperationalDB.update({
                      SiteHasStore: this.SiteHasStore,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SiteSendsEmails !== 'undefined'){
                   this.OperationalDB.update({
                      SiteSendsEmails: this.SiteSendsEmails,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SiteStockQtyHidden !== 'undefined'){
                   this.OperationalDB.update({
                      SiteStockQtyHidden: this.SiteStockQtyHidden,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SiteStorePricesHidden !== 'undefined'){
                   this.OperationalDB.update({
                      SiteStorePricesHidden: this.SiteStorePricesHidden,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.SiteStoreisGuestsOnly !== 'undefined'){
                   this.OperationalDB.update({
                      SiteStoreisGuestsOnly: this.SiteStoreisGuestsOnly,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          //console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          //console.log('here is element')
          //console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.SystemsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('systems').doc(item.id).delete() && this.SystemsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.SystemsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
              this.EditingSection2 = false
              this.EditingSection3 = false
              this.EditingSection1 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelSystemEditing(){
      this.EditingOwnership = false
    },
    EditOwnership() {
      this.EditingOwnership = !this.EditingOwnership
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
    //bu only
    AssignBU(){
      let userid = this.DynamicRecordData.Owner.id
      let ownerobj = {
        id: this.DynamicRecordData.Owner.id,
        Full_Name: this.DynamicRecordData.Owner.Full_Name,
        Name: this.DynamicRecordData.Owner.Name,
        Surname: this.DynamicRecordData.Owner.Surname
      }
      this.CreateLiaisonNotificationtoOwner(this.DynamicRecordData)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userid).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        let BUId = userdata.Business_Unitid
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(BUId).onSnapshot(snapshot => {
        let BUdata = snapshot.data()
        let buobj = {
          id: BUId,
          Name: BUdata.Name
        }
        this.OperationalDB.update({
          Owner: ownerobj,
          Ownerid: ownerobj.id,
          Business_Unit: buobj,
          Business_Unitid: buobj.id
        })
        
      })
      })
    },
    CreateLiaisonNotificationtoOwner(record){
        let owneruser = record.Owner
        let NewComms = {
          description: this.userLoggedIn.Full_Name+' assigned the record to '+owneruser.Full_Name,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.CurrentEntity.DisplayName,
          recordlocation: '/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.CurrentEntity.id,
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        //console.log(NewComms)
        this.SaveNewcomms(NewComms,record)  
    },
    SaveNewcomms(NewComms,record){
        let commscol = db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(record.id).collection('InternalLiaison')
        //console.log(commscol)
        let owneruser = record.Owner
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.CurrentEntity.Primary_Field_Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.CurrentEntity.Primary_Field_Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
    
      
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}

.stickytopright {
  position: fixed;
  top: 110px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>